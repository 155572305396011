import { Button } from '@npm/core/ui/components/atoms/Button';
import { Label } from '@npm/core/ui/components/atoms/Label';
import styled from 'styled-components';

// TODO: check with designers whether it shouldn't be adjusted on a global level here:
// libs/core/ui/src/components/atoms/Label/Label.styles.tsx:55:40
export const StyledAvailableLabel = styled(Label)`
  &&&.ant-tag {
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  }
`;

export const StyledUnlockButton = styled(Button).attrs({
  size: 'sm',
  variant: 'text',
})`
  && {
    height: 16px;
  }
`;
