// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FirmsApi,
  InternalFirmsIndexErrorCodes,
  InternalFirmsIndexErrorTypes,
  InternalFirmsCreateErrorCodes,
  InternalFirmsShowErrorCodes,
  InternalFirmsShowErrorTypes,
  InternalFirmsUpdateErrorCodes,
  InternalFirmsDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FirmsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalFirmsIndexMethod = FirmsApi['internalFirmsIndex'];
export type InternalFirmsIndexResponseType = MethodResult<InternalFirmsIndexMethod>;

export const useInternalFirmsIndex = (
  variables: Parameters<InternalFirmsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFirmsIndexMethod,
    InternalFirmsIndexErrorTypes
  >
) => {
  return useQuery<
    InternalFirmsIndexMethod,
    InternalFirmsIndexErrorTypes
  >(
    (...args) => api.internalFirmsIndex(...args),
    InternalFirmsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFirmsIndex'],
        ...apiQueryConfig.InternalFirmsIndex,
        ...config?.queryConfig
      },
    },
    'InternalFirmsIndex',
  );
};

export const useInternalFirmsIndexInfinite = (
  variables: Parameters<InternalFirmsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFirmsIndexMethod,
    InternalFirmsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFirmsIndexMethod,
    InternalFirmsIndexErrorTypes
  >(
    (...args) => api.internalFirmsIndex(...args),
    InternalFirmsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFirmsIndex', 'InternalFirmsIndexInfinite'],
        ...apiQueryConfig.InternalFirmsIndex,
        ...config?.queryConfig,
      },
    },
    'InternalFirmsIndex',
  );
};

export const useInternalFirmsIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalFirmsIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFirmsIndexMethod,
    InternalFirmsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFirmsIndexMethod,
    InternalFirmsIndexErrorTypes
  >(
    (...args) => api.internalFirmsIndex(...args),
    InternalFirmsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFirmsIndex'],
        ...apiQueryConfig.InternalFirmsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFirmsIndex',
  );
};


export type InternalFirmsCreateMethod = FirmsApi['internalFirmsCreate'];

export const useInternalFirmsCreate = () => {
  return useMutation<InternalFirmsCreateMethod>(
    (...args) => api.internalFirmsCreate(...args),
    InternalFirmsCreateErrorCodes,
    'InternalFirmsCreate',
    '/api/internal/firms',
    'post'
  );
};

export type InternalFirmsShowMethod = FirmsApi['internalFirmsShow'];
export type InternalFirmsShowResponseType = MethodResult<InternalFirmsShowMethod>;

export const useInternalFirmsShow = (
  variables: Parameters<InternalFirmsShowMethod>[0],
  config?: UseQueryConfig<
    InternalFirmsShowMethod,
    InternalFirmsShowErrorTypes
  >
) => {
  return useQuery<
    InternalFirmsShowMethod,
    InternalFirmsShowErrorTypes
  >(
    (...args) => api.internalFirmsShow(...args),
    InternalFirmsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFirmsShow'],
        ...apiQueryConfig.InternalFirmsShow,
        ...config?.queryConfig
      },
    },
    'InternalFirmsShow',
  );
};

export const useInternalFirmsShowInfinite = (
  variables: Parameters<InternalFirmsShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFirmsShowMethod,
    InternalFirmsShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFirmsShowMethod,
    InternalFirmsShowErrorTypes
  >(
    (...args) => api.internalFirmsShow(...args),
    InternalFirmsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFirmsShow', 'InternalFirmsShowInfinite'],
        ...apiQueryConfig.InternalFirmsShow,
        ...config?.queryConfig,
      },
    },
    'InternalFirmsShow',
  );
};

export const useInternalFirmsShowLazy = (baseOptions?: {
  variables?: Parameters<InternalFirmsShowMethod>[0],
  config?: UseQueryConfig<
    InternalFirmsShowMethod,
    InternalFirmsShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFirmsShowMethod,
    InternalFirmsShowErrorTypes
  >(
    (...args) => api.internalFirmsShow(...args),
    InternalFirmsShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFirmsShow'],
        ...apiQueryConfig.InternalFirmsShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFirmsShow',
  );
};


export type InternalFirmsUpdateMethod = FirmsApi['internalFirmsUpdate'];

export const useInternalFirmsUpdate = () => {
  return useMutation<InternalFirmsUpdateMethod>(
    (...args) => api.internalFirmsUpdate(...args),
    InternalFirmsUpdateErrorCodes,
    'InternalFirmsUpdate',
    '/api/internal/firms/{id}',
    'put'
  );
};

export type InternalFirmsDeleteMethod = FirmsApi['internalFirmsDelete'];

export const useInternalFirmsDelete = () => {
  return useMutation<InternalFirmsDeleteMethod>(
    (...args) => api.internalFirmsDelete(...args),
    InternalFirmsDeleteErrorCodes,
    'InternalFirmsDelete',
    '/api/internal/firms/{id}',
    'delete'
  );
};
