import { Icon } from '@npm/core/ui/components/atoms/Icon';
import styled from 'styled-components';

export const CheckIcon = styled(Icon).attrs({
  name: 'check',
})<{ $selected?: boolean }>`
  margin-left: ${({ theme }) => theme.spacing.sm}px;
  visibility: hidden;
  ${({ $selected }) => $selected && `visibility: visible;`}
`;
