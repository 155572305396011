import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { formatLabelCount } from '@npm/core/ui/utils/formatters';
import {
  CbOrderBuySell,
  type SubmissionApiSecondmarketSubmissionIndexRequest,
  useSecondmarketSubmissionIndex,
} from '@npm/data-access';

import { RENEW_ORDER_TOOLTIP } from '../../../../../order';
import {
  EXPIRING_ORDERS_STATE,
  getExpiringOrderDate,
} from '../../../../client-orders/expiringOrders';
import { type InvestorOrdersTab } from '../InvestorOrdersList.types';

import * as S from './InvestorsOrdersIndexFilters.styles';

type Props = {
  activeTab: InvestorOrdersTab;
  setActiveTab: (tab: InvestorOrdersTab) => void;
  variables: SubmissionApiSecondmarketSubmissionIndexRequest;
  handleSubmit: (
    values: SubmissionApiSecondmarketSubmissionIndexRequest
  ) => void;
};

export const InvestorsOrdersIndexFilters = ({
  activeTab,
  setActiveTab,
  variables,
}: Props) => {
  const { data } = useSecondmarketSubmissionIndex({
    issuerEntityId: variables.issuerEntityId,
    accountId: variables.accountId,
    includeField: ['type_totals'],
    side: CbOrderBuySell.items.sell,
    page: 0,
    orderItemState: undefined,
  });

  const { data: expiringData } = useSecondmarketSubmissionIndex({
    issuerEntityId: variables.issuerEntityId,
    accountId: variables.accountId,
    expiringBefore: getExpiringOrderDate(),
    findWithoutLiveNegotiation: true,
    orderItemState: EXPIRING_ORDERS_STATE,
  });

  const typeTotals = data?.type_totals;

  const handleTabChange = (key: InvestorOrdersTab) => {
    setActiveTab(key);
  };

  return (
    <S.Panel>
      <Tabs
        defaultActiveKey={'all'}
        activeKey={activeTab}
        onChange={handleTabChange}
        variant="tertiary"
        hideMoreButton
      >
        <Tabs.TabPane
          key={'all'}
          tab={formatLabelCount('All', data?.pagination?.total_records)}
        />
        <Tabs.TabPane
          key={'live'}
          tab={formatLabelCount(
            'Live in Markeplace',
            typeTotals?.live_in_marketplace
          )}
        />
        <Tabs.TabPane
          key={'incomplete'}
          tab={formatLabelCount('Incomplete', typeTotals?.incomplete)}
        />
        <Tabs.TabPane
          key={'expiring'}
          tab={
            <>
              {formatLabelCount(
                'Expiring Orders',
                expiringData?.pagination?.total_records
              )}
              <TooltipInfoIcon title={RENEW_ORDER_TOOLTIP} />
            </>
          }
        />
      </Tabs>
    </S.Panel>
  );
};
