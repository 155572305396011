// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PayingAgentServiceAgreementSigningApi,
  PayingAgentServiceAgreementSigningErrorCodes,
  PayingAgentServiceAgreementSigningCompleteErrorCodes,
  PayingAgentServiceAgreementSigningCompleteErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PayingAgentServiceAgreementSigningApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PayingAgentServiceAgreementSigningMethod = PayingAgentServiceAgreementSigningApi['payingAgentServiceAgreementSigning'];

export const usePayingAgentServiceAgreementSigning = () => {
  return useMutation<PayingAgentServiceAgreementSigningMethod>(
    (...args) => api.payingAgentServiceAgreementSigning(...args),
    PayingAgentServiceAgreementSigningErrorCodes,
    'PayingAgentServiceAgreementSigning',
    '/api/paying-agent-service-agreement-signing',
    'post'
  );
};

export type PayingAgentServiceAgreementSigningCompleteMethod = PayingAgentServiceAgreementSigningApi['payingAgentServiceAgreementSigningComplete'];
export type PayingAgentServiceAgreementSigningCompleteResponseType = MethodResult<PayingAgentServiceAgreementSigningCompleteMethod>;

export const usePayingAgentServiceAgreementSigningComplete = (
  variables: Parameters<PayingAgentServiceAgreementSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    PayingAgentServiceAgreementSigningCompleteMethod,
    PayingAgentServiceAgreementSigningCompleteErrorTypes
  >
) => {
  return useQuery<
    PayingAgentServiceAgreementSigningCompleteMethod,
    PayingAgentServiceAgreementSigningCompleteErrorTypes
  >(
    (...args) => api.payingAgentServiceAgreementSigningComplete(...args),
    PayingAgentServiceAgreementSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PayingAgentServiceAgreementSigningComplete'],
        ...apiQueryConfig.PayingAgentServiceAgreementSigningComplete,
        ...config?.queryConfig
      },
    },
    'PayingAgentServiceAgreementSigningComplete',
  );
};

export const usePayingAgentServiceAgreementSigningCompleteInfinite = (
  variables: Parameters<PayingAgentServiceAgreementSigningCompleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    PayingAgentServiceAgreementSigningCompleteMethod,
    PayingAgentServiceAgreementSigningCompleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    PayingAgentServiceAgreementSigningCompleteMethod,
    PayingAgentServiceAgreementSigningCompleteErrorTypes
  >(
    (...args) => api.payingAgentServiceAgreementSigningComplete(...args),
    PayingAgentServiceAgreementSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PayingAgentServiceAgreementSigningComplete', 'PayingAgentServiceAgreementSigningCompleteInfinite'],
        ...apiQueryConfig.PayingAgentServiceAgreementSigningComplete,
        ...config?.queryConfig,
      },
    },
    'PayingAgentServiceAgreementSigningComplete',
  );
};

export const usePayingAgentServiceAgreementSigningCompleteLazy = (baseOptions?: {
  variables?: Parameters<PayingAgentServiceAgreementSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    PayingAgentServiceAgreementSigningCompleteMethod,
    PayingAgentServiceAgreementSigningCompleteErrorTypes
  >
}) => {
  return useLazyQuery<
    PayingAgentServiceAgreementSigningCompleteMethod,
    PayingAgentServiceAgreementSigningCompleteErrorTypes
  >(
    (...args) => api.payingAgentServiceAgreementSigningComplete(...args),
    PayingAgentServiceAgreementSigningCompleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PayingAgentServiceAgreementSigningComplete'],
        ...apiQueryConfig.PayingAgentServiceAgreementSigningComplete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PayingAgentServiceAgreementSigningComplete',
  );
};

