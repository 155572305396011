// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MarketFeedStatisticsApi,
  BrokerageFirmMarketFeedStatisticErrorCodes,
  BrokerageFirmMarketFeedStatisticErrorTypes,
  MarketFeedStatisticErrorCodes,
  MarketFeedStatisticErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MarketFeedStatisticsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type BrokerageFirmMarketFeedStatisticMethod = MarketFeedStatisticsApi['brokerageFirmMarketFeedStatistic'];
export type BrokerageFirmMarketFeedStatisticResponseType = MethodResult<BrokerageFirmMarketFeedStatisticMethod>;

export const useBrokerageFirmMarketFeedStatistic = (
  variables: Parameters<BrokerageFirmMarketFeedStatisticMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmMarketFeedStatisticMethod,
    BrokerageFirmMarketFeedStatisticErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmMarketFeedStatisticMethod,
    BrokerageFirmMarketFeedStatisticErrorTypes
  >(
    (...args) => api.brokerageFirmMarketFeedStatistic(...args),
    BrokerageFirmMarketFeedStatisticErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmMarketFeedStatistic'],
        ...apiQueryConfig.BrokerageFirmMarketFeedStatistic,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmMarketFeedStatistic',
  );
};

export const useBrokerageFirmMarketFeedStatisticInfinite = (
  variables: Parameters<BrokerageFirmMarketFeedStatisticMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmMarketFeedStatisticMethod,
    BrokerageFirmMarketFeedStatisticErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmMarketFeedStatisticMethod,
    BrokerageFirmMarketFeedStatisticErrorTypes
  >(
    (...args) => api.brokerageFirmMarketFeedStatistic(...args),
    BrokerageFirmMarketFeedStatisticErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmMarketFeedStatistic', 'BrokerageFirmMarketFeedStatisticInfinite'],
        ...apiQueryConfig.BrokerageFirmMarketFeedStatistic,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmMarketFeedStatistic',
  );
};

export const useBrokerageFirmMarketFeedStatisticLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmMarketFeedStatisticMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmMarketFeedStatisticMethod,
    BrokerageFirmMarketFeedStatisticErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmMarketFeedStatisticMethod,
    BrokerageFirmMarketFeedStatisticErrorTypes
  >(
    (...args) => api.brokerageFirmMarketFeedStatistic(...args),
    BrokerageFirmMarketFeedStatisticErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmMarketFeedStatistic'],
        ...apiQueryConfig.BrokerageFirmMarketFeedStatistic,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmMarketFeedStatistic',
  );
};


export type MarketFeedStatisticMethod = MarketFeedStatisticsApi['marketFeedStatistic'];
export type MarketFeedStatisticResponseType = MethodResult<MarketFeedStatisticMethod>;

export const useMarketFeedStatistic = (
  variables: Parameters<MarketFeedStatisticMethod>[0],
  config?: UseQueryConfig<
    MarketFeedStatisticMethod,
    MarketFeedStatisticErrorTypes
  >
) => {
  return useQuery<
    MarketFeedStatisticMethod,
    MarketFeedStatisticErrorTypes
  >(
    (...args) => api.marketFeedStatistic(...args),
    MarketFeedStatisticErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarketFeedStatistic'],
        ...apiQueryConfig.MarketFeedStatistic,
        ...config?.queryConfig
      },
    },
    'MarketFeedStatistic',
  );
};

export const useMarketFeedStatisticInfinite = (
  variables: Parameters<MarketFeedStatisticMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarketFeedStatisticMethod,
    MarketFeedStatisticErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarketFeedStatisticMethod,
    MarketFeedStatisticErrorTypes
  >(
    (...args) => api.marketFeedStatistic(...args),
    MarketFeedStatisticErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarketFeedStatistic', 'MarketFeedStatisticInfinite'],
        ...apiQueryConfig.MarketFeedStatistic,
        ...config?.queryConfig,
      },
    },
    'MarketFeedStatistic',
  );
};

export const useMarketFeedStatisticLazy = (baseOptions?: {
  variables?: Parameters<MarketFeedStatisticMethod>[0],
  config?: UseQueryConfig<
    MarketFeedStatisticMethod,
    MarketFeedStatisticErrorTypes
  >
}) => {
  return useLazyQuery<
    MarketFeedStatisticMethod,
    MarketFeedStatisticErrorTypes
  >(
    (...args) => api.marketFeedStatistic(...args),
    MarketFeedStatisticErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarketFeedStatistic'],
        ...apiQueryConfig.MarketFeedStatistic,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarketFeedStatistic',
  );
};

