import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { Icon } from '@npm/core/ui/components/atoms/Icon/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { CbEventStatus, useEventList } from '@npm/data-access';
import { useCurrentRole } from '@npm/features/auth/user/role/hooks/useCurrentRole';
import { useTheme } from 'styled-components';

const MAIN_TITLE = 'Upcoming Programs';

export const UpcomingProgramsTitle = ({ raw }: RouteTitleProps) => {
  const role = useCurrentRole();
  const theme = useTheme();

  const { data, isLoading } = useEventList({
    issuerEntityId: role.subject?.id,
    eventStatus: CbEventStatus.items.UPCOMING,
  });

  // for breadcrumbs
  if (raw) {
    return <>{MAIN_TITLE}</>;
  }

  const title = `${MAIN_TITLE} ${
    data?.pagination?.total_records
      ? '(' + data?.pagination?.total_records + ')'
      : ''
  }`;

  return isLoading ? (
    <Helmet pageTitle={MAIN_TITLE}></Helmet>
  ) : (
    <>
      <Helmet pageTitle={MAIN_TITLE}></Helmet>
      <Flex align="center">
        {title}
        <Tooltip title="All upcoming programs for your Company on the NPM Platform.">
          <Flex style={{ marginLeft: '4px' }}>
            <Icon
              name="info-circle"
              size="xs"
              color={theme.color.info.typography.primary}
            />
          </Flex>
        </Tooltip>
      </Flex>
    </>
  );
};
