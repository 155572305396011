// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FilingsApi,
  FilingIndexErrorCodes,
  FilingIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FilingsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FilingIndexMethod = FilingsApi['filingIndex'];
export type FilingIndexResponseType = MethodResult<FilingIndexMethod>;

export const useFilingIndex = (
  variables: Parameters<FilingIndexMethod>[0],
  config?: UseQueryConfig<
    FilingIndexMethod,
    FilingIndexErrorTypes
  >
) => {
  return useQuery<
    FilingIndexMethod,
    FilingIndexErrorTypes
  >(
    (...args) => api.filingIndex(...args),
    FilingIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingIndex'],
        ...apiQueryConfig.FilingIndex,
        ...config?.queryConfig
      },
    },
    'FilingIndex',
  );
};

export const useFilingIndexInfinite = (
  variables: Parameters<FilingIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    FilingIndexMethod,
    FilingIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    FilingIndexMethod,
    FilingIndexErrorTypes
  >(
    (...args) => api.filingIndex(...args),
    FilingIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingIndex', 'FilingIndexInfinite'],
        ...apiQueryConfig.FilingIndex,
        ...config?.queryConfig,
      },
    },
    'FilingIndex',
  );
};

export const useFilingIndexLazy = (baseOptions?: {
  variables?: Parameters<FilingIndexMethod>[0],
  config?: UseQueryConfig<
    FilingIndexMethod,
    FilingIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    FilingIndexMethod,
    FilingIndexErrorTypes
  >(
    (...args) => api.filingIndex(...args),
    FilingIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FilingIndex'],
        ...apiQueryConfig.FilingIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FilingIndex',
  );
};

