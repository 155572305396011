/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Security } from '../model';
// @ts-ignore
import { ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract } from '../model';
/**
 * S2SBrokerHoldingsApi - axios parameter creator
 * @export
 */
export const S2SBrokerHoldingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary updates a holding\' pre-verified
         * @param {string} xNPMAuthorization 
         * @param {string} xNPMUser 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract} [serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SBrokerHoldingUpdatePreVerified: async (xNPMAuthorization: string, xNPMUser: string, securityNpmGuid: string, xWorkstation?: string, serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract?: ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xNPMAuthorization' is not null or undefined
            assertParamExists('s2SBrokerHoldingUpdatePreVerified', 'xNPMAuthorization', xNPMAuthorization)
            // verify required parameter 'xNPMUser' is not null or undefined
            assertParamExists('s2SBrokerHoldingUpdatePreVerified', 'xNPMUser', xNPMUser)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('s2SBrokerHoldingUpdatePreVerified', 'securityNpmGuid', securityNpmGuid)
            const localVarPath = changeUrl(`/api/s2s/broker_holdings/{security_npm_guid}/pre_verifieds`)
                .replace(`{${"security_npm_guid"}}`, encodeURIComponent(String(securityNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S2SBrokerHoldingsApi - functional programming interface
 * @export
 */
export const S2SBrokerHoldingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S2SBrokerHoldingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary updates a holding\' pre-verified
         * @param {string} xNPMAuthorization 
         * @param {string} xNPMUser 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract} [serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SBrokerHoldingUpdatePreVerified(xNPMAuthorization: string, xNPMUser: string, securityNpmGuid: string, xWorkstation?: string, serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract?: ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Security>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SBrokerHoldingUpdatePreVerified(xNPMAuthorization, xNPMUser, securityNpmGuid, xWorkstation, serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S2SBrokerHoldingsApi - factory interface
 * @export
 */
export const S2SBrokerHoldingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S2SBrokerHoldingsApiFp(configuration)
    return {
        /**
         * 
         * @summary updates a holding\' pre-verified
         * @param {string} xNPMAuthorization 
         * @param {string} xNPMUser 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract} [serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SBrokerHoldingUpdatePreVerified(xNPMAuthorization: string, xNPMUser: string, securityNpmGuid: string, xWorkstation?: string, serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract?: ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract, options?: any): AxiosPromise<Security> {
            return localVarFp.s2SBrokerHoldingUpdatePreVerified(xNPMAuthorization, xNPMUser, securityNpmGuid, xWorkstation, serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for s2SBrokerHoldingUpdatePreVerified operation in S2SBrokerHoldingsApi.
 * @export
 * @interface S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerifiedRequest
 */
export interface S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerifiedRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerified
     */
    readonly xNPMAuthorization: string

    /**
     * 
     * @type {string}
     * @memberof S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerified
     */
    readonly xNPMUser: string

    /**
     * 
     * @type {string}
     * @memberof S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerified
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerified
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract}
     * @memberof S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerified
     */
    readonly serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract?: ServiceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract
}

/**
 * S2SBrokerHoldingsApi - object-oriented interface
 * @export
 * @class S2SBrokerHoldingsApi
 * @extends {BaseAPI}
 */
export class S2SBrokerHoldingsApi extends BaseAPI {
    /**
     * 
     * @summary updates a holding\' pre-verified
     * @param {S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerifiedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SBrokerHoldingsApi
     */
    public s2SBrokerHoldingUpdatePreVerified(requestParameters: S2SBrokerHoldingsApiS2SBrokerHoldingUpdatePreVerifiedRequest, options?: AxiosRequestConfig) {
        return S2SBrokerHoldingsApiFp(this.configuration).s2SBrokerHoldingUpdatePreVerified(requestParameters.xNPMAuthorization, requestParameters.xNPMUser, requestParameters.securityNpmGuid, requestParameters.xWorkstation, requestParameters.serviceToServiceBrokerHoldingsPreVerifiedUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type S2SBrokerHoldingUpdatePreVerifiedErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SBrokerHoldingUpdatePreVerifiedErrorCodes = [
    401,
    404,
    422,
];


