import { UnlockPremiumContextProvider } from '../../../../auth/user/components';

import {
  BasicPageLayoutInner,
  type BasicPageLayoutInnerProps,
} from './BasicPageLayoutInner';

export type BasicPageLayoutProps = BasicPageLayoutInnerProps & {
  companyName: string;
};

export const BasicPageLayout = ({
  companyName,
  ...props
}: BasicPageLayoutProps) => {
  return (
    <UnlockPremiumContextProvider companyName={companyName}>
      <BasicPageLayoutInner {...props} />
    </UnlockPremiumContextProvider>
  );
};
