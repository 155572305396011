import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import * as S from '../Form/ExemptionComplete/ExemptionComplete.styles';
import { InfoTooltip } from '../Form/InfoTooltip';

type Props = {
  text: string;
  answer: string;
  margin?: boolean;
  tooltipText?: string;
};

export const ExemptAnswer = ({
  text,
  answer,
  margin = true,
  tooltipText,
}: Props) => {
  return (
    // max-width: 95% to make sure the tooltip icon doesn't break to the next line alone
    <Margin bottom={margin ? 'xxl' : undefined} style={{ maxWidth: '95%' }}>
      <Text size="sm">
        {text}
        <Margin
          left="xs"
          as="span"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        >
          {tooltipText && <InfoTooltip title={tooltipText} />}
        </Margin>{' '}
      </Text>
      <Margin top="md" bottom="xl">
        <Flex direction="row">
          <S.QuestionText>
            <Text size="sm">Category</Text>
          </S.QuestionText>
          <S.QuestionText>
            <S.ExemptionText size="sm" colorVariant="primary">
              {answer}
            </S.ExemptionText>
          </S.QuestionText>
        </Flex>
      </Margin>
    </Margin>
  );
};
