import {
  type Chart,
  type ChartType,
  type InteractionItem,
  type InteractionModeFunction,
  Tooltip,
  type TooltipModel,
  type TooltipPositionerFunction,
} from 'chart.js';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';

declare module 'chart.js' {
  interface InteractionModeMap {
    segment: InteractionModeFunction;
  }

  interface TooltipPositionerMap {
    followCursor: TooltipPositionerFunction<ChartType>;
  }
}

Interaction.modes.segment = function (chart, e) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const position = getRelativePosition(e, chart as any);

  const items: InteractionItem[] = [];
  Interaction.evaluateInteractionItems(
    chart,
    'x',
    position,
    (element, datasetIndex, index) => {
      items.push({ element, datasetIndex, index });
    }
  );
  return items;
};

Tooltip.positioners.followCursor = function (_, eventPosition) {
  return {
    x: eventPosition.x,
    y: eventPosition.y,
    xAlign: 'center',
  };
};

export const showStepTooltip = ({
  context: { chart, tooltip },
  canvas,
  getTooltipElement,
  onIndexChanged,
  tooltipWidth = tooltip.width,
  tooltipHeight = tooltip.height,
}: {
  context: { chart: Chart; tooltip: TooltipModel<'line'> };
  canvas: HTMLCanvasElement;
  getTooltipElement: (canvas: HTMLCanvasElement) => HTMLElement;
  onIndexChanged: (index: number | undefined) => void;
  tooltipWidth?: number;
  tooltipHeight?: number;
}) => {
  const tooltipEl = getTooltipElement(chart.canvas);

  const isActive = tooltip.opacity !== 0; // opacity is the source of truth

  if (!isActive) {
    onIndexChanged(undefined);
    return;
  }

  const segmentStart = tooltip.dataPoints?.[0];
  const segmentEnd = tooltip.dataPoints?.[1];

  onIndexChanged(segmentStart?.dataIndex);

  if (!segmentStart) return;

  tooltipEl.style.display = 'block';

  tooltipEl.style.top = '0px';
  tooltipEl.style.left = '0px';

  tooltipEl.style.width = tooltipWidth + 'px';
  tooltipEl.style.height = tooltipHeight + 'px';

  const chartRect = canvas.getBoundingClientRect();

  const x = tooltip.x;

  let y = segmentStart.element.y - tooltipHeight / 2;

  if (y < 0) {
    y = 0;
  }
  if (y + tooltipHeight > chartRect.height) {
    y = chartRect.height - tooltipHeight;
  }

  if (segmentStart && segmentEnd) {
    tooltipEl.style.transform = `translateX(${x}px) translateY(${y}px)`;
  }

  tooltipEl.style.transition = 'all .1s ease';
};
