/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalFairMarketValueCreateRequestContract } from '../model';
// @ts-ignore
import { InternalFairMarketValueIndex } from '../model';
// @ts-ignore
import { InternalFairMarketValueShow } from '../model';
// @ts-ignore
import { InternalFairMarketValueUpdateRequestContract } from '../model';
/**
 * FairMarketValuesApi - axios parameter creator
 * @export
 */
export const FairMarketValuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get all FMVs
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAllFairMarketValuesIndex: async (xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/fair_market_values`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteFairMarketValue: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDeleteFairMarketValue', 'id', id)
            const localVarPath = changeUrl(`/api/internal/fair_market_values/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a FMV
         * @param {string} companyId 
         * @param {InternalFairMarketValueCreateRequestContract} internalFairMarketValueCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFairMarketValueCreate: async (companyId: string, internalFairMarketValueCreateRequestContract: InternalFairMarketValueCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalFairMarketValueCreate', 'companyId', companyId)
            // verify required parameter 'internalFairMarketValueCreateRequestContract' is not null or undefined
            assertParamExists('internalFairMarketValueCreate', 'internalFairMarketValueCreateRequestContract', internalFairMarketValueCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/fair_market_values`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalFairMarketValueCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get fair_market_values for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFairMarketValueIndex: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalFairMarketValueIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/fair_market_values`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowFairMarketValue: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowFairMarketValue', 'id', id)
            const localVarPath = changeUrl(`/api/internal/fair_market_values/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalFairMarketValueUpdateRequestContract} [internalFairMarketValueUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateFairMarketValue: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalFairMarketValueUpdateRequestContract?: InternalFairMarketValueUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateFairMarketValue', 'id', id)
            const localVarPath = changeUrl(`/api/internal/fair_market_values/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalFairMarketValueUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary bulk upload FairMarketValues
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadFmvs: async (file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('internalUploadFmvs', 'file', file)
            const localVarPath = changeUrl(`/api/internal/fair_market_values/upload`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FairMarketValuesApi - functional programming interface
 * @export
 */
export const FairMarketValuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FairMarketValuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get all FMVs
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalAllFairMarketValuesIndex(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFairMarketValueIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalAllFairMarketValuesIndex(xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDeleteFairMarketValue(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDeleteFairMarketValue(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a FMV
         * @param {string} companyId 
         * @param {InternalFairMarketValueCreateRequestContract} internalFairMarketValueCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFairMarketValueCreate(companyId: string, internalFairMarketValueCreateRequestContract: InternalFairMarketValueCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFairMarketValueCreate(companyId, internalFairMarketValueCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get fair_market_values for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFairMarketValueIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFairMarketValueIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFairMarketValueIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowFairMarketValue(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFairMarketValueShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowFairMarketValue(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalFairMarketValueUpdateRequestContract} [internalFairMarketValueUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateFairMarketValue(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalFairMarketValueUpdateRequestContract?: InternalFairMarketValueUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateFairMarketValue(id, xNPMAuthorization, xSubscriptionPlan, internalFairMarketValueUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary bulk upload FairMarketValues
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUploadFmvs(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUploadFmvs(file, xNPMAuthorization, xSubscriptionPlan, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FairMarketValuesApi - factory interface
 * @export
 */
export const FairMarketValuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FairMarketValuesApiFp(configuration)
    return {
        /**
         * 
         * @summary get all FMVs
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAllFairMarketValuesIndex(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFairMarketValueIndex> {
            return localVarFp.internalAllFairMarketValuesIndex(xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteFairMarketValue(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalDeleteFairMarketValue(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a FMV
         * @param {string} companyId 
         * @param {InternalFairMarketValueCreateRequestContract} internalFairMarketValueCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFairMarketValueCreate(companyId: string, internalFairMarketValueCreateRequestContract: InternalFairMarketValueCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalFairMarketValueCreate(companyId, internalFairMarketValueCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get fair_market_values for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFairMarketValueIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFairMarketValueIndex> {
            return localVarFp.internalFairMarketValueIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowFairMarketValue(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFairMarketValueShow> {
            return localVarFp.internalShowFairMarketValue(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a FMV
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalFairMarketValueUpdateRequestContract} [internalFairMarketValueUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateFairMarketValue(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalFairMarketValueUpdateRequestContract?: InternalFairMarketValueUpdateRequestContract, options?: any): AxiosPromise<void> {
            return localVarFp.internalUpdateFairMarketValue(id, xNPMAuthorization, xSubscriptionPlan, internalFairMarketValueUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary bulk upload FairMarketValues
         * @param {string} file 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUploadFmvs(file: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.internalUploadFmvs(file, xNPMAuthorization, xSubscriptionPlan, includeField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalAllFairMarketValuesIndex operation in FairMarketValuesApi.
 * @export
 * @interface FairMarketValuesApiInternalAllFairMarketValuesIndexRequest
 */
export interface FairMarketValuesApiInternalAllFairMarketValuesIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalAllFairMarketValuesIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalAllFairMarketValuesIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalDeleteFairMarketValue operation in FairMarketValuesApi.
 * @export
 * @interface FairMarketValuesApiInternalDeleteFairMarketValueRequest
 */
export interface FairMarketValuesApiInternalDeleteFairMarketValueRequest {
    /**
     * 
     * @type {number}
     * @memberof FairMarketValuesApiInternalDeleteFairMarketValue
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalDeleteFairMarketValue
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalDeleteFairMarketValue
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFairMarketValueCreate operation in FairMarketValuesApi.
 * @export
 * @interface FairMarketValuesApiInternalFairMarketValueCreateRequest
 */
export interface FairMarketValuesApiInternalFairMarketValueCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalFairMarketValueCreate
     */
    readonly companyId: string

    /**
     * 
     * @type {InternalFairMarketValueCreateRequestContract}
     * @memberof FairMarketValuesApiInternalFairMarketValueCreate
     */
    readonly internalFairMarketValueCreateRequestContract: InternalFairMarketValueCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalFairMarketValueCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalFairMarketValueCreate
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFairMarketValueIndex operation in FairMarketValuesApi.
 * @export
 * @interface FairMarketValuesApiInternalFairMarketValueIndexRequest
 */
export interface FairMarketValuesApiInternalFairMarketValueIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalFairMarketValueIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalFairMarketValueIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalFairMarketValueIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalShowFairMarketValue operation in FairMarketValuesApi.
 * @export
 * @interface FairMarketValuesApiInternalShowFairMarketValueRequest
 */
export interface FairMarketValuesApiInternalShowFairMarketValueRequest {
    /**
     * 
     * @type {number}
     * @memberof FairMarketValuesApiInternalShowFairMarketValue
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalShowFairMarketValue
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalShowFairMarketValue
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUpdateFairMarketValue operation in FairMarketValuesApi.
 * @export
 * @interface FairMarketValuesApiInternalUpdateFairMarketValueRequest
 */
export interface FairMarketValuesApiInternalUpdateFairMarketValueRequest {
    /**
     * 
     * @type {number}
     * @memberof FairMarketValuesApiInternalUpdateFairMarketValue
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalUpdateFairMarketValue
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalUpdateFairMarketValue
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalFairMarketValueUpdateRequestContract}
     * @memberof FairMarketValuesApiInternalUpdateFairMarketValue
     */
    readonly internalFairMarketValueUpdateRequestContract?: InternalFairMarketValueUpdateRequestContract
}

/**
 * Request parameters for internalUploadFmvs operation in FairMarketValuesApi.
 * @export
 * @interface FairMarketValuesApiInternalUploadFmvsRequest
 */
export interface FairMarketValuesApiInternalUploadFmvsRequest {
    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalUploadFmvs
     */
    readonly file: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalUploadFmvs
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FairMarketValuesApiInternalUploadFmvs
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FairMarketValuesApiInternalUploadFmvs
     */
    readonly includeField?: Array<string>
}

/**
 * FairMarketValuesApi - object-oriented interface
 * @export
 * @class FairMarketValuesApi
 * @extends {BaseAPI}
 */
export class FairMarketValuesApi extends BaseAPI {
    /**
     * 
     * @summary get all FMVs
     * @param {FairMarketValuesApiInternalAllFairMarketValuesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FairMarketValuesApi
     */
    public internalAllFairMarketValuesIndex(requestParameters: FairMarketValuesApiInternalAllFairMarketValuesIndexRequest = {}, options?: AxiosRequestConfig) {
        return FairMarketValuesApiFp(this.configuration).internalAllFairMarketValuesIndex(requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a FMV
     * @param {FairMarketValuesApiInternalDeleteFairMarketValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FairMarketValuesApi
     */
    public internalDeleteFairMarketValue(requestParameters: FairMarketValuesApiInternalDeleteFairMarketValueRequest, options?: AxiosRequestConfig) {
        return FairMarketValuesApiFp(this.configuration).internalDeleteFairMarketValue(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a FMV
     * @param {FairMarketValuesApiInternalFairMarketValueCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FairMarketValuesApi
     */
    public internalFairMarketValueCreate(requestParameters: FairMarketValuesApiInternalFairMarketValueCreateRequest, options?: AxiosRequestConfig) {
        return FairMarketValuesApiFp(this.configuration).internalFairMarketValueCreate(requestParameters.companyId, requestParameters.internalFairMarketValueCreateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get fair_market_values for a company
     * @param {FairMarketValuesApiInternalFairMarketValueIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FairMarketValuesApi
     */
    public internalFairMarketValueIndex(requestParameters: FairMarketValuesApiInternalFairMarketValueIndexRequest, options?: AxiosRequestConfig) {
        return FairMarketValuesApiFp(this.configuration).internalFairMarketValueIndex(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a FMV
     * @param {FairMarketValuesApiInternalShowFairMarketValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FairMarketValuesApi
     */
    public internalShowFairMarketValue(requestParameters: FairMarketValuesApiInternalShowFairMarketValueRequest, options?: AxiosRequestConfig) {
        return FairMarketValuesApiFp(this.configuration).internalShowFairMarketValue(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a FMV
     * @param {FairMarketValuesApiInternalUpdateFairMarketValueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FairMarketValuesApi
     */
    public internalUpdateFairMarketValue(requestParameters: FairMarketValuesApiInternalUpdateFairMarketValueRequest, options?: AxiosRequestConfig) {
        return FairMarketValuesApiFp(this.configuration).internalUpdateFairMarketValue(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalFairMarketValueUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary bulk upload FairMarketValues
     * @param {FairMarketValuesApiInternalUploadFmvsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FairMarketValuesApi
     */
    public internalUploadFmvs(requestParameters: FairMarketValuesApiInternalUploadFmvsRequest, options?: AxiosRequestConfig) {
        return FairMarketValuesApiFp(this.configuration).internalUploadFmvs(requestParameters.file, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalAllFairMarketValuesIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalAllFairMarketValuesIndexErrorCodes = [
];

export type InternalDeleteFairMarketValueErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDeleteFairMarketValueErrorCodes = [
    401,
    404,
];

export type InternalFairMarketValueCreateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFairMarketValueCreateErrorCodes = [
    401,
    404,
];

export type InternalFairMarketValueIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFairMarketValueIndexErrorCodes = [
    401,
    404,
];

export type InternalShowFairMarketValueErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowFairMarketValueErrorCodes = [
    401,
    404,
];

export type InternalUpdateFairMarketValueErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateFairMarketValueErrorCodes = [
    401,
    404,
];

export type InternalUploadFmvsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUploadFmvsErrorCodes = [
];


