import styled from 'styled-components';

import { PriceContainer } from '../../../../../components/PriceBlock';

const borderRadiusVariant = 'md';

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius[borderRadiusVariant]}px;
`;

export const Header = styled.div<{ $isSuccess: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}px;

  padding: ${({ theme }) => theme.spacing.sm}px;
  border-top-left-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;
  border-top-right-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;
  background-color: ${({ theme, $isSuccess }) =>
    $isSuccess ? theme.color.info.backgrounds.secondaryDefault : undefined};
`;

export const IconWrapper = styled.div`
  line-height: 1;
  margin-left: auto;
`;

export const Footer = styled.div<{ $variant: 'info' | 'warning' | 'error' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing.sm}px;

  background-color: ${({ theme, $variant }) =>
    theme.color[$variant].backgrounds.secondaryDefault};

  border-bottom-left-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;
  border-bottom-right-radius: ${({ theme }) =>
    theme.borderRadius[borderRadiusVariant]}px;

  &&& .ant-spin-sm .ant-spin-dot {
    font-size: ${({ theme }) => theme.typography.size.xs}px;
  }
`;

export const Body = styled.div<{
  $priceBlockColorVariant?: 'tertiary' | 'muted';
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}px;

  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};

  &&& ${PriceContainer} {
    * {
      color: ${({ theme, $priceBlockColorVariant }) =>
        $priceBlockColorVariant
          ? theme.color.general.typography[$priceBlockColorVariant]
          : undefined};
    }
  }
`;

export const TryAgainButton = styled.button`
  color: ${({ theme }) => theme.color.error};
  padding: ${({ theme }) => theme.spacing.xs}px 0;
`;
