import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import {
  DiscardModal,
  useConfirmOnLeave,
} from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { useForm } from 'antd/lib/form/Form';

import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { CreateUserWithRolesForm } from '../../users/CreateNewUser';

import * as S from './NewAccountDrawer.styles';
import type { NewAccountFormValues } from './NewAccountDrawer.types';
import { accountIdValidator } from './NewAccountDrawer.utils';
import { useCreatePersonAccount } from './useCreatePersonAccount.hooks';

const NEW_PERSON_ACCOUNT_FORM_ID = 'new-person-account-form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewPersonAccountDrawer = ({ isOpen, onClose }: Props) => {
  const history = useHistory();

  const role = useCurrentRole();

  const { createPersonAccount, isCreatePersonAccLoading, isSendingEmail } =
    useCreatePersonAccount();

  const [form] = useForm<NewAccountFormValues>();

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave('NewPersonAccountDrawer');

  const handleCloseConfirm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
    onClose();
  };

  const handleSubmit = async () => {
    let fields: NewAccountFormValues = null;

    try {
      fields = await form.validateFields();
    } catch (error) {
      handleValidationError(form, error);
      return;
    }
    const createdAccount = await createPersonAccount(fields);
    handleCloseConfirm();
    history.push(
      `/brokerage-workstation/individuals/${createdAccount.result?.id}?tab=onboarding`
    );

    Notification.success({
      message: `You have successfully created the account ${createdAccount.result?.external_id}`,
    });
  };

  return (
    <>
      <Drawer
        open={isOpen}
        title={`Create New Individual Account for ${role.subject?.name}`}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        destroyOnClose
        submitButtonProps={{
          form: NEW_PERSON_ACCOUNT_FORM_ID,
          htmlType: 'submit',
          children: 'Save & Continue',
          'data-cy': CypressDataIds.Individuals.submitButton,
          loading: isCreatePersonAccLoading || isSendingEmail,
        }}
      >
        <Margin bottom="lg">
          <Alert message="Enter the Individual’s legal name and assign a unique ID. Once you click “Continue”, a new Individual account will be created in your brokerage firm and you can proceed to permission additional users to the account." />
        </Margin>
        <S.Form
          id={NEW_PERSON_ACCOUNT_FORM_ID}
          form={form}
          onValuesChange={() => setHasUnsavedChanges(true)}
          onFinish={handleSubmit}
        >
          <CreateUserWithRolesForm
            mode="new"
            brokerageFirmId={role.subject?.id}
            form={form}
            addon={
              <InputFormItem
                name="id"
                label="Account ID"
                extra="Must be alphanumeric value"
                rules={[
                  VALIDATION_RULES.required('Account ID'),
                  accountIdValidator,
                ]}
              >
                <Input
                  prefix={`${role.subject?.name} -`}
                  placeholder="000000"
                />
              </InputFormItem>
            }
          />
        </S.Form>
      </Drawer>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
