import {
  ApeVueApi,
  CapTableApi,
  CompanyPricingApi,
  getAxiosInstance,
  MTMApi,
  VenusApi,
} from '@npm/data-access';
import { type AxiosInstance } from 'axios';

const axiosInstance = getAxiosInstance();
const capTableAxiosInstance = CapTableApi.getAxiosInstance();
const mtmAxiosInstance = MTMApi.getAxiosInstance();
const venusAxiosInstance = VenusApi.getAxiosInstance();
const companyPricingAxiosInstance = CompanyPricingApi.getAxiosInstance();
const apeVueAxiosInstance = ApeVueApi.getAxiosInstance();

export const registerInterceptors = (
  interceptors: ((axios: AxiosInstance) => void)[]
) => {
  interceptors.forEach(interceptor => {
    interceptor(axiosInstance);
  });
};
export const registerCapTableInterceptors = (
  interceptors: ((axios: AxiosInstance) => void)[]
) => {
  interceptors.forEach(interceptor => {
    interceptor(capTableAxiosInstance);
  });
};
export const registerMTMInterceptors = (
  interceptors: ((axios: AxiosInstance) => void)[]
) => {
  interceptors.forEach(interceptor => {
    interceptor(mtmAxiosInstance);
  });
};
export const registerVenusInterceptors = (
  interceptors: ((axios: AxiosInstance) => void)[]
) => {
  interceptors.forEach(interceptor => {
    interceptor(venusAxiosInstance);
  });
};
export const registerCompanyPricingInterceptors = (
  interceptors: ((axios: AxiosInstance) => void)[]
) => {
  interceptors.forEach(interceptor => {
    interceptor(companyPricingAxiosInstance);
  });
};
export const registerApeVueInterceptors = (
  interceptors: ((axios: AxiosInstance) => void)[]
) => {
  interceptors.forEach(interceptor => {
    interceptor(apeVueAxiosInstance);
  });
};
