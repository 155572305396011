import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { capitalize } from 'lodash';
import { type DefaultTheme } from 'styled-components';

import { useUserContextStore } from '../../context';

import * as S from './Greeting.styles';

type Props = {
  iconBackground?: keyof DefaultTheme['color']['general']['layout'];
};

export const Greeting = ({ iconBackground = 'one' }: Props) => {
  const { user } = useUserContextStore();
  const { isMobile } = useBreakpoints();

  return (
    <Flex gap="sm" align="center">
      <S.IconWrapper $background={iconBackground}>
        <Icon name="hand-stop" size={isMobile ? 'sm' : 'md'} />
      </S.IconWrapper>
      <Heading variant="h0">{`Hi ${capitalize(user?.person?.first)}!`}</Heading>
    </Flex>
  );
};
