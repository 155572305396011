// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CiksApi,
  InternalIndexCikErrorCodes,
  InternalIndexCikErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CiksApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalIndexCikMethod = CiksApi['internalIndexCik'];
export type InternalIndexCikResponseType = MethodResult<InternalIndexCikMethod>;

export const useInternalIndexCik = (
  variables: Parameters<InternalIndexCikMethod>[0],
  config?: UseQueryConfig<
    InternalIndexCikMethod,
    InternalIndexCikErrorTypes
  >
) => {
  return useQuery<
    InternalIndexCikMethod,
    InternalIndexCikErrorTypes
  >(
    (...args) => api.internalIndexCik(...args),
    InternalIndexCikErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIndexCik'],
        ...apiQueryConfig.InternalIndexCik,
        ...config?.queryConfig
      },
    },
    'InternalIndexCik',
  );
};

export const useInternalIndexCikInfinite = (
  variables: Parameters<InternalIndexCikMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalIndexCikMethod,
    InternalIndexCikErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalIndexCikMethod,
    InternalIndexCikErrorTypes
  >(
    (...args) => api.internalIndexCik(...args),
    InternalIndexCikErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIndexCik', 'InternalIndexCikInfinite'],
        ...apiQueryConfig.InternalIndexCik,
        ...config?.queryConfig,
      },
    },
    'InternalIndexCik',
  );
};

export const useInternalIndexCikLazy = (baseOptions?: {
  variables?: Parameters<InternalIndexCikMethod>[0],
  config?: UseQueryConfig<
    InternalIndexCikMethod,
    InternalIndexCikErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalIndexCikMethod,
    InternalIndexCikErrorTypes
  >(
    (...args) => api.internalIndexCik(...args),
    InternalIndexCikErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalIndexCik'],
        ...apiQueryConfig.InternalIndexCik,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalIndexCik',
  );
};

