import { Skeleton as SkeletonBase } from '@npm/core/ui/components/atoms/Skeleton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  && > * + * {
    margin-top: ${({ theme }) => theme.spacing.lg}px;
  }
`;

export const Skeleton = styled(SkeletonBase.Base)`
  .ant-skeleton-content .ant-skeleton-paragraph {
    margin: ${({ theme }) => theme.spacing.xs}px 0;

    & > li {
      width: 100% !important;
    }
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing.md}px;
  }
`;
