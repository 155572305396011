import { Codebooks } from './codebooks.types';

const Items = {
  holding_entry: 'holding_entry',
  company_approval: 'company_approval',
  agreement_signing: 'agreement_signing',
  get_paid: 'get_paid',
  wire_funds: 'wire_funds',
  get_shares: 'get_shares',
  transfer_shares: 'transfer_shares',
} as const;

export type TradeTimelineItemType = (typeof Items)[keyof typeof Items];

const SELLER_TIMELINE_ITEMS: TradeTimelineItemType[] = [
  'holding_entry',
  'company_approval',
  'agreement_signing',
  'get_paid',
  'transfer_shares',
];

const BUYER_TIMELINE_ITEMS: TradeTimelineItemType[] = [
  'company_approval',
  'agreement_signing',
  'wire_funds',
  'get_shares',
];

export const CbTradeTimeline = {
  code: Codebooks.TRADE_TIMELINE,
  items: Items,
  sellerItems: SELLER_TIMELINE_ITEMS,
  buyerItems: BUYER_TIMELINE_ITEMS,
};
