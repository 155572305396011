// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompanyIntrinsicValuesApi,
  CompanyIntrinsicValuesErrorCodes,
  CompanyIntrinsicValuesErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompanyIntrinsicValuesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanyIntrinsicValuesMethod = CompanyIntrinsicValuesApi['companyIntrinsicValues'];
export type CompanyIntrinsicValuesResponseType = MethodResult<CompanyIntrinsicValuesMethod>;

export const useCompanyIntrinsicValues = (
  variables: Parameters<CompanyIntrinsicValuesMethod>[0],
  config?: UseQueryConfig<
    CompanyIntrinsicValuesMethod,
    CompanyIntrinsicValuesErrorTypes
  >
) => {
  return useQuery<
    CompanyIntrinsicValuesMethod,
    CompanyIntrinsicValuesErrorTypes
  >(
    (...args) => api.companyIntrinsicValues(...args),
    CompanyIntrinsicValuesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyIntrinsicValues'],
        ...apiQueryConfig.CompanyIntrinsicValues,
        ...config?.queryConfig
      },
    },
    'CompanyIntrinsicValues',
  );
};

export const useCompanyIntrinsicValuesInfinite = (
  variables: Parameters<CompanyIntrinsicValuesMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyIntrinsicValuesMethod,
    CompanyIntrinsicValuesErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyIntrinsicValuesMethod,
    CompanyIntrinsicValuesErrorTypes
  >(
    (...args) => api.companyIntrinsicValues(...args),
    CompanyIntrinsicValuesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyIntrinsicValues', 'CompanyIntrinsicValuesInfinite'],
        ...apiQueryConfig.CompanyIntrinsicValues,
        ...config?.queryConfig,
      },
    },
    'CompanyIntrinsicValues',
  );
};

export const useCompanyIntrinsicValuesLazy = (baseOptions?: {
  variables?: Parameters<CompanyIntrinsicValuesMethod>[0],
  config?: UseQueryConfig<
    CompanyIntrinsicValuesMethod,
    CompanyIntrinsicValuesErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyIntrinsicValuesMethod,
    CompanyIntrinsicValuesErrorTypes
  >(
    (...args) => api.companyIntrinsicValues(...args),
    CompanyIntrinsicValuesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyIntrinsicValues'],
        ...apiQueryConfig.CompanyIntrinsicValues,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyIntrinsicValues',
  );
};

