import {
  type ContextAction,
  ContextActionType,
  type WizardContext,
} from './Wizard.types';
import {
  calculateProgress,
  findNextStepAndSubstep,
  findPrevStepAndSubstep,
} from './Wizard.utils';

export const INITIAL_CONTEXT_STATE = {
  stepIndex: 0,
  progress: 0,
  steps: [],
};

export const createWizardContextReducer =
  <T>() =>
  (state: WizardContext<T>, action: ContextAction<T>): WizardContext<T> => {
    const { type } = action;

    switch (type) {
      case ContextActionType.UPDATE_DATA:
        return {
          ...state,
          data: {
            ...state.data,
            ...action.payload,
          },
        };

      case ContextActionType.MOVE_NEXT: {
        const { nextStep, nextSubstep } = findNextStepAndSubstep(
          state.steps,
          state.stepIndex,
          state.substepIndex
        );

        if (action?.config?.onComplete && nextStep.index === -1) {
          action.config.onComplete();
          return state;
        } else if (!action?.config?.onComplete && nextStep.index === -1) {
          throw new Error('No next step available.');
        }

        return {
          ...state,
          stepIndex: nextStep.index,
          substepIndex: nextSubstep?.index,
          progress: calculateProgress(nextStep.index, state.steps.length),
        };
      }

      case ContextActionType.MOVE_BACK: {
        const { prevStep, prevSubstep } = findPrevStepAndSubstep(
          state.steps,
          state.stepIndex,
          state.substepIndex
        );

        return {
          ...state,
          stepIndex: prevStep.index,
          substepIndex: prevSubstep?.index,
          progress: calculateProgress(prevStep.index, state.steps.length),
        };
      }

      case ContextActionType.UPDATE_STEP_INDEX: {
        let stepIndex: number | undefined = undefined;
        let substepIndex: number | undefined = undefined;

        if (typeof action.payload === 'object') {
          const { step, substep } = action.payload;

          if (typeof step === 'number') {
            stepIndex = step;
          }

          if (typeof step === 'string') {
            stepIndex = state.steps.findIndex(s => s.key === step);
          }

          if (typeof substep === 'number') {
            substepIndex = substep;
          }

          if (typeof substep === 'string' && stepIndex != null) {
            substepIndex = state.steps[stepIndex]?.substeps?.findIndex(
              ss => ss.key === substep
            );
          }
        } else {
          if (typeof action.payload === 'string') {
            stepIndex = state.steps.findIndex(
              s => s.key === (action.payload as string)
            );
          } else {
            stepIndex = action.payload as number;
          }
        }

        if (stepIndex === -1) {
          return {
            ...state,
            stepIndex: 0,
          };
        }

        return {
          ...state,
          stepIndex: stepIndex || 0,
          substepIndex: substepIndex || 0,
        };
      }

      case ContextActionType.UPDATE_STEPS: {
        return { ...state, steps: action.payload };
      }

      case ContextActionType.RESET_CONTEXT: {
        return { ...INITIAL_CONTEXT_STATE, ...action.payload };
      }

      default:
        return state;
    }
  };
