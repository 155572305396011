import React, { type ComponentProps, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { usePreviousDistinct } from '@npm/utils';

import { CypressDataIds } from '../../../../../constants';
import { useResizeEffect } from '../../../../../hooks/useResizeEffect';
import { BottomNavigation } from '../../../../atoms/BottomNavigation';
import { ErrorSkeleton } from '../../../../atoms/ErrorSkeleton';
import { Skeleton } from '../../../../atoms/Skeleton';
import {
  AlertContainer,
  useAlerts,
} from '../../../../molecules/AlertContainer';
import { PageState, type StepProps } from '../../Wizard.types';

import * as S from './StepContainer.styles';
import { type AppWizardType } from './StepContainer.config';

export const WIZARD_MAIN_PANEL = 'wizard-main-panel';

type Props<T> = {
  stepProps?: StepProps<T>;
  children: React.ReactNode;
  bottomNavigationProps?: ComponentProps<typeof BottomNavigation>;
  onSubmit?: () => Promise<void> | void;
  isWide?: boolean;
  fullWidth?: boolean;
  hasAutoScroll?: boolean;
  isLoading?: boolean;
  isUpdating?: boolean;
  pageState?: PageState;
  setPageState?: React.Dispatch<React.SetStateAction<PageState | undefined>>;
  wizardType?: AppWizardType;
  shouldSubmitOnNext?: boolean;
  centerContent?: boolean;
  'data-cy'?: string;
  showSeparator?: boolean;
  noPadding?: boolean;
  background?: string;
};

export const StepContainer = <T,>({
  stepProps,
  children,
  bottomNavigationProps = {},
  onSubmit,
  hasAutoScroll = false,
  isWide = false,
  fullWidth = false,
  isLoading = false,
  isUpdating = false,
  centerContent = false,
  noPadding,
  background,
  pageState,
  setPageState,
  wizardType,
  showSeparator,

  // TODO: Consider `true` by default if wizardType is not `program_submission`?
  shouldSubmitOnNext = pageState === PageState.EDIT,

  ...props
}: Props<T>) => {
  const { clearAlerts } = useAlerts();
  const previousPageState = usePreviousDistinct<PageState | undefined>(
    pageState
  );

  // distanceFromTop is the main page container's distance from top of the browser window
  // it's passed down to the Content div to calculate the max-height for the auto-scrolling form's lock functionality to work
  const [distanceFromTop, setDistanceFromTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useResizeEffect(() => {
    if (containerRef.current) {
      setDistanceFromTop(containerRef.current.getBoundingClientRect().top);
    }
  }, [containerRef.current]);

  const [hasError, setHasError] = useState(false);

  return (
    <S.PageContainer
      ref={containerRef}
      data-cy={props['data-cy']}
      $hasAutoScroll={hasAutoScroll}
      $background={background}
    >
      <S.Content
        id={WIZARD_MAIN_PANEL}
        $isWide={isWide}
        $fullWidth={fullWidth}
        $distanceFromTop={distanceFromTop}
        $hasAutoScroll={hasAutoScroll}
        $hasVerticalPadding={!hasAutoScroll}
        // program submission wizard uses general layout,
        // which requires different styling for this div
        $hideOverflow={wizardType === 'program_submission'}
        $centerContent={centerContent}
        $noPadding={noPadding}
      >
        {isLoading ? (
          <Skeleton.Base />
        ) : (
          <ErrorBoundary
            FallbackComponent={ErrorSkeleton}
            onError={() => setHasError(true)}
            onReset={() => setHasError(false)}
          >
            <AlertContainer />
            {children}
          </ErrorBoundary>
        )}
      </S.Content>
      <BottomNavigation
        {...{
          ...(stepProps || {}),
          ...bottomNavigationProps,
          ...(wizardType === 'program_submission' && {
            backText: 'Previous',
            nextText:
              stepProps?.isCurrentStepLast ||
              (!stepProps?.nextSubstep &&
                stepProps?.nextStep?.key === 'confirmation')
                ? 'Complete Submission'
                : 'Continue Submission',
          }),
          ...(pageState === PageState.EDIT && {
            backText: 'Exit Form',
            nextText: 'Save',
            backIconVariant: 'close',
            onBack: () => {
              clearAlerts();
              setPageState?.(previousPageState);
            },
          }),
          ...(shouldSubmitOnNext && {
            onNext: () => {
              clearAlerts();
              return onSubmit?.();
            },
          }),
        }}
        backButtonProps={{
          ...bottomNavigationProps.backButtonProps,
          'data-cy': CypressDataIds.Onboarding.PrevStepButton,
        }}
        nextButtonProps={{
          ...bottomNavigationProps.nextButtonProps,
          'data-cy': CypressDataIds.Onboarding.NextStepButton,
          disabled:
            hasError ||
            bottomNavigationProps.nextButtonProps?.disabled ||
            stepProps?.isNavigating ||
            isLoading ||
            isUpdating,
          loading: isUpdating,
        }}
        showSeparator={showSeparator}
      />
    </S.PageContainer>
  );
};
