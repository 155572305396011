import React, { type ComponentProps, Fragment, type ReactNode } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { useTotalRecords } from '@npm/core/ui/hooks/useTotalRecords';
import {
  type IssuerEntityAggregate,
  type IssuerEntityApiIssuerEntityIndexRequest,
  type IssuerEntityIndex,
  useIssuerEntityIndexInfinite,
} from '@npm/data-access';
import { type InfiniteData } from '@tanstack/react-query';

import { CompanyCard, CompanyCardSkeleton } from '../CompanyCard';

import { Results } from './CompanyCards.styles';
import { CompanyCardsNoDataArea } from './NoDataArea';

type Props = {
  data?: InfiniteData<IssuerEntityIndex>;
  variables: IssuerEntityApiIssuerEntityIndexRequest;
  selectedCompanyId?: number;
  selectedCompanies?: IssuerEntityAggregate[];
  onSelect?: (company: IssuerEntityAggregate) => void;
  variant?: 'broker' | 'investor';
  isFilterApplied?: boolean;
  noDataArea?: ReactNode;
  loaderCount?: number;
  mobileLoaderCount?: number;
  companyCardProps?: Partial<ComponentProps<typeof CompanyCard>>;
  onEnterIOIClick?: (companyId: number) => void;
  filterCompanies?: (company: IssuerEntityAggregate) => boolean;
  className?: string;
};

// make two separate components if more differences are to come between showing companies cards in Add to Watchlist drawer and elsewhere
export const CompanyCards = ({
  variables,
  selectedCompanyId,
  selectedCompanies = [],
  onSelect,
  variant = 'broker',
  isFilterApplied,
  noDataArea,
  loaderCount,
  mobileLoaderCount,
  companyCardProps,
  onEnterIOIClick,
  filterCompanies,
  className,
}: Props) => {
  const { data, isFetching, isRefetching, fetchNextPage, hasNextPage } =
    useIssuerEntityIndexInfinite(variables);

  const totalRecords = useTotalRecords(data);

  const isSelectedCompany = (company: IssuerEntityAggregate) =>
    selectedCompanies?.some(c => c.id === company.id) ||
    selectedCompanyId === company.id;

  // Don't show loader when refetching
  // In cases were refetch leads to data being removed it looks weird to show loader.
  const isFetchingOnly = isFetching && !isRefetching;

  return (
    <Results className={className}>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetchingOnly}
        loader={<CompanyCardSkeleton />}
        loaderCount={loaderCount}
        mobileLoaderCount={mobileLoaderCount}
      >
        {data?.pages?.map(page => (
          <Fragment key={page.pagination.page}>
            {page.issuer_entities.map(company => {
              if (filterCompanies && !filterCompanies(company)) {
                return null;
              }

              return (
                <CompanyCard
                  key={company.id}
                  company={company}
                  isSelected={isSelectedCompany(company)}
                  onSelect={() => {
                    if (company.id === selectedCompanyId) {
                      onSelect?.(undefined);
                    } else {
                      onSelect?.(company);
                    }
                  }}
                  variant={variant}
                  footer={
                    onEnterIOIClick && (
                      <Button
                        variant="outline"
                        onClick={() => onEnterIOIClick(company.id)}
                        color="success"
                      >
                        Buy
                      </Button>
                    )
                  }
                  {...companyCardProps}
                />
              );
            })}
          </Fragment>
        ))}
      </InfiniteScroll>
      {!isFetchingOnly &&
        !totalRecords &&
        (noDataArea || (
          <CompanyCardsNoDataArea isFilterApplied={isFilterApplied} />
        ))}
    </Results>
  );
};
