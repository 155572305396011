import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { DateTimePickerInput } from '@npm/core/ui/components/atoms/Input';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { DATE_FORMATS, getTimezoneShort } from '@npm/core/ui/utils/formatters';
import { format as formatDate } from 'date-fns';

type Props = {
  value: Date | undefined;
  onItemChange: () => void;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const PlacedAtFormSection = ({
  value,
  onItemChange,
  ...props
}: Props) => {
  return (
    <CollapsibleFormSection.Item
      label={`Created At (${getTimezoneShort()})`}
      preview={getPlacedAtPreview(value)}
      key="placedAtDate"
      name="placedAtDate"
      {...props}
    >
      <FormItem name="placedAtDate" marginBottom="sm">
        <DateTimePickerInput
          max={new Date()}
          defaultTime="endOfDay"
          allowClear
        />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};

export const getPlacedAtPreview = (value: Date | undefined) =>
  value ? formatDate(value, DATE_FORMATS.FULL) : 'Now';
