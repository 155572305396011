import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type ExtendedColumnType } from '@npm/core/ui/components/molecules/Table';
import {
  formatDate,
  getFullName,
  getTimezoneShort,
} from '@npm/core/ui/utils/formatters';
import { type RepresentativeAggregate } from '@npm/data-access';

export const columns: ExtendedColumnType<RepresentativeAggregate>[] = [
  {
    title: 'Account Representative',
    key: 'representative',
    render: rep => (
      <>
        <Text
          size="sm"
          colorVariant="primary"
          data-dd-action-name="Select Representative"
          data-dd-privacy="mask"
        >
          {getFullName(rep.first, rep.last, rep.middle)}
        </Text>
        <Text size="sm" data-dd-privacy="mask">
          {rep.email}
        </Text>
      </>
    ),
  },
  {
    title: 'Access as',
    key: 'access_as',
    render: rep => rep.access_as?.name,
  },
  {
    title: `Last Accessed at (${getTimezoneShort()})`,
    key: 'background_check_state',
    render: rep => formatDate(rep.last_login_at),
  },
];
