import { CbWorkstationType } from '@npm/data-access';

import { useCurrentWorkstation } from './useCurrentWorkstation';
import { useObo } from './useObo';

export const useIsBrokerage = () => {
  const workstation = useCurrentWorkstation();

  const { isObo } = useObo();

  const isBrokerage =
    workstation?.type?.code === CbWorkstationType.items.brokerage;

  return {
    isBrokerage,
    isObo,
    isBrokerageObo: isBrokerage && isObo,
    isBrokerageNotObo: isBrokerage && !isObo,
  };
};
