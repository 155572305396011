import { useBreakpoint } from '@npm/core/ui/utils/style';
import { Radio } from 'antd';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
  padding: ${({ theme }) => theme.spacing.xs}px;

  margin: 0 -${({ theme }) => theme.spacing.xs}px;
`;

export const RadioGroup = styled(Radio.Group)`
  display: flex;

  & > * {
    min-height: 170px;
    max-width: 320px;

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.md}px;
    }
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column;

    & > * {
      max-width: 100%;
      min-height: 110px;
    }

      & > *:not(:last-child) {
        margin-right: 0;
        margin-bottom: ${theme.spacing.sm}px;
      }
  `}
`;
