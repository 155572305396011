// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MotifsApi,
  InternalMotifIndexErrorCodes,
  InternalMotifIndexErrorTypes,
  MotifsErrorCodes,
  MotifsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MotifsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalMotifIndexMethod = MotifsApi['internalMotifIndex'];
export type InternalMotifIndexResponseType = MethodResult<InternalMotifIndexMethod>;

export const useInternalMotifIndex = (
  variables: Parameters<InternalMotifIndexMethod>[0],
  config?: UseQueryConfig<
    InternalMotifIndexMethod,
    InternalMotifIndexErrorTypes
  >
) => {
  return useQuery<
    InternalMotifIndexMethod,
    InternalMotifIndexErrorTypes
  >(
    (...args) => api.internalMotifIndex(...args),
    InternalMotifIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalMotifIndex'],
        ...apiQueryConfig.InternalMotifIndex,
        ...config?.queryConfig
      },
    },
    'InternalMotifIndex',
  );
};

export const useInternalMotifIndexInfinite = (
  variables: Parameters<InternalMotifIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalMotifIndexMethod,
    InternalMotifIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalMotifIndexMethod,
    InternalMotifIndexErrorTypes
  >(
    (...args) => api.internalMotifIndex(...args),
    InternalMotifIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalMotifIndex', 'InternalMotifIndexInfinite'],
        ...apiQueryConfig.InternalMotifIndex,
        ...config?.queryConfig,
      },
    },
    'InternalMotifIndex',
  );
};

export const useInternalMotifIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalMotifIndexMethod>[0],
  config?: UseQueryConfig<
    InternalMotifIndexMethod,
    InternalMotifIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalMotifIndexMethod,
    InternalMotifIndexErrorTypes
  >(
    (...args) => api.internalMotifIndex(...args),
    InternalMotifIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalMotifIndex'],
        ...apiQueryConfig.InternalMotifIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalMotifIndex',
  );
};


export type MotifsMethod = MotifsApi['motifs'];
export type MotifsResponseType = MethodResult<MotifsMethod>;

export const useMotifs = (
  variables: Parameters<MotifsMethod>[0],
  config?: UseQueryConfig<
    MotifsMethod,
    MotifsErrorTypes
  >
) => {
  return useQuery<
    MotifsMethod,
    MotifsErrorTypes
  >(
    (...args) => api.motifs(...args),
    MotifsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['Motifs'],
        ...apiQueryConfig.Motifs,
        ...config?.queryConfig
      },
    },
    'Motifs',
  );
};

export const useMotifsInfinite = (
  variables: Parameters<MotifsMethod>[0],
  config?: UseInfiniteQueryConfig<
    MotifsMethod,
    MotifsErrorTypes
  >
) => {
  return useInfiniteQuery<
    MotifsMethod,
    MotifsErrorTypes
  >(
    (...args) => api.motifs(...args),
    MotifsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['Motifs', 'MotifsInfinite'],
        ...apiQueryConfig.Motifs,
        ...config?.queryConfig,
      },
    },
    'Motifs',
  );
};

export const useMotifsLazy = (baseOptions?: {
  variables?: Parameters<MotifsMethod>[0],
  config?: UseQueryConfig<
    MotifsMethod,
    MotifsErrorTypes
  >
}) => {
  return useLazyQuery<
    MotifsMethod,
    MotifsErrorTypes
  >(
    (...args) => api.motifs(...args),
    MotifsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['Motifs'],
        ...apiQueryConfig.Motifs,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'Motifs',
  );
};

