import { getSideTooltipPosition } from '@npm/core/ui/components/atoms/ChartTooltip/ChartTooltip.utils';
import type { Chart, TooltipModel } from 'chart.js';

import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';

import {
  type MovingAverageChartPoint,
  type MovingAverageValue,
  type MtmChartPoint,
  type MtmValue,
  type MutualFundActiveDataPoint,
  type MutualFundChartPoint,
} from './MutualFundMarksChart.types';

export const isCommonAssetType = (assetType: string): boolean => {
  return assetType.toLowerCase().includes('common');
};

type AggregationMap = {
  [key: string]: {
    [key: string]: MtmValue[];
  };
};

export const convertMtmDataSet = (
  data: MtmValue[],
  series: CompanyOverviewChartSeries
): MtmChartPoint[] => {
  if (!data?.length) {
    return [];
  }

  const isPPS = series === 'PPS';

  const result: MtmChartPoint[] = [];

  const aggregationMap: AggregationMap = {};

  const reportingPeriods = [];

  data.forEach(item => {
    // new reporting period
    if (!aggregationMap[item.reporting_period]) {
      reportingPeriods.push(item.reporting_period);
      aggregationMap[item.reporting_period] = {
        [item.price_per_share]: [item],
      };
    }
    // new price per share
    else if (!aggregationMap[item.reporting_period][item.price_per_share]) {
      aggregationMap[item.reporting_period][item.price_per_share] = [item];
    }
    // existing reporting period and price per share -> aggregate
    else {
      aggregationMap[item.reporting_period][item.price_per_share].push(item);
    }
  });

  reportingPeriods.forEach(reportingPeriod => {
    const pricePerShares = Object.keys(aggregationMap[reportingPeriod]);

    pricePerShares.forEach(pricePerShare => {
      const items = aggregationMap[reportingPeriod][pricePerShare];

      result.push({
        x: reportingPeriod,
        y: isPPS ? items[0].price_per_share : items[0].implied_valuation,
        type: 'mtm',
        data: items,
      });
    });
  });

  return result;
};

export const convertMovingAverageDataSets = (
  data: MovingAverageValue[],
  series: CompanyOverviewChartSeries
): MovingAverageChartPoint[] => {
  if (!data?.length) {
    return [];
  }

  const isPPS = series === 'PPS';

  return data.map(item => ({
    x: item.date,
    y: isPPS
      ? 'moving_average' in item && (item.moving_average as number)
      : item.implied_valuation,
    type: 'movingAverage',
    data: item,
  }));
};

export const MUTUAL_FUND_CHART_HTML_ID = 'mutual-fund-chart';

export const getMutualFundChartTooltip = (canvas: HTMLCanvasElement) => {
  return canvas.parentNode.querySelector(
    `#${MUTUAL_FUND_CHART_HTML_ID}`
  ) as HTMLElement;
};

const TOOLTIP_WIDTH = 257;
const MTM_TOOLTIP_HEIGHT = 154;
const MOVING_AVERAGE_TOOLTIP_HEIGHT = 116;

const PADDING = 20;

export const showMutualFundChartTooltip = (
  context: {
    chart: Chart;
    tooltip: TooltipModel<'line'>;
  },
  setActiveDataPoint: (dataPoint: MutualFundActiveDataPoint) => void
) => {
  const { chart, tooltip } = context;

  const tooltipEl = getMutualFundChartTooltip(chart.canvas);

  const isActive = tooltip.opacity !== 0; // opacity is the source of truth

  if (!isActive) {
    setActiveDataPoint(undefined);
    return;
  }

  const dataPoint = tooltip.dataPoints?.[0]?.raw as MutualFundChartPoint;

  let tooltipHeight = 0;

  if (dataPoint?.type === 'mtm') {
    tooltipHeight = MTM_TOOLTIP_HEIGHT;
    setActiveDataPoint({
      type: 'mtm',
      data: dataPoint.data as MtmValue[],
    });
  } else if (dataPoint?.type === 'movingAverage') {
    tooltipHeight = MOVING_AVERAGE_TOOLTIP_HEIGHT;
    setActiveDataPoint({
      type: 'movingAverage',
      data: dataPoint.data as MovingAverageValue,
    });
  } else {
    setActiveDataPoint(undefined);
    return;
  }

  const point = tooltip.dataPoints?.[0].element;

  const tooltipPosition = getSideTooltipPosition(
    point,
    chart.chartArea,
    TOOLTIP_WIDTH,
    tooltipHeight,
    PADDING
  );

  tooltipEl.style.display = 'block';
  tooltipEl.style.top = '0px';
  tooltipEl.style.left = '0px';
  tooltipEl.style.width = TOOLTIP_WIDTH + 'px';
  tooltipEl.style.height = tooltipHeight + 'px';
  tooltipEl.style.transform = `translateX(${tooltipPosition.x}px) translateY(${tooltipPosition.y}px)`;
  tooltipEl.style.transition = 'all .1s ease';
};

export const sortMtmData = (
  data: MtmValue[],
  order: 'asc' | 'desc' = 'asc'
): MtmValue[] => {
  return data.sort((a, b) => {
    if (order === 'asc') {
      return new Date(a.reporting_period) < new Date(b.reporting_period)
        ? -1
        : 1;
    }
    return new Date(a.reporting_period) > new Date(b.reporting_period) ? -1 : 1;
  });
};

type SplitResult = {
  commonMtm: MtmValue[];
  preferredMtm: MtmValue[];
};

export const splitMtmDataByAssetType = (data: MtmValue[]): SplitResult => {
  if (!data?.length) {
    return {
      commonMtm: [],
      preferredMtm: [],
    };
  }

  const result: SplitResult = {
    commonMtm: [],
    preferredMtm: [],
  };

  data.forEach(item => {
    if (isCommonAssetType(item.mutual_fund_asset_name)) {
      result.commonMtm.push(item);
    } else {
      result.preferredMtm.push(item);
    }
  });

  return result;
};
