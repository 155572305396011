import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { truncate } from '@npm/core/ui/utils/formatters';
import {
  CbOrderBuySell,
  type Holding,
  type SecondmarketSubmissionForClientOrders,
} from '@npm/data-access';

import { getUniqueHoldingIdName } from '../../../../../holdings';
import { AggregatedHoldingLabel } from '../../../../../holdings/aggregated/List/components';
import { isOrderNotEditable } from '../InvestorOrdersList.utils';

import * as S from './InvestorOrdersTable.styles';

export const getHoldingDisplayValue = (
  submission: SecondmarketSubmissionForClientOrders,
  onHoldingView?: (holdingId: number) => void,
  onHoldingEdit?: (holdingId: number, holding: Holding) => void
) => {
  const isBuyOrder = submission?.side?.code === CbOrderBuySell.items.buy;
  const assetName = submission?.asset_type?.name;

  const holding = submission?.items?.[0]?.holding;

  // certificate number is not required for aggregated holdings
  const isMissingCertificateNumber = holding?.aggregated
    ? false
    : !holding?.certificate_number;

  const certificateNumber = holding?.certificate_number;

  const isMissingPoO = holding?.aggregated
    ? !holding.aggregated_proof_of_ownership_documents_count
    : !holding?.proof_of_ownership_document;

  if (isBuyOrder) {
    return <Text size="sm">{assetName}</Text>;
  }

  if (isMissingPoO || isMissingCertificateNumber) {
    return (
      <S.Wrapper>
        <Icon name="warning" size="xs" color="warning" />
        <Text
          as="button"
          size="sm"
          hyperlinkColor="warning"
          onClick={e => {
            onHoldingEdit(holding?.id, holding);
            e.stopPropagation();
          }}
          lineClamp={1}
        >
          {`Missing ${isMissingPoO ? 'Proof of Ownership' : getUniqueHoldingIdName(holding)}`}
        </Text>
      </S.Wrapper>
    );
  }

  const handleClick = (
    e: React.MouseEvent,
    callback?: (holdingId: number, holding: Holding) => void
  ) => {
    e.stopPropagation();
    if (callback) callback(holding?.id, holding);
  };

  return (
    <S.Wrapper>
      <S.TextWrapper>
        <Icon name={holding?.aggregated ? 'layers' : 'file-text'} size="xs" />
        <Text
          size="sm"
          colorVariant="secondary"
          lineClamp={1}
        >{`${submission?.asset_type?.name} • `}</Text>
      </S.TextWrapper>
      {certificateNumber || holding?.aggregated ? (
        onHoldingView ? (
          <Text
            size="sm"
            lineClamp={1}
            onClick={e => handleClick(e, onHoldingView)}
          >
            {certificateNumber ? truncate(certificateNumber, 24) : 'Aggregated'}
          </Text>
        ) : (
          certificateNumber || <AggregatedHoldingLabel noBg />
        )
      ) : onHoldingEdit ? (
        <Text
          as="button"
          size="sm"
          onClick={e => handleClick(e, onHoldingEdit)}
        >
          + Add ID
        </Text>
      ) : (
        EMPTY_VALUE
      )}
    </S.Wrapper>
  );
};

export const getSizeDisplayValue = (
  submission: SecondmarketSubmissionForClientOrders,
  onOrderEdit: (submission: SecondmarketSubmissionForClientOrders) => void
) => {
  const order = submission?.items?.[0];
  const isOrderEditDisabled = isOrderNotEditable(order?.state?.code);

  return (
    <Flex gap="xs">
      <Text.Quantity value={order?.quantity} />
      <Text size="sm" colorVariant="muted">
        @
      </Text>
      {order?.price ? (
        <Text.Price value={order?.price} />
      ) : (
        <Button
          size="sm"
          disabled={isOrderEditDisabled}
          onClick={e => {
            e.stopPropagation();
            onOrderEdit(submission);
          }}
        >
          + Add PPS
        </Button>
      )}
    </Flex>
  );
};
