// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  ThirdPartyOrdersExportApi,
  ThirdPartyOrdersExportShowErrorCodes,
  ThirdPartyOrdersExportShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new ThirdPartyOrdersExportApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ThirdPartyOrdersExportShowMethod = ThirdPartyOrdersExportApi['thirdPartyOrdersExportShow'];
export type ThirdPartyOrdersExportShowResponseType = MethodResult<ThirdPartyOrdersExportShowMethod>;

export const useThirdPartyOrdersExportShow = (
  variables: Parameters<ThirdPartyOrdersExportShowMethod>[0],
  config?: UseQueryConfig<
    ThirdPartyOrdersExportShowMethod,
    ThirdPartyOrdersExportShowErrorTypes
  >
) => {
  return useQuery<
    ThirdPartyOrdersExportShowMethod,
    ThirdPartyOrdersExportShowErrorTypes
  >(
    (...args) => api.thirdPartyOrdersExportShow(...args),
    ThirdPartyOrdersExportShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ThirdPartyOrdersExportShow'],
        ...apiQueryConfig.ThirdPartyOrdersExportShow,
        ...config?.queryConfig
      },
    },
    'ThirdPartyOrdersExportShow',
  );
};

export const useThirdPartyOrdersExportShowInfinite = (
  variables: Parameters<ThirdPartyOrdersExportShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    ThirdPartyOrdersExportShowMethod,
    ThirdPartyOrdersExportShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    ThirdPartyOrdersExportShowMethod,
    ThirdPartyOrdersExportShowErrorTypes
  >(
    (...args) => api.thirdPartyOrdersExportShow(...args),
    ThirdPartyOrdersExportShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ThirdPartyOrdersExportShow', 'ThirdPartyOrdersExportShowInfinite'],
        ...apiQueryConfig.ThirdPartyOrdersExportShow,
        ...config?.queryConfig,
      },
    },
    'ThirdPartyOrdersExportShow',
  );
};

export const useThirdPartyOrdersExportShowLazy = (baseOptions?: {
  variables?: Parameters<ThirdPartyOrdersExportShowMethod>[0],
  config?: UseQueryConfig<
    ThirdPartyOrdersExportShowMethod,
    ThirdPartyOrdersExportShowErrorTypes
  >
}) => {
  return useLazyQuery<
    ThirdPartyOrdersExportShowMethod,
    ThirdPartyOrdersExportShowErrorTypes
  >(
    (...args) => api.thirdPartyOrdersExportShow(...args),
    ThirdPartyOrdersExportShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ThirdPartyOrdersExportShow'],
        ...apiQueryConfig.ThirdPartyOrdersExportShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ThirdPartyOrdersExportShow',
  );
};

