import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import { PriceCard } from '@npm/core/ui/components/molecules/PriceCard';
import {
  CbVisibility,
  type Holding,
  type IssuerEntityAggregate,
  type SecondmarketOrderItemSimple,
} from '@npm/data-access';
import { type FormInstance, type FormProps } from 'antd';
import { useTheme } from 'styled-components';

import { useSponsorshipLevel } from '../../../../../../auth/user/role';
import { AggregatedHoldingDrawer } from '../../../../../../holdings/aggregated/Drawer';
import { useAggregatedHoldingDrawer } from '../../../../../../holdings/aggregated/Drawer/AggregatedHoldingDrawer.hooks';
import { HoldingMultiSelect } from '../../../../../drawers/components/HoldingMultiSelect';
import { OrderEntrySection } from '../../../../../drawers/OrderEntryDrawer/OrderPlacementDrawer/OrderPlacementForm';
import { useHoldingQuantities } from '../../../../../hooks/useHoldingsQuantities';
import { getCalculatedValues } from '../../EnterSellIndication.utils';
import { MarketIntelligenceV2 } from '../MarketIntelligenceV2';
import type { SellFormValues } from '../SellIndicationDrawer.types';

type Props = {
  form: FormInstance<SellFormValues>;
  values: SellFormValues | undefined;
  initialHolding: Holding | undefined;
  selectedHoldings: Holding[] | undefined;
  setSelectedHoldings: (holdings: Holding[]) => void;
  issuerEntity?: IssuerEntityAggregate;
  accountId?: number;
  isLoading?: boolean;
  order?: SecondmarketOrderItemSimple;
} & Pick<FormProps, 'id' | 'onFinish' | 'onValuesChange'>;

export const SellIndicationForm = ({
  form,
  values,
  initialHolding,
  selectedHoldings,
  setSelectedHoldings,
  issuerEntity,
  accountId,
  isLoading,
  ...formProps
}: Props) => {
  const theme = useTheme();

  const holdingGuids = Form.useWatch('holdingGuids', form);

  const [{ openAggregatedHoldingDrawer }, aggregatedHoldingDrawerProps] =
    useAggregatedHoldingDrawer();

  const formValues = Form.useWatch([], form) ?? ({} as SellFormValues);
  const { estimatedGrossProceeds } = getCalculatedValues(
    formValues?.quantity,
    formValues?.pricePerShare
  );

  const initialValues = {
    holdingGuids: initialHolding ? [initialHolding.npm_guid] : [],
  };

  useEffect(() => {
    // Set previously submitted values when user goes back to the form
    if (values) {
      form.setFieldsValue(values);
    }
    // Set initial values
    else {
      form.setFieldsValue({
        holdingGuids: initialHolding ? [initialHolding.npm_guid] : [],
      });
    }
  }, [form, values, initialHolding, setSelectedHoldings]);

  useEffect(() => {
    if (!holdingGuids?.length && !!selectedHoldings?.length) {
      form.setFieldValue(
        'holdingGuids',
        selectedHoldings.map(h => h.npm_guid)
      );
    }
  }, [form, holdingGuids, selectedHoldings]);

  const quantities = useHoldingQuantities(selectedHoldings);

  const { isSponsorshipLevel3 } = useSponsorshipLevel();

  const initialVisibility = isSponsorshipLevel3
    ? // IND Level3 orders are automatically external (can't toggle)
      CbVisibility.items.external
    : CbVisibility.items.internal;

  return (
    <div>
      <Form<SellFormValues>
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        {...formProps}
      >
        <Flex direction={'column'} gap={'lg'}>
          <Alert
            type={'info'}
            message={
              <Flex direction={'column'} gap={'sm'}>
                <Text size={'sm'} colorVariant={'primary'} weight={'bold'}>
                  Post Your offer anonymously.
                </Text>

                <Text size={'sm'} colorVariant={'secondary'}>
                  Your Standing Offer in {issuerEntity.name} will be visible to
                  all eligible buyers. Buyers will then be able to accept or
                  negotiate their terms. All user data is hidden until an order
                  reaches the contract & settlement stage
                </Text>
              </Flex>
            }
            showIcon={false}
            padding={'md'}
          />

          <Flex direction={'column'} gap={'sm'}>
            <DrawerSection
              title={'What do you want to sell?'}
              content={
                <Flex direction={'column'} gap={'xs'}>
                  <FormItem name={'holdingGuids'}>
                    <HoldingMultiSelect
                      initialHolding={initialHolding}
                      issuerEntityId={issuerEntity?.id}
                      accountId={accountId}
                      secondmarket={true}
                      quantities={quantities}
                      dropdownStyle={{
                        zIndex: theme.zIndex.aboveDrawer,
                      }}
                      onHoldingsSelect={setSelectedHoldings}
                      onAddHoldingClick={openAggregatedHoldingDrawer}
                      addHoldingTitle={'Add New Holding Type'}
                      filterOptions={holding => holding?.aggregated}
                    />
                  </FormItem>
                  <OrderEntrySection
                    activeAction={'sell'}
                    sizeType={'Shares'}
                    company={issuerEntity}
                    visibility={initialVisibility}
                    showMinimumQuantity={false}
                    showSizeExtraRow={false}
                    remainingQuantity={quantities?.remainingQuantity}
                    showValuation={false}
                    showPpsValuation
                    header={{
                      showHeader: false,
                    }}
                  />

                  <Margin top={'md'}>
                    <PriceCard
                      value={estimatedGrossProceeds}
                      title={'Your Estimated Gross Proceeds'}
                    />
                  </Margin>
                </Flex>
              }
            />
          </Flex>

          <Divider marginBottom={0} />
          <ErrorBoundary FallbackComponent={ErrorSkeleton}>
            <MarketIntelligenceV2
              issuerEntity={issuerEntity}
              isLoading={isLoading}
              pricePerShare={formValues?.pricePerShare}
            />
          </ErrorBoundary>
        </Flex>
      </Form>

      <AggregatedHoldingDrawer
        issuerEntity={issuerEntity}
        accountId={accountId}
        onSuccess={holding => {
          setSelectedHoldings([...selectedHoldings, holding]);
          form.setFieldsValue({
            holdingGuids: [...formValues.holdingGuids, holding.npm_guid],
          });
        }}
        {...aggregatedHoldingDrawerProps}
      />
    </div>
  );
};
