import { type ReactNode } from 'react';

import * as S from './UnsupportedResolutionScreen.styles';

type Props = {
  children?: ReactNode;
  content: ReactNode;
  isSupported: boolean;
  disablePadding?: boolean;
};

export const UnsupportedResolutionScreen = ({
  children,
  content,
  isSupported,
  disablePadding,
}: Props) => {
  if (isSupported) {
    return <>{children}</>;
  }

  return (
    <S.Container $disablePadding={disablePadding}>
      <S.Wrapper>{content}</S.Wrapper>
    </S.Container>
  );
};
