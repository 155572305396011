import React, { useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  AlertContainer,
  useAlerts,
} from '@npm/core/ui/components/molecules/AlertContainer';
import { lightTheme } from '@npm/core/ui/themes';
import { useOktaUserActivate } from '@npm/data-access';
import { oktaAuth } from '@npm/okta';
import { ThemeProvider } from 'styled-components';

import * as SForm from '../signup/OktaSignUp/OktaForm/OktaForm.styles';
import * as S from '../signup/OktaSignUp/OktaSignUp.styles';
import { NpmLogo } from '../signup/OktaSignUp/NpmLogo';

interface FormValues {
  email: string;
}

// This page allows user to activate their account.
// It calls `/api/user/activate` to send an activation email.
export const ActivationPage = () => {
  const [form] = Form.useForm<FormValues>();
  const [success, setSuccess] = useState(false);

  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute, isLoading } = useOktaUserActivate();

  const signInRedirect = async () => {
    await oktaAuth.signOut({
      revokeRefreshToken: true,
      revokeAccessToken: true,
      clearTokensBeforeRedirect: true,
    });
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await withShowApiErrorMiddleware(execute)({
        oktaServiceCreateRequestContract: {
          ...values,
        },
      });
    } catch (e) {
      setSuccess(false);
      return;
    }
    setSuccess(true);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <S.Container>
        <S.Content>
          <S.LogoContainer>
            <NpmLogo />
          </S.LogoContainer>

          <S.MainPadding>
            {success ? (
              <>
                <SForm.Header>
                  <Text size="sm">
                    You will receive an activation email shortly with
                    instructions to set up your account.
                    <br />
                    <br />
                    If you do not receive an activation email, please reach out
                    to{' '}
                    <a
                      href="mailto:npm@secondmarket.com"
                      style={{ textDecorationLine: 'underline' }}
                    >
                      NPM Support
                    </a>{' '}
                    .
                    <br />
                    <br />
                    If you have previously logged in to the NPM Platform, please
                    log in using your existing credentials.
                  </Text>
                </SForm.Header>
                <SForm.BottomLinks>
                  <SForm.TextLink size="sm" onClick={signInRedirect}>
                    Back to Sign In
                  </SForm.TextLink>
                </SForm.BottomLinks>
              </>
            ) : (
              <>
                <SForm.Header>
                  <SForm.Heading variant="h3" marginBottom="lg">
                    Activate Your Account
                  </SForm.Heading>
                  <Text size="sm">
                    Please use the email address at which you received the NPM
                    Platform invitation.
                  </Text>
                </SForm.Header>
                <AlertContainer />
                <Form form={form} onFinish={onSubmit}>
                  <SForm.FormItem
                    rules={[
                      VALIDATION_RULES.required('Email'),
                      {
                        ...VALIDATION_RULES.email(),
                        validateTrigger: 'onSubmit',
                      },
                    ]}
                    name="email"
                    label="Email"
                  >
                    <SForm.Input disabled={isLoading} placeholder="Email" />
                  </SForm.FormItem>
                </Form>

                <SForm.SubmitBtnContainer>
                  <S.FullWidthButton loading={isLoading} onClick={form.submit}>
                    Send Activation Email
                  </S.FullWidthButton>
                </SForm.SubmitBtnContainer>

                <SForm.BottomLinks>
                  <SForm.TextLink size="sm" onClick={signInRedirect}>
                    Back to Sign In
                  </SForm.TextLink>
                </SForm.BottomLinks>
              </>
            )}
          </S.MainPadding>
        </S.Content>
      </S.Container>
    </ThemeProvider>
  );
};

export default ActivationPage;
