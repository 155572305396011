import { useMemo } from 'react';

import {
  type AccountsBeneficialOwnerShow,
  CbBeneficialOwnerEntityExemption,
  CbBeneficialOwnerExemption,
  CbBeneficialOwnerRole,
  useCodebook,
} from '@npm/data-access';

import { type BeneficialOwnersExemptions } from '../../BeneficialOwnership.types';

import * as S from './ExemptionComplete.styles';
import { MAX_BENEFICIAL_OWNERS } from './ExemptionComplete.constants';

export const filterOwnerByRole = (
  owners: AccountsBeneficialOwnerShow[],
  role: string
) => {
  return owners?.filter(owner => owner.role.code === role);
};

export const getAlertProps = (
  shouldAddControlPersonOnly: boolean,
  exemptions: BeneficialOwnersExemptions,
  accountName: string
) => {
  if (shouldAddControlPersonOnly) {
    return {
      message: 'Beneficial Owners Information',
      description:
        'Please provide the following information for at least one individual with significant responsibility for managing the legal entity listed above as a control person, such as a CEO, CFO, COO, Managing Member, General Partner, or President.',
    };
  } else if (
    exemptions?.owner_exemption === CbBeneficialOwnerExemption.items.none &&
    exemptions?.entity_exemption === CbBeneficialOwnerEntityExemption.items.none
  ) {
    return {
      message: 'Beneficial Owners Information',
      description: (
        <S.Ul>
          <li>
            Please provide the following information for each individual
            beneficial owner, if any, who, directly or indirectly through any
            contract, arrangement, understanding, relationship or otherwise,
            owns 25 percent or more of the equity interests of {accountName}
          </li>
          <li>
            Please provide the following information for at least one individual
            with significant responsibility for managing the legal entity listed
            above as a control person, such as a CEO, CFO, COO, Managing Member,
            General Partner, or President.
          </li>
        </S.Ul>
      ),
    };
  }

  return {
    message: 'You are exempt based on the category selected',
  };
};

export const useDisablesConfig = (
  owners: AccountsBeneficialOwnerShow[],
  controlPersonOnly: boolean
) => {
  const controlPerson = filterOwnerByRole(
    owners,
    CbBeneficialOwnerRole.items.controlPerson
  )?.length;
  const beneficialOwners = filterOwnerByRole(
    owners,
    CbBeneficialOwnerRole.items.beneficialOwner
  )?.length;
  const both = filterOwnerByRole(
    owners,
    CbBeneficialOwnerRole.items.both
  )?.length;

  const idsFilled = !owners?.some(o => !o.government_issued_document_uploaded);

  return useMemo(
    () => ({
      completeButton:
        !(controlPersonOnly
          ? owners?.length > 0
          : (controlPerson === 1 &&
              beneficialOwners <= MAX_BENEFICIAL_OWNERS &&
              beneficialOwners > 0) ||
            (both === 1 &&
              beneficialOwners <= MAX_BENEFICIAL_OWNERS - 1 &&
              beneficialOwners >= 0)) || !idsFilled,

      addIndividualFormRole:
        (controlPerson > 0 && beneficialOwners <= MAX_BENEFICIAL_OWNERS) ||
        (both > 0 && beneficialOwners <= MAX_BENEFICIAL_OWNERS - 1),

      addIndividualButton: controlPersonOnly
        ? controlPerson === 1
        : (controlPerson === 1 && beneficialOwners === MAX_BENEFICIAL_OWNERS) ||
          (both === 1 && beneficialOwners === MAX_BENEFICIAL_OWNERS - 1),
    }),
    [
      beneficialOwners,
      both,
      controlPerson,
      controlPersonOnly,
      idsFilled,
      owners?.length,
    ]
  );
};

export const useExemptAnswerData = (exemptions: BeneficialOwnersExemptions) => {
  const { data: entityCb } = useCodebook({
    type: CbBeneficialOwnerEntityExemption.code,
  });
  const { data: ownerCb } = useCodebook({
    type: CbBeneficialOwnerExemption.code,
  });
  const entityExemption = useMemo(() => {
    if (entityCb) {
      if (
        exemptions?.entity_exemption ===
        CbBeneficialOwnerEntityExemption.items.none
      ) {
        return 'Not Exempt';
      } else {
        return entityCb.codebooks.find(
          v => v.code === exemptions?.entity_exemption
        )?.name;
      }
    }
    return null;
  }, [entityCb, exemptions?.entity_exemption]);

  const ownerExemption = useMemo(() => {
    if (ownerCb) {
      if (
        exemptions?.owner_exemption === CbBeneficialOwnerExemption.items.none ||
        exemptions?.owner_exemption ===
          CbBeneficialOwnerExemption.items.no_owner
      ) {
        return 'Not Exempt';
      } else {
        return ownerCb.codebooks.find(
          v => v.code === exemptions?.owner_exemption
        )?.name;
      }
    }
    return null;
  }, [exemptions?.owner_exemption, ownerCb]);

  return { entityExemption, ownerExemption };
};
