import React, { useMemo, useState } from 'react';

import {
  type DocumentApiDocumentCreateRequest,
  type DocumentSimple,
  type FolderAggregate,
  useDocumentCreate,
} from '@npm/data-access';
import { type UploadFile } from 'antd/lib/upload/interface';

import { UploadDrawer } from './components/UploadDrawer';

import * as S from './DataRoom.styles';
import {
  AddFolderDrawer,
  type AddFolderFormValues,
  type EditDocumentFormValues,
  type EditFolderFormValues,
  FileExplorer,
  Path,
  UploadButtons,
} from './components';

export type UploadRequestType = Pick<
  DocumentApiDocumentCreateRequest,
  'downloadable' | 'watermark' | 'displayName'
> & { file: UploadFile };

type Props = {
  companyLogoUrl?: string;
  companyName?: string;
  history: FolderAggregate[];
  selectedFolder: FolderAggregate;
  position: number;
  onHomeClick?: () => void;
  onFolderClick?: (id: number) => void;
  onBackClick?: (amount?: number) => void;
  onDownloadClick?: (id: number, fileName: string) => void;
  isEditable?: boolean | string[];
  useEditableProp?: boolean;
  actionsDisabled?: boolean;
  isLoading?: boolean;
  canAccessDataRoomsList?: boolean;
  onDocumentEdit?: (
    id: number,
    values: EditDocumentFormValues
  ) => Promise<void>;
  onDocumentDelete?: (id: number) => void;
  onDocumentClick?: (doc: DocumentSimple) => void;
  onFolderAdd?: (values: AddFolderFormValues) => Promise<void>;
  onFolderEdit?: (id: number, values: EditFolderFormValues) => Promise<void>;
  onFolderDelete?: (id: number) => void;
};

export const DataRoom = ({
  selectedFolder = { id: null },
  history,
  position,
  onFolderClick,
  onBackClick,
  onHomeClick,
  onDownloadClick,
  companyLogoUrl,
  companyName,
  isEditable,
  useEditableProp,
  canAccessDataRoomsList,
  isLoading,
  onDocumentEdit,
  onDocumentDelete,
  onDocumentClick,
  onFolderAdd,
  onFolderEdit,
  onFolderDelete,
  actionsDisabled,
}: Props) => {
  const isFolderEditable = useMemo(() => {
    if (Array.isArray(isEditable)) {
      const rootFolderName = history[0]?.name;
      // the check is done only based on name of the folder so we are checking only first level folders
      // otherwise every folder with editable name would be editable
      // condition: if not in root and first level parent is editable folder
      return (
        position >= 0 &&
        isEditable
          ?.map(x => x?.toLowerCase())
          .includes(rootFolderName?.toLowerCase())
      );
    } else {
      return isEditable;
    }
  }, [isEditable, history, position]);

  const [isAddFolderDrawerOpen, setIsAddFolderDrawerOpen] = useState(false);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);

  const { execute: createDocument } = useDocumentCreate();

  return (
    <>
      <S.Container>
        <Path
          canAccessDataRoomsList={canAccessDataRoomsList}
          onHomeClick={(...args) => {
            onHomeClick(...args);
          }}
          onBackClick={(...args) => {
            onBackClick(...args);
          }}
          {...{
            history,
            position,
            companyLogoUrl,
            companyName,
          }}
          actions={
            isFolderEditable && (
              <UploadButtons
                onUploadClick={() => setIsUploadDrawerOpen(true)}
                onAddFolder={() => setIsAddFolderDrawerOpen(true)}
                actionsDisabled={actionsDisabled}
              />
            )
          }
        />
        <FileExplorer
          isLoading={isLoading}
          folder={selectedFolder}
          isFolderEditable={isFolderEditable}
          useEditableProp={useEditableProp}
          onFolderClick={onFolderClick}
          onDownloadClick={onDownloadClick}
          onDocumentEdit={onDocumentEdit}
          onDocumentDelete={onDocumentDelete}
          onDocumentClick={onDocumentClick}
          onFolderEdit={onFolderEdit}
          onFolderDelete={onFolderDelete}
          actionsDisabled={actionsDisabled}
        />
      </S.Container>

      {isFolderEditable && (
        <AddFolderDrawer
          isOpen={isAddFolderDrawerOpen}
          onClose={() => setIsAddFolderDrawerOpen(false)}
          onSubmit={onFolderAdd}
        />
      )}
      {isFolderEditable && (
        <UploadDrawer
          onUploadFile={(payload, options) =>
            createDocument({ ...payload, folderId: selectedFolder.id }, options)
          }
          isOpen={isUploadDrawerOpen}
          onClose={() => setIsUploadDrawerOpen(false)}
        />
      )}
    </>
  );
};
