// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StockClassesApi,
  InternalStockClassShowErrorCodes,
  InternalStockClassShowErrorTypes,
  InternalStockClassUpdateErrorCodes,
  InternalStockClassRemoveErrorCodes,
  InternalStockClassesIndexErrorCodes,
  InternalStockClassesIndexErrorTypes,
  InternalAllStockClassesIndexErrorCodes,
  InternalAllStockClassesIndexErrorTypes,
  CompanyStockClassesErrorCodes,
  CompanyStockClassesErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StockClassesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalStockClassShowMethod = StockClassesApi['internalStockClassShow'];
export type InternalStockClassShowResponseType = MethodResult<InternalStockClassShowMethod>;

export const useInternalStockClassShow = (
  variables: Parameters<InternalStockClassShowMethod>[0],
  config?: UseQueryConfig<
    InternalStockClassShowMethod,
    InternalStockClassShowErrorTypes
  >
) => {
  return useQuery<
    InternalStockClassShowMethod,
    InternalStockClassShowErrorTypes
  >(
    (...args) => api.internalStockClassShow(...args),
    InternalStockClassShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalStockClassShow'],
        ...apiQueryConfig.InternalStockClassShow,
        ...config?.queryConfig
      },
    },
    'InternalStockClassShow',
  );
};

export const useInternalStockClassShowInfinite = (
  variables: Parameters<InternalStockClassShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalStockClassShowMethod,
    InternalStockClassShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalStockClassShowMethod,
    InternalStockClassShowErrorTypes
  >(
    (...args) => api.internalStockClassShow(...args),
    InternalStockClassShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalStockClassShow', 'InternalStockClassShowInfinite'],
        ...apiQueryConfig.InternalStockClassShow,
        ...config?.queryConfig,
      },
    },
    'InternalStockClassShow',
  );
};

export const useInternalStockClassShowLazy = (baseOptions?: {
  variables?: Parameters<InternalStockClassShowMethod>[0],
  config?: UseQueryConfig<
    InternalStockClassShowMethod,
    InternalStockClassShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalStockClassShowMethod,
    InternalStockClassShowErrorTypes
  >(
    (...args) => api.internalStockClassShow(...args),
    InternalStockClassShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalStockClassShow'],
        ...apiQueryConfig.InternalStockClassShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalStockClassShow',
  );
};


export type InternalStockClassUpdateMethod = StockClassesApi['internalStockClassUpdate'];

export const useInternalStockClassUpdate = () => {
  return useMutation<InternalStockClassUpdateMethod>(
    (...args) => api.internalStockClassUpdate(...args),
    InternalStockClassUpdateErrorCodes,
    'InternalStockClassUpdate',
    '/api/internal/stock_classes/{id}',
    'put'
  );
};

export type InternalStockClassRemoveMethod = StockClassesApi['internalStockClassRemove'];

export const useInternalStockClassRemove = () => {
  return useMutation<InternalStockClassRemoveMethod>(
    (...args) => api.internalStockClassRemove(...args),
    InternalStockClassRemoveErrorCodes,
    'InternalStockClassRemove',
    '/api/internal/stock_classes/{id}/remove',
    'post'
  );
};

export type InternalStockClassesIndexMethod = StockClassesApi['internalStockClassesIndex'];
export type InternalStockClassesIndexResponseType = MethodResult<InternalStockClassesIndexMethod>;

export const useInternalStockClassesIndex = (
  variables: Parameters<InternalStockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalStockClassesIndexMethod,
    InternalStockClassesIndexErrorTypes
  >
) => {
  return useQuery<
    InternalStockClassesIndexMethod,
    InternalStockClassesIndexErrorTypes
  >(
    (...args) => api.internalStockClassesIndex(...args),
    InternalStockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalStockClassesIndex'],
        ...apiQueryConfig.InternalStockClassesIndex,
        ...config?.queryConfig
      },
    },
    'InternalStockClassesIndex',
  );
};

export const useInternalStockClassesIndexInfinite = (
  variables: Parameters<InternalStockClassesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalStockClassesIndexMethod,
    InternalStockClassesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalStockClassesIndexMethod,
    InternalStockClassesIndexErrorTypes
  >(
    (...args) => api.internalStockClassesIndex(...args),
    InternalStockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalStockClassesIndex', 'InternalStockClassesIndexInfinite'],
        ...apiQueryConfig.InternalStockClassesIndex,
        ...config?.queryConfig,
      },
    },
    'InternalStockClassesIndex',
  );
};

export const useInternalStockClassesIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalStockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalStockClassesIndexMethod,
    InternalStockClassesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalStockClassesIndexMethod,
    InternalStockClassesIndexErrorTypes
  >(
    (...args) => api.internalStockClassesIndex(...args),
    InternalStockClassesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalStockClassesIndex'],
        ...apiQueryConfig.InternalStockClassesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalStockClassesIndex',
  );
};


export type InternalAllStockClassesIndexMethod = StockClassesApi['internalAllStockClassesIndex'];
export type InternalAllStockClassesIndexResponseType = MethodResult<InternalAllStockClassesIndexMethod>;

export const useInternalAllStockClassesIndex = (
  variables: Parameters<InternalAllStockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllStockClassesIndexMethod,
    InternalAllStockClassesIndexErrorTypes
  >
) => {
  return useQuery<
    InternalAllStockClassesIndexMethod,
    InternalAllStockClassesIndexErrorTypes
  >(
    (...args) => api.internalAllStockClassesIndex(...args),
    InternalAllStockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllStockClassesIndex'],
        ...apiQueryConfig.InternalAllStockClassesIndex,
        ...config?.queryConfig
      },
    },
    'InternalAllStockClassesIndex',
  );
};

export const useInternalAllStockClassesIndexInfinite = (
  variables: Parameters<InternalAllStockClassesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalAllStockClassesIndexMethod,
    InternalAllStockClassesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalAllStockClassesIndexMethod,
    InternalAllStockClassesIndexErrorTypes
  >(
    (...args) => api.internalAllStockClassesIndex(...args),
    InternalAllStockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllStockClassesIndex', 'InternalAllStockClassesIndexInfinite'],
        ...apiQueryConfig.InternalAllStockClassesIndex,
        ...config?.queryConfig,
      },
    },
    'InternalAllStockClassesIndex',
  );
};

export const useInternalAllStockClassesIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalAllStockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllStockClassesIndexMethod,
    InternalAllStockClassesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalAllStockClassesIndexMethod,
    InternalAllStockClassesIndexErrorTypes
  >(
    (...args) => api.internalAllStockClassesIndex(...args),
    InternalAllStockClassesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalAllStockClassesIndex'],
        ...apiQueryConfig.InternalAllStockClassesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalAllStockClassesIndex',
  );
};


export type CompanyStockClassesMethod = StockClassesApi['companyStockClasses'];
export type CompanyStockClassesResponseType = MethodResult<CompanyStockClassesMethod>;

export const useCompanyStockClasses = (
  variables: Parameters<CompanyStockClassesMethod>[0],
  config?: UseQueryConfig<
    CompanyStockClassesMethod,
    CompanyStockClassesErrorTypes
  >
) => {
  return useQuery<
    CompanyStockClassesMethod,
    CompanyStockClassesErrorTypes
  >(
    (...args) => api.companyStockClasses(...args),
    CompanyStockClassesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyStockClasses'],
        ...apiQueryConfig.CompanyStockClasses,
        ...config?.queryConfig
      },
    },
    'CompanyStockClasses',
  );
};

export const useCompanyStockClassesInfinite = (
  variables: Parameters<CompanyStockClassesMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyStockClassesMethod,
    CompanyStockClassesErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyStockClassesMethod,
    CompanyStockClassesErrorTypes
  >(
    (...args) => api.companyStockClasses(...args),
    CompanyStockClassesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyStockClasses', 'CompanyStockClassesInfinite'],
        ...apiQueryConfig.CompanyStockClasses,
        ...config?.queryConfig,
      },
    },
    'CompanyStockClasses',
  );
};

export const useCompanyStockClassesLazy = (baseOptions?: {
  variables?: Parameters<CompanyStockClassesMethod>[0],
  config?: UseQueryConfig<
    CompanyStockClassesMethod,
    CompanyStockClassesErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyStockClassesMethod,
    CompanyStockClassesErrorTypes
  >(
    (...args) => api.companyStockClasses(...args),
    CompanyStockClassesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyStockClasses'],
        ...apiQueryConfig.CompanyStockClasses,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyStockClasses',
  );
};

