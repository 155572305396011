import { matchPath } from 'react-router-dom';

import { IconWithActionItemsCount } from './IconWithActionItemsCount';
import { LabelWithActionItemsCount } from './LabelWithActionItemsCount';
import { type MenuItem, type MenuItemGroup } from './Navigation.types';

export const findSelectedRootItem = (
  menuItems: MenuItem[],
  parentPath: string | null | undefined
) => {
  if (!parentPath) {
    return null;
  }

  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i];
    if (!menuItem) continue;

    if (
      menuItem.key &&
      matchPath({ path: parentPath, end: false }, menuItem.key)
    ) {
      return menuItem.key;
    }

    const { children } = menuItem as MenuItemGroup;
    if (children) {
      const res = findSelectedRootItem(children, parentPath);
      if (res) {
        return res;
      }
    }
  }

  return null;
};

const isMenuItemGroup = (
  item: MenuItem | MenuItemGroup
): item is MenuItemGroup => (item as MenuItemGroup).children !== undefined;

export const mapMenuItemsWithActionItemsCount = (
  items: (MenuItem | MenuItemGroup)[],
  isCollapsed: boolean
): (MenuItem | MenuItemGroup)[] => {
  return items.reduce<(MenuItem | MenuItemGroup)[]>((acc, item) => {
    const childItems =
      isMenuItemGroup(item) && item.children
        ? mapMenuItemsWithActionItemsCount(item.children, isCollapsed)
        : [];

    const aggregateActionItemsCount = childItems.reduce(
      (sum, child) => sum + (child.actionItemsCount || 0),
      0
    );

    const shouldShowCount = isMenuItemGroup(item)
      ? aggregateActionItemsCount > 0
      : item.actionItemsCount && item.actionItemsCount > 0;

    const updatedItem: MenuItem | MenuItemGroup = {
      ...item,
      icon:
        isCollapsed && shouldShowCount ? (
          <IconWithActionItemsCount
            icon={item.icon}
            count={
              isMenuItemGroup(item)
                ? aggregateActionItemsCount
                : item.actionItemsCount
            }
          />
        ) : (
          item.icon
        ),
      label:
        !isCollapsed && shouldShowCount ? (
          <LabelWithActionItemsCount
            label={item.label}
            count={
              isMenuItemGroup(item)
                ? aggregateActionItemsCount
                : item.actionItemsCount
            }
          />
        ) : (
          item.label
        ),
      ...(isMenuItemGroup(item) && { children: childItems }),
    };

    return [...acc, updatedItem];
  }, []);
};
