import { useState } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  useAccountOnboardingAgreementShow,
  useMarketplaceAgreementSigning,
} from '@npm/data-access';

export const useMarketplaceAgreement = (
  accountId: number | undefined,
  isCompleted: boolean,
  callbackUrl?: string
) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { withShowApiErrorMiddleware } = useAlerts();

  const { data, isFetching: isLoading } = useAccountOnboardingAgreementShow(
    { id: accountId },
    {
      queryConfig: {
        enabled: !!accountId && isCompleted,
      },
    }
  );

  const { execute, isLoading: isOpenSignLoading } =
    useMarketplaceAgreementSigning();

  const handleOpenSign = async (roleId?: number) => {
    try {
      const response = await withShowApiErrorMiddleware(execute)({
        marketplaceAgreementSigningCreateRequestContract: {
          account_id: accountId,
          callback_url: callbackUrl ?? window.location.href,
        },
        ...(typeof roleId === 'number'
          ? { xUserRoleId: roleId.toString(), xAcrossAccounts: '0' }
          : {}),
      });

      if (response.recipient_view_uri) {
        setIsRedirecting(true);
        window.location.href = response.recipient_view_uri;
      } else {
        console.error("The recipient_view_uri wasn't provided");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    data,
    isLoading,
    isOpenSignLoading: isOpenSignLoading || isRedirecting,
    handleOpenSign,
  };
};
