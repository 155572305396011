import { ModalKey } from '@npm/core/ui/components/molecules/Modal';
import {
  CbInvestorLandingPage,
  CbRoleType,
  type UserRoleAggregate,
} from '@npm/data-access';

import { type UserContextStore } from '../auth/user/context';

export const LANDING_PAGE_CONFIG = {
  individualNpmsInvestor: '/second-market/investor/company-overview',
  individualNpmsBuyerInvestor: '/investor-workstation/individual-dashboard',
  entityNpmsInvestor: '/investor-workstation/entity-dashboard',
  investorSpouse: '/investor-workstation/account/agreements',
  otherInvestor: '/investor-workstation/programs/live-programs',
  unknownInvestor: '/investor-workstation', // if accounts are not loaded yet
  issuer: '/issuer-workstation/general/dashboard',
  issuerEventObserverWithActiveEvents:
    '/issuer-workstation/programs/active-programs',
  issuerEventObserverWithExpiredEvents:
    '/issuer-workstation/programs/expired-programs',
  brokerage: '/second-market',
  brokerageAccountManager: '/brokerage-workstation/holdings',
  advisor: '/advisor-workstation/dashboard',
  [CbInvestorLandingPage.items.live_programs]:
    '/investor-workstation/programs/live-programs',
  [CbInvestorLandingPage.items.matches]: '/investor-workstation/matches',
  [CbInvestorLandingPage.items.buyer_and_seller_personal_npms]:
    '/investor-workstation/individual-dashboard',
  [CbInvestorLandingPage.items.seller_personal_npms]:
    '/second-market/investor/company-overview',
  [CbInvestorLandingPage.items.entity_npms]:
    '/investor-workstation/entity-dashboard',
} as const;

export const getInvestorWorkstationLandingPage = (
  userContext: Partial<UserContextStore>,
  role: UserRoleAggregate,
  optInIfNoEvents?: boolean
) => {
  const investorLandingPage = userContext?.user?.investor_landing_page?.code;
  const allowSecondMarketOptin = userContext?.user?.allow_second_market_opt_in;
  const showOptIn = optInIfNoEvents && allowSecondMarketOptin;

  if (userContext.investorAccounts === undefined) {
    return LANDING_PAGE_CONFIG.unknownInvestor;
  }

  if (
    role?.role_name?.code === CbRoleType.items.SPOUSE ||
    userContext?.isSpouseRolesOnly
  ) {
    return LANDING_PAGE_CONFIG.investorSpouse;
  }

  const landingPage =
    LANDING_PAGE_CONFIG[
      investorLandingPage as keyof typeof LANDING_PAGE_CONFIG
    ] ?? LANDING_PAGE_CONFIG.otherInvestor;

  return showOptIn ? `${landingPage}?${ModalKey.OPT_IN_MODAL}=1` : landingPage;
};

export const getIssuerWorkstationLandingPage = (
  role: UserRoleAggregate,
  hasIssuerEventObserverActiveEvents: boolean
) => {
  const roleCode = role?.role_name?.code;

  if (roleCode === CbRoleType.items.ISSUER_EVENT_OBSERVER) {
    return hasIssuerEventObserverActiveEvents
      ? LANDING_PAGE_CONFIG.issuerEventObserverWithActiveEvents
      : LANDING_PAGE_CONFIG.issuerEventObserverWithExpiredEvents;
  } else {
    return LANDING_PAGE_CONFIG.issuer;
  }
};

export const getBrokerageWorkstationLandingPage = (
  role: UserRoleAggregate | null
) => {
  if (role?.role_name?.code === CbRoleType.items.ACCOUNT_MANAGER) {
    return LANDING_PAGE_CONFIG.brokerageAccountManager;
  }
  return LANDING_PAGE_CONFIG.brokerage;
};

export const getAdvisorWorkstationLandingPage = () => {
  return LANDING_PAGE_CONFIG.advisor;
};
