// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SCashAccountPartnerAccountPaymentInformationApi,
  S2SCashAccountPartnerPaymentInformationCreateErrorCodes,
  S2SCashAccountPartnerPaymentInformationDeleteErrorCodes,
  S2SCashAccountPartnerPaymentInformationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SCashAccountPartnerAccountPaymentInformationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SCashAccountPartnerPaymentInformationCreateMethod = S2SCashAccountPartnerAccountPaymentInformationApi['s2SCashAccountPartnerPaymentInformationCreate'];

export const useS2SCashAccountPartnerPaymentInformationCreate = () => {
  return useMutation<S2SCashAccountPartnerPaymentInformationCreateMethod>(
    (...args) => api.s2SCashAccountPartnerPaymentInformationCreate(...args),
    S2SCashAccountPartnerPaymentInformationCreateErrorCodes,
    'S2SCashAccountPartnerPaymentInformationCreate',
    '/api/s2s/userless/accounts/{account_npm_guid}/payment-information/cash-account-partner-payment-information',
    'post'
  );
};

export type S2SCashAccountPartnerPaymentInformationDeleteMethod = S2SCashAccountPartnerAccountPaymentInformationApi['s2SCashAccountPartnerPaymentInformationDelete'];

export const useS2SCashAccountPartnerPaymentInformationDelete = () => {
  return useMutation<S2SCashAccountPartnerPaymentInformationDeleteMethod>(
    (...args) => api.s2SCashAccountPartnerPaymentInformationDelete(...args),
    S2SCashAccountPartnerPaymentInformationDeleteErrorCodes,
    'S2SCashAccountPartnerPaymentInformationDelete',
    '/api/s2s/userless/accounts/{account_npm_guid}/payment-information/cash-account-partner-payment-information/{id}',
    'delete'
  );
};

export type S2SCashAccountPartnerPaymentInformationUpdateMethod = S2SCashAccountPartnerAccountPaymentInformationApi['s2SCashAccountPartnerPaymentInformationUpdate'];

export const useS2SCashAccountPartnerPaymentInformationUpdate = () => {
  return useMutation<S2SCashAccountPartnerPaymentInformationUpdateMethod>(
    (...args) => api.s2SCashAccountPartnerPaymentInformationUpdate(...args),
    S2SCashAccountPartnerPaymentInformationUpdateErrorCodes,
    'S2SCashAccountPartnerPaymentInformationUpdate',
    '/api/s2s/userless/accounts/{account_npm_guid}/payment-information/cash-account-partner-payment-information/{id}',
    'patch'
  );
};
