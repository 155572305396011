import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  accreditation_level: 'accreditation_level',
  entity_assets: 'entity_assets',
  entity_assets_rep: 'entity_assets_rep',
  entity_assets_rep_investment: 'entity_assets_rep_investment',
  entity_assets_rep_erisa: 'entity_assets_rep_erisa',
  entity_investments_owned: 'entity_investments_owned',
  entity_formed_to_acquire_securities: 'entity_formed_to_acquire_securities',
  entity_equity_owner_accredited: 'entity_equity_owner_accredited',
  entity_investment_decisions: 'entity_investment_decisions',
  entity_investors_directed: 'entity_investors_directed',
  entity_rep_title: 'entity_rep_title',
  securities_license: 'securities_license',
  signature: 'signature',
  qp_entity_rep_title: 'qp_entity_rep_title',
  qp_signature: 'qp_signature',
  qib_entity_rep_title: 'qib_entity_rep_title',
  qib_signature: 'qib_signature',
  qc_entity_rep_title: 'qc_entity_rep_title',
  qc_signature: 'qc_signature',
  family_company: 'family_company',
  income: 'income',
  income0: 'income0',
  income1: 'income1',
  income2: 'income2',
  min_investor_status: 'min_investor_status',
  net_worth: 'net_worth',
  net_worth_filing: 'net_worth_filing',
  org_type: 'org_type',
  qualified_purchaser: 'qualified_purchaser',
  qualified_institutional_buyer_assets: 'qualified_institutional_buyer_assets',
  qualified_institutional_buyer: 'qualified_institutional_buyer',
  qualified_client: 'qualified_client',
  entity_total_assets: 'entity_total_assets',
  reset_form: 'reset_form',
  other_type: 'other_type',
  total_assets: 'total_assets',
  total_investments: 'total_investments',
  total_liabilities: 'total_liabilities',
} as const;

export type AccreditationLabelType = keyof typeof Items;

type AttributeName =
  | 'active'
  | 'answer_set'
  | 'name'
  | 'question'
  | 'entity'
  | 'individual'
  | 'help'
  | 'help_link'
  | 'disable'
  | 'ordered_by';


export const CbAccreditationLabel = {
  code: Codebooks.ACCREDITATION_LABEL,
  items: Items,
  getAttributeValueByName: (item: CodebookItem, name: AttributeName) =>
    item.attributes?.find(q => q.name === name)?.value,
};
