// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SIssuerCapTablePartnerConfigsApi,
  S2SIssuerCapTablePartnerConfigsIndexErrorCodes,
  S2SIssuerCapTablePartnerConfigsIndexErrorTypes,
  S2SIssuerCapTablePartnerConfigsUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SIssuerCapTablePartnerConfigsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SIssuerCapTablePartnerConfigsIndexMethod = S2SIssuerCapTablePartnerConfigsApi['s2SIssuerCapTablePartnerConfigsIndex'];
export type S2SIssuerCapTablePartnerConfigsIndexResponseType = MethodResult<S2SIssuerCapTablePartnerConfigsIndexMethod>;

export const useS2SIssuerCapTablePartnerConfigsIndex = (
  variables: Parameters<S2SIssuerCapTablePartnerConfigsIndexMethod>[0],
  config?: UseQueryConfig<
    S2SIssuerCapTablePartnerConfigsIndexMethod,
    S2SIssuerCapTablePartnerConfigsIndexErrorTypes
  >
) => {
  return useQuery<
    S2SIssuerCapTablePartnerConfigsIndexMethod,
    S2SIssuerCapTablePartnerConfigsIndexErrorTypes
  >(
    (...args) => api.s2SIssuerCapTablePartnerConfigsIndex(...args),
    S2SIssuerCapTablePartnerConfigsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SIssuerCapTablePartnerConfigsIndex'],
        ...apiQueryConfig.S2SIssuerCapTablePartnerConfigsIndex,
        ...config?.queryConfig
      },
    },
    'S2SIssuerCapTablePartnerConfigsIndex',
  );
};

export const useS2SIssuerCapTablePartnerConfigsIndexInfinite = (
  variables: Parameters<S2SIssuerCapTablePartnerConfigsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    S2SIssuerCapTablePartnerConfigsIndexMethod,
    S2SIssuerCapTablePartnerConfigsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    S2SIssuerCapTablePartnerConfigsIndexMethod,
    S2SIssuerCapTablePartnerConfigsIndexErrorTypes
  >(
    (...args) => api.s2SIssuerCapTablePartnerConfigsIndex(...args),
    S2SIssuerCapTablePartnerConfigsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SIssuerCapTablePartnerConfigsIndex', 'S2SIssuerCapTablePartnerConfigsIndexInfinite'],
        ...apiQueryConfig.S2SIssuerCapTablePartnerConfigsIndex,
        ...config?.queryConfig,
      },
    },
    'S2SIssuerCapTablePartnerConfigsIndex',
  );
};

export const useS2SIssuerCapTablePartnerConfigsIndexLazy = (baseOptions?: {
  variables?: Parameters<S2SIssuerCapTablePartnerConfigsIndexMethod>[0],
  config?: UseQueryConfig<
    S2SIssuerCapTablePartnerConfigsIndexMethod,
    S2SIssuerCapTablePartnerConfigsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    S2SIssuerCapTablePartnerConfigsIndexMethod,
    S2SIssuerCapTablePartnerConfigsIndexErrorTypes
  >(
    (...args) => api.s2SIssuerCapTablePartnerConfigsIndex(...args),
    S2SIssuerCapTablePartnerConfigsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S2SIssuerCapTablePartnerConfigsIndex'],
        ...apiQueryConfig.S2SIssuerCapTablePartnerConfigsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S2SIssuerCapTablePartnerConfigsIndex',
  );
};


export type S2SIssuerCapTablePartnerConfigsUpdateMethod = S2SIssuerCapTablePartnerConfigsApi['s2SIssuerCapTablePartnerConfigsUpdate'];

export const useS2SIssuerCapTablePartnerConfigsUpdate = () => {
  return useMutation<S2SIssuerCapTablePartnerConfigsUpdateMethod>(
    (...args) => api.s2SIssuerCapTablePartnerConfigsUpdate(...args),
    S2SIssuerCapTablePartnerConfigsUpdateErrorCodes,
    'S2SIssuerCapTablePartnerConfigsUpdate',
    '/api/s2s/issuers/{issuer_npm_guid}/cap_table_partner_configs/{partner_name}',
    'patch'
  );
};
