import React, { useState } from 'react';

import { type CheckboxProps, type RadioProps } from 'antd';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { Heading, Text } from '../Typography';

import * as S from './ButtonCard.styles';

export type TooltipProps = {
  text: string;
  hideTitle?: boolean;
};

interface DefaultProps {
  label: React.ReactNode;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  tooltip?: TooltipProps;
  // shows standard radio or checkbox (non-card) if set to true
  showDefaultInput?: boolean;
  defaultInputOnMobile?: boolean;
}

export interface RadioButtonCardProps extends DefaultProps, RadioProps {
  variant: 'radio';
}

export interface CheckboxButtonCardProps extends DefaultProps, CheckboxProps {
  variant: 'checkbox';
}

type ButtonCardProps = RadioButtonCardProps | CheckboxButtonCardProps;

export const ButtonCard = ({
  label,
  icon,
  description,
  variant,
  tooltip,
  showDefaultInput,
  defaultInputOnMobile,
  ...rest
}: ButtonCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile, isTablet } = useBreakpoints();

  const shouldShowModal = (isMobile || isTablet) && !!tooltip?.text;
  const isPlain = !icon && !description;

  const renderFullContent = () => (
    <>
      {icon && <S.Icon>{icon}</S.Icon>}
      <Heading variant="h2" marginBottom="sm">
        {label}
      </Heading>
      {description && <Text size="sm">{description}</Text>}
    </>
  );

  const renderPlainContent = () => (
    <S.PlainContentWrapper
      onClick={e => {
        if (shouldShowModal) {
          e.preventDefault();
          setIsModalOpen(true);
        }
      }}
    >
      <Text size="sm" colorVariant="primary">
        {label}
      </Text>
      {!!tooltip?.text && (
        <Tooltip
          //  ensures tooltip is not scrolled with the element (autoscrolling forms)
          getPopupContainer={triggerNode =>
            triggerNode.parentElement ?? document.body
          }
          placement="bottom"
          title={
            <>
              {!tooltip?.hideTitle && (
                <Heading variant="h3" marginBottom="sm">
                  {label}
                </Heading>
              )}
              <Text size="sm">{tooltip?.text}</Text>
            </>
          }
        >
          <S.InfoIconWrap>
            <Icon name="info-circle" size="xs" />
          </S.InfoIconWrap>
        </Tooltip>
      )}
    </S.PlainContentWrapper>
  );

  const content = (
    <S.ContentWrapper>
      {isPlain ? renderPlainContent() : renderFullContent()}
    </S.ContentWrapper>
  );

  if ((defaultInputOnMobile && isMobile) || showDefaultInput) {
    return variant === 'radio' ? (
      <S.Radio {...rest} label={label || description} />
    ) : (
      <S.Checkbox {...rest} label={label || description} />
    );
  }

  const card =
    variant === 'radio' ? (
      <S.RadioButtonCard
        $isPlain={isPlain}
        {...rest}
        label={''}
        data-cy={rest['data-cy'] || 'radio-button-card'}
      >
        {content}
      </S.RadioButtonCard>
    ) : (
      <S.CheckboxButtonCard
        $isPlain={isPlain}
        {...rest}
        label={''}
        data-cy={rest['data-cy'] || 'checkbox-button-card'}
      >
        {content}
      </S.CheckboxButtonCard>
    );

  return (
    <>
      {card}
      {shouldShowModal && (
        <S.Modal
          open={isModalOpen}
          title={label}
          okText="Understood"
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
        >
          {tooltip?.text}
        </S.Modal>
      )}
    </>
  );
};
