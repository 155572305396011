import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { ReadOnly as ReadOnlyBase } from '@npm/core/ui/components/molecules/ReadOnly';
import { maskCharacter } from '@npm/core/ui/utils/maskCharacters';
import { type PaymentInformation } from '@npm/data-access';

import * as S from './ReadOnly.styles';
import { prepareReadOnlyData } from './ReadOnly.utils';

type Props = {
  data: PaymentInformation[];
  onAddNew?: () => void;
};

export const BankAccountInformationReadOnly = ({ data, onAddNew }: Props) => {
  return (
    <>
      {data?.map(paymentInfo => {
        return (
          <S.Card key={paymentInfo?.id}>
            <Heading variant="h3" marginBottom="lg">
              {paymentInfo.bank_account?.name} -{' '}
              {paymentInfo.bank_account?.beneficiary_name} (
              {maskCharacter(paymentInfo.bank_account?.account_number, '*', 4)})
            </Heading>
            <ReadOnlyBase items={prepareReadOnlyData(paymentInfo)} />
          </S.Card>
        );
      })}
      {onAddNew && (
        <Button
          variant="outline"
          icon={<Icon name="plus" size="xs" />}
          onClick={onAddNew}
        >
          Add new bank account
        </Button>
      )}
    </>
  );
};
