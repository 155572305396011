import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { type Holding } from '@npm/data-access';

import { getHoldingIdInfo } from '../../../holdings/Holdings.utils';

type Props = {
  holding: Holding;
};

export const SelectedHoldingOption = ({ holding }: Props) => {
  const { isMissingId, idLabel } = getHoldingIdInfo(holding, false);

  return (
    <Flex align="center">
      <Margin right="sm">
        {holding.asset?.type?.name}
        {holding.underlying_holding_type
          ? ` - ${holding.underlying_holding_type.name}`
          : ''}
      </Margin>
      <Label variant={isMissingId ? 'warning' : 'info'}>{idLabel}</Label>
    </Flex>
  );
};
