/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalExecutiveCreateRequestContract } from '../model';
// @ts-ignore
import { InternalExecutiveIndex } from '../model';
// @ts-ignore
import { InternalExecutiveShow } from '../model';
// @ts-ignore
import { InternalExecutiveUpdateRequestContract } from '../model';
/**
 * ExecutivesApi - axios parameter creator
 * @export
 */
export const ExecutivesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete an Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteExecutive: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDeleteExecutive', 'id', id)
            const localVarPath = changeUrl(`/api/internal/executives/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an Executive
         * @param {number} companyId 
         * @param {InternalExecutiveCreateRequestContract} internalExecutiveCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalExecutiveCreate: async (companyId: number, internalExecutiveCreateRequestContract: InternalExecutiveCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalExecutiveCreate', 'companyId', companyId)
            // verify required parameter 'internalExecutiveCreateRequestContract' is not null or undefined
            assertParamExists('internalExecutiveCreate', 'internalExecutiveCreateRequestContract', internalExecutiveCreateRequestContract)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/executives`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalExecutiveCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get executives for a company
         * @param {number} companyId 
         * @param {string} [sortBy] 
         * @param {string} [sortDir] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalExecutiveIndex: async (companyId: number, sortBy?: string, sortDir?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalExecutiveIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/executives`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort_dir'] = sortDir;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get information for a Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowExecutive: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalShowExecutive', 'id', id)
            const localVarPath = changeUrl(`/api/internal/executives/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalExecutiveUpdateRequestContract} [internalExecutiveUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateExecutive: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalExecutiveUpdateRequestContract?: InternalExecutiveUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateExecutive', 'id', id)
            const localVarPath = changeUrl(`/api/internal/executives/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalExecutiveUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExecutivesApi - functional programming interface
 * @export
 */
export const ExecutivesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExecutivesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete an Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDeleteExecutive(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDeleteExecutive(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create an Executive
         * @param {number} companyId 
         * @param {InternalExecutiveCreateRequestContract} internalExecutiveCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalExecutiveCreate(companyId: number, internalExecutiveCreateRequestContract: InternalExecutiveCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalExecutiveCreate(companyId, internalExecutiveCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get executives for a company
         * @param {number} companyId 
         * @param {string} [sortBy] 
         * @param {string} [sortDir] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalExecutiveIndex(companyId: number, sortBy?: string, sortDir?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalExecutiveIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalExecutiveIndex(companyId, sortBy, sortDir, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get information for a Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalShowExecutive(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalExecutiveShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalShowExecutive(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalExecutiveUpdateRequestContract} [internalExecutiveUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateExecutive(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalExecutiveUpdateRequestContract?: InternalExecutiveUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateExecutive(id, xNPMAuthorization, xSubscriptionPlan, internalExecutiveUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExecutivesApi - factory interface
 * @export
 */
export const ExecutivesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExecutivesApiFp(configuration)
    return {
        /**
         * 
         * @summary delete an Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteExecutive(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalDeleteExecutive(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an Executive
         * @param {number} companyId 
         * @param {InternalExecutiveCreateRequestContract} internalExecutiveCreateRequestContract 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalExecutiveCreate(companyId: number, internalExecutiveCreateRequestContract: InternalExecutiveCreateRequestContract, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalExecutiveCreate(companyId, internalExecutiveCreateRequestContract, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get executives for a company
         * @param {number} companyId 
         * @param {string} [sortBy] 
         * @param {string} [sortDir] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalExecutiveIndex(companyId: number, sortBy?: string, sortDir?: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalExecutiveIndex> {
            return localVarFp.internalExecutiveIndex(companyId, sortBy, sortDir, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get information for a Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalShowExecutive(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalExecutiveShow> {
            return localVarFp.internalShowExecutive(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an Executive
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {InternalExecutiveUpdateRequestContract} [internalExecutiveUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateExecutive(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, internalExecutiveUpdateRequestContract?: InternalExecutiveUpdateRequestContract, options?: any): AxiosPromise<void> {
            return localVarFp.internalUpdateExecutive(id, xNPMAuthorization, xSubscriptionPlan, internalExecutiveUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalDeleteExecutive operation in ExecutivesApi.
 * @export
 * @interface ExecutivesApiInternalDeleteExecutiveRequest
 */
export interface ExecutivesApiInternalDeleteExecutiveRequest {
    /**
     * 
     * @type {number}
     * @memberof ExecutivesApiInternalDeleteExecutive
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalDeleteExecutive
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalDeleteExecutive
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalExecutiveCreate operation in ExecutivesApi.
 * @export
 * @interface ExecutivesApiInternalExecutiveCreateRequest
 */
export interface ExecutivesApiInternalExecutiveCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof ExecutivesApiInternalExecutiveCreate
     */
    readonly companyId: number

    /**
     * 
     * @type {InternalExecutiveCreateRequestContract}
     * @memberof ExecutivesApiInternalExecutiveCreate
     */
    readonly internalExecutiveCreateRequestContract: InternalExecutiveCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalExecutiveCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalExecutiveCreate
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalExecutiveIndex operation in ExecutivesApi.
 * @export
 * @interface ExecutivesApiInternalExecutiveIndexRequest
 */
export interface ExecutivesApiInternalExecutiveIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof ExecutivesApiInternalExecutiveIndex
     */
    readonly companyId: number

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalExecutiveIndex
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalExecutiveIndex
     */
    readonly sortDir?: string

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalExecutiveIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalExecutiveIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalShowExecutive operation in ExecutivesApi.
 * @export
 * @interface ExecutivesApiInternalShowExecutiveRequest
 */
export interface ExecutivesApiInternalShowExecutiveRequest {
    /**
     * 
     * @type {number}
     * @memberof ExecutivesApiInternalShowExecutive
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalShowExecutive
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalShowExecutive
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUpdateExecutive operation in ExecutivesApi.
 * @export
 * @interface ExecutivesApiInternalUpdateExecutiveRequest
 */
export interface ExecutivesApiInternalUpdateExecutiveRequest {
    /**
     * 
     * @type {number}
     * @memberof ExecutivesApiInternalUpdateExecutive
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalUpdateExecutive
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof ExecutivesApiInternalUpdateExecutive
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {InternalExecutiveUpdateRequestContract}
     * @memberof ExecutivesApiInternalUpdateExecutive
     */
    readonly internalExecutiveUpdateRequestContract?: InternalExecutiveUpdateRequestContract
}

/**
 * ExecutivesApi - object-oriented interface
 * @export
 * @class ExecutivesApi
 * @extends {BaseAPI}
 */
export class ExecutivesApi extends BaseAPI {
    /**
     * 
     * @summary delete an Executive
     * @param {ExecutivesApiInternalDeleteExecutiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExecutivesApi
     */
    public internalDeleteExecutive(requestParameters: ExecutivesApiInternalDeleteExecutiveRequest, options?: AxiosRequestConfig) {
        return ExecutivesApiFp(this.configuration).internalDeleteExecutive(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an Executive
     * @param {ExecutivesApiInternalExecutiveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExecutivesApi
     */
    public internalExecutiveCreate(requestParameters: ExecutivesApiInternalExecutiveCreateRequest, options?: AxiosRequestConfig) {
        return ExecutivesApiFp(this.configuration).internalExecutiveCreate(requestParameters.companyId, requestParameters.internalExecutiveCreateRequestContract, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get executives for a company
     * @param {ExecutivesApiInternalExecutiveIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExecutivesApi
     */
    public internalExecutiveIndex(requestParameters: ExecutivesApiInternalExecutiveIndexRequest, options?: AxiosRequestConfig) {
        return ExecutivesApiFp(this.configuration).internalExecutiveIndex(requestParameters.companyId, requestParameters.sortBy, requestParameters.sortDir, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get information for a Executive
     * @param {ExecutivesApiInternalShowExecutiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExecutivesApi
     */
    public internalShowExecutive(requestParameters: ExecutivesApiInternalShowExecutiveRequest, options?: AxiosRequestConfig) {
        return ExecutivesApiFp(this.configuration).internalShowExecutive(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an Executive
     * @param {ExecutivesApiInternalUpdateExecutiveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExecutivesApi
     */
    public internalUpdateExecutive(requestParameters: ExecutivesApiInternalUpdateExecutiveRequest, options?: AxiosRequestConfig) {
        return ExecutivesApiFp(this.configuration).internalUpdateExecutive(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.internalExecutiveUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalDeleteExecutiveErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDeleteExecutiveErrorCodes = [
    401,
    404,
];

export type InternalExecutiveCreateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalExecutiveCreateErrorCodes = [
    401,
    404,
];

export type InternalExecutiveIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalExecutiveIndexErrorCodes = [
    401,
    404,
];

export type InternalShowExecutiveErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalShowExecutiveErrorCodes = [
    401,
    404,
];

export type InternalUpdateExecutiveErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateExecutiveErrorCodes = [
    401,
    404,
];


