import { useCallback, useMemo, useState } from 'react';
import { ZendeskAPI } from 'react-zendesk';

import { useLocation } from '@npm/core/ui/components/molecules/Link';
import { CbWorkstationType } from '@npm/data-access';
import { DatadogService } from '@npm/utils';
import axios from 'axios';

import { useUserContextStore } from '../../../auth/user/context';
import { useCurrentWorkstation, useObo } from '../../../auth/user/role';
import { useCurrentRole } from '../../../auth/user/role/hooks/useCurrentRole';

import { ZENDESK_API_URL } from './zendesk.env';
import { createZendeskStore } from './zendesk.store';
import {
  type CreateCustomerResult,
  type ZendeskTicketGroupID,
} from './zendesk.types';
import {
  getCustomerRequestSubject,
  getEncodedCustomerRequestAuthToken,
  getParsedPageName,
  getTicketCustomFields,
  getTicketTags,
} from './zendesk.utils';

export const useZendesk = () => {
  const isEnabled = createZendeskStore(s => s.isEnabled);
  const isOpen = createZendeskStore(s => s.isOpen);
  const isInitialized = createZendeskStore(s => s.isInitialized);
  const unreadMessageCount = createZendeskStore(s => s.unreadMessageCount);

  const role = useCurrentRole();
  const account = role?.subject;
  const { isObo, oboAccount } = useObo();
  const workstation = useCurrentWorkstation();
  const location = useLocation();

  const user = useUserContextStore(store => store.user);
  const parsedAccount = useMemo(() => {
    const isInv = workstation?.type?.code === CbWorkstationType.items.investor;
    const isNotInvOrOBO = !isInv && !isObo;
    const isNotAccountcentricInv = isInv && !account;

    if (isNotInvOrOBO || isNotAccountcentricInv) {
      return { name: '', external_id: '' };
    }

    return oboAccount || account;
  }, [account, oboAccount, workstation]);

  const [createRequestLoading, setCreateRequestLoading] = useState(false);

  const toggle = useCallback(() => {
    if (isEnabled) {
      ZendeskAPI('messenger', isOpen ? 'close' : 'open');
    }
  }, [isOpen, isEnabled]);

  const createCustomerRequest = useCallback(
    async (
      zendeskTicketGroupId: ZendeskTicketGroupID,
      message: string,
      extraFields?: Parameters<typeof getTicketCustomFields>[4],
      customSubject?: string
    ): Promise<CreateCustomerResult> => {
      setCreateRequestLoading(true);
      const result: CreateCustomerResult = {
        success: false,
        message: 'Support ticket could not be created',
      };

      try {
        const res = await axios({
          url: `${ZENDESK_API_URL}/api/v2/requests.json`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${getEncodedCustomerRequestAuthToken(
              user?.email,
              user?.zendesk_jwt
            )}`,
          },
          method: 'POST',
          data: JSON.stringify({
            request: {
              subject:
                customSubject ??
                getCustomerRequestSubject(zendeskTicketGroupId),
              comment: {
                body: message,
              },
              requester: {
                name: `${user?.person?.first} ${user?.person?.last}`,
                email: user?.email,
              },
              custom_fields: getTicketCustomFields(
                user?.person?.email,
                location?.pathname,
                workstation?.type?.code,
                parsedAccount,
                extraFields
              ),
              tags: getTicketTags(zendeskTicketGroupId, location?.pathname),
            },
          }),
        });

        if (res.status === 201) {
          result.success = true;
          result.message = 'Support ticket was successfully created';

          DatadogService.addAction('Zendesk Ticket Created', {
            ticket_group_id: zendeskTicketGroupId,
          });
        }
        return result;
      } catch (e) {
        console.log(e);
        return result;
      } finally {
        setCreateRequestLoading(false);
      }
    },
    [user, location, workstation, parsedAccount]
  );

  return {
    isEnabled: isEnabled && isInitialized,
    unreadMessageCount,
    user,
    account: parsedAccount,
    workstation,
    pageUrl: location?.pathname,
    pageName: getParsedPageName(location?.pathname),
    createRequestLoading,
    toggle,
    createCustomerRequest,
  };
};
