import React, { useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import {
  CbAccountSponsorshipType,
  type CodebookItem,
  type SecondmarketOrderItemShow,
} from '@npm/data-access';

import { RequestMoreInformationButton } from '../../../../components';

import {
  InvestorL2MarketOrderActions,
  type MarketOrderActionsProps,
} from './InvestorL2MarketOrderActions';

type Props = Pick<
  MarketOrderActionsProps,
  | 'orderItem'
  | 'handleShowNegotiations'
  | 'handleStartNegotiation'
  | 'buttonSize'
> & {
  orderItem: SecondmarketOrderItemShow;
  sponsorshipType: CodebookItem;
  handleShowOrderDetails: (orderItem: SecondmarketOrderItemShow) => void;
};

export const InvestorMarketOrderActions = ({
  orderItem,
  sponsorshipType,
  ...props
}: Props) => {
  const [isGoingToDetail, setIsGoingToDetail] = useState(false);

  const handleDetailClick = async () => {
    try {
      setIsGoingToDetail(true);
      await props.handleShowOrderDetails(orderItem);
    } finally {
      setIsGoingToDetail(false);
    }
  };

  if (CbAccountSponsorshipType.isSponsoredAccountMinLvl2(sponsorshipType)) {
    return (
      <InvestorL2MarketOrderActions
        orderItem={orderItem}
        {...props}
        isGoingToOrderDetail={isGoingToDetail}
        handleShowOrderDetails={handleDetailClick}
      />
    );
  }

  if (orderItem.account) {
    return (
      <Button
        variant="outline"
        block
        onClick={handleDetailClick}
        loading={isGoingToDetail}
      >
        Show Details
      </Button>
    );
  }
  return <RequestMoreInformationButton orderItem={orderItem} />;
};
