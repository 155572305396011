// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AssetDetailsApi,
  AssetDetailsIndexErrorCodes,
  AssetDetailsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AssetDetailsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AssetDetailsIndexMethod = AssetDetailsApi['assetDetailsIndex'];
export type AssetDetailsIndexResponseType = MethodResult<AssetDetailsIndexMethod>;

export const useAssetDetailsIndex = (
  variables: Parameters<AssetDetailsIndexMethod>[0],
  config?: UseQueryConfig<
    AssetDetailsIndexMethod,
    AssetDetailsIndexErrorTypes
  >
) => {
  return useQuery<
    AssetDetailsIndexMethod,
    AssetDetailsIndexErrorTypes
  >(
    (...args) => api.assetDetailsIndex(...args),
    AssetDetailsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AssetDetailsIndex'],
        ...apiQueryConfig.AssetDetailsIndex,
        ...config?.queryConfig
      },
    },
    'AssetDetailsIndex',
  );
};

export const useAssetDetailsIndexInfinite = (
  variables: Parameters<AssetDetailsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AssetDetailsIndexMethod,
    AssetDetailsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AssetDetailsIndexMethod,
    AssetDetailsIndexErrorTypes
  >(
    (...args) => api.assetDetailsIndex(...args),
    AssetDetailsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AssetDetailsIndex', 'AssetDetailsIndexInfinite'],
        ...apiQueryConfig.AssetDetailsIndex,
        ...config?.queryConfig,
      },
    },
    'AssetDetailsIndex',
  );
};

export const useAssetDetailsIndexLazy = (baseOptions?: {
  variables?: Parameters<AssetDetailsIndexMethod>[0],
  config?: UseQueryConfig<
    AssetDetailsIndexMethod,
    AssetDetailsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AssetDetailsIndexMethod,
    AssetDetailsIndexErrorTypes
  >(
    (...args) => api.assetDetailsIndex(...args),
    AssetDetailsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AssetDetailsIndex'],
        ...apiQueryConfig.AssetDetailsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AssetDetailsIndex',
  );
};

