import React from 'react';

import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type DocumentAggregate,
  type DocumentSimple,
  type FolderAggregate,
} from '@npm/data-access';

import { type EditDocumentFormValues } from '../EditDocumentDrawer';
import { type EditFolderFormValues } from '../EditFolderDrawer';

import * as S from './FileExporer.styles';
import { DocumentRow } from './DocumentRow';
import { FolderRow } from './FolderRow';

type Props = {
  folder?: FolderAggregate;
  isFolderEditable?: boolean;
  useEditableProp?: boolean;
  onFolderClick?: (id: number) => void;
  onDownloadClick?: (id: number, fileName: string) => void;
  onDocumentEdit?: (
    id: number,
    values: EditDocumentFormValues
  ) => Promise<void>;
  onDocumentDelete?: (id: number) => void;
  onDocumentClick?: (doc: DocumentSimple) => void;
  onFolderEdit?: (id: number, values: EditFolderFormValues) => Promise<void>;
  onFolderDelete?: (id: number) => void;
  isLoading?: boolean;
  actionsDisabled?: boolean;
};

export const FileExplorer = ({
  folder = { id: null },
  isFolderEditable,
  useEditableProp,
  onFolderClick,
  onDownloadClick,
  onDocumentEdit,
  onDocumentDelete,
  onFolderEdit,
  onFolderDelete,
  onDocumentClick,
  isLoading,
  actionsDisabled,
}: Props) => {
  const { folders } = folder;
  const documents = folder.documents as DocumentAggregate[];

  const isEmpty = !folders?.length && !documents?.length;

  return (
    <S.Card dataCy={CypressDataIds.DataRooms.FileExplorer.Card}>
      <S.HeaderContainer>
        <Text size="sm" colorVariant="primary">
          Name
        </Text>
      </S.HeaderContainer>
      {isEmpty || isLoading ? (
        <NoDataArea
          loading={isLoading}
          title={'There are no documents in this folder.'}
        />
      ) : (
        <>
          {folders.map(folder => (
            <FolderRow
              key={folder.id}
              folder={folder}
              isFolderEditable={isFolderEditable}
              onFolderClick={onFolderClick}
              onFolderEdit={onFolderEdit}
              onFolderDelete={onFolderDelete}
              actionsDisabled={actionsDisabled}
            />
          ))}
          {documents.map(document => (
            <DocumentRow
              key={document.id}
              document={document}
              isFolderEditable={isFolderEditable}
              useEditableProp={useEditableProp}
              onDownloadClick={onDownloadClick}
              onDocumentEdit={onDocumentEdit}
              onDocumentDelete={onDocumentDelete}
              onDocumentClick={onDocumentClick}
              actionsDisabled={actionsDisabled}
            />
          ))}
        </>
      )}
    </S.Card>
  );
};
