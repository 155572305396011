import React, { memo, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

import { PageTitle } from '@npm/core/ui/components/molecules/PageHeader/PageTitle/PageTitle';
import { DarkModeSwitcher } from '@npm/core/ui/components/organisms/DarkModeSwitcher';
import {
  Content,
  SidebarLayout,
} from '@npm/core/ui/components/organisms/Layout';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import {
  RoleSwitcher,
  useUserContextStore,
  WorkstationSwitcher,
} from '@npm/features/auth/user/context';
import { OboButton } from '@npm/features/auth/user/context/Obo/OboButton';
import { useObo } from '@npm/features/auth/user/role';
import { useLogoRedirectLink } from '@npm/features/logo';

import { OptIn } from '../../features/optIn/OptIn';

import { InvestorMenu } from './InvestorMenu';

type Props = {
  children: React.ReactNode;
  showPageHeader?: boolean;
  showHamburgerMenu?: boolean;
  collapsible?: boolean;
};

export const InvestorLayout = memo(function InvestorLayout({
  children,
  showPageHeader = true,
  showHamburgerMenu = true,
  collapsible,
}: Props) {
  const {
    isNewUser,
    isEntityNpmsInvestor,
    isIndividualNpmsInvestor,
    isNpmSettlementInvestor,
  } = useUserContextStore(store => store);

  const { isObo } = useObo();
  const { route } = useCurrentRoute();

  const logoLinkUrl = useLogoRedirectLink();

  const renderMenu = useCallback(
    (isSidebarCollapsed: boolean) => (
      <>
        <WorkstationSwitcher isCollapsed={isSidebarCollapsed} />
        {isObo ? (
          <OboButton isCollapsed={isSidebarCollapsed} />
        ) : (
          <RoleSwitcher isCollapsed={isSidebarCollapsed} />
        )}
        <InvestorMenu />
        <DarkModeSwitcher isCollapsed={isSidebarCollapsed} />
      </>
    ),
    [isObo]
  );

  const content = (
    <>
      {children}
      <Outlet />
    </>
  );

  if (route?.layout?.noLayout) {
    return <>{content}</>;
  }

  return (
    <>
      <PageTitle />
      <SidebarLayout
        logoLinkUrl={logoLinkUrl}
        renderMenu={renderMenu}
        mobileMenuType={isNewUser ? 'basic' : null}
        showHamburgerMenu={
          route?.layout?.noHamburgerMenu != null
            ? !route.layout.noHamburgerMenu
            : showHamburgerMenu
        }
        collapsible={route?.layout?.sidebarCollapsible ?? collapsible}
      >
        <Content
          showPageHeader={
            route?.layout?.noPageHeader === true ? false : showPageHeader
          }
        >
          {content}
        </Content>
        {!isObo &&
          !isEntityNpmsInvestor &&
          !isIndividualNpmsInvestor &&
          isNpmSettlementInvestor && <OptIn />}
      </SidebarLayout>
    </>
  );
});
