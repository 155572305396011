// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  RecommendationApi,
  HoldingRecommendationIndexErrorCodes,
  HoldingRecommendationIndexErrorTypes,
  WatchlistRecommendationIndexErrorCodes,
  WatchlistRecommendationIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new RecommendationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type HoldingRecommendationIndexMethod = RecommendationApi['holdingRecommendationIndex'];
export type HoldingRecommendationIndexResponseType = MethodResult<HoldingRecommendationIndexMethod>;

export const useHoldingRecommendationIndex = (
  variables: Parameters<HoldingRecommendationIndexMethod>[0],
  config?: UseQueryConfig<
    HoldingRecommendationIndexMethod,
    HoldingRecommendationIndexErrorTypes
  >
) => {
  return useQuery<
    HoldingRecommendationIndexMethod,
    HoldingRecommendationIndexErrorTypes
  >(
    (...args) => api.holdingRecommendationIndex(...args),
    HoldingRecommendationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingRecommendationIndex'],
        ...apiQueryConfig.HoldingRecommendationIndex,
        ...config?.queryConfig
      },
    },
    'HoldingRecommendationIndex',
  );
};

export const useHoldingRecommendationIndexInfinite = (
  variables: Parameters<HoldingRecommendationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    HoldingRecommendationIndexMethod,
    HoldingRecommendationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    HoldingRecommendationIndexMethod,
    HoldingRecommendationIndexErrorTypes
  >(
    (...args) => api.holdingRecommendationIndex(...args),
    HoldingRecommendationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['HoldingRecommendationIndex', 'HoldingRecommendationIndexInfinite'],
        ...apiQueryConfig.HoldingRecommendationIndex,
        ...config?.queryConfig,
      },
    },
    'HoldingRecommendationIndex',
  );
};

export const useHoldingRecommendationIndexLazy = (baseOptions?: {
  variables?: Parameters<HoldingRecommendationIndexMethod>[0],
  config?: UseQueryConfig<
    HoldingRecommendationIndexMethod,
    HoldingRecommendationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    HoldingRecommendationIndexMethod,
    HoldingRecommendationIndexErrorTypes
  >(
    (...args) => api.holdingRecommendationIndex(...args),
    HoldingRecommendationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['HoldingRecommendationIndex'],
        ...apiQueryConfig.HoldingRecommendationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'HoldingRecommendationIndex',
  );
};


export type WatchlistRecommendationIndexMethod = RecommendationApi['watchlistRecommendationIndex'];
export type WatchlistRecommendationIndexResponseType = MethodResult<WatchlistRecommendationIndexMethod>;

export const useWatchlistRecommendationIndex = (
  variables: Parameters<WatchlistRecommendationIndexMethod>[0],
  config?: UseQueryConfig<
    WatchlistRecommendationIndexMethod,
    WatchlistRecommendationIndexErrorTypes
  >
) => {
  return useQuery<
    WatchlistRecommendationIndexMethod,
    WatchlistRecommendationIndexErrorTypes
  >(
    (...args) => api.watchlistRecommendationIndex(...args),
    WatchlistRecommendationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WatchlistRecommendationIndex'],
        ...apiQueryConfig.WatchlistRecommendationIndex,
        ...config?.queryConfig
      },
    },
    'WatchlistRecommendationIndex',
  );
};

export const useWatchlistRecommendationIndexInfinite = (
  variables: Parameters<WatchlistRecommendationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    WatchlistRecommendationIndexMethod,
    WatchlistRecommendationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    WatchlistRecommendationIndexMethod,
    WatchlistRecommendationIndexErrorTypes
  >(
    (...args) => api.watchlistRecommendationIndex(...args),
    WatchlistRecommendationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WatchlistRecommendationIndex', 'WatchlistRecommendationIndexInfinite'],
        ...apiQueryConfig.WatchlistRecommendationIndex,
        ...config?.queryConfig,
      },
    },
    'WatchlistRecommendationIndex',
  );
};

export const useWatchlistRecommendationIndexLazy = (baseOptions?: {
  variables?: Parameters<WatchlistRecommendationIndexMethod>[0],
  config?: UseQueryConfig<
    WatchlistRecommendationIndexMethod,
    WatchlistRecommendationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    WatchlistRecommendationIndexMethod,
    WatchlistRecommendationIndexErrorTypes
  >(
    (...args) => api.watchlistRecommendationIndex(...args),
    WatchlistRecommendationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['WatchlistRecommendationIndex'],
        ...apiQueryConfig.WatchlistRecommendationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'WatchlistRecommendationIndex',
  );
};

