import React, { useState } from 'react';

import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import {
  CbOwnerType,
  type Submission,
  type SubmissionItemsInner,
} from '@npm/data-access';

import { orderEntryConfig, orderFilledConfig } from './rowData.config';

enum OrderDataTab {
  ORDER_ENTRY = 'order_entry',
  ORDER_FILLED = 'order_filled',
}

type Props = {
  orderItem: SubmissionItemsInner;
  submission: Submission;
  footer?: React.ReactNode;
};

export const OrderData = ({ orderItem, submission, footer }: Props) => {
  const [activeTab, setActiveTab] = useState<string>(OrderDataTab.ORDER_ENTRY);
  const hidePrice =
    submission?.event?.allow_non_binding_orders &&
    submission?.event?.type?.code === CbOwnerType.items.DutchAuctionBuy;

  return (
    <Card outlined={false} footer={footer}>
      <Tabs
        variant="secondary"
        activeKey={activeTab}
        onChange={setActiveTab}
        hideMoreButton
      >
        <Tabs.TabPane tab="Order Entry" key={OrderDataTab.ORDER_ENTRY}>
          <TableVertical
            rows={orderEntryConfig(submission, hidePrice)}
            data={orderItem}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Order Filled" key={OrderDataTab.ORDER_FILLED}>
          <TableVertical
            rows={orderFilledConfig(submission)}
            data={{
              ...orderItem?.filled,
              matches: orderItem.matches,
              holding: orderItem.holding,
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};
