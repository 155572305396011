import React from 'react';

import { Codebooks } from '@npm/data-access';
import {
  CbCompanyActive,
  type IssuerEntityApiIssuerEntityIndexRequest,
} from '@npm/data-access';

import {
  CodebooksRadioFilter,
  CodebooksSelectFilter,
  type FilterPanelItem,
  SearchFilter,
} from '../../../../filters';

import {
  ActiveCompaniesSwitchFilter,
  IssuerEntityInvestorsFilter,
  IssuerEntitySectorsFilter,
} from './filters';

export type IssuerEntityIndexFilterParams = Pick<
  IssuerEntityApiIssuerEntityIndexRequest,
  | 'search'
  | 'latestDealSize'
  | 'latestValuation'
  | 'totalFunding'
  | 'lastFunded'
  | 'companyActive'
  | 'watchlistOnly'
  | 'portfolioOnly'
  | 'size'
  | 'sector'
  | 'investor'
  | 'unverifiedHoldings'
  | 'includeField'
  | 'sort'
>;

export const ISSUER_ENTITY_INDEX_INITIAL_FILTER_VALUES = {
  companyActive: CbCompanyActive.items.all_active,
};

export const issuerEntityIndexFilters: FilterPanelItem<IssuerEntityIndexFilterParams>[] =
  [
    {
      name: 'search',
      label: 'Search',
      render: props => (
        <SearchFilter
          placeholder="Search by Company"
          filterOnChange
          {...props}
        />
      ),
      'data-dd-action-name': 'Filter Item - Company',
    },
    {
      name: 'sector',
      label: 'Sector',
      render: props => <IssuerEntitySectorsFilter {...props} />,
    },
    {
      name: 'latestDealSize',
      label: 'Latest Deal Size',
      render: props => (
        <CodebooksSelectFilter
          codebook={Codebooks.LATEST_DEAL_SIZE}
          {...props}
        />
      ),
    },
    {
      name: 'latestValuation',
      label: 'Last Round (LR)',
      tooltip:
        'Valuation based on the last Preferred or publicly known tender offer round',
      render: props => (
        <CodebooksSelectFilter
          codebook={Codebooks.LATEST_VALUATION}
          {...props}
        />
      ),
    },
    {
      name: 'investor',
      label: 'Deal Investor',
      render: props => <IssuerEntityInvestorsFilter {...props} />,
    },
    {
      name: 'totalFunding',
      label: 'Total Funding',
      render: props => (
        <CodebooksSelectFilter codebook={Codebooks.TOTAL_FUNDING} {...props} />
      ),
    },
    {
      name: 'lastFunded',
      label: 'Last Funded',
      render: props => {
        return (
          <CodebooksRadioFilter
            colProps={{ xs: 12, sm: 8 }}
            codebook={Codebooks.LAST_FUNDED}
            {...props}
          />
        );
      },
    },
    {
      name: 'companyActive',
      render: props => <ActiveCompaniesSwitchFilter {...props} />,
      alwaysVisible: true,
    },
  ];
