import React, { type ComponentProps } from 'react';

import type { IconSize } from '@npm/utils';
import { useTheme } from 'styled-components';

import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Tooltip } from '../../atoms/Tooltip';
import { Heading } from '../../atoms/Typography';

type Props = {
  title: React.ReactNode;
  tooltip: string;
  iconSize?: IconSize;
  variant?: ComponentProps<typeof Heading>['variant'];
} & Omit<ComponentProps<typeof Heading>, 'variant' | 'children'>;

export const TitleWithIconTooltip = ({
  title,
  tooltip,
  iconSize = 'xs',
  variant = 'h3',
  ...headingProps
}: Props) => {
  const theme = useTheme();

  return (
    <Flex align="center" gap="xs">
      <Heading variant={variant} {...headingProps}>
        {title}
      </Heading>
      <Tooltip title={tooltip}>
        <Flex justify="center" align="center">
          <Icon
            size={iconSize}
            name="info-circle"
            color={theme.color.info.typography.primary}
          />
        </Flex>
      </Tooltip>
    </Flex>
  );
};
