import styled, { css, type CSSProperties } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { Skeleton } from '../../atoms/Skeleton';

import { type TableVerticalRowType } from './TableVertical';

export const Wrapper = styled.div<{
  $bordered: boolean;
  $borderVariant: 'primary' | 'secondary';
}>`
  ${({ $bordered, $borderVariant, theme }) =>
    $bordered &&
    css`
      border: 1px solid ${theme.color.general.borders[$borderVariant]};
      border-radius: ${theme.borderRadius.md}px;
      padding: 0px ${theme.spacing.sm}px;
    `}
`;

export const Row = styled.div<{
  $borderVariant: 'primary' | 'secondary';
  $flexDirection?: TableVerticalRowType['flexDirection'];
  $borderTop?: boolean;
}>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.sm}px 0;

  ${({ theme, $flexDirection = 'row' }) => css`
    flex-direction: ${$flexDirection};
    gap: ${$flexDirection === 'column' ? theme.spacing.sm : theme.spacing.md};
  `}
  ${({ theme, $borderTop, $borderVariant }) =>
    $borderTop !== false &&
    css`
      border-top: 1px solid ${theme.color.general.borders[$borderVariant]};
    `};

  ${({ theme, $flexDirection = 'row' }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.xs}px 0;
    gap: ${$flexDirection === 'column' ? theme.spacing.xs : theme.spacing.sm}px;
  `}

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }

  & > * {
    flex: 1;
  }
`;

export const TitleContainer = styled.div<{
  $titleAlign?: CSSProperties['alignItems'];
  $titleContainerDisplay?: 'inline' | 'flex';
}>`
  display: ${({ $titleContainerDisplay }) => $titleContainerDisplay};
  align-items: ${({ $titleAlign }) => $titleAlign};
`;

export const InfoContainer = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xs}px;
  height: 16px;

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
    cursor: pointer;
  }
`;

export const SkeletonBase = styled(Skeleton.Base)`
  display: flex;
  align-items: center;

  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: ${({ theme }) => theme.typography.height.sm}px;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    .ant-skeleton-content .ant-skeleton-paragraph > li {
      height: ${({ theme }) => theme.typography.height.xs}px;
    }
  `}
`;
