import React from 'react';

import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { TextWithBottomInfo } from '@npm/core/ui/components/molecules/TextWithBottomInfo';
import { getFullName } from '@npm/core/ui/utils/formatters';

import { type OboModalState } from '../../../OboModal.types';

type Props = {
  account: OboModalState['account'];
  isActive: boolean;
  handleSwitchRepresentativeClick: () => void;
};

export const RepresentativeCellItem = ({
  account,
  isActive,
  handleSwitchRepresentativeClick,
}: Props) => {
  const renderBottomInfo = () => {
    const representative = account?.representative;

    if (!account.representative_count || !representative) {
      return EMPTY_VALUE;
    }

    if (!isActive || account.representative_count <= 1) {
      return <span data-dd-privacy="mask">{representative?.email}</span>;
    } else {
      return (
        <Text size="xs" weight="bold" onClick={handleSwitchRepresentativeClick}>
          Switch Representative
        </Text>
      );
    }
  };

  return (
    <TextWithBottomInfo
      bottomInfo={{
        leftInfo: renderBottomInfo(),
      }}
      data-dd-action-name="Representative"
      data-dd-privacy="mask"
    >
      {getFullName(
        account?.representative?.first,
        account?.representative?.last,
        account?.representative?.middle
      )}
    </TextWithBottomInfo>
  );
};
