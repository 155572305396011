// eslint-disable-next-line no-restricted-imports
import { Navigate, useLocation } from 'react-router-dom';

import { useUserContextStore } from '../../context';

export const withDefaultRedirects = <T,>(Component: React.ComponentType) => {
  return (props: T) => {
    const isNewUser = useUserContextStore(store => store.isNewUser);
    const { pathname } = useLocation();

    return (
      <>
        {isNewUser && pathname !== '/onboarding/postplatform' && (
          <Navigate to="/onboarding/postplatform" />
        )}
        <Component {...props} />
      </>
    );
  };
};
