import React, { type ComponentProps } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';
import {
  type BuyerSurvey,
  type BuyerSurveyApiBuyerSurveyCreateRequest,
} from '@npm/data-access';
import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';

type Props = {
  form: FormInstance<
    BuyerSurveyApiBuyerSurveyCreateRequest['accountsBuyerSurveyCreateRequestContract']
  >;
  formConfig: Pick<
    ComponentProps<typeof AutoScrollingForm>,
    'questions'
  >['questions'];
  handleValuesChange?: FormProps['onValuesChange'];
  initialData: BuyerSurvey;
  isLoading: boolean;
  scrollableParentId?: string;
  scrollEnabled?: boolean;
  extraTopPadding?: number;
  questionMinHeight?: number | string;
};

export const BuyerSurveyForm = ({
  form,
  formConfig,
  handleValuesChange,
  initialData,
  isLoading,
  scrollableParentId,
  scrollEnabled = true,
  extraTopPadding,
  questionMinHeight,
}: Props) => {
  return isLoading ? (
    <Skeleton.Base />
  ) : (
    <>
      <AutoScrollingForm
        questions={formConfig}
        form={form}
        initialValues={initialData}
        testId={'buyer-survey-form'}
        scrollEnabled={scrollEnabled}
        scrollableParentId={scrollableParentId}
        extraTopPadding={extraTopPadding}
        onValuesChange={handleValuesChange}
        questionMinHeight={questionMinHeight}
      />
      <Alert message="By clicking Submit, I certify and represent the foregoing responses are true, complete, and accurate." />
    </>
  );
};
