// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TradeAccountApi,
  TradeAccountCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TradeAccountApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TradeAccountCreateMethod = TradeAccountApi['tradeAccountCreate'];

export const useTradeAccountCreate = () => {
  return useMutation<TradeAccountCreateMethod>(
    (...args) => api.tradeAccountCreate(...args),
    TradeAccountCreateErrorCodes,
    'TradeAccountCreate',
    '/api/trade-accounts',
    'post'
  );
};
