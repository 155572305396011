// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuerEntityForMarketFeedApi,
  IssuerEntityForMarketFeedIndexErrorCodes,
  IssuerEntityForMarketFeedIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuerEntityForMarketFeedApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type IssuerEntityForMarketFeedIndexMethod = IssuerEntityForMarketFeedApi['issuerEntityForMarketFeedIndex'];
export type IssuerEntityForMarketFeedIndexResponseType = MethodResult<IssuerEntityForMarketFeedIndexMethod>;

export const useIssuerEntityForMarketFeedIndex = (
  variables: Parameters<IssuerEntityForMarketFeedIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityForMarketFeedIndexMethod,
    IssuerEntityForMarketFeedIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityForMarketFeedIndexMethod,
    IssuerEntityForMarketFeedIndexErrorTypes
  >(
    (...args) => api.issuerEntityForMarketFeedIndex(...args),
    IssuerEntityForMarketFeedIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityForMarketFeedIndex'],
        ...apiQueryConfig.IssuerEntityForMarketFeedIndex,
        ...config?.queryConfig
      },
    },
    'IssuerEntityForMarketFeedIndex',
  );
};

export const useIssuerEntityForMarketFeedIndexInfinite = (
  variables: Parameters<IssuerEntityForMarketFeedIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityForMarketFeedIndexMethod,
    IssuerEntityForMarketFeedIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityForMarketFeedIndexMethod,
    IssuerEntityForMarketFeedIndexErrorTypes
  >(
    (...args) => api.issuerEntityForMarketFeedIndex(...args),
    IssuerEntityForMarketFeedIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityForMarketFeedIndex', 'IssuerEntityForMarketFeedIndexInfinite'],
        ...apiQueryConfig.IssuerEntityForMarketFeedIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityForMarketFeedIndex',
  );
};

export const useIssuerEntityForMarketFeedIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityForMarketFeedIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityForMarketFeedIndexMethod,
    IssuerEntityForMarketFeedIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityForMarketFeedIndexMethod,
    IssuerEntityForMarketFeedIndexErrorTypes
  >(
    (...args) => api.issuerEntityForMarketFeedIndex(...args),
    IssuerEntityForMarketFeedIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityForMarketFeedIndex'],
        ...apiQueryConfig.IssuerEntityForMarketFeedIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityForMarketFeedIndex',
  );
};

