import { type ComponentProps, useState } from 'react';

import { useModal } from '@npm/core/ui/components/molecules/Modal';

import { type StatusChangeButtons } from '../components';

import { type HoldingStatusChangeModal } from './HoldingStatusChangeModal';

type OpenHoldingStatusChangeModalArgs = Parameters<
  ComponentProps<typeof StatusChangeButtons>['onClick']
>;

export const useHoldingStatusChangeModal = (): [
  (args: OpenHoldingStatusChangeModalArgs) => void,
  ComponentProps<typeof HoldingStatusChangeModal>,
] => {
  const [openModal, modalProps] = useModal();
  const [holdingStatusChangeModalProps, setHoldingStatusChangeModalProps] =
    useState<ComponentProps<typeof HoldingStatusChangeModal>>();

  const openHoldingStatusChangeModal = ([
    { type, id, certificate_number },
  ]: OpenHoldingStatusChangeModalArgs) => {
    setHoldingStatusChangeModalProps({ id, certificate_number, type });
    openModal();
  };

  return [
    openHoldingStatusChangeModal,
    { ...holdingStatusChangeModalProps, ...modalProps },
  ];
};
