import { css, type DefaultTheme } from 'styled-components';

type Breakpoints = keyof DefaultTheme['breakpoint'];
type BreakpointAcc = {
  [key in Breakpoints]: typeof css;
};
export const useBreakpoint = (theme: DefaultTheme) =>
  (Object.keys(theme.breakpoint) as Breakpoints[]).reduce<BreakpointAcc>(
    (acc, key) => {
      return {
        ...acc,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key]: (...cssArgs: any) => css`
        @media screen and (max-width: ${theme.breakpoint[key]}) {
          // prettier-ignore
          ${
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css(...cssArgs)
          }
        }
      `,
      };
    },
    {
      mobile: () => css``,
      smTablet: () => css``,
      tablet: () => css``,
      smDesktop: () => css``,
      desktop: () => css``,
    }
  );
