// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PreferredBuyerApi,
  PreferredBuyerCreateErrorCodes,
  PreferredBuyerDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PreferredBuyerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PreferredBuyerCreateMethod = PreferredBuyerApi['preferredBuyerCreate'];

export const usePreferredBuyerCreate = () => {
  return useMutation<PreferredBuyerCreateMethod>(
    (...args) => api.preferredBuyerCreate(...args),
    PreferredBuyerCreateErrorCodes,
    'PreferredBuyerCreate',
    '/api/issuer-entities/{issuer_entity_id}/preferred-buyers',
    'post'
  );
};

export type PreferredBuyerDestroyMethod = PreferredBuyerApi['preferredBuyerDestroy'];

export const usePreferredBuyerDestroy = () => {
  return useMutation<PreferredBuyerDestroyMethod>(
    (...args) => api.preferredBuyerDestroy(...args),
    PreferredBuyerDestroyErrorCodes,
    'PreferredBuyerDestroy',
    '/api/issuer-entities/{issuer_entity_id}/preferred-buyers/{id}',
    'delete'
  );
};
