import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  DATE_FORMATS,
  formatDate,
  getTimezoneShort,
  truncate,
} from '@npm/core/ui/utils/formatters';
import {
  type AssetTypeCode,
  CbAssetType,
  CbHoldingState,
  CbOwnerType,
  type DocumentAggregate,
  type DocumentSimple,
  type EventAggregate,
  getCodebookCode,
  type Holding,
} from '@npm/data-access';
import { type ModalProps } from 'antd/lib/modal';

import { AccountName } from '../account/components/AccountName';

import { ProofOfOwnershipViewButton } from './aggregated/List/components';
import { HoldingStatus } from './components/HoldingStatus';
import { VISIBLE_FIELDS } from './Form/HoldingForm.constants';
import { type HoldingAssetTypeKey } from './Form/HoldingForm.types';
import { getInitialFormData } from './Form/HoldingForm.utils';

import * as S from './Holdings.styles';
import { type HoldingItem, type HoldingItemKey } from './Holdings.types';

const getExtraHoldingColumns = (
  type: HoldingAssetTypeKey
): HoldingItemKey[] => {
  const {
    PreferredStock,
    CommonStock,
    RestrictedStockUnit,
    Unit,
    Option,
    Warrant,
  } = CbAssetType.items;

  switch (type) {
    case PreferredStock:
      return ['series', 'acquisition_date', 'cost_basis'];
    case CommonStock:
      return ['class', 'acquisition_date', 'cost_basis'];
    case Unit:
      return ['class', 'acquisition_date', 'cost_basis'];
    case RestrictedStockUnit:
      return ['class', 'grant_date', 'cost_basis'];
    case Option:
      return [
        'grant_type',
        'grant_date',
        'plan',
        'strike_price',
        'expiration_date',
      ];
    case Warrant:
      return ['grant_date', 'series', 'strike_price', 'expiration_date'];
    default:
      return [];
  }
};

export const renderCertificateNumber = ({
  holding,
  onEditHolding,
  openEditDrawer,
}: {
  holding?: Holding;
  onEditHolding?: (id: number, holding?: Holding) => void;
  openEditDrawer?: (holding?: Holding) => void;
}) => {
  if (holding.aggregated) return 'Aggregated';

  return holding?.certificate_number ? (
    <span data-cy={CypressDataIds.Holdings.Properties.UniqueId}>
      {holding.certificate_number}
    </span>
  ) : onEditHolding || openEditDrawer ? (
    <Text
      as="button"
      size="sm"
      onClick={e => {
        e.stopPropagation();
        onEditHolding?.(holding?.id, holding);
        holding && openEditDrawer?.(holding);
      }}
    >
      + Add
    </Text>
  ) : (
    EMPTY_VALUE
  );
};

export const getProofOfOwnershipName = (
  proofOfOwnership?: DocumentAggregate | File | null
) => {
  if (!proofOfOwnership) {
    return undefined;
  }

  if ('name' in proofOfOwnership) {
    return proofOfOwnership.name;
  }

  return proofOfOwnership.display_name || proofOfOwnership.file_name;
};

export const renderProofOfOwnershipDocument = ({
  holding,
  openDocument,
  onEditHolding,
  openEditDrawer,
}: {
  holding: Holding;
  openDocument: (doc?: DocumentSimple) => void;
  onEditHolding?: (id: number, holding: Holding) => void;
  openEditDrawer?: (holding: Holding) => void;
}) => {
  if (holding.aggregated) {
    return <ProofOfOwnershipViewButton holding={holding} />;
  }

  if (holding.proof_of_ownership_document) {
    return (
      <S.PdfWrapper>
        <Icon name="paperclip" size="xs" />
        <Text
          size="sm"
          onClick={() => {
            openDocument(holding.proof_of_ownership_document);
          }}
        >
          {truncate(
            getProofOfOwnershipName(holding.proof_of_ownership_document),
            50
          )}
        </Text>
      </S.PdfWrapper>
    );
  }

  if (
    holding.state?.code === CbHoldingState.items.verified ||
    (!onEditHolding && !openEditDrawer)
  )
    return EMPTY_VALUE;

  return (
    <Text
      as="button"
      size="sm"
      hyperlinkColor="warning"
      onClick={e => {
        onEditHolding?.(holding.id, holding);
        openEditDrawer?.(holding);
      }}
    >
      + Add {holding.spv ? 'Subscription Agreement' : 'Proof of Ownership'}
    </Text>
  );
};

export const getUniqueHoldingIdName = (
  holding: Holding,
  isShortened = false
) => {
  const { isOption, isWarrant, isRSU } = getHoldingType(holding);

  return isOption || isWarrant || isRSU
    ? 'Grant ID'
    : `Cert${isShortened ? '.' : 'ificate'} ID`;
};

export const getAddHoldingButtonText = (holding?: Holding) => {
  return `+ Add ${getUniqueHoldingIdName(holding)}`;
};

export const getIsHoldingAttributeVisible = (
  type: HoldingAssetTypeKey,
  attribute: HoldingItemKey
) => getExtraHoldingColumns(type).includes(attribute);

type HoldingItemArgs = {
  onClick?: (doc?: DocumentSimple) => void;
  onShowInstructions?: (props: ModalProps) => void;
  onEditHolding?: (id: number, holding: Holding) => void;
  getTooltip?: (holding: Holding) => string | null;
  statusAlign?: React.CSSProperties['alignItems'];
  styledAssetType?: boolean;
};

export const getHoldingItem = (
  item: HoldingItemKey
): ((args?: HoldingItemArgs) => HoldingItem) => {
  const items: Record<HoldingItemKey, (args?: HoldingItemArgs) => HoldingItem> =
    {
      issuer_entity: () => ({
        title: 'Company',
        key: 'issuer_entity',
        render: ({ issuer_entity }) => {
          return (
            <CompanyLogo
              url={issuer_entity?.logo_url}
              size="sm"
              name={issuer_entity?.name}
            />
          );
        },
      }),
      issuance: () => ({
        title: 'Issuance',
        key: 'issuance',
        render: ({ issuance }) => issuance?.name,
      }),
      account: () => ({
        title: 'Account',
        key: 'account',
        render: ({ account }) => <AccountName account={account} />,
      }),
      accountWithoutLink: () => ({
        title: 'Account',
        key: 'accountWithoutLink',
        render: ({ account }) => <AccountName withoutLink account={account} />,
      }),
      asset: ({ styledAssetType } = {}) => ({
        title: 'Asset Type',
        key: 'asset',
        render: ({ asset }) => {
          if (styledAssetType) {
            return (
              <Label variant="info" bordered round>
                {asset?.type?.name}
              </Label>
            );
          }
          return asset?.type?.name;
        },
      }),
      unique_id: ({ onEditHolding }) => ({
        title: 'Unique ID',
        key: 'certificate_number',
        width: 150,
        render: holding => renderCertificateNumber({ holding, onEditHolding }),
      }),
      quantity: () => ({
        title: 'Quantity',
        key: 'quantity',
        render: ({ quantity }) => <Text.Quantity value={quantity} />,
      }),

      vested_qty: () => ({
        title: 'Vested Quantity',
        key: 'vested_qty',
        // showing 0 as "0", not "--"
        render: ({ vested_qty }) => <Text.Quantity value={vested_qty || '0'} />,
      }),

      pre_verified: () => ({
        title: 'Pre-Verified',
        key: 'pre_verified',
        render: ({ pre_verified }) =>
          pre_verified ? (
            <Flex align="center" gap="xs">
              <Icon name="check" size="xs" color="info" />
              <Text size="sm" colorVariant="primary">
                Yes
              </Text>
            </Flex>
          ) : (
            <Text size="sm" colorVariant="tertiary">
              {pre_verified === null ? EMPTY_VALUE : 'No'}
            </Text>
          ),
      }),

      state: ({ onShowInstructions, getTooltip, statusAlign } = {}) => ({
        title: 'Status',
        key: 'state',
        render: holding => (
          <HoldingStatus
            holding={holding}
            onShowInstructions={onShowInstructions}
            getTooltip={getTooltip}
            align={statusAlign}
          />
        ),
      }),

      registered_name: () => ({
        title: 'Registered Name',
        key: 'registered_name',
        render: ({ registered_name }) => registered_name,
      }),

      proof_of_ownership_document: ({ onClick, onEditHolding }) => ({
        title: 'Proof of Ownership',
        className: 'no-text-transform',
        key: 'proof_of_ownership_document',
        render: holding =>
          renderProofOfOwnershipDocument({
            holding,
            openDocument: onClick,
            onEditHolding,
          }),
      }),

      grant_type: () => ({
        title: 'Grant Type',
        key: 'grant_type',
        render: ({ grant_type }) => grant_type?.name,
      }),

      grant_date: () => ({
        title: 'Grant Date',
        key: 'grant_date',
        render: ({ grant_date }) =>
          formatDate(grant_date, { dateFormat: DATE_FORMATS.DATE }) ||
          EMPTY_VALUE,
      }),

      plan: () => ({
        title: 'Plan',
        key: 'plan',
        render: ({ asset }) => asset?.plan || EMPTY_VALUE,
      }),

      class: () => ({
        title: 'Class',
        key: 'class',
        render: ({ asset }) => asset?.series || EMPTY_VALUE,
      }),

      series: () => ({
        title: 'Series',
        key: 'series',
        render: ({ asset }) => asset?.series || EMPTY_VALUE,
      }),

      cost_basis: () => ({
        title: 'Cost Basis',
        key: 'cost_basis',
        render: ({ cost_basis, issuance }) =>
          cost_basis ? (
            <Text.Price
              value={cost_basis}
              formatOptions={{ long: true }}
              currency={issuance.denomination?.name ?? 'USD'}
            />
          ) : (
            EMPTY_VALUE
          ),
      }),

      strike_price: () => ({
        title: 'Strike Price',
        key: 'strike_price',
        render: ({ asset, issuance }) =>
          asset?.strike_price ? (
            <Text.Price
              value={asset?.strike_price}
              formatOptions={{ long: true }}
              currency={issuance.denomination?.name ?? 'USD'}
            />
          ) : (
            EMPTY_VALUE
          ),
      }),

      expiration_date: () => ({
        title: 'Expiration Date',
        key: 'expiration_date',
        render: ({ expiration_date }) =>
          formatDate(expiration_date, { dateFormat: DATE_FORMATS.DATE }) ||
          EMPTY_VALUE,
      }),

      acquisition_date: () => ({
        title: 'Acquisition Date',
        key: 'acquisition_date',
        render: ({ acquisition_date }) =>
          formatDate(acquisition_date, { dateFormat: DATE_FORMATS.DATE }) ||
          EMPTY_VALUE,
      }),

      offer_price: () => ({
        title: 'Offer Price',
        key: 'offer_price',
        render: ({ asset, issuance }) =>
          asset?.offer_price ? (
            <Text.Price
              value={asset?.offer_price}
              formatOptions={{ long: true }}
              currency={issuance.denomination?.name ?? 'USD'}
            />
          ) : (
            EMPTY_VALUE
          ),
      }),

      remaining_quantity: () => ({
        title: 'Remaining Quantity',
        key: 'remaining_quantity',
        render: ({ remaining_quantity }) => (
          <Text.Quantity value={remaining_quantity} />
        ),
        titleInfo:
          'Outstanding Vested Quantity that is eligible to be sold and is not associated with a live order or matched transaction.',
      }),

      created_at: () => ({
        title: `Created At (${getTimezoneShort()})`,
        key: 'created_at',
        render: ({ created_at }) =>
          created_at ? formatDate(created_at) : EMPTY_VALUE,
      }),

      updated_at: () => ({
        title: `Updated At (${getTimezoneShort()})`,
        key: 'updated_at',
        render: ({ updated_at }) =>
          updated_at ? formatDate(updated_at) : EMPTY_VALUE,
      }),
    };

  return items[item];
};

export const getDisabledHoldingActionsTooltip = (
  holding: Partial<Holding> | undefined
) => {
  if (holding?.state?.code === CbHoldingState.items.verified)
    return 'Verified holdings cannot be modified.';

  return null;
};

export const getDisabledDeleteActionTooltip = (
  holding: Partial<Holding> | undefined
) => {
  if (!holding?.deletable)
    return 'Holdings with open or expired orders cannot be deleted.';

  return null;
};

export const getNoRemainingQuantityTooltip = (
  holding: Partial<Holding> | undefined
) => {
  if (holding?.remaining_quantity <= 0)
    return 'Your holding has no remaining quantity to sell.';

  return null;
};

export const getHoldingType = (holding: Holding) => {
  const assetType = holding.asset?.type?.code;
  const isWarrant = assetType === CbAssetType.items.Warrant;
  const isOption = assetType === CbAssetType.items.Option;
  const isPreferred = assetType === CbAssetType.items.PreferredStock;
  const isCommonStock = assetType === CbAssetType.items.CommonStock;
  const isUnit = assetType === CbAssetType.items.Unit;
  const isRSU = assetType === CbAssetType.items.RestrictedStockUnit;

  const isIneligible =
    holding?.asset?.eligible !== undefined && holding?.asset?.eligible !== true;

  return {
    isWarrant,
    isOption,
    isPreferred,
    isCommonStock,
    isUnit,
    isRSU,
    isIneligible,
  };
};

export const getAssetTypeCode = (type: Holding['asset']['type']) =>
  getCodebookCode<keyof typeof CbAssetType.items>(type);

const getHoldingAssetType = (holding: Holding): HoldingAssetTypeKey =>
  getAssetTypeCode(holding?.asset?.type);

export const getIsHoldingEligible = (holding: Holding) =>
  holding?.asset?.eligible === undefined || holding?.asset?.eligible === true;

export const getIsOrderHoldingExercisable = (
  holding: Holding,
  event: EventAggregate
) =>
  (getHoldingAssetType(holding) === CbAssetType.items.Option ||
    getHoldingAssetType(holding) === CbAssetType.items.Warrant) &&
  event?.type?.code === CbOwnerType.items.TenderOffer &&
  event?.order_settings?.exercise_and_hold === true;

export const isSpvHolding = (assetType: AssetTypeCode | null) => {
  return (
    assetType === CbAssetType.items.SingleLayerSpv ||
    assetType === CbAssetType.items.DoubleLayerSpv
  );
};

export const getIndividualInvestorHoldingStateTooltip = (holding: Holding) => {
  if (
    holding.state?.code === CbHoldingState.items.pre_verified ||
    (holding.state?.code === CbHoldingState.items.pending &&
      holding.pre_verified)
  ) {
    return 'Your holding has been pre-verified by the NPM team and is ready to be used to engage with live bids.';
  }
  if (holding.state?.code === CbHoldingState.items.pending) {
    return 'This holding is pending review from NPM before it can be sold. If needed, someone from NPM will contact you to collect any missing information and to verify the authenticity of your holding.';
  }
  if (holding.state?.code === CbHoldingState.items.verified) {
    return 'This holding has been verified and no other action is required.';
  }
  if (holding.state?.code === CbHoldingState.items.needs_verification) {
    return 'Requires additional information before being verified by the company. Additional details may have been sent for you to review to update your holding information.';
  }

  return null;
};

export const getHoldingCertificateNumber = (holding: Holding) => {
  const certificateNumber = holding.certificate_number?.trim();

  return certificateNumber ? truncate(certificateNumber, 20) : 'N/A';
};

type HoldingIdInfo = {
  isMissingId: boolean;
  isMissingProofOfOwnership: boolean;
  idLabel: string;
};

export const getHoldingIdInfo = (
  holding: Holding,
  truncateLabel = true
): HoldingIdInfo => {
  if (holding.aggregated) {
    return {
      isMissingId: false,
      isMissingProofOfOwnership:
        !holding.aggregated_proof_of_ownership_documents_count &&
        !holding.pre_verified,
      idLabel: 'Aggregated',
    };
  }

  const isMissingId = !holding.certificate_number;
  const isMissingProofOfOwnership = !holding.proof_of_ownership_document;

  return {
    isMissingId,
    isMissingProofOfOwnership,
    idLabel: isMissingId
      ? holding.asset.type.code === CbAssetType.items.Warrant ||
        holding.asset.type.code === CbAssetType.items.Option
        ? 'Missing Grant ID'
        : truncateLabel
          ? 'Missing Cert. ID'
          : 'Missing Certificate ID'
      : holding.certificate_number,
  };
};

/**
 * Determines whether a holding is completely filled in "correction mode".
 *
 * Normally, only a subset of fields is required.
 * In "correction mode," all fields (except the proof of ownership, plan for Options and expiration date for Options and Warrants) are required.
 *
 * @param holding - the holding object to validate
 * @returns boolean indicating if the holding passes as fully filled in correction mode
 */
export const isHoldingCompleteInCorrectionMode = (holding: Holding) => {
  const assetType = holding?.asset?.type?.code;
  const fields = VISIBLE_FIELDS[assetType];
  const holdingFormData = getInitialFormData(holding);

  const ignoreFields = [
    'filled_qty',
    'proof_of_ownership_document',
    'plan',
    'expiration_date',
  ];

  return fields?.every(
    fieldKey =>
      ignoreFields.indexOf(fieldKey) > -1 || !!holdingFormData[fieldKey]
  );
};
