// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StockPlansApi,
  ListStockPlanIssuancesErrorCodes,
  ListStockPlanIssuancesErrorTypes,
  GetStockPlanIssuanceErrorCodes,
  GetStockPlanIssuanceErrorTypes,
  ListStockPlansErrorCodes,
  ListStockPlansErrorTypes,
  GetStockPlanErrorCodes,
  GetStockPlanErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StockPlansApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ListStockPlanIssuancesMethod = StockPlansApi['listStockPlanIssuances'];
export type ListStockPlanIssuancesResponseType = MethodResult<ListStockPlanIssuancesMethod>;

export const useListStockPlanIssuances = (
  variables: Parameters<ListStockPlanIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListStockPlanIssuancesMethod,
    ListStockPlanIssuancesErrorTypes
  >
) => {
  return useQuery<
    ListStockPlanIssuancesMethod,
    ListStockPlanIssuancesErrorTypes
  >(
    (...args) => api.listStockPlanIssuances(...args),
    ListStockPlanIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListStockPlanIssuances'],
        ...apiQueryConfig.ListStockPlanIssuances,
        ...config?.queryConfig
      },
    },
    'ListStockPlanIssuances',
  );
};

export const useListStockPlanIssuancesInfinite = (
  variables: Parameters<ListStockPlanIssuancesMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListStockPlanIssuancesMethod,
    ListStockPlanIssuancesErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListStockPlanIssuancesMethod,
    ListStockPlanIssuancesErrorTypes
  >(
    (...args) => api.listStockPlanIssuances(...args),
    ListStockPlanIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListStockPlanIssuances', 'ListStockPlanIssuancesInfinite'],
        ...apiQueryConfig.ListStockPlanIssuances,
        ...config?.queryConfig,
      },
    },
    'ListStockPlanIssuances',
  );
};

export const useListStockPlanIssuancesLazy = (baseOptions?: {
  variables?: Parameters<ListStockPlanIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListStockPlanIssuancesMethod,
    ListStockPlanIssuancesErrorTypes
  >
}) => {
  return useLazyQuery<
    ListStockPlanIssuancesMethod,
    ListStockPlanIssuancesErrorTypes
  >(
    (...args) => api.listStockPlanIssuances(...args),
    ListStockPlanIssuancesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListStockPlanIssuances'],
        ...apiQueryConfig.ListStockPlanIssuances,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListStockPlanIssuances',
  );
};


export type GetStockPlanIssuanceMethod = StockPlansApi['getStockPlanIssuance'];
export type GetStockPlanIssuanceResponseType = MethodResult<GetStockPlanIssuanceMethod>;

export const useGetStockPlanIssuance = (
  variables: Parameters<GetStockPlanIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetStockPlanIssuanceMethod,
    GetStockPlanIssuanceErrorTypes
  >
) => {
  return useQuery<
    GetStockPlanIssuanceMethod,
    GetStockPlanIssuanceErrorTypes
  >(
    (...args) => api.getStockPlanIssuance(...args),
    GetStockPlanIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetStockPlanIssuance'],
        ...apiQueryConfig.GetStockPlanIssuance,
        ...config?.queryConfig
      },
    },
    'GetStockPlanIssuance',
  );
};

export const useGetStockPlanIssuanceInfinite = (
  variables: Parameters<GetStockPlanIssuanceMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetStockPlanIssuanceMethod,
    GetStockPlanIssuanceErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetStockPlanIssuanceMethod,
    GetStockPlanIssuanceErrorTypes
  >(
    (...args) => api.getStockPlanIssuance(...args),
    GetStockPlanIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetStockPlanIssuance', 'GetStockPlanIssuanceInfinite'],
        ...apiQueryConfig.GetStockPlanIssuance,
        ...config?.queryConfig,
      },
    },
    'GetStockPlanIssuance',
  );
};

export const useGetStockPlanIssuanceLazy = (baseOptions?: {
  variables?: Parameters<GetStockPlanIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetStockPlanIssuanceMethod,
    GetStockPlanIssuanceErrorTypes
  >
}) => {
  return useLazyQuery<
    GetStockPlanIssuanceMethod,
    GetStockPlanIssuanceErrorTypes
  >(
    (...args) => api.getStockPlanIssuance(...args),
    GetStockPlanIssuanceErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetStockPlanIssuance'],
        ...apiQueryConfig.GetStockPlanIssuance,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetStockPlanIssuance',
  );
};


export type ListStockPlansMethod = StockPlansApi['listStockPlans'];
export type ListStockPlansResponseType = MethodResult<ListStockPlansMethod>;

export const useListStockPlans = (
  variables: Parameters<ListStockPlansMethod>[0],
  config?: UseQueryConfig<
    ListStockPlansMethod,
    ListStockPlansErrorTypes
  >
) => {
  return useQuery<
    ListStockPlansMethod,
    ListStockPlansErrorTypes
  >(
    (...args) => api.listStockPlans(...args),
    ListStockPlansErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListStockPlans'],
        ...apiQueryConfig.ListStockPlans,
        ...config?.queryConfig
      },
    },
    'ListStockPlans',
  );
};

export const useListStockPlansInfinite = (
  variables: Parameters<ListStockPlansMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListStockPlansMethod,
    ListStockPlansErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListStockPlansMethod,
    ListStockPlansErrorTypes
  >(
    (...args) => api.listStockPlans(...args),
    ListStockPlansErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListStockPlans', 'ListStockPlansInfinite'],
        ...apiQueryConfig.ListStockPlans,
        ...config?.queryConfig,
      },
    },
    'ListStockPlans',
  );
};

export const useListStockPlansLazy = (baseOptions?: {
  variables?: Parameters<ListStockPlansMethod>[0],
  config?: UseQueryConfig<
    ListStockPlansMethod,
    ListStockPlansErrorTypes
  >
}) => {
  return useLazyQuery<
    ListStockPlansMethod,
    ListStockPlansErrorTypes
  >(
    (...args) => api.listStockPlans(...args),
    ListStockPlansErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListStockPlans'],
        ...apiQueryConfig.ListStockPlans,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListStockPlans',
  );
};


export type GetStockPlanMethod = StockPlansApi['getStockPlan'];
export type GetStockPlanResponseType = MethodResult<GetStockPlanMethod>;

export const useGetStockPlan = (
  variables: Parameters<GetStockPlanMethod>[0],
  config?: UseQueryConfig<
    GetStockPlanMethod,
    GetStockPlanErrorTypes
  >
) => {
  return useQuery<
    GetStockPlanMethod,
    GetStockPlanErrorTypes
  >(
    (...args) => api.getStockPlan(...args),
    GetStockPlanErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetStockPlan'],
        ...apiQueryConfig.GetStockPlan,
        ...config?.queryConfig
      },
    },
    'GetStockPlan',
  );
};

export const useGetStockPlanInfinite = (
  variables: Parameters<GetStockPlanMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetStockPlanMethod,
    GetStockPlanErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetStockPlanMethod,
    GetStockPlanErrorTypes
  >(
    (...args) => api.getStockPlan(...args),
    GetStockPlanErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetStockPlan', 'GetStockPlanInfinite'],
        ...apiQueryConfig.GetStockPlan,
        ...config?.queryConfig,
      },
    },
    'GetStockPlan',
  );
};

export const useGetStockPlanLazy = (baseOptions?: {
  variables?: Parameters<GetStockPlanMethod>[0],
  config?: UseQueryConfig<
    GetStockPlanMethod,
    GetStockPlanErrorTypes
  >
}) => {
  return useLazyQuery<
    GetStockPlanMethod,
    GetStockPlanErrorTypes
  >(
    (...args) => api.getStockPlan(...args),
    GetStockPlanErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetStockPlan'],
        ...apiQueryConfig.GetStockPlan,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetStockPlan',
  );
};

