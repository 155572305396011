import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography/Text';
import { FullScreenLoadingWithPortal } from '@npm/core/ui/components/molecules/FullScreenLoading';
import { type AccountShowAggregate, CbRoleType } from '@npm/data-access';

import {
  type WithSupportTicketType,
  ZendeskContactButton,
} from '../../../../app/zendesk';
import { GetStartedCard } from '../../../components/GetStartedCard';
import { ONBOARDING_AGREEMENTS_TEXT_CONTENT } from '../../OnboardingAgreements.config';
import { type OnboardingAgreementType } from '../../OnboardingAgreements.types';

type Props = {
  type: OnboardingAgreementType;
  onClick: () => void;
  isRedirectingToDocusign?: boolean;
  account: AccountShowAggregate | undefined;
} & WithSupportTicketType;

// will replace `OnboardingAgreementGetStarted` component
// for now, this is only used in INV account onboarding
export const OnboardingAgreementSignCta = ({
  type,
  onClick,
  isRedirectingToDocusign,
  zendeskTicketGroupId,
  account,
}: Props) => {
  if (isRedirectingToDocusign) {
    return (
      <FullScreenLoadingWithPortal loadingTitle="We're taking you to DocuSign" />
    );
  }

  const isDisabled =
    account?.user_role?.role_name?.code !== CbRoleType.items.ADMIN_AND_SIGNER;

  return (
    <GetStartedCard
      title={ONBOARDING_AGREEMENTS_TEXT_CONTENT.title[type]}
      description={
        <Text size="sm">
          {ONBOARDING_AGREEMENTS_TEXT_CONTENT.get_started[type]} If you have any
          questions, please{' '}
          <ZendeskContactButton zendeskTicketGroupId={zendeskTicketGroupId}>
            contact us
          </ZendeskContactButton>
          .
        </Text>
      }
      cta={
        <Tooltip
          title={
            isDisabled
              ? 'To sign this agreement, you must be an Account Admin & Signer.'
              : undefined
          }
        >
          <Button
            icon={<Icon name="redirect" />}
            onClick={onClick}
            disabled={isDisabled}
          >
            Sign {ONBOARDING_AGREEMENTS_TEXT_CONTENT.title[type]}
          </Button>
        </Tooltip>
      }
      iconName="ballpen"
      contentMaxWidth={500}
    />
  );
};
