import { Flex } from '@npm/core/ui/components/atoms/common';
import styled, { css } from 'styled-components';

const HoldingTypeLabel = styled(Flex)(
  ({ theme: { spacing, color, borderRadius } }) => css`
    padding: ${spacing.xs}px;
    display: inline-flex;
    border: 1px solid ${color.general.borders.primary};
    border-radius: ${borderRadius.sm}px;

    svg {
      color: ${color.general.typography.muted};
    }
  `
);

export const AggrHoldingsListComponentsStyles = { HoldingTypeLabel };
