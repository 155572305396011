// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CapTablePartnerUploadFileApi,
  GetCapTablePartnerUploadFileDownloadUrlsErrorCodes,
  GetCapTablePartnerUploadFileDownloadUrlsErrorTypes,
  GetCapTablePartnerUploadFileValidationsErrorCodes,
  GetCapTablePartnerUploadFileValidationsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CapTablePartnerUploadFileApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type GetCapTablePartnerUploadFileDownloadUrlsMethod = CapTablePartnerUploadFileApi['getCapTablePartnerUploadFileDownloadUrls'];
export type GetCapTablePartnerUploadFileDownloadUrlsResponseType = MethodResult<GetCapTablePartnerUploadFileDownloadUrlsMethod>;

export const useGetCapTablePartnerUploadFileDownloadUrls = (
  variables: Parameters<GetCapTablePartnerUploadFileDownloadUrlsMethod>[0],
  config?: UseQueryConfig<
    GetCapTablePartnerUploadFileDownloadUrlsMethod,
    GetCapTablePartnerUploadFileDownloadUrlsErrorTypes
  >
) => {
  return useQuery<
    GetCapTablePartnerUploadFileDownloadUrlsMethod,
    GetCapTablePartnerUploadFileDownloadUrlsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploadFileDownloadUrls(...args),
    GetCapTablePartnerUploadFileDownloadUrlsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploadFileDownloadUrls'],
        ...apiQueryConfig.GetCapTablePartnerUploadFileDownloadUrls,
        ...config?.queryConfig
      },
    },
    'GetCapTablePartnerUploadFileDownloadUrls',
  );
};

export const useGetCapTablePartnerUploadFileDownloadUrlsInfinite = (
  variables: Parameters<GetCapTablePartnerUploadFileDownloadUrlsMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetCapTablePartnerUploadFileDownloadUrlsMethod,
    GetCapTablePartnerUploadFileDownloadUrlsErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetCapTablePartnerUploadFileDownloadUrlsMethod,
    GetCapTablePartnerUploadFileDownloadUrlsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploadFileDownloadUrls(...args),
    GetCapTablePartnerUploadFileDownloadUrlsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploadFileDownloadUrls', 'GetCapTablePartnerUploadFileDownloadUrlsInfinite'],
        ...apiQueryConfig.GetCapTablePartnerUploadFileDownloadUrls,
        ...config?.queryConfig,
      },
    },
    'GetCapTablePartnerUploadFileDownloadUrls',
  );
};

export const useGetCapTablePartnerUploadFileDownloadUrlsLazy = (baseOptions?: {
  variables?: Parameters<GetCapTablePartnerUploadFileDownloadUrlsMethod>[0],
  config?: UseQueryConfig<
    GetCapTablePartnerUploadFileDownloadUrlsMethod,
    GetCapTablePartnerUploadFileDownloadUrlsErrorTypes
  >
}) => {
  return useLazyQuery<
    GetCapTablePartnerUploadFileDownloadUrlsMethod,
    GetCapTablePartnerUploadFileDownloadUrlsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploadFileDownloadUrls(...args),
    GetCapTablePartnerUploadFileDownloadUrlsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploadFileDownloadUrls'],
        ...apiQueryConfig.GetCapTablePartnerUploadFileDownloadUrls,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetCapTablePartnerUploadFileDownloadUrls',
  );
};


export type GetCapTablePartnerUploadFileValidationsMethod = CapTablePartnerUploadFileApi['getCapTablePartnerUploadFileValidations'];
export type GetCapTablePartnerUploadFileValidationsResponseType = MethodResult<GetCapTablePartnerUploadFileValidationsMethod>;

export const useGetCapTablePartnerUploadFileValidations = (
  variables: Parameters<GetCapTablePartnerUploadFileValidationsMethod>[0],
  config?: UseQueryConfig<
    GetCapTablePartnerUploadFileValidationsMethod,
    GetCapTablePartnerUploadFileValidationsErrorTypes
  >
) => {
  return useQuery<
    GetCapTablePartnerUploadFileValidationsMethod,
    GetCapTablePartnerUploadFileValidationsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploadFileValidations(...args),
    GetCapTablePartnerUploadFileValidationsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploadFileValidations'],
        ...apiQueryConfig.GetCapTablePartnerUploadFileValidations,
        ...config?.queryConfig
      },
    },
    'GetCapTablePartnerUploadFileValidations',
  );
};

export const useGetCapTablePartnerUploadFileValidationsInfinite = (
  variables: Parameters<GetCapTablePartnerUploadFileValidationsMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetCapTablePartnerUploadFileValidationsMethod,
    GetCapTablePartnerUploadFileValidationsErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetCapTablePartnerUploadFileValidationsMethod,
    GetCapTablePartnerUploadFileValidationsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploadFileValidations(...args),
    GetCapTablePartnerUploadFileValidationsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploadFileValidations', 'GetCapTablePartnerUploadFileValidationsInfinite'],
        ...apiQueryConfig.GetCapTablePartnerUploadFileValidations,
        ...config?.queryConfig,
      },
    },
    'GetCapTablePartnerUploadFileValidations',
  );
};

export const useGetCapTablePartnerUploadFileValidationsLazy = (baseOptions?: {
  variables?: Parameters<GetCapTablePartnerUploadFileValidationsMethod>[0],
  config?: UseQueryConfig<
    GetCapTablePartnerUploadFileValidationsMethod,
    GetCapTablePartnerUploadFileValidationsErrorTypes
  >
}) => {
  return useLazyQuery<
    GetCapTablePartnerUploadFileValidationsMethod,
    GetCapTablePartnerUploadFileValidationsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploadFileValidations(...args),
    GetCapTablePartnerUploadFileValidationsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploadFileValidations'],
        ...apiQueryConfig.GetCapTablePartnerUploadFileValidations,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetCapTablePartnerUploadFileValidations',
  );
};

