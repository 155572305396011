import { EMPTY_VALUE } from '../../components/atoms/Typography';

export const formatContactInformation = (
  contactInformation?: {
    address_line_1?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    state?: { name?: string | null };
    zip?: string | null;
    country?: { name?: string | null } | null;
  } | null
) => {
  if (!contactInformation) {
    return EMPTY_VALUE;
  }

  const { address_line_1, address_line_2, city, state, zip, country } =
    contactInformation;

  return [address_line_1, address_line_2, city, state?.name, zip, country?.name]
    .filter(Boolean)
    .join(', ');
};
