/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IssuerPortfolioPlot } from '../model';
// @ts-ignore
import { PortfolioPlot } from '../model';
/**
 * PortfolioPlotApi - axios parameter creator
 * @export
 */
export const PortfolioPlotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary portfolio company (port co) data plot points
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioCompanyPlot: async (issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('portfolioCompanyPlot', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/investor/issuer/{issuer_id}/portfolio_plot`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary plot all the mark points for your portfolio value or for a given company
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [issuerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioPlot: async (xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, issuerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/investor/portfolio_plot`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (issuerId !== undefined) {
                localVarQueryParameter['issuer_id'] = issuerId;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioPlotApi - functional programming interface
 * @export
 */
export const PortfolioPlotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioPlotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary portfolio company (port co) data plot points
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioCompanyPlot(issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerPortfolioPlot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioCompanyPlot(issuerId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary plot all the mark points for your portfolio value or for a given company
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [issuerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioPlot(xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, issuerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortfolioPlot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioPlot(xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, issuerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioPlotApi - factory interface
 * @export
 */
export const PortfolioPlotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioPlotApiFp(configuration)
    return {
        /**
         * 
         * @summary portfolio company (port co) data plot points
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioCompanyPlot(issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<IssuerPortfolioPlot> {
            return localVarFp.portfolioCompanyPlot(issuerId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary plot all the mark points for your portfolio value or for a given company
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [issuerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioPlot(xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, issuerId?: string, options?: any): AxiosPromise<PortfolioPlot> {
            return localVarFp.portfolioPlot(xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, issuerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for portfolioCompanyPlot operation in PortfolioPlotApi.
 * @export
 * @interface PortfolioPlotApiPortfolioCompanyPlotRequest
 */
export interface PortfolioPlotApiPortfolioCompanyPlotRequest {
    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioCompanyPlot
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioCompanyPlot
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioCompanyPlot
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioCompanyPlot
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioCompanyPlot
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for portfolioPlot operation in PortfolioPlotApi.
 * @export
 * @interface PortfolioPlotApiPortfolioPlotRequest
 */
export interface PortfolioPlotApiPortfolioPlotRequest {
    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioPlot
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioPlot
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioPlot
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioPlot
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioPlotApiPortfolioPlot
     */
    readonly issuerId?: string
}

/**
 * PortfolioPlotApi - object-oriented interface
 * @export
 * @class PortfolioPlotApi
 * @extends {BaseAPI}
 */
export class PortfolioPlotApi extends BaseAPI {
    /**
     * 
     * @summary portfolio company (port co) data plot points
     * @param {PortfolioPlotApiPortfolioCompanyPlotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPlotApi
     */
    public portfolioCompanyPlot(requestParameters: PortfolioPlotApiPortfolioCompanyPlotRequest, options?: AxiosRequestConfig) {
        return PortfolioPlotApiFp(this.configuration).portfolioCompanyPlot(requestParameters.issuerId, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary plot all the mark points for your portfolio value or for a given company
     * @param {PortfolioPlotApiPortfolioPlotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioPlotApi
     */
    public portfolioPlot(requestParameters: PortfolioPlotApiPortfolioPlotRequest = {}, options?: AxiosRequestConfig) {
        return PortfolioPlotApiFp(this.configuration).portfolioPlot(requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, requestParameters.issuerId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type PortfolioCompanyPlotErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PortfolioCompanyPlotErrorCodes = [
];

export type PortfolioPlotErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PortfolioPlotErrorCodes = [
];


