import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useMountedState } from '@npm/utils';

import { CypressDataIds } from '../../../constants';
import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { truncate } from '../../../utils/formatters';
import { useHandleApplePencilTouch } from '../../../utils/handleAppleTouch';
import { Button } from '../../atoms/Button';
import { Flex } from '../../atoms/common';
import { ErrorSkeleton } from '../../atoms/ErrorSkeleton';
import { Icon } from '../../atoms/Icon';
import { Skeleton } from '../../atoms/Skeleton';
import { Heading } from '../../atoms/Typography';
import { AlertContainer } from '../../molecules/AlertContainer';
import { useLayoutConfig } from '../Layout';

import * as S from './Drawer.styles';
import { useDrawerMaskScrollPropagation } from './Drawer.hooks';
import {
  FLOATING_BUTTON_HEIGHT,
  FLOATING_BUTTON_HEIGHT_MOBILE,
} from './Drawer.styles';
import {
  DRAWER_CONTENT_ID,
  DRAWER_HEADER_CLASSNAME,
  type DrawerProps,
} from './Drawer.types';
import { DrawerBreadcrumbs } from './DrawerBreadcrumbs';
import { ScrollIndicator } from './ScrollIndicator';

const DRAWER_MAX_WIDTH = 440;

export const Drawer = ({
  onClose,
  title,
  titleHeadingProps,
  children,
  isLoading,
  submitButtonProps,
  horizontalPadding = 'lg',
  footer,
  footerHeight,
  icon,
  id,
  alertContainerId,
  className,
  noHeader,
  getContainer,
  isFullHeight,
  backToRoute,
  isFooterFixed,
  extraHeaderContent,
  ...restProps
}: DrawerProps) => {
  const getMountedState = useMountedState();

  const layoutConfig = useLayoutConfig();
  const handleApplePencil = useHandleApplePencilTouch(onClose);
  const { isMobile } = useBreakpoints();
  const fullHeight = isFullHeight != null ? isFullHeight : isMobile;

  const [hasError, setHasError] = useState(false);

  const showSubmitButton =
    submitButtonProps?.onClick || submitButtonProps?.htmlType;

  const hasFooter = !!(footer || showSubmitButton);
  const renderFooter = () => {
    if (isLoading) return null;
    if (footer)
      return (
        <S.FooterWrapper
          $hasPadding={false}
          $height={footerHeight}
          $isFixed={isFooterFixed}
        >
          {footer}
        </S.FooterWrapper>
      );

    if (showSubmitButton)
      return (
        <S.FooterWrapper $isFixed={isFooterFixed}>
          <Button block {...submitButtonProps} {...handleApplePencil}>
            {submitButtonProps?.children || 'Update ' + title}
          </Button>
        </S.FooterWrapper>
      );
    return null;
  };

  const bottomOffset =
    hasFooter && (footerHeight || isFooterFixed || !isMobile)
      ? (footerHeight ??
        (isMobile ? FLOATING_BUTTON_HEIGHT_MOBILE : FLOATING_BUTTON_HEIGHT))
      : 0;

  const handleClose = e => {
    const drawerMask = document.querySelector('.ant-drawer-mask');

    if (drawerMask) {
      drawerMask.setAttribute('data-dd-action-name', 'Close Drawer');
    }

    onClose?.(e, 'mask');
  };

  // Propagate scroll events to the underlying scrollable element
  // so that the antd drawer mask doesn't swallow them
  useDrawerMaskScrollPropagation();

  return (
    <S.Drawer
      className={className}
      width={isMobile ? '100%' : DRAWER_MAX_WIDTH}
      destroyOnClose
      mask={true}
      maskClosable={!isMobile}
      onClose={handleClose}
      getContainer={
        getContainer !== undefined ? getContainer : isMobile ? 'body' : false
      }
      $layoutConfig={layoutConfig}
      $isFullHeight={fullHeight}
      data-dd-action-name="Drawer"
      {...restProps}
    >
      <>
        <S.Wrapper
          id={id}
          $footerHeight={footerHeight}
          data-cy={restProps['data-cy']}
        >
          {!noHeader && (
            <S.HeaderWrap
              className={DRAWER_HEADER_CLASSNAME}
              $horizontalPadding={horizontalPadding}
            >
              <S.HeaderButton
                type="button"
                onClick={e => onClose?.(e, 'button')}
                data-cy={CypressDataIds.Drawer.CloseButton}
                {...handleApplePencil}
              >
                {icon ? icon : <Icon name="chevron-left" size="xs" />}
              </S.HeaderButton>

              {title && (
                <Heading
                  style={{ width: '100%' }}
                  variant="h2"
                  as="div"
                  {...titleHeadingProps}
                >
                  <Flex gap="sm" style={{ width: '100%' }}>
                    <DrawerBreadcrumbs
                      title={backToRoute}
                      onBreadcrumbClick={() => onClose?.(undefined, 'button')}
                    />
                    {typeof title === 'string'
                      ? truncate(
                          title,
                          backToRoute ? 50 : 65,
                          '...',
                          backToRoute ? 35 : 50
                        )
                      : title}
                  </Flex>
                </Heading>
              )}

              {extraHeaderContent}
            </S.HeaderWrap>
          )}
          <S.Content
            id={DRAWER_CONTENT_ID}
            className={DRAWER_CONTENT_ID}
            $bottomPadding={bottomOffset}
            $horizontalPadding={horizontalPadding}
          >
            <AlertContainer id={alertContainerId} />
            {isLoading || (isLoading != null && !getMountedState()) ? (
              <Skeleton.Base />
            ) : (
              <ErrorBoundary
                FallbackComponent={ErrorSkeleton}
                onError={() => setHasError(true)}
                onReset={() => setHasError(false)}
              >
                {children}
              </ErrorBoundary>
            )}
            <ScrollIndicator bottomOffset={bottomOffset} />
          </S.Content>
        </S.Wrapper>
        {renderFooter()}
      </>
    </S.Drawer>
  );
};
