import { InputFormItem as FormItemBase } from '@npm/core/ui/components/atoms/FormItem';
import styled from 'styled-components';

export const FormItem = styled(FormItemBase).attrs({
  labelPosition: 'top',
})`
  & {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;
