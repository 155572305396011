import styled from 'styled-components';

const OrderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 0 0 auto;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.info.typography.primary};
`;

export const InstructionsStyles = {
  OrderLabel,
};
