/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



  /**
 * 
 * @export
 * @interface PartnerIssuerStatusSimple
 */
export interface PartnerIssuerStatusSimple {
    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerStatusSimple
     */
    'issuer_legal_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerStatusSimple
     */
    'external_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerStatusSimple
     */
    'onboarding_status'?: PartnerIssuerStatusSimpleOnboardingStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerIssuerStatusSimple
     */
    'active'?: boolean;
}

export const PartnerIssuerStatusSimpleOnboardingStatusEnum = {
    Pending: 'pending',
    Complete: 'complete'
} as const;

export type PartnerIssuerStatusSimpleOnboardingStatusEnum = typeof PartnerIssuerStatusSimpleOnboardingStatusEnum[keyof typeof PartnerIssuerStatusSimpleOnboardingStatusEnum];


