import React from 'react';
import { useParams } from 'react-router-dom';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { useEventShow, useSubmissionShow } from '@npm/data-access';
import { Skeleton } from 'antd';

import * as S from './CompanyPageLogo.styles';

export const CompanyPageLogo = ({
  issuerEntity,
  isLoading,
}: {
  issuerEntity?: {
    logo_url?: string | null;
    name?: string | null;
  };
  isLoading?: boolean;
}) => {
  return (
    <S.CompanyContainer>
      {!isLoading ? (
        <>
          <CompanyLogo
            url={issuerEntity?.logo_url}
            size="sm"
            title={issuerEntity?.name}
          />
          <Text size="sm" colorVariant="primary">
            {issuerEntity?.name}
          </Text>
        </>
      ) : (
        <>
          <Skeleton.Avatar size={20} />
          <Skeleton paragraph={{ rows: 1, width: 60 }} title={false} active />
        </>
      )}
    </S.CompanyContainer>
  );
};

export const EventPageCompanyLogo = () => {
  const { programId } = useParams();

  const { data, isLoading } = useEventShow(
    {
      id: Number(programId),
      includeField: [
        'assets',
        'issuance',
        'issuer_entity',
        'submission_statistics',
        'settings',
        'order_settings',
      ],
    },
    {
      onError: async error => {
        if (error.status !== -1 || error.originalStatus !== 403) {
          throw error;
        }
        // the 403 error is handled on a ProgramDetail level
      },
    }
  );

  return (
    <CompanyPageLogo issuerEntity={data?.issuer_entity} isLoading={isLoading} />
  );
};

export const SubmissionPageCompanyLogo = () => {
  const { submissionId } = useParams();

  const { data, isLoading } = useSubmissionShow({
    submissionId: Number(submissionId),
    includeField: ['issuer_entity'],
  });

  return (
    <CompanyPageLogo issuerEntity={data?.issuer_entity} isLoading={isLoading} />
  );
};
