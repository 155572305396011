import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { type CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { CbOrderBuySell, Codebooks, useCodebook } from '@npm/data-access';
import styled from 'styled-components';

type Props = {
  value?: string | undefined;
  onChange?: (value: string) => void;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const SideSelect = ({ value, onChange }: Props) => {
  const { data: sideData } = useCodebook({
    type: Codebooks.ORDER_BUY_SELL,
  });

  if (!sideData) return null;

  return (
    <Card>
      <Flex gap="sm">
        {sideData?.codebooks.map(({ code, name }) => (
          <Button
            variant={value === code ? 'outline' : 'text'}
            color={code === CbOrderBuySell.items.sell ? 'error' : 'success'}
            key={code}
            onClick={() => onChange(code)}
            block
          >
            {name}
          </Button>
        ))}
      </Flex>
    </Card>
  );
};

const Card = styled(CardBase)`
  .card-content {
    padding: ${({ theme }) => theme.spacing.sm}px;
    padding-right: ${({ theme }) => theme.spacing.md}px;
  }
`;
