/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Job } from '../model';
/**
 * PortfolioExtractApi - axios parameter creator
 * @export
 */
export const PortfolioExtractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary extract and match issuer and holding types from uploaded file
         * @param {File} file 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioExtractAndMatch: async (file: File, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('portfolioExtractAndMatch', 'file', file)
            const localVarPath = changeUrl(`/api/investor/upload_and_extract_portfolio`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioExtractApi - functional programming interface
 * @export
 */
export const PortfolioExtractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioExtractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary extract and match issuer and holding types from uploaded file
         * @param {File} file 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioExtractAndMatch(file: File, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioExtractAndMatch(file, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioExtractApi - factory interface
 * @export
 */
export const PortfolioExtractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioExtractApiFp(configuration)
    return {
        /**
         * 
         * @summary extract and match issuer and holding types from uploaded file
         * @param {File} file 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioExtractAndMatch(file: File, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<Job> {
            return localVarFp.portfolioExtractAndMatch(file, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for portfolioExtractAndMatch operation in PortfolioExtractApi.
 * @export
 * @interface PortfolioExtractApiPortfolioExtractAndMatchRequest
 */
export interface PortfolioExtractApiPortfolioExtractAndMatchRequest {
    /**
     * 
     * @type {File}
     * @memberof PortfolioExtractApiPortfolioExtractAndMatch
     */
    readonly file: File

    /**
     * 
     * @type {string}
     * @memberof PortfolioExtractApiPortfolioExtractAndMatch
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioExtractApiPortfolioExtractAndMatch
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioExtractApiPortfolioExtractAndMatch
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioExtractApiPortfolioExtractAndMatch
     */
    readonly xNPMAuthorization?: string
}

/**
 * PortfolioExtractApi - object-oriented interface
 * @export
 * @class PortfolioExtractApi
 * @extends {BaseAPI}
 */
export class PortfolioExtractApi extends BaseAPI {
    /**
     * 
     * @summary extract and match issuer and holding types from uploaded file
     * @param {PortfolioExtractApiPortfolioExtractAndMatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioExtractApi
     */
    public portfolioExtractAndMatch(requestParameters: PortfolioExtractApiPortfolioExtractAndMatchRequest, options?: AxiosRequestConfig) {
        return PortfolioExtractApiFp(this.configuration).portfolioExtractAndMatch(requestParameters.file, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }
}

export type PortfolioExtractAndMatchErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PortfolioExtractAndMatchErrorCodes = [
];


