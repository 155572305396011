import React, { useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { type FolderAggregate } from '@npm/data-access';
import { useForm } from 'antd/lib/form/Form';

export type EditFolderFormValues = {
  name: string;
};

const EDIT_FOLDER_FORM_ID = 'edit-document-form';

type Props = {
  folder: FolderAggregate;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (id: number, values: EditFolderFormValues) => Promise<void>;
};

export const EditFolderDrawer = ({
  folder,
  isOpen,
  onClose,
  onSubmit,
}: Props) => {
  const [form] = useForm<EditFolderFormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const fields = await form.validateFields();
      await onSubmit?.(folder.id, fields);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      open={isOpen}
      title="Edit Folder"
      submitButtonProps={{
        children: 'Save',
        form: EDIT_FOLDER_FORM_ID,
        htmlType: 'submit',
        'data-cy': CypressDataIds.DataRooms.EditFolderDrawer.SubmitButton,
        loading: isSubmitting,
      }}
      onClose={handleClose}
      destroyOnClose
    >
      <Form<EditFolderFormValues>
        id={EDIT_FOLDER_FORM_ID}
        form={form}
        initialValues={folder}
        data-cy={CypressDataIds.DataRooms.EditFolderDrawer.Form}
        onFinish={handleSubmit}
      >
        <InputFormItem
          name="name"
          label="Folder Name"
          rules={[VALIDATION_RULES.required('Name')]}
        >
          <Input placeholder="Folder Name" />
        </InputFormItem>
      </Form>
    </Drawer>
  );
};
