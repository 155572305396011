import { Progress as AntdProgress } from 'antd';
import styled from 'styled-components';

import { BodyTextStyles } from '../Typography';

export const Progress = styled(AntdProgress)`
  display: flex;
  &.ant-progress-circle {
    .ant-progress-text {
      margin-left: 0;
    }
  }
  .ant-progress-text {
    ${BodyTextStyles};
    margin-left: ${({ theme }) => theme.spacing.md}px;
  }
`;
