import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

import { DESKTOP_HEIGHT, MOBILE_HEIGHT } from './ProgressBar.constants';

export const StepsContainer = styled.div`
  width: 100%;

  & > :not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.sm}px;

    ${({ theme }) => useBreakpoint(theme).mobile`
      margin-left: ${theme.spacing.xs}px;
  `}
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Counter = styled.div`
  margin: 0 0 ${({ theme }) => `${theme.spacing.xs}px ${theme.spacing.sm}px`};
`;

export const Progress = styled.div`
  min-height: ${DESKTOP_HEIGHT}px;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing.sm}px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => useBreakpoint(theme).mobile`
    min-height: ${MOBILE_HEIGHT}px;
    padding-bottom: ${theme.spacing.xs}px;
  `}

  & > div {
    display: flex;
  }
`;
