import React, { type ComponentProps } from 'react';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { TextArea } from '@npm/core/ui/components/atoms/Input';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type HoldingsStatusRequestContract,
  useHoldingStatusUpdate,
} from '@npm/data-access';

import * as S from './HoldingStatusChangeModal.styles';
import {
  getModalConfig,
  getNewHoldingState,
} from './HoldingStatusChangeModal.utils';

type Props = {
  id: number;
  certificate_number: string;
  type: 'reject' | 'revert' | 'verify' | 'pre-verify';
  onSuccess?: () => void;
} & ComponentProps<typeof Modal>;

type RejectRequestForm = Pick<
  HoldingsStatusRequestContract,
  'correction_instructions'
> & {
  confirm_reject?: boolean;
};

export const HoldingStatusChangeModal = ({
  id,
  type,
  certificate_number,
  onSuccess,
  ...modalProps
}: Props) => {
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute: updateHoldingStatus, isLoading } = useHoldingStatusUpdate();

  const [form] = Form.useForm<RejectRequestForm>();

  const isConfirmed = Form.useWatch('confirm_reject', form);
  const correctionInstructions = Form.useWatch('correction_instructions', form);

  const isRejectDisabled =
    type === 'reject' && !(isConfirmed && correctionInstructions);

  const onOk = async e => {
    let values: RejectRequestForm;
    try {
      values = await form.validateFields();
    } catch (e) {
      return handleValidationError(form, e);
    }

    try {
      await withShowApiErrorMiddleware(updateHoldingStatus)({
        id: id.toString(),
        holdingsStatusRequestContract: {
          holding_state: getNewHoldingState(type),
          correction_instructions: values.correction_instructions,
        },
      });
      modalProps.onOk(e);
      Notification.success({
        message: `You have updated the holding ${certificate_number}`,
      });
      onSuccess?.();
      form.resetFields();
    } catch (e) {
      console.error(e);
    }
  };

  const renderCorrectionInstructionsForm = () => (
    <S.CorrectionInstructionsWrapper>
      <Form form={form}>
        <FormItem
          labelPosition="top"
          name="correction_instructions"
          label="Correction Instructions"
          rules={[VALIDATION_RULES.required('Correction Instructions')]}
        >
          <TextArea placeholder="E.g. The holding quantity provided does not match our records." />
        </FormItem>
        <FormItem
          valuePropName="checked"
          name="confirm_reject"
          rules={[
            VALIDATION_RULES.disclaimerAccepted('Confirmation is required'),
          ]}
        >
          <Checkbox
            label={`I confirm I want to reject the holding ${
              certificate_number ?? ''
            }`}
          />
        </FormItem>
      </Form>
    </S.CorrectionInstructionsWrapper>
  );

  return (
    <Modal
      {...modalProps}
      title={getModalConfig(certificate_number, type).title}
      okText={getModalConfig(certificate_number, type).confirm}
      okButtonProps={{
        loading: isLoading,
        disabled: isRejectDisabled,
        variant: type === 'reject' ? 'outline' : 'default',
        color: type === 'reject' ? 'error' : 'info',
        icon:
          type === 'reject' ? <Icon name="x-close" /> : <Icon name="check" />,
      }}
      cancelText="Not now"
      size="md"
      onOk={onOk}
    >
      <Text size="sm">
        {getModalConfig(certificate_number, type).description}
      </Text>
      {type === 'reject' && renderCorrectionInstructionsForm()}
    </Modal>
  );
};
