/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminIssuerAggregate } from '../model';
// @ts-ignore
import { AdminIssuerIndex } from '../model';
// @ts-ignore
import { AdminIssuerSupplyStatIndex } from '../model';
// @ts-ignore
import { Issuer } from '../model';
// @ts-ignore
import { IssuerIndex } from '../model';
/**
 * IssuersApi - axios parameter creator
 * @export
 */
export const IssuersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get issuer
         * @param {string} issuerId issuers &#x60;npm_guid&#x60;
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorIssuerShow: async (issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('investorIssuerShow', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/investor/issuers/{issuer_id}`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all issuers
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [search] Optionally search company by name
         * @param {boolean} [isVisible] True if the result should only contain issuers that ARE NOT defunct/public, false if the result should only contain issuers that ARE defunct/public.
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorIssuersIndex: async (xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, search?: string, isVisible?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/investor/issuers/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (isVisible !== undefined) {
                localVarQueryParameter['is_visible'] = isVisible;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary individual issuer
         * @param {string} issuerId 
         * @param {boolean} [getAccounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerShow: async (issuerId: string, getAccounts?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerShow', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (getAccounts !== undefined) {
                localVarQueryParameter['get_accounts'] = getAccounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all issuers the current admin user has access to
         * @param {string} [search] 
         * @param {Array<string>} [industries] 
         * @param {boolean} [hasSecurities] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuersIndex: async (search?: string, industries?: Array<string>, hasSecurities?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/issuers/`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (industries) {
                localVarQueryParameter['industries'] = industries.join(COLLECTION_FORMATS.csv);
            }

            if (hasSecurities !== undefined) {
                localVarQueryParameter['has_securities'] = hasSecurities;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists issuer supply stats
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuersSupplyStatsIndex: async (page?: number, size?: number, startDate?: string, endDate?: string, sortDirection?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/issuers/supply_stats`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssuersApi - functional programming interface
 * @export
 */
export const IssuersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssuersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get issuer
         * @param {string} issuerId issuers &#x60;npm_guid&#x60;
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorIssuerShow(issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Issuer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorIssuerShow(issuerId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all issuers
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [search] Optionally search company by name
         * @param {boolean} [isVisible] True if the result should only contain issuers that ARE NOT defunct/public, false if the result should only contain issuers that ARE defunct/public.
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorIssuersIndex(xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, search?: string, isVisible?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorIssuersIndex(xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, search, isVisible, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary individual issuer
         * @param {string} issuerId 
         * @param {boolean} [getAccounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerShow(issuerId: string, getAccounts?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminIssuerAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerShow(issuerId, getAccounts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all issuers the current admin user has access to
         * @param {string} [search] 
         * @param {Array<string>} [industries] 
         * @param {boolean} [hasSecurities] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuersIndex(search?: string, industries?: Array<string>, hasSecurities?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminIssuerIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuersIndex(search, industries, hasSecurities, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists issuer supply stats
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuersSupplyStatsIndex(page?: number, size?: number, startDate?: string, endDate?: string, sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminIssuerSupplyStatIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuersSupplyStatsIndex(page, size, startDate, endDate, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssuersApi - factory interface
 * @export
 */
export const IssuersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssuersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get issuer
         * @param {string} issuerId issuers &#x60;npm_guid&#x60;
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorIssuerShow(issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<Issuer> {
            return localVarFp.investorIssuerShow(issuerId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all issuers
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [search] Optionally search company by name
         * @param {boolean} [isVisible] True if the result should only contain issuers that ARE NOT defunct/public, false if the result should only contain issuers that ARE defunct/public.
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorIssuersIndex(xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, search?: string, isVisible?: boolean, page?: number, size?: number, options?: any): AxiosPromise<IssuerIndex> {
            return localVarFp.investorIssuersIndex(xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, search, isVisible, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary individual issuer
         * @param {string} issuerId 
         * @param {boolean} [getAccounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerShow(issuerId: string, getAccounts?: boolean, options?: any): AxiosPromise<AdminIssuerAggregate> {
            return localVarFp.issuerShow(issuerId, getAccounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all issuers the current admin user has access to
         * @param {string} [search] 
         * @param {Array<string>} [industries] 
         * @param {boolean} [hasSecurities] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuersIndex(search?: string, industries?: Array<string>, hasSecurities?: boolean, page?: number, size?: number, options?: any): AxiosPromise<AdminIssuerIndex> {
            return localVarFp.issuersIndex(search, industries, hasSecurities, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists issuer supply stats
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuersSupplyStatsIndex(page?: number, size?: number, startDate?: string, endDate?: string, sortDirection?: 'asc' | 'desc', options?: any): AxiosPromise<AdminIssuerSupplyStatIndex> {
            return localVarFp.issuersSupplyStatsIndex(page, size, startDate, endDate, sortDirection, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for investorIssuerShow operation in IssuersApi.
 * @export
 * @interface IssuersApiInvestorIssuerShowRequest
 */
export interface IssuersApiInvestorIssuerShowRequest {
    /**
     * issuers &#x60;npm_guid&#x60;
     * @type {string}
     * @memberof IssuersApiInvestorIssuerShow
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuerShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuerShow
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuerShow
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuerShow
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for investorIssuersIndex operation in IssuersApi.
 * @export
 * @interface IssuersApiInvestorIssuersIndexRequest
 */
export interface IssuersApiInvestorIssuersIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * Optionally search company by name
     * @type {string}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly search?: string

    /**
     * True if the result should only contain issuers that ARE NOT defunct/public, false if the result should only contain issuers that ARE defunct/public.
     * @type {boolean}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly isVisible?: boolean

    /**
     * 
     * @type {number}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuersApiInvestorIssuersIndex
     */
    readonly size?: number
}

/**
 * Request parameters for issuerShow operation in IssuersApi.
 * @export
 * @interface IssuersApiIssuerShowRequest
 */
export interface IssuersApiIssuerShowRequest {
    /**
     * 
     * @type {string}
     * @memberof IssuersApiIssuerShow
     */
    readonly issuerId: string

    /**
     * 
     * @type {boolean}
     * @memberof IssuersApiIssuerShow
     */
    readonly getAccounts?: boolean
}

/**
 * Request parameters for issuersIndex operation in IssuersApi.
 * @export
 * @interface IssuersApiIssuersIndexRequest
 */
export interface IssuersApiIssuersIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof IssuersApiIssuersIndex
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof IssuersApiIssuersIndex
     */
    readonly industries?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof IssuersApiIssuersIndex
     */
    readonly hasSecurities?: boolean

    /**
     * 
     * @type {number}
     * @memberof IssuersApiIssuersIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuersApiIssuersIndex
     */
    readonly size?: number
}

/**
 * Request parameters for issuersSupplyStatsIndex operation in IssuersApi.
 * @export
 * @interface IssuersApiIssuersSupplyStatsIndexRequest
 */
export interface IssuersApiIssuersSupplyStatsIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuersApiIssuersSupplyStatsIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuersApiIssuersSupplyStatsIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof IssuersApiIssuersSupplyStatsIndex
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof IssuersApiIssuersSupplyStatsIndex
     */
    readonly endDate?: string

    /**
     * defaults to desc
     * @type {'asc' | 'desc'}
     * @memberof IssuersApiIssuersSupplyStatsIndex
     */
    readonly sortDirection?: 'asc' | 'desc'
}

/**
 * IssuersApi - object-oriented interface
 * @export
 * @class IssuersApi
 * @extends {BaseAPI}
 */
export class IssuersApi extends BaseAPI {
    /**
     * 
     * @summary Get issuer
     * @param {IssuersApiInvestorIssuerShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuersApi
     */
    public investorIssuerShow(requestParameters: IssuersApiInvestorIssuerShowRequest, options?: AxiosRequestConfig) {
        return IssuersApiFp(this.configuration).investorIssuerShow(requestParameters.issuerId, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all issuers
     * @param {IssuersApiInvestorIssuersIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuersApi
     */
    public investorIssuersIndex(requestParameters: IssuersApiInvestorIssuersIndexRequest = {}, options?: AxiosRequestConfig) {
        return IssuersApiFp(this.configuration).investorIssuersIndex(requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, requestParameters.search, requestParameters.isVisible, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary individual issuer
     * @param {IssuersApiIssuerShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuersApi
     */
    public issuerShow(requestParameters: IssuersApiIssuerShowRequest, options?: AxiosRequestConfig) {
        return IssuersApiFp(this.configuration).issuerShow(requestParameters.issuerId, requestParameters.getAccounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all issuers the current admin user has access to
     * @param {IssuersApiIssuersIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuersApi
     */
    public issuersIndex(requestParameters: IssuersApiIssuersIndexRequest = {}, options?: AxiosRequestConfig) {
        return IssuersApiFp(this.configuration).issuersIndex(requestParameters.search, requestParameters.industries, requestParameters.hasSecurities, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists issuer supply stats
     * @param {IssuersApiIssuersSupplyStatsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuersApi
     */
    public issuersSupplyStatsIndex(requestParameters: IssuersApiIssuersSupplyStatsIndexRequest = {}, options?: AxiosRequestConfig) {
        return IssuersApiFp(this.configuration).issuersSupplyStatsIndex(requestParameters.page, requestParameters.size, requestParameters.startDate, requestParameters.endDate, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InvestorIssuerShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorIssuerShowErrorCodes = [
];

export type InvestorIssuersIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorIssuersIndexErrorCodes = [
];

export type IssuerShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerShowErrorCodes = [
];

export type IssuersIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuersIndexErrorCodes = [
];

export type IssuersSupplyStatsIndexErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuersSupplyStatsIndexErrorCodes = [
    422,
];


