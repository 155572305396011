import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { EMPTY_VALUE } from '@npm/core/ui/components/atoms/Typography';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';

import { RadioButton } from './OrderPreferences.styles';

type Props = {
  value?: boolean;
  onItemChange: () => void;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const DataRoomFormSection = ({
  value,
  onItemChange,
  ...props
}: Props) => {
  return (
    <CollapsibleFormSection.Item
      label="Data Room"
      optional
      preview={value == null ? EMPTY_VALUE : value ? 'Yes' : 'No'}
      key="dataRoom"
      name="dataRoom"
      {...props}
    >
      <FormItem name="dataRoom" marginBottom="sm">
        <RadioGroup onChange={() => onItemChange()}>
          <Radio value={true} label="Yes" />
          <Radio value={false} label="No" />
          {value != null && <RadioButton value={null} label="Deselect" />}
        </RadioGroup>
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};
