// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CashAccountApi,
  AccountListErrorCodes,
  AccountListErrorTypes,
  AccountOpenErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CashAccountApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountListMethod = CashAccountApi['accountList'];
export type AccountListResponseType = MethodResult<AccountListMethod>;

export const useAccountList = (
  variables: Parameters<AccountListMethod>[0],
  config?: UseQueryConfig<
    AccountListMethod,
    AccountListErrorTypes
  >
) => {
  return useQuery<
    AccountListMethod,
    AccountListErrorTypes
  >(
    (...args) => api.accountList(...args),
    AccountListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountList'],
        ...apiQueryConfig.AccountList,
        ...config?.queryConfig
      },
    },
    'AccountList',
  );
};

export const useAccountListInfinite = (
  variables: Parameters<AccountListMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountListMethod,
    AccountListErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountListMethod,
    AccountListErrorTypes
  >(
    (...args) => api.accountList(...args),
    AccountListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountList', 'AccountListInfinite'],
        ...apiQueryConfig.AccountList,
        ...config?.queryConfig,
      },
    },
    'AccountList',
  );
};

export const useAccountListLazy = (baseOptions?: {
  variables?: Parameters<AccountListMethod>[0],
  config?: UseQueryConfig<
    AccountListMethod,
    AccountListErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountListMethod,
    AccountListErrorTypes
  >(
    (...args) => api.accountList(...args),
    AccountListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountList'],
        ...apiQueryConfig.AccountList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountList',
  );
};


export type AccountOpenMethod = CashAccountApi['accountOpen'];

export const useAccountOpen = () => {
  return useMutation<AccountOpenMethod>(
    (...args) => api.accountOpen(...args),
    AccountOpenErrorCodes,
    'AccountOpen',
    '/api/investor/accounts/{account_id}/cash_accounts',
    'post'
  );
};
