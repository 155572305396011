import { useCallback, useEffect, useMemo, useState } from 'react';

import { type PaginationProps } from 'antd';
import { NumberParam, useQueryParams } from 'use-query-params';

import { getPageSize } from '../../components/molecules/Table';

import {
  type Pagination,
  type PaginationQueryKey,
  type PaginationQueryParamReturnType,
  type PaginationReturnType,
  type Props,
} from './usePagination.types';

export const PAGINATION_QUERY_SUFFIX = 'Pagination';

export const usePaginationQueryParam = (
  key: PaginationQueryKey
): PaginationQueryParamReturnType => {
  const keyWithSuffix = `${key}${PAGINATION_QUERY_SUFFIX}`;
  const paramConfigMap = { [keyWithSuffix]: NumberParam };

  const [query, _setQuery] = useQueryParams(paramConfigMap);

  const setQuery = useCallback(
    value => _setQuery({ [keyWithSuffix]: value }, 'replaceIn'),
    [_setQuery, keyWithSuffix]
  );

  const resetQuery = useCallback(() => setQuery(undefined), [setQuery]);

  return { query: query?.[keyWithSuffix] ?? undefined, setQuery, resetQuery };
};

export const usePagination = <T extends Pagination>(
  key: PaginationQueryKey,
  {
    variables,
    setVariables,
    paginationData,
    resetQueryOnUnmount = true,
  }: Props<T>
): PaginationReturnType => {
  const { query, setQuery, resetQuery } = usePaginationQueryParam(key);

  const pagination = useMemo(() => {
    return {
      defaultCurrent: 1,
      defaultPageSize: getPageSize(),
      current: paginationData?.page ?? 1,
      total: paginationData?.total_records ?? 0,
      pageSize: paginationData?.size ?? getPageSize(),
    };
  }, [
    paginationData?.page,
    paginationData?.size,
    paginationData?.total_records,
  ]);

  // Update pagination if variables page and query page dont match
  const queryPage = query;
  if (queryPage && queryPage !== variables?.page) {
    setVariables(v => ({ ...v, page: queryPage }));
  }

  const updatePaginationVariables = useCallback(
    pagination => {
      setQuery(pagination.current);
      setVariables(v => ({
        ...v,
        page: pagination.current,
        size: pagination.pageSize,
      }));
    },
    [setQuery, setVariables]
  );

  // reset query on dismount
  useEffect(() => {
    return () => {
      if (resetQueryOnUnmount) {
        resetQuery();
      }
    };
  }, [resetQueryOnUnmount, resetQuery]);

  return { pagination, updatePaginationVariables, query, setQuery, resetQuery };
};

export const useFrontendPaginationWithQuery = (
  key: PaginationQueryKey,
  paginationData?: PaginationProps
) => {
  const [pagination, setPagination] = useState<PaginationProps>({
    defaultPageSize: getPageSize(),
    pageSize: paginationData?.pageSize ?? getPageSize(),
    current: 1,
    ...(paginationData || {}),
  });
  const { query, setQuery, resetQuery } = usePaginationQueryParam(key);

  if (query && query !== pagination?.current) {
    setPagination(v => ({ ...v, current: query }));
  }

  const updatePaginationVariables = useCallback(
    (currentPagination: PaginationProps) => {
      setQuery(currentPagination.current);
      setPagination(p => ({ ...p, ...currentPagination }));
    },
    [setQuery, setPagination]
  );

  return {
    updatePaginationVariables,
    pagination,
    query,
    resetQuery,
  };
};

export const useLocalPagination = <T extends Pagination>({
  paginationData,
}: Pick<Props<T>, 'paginationData'>): Pick<
  PaginationReturnType,
  'pagination'
> => {
  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: getPageSize(),
    current: paginationData?.page ?? 1,
    total: paginationData?.total_records ?? 0,
    pageSize: paginationData?.size ?? getPageSize(),
  };

  return {
    pagination,
  };
};
