import { datadogRum } from '@datadog/browser-rum';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { ModalKey, useQsModal } from '@npm/core/ui/components/molecules/Modal';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type AccountApiSecondMarketAccountCopyCreateRequest,
  type AccountShowAggregate,
  CbAccountUiMode,
  queryClient,
  useAccountCreate,
  useSecondMarketAccountCopyCreate,
  useWatchlistCreate,
} from '@npm/data-access';
import { useUserContextStore } from '@npm/features/auth/user/context';
import { useCurrentWorkstation } from '@npm/features/auth/user/role';
import type { FormInstance } from 'antd';

import { type OptInFormValues } from './OptInAccountSelection/OptInAccountSelection.types';

const handleSignatureErrorDatadog = e => {
  if (e?.errorFields?.some(error => error?.name?.includes('signature'))) {
    datadogRum.addAction('Invalid signature', {
      actionType: 'custom',
    });
  }
};

const handleSuccessDatadog = () => {
  datadogRum.addAction('OptIn success', {
    actionType: 'custom',
  });
};

export const useOptInForm = (
  form: FormInstance<OptInFormValues>,
  accountType: string,
  watchlist: number[]
) => {
  const history = useHistory();
  const { execute: createAccountCopy } = useSecondMarketAccountCopyCreate();
  const { clearAlerts, withShowApiErrorMiddleware } = useAlerts();
  const { handleClose } = useQsModal(ModalKey.OPT_IN_MODAL);

  const workstation = useCurrentWorkstation();
  const setRole = useUserContextStore(store => store.setRole);
  const updateUserActiveRole = useUserContextStore(
    store => store.updateUserActiveRole
  );
  const user = useUserContextStore(store => store.user);

  const { execute: createAccount } = useAccountCreate();
  const { execute: createWatchlist } = useWatchlistCreate();

  const handleCopyAccount = async () => {
    // clear hanging alerts if any
    clearAlerts();

    let accountId: number;

    const selectedEntity = form.getFieldValue('selectedEntity');
    if (selectedEntity) {
      accountId = selectedEntity;
    } else {
      accountId = user?.person?.account_id || user?.active_role?.subject?.id;
    }

    const isEntity = !!selectedEntity;
    const body: AccountApiSecondMarketAccountCopyCreateRequest = {
      accountId,
      accountsSecondMarketAccountCopyCreateRequestContract: {
        ...(!isEntity && {
          account_ui_mode: CbAccountUiMode.items.seller,
        }),
      },
    };

    try {
      await form.validateFields();
    } catch (e) {
      handleSignatureErrorDatadog(e);
      handleValidationError(form, e);
      return;
    }

    try {
      const accountResponse: AccountShowAggregate =
        await withShowApiErrorMiddleware(createAccountCopy)(body, {
          onComplete: () => {
            Notification.success({
              message: 'Congratulations! You have created a new account',
            });
          },
        });

      if (watchlist.length > 0) {
        await withShowApiErrorMiddleware(createWatchlist)({
          watchlistCreateRequestContract: {
            account_id: accountResponse.id,
            issuer_entity_id: watchlist,
          },
        });
      }

      // Despite the query is invalidated, we need to make sure refetching is finished
      // before we redirect the user to a landing page
      await queryClient.refetchQueries({
        queryKey: ['UserRoleIndex'],
      });

      handleSuccessDatadog();
      handleClose();
      history.replace('/second-market/investor');
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateAccount = async () => {
    // clear hanging alerts if any
    clearAlerts();

    let values: OptInFormValues;

    try {
      values = await form.validateFields();
    } catch (e) {
      handleSignatureErrorDatadog(e);
      handleValidationError(form, e);
      return;
    }

    try {
      const { legalName } = values;

      const accountResponse: AccountShowAggregate =
        await withShowApiErrorMiddleware(createAccount)({
          accountCreateRequestContract: {
            name: legalName,
            type: { code: accountType },
          },
        });

      if (watchlist.length > 0) {
        await withShowApiErrorMiddleware(createWatchlist)({
          watchlistCreateRequestContract: {
            account_id: accountResponse.id,
            issuer_entity_id: watchlist,
          },
        });
      }

      Notification.success({
        message: `Congratulations! You have created a new account.'`,
      });

      setRole(accountResponse.user_role, workstation);
      updateUserActiveRole(accountResponse.user_role);

      // Despite the query is invalidated, we need to make sure refetching is finished
      // before we redirect the user to a landing page
      await queryClient.refetchQueries({
        queryKey: ['UserRoleIndex'],
      });

      handleSuccessDatadog();
      handleClose();
      history.replace('/second-market/investor');
    } catch (e) {
      Notification.error({
        message: 'Account creation failed. Please try again later',
      });
      console.error(e);
    }

    return undefined;
  };

  return { handleCreateAccount, handleCopyAccount };
};
