import { Label } from '@npm/core/ui/components/atoms/Label';
import { Select as SelectBase } from '@npm/core/ui/components/atoms/Select';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import styled from 'styled-components';

export const Select = styled(SelectBase)`
  &&& .ant-select-selection-overflow {
    gap: ${({ theme }) => theme.spacing.xs}px;
  }

  .ant-select-item-option-selected .ant-select-item-option-state {
    display: none;
  }
`;

export const SellMultipleTag = styled(Text)`
  padding-left: ${({ theme }) => theme.spacing.xs}px;
  padding-right: ${({ theme }) => theme.spacing.xs}px;
`;

export const QuantityLabel = styled(Label)`
  &&&.ant-tag {
    padding: 1px ${({ theme }) => theme.spacing.xs}px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  }
`;
