import { type LabelVariant } from '@npm/utils';
import { Tag as AntTag } from 'antd';
import styled, { css, type DefaultTheme } from 'styled-components';

import { centerFlex } from '../../../utils/style';
import { SmallTextStyles } from '../Typography';

const getVariant = (variant: LabelVariant, theme: DefaultTheme) => {
  return {
    general: css`
      background: ${theme.color.general.layout.zero};
      color: ${theme.color.general.typography.secondary};
      border-color: ${theme.color.general.borders.secondary};
    `,
    error: css`
      background: ${theme.color.error.backgrounds.secondaryDefault};
      color: ${theme.color.error.typography.primary};
      border-color: ${theme.color.error.borders.primary};
    `,
    success: css`
      background: ${theme.color.success.backgrounds.secondaryDefault};
      color: ${theme.color.success.typography.primary};
      border-color: ${theme.color.success.borders.primary};
    `,
    warning: css`
      background: ${theme.color.warning.backgrounds.secondaryDefault};
      color: ${theme.color.warning.typography.primary};
      border-color: ${theme.color.warning.borders.primary};
    `,
    info: css`
      background: ${theme.color.info.backgrounds.secondaryDefault};
      color: ${theme.color.info.typography.primary};
      border-color: ${theme.color.info.borders.primary};
    `,
    disabled: css`
      background: ${theme.color.general.layout.zero};
      color: ${theme.color.general.typography.tertiary};
      border-color: ${theme.color.general.borders.secondary};
    `,
  }[variant];
};

export const StyledLabel = styled(AntTag)<{
  $variant: LabelVariant;
  $round: boolean;
  $bordered?: boolean;
  $dashed?: boolean;
  $noBg?: boolean;
  $disabled?: boolean;
}>`
  &,
  &&.ant-tag {
    ${SmallTextStyles};
    border: ${({ $bordered }) => ($bordered ? '1px solid' : 'none')};
    border-radius: ${({ theme, $round }) =>
      $round ? 12 : theme.borderRadius.sm}px;
    ${({ $variant, theme }) => getVariant($variant || 'general', theme)};
    ${centerFlex};
    width: fit-content;
    display: inline-flex;
    padding: 0
      ${({ theme, $round }) => ($round ? theme.spacing.sm : theme.spacing.xs)}px;
    margin-right: 0;
    ${({ onClick }) =>
      onClick && `cursor: pointer; text-decoration: underline dashed;`}

    ${({ $dashed, theme }) =>
      $dashed &&
      css`
        text-decoration: underline dashed;
        text-underline-offset: ${theme.spacing.xs / 2}px;
      `}

    ${({ $noBg, theme }) =>
      $noBg &&
      css`
        background: transparent;
      `}
  }

  ${({ onClick }) =>
    !onClick &&
    `
    &&&.ant-tag[ant-click-animating-without-extra-node='true']::after {
      display: none;
    }
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.xs}px;

  svg {
    height: 12px;
    width: 12px;
  }
`;
