import { type DrawerProps as AntdDrawerProps } from 'antd';

import type { ButtonProps } from '../../atoms/Button';
import { type ThemeSpacing } from '../../atoms/common';
import type { Heading } from '../../atoms/Typography';

export const DRAWER_HEADER_CLASSNAME = 'drawer-header';
export const DRAWER_CONTENT_ID = 'drawer-content';

export const DrawerEnum = {
  ROLE_SWITCHER: 'ROLE_SWITCHER',
  OBO_SELECTION: 'OBO_SELECTION',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
  CONTACT_UPDATE: 'CONTACT_UPDATE',
} as const;

export type DrawerKey = (typeof DrawerEnum)[keyof typeof DrawerEnum];

export interface ActiveDrawerStore {
  activeDrawerKey: DrawerKey | undefined;
  openDrawer: (drawerKey: DrawerKey) => void;
  closeDrawer: () => void;
  toggleDrawer: (drawerKey: DrawerKey) => void;
  isOpen: (drawerKey: DrawerKey) => boolean;
}

export type DrawerProps = AntdDrawerProps & {
  submitButtonProps?: Partial<ButtonProps>;
  onClose?: (
    e?: React.MouseEvent | React.KeyboardEvent,
    trigger?: 'mask' | 'button'
  ) => void;
  isFooterFixed?: boolean;
  isLoading?: boolean;
  horizontalPadding?: ThemeSpacing;
  footerHeight?: number;
  icon?: React.ReactNode;
  id?: string;
  alertContainerId?: string;
  noHeader?: boolean;
  titleHeadingProps?: Partial<React.ComponentProps<typeof Heading>>;
  backToRoute?: string; // this will be used as a title for the drawer breadcrumb
  isFullHeight?: boolean;
  isTopBorder?: boolean;
  extraHeaderContent?: React.ReactNode;
};
