// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PortfolioImportApi,
  PortfolioImportErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PortfolioImportApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PortfolioImportMethod = PortfolioImportApi['portfolioImport'];

export const usePortfolioImport = () => {
  return useMutation<PortfolioImportMethod>(
    (...args) => api.portfolioImport(...args),
    PortfolioImportErrorCodes,
    'PortfolioImport',
    '/api/investor/import_portfolio',
    'post'
  );
};
