import React, { useEffect, useRef, useState } from 'react';

import { nanoid } from 'nanoid';

import { CypressDataIds } from '../../../constants';
import { Alert } from '../../atoms/Alert/Alert';

import * as S from './AlertContainer.styles';
import { useAlertsContext } from './AlertContainer.context';
import { useAlerts } from './AlertContainer.hooks';

export const AlertContainer = React.memo(
  ({ id, margin }: { id?: string; margin?: string }) => {
    const thisRef = useRef<HTMLDivElement>(null);

    const { dispatch } = useAlertsContext();

    const [containerId] = useState(id ?? nanoid());

    const { alerts } = useAlerts({ containerId });

    // scroll self into view on new alert
    useEffect(() => {
      if (alerts.length) {
        thisRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, [alerts]);

    // add self to state on mount
    useEffect(() => {
      dispatch({ type: 'ADD_CONTAINER', containerId });

      return () => {
        dispatch({ type: 'REMOVE_CONTAINER', containerId });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <S.AlertContainer
        ref={thisRef}
        data-cy={CypressDataIds.Components.Alert.Container}
        id={containerId}
        $margin={margin}
        $applyMargin={!!alerts.length}
      >
        {alerts.map((alert, i) => (
          <Alert
            key={i}
            {...alert}
            closable
            onClose={() => {
              dispatch({ type: 'REMOVE_ALERT', containerId, index: i });
            }}
          />
        ))}
      </S.AlertContainer>
    );
  }
);
