import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { type Holding, useHoldingDestroy } from '@npm/data-access';

export type HoldingDeleteModalProps = {
  holding?: Holding;
  onSuccess?: () => void;
  onCancel?: () => void;
  open?: boolean;
};

export const HoldingDeleteModal = ({
  holding,
  onSuccess,
  open,
  onCancel,
}: HoldingDeleteModalProps) => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const { execute: destroyHolding, isLoading } = useHoldingDestroy();

  const onOk = async () => {
    try {
      await withShowApiErrorMiddleware(destroyHolding)({
        id: holding?.id?.toString(),
      });
      onSuccess?.();
      onCancel?.();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      okText="Delete Holding"
      cancelText="Close"
      title="Delete Holding"
      onOk={onOk}
      okButtonProps={{
        color: 'error',
        loading: isLoading,
        icon: <Icon name="trash" />,
      }}
      size="md"
    >
      <Text size="sm">
        You are about to delete holding{' '}
        {holding?.certificate_number && (
          <>
            <Text as="span" size="sm" weight="bold">
              {holding?.certificate_number}
            </Text>{' '}
          </>
        )}
        for {holding?.issuer_entity?.name} from{' '}
        <Text as="span" size="sm" weight="bold">
          {holding?.account?.name}
        </Text>
        .
      </Text>
      <Text size="sm">
        Are you sure you want to continue? This action can’t be undone.
      </Text>
    </Modal>
  );
};
