import { useMemo } from 'react';

import { type ChartDataset } from 'chart.js';
import { isWithinInterval } from 'date-fns';

import { useOrderPriceDataSet } from '../../BidOfferHistory/components/BidOfferHistoryChart/hooks/useOrderPriceDataSet';
import { type CompanyOverviewChartSeries } from '../../CompanyOverview.types';
import { useMovingAverageChartDataSet } from '../../MutualFundMarks/components/MutualFundMarksChart/MutualFundMarksChart.hooks';
import { usePrimaryRoundDataset } from '../../PrimaryRounds/components/PrimaryRoundsChart/PrimaryRoundsChart.hooks';
import { useTapeDDataSets } from '../../TapeD/components/TapeDChart/TapeDChart.hooks';
import { useValuations409ADataSet } from '../../Valuations409A/Valuations409AChart/Valuations409AChart.hooks';

import {
  type CompanyOverviewSummaryChartDataPoint,
  CompanyOverviewSummaryChartDataSetKey,
  type CompanyOverviewSummaryChartSortedData,
  type SummaryChartDatasets,
  type SummaryChartTooltipData,
} from './SummaryChart.types';
import { summaryChartDataSetOrder } from './SummaryChart.utils';

const getDataSetEffect = (
  currentDataSet: CompanyOverviewSummaryChartDataSetKey,
  hoveredDataSet: CompanyOverviewSummaryChartDataSetKey | undefined
) => {
  if (!hoveredDataSet) {
    return undefined;
  }

  return currentDataSet === hoveredDataSet ? 'highlight' : 'dim';
};

export const useSummaryChartDataSets = (
  sortedData: CompanyOverviewSummaryChartSortedData,
  series: CompanyOverviewChartSeries,
  currentMinDate: Date,
  currentMaxDate: Date,
  hoveredDataset: CompanyOverviewSummaryChartDataSetKey | undefined
): SummaryChartDatasets => {
  const tapeDDatasets = useTapeDDataSets(sortedData.tapeDData, series, {
    isSummary: true,
    effect: getDataSetEffect(
      CompanyOverviewSummaryChartDataSetKey.TAPE_D,
      hoveredDataset
    ),
    order: summaryChartDataSetOrder.TAPE_D,
  });

  const bidsDataset = useOrderPriceDataSet(sortedData.bidsData, 'bid', {
    series,
    currentMinDate,
    currentMaxDate,
    effect: getDataSetEffect(
      CompanyOverviewSummaryChartDataSetKey.BIDS,
      hoveredDataset
    ),
    order: summaryChartDataSetOrder.BIDS,
  });

  const offersDataset = useOrderPriceDataSet(sortedData.asksData, 'ask', {
    series,
    currentMinDate,
    currentMaxDate,
    effect: getDataSetEffect(
      CompanyOverviewSummaryChartDataSetKey.ASKS,
      hoveredDataset
    ),
    order: summaryChartDataSetOrder.ASKS,
  });

  const tradesDataset = useOrderPriceDataSet(sortedData.tradesData, 'trade', {
    series,
    currentMinDate,
    currentMaxDate,
    effect: getDataSetEffect(
      CompanyOverviewSummaryChartDataSetKey.TRADES,
      hoveredDataset
    ),
    order: summaryChartDataSetOrder.TRADES,
  });

  const primaryRoundsDataset = usePrimaryRoundDataset(
    sortedData.primaryRoundsData,
    {
      series,
      currentMinDate,
      currentMaxDate,
      effect: getDataSetEffect(
        CompanyOverviewSummaryChartDataSetKey.PRIMARY_ROUNDS,
        hoveredDataset
      ),
      order: summaryChartDataSetOrder.PRIMARY_ROUNDS,
    }
  );

  const valuations409ADataset = useValuations409ADataSet(
    sortedData.valuations409AData,
    {
      currentMinDate,
      currentMaxDate,
      effect: getDataSetEffect(
        CompanyOverviewSummaryChartDataSetKey.VALUATIONS_409A,
        hoveredDataset
      ),
      order: summaryChartDataSetOrder.VALUATIONS_409A,
    }
  );

  const movingAverageDataset = useMovingAverageChartDataSet(
    sortedData.movingAverageData,
    {
      series,
      effect: getDataSetEffect(
        CompanyOverviewSummaryChartDataSetKey.MOVING_AVERAGE,
        hoveredDataset
      ),
      order: summaryChartDataSetOrder.MOVING_AVERAGE,
    }
  );

  return useMemo(() => {
    return {
      tapeDDataset: tapeDDatasets[0],
      bidsDataset,
      offersDataset,
      tradesDataset,
      primaryRoundsDataset,
      valuations409ADataset:
        series === 'PPS' ? valuations409ADataset : undefined,
      movingAverageDataset,
    };
  }, [
    series,
    tapeDDatasets,
    bidsDataset,
    offersDataset,
    tradesDataset,
    primaryRoundsDataset,
    valuations409ADataset,
    movingAverageDataset,
  ]);
};

export const useSummaryChartTooltipData = (
  date: string | undefined,
  datasets: SummaryChartDatasets
): SummaryChartTooltipData => {
  return useMemo(() => {
    if (!date) {
      return {
        date,
      };
    }

    const tapeDValue = [...datasets.tapeDDataset.data]
      .reverse()
      .find(({ x }) => x <= date)?.y;

    const movingAverageValue = datasets.movingAverageDataset.data.find(
      ({ x }) => x === date
    )?.y;

    const tradeValue = getBidOfferValue(date, datasets.tradesDataset);
    const bidValue = getBidOfferValue(date, datasets.bidsDataset);
    const offerValue = getBidOfferValue(date, datasets.offersDataset);

    const primaryRoundValue = getSteppedDatasetValue(
      date,
      datasets.primaryRoundsDataset
    );
    const valuation409AValue = getSteppedDatasetValue(
      date,
      datasets.valuations409ADataset
    );

    return {
      tapeDValue,
      bidValue,
      offerValue,
      tradeValue,
      primaryRoundValue,
      valuation409AValue,
      movingAverageValue,
    };
  }, [datasets, date]);
};

const getBidOfferValue = (
  date: string,
  dataset: ChartDataset<'line', CompanyOverviewSummaryChartDataPoint[]>
) => {
  const pairs = dataset.data
    .reduce(
      (acc, point) => {
        if (point.x == null || point.y == null) {
          acc.push([]);
        } else {
          acc[acc.length - 1].push(point);
        }
        return acc;
      },
      [[]]
    )
    .filter(pair => pair.length === 2);

  const pair = pairs.find(([start, end]) => {
    return isWithinInterval(new Date(date), {
      start: start.x,
      end: end.x,
    });
  });

  return pair?.[1]?.y;
};

const getSteppedDatasetValue = (
  date: string,
  dataset:
    | ChartDataset<'line', CompanyOverviewSummaryChartDataPoint[]>
    | undefined
): number | undefined => {
  const data = dataset?.data;

  if (!data?.length) {
    return undefined;
  }

  const reversedData = [...data].reverse();

  // Find the last data point where date is bigger
  return reversedData.find(({ x }) => date >= x)?.y;
};
