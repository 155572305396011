/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Job } from '../model';
// @ts-ignore
import { PortfolioImportRequestContract } from '../model';
/**
 * PortfolioImportApi - axios parameter creator
 * @export
 */
export const PortfolioImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary import a list of new holdings and create their respective accounts
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {PortfolioImportRequestContract} [portfolioImportRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioImport: async (xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, portfolioImportRequestContract?: PortfolioImportRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/investor/import_portfolio`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portfolioImportRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioImportApi - functional programming interface
 * @export
 */
export const PortfolioImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary import a list of new holdings and create their respective accounts
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {PortfolioImportRequestContract} [portfolioImportRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portfolioImport(xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, portfolioImportRequestContract?: PortfolioImportRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portfolioImport(xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, portfolioImportRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortfolioImportApi - factory interface
 * @export
 */
export const PortfolioImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortfolioImportApiFp(configuration)
    return {
        /**
         * 
         * @summary import a list of new holdings and create their respective accounts
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {PortfolioImportRequestContract} [portfolioImportRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portfolioImport(xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, portfolioImportRequestContract?: PortfolioImportRequestContract, options?: any): AxiosPromise<Job> {
            return localVarFp.portfolioImport(xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, portfolioImportRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for portfolioImport operation in PortfolioImportApi.
 * @export
 * @interface PortfolioImportApiPortfolioImportRequest
 */
export interface PortfolioImportApiPortfolioImportRequest {
    /**
     * 
     * @type {string}
     * @memberof PortfolioImportApiPortfolioImport
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioImportApiPortfolioImport
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioImportApiPortfolioImport
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof PortfolioImportApiPortfolioImport
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {PortfolioImportRequestContract}
     * @memberof PortfolioImportApiPortfolioImport
     */
    readonly portfolioImportRequestContract?: PortfolioImportRequestContract
}

/**
 * PortfolioImportApi - object-oriented interface
 * @export
 * @class PortfolioImportApi
 * @extends {BaseAPI}
 */
export class PortfolioImportApi extends BaseAPI {
    /**
     * 
     * @summary import a list of new holdings and create their respective accounts
     * @param {PortfolioImportApiPortfolioImportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioImportApi
     */
    public portfolioImport(requestParameters: PortfolioImportApiPortfolioImportRequest = {}, options?: AxiosRequestConfig) {
        return PortfolioImportApiFp(this.configuration).portfolioImport(requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, requestParameters.portfolioImportRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type PortfolioImportErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PortfolioImportErrorCodes = [
    422,
];


