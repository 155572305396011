// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CurrentStripeUserApi,
  CurrentStripeUserErrorCodes,
  CurrentStripeUserErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CurrentStripeUserApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CurrentStripeUserMethod = CurrentStripeUserApi['currentStripeUser'];
export type CurrentStripeUserResponseType = MethodResult<CurrentStripeUserMethod>;

export const useCurrentStripeUser = (
  variables: Parameters<CurrentStripeUserMethod>[0],
  config?: UseQueryConfig<
    CurrentStripeUserMethod,
    CurrentStripeUserErrorTypes
  >
) => {
  return useQuery<
    CurrentStripeUserMethod,
    CurrentStripeUserErrorTypes
  >(
    (...args) => api.currentStripeUser(...args),
    CurrentStripeUserErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CurrentStripeUser'],
        ...apiQueryConfig.CurrentStripeUser,
        ...config?.queryConfig
      },
    },
    'CurrentStripeUser',
  );
};

export const useCurrentStripeUserInfinite = (
  variables: Parameters<CurrentStripeUserMethod>[0],
  config?: UseInfiniteQueryConfig<
    CurrentStripeUserMethod,
    CurrentStripeUserErrorTypes
  >
) => {
  return useInfiniteQuery<
    CurrentStripeUserMethod,
    CurrentStripeUserErrorTypes
  >(
    (...args) => api.currentStripeUser(...args),
    CurrentStripeUserErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CurrentStripeUser', 'CurrentStripeUserInfinite'],
        ...apiQueryConfig.CurrentStripeUser,
        ...config?.queryConfig,
      },
    },
    'CurrentStripeUser',
  );
};

export const useCurrentStripeUserLazy = (baseOptions?: {
  variables?: Parameters<CurrentStripeUserMethod>[0],
  config?: UseQueryConfig<
    CurrentStripeUserMethod,
    CurrentStripeUserErrorTypes
  >
}) => {
  return useLazyQuery<
    CurrentStripeUserMethod,
    CurrentStripeUserErrorTypes
  >(
    (...args) => api.currentStripeUser(...args),
    CurrentStripeUserErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CurrentStripeUser'],
        ...apiQueryConfig.CurrentStripeUser,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CurrentStripeUser',
  );
};

