import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type AssetAggregate,
  CbAssetType,
  codebookEquals,
  getCodebookName,
} from '@npm/data-access';
import { useTheme } from 'styled-components';

import { AggregatedHoldingLabel } from './AggregatedHoldingLabel';
import { AggrHoldingsListComponentsStyles as S } from './styles';

type Props = {
  asset?: AssetAggregate;
  showLabelWithTooltip?: boolean;
};

export const AggregatedAssetType = ({
  asset,
  showLabelWithTooltip = false,
}: Props) => {
  const theme = useTheme();

  return (
    <S.HoldingTypeLabel gap="xs" align="center">
      <Icon name="layers" size="xs" />
      <Text size="sm">
        {getCodebookName(asset?.type)}
        {codebookEquals(asset?.type, CbAssetType.items.CommonStock) && (
          <span>&nbsp;Stock</span>
        )}
      </Text>
      {showLabelWithTooltip && (
        <Flex align="center">
          <Text.Dot
            colorVariant="tertiary"
            style={{ marginTop: theme.spacing.xs / 2 + 'px', lineHeight: 0 }}
          />
          <AggregatedHoldingLabel noBg />
        </Flex>
      )}
    </S.HoldingTypeLabel>
  );
};
