import React from 'react';

import { useTheme } from 'styled-components';

import { Icon } from '../../Icon';
import type { StepperItemSize, StepperItemVariant } from '../index';

import * as S from './VariantIcon.styles';

type Props = {
  size: StepperItemSize;
  isActive: boolean;
  variant?: StepperItemVariant;
  icon?: React.ReactNode;
  hasBackground?: boolean;
};

export const VariantIcon = ({
  size,
  variant,
  icon,
  isActive,
  hasBackground,
}: Props) => {
  const theme = useTheme();

  return (
    <S.Square $size={size} $isActive={isActive} $hasBackground={hasBackground}>
      {icon ? (
        icon
      ) : (
        <>
          {variant === 'done' && (
            <Icon
              name="check"
              size="xxs"
              color={theme.color.success.typography.primary}
            />
          )}
          {variant === 'error' && (
            <Icon
              name="alert-triangle"
              size="xxs"
              color={theme.color.error.typography.primary}
            />
          )}
          {variant === 'waiting' && (
            <Icon
              name="clock"
              size="xxs"
              color={theme.color.warning.typography.primary}
            />
          )}
        </>
      )}
    </S.Square>
  );
};
