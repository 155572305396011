import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  StepState,
  type WizardStore,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CbAccountType, type CbOnboardingStatus } from '@npm/data-access';

import {
  type WithSupportTicketType,
  ZendeskContactButton,
} from '../../app/zendesk';
import { type PostOnboardingContext } from '../../postOnboarding/PostOnboarding.types';
import { isOnboardingStepInWarningState } from '../PostOnboarding.utils';

import * as S from './WarningStateAlert.styles';

type Props = {
  stepKey: keyof typeof CbOnboardingStatus.steps;
} & WithSupportTicketType;

export const WarningStateAlert = ({ stepKey, zendeskTicketGroupId }: Props) => {
  const { stepState, accountType, onboardingStatus } = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => ({
      stepState: s.activeStepState,
      accountType: s.data.account?.type?.code,
      onboardingStatus: s.data.onboardingStatus,
    })
  );
  const showWarning =
    isOnboardingStepInWarningState(stepKey, onboardingStatus) &&
    stepState === StepState.EDIT;

  if (!showWarning) return null;

  return (
    <S.Wrapper>
      <Alert
        type="warning"
        message={
          <>
            <Text size="sm" colorVariant="primary" marginBottom="sm">
              Identity Verification Documents Required
            </Text>
            <Text size="sm">
              Updated photo identification is required. See the sections below
              to provide updated information.
            </Text>
            <Text size="sm">
              If you have questions or concerns, email{' '}
              <Text size="sm" href="mailto:support@npm.com">
                support@npm.com
              </Text>{' '}
              or{' '}
              <ZendeskContactButton zendeskTicketGroupId={zendeskTicketGroupId}>
                Click Here
              </ZendeskContactButton>
              .
            </Text>
          </>
        }
        description={
          <>
            <Text size="sm" marginBottom="xs">
              You need to submit updated photo identification for your
              submission to be completed. You may have been asked to complete
              this for one or more of the following reasons:
            </Text>
            <Text size="sm" as="ul" marginBottom="sm">
              <li>A valid government issued photo ID was not provided</li>
              <li>The photo ID submitted was expired</li>
              <li>
                The photo ID submitted was unreadable or partially obscured
              </li>
              <li>
                The information was originally provided over one year ago and
                needs to be resubmitted
              </li>
            </Text>
            <Text size="sm" marginBottom="xs">
              Required sections to be completed:
            </Text>
            <Text size="sm" as="ul">
              {onboardingStatus?.entity_tax_state?.code === 'warning' && (
                <li>Entity Tax ID</li>
              )}
              {onboardingStatus?.entity_formation_document_state?.code ===
                'warning' && <li>Formation Document</li>}
              {onboardingStatus?.individual_tax_state?.code === 'warning' && (
                <li>
                  {accountType === CbAccountType.items.OrganizationAccount &&
                    'Representative '}
                  Tax ID
                </li>
              )}
              {onboardingStatus?.representative_photo_state?.code ===
                'warning' && (
                <li>
                  {accountType === CbAccountType.items.OrganizationAccount &&
                    'Representative '}
                  Government Issued Photo ID
                </li>
              )}
            </Text>
          </>
        }
      />
    </S.Wrapper>
  );
};
