/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPartnerPartnerIssuerMap } from '../model';
// @ts-ignore
import { AdminPartnerPartnerIssuerMapIndex } from '../model';
// @ts-ignore
import { AdminPartnerPartnerIssuerMapUpdateRequestContract } from '../model';
/**
 * PartnerIssuerMapApi - axios parameter creator
 * @export
 */
export const PartnerIssuerMapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list an entity
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [partnerName] 
         * @param {string} [partnerIssuerId] 
         * @param {'pending' | 'complete'} [onboardingStatus] 
         * @param {boolean} [active] 
         * @param {string} [issuerNpmGuid] 
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerIssuerMapIndex: async (page?: number, size?: number, partnerName?: string, partnerIssuerId?: string, onboardingStatus?: 'pending' | 'complete', active?: boolean, issuerNpmGuid?: string, sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/partner/partner_issuer_maps`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (partnerName !== undefined) {
                localVarQueryParameter['partner_name'] = partnerName;
            }

            if (partnerIssuerId !== undefined) {
                localVarQueryParameter['partner_issuer_id'] = partnerIssuerId;
            }

            if (onboardingStatus !== undefined) {
                localVarQueryParameter['onboarding_status'] = onboardingStatus;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (issuerNpmGuid !== undefined) {
                localVarQueryParameter['issuer_npm_guid'] = issuerNpmGuid;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update partner issuer map
         * @param {number} id 
         * @param {AdminPartnerPartnerIssuerMapUpdateRequestContract} adminPartnerPartnerIssuerMapUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerIssuerMapUpdate: async (id: number, adminPartnerPartnerIssuerMapUpdateRequestContract: AdminPartnerPartnerIssuerMapUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partnerIssuerMapUpdate', 'id', id)
            // verify required parameter 'adminPartnerPartnerIssuerMapUpdateRequestContract' is not null or undefined
            assertParamExists('partnerIssuerMapUpdate', 'adminPartnerPartnerIssuerMapUpdateRequestContract', adminPartnerPartnerIssuerMapUpdateRequestContract)
            const localVarPath = changeUrl(`/api/admin/partner/partner_issuer_maps/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPartnerPartnerIssuerMapUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerIssuerMapApi - functional programming interface
 * @export
 */
export const PartnerIssuerMapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerIssuerMapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list an entity
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [partnerName] 
         * @param {string} [partnerIssuerId] 
         * @param {'pending' | 'complete'} [onboardingStatus] 
         * @param {boolean} [active] 
         * @param {string} [issuerNpmGuid] 
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerIssuerMapIndex(page?: number, size?: number, partnerName?: string, partnerIssuerId?: string, onboardingStatus?: 'pending' | 'complete', active?: boolean, issuerNpmGuid?: string, sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerPartnerIssuerMapIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerIssuerMapIndex(page, size, partnerName, partnerIssuerId, onboardingStatus, active, issuerNpmGuid, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update partner issuer map
         * @param {number} id 
         * @param {AdminPartnerPartnerIssuerMapUpdateRequestContract} adminPartnerPartnerIssuerMapUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerIssuerMapUpdate(id: number, adminPartnerPartnerIssuerMapUpdateRequestContract: AdminPartnerPartnerIssuerMapUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerPartnerIssuerMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerIssuerMapUpdate(id, adminPartnerPartnerIssuerMapUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerIssuerMapApi - factory interface
 * @export
 */
export const PartnerIssuerMapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerIssuerMapApiFp(configuration)
    return {
        /**
         * 
         * @summary list an entity
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [partnerName] 
         * @param {string} [partnerIssuerId] 
         * @param {'pending' | 'complete'} [onboardingStatus] 
         * @param {boolean} [active] 
         * @param {string} [issuerNpmGuid] 
         * @param {'created_at' | 'updated_at'} [sortBy] defaults to updated_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerIssuerMapIndex(page?: number, size?: number, partnerName?: string, partnerIssuerId?: string, onboardingStatus?: 'pending' | 'complete', active?: boolean, issuerNpmGuid?: string, sortBy?: 'created_at' | 'updated_at', sortDirection?: 'asc' | 'desc', options?: any): AxiosPromise<AdminPartnerPartnerIssuerMapIndex> {
            return localVarFp.partnerIssuerMapIndex(page, size, partnerName, partnerIssuerId, onboardingStatus, active, issuerNpmGuid, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update partner issuer map
         * @param {number} id 
         * @param {AdminPartnerPartnerIssuerMapUpdateRequestContract} adminPartnerPartnerIssuerMapUpdateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerIssuerMapUpdate(id: number, adminPartnerPartnerIssuerMapUpdateRequestContract: AdminPartnerPartnerIssuerMapUpdateRequestContract, options?: any): AxiosPromise<AdminPartnerPartnerIssuerMap> {
            return localVarFp.partnerIssuerMapUpdate(id, adminPartnerPartnerIssuerMapUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for partnerIssuerMapIndex operation in PartnerIssuerMapApi.
 * @export
 * @interface PartnerIssuerMapApiPartnerIssuerMapIndexRequest
 */
export interface PartnerIssuerMapApiPartnerIssuerMapIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly partnerName?: string

    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly partnerIssuerId?: string

    /**
     * 
     * @type {'pending' | 'complete'}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly onboardingStatus?: 'pending' | 'complete'

    /**
     * 
     * @type {boolean}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly active?: boolean

    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly issuerNpmGuid?: string

    /**
     * defaults to updated_at
     * @type {'created_at' | 'updated_at'}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly sortBy?: 'created_at' | 'updated_at'

    /**
     * defaults to desc
     * @type {'asc' | 'desc'}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapIndex
     */
    readonly sortDirection?: 'asc' | 'desc'
}

/**
 * Request parameters for partnerIssuerMapUpdate operation in PartnerIssuerMapApi.
 * @export
 * @interface PartnerIssuerMapApiPartnerIssuerMapUpdateRequest
 */
export interface PartnerIssuerMapApiPartnerIssuerMapUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AdminPartnerPartnerIssuerMapUpdateRequestContract}
     * @memberof PartnerIssuerMapApiPartnerIssuerMapUpdate
     */
    readonly adminPartnerPartnerIssuerMapUpdateRequestContract: AdminPartnerPartnerIssuerMapUpdateRequestContract
}

/**
 * PartnerIssuerMapApi - object-oriented interface
 * @export
 * @class PartnerIssuerMapApi
 * @extends {BaseAPI}
 */
export class PartnerIssuerMapApi extends BaseAPI {
    /**
     * 
     * @summary list an entity
     * @param {PartnerIssuerMapApiPartnerIssuerMapIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerIssuerMapApi
     */
    public partnerIssuerMapIndex(requestParameters: PartnerIssuerMapApiPartnerIssuerMapIndexRequest = {}, options?: AxiosRequestConfig) {
        return PartnerIssuerMapApiFp(this.configuration).partnerIssuerMapIndex(requestParameters.page, requestParameters.size, requestParameters.partnerName, requestParameters.partnerIssuerId, requestParameters.onboardingStatus, requestParameters.active, requestParameters.issuerNpmGuid, requestParameters.sortBy, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update partner issuer map
     * @param {PartnerIssuerMapApiPartnerIssuerMapUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerIssuerMapApi
     */
    public partnerIssuerMapUpdate(requestParameters: PartnerIssuerMapApiPartnerIssuerMapUpdateRequest, options?: AxiosRequestConfig) {
        return PartnerIssuerMapApiFp(this.configuration).partnerIssuerMapUpdate(requestParameters.id, requestParameters.adminPartnerPartnerIssuerMapUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type PartnerIssuerMapIndexErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PartnerIssuerMapIndexErrorCodes = [
    422,
];

export type PartnerIssuerMapUpdateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PartnerIssuerMapUpdateErrorCodes = [
    404,
];


