// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  RegionsApi,
  InternalRegionIndexErrorCodes,
  InternalRegionIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new RegionsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalRegionIndexMethod = RegionsApi['internalRegionIndex'];
export type InternalRegionIndexResponseType = MethodResult<InternalRegionIndexMethod>;

export const useInternalRegionIndex = (
  variables: Parameters<InternalRegionIndexMethod>[0],
  config?: UseQueryConfig<
    InternalRegionIndexMethod,
    InternalRegionIndexErrorTypes
  >
) => {
  return useQuery<
    InternalRegionIndexMethod,
    InternalRegionIndexErrorTypes
  >(
    (...args) => api.internalRegionIndex(...args),
    InternalRegionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalRegionIndex'],
        ...apiQueryConfig.InternalRegionIndex,
        ...config?.queryConfig
      },
    },
    'InternalRegionIndex',
  );
};

export const useInternalRegionIndexInfinite = (
  variables: Parameters<InternalRegionIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalRegionIndexMethod,
    InternalRegionIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalRegionIndexMethod,
    InternalRegionIndexErrorTypes
  >(
    (...args) => api.internalRegionIndex(...args),
    InternalRegionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalRegionIndex', 'InternalRegionIndexInfinite'],
        ...apiQueryConfig.InternalRegionIndex,
        ...config?.queryConfig,
      },
    },
    'InternalRegionIndex',
  );
};

export const useInternalRegionIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalRegionIndexMethod>[0],
  config?: UseQueryConfig<
    InternalRegionIndexMethod,
    InternalRegionIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalRegionIndexMethod,
    InternalRegionIndexErrorTypes
  >(
    (...args) => api.internalRegionIndex(...args),
    InternalRegionIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalRegionIndex'],
        ...apiQueryConfig.InternalRegionIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalRegionIndex',
  );
};

