// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StockIssuancesApi,
  ListStockIssuancesErrorCodes,
  ListStockIssuancesErrorTypes,
  GetStockIssuanceErrorCodes,
  GetStockIssuanceErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StockIssuancesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ListStockIssuancesMethod = StockIssuancesApi['listStockIssuances'];
export type ListStockIssuancesResponseType = MethodResult<ListStockIssuancesMethod>;

export const useListStockIssuances = (
  variables: Parameters<ListStockIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListStockIssuancesMethod,
    ListStockIssuancesErrorTypes
  >
) => {
  return useQuery<
    ListStockIssuancesMethod,
    ListStockIssuancesErrorTypes
  >(
    (...args) => api.listStockIssuances(...args),
    ListStockIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListStockIssuances'],
        ...apiQueryConfig.ListStockIssuances,
        ...config?.queryConfig
      },
    },
    'ListStockIssuances',
  );
};

export const useListStockIssuancesInfinite = (
  variables: Parameters<ListStockIssuancesMethod>[0],
  config?: UseInfiniteQueryConfig<
    ListStockIssuancesMethod,
    ListStockIssuancesErrorTypes
  >
) => {
  return useInfiniteQuery<
    ListStockIssuancesMethod,
    ListStockIssuancesErrorTypes
  >(
    (...args) => api.listStockIssuances(...args),
    ListStockIssuancesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['ListStockIssuances', 'ListStockIssuancesInfinite'],
        ...apiQueryConfig.ListStockIssuances,
        ...config?.queryConfig,
      },
    },
    'ListStockIssuances',
  );
};

export const useListStockIssuancesLazy = (baseOptions?: {
  variables?: Parameters<ListStockIssuancesMethod>[0],
  config?: UseQueryConfig<
    ListStockIssuancesMethod,
    ListStockIssuancesErrorTypes
  >
}) => {
  return useLazyQuery<
    ListStockIssuancesMethod,
    ListStockIssuancesErrorTypes
  >(
    (...args) => api.listStockIssuances(...args),
    ListStockIssuancesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['ListStockIssuances'],
        ...apiQueryConfig.ListStockIssuances,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'ListStockIssuances',
  );
};


export type GetStockIssuanceMethod = StockIssuancesApi['getStockIssuance'];
export type GetStockIssuanceResponseType = MethodResult<GetStockIssuanceMethod>;

export const useGetStockIssuance = (
  variables: Parameters<GetStockIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetStockIssuanceMethod,
    GetStockIssuanceErrorTypes
  >
) => {
  return useQuery<
    GetStockIssuanceMethod,
    GetStockIssuanceErrorTypes
  >(
    (...args) => api.getStockIssuance(...args),
    GetStockIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetStockIssuance'],
        ...apiQueryConfig.GetStockIssuance,
        ...config?.queryConfig
      },
    },
    'GetStockIssuance',
  );
};

export const useGetStockIssuanceInfinite = (
  variables: Parameters<GetStockIssuanceMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetStockIssuanceMethod,
    GetStockIssuanceErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetStockIssuanceMethod,
    GetStockIssuanceErrorTypes
  >(
    (...args) => api.getStockIssuance(...args),
    GetStockIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetStockIssuance', 'GetStockIssuanceInfinite'],
        ...apiQueryConfig.GetStockIssuance,
        ...config?.queryConfig,
      },
    },
    'GetStockIssuance',
  );
};

export const useGetStockIssuanceLazy = (baseOptions?: {
  variables?: Parameters<GetStockIssuanceMethod>[0],
  config?: UseQueryConfig<
    GetStockIssuanceMethod,
    GetStockIssuanceErrorTypes
  >
}) => {
  return useLazyQuery<
    GetStockIssuanceMethod,
    GetStockIssuanceErrorTypes
  >(
    (...args) => api.getStockIssuance(...args),
    GetStockIssuanceErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetStockIssuance'],
        ...apiQueryConfig.GetStockIssuance,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetStockIssuance',
  );
};

