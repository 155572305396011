// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SUserlessProofOfOwnershipApi,
  S2SUserlessListProofOfOwnershipMetadataErrorCodes,
  S2SUserlessListProofOfOwnershipMetadataErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SUserlessProofOfOwnershipApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SUserlessListProofOfOwnershipMetadataMethod = S2SUserlessProofOfOwnershipApi['s2SUserlessListProofOfOwnershipMetadata'];
export type S2SUserlessListProofOfOwnershipMetadataResponseType = MethodResult<S2SUserlessListProofOfOwnershipMetadataMethod>;

export const useS2SUserlessListProofOfOwnershipMetadata = (
  variables: Parameters<S2SUserlessListProofOfOwnershipMetadataMethod>[0],
  config?: UseQueryConfig<
    S2SUserlessListProofOfOwnershipMetadataMethod,
    S2SUserlessListProofOfOwnershipMetadataErrorTypes
  >
) => {
  return useQuery<
    S2SUserlessListProofOfOwnershipMetadataMethod,
    S2SUserlessListProofOfOwnershipMetadataErrorTypes
  >(
    (...args) => api.s2SUserlessListProofOfOwnershipMetadata(...args),
    S2SUserlessListProofOfOwnershipMetadataErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SUserlessListProofOfOwnershipMetadata'],
        ...apiQueryConfig.S2SUserlessListProofOfOwnershipMetadata,
        ...config?.queryConfig
      },
    },
    'S2SUserlessListProofOfOwnershipMetadata',
  );
};

export const useS2SUserlessListProofOfOwnershipMetadataInfinite = (
  variables: Parameters<S2SUserlessListProofOfOwnershipMetadataMethod>[0],
  config?: UseInfiniteQueryConfig<
    S2SUserlessListProofOfOwnershipMetadataMethod,
    S2SUserlessListProofOfOwnershipMetadataErrorTypes
  >
) => {
  return useInfiniteQuery<
    S2SUserlessListProofOfOwnershipMetadataMethod,
    S2SUserlessListProofOfOwnershipMetadataErrorTypes
  >(
    (...args) => api.s2SUserlessListProofOfOwnershipMetadata(...args),
    S2SUserlessListProofOfOwnershipMetadataErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SUserlessListProofOfOwnershipMetadata', 'S2SUserlessListProofOfOwnershipMetadataInfinite'],
        ...apiQueryConfig.S2SUserlessListProofOfOwnershipMetadata,
        ...config?.queryConfig,
      },
    },
    'S2SUserlessListProofOfOwnershipMetadata',
  );
};

export const useS2SUserlessListProofOfOwnershipMetadataLazy = (baseOptions?: {
  variables?: Parameters<S2SUserlessListProofOfOwnershipMetadataMethod>[0],
  config?: UseQueryConfig<
    S2SUserlessListProofOfOwnershipMetadataMethod,
    S2SUserlessListProofOfOwnershipMetadataErrorTypes
  >
}) => {
  return useLazyQuery<
    S2SUserlessListProofOfOwnershipMetadataMethod,
    S2SUserlessListProofOfOwnershipMetadataErrorTypes
  >(
    (...args) => api.s2SUserlessListProofOfOwnershipMetadata(...args),
    S2SUserlessListProofOfOwnershipMetadataErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S2SUserlessListProofOfOwnershipMetadata'],
        ...apiQueryConfig.S2SUserlessListProofOfOwnershipMetadata,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S2SUserlessListProofOfOwnershipMetadata',
  );
};

