import React from 'react';

import { RBAC } from '@npm/core/ui/models/permissions';
import { componentLoader } from '@npm/core/ui/routes/componentLoader';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';
import { type investorWorkstationRouteKeys } from '@npm/core/ui/routes/routes.investorWorkstation';
import { ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE } from '@npm/features/auth/user/role/roleOverrides';
import {
  EventPageCompanyLogo,
  EventPageTitle,
  OrderPageTitle,
  SubmissionPageCompanyLogo,
  SubmissionPageTitle,
} from '@npm/features/routes/components/pageTitle';

import { AgreementsTitle } from '../pages/general/agreements/AgreementsTitle';

type RouteDef = RouteDefinition & {
  path: investorWorkstationRouteKeys;
};

export const investorWorkstationPrefix = 'investor-workstation';
const {
  isInvMenuAgreements,
  isInvMenuOther,
  isInvAoboOrAccountCentric,
  isUserInAllAccounts,
  isInvMenuNda,
  isEntityNpmsInvestor,
  isInvNotAobo,
  isIndividualNpmsBuyerInvestor,
  isNpmsEntityOrBuyerInvestor,
} = RBAC.investor;

export const SIGN_NDA_PLAIN_PATH = `/${investorWorkstationPrefix}/programs/live-programs/:programId/sign-nda/plain`;

export const routes: RouteDef[] = [
  {
    path: `/${investorWorkstationPrefix}/account/detail`,
    component: componentLoader(() => import(`../pages/account/detail`)),
    title: 'Account Information',
    permissionsChecks: [isInvAoboOrAccountCentric, isInvMenuOther],
    isAccountCentricOnly: true,
    isLocalScope: true,
    // this route cannot have pageHistory: { isRoot: true }, because otherwise the breadcrumbs will not work. Instead of this, I resetting it on menu item click.
  },
  {
    path: `/${investorWorkstationPrefix}/individual-dashboard`,
    component: componentLoader(() => import(`../pages/individual-dashboard`)),
    permissionsChecks: [
      isInvMenuOther,
      isIndividualNpmsBuyerInvestor,
      isInvNotAobo,
    ],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/entity-dashboard`,
    component: componentLoader(() => import(`../pages/entity-dashboard`)),
    permissionsChecks: [isInvMenuOther, isEntityNpmsInvestor],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
    subRoutes: [
      {
        path: `/${investorWorkstationPrefix}/my-orders`,
        component: componentLoader(() => import(`../pages/my-orders`)),
        title: 'My Orders',
        permissionsChecks: [
          isInvMenuOther,
          isNpmsEntityOrBuyerInvestor,
          isInvNotAobo,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        path: `/${investorWorkstationPrefix}/company-data`,
        component: componentLoader(() => import(`../pages/company-data`)),
        title: 'Company Data',
        permissionsChecks: [
          isInvMenuOther,
          isNpmsEntityOrBuyerInvestor,
          isInvNotAobo,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
      },
      {
        path: `/${investorWorkstationPrefix}/dealsync`,
        component: componentLoader(() => import(`../pages/dealsync`)),
        title: 'DealSync',
        permissionsChecks: [isInvMenuOther, isEntityNpmsInvestor, isInvNotAobo],
        featureFlag: { type: 'allow-if-enabled', flag: 'FLOW_SCREENER' },
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
        pageHistory: {
          isRoot: true,
        },
        subRoutes: [
          {
            path: `/${investorWorkstationPrefix}/dealsync/upload-history`,
            component: componentLoader(
              () => import(`../pages/dealsync/upload-history`)
            ),
            title: 'Upload History',
            permissionsChecks: [
              isInvMenuOther,
              isEntityNpmsInvestor,
              isInvNotAobo,
            ],
            featureFlag: { type: 'allow-if-enabled', flag: 'FLOW_SCREENER' },
            roles: {
              overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
            },
          },
        ],
      },
      {
        path: `/${investorWorkstationPrefix}/sector-analysis`,
        component: componentLoader(() => import(`../pages/sector-analysis`)),
        title: 'Sector Analysis',
        permissionsChecks: [
          isInvMenuOther,
          isNpmsEntityOrBuyerInvestor,
          isInvNotAobo,
        ],
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
        featureFlag: { type: 'allow-if-enabled', flag: 'SECTOR_PAGE' },
        pageHistory: {
          isRoot: true,
        },
      },
    ],
  },
  {
    path: `/${investorWorkstationPrefix}/my-watchlist`,
    component: componentLoader(() => import(`../pages/my-watchlist`)),
    title: 'My Watchlist',
    permissionsChecks: [isInvMenuOther, isNpmsEntityOrBuyerInvestor],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/matches`,
    component: componentLoader(() => import(`../pages/matches`)),
    pageHeaderProps: {
      title: 'Matches',
      tooltip: 'All Matches associated with your account(s).',
    },
    permissionsChecks: [isInvMenuOther],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
    subRoutes: [
      {
        title: 'Trade Detail',
        path: `/${investorWorkstationPrefix}/matches/:matchActivityId`,
        permissionsChecks: [isInvMenuOther],
        component: componentLoader(
          () => import(`../pages/matches/trade-detail`)
        ),
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
        layout: {
          sidebarCollapsible: true,
          noPageHeader: true,
        },
        pageHistory: {
          doNotIncludeInHistory: true,
        },
      },
    ],
  },
  {
    path: `/${investorWorkstationPrefix}/account/holdings`,
    component: componentLoader(() => import(`../pages/account/holdings`)),
    pageHeaderProps: {
      title: 'Holdings',
      tooltip: 'All Holdings associated with your account(s).',
    },
    permissionsChecks: [isInvMenuOther],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/account/agreements`,
    component: componentLoader(() => import(`../pages/general/agreements`)),
    title: props => <AgreementsTitle {...props} />,
    pageHeaderProps: {
      tooltip: 'All Agreements associated with your account(s).',
    },
    permissionsChecks: [isInvMenuAgreements],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/account/transaction-confirmations`,
    component: componentLoader(
      () => import(`../pages/account/transaction-confirmations`)
    ),
    pageHeaderProps: {
      title: 'Transaction Confirmations',
      tooltip: 'Confirmations for all your settled transactions.',
    },
    permissionsChecks: [isInvMenuOther],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/account/tax-documents`,
    component: componentLoader(() => import(`../pages/account/tax-documents`)),
    pageHeaderProps: {
      title: 'Tax Documents',
      tooltip: 'Tax documents for all your settled transactions.',
    },
    permissionsChecks: [isInvMenuOther],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/accounts`,
    component: componentLoader(() => import(`../pages/accounts`)),
    title: 'Accounts',
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    permissionsChecks: [isUserInAllAccounts, isInvMenuOther],
    pageHistory: {
      isRoot: true,
    },
    subRoutes: [
      {
        path: `/${investorWorkstationPrefix}/accounts/:accountId`,
        component: componentLoader(() => import(`../pages/accounts/detail`)),
        roles: {
          overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
        },
        permissionsChecks: [isUserInAllAccounts, isInvMenuOther],
      },
    ],
  },
  {
    path: `/${investorWorkstationPrefix}/programs/submissions`,
    component: componentLoader(() => import(`../pages/programs/submissions`)),
    pageHeaderProps: {
      title: 'Submissions',
      tooltip: 'All Submissions associated with your account(s).',
    },
    permissionsChecks: [isInvMenuOther],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    pageHistory: {
      isRoot: true,
    },
    subRoutes: [
      {
        path: `/${investorWorkstationPrefix}/programs/submissions/:submissionId`,
        component: componentLoader(
          () => import(`../pages/programs/submissions/detail`)
        ),
        title: props => <SubmissionPageTitle {...props} />,
        isLocalScope: true,
        permissionsChecks: [isInvMenuOther],
        subRoutes: [
          {
            path: `/${investorWorkstationPrefix}/programs/submissions/:submissionId/orders/:orderId`,
            component: componentLoader(
              () => import(`../pages/programs/submissions/orders`)
            ),
            title: props => <OrderPageTitle {...props} />,
            pageHeaderProps: {
              companyLogo: <SubmissionPageCompanyLogo />,
            },
            isLocalScope: true,
            permissionsChecks: [isInvMenuOther],
          },
        ],
      },
    ],
  },
  {
    path: `/${investorWorkstationPrefix}/programs/live-programs`,
    component: componentLoader(() => import(`../pages/programs/live-programs`)),
    pageHeaderProps: {
      title: 'Live Programs',
      tooltip: 'All Live Programs associated with your account(s).',
    },
    permissionsChecks: [isInvMenuOther],
    pageHistory: {
      isRoot: true,
    },
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    subRoutes: [
      {
        path: `/${investorWorkstationPrefix}/programs/live-programs/:programId/sign-nda`,
        component: componentLoader(() => import(`../pages/programs/sign-nda`)),
        permissionsChecks: [isInvMenuNda],
        isLocalScope: true,
        pageHistory: {
          doNotIncludeInHistory: true,
        },
      },
      {
        path: SIGN_NDA_PLAIN_PATH,
        component: componentLoader(
          () => import(`../pages/programs/sign-nda-plain`)
        ),
        permissionsChecks: [isInvMenuNda],
        isLocalScope: true,
        pageHistory: {
          doNotIncludeInHistory: true,
        },
      },
      {
        path: `/${investorWorkstationPrefix}/programs/live-programs/:programId`,
        component: componentLoader(
          () => import(`../pages/programs/live-programs/detail`)
        ),
        pageHeaderProps: {
          companyLogo: <EventPageCompanyLogo />,
        },
        title: props => <EventPageTitle {...props} />,
        permissionsChecks: [isInvMenuOther],
        isLocalScope: true,
        roles: {
          on403(history, params) {
            if (!params.programId) {
              return;
            }
            history.replace(
              `/${investorWorkstationPrefix}/programs/live-programs/${params.programId}/403?allAccounts=1`
            );
          },
        },
        subRoutes: [
          {
            path: `/${investorWorkstationPrefix}/programs/live-programs/:programId/submission`,
            component: componentLoader(
              () => import(`../pages/programs/live-programs/submission`)
            ),
            title: props => <EventPageTitle {...props} />,
            pageHeaderProps: {
              companyLogo: <EventPageCompanyLogo />,
              slim: true,
              breadcrumbProps: {
                lastLevelText: 'Leave Submission',
                lastLevelOnly: true,
              },
            },
            pageHistory: {
              doNotIncludeInHistory: true,
            },
            isLocalScope: true,
            permissionsChecks: [isInvMenuOther],
          },
          {
            path: `/${investorWorkstationPrefix}/programs/live-programs/:programId/submissions/:submissionId/orders/:orderId`,
            component: componentLoader(
              () => import(`../pages/programs/live-programs/order-detail`)
            ),
            title: props => <OrderPageTitle {...props} />,
            pageHeaderProps: {
              companyLogo: <SubmissionPageCompanyLogo />,
            },
            isLocalScope: true,
            permissionsChecks: [isInvMenuOther],
          },
          {
            path: `/${investorWorkstationPrefix}/programs/live-programs/:programId/403`,
            component: componentLoader(
              () => import(`../pages/programs/live-programs/403`)
            ),
            pageHistory: {
              doNotIncludeInHistory: true,
            },
            isLocalScope: true,
          },
        ],
      },
    ],
  },
  {
    path: `/${investorWorkstationPrefix}/programs/data-rooms`,
    component: componentLoader(() => import(`../pages/programs/data-rooms`)),
    pageHeaderProps: {
      title: 'Data Rooms',
      tooltip:
        'All documents that your account(s) has been permissioned to access.',
    },
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
    permissionsChecks: [isInvMenuOther],
    pageHistory: {
      isRoot: true,
    },
    subRoutes: [
      {
        path: `/${investorWorkstationPrefix}/programs/data-rooms/:roomId`,
        component: componentLoader(
          () => import(`../pages/programs/data-rooms/detail`)
        ),
        pageHeaderProps: {
          title: 'Data Rooms',
          tooltip:
            'All documents that your account(s) has been permissioned to access.',
          breadcrumbProps: {
            render: false,
          },
        },
        isLocalScope: true,
      },
    ],
  },
  {
    path: `/${investorWorkstationPrefix}/programs/ndas`,
    component: componentLoader(() => import(`../pages/programs/ndas`)),
    pageHeaderProps: {
      title: 'Non-Disclosure Agreements',
      tooltip: 'All NDAs associated with your account(s).',
    },
    permissionsChecks: [isInvMenuNda],
    pageHistory: {
      isRoot: true,
    },
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/portfolio`,
    component: componentLoader(() => import(`../pages/portfolio`)),
    pageHistory: {
      isRoot: true,
      doNotIncludeInHistory: true,
    },
    title: 'Portfolio',
    permissionsChecks: [isInvMenuOther],
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
  },
  {
    path: `/${investorWorkstationPrefix}/portfolio/import`,
    pageHistory: {
      doNotIncludeInHistory: true,
    },
    layout: {
      noLayout: true,
    },
    component: componentLoader(() => import(`../pages/portfolio/import`)),
    roles: {
      overrideFn: ALL_ACCOUNTS_ONLY_ROLE_OVERRIDE,
    },
  },
  {
    path: `/${investorWorkstationPrefix}`,
    component: componentLoader(() => import(`../pages`)),
  },
  {
    path: `/${investorWorkstationPrefix}/agreements/interim`,
    component: componentLoader(
      () => import(`@npm/features/agreements/AgreementsInterim`)
    ),
  },
];
