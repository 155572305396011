// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  DocumentApi,
  DocumentIndexErrorCodes,
  DocumentIndexErrorTypes,
  DocumentCreateErrorCodes,
  DocumentShowErrorCodes,
  DocumentShowErrorTypes,
  DocumentUpdateErrorCodes,
  DocumentDestroyErrorCodes,
  DocumentDownloadErrorCodes,
  DocumentDownloadErrorTypes,
  DocumentPreviewErrorCodes,
  DocumentPreviewErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new DocumentApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type DocumentIndexMethod = DocumentApi['documentIndex'];
export type DocumentIndexResponseType = MethodResult<DocumentIndexMethod>;

export const useDocumentIndex = (
  variables: Parameters<DocumentIndexMethod>[0],
  config?: UseQueryConfig<
    DocumentIndexMethod,
    DocumentIndexErrorTypes
  >
) => {
  return useQuery<
    DocumentIndexMethod,
    DocumentIndexErrorTypes
  >(
    (...args) => api.documentIndex(...args),
    DocumentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentIndex'],
        ...apiQueryConfig.DocumentIndex,
        ...config?.queryConfig
      },
    },
    'DocumentIndex',
  );
};

export const useDocumentIndexInfinite = (
  variables: Parameters<DocumentIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    DocumentIndexMethod,
    DocumentIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    DocumentIndexMethod,
    DocumentIndexErrorTypes
  >(
    (...args) => api.documentIndex(...args),
    DocumentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentIndex', 'DocumentIndexInfinite'],
        ...apiQueryConfig.DocumentIndex,
        ...config?.queryConfig,
      },
    },
    'DocumentIndex',
  );
};

export const useDocumentIndexLazy = (baseOptions?: {
  variables?: Parameters<DocumentIndexMethod>[0],
  config?: UseQueryConfig<
    DocumentIndexMethod,
    DocumentIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    DocumentIndexMethod,
    DocumentIndexErrorTypes
  >(
    (...args) => api.documentIndex(...args),
    DocumentIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['DocumentIndex'],
        ...apiQueryConfig.DocumentIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'DocumentIndex',
  );
};


export type DocumentCreateMethod = DocumentApi['documentCreate'];

export const useDocumentCreate = () => {
  return useMutation<DocumentCreateMethod>(
    (...args) => api.documentCreate(...args),
    DocumentCreateErrorCodes,
    'DocumentCreate',
    '/api/documents',
    'post'
  );
};

export type DocumentShowMethod = DocumentApi['documentShow'];
export type DocumentShowResponseType = MethodResult<DocumentShowMethod>;

export const useDocumentShow = (
  variables: Parameters<DocumentShowMethod>[0],
  config?: UseQueryConfig<
    DocumentShowMethod,
    DocumentShowErrorTypes
  >
) => {
  return useQuery<
    DocumentShowMethod,
    DocumentShowErrorTypes
  >(
    (...args) => api.documentShow(...args),
    DocumentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentShow'],
        ...apiQueryConfig.DocumentShow,
        ...config?.queryConfig
      },
    },
    'DocumentShow',
  );
};

export const useDocumentShowInfinite = (
  variables: Parameters<DocumentShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    DocumentShowMethod,
    DocumentShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    DocumentShowMethod,
    DocumentShowErrorTypes
  >(
    (...args) => api.documentShow(...args),
    DocumentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentShow', 'DocumentShowInfinite'],
        ...apiQueryConfig.DocumentShow,
        ...config?.queryConfig,
      },
    },
    'DocumentShow',
  );
};

export const useDocumentShowLazy = (baseOptions?: {
  variables?: Parameters<DocumentShowMethod>[0],
  config?: UseQueryConfig<
    DocumentShowMethod,
    DocumentShowErrorTypes
  >
}) => {
  return useLazyQuery<
    DocumentShowMethod,
    DocumentShowErrorTypes
  >(
    (...args) => api.documentShow(...args),
    DocumentShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['DocumentShow'],
        ...apiQueryConfig.DocumentShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'DocumentShow',
  );
};


export type DocumentUpdateMethod = DocumentApi['documentUpdate'];

export const useDocumentUpdate = () => {
  return useMutation<DocumentUpdateMethod>(
    (...args) => api.documentUpdate(...args),
    DocumentUpdateErrorCodes,
    'DocumentUpdate',
    '/api/documents/{id}',
    'put'
  );
};

export type DocumentDestroyMethod = DocumentApi['documentDestroy'];

export const useDocumentDestroy = () => {
  return useMutation<DocumentDestroyMethod>(
    (...args) => api.documentDestroy(...args),
    DocumentDestroyErrorCodes,
    'DocumentDestroy',
    '/api/documents/{id}',
    'delete'
  );
};

export type DocumentDownloadMethod = DocumentApi['documentDownload'];
export type DocumentDownloadResponseType = MethodResult<DocumentDownloadMethod>;

export const useDocumentDownload = (
  variables: Parameters<DocumentDownloadMethod>[0],
  config?: UseQueryConfig<
    DocumentDownloadMethod,
    DocumentDownloadErrorTypes
  >
) => {
  return useQuery<
    DocumentDownloadMethod,
    DocumentDownloadErrorTypes
  >(
    (...args) => api.documentDownload(...args),
    DocumentDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentDownload'],
        ...apiQueryConfig.DocumentDownload,
        ...config?.queryConfig
      },
    },
    'DocumentDownload',
  );
};

export const useDocumentDownloadInfinite = (
  variables: Parameters<DocumentDownloadMethod>[0],
  config?: UseInfiniteQueryConfig<
    DocumentDownloadMethod,
    DocumentDownloadErrorTypes
  >
) => {
  return useInfiniteQuery<
    DocumentDownloadMethod,
    DocumentDownloadErrorTypes
  >(
    (...args) => api.documentDownload(...args),
    DocumentDownloadErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentDownload', 'DocumentDownloadInfinite'],
        ...apiQueryConfig.DocumentDownload,
        ...config?.queryConfig,
      },
    },
    'DocumentDownload',
  );
};

export const useDocumentDownloadLazy = (baseOptions?: {
  variables?: Parameters<DocumentDownloadMethod>[0],
  config?: UseQueryConfig<
    DocumentDownloadMethod,
    DocumentDownloadErrorTypes
  >
}) => {
  return useLazyQuery<
    DocumentDownloadMethod,
    DocumentDownloadErrorTypes
  >(
    (...args) => api.documentDownload(...args),
    DocumentDownloadErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['DocumentDownload'],
        ...apiQueryConfig.DocumentDownload,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'DocumentDownload',
  );
};


export type DocumentPreviewMethod = DocumentApi['documentPreview'];
export type DocumentPreviewResponseType = MethodResult<DocumentPreviewMethod>;

export const useDocumentPreview = (
  variables: Parameters<DocumentPreviewMethod>[0],
  config?: UseQueryConfig<
    DocumentPreviewMethod,
    DocumentPreviewErrorTypes
  >
) => {
  return useQuery<
    DocumentPreviewMethod,
    DocumentPreviewErrorTypes
  >(
    (...args) => api.documentPreview(...args),
    DocumentPreviewErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentPreview'],
        ...apiQueryConfig.DocumentPreview,
        ...config?.queryConfig
      },
    },
    'DocumentPreview',
  );
};

export const useDocumentPreviewInfinite = (
  variables: Parameters<DocumentPreviewMethod>[0],
  config?: UseInfiniteQueryConfig<
    DocumentPreviewMethod,
    DocumentPreviewErrorTypes
  >
) => {
  return useInfiniteQuery<
    DocumentPreviewMethod,
    DocumentPreviewErrorTypes
  >(
    (...args) => api.documentPreview(...args),
    DocumentPreviewErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['DocumentPreview', 'DocumentPreviewInfinite'],
        ...apiQueryConfig.DocumentPreview,
        ...config?.queryConfig,
      },
    },
    'DocumentPreview',
  );
};

export const useDocumentPreviewLazy = (baseOptions?: {
  variables?: Parameters<DocumentPreviewMethod>[0],
  config?: UseQueryConfig<
    DocumentPreviewMethod,
    DocumentPreviewErrorTypes
  >
}) => {
  return useLazyQuery<
    DocumentPreviewMethod,
    DocumentPreviewErrorTypes
  >(
    (...args) => api.documentPreview(...args),
    DocumentPreviewErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['DocumentPreview'],
        ...apiQueryConfig.DocumentPreview,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'DocumentPreview',
  );
};

