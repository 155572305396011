import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CypressDataIds } from '@npm/core/ui/constants';
import { truncate } from '@npm/core/ui/utils/formatters';
import { type AssetAggregate, type Holding } from '@npm/data-access';

import { AggregatedAssetType } from '../aggregated/List/components';

import { LabelContainer } from './HoldingCard.styles';

type Props = Pick<Holding, 'certificate_number'> & {
  asset?: AssetAggregate;
  wrap?: boolean;
  idMaxLength?: number;
  labelVariant?: 'label' | 'text';
  holding?: Holding;
};

export const HoldingCardHeaderLabel = ({
  certificate_number,
  asset,
  wrap,
  idMaxLength = 16,
  labelVariant = 'label',
  holding,
}: Props) => {
  if (labelVariant === 'text') {
    return (
      <LabelContainer $wrap={wrap}>
        <Icon name="file-text" size="xs" colorVariant="muted" />
        {asset && <span>{asset?.type?.name}</span>}
        {certificate_number ? (
          <Text
            data-cy={CypressDataIds.Holdings.Properties.UniqueId}
            size="sm"
            colorVariant="primary"
          >
            •&nbsp;{truncate(certificate_number, idMaxLength)}
          </Text>
        ) : null}
      </LabelContainer>
    );
  }

  return (
    <LabelContainer $wrap={wrap}>
      {asset &&
        (holding?.aggregated ? (
          <AggregatedAssetType asset={asset} showLabelWithTooltip />
        ) : (
          <Label variant="info" round>
            {asset?.type?.name}
          </Label>
        ))}
      {certificate_number ? (
        <Label
          variant="info"
          round
          data-cy={CypressDataIds.Holdings.Properties.UniqueId}
        >
          {truncate(certificate_number, idMaxLength)}
        </Label>
      ) : null}
    </LabelContainer>
  );
};
