// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PersonaApi,
  AccountPersonaShowErrorCodes,
  AccountPersonaShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PersonaApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountPersonaShowMethod = PersonaApi['accountPersonaShow'];
export type AccountPersonaShowResponseType = MethodResult<AccountPersonaShowMethod>;

export const useAccountPersonaShow = (
  variables: Parameters<AccountPersonaShowMethod>[0],
  config?: UseQueryConfig<
    AccountPersonaShowMethod,
    AccountPersonaShowErrorTypes
  >
) => {
  return useQuery<
    AccountPersonaShowMethod,
    AccountPersonaShowErrorTypes
  >(
    (...args) => api.accountPersonaShow(...args),
    AccountPersonaShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountPersonaShow'],
        ...apiQueryConfig.AccountPersonaShow,
        ...config?.queryConfig
      },
    },
    'AccountPersonaShow',
  );
};

export const useAccountPersonaShowInfinite = (
  variables: Parameters<AccountPersonaShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountPersonaShowMethod,
    AccountPersonaShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountPersonaShowMethod,
    AccountPersonaShowErrorTypes
  >(
    (...args) => api.accountPersonaShow(...args),
    AccountPersonaShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountPersonaShow', 'AccountPersonaShowInfinite'],
        ...apiQueryConfig.AccountPersonaShow,
        ...config?.queryConfig,
      },
    },
    'AccountPersonaShow',
  );
};

export const useAccountPersonaShowLazy = (baseOptions?: {
  variables?: Parameters<AccountPersonaShowMethod>[0],
  config?: UseQueryConfig<
    AccountPersonaShowMethod,
    AccountPersonaShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountPersonaShowMethod,
    AccountPersonaShowErrorTypes
  >(
    (...args) => api.accountPersonaShow(...args),
    AccountPersonaShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountPersonaShow'],
        ...apiQueryConfig.AccountPersonaShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountPersonaShow',
  );
};

