import { Icon } from '@npm/core/ui/components/atoms/Icon';
import styled from 'styled-components';

export const Container = styled.div`
  & > div:first-child {
    padding-top: 0;
  }
`;

export const ColorIconWrapper = styled(Icon)`
  background: ${({ theme }) => theme.color.general.layout.zero};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  padding: ${({ theme }) => theme.spacing.sm / 2}px;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
`;
