import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import styled, { css } from 'styled-components';

export const LabelContainer = styled.div<{ $wrap?: boolean }>`
  display: flex;
  ${({ $wrap = true }) => $wrap && `flex-wrap: wrap-reverse;`}
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}px;
`;

export const ButtonsContainer = styled.div`
  display: flex;

  gap: ${({ theme }) => theme.spacing.sm}px;

  & > * {
    flex: 1;
  }
`;

export type FooterVariant = 'primary' | 'secondary';

export const Card = styled(CardBase)<{ $footerVariant?: FooterVariant }>`
  ${({ $footerVariant, theme }) =>
    $footerVariant === 'secondary' &&
    css`
      & .card-footer {
        padding-top: ${({ theme }) => theme.spacing.sm}px;
        border-bottom-left-radius: ${theme.borderRadius.lg}px;
        border-bottom-right-radius: ${theme.borderRadius.lg}px;
        border-top: 1px solid ${theme.color.general.borders.secondary};
        background: ${theme.color.general.layout.two};
      }
      & .card-content {
        margin-bottom: ${theme.spacing.sm}px;
      }
    `}
`;
