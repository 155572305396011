// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SectorApi,
  GetMotifVolumeErrorCodes,
  GetMotifVolumeErrorTypes,
  GetSectorReturnsErrorCodes,
  GetSectorReturnsErrorTypes,
  GetSectorVolumeErrorCodes,
  GetSectorVolumeErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SectorApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type GetMotifVolumeMethod = SectorApi['getMotifVolume'];
export type GetMotifVolumeResponseType = MethodResult<GetMotifVolumeMethod>;

export const useGetMotifVolume = (
  variables: Parameters<GetMotifVolumeMethod>[0],
  config?: UseQueryConfig<
    GetMotifVolumeMethod,
    GetMotifVolumeErrorTypes
  >
) => {
  return useQuery<
    GetMotifVolumeMethod,
    GetMotifVolumeErrorTypes
  >(
    (...args) => api.getMotifVolume(...args),
    GetMotifVolumeErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetMotifVolume'],
        ...apiQueryConfig.GetMotifVolume,
        ...config?.queryConfig
      },
    },
    'GetMotifVolume',
  );
};

export const useGetMotifVolumeInfinite = (
  variables: Parameters<GetMotifVolumeMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetMotifVolumeMethod,
    GetMotifVolumeErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetMotifVolumeMethod,
    GetMotifVolumeErrorTypes
  >(
    (...args) => api.getMotifVolume(...args),
    GetMotifVolumeErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetMotifVolume', 'GetMotifVolumeInfinite'],
        ...apiQueryConfig.GetMotifVolume,
        ...config?.queryConfig,
      },
    },
    'GetMotifVolume',
  );
};

export const useGetMotifVolumeLazy = (baseOptions?: {
  variables?: Parameters<GetMotifVolumeMethod>[0],
  config?: UseQueryConfig<
    GetMotifVolumeMethod,
    GetMotifVolumeErrorTypes
  >
}) => {
  return useLazyQuery<
    GetMotifVolumeMethod,
    GetMotifVolumeErrorTypes
  >(
    (...args) => api.getMotifVolume(...args),
    GetMotifVolumeErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetMotifVolume'],
        ...apiQueryConfig.GetMotifVolume,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetMotifVolume',
  );
};


export type GetSectorReturnsMethod = SectorApi['getSectorReturns'];
export type GetSectorReturnsResponseType = MethodResult<GetSectorReturnsMethod>;

export const useGetSectorReturns = (
  variables: Parameters<GetSectorReturnsMethod>[0],
  config?: UseQueryConfig<
    GetSectorReturnsMethod,
    GetSectorReturnsErrorTypes
  >
) => {
  return useQuery<
    GetSectorReturnsMethod,
    GetSectorReturnsErrorTypes
  >(
    (...args) => api.getSectorReturns(...args),
    GetSectorReturnsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetSectorReturns'],
        ...apiQueryConfig.GetSectorReturns,
        ...config?.queryConfig
      },
    },
    'GetSectorReturns',
  );
};

export const useGetSectorReturnsInfinite = (
  variables: Parameters<GetSectorReturnsMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetSectorReturnsMethod,
    GetSectorReturnsErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetSectorReturnsMethod,
    GetSectorReturnsErrorTypes
  >(
    (...args) => api.getSectorReturns(...args),
    GetSectorReturnsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetSectorReturns', 'GetSectorReturnsInfinite'],
        ...apiQueryConfig.GetSectorReturns,
        ...config?.queryConfig,
      },
    },
    'GetSectorReturns',
  );
};

export const useGetSectorReturnsLazy = (baseOptions?: {
  variables?: Parameters<GetSectorReturnsMethod>[0],
  config?: UseQueryConfig<
    GetSectorReturnsMethod,
    GetSectorReturnsErrorTypes
  >
}) => {
  return useLazyQuery<
    GetSectorReturnsMethod,
    GetSectorReturnsErrorTypes
  >(
    (...args) => api.getSectorReturns(...args),
    GetSectorReturnsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetSectorReturns'],
        ...apiQueryConfig.GetSectorReturns,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetSectorReturns',
  );
};


export type GetSectorVolumeMethod = SectorApi['getSectorVolume'];
export type GetSectorVolumeResponseType = MethodResult<GetSectorVolumeMethod>;

export const useGetSectorVolume = (
  variables: Parameters<GetSectorVolumeMethod>[0],
  config?: UseQueryConfig<
    GetSectorVolumeMethod,
    GetSectorVolumeErrorTypes
  >
) => {
  return useQuery<
    GetSectorVolumeMethod,
    GetSectorVolumeErrorTypes
  >(
    (...args) => api.getSectorVolume(...args),
    GetSectorVolumeErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetSectorVolume'],
        ...apiQueryConfig.GetSectorVolume,
        ...config?.queryConfig
      },
    },
    'GetSectorVolume',
  );
};

export const useGetSectorVolumeInfinite = (
  variables: Parameters<GetSectorVolumeMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetSectorVolumeMethod,
    GetSectorVolumeErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetSectorVolumeMethod,
    GetSectorVolumeErrorTypes
  >(
    (...args) => api.getSectorVolume(...args),
    GetSectorVolumeErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetSectorVolume', 'GetSectorVolumeInfinite'],
        ...apiQueryConfig.GetSectorVolume,
        ...config?.queryConfig,
      },
    },
    'GetSectorVolume',
  );
};

export const useGetSectorVolumeLazy = (baseOptions?: {
  variables?: Parameters<GetSectorVolumeMethod>[0],
  config?: UseQueryConfig<
    GetSectorVolumeMethod,
    GetSectorVolumeErrorTypes
  >
}) => {
  return useLazyQuery<
    GetSectorVolumeMethod,
    GetSectorVolumeErrorTypes
  >(
    (...args) => api.getSectorVolume(...args),
    GetSectorVolumeErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetSectorVolume'],
        ...apiQueryConfig.GetSectorVolume,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetSectorVolume',
  );
};

