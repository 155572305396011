import React from 'react';

import { Flex, Padding } from '@npm/core/ui/components/atoms/common';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { SidebarSectionCard } from '@npm/core/ui/components/organisms/WizardNew/Layout/WizardLayout.styles';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbAccountSponsorshipType } from '@npm/data-access';
import { useTheme } from 'styled-components';

import {
  type OnboardingSectionConfig,
  type PostOnboardingContextType,
  type SectionKey,
} from '../../../PostOnboarding.types';
import { SidebarItem } from '../../SidebarItem';

import * as S from './SidebarSection.styles';
import { MainSectionLabel } from './MainSectionLabel';

type Props = OnboardingSectionConfig & {
  sectionKey: SectionKey;
  isActive: boolean;
  handleCloseFormAttempt: (callback?: () => void) => void;
};

export const SidebarSection = ({
  sectionKey,
  title,
  subTitle,
  tooltip,
  items,
  extraContent,
  handleCloseFormAttempt,
  v2SectionProps,
}: Props) => {
  const { durationInMin, isMainSection } = v2SectionProps || {};

  const theme = useTheme();
  const { isMobile } = useBreakpoints();
  const { account, isSectionCompleted } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      account: s.data.account,
      isSectionCompleted: s.data.sectionsProgress?.[sectionKey] === 100,
    })
  );

  // TODO: NFE-7276 should include level 2 as well?
  const hasFullAccessToMarketplace =
    CbAccountSponsorshipType.isSponsoredAccountMinLvl2(
      account?.sponsorship_type
    );

  return (
    <SidebarSectionCard
      outlined={true}
      noContentPadding
      style={{
        border:
          isMainSection && !isSectionCompleted
            ? `2px solid ${theme.color.info.borders.primary}`
            : null,
      }}
    >
      {isMainSection ? (
        <S.SectionTitleWrapper>
          <Flex align="center" justify="space-between">
            <Text
              size="default"
              color="info"
              colorVariant="primary"
              weight="bold"
            >
              {title}
            </Text>
            <MainSectionLabel
              sectionKey={sectionKey}
              isSectionCompleted={isSectionCompleted}
              hasFullAccessToMarketplace={hasFullAccessToMarketplace}
              durationInMin={durationInMin}
            />
          </Flex>
          {subTitle && !isSectionCompleted ? (
            <Text size="sm" colorVariant="secondary">
              {subTitle}
            </Text>
          ) : null}
        </S.SectionTitleWrapper>
      ) : (
        <Padding top="md" left="md" right="md">
          <Text size="sm" colorVariant="primary">
            {title}{' '}
            {tooltip && (
              <TooltipInfoIcon
                title={tooltip}
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
              />
            )}
          </Text>
        </Padding>
      )}
      {extraContent && (
        <Padding top="md" left="md" right="md">
          {extraContent}
        </Padding>
      )}
      <Padding all={isMobile ? 'sm' : 'md'}>
        {items
          .filter(({ title }) => !!title)
          .map(item => (
            <SidebarItem
              key={item.key}
              step={item}
              handleCloseFormAttempt={handleCloseFormAttempt}
              testId={`stepper-item-${item.key}`}
            />
          ))}
      </Padding>
    </SidebarSectionCard>
  );
};
