import { Text } from '@npm/core/ui/components/atoms/Typography';
import styled from 'styled-components';

export const UserOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectedAccountContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SelectedAccountFirm = styled(Text).attrs({
  colorVariant: 'tertiary',
})``;
