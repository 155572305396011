import { type ComponentProps } from 'react';

import styled, {
  type ColorGroup,
  css,
  type DefaultTheme,
} from 'styled-components';

import { getFocusBorderStyles, useBreakpoint } from '../../../../utils/style';
import {
  applyFontSizeShrinkCss,
  BodyTextBoldVariationSettings,
  BodyTextStyles,
  ExtraSmallTextStyles,
  Heading0Styles,
  Heading1Styles,
  Heading2Styles,
  SmallTextBoldVariationSettings,
  SmallTextStyles,
  XXSmallTextStyles,
} from '../typography.styles';
import { type TypographyStyledComponentProps } from '../typography.types';

import { type Text as TextComponent } from './Text';
import { type TextSize, type TextWeight } from './Text.types';

const sizesMap = {
  xxl: Heading0Styles,
  xl: Heading1Styles,
  lg: Heading2Styles,
  default: BodyTextStyles,
  sm: SmallTextStyles,
  xs: ExtraSmallTextStyles,
  xxs: XXSmallTextStyles,
};

const getFontSize = (size: TextSize, theme: DefaultTheme) => {
  return size === 'default'
    ? theme.typography.size.md
    : theme.typography.size[size];
};

export const Text = styled.p<
  {
    $size: TextSize;
    $weight: TextWeight;
    $blur: ComponentProps<typeof TextComponent>['blur'];
    $textWrap?: ComponentProps<typeof TextComponent>['textWrap'];
    $valueLength?: number;
    $textAlign?: React.CSSProperties['textAlign'];
    $lineClamp?: number;
    $hyperlinkColor?: ColorGroup;
  } & TypographyStyledComponentProps
>`
  ${({ $size }) => sizesMap[$size]};
  font-synthesis: none !important;

  ${({ $size, $weight, theme }) =>
    $size === 'default' &&
    $weight === 'bold' &&
    css`
      ${BodyTextBoldVariationSettings}
      ${useBreakpoint(theme).mobile`
        ${BodyTextBoldVariationSettings}
      `}
    `}

  ${({ $size, $weight }) =>
    $size === 'sm' && $weight === 'bold' && SmallTextBoldVariationSettings}

  ${({ $size, $weight }) =>
    ($size === 'xs' || $size === 'xxs') &&
    $weight === 'bold' &&
    SmallTextBoldVariationSettings}

  b, strong {
    ${({ $size }) =>
      $size === 'default'
        ? BodyTextBoldVariationSettings
        : SmallTextBoldVariationSettings}
  }

  ${({ $blur }) => `
    ${$blur ? 'filter: blur(3px);' : ''}
    ${$blur ? 'user-select: none;' : ''}
  `}

  ${({ $textWrap }) =>
    $textWrap &&
    css`
      text-wrap: ${$textWrap};
    `}


  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}

  ${({ $hyperlinkColor, theme }) =>
    $hyperlinkColor &&
    css`
      color: ${theme.color[$hyperlinkColor].typography.primary} !important;
      :hover {
        color: ${() => {
          const colorTheme = $hyperlinkColor && theme.color[$hyperlinkColor];
          return colorTheme && 'primaryHover' in colorTheme.backgrounds
            ? colorTheme.backgrounds.primaryHover
            : theme.color.info.backgrounds.primaryHover;
        }} !important;
      }
    `}

  ${({ $lineClamp }) =>
    $lineClamp &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${$lineClamp};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  body.tab-focus ${getFocusBorderStyles()};

  // override size with shrinked size based on text value length
  ${({ $valueLength, $size, theme }) =>
    $valueLength &&
    applyFontSizeShrinkCss($valueLength, getFontSize($size, theme))}
`;
