import { Text } from '@npm/core/ui/components/atoms/Typography';
import { TableVertical as TableVerticalBase } from '@npm/core/ui/components/molecules/TableVertical';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: auto;
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm}px;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  border-radius: ${({ theme }) => theme.spacing.xs}px;

  & > * {
    flex-grow: 1;
  }
`;

export const TableVertical = styled(TableVerticalBase)`
  margin: ${({ theme }) => -theme.spacing.sm}px 0;
  .table-vertical-row {
    padding: ${({ theme }) => theme.spacing.xs}px 0;
  }
`;

export const FooterText = styled(Text)`
  text-align: center;
  max-width: 240px;
  white-space: normal;
`;
