import React from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { type IssuerEntityApiIssuerEntityIndexRequest } from '@npm/data-access';

import { FilterPanel } from '../../../../filters/filters/FilterPanel';

import { type FilterTabKey } from './filters/FilterTabs';

import * as S from './IssuerEntityIndexFilter.styles';
import { CustomCollapsedContent } from './CustomCollapsedContent';
import {
  ISSUER_ENTITY_INDEX_INITIAL_FILTER_VALUES,
  type IssuerEntityIndexFilterParams,
  issuerEntityIndexFilters,
} from './IssuerEntityIndexFilter.config';

type Props = {
  handleSubmit: (values: IssuerEntityIndexFilterParams) => void;
  totalActiveFilters: number;
  variables: IssuerEntityApiIssuerEntityIndexRequest;
  initialFilterValues?: Partial<IssuerEntityApiIssuerEntityIndexRequest>;
  closeDrawer: () => void;
  showFilterTabs?: boolean;
  isShownInsideDrawer?: boolean;
};

export const IssuerEntityIndexFilter = ({
  handleSubmit,
  initialFilterValues,
  variables,
  totalActiveFilters,
  showFilterTabs,
  isShownInsideDrawer,
  closeDrawer,
}: Props) => {
  const [form] = Form.useForm<IssuerEntityIndexFilterParams>();

  const handleTabsChange = (key: FilterTabKey) => {
    if (key === 'watchlist') {
      handleSubmit({ watchlistOnly: true, portfolioOnly: false });
    } else if (key === 'portfolio') {
      handleSubmit({ watchlistOnly: false, portfolioOnly: true });
    } else {
      handleSubmit({ watchlistOnly: false, portfolioOnly: false });
    }
  };

  const filterPanel = (
    <FilterPanel
      form={form}
      isExpandable
      filters={issuerEntityIndexFilters}
      initialValues={ISSUER_ENTITY_INDEX_INITIAL_FILTER_VALUES}
      initialFilterValues={initialFilterValues}
      handleSubmit={handleSubmit}
      totalRecords={undefined}
      totalActiveFilters={totalActiveFilters}
      renderCustomCollapsedContent={(isExpanded, toggleIsExpanded) => (
        <CustomCollapsedContent
          closeDrawer={closeDrawer}
          isShownInsideDrawer={isShownInsideDrawer}
          filtersToggleButtonProps={{
            isOpened: isExpanded,
            onClick: toggleIsExpanded,
          }}
          showFilterTabs={showFilterTabs}
          variables={variables}
          handleTabsChange={handleTabsChange}
        />
      )}
    />
  );

  return (
    <S.FixedWrapper $isShownInsideDrawer={isShownInsideDrawer}>
      {filterPanel}
    </S.FixedWrapper>
  );
};
