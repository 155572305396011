import React, { useEffect, useState } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useTheme } from 'styled-components';

import * as S from './TableScrollIndicator.styles';

export const TABLE_SCROLLABLE_ELEMENT_IDENTIFIER = '.ant-table-body';
const SCROLL_INDICATOR_BUTTON_CLASS = 'scroll-indicator-button';

const OPACITY_DECREASE_THRESHOLD_PX = 120;

const getOpacity = (distanceFromEdge: number) => {
  if (distanceFromEdge >= OPACITY_DECREASE_THRESHOLD_PX) {
    return 0;
  }

  return Math.max(0, 1 - distanceFromEdge / OPACITY_DECREASE_THRESHOLD_PX);
};

type Props = {
  tableId: string;
  handleClick?: () => void;
};

export const TableScrollIndicator = ({ tableId, handleClick }: Props) => {
  const theme = useTheme();
  const [distanceFromTop, setDistanceFromTop] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { isMobile, isTablet } = useBreakpoints();

  useEffect(() => {
    const tableElement = document
      .getElementById(tableId)
      ?.querySelector(TABLE_SCROLLABLE_ELEMENT_IDENTIFIER);

    if (!tableElement) return;

    const handleScroll = () => {
      setDistanceFromTop(tableElement.scrollTop);
    };

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = event => {
      // return if the mouse has left to the indicator button (which needs to remain clickable)
      if (event.relatedTarget?.closest(`.${SCROLL_INDICATOR_BUTTON_CLASS}`)) {
        return;
      }
      setIsHovered(false);
    };

    tableElement.addEventListener('scroll', handleScroll);
    tableElement.addEventListener('mouseenter', handleMouseEnter);
    tableElement.addEventListener('mouseleave', handleMouseLeave);

    // eslint-disable-next-line consistent-return
    return () => {
      tableElement.removeEventListener('scroll', handleScroll);
      tableElement.removeEventListener('mouseenter', handleMouseEnter);
      tableElement.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [tableId]);

  return (
    (isHovered || isMobile || isTablet) && (
      <S.ScrollIndicatorButton
        className={SCROLL_INDICATOR_BUTTON_CLASS}
        onClick={handleClick}
        size="sm"
        variant="text"
        icon={
          <Icon
            name={'chevron-down'}
            color={theme.color.info.typography.primary}
            size={'xxs'}
          />
        }
        $opacity={getOpacity(distanceFromTop)}
      >
        Show More
      </S.ScrollIndicatorButton>
    )
  );
};
