/* eslint-disable react/no-multi-comp */
import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CypressDataIds } from '@npm/core/ui/constants';

import * as S from './DocumentViewerModal.styles';
import { type Document, type DocumentWithId } from './DocumentViewer.types';
import { useDocumentDownload } from './DocumentViewerHook';
import { WebviewerWrapper } from './WebviewerWrapper';

type Props = {
  document: Document;
  documentMetadata:
    | {
        displayName: string | null | undefined;
        downloadable: boolean | null | undefined;
      }
    | null
    | undefined;
  isOpen: boolean;
  handleCancel: () => void;
  handleDownload?: () => void;
  includeOboHeaders?: boolean;
};

const ModalFooter = ({
  handleCancel,
  document,
  documentMetadata,
  handleDownload,
  includeOboHeaders,
}: Pick<
  Props,
  | 'handleCancel'
  | 'documentMetadata'
  | 'document'
  | 'handleDownload'
  | 'includeOboHeaders'
>) => {
  const npmDocumentId = document?.type === 'with-npm-id' ? document.id : null;

  const { handleDownload: downloadNpmDocument, isLoading } =
    useDocumentDownload(includeOboHeaders);

  return (
    <S.FooterWrapper>
      <Button variant={'default'} onClick={handleCancel} blockOnMobile>
        Close
      </Button>
      {(documentMetadata?.downloadable && !!npmDocumentId) || handleDownload ? (
        <Button
          variant={'outline'}
          loading={isLoading}
          blockOnMobile
          onClick={
            handleDownload
              ? handleDownload
              : () => downloadNpmDocument(document, documentMetadata)
          }
          icon={<Icon name="download" />}
        >
          Download
        </Button>
      ) : null}
    </S.FooterWrapper>
  );
};

export const DocumentViewerModal = ({
  isOpen,
  handleCancel,
  document,
  documentMetadata,
  handleDownload,
  includeOboHeaders = true,
}: Props) => {
  return (
    <S.Modal
      data-cy={CypressDataIds.DocumentPreview.Modal}
      destroyOnClose={true}
      open={isOpen}
      title={documentMetadata?.displayName ?? 'Document preview'}
      onCancel={handleCancel}
      footer={
        <ModalFooter
          handleCancel={handleCancel}
          documentMetadata={documentMetadata}
          document={document}
          handleDownload={handleDownload}
          includeOboHeaders={includeOboHeaders}
        />
      }
    >
      <WebviewerWrapper
        key={(document as DocumentWithId)?.id}
        document={document}
        mode="full"
        handleClose={handleCancel}
        includeOboHeaders={includeOboHeaders}
      />
    </S.Modal>
  );
};
