import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import { useTheme } from 'styled-components';

import { type OrderSizeType } from '../../../../../../order';
import { getCalculatedValues } from '../../EnterSellIndication.utils';
import type { SellFormValues } from '../SellIndicationDrawer.types';

import * as S from './ReviewOrderSection.styles';
import { useReviewSectionConfig } from './ReviewOrderSection.hooks';

type Props = {
  values: SellFormValues;
  exerciseCosts: number;
  orderSizeType: OrderSizeType;
  errorMessage?: string | false;
};

export const ReviewOrderSection = ({
  values,
  exerciseCosts,
  orderSizeType,
  errorMessage,
}: Props) => {
  const theme = useTheme();

  const { estimatedGrossProceeds, estimatedNetProceeds } = getCalculatedValues(
    values?.quantity,
    values?.pricePerShare,
    exerciseCosts
  );

  return (
    <S.ReviewOrderContainer>
      <S.Card variant="compact" background="one">
        <TableVertical
          data={values}
          rows={useReviewSectionConfig({
            values,
            orderSizeType,
            estimatedGrossProceeds,
            exerciseCosts,
          })}
        />
      </S.Card>
      <S.NetEstimatedProceeds>
        <Text.Price
          value={estimatedNetProceeds}
          style={{ fontSize: `${theme.typography.size.xl}px` }}
        />
        <Text size="sm">Your Estimated Net Proceeds</Text>
      </S.NetEstimatedProceeds>
      {errorMessage && (
        <Margin top="lg">
          <Alert type="error" message={errorMessage} />
        </Margin>
      )}
    </S.ReviewOrderContainer>
  );
};
