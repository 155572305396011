import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { GetStartedCard } from '../../components/GetStartedCard';

type Props = {
  onClick: () => void;
};

export const AddBankAccountCta = ({ onClick }: Props) => {
  return (
    <GetStartedCard
      title="Bank Information"
      description={
        <Text size="sm">
          Enter payment instructions for a bank account held under the same name
          as this NPM Platform account.
        </Text>
      }
      iconName="coin"
      cta={
        <Button variant="outline" icon={<Icon name="plus" />} onClick={onClick}>
          Add Bank Account
        </Button>
      }
    />
  );
};
