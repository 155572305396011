import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  type BrokerageFirmAggregate,
  CbATSPool,
  CbVisibility,
  type IssuerEntityAggregate,
  type VisibilityCode,
} from '@npm/data-access';
import Form from 'antd/lib/form';

import { useIsInvestor } from '../../../../../../auth/user/role/hooks/useIsInvestor';
import { ATSPoolSelect } from '../../../../components/inputs/ATSPoolSelect';
import { type VisibilityFormSectionFields } from '../../../../components/inputs/types';
import { handleExpirationDateResetOnVisibilityChange } from '../../../../components/inputs/utils';
import {
  useVisibilitySectionPreviewLabel,
  validateVisibilityOption,
  VisibilitySelect,
} from '../../../../components/inputs/VisibilitySelect';
import {
  useATSPoolPermissions,
  useOrderVisibilityPermissions,
} from '../../../../utils/order';

type Props = {
  value: VisibilityFormSectionFields;
  initialValue?: string;
  hidden?: boolean;
  orderType: string | undefined;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  issuer?: IssuerEntityAggregate;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const VisibilityFormSection = ({
  value,
  initialValue,
  orderType,
  brokerageFirm,
  hidden,
  issuer,
  ...props
}: Props) => {
  const isInvestor = useIsInvestor();

  const { isAllowedToExternalize } = useOrderVisibilityPermissions();

  const { getIsAtsPoolEditAllowed } = useATSPoolPermissions();

  const form = Form.useFormInstance<VisibilityFormSectionFields>();

  const previewLabel = useVisibilitySectionPreviewLabel({
    visibilityFields: value,
  });

  const isAtsPoolSectionVisible = getIsAtsPoolEditAllowed(
    value.visibility as VisibilityCode,
    brokerageFirm
  );

  const handleVisibilityChange = (val: VisibilityCode) => {
    handleExpirationDateResetOnVisibilityChange(form, val, value.expiration);

    // for INV (lvl3) we determine ats_pool value by affiliate disclosure modal (in getSubmitPayload)
    if (!isInvestor) {
      form.setFieldsValue({
        atsPool:
          val === CbVisibility.items.internal
            ? CbATSPool.items.internal
            : CbATSPool.items.ats_sm,
      });
    }
  };

  return (
    <CollapsibleFormSection.Item
      label="Visibility"
      preview={previewLabel}
      style={hidden ? { display: 'none' } : {}}
      key="visibility"
      name="visibility"
      {...props}
    >
      <FormItem
        name="visibility"
        marginBottom="sm"
        rules={[VALIDATION_RULES.required()]}
        hidden={hidden}
      >
        <VisibilitySelect
          onChange={handleVisibilityChange}
          validateOption={option =>
            validateVisibilityOption(
              option,
              orderType,
              brokerageFirm,
              isAllowedToExternalize,
              issuer
            )
          }
        />
      </FormItem>
      <FormItem
        name="atsPool"
        marginBottom="sm"
        rules={[VALIDATION_RULES.required()]}
        hidden={hidden || !isAtsPoolSectionVisible}
      >
        <ATSPoolSelect
          visibility={value.visibility as VisibilityCode}
          brokerageFirm={brokerageFirm}
        />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};
