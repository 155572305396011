import { CbOnboardingStatus } from '@npm/data-access';

import { BeneficialOwnershipPostOnboarding } from './steps/BeneficialOwnership';

import {
  type GetSectionsConfigFn,
  parentStepKeyToTitleMap,
  sectionKeyToTitleMap,
} from './PostOnboarding.types';
import { filterOnboardingSectionsConfig } from './PostOnboarding.utils';
import {
  AuthorizedSignersPostOnboarding,
  BankAccountInformationPostOnboarding,
  BuyerSurveyPostOnboarding,
  EntityAccreditationPostOnboarding,
  EntityInformationPostOnboarding,
  EntityTaxIdPostOnboarding,
  FormationDocumentPostOnboarding,
  GovernmentIDPostOnboarding,
  IndividualInformationPostOnboarding,
  IndividualTaxIdPostOnboarding,
  MarketplaceAgreementPostOnboarding,
  PayingAgentAgreementPostOnboarding,
  TaxInformationPostOnboarding,
} from './steps';

export const getIdentityVerificationSteps = () => {
  const entityInformation = {
    key: 'entity_information_state',
    title: CbOnboardingStatus.titleMap.entity_information_state,
    component: stepProps => (
      <EntityInformationPostOnboarding stepProps={stepProps} />
    ),
    isEditable: true,
  } as const;

  const taxInformation = {
    key: 'tax_information_state',
    title: 'Account’s Taxpayer Status',
    component: stepProps => (
      <TaxInformationPostOnboarding
        stepProps={stepProps}
        zendeskTicketGroupId={'post_onboarding_entity_tax_information_state'}
      />
    ),
  } as const;

  const taxId = {
    key: 'entity_tax_state',
    title: CbOnboardingStatus.titleMap.entity_tax_state,
    component: stepProps => (
      <EntityTaxIdPostOnboarding
        stepProps={stepProps}
        zendeskTicketGroupId={'post_onboarding_entity_tax_id'}
      />
    ),
  } as const;

  const formationDocument = {
    key: 'entity_formation_document_state',
    title: CbOnboardingStatus.titleMap.entity_formation_document_state,
    component: stepProps => (
      <FormationDocumentPostOnboarding
        stepProps={stepProps}
        zendeskTicketGroupId={'post_onboarding_entity_formation_document'}
      />
    ),
  } as const;

  const authorizedSigner = {
    key: 'authorized_signer_state',
    title: CbOnboardingStatus.titleMap.authorized_signer_state,
    component: stepProps => (
      <AuthorizedSignersPostOnboarding stepProps={stepProps} />
    ),
    isEditable: true,
  } as const;

  const beneficialOwner = {
    key: 'beneficial_ownership_assessment_state',
    title: CbOnboardingStatus.titleMap.beneficial_ownership_assessment_state,
    titleTooltip: 'Information related to control person or beneficiary owner',
    component: stepProps => (
      <BeneficialOwnershipPostOnboarding stepProps={stepProps} />
    ),
  } as const;

  return {
    entityInformation,
    taxInformation,
    taxId,
    authorizedSigner,
    formationDocument,
    beneficialOwner,
  };
};

export const getRepresentativeIdentityVerificationSteps = (
  personId: number
) => {
  const titleTooltip =
    'We need an entity representative information and Identity Verification in order to verify your account';

  const personalInformation = {
    key: 'personal_information_state',
    title: `Representative ${CbOnboardingStatus.titleMap.personal_information_state}`,
    titleTooltip,
    component: stepProps => (
      <IndividualInformationPostOnboarding
        stepProps={stepProps}
        personId={personId}
      />
    ),
    isEditable: true,
  } as const;

  const individualTaxId = {
    key: 'individual_tax_state',
    title: 'Representative Tax ID',
    titleTooltip,
    component: stepProps => (
      <IndividualTaxIdPostOnboarding
        zendeskTicketGroupId={'post_onboarding_individual_tax_id'}
        stepProps={stepProps}
        personId={personId}
      />
    ),
  } as const;

  const governmentIssuedId = {
    key: 'representative_photo_state',
    title: CbOnboardingStatus.titleMap.representative_photo_state,
    titleTooltip,
    component: stepProps => (
      <GovernmentIDPostOnboarding
        zendeskTicketGroupId={'post_onboarding_entity_government_id'}
        stepProps={stepProps}
      />
    ),
  } as const;

  return { personalInformation, individualTaxId, governmentIssuedId };
};

export const getOtherSteps = () => {
  const marketplaceAgreement = {
    key: 'marketplace_agreement_state',
    title: CbOnboardingStatus.titleMap.marketplace_agreement_state,
    titleTooltip:
      'Agree to NPM standard terms prior to posting sell indications or negotiating with buyers.',
    component: stepProps => (
      <MarketplaceAgreementPostOnboarding
        zendeskTicketGroupId={'post_onboarding_entity_marketplace_agreement'}
        stepProps={stepProps}
      />
    ),
  } as const;

  const payingAgentAgreement = {
    key: 'paying_agent_agreement_state',
    title: CbOnboardingStatus.titleMap.paying_agent_agreement_state,
    component: stepProps => (
      <PayingAgentAgreementPostOnboarding
        zendeskTicketGroupId={'post_onboarding_entity_paying_agent_agreement'}
        stepProps={stepProps}
      />
    ),
  } as const;

  const accreditation = {
    key: 'accredited_investor_state',
    title: CbOnboardingStatus.titleMap.accredited_investor_state,
    component: stepProps => (
      <EntityAccreditationPostOnboarding
        zendeskTicketGroupId={'post_onboarding_entity_accreditation'}
        stepProps={stepProps}
      />
    ),
  } as const;

  const buyerSurvey = {
    key: 'buyer_survey_state',
    title: CbOnboardingStatus.titleMap.buyer_survey_state,
    titleTooltip: (
      <>
        Completing the Buyer Survey helps us understand your financial
        experience and objectives, and is a required step to receive investment
        opportunities from us.
        <br />
        <br />
        Note: This survey is not required if you are participating in company
        sponsored tender offer.
      </>
    ),
    component: stepProps => (
      <BuyerSurveyPostOnboarding stepProps={stepProps} variant="entity" />
    ),
    isEditable: true,
  } as const;

  const bankInformation = {
    key: 'bank_account_state',
    title: CbOnboardingStatus.titleMap.bank_account_state,
    titleTooltip:
      'Enter payment instructions for a bank account held under the same name as this NPM account',
    component: stepProps => (
      <BankAccountInformationPostOnboarding stepProps={stepProps} />
    ),
    isEditable: true,
  } as const;

  return {
    marketplaceAgreement,
    payingAgentAgreement,
    accreditation,
    buyerSurvey,
    bankInformation,
  };
};

export const getNPMSEntityOnboardingSections: GetSectionsConfigFn = ({
  personId,
  npmsStepsArgs,
}) => {
  const { isRequiredForMarketplaceSectionCompleted } = npmsStepsArgs || {};

  const {
    entityInformation,
    taxInformation,
    taxId,
    formationDocument,
    beneficialOwner,
    authorizedSigner,
  } = getIdentityVerificationSteps();

  const { personalInformation, individualTaxId, governmentIssuedId } =
    getRepresentativeIdentityVerificationSteps(personId);

  const {
    accreditation,
    buyerSurvey,
    bankInformation,
    marketplaceAgreement,
    payingAgentAgreement,
  } = getOtherSteps();

  return [
    {
      key: 'required_for_marketplace',
      title: sectionKeyToTitleMap.required_for_marketplace,
      subTitle:
        'We need to verify your identity before you can place orders that will be visible to buyers and sellers on the Marketplace',
      v2SectionProps: {
        durationInMin: 8,
        isMainSection: true,
      },
      items: [
        accreditation,
        buyerSurvey,
        entityInformation,
        taxId,
        formationDocument,
        beneficialOwner,
        marketplaceAgreement,
      ],
    },
    {
      key: 'other_requirements',
      title: 'Additional Requirements',
      v2SectionProps: {
        isMainSection: false,
        hidden: !isRequiredForMarketplaceSectionCompleted,
      },
      items: [
        {
          key: 'representative_identity_verification',
          title: parentStepKeyToTitleMap.representative_identity_verification,
          component: () => null,
          substeps: [personalInformation, individualTaxId, governmentIssuedId],
        },
        taxInformation,
        authorizedSigner,
        bankInformation,
        payingAgentAgreement,
      ],
    },
  ];
};

export const getNonNPMSEntityOnboardingSections: GetSectionsConfigFn = ({
  personId,
}) => {
  const {
    entityInformation,
    taxInformation,
    taxId,
    formationDocument,
    authorizedSigner,
    beneficialOwner,
  } = getIdentityVerificationSteps();

  const { personalInformation, individualTaxId, governmentIssuedId } =
    getRepresentativeIdentityVerificationSteps(personId);

  const {
    marketplaceAgreement,
    payingAgentAgreement,
    accreditation,
    buyerSurvey,
    bankInformation,
  } = getOtherSteps();

  return [
    {
      key: 'aml_kyc',
      title: sectionKeyToTitleMap.aml_kyc,
      tooltip:
        'In order to help the government fight the funding of terrorism and money laundering activities, U.S. federal law requires all financial institutions to obtain, verify, and record information that identifies each person or entity with whom we conduct securities transactions.',
      showProgress: true,
      items: [
        {
          key: 'entity_information',
          title: parentStepKeyToTitleMap.entity_information,
          component: () => null,
          tooltip:
            'This information is collected to populate agreements and confirm your identity to keep you and NPM safe.',
          substeps: [
            entityInformation,
            taxInformation,
            taxId,
            formationDocument,
          ],
        },
        authorizedSigner,
        beneficialOwner,
        {
          key: 'representative_identity_verification',
          title: parentStepKeyToTitleMap.representative_identity_verification,
          component: () => null,
          substeps: [personalInformation, individualTaxId, governmentIssuedId],
        },
      ],
    },
    {
      key: 'agreements',
      title: sectionKeyToTitleMap.agreements,
      tooltip: 'Legal agreements required for broker-dealer services.',
      subTitleTooltip: '',
      items: [marketplaceAgreement, payingAgentAgreement],
    },
    {
      key: 'other_requirements',
      title: sectionKeyToTitleMap.other_requirements,
      tooltip:
        "Required information to complete the settlement process, to evaluate the entity's level of financial sophistication, and to better understand their financial profile.",
      items: [accreditation, buyerSurvey, bankInformation],
    },
  ];
};

export const getEntityOnboardingSections: GetSectionsConfigFn = props => {
  const { variant, onboardingStatus } = props;
  const isNPMS = variant === 'npms';

  const config = isNPMS
    ? getNPMSEntityOnboardingSections(props)
    : getNonNPMSEntityOnboardingSections(props);

  return filterOnboardingSectionsConfig(config, onboardingStatus);
};
