import React from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading } from '@npm/core/ui/components/atoms/Typography/Heading';
import { type IconNames } from '@npm/utils';
import { type ColorGroup } from 'styled-components';

import * as S from './GetStartedCard.styles';

type Props = {
  title: string;
  description: React.ReactNode;
  iconName: IconNames;
  iconColor?: ColorGroup;
  cta?: React.ReactNode;
  contentMaxWidth?: number;
};

export const GetStartedCard = ({
  title,
  description,
  cta,
  iconName,
  iconColor = 'info',
  contentMaxWidth,
}: Props) => {
  return (
    <S.Card $contentMaxWidth={contentMaxWidth} noContentPadding>
      <S.IconWrapper>
        <Icon name={iconName} size="md" color={iconColor} />
      </S.IconWrapper>
      <Heading variant="h1" marginBottom="sm">
        {title}
      </Heading>
      <Margin bottom="lg">{description}</Margin>
      {cta}
    </S.Card>
  );
};
