import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';

import { Greeting } from '../../../../auth/user/components';
import { useUserContextStore } from '../../../../auth/user/context';

export const GreetingWithSubtitle = () => {
  const isSponsoredInvestor = useUserContextStore(
    store => !!store.sponsorshipType
  );

  return (
    <>
      <Greeting />
      <Text size="default" colorVariant="secondary">
        Track your portfolio and watchlist activity{' '}
        {isSponsoredInvestor && '| Trade on SecondMarket'}
      </Text>
    </>
  );
};
