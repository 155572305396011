import {
  type AffiliateDisclosureType,
  type ATSPoolCode,
  CbAffiliateDisclosure,
  CbATSPool,
  CbOrderEntryType,
  CbOrderItemUnit,
  CbSubmissionStructure,
  type Holding,
  type SecondmarketOrderItemUpdateRequestContract,
  type SecondmarketSubmissionCreateRequestContract,
} from '@npm/data-access';
import Big from 'big.js';

import { sizeInShares } from '../../../../order';
import { EXECUTION_FEE } from '../../../utils/investor/calculateEstimatedPrice';

import { type SellFormValues } from './SellIndicationDrawer';

export const getSubmitPayload = (
  values: SellFormValues,
  accountId: number,
  issuerEntityId: number,
  holding: Holding,
  quantity: number,
  affiliateDisclosure?: AffiliateDisclosureType,
  signature?: string,
  isSponsorshipLevel3?: boolean
): SecondmarketSubmissionCreateRequestContract => {
  let atsPoolValue: ATSPoolCode;

  const isMissingPoO = !holding?.aggregated_proof_of_ownership_documents_count;

  if (isSponsorshipLevel3 && !isMissingPoO) {
    atsPoolValue =
      affiliateDisclosure === CbAffiliateDisclosure.items.no
        ? CbATSPool.items.ats_sm
        : CbATSPool.items.ats_i;
  } else {
    atsPoolValue = CbATSPool.items.internal;
  }

  return {
    issuer_entity_id: issuerEntityId,
    account_id: accountId,
    order_entry_type: CbOrderEntryType.items.firm,
    ats_pool: atsPoolValue,
    structure: CbSubmissionStructure.items.direct,
    order_buy_sell: 'sell',
    gtc: true,
    expiration: undefined,
    asset_type: holding.asset.type,
    affiliate_disclosure: affiliateDisclosure,
    signature,
    holding_id: holding.id,
    holding_npm_guid: holding.npm_guid,
    order_item_unit: CbOrderItemUnit.items.SHARES,
    quantity,
    price: values.pricePerShare,
  };
};

export const getCalculatedValues = (
  quantity: number,
  pricePerShare?: number,
  exerciseCosts?: number
) => {
  let estimatedGrossProceeds = 0;

  if (!quantity || !pricePerShare) {
    return { estimatedGrossProceeds: null, estimatedNetProceeds: null };
  } else {
    estimatedGrossProceeds = new Big(quantity).times(pricePerShare).toNumber();
  }

  const executionFee = estimatedGrossProceeds * EXECUTION_FEE;
  const estimatedNetProceeds = new Big(estimatedGrossProceeds)
    .minus(executionFee)
    .minus(exerciseCosts ?? 0)
    .toNumber();

  return { estimatedGrossProceeds, estimatedNetProceeds };
};

export const getEditOrderPayload = (
  values: SellFormValues
): SecondmarketOrderItemUpdateRequestContract => ({
  order_item_unit: CbOrderItemUnit.items.SHARES,
  quantity: sizeInShares('Shares', values.quantity, values.pricePerShare),
  price: values.pricePerShare,
});

export const calculateExerciseCosts = (holding: Holding, amount: number) => {
  return holding?.asset?.strike_price
    ? new Big(holding.asset.strike_price).times(amount).toNumber()
    : 0;
};
