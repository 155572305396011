/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventAggregate } from '../model';
// @ts-ignore
import { EventIndex } from '../model';
/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all events
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [eventStatus] 
         * @param {number} [issuerEntityId] 
         * @param {number} [accountId] 
         * @param {number} [userId] 
         * @param {boolean} [sort] 
         * @param {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventList: async (page?: number, size?: number, search?: string, eventStatus?: string, issuerEntityId?: number, accountId?: number, userId?: number, sort?: boolean, includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/events`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (eventStatus !== undefined) {
                localVarQueryParameter['event_status'] = eventStatus;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submission stats for unauthorized role
         * @param {number} id 
         * @param {number} [accountId] 
         * @param {number} [userId] 
         * @param {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventShow: async (id: number, accountId?: number, userId?: number, includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eventShow', 'id', id)
            const localVarPath = changeUrl(`/api/events/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all events
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [eventStatus] 
         * @param {number} [issuerEntityId] 
         * @param {number} [accountId] 
         * @param {number} [userId] 
         * @param {boolean} [sort] 
         * @param {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventList(page?: number, size?: number, search?: string, eventStatus?: string, issuerEntityId?: number, accountId?: number, userId?: number, sort?: boolean, includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventList(page, size, search, eventStatus, issuerEntityId, accountId, userId, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary submission stats for unauthorized role
         * @param {number} id 
         * @param {number} [accountId] 
         * @param {number} [userId] 
         * @param {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventShow(id: number, accountId?: number, userId?: number, includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventShow(id, accountId, userId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all events
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [eventStatus] 
         * @param {number} [issuerEntityId] 
         * @param {number} [accountId] 
         * @param {number} [userId] 
         * @param {boolean} [sort] 
         * @param {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventList(page?: number, size?: number, search?: string, eventStatus?: string, issuerEntityId?: number, accountId?: number, userId?: number, sort?: boolean, includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<EventIndex> {
            return localVarFp.eventList(page, size, search, eventStatus, issuerEntityId, accountId, userId, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary submission stats for unauthorized role
         * @param {number} id 
         * @param {number} [accountId] 
         * @param {number} [userId] 
         * @param {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventShow(id: number, accountId?: number, userId?: number, includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<EventAggregate> {
            return localVarFp.eventShow(id, accountId, userId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventList operation in EventApi.
 * @export
 * @interface EventApiEventListRequest
 */
export interface EventApiEventListRequest {
    /**
     * 
     * @type {number}
     * @memberof EventApiEventList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EventApiEventList
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly eventStatus?: string

    /**
     * 
     * @type {number}
     * @memberof EventApiEventList
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {number}
     * @memberof EventApiEventList
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof EventApiEventList
     */
    readonly userId?: number

    /**
     * 
     * @type {boolean}
     * @memberof EventApiEventList
     */
    readonly sort?: boolean

    /**
     * 
     * @type {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>}
     * @memberof EventApiEventList
     */
    readonly includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventList
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for eventShow operation in EventApi.
 * @export
 * @interface EventApiEventShowRequest
 */
export interface EventApiEventShowRequest {
    /**
     * 
     * @type {number}
     * @memberof EventApiEventShow
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof EventApiEventShow
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof EventApiEventShow
     */
    readonly userId?: number

    /**
     * 
     * @type {Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>}
     * @memberof EventApiEventShow
     */
    readonly includeField?: Array<'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'binding_order_label' | 'non_binding_order_label' | 'allow_non_binding_orders'>

    /**
     * 
     * @type {string}
     * @memberof EventApiEventShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof EventApiEventShow
     */
    readonly xUserRoleId?: string
}

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @summary lists all events
     * @param {EventApiEventListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventList(requestParameters: EventApiEventListRequest = {}, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).eventList(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.eventStatus, requestParameters.issuerEntityId, requestParameters.accountId, requestParameters.userId, requestParameters.sort, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary submission stats for unauthorized role
     * @param {EventApiEventShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventShow(requestParameters: EventApiEventShowRequest, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).eventShow(requestParameters.id, requestParameters.accountId, requestParameters.userId, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EventListErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventListErrorCodes = [
    401,
];

export type EventShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventShowErrorCodes = [
    401,
    442,
];


