import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  ul {
    padding-left: ${({ theme }) => theme.spacing.lg}px;
  }
`;
