import { Flex } from '@npm/core/ui/components/atoms/common';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Link } from '@npm/core/ui/components/molecules/Link';
import {
  type ExtendedColumnType,
  TABLE_ACTIONS_COLUMN_WIDTH,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbOrderBuySell,
  type SecondmarketSubmissionForClientOrders,
} from '@npm/data-access';

import { type OpenRenewOrderModal } from '../../../client-orders/expiringOrders/RenewOrderModal/RenewOrderModal.types';
import { OrderStatusLabel } from '../../../components';
import { PriceBlock } from '../../../components/PriceBlock';

import { getHoldingDisplayValue } from './InvestorOrdersTable/InvestorOrdersTable.utils';

import { InvestorOrdersDropdown } from './InvestorOrdersDropdown';
import type { HoldingOrderActions } from './InvestorOrdersList.types';
import { isOrderNotEditable } from './InvestorOrdersList.utils';

export type OrdersActionsConfig = {
  showFees?: boolean;
  isParentOrderDetail?: boolean;
  onRenewOrderClick?: OpenRenewOrderModal;
} & HoldingOrderActions;

type OrdersConfig = (ExtendedColumnType<SecondmarketSubmissionForClientOrders> &
  TableVerticalRowType<SecondmarketSubmissionForClientOrders>)[];

export const useOrdersConfig = ({
  onHoldingDetailClick,
  onHoldingEditClick,
  onOrderCancelClick,
  onOrderDetailClick,
  onOrderEditClick,
  onRenewOrderClick,
  showFees,
  isParentOrderDetail = false,
  onParentOrderClick,
}: OrdersActionsConfig): OrdersConfig => {
  const { isMobile } = useBreakpoints();
  return [
    {
      key: 'order_id',
      title: 'Order ID',
      render: ({ items, side }) => {
        const item = items?.[0];

        if (!item) {
          return null;
        }

        return (
          <Flex direction={'column'} gap={'xs'}>
            <Flex align="center" gap="sm">
              <Label
                round
                variant={
                  side?.name === CbOrderBuySell?.items?.buy
                    ? 'success'
                    : 'error'
                }
              >
                {side?.name}
              </Label>
              <Text size="sm" colorVariant="primary">
                {item.id}
              </Text>
            </Flex>
            {item.parent_order_item_id && !isParentOrderDetail && (
              <Text size={'xs'} colorVariant={'tertiary'}>
                Parent Order{' '}
                <Link
                  to={`/second-market/investor/opportunity/parent/${item?.parent_order_item_id}`}
                >
                  #{item?.parent_order_item_id}
                </Link>
              </Text>
            )}
            {!item.parent_order_item_id &&
              !isParentOrderDetail &&
              item.original_quantity > item.quantity && (
                <Text size={'xs'} colorVariant={'tertiary'}>
                  Originally{' '}
                  <Text.Quantity
                    as={'span'}
                    size={'xs'}
                    colorVariant={'tertiary'}
                    value={item.original_quantity}
                  />{' '}
                  shares
                </Text>
              )}
          </Flex>
        );
      },
      hidden: isMobile,
      width: 160,
    },
    {
      key: 'certificate_number',
      title: 'Holding',
      render: submission =>
        getHoldingDisplayValue(
          submission,
          onHoldingDetailClick,
          onHoldingEditClick
        ),
      responsive: TABLE_BREAKPOINTS.DESKTOP_XL,
      width: 220,
    },

    {
      key: 'quantity',
      title: 'PPS × Quantity = Estimated Gross Proceeds',
      render: submission => {
        const order = submission?.items?.[0];
        const isOrderEditDisabled = isOrderNotEditable(order?.state?.code);

        return (
          <PriceBlock
            quantity={order?.quantity}
            price={order?.price}
            isBuy={submission?.side?.code === CbOrderBuySell.items.buy}
            onAddClick={() => onOrderEditClick?.(submission)}
            compact
            style={{ width: '95%' }}
            disabled={isOrderEditDisabled}
          />
        );
      },
      hidden: isMobile,
    },
    {
      title: 'Execution Fee',
      key: 'execution_fee',
      render: () => <Text.Quantity value={1} unit="%" />,
      responsive: TABLE_BREAKPOINTS.NONE,
      hidden: !showFees,
    },
    {
      key: 'order_status',
      title: 'Order Status',
      align: 'right',
      render: ({ items }) => (
        <OrderStatusLabel state={items?.[0]?.state} variant="individual" />
      ),
      responsive: TABLE_BREAKPOINTS.DESKTOP,
      hidden: isMobile,
    },
    {
      key: 'action_buttons',
      width: TABLE_ACTIONS_COLUMN_WIDTH,
      render: submission => (
        <InvestorOrdersDropdown
          submission={submission}
          {...{
            onOrderCancelClick,
            onOrderDetailClick,
            onOrderEditClick,
            onRenewOrderClick,
            onParentOrderClick,
            showOnlyDetailButton: isParentOrderDetail,
          }}
        />
      ),

      hidden:
        !(
          isParentOrderDetail ||
          (onOrderDetailClick && onOrderEditClick && onOrderCancelClick)
        ) || isMobile,
    },
  ];
};
