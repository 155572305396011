import { useMemo } from 'react';

import { type CompanyPricingApi } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { sliceDataset } from '../../../../CompanyOverview.utils';
import { getPrimaryRoundsColor } from '../../../../PrimaryRounds/PrimaryRounds.utils';
import { type CompanyOverviewChartDataSetEffect } from '../../../../TapeDPricing/TapeDPricing.types';
import { convertPrimaryPriceToDataSet } from '../BidOfferHistoryChart.utils';

type HookOptions = {
  currentMinDate: Date;
  currentMaxDate: Date;
  series: 'PPS' | 'EST_VALUATION';
  effect?: CompanyOverviewChartDataSetEffect;
};

export const usePrimaryRoundDataSet = (
  sortedValuationData: CompanyPricingApi.PrimaryRoundPrice[],
  options: HookOptions
) => {
  const theme = useTheme();

  const { series, currentMinDate, currentMaxDate, effect } = options;

  return useMemo(() => {
    const fullDataset = convertPrimaryPriceToDataSet(
      sortedValuationData,
      series === 'PPS' ? 'price_per_share' : 'valuation'
    ).filter(Boolean);

    const data = sliceDataset(
      fullDataset,
      currentMinDate,
      currentMaxDate,
      new Date()
    );

    return {
      label: 'Primary Round',
      data,
      borderColor: function (context) {
        return getPrimaryRoundsColor(theme);
      },
      borderWidth: effect === 'highlight' ? 3 : 2,
      stepped: 'before',
      segment: {
        borderWidth:
          effect === 'highlight'
            ? 3
            : ctx => {
                // Highlight the hovered segment
                return ctx?.p0?.x ===
                  ctx?.chart?.hoveredRaw?.nearestSegment?.p1?.x &&
                  ctx?.p1?.x === ctx?.chart?.hoveredRaw?.nearestSegment?.p2?.x
                  ? 3
                  : 1;
              },
      },
    } as const;
  }, [
    theme,
    series,
    sortedValuationData,
    currentMinDate,
    currentMaxDate,
    effect,
  ]);
};
