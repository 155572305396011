import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';

export const GovernmentIdGetStartedContent = () => {
  return (
    <>
      <Text colorVariant="secondary">
        Upload a copy of the representative’s unexpired, government issued photo
        ID.
      </Text>
      <Text colorVariant="secondary">
        The legal name, photo and expiration date (if applicable) should be
        legible in the uploaded copy.
      </Text>
    </>
  );
};

export const GovernmentIdGetStarted = ({
  onGetStarted,
}: ComponentProps<typeof BaseGetStarted>) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title="Government-Issued ID"
      withBullets
      content={<GovernmentIdGetStartedContent />}
    />
  );
};
