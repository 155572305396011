import React from 'react';

import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useTheme } from 'styled-components';

import * as S from '../DescriptionWithBaseInfo/components/BaseInfo.styles';

export const DescriptionWithBaseInfoSkeleton = () => {
  const { isMobile } = useBreakpoints();
  const theme = useTheme();

  return (
    <>
      <Skeleton.Base
        title={null}
        paragraph={{ rows: 2 }}
        style={{ marginBottom: theme.spacing.md + 'px' }}
      />
      <S.Wrapper>
        {Array.from({ length: 4 }).map((_, idx) => (
          <S.Item key={idx}>
            <Skeleton.Avatar
              shape="square"
              size={isMobile ? 24 : 32}
              style={{ marginRight: theme.spacing.sm + 'px' }}
            />
            <Skeleton.Base noMargin paragraph={false} />
          </S.Item>
        ))}
      </S.Wrapper>
    </>
  );
};
