// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StakeholderOwnershipStatsApi,
  StakeholderOwnershipStatsErrorCodes,
  StakeholderOwnershipStatsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StakeholderOwnershipStatsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type StakeholderOwnershipStatsMethod = StakeholderOwnershipStatsApi['stakeholderOwnershipStats'];
export type StakeholderOwnershipStatsResponseType = MethodResult<StakeholderOwnershipStatsMethod>;

export const useStakeholderOwnershipStats = (
  variables: Parameters<StakeholderOwnershipStatsMethod>[0],
  config?: UseQueryConfig<
    StakeholderOwnershipStatsMethod,
    StakeholderOwnershipStatsErrorTypes
  >
) => {
  return useQuery<
    StakeholderOwnershipStatsMethod,
    StakeholderOwnershipStatsErrorTypes
  >(
    (...args) => api.stakeholderOwnershipStats(...args),
    StakeholderOwnershipStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StakeholderOwnershipStats'],
        ...apiQueryConfig.StakeholderOwnershipStats,
        ...config?.queryConfig
      },
    },
    'StakeholderOwnershipStats',
  );
};

export const useStakeholderOwnershipStatsInfinite = (
  variables: Parameters<StakeholderOwnershipStatsMethod>[0],
  config?: UseInfiniteQueryConfig<
    StakeholderOwnershipStatsMethod,
    StakeholderOwnershipStatsErrorTypes
  >
) => {
  return useInfiniteQuery<
    StakeholderOwnershipStatsMethod,
    StakeholderOwnershipStatsErrorTypes
  >(
    (...args) => api.stakeholderOwnershipStats(...args),
    StakeholderOwnershipStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StakeholderOwnershipStats', 'StakeholderOwnershipStatsInfinite'],
        ...apiQueryConfig.StakeholderOwnershipStats,
        ...config?.queryConfig,
      },
    },
    'StakeholderOwnershipStats',
  );
};

export const useStakeholderOwnershipStatsLazy = (baseOptions?: {
  variables?: Parameters<StakeholderOwnershipStatsMethod>[0],
  config?: UseQueryConfig<
    StakeholderOwnershipStatsMethod,
    StakeholderOwnershipStatsErrorTypes
  >
}) => {
  return useLazyQuery<
    StakeholderOwnershipStatsMethod,
    StakeholderOwnershipStatsErrorTypes
  >(
    (...args) => api.stakeholderOwnershipStats(...args),
    StakeholderOwnershipStatsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StakeholderOwnershipStats'],
        ...apiQueryConfig.StakeholderOwnershipStats,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StakeholderOwnershipStats',
  );
};

