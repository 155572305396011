import { useMemo } from 'react';

import { transparentise } from '@npm/core/ui/utils/style';
import { type ChartDataset } from 'chart.js';
import { useTheme } from 'styled-components';

import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import { getMinimumDate } from '../../../CompanyOverview.utils';
import { getPrimaryRoundsColor } from '../../../PrimaryRounds/PrimaryRounds.utils';
import { type CompanyOverviewChartDataSetEffect } from '../../../TapeDPricing/TapeDPricing.types';
import { type TapeDValue } from '../../TapeD.types';
import { getTapeDColor } from '../../TapeD.utils';

import { type TapeDChartPoint } from './TapeDChart.types';
import { convertTapeDToChartData } from './TapeDChart.utils';

export const useTapeDDateLimits = (data: TapeDValue[]) => {
  return useMemo(() => {
    const minDate = getMinimumDate(data);
    const maxDate = new Date();

    return [minDate, maxDate];
  }, [data]);
};

type HookOptions = {
  isSummary?: boolean;
  effect?: CompanyOverviewChartDataSetEffect;
  order?: number;
};

export const useTapeDDataSets = (
  sortedData: TapeDValue[],
  series: CompanyOverviewChartSeries,
  options: HookOptions = { isSummary: false }
): ChartDataset<'line', TapeDChartPoint[]>[] => {
  const { isSummary, effect } = options;

  const theme = useTheme();

  return useMemo(() => {
    const {
      midEstimateData,
      lowEstimateData,
      highEstimateData,
      valuationData,
    } = convertTapeDToChartData(sortedData, series);

    const borderColor = getTapeDColor(theme);

    const midEstimateDataset = {
      label: 'Mid Estimate',
      data: midEstimateData,
      borderColor:
        effect === 'dim' ? transparentise(borderColor, 0.3) : borderColor,
      borderWidth: effect === 'highlight' ? 4 : 2,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: options.order,
    };

    if (isSummary) {
      return [midEstimateDataset];
    }

    return [
      {
        label: 'High Estimate',
        data: highEstimateData,
        borderWidth: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: transparentise(borderColor, 0.125),
        fill: 1,
        tension: 0.4,
      },
      {
        label: 'Low Estimate',
        data: lowEstimateData,
        borderWidth: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: transparentise(borderColor, 0.125),
        tension: 0.4,
      },
      midEstimateDataset,
      {
        label: 'Primary Round',
        data: valuationData,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderColor: getPrimaryRoundsColor(theme),
      },
    ];
  }, [sortedData, series, effect, isSummary]);
};
