import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';
import {
  CbOrderItemState,
  type SecondmarketInvestorOrderItemShow,
} from '@npm/data-access';
import { addDays, isBefore } from 'date-fns';

export const EXPIRING_ORDERS_STATE: string[] = [
  CbOrderItemState.items.live_in_marketplace,
  CbOrderItemState.items.not_posted_to_marketplace,
  CbOrderItemState.items.incomplete,
];

const EXPIRING_ORDERS_DAYS_LIMIT = 5;

export const getExpiringOrderDate = () => {
  return parseDateToString(
    addDays(new Date(), EXPIRING_ORDERS_DAYS_LIMIT),
    DATE_FORMATS.DATE_NUMERIC
  );
};

export const isExpiringOrder = (order: SecondmarketInvestorOrderItemShow) => {
  const expiresAtDate = new Date(order.expires_at);
  const expiringLimitDate = addDays(new Date(), EXPIRING_ORDERS_DAYS_LIMIT);

  const isInAllowedState = EXPIRING_ORDERS_STATE.includes(order.state.code);

  return isInAllowedState && isBefore(expiresAtDate, expiringLimitDate);
};
