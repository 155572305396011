import React from 'react';

import { type ColorGroup } from 'styled-components';

import {
  formatAsCompactNumber,
  formatPrice,
  formatQuantity,
  formatRange,
  getCurrencySymbol,
  type NumberFormatOptions,
} from '../../../utils/formatters';
import { Icon } from '../../atoms/Icon';
import { Tooltip } from '../../atoms/Tooltip';
import { EMPTY_VALUE, Text } from '../../atoms/Typography';

import * as S from './BigNumberValue.styles';

type PrimitiveValueOrRange =
  | number
  | string
  | [number | string, number | string];

export type BigNumberValueProps = {
  title?: string;
  helpText?: string;
  value?: PrimitiveValueOrRange | React.ReactNode;
  valueColor?: ColorGroup;
  valueColorVariant?: 'primary' | 'secondary';
  currency?: string;
  currencyColor?: ColorGroup;
  currencyAsSymbol?: boolean;
  className?: string;
  helpIconColor?: ColorGroup;
  formatOptions?: NumberFormatOptions;
  compactFormat?: boolean;
  variant?: 'lg' | 'xl';
  shrinkLongText?: boolean;
};

const BigPrice = ({
  value,
  currency,
  valueColor = 'general',
  currencyColor = 'general',
  currencyAsSymbol,
  valueColorVariant = 'primary',
  formatOptions,
  compactFormat,
  variant,
  shrinkLongText,
}: {
  value?: PrimitiveValueOrRange;
} & Pick<
  BigNumberValueProps,
  | 'variant'
  | 'valueColor'
  | 'valueColorVariant'
  | 'currency'
  | 'currencyColor'
  | 'currencyAsSymbol'
  | 'formatOptions'
  | 'compactFormat'
  | 'shrinkLongText'
>) => {
  const formatPrimitive = (value: number | string | null | undefined) => {
    return compactFormat
      ? formatAsCompactNumber(value, formatOptions)
      : formatPrice(value, undefined, formatOptions);
  };

  const formattedValue = Array.isArray(value)
    ? formatRange(value, {
        ...formatOptions,
        subFormatter: formatPrimitive,
      })
    : formatPrimitive(value);

  const hasValue = formattedValue !== EMPTY_VALUE;

  const currencySymbol =
    currency && currencyAsSymbol ? getCurrencySymbol(currency) : undefined;

  return (
    <Text>
      <S.BigText
        color={valueColor}
        forwardedAs="span"
        colorVariant={valueColorVariant}
        $variant={variant}
        $valueLength={shrinkLongText ? formattedValue.length : undefined}
      >
        {hasValue && currencySymbol}
        {formattedValue}{' '}
      </S.BigText>
      {hasValue && !!currency && !currencySymbol && (
        <Text as={'span'} color={currencyColor} colorVariant={'secondary'}>
          {currency}
        </Text>
      )}
    </Text>
  );
};

const BigQuantity = ({
  value,
  valueColorVariant = 'primary',
  formatOptions,
  variant,
  compactFormat,
  shrinkLongText,
}: {
  value?: PrimitiveValueOrRange;
} & Pick<
  BigNumberValueProps,
  | 'variant'
  | 'valueColorVariant'
  | 'formatOptions'
  | 'compactFormat'
  | 'shrinkLongText'
>) => {
  const formatPrimitive = (value: number | string | null | undefined) => {
    return compactFormat
      ? formatAsCompactNumber(value, formatOptions)
      : formatQuantity(value, formatOptions);
  };

  const formattedValue = Array.isArray(value)
    ? formatRange(value, {
        ...formatOptions,
        ...(compactFormat && {
          subFormatter: val => formatAsCompactNumber(val, formatOptions),
        }),
      })
    : formatPrimitive(value);

  return (
    <S.BigText
      color="general"
      colorVariant={valueColorVariant}
      $variant={variant}
      $valueLength={shrinkLongText ? formattedValue.length : undefined}
    >
      {formattedValue}
    </S.BigText>
  );
};

const isPrimitive = (value: unknown) =>
  typeof value === 'string' || typeof value === 'number';

const isPrimitiveRange = (value: unknown) =>
  Array.isArray(value) &&
  value.length === 2 &&
  (value[0] == null || isPrimitive(value[0])) &&
  (value[1] == null || isPrimitive(value[1]));

// Described in the Design System as "Header Row"
export const BigNumberValue = ({
  title,
  value,
  valueColor,
  currency,
  currencyColor,
  currencyAsSymbol,
  helpText,
  valueColorVariant,
  helpIconColor = 'info',
  className,
  formatOptions,
  compactFormat,
  variant = 'xl',
  shrinkLongText = true,
}: BigNumberValueProps) => {
  const renderValue = () => {
    if (value == null || isPrimitive(value) || isPrimitiveRange(value)) {
      return currency ? (
        <BigPrice
          valueColor={valueColor}
          currencyColor={currencyColor}
          valueColorVariant={valueColorVariant}
          value={value as PrimitiveValueOrRange}
          currency={currency}
          currencyAsSymbol={currencyAsSymbol}
          formatOptions={formatOptions}
          compactFormat={compactFormat}
          variant={variant}
          shrinkLongText={shrinkLongText}
        />
      ) : (
        <BigQuantity
          value={value as PrimitiveValueOrRange}
          valueColorVariant={valueColorVariant}
          formatOptions={formatOptions}
          compactFormat={compactFormat}
          variant={variant}
          shrinkLongText={shrinkLongText}
        />
      );
    }
    return value;
  };

  return (
    <div className={className}>
      <Text size="sm" marginBottom="xs">
        {title}
        {helpText && (
          <Tooltip title={helpText}>
            <S.IconWrapper $helpIconColor={helpIconColor}>
              &nbsp;
              <Icon name="info-circle" size="xs" />
            </S.IconWrapper>
          </Tooltip>
        )}
      </Text>
      {renderValue()}
    </div>
  );
};
