import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import type { ButtonVariant } from '@npm/core/ui/components/atoms/Button/Button.types';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useNavigate } from '@npm/core/ui/components/molecules/Link';
import { NumberParam, useQueryParams } from 'use-query-params';

import { useSponsorshipLevel } from '../../../auth/user/role';

type Props = { variant?: ButtonVariant; text?: string };

export const SellIndicationButton = ({
  variant,
  text = 'Sell Your Shares',
}: Props) => {
  const navigate = useNavigate();

  const { isSponsorshipMinLevel2 } = useSponsorshipLevel();

  const [queryParams] = useQueryParams({
    companyId: NumberParam,
  });

  return (
    <Button
      variant={(variant ?? isSponsorshipMinLevel2) ? 'default' : 'outline'}
      color="error"
      icon={<Icon name="arrows-opposite" />}
      blockOnMobile
      onClick={() =>
        navigate(
          `/second-market/investor/sell-indication?companyId=${queryParams.companyId}`
        )
      }
    >
      {text}
    </Button>
  );
};
