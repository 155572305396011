// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SUserlessSMHoldingDeletionBatchesApi,
  S2SUserlessBulkCreateSMHoldingDeletionnBatchesErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SUserlessSMHoldingDeletionBatchesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SUserlessBulkCreateSMHoldingDeletionnBatchesMethod = S2SUserlessSMHoldingDeletionBatchesApi['s2SUserlessBulkCreateSMHoldingDeletionnBatches'];

export const useS2SUserlessBulkCreateSMHoldingDeletionnBatches = () => {
  return useMutation<S2SUserlessBulkCreateSMHoldingDeletionnBatchesMethod>(
    (...args) => api.s2SUserlessBulkCreateSMHoldingDeletionnBatches(...args),
    S2SUserlessBulkCreateSMHoldingDeletionnBatchesErrorCodes,
    'S2SUserlessBulkCreateSMHoldingDeletionnBatches',
    '/api/s2s/userless/sm_holdings/delete_batch',
    'post'
  );
};
