import { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type MenuItem,
  type MenuItemGroup,
} from '@npm/core/ui/components/organisms/Layout';
import { CbWorkstationType } from '@npm/data-access';
import { useOktaUserClaims } from '@npm/okta';

import { useUserContextStore } from '../context';
import { useCurrentWorkstation } from '../role';

export const simpleLogoutItem = {
  label: 'Logout',
  key: '/logout',
  icon: <Icon name="logout" size="xs" />,
};

export const useUserMenuItems = () => {
  const user = useUserContextStore(store => store.user);
  const getFullName = useUserContextStore(store => store.getFullName);

  const isIndividualNpmsInvestor = useUserContextStore(
    store => store.isIndividualNpmsInvestor
  );

  const workstation = useCurrentWorkstation();
  const isInvestorWs =
    workstation?.type?.code === CbWorkstationType.items.investor;

  // Fallback when user store is not available (e.g. the terms and conditions page)
  const oktaUserClaims = useOktaUserClaims();

  return useMemo<(MenuItem | MenuItemGroup)[]>(
    () => [
      {
        key: 'user',
        label: (
          <span data-dd-privacy="mask">
            {getFullName() || oktaUserClaims?.name}
            <br />
            {user?.person?.email || oktaUserClaims?.email}
          </span>
        ),
        icon: <Icon name="user-circle" size="xs" />,
        disabled: true,
      },
      ...(isIndividualNpmsInvestor && isInvestorWs
        ? [
            {
              key: 'trading-mode',
              label: <span>Trading Mode</span>,
              icon: <Icon name="trading-mode" size="xs" />,
            },
          ]
        : []),
      {
        label: (
          <Flex align="center" justify="space-between">
            <span>Logout</span>
          </Flex>
        ),
        title: 'Logout',
        key: '/logout',
        icon: <Icon name="logout" size="xs" />,
      },
    ],
    [
      getFullName,
      oktaUserClaims?.name,
      oktaUserClaims?.email,
      user?.person?.email,
      isIndividualNpmsInvestor,
      isInvestorWs,
    ]
  );
};
