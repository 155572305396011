// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OrderBookHistoryApi,
  OrderBookHistoryIndexErrorCodes,
  OrderBookHistoryIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OrderBookHistoryApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type OrderBookHistoryIndexMethod = OrderBookHistoryApi['orderBookHistoryIndex'];
export type OrderBookHistoryIndexResponseType = MethodResult<OrderBookHistoryIndexMethod>;

export const useOrderBookHistoryIndex = (
  variables: Parameters<OrderBookHistoryIndexMethod>[0],
  config?: UseQueryConfig<
    OrderBookHistoryIndexMethod,
    OrderBookHistoryIndexErrorTypes
  >
) => {
  return useQuery<
    OrderBookHistoryIndexMethod,
    OrderBookHistoryIndexErrorTypes
  >(
    (...args) => api.orderBookHistoryIndex(...args),
    OrderBookHistoryIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderBookHistoryIndex'],
        ...apiQueryConfig.OrderBookHistoryIndex,
        ...config?.queryConfig
      },
    },
    'OrderBookHistoryIndex',
  );
};

export const useOrderBookHistoryIndexInfinite = (
  variables: Parameters<OrderBookHistoryIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrderBookHistoryIndexMethod,
    OrderBookHistoryIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrderBookHistoryIndexMethod,
    OrderBookHistoryIndexErrorTypes
  >(
    (...args) => api.orderBookHistoryIndex(...args),
    OrderBookHistoryIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrderBookHistoryIndex', 'OrderBookHistoryIndexInfinite'],
        ...apiQueryConfig.OrderBookHistoryIndex,
        ...config?.queryConfig,
      },
    },
    'OrderBookHistoryIndex',
  );
};

export const useOrderBookHistoryIndexLazy = (baseOptions?: {
  variables?: Parameters<OrderBookHistoryIndexMethod>[0],
  config?: UseQueryConfig<
    OrderBookHistoryIndexMethod,
    OrderBookHistoryIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    OrderBookHistoryIndexMethod,
    OrderBookHistoryIndexErrorTypes
  >(
    (...args) => api.orderBookHistoryIndex(...args),
    OrderBookHistoryIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrderBookHistoryIndex'],
        ...apiQueryConfig.OrderBookHistoryIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrderBookHistoryIndex',
  );
};

