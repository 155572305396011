import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { Form, type FormInstance } from 'antd';

import * as S from './SignatureModal.styles';
import { SignatureFormContent } from './SignatureFormContent';
import {
  SIGNATURE_MODAL_MAX_HEIGHT,
  SIGNATURE_MODAL_WIDTH,
} from './SignatureModal.constants';

const SIGNATURE_MODAL_FORM_ID = 'signature-form';

type Props = {
  variant: 'entity' | 'individual';
  isOpen: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  isLoading: boolean;
  form?: FormInstance;
};

export const SignatureModal = ({
  variant,
  isOpen,
  handleSubmit,
  handleCancel,
  isLoading,
  form,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const renderContent = () => {
    const content = (
      <SignatureFormContent
        shouldRequireSignatureFields={variant === 'entity' && !!form}
      />
    );

    if (form) {
      return (
        <Form form={form} id={SIGNATURE_MODAL_FORM_ID} onFinish={handleSubmit}>
          {content}
        </Form>
      );
    } else {
      return content;
    }
  };

  const submitButtonProps = form
    ? { form: SIGNATURE_MODAL_FORM_ID, htmlType: 'submit' as const }
    : { onClick: handleSubmit };

  return (
    <S.Modal
      destroyOnClose={true}
      data-cy="sign-modal"
      $maxHeight={SIGNATURE_MODAL_MAX_HEIGHT}
      width={SIGNATURE_MODAL_WIDTH}
      open={isOpen}
      title="Electronic Signature"
      footer={
        <Flex
          gap="sm"
          direction={isMobile ? 'column-reverse' : 'row'}
          justify={isMobile ? 'stretch' : 'flex-end'}
          style={{ width: '100%' }}
        >
          <Button
            variant="text"
            icon={<Icon name="chevron-left" />}
            onClick={handleCancel}
            disabled={isLoading}
            blockOnMobile
          >
            Review Information
          </Button>
          <Button
            {...submitButtonProps}
            data-cy={
              CypressDataIds.Onboarding.Accreditation.SignAgreementButton
            }
            disabled={isLoading}
            loading={isLoading}
            blockOnMobile
          >
            Sign off Accreditation Statement
          </Button>
        </Flex>
      }
      onCancel={handleCancel}
    >
      <Divider marginTop={0} />
      {renderContent()}
    </S.Modal>
  );
};
