import styled, { css, type DefaultTheme } from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { Button } from '../../../atoms/Button';
import {
  type CardBaseProps,
  type CardBaseVariant,
} from '../../../atoms/CardBase';
import { Skeleton } from '../../../atoms/Skeleton';
import { Text } from '../../../atoms/Typography';

import { type HeaderProps } from './CardHeader';

const ICON_SIZE = 20;
const ICON_SIZE_MOBILE = 20;

const getContentPadding = (
  theme: DefaultTheme
): Record<CardBaseVariant, string> => ({
  default: `${theme.spacing.lg / 2}px ${theme.spacing.md}px`,
  secondMarket: `${theme.spacing.lg / 2}px ${theme.spacing.md}px`,
  clientOrder: `${theme.spacing.md / 2}px ${theme.spacing.md}px`,
  compact: `14px ${theme.spacing.sm}px`,
  drawer: `${theme.spacing.lg / 2}px 0`,
});

export const HeaderWrapper = styled.div<{
  $isCardExpanded?: boolean;
  $underlined?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.lg}px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.lg}px;
  overflow: hidden;
  ${({ theme, $isCardExpanded }) =>
    !$isCardExpanded &&
    `
      border-bottom-left-radius: ${theme.borderRadius.lg}px;
      border-bottom-right-radius: ${theme.borderRadius.lg}px;
  `}

  ${({ theme, $underlined }) =>
    $underlined &&
    css`
      border-bottom: 1px solid ${theme.color.general.borders.primary};
    `}
`;

export const SubHeader = styled(Text)<{ $variant: CardBaseProps['variant'] }>`
  padding: ${({ theme }) => theme.spacing.xs}px
    ${({ theme, $variant }) =>
      $variant ? theme.spacing.sm : theme.spacing.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.two};

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.xs}px ${theme.spacing.sm}px;
  `}
`;

export const Content = styled.div<{
  $backgroundLayoutColor: HeaderProps['backgroundLayoutColor'];
  $variant: CardBaseProps['variant'];
}>`
  ${({ theme, $backgroundLayoutColor = 'one', $variant = 'default' }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${$variant ? getContentPadding(theme)[$variant] : 0};
    background-color: ${theme.color.general.layout[$backgroundLayoutColor]};
    ${() =>
      useBreakpoint(theme).mobile`${css`
        padding: ${$variant === 'compact'
          ? `14px ${theme.spacing.sm}px`
          : `${theme.spacing.sm}px`};
      `}`};
  `}
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column;
    align-items: flex-start;

    // only if there is icon
    h3:not(:first-child) {
      margin-top: ${theme.spacing.sm}px;
    }
  `}
`;

export const RightContent = styled.div<{
  $alignSelf?: React.CSSProperties['alignSelf'];
}>`
  display: flex;
  align-self: ${({ $alignSelf }) => $alignSelf || 'flex-start'};

  > button {
    margin-left: ${({ theme }) => theme.spacing.sm}px;
  }
`;

export const IconWrapper = styled.div<{ $noMarginRight: boolean }>`
  display: flex;
  align-items: center;
  margin-right: ${({ theme, $noMarginRight }) =>
    $noMarginRight ? 0 : theme.spacing.sm}px;

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Icon = styled.div`
  margin-right: ${({ theme }) => theme.spacing.sm}px;

  > * {
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;

    ${({ theme }) => useBreakpoint(theme).mobile`
      width: ${ICON_SIZE_MOBILE}px;
      height: ${ICON_SIZE_MOBILE}px;
    `}
  }
`;

export const HeaderContentLoading = styled(Skeleton.Base).attrs({
  paragraph: { rows: 1 },
  title: false,
})`
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    width: 40% !important;
    height: ${({ theme }) => theme.typography.height.md}px;
    ${({ theme }) => useBreakpoint(theme).mobile`
      width: 75% !important;
      height: ${theme.typography.height.sm}px;
    `}
  }
`;

export const ExpandButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.color.general.typography.primary};

    svg {
      width: initial;
      height: initial;
    }
  }
`;
