// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  VenusDataSyncApi,
  VenusDataSyncShowErrorCodes,
  VenusDataSyncShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new VenusDataSyncApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type VenusDataSyncShowMethod = VenusDataSyncApi['venusDataSyncShow'];
export type VenusDataSyncShowResponseType = MethodResult<VenusDataSyncShowMethod>;

export const useVenusDataSyncShow = (
  variables: Parameters<VenusDataSyncShowMethod>[0],
  config?: UseQueryConfig<
    VenusDataSyncShowMethod,
    VenusDataSyncShowErrorTypes
  >
) => {
  return useQuery<
    VenusDataSyncShowMethod,
    VenusDataSyncShowErrorTypes
  >(
    (...args) => api.venusDataSyncShow(...args),
    VenusDataSyncShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusDataSyncShow'],
        ...apiQueryConfig.VenusDataSyncShow,
        ...config?.queryConfig
      },
    },
    'VenusDataSyncShow',
  );
};

export const useVenusDataSyncShowInfinite = (
  variables: Parameters<VenusDataSyncShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    VenusDataSyncShowMethod,
    VenusDataSyncShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    VenusDataSyncShowMethod,
    VenusDataSyncShowErrorTypes
  >(
    (...args) => api.venusDataSyncShow(...args),
    VenusDataSyncShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusDataSyncShow', 'VenusDataSyncShowInfinite'],
        ...apiQueryConfig.VenusDataSyncShow,
        ...config?.queryConfig,
      },
    },
    'VenusDataSyncShow',
  );
};

export const useVenusDataSyncShowLazy = (baseOptions?: {
  variables?: Parameters<VenusDataSyncShowMethod>[0],
  config?: UseQueryConfig<
    VenusDataSyncShowMethod,
    VenusDataSyncShowErrorTypes
  >
}) => {
  return useLazyQuery<
    VenusDataSyncShowMethod,
    VenusDataSyncShowErrorTypes
  >(
    (...args) => api.venusDataSyncShow(...args),
    VenusDataSyncShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['VenusDataSyncShow'],
        ...apiQueryConfig.VenusDataSyncShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'VenusDataSyncShow',
  );
};

