import React from 'react';

import { type ProgressProps } from 'antd';
import { useTheme } from 'styled-components';

import * as S from './ProgressRing.styles';

export const ProgressRing = (props: ProgressProps) => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <S.ProgressRing
        {...props}
        size="small"
        type="circle"
        strokeColor={theme.color.info.typography.primary}
        width={24}
        strokeWidth={6}
      />
    </S.Wrapper>
  );
};
