import React from 'react';

import {
  type UserRoleAggregate,
  type WatchlistCreateRequestContract,
} from '@npm/data-access';
import { Form, type FormInstance } from 'antd';

import { CompanyCard } from '../../../../../company';
import { AdvancedSettingsSection } from '../AdvancedSettingsSection';

type Props = {
  formRef: React.Ref<FormInstance<WatchlistCreateRequestContract>>;
  initialValues: WatchlistCreateRequestContract;
  filterAccounts?: (role: UserRoleAggregate) => boolean;
} & React.ComponentProps<typeof CompanyCard>;

export const CompanyCardWithForm = ({
  formRef,
  initialValues,
  filterAccounts,
  ...restProps
}: Props) => {
  const [form] = Form.useForm<WatchlistCreateRequestContract>();

  return (
    <Form form={form} ref={formRef} initialValues={initialValues}>
      <CompanyCard
        {...restProps}
        footer={<AdvancedSettingsSection filterAccounts={filterAccounts} />}
      />
    </Form>
  );
};
