import React, { useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { type CompanyPricingApi } from '@npm/data-access';

import { MutualFundMarksChart } from './components/MutualFundMarksChart';
import { MutualFundMarksFilter } from './components/MutualFundMarksFilter/MutualFundMarksFilter';
import type { MutualFundMarksFilterFormValues } from './components/MutualFundMarksFilter/MutualFundMarksFilter.types';
import { MutualFundMarksTable } from './components/MutualFundMarksTable';

import { useMutualFundFilterValues } from './MutualFundMarks.hooks';

type Props = {
  mtmData: CompanyPricingApi.MarkToMarketIndex;
  movingAverageData: CompanyPricingApi.MovingAveragePrices;
};

export const MutualFundMarks = withErrorBoundary(
  ({ mtmData, movingAverageData }: Props) => {
    const filterValues = useMutualFundFilterValues(mtmData?.mark_to_markets);

    const [filters, setFilters] = useState<MutualFundMarksFilterFormValues>({});

    const filteredData = useMemo(() => {
      if (!mtmData?.mark_to_markets) {
        return [];
      }

      return mtmData.mark_to_markets.filter(item => {
        if (
          filters.parentName &&
          item.mutual_fund_parent_name !== filters.parentName
        ) {
          return false;
        }

        return !(
          filters.assetType &&
          !item.mutual_fund_asset_name
            ?.toLowerCase()
            .includes(filters.assetType.toLowerCase())
        );
      });
    }, [mtmData, filters]);

    return (
      <CardBase noContentPadding={true}>
        <Flex direction={'column'} gap={'md'}>
          <MutualFundMarksChart
            mtmData={filteredData}
            movingAverageData={movingAverageData.prices}
            filter={
              <MutualFundMarksFilter
                filterValues={filterValues}
                activeFilters={Object.values(filters).filter(Boolean).length}
                onSubmit={values => {
                  setFilters(values ?? {});
                }}
              />
            }
          />

          <MutualFundMarksTable data={filteredData} />
        </Flex>
      </CardBase>
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);
