import React from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type VenusApi } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { CorporateProfileSkeleton } from '../skeletons';

import { Executives } from './components/Executives';

type Props = {
  companyProfile: VenusApi.CompanyProfile | undefined;
  isLoading?: boolean;
};

export const CorporateProfile = ({ companyProfile, isLoading }: Props) => {
  const theme = useTheme();

  if (isLoading) return <CorporateProfileSkeleton />;

  return (
    <>
      <Margin bottom={theme.spacing.lg / 2}>
        <Text size="sm" as="h4" marginBottom="xs">
          Executives
        </Text>
        <Executives companyProfile={companyProfile} collapseAfter={30} />
      </Margin>
      <Text size="sm" as="h4" marginBottom="xs">
        Legal Counsel
      </Text>
      {companyProfile?.legal_counsel ? (
        <Text size="sm" colorVariant="primary">
          {companyProfile.legal_counsel}
        </Text>
      ) : (
        <Text size="sm">No Data</Text>
      )}
    </>
  );
};
