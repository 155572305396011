import { CbSubscriptionPlan } from '@npm/data-access';
import { type IconNames } from '@npm/utils';

export type SubscriptionPlan = {
  key: keyof (typeof CbSubscriptionPlan)['items'];
  title: string;
  description: string;
  icon: IconNames;
  color: 'warning' | 'info';
  attributes: string[];
};

export const subscriptionPlans: SubscriptionPlan[] = [
  {
    key: CbSubscriptionPlan.items.basic,
    title: 'Basic',
    description: 'Free access as a sponsored user of the NPM platform',
    icon: 'circle-check',
    color: 'info',
    attributes: [
      'Company Profile',
      'Financing History',
      'Implied Valuations',
      'Premium/Discount to last round',
    ],
  },
  {
    key: CbSubscriptionPlan.items.premium,
    title: 'Premium',
    description:
      'Comprehensive market data, deal analytics and diligence for professional investors',
    icon: 'diamond',
    color: 'warning',
    attributes: [
      'Everything in basic',
      'Tape D™ Price',
      'Historical Bids/Asks',
      'Primary Round detail',
      '409A Valuations',
      'Mutual Fund Marks',
      'Issued Shares',
      'Waterfall Analysis',
      'Deal Terms',
      'Portfolio Analytics',
      'Source Documents',
    ],
  },
];
