// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SUserlessSMHoldingUpdateBatchesApi,
  S2SUserlessBulkCreateSMHoldingUpdateBatchesErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SUserlessSMHoldingUpdateBatchesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SUserlessBulkCreateSMHoldingUpdateBatchesMethod = S2SUserlessSMHoldingUpdateBatchesApi['s2SUserlessBulkCreateSMHoldingUpdateBatches'];

export const useS2SUserlessBulkCreateSMHoldingUpdateBatches = () => {
  return useMutation<S2SUserlessBulkCreateSMHoldingUpdateBatchesMethod>(
    (...args) => api.s2SUserlessBulkCreateSMHoldingUpdateBatches(...args),
    S2SUserlessBulkCreateSMHoldingUpdateBatchesErrorCodes,
    'S2SUserlessBulkCreateSMHoldingUpdateBatches',
    '/api/s2s/userless/sm_holdings/update_batch',
    'post'
  );
};
