// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StockClassesApi,
  InvestorStockClassesIndexErrorCodes,
  InvestorStockClassesIndexErrorTypes,
  StockClassesIndexErrorCodes,
  StockClassesIndexErrorTypes,
  StockClassesCreateErrorCodes,
  StockClassShowErrorCodes,
  StockClassShowErrorTypes,
  StockClassFilingsErrorCodes,
  StockClassFilingsErrorTypes,
  StockClassTransactionsErrorCodes,
  StockClassTransactionsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StockClassesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InvestorStockClassesIndexMethod = StockClassesApi['investorStockClassesIndex'];
export type InvestorStockClassesIndexResponseType = MethodResult<InvestorStockClassesIndexMethod>;

export const useInvestorStockClassesIndex = (
  variables: Parameters<InvestorStockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorStockClassesIndexMethod,
    InvestorStockClassesIndexErrorTypes
  >
) => {
  return useQuery<
    InvestorStockClassesIndexMethod,
    InvestorStockClassesIndexErrorTypes
  >(
    (...args) => api.investorStockClassesIndex(...args),
    InvestorStockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorStockClassesIndex'],
        ...apiQueryConfig.InvestorStockClassesIndex,
        ...config?.queryConfig
      },
    },
    'InvestorStockClassesIndex',
  );
};

export const useInvestorStockClassesIndexInfinite = (
  variables: Parameters<InvestorStockClassesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorStockClassesIndexMethod,
    InvestorStockClassesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorStockClassesIndexMethod,
    InvestorStockClassesIndexErrorTypes
  >(
    (...args) => api.investorStockClassesIndex(...args),
    InvestorStockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorStockClassesIndex', 'InvestorStockClassesIndexInfinite'],
        ...apiQueryConfig.InvestorStockClassesIndex,
        ...config?.queryConfig,
      },
    },
    'InvestorStockClassesIndex',
  );
};

export const useInvestorStockClassesIndexLazy = (baseOptions?: {
  variables?: Parameters<InvestorStockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorStockClassesIndexMethod,
    InvestorStockClassesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorStockClassesIndexMethod,
    InvestorStockClassesIndexErrorTypes
  >(
    (...args) => api.investorStockClassesIndex(...args),
    InvestorStockClassesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorStockClassesIndex'],
        ...apiQueryConfig.InvestorStockClassesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorStockClassesIndex',
  );
};


export type StockClassesIndexMethod = StockClassesApi['stockClassesIndex'];
export type StockClassesIndexResponseType = MethodResult<StockClassesIndexMethod>;

export const useStockClassesIndex = (
  variables: Parameters<StockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    StockClassesIndexMethod,
    StockClassesIndexErrorTypes
  >
) => {
  return useQuery<
    StockClassesIndexMethod,
    StockClassesIndexErrorTypes
  >(
    (...args) => api.stockClassesIndex(...args),
    StockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassesIndex'],
        ...apiQueryConfig.StockClassesIndex,
        ...config?.queryConfig
      },
    },
    'StockClassesIndex',
  );
};

export const useStockClassesIndexInfinite = (
  variables: Parameters<StockClassesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    StockClassesIndexMethod,
    StockClassesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    StockClassesIndexMethod,
    StockClassesIndexErrorTypes
  >(
    (...args) => api.stockClassesIndex(...args),
    StockClassesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassesIndex', 'StockClassesIndexInfinite'],
        ...apiQueryConfig.StockClassesIndex,
        ...config?.queryConfig,
      },
    },
    'StockClassesIndex',
  );
};

export const useStockClassesIndexLazy = (baseOptions?: {
  variables?: Parameters<StockClassesIndexMethod>[0],
  config?: UseQueryConfig<
    StockClassesIndexMethod,
    StockClassesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    StockClassesIndexMethod,
    StockClassesIndexErrorTypes
  >(
    (...args) => api.stockClassesIndex(...args),
    StockClassesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StockClassesIndex'],
        ...apiQueryConfig.StockClassesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StockClassesIndex',
  );
};


export type StockClassesCreateMethod = StockClassesApi['stockClassesCreate'];

export const useStockClassesCreate = () => {
  return useMutation<StockClassesCreateMethod>(
    (...args) => api.stockClassesCreate(...args),
    StockClassesCreateErrorCodes,
    'StockClassesCreate',
    '/api/admin/issuers/{issuer_id}/stock_classes',
    'post'
  );
};

export type StockClassShowMethod = StockClassesApi['stockClassShow'];
export type StockClassShowResponseType = MethodResult<StockClassShowMethod>;

export const useStockClassShow = (
  variables: Parameters<StockClassShowMethod>[0],
  config?: UseQueryConfig<
    StockClassShowMethod,
    StockClassShowErrorTypes
  >
) => {
  return useQuery<
    StockClassShowMethod,
    StockClassShowErrorTypes
  >(
    (...args) => api.stockClassShow(...args),
    StockClassShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassShow'],
        ...apiQueryConfig.StockClassShow,
        ...config?.queryConfig
      },
    },
    'StockClassShow',
  );
};

export const useStockClassShowInfinite = (
  variables: Parameters<StockClassShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    StockClassShowMethod,
    StockClassShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    StockClassShowMethod,
    StockClassShowErrorTypes
  >(
    (...args) => api.stockClassShow(...args),
    StockClassShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassShow', 'StockClassShowInfinite'],
        ...apiQueryConfig.StockClassShow,
        ...config?.queryConfig,
      },
    },
    'StockClassShow',
  );
};

export const useStockClassShowLazy = (baseOptions?: {
  variables?: Parameters<StockClassShowMethod>[0],
  config?: UseQueryConfig<
    StockClassShowMethod,
    StockClassShowErrorTypes
  >
}) => {
  return useLazyQuery<
    StockClassShowMethod,
    StockClassShowErrorTypes
  >(
    (...args) => api.stockClassShow(...args),
    StockClassShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StockClassShow'],
        ...apiQueryConfig.StockClassShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StockClassShow',
  );
};


export type StockClassFilingsMethod = StockClassesApi['stockClassFilings'];
export type StockClassFilingsResponseType = MethodResult<StockClassFilingsMethod>;

export const useStockClassFilings = (
  variables: Parameters<StockClassFilingsMethod>[0],
  config?: UseQueryConfig<
    StockClassFilingsMethod,
    StockClassFilingsErrorTypes
  >
) => {
  return useQuery<
    StockClassFilingsMethod,
    StockClassFilingsErrorTypes
  >(
    (...args) => api.stockClassFilings(...args),
    StockClassFilingsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassFilings'],
        ...apiQueryConfig.StockClassFilings,
        ...config?.queryConfig
      },
    },
    'StockClassFilings',
  );
};

export const useStockClassFilingsInfinite = (
  variables: Parameters<StockClassFilingsMethod>[0],
  config?: UseInfiniteQueryConfig<
    StockClassFilingsMethod,
    StockClassFilingsErrorTypes
  >
) => {
  return useInfiniteQuery<
    StockClassFilingsMethod,
    StockClassFilingsErrorTypes
  >(
    (...args) => api.stockClassFilings(...args),
    StockClassFilingsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassFilings', 'StockClassFilingsInfinite'],
        ...apiQueryConfig.StockClassFilings,
        ...config?.queryConfig,
      },
    },
    'StockClassFilings',
  );
};

export const useStockClassFilingsLazy = (baseOptions?: {
  variables?: Parameters<StockClassFilingsMethod>[0],
  config?: UseQueryConfig<
    StockClassFilingsMethod,
    StockClassFilingsErrorTypes
  >
}) => {
  return useLazyQuery<
    StockClassFilingsMethod,
    StockClassFilingsErrorTypes
  >(
    (...args) => api.stockClassFilings(...args),
    StockClassFilingsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StockClassFilings'],
        ...apiQueryConfig.StockClassFilings,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StockClassFilings',
  );
};


export type StockClassTransactionsMethod = StockClassesApi['stockClassTransactions'];
export type StockClassTransactionsResponseType = MethodResult<StockClassTransactionsMethod>;

export const useStockClassTransactions = (
  variables: Parameters<StockClassTransactionsMethod>[0],
  config?: UseQueryConfig<
    StockClassTransactionsMethod,
    StockClassTransactionsErrorTypes
  >
) => {
  return useQuery<
    StockClassTransactionsMethod,
    StockClassTransactionsErrorTypes
  >(
    (...args) => api.stockClassTransactions(...args),
    StockClassTransactionsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassTransactions'],
        ...apiQueryConfig.StockClassTransactions,
        ...config?.queryConfig
      },
    },
    'StockClassTransactions',
  );
};

export const useStockClassTransactionsInfinite = (
  variables: Parameters<StockClassTransactionsMethod>[0],
  config?: UseInfiniteQueryConfig<
    StockClassTransactionsMethod,
    StockClassTransactionsErrorTypes
  >
) => {
  return useInfiniteQuery<
    StockClassTransactionsMethod,
    StockClassTransactionsErrorTypes
  >(
    (...args) => api.stockClassTransactions(...args),
    StockClassTransactionsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StockClassTransactions', 'StockClassTransactionsInfinite'],
        ...apiQueryConfig.StockClassTransactions,
        ...config?.queryConfig,
      },
    },
    'StockClassTransactions',
  );
};

export const useStockClassTransactionsLazy = (baseOptions?: {
  variables?: Parameters<StockClassTransactionsMethod>[0],
  config?: UseQueryConfig<
    StockClassTransactionsMethod,
    StockClassTransactionsErrorTypes
  >
}) => {
  return useLazyQuery<
    StockClassTransactionsMethod,
    StockClassTransactionsErrorTypes
  >(
    (...args) => api.stockClassTransactions(...args),
    StockClassTransactionsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StockClassTransactions'],
        ...apiQueryConfig.StockClassTransactions,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StockClassTransactions',
  );
};

