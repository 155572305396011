import type { OnboardingStatus } from './dashboard.types';

type Item = {
  stepNumber: number;
  title: string;
  description: string;
  isCompleted?: boolean;
  isCurrentStep?: boolean;
};

type StepperItems = Pick<
  OnboardingStatus,
  | 'isOnboardingStarted'
  | 'isBuyerEligibilityComplete'
  | 'isAmlKycComplete'
  | 'isMarketplaceAgreementComplete'
  | 'isAccreditationComplete'
  | 'areAgreementsComplete'
>;

const MARKETPLACE_AGREEMENT_TITLE = 'Marketplace Agrement';
const MARKETPLACE_AGREEMENT_DESCRIPTION =
  'Covers the Terms and Conditions for access to NPM’s marketplace and services to you';

export const getIndividualStepperItems = ({
  isOnboardingStarted,
  isAmlKycComplete,
  isMarketplaceAgreementComplete,
}: StepperItems): Item[] => {
  return [
    {
      stepNumber: 1,
      title: 'Identity Verification',
      description: 'Confirm your identity to keep you and NPM safe',
      isCurrentStep: isOnboardingStarted && !isAmlKycComplete,
      isCompleted: isAmlKycComplete,
    },
    {
      stepNumber: 2,
      title: MARKETPLACE_AGREEMENT_TITLE,
      description: MARKETPLACE_AGREEMENT_DESCRIPTION,
      isCurrentStep: isAmlKycComplete && !isMarketplaceAgreementComplete,
      isCompleted: isMarketplaceAgreementComplete,
    },
  ];
};

export const getEntityStepperItems = ({
  isOnboardingStarted,
  isAmlKycComplete,
  isMarketplaceAgreementComplete,
  isBuyerEligibilityComplete,
}): Item[] => {
  return [
    {
      stepNumber: 1,
      title: 'Buyer Eligibility',
      description:
        'Help us understand your financial experience and objectives. You need to be an accredited investor to purchase shares',
      isCurrentStep: !isBuyerEligibilityComplete,
      isCompleted: isBuyerEligibilityComplete,
    },
    {
      stepNumber: 2,
      title: 'AML / KYC',
      description:
        'Verify your identity, complete beneficial ownership assessment, and add authorized signers',
      isCurrentStep:
        isOnboardingStarted && isBuyerEligibilityComplete && !isAmlKycComplete,
      isCompleted: isAmlKycComplete,
    },
    {
      stepNumber: 3,
      title: MARKETPLACE_AGREEMENT_TITLE,
      description: MARKETPLACE_AGREEMENT_DESCRIPTION,
      isCurrentStep:
        isBuyerEligibilityComplete &&
        isAmlKycComplete &&
        !isMarketplaceAgreementComplete,
      isCompleted: isMarketplaceAgreementComplete,
    },
  ];
};

export const getInvestorStepperItems = ({
  isOnboardingStarted,
  isAmlKycComplete,
  isMarketplaceAgreementComplete,
  isBuyerEligibilityComplete,
}: StepperItems): Item[] => {
  return [
    {
      stepNumber: 1,
      title: 'Buyer Eligibility',
      description:
        'Help us understand your financial experience and objectives. You need to be an accredited investor to purchase shares',
      isCurrentStep: !isBuyerEligibilityComplete,
      isCompleted: isBuyerEligibilityComplete,
    },
    {
      stepNumber: 2,
      title: 'Identity Verification',
      description: 'Confirm your identity to keep you and NPM safe',
      isCurrentStep:
        isOnboardingStarted && isBuyerEligibilityComplete && !isAmlKycComplete,
      isCompleted: isAmlKycComplete,
    },
    {
      stepNumber: 3,
      title: MARKETPLACE_AGREEMENT_TITLE,
      description: MARKETPLACE_AGREEMENT_DESCRIPTION,
      isCurrentStep:
        isBuyerEligibilityComplete &&
        isAmlKycComplete &&
        !isMarketplaceAgreementComplete,
      isCompleted: isMarketplaceAgreementComplete,
    },
  ];
};
