// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CommentApi,
  CommentIndexErrorCodes,
  CommentIndexErrorTypes,
  CommentCreateErrorCodes,
  CommentShowErrorCodes,
  CommentShowErrorTypes,
  CommentUpdateErrorCodes,
  CommentDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CommentApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CommentIndexMethod = CommentApi['commentIndex'];
export type CommentIndexResponseType = MethodResult<CommentIndexMethod>;

export const useCommentIndex = (
  variables: Parameters<CommentIndexMethod>[0],
  config?: UseQueryConfig<
    CommentIndexMethod,
    CommentIndexErrorTypes
  >
) => {
  return useQuery<
    CommentIndexMethod,
    CommentIndexErrorTypes
  >(
    (...args) => api.commentIndex(...args),
    CommentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CommentIndex'],
        ...apiQueryConfig.CommentIndex,
        ...config?.queryConfig
      },
    },
    'CommentIndex',
  );
};

export const useCommentIndexInfinite = (
  variables: Parameters<CommentIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    CommentIndexMethod,
    CommentIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    CommentIndexMethod,
    CommentIndexErrorTypes
  >(
    (...args) => api.commentIndex(...args),
    CommentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CommentIndex', 'CommentIndexInfinite'],
        ...apiQueryConfig.CommentIndex,
        ...config?.queryConfig,
      },
    },
    'CommentIndex',
  );
};

export const useCommentIndexLazy = (baseOptions?: {
  variables?: Parameters<CommentIndexMethod>[0],
  config?: UseQueryConfig<
    CommentIndexMethod,
    CommentIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    CommentIndexMethod,
    CommentIndexErrorTypes
  >(
    (...args) => api.commentIndex(...args),
    CommentIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CommentIndex'],
        ...apiQueryConfig.CommentIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CommentIndex',
  );
};


export type CommentCreateMethod = CommentApi['commentCreate'];

export const useCommentCreate = () => {
  return useMutation<CommentCreateMethod>(
    (...args) => api.commentCreate(...args),
    CommentCreateErrorCodes,
    'CommentCreate',
    '/api/comments',
    'post'
  );
};

export type CommentShowMethod = CommentApi['commentShow'];
export type CommentShowResponseType = MethodResult<CommentShowMethod>;

export const useCommentShow = (
  variables: Parameters<CommentShowMethod>[0],
  config?: UseQueryConfig<
    CommentShowMethod,
    CommentShowErrorTypes
  >
) => {
  return useQuery<
    CommentShowMethod,
    CommentShowErrorTypes
  >(
    (...args) => api.commentShow(...args),
    CommentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CommentShow'],
        ...apiQueryConfig.CommentShow,
        ...config?.queryConfig
      },
    },
    'CommentShow',
  );
};

export const useCommentShowInfinite = (
  variables: Parameters<CommentShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    CommentShowMethod,
    CommentShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    CommentShowMethod,
    CommentShowErrorTypes
  >(
    (...args) => api.commentShow(...args),
    CommentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CommentShow', 'CommentShowInfinite'],
        ...apiQueryConfig.CommentShow,
        ...config?.queryConfig,
      },
    },
    'CommentShow',
  );
};

export const useCommentShowLazy = (baseOptions?: {
  variables?: Parameters<CommentShowMethod>[0],
  config?: UseQueryConfig<
    CommentShowMethod,
    CommentShowErrorTypes
  >
}) => {
  return useLazyQuery<
    CommentShowMethod,
    CommentShowErrorTypes
  >(
    (...args) => api.commentShow(...args),
    CommentShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CommentShow'],
        ...apiQueryConfig.CommentShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CommentShow',
  );
};


export type CommentUpdateMethod = CommentApi['commentUpdate'];

export const useCommentUpdate = () => {
  return useMutation<CommentUpdateMethod>(
    (...args) => api.commentUpdate(...args),
    CommentUpdateErrorCodes,
    'CommentUpdate',
    '/api/comments/{id}',
    'put'
  );
};

export type CommentDeleteMethod = CommentApi['commentDelete'];

export const useCommentDelete = () => {
  return useMutation<CommentDeleteMethod>(
    (...args) => api.commentDelete(...args),
    CommentDeleteErrorCodes,
    'CommentDelete',
    '/api/comments/{id}',
    'delete'
  );
};
