import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Link as BaseLink } from '@npm/core/ui/components/molecules/Link';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { css } from 'styled-components';

const tabItemCommonStyles = css`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding-left: ${({ theme }) => theme.spacing.md}px;
  padding-right: ${({ theme }) => theme.spacing.md}px;

  &.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${({ theme }) => theme.spacing.md}px;
    right: ${({ theme }) => theme.spacing.md}px;
    height: 2px;
    background-color: ${({ theme }) => theme.color.info.borders.primary};
  }

  svg {
    color: inherit;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
      padding-left: ${theme.spacing.lg / 2}px;
      padding-right: ${theme.spacing.lg / 2}px;

      &.active::after {
        left: ${theme.spacing.lg / 2}px;
        right: ${theme.spacing.lg / 2}px;
      }
    `}
`;

export const Link = styled(BaseLink)`
  ${tabItemCommonStyles}

  &:hover,
  body.tab-focus &:focus {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryHover};
  }
`;

export const DisabledLink = styled.div`
  ${tabItemCommonStyles}

  svg {
    color: inherit;
  }
`;

export const TextFlex = styled(Text)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}px;
`;
