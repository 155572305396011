import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Container = styled.div`
  & > *:first-child {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
    ${({ theme }) => useBreakpoint(theme).mobile`
        margin-bottom: ${theme.spacing.sm}px;
    `}
  }
`;
