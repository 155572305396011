import create, { type GetState, type SetState } from 'zustand';

import { createRoleStore, type RoleStore } from './role/role.store';
import { createSupportStore, type SupportStore } from './support/support.store';
import { createUserStore, type UserStore } from './user/user.store';

export type UserContextStore = RoleStore & SupportStore & UserStore;

export type StoreCreateArgs = [
  SetState<UserContextStore>,
  GetState<UserContextStore>,
  unknown,
];

export const useUserContextStore = create<UserContextStore>(
  (...a: StoreCreateArgs) => ({
    ...createRoleStore(...a),
    ...createSupportStore(...a),
    ...createUserStore(...a),
  })
);
