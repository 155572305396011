import { InputFormItem } from '@npm/core/ui/components/atoms/FormItem';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import { Radio as AntRadio } from 'antd';
import styled from 'styled-components';

const WRAP_WIDTH = 800;

export const Wrap = styled.div`
  width: ${WRAP_WIDTH}px;
  max-width: 100%;

  ${({ theme }) => useBreakpoint(theme).mobile`
    width: 100%;
  `}
`;

export const RadioGroup = styled(AntRadio.Group)`
  display: flex;
  flex-direction: column;
`;

export const FormItem = styled(InputFormItem)`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;

  & > * {
    flex: 0 1 50%;
    padding-right: ${({ theme }) => theme.spacing.md}px;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    flex-direction: column;
    align-items: stretch;

    & > * {
      flex: 1 1 100%;
      padding-right: 0;
    }
  `}
`;
