import type { FolderAggregate } from '@npm/data-access';

type Return = [FolderAggregate | undefined, FolderAggregate[]];

const EMPTY_RESULT = [undefined, []] as Return;

// Finds a folder by id in a folder structure and returns the folder and its history as a path
// History is an array of folders starting from the root folder to the selected folder (included)
export const findFolderWithHistoryById = (
  folder: FolderAggregate,
  id: number | null | undefined
): [FolderAggregate, FolderAggregate[]] => {
  if (!folder) {
    return EMPTY_RESULT;
  }

  if (!id) {
    return [folder, [folder]];
  }

  if (folder.id === id) {
    return [folder, [folder]];
  }

  for (const f of folder.folders) {
    const [selectedFolder, history] = findFolderWithHistoryById(f, id);

    if (selectedFolder) {
      return [selectedFolder, [folder, ...history]];
    }
  }

  return EMPTY_RESULT;
};

export const getTaxDocumentTypeName = <T extends FolderAggregate>(
  folder: T
): string => {
  return 'tax_document_type' in folder &&
    typeof folder.tax_document_type === 'object' &&
    folder.tax_document_type &&
    'name' in folder.tax_document_type
    ? (folder.tax_document_type.name as string)
    : '';
};
