import {
  Flex,
  InfoIconWrap,
  Margin,
} from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { PmInsightsLogo } from '@npm/core/ui/components/molecules/PmInsightsLogo';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { darkTheme, lightTheme } from '@npm/core/ui/themes';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { ApeVueApi, type IssuerEntityAggregate } from '@npm/data-access';
import { ThemeProvider, useTheme } from 'styled-components';

import { useUserContextStore } from '../../../../../../auth/user/context';
import { LockedTextGuard } from '../../../../components';
import {
  DataLine,
  type MarketIntelligenceItem,
  MarketIntelligenceItemType,
} from '../DataLine';

import * as S from './MarketIntelligenceV2.styles';

const DUMMY_VALUE = 50.56;

type Props = {
  issuerEntity: IssuerEntityAggregate;
  isLoading?: boolean;
  pricePerShare?: number;
};

/* Market Intelligence v2 component for the Sell Indication Drawer
 * v2 is used to differentiate from the Market Intelligence component used in the Company Market page */
export const MarketIntelligenceV2 = ({
  issuerEntity,
  isLoading,
  pricePerShare,
}: Props) => {
  const theme = useTheme();

  const { isDarkMode } = useDarkModeContext();

  const isSponsoredInvestor = useUserContextStore(
    store => !!store.sponsorshipType
  );

  const { data, isFetching } = ApeVueApi.useCompaniesPPS(
    {
      companyNpmGuid: issuerEntity?.npm_guid,
    },
    {
      onError: () => {
        // do nothing
      },
    }
  );

  const showPmInsights = !!data?.pps;

  const items: MarketIntelligenceItem[] = [
    {
      title: 'Highest Bid',
      type: MarketIntelligenceItemType.CIRCLE,
      value: issuerEntity?.bid_price_high,
      isLoading: isLoading,
      color: theme.color.success.typography.primary,
      blurred: !isSponsoredInvestor,
      tooltip:
        "The highest live bid (buy) indication currently available in NPM's order book",
    },
    {
      title: 'Lowest Offer',
      type: MarketIntelligenceItemType.CIRCLE,
      value: issuerEntity?.ask_price_low,
      isLoading: isLoading,
      color: theme.color.error.typography.primary,
      blurred: !isSponsoredInvestor,
      tooltip:
        "The lowest live offer (sell) indication currently available in NPM's order book",
    },
    showPmInsights && {
      title: 'PM Insights Price (delayed)',
      type: MarketIntelligenceItemType.CIRCLE,
      tooltip:
        'Estimated market price based on aggregated data from various private market trading venues. Data is provided by PM Insights and updated as of the 15th of the previous month.',
      value: data?.pps ? Number(data.pps) : undefined,
      isLoading: isFetching,
      color: theme.color.info.typography.primary,
      getDataLineTooltip: item => (
        <>
          <ThemeProvider theme={isDarkMode ? lightTheme : darkTheme}>
            <S.PmInsightsPrice>
              <Text.Price size={'sm'} value={item.value} />
            </S.PmInsightsPrice>
          </ThemeProvider>
          <Text size={'sm'} colorVariant={'tertiary'}>
            PM Insights Price (delayed)
          </Text>
          {data?.date && (
            <Text size={'sm'} colorVariant={'tertiary'}>
              As of{' '}
              {formatDate(data?.date, {
                dateFormat: DATE_FORMATS.DATE,
              })}
            </Text>
          )}
        </>
      ),
    },
    {
      title: 'Your Offer',
      type: MarketIntelligenceItemType.TRIANGLE,
      value: pricePerShare ?? 0,
      color: theme.color.general.typography.primary,
    },
  ].filter(Boolean);

  const itemsWithValue = items.filter(item => !!item.value);

  const showDataLine = itemsWithValue.length > 1;

  return (
    <Flex direction="column" gap="md">
      <Flex gap="sm">
        <Text weight="bold">{issuerEntity?.name} Market Intelligence</Text>
        <Tooltip title="Highest Bid and Lowest Offer are referenced from NPM's order book. PM Insights Price is estimated by PM Insights, and is aggregated across various market venues.">
          <Flex align="center">
            <Icon
              name="info-circle"
              size="xs"
              color={theme.color.info.typography.primary}
            />
          </Flex>
        </Tooltip>
      </Flex>
      <S.Content>
        {items.map(({ title, type, tooltip, value, color, blurred }) => (
          <Flex key={title} justify="space-between" align="center">
            <Flex align="center">
              {type === MarketIntelligenceItemType.CIRCLE ? (
                <Icon name={'ring'} size={'xs'} color={color} strokeWidth={3} />
              ) : (
                <Icon name={'triangle-down'} size={'xs'} color={color} />
              )}
              <Margin right="sm" />
              <Text size="sm" colorVariant="secondary">
                {title}
              </Text>
              {tooltip && (
                <Tooltip title={tooltip}>
                  <Margin left="xs">
                    <InfoIconWrap>
                      <Icon name="info-circle" />
                    </InfoIconWrap>
                  </Margin>
                </Tooltip>
              )}
            </Flex>
            {blurred ? (
              <LockedTextGuard>
                <Text.Price size="default" value={DUMMY_VALUE} />
              </LockedTextGuard>
            ) : (
              <Text.Price size="default" value={value} />
            )}
          </Flex>
        ))}
        {(showDataLine || showPmInsights) && <Divider />}
        {showDataLine && <DataLine data={itemsWithValue} />}
        {showPmInsights && (
          <Flex gap={'sm'} align={'center'}>
            <Text size="xs" colorVariant="tertiary">
              PM Insights Price is provided by
            </Text>
            <PmInsightsLogo />
          </Flex>
        )}
      </S.Content>
    </Flex>
  );
};
