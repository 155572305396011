// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  BeneficialOwnersContactInformationApi,
  BeneficialOwnerContactInformationCreateErrorCodes,
  BeneficialOwnerContactInformationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new BeneficialOwnersContactInformationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type BeneficialOwnerContactInformationCreateMethod = BeneficialOwnersContactInformationApi['beneficialOwnerContactInformationCreate'];

export const useBeneficialOwnerContactInformationCreate = () => {
  return useMutation<BeneficialOwnerContactInformationCreateMethod>(
    (...args) => api.beneficialOwnerContactInformationCreate(...args),
    BeneficialOwnerContactInformationCreateErrorCodes,
    'BeneficialOwnerContactInformationCreate',
    '/api/beneficial-owners/{beneficial_owner_id}/contact-informations',
    'post'
  );
};

export type BeneficialOwnerContactInformationUpdateMethod = BeneficialOwnersContactInformationApi['beneficialOwnerContactInformationUpdate'];

export const useBeneficialOwnerContactInformationUpdate = () => {
  return useMutation<BeneficialOwnerContactInformationUpdateMethod>(
    (...args) => api.beneficialOwnerContactInformationUpdate(...args),
    BeneficialOwnerContactInformationUpdateErrorCodes,
    'BeneficialOwnerContactInformationUpdate',
    '/api/beneficial-owners/{beneficial_owner_id}/contact-informations/{id}',
    'put'
  );
};
