import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { Form as BaseForm } from '../../atoms/Form';
import {
  CheckboxButtonCard,
  RadioButtonCard,
} from '../ButtonCard/ButtonCard.styles';
import { type ThemeSpacing } from '../common';
import { ExtraSmallTextStyles, SmallTextStyles } from '../Typography';

export const FormItem = styled(BaseForm.Item)<{
  $marginBottom?: ThemeSpacing;
}>`
  margin: 0;
  margin-bottom: ${({ theme, $marginBottom }) =>
    $marginBottom ? `${theme.spacing[$marginBottom]}px` : 0};
  &.ant-form-item-has-error
    ${RadioButtonCard},
    &.ant-form-item-has-error
    ${CheckboxButtonCard},
    &.ant-form-item-has-error
    .ql-container {
    border: 1px solid ${({ theme }) => theme.color.error.typography.primary} !important;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    ${SmallTextStyles}
    margin-top: ${({ theme }) => theme.spacing.xs}px;
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }
  .ant-form-item-explain-error {
    ${ExtraSmallTextStyles};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    padding: ${({ theme }) =>
      `${theme.spacing.xs}px ${theme.spacing.lg / 2}px`};
    background-color: ${({ theme }) => theme.color.error.typography.primary};
    color: ${({ theme }) => theme.color.grey_0};
  }
  .ant-form-item-explain-warning {
    color: ${({ theme }) => theme.color.warning.typography.primary};
  }
  ${({ theme }) => useBreakpoint(theme).tablet`
    & > .ant-form-item-label > label {
      margin-bottom: ${theme.spacing.xs / 2}px;
    }
  `}
  & .ant-form-item-label {
    ${SmallTextStyles};
    color: ${({ theme }) => theme.color.general.typography.primary};
    margin-right: ${({ theme }) => theme.spacing.md}px;
    padding-bottom: 0;
    & > label {
      ${SmallTextStyles};
      color: ${({ theme }) => theme.color.general.typography.primary};
      height: auto;
      margin-bottom: ${({ theme }) => theme.spacing.xs / 2}px;
    }
    > label::after {
      display: none;
    }
  }
  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  & .ant-form-item-control {
    width: 100%;
  }
`;
