// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AgreementSigningApi,
  AgreementSigningErrorCodes,
  AgreementSigningCompleteErrorCodes,
  AgreementSigningCompleteErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AgreementSigningApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AgreementSigningMethod = AgreementSigningApi['agreementSigning'];

export const useAgreementSigning = () => {
  return useMutation<AgreementSigningMethod>(
    (...args) => api.agreementSigning(...args),
    AgreementSigningErrorCodes,
    'AgreementSigning',
    '/api/agreement-signing',
    'post'
  );
};

export type AgreementSigningCompleteMethod = AgreementSigningApi['agreementSigningComplete'];
export type AgreementSigningCompleteResponseType = MethodResult<AgreementSigningCompleteMethod>;

export const useAgreementSigningComplete = (
  variables: Parameters<AgreementSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    AgreementSigningCompleteMethod,
    AgreementSigningCompleteErrorTypes
  >
) => {
  return useQuery<
    AgreementSigningCompleteMethod,
    AgreementSigningCompleteErrorTypes
  >(
    (...args) => api.agreementSigningComplete(...args),
    AgreementSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AgreementSigningComplete'],
        ...apiQueryConfig.AgreementSigningComplete,
        ...config?.queryConfig
      },
    },
    'AgreementSigningComplete',
  );
};

export const useAgreementSigningCompleteInfinite = (
  variables: Parameters<AgreementSigningCompleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    AgreementSigningCompleteMethod,
    AgreementSigningCompleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    AgreementSigningCompleteMethod,
    AgreementSigningCompleteErrorTypes
  >(
    (...args) => api.agreementSigningComplete(...args),
    AgreementSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AgreementSigningComplete', 'AgreementSigningCompleteInfinite'],
        ...apiQueryConfig.AgreementSigningComplete,
        ...config?.queryConfig,
      },
    },
    'AgreementSigningComplete',
  );
};

export const useAgreementSigningCompleteLazy = (baseOptions?: {
  variables?: Parameters<AgreementSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    AgreementSigningCompleteMethod,
    AgreementSigningCompleteErrorTypes
  >
}) => {
  return useLazyQuery<
    AgreementSigningCompleteMethod,
    AgreementSigningCompleteErrorTypes
  >(
    (...args) => api.agreementSigningComplete(...args),
    AgreementSigningCompleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AgreementSigningComplete'],
        ...apiQueryConfig.AgreementSigningComplete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AgreementSigningComplete',
  );
};

