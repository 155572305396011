import { type ComponentProps } from 'react';

import { type AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';
import { type FormInstance } from 'antd';

import { PersonInfoForm } from '../../../users/CreateNewUser/PersonInfoForm';
import { ContactInformationForm } from '../index';

import { type IndividualInfoFormType } from './IndividualInformation.types';

export const autoscrollQuestions: (
  isFormLoading: boolean,
  form: FormInstance<IndividualInfoFormType>,
  initialCountryCode?: string,
  isFormFilled?: boolean
) => Pick<
  ComponentProps<typeof AutoScrollingForm>,
  'questions'
>['questions'] = (
  isFormLoading = false,
  form,
  initialCountryCode,
  isFormFilled
) => [
  {
    title: 'Tell us about yourself',
    name: 'personal-information',
    children: ({ onQuestionAnswered, isDisabled }) => (
      <PersonInfoForm
        form={form}
        isDisabled={isDisabled}
        onNext={onQuestionAnswered}
        isLoading={isFormLoading}
      />
    ),
  },
  {
    title: 'Personal Information',
    name: 'contact-information',
    children: ({ isDisabled }) => (
      <ContactInformationForm
        form={form}
        isDisabled={isDisabled}
        isLoading={isFormLoading}
        initialCountryCode={initialCountryCode}
        isFilled={isFormFilled}
      />
    ),
  },
];
