// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  VenusProxyApi,
  VenusProxyShowErrorCodes,
  VenusProxyShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new VenusProxyApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type VenusProxyShowMethod = VenusProxyApi['venusProxyShow'];
export type VenusProxyShowResponseType = MethodResult<VenusProxyShowMethod>;

export const useVenusProxyShow = (
  variables: Parameters<VenusProxyShowMethod>[0],
  config?: UseQueryConfig<
    VenusProxyShowMethod,
    VenusProxyShowErrorTypes
  >
) => {
  return useQuery<
    VenusProxyShowMethod,
    VenusProxyShowErrorTypes
  >(
    (...args) => api.venusProxyShow(...args),
    VenusProxyShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusProxyShow'],
        ...apiQueryConfig.VenusProxyShow,
        ...config?.queryConfig
      },
    },
    'VenusProxyShow',
  );
};

export const useVenusProxyShowInfinite = (
  variables: Parameters<VenusProxyShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    VenusProxyShowMethod,
    VenusProxyShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    VenusProxyShowMethod,
    VenusProxyShowErrorTypes
  >(
    (...args) => api.venusProxyShow(...args),
    VenusProxyShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['VenusProxyShow', 'VenusProxyShowInfinite'],
        ...apiQueryConfig.VenusProxyShow,
        ...config?.queryConfig,
      },
    },
    'VenusProxyShow',
  );
};

export const useVenusProxyShowLazy = (baseOptions?: {
  variables?: Parameters<VenusProxyShowMethod>[0],
  config?: UseQueryConfig<
    VenusProxyShowMethod,
    VenusProxyShowErrorTypes
  >
}) => {
  return useLazyQuery<
    VenusProxyShowMethod,
    VenusProxyShowErrorTypes
  >(
    (...args) => api.venusProxyShow(...args),
    VenusProxyShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['VenusProxyShow'],
        ...apiQueryConfig.VenusProxyShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'VenusProxyShow',
  );
};

