import React, { type ComponentProps } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type Holding } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { AggregatedAssetType } from '../../aggregated/List/components';
import { type HoldingDetailDrawer } from '../HoldingDetailDrawer';
import { HoldingUniqueIdWithDetailDrawer } from '../HoldingUniqueIdWithDetailDrawer';

import * as S from './HoldingLabel.styles';

export type HoldingForHoldingLabel = Pick<
  Holding,
  'id' | 'certificate_number' | 'asset' | 'aggregated'
>;

type Props = {
  holding: HoldingForHoldingLabel;
  isUniqueIdClickable?: boolean;
  holdingUniqueIdMaxLength?: number;
  showCertificate?: boolean;
  holdingDetailDrawerProps?: Pick<
    ComponentProps<typeof HoldingDetailDrawer>,
    'showEditHoldingButton' | 'editHoldingDrawerProps'
  >;
};

export const HoldingLabel = ({
  holding,
  isUniqueIdClickable = true,
  holdingUniqueIdMaxLength,
  showCertificate = true,
  holdingDetailDrawerProps,
}: Props) => {
  const theme = useTheme();

  if (!holding) {
    return null;
  }

  if (holding?.aggregated) {
    return <AggregatedAssetType asset={holding.asset} showLabelWithTooltip />;
  }

  return (
    <S.Wrapper align="center" gap="xs">
      <S.Icon size="xs" name="file-text" color="general" colorVariant="muted" />
      {holding.asset?.type?.code && (
        <Flex align="center" gap="xs">
          <Text size="sm" colorVariant="secondary">
            {holding.asset?.type?.name}
          </Text>
          {showCertificate ? (
            <Text.Dot
              colorVariant="tertiary"
              style={{ marginTop: theme.spacing.xs / 2 + 'px', lineHeight: 0 }}
            />
          ) : null}
        </Flex>
      )}
      {showCertificate && (
        <HoldingUniqueIdWithDetailDrawer
          holding={holding}
          isUniqueIdClickable={isUniqueIdClickable}
          holdingUniqueIdMaxLength={holdingUniqueIdMaxLength}
          withIcon={false}
          holdingDetailDrawerProps={holdingDetailDrawerProps}
        />
      )}
    </S.Wrapper>
  );
};
