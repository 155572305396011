import React, { type ComponentProps, type ReactNode } from 'react';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { BigNumberValue } from '@npm/core/ui/components/molecules/BigNumberValue';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';
import {
  TableVertical,
  type TableVerticalRowType,
} from '@npm/core/ui/components/molecules/TableVertical';
import {
  CbOrderBuySell,
  CbOrderEntryType,
  type OrderItemUnitCode,
  type SecondmarketOrderItemShow,
} from '@npm/data-access';
import { useTheme } from 'styled-components';

import { useUserContextStore } from '../../../../../auth/user/context';
import { parseOrderSize } from '../../../../../order';
import { getApplicableSizeType } from '../../../../../order/OrderSizeDisplay';
import {
  SMCardContent,
  SMCardRow,
} from '../../../../SecondMarketComponents.styles';

type Props = {
  marketOrder?: SecondmarketOrderItemShow;
  isMine?: boolean;
  isLoading?: boolean;
  rows: TableVerticalRowType<SecondmarketOrderItemShow>[];
  footer?: ReactNode;
  requestNegotiationAccessCheckboxValue?: boolean;
  setRequestNegotiationAccessCheckboxValue?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  header?: {
    companyLogoProps?: {
      linkProps: Omit<
        ComponentProps<typeof CompanyLogo>['linkProps'],
        'companyId'
      >;
    };
  };
};

export const MarketOrderCard = ({
  marketOrder,
  isMine,
  isLoading,
  rows,
  footer,
  header,
  requestNegotiationAccessCheckboxValue,
  setRequestNegotiationAccessCheckboxValue,
}: Props) => {
  const theme = useTheme();

  const sizeType = getApplicableSizeType(
    useUserContextStore(store => store.sizeType),
    marketOrder?.price,
    marketOrder?.unit?.code as OrderItemUnitCode
  );

  return (
    <Card
      variant="secondMarket"
      isLoading={isLoading}
      header={{
        variant: 'secondMarket',
        title: (
          <CompanyLogo
            url={marketOrder?.issuer_entity?.logo_url}
            name={marketOrder?.issuer_entity?.name}
            size="xl"
            linkProps={{
              ...header?.companyLogoProps?.linkProps,
              companyId: marketOrder?.issuer_entity?.id,
            }}
          />
        ),
        label: (
          <Flex direction={'row'} gap={'sm'}>
            {CbOrderEntryType.getLabel(CbLabel, marketOrder?.order_type)}
            {CbOrderBuySell.getLabel(CbLabel, marketOrder?.order_side, isMine)}
          </Flex>
        ),
      }}
      footer={footer}
    >
      <SMCardContent>
        <SMCardRow>
          <BigNumberValue
            variant="lg"
            compactFormat
            title={`Size (${sizeType})`}
            value={parseOrderSize({ sizeType, orderItem: marketOrder })}
            currency={sizeType}
          />

          <Flex direction="row" style={{ alignItems: 'flex-end' }}>
            <BigNumberValue
              variant="lg"
              currency={marketOrder?.currency?.name}
              title={'Price Per Share'}
              helpText="Price Per Share, Discount/Premium to Last Round (LR), and Implied Valuation"
              value={marketOrder?.price}
              formatOptions={{ long: true }}
            />

            {!!marketOrder?.pct_premium_to_preferred_price && (
              <PercentageChange
                style={{ marginBottom: theme.spacing.xs / 2 }}
                change={marketOrder?.pct_premium_to_preferred_price}
              />
            )}
          </Flex>
        </SMCardRow>
        <TableVertical<SecondmarketOrderItemShow>
          rows={rows}
          data={marketOrder}
        />
      </SMCardContent>

      {setRequestNegotiationAccessCheckboxValue && (
        <Checkbox
          value={requestNegotiationAccessCheckboxValue}
          onChange={e =>
            setRequestNegotiationAccessCheckboxValue(e.target.value)
          }
          style={{ marginTop: theme.spacing.md }}
          label="I would like to request Negotiation Access, so that I can directly respond to opportunities on the SecondMarket platform (Optional)"
        />
      )}
    </Card>
  );
};
