import React, { type ComponentProps, Fragment } from 'react';

import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { useFolderIndexInfinite } from '@npm/data-access';

import { type DataRoomsList } from '../DataRoomsList';

import { DataRoomCard } from './Card';

type Props = ComponentProps<typeof DataRoomsList>;

export const DataRoomsCards = ({ onAccessClick, variables }: Props) => {
  const { data, isFetching, fetchNextPage, hasNextPage } =
    useFolderIndexInfinite(variables);

  const totalRecords = data?.pages?.[0]?.pagination?.total_records;

  return (
    <>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetching}
        loader={<DataRoomCard isLoading />}
      >
        {data?.pages.map(page => (
          <Fragment key={page.pagination.page}>
            {page.folders.map(folder => (
              <DataRoomCard
                key={folder.id}
                dataRoom={folder}
                onAccessClick={onAccessClick}
              />
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
      {!isFetching && !totalRecords && (
        <NoDataArea outlined title="No Data Rooms found." />
      )}
    </>
  );
};
