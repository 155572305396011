import React from 'react';

import { motion } from 'framer-motion';

import { useDarkModeContext } from '../../../context/DarkModeContext';

const LOGO_SHORT_DARK = '/public/assets/logo-short-dark.svg';
const LOGO_SHORT_LIGHT = '/public/assets/logo-short-light.svg';

type Props = {
  width?: number | null | undefined;
  height?: number | null | undefined;
  style?: React.CSSProperties;
};

export const LogoShort = ({ width = 71, height = 26, style }: Props) => {
  const { isDarkMode } = useDarkModeContext();
  return (
    <motion.img
      src={isDarkMode ? LOGO_SHORT_DARK : LOGO_SHORT_LIGHT}
      alt="NPM logo"
      height={height ?? undefined}
      width={width ?? undefined}
      style={style}
      layout
      transition={{ duration: 0.2 }}
      layoutDependency={width}
    />
  );
};
