import { Flex } from '@npm/core/ui/components/atoms/common';
import { useNavigate } from '@npm/core/ui/components/molecules/Link';
import {
  CbOrderBuySell,
  CbOrderEntryType,
  CbSubmissionStructure,
  type SecondmarketOrderItem,
} from '@npm/data-access';

import { useSponsorshipLevel } from '../../../../../../auth/user/role';
import { type OpenRenewOrderModal } from '../../../../../client-orders/expiringOrders/RenewOrderModal/RenewOrderModal.types';
import { useRequestMoreInformationContext } from '../../../../../components';
import { useCanRenewOrder } from '../../../../../hooks/useCanRenewOrder';

import * as S from './OrderCard.styles';
import { ExpiresOnDateTime } from './ExpiresOnDateTime';
import { getButtonVariant, getLabels } from './OrderCard.utils';
import { OrderCardSkeleton } from './OrderCardSkeleton';
import { PriceRow } from './PriceRow';

type Props = {
  order: SecondmarketOrderItem | undefined;
  accountId?: number;
  isLoading?: boolean;
  onRenewClick?: OpenRenewOrderModal;
};

export const OrderCard = ({
  order,
  accountId,
  isLoading,
  onRenewClick,
}: Props) => {
  const { isSponsorshipMinLevel2 } = useSponsorshipLevel();

  const isBuy = order?.order_side?.code === CbOrderBuySell.items.buy;
  const isMySellOrder = order?.account?.id === accountId && !isBuy;

  const navigate = useNavigate();

  const isIOI =
    order?.order_type?.code === CbOrderEntryType.items.soft_ioi ||
    order?.order?.structure?.code === CbSubmissionStructure.items.spv;

  const goesToDetail =
    isMySellOrder || (isSponsorshipMinLevel2 && (!isIOI || !isBuy));
  const hasExpirationDate = !!order?.expires_at;

  const { requestMoreInformation } = useRequestMoreInformationContext();

  const { canRenewOrder } = useCanRenewOrder(order?.account?.id);

  const handleOrderClick = () => {
    if (onRenewClick) {
      onRenewClick(order, !isMySellOrder && !isSponsorshipMinLevel2);
    } else if (goesToDetail) {
      navigate(`/second-market/investor/opportunity/${order?.id}`);
    } else {
      requestMoreInformation(order);
    }
  };

  if (isLoading) return <OrderCardSkeleton />;

  return (
    <S.OrderCardContainer onClick={handleOrderClick}>
      {getLabels(order, accountId)}
      <PriceRow orderItem={order} />
      <Flex justify={hasExpirationDate ? 'space-between' : 'flex-end'}>
        <ExpiresOnDateTime date={order?.expires_at} />
        {getButtonVariant({
          order,
          accountId,
          isSponsorshipMinLevel2,
          isIOI,
          isBuy,
          onOrderClick: handleOrderClick,
          onRenewClick,
          canRenewOrder,
        })}
      </Flex>
    </S.OrderCardContainer>
  );
};
