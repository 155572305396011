import { Drawer as AntDrawer } from 'antd';
import styled from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { BUTTON_HEIGHT_MD } from '../../../atoms/Button/Button.styles';
import { type useLayoutConfig } from '../Layout.hooks';

export const Drawer = styled(AntDrawer)<{
  $layoutConfig: ReturnType<typeof useLayoutConfig>;
}>`
  /* mobile menu drawer should be on top of any other drawer */
  z-index: ${({ theme }) => theme.zIndex.aboveDrawer};

  &.ant-drawer-left .ant-drawer-content-wrapper {
    box-shadow: ${({ theme }) => theme.shadow.level1};
    border-right: 1px solid
      ${({ theme }) => theme.color.general.borders.primary};
  }

  .ant-drawer-mask {
    background: ${({ theme }) => theme.color.general.overlay.soft};
  }

  .ant-drawer-body {
    padding: ${({ theme }) => theme.spacing.sm}px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-content {
    background: ${({ theme }) => theme.color.general.layout.zero};
  }

  .ant-menu-submenu-title {
    padding-right: ${({ theme }) => theme.spacing.md}px !important;
  }

  & .ant-drawer-footer {
    padding: 0;
    border-top: 0;
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${BUTTON_HEIGHT_MD}px;
  gap: ${({ theme }) => theme.spacing.sm}px;
  padding-left: ${({ theme }) => theme.spacing.sm}px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  button svg {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding-left: 0px;
  `}
`;

export const BurgerDropdownWrapper = styled.div<{
  $layoutConfig: ReturnType<typeof useLayoutConfig>;
}>`
  display: flex;
  height: ${({ theme, $layoutConfig }) =>
    $layoutConfig.header.height + theme.spacing.lg}px;

  ${({ theme, $layoutConfig }) => useBreakpoint(theme).mobile`
    height: ${$layoutConfig.header.height}px;
  `}
`;

export const OverlayWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  background: ${({ theme }) => theme.color.general.layout.one};
  transform: translateY(-4px);
  box-shadow: ${({ theme }) => theme.shadow.level1};
`;
