import React, { memo, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { getAllRoutesRecursively } from '@npm/core/ui/routes';
import { registerRoutes } from '@npm/core/ui/routes/router.constants';
import { CbWorkstationType } from '@npm/data-access';
import { useCurrentWorkstation, useObo } from '@npm/features/auth/user/role';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';
import {
  OnboardingRouter,
  routes as onboardingRoutes,
} from '@npm/onboarding/router';
import {
  PreplatformRouter,
  routes as preplatformRoutes,
} from '@npm/preplatform/router';
import {
  SecondMarketRouter,
  routes as secondMarketRoutes,
} from '@npm/second-market/router';
import {
  AdvisorRouter,
  routes as advisorRoutes,
} from '@npm/workstations/advisor/router';
import {
  BrokerageRouter,
  routes as brokerageRoutes,
} from '@npm/workstations/brokerage/router';
import {
  InvestorRouter,
  routes as investorRoutes,
} from '@npm/workstations/investor/router';
import {
  IssuerRouter,
  routes as issuerRoutes,
} from '@npm/workstations/issuer/router';

import IndexPage from '../pages';

import { routes as globalRoutes } from './routes.global';

[
  globalRoutes,
  onboardingRoutes,
  preplatformRoutes,
  secondMarketRoutes,
  advisorRoutes,
  brokerageRoutes,
  investorRoutes,
  issuerRoutes,
].forEach(registerRoutes);

export const Router = memo(function Router() {
  const workstation = useCurrentWorkstation();
  const { isObo } = useObo();

  const flatGlobalRoutes = useMemo(() => {
    return getAllRoutesRecursively(globalRoutes);
  }, []);

  return (
    <Routes>
      <Route
        path="/advisor-workstation/*"
        element={
          <AdvisorRouter
            enabled={
              workstation?.type?.code === CbWorkstationType.items.advisor
            }
          />
        }
      />
      <Route
        path="/brokerage-workstation/*"
        element={
          <BrokerageRouter
            enabled={
              workstation?.type?.code === CbWorkstationType.items.brokerage ||
              isObo
            }
          />
        }
      />
      <Route
        path="/investor-workstation/*"
        element={
          <InvestorRouter
            enabled={
              workstation?.type?.code === CbWorkstationType.items.investor ||
              isObo
            }
          />
        }
      />
      <Route
        path="/issuer-workstation/*"
        element={
          <IssuerRouter
            enabled={workstation?.type?.code === CbWorkstationType.items.issuer}
          />
        }
      />
      <Route path="/onboarding/*" element={<OnboardingRouter />} />
      <Route path="/post-onboarding/*" element={<OnboardingRouter />} />
      <Route path="/second-market/*" element={<SecondMarketRouter />} />
      <Route path="/preplatform/*" element={<PreplatformRouter />} />

      {flatGlobalRoutes.map(route => {
        return route.needLogin !== false ? (
          <Route
            element={
              <ProtectedRoute permissionsChecks={route.permissionsChecks} />
            }
            key={route.path}
          >
            <Route path={route.path} Component={route.component} />
          </Route>
        ) : (
          <Route
            key={route.path}
            path={route.path}
            Component={route.component}
          />
        );
      })}

      <Route
        path="*"
        element={
          <ErrorPath
            heading="Error"
            title="Sorry, the page you visited does not exist."
            status="404"
          />
        }
      />
    </Routes>
  );
});
