import { useMemo } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyTagList } from '@npm/core/ui/components/molecules/CompanyTagList';
import { getFullName } from '@npm/core/ui/utils/formatters';
import type { AccountIndex, HoldingIndex } from '@npm/data-access';
import { AccountPanel } from '@npm/features/account/components/AccountPanel';
import { useUserContextStore } from '@npm/features/auth/user/context';
import { Form } from 'antd';

import { EntitySelect } from '../EntitySelect';
import { type OptInFormValues } from '../OptInAccountSelection.types';

import * as S from './CopyAccount.styles';

export const CopyAccount = ({
  isEntity,
  accounts,
  isLoadingEntities,
  accountsData,
}: {
  isEntity: boolean;
  accounts: AccountIndex;
  isLoadingEntities: boolean;
  accountsData: HoldingIndex;
}) => {
  const user = useUserContextStore(store => store.user);
  const form = Form.useFormInstance<OptInFormValues>();

  const holdings = useMemo(() => {
    if (!accountsData?.holdings) return [];

    const seenIssuers = new Set<number>();
    return accountsData.holdings.filter(holding => {
      const existing = seenIssuers.has(holding.issuer_entity?.id);
      if (!existing) {
        seenIssuers.add(holding.issuer_entity?.id);
      }
      return !existing;
    });
  }, [accountsData]);

  return (
    <>
      {isEntity ? (
        <EntitySelect
          eligibleEntities={accounts}
          form={form}
          isLoadingEntities={isLoadingEntities}
        />
      ) : (
        <AccountPanel
          showTitle={false}
          accountName={getFullName(
            user?.person?.first,
            user?.person?.middle,
            user?.person?.last
          )}
          brokerageFirmName={user?.active_role?.subject?.brokerage_firm_name}
          accountExternalId={user?.active_role?.subject?.external_id}
        />
      )}

      {!!holdings.length && (
        <S.Container>
          <Text color="general" size="sm">
            We are going to import the following holdings in:
          </Text>
          <CompanyTagList
            companies={holdings.map(({ issuer_entity }) => issuer_entity)}
            showMax={3}
          />
        </S.Container>
      )}
    </>
  );
};
