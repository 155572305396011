import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { ProgressBar } from '@npm/core/ui/components/atoms/ProgressBar';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CbAccountSponsorshipType } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { type PostOnboardingContextType } from '../PostOnboarding.types';
import {
  DoneLabel,
  MainSectionLabel,
} from '../Sidebar/v2/SidebarSection/MainSectionLabel';

import * as S from './MobileSubHeader.styles';

export const MobileSubHeader = () => {
  const theme = useTheme();

  const { account, activeSectionProps, sectionsProgress } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      account: s.data.account,
      activeSectionProps: s.data.activeSectionProps,
      sectionsProgress: s.data.sectionsProgress,
    })
  );

  const isMainSection =
    activeSectionProps?.activeSection?.v2SectionProps?.isMainSection;
  const activeSectionKey = activeSectionProps?.activeSection?.key;
  const activeSectionDuration =
    activeSectionProps?.activeSection?.v2SectionProps?.durationInMin;
  const isActiveSectionCompleted = sectionsProgress?.[activeSectionKey] === 100;

  // TODO: NFE-7276 should include level 2 as well?
  const hasFullAccessToMarketplace =
    CbAccountSponsorshipType.isSponsoredAccountMinLvl2(
      account?.sponsorship_type
    );

  return (
    <S.Wrapper direction="column">
      {activeSectionProps?.activeSection?.key === 'required_for_marketplace' &&
        !isActiveSectionCompleted && (
          <S.SectionDescriptionWrapper gap="sm">
            <Icon name="info-circle" color="info" />
            <Text size="sm">{activeSectionProps?.activeSection?.subTitle}</Text>
          </S.SectionDescriptionWrapper>
        )}
      <ProgressBar
        title={
          <Text size="sm" color="info" weight="bold">
            {activeSectionProps?.activeSection?.title}
          </Text>
        }
        currentStep={activeSectionProps?.stepIndex}
        totalSteps={activeSectionProps?.totalSteps}
        stepBackground={theme.color.general.layout.two}
        extra={
          isMainSection ? (
            <MainSectionLabel
              sectionKey={activeSectionKey}
              isSectionCompleted={isActiveSectionCompleted}
              hasFullAccessToMarketplace={hasFullAccessToMarketplace}
              durationInMin={activeSectionDuration}
            />
          ) : isActiveSectionCompleted ? (
            <DoneLabel />
          ) : null
        }
      />
    </S.Wrapper>
  );
};
