import React from 'react';

import { type TagProps } from 'antd';
import { type Variant } from 'styled-components';

import { Icon } from '../Icon';

import * as S from './Chip.styles';

export type ChipSize = 'sm' | 'lg';
export type ChipType = 'regular' | 'logo';

export type ChipProps = {
  children: React.ReactNode;
  $size?: ChipSize;
  $type?: ChipType;
  colorVariant?: Variant;
  onClick?: () => void;
} & TagProps;

export const Chip = ({
  children,
  $size = 'lg',
  $type = 'regular',
  onClick,
  ...props
}: ChipProps) => {
  return (
    <S.StyledChip
      {...props}
      $type={$type}
      $size={$size}
      $clickable={!!onClick}
      closeIcon={<Icon name="x-close" size="xxs" />}
      onClick={onClick}
      {...(!!onClick && { tabIndex: 0, role: 'button' })}
    >
      {children}
    </S.StyledChip>
  );
};
