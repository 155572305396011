import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const TradeButton = styled(Button)`
  && {
    min-width: 120px;
  }
`;

export const ButtonsContainer = styled(Flex).attrs({
  gap: 'md',
  direction: 'row',
})`
  ${({ theme }) => useBreakpoint(theme).tablet`
    flex-direction: column;
    flex: 1;
  `}
`;
