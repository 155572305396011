import {
  EMPTY_VALUE,
  type PriceOrQuantityValue,
  Text,
} from '@npm/core/ui/components/atoms/Typography';

import * as S from './OrderTotal.styles';

type Props = {
  total: PriceOrQuantityValue | undefined;
  label: string;
};

export const OrderTotal = ({ total, label }: Props) => {
  return (
    <S.Container>
      {total == null || total === EMPTY_VALUE ? (
        <S.EmptyPrice>{EMPTY_VALUE}</S.EmptyPrice>
      ) : (
        <S.Price value={total} />
      )}
      <Text size="sm">{label}</Text>
    </S.Container>
  );
};
