import React from 'react';

import { TooltipInfoIcon } from '../../atoms/Tooltip';

import * as S from './TextWithTooltip.styles';

type Props = {
  tooltipText: React.ReactNode;
  children: React.ReactNode;
  alignVertical?: 'flex-start' | 'center' | 'flex-end';
};

export const TextWithTooltip = ({
  tooltipText,
  children,
  alignVertical = 'center',
}: Props) => {
  return (
    <S.Container $alignVertical={alignVertical}>
      {children}
      {tooltipText && <TooltipInfoIcon title={tooltipText} />}
    </S.Container>
  );
};
