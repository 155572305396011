import { Margin, Padding } from '@npm/core/ui/components/atoms/common';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';

import { useUserContextStore } from '../../context';

export const Signature = () => {
  const fullLegalName = useUserContextStore(store => store.getFullName());

  return (
    <Padding y="lg">
      <InputFormItem
        name="signature"
        label="Signature"
        rules={[
          VALIDATION_RULES.matchSpecificValue(
            fullLegalName,
            `Signature must match your full legal name "${fullLegalName}"`
          ),
        ]}
        validateTrigger="onBlur"
        extra={`Your full legal name “${fullLegalName}”`}
        data-dd-privacy="mask"
      >
        <Input placeholder="Signature" />
      </InputFormItem>
      <Margin top="md">
        <Text size="xs" color="general" colorVariant="tertiary">
          Dated {parseDateToString(new Date(), DATE_FORMATS.FULL)}
        </Text>
      </Margin>
    </Padding>
  );
};
