type PathMetadata = typeof investorGeneralMetadata;

export const registerInvestorGeneralMetadata = (metadata: PathMetadata) => {
  investorGeneralMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const investorGeneralMetadata = [
  {
    pathRegex: /.*investor-workstation\/entity-dashboard.*/,
    metadata: {
      screenId: 'NFE-4880',
      screenName: 'Entity Investor Dashboard',
    },
  },
  {
    pathRegex: /.*investor-workstation\/company-data.*/,
    metadata: {
      screenId: 'NFE-1046',
      screenName: 'Company Data',
    },
  },
  {
    pathRegex: /.*investor-workstation\/my-orders.*/,
    metadata: {
      screenId: 'NFE-5309',
      screenName: 'My Orders',
    },
  },
  {
    pathRegex: /.*investor-workstation\/my-watchlist.*/,
    metadata: {
      screenId: 'NFE-5319',
      screenName: 'My Watchlist',
    },
  },
  {
    pathRegex: /.*investor-workstation\/account\/detail.*/,
    metadata: {
      screenId: 'NFE-507',
      screenName: 'Account - Account Information',
    },
  },
  {
    pathRegex: /.*investor-workstation\/account\/agreements.*/,
    metadata: {
      screenId: 'NFE-496',
      screenName: 'INV Account - Agreements',
    },
  },
  {
    pathRegex: /.*investor-workstation\/accounts\/d*.*/,
    metadata: {
      screenId: 'NFE-507',
      screenName: 'Account - Account Information',
    },
  },
  {
    pathRegex: /.*investor-workstation\/account\/holdings.*/,
    metadata: {
      screenId: 'NFE-505',
      screenName: 'Holdings',
    },
  },
  {
    pathRegex: /.*investor-workstation\/accounts.*/,
    metadata: {
      screenId: 'NFE-491',
      screenName: 'Marketplace - Select Account',
    },
  },
  {
    pathRegex: /.*investor-workstation\/general\/agreements.*/,
    metadata: {
      screenId: 'NFE-496',
      screenName: 'Marketplace - Agreements',
    },
  },
  {
    pathRegex: /.*investor-workstation\/programs\/data-rooms.*/,
    metadata: {
      screenId: 'NFE-503',
      screenName: 'Event Data Rooms',
    },
  },
  {
    pathRegex: /.*investor-workstation\/programs\/ndas.*/,
    metadata: {
      screenId: 'NFE-501',
      screenName: 'Programs - NDAs',
    },
  },
  {
    pathRegex: /.*investor-workstation\/programs\/submissions\/\d+.*/,
    metadata: {
      screenId: 'NFE-641',
      screenName: 'Submission card',
    },
  },
  {
    pathRegex:
      /.*investor-workstation\/programs\/submissions\/\d+\/orders\/\d+.*/,
    metadata: {
      screenId: 'NFE-641',
      screenName: 'Submission card',
    },
  },
  {
    pathRegex: /.*investor-workstation\/programs\/submissions.*/,
    metadata: {
      screenId: 'NFE-504',
      screenName: 'Programs - Submissions',
    },
  },
  {
    pathRegex: /.*investor-workstation\/matches\/\d+.*/,
    metadata: {
      screenId: 'NFE-6953',
      screenName: 'INV - Matches - Trade Detail',
    },
  },
  {
    pathRegex: /.*investor-workstation\/matches.*/,
    metadata: {
      screenId: 'NFE-3764',
      screenName: 'INV - Matches',
    },
  },
  {
    pathRegex: /.*investor-workstation\/portfolio.*/,
    metadata: {
      screenId: 'NFE-4084',
      screenName: 'INV + - Portfolio - View portfolio',
    },
  },
  {
    pathRegex: /.*investor-workstation\/portfolio\/import.*[&?]step=upload.*/,
    metadata: {
      screenId: 'NFE-3892',
      screenName: 'INV + - Portfolio - Import portfolio wizard',
    },
  },
  {
    pathRegex: /.*investor-workstation\/portfolio\/import.*[&?]step=review.*/,
    metadata: {
      screenId: 'NFE-3892',
      screenName: 'INV + - Portfolio - Import portfolio wizard',
    },
  },
  {
    pathRegex: /.*investor-workstation\/sector-analysis.*/,
    metadata: {
      screenId: 'NFE-7115',
      screenName: 'INV + - Sector Analysis',
    },
  },
];
