// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AccountsApi,
  AccountSecurityAdminIndexErrorCodes,
  AccountSecurityAdminIndexErrorTypes,
  AccountIndexErrorCodes,
  AccountIndexErrorTypes,
  AccountCreateErrorCodes,
  AccountAdminIndexErrorCodes,
  AccountAdminIndexErrorTypes,
  AccountShowErrorCodes,
  AccountShowErrorTypes,
  AccountAdminShowErrorCodes,
  AccountAdminShowErrorTypes,
  IssuerAccountIndexErrorCodes,
  IssuerAccountIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AccountsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountSecurityAdminIndexMethod = AccountsApi['accountSecurityAdminIndex'];
export type AccountSecurityAdminIndexResponseType = MethodResult<AccountSecurityAdminIndexMethod>;

export const useAccountSecurityAdminIndex = (
  variables: Parameters<AccountSecurityAdminIndexMethod>[0],
  config?: UseQueryConfig<
    AccountSecurityAdminIndexMethod,
    AccountSecurityAdminIndexErrorTypes
  >
) => {
  return useQuery<
    AccountSecurityAdminIndexMethod,
    AccountSecurityAdminIndexErrorTypes
  >(
    (...args) => api.accountSecurityAdminIndex(...args),
    AccountSecurityAdminIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSecurityAdminIndex'],
        ...apiQueryConfig.AccountSecurityAdminIndex,
        ...config?.queryConfig
      },
    },
    'AccountSecurityAdminIndex',
  );
};

export const useAccountSecurityAdminIndexInfinite = (
  variables: Parameters<AccountSecurityAdminIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountSecurityAdminIndexMethod,
    AccountSecurityAdminIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountSecurityAdminIndexMethod,
    AccountSecurityAdminIndexErrorTypes
  >(
    (...args) => api.accountSecurityAdminIndex(...args),
    AccountSecurityAdminIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountSecurityAdminIndex', 'AccountSecurityAdminIndexInfinite'],
        ...apiQueryConfig.AccountSecurityAdminIndex,
        ...config?.queryConfig,
      },
    },
    'AccountSecurityAdminIndex',
  );
};

export const useAccountSecurityAdminIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountSecurityAdminIndexMethod>[0],
  config?: UseQueryConfig<
    AccountSecurityAdminIndexMethod,
    AccountSecurityAdminIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountSecurityAdminIndexMethod,
    AccountSecurityAdminIndexErrorTypes
  >(
    (...args) => api.accountSecurityAdminIndex(...args),
    AccountSecurityAdminIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountSecurityAdminIndex'],
        ...apiQueryConfig.AccountSecurityAdminIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountSecurityAdminIndex',
  );
};


export type AccountIndexMethod = AccountsApi['accountIndex'];
export type AccountIndexResponseType = MethodResult<AccountIndexMethod>;

export const useAccountIndex = (
  variables: Parameters<AccountIndexMethod>[0],
  config?: UseQueryConfig<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >
) => {
  return useQuery<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >(
    (...args) => api.accountIndex(...args),
    AccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountIndex'],
        ...apiQueryConfig.AccountIndex,
        ...config?.queryConfig
      },
    },
    'AccountIndex',
  );
};

export const useAccountIndexInfinite = (
  variables: Parameters<AccountIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >(
    (...args) => api.accountIndex(...args),
    AccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountIndex', 'AccountIndexInfinite'],
        ...apiQueryConfig.AccountIndex,
        ...config?.queryConfig,
      },
    },
    'AccountIndex',
  );
};

export const useAccountIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountIndexMethod>[0],
  config?: UseQueryConfig<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountIndexMethod,
    AccountIndexErrorTypes
  >(
    (...args) => api.accountIndex(...args),
    AccountIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountIndex'],
        ...apiQueryConfig.AccountIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountIndex',
  );
};


export type AccountCreateMethod = AccountsApi['accountCreate'];

export const useAccountCreate = () => {
  return useMutation<AccountCreateMethod>(
    (...args) => api.accountCreate(...args),
    AccountCreateErrorCodes,
    'AccountCreate',
    '/api/investor/accounts',
    'post'
  );
};

export type AccountAdminIndexMethod = AccountsApi['accountAdminIndex'];
export type AccountAdminIndexResponseType = MethodResult<AccountAdminIndexMethod>;

export const useAccountAdminIndex = (
  variables: Parameters<AccountAdminIndexMethod>[0],
  config?: UseQueryConfig<
    AccountAdminIndexMethod,
    AccountAdminIndexErrorTypes
  >
) => {
  return useQuery<
    AccountAdminIndexMethod,
    AccountAdminIndexErrorTypes
  >(
    (...args) => api.accountAdminIndex(...args),
    AccountAdminIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAdminIndex'],
        ...apiQueryConfig.AccountAdminIndex,
        ...config?.queryConfig
      },
    },
    'AccountAdminIndex',
  );
};

export const useAccountAdminIndexInfinite = (
  variables: Parameters<AccountAdminIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountAdminIndexMethod,
    AccountAdminIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountAdminIndexMethod,
    AccountAdminIndexErrorTypes
  >(
    (...args) => api.accountAdminIndex(...args),
    AccountAdminIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAdminIndex', 'AccountAdminIndexInfinite'],
        ...apiQueryConfig.AccountAdminIndex,
        ...config?.queryConfig,
      },
    },
    'AccountAdminIndex',
  );
};

export const useAccountAdminIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountAdminIndexMethod>[0],
  config?: UseQueryConfig<
    AccountAdminIndexMethod,
    AccountAdminIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountAdminIndexMethod,
    AccountAdminIndexErrorTypes
  >(
    (...args) => api.accountAdminIndex(...args),
    AccountAdminIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountAdminIndex'],
        ...apiQueryConfig.AccountAdminIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountAdminIndex',
  );
};


export type AccountShowMethod = AccountsApi['accountShow'];
export type AccountShowResponseType = MethodResult<AccountShowMethod>;

export const useAccountShow = (
  variables: Parameters<AccountShowMethod>[0],
  config?: UseQueryConfig<
    AccountShowMethod,
    AccountShowErrorTypes
  >
) => {
  return useQuery<
    AccountShowMethod,
    AccountShowErrorTypes
  >(
    (...args) => api.accountShow(...args),
    AccountShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountShow'],
        ...apiQueryConfig.AccountShow,
        ...config?.queryConfig
      },
    },
    'AccountShow',
  );
};

export const useAccountShowInfinite = (
  variables: Parameters<AccountShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountShowMethod,
    AccountShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountShowMethod,
    AccountShowErrorTypes
  >(
    (...args) => api.accountShow(...args),
    AccountShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountShow', 'AccountShowInfinite'],
        ...apiQueryConfig.AccountShow,
        ...config?.queryConfig,
      },
    },
    'AccountShow',
  );
};

export const useAccountShowLazy = (baseOptions?: {
  variables?: Parameters<AccountShowMethod>[0],
  config?: UseQueryConfig<
    AccountShowMethod,
    AccountShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountShowMethod,
    AccountShowErrorTypes
  >(
    (...args) => api.accountShow(...args),
    AccountShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountShow'],
        ...apiQueryConfig.AccountShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountShow',
  );
};


export type AccountAdminShowMethod = AccountsApi['accountAdminShow'];
export type AccountAdminShowResponseType = MethodResult<AccountAdminShowMethod>;

export const useAccountAdminShow = (
  variables: Parameters<AccountAdminShowMethod>[0],
  config?: UseQueryConfig<
    AccountAdminShowMethod,
    AccountAdminShowErrorTypes
  >
) => {
  return useQuery<
    AccountAdminShowMethod,
    AccountAdminShowErrorTypes
  >(
    (...args) => api.accountAdminShow(...args),
    AccountAdminShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAdminShow'],
        ...apiQueryConfig.AccountAdminShow,
        ...config?.queryConfig
      },
    },
    'AccountAdminShow',
  );
};

export const useAccountAdminShowInfinite = (
  variables: Parameters<AccountAdminShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountAdminShowMethod,
    AccountAdminShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountAdminShowMethod,
    AccountAdminShowErrorTypes
  >(
    (...args) => api.accountAdminShow(...args),
    AccountAdminShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAdminShow', 'AccountAdminShowInfinite'],
        ...apiQueryConfig.AccountAdminShow,
        ...config?.queryConfig,
      },
    },
    'AccountAdminShow',
  );
};

export const useAccountAdminShowLazy = (baseOptions?: {
  variables?: Parameters<AccountAdminShowMethod>[0],
  config?: UseQueryConfig<
    AccountAdminShowMethod,
    AccountAdminShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountAdminShowMethod,
    AccountAdminShowErrorTypes
  >(
    (...args) => api.accountAdminShow(...args),
    AccountAdminShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountAdminShow'],
        ...apiQueryConfig.AccountAdminShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountAdminShow',
  );
};


export type IssuerAccountIndexMethod = AccountsApi['issuerAccountIndex'];
export type IssuerAccountIndexResponseType = MethodResult<IssuerAccountIndexMethod>;

export const useIssuerAccountIndex = (
  variables: Parameters<IssuerAccountIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerAccountIndexMethod,
    IssuerAccountIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerAccountIndexMethod,
    IssuerAccountIndexErrorTypes
  >(
    (...args) => api.issuerAccountIndex(...args),
    IssuerAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerAccountIndex'],
        ...apiQueryConfig.IssuerAccountIndex,
        ...config?.queryConfig
      },
    },
    'IssuerAccountIndex',
  );
};

export const useIssuerAccountIndexInfinite = (
  variables: Parameters<IssuerAccountIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerAccountIndexMethod,
    IssuerAccountIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerAccountIndexMethod,
    IssuerAccountIndexErrorTypes
  >(
    (...args) => api.issuerAccountIndex(...args),
    IssuerAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerAccountIndex', 'IssuerAccountIndexInfinite'],
        ...apiQueryConfig.IssuerAccountIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerAccountIndex',
  );
};

export const useIssuerAccountIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerAccountIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerAccountIndexMethod,
    IssuerAccountIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerAccountIndexMethod,
    IssuerAccountIndexErrorTypes
  >(
    (...args) => api.issuerAccountIndex(...args),
    IssuerAccountIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerAccountIndex'],
        ...apiQueryConfig.IssuerAccountIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerAccountIndex',
  );
};

