import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import type { UploadFile } from 'antd/lib/upload/interface';
import { useTheme } from 'styled-components';

import { PooUploadComponentsStyles as S } from './styles';

type Props = {
  file: UploadFile;
  onRemove: (file: UploadFile) => void;
};

export const FileItem = ({ file, onRemove }: Props) => {
  const theme = useTheme();

  return (
    <S.FileContainer>
      <S.PaperClipWrapper>
        <Icon name="paperclip" size="xs" />
      </S.PaperClipWrapper>
      <S.FileName>{file.name}</S.FileName>
      <S.TrashButton
        as="button"
        onClick={e => {
          onRemove?.(file);
          e.stopPropagation();
        }}
      >
        <Icon
          name="trash"
          size="xs"
          color={theme.color.error.typography.primary}
        />
      </S.TrashButton>
    </S.FileContainer>
  );
};
