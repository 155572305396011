import React from 'react';

import { type CbLabelProps, type CbRenderElement } from '@npm/utils';

import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { EMPTY_VALUE, Text } from '../Typography';

import { Label } from './Label';

export const CbLabel = (props: CbLabelProps): CbRenderElement => {
  if (!props) return null;

  const {
    variant = 'general',
    iconProps,
    label,
    round,
    dashed,
    style,
    tooltip,
  } = props;

  if (!label) return <Text size="sm">{EMPTY_VALUE}</Text>;

  const icon = iconProps ? <Icon {...iconProps} /> : undefined;

  return (
    <Tooltip title={tooltip}>
      <Label
        round={round}
        icon={icon}
        variant={variant}
        dashed={dashed}
        style={style}
      >
        {label}
      </Label>
    </Tooltip>
  );
};
