// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PortfolioPlotApi,
  PortfolioPlotErrorCodes,
  PortfolioPlotErrorTypes,
  PortfolioCompanyPlotErrorCodes,
  PortfolioCompanyPlotErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PortfolioPlotApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PortfolioPlotMethod = PortfolioPlotApi['portfolioPlot'];
export type PortfolioPlotResponseType = MethodResult<PortfolioPlotMethod>;

export const usePortfolioPlot = (
  variables: Parameters<PortfolioPlotMethod>[0],
  config?: UseQueryConfig<
    PortfolioPlotMethod,
    PortfolioPlotErrorTypes
  >
) => {
  return useQuery<
    PortfolioPlotMethod,
    PortfolioPlotErrorTypes
  >(
    (...args) => api.portfolioPlot(...args),
    PortfolioPlotErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PortfolioPlot'],
        ...apiQueryConfig.PortfolioPlot,
        ...config?.queryConfig
      },
    },
    'PortfolioPlot',
  );
};

export const usePortfolioPlotInfinite = (
  variables: Parameters<PortfolioPlotMethod>[0],
  config?: UseInfiniteQueryConfig<
    PortfolioPlotMethod,
    PortfolioPlotErrorTypes
  >
) => {
  return useInfiniteQuery<
    PortfolioPlotMethod,
    PortfolioPlotErrorTypes
  >(
    (...args) => api.portfolioPlot(...args),
    PortfolioPlotErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PortfolioPlot', 'PortfolioPlotInfinite'],
        ...apiQueryConfig.PortfolioPlot,
        ...config?.queryConfig,
      },
    },
    'PortfolioPlot',
  );
};

export const usePortfolioPlotLazy = (baseOptions?: {
  variables?: Parameters<PortfolioPlotMethod>[0],
  config?: UseQueryConfig<
    PortfolioPlotMethod,
    PortfolioPlotErrorTypes
  >
}) => {
  return useLazyQuery<
    PortfolioPlotMethod,
    PortfolioPlotErrorTypes
  >(
    (...args) => api.portfolioPlot(...args),
    PortfolioPlotErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PortfolioPlot'],
        ...apiQueryConfig.PortfolioPlot,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PortfolioPlot',
  );
};


export type PortfolioCompanyPlotMethod = PortfolioPlotApi['portfolioCompanyPlot'];
export type PortfolioCompanyPlotResponseType = MethodResult<PortfolioCompanyPlotMethod>;

export const usePortfolioCompanyPlot = (
  variables: Parameters<PortfolioCompanyPlotMethod>[0],
  config?: UseQueryConfig<
    PortfolioCompanyPlotMethod,
    PortfolioCompanyPlotErrorTypes
  >
) => {
  return useQuery<
    PortfolioCompanyPlotMethod,
    PortfolioCompanyPlotErrorTypes
  >(
    (...args) => api.portfolioCompanyPlot(...args),
    PortfolioCompanyPlotErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PortfolioCompanyPlot'],
        ...apiQueryConfig.PortfolioCompanyPlot,
        ...config?.queryConfig
      },
    },
    'PortfolioCompanyPlot',
  );
};

export const usePortfolioCompanyPlotInfinite = (
  variables: Parameters<PortfolioCompanyPlotMethod>[0],
  config?: UseInfiniteQueryConfig<
    PortfolioCompanyPlotMethod,
    PortfolioCompanyPlotErrorTypes
  >
) => {
  return useInfiniteQuery<
    PortfolioCompanyPlotMethod,
    PortfolioCompanyPlotErrorTypes
  >(
    (...args) => api.portfolioCompanyPlot(...args),
    PortfolioCompanyPlotErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PortfolioCompanyPlot', 'PortfolioCompanyPlotInfinite'],
        ...apiQueryConfig.PortfolioCompanyPlot,
        ...config?.queryConfig,
      },
    },
    'PortfolioCompanyPlot',
  );
};

export const usePortfolioCompanyPlotLazy = (baseOptions?: {
  variables?: Parameters<PortfolioCompanyPlotMethod>[0],
  config?: UseQueryConfig<
    PortfolioCompanyPlotMethod,
    PortfolioCompanyPlotErrorTypes
  >
}) => {
  return useLazyQuery<
    PortfolioCompanyPlotMethod,
    PortfolioCompanyPlotErrorTypes
  >(
    (...args) => api.portfolioCompanyPlot(...args),
    PortfolioCompanyPlotErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PortfolioCompanyPlot'],
        ...apiQueryConfig.PortfolioCompanyPlot,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PortfolioCompanyPlot',
  );
};

