import React, { type ComponentProps } from 'react';

import {
  type HoldingApiHoldingIndexRequest,
  useHoldingIndexInfinite,
} from '@npm/data-access';

import { type HoldingCard } from '../../Card';

import { HoldingsCards } from './HoldingsCards';

type Props = {
  secondmarket: boolean | undefined;
  variables: HoldingApiHoldingIndexRequest;
  isFilterApplied?: boolean;
  filterVariables?: Partial<HoldingApiHoldingIndexRequest>;
} & Omit<ComponentProps<typeof HoldingCard>, 'holding'>;

export const HoldingsCardsContainer = ({
  secondmarket,
  variables,
  ...props
}: Props) => {
  const { data, isFetching, fetchNextPage, hasNextPage } =
    useHoldingIndexInfinite({
      aggregatedHolding: false,
      ...variables,
      secondmarket,
    });

  return (
    <HoldingsCards
      {...props}
      data={data}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    />
  );
};
