import React, { useCallback, useMemo } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { type MenuItem } from '@npm/core/ui/components/organisms/Layout';
import { type ItemType } from 'antd/lib/menu/hooks/useItems';

import { useZendesk } from '../widget/zendesk.hooks';

import * as S from './HelpButton/HelpButton.styles';

export const useHelpMenuItem = () => {
  const { toggle: toggleZendesk, unreadMessageCount, isEnabled } = useZendesk();

  const helpMenuItem = useMemo(() => {
    return {
      label: (
        <Tooltip
          title={
            !isEnabled ? 'Online helpdesk is not available right now.' : null
          }
        >
          Help & Support
        </Tooltip>
      ),
      title: isEnabled
        ? 'Help & Support'
        : 'Online helpdesk is not available right now',
      key: 'help',
      icon: unreadMessageCount ? (
        <S.Badge size="sm">{unreadMessageCount}</S.Badge>
      ) : (
        <Icon name="message" />
      ),
    } satisfies MenuItem;
  }, []);

  const handleHelpMenuItemClick = useCallback(
    (key: string) => {
      if (key === 'help') {
        toggleZendesk();
        return true;
      }
      return false;
    },
    [toggleZendesk]
  );

  return useMemo(() => {
    return {
      helpMenuItem,
      handleHelpMenuItemClick,
    };
  }, [helpMenuItem, handleHelpMenuItemClick]);
};
