// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MarketplaceAgreementSigningApi,
  MarketplaceAgreementSigningErrorCodes,
  MarketplaceAgreementSigningCompleteErrorCodes,
  MarketplaceAgreementSigningCompleteErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MarketplaceAgreementSigningApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type MarketplaceAgreementSigningMethod = MarketplaceAgreementSigningApi['marketplaceAgreementSigning'];

export const useMarketplaceAgreementSigning = () => {
  return useMutation<MarketplaceAgreementSigningMethod>(
    (...args) => api.marketplaceAgreementSigning(...args),
    MarketplaceAgreementSigningErrorCodes,
    'MarketplaceAgreementSigning',
    '/api/marketplace-agreement-signing',
    'post'
  );
};

export type MarketplaceAgreementSigningCompleteMethod = MarketplaceAgreementSigningApi['marketplaceAgreementSigningComplete'];
export type MarketplaceAgreementSigningCompleteResponseType = MethodResult<MarketplaceAgreementSigningCompleteMethod>;

export const useMarketplaceAgreementSigningComplete = (
  variables: Parameters<MarketplaceAgreementSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    MarketplaceAgreementSigningCompleteMethod,
    MarketplaceAgreementSigningCompleteErrorTypes
  >
) => {
  return useQuery<
    MarketplaceAgreementSigningCompleteMethod,
    MarketplaceAgreementSigningCompleteErrorTypes
  >(
    (...args) => api.marketplaceAgreementSigningComplete(...args),
    MarketplaceAgreementSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarketplaceAgreementSigningComplete'],
        ...apiQueryConfig.MarketplaceAgreementSigningComplete,
        ...config?.queryConfig
      },
    },
    'MarketplaceAgreementSigningComplete',
  );
};

export const useMarketplaceAgreementSigningCompleteInfinite = (
  variables: Parameters<MarketplaceAgreementSigningCompleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarketplaceAgreementSigningCompleteMethod,
    MarketplaceAgreementSigningCompleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarketplaceAgreementSigningCompleteMethod,
    MarketplaceAgreementSigningCompleteErrorTypes
  >(
    (...args) => api.marketplaceAgreementSigningComplete(...args),
    MarketplaceAgreementSigningCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarketplaceAgreementSigningComplete', 'MarketplaceAgreementSigningCompleteInfinite'],
        ...apiQueryConfig.MarketplaceAgreementSigningComplete,
        ...config?.queryConfig,
      },
    },
    'MarketplaceAgreementSigningComplete',
  );
};

export const useMarketplaceAgreementSigningCompleteLazy = (baseOptions?: {
  variables?: Parameters<MarketplaceAgreementSigningCompleteMethod>[0],
  config?: UseQueryConfig<
    MarketplaceAgreementSigningCompleteMethod,
    MarketplaceAgreementSigningCompleteErrorTypes
  >
}) => {
  return useLazyQuery<
    MarketplaceAgreementSigningCompleteMethod,
    MarketplaceAgreementSigningCompleteErrorTypes
  >(
    (...args) => api.marketplaceAgreementSigningComplete(...args),
    MarketplaceAgreementSigningCompleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarketplaceAgreementSigningComplete'],
        ...apiQueryConfig.MarketplaceAgreementSigningComplete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarketplaceAgreementSigningComplete',
  );
};

