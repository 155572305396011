import { type ComponentProps, useEffect } from 'react';

import { TooltipGlobalStyles } from '@npm/core/ui/components/atoms/Tooltip';
import { Form } from 'antd';
import { isNil } from 'lodash';

import type { Action, OrderPlacementFormValues } from '../..';
import { OrderSize } from '../../../../components/OrderSize';

type Props = {
  activeAction: Action;
} & Omit<ComponentProps<typeof OrderSize>, 'activeAction'>;

export const OrderEntrySection = ({
  activeAction,
  remainingQuantity: remainingHoldingQuantity,
  sizeType: orderSizeType,
  onSizeTypeChange: toggleSizeType,
  company,
  visibility,
  showMinimumQuantity,
  showSizeExtraRow,
  showValuation,
  header,
  showPpsValuation,
}: Props) => {
  const form = Form.useFormInstance<OrderPlacementFormValues>();
  const quantity = Form.useWatch('quantity', form);
  const pricePerShare = Form.useWatch('pricePerShare', form);

  useEffect(() => {
    const estimatedGrossPrice =
      (isNil(quantity) ? 0 : quantity) *
      (isNil(pricePerShare) ? 0 : pricePerShare);
    form.setFieldsValue({ estimatedGrossPrice });
    form.validateFields(['estimatedGrossPrice']);
  }, [form, quantity, pricePerShare]);

  return (
    <>
      <TooltipGlobalStyles />
      <OrderSize
        activeAction={activeAction}
        remainingQuantity={remainingHoldingQuantity}
        sizeType={orderSizeType}
        onSizeTypeChange={toggleSizeType}
        company={company}
        visibility={visibility}
        showSizeExtraRow={showSizeExtraRow}
        showMinimumQuantity={showMinimumQuantity}
        showValuation={showValuation}
        header={header}
        showPpsValuation={showPpsValuation}
      />
    </>
  );
};
