import React, { useCallback, useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { SIDEBAR_TOOLTIP_CLASSNAME } from '@npm/core/ui/components/organisms/Layout/Sidebar/Sidebar.styles';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  CbWorkstationType,
  type UserRoleAggregate,
  useWorkstationCreate,
  useWorkstationIndex,
  type Workstation,
  type WorkstationType,
} from '@npm/data-access';
import { useQueryClient } from '@tanstack/react-query';

import { useGetWorkstationLandingPage } from '../../../../workstations/workstationLandingPage.hooks';
import { useCurrentAccount, useCurrentWorkstation } from '../../role';
import { useCurrentRole } from '../../role/hooks/useCurrentRole';
import { useUserContextStore, WORKSTATION_ICON_MAP } from '../store';
import { SwitcherButton } from '../SwitcherButton';

import { invalidateWorkstationCache } from './WorkstationSwitcher.utils';

type Props = {
  variant?: 'desktop' | 'mobile';
  isCollapsed: boolean;
};

const getWorkstationKey = (ws: Workstation) => {
  return ws.type?.code === CbWorkstationType.items.brokerage ||
    ws.type?.code === CbWorkstationType.items.advisor
    ? ws.user_role?.id
    : ws.type?.code;
};

export const WorkstationSwitcher = ({ isCollapsed }: Props) => {
  const currentWorkstation = useCurrentWorkstation();
  const role = useCurrentRole();
  const setRole = useUserContextStore(store => store.setRole);
  const context = useUserContextStore();
  const { isObo, isOboOrAccountCentric } = useCurrentAccount();
  const getWorkstationLandingPage = useGetWorkstationLandingPage();

  const { data, isLoading } = useWorkstationIndex({});

  // If OBO then workstation is Investor
  // WS for Brokerage role is based on the role id
  const selectedWorkstationKey = isObo
    ? CbWorkstationType.items.investor
    : currentWorkstation?.type?.code === CbWorkstationType.items.brokerage ||
        currentWorkstation?.type?.code === CbWorkstationType.items.advisor
      ? role?.id
      : currentWorkstation?.type?.code;

  const selectedWorkstation = data?.workstations?.find(
    ws => getWorkstationKey(ws) === selectedWorkstationKey
  );

  const { execute: switchWorkstation } = useWorkstationCreate();

  const { showApiError } = useAlerts();

  const queryClient = useQueryClient();

  const handleWorkstationSwitch = useCallback(
    async (workstation: Workstation) => {
      let nextRole: UserRoleAggregate | null;

      try {
        const data = await switchWorkstation(
          {
            workstationCreateRequestContract: {
              type: workstation.type?.code,
              user_role_id: workstation.user_role?.id,
            },
          },
          {
            roles: {
              workstation: workstation.type?.code as WorkstationType,
              roleId: workstation.user_role?.id || undefined,
            },
          }
        );
        invalidateWorkstationCache(workstation, queryClient);

        // user should land in all-accounts mode (role === null) if they are selecting
        // investor workstation and they have more than 1 account role available
        nextRole =
          workstation.type?.code === CbWorkstationType.items.investor &&
          workstation.user_role_count > 1
            ? null
            : data.user_role;

        const redirectTo = await getWorkstationLandingPage(
          workstation.type?.code,
          context,
          nextRole
        );

        setRole(nextRole, workstation, {
          redirectTo,
        });
      } catch (e) {
        showApiError(e);
      }
    },
    [setRole, showApiError, switchWorkstation, context]
  );

  const isTriggerDisabled = data?.workstations?.length < 2;

  const getWsDescription = useCallback(
    (workstation: Workstation, selected: boolean) => {
      if (selected && isOboOrAccountCentric) {
        return role?.subject?.name; // if in the account centric mode, show the account name
      }
      return workstation?.description;
    },
    [isOboOrAccountCentric, role?.subject?.name]
  );

  const trigger = (
    <Tooltip
      title={
        isCollapsed && (
          <>
            {selectedWorkstation?.type?.name}
            <br />
            {getWsDescription(selectedWorkstation, true)}
          </>
        )
      }
      placement="right"
      overlayClassName={SIDEBAR_TOOLTIP_CLASSNAME}
    >
      <SwitcherButton
        size="md"
        data-cy={CypressDataIds.WorkstationSwitcher.OpenButton}
        data-dd-action-name="Workstation Switcher"
        icon={WORKSTATION_ICON_MAP[selectedWorkstation?.type?.code]}
        isCollapsed={isCollapsed}
        disabled={isTriggerDisabled}
      >
        <Flex align="center" gap="xs" style={{ flexGrow: 1 }}>
          <div>{selectedWorkstation?.type?.name}</div>
          {data?.workstations?.length > 1 && <Icon name="chevron-opposite" />}
        </Flex>
      </SwitcherButton>
    </Tooltip>
  );

  const dropdownItems = useMemo(
    () =>
      isTriggerDisabled
        ? []
        : data?.workstations?.map(ws => {
            const key = getWorkstationKey(ws);
            const selected = key === selectedWorkstationKey;

            return {
              key,
              selected,
              label: ws.type?.name,
              description: getWsDescription(ws, selected),
              title: '', // disables tooltips for this menu when the sidebar is collapsed
              onClick: () => {
                handleWorkstationSwitch(ws);
              },
              'data-dd-action-name': `${ws.type?.name} Button`,
            };
          }),
    [
      data,
      selectedWorkstationKey,
      isTriggerDisabled,
      getWsDescription,
      handleWorkstationSwitch,
    ]
  );

  if (isLoading) return null;
  if (isTriggerDisabled) return trigger;

  return (
    <Dropdown
      fixedPosition
      zIndex="aboveAll"
      placement="bottomLeft"
      menuProps={{
        selectable: true,
        style: { maxHeight: 300, minWidth: 220, overflow: 'auto' },
      }}
      dataCy={CypressDataIds.WorkstationSwitcher.Dropdown.Content}
      items={dropdownItems}
    >
      {trigger}
    </Dropdown>
  );
};
