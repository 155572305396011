import React, { useState } from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useConfirmOnLeave } from '@npm/core/ui/components/organisms/DiscardModal';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  StepState,
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { CypressDataIds } from '@npm/core/ui/constants';

import {
  AddAuthorizedSignerCta,
  AddAuthorizedSignerForm,
  AuthorizedSignersAlert,
  AuthorizedSignersReadOnly,
  useAuthorizedSignersForm,
} from '../../../onboardingAndSubmissionSteps/AuthorizedSigners';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';

const AUTHORIZED_SIGNER_FORM_ID = 'authorized-signer-form';

type Props = {
  stepProps: Wizard.StepProps;
};

export const AuthorizedSignersPostOnboarding = ({ stepProps }: Props) => {
  const { account, stepState, dispatch } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      account: s.data.account,
      stepState: s.activeStepState,
      dispatch: s.dispatch,
    })
  );
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave('AuthorizedSignersPostOnboarding');

  const { form, handleSubmit, data, isLoading, isUpdating, pagination } =
    useAuthorizedSignersForm(account, {
      onUpdate: () => {
        setHasUnsavedChanges(false);
        dispatch({
          type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
          payload: StepState.FILLED,
        });
        setIsFormOpen(false);
        form.resetFields();
        Notification.success({
          message: 'You have updated your Account Authorized Signers',
        });
        stepProps.handleStepComplete();
      },
    });

  const handleCloseConfirm = () => {
    setHasUnsavedChanges(false);
    setIsFormOpen(false);
  };

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isUpdating}
      handleEdit={() => setIsFormOpen(true)}
      updateButtonProps={{
        children: 'Add another authorized signer',
        icon: 'plus',
        hideButton: data?.pagination?.total_records === 0,
        'data-cy':
          CypressDataIds.PostOnboarding.AuthorizedSigner.AddAnotherSignerButton,
      }}
    >
      <AuthorizedSignersAlert />
      {stepState === StepState.EDIT && (
        <AddAuthorizedSignerCta
          onClick={() => setIsFormOpen(true)}
          accountName={account?.name}
        />
      )}
      {stepState === StepState.FILLED && (
        <AuthorizedSignersReadOnly data={data} paginationData={pagination} />
      )}
      <Drawer
        open={isFormOpen}
        title="Authorized Signer"
        isLoading={isLoading}
        isFullHeight
        submitButtonProps={{
          children: 'Submit',
          loading: isUpdating,
          disabled: isLoading || isUpdating,
          form: AUTHORIZED_SIGNER_FORM_ID,
          htmlType: 'submit',
        }}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
      >
        <AddAuthorizedSignerForm
          id={AUTHORIZED_SIGNER_FORM_ID}
          form={form}
          signerAvailable={Boolean(data?.signers?.length)}
          onValuesChange={() => setHasUnsavedChanges(true)}
          onFinish={handleSubmit}
        />
      </Drawer>
      <DiscardModal {...discardModalProps}>
        You have not saved your changes. <br /> Please make sure you have added
        a signer before proceeding.
      </DiscardModal>
    </StepContainer>
  );
};
