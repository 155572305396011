import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import {
  type ColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { type AccountsOnboardingAgreementShow } from '@npm/data-access';

import { type OnboardingAgreementType } from '../../OnboardingAgreements.types';

import * as S from './OnboardingAgreementReadOnly.styles';
import { NAME_CELL_CLASS } from './OnboardingAgreementReadOnly.styles';

export const getOnboardingAgreementColumns = (
  type: OnboardingAgreementType,
  openDocumentViewer: (id?: number) => void,
  handleReplaceButtonClick: () => void
): ColumnType<AccountsOnboardingAgreementShow>[] => [
  {
    key: 'name',
    title: 'Name',
    className: NAME_CELL_CLASS,
    render: agreement => agreement[type]?.display_name,
  },
  {
    key: 'brokerage',
    title: 'Brokerage Firm',
    responsive: TABLE_BREAKPOINTS.TABLET_SM,
    render: ({ brokerage_firm_name }) => brokerage_firm_name,
  },
  {
    key: 'signatures',
    title: 'Signatures',
    responsive: TABLE_BREAKPOINTS.TABLET_SM,
    render: ({ paying_agent_service_signer, marketplace_signer }) => {
      const agreementSigner =
        type === 'paying_agent_service_agreement'
          ? paying_agent_service_signer
          : marketplace_signer;
      // should use `signed` property instead, once it's working correctly on the BE
      const applySuccessStyles = agreementSigner.signed_at !== null;

      return (
        <S.SignaturesWrapper>
          <Label
            variant={applySuccessStyles ? 'success' : 'general'}
            icon={applySuccessStyles ? <Icon name="check" /> : null}
          >
            {agreementSigner?.type?.name}
          </Label>
        </S.SignaturesWrapper>
      );
    },
  },
  {
    key: 'actions',
    title: '',
    responsive: TABLE_BREAKPOINTS.MOBILE,
    render: agreement => (
      <Flex justify="flex-end" gap="sm">
        {handleReplaceButtonClick && (
          <Button
            variant="text"
            onClick={handleReplaceButtonClick}
            icon={<Icon name="upload" />}
          >
            Replace
          </Button>
        )}
        <Button
          variant="outline"
          icon={<Icon name="external-link" />}
          onClick={() => openDocumentViewer(agreement[type]?.id)}
        >
          Open
        </Button>
      </Flex>
    ),
  },
];
