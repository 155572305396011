import { useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { CbRoleType } from '@npm/data-access';
import {
  type Account,
  type SignerApiAccountSignerIndexRequest,
  useAccountSignerCreate,
  useAccountSignerIndex,
} from '@npm/data-access';
import { type PaginationProps } from 'antd';
import { omit } from 'lodash';

import { type FormValues } from './Form/AddAuthorizedSignerForm.types';

export const useAuthorizedSignersForm = (
  account: Account,
  { onUpdate }: Partial<{ onUpdate: () => void }> = {}
) => {
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute, isLoading: isUpdating } = useAccountSignerCreate();
  const [form] = Form.useForm<FormValues>();

  const [variables, setVariables] =
    useState<SignerApiAccountSignerIndexRequest>({
      accountId: account?.id,
      page: 1,
      size: PAGE_SIZE_HARD_LIMIT,
    });

  const { data, isLoading } = useAccountSignerIndex(variables, {
    queryConfig: { enabled: !!account.id },
  });

  const { pagination, updatePaginationVariables } =
    usePagination<SignerApiAccountSignerIndexRequest>('authorizedSigners', {
      variables,
      setVariables,
      paginationData: data?.pagination,
    });

  const handleSubmit = async () => {
    let values: FormValues;
    const codebookValue = `${CbRoleType.code}.${CbRoleType.items.GUEST_AND_SIGNER}`;

    try {
      values = await form.validateFields();
    } catch (e) {
      return handleValidationError(form, e);
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        accountId: account?.id,
        accountsSignerCreateRequestContract: {
          ...omit(values, 'accept-disclaimer'),
          signer_role_type: codebookValue,
        },
      });

      onUpdate?.();
    } catch (e) {
      console.error(e);
    }
  };

  return {
    form,
    data,
    handleSubmit,
    isLoading,
    isUpdating,
    pagination: [pagination, updatePaginationVariables] as [
      PaginationProps,
      (pagination: PaginationProps) => void,
    ],
  };
};
