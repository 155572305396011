import { useMemo } from 'react';

import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { ParamNotFoundError } from '@npm/core/ui/hooks/useParams';
import {
  type Account,
  type AccountUiModeType,
  CbAccountUiMode,
  CbBackgroundCheckState,
  CbHoldingState,
  CbOnboardingStatus,
  useAccountOnboardingStatusShow,
  useAccountShow,
  useHoldingIndex,
  type UserRoleAggregate,
} from '@npm/data-access';

import {
  isIndividualAccount,
  isNPMS,
  useUserContextStore,
} from '../auth/user/context';

import type {
  BackgroundCheckStatus,
  OnboardingStatus,
  UserVariantType,
} from './dashboard.types';

const INDIVIDUAL_AML_KYC_KEYS = [
  CbOnboardingStatus.steps.personal_information_state,
  CbOnboardingStatus.steps.tax_information_state,
  CbOnboardingStatus.steps.spouse_information_state,
  CbOnboardingStatus.steps.individual_tax_state,
  CbOnboardingStatus.steps.representative_photo_state,
];

const INVESTOR_AML_KYC_KEYS = INDIVIDUAL_AML_KYC_KEYS;

const INDIVIDUAL_AML_KYC_WITHOUT_SPOUSE_KEYS = [
  CbOnboardingStatus.steps.personal_information_state,
  CbOnboardingStatus.steps.tax_information_state,
  CbOnboardingStatus.steps.individual_tax_state,
  CbOnboardingStatus.steps.representative_photo_state,
];

const ENTITY_AML_KYC_KEYS = [
  CbOnboardingStatus.steps.entity_information_state,
  CbOnboardingStatus.steps.entity_tax_state,
  CbOnboardingStatus.steps.entity_formation_document_state,
  CbOnboardingStatus.steps.beneficial_ownership_assessment_state,
];

export const useOnboardingStatus = (
  accountId: number | undefined,
  variant: UserVariantType
): OnboardingStatus => {
  const { data: onboardingData, isLoading: isOnboardingLoading } =
    useAccountOnboardingStatusShow(
      { id: accountId },
      { queryConfig: { enabled: accountId != null } }
    );

  const isComplete = CbOnboardingStatus.items.completed;

  const amlKycKeys =
    variant === 'individual'
      ? INDIVIDUAL_AML_KYC_KEYS
      : variant === 'investor'
        ? INVESTOR_AML_KYC_KEYS
        : ENTITY_AML_KYC_KEYS;

  const isAmlKycComplete =
    onboardingData &&
    amlKycKeys.every(
      key => !onboardingData[key] || onboardingData[key].code === isComplete
    );

  const isIndividualAmlKycWithoutSpouseCompleted =
    onboardingData &&
    INDIVIDUAL_AML_KYC_WITHOUT_SPOUSE_KEYS.every(
      key => !onboardingData[key] || onboardingData[key].code === isComplete
    );

  const isMarketplaceAgreementComplete =
    onboardingData?.marketplace_agreement_state?.code === isComplete;

  const areAgreementsComplete = isMarketplaceAgreementComplete; // paying_agent_agreement_state is not required

  const isPaymentInstructionsComplete =
    onboardingData?.bank_account_state?.code === isComplete;

  const isSpouseInformationComplete =
    onboardingData?.spouse_information_state?.code === isComplete;

  const isAccreditationComplete =
    onboardingData?.accredited_investor_state?.code === isComplete;

  const isBuyerEligibilityComplete =
    isAccreditationComplete &&
    onboardingData?.buyer_survey_state?.code === isComplete;

  const isOnboardingStarted =
    onboardingData &&
    Object.entries(onboardingData).some(
      ([key, codebookItem]) =>
        !!codebookItem &&
        codebookItem.code === isComplete &&
        key !== CbOnboardingStatus.steps.authorized_signer_state // completed during sign up
    );

  const isSellerOnboardingToMarketplaceComplete =
    isAmlKycComplete && isMarketplaceAgreementComplete;

  const isOnboardingToMarketplaceComplete =
    variant === 'individual'
      ? isSellerOnboardingToMarketplaceComplete
      : isBuyerEligibilityComplete && isSellerOnboardingToMarketplaceComplete;

  return {
    isOnboardingLoading,
    isAmlKycComplete,
    isIndividualAmlKycWithoutSpouseCompleted,
    isMarketplaceAgreementComplete,
    isOnboardingStarted,
    areAgreementsComplete,
    isOnboardingToMarketplaceComplete,
    isPaymentInstructionsComplete,
    isSpouseInformationComplete,
    isAccreditationComplete,
    isBuyerEligibilityComplete,
  };
};

export const getBackgroundCheckStatus = (
  account: Pick<Account, 'background_check_state'>,
  isLoading: boolean
): BackgroundCheckStatus => {
  const bgCode = account?.background_check_state?.code;
  const isBgCheckNone = bgCode === CbBackgroundCheckState.items.none;
  const isBgCheckPassed = bgCode === CbBackgroundCheckState.items.passed;
  const isBgCheckPending = bgCode === CbBackgroundCheckState.items.pending;
  const isBgCheckFailed = bgCode === CbBackgroundCheckState.items.failed;

  return {
    isBgCheckNone,
    isBgCheckFailed,
    isBgCheckPending,
    isBgCheckPassed,
    isBgCheckLoading: isLoading,
  };
};

export const useBackgroundCheckStatus = (accountId: number) => {
  const { data: accountData, isLoading } = useAccountShow({
    id: accountId?.toString(),
  });

  return getBackgroundCheckStatus(accountData, isLoading);
};

export const useHoldingsInformation = (
  accountId: number,
  issuerEntityId: number
) => {
  const { data: holdingsData } = useHoldingIndex({
    issuerEntityId,
    accountId,
    secondmarket: true,
    aggregatedHolding: false,
    size: PAGE_SIZE_HARD_LIMIT,
  });

  const holdings = holdingsData?.holdings;

  const verifiedHoldingsCount = useMemo(
    () =>
      holdings?.reduce(
        (total, holding) =>
          total +
          (holding.state.code === CbHoldingState.items.verified ? 1 : 0),
        0
      ),
    [holdings]
  );

  const isAnyHoldingMissingPoO = useMemo(
    () => holdings?.some(holding => !holding.proof_of_ownership_document),
    [holdings]
  );

  const isAnyHoldingMissingCertId = useMemo(
    () => holdings?.some(holding => !holding.certificate_number),
    [holdings]
  );

  if (!holdings)
    return {
      verifiedHoldingsCount: 0,
      totalHoldings: 0,
      areAllHoldingsVerified: true,
      isAnyHoldingMissingPoO: false,
      isAnyHoldingMissingCertificateId: false,
    };

  return {
    totalHoldings: holdings.length,
    verifiedHoldingsCount,
    areAllHoldingsVerified: verifiedHoldingsCount === holdings.length,
    isAnyHoldingMissingPoO,
    isAnyHoldingMissingCertId,
  };
};

export const useIndividualInvestorNpmsAccount = (): UserRoleAggregate => {
  const investorAccounts = useUserContextStore(store => store.investorAccounts);

  const npmsAccount = useMemo(() => {
    return investorAccounts?.find(
      role => isNPMS(role?.subject) && isIndividualAccount(role)
    );
  }, [investorAccounts]);

  if (!npmsAccount) {
    throw new ParamNotFoundError('Could not find an individual NPMS account');
  }

  return npmsAccount;
};

export const useIndividualInvestorTradingMode = () => {
  const npmsAccount = useIndividualInvestorNpmsAccount();

  const currentAccountUiMode = npmsAccount.subject?.account_ui_mode
    ?.code as AccountUiModeType;

  return currentAccountUiMode ?? CbAccountUiMode.items.seller;
};
