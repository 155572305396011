import { useEffect } from 'react';

import { Loader } from '@npm/core/ui/components/atoms/Loader';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { lightTheme } from '@npm/core/ui/themes';
import { useOktaUserVerify } from '@npm/data-access';
import { oktaAuth } from '@npm/okta';
import { ThemeProvider } from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import * as S from '../signup/OktaSignUp/OktaSignUp.styles';
import { NpmLogo } from '../signup/OktaSignUp/NpmLogo';

// This key is sent from the invitation email.
// Example: http://portal.lvh.me:3001/invitation?allAccounts=0&key=NxTA2WKhRBSA4t9dw36DUsxGnTiYLYMDW%2FFNCKoawsCp4LxXetePbA%3D%3D--3TKA0Rn20M4eHoa8--YqhezYZd60vr4xx2PC4m2g%3D%3D&roleId=100195
const QUERY_PARAM_KEY = 'key';

// This page handles redirection to either Okta's sign-in or to it's activation page.
// We call `/api/user/verify` to check if user is already activated.
export const InvitationPage = () => {
  const { execute } = useOktaUserVerify();
  const { withShowApiErrorMiddleware } = useAlerts();

  const [userKey] = useQueryParam(QUERY_PARAM_KEY, StringParam);
  const [fromURI] = useQueryParam('fromURI', StringParam);

  // if fromURI not specified, user might be here incorrectly. Remove query params to avoid loops.
  const originalUri = fromURI ?? window.location.href.split('?')[0];

  const signInRedirect = async () => {
    await oktaAuth.signInWithRedirect({ originalUri });
  };

  useEffect(() => {
    if (!userKey) {
      console.error(
        'User visited the invitation page without a key! This shouldn not happen. Redirecting to Okta login.'
      );
      signInRedirect();
    }

    withShowApiErrorMiddleware(execute)(
      {
        oktaServiceShowRequestContract: { key: userKey },
      },
      {
        onComplete: data => {
          // Either redirect user to sign-in or activate page in Okta.
          if (data.activation_link) {
            const activationUrl = new URL(data.activation_link);
            activationUrl.searchParams.set('fromURI', originalUri);
            window.open(activationUrl.toString());
          } else {
            signInRedirect();
          }
        },
      }
    );
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <S.Container>
        <S.Content>
          <S.LogoContainer>
            <NpmLogo />
          </S.LogoContainer>
          <S.MainPadding>
            <Loader />
          </S.MainPadding>
        </S.Content>
      </S.Container>
    </ThemeProvider>
  );
};

export default InvitationPage;
