// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  UserApi,
  AdvisorFirmUserIndexErrorCodes,
  AdvisorFirmUserIndexErrorTypes,
  BrokerageFirmUserIndexErrorCodes,
  BrokerageFirmUserIndexErrorTypes,
  UserShowCurrentErrorCodes,
  UserShowCurrentErrorTypes,
  OktaUserActivateErrorCodes,
  LoginHistoryShowErrorCodes,
  LoginHistoryShowErrorTypes,
  OktaUserVerifyErrorCodes,
  LoginHistoryShowUserErrorCodes,
  LoginHistoryShowUserErrorTypes,
  UserCreateErrorCodes,
  UserUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new UserApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AdvisorFirmUserIndexMethod = UserApi['advisorFirmUserIndex'];
export type AdvisorFirmUserIndexResponseType = MethodResult<AdvisorFirmUserIndexMethod>;

export const useAdvisorFirmUserIndex = (
  variables: Parameters<AdvisorFirmUserIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorFirmUserIndexMethod,
    AdvisorFirmUserIndexErrorTypes
  >
) => {
  return useQuery<
    AdvisorFirmUserIndexMethod,
    AdvisorFirmUserIndexErrorTypes
  >(
    (...args) => api.advisorFirmUserIndex(...args),
    AdvisorFirmUserIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorFirmUserIndex'],
        ...apiQueryConfig.AdvisorFirmUserIndex,
        ...config?.queryConfig
      },
    },
    'AdvisorFirmUserIndex',
  );
};

export const useAdvisorFirmUserIndexInfinite = (
  variables: Parameters<AdvisorFirmUserIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AdvisorFirmUserIndexMethod,
    AdvisorFirmUserIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AdvisorFirmUserIndexMethod,
    AdvisorFirmUserIndexErrorTypes
  >(
    (...args) => api.advisorFirmUserIndex(...args),
    AdvisorFirmUserIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorFirmUserIndex', 'AdvisorFirmUserIndexInfinite'],
        ...apiQueryConfig.AdvisorFirmUserIndex,
        ...config?.queryConfig,
      },
    },
    'AdvisorFirmUserIndex',
  );
};

export const useAdvisorFirmUserIndexLazy = (baseOptions?: {
  variables?: Parameters<AdvisorFirmUserIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorFirmUserIndexMethod,
    AdvisorFirmUserIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AdvisorFirmUserIndexMethod,
    AdvisorFirmUserIndexErrorTypes
  >(
    (...args) => api.advisorFirmUserIndex(...args),
    AdvisorFirmUserIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AdvisorFirmUserIndex'],
        ...apiQueryConfig.AdvisorFirmUserIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AdvisorFirmUserIndex',
  );
};


export type BrokerageFirmUserIndexMethod = UserApi['brokerageFirmUserIndex'];
export type BrokerageFirmUserIndexResponseType = MethodResult<BrokerageFirmUserIndexMethod>;

export const useBrokerageFirmUserIndex = (
  variables: Parameters<BrokerageFirmUserIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmUserIndexMethod,
    BrokerageFirmUserIndexErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmUserIndexMethod,
    BrokerageFirmUserIndexErrorTypes
  >(
    (...args) => api.brokerageFirmUserIndex(...args),
    BrokerageFirmUserIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmUserIndex'],
        ...apiQueryConfig.BrokerageFirmUserIndex,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmUserIndex',
  );
};

export const useBrokerageFirmUserIndexInfinite = (
  variables: Parameters<BrokerageFirmUserIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmUserIndexMethod,
    BrokerageFirmUserIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmUserIndexMethod,
    BrokerageFirmUserIndexErrorTypes
  >(
    (...args) => api.brokerageFirmUserIndex(...args),
    BrokerageFirmUserIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmUserIndex', 'BrokerageFirmUserIndexInfinite'],
        ...apiQueryConfig.BrokerageFirmUserIndex,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmUserIndex',
  );
};

export const useBrokerageFirmUserIndexLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmUserIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmUserIndexMethod,
    BrokerageFirmUserIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmUserIndexMethod,
    BrokerageFirmUserIndexErrorTypes
  >(
    (...args) => api.brokerageFirmUserIndex(...args),
    BrokerageFirmUserIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmUserIndex'],
        ...apiQueryConfig.BrokerageFirmUserIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmUserIndex',
  );
};


export type UserShowCurrentMethod = UserApi['userShowCurrent'];
export type UserShowCurrentResponseType = MethodResult<UserShowCurrentMethod>;

export const useUserShowCurrent = (
  variables: Parameters<UserShowCurrentMethod>[0],
  config?: UseQueryConfig<
    UserShowCurrentMethod,
    UserShowCurrentErrorTypes
  >
) => {
  return useQuery<
    UserShowCurrentMethod,
    UserShowCurrentErrorTypes
  >(
    (...args) => api.userShowCurrent(...args),
    UserShowCurrentErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserShowCurrent'],
        ...apiQueryConfig.UserShowCurrent,
        ...config?.queryConfig
      },
    },
    'UserShowCurrent',
  );
};

export const useUserShowCurrentInfinite = (
  variables: Parameters<UserShowCurrentMethod>[0],
  config?: UseInfiniteQueryConfig<
    UserShowCurrentMethod,
    UserShowCurrentErrorTypes
  >
) => {
  return useInfiniteQuery<
    UserShowCurrentMethod,
    UserShowCurrentErrorTypes
  >(
    (...args) => api.userShowCurrent(...args),
    UserShowCurrentErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['UserShowCurrent', 'UserShowCurrentInfinite'],
        ...apiQueryConfig.UserShowCurrent,
        ...config?.queryConfig,
      },
    },
    'UserShowCurrent',
  );
};

export const useUserShowCurrentLazy = (baseOptions?: {
  variables?: Parameters<UserShowCurrentMethod>[0],
  config?: UseQueryConfig<
    UserShowCurrentMethod,
    UserShowCurrentErrorTypes
  >
}) => {
  return useLazyQuery<
    UserShowCurrentMethod,
    UserShowCurrentErrorTypes
  >(
    (...args) => api.userShowCurrent(...args),
    UserShowCurrentErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['UserShowCurrent'],
        ...apiQueryConfig.UserShowCurrent,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'UserShowCurrent',
  );
};


export type OktaUserActivateMethod = UserApi['oktaUserActivate'];

export const useOktaUserActivate = () => {
  return useMutation<OktaUserActivateMethod>(
    (...args) => api.oktaUserActivate(...args),
    OktaUserActivateErrorCodes,
    'OktaUserActivate',
    '/api/user/activate',
    'post'
  );
};

export type LoginHistoryShowMethod = UserApi['loginHistoryShow'];
export type LoginHistoryShowResponseType = MethodResult<LoginHistoryShowMethod>;

export const useLoginHistoryShow = (
  variables: Parameters<LoginHistoryShowMethod>[0],
  config?: UseQueryConfig<
    LoginHistoryShowMethod,
    LoginHistoryShowErrorTypes
  >
) => {
  return useQuery<
    LoginHistoryShowMethod,
    LoginHistoryShowErrorTypes
  >(
    (...args) => api.loginHistoryShow(...args),
    LoginHistoryShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['LoginHistoryShow'],
        ...apiQueryConfig.LoginHistoryShow,
        ...config?.queryConfig
      },
    },
    'LoginHistoryShow',
  );
};

export const useLoginHistoryShowInfinite = (
  variables: Parameters<LoginHistoryShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    LoginHistoryShowMethod,
    LoginHistoryShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    LoginHistoryShowMethod,
    LoginHistoryShowErrorTypes
  >(
    (...args) => api.loginHistoryShow(...args),
    LoginHistoryShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['LoginHistoryShow', 'LoginHistoryShowInfinite'],
        ...apiQueryConfig.LoginHistoryShow,
        ...config?.queryConfig,
      },
    },
    'LoginHistoryShow',
  );
};

export const useLoginHistoryShowLazy = (baseOptions?: {
  variables?: Parameters<LoginHistoryShowMethod>[0],
  config?: UseQueryConfig<
    LoginHistoryShowMethod,
    LoginHistoryShowErrorTypes
  >
}) => {
  return useLazyQuery<
    LoginHistoryShowMethod,
    LoginHistoryShowErrorTypes
  >(
    (...args) => api.loginHistoryShow(...args),
    LoginHistoryShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['LoginHistoryShow'],
        ...apiQueryConfig.LoginHistoryShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'LoginHistoryShow',
  );
};


export type OktaUserVerifyMethod = UserApi['oktaUserVerify'];

export const useOktaUserVerify = () => {
  return useMutation<OktaUserVerifyMethod>(
    (...args) => api.oktaUserVerify(...args),
    OktaUserVerifyErrorCodes,
    'OktaUserVerify',
    '/api/user/verify',
    'post'
  );
};

export type LoginHistoryShowUserMethod = UserApi['loginHistoryShowUser'];
export type LoginHistoryShowUserResponseType = MethodResult<LoginHistoryShowUserMethod>;

export const useLoginHistoryShowUser = (
  variables: Parameters<LoginHistoryShowUserMethod>[0],
  config?: UseQueryConfig<
    LoginHistoryShowUserMethod,
    LoginHistoryShowUserErrorTypes
  >
) => {
  return useQuery<
    LoginHistoryShowUserMethod,
    LoginHistoryShowUserErrorTypes
  >(
    (...args) => api.loginHistoryShowUser(...args),
    LoginHistoryShowUserErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['LoginHistoryShowUser'],
        ...apiQueryConfig.LoginHistoryShowUser,
        ...config?.queryConfig
      },
    },
    'LoginHistoryShowUser',
  );
};

export const useLoginHistoryShowUserInfinite = (
  variables: Parameters<LoginHistoryShowUserMethod>[0],
  config?: UseInfiniteQueryConfig<
    LoginHistoryShowUserMethod,
    LoginHistoryShowUserErrorTypes
  >
) => {
  return useInfiniteQuery<
    LoginHistoryShowUserMethod,
    LoginHistoryShowUserErrorTypes
  >(
    (...args) => api.loginHistoryShowUser(...args),
    LoginHistoryShowUserErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['LoginHistoryShowUser', 'LoginHistoryShowUserInfinite'],
        ...apiQueryConfig.LoginHistoryShowUser,
        ...config?.queryConfig,
      },
    },
    'LoginHistoryShowUser',
  );
};

export const useLoginHistoryShowUserLazy = (baseOptions?: {
  variables?: Parameters<LoginHistoryShowUserMethod>[0],
  config?: UseQueryConfig<
    LoginHistoryShowUserMethod,
    LoginHistoryShowUserErrorTypes
  >
}) => {
  return useLazyQuery<
    LoginHistoryShowUserMethod,
    LoginHistoryShowUserErrorTypes
  >(
    (...args) => api.loginHistoryShowUser(...args),
    LoginHistoryShowUserErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['LoginHistoryShowUser'],
        ...apiQueryConfig.LoginHistoryShowUser,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'LoginHistoryShowUser',
  );
};


export type UserCreateMethod = UserApi['userCreate'];

export const useUserCreate = () => {
  return useMutation<UserCreateMethod>(
    (...args) => api.userCreate(...args),
    UserCreateErrorCodes,
    'UserCreate',
    '/api/users',
    'post'
  );
};

export type UserUpdateMethod = UserApi['userUpdate'];

export const useUserUpdate = () => {
  return useMutation<UserUpdateMethod>(
    (...args) => api.userUpdate(...args),
    UserUpdateErrorCodes,
    'UserUpdate',
    '/api/users/{id}',
    'put'
  );
};
