import React, { type ReactComponentElement } from 'react';

import { notification } from 'antd';
import { type ArgsProps, type IconType } from 'antd/lib/notification';

import { CypressDataIds } from '../../../constants';
import { breakpoints } from '../../../themes';
import { Icon } from '../Icon';

type NotificationOptions = Omit<ArgsProps, 'placement'> & {
  containerId?: string;
};

const DEFAULT_CONFIG = {
  duration: 4,
  className: 'ant-notification-regular',
  closeIcon: <Icon name="x-close" size={12} />,
} as const;

const Icons: Record<IconType, ReactComponentElement<typeof Icon>> = {
  success: <Icon name="check" size={18} />,
  info: <Icon name="info-circle" size={18} />,
  error: <Icon name="warning" size={18} />,
  warning: <Icon name="info-circle" size={18} />,
};

const open = ({
  type = 'info',
  containerId,
  icon,
  ...rest
}: NotificationOptions) => {
  const positionConfig =
    /* This check is not optimal, but we can't use hooks here...
       I don't think the user will mess with window size in the 4 seconds that a notification is visible. */
    window.innerWidth <= breakpoints.tablet
      ? ({
          placement: 'top',
          top: 56,
        } as const)
      : ({
          placement: 'bottom',
        } as const);

  notification.open({
    ...DEFAULT_CONFIG,
    ...positionConfig,
    getContainer: () =>
      (containerId ? document.getElementById(containerId) : null) ??
      document.body,
    icon: icon ?? Icons[type],
    type,
    ...rest,
  });
};

const success = (config: NotificationOptions) => {
  open({
    type: 'success',
    props: { 'data-testid': CypressDataIds.Components.Notification.Success },
    ...config,
  });
};

const error = (config: NotificationOptions) => {
  open({
    type: 'error',
    props: { 'data-testid': CypressDataIds.Components.Notification.Error },
    ...config,
  });
};

const destroy = notification.destroy;

const close = notification.close;

export const Notification = {
  open,
  close,
  success,
  error,
  destroy,
};
