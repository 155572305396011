// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FoldersApi,
  FolderIndexErrorCodes,
  FolderIndexErrorTypes,
  FolderCreateErrorCodes,
  FolderShowErrorCodes,
  FolderShowErrorTypes,
  FolderUpdateErrorCodes,
  FolderDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FoldersApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FolderIndexMethod = FoldersApi['folderIndex'];
export type FolderIndexResponseType = MethodResult<FolderIndexMethod>;

export const useFolderIndex = (
  variables: Parameters<FolderIndexMethod>[0],
  config?: UseQueryConfig<
    FolderIndexMethod,
    FolderIndexErrorTypes
  >
) => {
  return useQuery<
    FolderIndexMethod,
    FolderIndexErrorTypes
  >(
    (...args) => api.folderIndex(...args),
    FolderIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderIndex'],
        ...apiQueryConfig.FolderIndex,
        ...config?.queryConfig
      },
    },
    'FolderIndex',
  );
};

export const useFolderIndexInfinite = (
  variables: Parameters<FolderIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    FolderIndexMethod,
    FolderIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    FolderIndexMethod,
    FolderIndexErrorTypes
  >(
    (...args) => api.folderIndex(...args),
    FolderIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderIndex', 'FolderIndexInfinite'],
        ...apiQueryConfig.FolderIndex,
        ...config?.queryConfig,
      },
    },
    'FolderIndex',
  );
};

export const useFolderIndexLazy = (baseOptions?: {
  variables?: Parameters<FolderIndexMethod>[0],
  config?: UseQueryConfig<
    FolderIndexMethod,
    FolderIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    FolderIndexMethod,
    FolderIndexErrorTypes
  >(
    (...args) => api.folderIndex(...args),
    FolderIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FolderIndex'],
        ...apiQueryConfig.FolderIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FolderIndex',
  );
};


export type FolderCreateMethod = FoldersApi['folderCreate'];

export const useFolderCreate = () => {
  return useMutation<FolderCreateMethod>(
    (...args) => api.folderCreate(...args),
    FolderCreateErrorCodes,
    'FolderCreate',
    '/api/folders',
    'post'
  );
};

export type FolderShowMethod = FoldersApi['folderShow'];
export type FolderShowResponseType = MethodResult<FolderShowMethod>;

export const useFolderShow = (
  variables: Parameters<FolderShowMethod>[0],
  config?: UseQueryConfig<
    FolderShowMethod,
    FolderShowErrorTypes
  >
) => {
  return useQuery<
    FolderShowMethod,
    FolderShowErrorTypes
  >(
    (...args) => api.folderShow(...args),
    FolderShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderShow'],
        ...apiQueryConfig.FolderShow,
        ...config?.queryConfig
      },
    },
    'FolderShow',
  );
};

export const useFolderShowInfinite = (
  variables: Parameters<FolderShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    FolderShowMethod,
    FolderShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    FolderShowMethod,
    FolderShowErrorTypes
  >(
    (...args) => api.folderShow(...args),
    FolderShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderShow', 'FolderShowInfinite'],
        ...apiQueryConfig.FolderShow,
        ...config?.queryConfig,
      },
    },
    'FolderShow',
  );
};

export const useFolderShowLazy = (baseOptions?: {
  variables?: Parameters<FolderShowMethod>[0],
  config?: UseQueryConfig<
    FolderShowMethod,
    FolderShowErrorTypes
  >
}) => {
  return useLazyQuery<
    FolderShowMethod,
    FolderShowErrorTypes
  >(
    (...args) => api.folderShow(...args),
    FolderShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FolderShow'],
        ...apiQueryConfig.FolderShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FolderShow',
  );
};


export type FolderUpdateMethod = FoldersApi['folderUpdate'];

export const useFolderUpdate = () => {
  return useMutation<FolderUpdateMethod>(
    (...args) => api.folderUpdate(...args),
    FolderUpdateErrorCodes,
    'FolderUpdate',
    '/api/folders/{id}',
    'put'
  );
};

export type FolderDestroyMethod = FoldersApi['folderDestroy'];

export const useFolderDestroy = () => {
  return useMutation<FolderDestroyMethod>(
    (...args) => api.folderDestroy(...args),
    FolderDestroyErrorCodes,
    'FolderDestroy',
    '/api/folders/{id}',
    'delete'
  );
};
