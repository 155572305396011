import { Flex } from '../../atoms/common';
import { Text } from '../../atoms/Typography';

import { InstructionsStyles as S } from './Instructions.styles';

type Props = {
  instructions: string[];
};

export const Instructions = ({ instructions }: Props) => (
  <Flex direction="column" gap="md">
    {instructions.map((instruction, index) => (
      <Flex key={index} align={'baseline'} gap="md">
        <S.OrderLabel>
          <Text size={'sm'} color={'info'} weight={'bold'}>
            {index + 1}
          </Text>
        </S.OrderLabel>
        <Text size={'sm'} colorVariant={'primary'}>
          {instruction}
        </Text>
      </Flex>
    ))}
  </Flex>
);
