import styled from 'styled-components';

export const SvgIcon = styled.svg<{
  $strokeWidth: number;
  $color?: string;
  $keepOriginalColor?: boolean;
}>`
  path,
  rect,
  circle {
    vector-effect: non-scaling-stroke;
    stroke-width: ${({ $strokeWidth }) => $strokeWidth};
  }
  path[stroke],
  rect[stroke],
  circle[stroke] {
    stroke: ${({ $color, $keepOriginalColor }) =>
      !$keepOriginalColor && ($color || 'currentColor')};
  }
  path[fill],
  rect[fill],
  circle[fill] {
    fill: ${({ $color, $keepOriginalColor }) =>
      !$keepOriginalColor && ($color || 'currentColor')};
  }
`;
