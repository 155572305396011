import { useMemo } from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';

type Props = {
  stockClassValuation: number;
  impliedValuation?: number;
  isLoading?: boolean;
};

export const ImpliedValuation = ({
  stockClassValuation,
  impliedValuation,
  isLoading,
}: Props) => {
  const valuationDifferencePercentage = useMemo(() => {
    if (!stockClassValuation || !impliedValuation) return null;

    return Math.round(100 - (impliedValuation / stockClassValuation) * 100);
  }, [stockClassValuation, impliedValuation]);

  if (!stockClassValuation && !isLoading) return null;

  return (
    <Margin top="xs">
      {isLoading ? (
        <Skeleton.Input size="small" />
      ) : (
        <Flex align="center" gap="xs">
          <Text size="sm">Implied Valuation</Text>
          <TooltipInfoIcon title="This displays the implied valuation or PPS of your order and indicates the difference vs. the estimated Last Preferred Price Per Share." />
          <Text.Price
            value={
              impliedValuation != null ? impliedValuation : stockClassValuation
            }
            currency="USD"
            size="sm"
            color="info"
            colorVariant="primary"
            currencyColor="info"
            currencyColorVariant="primary"
            compact
          />
          {valuationDifferencePercentage !== 0 &&
            valuationDifferencePercentage && (
              <Text
                size="sm"
                color={valuationDifferencePercentage < 0 ? 'success' : 'error'}
                colorVariant="primary"
                as="div"
              >
                <Flex align="center">
                  <Icon
                    name={
                      valuationDifferencePercentage < 0
                        ? 'arrow-narrow-45-up'
                        : 'arrow-narrow-45-down'
                    }
                    size="sm"
                  />
                  <span>{Math.abs(valuationDifferencePercentage)}%</span>
                </Flex>
              </Text>
            )}
        </Flex>
      )}
    </Margin>
  );
};
