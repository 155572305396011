import React from 'react';

import * as S from './OptInFooter.styles';

interface Props {
  id: string;
  onClose: () => void;
  disabled: boolean;
}

export const OptInFooter = ({ id, onClose, disabled }: Props) => {
  const buttonsContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef => {
        if (containerRef && buttonsContainerRef) {
          const buttonsContainerHeight =
            buttonsContainerRef.current?.clientHeight;

          containerRef.style.minHeight = `${buttonsContainerHeight}px`;
        }
      }}
    >
      <S.ButtonsContainer ref={buttonsContainerRef}>
        <S.BackButton variant="text" onClick={onClose} blockOnMobile>
          Back
        </S.BackButton>
        <S.SubmitButton
          form={id}
          htmlType="submit"
          blockOnMobile
          data-dd-action-name={'Submit OptIn'}
          disabled={disabled}
        >
          Access SecondMarket
        </S.SubmitButton>
      </S.ButtonsContainer>
    </div>
  );
};
