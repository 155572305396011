// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PartnerIssuerMapApi,
  PartnerIssuerMapIndexErrorCodes,
  PartnerIssuerMapIndexErrorTypes,
  PartnerIssuerMapUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PartnerIssuerMapApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PartnerIssuerMapIndexMethod = PartnerIssuerMapApi['partnerIssuerMapIndex'];
export type PartnerIssuerMapIndexResponseType = MethodResult<PartnerIssuerMapIndexMethod>;

export const usePartnerIssuerMapIndex = (
  variables: Parameters<PartnerIssuerMapIndexMethod>[0],
  config?: UseQueryConfig<
    PartnerIssuerMapIndexMethod,
    PartnerIssuerMapIndexErrorTypes
  >
) => {
  return useQuery<
    PartnerIssuerMapIndexMethod,
    PartnerIssuerMapIndexErrorTypes
  >(
    (...args) => api.partnerIssuerMapIndex(...args),
    PartnerIssuerMapIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PartnerIssuerMapIndex'],
        ...apiQueryConfig.PartnerIssuerMapIndex,
        ...config?.queryConfig
      },
    },
    'PartnerIssuerMapIndex',
  );
};

export const usePartnerIssuerMapIndexInfinite = (
  variables: Parameters<PartnerIssuerMapIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    PartnerIssuerMapIndexMethod,
    PartnerIssuerMapIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    PartnerIssuerMapIndexMethod,
    PartnerIssuerMapIndexErrorTypes
  >(
    (...args) => api.partnerIssuerMapIndex(...args),
    PartnerIssuerMapIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PartnerIssuerMapIndex', 'PartnerIssuerMapIndexInfinite'],
        ...apiQueryConfig.PartnerIssuerMapIndex,
        ...config?.queryConfig,
      },
    },
    'PartnerIssuerMapIndex',
  );
};

export const usePartnerIssuerMapIndexLazy = (baseOptions?: {
  variables?: Parameters<PartnerIssuerMapIndexMethod>[0],
  config?: UseQueryConfig<
    PartnerIssuerMapIndexMethod,
    PartnerIssuerMapIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    PartnerIssuerMapIndexMethod,
    PartnerIssuerMapIndexErrorTypes
  >(
    (...args) => api.partnerIssuerMapIndex(...args),
    PartnerIssuerMapIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PartnerIssuerMapIndex'],
        ...apiQueryConfig.PartnerIssuerMapIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PartnerIssuerMapIndex',
  );
};


export type PartnerIssuerMapUpdateMethod = PartnerIssuerMapApi['partnerIssuerMapUpdate'];

export const usePartnerIssuerMapUpdate = () => {
  return useMutation<PartnerIssuerMapUpdateMethod>(
    (...args) => api.partnerIssuerMapUpdate(...args),
    PartnerIssuerMapUpdateErrorCodes,
    'PartnerIssuerMapUpdate',
    '/api/admin/partner/partner_issuer_maps/{id}',
    'patch'
  );
};
