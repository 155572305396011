import { Codebooks } from './codebooks.types';

const Items = {
  event: 'event',
  agreement: 'agreement',
  event_folder: 'event_folder',
  match: 'match',
  tax_document: 'tax_document',
  transaction_confirmation: 'transaction_confirmation',
} as const;

export const CbResourceType = {
  code: Codebooks.RESOURCE_TYPE,
  items: Items,
};
