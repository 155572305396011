import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

const MIN_HEIGHT_ROW = 56;
const MIN_HEIGHT_ROW_MOBILE = 52;

export const Card = styled(CardBase)`
  & > * > .card-content {
    padding: 0;

    & > *:not(:last-child) {
      border-bottom: solid 1px
        ${({ theme }) => theme.color.general.borders.primary};
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm}px
    ${({ theme }) => theme.spacing.md}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.sm}px ${theme.spacing.lg / 2}px;
  `}
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${MIN_HEIGHT_ROW}px;
  padding: ${({ theme }) => theme.spacing.sm}px
    ${({ theme }) => theme.spacing.md}px;

  &:hover {
    background-color: ${({ theme }) => theme.color.general.layout.two};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.general.layout.zero};
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    min-height: ${MIN_HEIGHT_ROW_MOBILE}px;
    padding: ${theme.spacing.xs}px ${theme.spacing.lg / 2}px;
  `}
`;

export const FolderRow = styled(ItemRow)`
  color: ${({ theme }) => theme.color.general.typography.secondary};
  cursor: pointer;

  button:not(.ant-dropdown-trigger) > svg {
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }
`;

export const FileRow = styled(ItemRow)`
  color: ${({ theme }) => theme.color.info.typography.primary};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  & > p {
    text-align: start;
  }
`;

export const IconContainer = styled.div`
  overflow: visible;
  display: flex;
  position: relative;
  margin-right: ${({ theme }) => theme.spacing.md}px;
  ${({ theme }) => useBreakpoint(theme).mobile`
      margin-right: ${theme.spacing.sm}px;
    `}
`;

export const IconBadge = styled.div`
  position: absolute;
  bottom: -2px;
  left: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.color.info.typography.primary};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
`;

export const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing.sm}px !important;
  }
`;

export const ProcessingIcon = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;

  svg {
    animation: spin 1s linear infinite;
  }
`;
