// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  NdaUserApi,
  NdaUserShowErrorCodes,
  NdaUserShowErrorTypes,
  NdaUserIndexErrorCodes,
  NdaUserIndexErrorTypes,
  NdaUserCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new NdaUserApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type NdaUserShowMethod = NdaUserApi['ndaUserShow'];
export type NdaUserShowResponseType = MethodResult<NdaUserShowMethod>;

export const useNdaUserShow = (
  variables: Parameters<NdaUserShowMethod>[0],
  config?: UseQueryConfig<
    NdaUserShowMethod,
    NdaUserShowErrorTypes
  >
) => {
  return useQuery<
    NdaUserShowMethod,
    NdaUserShowErrorTypes
  >(
    (...args) => api.ndaUserShow(...args),
    NdaUserShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NdaUserShow'],
        ...apiQueryConfig.NdaUserShow,
        ...config?.queryConfig
      },
    },
    'NdaUserShow',
  );
};

export const useNdaUserShowInfinite = (
  variables: Parameters<NdaUserShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    NdaUserShowMethod,
    NdaUserShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    NdaUserShowMethod,
    NdaUserShowErrorTypes
  >(
    (...args) => api.ndaUserShow(...args),
    NdaUserShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NdaUserShow', 'NdaUserShowInfinite'],
        ...apiQueryConfig.NdaUserShow,
        ...config?.queryConfig,
      },
    },
    'NdaUserShow',
  );
};

export const useNdaUserShowLazy = (baseOptions?: {
  variables?: Parameters<NdaUserShowMethod>[0],
  config?: UseQueryConfig<
    NdaUserShowMethod,
    NdaUserShowErrorTypes
  >
}) => {
  return useLazyQuery<
    NdaUserShowMethod,
    NdaUserShowErrorTypes
  >(
    (...args) => api.ndaUserShow(...args),
    NdaUserShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NdaUserShow'],
        ...apiQueryConfig.NdaUserShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NdaUserShow',
  );
};


export type NdaUserIndexMethod = NdaUserApi['ndaUserIndex'];
export type NdaUserIndexResponseType = MethodResult<NdaUserIndexMethod>;

export const useNdaUserIndex = (
  variables: Parameters<NdaUserIndexMethod>[0],
  config?: UseQueryConfig<
    NdaUserIndexMethod,
    NdaUserIndexErrorTypes
  >
) => {
  return useQuery<
    NdaUserIndexMethod,
    NdaUserIndexErrorTypes
  >(
    (...args) => api.ndaUserIndex(...args),
    NdaUserIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NdaUserIndex'],
        ...apiQueryConfig.NdaUserIndex,
        ...config?.queryConfig
      },
    },
    'NdaUserIndex',
  );
};

export const useNdaUserIndexInfinite = (
  variables: Parameters<NdaUserIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    NdaUserIndexMethod,
    NdaUserIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    NdaUserIndexMethod,
    NdaUserIndexErrorTypes
  >(
    (...args) => api.ndaUserIndex(...args),
    NdaUserIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NdaUserIndex', 'NdaUserIndexInfinite'],
        ...apiQueryConfig.NdaUserIndex,
        ...config?.queryConfig,
      },
    },
    'NdaUserIndex',
  );
};

export const useNdaUserIndexLazy = (baseOptions?: {
  variables?: Parameters<NdaUserIndexMethod>[0],
  config?: UseQueryConfig<
    NdaUserIndexMethod,
    NdaUserIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    NdaUserIndexMethod,
    NdaUserIndexErrorTypes
  >(
    (...args) => api.ndaUserIndex(...args),
    NdaUserIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NdaUserIndex'],
        ...apiQueryConfig.NdaUserIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NdaUserIndex',
  );
};


export type NdaUserCreateMethod = NdaUserApi['ndaUserCreate'];

export const useNdaUserCreate = () => {
  return useMutation<NdaUserCreateMethod>(
    (...args) => api.ndaUserCreate(...args),
    NdaUserCreateErrorCodes,
    'NdaUserCreate',
    '/api/nda-users',
    'post'
  );
};
