import { Codebooks } from './codebooks.types';

const Items = {
  AgreementDocument: 'AgreementDocument',
  Organization: 'Organization',
  BackgroundCheck: 'BackgroundCheck',
  Person: 'Person',
  PrivateCompany: 'PrivateCompany',
  Event: 'Event',
  Account: 'Account',
  AuthorizedSigner: 'AuthorizedSigner',
  Holding: 'Holding',
  PrimaryCapitalRaise: 'PrimaryCapitalRaise',
  Portal: 'Portal',
  TenderOffer: 'TenderOffer',
  DutchAuctionSell: 'DutchAuctionSell',
  DutchAuctionBuy: 'DutchAuctionBuy',
  BeneficialOwner: 'BeneficialOwner',
  MergerAndAcquisition: 'MergerAndAcquisition',
  InformationalEvent: 'InformationalEvent',
  Submission: 'Submission',
  OrderItem: 'OrderItem',
  AnonymousAccount: 'AnonymousAccount',
  BrokerageFirm: 'BrokerageFirm',
  NdaUser: 'NdaUser',
  OrganizationAccount: 'OrganizationAccount',
  PersonAccount: 'PersonAccount',
  PrivateCompanyIssuerEntity: 'PrivateCompanyIssuerEntity',
  StockHolding: 'StockHolding',
  Match: 'Match',
  MatchActivity: 'MatchActivity',
} as const;

export const CbOwnerType = {
  code: Codebooks.OWNER_TYPE,
  items: Items,
};
