import React, { useEffect, useState } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbNotificationCategory } from '@npm/data-access';
import { useTheme } from 'styled-components';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { UrlParams } from '../../../auth/user/context';

import { MarkAllReadButton } from './components/MarkAllReadButton/MarkAllReadButton';
import { NotificationCategorySelect } from './components/NotificationCategorySelect/NotificationCategorySelect';
import { NotificationList } from './components/NotificationList/NotificationList';
import { NotificationSettingsModal } from './components/NotificationSettingsModal/NotificationSettingsModal';

import * as S from './NotificationDrawer.styles';
import { NoPaddingButton } from './NotificationDrawer.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NotificationDrawer = ({ isOpen, onClose }: Props) => {
  const [settingsDefaultOpen, setSettingsDefaultOpen] = useQueryParam(
    UrlParams.NOTIFICATION_SETTINGS_OPEN,
    BooleanParam
  );
  const [isSettingsModalOpen, setSettingsModalOpen] =
    useState(settingsDefaultOpen);
  const [notificationCategory, setNotificationCategory] = useState<
    string | undefined
  >(CbNotificationCategory.items.marketplace);

  const theme = useTheme();
  const { isMobile, isSmTablet, isTablet } = useBreakpoints();

  const hasFloatingMenuButton = isMobile || isSmTablet || isTablet;

  useEffect(() => {
    if (settingsDefaultOpen) {
      setSettingsDefaultOpen(false);
    }
  }, [settingsDefaultOpen, setSettingsDefaultOpen]);

  return (
    <>
      <S.Drawer
        open={isOpen}
        onClose={onClose}
        isFullHeight={true}
        placement={!hasFloatingMenuButton ? 'left' : 'right'}
        zIndex={theme.zIndex.aboveAll}
        destroyOnClose={true}
        title={
          <Flex align={'center'} justify="space-between" style={{ flex: 1 }}>
            <Flex align={'center'} justify="flex-start" gap={'sm'}>
              {!isMobile && <Text>Notifications</Text>}
              <MarkAllReadButton />
            </Flex>
            <Flex align={'center'} justify="flex-end" gap={'xs'}>
              <NotificationCategorySelect onChange={setNotificationCategory} />
              <NoPaddingButton
                variant="text"
                icon={<Icon name="settings" />}
                onClick={() => setSettingsModalOpen(true)}
              />
            </Flex>
          </Flex>
        }
      >
        <NotificationList notificationCategory={notificationCategory} />
      </S.Drawer>
      <NotificationSettingsModal
        visible={isSettingsModalOpen}
        setVisible={setSettingsModalOpen}
      />
    </>
  );
};
