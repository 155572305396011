import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';
import { Result as ResultAntd } from 'antd';
import styled from 'styled-components';

export const Result = styled(ResultAntd)`
  width: 100vw;
  height: 100vh;
  ${centerFlex};
  flex-direction: column;

  .ant-result-title {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }

  .ant-result-subtitle {
    color: ${({ theme }) => theme.color.general.typography.primary};
  }

  .ant-result-extra {
    ${centerFlex}
    margin-top: ${({ theme }) => theme.spacing.md}px;
  }

  .ant-result-icon {
    ${centerFlex};
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }

  .ant-result-extra {
    a,
    button {
      width: auto;

      button {
        width: auto;
      }
    }

    ${({ theme }) => useBreakpoint(theme).mobile`
      width: 100%;
    `}
  }
`;
