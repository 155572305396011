import styled from 'styled-components';

export const PriceCardWrapper = styled.div<{ $highlighted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.sm}px;
  padding: ${({ theme }) => theme.spacing.md}px;

  border: 1px solid
    ${({ theme, $highlighted }) =>
      $highlighted
        ? theme.color.info.borders.primary
        : theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background: ${({ theme, $highlighted }) =>
    $highlighted
      ? theme.color.info.backgrounds.secondaryDefault
      : theme.color.general.layout.two};
`;
