import { type CbLabelComponent } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const items = {
  processing: 'processing',
  completed: 'completed',
  cancelled: 'cancelled',
  failed: 'failed',
} as const;

const labelMap = {
  processing: 'warning',
  completed: 'success',
  cancelled: 'error',
  failed: 'error',
};

const iconMap = {
  processing: 'clock',
  completed: 'check',
  cancelled: 'warning',
  failed: 'warning',
};

export const CbFlowScreenerStatus = {
  code: Codebooks.FLOW_SCREENER_STATUS,
  items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined
  ) =>
    Component({
      variant: labelMap[codebookItem?.code ?? 'none'],
      label: codebookItem?.name,
      iconProps: {
        name: iconMap[codebookItem?.code ?? 'none'],
      },
    }),
};
