import React from 'react';

import type { IconNames } from '@npm/utils';

import { Flex } from '../../atoms/common';
import { Icon } from '../../atoms/Icon';
import { Heading } from '../../atoms/Typography';

import * as S from './InfoMessage.styles';

export type InfoAlertProps = {
  iconName: IconNames;
  heading: string | React.ReactNode;
  description?: React.ReactNode;
  type: 'info' | 'success' | 'warning' | 'error';
  style?: React.CSSProperties;
};

export const InfoMessage: React.FC<InfoAlertProps> = ({
  iconName,
  heading,
  description,
  type,
  style,
}) => {
  return (
    <S.Message $type={type} style={style}>
      <Flex align={'center'} gap={'sm'}>
        <Icon name={iconName} size={'md'} />
        {typeof heading === 'string' ? (
          <Heading variant="h1">{heading}</Heading>
        ) : (
          heading
        )}
      </Flex>
      {description}
    </S.Message>
  );
};
