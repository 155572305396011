// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EntityAccountAccreditationApi,
  EntityAccountAccreditationShowErrorCodes,
  EntityAccountAccreditationShowErrorTypes,
  EntityAccountAccreditationCreateErrorCodes,
  EntityAccountAccreditationShowByIdErrorCodes,
  EntityAccountAccreditationShowByIdErrorTypes,
  EntityAccountAccreditationUpdateErrorCodes,
  EntityAccountAccreditationDeleteErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EntityAccountAccreditationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EntityAccountAccreditationShowMethod = EntityAccountAccreditationApi['entityAccountAccreditationShow'];
export type EntityAccountAccreditationShowResponseType = MethodResult<EntityAccountAccreditationShowMethod>;

export const useEntityAccountAccreditationShow = (
  variables: Parameters<EntityAccountAccreditationShowMethod>[0],
  config?: UseQueryConfig<
    EntityAccountAccreditationShowMethod,
    EntityAccountAccreditationShowErrorTypes
  >
) => {
  return useQuery<
    EntityAccountAccreditationShowMethod,
    EntityAccountAccreditationShowErrorTypes
  >(
    (...args) => api.entityAccountAccreditationShow(...args),
    EntityAccountAccreditationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EntityAccountAccreditationShow'],
        ...apiQueryConfig.EntityAccountAccreditationShow,
        ...config?.queryConfig
      },
    },
    'EntityAccountAccreditationShow',
  );
};

export const useEntityAccountAccreditationShowInfinite = (
  variables: Parameters<EntityAccountAccreditationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    EntityAccountAccreditationShowMethod,
    EntityAccountAccreditationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    EntityAccountAccreditationShowMethod,
    EntityAccountAccreditationShowErrorTypes
  >(
    (...args) => api.entityAccountAccreditationShow(...args),
    EntityAccountAccreditationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EntityAccountAccreditationShow', 'EntityAccountAccreditationShowInfinite'],
        ...apiQueryConfig.EntityAccountAccreditationShow,
        ...config?.queryConfig,
      },
    },
    'EntityAccountAccreditationShow',
  );
};

export const useEntityAccountAccreditationShowLazy = (baseOptions?: {
  variables?: Parameters<EntityAccountAccreditationShowMethod>[0],
  config?: UseQueryConfig<
    EntityAccountAccreditationShowMethod,
    EntityAccountAccreditationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    EntityAccountAccreditationShowMethod,
    EntityAccountAccreditationShowErrorTypes
  >(
    (...args) => api.entityAccountAccreditationShow(...args),
    EntityAccountAccreditationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EntityAccountAccreditationShow'],
        ...apiQueryConfig.EntityAccountAccreditationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EntityAccountAccreditationShow',
  );
};


export type EntityAccountAccreditationCreateMethod = EntityAccountAccreditationApi['entityAccountAccreditationCreate'];

export const useEntityAccountAccreditationCreate = () => {
  return useMutation<EntityAccountAccreditationCreateMethod>(
    (...args) => api.entityAccountAccreditationCreate(...args),
    EntityAccountAccreditationCreateErrorCodes,
    'EntityAccountAccreditationCreate',
    '/api/accounts/{account_id}/accreditation',
    'post'
  );
};

export type EntityAccountAccreditationShowByIdMethod = EntityAccountAccreditationApi['entityAccountAccreditationShowById'];
export type EntityAccountAccreditationShowByIdResponseType = MethodResult<EntityAccountAccreditationShowByIdMethod>;

export const useEntityAccountAccreditationShowById = (
  variables: Parameters<EntityAccountAccreditationShowByIdMethod>[0],
  config?: UseQueryConfig<
    EntityAccountAccreditationShowByIdMethod,
    EntityAccountAccreditationShowByIdErrorTypes
  >
) => {
  return useQuery<
    EntityAccountAccreditationShowByIdMethod,
    EntityAccountAccreditationShowByIdErrorTypes
  >(
    (...args) => api.entityAccountAccreditationShowById(...args),
    EntityAccountAccreditationShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EntityAccountAccreditationShowById'],
        ...apiQueryConfig.EntityAccountAccreditationShowById,
        ...config?.queryConfig
      },
    },
    'EntityAccountAccreditationShowById',
  );
};

export const useEntityAccountAccreditationShowByIdInfinite = (
  variables: Parameters<EntityAccountAccreditationShowByIdMethod>[0],
  config?: UseInfiniteQueryConfig<
    EntityAccountAccreditationShowByIdMethod,
    EntityAccountAccreditationShowByIdErrorTypes
  >
) => {
  return useInfiniteQuery<
    EntityAccountAccreditationShowByIdMethod,
    EntityAccountAccreditationShowByIdErrorTypes
  >(
    (...args) => api.entityAccountAccreditationShowById(...args),
    EntityAccountAccreditationShowByIdErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EntityAccountAccreditationShowById', 'EntityAccountAccreditationShowByIdInfinite'],
        ...apiQueryConfig.EntityAccountAccreditationShowById,
        ...config?.queryConfig,
      },
    },
    'EntityAccountAccreditationShowById',
  );
};

export const useEntityAccountAccreditationShowByIdLazy = (baseOptions?: {
  variables?: Parameters<EntityAccountAccreditationShowByIdMethod>[0],
  config?: UseQueryConfig<
    EntityAccountAccreditationShowByIdMethod,
    EntityAccountAccreditationShowByIdErrorTypes
  >
}) => {
  return useLazyQuery<
    EntityAccountAccreditationShowByIdMethod,
    EntityAccountAccreditationShowByIdErrorTypes
  >(
    (...args) => api.entityAccountAccreditationShowById(...args),
    EntityAccountAccreditationShowByIdErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EntityAccountAccreditationShowById'],
        ...apiQueryConfig.EntityAccountAccreditationShowById,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EntityAccountAccreditationShowById',
  );
};


export type EntityAccountAccreditationUpdateMethod = EntityAccountAccreditationApi['entityAccountAccreditationUpdate'];

export const useEntityAccountAccreditationUpdate = () => {
  return useMutation<EntityAccountAccreditationUpdateMethod>(
    (...args) => api.entityAccountAccreditationUpdate(...args),
    EntityAccountAccreditationUpdateErrorCodes,
    'EntityAccountAccreditationUpdate',
    '/api/accounts/{account_id}/accreditations/{id}',
    'put'
  );
};

export type EntityAccountAccreditationDeleteMethod = EntityAccountAccreditationApi['entityAccountAccreditationDelete'];

export const useEntityAccountAccreditationDelete = () => {
  return useMutation<EntityAccountAccreditationDeleteMethod>(
    (...args) => api.entityAccountAccreditationDelete(...args),
    EntityAccountAccreditationDeleteErrorCodes,
    'EntityAccountAccreditationDelete',
    '/api/accounts/{account_id}/accreditations/{id}',
    'delete'
  );
};
