import { Button } from '@npm/core/ui/components/atoms/Button';
import { SM_COMPANY_CAROUSEL_HEIGHT } from '@npm/core/ui/hooks/useSMHeaderHeight';
import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

const CAROUSEL_COMPANY_CARD_WIDTH = 180;

export const CarouselCointainer = styled.div<{ isDesktop?: boolean }>`
  position: relative;
  overflow: hidden;
  height: ${SM_COMPANY_CAROUSEL_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};
`;

export const CarouselSlide = styled.div`
  display: flex;
  height: ${SM_COMPANY_CAROUSEL_HEIGHT}px;

  animation: slide 375s infinite linear;

  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    // can't be relative to ensure same speed across different resolutions
    100% {
      transform: translateX(-15000px);
    }
  }

  &:hover {
    animation-play-state: paused;
    cursor: pointer;
  }
`;

export const CompanyCardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${CAROUSEL_COMPANY_CARD_WIDTH}px;
  padding: 12px ${({ theme }) => theme.spacing.md}px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryHover};
    transform: scale(1.05);
  }
`;

export const CompanySelectButtonWrapper = styled.div`
  ${centerFlex}
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.color.general.layout.zero};
  padding: 0 ${({ theme }) => theme.spacing.md}px;
  border-right: 1px solid ${({ theme }) => theme.color.general.borders.primary};

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: 0 ${theme.spacing.sm}px;
  `}
`;

export const CompanySelectButton = styled(Button)`
  span {
    display: inline-flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs}px;
  }
`;
