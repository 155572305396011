/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



  /**
 * 
 * @export
 * @interface AdminIssuerStakeholderSummaryRequestContract
 */
export interface AdminIssuerStakeholderSummaryRequestContract {
    /**
     * 
     * @type {string}
     * @memberof AdminIssuerStakeholderSummaryRequestContract
     */
    'end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminIssuerStakeholderSummaryRequestContract
     */
    'start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminIssuerStakeholderSummaryRequestContract
     */
    'sort_dir'?: AdminIssuerStakeholderSummaryRequestContractSortDirEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminIssuerStakeholderSummaryRequestContract
     */
    'issuer_ids'?: Array<string>;
}

export const AdminIssuerStakeholderSummaryRequestContractSortDirEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type AdminIssuerStakeholderSummaryRequestContractSortDirEnum = typeof AdminIssuerStakeholderSummaryRequestContractSortDirEnum[keyof typeof AdminIssuerStakeholderSummaryRequestContractSortDirEnum];


