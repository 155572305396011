import {
  type CbLabelComponent,
  type IconNames,
  type IconProps,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  countersign_needed: 'countersign_needed',
  defective: 'defective',
  incomplete: 'incomplete',
  not_started: 'not_started',
  partially_settled: 'partially_settled',
  pending: 'pending',
  ready_to_settle: 'ready_to_settle',
  settled: 'settled',
  submitted: 'submitted',
};

export type SubmissionStateCode = keyof typeof Items;

type Attributes = 'filter_visibility';

const LabelMap: Record<SubmissionStateCode, LabelVariant> = {
  countersign_needed: 'warning',
  defective: 'error',
  incomplete: 'warning',
  not_started: 'general',
  partially_settled: 'info',
  pending: 'warning',
  ready_to_settle: 'info',
  settled: 'success',
  submitted: 'success',
};

const LabelIconMap: Record<SubmissionStateCode, IconNames> = {
  countersign_needed: 'alert-triangle',
  defective: 'alert-triangle',
  incomplete: 'alert-triangle',
  not_started: 'info-circle',
  partially_settled: 'info-circle',
  pending: 'warning',
  ready_to_settle: 'info-circle',
  settled: 'check',
  submitted: 'check',
};

export const CbSubmissionState = {
  code: Codebooks.SUBMISSION_STATE,
  items: Items,
  labels: LabelMap,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined,
    iconProps?: IconProps
  ) =>
    Component({
      variant: LabelMap[codebookItem?.code ?? 'none'],
      label: codebookItem?.name,
      iconProps: {
        name: LabelIconMap[
          (codebookItem?.code as SubmissionStateCode) ?? 'info-circle'
        ],
        ...iconProps,
      },
    }),
  getAttrByName: (item: CodebookItem, name: Attributes) =>
    item.attributes?.find(q => q.name === name),
};
