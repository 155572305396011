import { Flex } from '@npm/core/ui/components/atoms/common';
import styled, { css } from 'styled-components';

const Item = styled(Flex)(
  ({ theme: { spacing, color, borderRadius } }) => css`
    padding: ${spacing.md}px;
    gap: ${spacing.sm}px;
    background-color: ${color.general.layout.two};
    border: 1px solid ${color.general.borders.primary};
    border-radius: ${borderRadius.md}px;
    cursor: pointer;

    :hover,
    :active,
    :focus {
      background-color: ${color.general.layout.zero};

      button {
        color: ${color.info.backgrounds.primaryHover} !important;
      }
    }
  `
);

const PaperClipWrapper = styled(Flex)(
  ({ theme: { spacing, color, borderRadius } }) => css`
    padding: ${spacing.xs}px;
    color: ${color.info.typography.primary};
    background-color: ${color.general.layout.zero};
    border-radius: ${borderRadius.sm}px;
  `
);

export const PooDrawerComponentsStyles = { PaperClipWrapper, Item };
