import React, { type ComponentProps, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { CypressDataIds } from '@npm/core/ui/constants';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';
import { type FolderAggregate } from '@npm/data-access';

import * as S from '../FileExporer.styles';
import { type DataRoom } from '../../../DataRoom';
import { EditFolderDrawer } from '../../EditFolderDrawer';

type Props = {
  folder: FolderAggregate;
  isFolderEditable: boolean;
  actionsDisabled?: boolean;
} & Pick<
  ComponentProps<typeof DataRoom>,
  'onFolderClick' | 'onFolderDelete' | 'onFolderEdit'
>;

export const FolderRow = ({
  folder,
  isFolderEditable,
  onFolderClick,
  onFolderDelete,
  onFolderEdit,
  actionsDisabled,
}: Props) => {
  const handleClick = () => onFolderClick?.(folder.id);

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  return (
    <S.FolderRow
      role="button"
      onClick={handleClick}
      data-cy={CypressDataIds.DataRooms.FileExplorer.FolderRow}
    >
      <S.Title>
        <S.IconContainer>
          <Icon name="folder" size="xs" />
        </S.IconContainer>
        <Text size="sm" colorVariant="primary">
          {folder.name}
        </Text>
      </S.Title>

      <S.Actions onClick={event => event.stopPropagation()}>
        {isFolderEditable && (
          <Dropdown
            items={[
              {
                label: 'Edit Folder',
                icon: <Icon name="edit" />,
                onClick: () => setIsEditDrawerOpen(true),
                disabled: actionsDisabled,
                tooltip: getDisabledActionTooltipText(actionsDisabled),
                'data-cy':
                  CypressDataIds.DataRooms.EditFolderDrawer.TriggerButton,
              },
              {
                label: 'Delete',
                icon: <Icon name="trash" />,
                onClick: () => setIsConfirmDeleteModalOpen(true),
                danger: true,
                disabled: actionsDisabled,
                tooltip: getDisabledActionTooltipText(actionsDisabled),
                'data-cy':
                  CypressDataIds.DataRooms.DeleteFolderModal.TriggerButton,
              },
            ]}
          >
            <Button
              variant="text"
              icon={<Icon name="dots-vertical" size="xs" />}
              data-cy={CypressDataIds.DataRooms.FileExplorer.RowDropdownTrigger}
            />
          </Dropdown>
        )}

        {isFolderEditable && (
          <EditFolderDrawer
            folder={folder}
            isOpen={isEditDrawerOpen}
            onClose={() => setIsEditDrawerOpen(false)}
            onSubmit={onFolderEdit}
          />
        )}

        {isFolderEditable && (
          <Modal
            title="Delete folder"
            okText="Delete"
            cancelText="Cancel"
            destroyOnClose
            open={isConfirmDeleteModalOpen}
            onOk={async () => {
              try {
                await onFolderDelete?.(folder.id);
                setIsConfirmDeleteModalOpen(false);
              } catch (e) {
                console.error(e);
              }
            }}
            okButtonProps={{
              color: 'error',
              icon: <Icon name="trash" />,
            }}
            onCancel={() => {
              setIsConfirmDeleteModalOpen(false);
            }}
            size="md"
          >
            You are about to delete a folder ‘<b>{folder.name}</b>’. Are you
            sure you want to continue? This action can’t be undone.
          </Modal>
        )}

        <Button
          icon={<Icon name="chevron-right" size="xs" />}
          variant="text"
          size="md"
          color="info"
          onClick={handleClick}
        />
      </S.Actions>
    </S.FolderRow>
  );
};
