import { useEffect, useState } from 'react';

import create from 'zustand';

import { type ActiveDrawerStore } from './Drawer.types';

/* this hook can be used for any case when only 1 drawer should be open at a time,
to use, add new drawer identifier inside Drawer.types.ts */
export const useActiveDrawerStore = create<ActiveDrawerStore>((set, get) => ({
  activeDrawerKey: undefined,
  openDrawer: drawerKey => set({ activeDrawerKey: drawerKey }),
  closeDrawer: () => set({ activeDrawerKey: undefined }),
  toggleDrawer: drawerKey =>
    set({
      activeDrawerKey:
        get().activeDrawerKey === drawerKey ? undefined : drawerKey,
    }),
  isOpen: drawerKey => get().activeDrawerKey === drawerKey,
}));

export const useDrawer = (initialOpen = false) => {
  const [open, setOpen] = useState(initialOpen);

  return {
    openDrawer: () => setOpen(true),
    closeDrawer: () => setOpen(false),
    toggleDrawer: () => setOpen(v => !v),
    isOpen: open,
  };
};

/**
 * This hook allows rest of the page to be scrollable even though the drawer is open.
 * It works by forwarding scroll event from .ant-drawer-mask to whatever is below cursor at that moment
 */
export const useDrawerMaskScrollPropagation = () => {
  useEffect(() => {
    const maskSelector = '.ant-drawer-mask';
    let mask: HTMLElement | null = null;

    const propagateScroll = (event: WheelEvent) => {
      if (!mask) return;
      event.stopPropagation();

      mask.style.pointerEvents = 'none';
      const elementUnderMouse = document.elementFromPoint(
        event.clientX,
        event.clientY
      ) as HTMLElement;
      mask.style.pointerEvents = 'auto';

      if (!elementUnderMouse) return;

      let scrollableParent = elementUnderMouse;
      while (scrollableParent && scrollableParent !== document.body) {
        const overflowY = window.getComputedStyle(scrollableParent).overflowY;
        if (overflowY === 'scroll' || overflowY === 'auto') {
          scrollableParent.scrollBy(0, event.deltaY);
          break;
        }
        scrollableParent = scrollableParent.parentElement as HTMLElement;
      }
    };

    const attachListener = () => {
      mask = document.querySelector(maskSelector);
      mask?.addEventListener('wheel', propagateScroll);
    };

    const detachListener = () => {
      mask?.removeEventListener('wheel', propagateScroll);
    };

    const observer = new MutationObserver(() => {
      const drawerOpen = !!document.querySelector(maskSelector);
      if (drawerOpen) {
        attachListener();
      } else {
        detachListener();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      detachListener();
      observer.disconnect();
    };
  }, []);
};
