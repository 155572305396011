import React from 'react';

import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { Flex, InfoIconWrap } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { CbAccreditationLevel, type CodebookShow } from '@npm/data-access';
import { Form, Radio } from 'antd';

type PurchaserModalQuestionnaireFormProps = {
  qualifiedPurchaserCodebooks: CodebookShow[] | undefined;
};

export const PurchaserQuestionnaireFormContent: React.FC<
  PurchaserModalQuestionnaireFormProps
> = ({ qualifiedPurchaserCodebooks }) => (
  <Flex direction="column" gap="lg">
    <Flex gap="sm">
      <Heading variant="h3">
        Please check the appropriate box applicable to your organization
      </Heading>
      <Tooltip title="The purpose of this questionnaire is to obtain information relating to whether you are a “Qualified Purchaser” under the Investment Company Act.">
        <InfoIconWrap>
          <Icon name="info-circle" />
        </InfoIconWrap>
      </Tooltip>
    </Flex>
    <Form.Item
      name={CbAccreditationLevel.items.qualified_purchaser}
      preserve={true}
    >
      <Radio.Group>
        <Flex direction="column" gap="sm">
          {qualifiedPurchaserCodebooks?.map(codebookItem => (
            <ButtonCard
              key={codebookItem.code}
              variant="radio"
              label={codebookItem.name}
              value={codebookItem.code}
            />
          ))}
        </Flex>
      </Radio.Group>
    </Form.Item>
  </Flex>
);
