import { type ComponentProps, useCallback, useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { type UploadFile } from 'antd/lib/upload/interface';
import { type AxiosProgressEvent } from 'axios';

import { type UploadRequestType } from '../../DataRoom';

import { type UploadForm } from './UploadForm';

export const useUploadForm = (): [
  {
    updateProgress: (
      fileUid?: string,
      progressEvent?: AxiosProgressEvent
    ) => void;
    addFile: (file: UploadFile) => void;
    removeFile: (file: UploadFile) => void;
    resetState: () => void;
  },
  Pick<
    ComponentProps<typeof UploadForm>,
    'form' | 'fileList' | 'progresses' | 'isUploading'
  >,
] => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progresses, setProgresses] = useState<
    ComponentProps<typeof UploadForm>['progresses']
  >({});
  const [form] = Form.useForm<Record<string, UploadRequestType>>();

  const updateProgress = useCallback(
    (fileUid?: string, progressEvent?: AxiosProgressEvent) => {
      if (!fileUid) {
        // remove all
        setProgresses({});
      } else if (progressEvent) {
        // update progress at
        setProgresses(v => ({
          ...v,
          [fileUid]: {
            loaded: progressEvent.loaded,
            total: progressEvent.total,
          },
        }));
      } else {
        // remove progress at
        setProgresses(v => {
          const { [fileUid]: _, ...rest } = v;
          return rest;
        });
      }
    },
    []
  );

  const addFile = useCallback((file: UploadFile) => {
    setFileList(files => [...files, file]);
  }, []);

  const removeFile = useCallback((file: UploadFile) => {
    setFileList(v => v.filter(f => f.uid !== file.uid));
  }, []);

  const resetState = () => {
    setFileList([]);
    setProgresses({});
  };

  return [
    { updateProgress, addFile, removeFile, resetState },
    {
      form,
      fileList,
      progresses,
      isUploading: !!Object.keys(progresses).length,
    },
  ];
};
