import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';

export const HeadingTooltipIconWrapper = styled(Flex)`
  display: inline-flex;
  vertical-align: middle;
  margin-top: ${({ theme }) => -theme.spacing.xs / 2}px;
  margin-left: ${({ theme }) => theme.spacing.xs}px;
`;

export const ScrollIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.xs}px;
  right: 50%;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-radius: 50%;
  transform: rotate(90deg);
  width: 24px;
  height: 24px;
`;

export const Divider = styled(AntDivider)`
  background-color: ${({ theme }) => theme.color.general.borders.primary};
  height: 20px;
`;
