import { useCallback, useState } from 'react';

import { useDebounceValue } from 'usehooks-ts';

type ReturnProps = [
  {
    // debounced search term, passed to the API calls
    searchTerm: string;
  },
  {
    // props for the select component
    searchValue: string;
    onSearch: (value: string) => void;
  },
];

export const useSelectAsync = (): ReturnProps => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedVal, setDebouncedVal] = useDebounceValue('', 300, {
    leading: false,
    trailing: true,
  });

  const onSearch = useCallback((value: string) => {
    setSearchValue(value);
    setDebouncedVal(value);
  }, []);

  return [
    {
      searchTerm: debouncedVal,
    },
    {
      searchValue,
      onSearch,
    },
  ];
};
