import { WysiwygContent } from '@npm/core/ui/components/atoms/WysiwygContent';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import DOMPurify from 'dompurify';

type Props = {
  isOpen: boolean;
  message: string;
  onSubmit: () => void;
};

export const ReviewTermsModal = ({ isOpen, message, onSubmit }: Props) => {
  return (
    <Modal
      title="Order Terms"
      size="lg"
      open={isOpen}
      okText="Understood"
      onOk={onSubmit}
      cancelButtonProps={{ hidden: true }}
      closable={false}
      onCancel={onSubmit}
    >
      <WysiwygContent
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(message, {
            RETURN_TRUSTED_TYPE: true,
          }),
        }}
      />
    </Modal>
  );
};
