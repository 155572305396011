import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { InputFormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { BodyTextStyles } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { css } from 'styled-components';

const RADIO_BUTTON_MIN_WIDTH = 180;
const RADIO_BUTTON_MIN_WIDTH_MOBILE = 120;

export const StyledFormItem = styled(InputFormItem)<{
  $showRadioGroup: boolean;
  $showItemsInRow: boolean;
}>`
  max-width: 400px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-bottom: ${theme.spacing.md}px;
  `}

  .ant-form-item-control-input-content {
    display: flex;
  }

  .ant-radio {
    top: 0;
  }

  ${({ theme, $showRadioGroup, $showItemsInRow }) => css`
    && label {
      margin-bottom: ${$showRadioGroup ? theme.spacing.md : theme.spacing.xs}px;
    }

    .ant-radio-group {
      display: ${$showItemsInRow ? 'flex' : 'inline-block'};

      label {
        margin-right: ${$showItemsInRow ? theme.spacing.md : 0}px;
        padding: ${theme.spacing.sm}px ${theme.spacing.md}px;

        border: 1px solid ${theme.color.general.borders.primary};
        border-radius: ${theme.borderRadius.sm}px;
        background-color: ${theme.color.general.layout.one};

        min-width: ${RADIO_BUTTON_MIN_WIDTH}px;

        ${useBreakpoint(theme).mobile`
          min-width: ${RADIO_BUTTON_MIN_WIDTH_MOBILE}px;
        `}
      }
    }

    .ant-radio-wrapper span {
      ${BodyTextStyles}
    }
  `}
`;

export const StyledButtonCard = styled(ButtonCard)`
  flex: 1 0 50%;
`;

export const StyledSelect = styled(Select)`
  max-width: 100%;
`;
