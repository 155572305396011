import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type Holding } from '@npm/data-access';

import { useUserContextStore } from '../../../../../auth/user/context';
import { getHoldingIdInfo } from '../../../../../holdings/Holdings.utils';

import * as S from './HoldingTag.styles';

type Props = {
  holding: Holding;
  onClose: () => void;
};

export const HoldingTag = ({ holding, onClose }: Props) => {
  const isEntityNpmsInvestor = useUserContextStore(
    store => store.isEntityNpmsInvestor
  );

  const { isMissingId, isMissingProofOfOwnership } = getHoldingIdInfo(holding);

  const isIncomplete = isEntityNpmsInvestor
    ? isMissingId
    : isMissingId || isMissingProofOfOwnership;

  const assetType = holding?.asset?.type?.name ?? 'Holding';

  return (
    <S.Tag $variant={isIncomplete ? 'warning' : 'info'}>
      {holding.aggregated && (
        <Icon name={'layers'} size={'xs'} colorVariant={'muted'} />
      )}
      {assetType}
      <S.IconWrapper onClick={onClose}>
        <Icon name={'x-close'} size={'sm'} />
      </S.IconWrapper>
    </S.Tag>
  );
};
