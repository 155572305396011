import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import type { IssuerEntityAggregate } from '@npm/data-access';

import * as S from './styles';

type Props = {
  title?: React.ReactNode;
  id?: number;
  withCompanyLogo: boolean;
  issuerEntity?: IssuerEntityAggregate;
};

export const DrawerTitle = ({
  title,
  id,
  withCompanyLogo,
  issuerEntity,
}: Props) => {
  const defaultTitle = id ? 'Edit Holding' : 'Add Holding';

  return withCompanyLogo ? (
    <S.DrawerTitleContainer>
      {title || defaultTitle}
      <CompanyLogo
        size={'md'}
        name={issuerEntity?.name}
        url={issuerEntity?.logo_url}
      />
    </S.DrawerTitleContainer>
  ) : (
    <>{title || defaultTitle}</>
  );
};
