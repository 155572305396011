// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StripeWebhookApi,
  StripeWebhookCallbackErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StripeWebhookApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type StripeWebhookCallbackMethod = StripeWebhookApi['stripeWebhookCallback'];

export const useStripeWebhookCallback = () => {
  return useMutation<StripeWebhookCallbackMethod>(
    (...args) => api.stripeWebhookCallback(...args),
    StripeWebhookCallbackErrorCodes,
    'StripeWebhookCallback',
    '/api/public/stripe-webhook',
    'post'
  );
};
