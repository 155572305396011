// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OcxUploadsApi,
  GetOcxUploadImportErrorStatsErrorCodes,
  GetOcxUploadImportErrorStatsErrorTypes,
  GetOcxUploadImportErrorsErrorCodes,
  GetOcxUploadImportErrorsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OcxUploadsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type GetOcxUploadImportErrorStatsMethod = OcxUploadsApi['getOcxUploadImportErrorStats'];
export type GetOcxUploadImportErrorStatsResponseType = MethodResult<GetOcxUploadImportErrorStatsMethod>;

export const useGetOcxUploadImportErrorStats = (
  variables: Parameters<GetOcxUploadImportErrorStatsMethod>[0],
  config?: UseQueryConfig<
    GetOcxUploadImportErrorStatsMethod,
    GetOcxUploadImportErrorStatsErrorTypes
  >
) => {
  return useQuery<
    GetOcxUploadImportErrorStatsMethod,
    GetOcxUploadImportErrorStatsErrorTypes
  >(
    (...args) => api.getOcxUploadImportErrorStats(...args),
    GetOcxUploadImportErrorStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetOcxUploadImportErrorStats'],
        ...apiQueryConfig.GetOcxUploadImportErrorStats,
        ...config?.queryConfig
      },
    },
    'GetOcxUploadImportErrorStats',
  );
};

export const useGetOcxUploadImportErrorStatsInfinite = (
  variables: Parameters<GetOcxUploadImportErrorStatsMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetOcxUploadImportErrorStatsMethod,
    GetOcxUploadImportErrorStatsErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetOcxUploadImportErrorStatsMethod,
    GetOcxUploadImportErrorStatsErrorTypes
  >(
    (...args) => api.getOcxUploadImportErrorStats(...args),
    GetOcxUploadImportErrorStatsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetOcxUploadImportErrorStats', 'GetOcxUploadImportErrorStatsInfinite'],
        ...apiQueryConfig.GetOcxUploadImportErrorStats,
        ...config?.queryConfig,
      },
    },
    'GetOcxUploadImportErrorStats',
  );
};

export const useGetOcxUploadImportErrorStatsLazy = (baseOptions?: {
  variables?: Parameters<GetOcxUploadImportErrorStatsMethod>[0],
  config?: UseQueryConfig<
    GetOcxUploadImportErrorStatsMethod,
    GetOcxUploadImportErrorStatsErrorTypes
  >
}) => {
  return useLazyQuery<
    GetOcxUploadImportErrorStatsMethod,
    GetOcxUploadImportErrorStatsErrorTypes
  >(
    (...args) => api.getOcxUploadImportErrorStats(...args),
    GetOcxUploadImportErrorStatsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetOcxUploadImportErrorStats'],
        ...apiQueryConfig.GetOcxUploadImportErrorStats,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetOcxUploadImportErrorStats',
  );
};


export type GetOcxUploadImportErrorsMethod = OcxUploadsApi['getOcxUploadImportErrors'];
export type GetOcxUploadImportErrorsResponseType = MethodResult<GetOcxUploadImportErrorsMethod>;

export const useGetOcxUploadImportErrors = (
  variables: Parameters<GetOcxUploadImportErrorsMethod>[0],
  config?: UseQueryConfig<
    GetOcxUploadImportErrorsMethod,
    GetOcxUploadImportErrorsErrorTypes
  >
) => {
  return useQuery<
    GetOcxUploadImportErrorsMethod,
    GetOcxUploadImportErrorsErrorTypes
  >(
    (...args) => api.getOcxUploadImportErrors(...args),
    GetOcxUploadImportErrorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetOcxUploadImportErrors'],
        ...apiQueryConfig.GetOcxUploadImportErrors,
        ...config?.queryConfig
      },
    },
    'GetOcxUploadImportErrors',
  );
};

export const useGetOcxUploadImportErrorsInfinite = (
  variables: Parameters<GetOcxUploadImportErrorsMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetOcxUploadImportErrorsMethod,
    GetOcxUploadImportErrorsErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetOcxUploadImportErrorsMethod,
    GetOcxUploadImportErrorsErrorTypes
  >(
    (...args) => api.getOcxUploadImportErrors(...args),
    GetOcxUploadImportErrorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetOcxUploadImportErrors', 'GetOcxUploadImportErrorsInfinite'],
        ...apiQueryConfig.GetOcxUploadImportErrors,
        ...config?.queryConfig,
      },
    },
    'GetOcxUploadImportErrors',
  );
};

export const useGetOcxUploadImportErrorsLazy = (baseOptions?: {
  variables?: Parameters<GetOcxUploadImportErrorsMethod>[0],
  config?: UseQueryConfig<
    GetOcxUploadImportErrorsMethod,
    GetOcxUploadImportErrorsErrorTypes
  >
}) => {
  return useLazyQuery<
    GetOcxUploadImportErrorsMethod,
    GetOcxUploadImportErrorsErrorTypes
  >(
    (...args) => api.getOcxUploadImportErrors(...args),
    GetOcxUploadImportErrorsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetOcxUploadImportErrors'],
        ...apiQueryConfig.GetOcxUploadImportErrors,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetOcxUploadImportErrors',
  );
};

