/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Company-Pricing API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AnomalousOrderIndex } from '../model';
// @ts-ignore
import { AnomalousOrderUpdateRequestContract } from '../model';
// @ts-ignore
import { IsAnomalousOrder } from '../model';
/**
 * AnomalousOrderApi - axios parameter creator
 * @export
 */
export const AnomalousOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get AnomalousOrder
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnomalousOrder: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnomalousOrder', 'id', id)
            const localVarPath = changeUrl(`/api/anomalous_orders/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get Company AnomalousOrders
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAnomalousOrders: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyAnomalousOrders', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/anomalous_orders`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get if an order is anomalous
         * @param {string} id 
         * @param {number} price 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAnomalousOrderFinder: async (id: string, price: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('isAnomalousOrderFinder', 'id', id)
            // verify required parameter 'price' is not null or undefined
            assertParamExists('isAnomalousOrderFinder', 'price', price)
            const localVarPath = changeUrl(`/api/companies/{id}/order_is_anomalous`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update AnomalousOrder
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {AnomalousOrderUpdateRequestContract} [anomalousOrderUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnomalousOrder: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, anomalousOrderUpdateRequestContract?: AnomalousOrderUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAnomalousOrder', 'id', id)
            const localVarPath = changeUrl(`/api/anomalous_orders/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(anomalousOrderUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnomalousOrderApi - functional programming interface
 * @export
 */
export const AnomalousOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnomalousOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get AnomalousOrder
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnomalousOrder(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnomalousOrder(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get Company AnomalousOrders
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyAnomalousOrders(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnomalousOrderIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyAnomalousOrders(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get if an order is anomalous
         * @param {string} id 
         * @param {number} price 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAnomalousOrderFinder(id: string, price: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsAnomalousOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isAnomalousOrderFinder(id, price, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update AnomalousOrder
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {AnomalousOrderUpdateRequestContract} [anomalousOrderUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnomalousOrder(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, anomalousOrderUpdateRequestContract?: AnomalousOrderUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnomalousOrder(id, xNPMAuthorization, xSubscriptionPlan, anomalousOrderUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnomalousOrderApi - factory interface
 * @export
 */
export const AnomalousOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnomalousOrderApiFp(configuration)
    return {
        /**
         * 
         * @summary get AnomalousOrder
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnomalousOrder(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAnomalousOrder(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get Company AnomalousOrders
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAnomalousOrders(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<AnomalousOrderIndex> {
            return localVarFp.getCompanyAnomalousOrders(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get if an order is anomalous
         * @param {string} id 
         * @param {number} price 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAnomalousOrderFinder(id: string, price: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<IsAnomalousOrder> {
            return localVarFp.isAnomalousOrderFinder(id, price, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update AnomalousOrder
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {AnomalousOrderUpdateRequestContract} [anomalousOrderUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnomalousOrder(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, anomalousOrderUpdateRequestContract?: AnomalousOrderUpdateRequestContract, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnomalousOrder(id, xNPMAuthorization, xSubscriptionPlan, anomalousOrderUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAnomalousOrder operation in AnomalousOrderApi.
 * @export
 * @interface AnomalousOrderApiGetAnomalousOrderRequest
 */
export interface AnomalousOrderApiGetAnomalousOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof AnomalousOrderApiGetAnomalousOrder
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiGetAnomalousOrder
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiGetAnomalousOrder
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for getCompanyAnomalousOrders operation in AnomalousOrderApi.
 * @export
 * @interface AnomalousOrderApiGetCompanyAnomalousOrdersRequest
 */
export interface AnomalousOrderApiGetCompanyAnomalousOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiGetCompanyAnomalousOrders
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiGetCompanyAnomalousOrders
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiGetCompanyAnomalousOrders
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for isAnomalousOrderFinder operation in AnomalousOrderApi.
 * @export
 * @interface AnomalousOrderApiIsAnomalousOrderFinderRequest
 */
export interface AnomalousOrderApiIsAnomalousOrderFinderRequest {
    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiIsAnomalousOrderFinder
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof AnomalousOrderApiIsAnomalousOrderFinder
     */
    readonly price: number

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiIsAnomalousOrderFinder
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiIsAnomalousOrderFinder
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for updateAnomalousOrder operation in AnomalousOrderApi.
 * @export
 * @interface AnomalousOrderApiUpdateAnomalousOrderRequest
 */
export interface AnomalousOrderApiUpdateAnomalousOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof AnomalousOrderApiUpdateAnomalousOrder
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiUpdateAnomalousOrder
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof AnomalousOrderApiUpdateAnomalousOrder
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {AnomalousOrderUpdateRequestContract}
     * @memberof AnomalousOrderApiUpdateAnomalousOrder
     */
    readonly anomalousOrderUpdateRequestContract?: AnomalousOrderUpdateRequestContract
}

/**
 * AnomalousOrderApi - object-oriented interface
 * @export
 * @class AnomalousOrderApi
 * @extends {BaseAPI}
 */
export class AnomalousOrderApi extends BaseAPI {
    /**
     * 
     * @summary get AnomalousOrder
     * @param {AnomalousOrderApiGetAnomalousOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnomalousOrderApi
     */
    public getAnomalousOrder(requestParameters: AnomalousOrderApiGetAnomalousOrderRequest, options?: AxiosRequestConfig) {
        return AnomalousOrderApiFp(this.configuration).getAnomalousOrder(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get Company AnomalousOrders
     * @param {AnomalousOrderApiGetCompanyAnomalousOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnomalousOrderApi
     */
    public getCompanyAnomalousOrders(requestParameters: AnomalousOrderApiGetCompanyAnomalousOrdersRequest, options?: AxiosRequestConfig) {
        return AnomalousOrderApiFp(this.configuration).getCompanyAnomalousOrders(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get if an order is anomalous
     * @param {AnomalousOrderApiIsAnomalousOrderFinderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnomalousOrderApi
     */
    public isAnomalousOrderFinder(requestParameters: AnomalousOrderApiIsAnomalousOrderFinderRequest, options?: AxiosRequestConfig) {
        return AnomalousOrderApiFp(this.configuration).isAnomalousOrderFinder(requestParameters.id, requestParameters.price, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update AnomalousOrder
     * @param {AnomalousOrderApiUpdateAnomalousOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnomalousOrderApi
     */
    public updateAnomalousOrder(requestParameters: AnomalousOrderApiUpdateAnomalousOrderRequest, options?: AxiosRequestConfig) {
        return AnomalousOrderApiFp(this.configuration).updateAnomalousOrder(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.anomalousOrderUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetAnomalousOrderErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetAnomalousOrderErrorCodes = [
];

export type GetCompanyAnomalousOrdersErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetCompanyAnomalousOrdersErrorCodes = [
];

export type IsAnomalousOrderFinderErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IsAnomalousOrderFinderErrorCodes = [
];

export type UpdateAnomalousOrderErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const UpdateAnomalousOrderErrorCodes = [
];


