import { Flex } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CompanySearchLogo } from '@npm/core/ui/components/molecules/CompanySearchLogo';
import { truncate } from '@npm/core/ui/utils/formatters';
import type { IssuerEntityAggregate, Watchlist } from '@npm/data-access';
import { useTheme } from 'styled-components';

import * as S from './ManageCompaniesDropdown.styles';

type Props = {
  company: IssuerEntityAggregate;
  onCompanyChange: (companyId: number) => void;
  onManageWatchlists: () => void;
  watchlists: Watchlist[];
};

export const ManageCompaniesDropdown = ({
  company,
  onCompanyChange,
  onManageWatchlists,
  watchlists,
}: Props) => {
  const theme = useTheme();

  return (
    <Dropdown
      items={[
        {
          label: (
            <Flex align="center" gap="sm">
              <Icon name="edit" size={'sm'} />
              <Text color="info">Add/Remove Companies</Text>
            </Flex>
          ),
          onClick: onManageWatchlists,
        },
        ...(watchlists?.map(watchlist => ({
          key: watchlist?.id,
          label: (
            <CompanySearchLogo
              size={'sm'}
              name={watchlist?.issuer_entity?.name}
              url={watchlist?.issuer_entity?.logo_url}
            />
          ),
          onClick: () =>
            watchlist?.issuer_entity?.id
              ? onCompanyChange(watchlist?.issuer_entity?.id)
              : null,
        })) ?? []),
      ]}
      zIndex={'fixed'}
      noMargin
      placement="bottomLeft"
      fixedPosition
    >
      <S.CompanyCardWrapper>
        <Flex>
          <CompanyLogo url={company?.logo_url} size="md" />

          <S.TextWrap>
            <Text colorVariant="primary">{truncate(company?.name, 18)}</Text>
          </S.TextWrap>
        </Flex>
        <Icon
          name="chevron-opposite"
          size={'xs'}
          color={theme.color.info.typography.primary}
        />
      </S.CompanyCardWrapper>
    </Dropdown>
  );
};
