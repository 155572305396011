import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { BUTTON_HEIGHT_LG } from '@npm/core/ui/components/atoms/Button/Button.styles';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { type ExtendedColumnType } from '@npm/core/ui/components/molecules/Table';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  type UserRoleAggregate,
  type UserRoleApiUserRoleIndexRequest,
  useUserRoleIndex,
  type Workstation,
} from '@npm/data-access';
import { Form } from 'antd';
import { useTheme } from 'styled-components';

import { FilterPanel, SearchFilter, useFilters } from '../../../../../filters';
import { useCurrentWorkstation } from '../../../role';
import { USER_ROLE_INDEX_BASE_PARAMS, useUserContextStore } from '../../store';
import { type RoleSwitcherProps } from '../RoleSwitcher.types';

import * as S from './AccountSwitcher.styles';
import { AccountCards, AccountsTable } from './AccountsList';
import { useAccountSwitchRedirect } from './AccountSwitcher.hooks';

type Props = RoleSwitcherProps & {
  title?: string;
  warningContent?: React.ReactNode;
  onSubmit?: (nextRole: UserRoleAggregate, workstation: Workstation) => void;
  initialAccountId?: number;
  allowRedirect?: boolean;
  redirectTo?: string;
  filterOptions?: (account: UserRoleAggregate) => boolean;
  noDataAreaTitle?: string;
  userRoleIndexVariables?: UserRoleApiUserRoleIndexRequest;
  extraColumns?: ExtendedColumnType<UserRoleAggregate>[];
  hideBrokerageFirm?: boolean;
  withoutSettingRole?: boolean;
};

export const AccountSwitcher = ({
  title,
  isOpen,
  onClose,
  onSubmit,
  warningContent,
  allowRedirect = true,
  redirectTo,
  filterOptions,
  noDataAreaTitle,
  userRoleIndexVariables,
  initialAccountId,
  extraColumns,
  hideBrokerageFirm = false,
  withoutSettingRole = false,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  const workstation = useCurrentWorkstation();
  const setRole = useUserContextStore(store => store.setRole);
  const getRedirectPath = useAccountSwitchRedirect();

  const [form] = Form.useForm<{ search?: string }>();
  const [{ variables, isFilterApplied }, filterPanelProps] = useFilters({
    ...USER_ROLE_INDEX_BASE_PARAMS,
    ...userRoleIndexVariables,
  });

  const { data, isLoading } = useUserRoleIndex(variables, {
    queryConfig: {
      enabled: isOpen,
    },
  });

  const options = useMemo(() => {
    if (!data?.user_roles) return [];
    if (!filterOptions) return data.user_roles;
    return data.user_roles.filter(filterOptions);
  }, [data, filterOptions]);

  const wasInitialRoleSet = useRef(false);
  const [selectedRole, setSelectedRole] = useState<UserRoleAggregate>();

  useEffect(() => {
    if (
      isOpen &&
      initialAccountId &&
      options.length > 0 &&
      !wasInitialRoleSet.current
    ) {
      const initialRole = options.find(
        role => role.subject.id === initialAccountId
      );
      setSelectedRole(initialRole);
      wasInitialRoleSet.current = true;
    }
  }, [options, initialAccountId, isOpen]);

  const handleClose = () => {
    form.resetFields();
    filterPanelProps.handleSubmit(undefined);
    setSelectedRole(undefined);
    wasInitialRoleSet.current = false;
    onClose?.();
  };

  const handleSubmit = (nextRole: UserRoleAggregate) => {
    !withoutSettingRole &&
      setRole(
        nextRole,
        workstation,
        (redirectTo || allowRedirect) && {
          redirectTo: redirectTo ?? getRedirectPath(nextRole),
        }
      );
    onSubmit?.(nextRole, workstation);
    handleClose();
  };

  const defaultTitle =
    workstation?.user_role_count > 1 ? 'Filter by Account' : 'Your Account';

  return (
    <S.Modal
      title={title || defaultTitle}
      open={isOpen}
      onCancel={() => handleClose()}
      footer={
        <Flex
          gap={isMobile ? 'sm' : undefined}
          direction={isMobile ? 'column' : 'row'}
          style={{ padding: isMobile ? theme.spacing.sm : undefined }}
        >
          {isMobile ? (
            <Button variant="outline" onClick={() => handleClose()}>
              Cancel
            </Button>
          ) : (
            <Button
              disabled={!selectedRole}
              onClick={() => handleSubmit(selectedRole)}
              block
            >
              Select Account
            </Button>
          )}
        </Flex>
      }
      style={{ top: 60 }}
      width={1000}
      isFullScreen={isMobile}
      fullScreenProps={{
        footerHeight:
          BUTTON_HEIGHT_LG +
          theme.spacing.sm + // number of buttons
          theme.spacing.sm, // footer padding,
      }}
    >
      <Flex
        direction="column"
        gap="md"
        data-cy={CypressDataIds.RoleSwitcher.Drawer.Content}
      >
        {warningContent && (
          <Alert type="info" message={warningContent} showIcon />
        )}
        <FilterPanel
          form={form}
          defaultColumnProps={{
            span: 24,
            md: 12,
          }}
          filters={[
            {
              name: 'search',
              label: 'Search',
              render: props => (
                <SearchFilter placeholder="By Account or ID" {...props} />
              ),
            },
          ]}
          totalRecords={options?.length}
          {...filterPanelProps}
        />
        {isMobile ? (
          <AccountCards
            data={options}
            isLoading={isLoading}
            isAnyFilterApplied={isFilterApplied}
            onClick={userRole => handleSubmit(userRole)}
            noDataAreaTitle={noDataAreaTitle}
            extraColumns={extraColumns}
          />
        ) : (
          <AccountsTable
            data={options}
            isLoading={isLoading}
            isAnyFilterApplied={isFilterApplied}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            noDataAreaTitle={noDataAreaTitle}
            extraColumns={extraColumns}
            hideBrokerageFirm={hideBrokerageFirm}
          />
        )}
      </Flex>
    </S.Modal>
  );
};
