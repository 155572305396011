/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IssuerEntitiesCapTablePartnerConfigIndex } from '../model';
// @ts-ignore
import { IssuerEntitiesCapTablePartnerConfigShow } from '../model';
// @ts-ignore
import { IssuerEntitiesCapTablePartnerConfigUpdateRequestContract } from '../model';
/**
 * IssuerEntityCapTablePartnerConfigApi - axios parameter creator
 * @export
 */
export const IssuerEntityCapTablePartnerConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieves cap table partner configs for the specified issuer entity
         * @param {number} issuerEntityId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityCapTablePartnerConfigIndex: async (issuerEntityId: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('issuerEntityCapTablePartnerConfigIndex', 'issuerEntityId', issuerEntityId)
            const localVarPath = changeUrl(`/api/issuer-entities/{issuer_entity_id}/cap_table_partner_configs`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates cap table partner configs for the specified issuer entity
         * @param {number} issuerEntityId 
         * @param {string} partnerName 
         * @param {IssuerEntitiesCapTablePartnerConfigUpdateRequestContract} issuerEntitiesCapTablePartnerConfigUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityCapTablePartnerConfigUpdate: async (issuerEntityId: number, partnerName: string, issuerEntitiesCapTablePartnerConfigUpdateRequestContract: IssuerEntitiesCapTablePartnerConfigUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerEntityId' is not null or undefined
            assertParamExists('issuerEntityCapTablePartnerConfigUpdate', 'issuerEntityId', issuerEntityId)
            // verify required parameter 'partnerName' is not null or undefined
            assertParamExists('issuerEntityCapTablePartnerConfigUpdate', 'partnerName', partnerName)
            // verify required parameter 'issuerEntitiesCapTablePartnerConfigUpdateRequestContract' is not null or undefined
            assertParamExists('issuerEntityCapTablePartnerConfigUpdate', 'issuerEntitiesCapTablePartnerConfigUpdateRequestContract', issuerEntitiesCapTablePartnerConfigUpdateRequestContract)
            const localVarPath = changeUrl(`/api/issuer-entities/{issuer_entity_id}/cap_table_partner_configs/{partner_name}`)
                .replace(`{${"issuer_entity_id"}}`, encodeURIComponent(String(issuerEntityId)))
                .replace(`{${"partner_name"}}`, encodeURIComponent(String(partnerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issuerEntitiesCapTablePartnerConfigUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssuerEntityCapTablePartnerConfigApi - functional programming interface
 * @export
 */
export const IssuerEntityCapTablePartnerConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssuerEntityCapTablePartnerConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieves cap table partner configs for the specified issuer entity
         * @param {number} issuerEntityId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityCapTablePartnerConfigIndex(issuerEntityId: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntitiesCapTablePartnerConfigIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityCapTablePartnerConfigIndex(issuerEntityId, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates cap table partner configs for the specified issuer entity
         * @param {number} issuerEntityId 
         * @param {string} partnerName 
         * @param {IssuerEntitiesCapTablePartnerConfigUpdateRequestContract} issuerEntitiesCapTablePartnerConfigUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityCapTablePartnerConfigUpdate(issuerEntityId: number, partnerName: string, issuerEntitiesCapTablePartnerConfigUpdateRequestContract: IssuerEntitiesCapTablePartnerConfigUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntitiesCapTablePartnerConfigShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityCapTablePartnerConfigUpdate(issuerEntityId, partnerName, issuerEntitiesCapTablePartnerConfigUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssuerEntityCapTablePartnerConfigApi - factory interface
 * @export
 */
export const IssuerEntityCapTablePartnerConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssuerEntityCapTablePartnerConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieves cap table partner configs for the specified issuer entity
         * @param {number} issuerEntityId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityCapTablePartnerConfigIndex(issuerEntityId: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntitiesCapTablePartnerConfigIndex> {
            return localVarFp.issuerEntityCapTablePartnerConfigIndex(issuerEntityId, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates cap table partner configs for the specified issuer entity
         * @param {number} issuerEntityId 
         * @param {string} partnerName 
         * @param {IssuerEntitiesCapTablePartnerConfigUpdateRequestContract} issuerEntitiesCapTablePartnerConfigUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityCapTablePartnerConfigUpdate(issuerEntityId: number, partnerName: string, issuerEntitiesCapTablePartnerConfigUpdateRequestContract: IssuerEntitiesCapTablePartnerConfigUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntitiesCapTablePartnerConfigShow> {
            return localVarFp.issuerEntityCapTablePartnerConfigUpdate(issuerEntityId, partnerName, issuerEntitiesCapTablePartnerConfigUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for issuerEntityCapTablePartnerConfigIndex operation in IssuerEntityCapTablePartnerConfigApi.
 * @export
 * @interface IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndexRequest
 */
export interface IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for issuerEntityCapTablePartnerConfigUpdate operation in IssuerEntityCapTablePartnerConfigApi.
 * @export
 * @interface IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdateRequest
 */
export interface IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly issuerEntityId: number

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly partnerName: string

    /**
     * 
     * @type {IssuerEntitiesCapTablePartnerConfigUpdateRequestContract}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly issuerEntitiesCapTablePartnerConfigUpdateRequestContract: IssuerEntitiesCapTablePartnerConfigUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * IssuerEntityCapTablePartnerConfigApi - object-oriented interface
 * @export
 * @class IssuerEntityCapTablePartnerConfigApi
 * @extends {BaseAPI}
 */
export class IssuerEntityCapTablePartnerConfigApi extends BaseAPI {
    /**
     * 
     * @summary retrieves cap table partner configs for the specified issuer entity
     * @param {IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuerEntityCapTablePartnerConfigApi
     */
    public issuerEntityCapTablePartnerConfigIndex(requestParameters: IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigIndexRequest, options?: AxiosRequestConfig) {
        return IssuerEntityCapTablePartnerConfigApiFp(this.configuration).issuerEntityCapTablePartnerConfigIndex(requestParameters.issuerEntityId, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates cap table partner configs for the specified issuer entity
     * @param {IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuerEntityCapTablePartnerConfigApi
     */
    public issuerEntityCapTablePartnerConfigUpdate(requestParameters: IssuerEntityCapTablePartnerConfigApiIssuerEntityCapTablePartnerConfigUpdateRequest, options?: AxiosRequestConfig) {
        return IssuerEntityCapTablePartnerConfigApiFp(this.configuration).issuerEntityCapTablePartnerConfigUpdate(requestParameters.issuerEntityId, requestParameters.partnerName, requestParameters.issuerEntitiesCapTablePartnerConfigUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type IssuerEntityCapTablePartnerConfigIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 403;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityCapTablePartnerConfigIndexErrorCodes = [
    401,
    403,
];

export type IssuerEntityCapTablePartnerConfigUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 403;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityCapTablePartnerConfigUpdateErrorCodes = [
    401,
    403,
];


