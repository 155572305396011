import React from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type AccountForBrokerage,
  CbAccreditationState,
  CbBackgroundCheckState,
} from '@npm/data-access';
import { useTheme } from 'styled-components';

import { AccountName } from '../../../../../../../../account/components/AccountName';
import { type OboModalState } from '../../../../OboModal.types';
import { RepresentativeButton } from '../../RepresentativeButton';

import * as S from './SelectAccountCard.styles';

type Props = {
  account: OboModalState['account'];
  isSelected: boolean;
  goToRepresentativeStep: () => void;
  handleComplete: (nextOboState: OboModalState) => void;
  handleSelect: (account: OboModalState['account']) => void;
};

export const SelectAccountCard = ({
  account: acc,
  isSelected,
  goToRepresentativeStep,
  handleComplete,
  handleSelect,
}: Props) => {
  const account = acc as AccountForBrokerage;
  const theme = useTheme();

  const handleButtonClick = () => {
    if (!account.representative_count) {
      handleSelect(account);
    }

    handleComplete({
      account,
      representative: account.representative,
    });
  };

  const handleSwitchRepresentative = () => {
    handleSelect(account);
    goToRepresentativeStep();
  };

  return (
    <S.ItemButton onClick={handleButtonClick} $isSelected={isSelected}>
      <Flex justify="space-between" align="center" style={{ width: '100%' }}>
        <Margin bottom="sm">
          <AccountName account={account} isActive={false} />
        </Margin>
        <Icon
          name="chevron-right"
          color={theme.color.info.typography.primary}
          size="xxs"
        />
      </Flex>
      <S.PairWrapper>
        <S.PairWrapper>
          <Text size="xs" colorVariant="tertiary">
            AML/KYC
          </Text>
          {CbBackgroundCheckState.getLabel(
            CbLabel,
            account.background_check_state
          )}
        </S.PairWrapper>
        <S.PairWrapper>
          <Text size="xs" colorVariant="tertiary">
            Accreditation
          </Text>
          {CbAccreditationState.getLabel(CbLabel, account.accreditation_state)}
        </S.PairWrapper>
      </S.PairWrapper>
      {account.representative_count > 0 && (
        <RepresentativeButton
          onClick={
            account.representative_count > 1 ? handleSwitchRepresentative : null
          }
          representative={account.representative}
        />
      )}
    </S.ItemButton>
  );
};
