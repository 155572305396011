import React from 'react';
import { useParams } from 'react-router-dom';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { truncate } from '@npm/core/ui/utils/formatters';

export const OrderPageTitle = ({ raw }: RouteTitleProps) => {
  const { isMobile } = useBreakpoints();
  const { orderId } = useParams();

  const title = `Order #${orderId}`;

  if (raw) {
    return <>{isMobile ? truncate(title) : title}</>;
  }

  return (
    <>
      <Helmet pageTitle={title}></Helmet>
      {title}
    </>
  );
};
