import { useState } from 'react';

import { getPageSize } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbOrderBuySell,
  CbSecondMarketOrderSort,
  type SubmissionApiSecondmarketSubmissionIndexRequest,
} from '@npm/data-access';

import { FILTER_QUERY_KEY, usePersistedFilters } from '../../../../filters';

import {
  InvestorOrdersCards,
  InvestorOrdersTable,
  type OrdersActionsConfig,
} from './index';
import { InvestorsOrdersIndexFilters } from './InvestorOrdersIndexFilters';
import { type InvestorOrdersTab } from './InvestorOrdersList.types';
import { getEnhancedVariables } from './InvestorOrdersList.utils';

type Props = {
  accountId: number;
  issuerEntityId: number;
  hideAdditionalFields?: boolean;
  hideDropdownItems?: boolean;
  showOrderType?: boolean;
} & OrdersActionsConfig;

export const InvestorOrdersList = ({
  accountId,
  issuerEntityId,
  hideAdditionalFields,
  hideDropdownItems,
  showOrderType,
  ...props
}: Props) => {
  const [tab, setTab] = useState<InvestorOrdersTab>('all');

  const { isMobile } = useBreakpoints();

  const [{ variables, setVariables }, filterPanelProps] =
    usePersistedFilters<SubmissionApiSecondmarketSubmissionIndexRequest>(
      FILTER_QUERY_KEY.myOrders,
      {
        issuerEntityId,
        accountId,
        size: getPageSize(isMobile ? 'cards' : 'table'),
        includeField: ['items', 'type_totals'],
        sort: CbSecondMarketOrderSort.items.newest,
        side: CbOrderBuySell.items.sell,
      }
    );

  const filters = (
    <InvestorsOrdersIndexFilters
      {...filterPanelProps}
      activeTab={tab}
      setActiveTab={setTab}
    />
  );

  const enhancedVariables = getEnhancedVariables(tab, variables);

  return isMobile ? (
    <InvestorOrdersCards
      variables={enhancedVariables}
      filters={filters}
      hideAdditionalFields={hideAdditionalFields}
      hideDropdownItems={hideDropdownItems}
      showOrderType={showOrderType}
      {...props}
    />
  ) : (
    <InvestorOrdersTable
      variables={enhancedVariables}
      setVariables={setVariables}
      filters={filters}
      {...props}
    />
  );
};
