import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useTheme } from 'styled-components';

import { type SubscriptionPlan } from '../UnlockPremiumModal.config';

import * as S from './PlanAttributesList.styles';

type Props = {
  attributes: SubscriptionPlan['attributes'];
  color: 'info' | 'warning';
};

export const PlanAttributesList = ({ attributes, color }: Props) => {
  const theme = useTheme();

  return (
    <S.List>
      {attributes.map(attr => (
        <S.ListItem key={attr}>
          <Icon
            name="check"
            color={theme.color[color].typography.primary}
            size="xs"
          />
          <Text size="sm">{attr}</Text>
        </S.ListItem>
      ))}
    </S.List>
  );
};
