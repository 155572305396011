import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useConfirmOnLeave } from '@npm/core/ui/components/organisms/DiscardModal';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  type Holding,
  type IssuerEntitiesHoldingCreateRequestContract,
  type IssuerEntityAggregate,
  useAccountShow,
  useHoldingUpdate,
  useIssuerEntityHoldingCreate,
} from '@npm/data-access';
import { useQueryClient } from '@tanstack/react-query';

import * as S from '../../Drawer/HoldingDrawer.styles';
import { usePermissions } from '../../../auth/permissions/usePermissions';
import { DrawerTitle } from '../../Drawer/components';
import {
  type AddAggregatedHoldingFormValues,
  AggregatedHoldingForm,
  useAggregatedHoldingForm,
} from '../Form';
import {
  ProofOfOwnershipUpload,
  useProofOfOwnershipUpload,
} from '../ProofOfOwnershipUpload';

export type AggregatedHoldingDrawerProps = {
  holdingId?: number;
  setHoldingId?: (id: number) => void;
  issuerEntity?: IssuerEntityAggregate;
  accountId?: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: (holding: Holding) => void;
};

export const AggregatedHoldingDrawer = ({
  holdingId,
  onClose,
  open,
  accountId,
  issuerEntity,
  setHoldingId,
  onSuccess,
}: AggregatedHoldingDrawerProps) => {
  const isEdit = !!holdingId;

  const { canWrite, BRO_ACCOUNT_MANAGER } = usePermissions();
  const writeDisabled = !(canWrite || BRO_ACCOUNT_MANAGER);

  const { withShowApiErrorMiddleware, clearAlerts } = useAlerts();

  const queryClient = useQueryClient();

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave('AggregatedHoldingDrawer' + holdingId);

  const {
    form,
    isFetching: isFetchingHolding,
    holding,
  } = useAggregatedHoldingForm({
    holdingId,
  });

  const {
    addFile,
    removeFile,
    fileList,
    isFetching: isFetchingDocs,
    isSaving: isSavingDocs,
    setFileList,
    updateAllDocuments,
  } = useProofOfOwnershipUpload({
    holdingId,
  });

  const { data: account } = useAccountShow(
    { id: accountId?.toString() },
    { queryConfig: { enabled: !!accountId } }
  );

  const { execute: create, isLoading: isCreating } =
    useIssuerEntityHoldingCreate();

  const { execute: update, isLoading: isUpdating } = useHoldingUpdate();

  const resetForm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
    setFileList([]);
  };

  const submitHolding = async (): Promise<Holding | null> => {
    clearAlerts();

    let values: AddAggregatedHoldingFormValues;
    try {
      values = await form.validateFields();
    } catch (e) {
      handleValidationError(form, e);
      return null;
    }

    const requestContract: IssuerEntitiesHoldingCreateRequestContract = {
      aggregated: true,
      attest_accuracy: true, // always true
      registered_name: account?.name || holding?.registered_name, // we don't allow user input // use holding value if account missing (BRO AM edit)
      vested_qty: values.quantity, // we don't allow user input of vested_qty
      quantity: values.quantity, // user input
      asset: {
        type: values.type, // user input
      },
    };

    let result: Holding;

    // 1. create / update holding
    try {
      if (isEdit) {
        result = await withShowApiErrorMiddleware(update)({
          id: String(holdingId),
          issuerEntitiesHoldingCreateRequestContract: requestContract,
        });
        Notification.success({
          message: 'The aggregated holding has been successfully edited.',
        });
      } else {
        result = await withShowApiErrorMiddleware(create)({
          id: Number(issuerEntity.id), // using issuerEntity id for new holding as per spec
          issuerEntitiesHoldingCreateRequestContract: requestContract,
        });
        Notification.success({
          message: 'The aggregated holding has been successfully created.',
        });
      }
    } catch (e) {
      console.error(e);
      return null;
    }

    // 2. update documents
    try {
      if (result?.id) {
        // we can upload documents only after holding was created
        const someUpdateHappened = await updateAllDocuments(result?.id);
        if (someUpdateHappened) {
          result = {
            ...result,
            aggregated_proof_of_ownership_documents_count: fileList?.length,
          };

          Notification.success({
            message:
              'The Proof of Ownership documents have been successfully updated.',
          });
        }
      }

      void queryClient.invalidateQueries({ queryKey: ['HoldingShow'] });
      void queryClient.invalidateQueries({ queryKey: ['DocumentIndex'] });

      resetForm();
      return result;
    } catch (e) {
      // if there was an error while uploading after creating the holding, change drawer to edit mode
      if (!holdingId && result?.id) {
        setHoldingId(result?.id);
      }

      Notification.error({
        message: 'Saving of some documents has failed. Please try again.',
      });
      console.error(e);
      return null;
    } finally {
      // in case upload fails but create/update was successful we still want to re-fetch list in the background
      void queryClient.invalidateQueries({ queryKey: ['HoldingIndex'] });
    }
  };

  const closeDrawer = () => {
    resetForm();
    onClose?.();
  };

  const handleSubmitAndClose = async () => {
    const holding = await submitHolding();

    if (holding) {
      closeDrawer();
      onSuccess?.(holding);
    }
  };

  const handleSubmitAndAdd = () => {
    void submitHolding();
  };

  const isLoading =
    (accountId && !account) || isFetchingHolding || isFetchingDocs;

  return (
    <>
      <Drawer
        title={
          <DrawerTitle
            title={holdingId ? 'Edit Holding Type' : 'Add Holding Type'}
            withCompanyLogo
            // get the value from the holding if we don't have it beforehand (BRO AM edit)
            issuerEntity={issuerEntity || holding?.issuer_entity}
          />
        }
        open={open}
        onClose={() => onCloseAttempt(closeDrawer)}
        isLoading={isLoading}
        footerHeight={isEdit ? undefined : 120} // needed for correct min height on mobile (2 buttons)
        footer={
          <S.Footer>
            {isEdit ? (
              <Button
                disabled={writeDisabled}
                loading={isUpdating || isSavingDocs}
                onClick={handleSubmitAndClose}
              >
                Save Holding Type
              </Button>
            ) : (
              <>
                <Button
                  disabled={writeDisabled}
                  loading={isCreating || isSavingDocs}
                  onClick={handleSubmitAndClose}
                >
                  Submit New Holding Type
                </Button>
                <Button
                  variant="outline"
                  disabled={writeDisabled}
                  loading={isCreating || isSavingDocs}
                  onClick={handleSubmitAndAdd}
                >
                  Submit & Add Another Holding Type
                </Button>
              </>
            )}
          </S.Footer>
        }
      >
        <AggregatedHoldingForm
          issuerEntity={issuerEntity || holding?.issuer_entity}
          form={form}
          onFinish={handleSubmitAndClose} // close by default
          onValuesChange={() => setHasUnsavedChanges(true)}
          isEdit={isEdit}
        />
        <ProofOfOwnershipUpload
          fileList={fileList}
          onUpload={f => {
            setHasUnsavedChanges(true);
            addFile(f);
          }}
          onRemove={f => {
            setHasUnsavedChanges(true);
            removeFile(f);
          }}
        />
      </Drawer>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
