import React from 'react';

import { type SectionKey } from '../../../../PostOnboarding.types';

import { DurationLabel } from './DurationLabel';
import { MarketplaceAccessStatusLabel } from './MarketplaceAccessStatusLabel';

type Props = {
  sectionKey: SectionKey;
  isSectionCompleted: boolean;
  hasFullAccessToMarketplace: boolean;
  durationInMin?: number;
};

export const MainSectionLabel = ({
  sectionKey,
  isSectionCompleted,
  hasFullAccessToMarketplace,
  durationInMin,
}: Props) => {
  if (isSectionCompleted) {
    if (sectionKey === 'required_for_marketplace') {
      return (
        <MarketplaceAccessStatusLabel
          hasFullAccessToMarketplace={hasFullAccessToMarketplace}
        />
      );
    }
  } else {
    if (durationInMin) {
      return <DurationLabel durationInMin={durationInMin} />;
    }
  }

  return null;
};
