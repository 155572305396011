import { useEffect, useRef } from 'react';

import { useModal } from '@npm/core/ui/components/molecules/Modal';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import {
  type AccountShowAggregate,
  CbAccountSponsorshipType,
  CbAccountType,
  type OnboardingStatus,
} from '@npm/data-access';
import { every, some } from 'lodash';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';

import { isNPMS } from '../../auth/user/context';
import { useCurrentAccount } from '../../auth/user/role';
import {
  AMLKYCCompletedModal,
  RequiredForMarketplaceCompletedModal,
} from '../modals';
import { OnboardingCompletedModal } from '../modals/OnboardingCompletedModal';
import { SponsorshipDowngradeModal } from '../modals/SponsorshipDowngradeModal';
import { HIDE_SUCCESS_MESSAGE_KEY } from '../PostOnboarding.constants';
import {
  type OnboardingSectionConfig,
  type PostOnboardingContextType,
} from '../PostOnboarding.types';
import { calculateSectionProgress } from '../PostOnboarding.utils';

export const useCompletedOnboardingSectionModals = (
  sectionsConfig: OnboardingSectionConfig[]
) => {
  const initialSectionsProgress =
    useRef<PostOnboardingContextType['sectionsProgress']>(null);
  const initialSponsorshipLevel =
    useRef<AccountShowAggregate['sponsorship_type']>(null);

  const [openRequiredForMarketplaceModal, requiredForMarketplaceModalProps] =
    useModal();
  const [openAMLKYCCompletedModal, AMLKYCCompletedModalProps] = useModal();
  const [openOnboardingCompletedModal, onboardingCompletedModalProps] =
    useModal();
  const [openSponsorshipDowngradeModal, sponsorshipDowngradeModalProps] =
    useModal();

  const currentAccount = useCurrentAccount();
  const accountSponsorshipLevel = currentAccount?.account?.sponsorship_type;
  const isNPMSAccount = isNPMS({
    brokerage_firm_name: currentAccount?.account?.brokerage_firm?.name,
  });
  const isIndividualAccount =
    currentAccount?.account?.type?.code === CbAccountType.items.PersonAccount;

  const [queryParams, setQueryParams] = useQueryParams({
    signing_result: StringParam,
    [HIDE_SUCCESS_MESSAGE_KEY]: BooleanParam,
  });

  const dispatch = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => s.dispatch
  );

  const handleOnboardingStatusUpdate = (
    sectionsConfig: OnboardingSectionConfig[],
    onboardingStatus: OnboardingStatus
  ) => {
    const isSigningComplete = queryParams.signing_result === 'signing_complete';

    const sectionsProgress = sectionsConfig.reduce(
      (acc, section) => {
        return {
          ...acc,
          [section.key]: calculateSectionProgress(
            section.items || [],
            onboardingStatus
          ),
        };
      },
      {} as PostOnboardingContextType['sectionsProgress']
    );

    if (
      // set initial progress of the onboarding when user enters the page
      // or when the number of sections programatically changes (e.g. displaying "Other Requirements" section only after "Required for Marketplace" is completed)
      !initialSectionsProgress.current ||
      Object.keys(sectionsProgress).length !==
        Object.keys(initialSectionsProgress.current).length
    ) {
      initialSectionsProgress.current = sectionsProgress;
    }

    dispatch({
      type: WizardStoreActionTypes.UPDATE_DATA,
      payload: {
        sectionsProgress,
      },
    });

    if (
      isNPMSAccount &&
      sectionsProgress.required_for_marketplace === 100 &&
      (initialSectionsProgress.current.required_for_marketplace !== 100 ||
        // handle onboarding agreement being the last incomplete step
        // user getting redirected back to the app from docusign - `signing_result` query param is present in the url
        isSigningComplete)
    ) {
      openRequiredForMarketplaceModal();
      // update the `initialSectionsProgress` ref to avoid showing the success modal again
      // if the user edits the info in one of the already completed steps
      initialSectionsProgress.current = {
        ...initialSectionsProgress.current,
        required_for_marketplace: 100,
      };
      setQueryParams({ signing_result: undefined });
      return;
    }

    const areAllStepsCompleted = every(sectionsProgress, val => val === 100);

    if (
      areAllStepsCompleted &&
      (some(initialSectionsProgress.current, val => val < 100) ||
        isSigningComplete)
    ) {
      openOnboardingCompletedModal();
      // update the `initialSectionsProgress` ref to avoid showing the success modal again
      // if the user edits the info in one of the already completed steps
      initialSectionsProgress.current = {
        aml_kyc: 100,
        identity_verification: 100,
        agreements: 100,
        other_requirements: 100,
        required_for_marketplace: 100,
      };
      setQueryParams({ signing_result: undefined });
      return;
    }

    if (
      !isNPMSAccount &&
      isIndividualAccount &&
      sectionsProgress.aml_kyc === 100 &&
      initialSectionsProgress.current.aml_kyc !== 100
    ) {
      openAMLKYCCompletedModal();
      // update the `initialSectionsProgress` ref to avoid showing the success modal again
      // if the user edits the info in one of the already completed steps
      initialSectionsProgress.current = {
        ...initialSectionsProgress.current,
        aml_kyc: 100,
      };
    }
  };

  // handle sponsorship level downgrade & upgrade
  useEffect(() => {
    if (!accountSponsorshipLevel || !isNPMSAccount) {
      return;
    }

    if (!initialSponsorshipLevel.current) {
      initialSponsorshipLevel.current = accountSponsorshipLevel;
      return;
    }

    if (initialSponsorshipLevel.current.code !== accountSponsorshipLevel.code) {
      // downgrade: account has initially level 3 sponsorship, but changes the address to non-US state -> BE automatically downgrades the user to level 1
      if (
        initialSponsorshipLevel.current.code ===
          CbAccountSponsorshipType.items.level_three &&
        accountSponsorshipLevel.code ===
          CbAccountSponsorshipType.items.level_one
      ) {
        openSponsorshipDowngradeModal();
      }

      // upgrade: account has initially level 1 sponsorship, but changes the address to US state -> BE automatically upgrades the user to level 3
      if (
        initialSponsorshipLevel.current.code ===
          CbAccountSponsorshipType.items.level_one &&
        accountSponsorshipLevel.code ===
          CbAccountSponsorshipType.items.level_three
      ) {
        openRequiredForMarketplaceModal();
      }

      initialSponsorshipLevel.current = accountSponsorshipLevel;
    }
  }, [accountSponsorshipLevel, isNPMSAccount]);

  return {
    handleOnboardingStatusUpdate,
    renderModals: (accountId: number) =>
      // TODO: NFE-7030 revisit this solution
      queryParams[HIDE_SUCCESS_MESSAGE_KEY] ? null : (
        <>
          <AMLKYCCompletedModal
            accountId={accountId}
            modalProps={AMLKYCCompletedModalProps}
          />
          <OnboardingCompletedModal
            accountId={accountId}
            modalProps={onboardingCompletedModalProps}
          />
          <RequiredForMarketplaceCompletedModal
            accountId={accountId}
            modalProps={requiredForMarketplaceModalProps}
            sectionsConfig={sectionsConfig}
          />
          <SponsorshipDowngradeModal
            modalProps={sponsorshipDowngradeModalProps}
          />
        </>
      ),
  };
};
