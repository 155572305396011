import React from 'react';

import * as S from './Tooltip.styles';
import {
  TooltipClassName,
  type TooltipProps,
  type TooltipSize,
} from './Tooltip.types';

export const Tooltip = ({
  children,
  size = 'md',
  disabled = false,
  ...props
}: TooltipProps) => {
  const className: Record<TooltipSize, string> = {
    sm: TooltipClassName.SM,
    md: TooltipClassName.MD,
    lg: TooltipClassName.LG,
  };

  return disabled ? (
    <>{children}</>
  ) : (
    <>
      <S.TooltipGlobalStyles />
      <S.StyledTooltip overlayClassName={className[size]} {...props}>
        {children}
      </S.StyledTooltip>
    </>
  );
};
