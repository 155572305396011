import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.xs / 2}px;
  height: ${({ theme }) => theme.spacing.md}px;

  &,
  && button,
  && button:hover {
    color: ${({ theme }) => theme.color.error.typography.primary};
  }
`;

export const IconWrapper = styled.span`
  display: inline-block;
  transform: translateY(-1px);
  padding: 0 ${({ theme }) => theme.spacing.xs / 2}px;
`;
