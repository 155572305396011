import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import styled, { css } from 'styled-components';

export const Card = styled(CardBase)`
  ${({ variant }) =>
    variant === 'compact' &&
    css`
      background: none;
      .card-header-content {
        padding: ${({ theme }) => theme.spacing.sm}px;
      }
      .card-content {
        padding: 0 ${({ theme }) => theme.spacing.sm}px;
      }
    `}

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
`;

export { ButtonsContainer, LabelContainer } from './HoldingCard.styles';
