// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TaxDocumentApi,
  TaxDocumentIndexErrorCodes,
  TaxDocumentIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TaxDocumentApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TaxDocumentIndexMethod = TaxDocumentApi['taxDocumentIndex'];
export type TaxDocumentIndexResponseType = MethodResult<TaxDocumentIndexMethod>;

export const useTaxDocumentIndex = (
  variables: Parameters<TaxDocumentIndexMethod>[0],
  config?: UseQueryConfig<
    TaxDocumentIndexMethod,
    TaxDocumentIndexErrorTypes
  >
) => {
  return useQuery<
    TaxDocumentIndexMethod,
    TaxDocumentIndexErrorTypes
  >(
    (...args) => api.taxDocumentIndex(...args),
    TaxDocumentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaxDocumentIndex'],
        ...apiQueryConfig.TaxDocumentIndex,
        ...config?.queryConfig
      },
    },
    'TaxDocumentIndex',
  );
};

export const useTaxDocumentIndexInfinite = (
  variables: Parameters<TaxDocumentIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    TaxDocumentIndexMethod,
    TaxDocumentIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    TaxDocumentIndexMethod,
    TaxDocumentIndexErrorTypes
  >(
    (...args) => api.taxDocumentIndex(...args),
    TaxDocumentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TaxDocumentIndex', 'TaxDocumentIndexInfinite'],
        ...apiQueryConfig.TaxDocumentIndex,
        ...config?.queryConfig,
      },
    },
    'TaxDocumentIndex',
  );
};

export const useTaxDocumentIndexLazy = (baseOptions?: {
  variables?: Parameters<TaxDocumentIndexMethod>[0],
  config?: UseQueryConfig<
    TaxDocumentIndexMethod,
    TaxDocumentIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    TaxDocumentIndexMethod,
    TaxDocumentIndexErrorTypes
  >(
    (...args) => api.taxDocumentIndex(...args),
    TaxDocumentIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TaxDocumentIndex'],
        ...apiQueryConfig.TaxDocumentIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TaxDocumentIndex',
  );
};

