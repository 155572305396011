import { type ComponentProps, useState } from 'react';

import { useCurrentWorkstation, useObo } from '../../../auth/user/role';
import { type OboDefinition } from '../../../auth/user/role/userRole.types';

import { type OrderPlacement } from './OrderPlacement';
import { type OrderPlacementFormValues } from './OrderPlacementDrawer';

export const useOrderPlacement = (): [
  {
    handlePlaceOrder: (
      initialValues?: Partial<OrderPlacementFormValues>
    ) => void;
  },
  Omit<ComponentProps<typeof OrderPlacement>, 'company'>,
] => {
  const [initialValues, setInitialValues] =
    useState<Partial<OrderPlacementFormValues>>();
  const [isOrderPlacementOpen, setIsOrderPlacementOpen] = useState(false);
  const [isOboModalOpen, setIsOboModalOpen] = useState(false);

  const workstation = useCurrentWorkstation();
  const isInvestorWS = workstation?.type?.code === 'investor';
  const globalObo = useObo();
  const [oboOverride, setOboOverride] = useState<OboDefinition | null>(null);
  const isObo = globalObo.isObo || !!oboOverride;
  const isInvestor = isInvestorWS && !isObo;

  const handlePlaceOrder = (
    initialValues?: Partial<OrderPlacementFormValues>
  ) => {
    setInitialValues(initialValues);
    if (isInvestor) {
      setIsOrderPlacementOpen(true);
    } else {
      if (isObo) {
        setIsOrderPlacementOpen(true);
      } else {
        setIsOboModalOpen(true);
      }
    }
  };

  return [
    { handlePlaceOrder },
    {
      isOrderPlacementOpen,
      setIsOrderPlacementOpen,
      initialValues,
      isOboModalOpen,
      setIsOboModalOpen,
      isInvestor,
      obo:
        oboOverride ||
        (globalObo.isObo
          ? {
              account: globalObo.oboAccount,
              representative: globalObo.oboRepresentative,
            }
          : null),
      setOboOverride: setOboOverride,
    },
  ];
};
