import React, { useCallback } from 'react';

import { Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type OnboardingStatus } from '@npm/data-access';
import { NumberParam, useQueryParams } from 'use-query-params';

import { type SidebarSection } from '../../postOnboarding/PostOnboarding.types';

import { SidebarSection as Section } from './SidebarSection';

export type SidebarConfig = SidebarSection[];

type Props = {
  sidebarConfig: SidebarConfig;
  onboardingStatusData: OnboardingStatus;
  handleItemClick: (key: string, isParentItemClicked: boolean) => void;
  activeStepKey: string;
};

export const Sidebar = ({
  sidebarConfig,
  onboardingStatusData,
  activeStepKey,
  handleItemClick,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();
  const [_, setQueryParams] = useQueryParams({
    pageState: NumberParam,
  });

  const handleSidebarItemClick = useCallback(
    (key: string, isParentItemClicked: boolean) => {
      if (activeStepKey === key) {
        setQueryParams({ pageState: Wizard.PageState.INITIAL });
      } else {
        setQueryParams({ pageState: undefined });
      }
      handleItemClick(key, isParentItemClicked);
    },
    [isMobile, isTablet, handleItemClick]
  );

  if (!onboardingStatusData) return null;

  return (
    <>
      {sidebarConfig.map(section => {
        if (section.hideSection || section.items.every(item => item.hideItem)) {
          return null;
        }

        return (
          <Section
            key={section.key}
            activeStepKey={activeStepKey}
            onboardingStatusData={onboardingStatusData}
            onClick={handleSidebarItemClick}
            {...section}
          />
        );
      })}
    </>
  );
};
