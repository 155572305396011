import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbOrderBuySell, type SecondmarketOrderItem } from '@npm/data-access';
import Big from 'big.js';

import * as S from './OrderCard.styles';

type Props = {
  orderItem: SecondmarketOrderItem;
  compact?: boolean;
  hideStructureAndAsset?: boolean;
};

export const PriceRow = ({
  orderItem,
  compact,
  hideStructureAndAsset,
}: Props) => {
  const { isMobile, isSmDesktop } = useBreakpoints();
  const isBuy = orderItem?.order_side?.code === CbOrderBuySell.items.buy;

  const isTwoRowsView = isMobile || isSmDesktop;

  const totalPrice = orderItem?.price
    ? new Big(orderItem?.price).times(orderItem?.quantity).toNumber()
    : null;

  return (
    <S.PriceRowWrapper direction={isTwoRowsView ? 'column' : 'row'}>
      <Flex align="center">
        <S.PriceBlock>
          <Text.Price
            size={compact ? 'lg' : 'xl'}
            color={isBuy ? 'success' : 'error'}
            value={orderItem?.price}
          />
          <Text colorVariant="tertiary"> / sh</Text>
        </S.PriceBlock>
        <Flex gap="sm">
          <Margin left="xs" />
          <Text colorVariant="tertiary">×</Text>
          <Text.Quantity size={'default'} value={orderItem?.quantity} />
          <Text colorVariant="tertiary">=</Text>
          {totalPrice && (
            <Text.Price
              size={'default'}
              value={totalPrice}
              formatOptions={{ minimumFractionDigits: 0 }}
            />
          )}
        </Flex>
      </Flex>
      {!hideStructureAndAsset &&
        (isTwoRowsView ? (
          <S.StructureAndAsset>
            <Text size="sm" colorVariant="primary">
              {orderItem?.order?.structure?.name} •
            </Text>
            <Margin right="xs" />
            <Text size="xs" colorVariant="secondary">
              {orderItem?.asset?.type?.name}
            </Text>
          </S.StructureAndAsset>
        ) : (
          <S.ContentWrap>
            <Flex direction="column">
              <Text size="sm" colorVariant="primary">
                {orderItem?.order?.structure?.name}
              </Text>
              <Text size="xs" colorVariant="secondary">
                {orderItem?.asset?.type?.name}
              </Text>
            </Flex>
          </S.ContentWrap>
        ))}
    </S.PriceRowWrapper>
  );
};
