// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  WatchlistApi,
  WatchlistIndexErrorCodes,
  WatchlistIndexErrorTypes,
  WatchlistCreateErrorCodes,
  WatchlistShowErrorCodes,
  WatchlistShowErrorTypes,
  WatchlistUpdateErrorCodes,
  WatchlistDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new WatchlistApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type WatchlistIndexMethod = WatchlistApi['watchlistIndex'];
export type WatchlistIndexResponseType = MethodResult<WatchlistIndexMethod>;

export const useWatchlistIndex = (
  variables: Parameters<WatchlistIndexMethod>[0],
  config?: UseQueryConfig<
    WatchlistIndexMethod,
    WatchlistIndexErrorTypes
  >
) => {
  return useQuery<
    WatchlistIndexMethod,
    WatchlistIndexErrorTypes
  >(
    (...args) => api.watchlistIndex(...args),
    WatchlistIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WatchlistIndex'],
        ...apiQueryConfig.WatchlistIndex,
        ...config?.queryConfig
      },
    },
    'WatchlistIndex',
  );
};

export const useWatchlistIndexInfinite = (
  variables: Parameters<WatchlistIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    WatchlistIndexMethod,
    WatchlistIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    WatchlistIndexMethod,
    WatchlistIndexErrorTypes
  >(
    (...args) => api.watchlistIndex(...args),
    WatchlistIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WatchlistIndex', 'WatchlistIndexInfinite'],
        ...apiQueryConfig.WatchlistIndex,
        ...config?.queryConfig,
      },
    },
    'WatchlistIndex',
  );
};

export const useWatchlistIndexLazy = (baseOptions?: {
  variables?: Parameters<WatchlistIndexMethod>[0],
  config?: UseQueryConfig<
    WatchlistIndexMethod,
    WatchlistIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    WatchlistIndexMethod,
    WatchlistIndexErrorTypes
  >(
    (...args) => api.watchlistIndex(...args),
    WatchlistIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['WatchlistIndex'],
        ...apiQueryConfig.WatchlistIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'WatchlistIndex',
  );
};


export type WatchlistCreateMethod = WatchlistApi['watchlistCreate'];

export const useWatchlistCreate = () => {
  return useMutation<WatchlistCreateMethod>(
    (...args) => api.watchlistCreate(...args),
    WatchlistCreateErrorCodes,
    'WatchlistCreate',
    '/api/watchlists',
    'post'
  );
};

export type WatchlistShowMethod = WatchlistApi['watchlistShow'];
export type WatchlistShowResponseType = MethodResult<WatchlistShowMethod>;

export const useWatchlistShow = (
  variables: Parameters<WatchlistShowMethod>[0],
  config?: UseQueryConfig<
    WatchlistShowMethod,
    WatchlistShowErrorTypes
  >
) => {
  return useQuery<
    WatchlistShowMethod,
    WatchlistShowErrorTypes
  >(
    (...args) => api.watchlistShow(...args),
    WatchlistShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WatchlistShow'],
        ...apiQueryConfig.WatchlistShow,
        ...config?.queryConfig
      },
    },
    'WatchlistShow',
  );
};

export const useWatchlistShowInfinite = (
  variables: Parameters<WatchlistShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    WatchlistShowMethod,
    WatchlistShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    WatchlistShowMethod,
    WatchlistShowErrorTypes
  >(
    (...args) => api.watchlistShow(...args),
    WatchlistShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['WatchlistShow', 'WatchlistShowInfinite'],
        ...apiQueryConfig.WatchlistShow,
        ...config?.queryConfig,
      },
    },
    'WatchlistShow',
  );
};

export const useWatchlistShowLazy = (baseOptions?: {
  variables?: Parameters<WatchlistShowMethod>[0],
  config?: UseQueryConfig<
    WatchlistShowMethod,
    WatchlistShowErrorTypes
  >
}) => {
  return useLazyQuery<
    WatchlistShowMethod,
    WatchlistShowErrorTypes
  >(
    (...args) => api.watchlistShow(...args),
    WatchlistShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['WatchlistShow'],
        ...apiQueryConfig.WatchlistShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'WatchlistShow',
  );
};


export type WatchlistUpdateMethod = WatchlistApi['watchlistUpdate'];

export const useWatchlistUpdate = () => {
  return useMutation<WatchlistUpdateMethod>(
    (...args) => api.watchlistUpdate(...args),
    WatchlistUpdateErrorCodes,
    'WatchlistUpdate',
    '/api/watchlists/{issuer_entity_id}',
    'put'
  );
};

export type WatchlistDestroyMethod = WatchlistApi['watchlistDestroy'];

export const useWatchlistDestroy = () => {
  return useMutation<WatchlistDestroyMethod>(
    (...args) => api.watchlistDestroy(...args),
    WatchlistDestroyErrorCodes,
    'WatchlistDestroy',
    '/api/watchlists/{issuer_entity_id}',
    'delete'
  );
};
