import { type ComponentProps } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { type FormInstance } from 'antd';

type Props = Omit<ComponentProps<typeof Modal>, 'title'> & {
  form: FormInstance;
  accountName: string;
};

export const ConfirmModal = (props: Props) => {
  return (
    <Modal
      {...props}
      title="Reset Beneficial Ownership Assessment"
      okText="Reset"
      cancelText="Cancel"
      onCancel={e => {
        props.form.resetFields();
        props.onCancel?.(e);
      }}
      onOk={e => {
        props.form.resetFields();
        props.onOk?.(e);
      }}
    >
      <Alert
        type="warning"
        message="Resetting Beneficial Ownership Assessment will also delete all the individuals identified as part of the Beneficial Ownership Assessment. Are you sure you want to proceed?"
      />
    </Modal>
  );
};
