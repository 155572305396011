import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useTotalRecords as getTotalsRecords } from '@npm/core/ui/components/molecules/TotalRecordsBadge';
import { WatchlistDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbOrderBuySell,
  useWatchlistIndex,
  type WatchlistApiWatchlistIndexRequest,
} from '@npm/data-access';
import { type FormInstance } from 'antd';
import { useTheme } from 'styled-components';

import { useIsInvestor } from '../../../auth/user/role/hooks/useIsInvestor';
import { CollapsibleFilterPanel } from '../../../filters';
import WatchListToggle from '../WatchListNotification/WatchListToggle';

import * as S from './WatchListIndexFilters.styles';
import {
  getWatchlistIndexFilters,
  type WatchlistIndexFilterParams,
} from './WatchlistIndexFilters.config';

type Props = {
  form: FormInstance;
  variables?: WatchlistApiWatchlistIndexRequest;
  initialFilterValues?: Partial<WatchlistApiWatchlistIndexRequest>;
  handleSubmit: (values: WatchlistIndexFilterParams) => void;
  totalActiveFilters: number;
  onAddToWatchlist?: () => void;
  totalRecords: number;
  headerHeight?: number;
  showExtraContent?: boolean;
  showCompanySearch?: boolean;
  showAccountSearch?: boolean;
};

export const WatchlistIndexFilters = ({
  form,
  handleSubmit,
  variables,
  initialFilterValues,
  totalActiveFilters,
  onAddToWatchlist,
  totalRecords,
  headerHeight,
  showExtraContent,
  showCompanySearch,
  showAccountSearch,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  const isInvestor = useIsInvestor();

  const allRecords = getTotalsRecords(useWatchlistIndex, {
    ...variables,
    orderBuySell: undefined,
  });
  const buyTotal = getTotalsRecords(useWatchlistIndex, {
    ...variables,
    orderBuySell: CbOrderBuySell.items.buy,
  });
  const sellTotal = getTotalsRecords(useWatchlistIndex, {
    ...variables,
    orderBuySell: CbOrderBuySell.items.sell,
  });

  const handleChange = (key: string): void => {
    if (key === 'all') {
      handleSubmit({ orderBuySell: undefined });
    } else {
      handleSubmit({ orderBuySell: key });
    }
  };

  return (
    <CollapsibleFilterPanel
      isFullWidth={true}
      form={form}
      filters={getWatchlistIndexFilters(
        showCompanySearch,
        showAccountSearch,
        isInvestor
      )}
      initialFilterValues={initialFilterValues}
      handleSubmit={handleSubmit}
      totalRecords={totalRecords}
      filterDropdownProps={{ totalActiveFilters }}
      totalActiveFilters={totalActiveFilters}
      {...(allRecords !== 0 && {
        filterTabs: (
          <>
            <Tabs
              variant="tertiary"
              hideMoreButton
              defaultActiveKey="all"
              activeKey={
                variables.orderBuySell ? variables.orderBuySell : 'all'
              }
              onChange={handleChange}
            >
              <Tabs.TabPane
                key="all"
                tab={allRecords === undefined ? 'All' : `All (${allRecords})`}
              />
              <Tabs.TabPane
                key={CbOrderBuySell.items.buy}
                tab={buyTotal === undefined ? 'Buy' : `Buy (${buyTotal})`}
              />

              <Tabs.TabPane
                key={CbOrderBuySell.items.sell}
                tab={sellTotal === undefined ? 'Sell' : `Sell (${sellTotal})`}
              />
            </Tabs>

            <S.Flex>
              {isMobile ? (
                <Dropdown
                  items={[
                    {
                      label: <WatchListToggle />,
                      key: 'watchlist-toggle',
                    },
                  ]}
                >
                  <Button
                    size="md"
                    variant="text"
                    icon={<Icon name="dots-vertical" size="lg" />}
                  />
                </Dropdown>
              ) : (
                <WatchListToggle />
              )}
            </S.Flex>
          </>
        ),
        ...(showExtraContent && {
          extraContent: (
            <Flex align="center" style={{ marginRight: theme.spacing.sm }}>
              <Button
                data-cy={WatchlistDataIds.Drawer.OpenButton}
                size={isMobile ? 'sm' : 'md'}
                icon={<Icon name="plus" size={'xs'} />}
                onClick={onAddToWatchlist}
              >
                Add to Watchlist
              </Button>
            </Flex>
          ),
        }),
      })}
      affixOffsetTop={headerHeight}
    />
  );
};
