import {
  type UserRoleAggregate,
  type UserRoleApiUserRoleIndexRequest,
  type UserRoleIndex,
} from '@npm/data-access';
import { type FormInstance } from 'antd/lib/form';

export const makeAccountAutoselect =
  (
    form: FormInstance<{ account_id: number }>,
    accountCentricAccountId: number,
    filterAccounts?: (role: UserRoleAggregate) => boolean
  ) =>
  (data: UserRoleIndex, variables: UserRoleApiUserRoleIndexRequest) => {
    // Preselect account if there is only one account available
    if (
      !accountCentricAccountId &&
      !form.getFieldValue('account_id') &&
      !variables.search
    ) {
      const filteredUserRoles = filterAccounts
        ? data?.user_roles?.filter(filterAccounts)
        : data?.user_roles;
      if (
        filteredUserRoles?.length === 1 &&
        filteredUserRoles[0]?.subject?.id
      ) {
        form.setFieldValue('account_id', filteredUserRoles[0].subject.id);
      }
    }
  };

export const getAccountFormItemPreview = (
  userRoles: UserRoleIndex['user_roles'],
  selectedAccountId: number
) => {
  return userRoles?.find(role => role.subject?.id === selectedAccountId)
    ?.subject?.name;
};
