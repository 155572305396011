import { Alert } from '@npm/core/ui/components/atoms/Alert';
import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md}px;
  gap: ${({ theme }) => theme.spacing.sm}px;
`;

export const FooterAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;
`;
