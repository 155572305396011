import { CbAccountSponsorshipType } from '@npm/data-access';

import { useUserContextStore } from '../../context';

export const useSponsorshipLevel = () => {
  const sponsorshipType = useUserContextStore(store => store.sponsorshipType);

  return {
    isSponsored: CbAccountSponsorshipType.isSponsoredAccount(sponsorshipType),
    isSponsorshipLevel1:
      CbAccountSponsorshipType.isSponsoredAccountLevel1(sponsorshipType),
    isSponsorshipLevel2:
      CbAccountSponsorshipType.isSponsoredAccountLevel2(sponsorshipType),
    isSponsorshipMinLevel2:
      CbAccountSponsorshipType.isSponsoredAccountMinLvl2(sponsorshipType),
    isSponsorshipLevel3:
      CbAccountSponsorshipType.isSponsoredAccountLevel3(sponsorshipType),
  };
};
