import {
  preserveHorizontalFocusBorder,
  useBreakpoint,
} from '@npm/core/ui/utils/style';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { borderWithShadowCSS } from './components/styles';

export type FilterPanelAnimationState = 'expanded' | 'collapsed' | 'animating';

export const FormItemContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    gap: ${theme.spacing.sm}px;

    > *:first-child {
      max-width: 100%;
      flex: 1;
    }

    :focus-within {
      ${preserveHorizontalFocusBorder}
    }
  `}
`;

export const ExpandedFiltersContainer = styled(motion.div)<{
  $animationState: FilterPanelAnimationState;
}>`
  ${({ theme, $animationState }) => css`
    height: 0;
    opacity: 0;
    border-radius: ${theme.borderRadius.md}px;
    ${borderWithShadowCSS};

    overflow: ${$animationState !== 'expanded' ? 'hidden' : 'visible'};
    box-shadow: ${$animationState === 'collapsed' && 'none'};
    margin-top: ${$animationState !== 'collapsed' && '12px'};,
  `}
`;

// padding was causing a jump on the animated parent container
// -> needed to add an inner container
export const ExpandedFiltersInnerContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.md}px;
  `}
`;

export const Footer = styled.div<{
  $areFiltersExpanded?: boolean;
  $justify?: string;
}>`
  ${({ theme, $areFiltersExpanded, $justify }) => css`
    display: flex;
    align-items: center;
    justify-content: ${$justify};
    gap: ${theme.spacing.xs}px;
    // +2 to take into account the boxShadow
    margin-top: ${$areFiltersExpanded
      ? theme.spacing.lg / 2 + 2
      : theme.spacing.lg / 2}px;

    ${() => useBreakpoint(theme).mobile`
          margin-top: ${
            $areFiltersExpanded ? theme.spacing.sm + 2 : theme.spacing.sm
          }px;
      `}
  `}
`;
