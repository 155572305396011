import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $variant: 'horizontal' | 'vertical';
  $isDarkMode: boolean;
}>`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.md}px;
  overflow: hidden;

  padding: ${({ theme }) => theme.spacing.md}px;
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  border: 1px solid ${({ theme }) => theme.color.info.typography.primary};
  box-shadow: ${({ theme }) => theme.shadow.level2};

  background: ${({ theme }) =>
    `linear-gradient(${theme.color.info.borders.secondary} 0%,
      ${theme.color.info.backgrounds.secondaryDefault} 83.45%
     )`};

  ${({ $variant }) =>
    $variant === 'vertical' &&
    css`
      flex-direction: column;
      align-items: stretch;
    `}

  ${({ theme }) => useBreakpoint(theme).tablet`
      padding: ${theme.spacing.sm}px;
    `}
`;

export const GreetingWrapper = styled.div<{
  $variant: 'horizontal' | 'vertical';
}>`
  ${({ $variant }) =>
    $variant === 'horizontal' &&
    `
    flex-basis: 400px;
    flex-shrink: 1;

      && button {
        width: 250px;
        max-width: 100%;
      }
    `};
`;
