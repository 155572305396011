import { type ComponentProps } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import {
  type ATSPoolCode,
  type BrokerageFirmAggregate,
  CbATSPool,
  CbOrderSource,
  CbVisibility,
  type OrderSourceCode,
  type VisibilityCode,
} from '@npm/data-access';

import { useIsInvestor } from '../../../../auth/user/role/hooks/useIsInvestor';
import { canSelectATSPool } from '../../utils/order';

type Props = {
  formValues: {
    visibility?: VisibilityCode;
    atsPool?: ATSPoolCode;
  };
  brokerageFirm:
    | Pick<BrokerageFirmAggregate, 'name' | 'access_ats_i'>
    | undefined;
  source: OrderSourceCode | undefined;
  marginBottom?: ComponentProps<typeof Margin>['bottom'];
};

export const OrderAlerts = ({
  formValues,
  brokerageFirm,
  source,
  marginBottom,
}: Props) => {
  const isInvestor = useIsInvestor();

  // We want to show these messages only to brokers
  if (isInvestor) return null;

  const showVisibilityAlert = canSelectATSPool(
    formValues.visibility,
    brokerageFirm
  );

  const showAttestAlert =
    formValues.visibility === CbVisibility.items.external &&
    formValues.atsPool === CbATSPool.items.ats_sm &&
    source !== CbOrderSource.items.historical;

  if (!showVisibilityAlert && !showAttestAlert) return null;

  return (
    <Margin bottom={marginBottom}>
      <Flex gap="xs" direction="column">
        {showVisibilityAlert && (
          <Alert
            type="warning"
            message={
              formValues.atsPool === CbATSPool.items.ats_sm
                ? `ATS order will be visible to all ${brokerageFirm?.name} Investors & Brokers`
                : `ATS order will be visible only to ${brokerageFirm?.name} Investors`
            }
          />
        )}

        {showAttestAlert && (
          <Alert type="warning" message="You have to attest to 15c2-11" />
        )}
      </Flex>
    </Margin>
  );
};
