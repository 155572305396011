import { useUserShowCurrent } from '@npm/data-access';

import { isNPMS, useUserContextStore } from '../../auth/user/context';

export const useNpmsAccountUser = () => {
  const investorUserRoles = useUserContextStore(
    store => store.investorAccounts
  );

  const npmsAccountUserRoleId = investorUserRoles?.find(role =>
    isNPMS(role?.subject)
  )?.id;

  const { data: npmsAccountUser } = useUserShowCurrent(
    // this ensures we are getting the `user` object that is tied to the NPMS account
    { xUserRoleId: npmsAccountUserRoleId?.toString() },
    { queryConfig: { enabled: !!npmsAccountUserRoleId } }
  );

  return npmsAccountUser;
};
