import { type ForwardRefExoticComponent } from 'react';

import { Input } from 'antd';
import { type InputProps } from 'antd/es/input';
import styled from 'styled-components';

import { disabledCSS, errorStatusCSS, inputCSS, readOnlyCSS } from './styles';

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
export const StyledInput = styled<ForwardRefExoticComponent<InputProps>>(
  Input
)<{
  $readOnly?: boolean;
}>`
  &.ant-input,
  &.ant-input-affix-wrapper {
    ${inputCSS};
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  &.ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    ${errorStatusCSS};
  }

  &.ant-input-status-warning:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  &.ant-input-affix-wrapper-status-warning:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    ${errorStatusCSS};
  }

  &.ant-input-disabled,
  &.ant-input-affix-wrapper-disabled {
    ${disabledCSS};

    .ant-input-suffix {
      color: ${({ theme }) => theme.color.general.typography.muted} !important;
    }
  }

  // Fix for Chrome autofill border color
  &.ant-input-status-success {
    border-color: ${({ theme }) =>
      theme.color.general.borders.secondary} !important;
  }

  // Fix for Chrome autofill background color
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  &.ant-input,
  &.ant-input-affix-wrapper {
    ${({ $readOnly }) => $readOnly && readOnlyCSS};
  }
`;
