import { useMemo } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import {
  Tooltip,
  TooltipInfoIcon,
} from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { VenusApi } from '@npm/data-access';

const { useCompaniesLatestStockClassDetails } = VenusApi;

const TOOLTIP_MESSAGE =
  'Implied PPS is for informational purposes only. Implied PPS is estimated by NPM based upon publicly available data and includes proprietary estimates when data is unavailable. It does not account for price variations between share classes and may be different from prices being offered in the market. Client acknowledges that the actual PPS in this transaction may differ from NPM’s Implied PPS.';

type Props = {
  venusId: number;
  pricePerShare?: number;
  showDisclaimer?: boolean;
};

export const EstLastPreferredPPS = ({
  venusId,
  pricePerShare,
  showDisclaimer = true,
}: Props) => {
  const { data: latestStockClassData, isLoading } =
    useCompaniesLatestStockClassDetails(
      { id: String(venusId) },
      {
        queryConfig: { enabled: !!venusId },
        onError: error => {
          // Do not destroy the entire form if fetching the latest stock class details fails
          console.error(error);
        },
      }
    );

  const ppsDifferencePercentage = useMemo(() => {
    if (!latestStockClassData?.price_per_share || !pricePerShare) return null;

    return Math.round(
      100 - (pricePerShare / latestStockClassData.price_per_share) * 100
    );
  }, [latestStockClassData, pricePerShare]);

  if (!latestStockClassData?.price_per_share && !isLoading) {
    return null;
  }

  return (
    <Margin top="xs">
      {isLoading ? (
        <Skeleton.Input size="small" />
      ) : (
        <Flex direction="column" gap="md">
          <Flex align="center" gap="xs">
            <Text size="sm">Est. Last Preferred PPS</Text>
            <TooltipInfoIcon title="Estimated Last Preferred Price Per Share" />
            <Text.Price
              value={
                pricePerShare != null
                  ? pricePerShare
                  : latestStockClassData.price_per_share
              }
              currency="USD"
              size="sm"
              color="info"
              colorVariant="primary"
              currencyColor="info"
              currencyColorVariant="primary"
            />
            {ppsDifferencePercentage !== 0 && ppsDifferencePercentage && (
              <Text
                size="sm"
                color={ppsDifferencePercentage < 0 ? 'success' : 'error'}
                colorVariant="primary"
                as="div"
              >
                <Flex align="center">
                  <Icon
                    name={
                      ppsDifferencePercentage < 0
                        ? 'arrow-narrow-45-up'
                        : 'arrow-narrow-45-down'
                    }
                    size="xs"
                  />
                  <span>{Math.abs(ppsDifferencePercentage)}%</span>
                </Flex>
              </Text>
            )}
          </Flex>
          {showDisclaimer && (
            <Tooltip title={TOOLTIP_MESSAGE}>
              <Alert message={<Text lineClamp={1}>{TOOLTIP_MESSAGE}</Text>} />
            </Tooltip>
          )}
        </Flex>
      )}
    </Margin>
  );
};
