// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OcfImportsApi,
  ImportOcfFilesErrorCodes,
  FetchAndImportOcfFilesErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OcfImportsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type ImportOcfFilesMethod = OcfImportsApi['importOcfFiles'];

export const useImportOcfFiles = () => {
  return useMutation<ImportOcfFilesMethod>(
    (...args) => api.importOcfFiles(...args),
    ImportOcfFilesErrorCodes,
    'ImportOcfFiles',
    '/api/issuers/{issuer_id}/imports',
    'post'
  );
};

export type FetchAndImportOcfFilesMethod = OcfImportsApi['fetchAndImportOcfFiles'];

export const useFetchAndImportOcfFiles = () => {
  return useMutation<FetchAndImportOcfFilesMethod>(
    (...args) => api.fetchAndImportOcfFiles(...args),
    FetchAndImportOcfFilesErrorCodes,
    'FetchAndImportOcfFiles',
    '/api/issuers/{issuer_id}/imports/fetch_from_source',
    'post'
  );
};
