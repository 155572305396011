import { useMemo, useState } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import {
  CbBeneficialOwnerExemption,
  CbWorkstationType,
  useAccountBeneficialOwnersIndex,
  useCodebook,
} from '@npm/data-access';

import { useCurrentWorkstation } from '../../../../auth/user/role';
import {
  type BeneficialOwnersExemptions,
  type FromGetStartedFuncs,
} from '../../BeneficialOwnership.types';
import { ExemptAnswer } from '../../ExemptAnswer';
import { OwnersTable } from '../../OwnersTable';
import { ConfirmDialog } from '../ConfirmDialog';

import * as S from './ExemptionComplete.styles';
import { AddIndividualButton } from './AddIndividualButton';
import {
  getAlertProps,
  useDisablesConfig,
  useExemptAnswerData,
} from './ExemptionComplete.utils';

type Props = {
  formResult: BeneficialOwnersExemptions;
  accountName: string;
  accountId: number;
  onReset: () => void;
  completeStep?: () => void;
} & FromGetStartedFuncs;

export const ExemptionComplete = ({
  formResult: exemptions,
  accountName,
  accountId,
  onReset,
  completeStep,
  onGetStarted,
}: Props) => {
  const [noBeneficialOwnerCheck, setNoBeneficialOwnerCheck] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const addControlPersonOnly =
    !!(
      exemptions?.owner_exemption &&
      exemptions?.owner_exemption !== CbBeneficialOwnerExemption.items.none
    ) || noBeneficialOwnerCheck;
  const workstation = useCurrentWorkstation();
  const { entityExemption, ownerExemption } = useExemptAnswerData(exemptions);
  const { data, isLoading } = useAccountBeneficialOwnersIndex({
    accountId,
  });
  const { completeButton, addIndividualFormRole, addIndividualButton } =
    useDisablesConfig(data?.beneficial_owners, addControlPersonOnly);

  const { data: beneficialOwnerExemptionCbData } = useCodebook({
    type: CbBeneficialOwnerExemption.code,
  });

  const noBeneficialOwnerCheckboxLabel = useMemo(() => {
    return beneficialOwnerExemptionCbData?.codebooks?.find(
      cb => cb.code === CbBeneficialOwnerExemption.items.no_owner
    )?.name;
  }, [beneficialOwnerExemptionCbData]);

  return (
    <>
      <ExemptAnswer
        text={`Is ${accountName} excluded from the definition of “legal entity” customer under one of the categories listed below?`}
        answer={entityExemption}
        tooltipText={`${accountName} is one of the categories of exempt person (31 CFR 1020.315(2) – (5) ) for purposes of currency transaction reporting obligations`}
      />

      {ownerExemption && (
        <ExemptAnswer
          margin={false}
          text={`Is ${accountName} exempt from the beneficial ownership requirement under one of the categories listed below?`}
          answer={ownerExemption}
        />
      )}

      <Divider marginBottom={24} />

      <Alert
        type="info"
        {...getAlertProps(addControlPersonOnly, exemptions, accountName)}
      />

      {exemptions?.owner_exemption === CbBeneficialOwnerExemption.items.none &&
      exemptions?.entity_exemption === CbBeneficialOwnerExemption.items.none ? (
        <Margin top="lg">
          <Checkbox
            onChange={() => setNoBeneficialOwnerCheck(c => !c)}
            label={noBeneficialOwnerCheckboxLabel}
          />
        </Margin>
      ) : null}

      <OwnersTable
        onGetStarted={onGetStarted}
        accountId={accountId}
        owners={data?.beneficial_owners}
        isLoading={isLoading}
      />

      <Margin top="lg">
        <S.ActionButtonWrapper>
          {exemptions?.owner_exemption && (
            <>
              <AddIndividualButton
                disableControlPerson={addIndividualFormRole}
                disabled={addIndividualButton}
                accountId={accountId}
                controlPersonOnly={addControlPersonOnly}
                onGetStarted={onGetStarted}
              />
              <S.CompleteButton
                onGetStarted={onGetStarted}
                controlPersonOnly={addControlPersonOnly}
                disabled={completeButton}
                onClick={() => setConfirmOpen(true)}
              />
            </>
          )}
          {workstation?.type?.code === CbWorkstationType.items.brokerage ? (
            <S.ResetButton
              onReset={onReset}
              accountId={accountId}
              accountName={accountName}
            />
          ) : null}
        </S.ActionButtonWrapper>
      </Margin>

      <ConfirmDialog
        completeStep={completeStep}
        accountId={accountId}
        exemptions={
          noBeneficialOwnerCheck
            ? {
                ...exemptions,
                owner_exemption: CbBeneficialOwnerExemption.items.no_owner,
              }
            : exemptions
        }
        owners={data?.beneficial_owners}
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
      />
    </>
  );
};
