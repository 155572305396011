import { Radio as AntRadio, type RadioProps } from 'antd';
import styled, { css } from 'styled-components';

import { SmallTextStyles } from '../Typography';

export const radioStyles = css`
  && {
    ${SmallTextStyles}
    color: ${({ theme }) => theme.color.general.typography.primary};
  }

  .ant-radio-disabled + span {
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }

  .ant-radio-disabled .ant-radio-inner {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) =>
      theme.color.general.borders.secondary} !important;

    &::after {
      background-color: ${({ theme }) => theme.color.general.borders.secondary};
    }
  }

  .ant-radio-inner {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.general.borders.secondary};

    ::after {
      background-color: ${({ theme }) => theme.color.info.typography.primary};
    }
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.blue_60};

    ::after {
      transform: scale(0.625);
    }
  }

  .ant-radio-checked::after {
    border: 1px solid ${({ theme }) => theme.color.blue_60};
  }

  .ant-radio-inner,
  .ant-radio-input {
    width: 16px;
    height: 16px;
  }

  &.ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.color.blue_40};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.info.borders.primary};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
`;

type ExternalRadioProps = React.ForwardRefExoticComponent<
  RadioProps & React.RefAttributes<HTMLElement>
>;

export const StyledRadio = styled<ExternalRadioProps>(AntRadio)`
  ${radioStyles}
`;
