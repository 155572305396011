import { Flex } from '@npm/core/ui/components/atoms/common';
import { centerFlex } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

const ICON_DIMENSIONS = 32;

export const IconBackground = styled.div`
  ${centerFlex};
  height: ${ICON_DIMENSIONS}px;
  width: ${ICON_DIMENSIONS}px;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.zero};
`;

export const AccessMarket = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.sm}px;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme }) => theme.color.general.layout.zero};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.general.layout.one};
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
  }
`;
