/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GicsSectorIndex } from '../model';
/**
 * GICSSectosApi - axios parameter creator
 * @export
 */
export const GICSSectosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get gics sectors
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gicsSectors: async (xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/gics_sectors`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GICSSectosApi - functional programming interface
 * @export
 */
export const GICSSectosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GICSSectosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get gics sectors
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gicsSectors(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GicsSectorIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gicsSectors(xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GICSSectosApi - factory interface
 * @export
 */
export const GICSSectosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GICSSectosApiFp(configuration)
    return {
        /**
         * 
         * @summary get gics sectors
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gicsSectors(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<GicsSectorIndex> {
            return localVarFp.gicsSectors(xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for gicsSectors operation in GICSSectosApi.
 * @export
 * @interface GICSSectosApiGicsSectorsRequest
 */
export interface GICSSectosApiGicsSectorsRequest {
    /**
     * 
     * @type {string}
     * @memberof GICSSectosApiGicsSectors
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof GICSSectosApiGicsSectors
     */
    readonly xSubscriptionPlan?: string
}

/**
 * GICSSectosApi - object-oriented interface
 * @export
 * @class GICSSectosApi
 * @extends {BaseAPI}
 */
export class GICSSectosApi extends BaseAPI {
    /**
     * 
     * @summary get gics sectors
     * @param {GICSSectosApiGicsSectorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GICSSectosApi
     */
    public gicsSectors(requestParameters: GICSSectosApiGicsSectorsRequest = {}, options?: AxiosRequestConfig) {
        return GICSSectosApiFp(this.configuration).gicsSectors(requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GicsSectorsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GicsSectorsErrorCodes = [
];


