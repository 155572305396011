import React from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { AccountBadge } from '@npm/core/ui/components/molecules/AccountBadge';
import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import { Disclosure } from '@npm/core/ui/components/organisms/WizardNew/Disclosure';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { WizardWrapper } from '@npm/core/ui/components/organisms/WizardNew/WizardWrapper';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params';

import { isNPMS } from '../auth/user/context';
import { useCurrentAccount } from '../auth/user/role';

import {
  PostOnboardingContextProvider,
  usePostOnboardingContextValue,
} from './hooks/usePostOnboardingContext';
import { PersonaContextProvider } from './Persona/PersonaContext';
import { PersonaModal } from './Persona/PersonaModal';

import * as S from './PostOnboardingWizardWrapper.styles';
import { MobileSubHeader } from './MobileSubHeader';
import {
  EXIT_PAGE_KEY,
  EXIT_ROUTE_KEY,
  INFO_MESSAGE_KEY,
} from './PostOnboarding.constants';
import { type PostOnboardingContextType } from './PostOnboarding.types';
import { getActiveStepQueryParamConfig } from './PostOnboarding.utils';
import { Sidebar } from './Sidebar';

export const ACTIVE_STEP_QUERY_PARAM_KEY = 'activeStep';

export const PostOnboardingWizardWrapper: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isMobile, isTablet } = useBreakpoints();

  const { isStepperMobileOpen, account, steps, hideBottomNavigation } =
    useWizardStore((s: WizardStore<PostOnboardingContextType>) => ({
      isStepperMobileOpen: s.isStepperMobileOpen,
      account: s.data.account,
      steps: s.steps,
      hideBottomNavigation: s.data.hideBottomNavigation,
    }));

  const currentAccount = useCurrentAccount();
  const isNPMSAccount = isNPMS({
    brokerage_firm_name: currentAccount?.account?.brokerage_firm?.name,
  });

  const [{ exitRoute = '/investor-workstation', exitPage, infoMessage }] =
    useQueryParams({
      [EXIT_ROUTE_KEY]: StringParam,
      [EXIT_PAGE_KEY]: StringParam,
      // TODO: NFE-7044 revisit, this might not be needed
      [INFO_MESSAGE_KEY]: StringParam,
    });

  // handle return to a specific step from outside of the app (i.e. docusign - agreements steps)
  const [activeStepQueryParam] = useQueryParam(
    'activeStep',
    getActiveStepQueryParamConfig(steps)
  );

  const postOnboardingContextValue = usePostOnboardingContextValue(
    currentAccount.accountId
  );

  const { sectionsConfig, successModals, firstIncompleteStep } =
    postOnboardingContextValue;

  if (!children) {
    return <FullScreenLoading loadingTitle="Loading Account Onboarding..." />;
  }

  return (
    <PostOnboardingContextProvider contextValue={postOnboardingContextValue}>
      <PersonaContextProvider>
        {!(isMobile || isTablet) && <S.Background />}
        <WizardWrapper
          wizardLayoutProps={{
            title: 'Onboarding',
            titleExtra: <AccountBadge account={account} />,
            exitRoute,
            exitButtonText: exitPage,
            renderSidebar: props =>
              currentAccount.accountLoading ? (
                <Skeleton.Base />
              ) : (
                <Flex direction="column" gap="sm">
                  {/* TODO: NFE-7044 revisit, this might not be needed */}
                  {infoMessage && (
                    <Alert type="info" message={infoMessage} closable />
                  )}
                  <Sidebar
                    {...props}
                    sidebarConfig={sectionsConfig}
                    isV2={isNPMSAccount}
                  />
                </Flex>
              ),
            subHeader:
              !isStepperMobileOpen && (isMobile || isTablet) ? (
                <MobileSubHeader />
              ) : null,
            isSubHeaderAffixed: false,
            expandablePanel: <Disclosure />,
            wide: true,
            hideBottomNavigation,
          }}
          startIndex={activeStepQueryParam ?? firstIncompleteStep}
        >
          {children}
        </WizardWrapper>
        {successModals}
        <PersonaModal />
      </PersonaContextProvider>
    </PostOnboardingContextProvider>
  );
};
