/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract } from '../model';
// @ts-ignore
import { ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationShow } from '../model';
// @ts-ignore
import { ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract } from '../model';
/**
 * S2SCashAccountPartnerAccountPaymentInformationApi - axios parameter creator
 * @export
 */
export const S2SCashAccountPartnerAccountPaymentInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a new cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract} [serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCashAccountPartnerPaymentInformationCreate: async (accountNpmGuid: string, xNPMAuthorization?: string, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountNpmGuid' is not null or undefined
            assertParamExists('s2SCashAccountPartnerPaymentInformationCreate', 'accountNpmGuid', accountNpmGuid)
            const localVarPath = changeUrl(`/api/s2s/userless/accounts/{account_npm_guid}/payment-information/cash-account-partner-payment-information`)
                .replace(`{${"account_npm_guid"}}`, encodeURIComponent(String(accountNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} id The external identifier of the bank account information. This is a UUID.
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCashAccountPartnerPaymentInformationDelete: async (accountNpmGuid: string, id: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountNpmGuid' is not null or undefined
            assertParamExists('s2SCashAccountPartnerPaymentInformationDelete', 'accountNpmGuid', accountNpmGuid)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('s2SCashAccountPartnerPaymentInformationDelete', 'id', id)
            const localVarPath = changeUrl(`/api/s2s/userless/accounts/{account_npm_guid}/payment-information/cash-account-partner-payment-information/{id}`)
                .replace(`{${"account_npm_guid"}}`, encodeURIComponent(String(accountNpmGuid)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} id The external identifier of the bank account information. This is a UUID.
         * @param {string} [xNPMAuthorization] 
         * @param {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract} [serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCashAccountPartnerPaymentInformationUpdate: async (accountNpmGuid: string, id: string, xNPMAuthorization?: string, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountNpmGuid' is not null or undefined
            assertParamExists('s2SCashAccountPartnerPaymentInformationUpdate', 'accountNpmGuid', accountNpmGuid)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('s2SCashAccountPartnerPaymentInformationUpdate', 'id', id)
            const localVarPath = changeUrl(`/api/s2s/userless/accounts/{account_npm_guid}/payment-information/cash-account-partner-payment-information/{id}`)
                .replace(`{${"account_npm_guid"}}`, encodeURIComponent(String(accountNpmGuid)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S2SCashAccountPartnerAccountPaymentInformationApi - functional programming interface
 * @export
 */
export const S2SCashAccountPartnerAccountPaymentInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S2SCashAccountPartnerAccountPaymentInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a new cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract} [serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SCashAccountPartnerPaymentInformationCreate(accountNpmGuid: string, xNPMAuthorization?: string, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SCashAccountPartnerPaymentInformationCreate(accountNpmGuid, xNPMAuthorization, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} id The external identifier of the bank account information. This is a UUID.
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SCashAccountPartnerPaymentInformationDelete(accountNpmGuid: string, id: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SCashAccountPartnerPaymentInformationDelete(accountNpmGuid, id, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} id The external identifier of the bank account information. This is a UUID.
         * @param {string} [xNPMAuthorization] 
         * @param {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract} [serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SCashAccountPartnerPaymentInformationUpdate(accountNpmGuid: string, id: string, xNPMAuthorization?: string, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SCashAccountPartnerPaymentInformationUpdate(accountNpmGuid, id, xNPMAuthorization, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S2SCashAccountPartnerAccountPaymentInformationApi - factory interface
 * @export
 */
export const S2SCashAccountPartnerAccountPaymentInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S2SCashAccountPartnerAccountPaymentInformationApiFp(configuration)
    return {
        /**
         * 
         * @summary create a new cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} [xNPMAuthorization] 
         * @param {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract} [serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCashAccountPartnerPaymentInformationCreate(accountNpmGuid: string, xNPMAuthorization?: string, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract, options?: any): AxiosPromise<ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationShow> {
            return localVarFp.s2SCashAccountPartnerPaymentInformationCreate(accountNpmGuid, xNPMAuthorization, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} id The external identifier of the bank account information. This is a UUID.
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCashAccountPartnerPaymentInformationDelete(accountNpmGuid: string, id: string, xNPMAuthorization?: string, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.s2SCashAccountPartnerPaymentInformationDelete(accountNpmGuid, id, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update cash account partner payment information
         * @param {string} accountNpmGuid 
         * @param {string} id The external identifier of the bank account information. This is a UUID.
         * @param {string} [xNPMAuthorization] 
         * @param {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract} [serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SCashAccountPartnerPaymentInformationUpdate(accountNpmGuid: string, id: string, xNPMAuthorization?: string, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract, options?: any): AxiosPromise<ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationShow> {
            return localVarFp.s2SCashAccountPartnerPaymentInformationUpdate(accountNpmGuid, id, xNPMAuthorization, serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for s2SCashAccountPartnerPaymentInformationCreate operation in S2SCashAccountPartnerAccountPaymentInformationApi.
 * @export
 * @interface S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationCreateRequest
 */
export interface S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationCreate
     */
    readonly accountNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationCreate
     */
    readonly serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract
}

/**
 * Request parameters for s2SCashAccountPartnerPaymentInformationDelete operation in S2SCashAccountPartnerAccountPaymentInformationApi.
 * @export
 * @interface S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationDeleteRequest
 */
export interface S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationDelete
     */
    readonly accountNpmGuid: string

    /**
     * The external identifier of the bank account information. This is a UUID.
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationDelete
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for s2SCashAccountPartnerPaymentInformationUpdate operation in S2SCashAccountPartnerAccountPaymentInformationApi.
 * @export
 * @interface S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdateRequest
 */
export interface S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdate
     */
    readonly accountNpmGuid: string

    /**
     * The external identifier of the bank account information. This is a UUID.
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdate
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdate
     */
    readonly serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract?: ServiceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract
}

/**
 * S2SCashAccountPartnerAccountPaymentInformationApi - object-oriented interface
 * @export
 * @class S2SCashAccountPartnerAccountPaymentInformationApi
 * @extends {BaseAPI}
 */
export class S2SCashAccountPartnerAccountPaymentInformationApi extends BaseAPI {
    /**
     * 
     * @summary create a new cash account partner payment information
     * @param {S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApi
     */
    public s2SCashAccountPartnerPaymentInformationCreate(requestParameters: S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationCreateRequest, options?: AxiosRequestConfig) {
        return S2SCashAccountPartnerAccountPaymentInformationApiFp(this.configuration).s2SCashAccountPartnerPaymentInformationCreate(requestParameters.accountNpmGuid, requestParameters.xNPMAuthorization, requestParameters.serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete cash account partner payment information
     * @param {S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApi
     */
    public s2SCashAccountPartnerPaymentInformationDelete(requestParameters: S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationDeleteRequest, options?: AxiosRequestConfig) {
        return S2SCashAccountPartnerAccountPaymentInformationApiFp(this.configuration).s2SCashAccountPartnerPaymentInformationDelete(requestParameters.accountNpmGuid, requestParameters.id, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update cash account partner payment information
     * @param {S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SCashAccountPartnerAccountPaymentInformationApi
     */
    public s2SCashAccountPartnerPaymentInformationUpdate(requestParameters: S2SCashAccountPartnerAccountPaymentInformationApiS2SCashAccountPartnerPaymentInformationUpdateRequest, options?: AxiosRequestConfig) {
        return S2SCashAccountPartnerAccountPaymentInformationApiFp(this.configuration).s2SCashAccountPartnerPaymentInformationUpdate(requestParameters.accountNpmGuid, requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.serviceToServiceUserlessAccountsPaymentInformationCashAccountPartnerPaymentInformationUpdateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }
}

export type S2SCashAccountPartnerPaymentInformationCreateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SCashAccountPartnerPaymentInformationCreateErrorCodes = [
    404,
    422,
];

export type S2SCashAccountPartnerPaymentInformationDeleteErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SCashAccountPartnerPaymentInformationDeleteErrorCodes = [
    404,
];

export type S2SCashAccountPartnerPaymentInformationUpdateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SCashAccountPartnerPaymentInformationUpdateErrorCodes = [
    404,
    422,
];


