import { useMemo } from 'react';

import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { type CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { Codebooks, type IssuerEntityAggregate } from '@npm/data-access';
import {
  type BrokerageFirmAggregate,
  CbOrderEntryType,
  CbVisibility,
  useCodebook,
} from '@npm/data-access';

import { useIsInvestor } from '../../../../auth/user/role/hooks/useIsInvestor';

import * as S from './VisibilitySelect.styles';
import type { VisibilityFormSectionFields } from './types';

type Props = {
  value?: string | undefined;
  onChange?: (value: string) => void;
  validateOption: (option: string) => { disabled: boolean; warning?: string };
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const VisibilitySelect = ({
  value,
  onChange,
  validateOption,
}: Props) => {
  const { data: visibilityData } = useCodebook({
    type: Codebooks.VISIBILITY,
  });

  const sortedOptions = useMemo(
    () => [...(visibilityData?.codebooks ?? [])].reverse(),
    [visibilityData]
  );

  if (!visibilityData) return null;

  return (
    <S.Tabs
      variant="secondary"
      activeKey={value}
      onChange={onChange}
      tabBarStyle={{
        margin: 0,
      }}
      hideMoreButton
    >
      {sortedOptions.map(({ code, name }) => {
        const { disabled, warning } = validateOption(code);

        return (
          <Tabs.TabPane
            key={code}
            tab={<S.Tooltip title={warning}>{name}</S.Tooltip>}
            disabled={disabled}
          />
        );
      })}
    </S.Tabs>
  );
};

/**
 * Only for new orders!
 */
export const validateVisibilityOption = (
  option: string,
  orderType: string,
  brokerageFirm: Pick<BrokerageFirmAggregate, 'access_ats_i'> | undefined,
  isAllowedToExternalize: boolean,
  issuer: IssuerEntityAggregate
) => {
  let warning: string | undefined;
  if (option === CbVisibility.items.external) {
    if (!isAllowedToExternalize) {
      warning = 'You are not authorized to externalize orders';
    } else if (
      orderType === CbOrderEntryType.items.soft_ioi &&
      !brokerageFirm?.access_ats_i
    ) {
      warning = 'Cannot externalize an IOI order';
    }
  }

  const isTradingDisabled = issuer?.secondmarket_trading === false;
  const disabledText = `NPM has been informed by ${issuer?.name} that all share transfers are being restricted for the time being. Thank you for your understanding and cooperation. We will let you know if anything changes.`;

  if (option === CbVisibility.items.external && isTradingDisabled) {
    warning = disabledText;
  }

  return {
    disabled: !!warning,
    warning,
  };
};

// We are showing ATS pool details only to BRO, not INV (LVL3)
export const useVisibilitySectionPreviewLabel = ({
  visibilityFields,
}: {
  visibilityFields: VisibilityFormSectionFields;
}) => {
  const isInvestor = useIsInvestor();

  const { data: atsPoolCoodebookData } = useCodebook(
    {
      type: Codebooks.ATS_POOL,
    },
    { queryConfig: { enabled: !isInvestor } }
  );

  const { data: visibilityCodebookData } = useCodebook(
    {
      type: Codebooks.VISIBILITY,
    },
    { queryConfig: { enabled: isInvestor } }
  );

  const options =
    (isInvestor
      ? visibilityCodebookData?.codebooks
      : atsPoolCoodebookData?.codebooks) || [];

  const value = isInvestor
    ? visibilityFields.visibility
    : visibilityFields.atsPool;

  return options.find(i => i.code === value)?.name;
};
