import { type ComponentProps, useEffect } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { CypressDataIds } from '@npm/core/ui/constants';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  BoaFromGetStartedAction,
  type FromGetStartedFuncs,
} from '../../../BeneficialOwnership.types';

type Props = ComponentProps<typeof Button> & {
  disabled: boolean;
  onClick: () => void;
  controlPersonOnly?: boolean;
} & Pick<FromGetStartedFuncs, 'onGetStarted'>;
export const CompleteButton = ({
  onClick,
  disabled,
  controlPersonOnly,
  onGetStarted,
  ...rest
}: Props) => {
  const [params, setQueryParams] = useQueryParams({
    fromGetStarted: StringParam,
  });
  const completeAssessment = () => {
    if (!disabled) onClick();
  };

  useEffect(() => {
    if (params.fromGetStarted === BoaFromGetStartedAction.Complete) {
      onClick();
      setQueryParams({ fromGetStarted: undefined }, 'replaceIn');
    }
  }, [params.fromGetStarted]);

  const getStarted = onGetStarted
    ? () => onGetStarted(BoaFromGetStartedAction.Complete)
    : completeAssessment;

  return disabled ? (
    <Tooltip
      title={
        controlPersonOnly
          ? 'In order to complete the application you must add a control person and their respective government issued ID'
          : 'In order to complete the application you must add a control person and a beneficial owner, and their respective government issued IDs'
      }
      size="lg"
    >
      <Button {...rest} onClick={getStarted} disabled={disabled}>
        Complete Assessment
      </Button>
    </Tooltip>
  ) : (
    <Button
      {...rest}
      onClick={getStarted}
      disabled={disabled}
      data-cy={
        CypressDataIds.PostOnboarding.BeneficialOwnershipAssessment
          .CompleteAssessmentButton
      }
    >
      Complete Assessment
    </Button>
  );
};
