import { Text, type TextSize } from '../Typography';

import { TextIconStyles as S } from './TextIcon.styles';

type Props = {
  size?: TextSize;
  label: string;
};

export const TextIcon = ({ size = 'default', label }: Props) => {
  return (
    <S.TextIcon $size={size}>
      <Text size={size} color={'info'}>
        {label}
      </Text>
    </S.TextIcon>
  );
};
