import { Codebooks } from './codebooks.types';

const items = {
  BuyerAndSeller: 'BuyerAndSeller',
  StockNotice: 'StockNotice',
  Trade: 'Trade',
  PrematchAgreement: 'PrematchAgreement',
  PayingAgentServicesAgreement: 'PayingAgentServicesAgreement',
  MarketplaceAgreement: 'MarketplaceAgreement',
} as const;

export const CbAgreementType = {
  code: Codebooks.AGREEMENT_TYPE,
  items,
};
