import React from 'react';

import { Form } from 'antd';
import { useTheme } from 'styled-components';

import { Text } from '../Typography';

type Props = {
  fieldName: string;
  limit: number;
};

export const CharLimitHelper = ({ fieldName, limit }: Props) => {
  const theme = useTheme();

  return (
    <Form.Item noStyle shouldUpdate>
      {form => (
        <Text size="sm" style={{ marginTop: theme.spacing.xs }}>
          {(form.getFieldValue(fieldName) || '').length}/{limit} characters
        </Text>
      )}
    </Form.Item>
  );
};
