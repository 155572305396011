// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AffiliationApi,
  AffiliationCreateErrorCodes,
  AffiliationShowErrorCodes,
  AffiliationShowErrorTypes,
  AffiliationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AffiliationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AffiliationCreateMethod = AffiliationApi['affiliationCreate'];

export const useAffiliationCreate = () => {
  return useMutation<AffiliationCreateMethod>(
    (...args) => api.affiliationCreate(...args),
    AffiliationCreateErrorCodes,
    'AffiliationCreate',
    '/api/affiliations',
    'post'
  );
};

export type AffiliationShowMethod = AffiliationApi['affiliationShow'];
export type AffiliationShowResponseType = MethodResult<AffiliationShowMethod>;

export const useAffiliationShow = (
  variables: Parameters<AffiliationShowMethod>[0],
  config?: UseQueryConfig<
    AffiliationShowMethod,
    AffiliationShowErrorTypes
  >
) => {
  return useQuery<
    AffiliationShowMethod,
    AffiliationShowErrorTypes
  >(
    (...args) => api.affiliationShow(...args),
    AffiliationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AffiliationShow'],
        ...apiQueryConfig.AffiliationShow,
        ...config?.queryConfig
      },
    },
    'AffiliationShow',
  );
};

export const useAffiliationShowInfinite = (
  variables: Parameters<AffiliationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AffiliationShowMethod,
    AffiliationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AffiliationShowMethod,
    AffiliationShowErrorTypes
  >(
    (...args) => api.affiliationShow(...args),
    AffiliationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AffiliationShow', 'AffiliationShowInfinite'],
        ...apiQueryConfig.AffiliationShow,
        ...config?.queryConfig,
      },
    },
    'AffiliationShow',
  );
};

export const useAffiliationShowLazy = (baseOptions?: {
  variables?: Parameters<AffiliationShowMethod>[0],
  config?: UseQueryConfig<
    AffiliationShowMethod,
    AffiliationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AffiliationShowMethod,
    AffiliationShowErrorTypes
  >(
    (...args) => api.affiliationShow(...args),
    AffiliationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AffiliationShow'],
        ...apiQueryConfig.AffiliationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AffiliationShow',
  );
};


export type AffiliationUpdateMethod = AffiliationApi['affiliationUpdate'];

export const useAffiliationUpdate = () => {
  return useMutation<AffiliationUpdateMethod>(
    (...args) => api.affiliationUpdate(...args),
    AffiliationUpdateErrorCodes,
    'AffiliationUpdate',
    '/api/affiliations/{id}',
    'put'
  );
};
