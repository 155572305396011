import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { centerFlex } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const ReviewOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
`;

export const Card = styled(CardBase)`
  border: none;

  .card-content {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export const NetEstimatedProceeds = styled.div`
  ${centerFlex};
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing.md}px;
  gap: ${({ theme }) => theme.spacing.xs}px;

  background-color: ${({ theme }) => theme.color.general.layout.zero};
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};
`;
