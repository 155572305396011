import styled, { type ColorGroup } from 'styled-components';

import { centerFlex } from '../../../../utils/style';

export const SquareIconWrapper = styled.div<{ $color: ColorGroup }>`
  ${centerFlex}
  padding: ${({ theme }) => theme.spacing.sm}px;
  width: fit-content;
  height: fit-content;
  color: ${({ theme, $color }) => theme.color[$color].typography.primary};
  background-color: ${({ theme, $color }) =>
    $color === 'general'
      ? theme.color.general.backgrounds.muted
      : theme.color[$color].backgrounds.secondaryDefault};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
`;
