// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AssetClassApi,
  OrganizationAssetClassListErrorCodes,
  OrganizationAssetClassListErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AssetClassApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type OrganizationAssetClassListMethod = AssetClassApi['organizationAssetClassList'];
export type OrganizationAssetClassListResponseType = MethodResult<OrganizationAssetClassListMethod>;

export const useOrganizationAssetClassList = (
  variables: Parameters<OrganizationAssetClassListMethod>[0],
  config?: UseQueryConfig<
    OrganizationAssetClassListMethod,
    OrganizationAssetClassListErrorTypes
  >
) => {
  return useQuery<
    OrganizationAssetClassListMethod,
    OrganizationAssetClassListErrorTypes
  >(
    (...args) => api.organizationAssetClassList(...args),
    OrganizationAssetClassListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrganizationAssetClassList'],
        ...apiQueryConfig.OrganizationAssetClassList,
        ...config?.queryConfig
      },
    },
    'OrganizationAssetClassList',
  );
};

export const useOrganizationAssetClassListInfinite = (
  variables: Parameters<OrganizationAssetClassListMethod>[0],
  config?: UseInfiniteQueryConfig<
    OrganizationAssetClassListMethod,
    OrganizationAssetClassListErrorTypes
  >
) => {
  return useInfiniteQuery<
    OrganizationAssetClassListMethod,
    OrganizationAssetClassListErrorTypes
  >(
    (...args) => api.organizationAssetClassList(...args),
    OrganizationAssetClassListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['OrganizationAssetClassList', 'OrganizationAssetClassListInfinite'],
        ...apiQueryConfig.OrganizationAssetClassList,
        ...config?.queryConfig,
      },
    },
    'OrganizationAssetClassList',
  );
};

export const useOrganizationAssetClassListLazy = (baseOptions?: {
  variables?: Parameters<OrganizationAssetClassListMethod>[0],
  config?: UseQueryConfig<
    OrganizationAssetClassListMethod,
    OrganizationAssetClassListErrorTypes
  >
}) => {
  return useLazyQuery<
    OrganizationAssetClassListMethod,
    OrganizationAssetClassListErrorTypes
  >(
    (...args) => api.organizationAssetClassList(...args),
    OrganizationAssetClassListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['OrganizationAssetClassList'],
        ...apiQueryConfig.OrganizationAssetClassList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'OrganizationAssetClassList',
  );
};

