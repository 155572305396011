// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StripeCustomerPortalApi,
  StripeCustomerPortalErrorCodes,
  StripeCustomerPortalCompleteErrorCodes,
  StripeCustomerPortalCompleteErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StripeCustomerPortalApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type StripeCustomerPortalMethod = StripeCustomerPortalApi['stripeCustomerPortal'];

export const useStripeCustomerPortal = () => {
  return useMutation<StripeCustomerPortalMethod>(
    (...args) => api.stripeCustomerPortal(...args),
    StripeCustomerPortalErrorCodes,
    'StripeCustomerPortal',
    '/api/stripe/customer-portal',
    'post'
  );
};

export type StripeCustomerPortalCompleteMethod = StripeCustomerPortalApi['stripeCustomerPortalComplete'];
export type StripeCustomerPortalCompleteResponseType = MethodResult<StripeCustomerPortalCompleteMethod>;

export const useStripeCustomerPortalComplete = (
  variables: Parameters<StripeCustomerPortalCompleteMethod>[0],
  config?: UseQueryConfig<
    StripeCustomerPortalCompleteMethod,
    StripeCustomerPortalCompleteErrorTypes
  >
) => {
  return useQuery<
    StripeCustomerPortalCompleteMethod,
    StripeCustomerPortalCompleteErrorTypes
  >(
    (...args) => api.stripeCustomerPortalComplete(...args),
    StripeCustomerPortalCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StripeCustomerPortalComplete'],
        ...apiQueryConfig.StripeCustomerPortalComplete,
        ...config?.queryConfig
      },
    },
    'StripeCustomerPortalComplete',
  );
};

export const useStripeCustomerPortalCompleteInfinite = (
  variables: Parameters<StripeCustomerPortalCompleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    StripeCustomerPortalCompleteMethod,
    StripeCustomerPortalCompleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    StripeCustomerPortalCompleteMethod,
    StripeCustomerPortalCompleteErrorTypes
  >(
    (...args) => api.stripeCustomerPortalComplete(...args),
    StripeCustomerPortalCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StripeCustomerPortalComplete', 'StripeCustomerPortalCompleteInfinite'],
        ...apiQueryConfig.StripeCustomerPortalComplete,
        ...config?.queryConfig,
      },
    },
    'StripeCustomerPortalComplete',
  );
};

export const useStripeCustomerPortalCompleteLazy = (baseOptions?: {
  variables?: Parameters<StripeCustomerPortalCompleteMethod>[0],
  config?: UseQueryConfig<
    StripeCustomerPortalCompleteMethod,
    StripeCustomerPortalCompleteErrorTypes
  >
}) => {
  return useLazyQuery<
    StripeCustomerPortalCompleteMethod,
    StripeCustomerPortalCompleteErrorTypes
  >(
    (...args) => api.stripeCustomerPortalComplete(...args),
    StripeCustomerPortalCompleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StripeCustomerPortalComplete'],
        ...apiQueryConfig.StripeCustomerPortalComplete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StripeCustomerPortalComplete',
  );
};

