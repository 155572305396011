import { css } from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { SmallTextStyles } from '../Typography';

const POPUP_WIDTH_DESKTOP = '460px';
const POPUP_WIDTH_MOBILE = '100%';

// POPUP_WIDTH_DESKTOP + 2 * 16px (left & right padding)
const NOTICE_DESKTOP_WIDTH = '492px';
// the most pixels visible on the smallest tablet size
const NOTICE_MOBILE_WIDTH = '100%';

// This is to reset de default margin left that is getting overwritten by typography styles
const ANT_DEFAULT_TEXT_MARGIN = '48px';

export const StyledNotification = css`
  .ant-notification {
    padding: 0 ${({ theme }) => theme.spacing.md}px;
    width: ${NOTICE_DESKTOP_WIDTH};
    margin: 0;

    ${({ theme }) => useBreakpoint(theme).tablet`
      width: ${NOTICE_MOBILE_WIDTH};
      padding: 0 ${({ theme }) => theme.spacing.sm}px;
    `}
  }

  .ant-notification.ant-notification-regular {
    z-index: ${({ theme }) => theme.zIndex.fixed};
    position: absolute;
  }

  .ant-notification-notice.ant-notification-regular {
    width: 100%;
    max-width: ${POPUP_WIDTH_DESKTOP};
    padding: ${({ theme }) => theme.spacing.sm + 2}px;
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;

    ${({ theme }) => useBreakpoint(theme).tablet`
        max-width: ${POPUP_WIDTH_MOBILE};
        padding: ${({ theme }) => theme.spacing.sm}px;
    `}

    border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
    background: ${({ theme }) => theme.color.general.layout.one};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
    box-shadow: ${({ theme }) => theme.shadow.level1};
  }

  .ant-notification-notice-info.ant-notification-regular,
  .ant-notification-notice-error.ant-notification-regular,
  .ant-notification-notice-success.ant-notification-regular,
  .ant-notification-notice-warning.ant-notification-regular {
    padding-left: 0;
    border-left: 4px solid;
  }

  .ant-notification-notice-info.ant-notification-regular {
    border-left-color: ${({ theme }) => theme.color.info.typography.primary};

    & svg:not(.ant-notification-notice-close-x svg) {
      color: ${({ theme }) => theme.color.info.typography.primary};
    }
  }

  .ant-notification-notice-error.ant-notification-regular {
    border-left-color: ${({ theme }) => theme.color.error.typography.primary};

    & svg:not(.ant-notification-notice-close-x svg) {
      color: ${({ theme }) => theme.color.error.typography.primary};
    }
  }

  .ant-notification-notice-warning.ant-notification-regular {
    border-left-color: ${({ theme }) => theme.color.warning.typography.primary};

    & svg:not(.ant-notification-notice-close-x svg) {
      color: ${({ theme }) => theme.color.warning.typography.primary};
    }
  }

  .ant-notification-notice-success.ant-notification-regular {
    border-left-color: ${({ theme }) => theme.color.success.typography.primary};

    & svg:not(.ant-notification-notice-close-x svg) {
      color: ${({ theme }) => theme.color.success.typography.primary};
    }
  }

  .ant-notification-regular {
    .ant-notification-notice-message {
      color: ${({ theme }) => theme.color.general.typography.primary};
      font-size: ${({ theme }) => theme.typography.size.sm}px;
      font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
      ${SmallTextStyles};
      margin-left: ${ANT_DEFAULT_TEXT_MARGIN};
    }

    .ant-notification-notice-description {
      color: ${({ theme }) => theme.color.general.typography.primary};
      ${SmallTextStyles};
      margin-left: ${ANT_DEFAULT_TEXT_MARGIN};
    }

    .ant-notification-notice-icon {
      margin-left: ${({ theme }) => theme.spacing.md}px;
    }

    .ant-notification-notice-close {
      top: 50%;
      transform: translate(0, -50%);
      right: ${({ theme }) => theme.spacing.md}px;
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }

    .ant-notification-notice-icon {
      top: 50%;
      transform: translate(0, -50%);
    }

    .ant-notification-notice-close-x {
      ${centerFlex};
    }
  }
`;
