import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useTheme } from 'styled-components';

import { ChartLegendItem } from '../../../../components/ChartLegendItem';
import { PointLineIcon } from '../../../../components/PointLineIcon';
import { getPrimaryRoundsColor } from '../../../../PrimaryRounds/PrimaryRounds.utils';
import {
  getBidsColor,
  getOffersColor,
  getTradesColor,
} from '../../../BidOfferHistory.utils';
import { type ChartMode } from '../BidOfferHistoryChart.types';

type Props = {
  mode: ChartMode;
};

export const BidOfferHistoryChartLegend = ({ mode }: Props) => {
  const theme = useTheme();

  return (
    <Flex gap="md" align="center" justify="center">
      {mode === 'trades' ? (
        <ChartLegendItem
          icon={<Icon name="segment" size="xs" color={getTradesColor()} />}
          label={'Reported Trade Level'}
        />
      ) : (
        <>
          <ChartLegendItem
            icon={<Icon name="segment" size="xs" color={getBidsColor(theme)} />}
            label={'Bid Level'}
          />

          <ChartLegendItem
            icon={
              <Icon name="segment" size="xs" color={getOffersColor(theme)} />
            }
            label={'Offer Level'}
          />
        </>
      )}
      <ChartLegendItem
        icon={<PointLineIcon color={getPrimaryRoundsColor(theme)} />}
        label={'Primary Round'}
      />
    </Flex>
  );
};
