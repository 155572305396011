import { InputFormItem } from '@npm/core/ui/components/atoms/FormItem';
import styled from 'styled-components';

const CONTENT_WIDTH = 390;

export const Wrap = styled.div`
  width: ${CONTENT_WIDTH}px;
  max-width: 100%;
`;

export const FormItem = styled(InputFormItem)<{ $compact?: boolean }>`
  margin-bottom: ${({ theme, $compact }) =>
    $compact ? theme.spacing.xs : theme.spacing.xl}px;
`;
