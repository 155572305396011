// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SubmissionsApi,
  BrokerageFirmSubmissionStatisticShowErrorCodes,
  BrokerageFirmSubmissionStatisticShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SubmissionsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type BrokerageFirmSubmissionStatisticShowMethod = SubmissionsApi['brokerageFirmSubmissionStatisticShow'];
export type BrokerageFirmSubmissionStatisticShowResponseType = MethodResult<BrokerageFirmSubmissionStatisticShowMethod>;

export const useBrokerageFirmSubmissionStatisticShow = (
  variables: Parameters<BrokerageFirmSubmissionStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmSubmissionStatisticShowMethod,
    BrokerageFirmSubmissionStatisticShowErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmSubmissionStatisticShowMethod,
    BrokerageFirmSubmissionStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmSubmissionStatisticShow(...args),
    BrokerageFirmSubmissionStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmSubmissionStatisticShow'],
        ...apiQueryConfig.BrokerageFirmSubmissionStatisticShow,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmSubmissionStatisticShow',
  );
};

export const useBrokerageFirmSubmissionStatisticShowInfinite = (
  variables: Parameters<BrokerageFirmSubmissionStatisticShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmSubmissionStatisticShowMethod,
    BrokerageFirmSubmissionStatisticShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmSubmissionStatisticShowMethod,
    BrokerageFirmSubmissionStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmSubmissionStatisticShow(...args),
    BrokerageFirmSubmissionStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmSubmissionStatisticShow', 'BrokerageFirmSubmissionStatisticShowInfinite'],
        ...apiQueryConfig.BrokerageFirmSubmissionStatisticShow,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmSubmissionStatisticShow',
  );
};

export const useBrokerageFirmSubmissionStatisticShowLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmSubmissionStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmSubmissionStatisticShowMethod,
    BrokerageFirmSubmissionStatisticShowErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmSubmissionStatisticShowMethod,
    BrokerageFirmSubmissionStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmSubmissionStatisticShow(...args),
    BrokerageFirmSubmissionStatisticShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmSubmissionStatisticShow'],
        ...apiQueryConfig.BrokerageFirmSubmissionStatisticShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmSubmissionStatisticShow',
  );
};

