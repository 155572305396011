import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { getAllRoutesRecursively } from '@npm/core/ui/routes/router.utils';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';

import {
  postOnboardingPrefix,
  routes,
  workstationPrefix,
} from './routes.onboarding';

export const OnboardingRouter = () => {
  const { isEnabled } = useFeatureFlags();
  const allRoutes = useMemo(() => {
    return getAllRoutesRecursively(routes).filter(r => {
      return !r.featureFlag || isEnabled(r.featureFlag);
    });
  }, []);

  return (
    <Routes>
      {allRoutes.map(route => {
        const path = route.path
          .replace(`/${workstationPrefix}`, '')
          .replace(`/${postOnboardingPrefix}`, '');
        return route.needLogin !== false ? (
          <Route
            element={
              <ProtectedRoute permissionsChecks={route.permissionsChecks} />
            }
            key={path}
          >
            <Route path={path} Component={route.component} />
          </Route>
        ) : (
          <Route key={path} path={path} Component={route.component} />
        );
      })}
      <Route
        path="*"
        element={
          <ErrorPath
            heading="Error"
            title="Sorry, the page you visited does not exist."
            status="404"
          />
        }
      />
    </Routes>
  );
};
