import { useEffect } from 'react';

import {
  useHistory,
  useLocation,
} from '@npm/core/ui/components/molecules/Link';
import { NumberParam, useQueryParam } from 'use-query-params';

import { UrlParams } from '../../../../auth/user/context';

/**
 * Custom hook to observe and handle 'exitObo' query parameter in the URL.
 */
export const useObserveExitObo = (): void => {
  const location = useLocation();
  const history = useHistory();
  const [exitObo] = useQueryParam(UrlParams.EXIT_OBO, NumberParam);

  useEffect(() => {
    try {
      const params = new URLSearchParams(location.search);

      if (exitObo) {
        // Remove specific query parameters related to 'exitObo'
        [
          UrlParams.EXIT_OBO,
          UrlParams.OBO_ACCOUNT_ID,
          UrlParams.OBO_PERSON_ID,
          UrlParams.OBO_USER_ID,
        ].forEach((param: string) => params.delete(param));

        // Replace the current URL with the updated search parameters
        history.replace({ search: params.toString() });
      }
    } catch (error) {
      console.error('Failed to handle exitObo:', error);
    }
  }, [exitObo]);
};
