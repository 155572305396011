import {
  type CbLabelProps,
  type CbLabelComponent,
  type IconNames,
  type LabelVariant,
} from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  provided: 'provided',
  not_provided: 'not_provided',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  provided: 'success',
  not_provided: 'general',
};

const LabelIconMap: Record<Keys, IconNames> = {
  provided: 'check',
  not_provided: 'info-circle',
};

export const CbBankInformationStatus = {
  code: Codebooks.BANK_INFORMATION_STATUS,
  items: Items,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined,
    labelProps?: Partial<CbLabelProps>
  ) => Component({
      ...labelProps,
      variant: LabelMap[(codebookItem?.code as Keys) ?? 'not_provided'],
      label: codebookItem?.name ?? 'Not Provided',
      iconProps: { name: LabelIconMap[(codebookItem.code as Keys) ?? 'not_provided'] },
    })
};
