import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import {
  CbATSPool,
  CbVisibility,
  type SecondmarketOrderItemSimple,
} from '@npm/data-access';

export const VisibilityCell = ({
  item,
}: {
  item: SecondmarketOrderItemSimple;
}) => {
  const isInternal =
    item?.ats_pool?.code === CbATSPool.items.internal ||
    item?.visibility?.code === CbVisibility.items.internal;
  const visibility = item?.ats_pool?.name ?? item?.visibility?.name;

  return (
    <>
      <Text size="sm" colorVariant={isInternal ? 'tertiary' : 'primary'}>
        {isInternal
          ? formatDate(item?.order_received_date, {
              dateFormat: DATE_FORMATS.DATE,
            })
          : formatDate(item?.order_published_date, {
              dateFormat: DATE_FORMATS.DATE,
            })}
      </Text>
      <Text colorVariant="secondary" size="xs">
        {visibility}
      </Text>
    </>
  );
};
