import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

// applies only for mobile and tablet resolutions
export const useSidebarState = (initialSidebarOpenState = true) => {
  const { isMobile, isTablet } = useBreakpoints();

  const [{ checklistOpen }, setQueryParams] = useQueryParams({
    checklistOpen: BooleanParam,
    step: StringParam,
    personId: NumberParam,
    pageState: NumberParam,
  });

  const setSidebarOpen = (sidebarOpen: boolean) => {
    if (isMobile || isTablet) {
      setQueryParams(
        {
          checklistOpen: sidebarOpen,
          ...(sidebarOpen && {
            step: undefined,
            personId: undefined,
            pageState: undefined,
          }),
        },
        'replaceIn'
      );
    }
  };

  return [
    checklistOpen == null ? initialSidebarOpenState : checklistOpen,
    setSidebarOpen,
  ] as const;
};
