import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Loader } from '@npm/core/ui/components/atoms/Loader';
import {
  filterRoutesByFeatureFlag,
  getAllRoutesRecursively,
} from '@npm/core/ui/routes/index';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { useCurrentWorkstation } from '@npm/features/auth/user/role';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';

import { SecondMarketLayout } from '../components/Layout';

import { routes, workstationPrefix } from './routes.secondMarket';

export const SecondMarketRouter = () => {
  const { isEnabled } = useFeatureFlags();
  const workstation = useCurrentWorkstation();

  const allRoutes = useMemo(() => {
    return getAllRoutesRecursively(
      filterRoutesByFeatureFlag(isEnabled, routes)
    );
  }, []);

  if (!workstation) {
    // In rare cases, when entering or existing OBO, workstation can be undefined for a moment
    return <Loader />;
  }

  return (
    <Routes>
      <Route Component={SecondMarketLayout}>
        {allRoutes.map(({ needLogin, ...route }) => {
          const path = route.path.replace(`/${workstationPrefix}`, '');
          return needLogin !== false ? (
            <Route
              element={
                <ProtectedRoute permissionsChecks={route.permissionsChecks} />
              }
              key={path}
            >
              <Route path={path} Component={route.component} />
            </Route>
          ) : (
            <Route key={path} path={path} Component={route.component} />
          );
        })}
      </Route>
      <Route
        path="*"
        element={
          <ErrorPath
            heading="Error"
            title="Sorry, the page you visited does not exist."
            status="404"
          />
        }
      />
    </Routes>
  );
};
