import { useMemo } from 'react';

import { transparentise } from '@npm/core/ui/utils/style';
import { type CompanyPricingApi } from '@npm/data-access';
import { useTheme } from 'styled-components';

import type { CompanyOverviewChartDataSetEffect } from '../../../../TapeDPricing/TapeDPricing.types';
import {
  getBidsColor,
  getOffersColor,
  getTradesColor,
} from '../../../BidOfferHistory.utils';
import { convertOrderPriceToDataSet } from '../BidOfferHistoryChart.utils';

type HookOptions = {
  currentMinDate: Date;
  currentMaxDate: Date;
  series: 'PPS' | 'EST_VALUATION';
  effect?: CompanyOverviewChartDataSetEffect;
  order?: number;
};

const getConfigByType = (type: 'bid' | 'ask' | 'trade') => {
  switch (type) {
    case 'bid':
      return {
        label: 'Bid Level',
        getBorderColor: getBidsColor,
      };
    case 'ask':
      return {
        label: 'Offer Level',
        getBorderColor: getOffersColor,
      };
    case 'trade':
      return {
        label: 'Trade Level',
        getBorderColor: getTradesColor,
      };
    default:
      throw new Error('Invalid type');
  }
};

export const useOrderPriceDataSet = (
  orderPrices: CompanyPricingApi.OrderPrice[],
  type: 'bid' | 'ask' | 'trade',
  options: HookOptions
) => {
  const theme = useTheme();

  const { series, currentMinDate, currentMaxDate, effect, order } = options;

  return useMemo(() => {
    const data = convertOrderPriceToDataSet(
      orderPrices,
      series === 'PPS' ? 'price_per_share' : 'implied_valuation',
      type,
      currentMinDate,
      currentMaxDate
    );

    const typeConfig = getConfigByType(type);

    const borderColor = typeConfig.getBorderColor(theme);

    return {
      label: typeConfig.label,
      data: data.filter(Boolean),
      borderColor: function () {
        return effect === 'dim'
          ? transparentise(borderColor, 0.3)
          : borderColor;
      },
      borderWidth: effect === 'highlight' ? 3 : 1,
      order,
      segment: {
        borderWidth: ctx => {
          // Highlight the hovered line
          return effect === 'highlight' ||
            ctx?.p0?.raw?.raw === ctx?.chart?.hoveredRaw?.raw ||
            ctx?.p1?.raw?.raw === ctx?.chart?.hoveredRaw?.raw
            ? 3
            : 1;
        },
      },
    };
  }, [
    orderPrices,
    series,
    type,
    currentMinDate,
    currentMaxDate,
    theme,
    effect,
    order,
  ]);
};
