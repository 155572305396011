import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import {
  CrosshairPlugin,
  useChartOptions,
} from '@npm/core/ui/components/atoms/Charts';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Radio } from '@npm/core/ui/components/atoms/Radio';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { Chart } from 'chart.js';
import { throttle } from 'lodash';

import { useCompanyOverviewChartFilter } from '../../../CompanyOverview.hooks';
import {
  type CompanyOverviewChartSeries,
  type HoveredPoint,
  type PriceType,
} from '../../../CompanyOverview.types';
import {
  getCompanyOverviewXScaleConfig,
  getCompanyOverviewYScaleConfig,
  getMonthlyXLabels,
  sortArrayByDateField,
} from '../../../CompanyOverview.utils';
import { CompanyOverviewChartContainer } from '../../../components/CompanyOverviewChartContainer';
import { CompanyOverviewChartFilters } from '../../../components/CompanyOverviewChartFilters/CompanyOverviewChartFilters';

import { BidOfferHistoryChartLegend } from './components/BidOfferHistoryChartLegend';
import { BidOfferHistoryChartTooltip } from './components/BidOfferHistoryChartTooltip/BidOfferHistoryChartTooltip';
import { useOrderPriceDataSet } from './hooks/useOrderPriceDataSet';
import { usePrimaryRoundDataSet } from './hooks/usePrimaryRoundDataSet';

import * as S from './BidOfferHistoryChart.styles';
import { useBidOfferHistoryChartDateLimits } from './BidOfferHistoryChart.hooks';
import {
  type BidOfferData,
  type ChartMode,
  type SelectedPointData,
} from './BidOfferHistoryChart.types';
import {
  BID_OFFER_CHART_HTML_ID,
  getBidOffersChartTooltip,
  getDistanceOfThePointFromTheLine,
  showBidOfferHistoryChartTooltip,
} from './BidOfferHistoryChart.utils';

type Props = {
  data: BidOfferData;
  mode?: ChartMode;
};

export const BidOfferHistoryChart = ({ data, mode = 'bids_offers' }: Props) => {
  // Dark-mode context used to fix issues with switching between dark and light mode
  const { isDarkMode } = useDarkModeContext();

  const [activeItem, setActiveItem] = useState<SelectedPointData>(undefined);
  const [series, setSeries] = useState<CompanyOverviewChartSeries>('PPS');
  const [priceType, setPriceType] = useState<PriceType>('average');

  const hasValuation = useMemo(() => {
    if (mode === 'trades') {
      return data.aggregated_trades?.some(trade => !!trade.implied_valuation);
    }
    return (
      data.aggregated_bids?.some(trade => !!trade.implied_valuation) ||
      data.aggregated_asks?.some(trade => !!trade.implied_valuation) ||
      data.best_bids?.some(trade => !!trade.implied_valuation) ||
      data.best_asks?.some(trade => !!trade.implied_valuation)
    );
  }, [mode, data]);

  // Data need to be sorted as they don't come from API in correct order

  const [sortedBids, sortedAsks, sortedTrades, sortedValuations] =
    useMemo(() => {
      if (mode === 'trades') {
        return [
          [],
          [],
          sortArrayByDateField(data.aggregated_trades),
          sortArrayByDateField(data.primary_rounds),
        ];
      }

      if (priceType === 'best') {
        return [
          sortArrayByDateField(data.best_bids),
          sortArrayByDateField(data.best_asks),
          [],
          sortArrayByDateField(data.primary_rounds),
        ];
      }

      return [
        sortArrayByDateField(data.aggregated_bids),
        sortArrayByDateField(data.aggregated_asks),
        [],
        sortArrayByDateField(data.primary_rounds),
      ];
    }, [data, mode, priceType]);

  const [minDate, maxDate] = useBidOfferHistoryChartDateLimits(
    sortedBids,
    sortedAsks,
    sortedTrades,
    sortedValuations
  );

  const {
    setCurrentPage,
    currentMinDate,
    currentMaxDate,
    currentPage,
    pageCount,
    range,
    setRange,
  } = useCompanyOverviewChartFilter(minDate, maxDate);

  const labels = useMemo(() => {
    return getMonthlyXLabels(currentMinDate, currentMaxDate);
  }, [currentMinDate, currentMaxDate]);

  const datasetOptions = {
    series,
    currentMinDate,
    currentMaxDate,
  };

  const bidsDataSet = useOrderPriceDataSet(sortedBids, 'bid', datasetOptions);
  const offersDataSet = useOrderPriceDataSet(sortedAsks, 'ask', datasetOptions);
  const tradesDataSet = useOrderPriceDataSet(
    sortedTrades,
    'trade',
    datasetOptions
  );

  const valuationData = usePrimaryRoundDataSet(
    sortedValuations,
    datasetOptions
  );

  const options = useChartOptions();

  const canvasRef = useRef<HTMLCanvasElement>();

  const chartRef = useRef<Chart<'line', { x: string; y: number }[], string>>();

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  const resetTooltip = useCallback(() => {
    if (!chartRef.current) return;

    getBidOffersChartTooltip(chartRef.current.canvas).style.opacity = '0';

    (chartRef.current as unknown as HoveredPoint).hoveredRaw = {
      raw: null,
      nearestSegment: null,
    };

    chartRef.current.update('none');
  }, []);

  const handleMouseMove = useCallback(
    throttle((event: React.MouseEvent) => {
      if (!chartRef.current) return;

      const TOLERANCE = 10;

      const rect = chartRef.current.canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      const mouseP = { x: mouseX, y: mouseY };

      let minDistance = Infinity;
      let nearestSegment = null;

      chartRef.current.data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chartRef.current.getDatasetMeta(datasetIndex);
        const points = meta.data;

        for (let i = 0; i < points.length - 1; i++) {
          const p1 = points[i];
          const p2 = points[i + 1];

          let distance: number;
          if (dataset.stepped === 'before') {
            distance = Math.min(
              // horizontal line
              getDistanceOfThePointFromTheLine(mouseP, p1, {
                ...p2,
                y: p1.y,
              }),
              // vertical line
              getDistanceOfThePointFromTheLine(mouseP, { ...p1, x: p2.x }, p2)
            );
          } else {
            distance = getDistanceOfThePointFromTheLine(mouseP, p1, p2);
          }

          if (distance < minDistance && distance < TOLERANCE) {
            minDistance = distance;
            nearestSegment = { datasetIndex, p1, p2, distance };
          }
        }

        if (nearestSegment) {
          const data = (
            nearestSegment.p1?.raw as {
              raw: unknown;
            }
          )?.raw;

          showBidOfferHistoryChartTooltip(
            {
              canvas: chartRef.current?.canvas,
            },
            data as SelectedPointData,
            setActiveItem,
            nearestSegment,
            priceType
          );
          (chartRef.current as unknown as HoveredPoint).hoveredRaw = {
            raw: data,
            nearestSegment,
          };
          chartRef.current.update('none');
        } else {
          resetTooltip();
        }
      });
    }, 100),
    [resetTooltip, priceType]
  );

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new Chart<'line', { x: string; y: number }[], string>(
      ctx,
      {
        type: 'line',
        data: {
          labels,
          datasets:
            mode === 'trades'
              ? [valuationData, tradesDataSet]
              : [valuationData, bidsDataSet, offersDataSet],
        },
        options: options({
          interaction: {
            intersect: false,
            mode: 'point',
          },
          scales: {
            x: getCompanyOverviewXScaleConfig(currentMinDate, currentMaxDate),
            y: getCompanyOverviewYScaleConfig(),
          },
          animation: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }),
        plugins: [CrosshairPlugin],
      }
    );

    chartRef.current.update('none');

    return () => destroyChart();
  }, [
    bidsDataSet,
    offersDataSet,
    tradesDataSet,
    valuationData,
    mode,
    labels,
    isDarkMode,
    options,
    currentMinDate,
    currentMaxDate,
  ]);

  const priceTypeRadioFilter = useMemo(() => {
    return (
      <S.RadioGroup
        value={priceType}
        onChange={e => setPriceType(e.target.value)}
        style={{
          flexShrink: 0,
        }}
      >
        <Radio value="average" label="Average Price" />
        <Radio value="best" label="Best Bid / Offer" />
      </S.RadioGroup>
    );
  }, [priceType, setPriceType]);

  return (
    <CardBase noContentPadding={true}>
      <Flex direction="column" gap="xs">
        <CompanyOverviewChartFilters
          range={range}
          series={series}
          onChangeSeries={setSeries}
          canChangeSeries={hasValuation}
          onChangeRange={range => {
            setRange(range);
            resetTooltip();
          }}
          customFilterBefore={mode === 'bids_offers' && priceTypeRadioFilter}
          pagination={{
            page: currentPage,
            totalPages: pageCount,
            onPageChange: val => {
              setCurrentPage(val);
              resetTooltip();
            },
          }}
        />
        <CompanyOverviewChartContainer>
          <canvas ref={canvasRef} onMouseMove={handleMouseMove}></canvas>
          <div
            id={BID_OFFER_CHART_HTML_ID}
            className="html-chart-tooltip"
            style={{ opacity: activeItem ? 0.9 : 0 }}
          >
            <BidOfferHistoryChartTooltip
              point={activeItem}
              priceType={priceType}
            />
          </div>
        </CompanyOverviewChartContainer>
        <Margin bottom="sm">
          <BidOfferHistoryChartLegend mode={mode} />
        </Margin>
      </Flex>
    </CardBase>
  );
};
