import { Codebooks } from './codebooks.types';

const Items = {
  approve_all: 'approve_all',
  edit_holding: 'edit_holding',
  go_to_program: 'go_to_program',
  manage_transfer: 'manage_transfer',
  show_details: 'show_details',
  transfer_details: 'transfer_details',
} as const;

export type MatchActivityAction = typeof Items[keyof typeof Items];

export const CbMatchActivityAction = {
  code: Codebooks.MATCH_ACTIVITY_ACTION,
  items: Items,
};
