import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { type FolderAggregate } from '@npm/data-access';

import { getTaxDocumentTypeName } from '../../../DataRoom/DataRoom.utils';
import { type DataRoomsCards } from '../DataRoomsCards';

import * as S from './DataRoomCard.styles';

type Props = {
  dataRoom?: FolderAggregate;
  isLoading?: boolean;
} & Pick<ComponentProps<typeof DataRoomsCards>, 'onAccessClick'>;

export const DataRoomCard = ({ isLoading, dataRoom, onAccessClick }: Props) => {
  const { issuer_entity, owner_name, event_type, name } = dataRoom || {};

  const headerProps: ComponentProps<typeof Card>['header'] = issuer_entity
    ? {
        icon: (
          <CompanyLogo
            url={issuer_entity?.logo_url}
            title={issuer_entity?.logo_name}
            size="sm"
          />
        ),
        iconText: issuer_entity?.name,
        label: (
          <Label variant="info" round>
            {event_type?.name}
          </Label>
        ),
      }
    : {
        title: owner_name,
        label: getTaxDocumentTypeName(dataRoom) && (
          <Label variant="info" round>
            {getTaxDocumentTypeName(dataRoom)}
          </Label>
        ),
      };

  return (
    <Card
      isLoading={isLoading}
      header={headerProps}
      footer={<Button onClick={() => onAccessClick(dataRoom)}>Access</Button>}
    >
      <S.CardContent>
        <Text size="sm" colorVariant={'primary'}>
          {name}
        </Text>
      </S.CardContent>
    </Card>
  );
};
