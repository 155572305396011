import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { FullScreenLoading } from '@npm/core/ui/components/molecules/FullScreenLoading';
import {
  filterRoutesByFeatureFlag,
  getAllRoutesRecursively,
} from '@npm/core/ui/routes';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';
import { useIsMutating } from '@tanstack/react-query';

import { BrokerageLayout } from '../components/Layout/BrokerageLayout';

import { routes, workstationPrefix } from './routes.brokerageWorkstation';

type Props = {
  enabled: boolean;
};

export const BrokerageRouter = ({ enabled }: Props) => {
  const { isEnabled } = useFeatureFlags();

  const allRoutes = useMemo(() => {
    return getAllRoutesRecursively(
      filterRoutesByFeatureFlag(isEnabled, routes)
    );
  }, []);

  const isWorkstatingSwitchingInProgress =
    useIsMutating(['WorkstationCreate']) > 0;

  return (
    <Routes>
      {enabled && (
        <Route Component={BrokerageLayout}>
          {allRoutes.map(route => {
            const path = route.path.replace(`/${workstationPrefix}`, '');
            return route.needLogin !== false ? (
              <Route
                element={
                  <ProtectedRoute
                    permissionsChecks={route.permissionsChecks}
                    isLoading={isWorkstatingSwitchingInProgress}
                  />
                }
                key={path}
              >
                <Route path={path} Component={route.component} />
              </Route>
            ) : (
              <Route key={path} path={path} Component={route.component} />
            );
          })}
        </Route>
      )}
      {isWorkstatingSwitchingInProgress && (
        <Route
          path="*"
          element={
            <FullScreenLoading loadingText="Switching your workstation..." />
          }
        />
      )}
      <Route
        path="*"
        element={
          <ErrorPath
            heading="Error"
            title="Sorry, the page you visited does not exist."
            status="404"
          />
        }
      />
    </Routes>
  );
};
