// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompanyApi,
  GetCompanyErrorCodes,
  GetCompanyErrorTypes,
  UpdateCompanyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompanyApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type GetCompanyMethod = CompanyApi['getCompany'];
export type GetCompanyResponseType = MethodResult<GetCompanyMethod>;

export const useGetCompany = (
  variables: Parameters<GetCompanyMethod>[0],
  config?: UseQueryConfig<
    GetCompanyMethod,
    GetCompanyErrorTypes
  >
) => {
  return useQuery<
    GetCompanyMethod,
    GetCompanyErrorTypes
  >(
    (...args) => api.getCompany(...args),
    GetCompanyErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCompany'],
        ...apiQueryConfig.GetCompany,
        ...config?.queryConfig
      },
    },
    'GetCompany',
  );
};

export const useGetCompanyInfinite = (
  variables: Parameters<GetCompanyMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetCompanyMethod,
    GetCompanyErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetCompanyMethod,
    GetCompanyErrorTypes
  >(
    (...args) => api.getCompany(...args),
    GetCompanyErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCompany', 'GetCompanyInfinite'],
        ...apiQueryConfig.GetCompany,
        ...config?.queryConfig,
      },
    },
    'GetCompany',
  );
};

export const useGetCompanyLazy = (baseOptions?: {
  variables?: Parameters<GetCompanyMethod>[0],
  config?: UseQueryConfig<
    GetCompanyMethod,
    GetCompanyErrorTypes
  >
}) => {
  return useLazyQuery<
    GetCompanyMethod,
    GetCompanyErrorTypes
  >(
    (...args) => api.getCompany(...args),
    GetCompanyErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetCompany'],
        ...apiQueryConfig.GetCompany,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetCompany',
  );
};


export type UpdateCompanyMethod = CompanyApi['updateCompany'];

export const useUpdateCompany = () => {
  return useMutation<UpdateCompanyMethod>(
    (...args) => api.updateCompany(...args),
    UpdateCompanyErrorCodes,
    'UpdateCompany',
    '/api/companies/{id}',
    'put'
  );
};
