import { type ColorGroup, css, type Variant } from 'styled-components';

export const BORDER_THICKNESS = '2px';

export const getFocusBorderStyles = (
  borderColor?: ColorGroup,
  colorVariant?: Variant
) => css`
  &:focus {
    box-shadow: 0 0 0 ${BORDER_THICKNESS}
      ${({ theme }) =>
        borderColor
          ? theme.color[borderColor].borders[colorVariant || 'primary']
          : theme.color.info.borders.primary};
  }
`;

// preserves box shadow border for elements inside a wrapper with hidden overflow
export const preserveHorizontalFocusBorder = `
  padding-left: ${BORDER_THICKNESS};
  padding-right: ${BORDER_THICKNESS};
  margin-left: -${BORDER_THICKNESS};
  margin-right: -${BORDER_THICKNESS};
`;

export const preserveVerticalFocusBorder = `
  padding-top: ${BORDER_THICKNESS};
  padding-bottom: ${BORDER_THICKNESS};
  margin-top: -${BORDER_THICKNESS};
  margin-bottom: -${BORDER_THICKNESS};
`;

export const centerFlex = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const centerSelfAbsolute = `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ellipsisTextOverflow = `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const resetButtonStyles = css`
  display: inline-flex;
  align-items: center;
  background: ${({ theme }) => theme.color.transparent};
  border: none;
  outline: none;
`;

export const getVh = (percentage: number) => css`
  height: ${percentage}vh; // fallback
  height: calc(var(--vh, 1vh) * ${percentage});
`;
