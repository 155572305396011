import { useState } from 'react';

import { VenusApi } from '@npm/data-access';
import type { FormInstance } from 'antd';

import type { OrderValuationType } from '../order.types';

const { useCompanyImpliedValues } = VenusApi;

export const useOrderValuationToggle = (
  defaultValuationType?: OrderValuationType
) => {
  const [valuationType, setValuationType] = useState<OrderValuationType>(
    defaultValuationType ?? 'PPS'
  );

  const toggleValuationType = () => {
    setValuationType(sizeType =>
      sizeType === 'Valuation' ? 'PPS' : 'Valuation'
    );
  };

  return {
    valuationType,
    updateValuationType: setValuationType,
    toggleValuationType,
  };
};

export const useCalculatePpsOrValuation = (
  form: FormInstance,
  venusId: number,
  variant: OrderValuationType,
  valuation?: number,
  pricePerShare?: number,
  showValuation?: boolean
) => {
  const { data: companyProfile } = VenusApi.useCompanyProfile(
    {
      id: String(venusId),
    },
    {
      queryConfig: { enabled: !!venusId },
      onError: error => {
        if (error?.status === -1 && error?.originalStatus === 404) return;
        console.error(error);
      },
    }
  );

  const isQueryEnabled =
    showValuation &&
    companyProfile?.covered === true &&
    ((variant === 'Valuation' && !!valuation) ||
      (variant === 'PPS' && !!pricePerShare));

  useCompanyImpliedValues(
    {
      id: String(venusId),
      valuation: variant === 'Valuation' ? valuation : undefined,
      pricePerShare: variant === 'PPS' ? pricePerShare : undefined,
    },
    {
      queryConfig: { enabled: isQueryEnabled },
      onComplete: data => {
        if (variant === 'PPS') {
          form.setFieldValue('impliedValuation', data?.valuation);
        }
        if (variant === 'Valuation') {
          form.setFieldValue('pricePerShare', data?.price_per_share);
        }
      },
    }
  );
};
