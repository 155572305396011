import { Codebooks } from './codebooks.types';

const Items = {
  DOMESTIC_BANK: 'DomesticBank',
  INTERNATIONAL_BANK: 'InternationalBank',
  BROKERAGE_ACCOUNT: 'BrokerageAccount',
  DOMESTIC_CREDIT_UNION: 'DomesticCreditUnion',
  DOMESTIC_PARTNER_BANK: 'DomesticPartnerBank',
} as const;

export const CbBankAccountType = {
  code: Codebooks.BANK_ACCOUNT_TYPE,
  items: Items,
};
