import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md}px;
  margin-bottom: ${({ theme }) => theme.spacing.sm}px;

  h1 {
    overflow-wrap: anywhere;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    margin-top: ${({ theme }) => theme.spacing.sm}px;
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  }
`;
