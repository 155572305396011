// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AdminPartnerAccountApi,
  AdminPartnerAccountIndexErrorCodes,
  AdminPartnerAccountIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AdminPartnerAccountApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AdminPartnerAccountIndexMethod = AdminPartnerAccountApi['adminPartnerAccountIndex'];
export type AdminPartnerAccountIndexResponseType = MethodResult<AdminPartnerAccountIndexMethod>;

export const useAdminPartnerAccountIndex = (
  variables: Parameters<AdminPartnerAccountIndexMethod>[0],
  config?: UseQueryConfig<
    AdminPartnerAccountIndexMethod,
    AdminPartnerAccountIndexErrorTypes
  >
) => {
  return useQuery<
    AdminPartnerAccountIndexMethod,
    AdminPartnerAccountIndexErrorTypes
  >(
    (...args) => api.adminPartnerAccountIndex(...args),
    AdminPartnerAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdminPartnerAccountIndex'],
        ...apiQueryConfig.AdminPartnerAccountIndex,
        ...config?.queryConfig
      },
    },
    'AdminPartnerAccountIndex',
  );
};

export const useAdminPartnerAccountIndexInfinite = (
  variables: Parameters<AdminPartnerAccountIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AdminPartnerAccountIndexMethod,
    AdminPartnerAccountIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AdminPartnerAccountIndexMethod,
    AdminPartnerAccountIndexErrorTypes
  >(
    (...args) => api.adminPartnerAccountIndex(...args),
    AdminPartnerAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdminPartnerAccountIndex', 'AdminPartnerAccountIndexInfinite'],
        ...apiQueryConfig.AdminPartnerAccountIndex,
        ...config?.queryConfig,
      },
    },
    'AdminPartnerAccountIndex',
  );
};

export const useAdminPartnerAccountIndexLazy = (baseOptions?: {
  variables?: Parameters<AdminPartnerAccountIndexMethod>[0],
  config?: UseQueryConfig<
    AdminPartnerAccountIndexMethod,
    AdminPartnerAccountIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AdminPartnerAccountIndexMethod,
    AdminPartnerAccountIndexErrorTypes
  >(
    (...args) => api.adminPartnerAccountIndex(...args),
    AdminPartnerAccountIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AdminPartnerAccountIndex'],
        ...apiQueryConfig.AdminPartnerAccountIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AdminPartnerAccountIndex',
  );
};

