import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;

  ${({ theme }) => useBreakpoint(theme).smTablet`
      flex-direction: column;
  `}
`;

export const Col = styled.div<{
  $sideColumn?: boolean;
}>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: auto;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }

  ${({ $sideColumn }) =>
    $sideColumn &&
    `
    flex: 0 0 380px;
    max-width: 35%;
  `}

  ${({ theme, $sideColumn }) => useBreakpoint(theme).smTablet`
    flex-direction: column;
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: ${theme.spacing.sm}px;
    }

    ${
      $sideColumn &&
      `
      max-width: 100%;
      align-self: stretch;
    `
    }
  `}
`;
