import React, { type ComponentProps } from 'react';

import { BottomNavigation } from '@npm/core/ui/components/atoms/BottomNavigation';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';

import * as S from './UploadButtons.styles';

type Props = {
  onAddFolder?: () => void;
  onUploadClick?: () => void;
  actionsDisabled?: boolean;
};

const UploadButton = (props: ComponentProps<typeof Button>) => (
  <Button
    icon={<Icon name="plus" />}
    data-cy={CypressDataIds.DataRooms.UploadDrawer.TriggerButton}
    {...props}
  >
    Upload
  </Button>
);

const AddFolderButton = (props: ComponentProps<typeof Button>) => (
  <Button
    icon={<Icon name="plus" />}
    variant="outline"
    data-cy={CypressDataIds.DataRooms.AddFolderDrawer.TriggerButton}
    {...props}
  >
    Add Folder
  </Button>
);

export const UploadButtons = ({
  onAddFolder,
  onUploadClick,
  actionsDisabled,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const buttonSize = isMobile ? 'lg' : 'md';

  const addFolderButton = (
    <Tooltip title={getDisabledActionTooltipText(actionsDisabled)}>
      <AddFolderButton
        onClick={onAddFolder}
        variant="outline"
        size={buttonSize}
        disabled={actionsDisabled}
      />
    </Tooltip>
  );

  const uploadButton = (
    <Tooltip title={getDisabledActionTooltipText(actionsDisabled)}>
      <UploadButton
        onClick={onUploadClick}
        size={buttonSize}
        disabled={actionsDisabled}
      />
    </Tooltip>
  );

  return isMobile ? (
    <BottomNavigation backButton={addFolderButton} nextButton={uploadButton} />
  ) : (
    <S.UploadButtons>
      <>
        {addFolderButton}
        {uploadButton}
      </>
    </S.UploadButtons>
  );
};
