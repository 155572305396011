import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { CbWorkstationType } from '@npm/data-access';
import { type UserRoleApiUserRoleIndexRequest } from '@npm/data-access';

export const USER_ROLE_INDEX_BASE_PARAMS: UserRoleApiUserRoleIndexRequest = {
  size: PAGE_SIZE_HARD_LIMIT,
};

export const WORKSTATION_ICON_MAP = {
  [CbWorkstationType.items.brokerage]: <Icon name="building-bank" size="xs" />,
  [CbWorkstationType.items.issuer]: <Icon name="building" size="xs" />,
  [CbWorkstationType.items.investor]: <Icon name="building" size="xs" />,
  [CbWorkstationType.items.advisor]: <Icon name="building-bank" size="xs" />,
};

export enum UrlParams {
  ROLE_ID = 'roleId',
  SUBJECT_ID = 'subjectId',
  ALL_ACCOUNTS_MODE = 'allAccounts',
  ACCOUNT_ID = 'accountId',
  COMPANY_ID = 'companyId',
  OBO_ACCOUNT_ID = 'oboAccountId',
  OBO_USER_ID = 'oboUserId',
  OBO_PERSON_ID = 'oboPersonId',
  PERMISSIONS_CHECK_OFF = 'permissionsCheckOff',
  EXIT_OBO = 'exitObo',
  NOTIFICATION_SETTINGS_OPEN = 'notificationSettingsOpen',
}
