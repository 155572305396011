import React, { type Component, forwardRef } from 'react';

import { type RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { type PickerMode } from 'rc-picker/lib/interface';
import { createGlobalStyle } from 'styled-components';

import { Icon } from '../Icon';

import * as S from './DateRangePickerInput.styles';

export type DateRangePickerInputProps = Omit<
  RangePickerProps<Date>,
  'picker' | 'locale' | 'generateConfig' | 'hideHeader' | 'components'
> & {
  picker?: Exclude<PickerMode, 'time'>;
};

export const DateRangePickerGlobalStyles = createGlobalStyle`
  .ant-picker-dropdown {
    background: transparent;
  }
`;

export const DateRangePickerInput = forwardRef<
  Component<DateRangePickerInputProps>,
  DateRangePickerInputProps
>(function DateRangePickerInput({
  value,
  ...props
}: DateRangePickerInputProps) {
  return (
    <>
      <DateRangePickerGlobalStyles />
      <S.RangePicker
        value={value}
        suffixIcon={<Icon name="calendar" />}
        disabledDate={date => date > new Date()}
        picker="date"
        panelRender={panel => (
          <S.DateRangePickerStyledPanel>{panel}</S.DateRangePickerStyledPanel>
        )}
        {...props}
      />
    </>
  );
});
