import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type MenuItem,
  type MenuItemGroup,
} from '@npm/core/ui/components/organisms/Layout';
import { RBAC } from '@npm/core/ui/models/permissions';
import {
  filterMenuItems,
  type UserContextStore,
} from '@npm/features/auth/user/context';
import type { UserRoleContextType } from '@npm/features/auth/user/role/userRole.types';

const { isAdvisorAdmin } = RBAC.advisor;

const workstationPrefix = 'advisor-workstation';

export const getAdvisorSidebarItems: (
  userContext: UserContextStore,
  userRole: UserRoleContextType | null
) => (MenuItem | MenuItemGroup)[] = (userContext, userRole) => {
  return filterMenuItems(
    [
      {
        label: 'Dashboard',
        key: `/${workstationPrefix}/dashboard`,
        icon: <Icon name="layout-dashboard" />,
        permissionsChecks: [isAdvisorAdmin],
      },
      {
        label: 'Prospects',
        key: `/${workstationPrefix}/prospects`,
        icon: <Icon name="user" />,
        permissionsChecks: [isAdvisorAdmin],
      },
    ],
    userContext,
    userRole,
    null
  );
};
