import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { eventBus } from '@npm/core/ui/utils/eventBus';
import { useOktaUserClaims } from '@npm/okta';

import { useUserContextStore } from '../../../auth/user/context';

type Props = {
  handleGoBack?: () => void;
};

export const UnauthorizedContent = ({ handleGoBack }: Props) => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();
  const user = useUserContextStore(s => s.user);
  const oktaUserClaims = useOktaUserClaims();
  const userEmail = user?.email ?? oktaUserClaims?.email;

  const handleGoBackClick = () => {
    if (handleGoBack) {
      handleGoBack();
    } else if (window.history.length < 2) {
      history.replace('/');
    } else {
      history.goBack();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Text
        size="sm"
        style={{ maxWidth: '600px', minHeight: '64px' }}
        marginBottom="md"
      >
        {userEmail
          ? `You are logged in as ${userEmail}, but it seems you don’t
          have necessary permissions to access this content. If you believe this
          is an error, please contact support for assistance.`
          : 'Access Denied! You are not authorized to access this page.'}
      </Text>
      <Flex
        gap="sm"
        justify="center"
        direction={isMobile ? 'column-reverse' : 'row'}
      >
        <Button
          variant="text"
          icon={<Icon name="arrow-narrow-left" />}
          onClick={handleGoBackClick}
          blockOnMobile
        >
          Go Back
        </Button>
        <Button
          variant="outline"
          onClick={() =>
            eventBus.dispatch('REQUEST_SUPPORT', { key: 'page_error' })
          }
          icon={<Icon name="message" />}
          blockOnMobile
        >
          Help & Support
        </Button>
      </Flex>
    </div>
  );
};
