/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ServiceToServiceUserlessHoldingsSmHoldingCreateBatch } from '../model';
// @ts-ignore
import { ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract } from '../model';
/**
 * S2SUserlessSMHoldingCreationBatchesApi - axios parameter creator
 * @export
 */
export const S2SUserlessSMHoldingCreationBatchesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates holding creation batches without user permission validation
         * @param {string} xNPMAuthorization 
         * @param {ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract} serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUserlessBulkCreateSMHoldingCreationBatches: async (xNPMAuthorization: string, serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xNPMAuthorization' is not null or undefined
            assertParamExists('s2SUserlessBulkCreateSMHoldingCreationBatches', 'xNPMAuthorization', xNPMAuthorization)
            // verify required parameter 'serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract' is not null or undefined
            assertParamExists('s2SUserlessBulkCreateSMHoldingCreationBatches', 'serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract', serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract)
            const localVarPath = changeUrl(`/api/s2s/userless/sm_holdings/create_batch`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S2SUserlessSMHoldingCreationBatchesApi - functional programming interface
 * @export
 */
export const S2SUserlessSMHoldingCreationBatchesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S2SUserlessSMHoldingCreationBatchesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates holding creation batches without user permission validation
         * @param {string} xNPMAuthorization 
         * @param {ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract} serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SUserlessBulkCreateSMHoldingCreationBatches(xNPMAuthorization: string, serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceToServiceUserlessHoldingsSmHoldingCreateBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SUserlessBulkCreateSMHoldingCreationBatches(xNPMAuthorization, serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S2SUserlessSMHoldingCreationBatchesApi - factory interface
 * @export
 */
export const S2SUserlessSMHoldingCreationBatchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S2SUserlessSMHoldingCreationBatchesApiFp(configuration)
    return {
        /**
         * 
         * @summary creates holding creation batches without user permission validation
         * @param {string} xNPMAuthorization 
         * @param {ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract} serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUserlessBulkCreateSMHoldingCreationBatches(xNPMAuthorization: string, serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract, xWorkstation?: string, options?: any): AxiosPromise<ServiceToServiceUserlessHoldingsSmHoldingCreateBatch> {
            return localVarFp.s2SUserlessBulkCreateSMHoldingCreationBatches(xNPMAuthorization, serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract, xWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for s2SUserlessBulkCreateSMHoldingCreationBatches operation in S2SUserlessSMHoldingCreationBatchesApi.
 * @export
 * @interface S2SUserlessSMHoldingCreationBatchesApiS2SUserlessBulkCreateSMHoldingCreationBatchesRequest
 */
export interface S2SUserlessSMHoldingCreationBatchesApiS2SUserlessBulkCreateSMHoldingCreationBatchesRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SUserlessSMHoldingCreationBatchesApiS2SUserlessBulkCreateSMHoldingCreationBatches
     */
    readonly xNPMAuthorization: string

    /**
     * 
     * @type {ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract}
     * @memberof S2SUserlessSMHoldingCreationBatchesApiS2SUserlessBulkCreateSMHoldingCreationBatches
     */
    readonly serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof S2SUserlessSMHoldingCreationBatchesApiS2SUserlessBulkCreateSMHoldingCreationBatches
     */
    readonly xWorkstation?: string
}

/**
 * S2SUserlessSMHoldingCreationBatchesApi - object-oriented interface
 * @export
 * @class S2SUserlessSMHoldingCreationBatchesApi
 * @extends {BaseAPI}
 */
export class S2SUserlessSMHoldingCreationBatchesApi extends BaseAPI {
    /**
     * 
     * @summary creates holding creation batches without user permission validation
     * @param {S2SUserlessSMHoldingCreationBatchesApiS2SUserlessBulkCreateSMHoldingCreationBatchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SUserlessSMHoldingCreationBatchesApi
     */
    public s2SUserlessBulkCreateSMHoldingCreationBatches(requestParameters: S2SUserlessSMHoldingCreationBatchesApiS2SUserlessBulkCreateSMHoldingCreationBatchesRequest, options?: AxiosRequestConfig) {
        return S2SUserlessSMHoldingCreationBatchesApiFp(this.configuration).s2SUserlessBulkCreateSMHoldingCreationBatches(requestParameters.xNPMAuthorization, requestParameters.serviceToServiceUserlessHoldingsSmHoldingsCreateBatchCreateRequestContract, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}

export type S2SUserlessBulkCreateSMHoldingCreationBatchesErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SUserlessBulkCreateSMHoldingCreationBatchesErrorCodes = [
    422,
];


