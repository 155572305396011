import { useMemo } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import type { ButtonVariant } from '@npm/core/ui/components/atoms/Button/Button.types';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  CbSecondMarketOrderSort,
  Codebooks,
  useCodebook,
} from '@npm/data-access';

type Props = {
  value: string | undefined;
  onChange: (sortBy: string) => void;
  label?: string;
  buttonVariant?: ButtonVariant;
  buttonAlignment?: 'left' | 'right';
  omitSortValues?: string[];
  fixedPosition?: boolean;
};

export const SortDropdown = ({
  value,
  onChange,
  buttonVariant = 'outline',
  buttonAlignment = 'right',
  omitSortValues,
  fixedPosition = false,
}: Props) => {
  const { data } = useCodebook({
    type: Codebooks.SECONDMARKET_ORDER_SORT,
  });

  const filteredCodebookItems = omitSortValues
    ? data?.codebooks?.filter(({ code }) => !omitSortValues.includes(code))
    : data?.codebooks;

  const selectedCodebookValue = useMemo(
    () => data?.codebooks?.find(({ code }) => code === value),
    [data, value]
  );

  const dropdownItems =
    filteredCodebookItems?.map(({ code, name }) => ({
      label: name,
      onClick: () => onChange(code),
      icon: CbSecondMarketOrderSort.iconMap[code] && (
        <Icon name={CbSecondMarketOrderSort.iconMap[code]} />
      ),
    })) ?? [];

  return (
    <Dropdown items={dropdownItems} fixedPosition={fixedPosition}>
      <Button
        variant={buttonVariant}
        size="md"
        icon={<Icon name="sort-descending" />}
        iconPosition="right"
        style={{ marginLeft: buttonAlignment === 'right' ? 'auto' : undefined }}
      >
        {selectedCodebookValue?.name}
      </Button>
    </Dropdown>
  );
};
