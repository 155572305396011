import { useState } from 'react';

import { type Holding } from '@npm/data-access';

import { type ProofOfOwnershipDrawerProps } from './ProofOfOwnershipDrawer';

type Return = [
  {
    openProofOfOwnershipDrawer: (holding: Holding) => void;
  },
  ProofOfOwnershipDrawerProps,
];

export const useProofOfOwnershipDrawer = (): Return => {
  const [open, setOpen] = useState(false);
  const [holdingId, setHoldingId] = useState<number | null>(null);

  const openProofOfOwnershipDrawer = (holding: Holding) => {
    setOpen(true);
    setHoldingId(holding.id);
  };

  return [
    { openProofOfOwnershipDrawer },
    {
      open,
      holdingId,
      onClose: () => setOpen(false),
    },
  ];
};
