import { useEffect } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  CbSecondMarketOrderSort,
  type IssuerEntityAggregate,
} from '@npm/data-access';
import pluralize from 'pluralize';
import { useTheme } from 'styled-components';

import { SortDropdown } from '../../../../../../components/SortDropdown';
import { OrderCard } from '../../../../../CompanyMarket/BidsAndOffers/BidsAndOffersList/OrderCard';
import { type UseSellIndicationInventoryReminderReturn } from '../../../useSellIndicationDrawer.hooks';

import { SellIndicationOrderCard } from './SellIndicationOrderCard';

type Props = {
  issuerEntity?: IssuerEntityAggregate;
  goToNextStep: () => void;
} & UseSellIndicationInventoryReminderReturn;

export const SellIndicationInventoryReminder = ({
  issuerEntity,
  orders,
  totalOrders,
  fetchNextPage,
  hasNextPage,
  isLoading,
  isFetchingNextPage,
  sort,
  setSort,
  goToNextStep,
}: Props) => {
  const theme = useTheme();

  useEffect(() => {
    if (!orders?.length && !isLoading) {
      goToNextStep();
    }
  }, [orders, isLoading, goToNextStep]);

  if (!orders?.length) {
    return null;
  }

  return (
    <Flex direction={'column'} gap={'md'}>
      <Alert
        type={'info'}
        showIcon={false}
        style={{ padding: `${theme.spacing.md}` }}
        message={
          <Flex direction={'column'} gap={'sm'}>
            <Text size={'sm'} weight={'bold'} colorVariant={'primary'}>
              We have buyers looking to purchase {issuerEntity.name}
            </Text>

            <Text size={'sm'} colorVariant={'secondary'}>
              Sell your shares to any bid below. Your offer will remain
              anonymous until you match.
            </Text>
          </Flex>
        }
      />
      <Flex align="center" justify="space-between" gap={'sm'}>
        <Label round bordered={false}>
          {totalOrders} {pluralize('Bid', totalOrders)} from{' '}
          {pluralize('Buyer', totalOrders)}
        </Label>
        <SortDropdown
          value={sort}
          buttonVariant="text"
          buttonAlignment="left"
          fixedPosition
          onChange={setSort}
          omitSortValues={[
            CbSecondMarketOrderSort.items.valuation_ascending,
            CbSecondMarketOrderSort.items.valuation_descending,
          ]}
        />
      </Flex>

      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetchingNextPage}
        loader={<OrderCard order={undefined} isLoading />}
      >
        {orders.map(order => (
          <SellIndicationOrderCard key={order.id} order={order} />
        ))}
      </InfiniteScroll>
    </Flex>
  );
};
