// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FlowScreenerApi,
  FlowScreenerIndexErrorCodes,
  FlowScreenerIndexErrorTypes,
  FlowScreenerCreateErrorCodes,
  FlowScreenerUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FlowScreenerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FlowScreenerIndexMethod = FlowScreenerApi['flowScreenerIndex'];
export type FlowScreenerIndexResponseType = MethodResult<FlowScreenerIndexMethod>;

export const useFlowScreenerIndex = (
  variables: Parameters<FlowScreenerIndexMethod>[0],
  config?: UseQueryConfig<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >
) => {
  return useQuery<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >(
    (...args) => api.flowScreenerIndex(...args),
    FlowScreenerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FlowScreenerIndex'],
        ...apiQueryConfig.FlowScreenerIndex,
        ...config?.queryConfig
      },
    },
    'FlowScreenerIndex',
  );
};

export const useFlowScreenerIndexInfinite = (
  variables: Parameters<FlowScreenerIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >(
    (...args) => api.flowScreenerIndex(...args),
    FlowScreenerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FlowScreenerIndex', 'FlowScreenerIndexInfinite'],
        ...apiQueryConfig.FlowScreenerIndex,
        ...config?.queryConfig,
      },
    },
    'FlowScreenerIndex',
  );
};

export const useFlowScreenerIndexLazy = (baseOptions?: {
  variables?: Parameters<FlowScreenerIndexMethod>[0],
  config?: UseQueryConfig<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >(
    (...args) => api.flowScreenerIndex(...args),
    FlowScreenerIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FlowScreenerIndex'],
        ...apiQueryConfig.FlowScreenerIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FlowScreenerIndex',
  );
};


export type FlowScreenerCreateMethod = FlowScreenerApi['flowScreenerCreate'];

export const useFlowScreenerCreate = () => {
  return useMutation<FlowScreenerCreateMethod>(
    (...args) => api.flowScreenerCreate(...args),
    FlowScreenerCreateErrorCodes,
    'FlowScreenerCreate',
    '/api/flow-screeners',
    'post'
  );
};

export type FlowScreenerUpdateMethod = FlowScreenerApi['flowScreenerUpdate'];

export const useFlowScreenerUpdate = () => {
  return useMutation<FlowScreenerUpdateMethod>(
    (...args) => api.flowScreenerUpdate(...args),
    FlowScreenerUpdateErrorCodes,
    'FlowScreenerUpdate',
    '/api/flow-screeners/{id}',
    'put'
  );
};
