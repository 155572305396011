import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  min-width: 320px;
  max-width: 400px;
  margin-left: auto;
  background: ${({ theme }) => theme.color.general.layout.two};
  border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.sm * 3}px;
  overflow: hidden;

  ${({ theme }) => useBreakpoint(theme).mobile`
    max-width: 95vw;
  `}
`;

export const InfoWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px;
`;

export const ActionsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sm}px
    ${({ theme }) => theme.spacing.md}px;
  background: ${({ theme }) => theme.color.general.layout.one};
`;

export const ActionRow = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm}px;
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing.sm}px;
    vertical-align: middle;
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }
`;
