import styled from 'styled-components';

import { type TextSize } from '../Typography';

const SIZE_MAP: Record<TextSize, number> = {
  xxs: 20,
  xs: 24,
  sm: 28,
  default: 32,
  lg: 36,
  xl: 48,
  xxl: 56,
};

const TextIcon = styled.div<{ $size: TextSize }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${props => SIZE_MAP[props.$size]}px;
  height: ${props => SIZE_MAP[props.$size]}px;

  background-color: ${({ theme }) => theme.color.general.layout.two};

  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
`;

export const TextIconStyles = {
  TextIcon,
};
