import { TimePicker as TimePickerBase } from 'antd';
import 'antd/lib/time-picker/style/index';
import styled from 'styled-components';

import { getSize, getVariant } from '../../Button/Button.styles';
import { InputTextStyles } from '../../Typography';
import { disabledCSS, errorStatusCSS, inputCSS } from '../styles';

const SUFFIX_WIDTH = 25;

export const Panel = styled.div`
  && {
    .ant-picker-time-panel .ant-picker-time-panel-column {
      overflow-y: auto;
      scrollbar-width: thin;
    }

    .ant-picker-header {
      color: ${({ theme }) => theme.color.general.typography.primary};
    }

    .ant-picker-content
      .ant-picker-time-panel-cell
      .ant-picker-time-panel-cell-inner {
      color: ${({ theme }) => theme.color.general.typography.primary};
    }

    .ant-picker-content
      .ant-picker-time-panel-cell
      .ant-picker-time-panel-cell-inner:hover {
      background-color: ${({ theme }) => theme.color.general.backgrounds.muted};
    }

    .ant-picker-time-panel,
    .ant-picker-time-panel .ant-picker-time-panel-column {
      border-left-color: ${({ theme }) => theme.color.general.borders.primary};
    }

    .ant-picker-cell-selected > .ant-picker-cell-inner,
    .ant-picker-cell-selected > .ant-picker-cell-inner:hover,
    .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner,
    .ant-picker-time-panel-cell-selected
      > .ant-picker-time-panel-cell-inner:hover {
      background-color: ${({ theme }) =>
        theme.color.info.borders.secondary} !important;
      color: ${({ theme }) =>
        theme.color.general.typography.primary} !important;
    }

    .ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
      color: ${({ theme }) => theme.color.general.typography.muted} !important;
    }

    .ant-picker-cell-today > .ant-picker-cell-inner::before {
      border: 1px solid ${({ theme }) => theme.color.info.borders.primary};
    }

    .ant-picker-cell-selected > .ant-picker-cell-inner::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: ${({ theme }) => theme.zIndex.dropdown};
      border: 1px solid ${({ theme }) => theme.color.info.borders.primary};
      border-radius: 2px;
      content: '';
    }

    .ant-picker-ok > button {
      ${({ theme }) => getSize('md', false, theme)};
      ${({ theme }) => getVariant('outline', 'info', theme)};
    }
  }
`;

export const TimePicker = styled(TimePickerBase)`
  &.ant-picker {
    ${inputCSS};
    width: 100%;
    overflow: hidden;

    & > .ant-picker-input {
      & > input {
        ${InputTextStyles};
        padding-right: ${({ theme }) => theme.spacing.sm}px;
      }

      & > input::placeholder {
        color: ${({ theme }) => theme.color.general.typography.secondary};
      }

      & > .ant-picker-suffix {
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          color: ${({ theme }) => theme.color.general.typography.secondary};
        }
      }

      & > .ant-picker-clear {
        right: calc(${SUFFIX_WIDTH}px + ${({ theme }) => theme.spacing.xs}px);
        color: ${({ theme }) => theme.color.info.typography.primary};
        background: none;
      }
    }

    &.ant-picker-focused .ant-picker-suffix > svg {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  &.ant-picker-small {
    padding: ${({ theme }) => theme.spacing.sm}px !important;
    min-height: 32px !important;
  }

  &.ant-picker-status-error:not(.ant-picker-disabled):not(
      .ant-picker-borderless
    ).ant-picker {
    ${errorStatusCSS};
  }

  &.ant-picker-status-warning:not(.ant-picker-disabled):not(
      .ant-picker-borderless
    ).ant-picker {
    ${errorStatusCSS}
  }

  &.ant-picker-disabled {
    ${disabledCSS};
  }
`;
