import React, { type ComponentProps, useCallback, useMemo } from 'react';

import { InputNumber } from '@npm/core/ui/components/atoms/Input';
import type { InputNumberProps } from 'antd';
import { debounce } from 'lodash';

import { type FilterItemProps } from '../FilterPanel';

type Props = FilterItemProps &
  ComponentProps<typeof InputNumber> & { filterOnChange?: boolean };

export const NumberFilter = ({
  name,
  onFilterSubmit,
  filterOnChange = true,
  onChange,
  ...rest
}: Props) => {
  const handleSearch = useCallback(() => {
    onFilterSubmit?.(name, !filterOnChange);
  }, [onFilterSubmit, name]);

  const debouncedSearch = useMemo(
    () => debounce(handleSearch, 500),
    [handleSearch]
  );

  const handleChange = (...args: Parameters<InputNumberProps['onChange']>) => {
    onChange(...args);
    debouncedSearch();
  };

  return <InputNumber onChange={handleChange} {...rest} />;
};
