import { Flex } from '@npm/core/ui/components/atoms/common';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.sm}px;
`;

export const SectionDescriptionWrapper = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.sm}px;
  background-color: ${({ theme }) =>
    theme.color.info.backgrounds.secondaryDefault};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid ${({ theme }) => theme.color.info.borders.primary};
`;
