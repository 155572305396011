/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Company-Pricing API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MotifReturns } from '../model';
/**
 * MotifApi - axios parameter creator
 * @export
 */
export const MotifApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get Rates of Returns
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMotifReturns: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMotifReturns', 'id', id)
            const localVarPath = changeUrl(`/api/motifs/{id}/returns`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MotifApi - functional programming interface
 * @export
 */
export const MotifApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MotifApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get Rates of Returns
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMotifReturns(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MotifReturns>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMotifReturns(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MotifApi - factory interface
 * @export
 */
export const MotifApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MotifApiFp(configuration)
    return {
        /**
         * 
         * @summary get Rates of Returns
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMotifReturns(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<MotifReturns> {
            return localVarFp.getMotifReturns(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMotifReturns operation in MotifApi.
 * @export
 * @interface MotifApiGetMotifReturnsRequest
 */
export interface MotifApiGetMotifReturnsRequest {
    /**
     * 
     * @type {number}
     * @memberof MotifApiGetMotifReturns
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof MotifApiGetMotifReturns
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof MotifApiGetMotifReturns
     */
    readonly xSubscriptionPlan?: string
}

/**
 * MotifApi - object-oriented interface
 * @export
 * @class MotifApi
 * @extends {BaseAPI}
 */
export class MotifApi extends BaseAPI {
    /**
     * 
     * @summary get Rates of Returns
     * @param {MotifApiGetMotifReturnsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MotifApi
     */
    public getMotifReturns(requestParameters: MotifApiGetMotifReturnsRequest, options?: AxiosRequestConfig) {
        return MotifApiFp(this.configuration).getMotifReturns(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetMotifReturnsErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetMotifReturnsErrorCodes = [
    401,
];


