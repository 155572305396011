import React from 'react';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { type VenusApi } from '@npm/data-access';
import { Grid, type PaginationProps } from 'antd';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../../documents/DocumentViewer';
import { useHandleFilingClick } from '../../CompanyOverview.hooks';

import * as S from './InvestmentDataTable.styles';
import { getInvestmentDataColumns } from './InvestmentDataTable.columns';
import { getTableRowClassName } from './InvestmentDataTable.utils';

type Props = {
  venusCompanyId: number | undefined;
  issuerEntityId: number | undefined;
  data: VenusApi.FinancialActivity[] | undefined;
  isLoading?: boolean;
  pagination?: PaginationProps;
  onTableChange?: (pagination: PaginationProps) => void;
  defaultExpandedRowKeys?: string[];
  includeUnclassified?: boolean;
  setIncludeUnclassified?: (value: boolean) => void;
};

const { useBreakpoint } = Grid;

export const InvestmentDataTable = ({
  venusCompanyId,
  issuerEntityId,
  data,
  isLoading,
  pagination,
  onTableChange,
  defaultExpandedRowKeys,
  includeUnclassified,
  setIncludeUnclassified,
}: Props) => {
  const breakpoint = useBreakpoint();

  const { documentViewerModalProps, showDocumentPreview } = useDocumentViewer();

  const { handleFilingClick, filingIdLoading } = useHandleFilingClick(
    venusCompanyId,
    issuerEntityId,
    showDocumentPreview
  );

  return (
    <S.StyledCard
      variant="secondMarket"
      header={{
        title: 'Financing Activity',
        label: (
          <Checkbox
            checked={includeUnclassified}
            onChange={event => {
              setIncludeUnclassified(event?.target?.checked);
              onTableChange?.({ ...pagination, current: 1 });
            }}
            label="Include unclassified financings"
          />
        ),
      }}
      noContentPadding
    >
      <Table<VenusApi.FinancialActivity>
        outlined={false}
        columns={getInvestmentDataColumns({
          onFilingsClick: handleFilingClick,
          isMobile: !breakpoint.md,
          filingIdLoading,
        })}
        rowKey={
          (record: VenusApi.FinancialActivity) =>
            record.id?.toString() + data?.indexOf(record).toString() // IDs are not unique here
        }
        rowClassName={record => getTableRowClassName(record.id)}
        dataSource={data}
        loading={isLoading}
        expandable={{
          defaultExpandedRowKeys,
          showExpandColumn: !breakpoint.sm,
        }}
        pagination={pagination}
        onChange={onTableChange}
      />
      <DocumentViewerModal {...documentViewerModalProps} />
    </S.StyledCard>
  );
};
