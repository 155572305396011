import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import {
  type AccountShowAggregate,
  type CbBackgroundCheckState,
  type CbOnboardingStatus,
  type OnboardingStatus,
  type Person,
} from '@npm/data-access';

type OnboardingVariant = 'npms' | 'non-npms';

export type PostOnboardingContextType = {
  account: AccountShowAggregate;
  onboardingStatus: OnboardingStatus;
  activeSectionProps: {
    activeSection: OnboardingSectionConfig;
    stepIndex: number;
    totalSteps: number;
    isSubstep: boolean;
    totalStepSubsteps: number;
    substepIndex: number;
  };
  sectionsProgress: Record<SectionKey, number>;
  isInvitedToLivePrograms: boolean;
  representative?: Person;
  hideBottomNavigation?: boolean;
};

export type GetWizardStepsArgs = {
  variant: OnboardingVariant;
  onboardingStatus: OnboardingStatus | undefined;
  personId?: number;
  backgroundCheckStatus?: keyof (typeof CbBackgroundCheckState)['items'];
  canEditSpouse?: boolean;
  shouldUsePersona?: boolean;
  npmsStepsArgs?: {
    isRequiredForMarketplaceSectionCompleted?: boolean;
    isIndividualSellerOnly?: boolean;
  };
};

export type GetWizardStepsFn = (
  args: GetWizardStepsArgs & {
    getSectionsConfig: (args: GetWizardStepsArgs) => OnboardingSectionConfig[];
  }
) => Wizard.StepDefinition[];

export type GetSectionsConfigFn = (
  args: GetWizardStepsArgs
) => OnboardingSectionConfig[];

export type OnboardingStep = Wizard.StepDefinition & {
  substeps?: Wizard.StepDefinition[];
  tooltip?: string;
};

export type OnboardingSectionConfig = {
  key: SectionKey;
  items?: (
    | OnboardingSectionItemWithSubsteps
    | OnboardingSectionItemWithoutSubsteps
  )[];
  title: string;
  tooltip?: string;
  subTitle?: string;
  subTitleTooltip?: string;
  showProgress?: boolean;
  extraContent?: React.ReactNode;
  hideContentHeader?: boolean;
  disableExpand?: boolean;
  v2SectionProps?: {
    durationInMin?: number;
    isMainSection?: boolean;
    hidden?: boolean;
  };
};

export const sectionKeyToTitleMap = {
  required_for_marketplace: 'Required for Marketplace', // NPMS
  aml_kyc: 'AML / KYC', // non-NPMS
  identity_verification: 'Identity Verification', // NPMS
  agreements: 'Agreements', // non-NPMS
  other_requirements: 'Other Requirements', // both
};

export const PERSONA_IDENTITY_VERIFICATION_STEP_KEY =
  'persona_identity_verification';

export const parentStepKeyToTitleMap = {
  [PERSONA_IDENTITY_VERIFICATION_STEP_KEY]: 'Identity Verification',
  personal_information: 'Personal Information',
  entity_information: 'Entity Information',
  identity_verification: 'Identity Verification',
  representative_identity_verification: 'Representative Information',
};

export type SectionKey = keyof typeof sectionKeyToTitleMap;
export type ParentStepKey = keyof typeof parentStepKeyToTitleMap;
export type StepKey = keyof typeof CbOnboardingStatus.steps;

export type OnboardingSectionItemWithSubsteps = OnboardingStep & {
  key: ParentStepKey;
  substeps: OnboardingSectionItemWithoutSubsteps[];
};
export type OnboardingSectionItemWithoutSubsteps = OnboardingStep & {
  key: StepKey;
};
