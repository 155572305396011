import React, { type ComponentProps } from 'react';

import { type TooltipPlacement } from 'antd/es/tooltip';

import { Icon } from '../Icon';

import * as S from './Tooltip.styles';
import { Tooltip } from './Tooltip';

type Props = {
  title: ComponentProps<typeof Tooltip>['title'];
  tooltipPlacement?: TooltipPlacement;
  iconProps?: Omit<ComponentProps<typeof Icon>, 'name'>;
  style?: React.CSSProperties;
};

export const TooltipInfoIcon = ({
  title,
  iconProps,
  tooltipPlacement,
  style,
}: Props) => (
  <Tooltip title={title} placement={tooltipPlacement}>
    <S.TooltipInfoIcon style={style}>
      <Icon name="info-circle" size="xs" {...iconProps} />
    </S.TooltipInfoIcon>
  </Tooltip>
);
