import React, { useState } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useLocation } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { PageHeader } from '@npm/core/ui/components/molecules/PageHeader';
import { getPageSize } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useTotalRecords } from '@npm/core/ui/hooks/useTotalRecords';
import {
  useIssuerEntityShow,
  useWatchlistIndex,
  type WatchlistApiWatchlistIndexRequest,
} from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';
import { useCurrentAccount } from '../../auth/user/role';
import { useIsInvestor } from '../../auth/user/role/hooks/useIsInvestor';
import {
  FILTER_QUERY_KEY,
  FilterPanel,
  IssuerEntityFilter,
  usePersistedFilters,
} from '../../filters';
import { useFilterFormSync } from '../../filters/filters/FilterPanel/hooks/useFilterFormSync';

import { AddWatchlistDrawer } from './Drawers/AddWatchlistDrawer';
import { EditWatchlistDrawer } from './Drawers/EditWatchlistDrawer';
import { WatchlistIndexFilters } from './Filters/WatchlistIndexFilters';
import { WatchlistStatistics } from './Statistics/WatchlistStatistics';

import { type WatchlistIndexFilterParams } from './Filters';
import { useWatchlistConfig } from './useWatchlistConfig.hooks';
import { useDeleteConfirm } from './Watchlist.hooks';
import { WatchlistList } from './WatchlistList';

type IssuerEntity = {
  issuerEntityId: number;
  accountId: number;
  companyName?: string;
};

type Props = {
  companyId?: number;
  accountId?: number;
  headerHeight?: number;
  companySearchPlacement?: 'uncollapsed' | 'collapsed' | null;
  showSubtitle?: boolean;
  showAddToWatchlistButton?: boolean;
  showAccountFilter?: boolean;
};

export const Watchlist = ({
  companyId,
  accountId,
  headerHeight,
  companySearchPlacement = null,
  showSubtitle = false,
  showAddToWatchlistButton = false,
  showAccountFilter = false,
}: Props) => {
  const [form] = Form.useForm<WatchlistIndexFilterParams>();
  const [existingValues, setExistingValues] = useState<IssuerEntity>();
  const [operation, setOperation] = useState<'add' | 'edit' | 'delete' | null>(
    null
  );
  const { isMobile } = useBreakpoints();
  const { pathname } = useLocation();

  const isSecondMarket = pathname.includes('second-market');
  const isCompanyCentric = !!companyId;

  const isInvestor = useIsInvestor();
  const isSponsoredInvestor = useUserContextStore(
    store => !!store.sponsorshipType
  );

  const { onDelete } = useDeleteConfirm({
    onComplete: setOperation,
    issuerEntityId: existingValues?.issuerEntityId,
    accountId: existingValues?.accountId,
    companyName: existingValues?.companyName,
  });
  const { accountId: accountCentricAccountId } = useCurrentAccount();

  const [{ variables, setVariables, isFilterApplied }, filterPanelProps] =
    usePersistedFilters<WatchlistApiWatchlistIndexRequest>(
      FILTER_QUERY_KEY.watchlist,
      {
        page: 1,
        size: getPageSize(isMobile ? 'cards' : 'table'),
        issuerEntityId: companyId,
        accountId: accountId || accountCentricAccountId,
        includeField: [
          'account',
          'issuer_entity',
          'issuer_entity_sectors',
          'user_can_edit_watchlist',
          'minimum_size_range',
          'distinct_issuer_entity_count',
          'note',
        ],
      }
    );

  const { data: issuerData } = useIssuerEntityShow(
    {
      id: variables?.issuerEntityId?.toString(),
    },
    { queryConfig: { enabled: !!variables?.issuerEntityId } }
  );

  const { data } = useWatchlistIndex({ ...variables });
  const totalRecords = useTotalRecords(data);

  const { data: statsData, isLoading: isStatsDataLoading } = useWatchlistIndex({
    ...variables,
    page: 0,
    size: 1,
  });

  const onEditClick = (issuerEntityId: number, accountId: number) => {
    setExistingValues({
      issuerEntityId,
      accountId,
    });
    setOperation('edit');
  };

  const onDeleteClick = (
    issuerEntityId: number,
    accountId: number,
    companyName: string
  ) => {
    setExistingValues({
      issuerEntityId,
      accountId,
      companyName,
    });
    setOperation('delete');
  };

  const onClose = () => {
    setExistingValues(undefined);
    setOperation(null);
  };

  const config = useWatchlistConfig({
    onEditClick,
    onDeleteClick,
    isCompanyCentric,
    isSecondMarket,
    isInvestor,
    isSponsoredInvestor,
  });

  useFilterFormSync(variables, form);

  const filters = (
    <WatchlistIndexFilters
      form={form}
      onAddToWatchlist={() => setOperation('add')}
      totalRecords={totalRecords}
      headerHeight={headerHeight}
      showExtraContent={isSecondMarket}
      showCompanySearch={companySearchPlacement === 'collapsed'}
      showAccountSearch={showAccountFilter}
      {...filterPanelProps}
    />
  );

  return (
    <>
      {showAddToWatchlistButton && (
        <PageHeader.Button
          icon={<Icon name="plus" size="xs" />}
          onClick={() => setOperation('add')}
        >
          Add to Watchlist
        </PageHeader.Button>
      )}
      {showSubtitle && (
        <Text colorVariant="secondary" marginBottom="lg">
          Track companies and get notified of secondary trading opportunities to
          purchase/sell shares
        </Text>
      )}
      {companySearchPlacement === 'uncollapsed' && (
        <Margin bottom="md">
          <FilterPanel
            form={form}
            totalRecords={totalRecords}
            filters={[
              {
                name: 'issuerEntityId',
                label: 'Company',
                render: props => <IssuerEntityFilter {...props} />,
              },
            ]}
            {...filterPanelProps}
          />
        </Margin>
      )}
      <WatchlistStatistics
        data={statsData}
        isLoading={isStatsDataLoading}
        companyName={issuerData?.name}
      />
      <WatchlistList
        config={config}
        filters={filters}
        variables={variables}
        setVariables={setVariables}
        onAddToWatchlist={() => setOperation('add')}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        isFilterApplied={isFilterApplied}
        isCompanyCentric={isCompanyCentric}
        isSecondMarket={isSecondMarket}
      />
      <AddWatchlistDrawer
        open={operation === 'add'}
        onClose={() => setOperation(null)}
        accountId={accountId}
      />
      <EditWatchlistDrawer
        open={operation === 'edit'}
        onClose={onClose}
        issuerEntityId={existingValues?.issuerEntityId}
        accountId={existingValues?.accountId}
      />
      <Modal
        open={operation === 'delete'}
        title="Remove from Watchlist"
        okText="Remove from Watchlist"
        cancelText="Close"
        onOk={() => onDelete()}
        onCancel={onClose}
        okButtonProps={{
          color: 'error',
          icon: <Icon name="trash" />,
        }}
      >
        You are about to remove &apos;{existingValues?.companyName}&apos; from
        your Watchlist. Are you sure you want to continue?
      </Modal>
    </>
  );
};
