import { type LinkProps } from 'react-router-dom';

import { useNavigate } from './useNavigate';

/**
 * @deprecated Use useNavigate instead
 */
export const useHistory = () => {
  const navigate = useNavigate();

  const push = (to: LinkProps['to'], state?: LinkProps['state']) =>
    navigate(to, { state });

  const replace = (to: LinkProps['to']) => navigate(to, { replace: true });

  const goBack = (steps = -1) => navigate(steps);

  return {
    goBack,
    navigate,
    push,
    replace,
  };
};

export type History = ReturnType<typeof useHistory>;
