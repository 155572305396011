import { Fragment } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { formatPrice } from '@npm/core/ui/utils/formatters';

import * as S from './DataLine.styles';

export enum MarketIntelligenceItemType {
  CIRCLE = 'CIRCLE',
  TRIANGLE = 'TRIANGLE',
}

export type MarketIntelligenceItem = {
  title: string;
  type: MarketIntelligenceItemType;
  value: number | undefined;
  isLoading?: boolean;
  color: string;
  blurred?: boolean;
  tooltip?: string;
  getDataLineTooltip?: (item: MarketIntelligenceItem) => React.ReactNode;
};

type Props = {
  data: MarketIntelligenceItem[];
};

export const DataLine = ({ data }: Props) => {
  const values = data.map(item => item.value);
  const min = Math.min(...values);
  const max = Math.max(...values);

  if (values.length <= 1) {
    return null;
  }

  const getLeftPosition = value => {
    if (max === min) {
      return 50; // Avoid division by zero when all values are the same
    }
    return ((value - min) / (max - min)) * 100;
  };

  return (
    <Flex direction={'column'} gap={'sm'}>
      <S.LineContainer>
        <S.Line>
          <S.InnerLine>
            <S.IndicatorsWrapper>
              {data.map((item, index) => (
                <Tooltip
                  key={index}
                  title={
                    item.getDataLineTooltip
                      ? item.getDataLineTooltip(item)
                      : `${item.title}: $${formatPrice(item.value)}`
                  }
                >
                  {item.type === MarketIntelligenceItemType.CIRCLE && (
                    <S.Indicator $left={getLeftPosition(item.value)}>
                      <Icon name={'ring'} color={item.color} strokeWidth={3} />
                    </S.Indicator>
                  )}
                  {item.type === MarketIntelligenceItemType.TRIANGLE && (
                    <S.TriangleIndicator $left={getLeftPosition(item.value)}>
                      <Icon name={'triangle-down'} color={item.color} />
                    </S.TriangleIndicator>
                  )}
                </Tooltip>
              ))}
            </S.IndicatorsWrapper>
          </S.InnerLine>
        </S.Line>
      </S.LineContainer>
      <Flex justify="space-between">
        <Text.Price value={min} />
        <Text.Price value={max} />
      </Flex>
    </Flex>
  );
};
