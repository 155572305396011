import { useCallback, useEffect, useState } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  CbDocumentType,
  CbOwnerType,
  useDocumentCreate,
  useDocumentDestroy,
  useDocumentIndex,
} from '@npm/data-access';
import { type UploadFile } from 'antd/lib/upload/interface';

export const useFileList = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const addFile = useCallback((file: UploadFile) => {
    setFileList(files => [...files, file]);
  }, []);

  const removeFile = useCallback((file: UploadFile) => {
    setFileList(v => v.filter(f => f.uid !== file.uid));
  }, []);

  return { addFile, removeFile, fileList, setFileList };
};

type Params = {
  holdingId?: number;
};

export const useProofOfOwnershipUpload = ({ holdingId }: Params) => {
  const { setFileList, fileList, ...fileListProps } = useFileList();
  const { withShowApiErrorMiddleware } = useAlerts();

  const { data: documents, isFetching } = useDocumentIndex(
    {
      ownerId: holdingId,
      ownerType: CbOwnerType.items.Holding,
      type: CbDocumentType.items.proof_of_ownership_document,
    },
    {
      queryConfig: { enabled: Boolean(holdingId) },
    }
  );

  const { execute: createDocument, isLoading: isUploading } =
    useDocumentCreate();

  const { execute: destroyDocument, isLoading: isDestroying } =
    useDocumentDestroy();

  const uploadDocument = async (doc: File, ownerId: number) => {
    await withShowApiErrorMiddleware(createDocument)({
      file: doc,
      category: CbDocumentType.items.proof_of_ownership_document,
      ownerType: CbOwnerType.items.Holding,
      ownerId,
      displayName: doc.name,
    });
  };

  const updateAllDocuments = async (ownerId: number) => {
    const serverDocs = documents?.documents || [];

    // local files not on server should be uploaded
    const filesToUpload = fileList.filter(
      file => !serverDocs.some(doc => String(doc.id) === file.uid)
    );

    // server files not in fileList should be deleted
    const docsToDelete = serverDocs.filter(
      doc => !fileList.some(file => file.uid === String(doc.id))
    );

    const uploadPromises = filesToUpload.map(file =>
      uploadDocument(file as unknown as File, ownerId)
    );

    const destroyPromises = docsToDelete.map(doc =>
      destroyDocument({ id: doc.id })
    );

    await Promise.all([...uploadPromises, ...destroyPromises]);

    // if there was some update
    return Boolean(docsToDelete.length || filesToUpload.length);
  };

  // load existing documents into fileList when editing holding
  useEffect(() => {
    if (documents?.documents?.length > 0 && holdingId) {
      const files: UploadFile[] = documents?.documents.map(doc => ({
        name: doc?.display_name,
        uid: String(doc?.id),
      }));
      setFileList(files);
    } else {
      if (!holdingId) {
        // reset when adding new holding
        setFileList([]);
      }
    }
  }, [documents, holdingId]);

  return {
    ...fileListProps,
    fileList,
    setFileList,
    isFetching,
    isSaving: isDestroying || isUploading,
    updateAllDocuments,
  };
};
