import React from 'react';

import { ContextualHelp } from '@npm/core/ui/components/molecules/ContextualHelp';
import {
  EducationalModal,
  useEducationalModal,
} from '@npm/core/ui/components/organisms/EducationalModal';

import { educationalModalCertificatesConfig } from './EducationalModalCertificates.config';

type Props = {
  $isInline?: boolean;
};

export const ContextualHelpCertificates = ({ $isInline }: Props) => {
  const [openModal, educationalModalProps] = useEducationalModal(
    educationalModalCertificatesConfig
  );

  return (
    <>
      <ContextualHelp onClick={() => openModal()} $isInline={$isInline}>
        Where do I find my certificates and option grants?
      </ContextualHelp>
      <EducationalModal {...educationalModalProps} />
    </>
  );
};
