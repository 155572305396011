import { type Dispatch } from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { Wizard } from '@npm/core/ui/components/organisms/Wizard';
import { getFullName } from '@npm/core/ui/utils/formatters';
import { onboardingStatusToPageStateMap } from '@npm/core/ui/utils/onBoardingStatusToPageStateMap';
import { CbAccountType, CbRoleType } from '@npm/data-access';
import {
  type AccountShowAggregate,
  CbOnboardingStatus,
  type OnboardingStatus,
  type UserWithRolesForAccount,
} from '@npm/data-access';
import { isNil } from 'lodash';

import {
  type GetStepsFn,
  type PostOnboardingContext,
} from './PostOnboarding.types';

export const isStepIncomplete = (stepStatus: string) => {
  return (
    stepStatus === CbOnboardingStatus.items.incomplete ||
    stepStatus === CbOnboardingStatus.items.warning ||
    stepStatus === undefined
  );
};

export const findPostOnboardingNextIncompleteStep = (
  context: Wizard.WizardContext<PostOnboardingContext>,
  {
    currentStepKey,
    startingIndex,
  }: {
    currentStepKey?: string;
    startingIndex?: number;
  } = {}
) => {
  const isStartingIndexDefined = !isNil(startingIndex);
  const isCurrentStepIndexDefined = !isNil(context.stepIndex);

  // determine where to start searching for the next incomplete step
  const startSearchIndex = isStartingIndexDefined
    ? startingIndex
    : isCurrentStepIndexDefined
      ? context.stepIndex
      : -1;

  // if a current step key is provided, check if it's incomplete and return the current step index
  if (currentStepKey) {
    const currentStepStatus =
      context.data.onboardingStatus[currentStepKey]?.code;

    if (isStepIncomplete(currentStepStatus)) {
      return context.stepIndex;
    }
  }

  // function to find the next incomplete step
  const findNextIncompleteStep = (startIndex: number) =>
    Wizard.findNextIncompleteStep<Partial<PostOnboardingContext>>(
      context.steps,
      startIndex,
      context.data,
      (step, ctx) => {
        const onboardingStatus = ctx.onboardingStatus[step.key]?.code;
        return isStepIncomplete(onboardingStatus);
      }
    );

  // find the next incomplete step, starting from the determined index
  const nextIncompleteStep = findNextIncompleteStep(startSearchIndex);

  if (nextIncompleteStep === -1) {
    return findNextIncompleteStep(-1);
  }

  return nextIncompleteStep;
};

export const completeOnboardingStep = (
  updateContext: Dispatch<Wizard.ContextAction<PostOnboardingContext>>,
  onWizardComplete: () => void
) => {
  const isStepIncomplete = (
    step: Wizard.StepDefinition<PostOnboardingContext>,
    context: PostOnboardingContext
  ) => {
    const onboardingStatus = (context as PostOnboardingContext)
      .onboardingStatus[step.key]?.code;

    return (
      (!step.rule || step.rule(context)) &&
      (onboardingStatus === CbOnboardingStatus.items.incomplete ||
        onboardingStatus === CbOnboardingStatus.items.warning)
    );
  };

  updateContext({
    type: Wizard.ContextActionType.MOVE_NEXT,
    config: {
      isStepIncomplete,
      onComplete: onWizardComplete,
    },
  });
};

export const getCurrentStepState: (
  onboardingStepKey: string,
  onboardingStatusData?: OnboardingStatus
) => Wizard.PageState = (onboardingStepKey, onboardingStatusData) => {
  return onboardingStatusToPageStateMap[
    onboardingStatusData?.[onboardingStepKey]?.code
  ];
};

export const showSelectedRepNotification = (
  rep: PostOnboardingContext['representative']
) => {
  Notification.success({
    message: `You are adding information on behalf of ${getFullName(
      rep.first,
      rep.last,
      rep.middle
    )}`,
  });
};

export const getFirstStepInWarningState = (account: AccountShowAggregate) => {
  if (account.type?.code === CbAccountType.items.OrganizationAccount) {
    return CbOnboardingStatus.steps.entity_tax_state;
  } else {
    return CbOnboardingStatus.steps.individual_tax_state;
  }
};

export const getSteps: GetStepsFn = ({
  isIndividual,
  getSidebarConfig,
  personId,
  onboardingStatus,
}) => {
  const sidebarConfig = getSidebarConfig({
    isIndividual,
    personId,
    onboardingStatus,
  });

  const flattenedSteps = [];

  sidebarConfig.forEach(({ items, hideSection }) => {
    if (hideSection) return;

    items.forEach(({ substeps, hideItem, ...step }) => {
      if (!hideItem) {
        if (substeps) {
          flattenedSteps.push(...substeps);
        } else {
          flattenedSteps.push(step);
        }
      }
    });
  });

  return flattenedSteps;
};

export const hasUserSpouseRole = (user: UserWithRolesForAccount) => {
  return !!user.roles?.find(role => role.code === CbRoleType.items.SPOUSE);
};
