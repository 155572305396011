import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { InfoMessage } from '@npm/core/ui/components/molecules/InfoMessage';

import { ZendeskContactButton } from '../../app/zendesk';
import { useSponsorshipLevel } from '../../auth/user/role';
import {
  useBackgroundCheckStatus,
  useOnboardingStatus,
} from '../dashboard.hooks';
import type { UserVariantType } from '../dashboard.types';

import { GreetingWithSubtitle } from './OnboardingBanner/GreetingWithSubtitle/GreetingWithSubtitle';

import { OnboardingBanner } from './OnboardingBanner';

type Props = {
  accountId: number;
  variant: UserVariantType;
  onOnboardingClick?: () => void;
};

export const BannerSection = ({
  accountId,
  variant,
  onOnboardingClick,
}: Props) => {
  const { isSponsorshipLevel1, isSponsorshipMinLevel2 } = useSponsorshipLevel();

  const { isOnboardingLoading, isOnboardingToMarketplaceComplete } =
    useOnboardingStatus(accountId, variant);

  const { isBgCheckFailed } = useBackgroundCheckStatus(accountId);

  if (isOnboardingLoading) return null;

  if (
    isOnboardingToMarketplaceComplete &&
    isSponsorshipMinLevel2 &&
    variant !== 'individual'
  ) {
    return <GreetingWithSubtitle />;
  }

  if (isOnboardingToMarketplaceComplete && isBgCheckFailed) {
    return (
      <InfoMessage
        type="warning"
        heading="Identity Verification Documents Required"
        iconName="alert-triangle"
        description={
          <>
            <Margin top="sm" bottom="sm">
              <Text size="sm" colorVariant="primary">
                Updated photo identification is required.{' '}
                <span onClick={onOnboardingClick}>Go to Onboarding</span> and
                provide updated information.
              </Text>
              <Text size="sm" colorVariant="primary">
                If you have questions or concerns, email{' '}
                <Text size="sm" href="mailto:support@npm.com">
                  support@npm.com
                </Text>{' '}
                or{' '}
                <ZendeskContactButton
                  zendeskTicketGroupId={'onboarding_entity_tax_id'}
                >
                  Click Here
                </ZendeskContactButton>
                .
              </Text>
            </Margin>
            <Button
              icon={<Icon name="external-link" />}
              onClick={onOnboardingClick}
              style={{ width: '200px' }}
            >
              Complete Onboarding
            </Button>
          </>
        }
        style={{ gap: 0 }}
      />
    );
  }

  // lvl 1 = alert is displayed for non-US based accounts
  // lvl 2 = we do not display any alert, level is set manually by NPM
  // lvl 3 = granted automatically to US based users
  if (isOnboardingToMarketplaceComplete && isSponsorshipLevel1) {
    return (
      <div>
        <Margin bottom="lg">
          <GreetingWithSubtitle />
        </Margin>
        <InfoMessage
          type="info"
          heading="We’re reviewing your application to access our marketplace"
          iconName="badge"
          description={
            <Text size="sm">
              If you have questions or concerns, email{' '}
              <Text size="sm" href="mailto:support@npm.com">
                support@npm.com
              </Text>{' '}
              or{' '}
              <ZendeskContactButton zendeskTicketGroupId="marketplace_application">
                click here
              </ZendeskContactButton>{' '}
              to contact us.
            </Text>
          }
        />
      </div>
    );
  }

  if (!isOnboardingToMarketplaceComplete) {
    return (
      <OnboardingBanner
        accountId={accountId}
        variant={variant}
        handleOnClick={onOnboardingClick}
        subtitle={
          variant === 'entity' && 'Complete onboarding to enable trading. '
        }
      />
    );
  }

  return null;
};
