import React from 'react';

import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import {
  DATE_FORMATS,
  formatDate,
  formatNumToAddComma,
} from '@npm/core/ui/utils/formatters';
import { type Codebook, type VenusApi } from '@npm/data-access';

import { PremiumTextGuard } from '../../../../auth/user/components';

const renderBooleanValue = val => {
  let value = null;

  if (val === true) {
    value = 'True';
  } else if (val === false) {
    value = 'False';
  }

  return (
    <Text size="sm" colorVariant="primary">
      {value || EMPTY_VALUE}
    </Text>
  );
};

export const getCapTableColumns = (
  companyName: string | undefined,
  denomination?: Codebook
): ExtendedColumnType<VenusApi.CapTableStockClass>[] => {
  return [
    {
      title: 'Stock Class',
      key: 'name',
      render: ({ name }) => name,
      responsive: TABLE_BREAKPOINTS.MOBILE,
    },
    {
      title: 'Original Issue PPS',
      titleInfo: 'Original Issue Price Per Share',
      key: 'original_issue_price',
      render: ({ original_issue_price }) => {
        return (
          <PremiumTextGuard>
            <Text.Price
              size="sm"
              colorVariant="primary"
              value={original_issue_price}
            />
          </PremiumTextGuard>
        );
      },
      responsive: TABLE_BREAKPOINTS.MOBILE,
    },
    {
      title: 'Conversion PPS',
      key: 'conversion_price',
      render: ({ conversion_price }) => {
        return (
          <PremiumTextGuard>
            <Text.Price
              value={conversion_price}
              currency={denomination?.name}
            />
          </PremiumTextGuard>
        );
      },
      responsive: TABLE_BREAKPOINTS.MOBILE,
      titleInfo: 'Conversion Price Per Share',
    },
    {
      title: 'Authorized Shares',
      key: 'total_authorized_shares',
      render: ({ total_authorized_shares }) => (
        <PremiumTextGuard>
          <Text size="sm" colorVariant="primary">
            {total_authorized_shares
              ? formatNumToAddComma(total_authorized_shares)
              : EMPTY_VALUE}
          </Text>
        </PremiumTextGuard>
      ),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      title: 'Issued Shares',
      key: 'total_issued_shares',
      titleInfo: `This estimate is based on NPM's analysis of ${companyName}'s public filings and its proprietary research.`,
      render: ({ total_issued_shares }) => (
        <PremiumTextGuard>
          <Text size="sm" colorVariant="primary">
            {total_issued_shares
              ? formatNumToAddComma(total_issued_shares)
              : EMPTY_VALUE}
          </Text>
        </PremiumTextGuard>
      ),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      title: 'Original Issuance Date',
      key: 'original_issuance_date',
      render: ({ original_issuance_date }) => (
        <PremiumTextGuard>
          <Text size="sm" colorVariant="primary">
            {original_issuance_date
              ? formatDate(original_issuance_date, {
                  dateFormat: DATE_FORMATS.DATE,
                })
              : EMPTY_VALUE}
          </Text>
        </PremiumTextGuard>
      ),
      responsive: TABLE_BREAKPOINTS.DESKTOP_SM,
    },
    {
      title: 'Original Issue PPS',
      key: 'original_issue_price',
      render: ({ original_issue_price }) =>
        original_issue_price ? (
          <Text.Price
            value={original_issue_price}
            currency={denomination?.name}
          />
        ) : (
          EMPTY_VALUE
        ),
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Financing round',
      key: 'financing_round',
      render: ({ financing_round }) => financing_round,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Conversion Ratio',
      key: 'conversion_ratio',
      render: ({ conversion_ratio }) => conversion_ratio,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Stock Type',
      key: 'stock_type',
      render: ({ stock_type }) => stock_type,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Seniority',
      key: 'seniority',
      render: ({ seniority }) => seniority,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Participating Cap',
      key: 'participation_cap_multiple',
      render: ({ participation_cap_multiple }) => participation_cap_multiple,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Liquidation multiple',
      key: 'liquidation_multiple',
      render: ({ liquidation_multiple }) => liquidation_multiple,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Liquidation Preference',
      key: 'liquidation_preference',
      render: ({ liquidation_preference }) => liquidation_preference,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Cumulative Dividends',
      key: 'is_cumulative_divident',
      render: ({ is_cumulative_divident }) =>
        renderBooleanValue(is_cumulative_divident),
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Dividend Rate',
      key: 'dividend_rate',
      render: ({ dividend_rate }) => dividend_rate,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Anti-Dilution Protection',
      key: 'anti_dilution_protection',
      render: ({ anti_dilution_protection }) => anti_dilution_protection,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Pay to play',
      key: 'pay_to_play',
      render: ({ pay_to_play }) => renderBooleanValue(pay_to_play),
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Reorg Recap',
      key: 'reorg_recap',
      render: ({ reorg_recap }) => reorg_recap,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
    {
      title: 'Number of Votes',
      key: 'votes_per_share',
      render: ({ votes_per_share }) => votes_per_share,
      responsive: TABLE_BREAKPOINTS.NONE,
    },
  ];
};
