// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AnomalousOrderApi,
  GetCompanyAnomalousOrdersErrorCodes,
  GetCompanyAnomalousOrdersErrorTypes,
  GetAnomalousOrderErrorCodes,
  GetAnomalousOrderErrorTypes,
  UpdateAnomalousOrderErrorCodes,
  IsAnomalousOrderFinderErrorCodes,
  IsAnomalousOrderFinderErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AnomalousOrderApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type GetCompanyAnomalousOrdersMethod = AnomalousOrderApi['getCompanyAnomalousOrders'];
export type GetCompanyAnomalousOrdersResponseType = MethodResult<GetCompanyAnomalousOrdersMethod>;

export const useGetCompanyAnomalousOrders = (
  variables: Parameters<GetCompanyAnomalousOrdersMethod>[0],
  config?: UseQueryConfig<
    GetCompanyAnomalousOrdersMethod,
    GetCompanyAnomalousOrdersErrorTypes
  >
) => {
  return useQuery<
    GetCompanyAnomalousOrdersMethod,
    GetCompanyAnomalousOrdersErrorTypes
  >(
    (...args) => api.getCompanyAnomalousOrders(...args),
    GetCompanyAnomalousOrdersErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCompanyAnomalousOrders'],
        ...apiQueryConfig.GetCompanyAnomalousOrders,
        ...config?.queryConfig
      },
    },
    'GetCompanyAnomalousOrders',
  );
};

export const useGetCompanyAnomalousOrdersInfinite = (
  variables: Parameters<GetCompanyAnomalousOrdersMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetCompanyAnomalousOrdersMethod,
    GetCompanyAnomalousOrdersErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetCompanyAnomalousOrdersMethod,
    GetCompanyAnomalousOrdersErrorTypes
  >(
    (...args) => api.getCompanyAnomalousOrders(...args),
    GetCompanyAnomalousOrdersErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCompanyAnomalousOrders', 'GetCompanyAnomalousOrdersInfinite'],
        ...apiQueryConfig.GetCompanyAnomalousOrders,
        ...config?.queryConfig,
      },
    },
    'GetCompanyAnomalousOrders',
  );
};

export const useGetCompanyAnomalousOrdersLazy = (baseOptions?: {
  variables?: Parameters<GetCompanyAnomalousOrdersMethod>[0],
  config?: UseQueryConfig<
    GetCompanyAnomalousOrdersMethod,
    GetCompanyAnomalousOrdersErrorTypes
  >
}) => {
  return useLazyQuery<
    GetCompanyAnomalousOrdersMethod,
    GetCompanyAnomalousOrdersErrorTypes
  >(
    (...args) => api.getCompanyAnomalousOrders(...args),
    GetCompanyAnomalousOrdersErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetCompanyAnomalousOrders'],
        ...apiQueryConfig.GetCompanyAnomalousOrders,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetCompanyAnomalousOrders',
  );
};


export type GetAnomalousOrderMethod = AnomalousOrderApi['getAnomalousOrder'];
export type GetAnomalousOrderResponseType = MethodResult<GetAnomalousOrderMethod>;

export const useGetAnomalousOrder = (
  variables: Parameters<GetAnomalousOrderMethod>[0],
  config?: UseQueryConfig<
    GetAnomalousOrderMethod,
    GetAnomalousOrderErrorTypes
  >
) => {
  return useQuery<
    GetAnomalousOrderMethod,
    GetAnomalousOrderErrorTypes
  >(
    (...args) => api.getAnomalousOrder(...args),
    GetAnomalousOrderErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetAnomalousOrder'],
        ...apiQueryConfig.GetAnomalousOrder,
        ...config?.queryConfig
      },
    },
    'GetAnomalousOrder',
  );
};

export const useGetAnomalousOrderInfinite = (
  variables: Parameters<GetAnomalousOrderMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetAnomalousOrderMethod,
    GetAnomalousOrderErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetAnomalousOrderMethod,
    GetAnomalousOrderErrorTypes
  >(
    (...args) => api.getAnomalousOrder(...args),
    GetAnomalousOrderErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetAnomalousOrder', 'GetAnomalousOrderInfinite'],
        ...apiQueryConfig.GetAnomalousOrder,
        ...config?.queryConfig,
      },
    },
    'GetAnomalousOrder',
  );
};

export const useGetAnomalousOrderLazy = (baseOptions?: {
  variables?: Parameters<GetAnomalousOrderMethod>[0],
  config?: UseQueryConfig<
    GetAnomalousOrderMethod,
    GetAnomalousOrderErrorTypes
  >
}) => {
  return useLazyQuery<
    GetAnomalousOrderMethod,
    GetAnomalousOrderErrorTypes
  >(
    (...args) => api.getAnomalousOrder(...args),
    GetAnomalousOrderErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetAnomalousOrder'],
        ...apiQueryConfig.GetAnomalousOrder,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetAnomalousOrder',
  );
};


export type UpdateAnomalousOrderMethod = AnomalousOrderApi['updateAnomalousOrder'];

export const useUpdateAnomalousOrder = () => {
  return useMutation<UpdateAnomalousOrderMethod>(
    (...args) => api.updateAnomalousOrder(...args),
    UpdateAnomalousOrderErrorCodes,
    'UpdateAnomalousOrder',
    '/api/anomalous_orders/{id}',
    'put'
  );
};

export type IsAnomalousOrderFinderMethod = AnomalousOrderApi['isAnomalousOrderFinder'];
export type IsAnomalousOrderFinderResponseType = MethodResult<IsAnomalousOrderFinderMethod>;

export const useIsAnomalousOrderFinder = (
  variables: Parameters<IsAnomalousOrderFinderMethod>[0],
  config?: UseQueryConfig<
    IsAnomalousOrderFinderMethod,
    IsAnomalousOrderFinderErrorTypes
  >
) => {
  return useQuery<
    IsAnomalousOrderFinderMethod,
    IsAnomalousOrderFinderErrorTypes
  >(
    (...args) => api.isAnomalousOrderFinder(...args),
    IsAnomalousOrderFinderErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IsAnomalousOrderFinder'],
        ...apiQueryConfig.IsAnomalousOrderFinder,
        ...config?.queryConfig
      },
    },
    'IsAnomalousOrderFinder',
  );
};

export const useIsAnomalousOrderFinderInfinite = (
  variables: Parameters<IsAnomalousOrderFinderMethod>[0],
  config?: UseInfiniteQueryConfig<
    IsAnomalousOrderFinderMethod,
    IsAnomalousOrderFinderErrorTypes
  >
) => {
  return useInfiniteQuery<
    IsAnomalousOrderFinderMethod,
    IsAnomalousOrderFinderErrorTypes
  >(
    (...args) => api.isAnomalousOrderFinder(...args),
    IsAnomalousOrderFinderErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IsAnomalousOrderFinder', 'IsAnomalousOrderFinderInfinite'],
        ...apiQueryConfig.IsAnomalousOrderFinder,
        ...config?.queryConfig,
      },
    },
    'IsAnomalousOrderFinder',
  );
};

export const useIsAnomalousOrderFinderLazy = (baseOptions?: {
  variables?: Parameters<IsAnomalousOrderFinderMethod>[0],
  config?: UseQueryConfig<
    IsAnomalousOrderFinderMethod,
    IsAnomalousOrderFinderErrorTypes
  >
}) => {
  return useLazyQuery<
    IsAnomalousOrderFinderMethod,
    IsAnomalousOrderFinderErrorTypes
  >(
    (...args) => api.isAnomalousOrderFinder(...args),
    IsAnomalousOrderFinderErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IsAnomalousOrderFinder'],
        ...apiQueryConfig.IsAnomalousOrderFinder,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IsAnomalousOrderFinder',
  );
};

