import { Layout } from 'antd';
import styled from 'styled-components';

import { useBreakpoint } from '../../../../utils/style';
import { type useLayoutConfig } from '../Layout.hooks';
import { APP_HEADER_HEIGHT, APP_HEADER_HEIGHT_MOBILE } from '../Layout.styles';

export const HeightFiller = styled.div<{ $headerHeight: number }>`
  height: ${({ $headerHeight }) => $headerHeight}px;
`;

export const StyledHeader = styled(Layout.Header)<{
  $layoutConfig: ReturnType<typeof useLayoutConfig>;
}>`
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: ${({ theme, $layoutConfig }) =>
    $layoutConfig.isMobile || $layoutConfig.isTablet
      ? theme.color.general.layout.zero
      : theme.color.general.layout.one};
  padding: 0 0 0 ${({ theme }) => theme.spacing.md}px;
  height: ${APP_HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  // above drawer so the shadow of the drawer doesn't go above navigation on desktop
  z-index: ${({ theme }) => theme.zIndex.aboveDrawer};

  ${({ $layoutConfig, theme }) =>
    ($layoutConfig.isMobile || $layoutConfig.isTablet) &&
    `
      padding: 0 ${
        $layoutConfig.isTablet ? theme.spacing.md : theme.spacing.sm
      }px;
      align-items: center;
      border: none;
      z-index: ${theme.zIndex.aboveDrawer};
  `}

  ${({ theme }) => useBreakpoint(theme).tablet`
    z-index: ${({ theme }) => theme.zIndex.drawer};
    height: ${APP_HEADER_HEIGHT_MOBILE}px;
  `}
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}px;
  background: ${({ theme }) => theme.color.general.layout.one};
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
`;
