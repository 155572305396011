import React from 'react';

import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import { type OnboardingSectionConfig } from '../../postOnboardingNew/PostOnboarding.types';
import { type PostOnboardingContextType } from '../PostOnboarding.types';

import { SidebarSection as V2SidebarSection } from './v2/SidebarSection';

import { SidebarSection } from './SidebarSection';

export type SidebarConfig = OnboardingSectionConfig[];

type Props = {
  sidebarConfig: SidebarConfig;
  isV2?: boolean;
  handleCloseFormAttempt?: (callback?: () => void) => void;
};

export const Sidebar = ({
  sidebarConfig,
  isV2,
  handleCloseFormAttempt,
}: Props) => {
  const { activeSection, onboardingStatus } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      activeSection: s.data.activeSectionProps?.activeSection,
      onboardingStatus: s.data.onboardingStatus,
    })
  );

  if (!onboardingStatus || !activeSection) return null;

  return (
    <>
      {sidebarConfig.map(section => {
        const commonProps = {
          sectionKey: section.key,
          isActive: section.key === activeSection.key,
          handleCloseFormAttempt: handleCloseFormAttempt,
          ...section,
        };

        return isV2 ? (
          <V2SidebarSection key={section.key} {...commonProps} />
        ) : (
          <SidebarSection key={section.key} {...commonProps} />
        );
      })}
    </>
  );
};
