import { Flex } from '@npm/core/ui/components/atoms/common';
import { centerFlex } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const OrderCardContainer = styled(Flex).attrs({
  direction: 'column',
})`
  padding: ${({ theme }) => theme.spacing.sm}px;
  gap: ${({ theme }) => theme.spacing.sm}px;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme }) => theme.color.general.layout.two};

  cursor: pointer;
`;

export const PriceRowWrapper = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
})`
  gap: ${({ theme }) => theme.spacing.sm}px;
  padding: ${({ theme }) => theme.spacing.xs}px;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.zero};
`;

export const PriceBlock = styled.div`
  ${centerFlex}
  padding: 0 ${({ theme }) => theme.spacing.md}px;
  gap: ${({ theme }) => theme.spacing.sm}px;

  min-width: fit-content;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.one};
`;

export const ContentWrap = styled(Flex)`
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing.sm}px;

  border-left: 1px solid ${({ theme }) => theme.color.general.borders.primary};
`;

export const StructureAndAsset = styled.div`
  ${centerFlex};
  border-top: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  padding-top: ${({ theme }) => theme.spacing.xs}px;

  width: 100%;
`;
