import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type ExtendedColumnType } from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { CompanyOverviewPriceDisplay } from '../../../components/CompanyOverviewPriceDisplay';

import * as S from './Levels.styles';
import { type AverageAndBestLevelsDataType } from './Levels.types';

export const getColumns = (
  breakpoint: Partial<Record<Breakpoint, boolean>>
): ExtendedColumnType<AverageAndBestLevelsDataType>[] => [
  {
    title: 'Date',
    key: 'date',
    render: ({ date }) => (
      <Text size="sm" colorVariant="primary">
        {formatDate(date, { dateFormat: DATE_FORMATS.MONTH_AND_YEAR })}
      </Text>
    ),
  },
  {
    title: 'Total Volume Range',
    key: 'total_volume_range',
    render: ({ aggregated }) => (
      <Text size="sm" colorVariant="primary">
        ${aggregated.total_value?.toUpperCase()}
      </Text>
    ),
  },
  {
    title: 'Avg. PPS',
    key: 'avg-pps',
    render: ({
      aggregated: { price_per_share, discount_or_premium, implied_valuation },
    }) => (
      <CompanyOverviewPriceDisplay
        price={price_per_share}
        change={discount_or_premium}
        impliedValuation={implied_valuation}
        multiplyChange={true}
        align="flex-start"
      />
    ),
  },
  {
    title: <S.Divider type="vertical" />,
    key: 'divider',
    align: 'center',
    render: () => <S.Divider type="vertical" />,
    hidden: !breakpoint.sm,
  },
  {
    title: 'Best PPS',
    key: 'best-pps',
    render: ({
      best: { price_per_share, discount_or_premium, implied_valuation },
      aggregated,
    }) => (
      <CompanyOverviewPriceDisplay
        price={price_per_share}
        change={discount_or_premium}
        impliedValuation={implied_valuation}
        multiplyChange={true}
        align="flex-start"
        isMuted={price_per_share === aggregated.price_per_share}
      />
    ),
  },
];
