import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { CbOrderBuySell } from '@npm/data-access';
import { Form, type FormInstance } from 'antd';

import { type FormValues } from './UpsertWatchlistForm';

const actions = {
  buy: 'Buy',
  buySell: 'Buy & Sell',
  sell: 'Sell',
} as const;

type Props = {
  form: FormInstance<FormValues>;
};

export const ActionsTabs = ({ form }: Props) => {
  const buySell = Form.useWatch('order_buy_sell', form);
  const activeAction = buySell ?? 'buySell';

  const handleActionChange = (action: string) => {
    if (action === 'buy') {
      form.setFieldsValue({ order_buy_sell: CbOrderBuySell.items.buy });
    } else if (action === 'sell') {
      form.setFieldsValue({ order_buy_sell: CbOrderBuySell.items.sell });
    } else {
      form.setFieldsValue({ order_buy_sell: undefined });
    }
  };

  return (
    <>
      <FormItem name="order_buy_sell" hidden>
        <Input />
      </FormItem>
      <Tabs
        variant="secondary"
        activeKey={activeAction}
        onChange={handleActionChange}
        tabBarStyle={{ margin: 0 }}
        hideMoreButton
      >
        {Object.entries(actions).map(([key, title]) => (
          <Tabs.TabPane key={key} tab={title} />
        ))}
      </Tabs>
    </>
  );
};
