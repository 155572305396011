import { Flex } from '@npm/core/ui/components/atoms/common';
import styled from 'styled-components';

export const ItemWrapper = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.xs}px 0;

  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};

  &:last-child {
    border-bottom: none;
  }
`;
