// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EventReportsApi,
  EventReportListErrorCodes,
  EventReportListErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EventReportsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventReportListMethod = EventReportsApi['eventReportList'];
export type EventReportListResponseType = MethodResult<EventReportListMethod>;

export const useEventReportList = (
  variables: Parameters<EventReportListMethod>[0],
  config?: UseQueryConfig<
    EventReportListMethod,
    EventReportListErrorTypes
  >
) => {
  return useQuery<
    EventReportListMethod,
    EventReportListErrorTypes
  >(
    (...args) => api.eventReportList(...args),
    EventReportListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventReportList'],
        ...apiQueryConfig.EventReportList,
        ...config?.queryConfig
      },
    },
    'EventReportList',
  );
};

export const useEventReportListInfinite = (
  variables: Parameters<EventReportListMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventReportListMethod,
    EventReportListErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventReportListMethod,
    EventReportListErrorTypes
  >(
    (...args) => api.eventReportList(...args),
    EventReportListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventReportList', 'EventReportListInfinite'],
        ...apiQueryConfig.EventReportList,
        ...config?.queryConfig,
      },
    },
    'EventReportList',
  );
};

export const useEventReportListLazy = (baseOptions?: {
  variables?: Parameters<EventReportListMethod>[0],
  config?: UseQueryConfig<
    EventReportListMethod,
    EventReportListErrorTypes
  >
}) => {
  return useLazyQuery<
    EventReportListMethod,
    EventReportListErrorTypes
  >(
    (...args) => api.eventReportList(...args),
    EventReportListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventReportList'],
        ...apiQueryConfig.EventReportList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventReportList',
  );
};

