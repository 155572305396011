import { type ComponentProps, useCallback, useMemo, useState } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { type ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { PAGE_SIZE_HARD_LIMIT } from '@npm/core/ui/components/molecules/Table';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { CbBuyerSurveyQuestion, Codebooks } from '@npm/data-access';
import {
  type Account,
  type AccountsBuyerSurveyCreateRequestContract,
  type BuyerSurvey,
  type BuyerSurveyCodebookItem,
  type BuyerSurveyListResponseType,
  type BuyerSurveyQuestionCodebookItem,
  unpackCodebooks,
  useBuyerSurveyCreate,
  useBuyerSurveyList,
  useCodebook,
  useSectorIndex,
} from '@npm/data-access';
import { type FormInstance } from 'antd';

import { prepareFormConfig } from './BuyerSurvey.utils';

type BuyerSurveyProps = {
  accountId: Account['id'];
  variant: 'individual' | 'entity';
  onSubmitSuccess?: () => void;
  onLoadSuccess?: (data: BuyerSurvey) => void;
};

export const useBuyerSurveyForm = (
  form: FormInstance | null,
  { accountId, variant, onSubmitSuccess, onLoadSuccess }: BuyerSurveyProps
) => {
  const [formHasErrored, setFormHasErrored] = useState(false);
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute: createBuyerSurvey, isLoading: isCreating } =
    useBuyerSurveyCreate();

  const { data: buyerSurverData } = useCodebook({
    type: Codebooks.BUYER_SURVEY,
  });

  const { data: buyerSurverQuestionData } = useCodebook({
    type: Codebooks.BUYER_SURVEY_QUESTION,
  });

  const buyerSurverCodebooks = buyerSurverData?.codebooks ?? [];
  const buyerSurverQuestionCodebooks = buyerSurverQuestionData?.codebooks ?? [];

  const { data: sectorsData, isLoading: sectorsLoading } = useSectorIndex({
    page: 1,
    size: PAGE_SIZE_HARD_LIMIT,
  });

  const {
    data: surveyData,
    isLoading: surveyLoading,
    refetch,
  } = useBuyerSurveyList(
    {
      accountId,
    },
    { onComplete: onLoadSuccess }
  );

  const sortedAndfilteredBuyerSurveyQuestions = useMemo(() => {
    if (!variant) return [];

    const filtered = buyerSurverQuestionCodebooks.filter(item =>
      item.attributes?.some(
        attr => attr.name === variant && attr.value === 'true'
      )
    ) as BuyerSurveyQuestionCodebookItem[];

    return filtered.sort((a, b) => {
      const getOrder = (item: BuyerSurveyQuestionCodebookItem) => {
        return Number(
          CbBuyerSurveyQuestion.getAttributeValueByName(
            item,
            `ordered_by_${variant}`
          )
        );
      };

      return getOrder(a) - getOrder(b);
    });
  }, [buyerSurverQuestionCodebooks, variant]);

  const data = useMemo<BuyerSurveyListResponseType | undefined>(() => {
    if (!surveyData?.id || !sortedAndfilteredBuyerSurveyQuestions.length) {
      return undefined;
    }
    return unpackCodebooks<BuyerSurveyListResponseType>(
      surveyData,
      sortedAndfilteredBuyerSurveyQuestions
        .map(c => c.code as keyof BuyerSurveyListResponseType)
        .filter(i => i !== 'sectors')
    );
  }, [surveyData, sortedAndfilteredBuyerSurveyQuestions]);

  const getFormConfig = useCallback(
    () =>
      prepareFormConfig(
        sortedAndfilteredBuyerSurveyQuestions,
        buyerSurverCodebooks as BuyerSurveyCodebookItem[],
        sectorsData?.sectors,
        sectorsLoading
      ),
    [
      sortedAndfilteredBuyerSurveyQuestions,
      buyerSurverCodebooks,
      sectorsData?.sectors,
      sectorsLoading,
    ]
  );

  const getReadOnlyData = useCallback((): Pick<
    ComponentProps<typeof ReadOnly>,
    'items'
  >['items'] => {
    if (!surveyData || !sortedAndfilteredBuyerSurveyQuestions.length) {
      return [];
    }

    return sortedAndfilteredBuyerSurveyQuestions.reduce(
      (result, { code, name }) => {
        let value: string;
        const surveyQuestion = surveyData[code];

        if (Array.isArray(surveyQuestion)) {
          if (code === 'sectors') {
            value = surveyQuestion.join(', ');
            // array of codebook objects
          } else {
            value = surveyQuestion.map(cb => cb?.name).join(', ');
          }
          // codebook object
        } else if (surveyQuestion && typeof surveyQuestion === 'object') {
          value = surveyQuestion.name;
        }

        if (value) {
          result.push({
            label: name,
            value,
          });
        }

        return result;
      },
      []
    );
  }, [sortedAndfilteredBuyerSurveyQuestions, surveyData]);

  const onSubmit = useCallback(async () => {
    let values: AccountsBuyerSurveyCreateRequestContract;
    try {
      values = await form.validateFields();
    } catch (e) {
      setFormHasErrored(true);
      return handleValidationError(form, e);
    }

    try {
      await withShowApiErrorMiddleware(createBuyerSurvey)({
        accountId,
        accountsBuyerSurveyCreateRequestContract: values,
      });
      onSubmitSuccess?.();
      refetch();
    } catch (error) {
      console.error(error);
    }
  }, [accountId, createBuyerSurvey, form, onSubmitSuccess]);

  return {
    onSubmit,
    isSubmitting: isCreating,
    getFormConfig,
    getReadOnlyData,
    data,
    isLoading: surveyLoading,
    refetch,
    formHasErrored,
  };
};
