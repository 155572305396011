import { type HistoryEvent } from '../components/molecules/Link/useHistoryEventListener';

type SupportTicket = {
  key: string;
  title?: string;
};

const UIEventValues = {
  REQUEST_SUPPORT: 'REQUEST_SUPPORT',
  HISTORY_EVENT: 'HISTORY_EVENT',
} as const;

export type UIEvent =
  | typeof UIEventValues.REQUEST_SUPPORT
  | typeof UIEventValues.HISTORY_EVENT;

export const eventBus = {
  on: <E extends UIEvent>(
    event: E,
    callback: (
      arg: E extends typeof UIEventValues.HISTORY_EVENT
        ? HistoryEvent
        : E extends typeof UIEventValues.REQUEST_SUPPORT
          ? SupportTicket
          : never
    ) => void
  ): (() => void) => {
    const fn = (e: Event) =>
      callback(
        (
          e as CustomEvent<
            E extends typeof UIEventValues.HISTORY_EVENT
              ? HistoryEvent
              : E extends typeof UIEventValues.REQUEST_SUPPORT
                ? SupportTicket
                : never
          >
        ).detail
      );
    document.addEventListener(event, fn);
    return () => document.removeEventListener(event, fn);
  },
  dispatch: <E extends UIEvent>(
    event: E,
    data?: E extends typeof UIEventValues.HISTORY_EVENT
      ? HistoryEvent
      : E extends typeof UIEventValues.REQUEST_SUPPORT
        ? SupportTicket
        : never
  ) => {
    return document.dispatchEvent(
      new CustomEvent<
        E extends typeof UIEventValues.HISTORY_EVENT
          ? HistoryEvent
          : E extends typeof UIEventValues.REQUEST_SUPPORT
            ? SupportTicket
            : never
      >(event, { detail: data })
    );
  },
};
