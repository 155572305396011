import { Flex } from '@npm/core/ui/components/atoms/common';
import styled from 'styled-components';

export const Wrapper = styled(Flex)<{ read: boolean }>`
  padding: ${({ theme }) => theme.spacing.md}px;

  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme, read }) =>
    read ? theme.color.general.layout.two : theme.color.general.layout.zero};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.general.layout.one};
  }
`;
