import { Card as CardBase } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const PathCard = styled(CardBase)`
  & .card-content {
    padding: 0;
  }
`;

export const PathContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md}px;

  & > :nth-last-child(3) {
    min-width: 60px;
    flex-shrink: 99;
  }

  & > :nth-last-child(2) {
    min-width: 60px;
    flex-shrink: 1;
  }

  & > :nth-child(1) {
    min-width: unset;
    flex-shrink: unset;
  }

  & > .dots > div {
    overflow: visible;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.sm}px;
      & > :nth-last-child(3), & > :nth-last-child(2) {
        min-width: 48px;
      }
      & > :nth-child(1) {
        min-width: unset;
        flex-shrink: unset;
      }
  `}
`;

export const Level = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.general.typography.secondary};

  p,
  button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 16px;
  }

  svg:first-of-type {
    margin: 0 ${({ theme }) => theme.spacing.sm}px;

    ${({ theme }) => useBreakpoint(theme).mobile`
      margin: 0 ${theme.spacing.xs}px;
    `}
  }
`;

export const LevelContent = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;
// This prevents adding text ellipsis on last level too soon.
// Without that the ellipsis would be shown as soon as parent level starts to shrink
export const LevelBumper = styled.div`
  min-width: 0;
  flex: 1;
  width: 3px;
`;

export const ActionsContainer = styled.div`
   {
    margin-left: auto;
  }
`;
