import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { useTheme } from 'styled-components';

type Props = {
  date?: string;
  showTimeIcon?: boolean;
};

export const ExpiresOnDateTime = ({ date, showTimeIcon = false }: Props) => {
  const { isMobile, isSmDesktop } = useBreakpoints();
  const theme = useTheme();

  const showIcon = showTimeIcon || isSmDesktop || isMobile;

  if (!date) return null;

  return (
    <Flex align="center">
      {showIcon ? (
        <Icon
          name="history"
          size="xs"
          color={theme.color.general.typography.tertiary}
        />
      ) : (
        <Text size="sm" colorVariant="primary">
          Expires on
        </Text>
      )}
      <Margin right="xs" />
      <Text size="sm" colorVariant="primary">
        {formatDate(date, {
          dateFormat: DATE_FORMATS.MONTH_AND_DAY,
        })}
      </Text>

      <Margin left="xs" />
      <Text size="sm" colorVariant="tertiary">
        {formatDate(date, {
          dateFormat: DATE_FORMATS.TIME,
          withTimeZone: true,
        })}
      </Text>
    </Flex>
  );
};
