import { Card } from '@npm/core/ui/components/molecules/Card';
import { centerFlex } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  .card-content {
    ${centerFlex}
    flex-direction: column;
    min-height: 254px;
    text-align: center;
  }
`;
