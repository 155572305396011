import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { type ColorGroup } from 'styled-components';

import * as S from './AccountPanel.styles';

type Props = {
  accountName?: string;
  brokerageFirmName?: string;
  accountExternalId?: string;
  accountType?: string;
  representativeName?: string;
  representativeRole?: string;
  titleColor?: ColorGroup;
  omitContainerStyles?: boolean;
  includeCurrentlyUsingTitle?: boolean;
  showTitle?: boolean;
  showRepresentative?: boolean;
  onAccountSwitch?: () => void;
};

export const AccountPanel = ({
  accountName,
  brokerageFirmName,
  accountExternalId,
  accountType,
  representativeName,
  representativeRole,
  omitContainerStyles = false,
  includeCurrentlyUsingTitle = false,
  showTitle = true,
  titleColor = 'general',
  showRepresentative = true,
  onAccountSwitch,
}: Props) => {
  return (
    <S.Container
      $omitStyles={omitContainerStyles}
      $showRepresentative={showRepresentative}
    >
      <Skeleton.Base
        loading={!accountName}
        title={false}
        paragraph={{ rows: 2 }}
      >
        {includeCurrentlyUsingTitle && (
          <Text size="xs" marginBottom="sm">
            You are currently using
          </Text>
        )}
        {showTitle && (
          <Text
            size="xs"
            color={titleColor}
            colorVariant="tertiary"
            marginBottom="xs"
          >
            Account
          </Text>
        )}
        <Text
          size="sm"
          marginBottom="xs"
          colorVariant="primary"
          data-dd-privacy="mask"
        >
          {accountName}
        </Text>
        <S.Row>
          <Text
            size="xs"
            marginBottom={representativeName && showRepresentative ? 'md' : 0}
            colorVariant="secondary"
            data-dd-privacy="mask"
          >
            {accountExternalId && `${brokerageFirmName} - ${accountExternalId}`}
            {accountType && <>&nbsp;&nbsp;•&nbsp;&nbsp;{accountType}</>}
          </Text>
          {!!onAccountSwitch && (
            <S.AccountSwitcher
              variant="text"
              icon={<Icon name="refresh" size="xs" />}
              onClick={onAccountSwitch}
              onPointerDown={onAccountSwitch}
            >
              <Text size="sm" colorVariant="primary" as="span">
                Switch account
              </Text>
            </S.AccountSwitcher>
          )}
        </S.Row>
        {representativeName && showRepresentative && (
          <>
            <Text
              size="xs"
              color={titleColor}
              colorVariant="tertiary"
              marginBottom="xs"
            >
              Account Representative
            </Text>
            <Flex align="center">
              <Text size="sm" colorVariant="primary" data-dd-privacy="mask">
                {representativeName || EMPTY_VALUE}
              </Text>
              {representativeRole && (
                <Text size="xs" colorVariant="secondary">
                  &nbsp;&nbsp;•&nbsp;&nbsp;
                  {representativeRole}
                </Text>
              )}
            </Flex>
          </>
        )}
      </Skeleton.Base>
    </S.Container>
  );
};
