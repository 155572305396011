import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type Blocker, useBlocker } from 'react-router-dom';

const DiscardModalContext = createContext<{
  unsavedFormIds: string[];
  hasAnyUnsavedChanges: boolean;
  hasUnsavedChanges: (formId: string) => boolean;
  onFormChange: (formId: string, hasChanges: boolean) => void;
  blocker: Blocker;
} | null>(null);

export const DiscardModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [unsavedFormIds, setUnsavedFormIds] = useState<string[]>([]);
  const hasAnyUnsavedChanges = unsavedFormIds.length > 0;

  const onFormChange = useCallback(
    (formId: string, hasUnsavedChanges: boolean) => {
      setUnsavedFormIds(current => {
        const next = [...current].filter(id => id !== formId);
        if (hasUnsavedChanges) next.push(formId); // The latest change must go last
        return next;
      });
    },
    []
  );

  const hasUnsavedChanges = useCallback(
    (formId: string) => unsavedFormIds.includes(formId),
    [unsavedFormIds]
  );

  const shouldBlockNavigation = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasAnyUnsavedChanges &&
      currentLocation.pathname !== nextLocation.pathname,
    [hasAnyUnsavedChanges]
  );

  const blocker = useBlocker(shouldBlockNavigation);

  // Browser-level actions, such as closing a tab, closing the browser window,
  // and manually changing the URL, can only be intercepted using the default
  // confirmation dialog
  useEffect(() => {
    const handleWindowUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ''; // Legacy
    };

    if (hasAnyUnsavedChanges) {
      window.addEventListener('beforeunload', handleWindowUnload);
    } else {
      window.removeEventListener('beforeunload', handleWindowUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [hasAnyUnsavedChanges]);

  const value = useMemo(() => {
    return {
      unsavedFormIds,
      hasAnyUnsavedChanges,
      hasUnsavedChanges,
      onFormChange,
      blocker,
    };
  }, [
    unsavedFormIds,
    hasAnyUnsavedChanges,
    hasUnsavedChanges,
    onFormChange,
    blocker,
  ]);

  return (
    <DiscardModalContext.Provider value={value}>
      {children}
    </DiscardModalContext.Provider>
  );
};

export const useConfirmOnLeave = (formId: string) => {
  const context = useContext(DiscardModalContext);
  if (!context)
    throw new Error(
      'useConfirmOnLeave must be used within a DiscardModalProvider'
    );

  const { unsavedFormIds, onFormChange, blocker } = context;
  const hasUnsavedChanges = context.hasUnsavedChanges(formId);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const closeCallback = useRef<() => void>();

  const setHasUnsavedChanges = useCallback(
    (value: boolean) => onFormChange(formId, value),
    [onFormChange, formId]
  );

  useEffect(() => {
    // If the form is unmounted, unblock navigation
    return () => {
      onFormChange(formId, false);
    };
  }, [formId]);

  useEffect(() => {
    // If react router has blocked navigation, open the confirm modal,
    // but only if this form is the latest unsaved form
    if (unsavedFormIds.at(-1) === formId && blocker.state === 'blocked') {
      setIsConfirmModalOpen(true);
    }
  }, [unsavedFormIds, formId, blocker.state]);

  const handleCancel = useCallback(() => {
    blocker.reset?.();
    setIsConfirmModalOpen(false);
  }, [blocker]);

  const handleConfirm = useCallback(() => {
    setHasUnsavedChanges(false);
    setIsConfirmModalOpen(false);

    closeCallback.current?.();
    blocker?.proceed?.();
  }, [blocker, setHasUnsavedChanges]);

  const handleCloseAttempt = useCallback(
    (callback?: () => void) => {
      closeCallback.current = callback;
      if (hasUnsavedChanges) {
        setIsConfirmModalOpen(true);
      } else {
        handleConfirm();
      }
    },
    [handleConfirm, hasUnsavedChanges]
  );

  return {
    hasUnsavedChanges,
    setHasUnsavedChanges,
    onCloseAttempt: handleCloseAttempt,
    discardModalProps: {
      open: isConfirmModalOpen,
      onCancel: handleCancel,
      onOk: handleConfirm,
    },
  };
};

export const useBlockerState = () => {
  const context = useContext(DiscardModalContext);
  if (!context)
    throw new Error(
      'useBlockerState must be used within a DiscardModalProvider'
    );

  return { isBlocked: context.hasAnyUnsavedChanges };
};
