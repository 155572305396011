import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { ShowMore } from '@npm/core/ui/components/molecules/ShowMore';
import { type VenusApi } from '@npm/data-access';
import { useTheme } from 'styled-components';

import * as S from './Investors.styles';

type Props = {
  companyProfile: VenusApi.CompanyProfile | undefined;
  isLoading?: boolean;
  defaultExpanded?: boolean;
  collapseAfter?: number;
};

export const Investors = ({
  companyProfile,
  isLoading,
  defaultExpanded = false,
  collapseAfter = 3,
}: Props) => {
  const theme = useTheme();
  const investors = companyProfile?.investors || [];
  const base = investors.slice(0, collapseAfter);
  const rest = investors.slice(collapseAfter);

  const renderInvestor = (investor: string, i: number) => (
    <S.Label key={i} round>
      {investor}
    </S.Label>
  );

  if (isLoading) return <Skeleton.Base noMargin paragraph={{ rows: 1 }} />;

  return (
    <div>
      <Text size="sm" colorVariant="primary" marginBottom="md">
        Investors{' '}
        {investors.length ? (
          <Text size="sm" as="span" colorVariant="tertiary">
            ({investors.length})
          </Text>
        ) : null}
      </Text>
      {investors.length ? (
        <Flex gap={'sm'} $wrap={true}>
          <>
            {base.map((firm, i) => renderInvestor(firm, i))}
            <ShowMore
              renderCollapsed={
                <Text
                  as="span"
                  size="sm"
                  colorVariant="tertiary"
                  style={{ marginRight: theme.spacing.xs + 'px' }}
                >
                  (+{rest.length}){' '}
                </Text>
              }
              renderExpanded={rest.map((firm, i) => renderInvestor(firm, i))}
              collapsible={rest.length > 0}
              defaultExpanded={defaultExpanded}
            />
          </>
        </Flex>
      ) : (
        <Text size="sm">No Data</Text>
      )}
    </div>
  );
};
