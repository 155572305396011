import { type ComponentProps, useCallback, useMemo } from 'react';

import { type FolderAggregate } from '@npm/data-access';
import { NumberParam, useQueryParam } from 'use-query-params';

import { type DataRoom } from './DataRoom';
import { findFolderWithHistoryById } from './DataRoom.utils';

export const useDataRoom = (
  folderStructure: FolderAggregate,
  canAccessDataRoomsList?: boolean
): [
  { goToRoot: () => void },
  Pick<
    ComponentProps<typeof DataRoom>,
    'selectedFolder' | 'history' | 'onFolderClick' | 'onBackClick' | 'position'
  >,
] => {
  const [selectedFolderId, setSelectedFolderId] = useQueryParam(
    'folderId',
    NumberParam
  );

  const [selectedFolder, history] = useMemo(
    () => findFolderWithHistoryById(folderStructure, selectedFolderId),
    [folderStructure, selectedFolderId]
  );

  const position = Math.max(history.length - 1, 0);

  const onFolderClick = useCallback(
    id => {
      setSelectedFolderId(
        selectedFolder?.folders.find(folder => folder.id === id)?.id
      );
    },
    [selectedFolder, folderStructure]
  );

  const onBackClick = useCallback(
    (amount = 1) => {
      if (position <= 0) return;

      const newPosition = Math.max(position - amount, 0);

      setSelectedFolderId(history[newPosition].id);
    },
    [position, history]
  );

  const goToRoot = useCallback(
    () => setSelectedFolderId(history[0]?.id),
    [history]
  );

  return [
    { goToRoot },
    {
      selectedFolder,
      // remove first item from history if user can't access the root list
      position: canAccessDataRoomsList ? position : position - 1,
      history: canAccessDataRoomsList ? history : history.slice(1),
      onFolderClick,
      onBackClick,
    },
  ];
};
