import React from 'react';

import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { AlertContainer } from '@npm/core/ui/components/molecules/AlertContainer';
import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  StepState,
  type WizardStore,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { type CbOnboardingStatus } from '@npm/data-access';

import { type ZendeskTicketGroupID } from '../../app/zendesk';
import {
  type OnboardingStep,
  type PostOnboardingContextType,
} from '../PostOnboarding.types';
import { StepActionButtons } from '../StepActionButtons';
import { WarningStateAlert } from '../WarningStateAlert';

import * as S from './StepContainer.styles';
import { StepHeading } from './StepHeading';

export const POST_ONBOARDING_STEP_CONTAINER_ID =
  'post-onboarding-step-container';

type Props = {
  children: React.ReactNode;
  stepProps: Wizard.StepProps & { step: OnboardingStep };
  isLoading?: boolean;
  isUpdating?: boolean;
  handleSubmit?: () => void;
  disableSubmitButton?: boolean;
  handleEdit?: () => void;
  updateButtonProps?: React.ComponentProps<
    typeof StepActionButtons
  >['updateButtonProps'];
  zendeskTicketGroupId?: ZendeskTicketGroupID;
  'data-cy'?: string;
  isFullWidth?: boolean;
  hideStepHeading?: boolean;
};

export const StepContainer = ({
  stepProps,
  isLoading,
  isUpdating,
  handleSubmit,
  disableSubmitButton,
  handleEdit,
  updateButtonProps,
  children,
  zendeskTicketGroupId,
  isFullWidth = false,
  hideStepHeading = false,
  ...props
}: Props) => {
  const activeStepState = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => s.activeStepState
  );

  return (
    <S.StepContainer
      id={POST_ONBOARDING_STEP_CONTAINER_ID}
      $isFullWidth={isFullWidth}
      data-cy={props['data-cy']}
    >
      {!hideStepHeading && (
        <StepHeading
          title={stepProps.step.title}
          tooltip={stepProps.step.titleTooltip}
        />
      )}
      <AlertContainer />
      <WarningStateAlert
        stepKey={stepProps.step.key as keyof typeof CbOnboardingStatus.steps}
        zendeskTicketGroupId={zendeskTicketGroupId}
      />
      <S.ChildrenWrapper>
        {isLoading ? <Skeleton.Base /> : children}
      </S.ChildrenWrapper>
      {!isLoading && (
        <StepActionButtons
          stepTitle={stepProps.step.title}
          handleSubmit={handleSubmit}
          handleEdit={handleEdit}
          updateButtonProps={updateButtonProps}
          isFilled={activeStepState === StepState.FILLED}
          isEditable={stepProps.step.isEditable}
          isLoading={isUpdating}
          disableSubmitButton={disableSubmitButton}
          handleCloseFormAttempt={stepProps.handleCloseFormAttempt}
        />
      )}
    </S.StepContainer>
  );
};
