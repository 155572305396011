import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';

import { Result } from './ErrorPath.styles';
import { NotFoundContent } from './NotFoundContent';
import { PlugIcon } from './PlugIcon';
import { UnauthorizedContent } from './UnauthorizedContent';

const defaultHeadingByStatus = {
  '403': 'Unauthorized',
  '404': 'Error',
};

const defaultTitleByStatus = {
  '403': null,
  '404': 'Sorry, the page you visited does not exist.',
};

type Props = {
  status?: '404' | '403';
  heading?: string;
  title?: string;
};

export const ErrorPath = ({
  status,
  heading = defaultHeadingByStatus[status],
  title = defaultTitleByStatus[status],
}: Props) => {
  const history = useHistory();

  const handleGoBackUnauthorized = () => {
    // history.length < 4 means that user opened the app from a link and landed on the error page
    if (window.history.length < 4) {
      history.replace('/');
    } else {
      // need to go back 2 steps otherwise the user will land back on error page
      history.goBack(-2);
    }
  };

  return (
    <Result
      icon={<PlugIcon status={status} />}
      title={
        <Heading marginBottom="xs" variant="h1">
          {heading}
        </Heading>
      }
      subTitle={
        title ? (
          <Text size="sm" colorVariant="secondary">
            {title}
          </Text>
        ) : null
      }
      extra={
        <Flex gap={'md'} style={{ width: '100%' }}>
          {status === '403' && (
            <UnauthorizedContent handleGoBack={handleGoBackUnauthorized} />
          )}
          {status === '404' && <NotFoundContent />}
          {status !== '404' && status !== '403' && (
            <Button onClick={() => window.location.reload()}>
              Refresh Page
            </Button>
          )}
        </Flex>
      }
    />
  );
};

export default ErrorPath;
