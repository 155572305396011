import React from 'react';

import { type SkeletonProps } from 'antd';
import { type AvatarProps } from 'antd/lib/skeleton/Avatar';
import { type SkeletonButtonProps } from 'antd/lib/skeleton/Button';
import { type SkeletonImageProps } from 'antd/lib/skeleton/Image';
import { type SkeletonInputProps } from 'antd/lib/skeleton/Input';
import { type SkeletonNodeProps } from 'antd/lib/skeleton/Node';

import { SkeletonStyled } from './Skeleton.styles';

export const Skeleton = {
  Base: (props: SkeletonProps & { noMargin?: boolean }) => (
    <SkeletonStyled
      data-cy="loader"
      active
      {...props}
      $noMargin={props?.noMargin}
    />
  ),
  Button: (props: SkeletonButtonProps) => (
    <SkeletonStyled.Button data-cy="loader" active {...props} />
  ),
  Avatar: (props: AvatarProps) => (
    <SkeletonStyled.Avatar data-cy="loader" active {...props} />
  ),
  Input: (props: SkeletonInputProps) => (
    <SkeletonStyled.Input data-cy="loader" active {...props} />
  ),
  Image: (props: SkeletonImageProps) => (
    <SkeletonStyled.Image data-cy="loader" {...props} />
  ),
  Node: (props: SkeletonNodeProps) => (
    <SkeletonStyled.Node data-cy="loader" active {...props} />
  ),
};
