import { type ComponentProps, type ReactNode } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Image } from '@npm/core/ui/components/atoms/Image/Image.styles';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';

import { getCompanyDataAsset } from './BasicPageLayout.config';

type ReadMoreModalProps = Omit<ComponentProps<typeof Modal>, 'onCancel'> & {
  title: string;
  description: ReactNode;
  imagePrefix: string;
  imagesSuffix: string;
  requestPremiumMembership: () => void;
  onCancel: () => void;
};

export const ReadMoreModal = ({
  title,
  description,
  imagePrefix,
  imagesSuffix,
  requestPremiumMembership,
  ...modalProps
}: ReadMoreModalProps) => {
  const handleRequestPremium = () => {
    modalProps?.onCancel?.();
    requestPremiumMembership();
  };

  return (
    <Modal
      title={title}
      okText="Request Trial"
      okButtonProps={{
        onClick: handleRequestPremium,
      }}
      cancelText="Close"
      width={600}
      // to cover Tooltip
      zIndex={9999}
      {...modalProps}
    >
      <Flex direction="column" gap="lg">
        <Image
          preview={false}
          src={getCompanyDataAsset(imagePrefix, imagesSuffix)}
        />
        {typeof description === 'string' ? (
          <Text size="sm">{description}</Text>
        ) : (
          description
        )}
      </Flex>
    </Modal>
  );
};
