export { useParams } from 'react-router-dom';

export class ParamNotFoundError extends Error {
  status: number;

  constructor(message: string) {
    super(message);
    this.status = 404;
  }
}
