import { BulletPoints } from '@npm/core/ui/components/molecules/BulletPoints';
import { type OrderItemStateCode } from '@npm/data-access';

const commonTooltips = {
  partially_filled: 'This order has been partially filled and is settled.',
  filled: 'This order has been filled and has settled.',
  matched:
    'This order has been matched with a counterparty and has not been settled.',
  pending_settlement: 'This order is waiting to be settled.',
};

const individualTooltips = {
  incomplete: (
    <>
      Note that we need the following details before posting your offer to the
      Marketplace:
      <BulletPoints
        bulletPoints={['Order Price Per Share', 'Holding Proof of Ownership']}
      />
    </>
  ),
  not_posted_to_marketplace:
    'This order is currently under review by NPM and will be posted to the marketplace once approved. If any additional information is required, a representative will reach out to you.',
  cancelled:
    'This order has been cancelled. To place a new order, click the Submit a Sell Indication button.',
  expired:
    'This order has expired. To place a new order, click the Submit a Sell Indication button.',
};

const entityTooltips = {
  incomplete:
    'This order incomplete because your order is missing a price per share. Once you confirm your order, we will review and post to the marketplace on your behalf.',
  cancelled:
    'This order has been cancelled. To place a new order, click the Enter IOI button.',
  expired:
    'This order has expired. To place a new order, click the Enter IOI button.',
};

const brokerTooltips = {
  cancelled:
    'This order has been cancelled. To place a new order, click the Trade button.',
  expired:
    'This order has expired. To place a new order, click the Trade button.',
};

export const getTooltip = (
  code: OrderItemStateCode,
  variant: 'individual' | 'entity' | 'broker'
) => {
  switch (variant) {
    case 'individual':
      return individualTooltips[code] ?? commonTooltips[code];
    case 'entity':
      return entityTooltips[code] ?? commonTooltips[code];
    case 'broker':
      return brokerTooltips[code] ?? commonTooltips[code];
    default:
      return undefined;
  }
};
