import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

import { ResetButton as ResetButtonBase } from '../../ResetButton';

import { CompleteButton as CompleteButtonBase } from './CompleteButton/CompleteButton';

export const QuestionText = styled.div`
  width: 50%;
`;

export const ExemptionText = styled(Text)`
  text-align: left;
  max-width: 395px;
`;

export const CompleteButton = styled(CompleteButtonBase)`
  ${({ theme }) => useBreakpoint(theme).mobile`
    grid-column: span 2;
  `}
`;

export const ResetButton = styled(ResetButtonBase)`
  ${({ theme }) => useBreakpoint(theme).mobile`
    grid-column: 2;
    grid-row: 1;
  `}
`;

export const ActionButtonWrapper = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacing.md}px;
  grid-template-columns: repeat(3, fit-content(100%));

  ${({ theme }) => useBreakpoint(theme).mobile`
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: ${({ theme }) => theme.spacing.md}px;
    grid-template-rows: repeat(2, fit-content(100%));
  `}
`;

export const Ul = styled.ul`
  padding-left: 0;
`;
