import { Button } from '@npm/core/ui/components/atoms/Button';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  ${({ theme }) => useBreakpoint(theme).smTablet`
    align-items: center;
    flex-direction: row;
    padding: ${theme.spacing.md}px;
    background-color: ${({ theme }) => theme.color.general.layout.one};
    width: 100%;
    position: fixed; 
    bottom: 0;
  `}
`;

export const BackButton = styled(Button)`
  ${({ theme }) => useBreakpoint(theme).smTablet`
    width: 60px;
    flex-shrink: 0;
    flex: 1;
  `}
`;

export const SubmitButton = styled(Button)`
  ${({ theme }) => useBreakpoint(theme).smTablet`
    flex: 2;
  `}
`;
