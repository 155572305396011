import { type GridComponentProps } from '@npm/core/ui/components/atoms/Grid';
import { type Rule } from 'antd/lib/form';

export const getGridConfig: (
  columnCount: 1 | 2
) => Pick<GridComponentProps, 'cols' | 'areas' | 'gap'> = columnCount => ({
  cols: {
    default: {
      count: columnCount,
      size: '1fr',
    },
    mobile: {
      count: 1,
      size: '1fr',
    },
  },
  areas: {
    default:
      columnCount === 1
        ? '"first" "middle" "last" "email"'
        : "'first middle' 'last email'",
    mobile: '"first" "middle" "last" "email"',
  },
  gap: {
    default: '16px',
  },
});

export const NAME_VALIDATION_RULE: Rule = {
  pattern: /^[\p{L}\p{M}\s-]+$/u, // same regex as they have on BE
  message: 'Only letters, spaces and hyphens are allowed.',
};
