import React, { useMemo, useRef, useState } from 'react';

import {
  Select,
  SELECT_PAGE_SIZE_BIG,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { CompanySearchLogo } from '@npm/core/ui/components/molecules/CompanySearchLogo';
import {
  type IssuerEntityAggregate,
  useIssuerEntityIndexInfinite,
  type Watchlist,
} from '@npm/data-access';
import { uniqBy } from 'lodash';

import { useRequestCompanyCoverage } from '../../company/RequestCompanyCoverage';

import * as S from './WatchlistInput.styles';
import { WatchlistCompanyTag } from './WatchlistCompanyTag';
import {
  areAllValuesUndeletable,
  canCompanyBeUnselected,
  getWatchlistMap,
  keepUndeletableValues,
} from './WatchlistInput.utils';

type Props = {
  onFocus?: () => void;
  value?: string[];
  onChange?: (companies: string | string[]) => void;
  closeOnceSelected?: boolean;
  watchlists?: Watchlist[];
  mode?: 'multiple' | 'single';
  showRequestCoverage?: boolean;
};

export const WatchlistInput = ({
  value,
  onChange,
  onFocus,
  closeOnceSelected = true,
  watchlists,
  mode = 'multiple',
  showRequestCoverage = false,
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();
  const resetSearchTerm = () => selectAsyncProps.onSearch('');

  const { requestCoverageButtonComponent, requestCoverageModalComponent } =
    useRequestCompanyCoverage({ searchTerm });

  const selectedCompaniesMapCache = useRef<
    Record<string, IssuerEntityAggregate>
  >({});

  const [open, setOpen] = useState(false);
  const wasClosedOnce = useRef(false);

  const watchlistMap = useMemo(
    () => getWatchlistMap(watchlists ?? []),
    [watchlists]
  );

  const {
    data,
    isLoading,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useIssuerEntityIndexInfinite(
    {
      size: SELECT_PAGE_SIZE_BIG,
      search: searchTerm,
    },
    { onError: Select.onError }
  );

  const options = useMemo(() => {
    if (!data) return [];

    const merged = data?.pages?.reduce(
      (mergedArray, page) => [...mergedArray, ...page.issuer_entities],
      []
    );

    return uniqBy(
      merged.map(item => ({
        label: (
          <CompanySearchLogo
            url={item.logo_url}
            size="sm"
            name={item.name}
            title={item.name}
          />
        ),
        value: item.id.toString(),
        item,
      })),
      'value'
    );
  }, [data]);

  const displayValue = useMemo(() => {
    if (mode === 'single' && value && !Array.isArray(value)) {
      const selectedItem = options.find(option => option.value === value);
      return selectedItem ? selectedItem.item.name : value;
    }
    return value;
  }, [mode, value, options]);

  return (
    <>
      <S.Select
        open={open}
        onDropdownVisibleChange={visible => setOpen(visible)}
        variant="search"
        mode={mode === 'single' ? undefined : mode}
        tagRender={({ value, onClose }) => {
          const item = selectedCompaniesMapCache.current[value];

          return (
            <WatchlistCompanyTag
              companyId={value}
              company={item}
              onClose={onClose}
              disableRemove={
                !canCompanyBeUnselected(parseInt(value), watchlistMap)
              }
            />
          );
        }}
        maxTagCount={999}
        onPopupScroll={e =>
          onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
        }
        placeholder={'Select...'}
        error={error}
        loading={isLoading}
        infiniteLoading={isFetchingNextPage}
        value={displayValue}
        allowClear={
          mode === 'multiple' &&
          areAllValuesUndeletable(displayValue, watchlistMap)
            ? false
            : undefined
        }
        onChange={(values: string | string[]) => {
          values = keepUndeletableValues(values, displayValue, watchlistMap);

          if (closeOnceSelected && !wasClosedOnce.current) {
            wasClosedOnce.current = true;
            setOpen(false);
          }
          if (mode === 'multiple' && values instanceof Array) {
            values.forEach(value => {
              if (!selectedCompaniesMapCache.current[value]) {
                const company = options.find(
                  option => option.value === value
                )?.item;
                if (company) {
                  selectedCompaniesMapCache.current[value] = company;
                }
              }
            });
          }
          onChange(values);
          resetSearchTerm();
        }}
        onBlur={resetSearchTerm}
        autoClearSearchValue={true}
        getPopupContainer={() => document.body}
        onFocus={onFocus}
        {...(!showRequestCoverage && {
          notFoundContent: searchTerm
            ? `"${searchTerm}" is not available`
            : 'No data',
        })}
        {...selectAsyncProps}
      >
        {options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            item={option.item}
          >
            {option.label}
          </Select.Option>
        ))}
        {showRequestCoverage && (
          <>
            {!isLoading && !!searchTerm.length && (
              <Select.Option disabled>
                {requestCoverageButtonComponent}
              </Select.Option>
            )}
          </>
        )}
      </S.Select>
      {requestCoverageModalComponent}
    </>
  );
};
