import React, { useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  getFilenameExtension,
  getFilenameWithoutExtension,
} from '@npm/core/ui/utils/file';
import { type DocumentAggregate } from '@npm/data-access';
import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTheme } from 'styled-components';

const EDIT_DOCUMENT_FORM_ID = 'edit-document-form';

export type EditDocumentFormValues = {
  display_name: string;
  downloadable?: boolean;
  watermark?: boolean;
};

type Props = {
  document: DocumentAggregate;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (id: number, values: EditDocumentFormValues) => Promise<void>;
};

export const EditDocumentDrawer = ({
  document,
  isOpen,
  onClose,
  onSubmit,
}: Props) => {
  const theme = useTheme();

  const [form] = useForm<EditDocumentFormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { display_name, ...fields } = await form.validateFields();
      await onSubmit?.(document.id, {
        display_name:
          display_name + getFilenameExtension(document.display_name),
        ...fields,
      });
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      open={isOpen}
      title="Edit Document"
      onClose={handleClose}
      destroyOnClose
      submitButtonProps={{
        children: 'Save',
        form: EDIT_DOCUMENT_FORM_ID,
        htmlType: 'submit',
        'data-cy': CypressDataIds.DataRooms.EditDocumentDrawer.SubmitButton,
        loading: isSubmitting,
      }}
    >
      <Form<EditDocumentFormValues>
        id={EDIT_DOCUMENT_FORM_ID}
        form={form}
        initialValues={{
          ...document,
          display_name: getFilenameWithoutExtension(document.display_name),
        }}
        data-cy={CypressDataIds.DataRooms.EditDocumentDrawer.Form}
        onFinish={handleSubmit}
      >
        <Row gutter={[theme.spacing.md, theme.spacing.lg]}>
          <Col span={24}>
            <InputFormItem
              name="display_name"
              label="Display Name"
              rules={[VALIDATION_RULES.required('Name')]}
            >
              <Input suffix={getFilenameExtension(document.display_name)} />
            </InputFormItem>
          </Col>
          <Col span={12}>
            <FormItem valuePropName="checked" name="downloadable">
              <Switch id="drawer-download-switch" title={'Download'} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem valuePropName={'checked'} name="watermark">
              <Switch id="drawer-watermark-switch" title={'Watermark'} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
