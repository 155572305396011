import { useEffect, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  type Account,
  getApiErrorCode,
  useAccountSpouseShow,
  useAccountSpouseUpdate,
  usePersonShow,
} from '@npm/data-access';
import { isMatch } from 'lodash';

import { useGetPostOnboardingPersonId } from '../../postOnboarding';

import { type SpousalForm } from './Form/SpousalInformationForm.types';

export const useSpousalInformationForm = (
  account: Account,
  {
    isCompleted = true,
    skipCacheInvalidation,
    onUpdate,
  }: {
    isCompleted: boolean;
    skipCacheInvalidation?: (spouseEmail: string) => boolean;
    onUpdate?: (spouseEmail: string) => void;
  }
) => {
  const [form] = Form.useForm<SpousalForm>();
  const { withShowApiErrorMiddleware } = useAlerts();
  const handleError = useErrorHandler();
  const getPersonId = useGetPostOnboardingPersonId();
  const personId = getPersonId(account);

  const { data, isFetching } = useAccountSpouseShow(
    {
      accountId: account?.id,
    },
    {
      queryConfig: {
        enabled: !!account?.id && isCompleted,
      },
      onError: e => {
        // if spouse not found (404), it only means that the person doesn't have one
        // -> don't show error
        if (getApiErrorCode(e) === 404) {
          console.error(e);
        } else {
          handleError(e);
        }
      },
    }
  );

  const { data: personData, isFetching: isFetchingPerson } = usePersonShow(
    {
      id: personId,
    },
    {
      queryConfig: {
        enabled: !!personId,
      },
    }
  );

  const { execute: updateSpouse, isLoading: isUpdating } =
    useAccountSpouseUpdate();

  const initialValues = useMemo<Partial<SpousalForm> | null>(() => {
    if (isCompleted && !data) {
      return { has_spouse: false };
    }

    if (isCompleted) {
      return {
        ...data,
        has_spouse: !!data,
      };
    }

    return null;
  }, [data, isCompleted]);

  const handleSubmit = async () => {
    let values: SpousalForm;

    try {
      values = await form.validateFields();
    } catch (e) {
      return console.error(e);
    }

    if (isMatch(initialValues, values)) {
      onUpdate?.(values.email);
      return;
    }

    try {
      await withShowApiErrorMiddleware(updateSpouse)(
        {
          accountId: account.id,
          accountsSpouseUpdateRequestContract: {
            ...(values.has_spouse && {
              first: values.first,
              middle: values.middle,
              last: values.last,
              email: values.email,
            }),
            has_spouse: values.has_spouse,
          },
        },
        {
          skipCacheInvalidation: skipCacheInvalidation?.(values.email),
        }
      );
      onUpdate?.(values.email);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  return {
    form,
    isLoading: isFetching || isFetchingPerson,
    isUpdating,
    handleSubmit,
    data,
    canEditSpouse: personData?.can_change_spouse === true,
  };
};
