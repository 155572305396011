import React, { useMemo, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import {
  Select,
  SELECT_PAGE_SIZE,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { WysiwygInput } from '@npm/core/ui/components/atoms/WysiwygInput';
import { useParams } from '@npm/core/ui/hooks/useParams';
import {
  useAccountShow,
  useBrokerageFirmUserIndexInfinite,
  type UserWithRolesAggregate,
} from '@npm/data-access';
import { Form, type FormInstance } from 'antd';

import { useEmail } from '../../hooks';
import { UserRolesFormItem } from '../Form/UserRolesFormItem';
import { PersonInfoForm } from '../PersonInfoForm';

import * as S from './CreateUserWithRolesForm.styles';

type Props = {
  brokerageFirmId: number;
  form: FormInstance;
  mode: 'new' | 'existing' | 'any';
  addon?: React.ReactNode;
};

export const CreateUserWithRolesForm = ({
  brokerageFirmId,
  form,
  mode = 'any',
  addon,
}: Props) => {
  const [creatingNewUser, setCreatingNewUser] = useState(mode === 'new');
  const [sendEmail, setSendEmail] = useState(false);

  const toggleSendEmail = () => {
    // Clear the email fields if the user is toggling the switch
    if (sendEmail) {
      form.setFieldsValue({ body: '', subject: '' });
    }

    setSendEmail(!sendEmail);
  };

  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  // @TODO: AccountId is not available in params in brokerage workstation --> individuals --> New Account.
  // Query param is not available, we should find better way to obtain accountId
  const { accountId } = useParams<{ accountId: string }>();
  const { data: accountData } = useAccountShow(
    { id: accountId },
    { queryConfig: { enabled: !!accountId } }
  );

  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useBrokerageFirmUserIndexInfinite({
      brokerageFirmId,
      ...(searchTerm ? { search: searchTerm } : {}),
      size: SELECT_PAGE_SIZE,
    });

  const { from } = useEmail();

  const options = useMemo(() => {
    if (!data) return null;

    const usersInAccount = accountData?.users.map(user => user.id) || [];
    const mergedUserPages = data.pages.reduce(
      (mergedArray, page) => [...mergedArray, ...page.users],
      [] as UserWithRolesAggregate[]
    );

    // Remove users from option if already assigned to given account
    const filteredUsers = mergedUserPages.filter(
      user => usersInAccount.some(id => id === user.id) === false
    );

    return filteredUsers.map(user => ({
      id: user.id,
      label: `${user.person.first} ${user.person.last}`,
      value: user.id,
      email: `${user.person.email}`,
    }));
  }, [data, accountData?.users]);

  const newUserEmail = Form.useWatch('email', form);
  const searchValue = Form.useWatch('search', form);

  const userEmail = useMemo(
    () =>
      creatingNewUser
        ? newUserEmail
        : options?.find(option => option.value === searchValue?.value)?.email,
    [creatingNewUser, newUserEmail, searchValue, options]
  );

  return (
    <S.Container>
      <S.Section>
        {creatingNewUser && (mode === 'new' || mode === 'any') && (
          <>
            <Margin bottom="md">
              <PersonInfoForm
                form={form}
                defaultColumnCount={1}
                includeEmailField
              />
            </Margin>
            {addon}
          </>
        )}
        {!creatingNewUser && (mode === 'existing' || mode === 'any') && (
          <S.FormItem
            name="search"
            label="Search User"
            rules={[VALIDATION_RULES.required()]}
          >
            <Select
              onPopupScroll={e =>
                onPopupScroll(
                  e,
                  hasNextPage && !isFetchingNextPage && fetchNextPage
                )
              }
              infiniteLoading={isFetchingNextPage}
              variant="search"
              placeholder="By Email, First name or Last name"
              loading={isLoading}
              labelInValue
              {...selectAsyncProps}
            >
              {options?.map(option => (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  {option.label}{' '}
                  <Text size="sm" colorVariant="secondary">
                    {option.email}
                  </Text>
                </Select.Option>
              ))}
            </Select>
          </S.FormItem>
        )}
        {mode === 'any' && (
          <Button
            size="sm"
            onClick={() => setCreatingNewUser(!creatingNewUser)}
          >
            {creatingNewUser ? 'Add Existing User' : 'Add New User'}
          </Button>
        )}
      </S.Section>
      <S.Section>
        <UserRolesFormItem />
      </S.Section>
      <S.EmailSection>
        <Switch
          id="send-email-switch"
          title="Send email"
          titleAfter
          onChange={() => toggleSendEmail()}
        />
        {sendEmail ? (
          <S.StyledEmailSection>
            <S.TableVertical
              rows={[
                { key: 'from', title: 'From', render: ({ from }) => from },
                { key: 'to', title: 'To', render: ({ to }) => to },
              ]}
              data={{
                from,
                to: userEmail,
              }}
            />
            <S.FormItem
              name="subject"
              label="Subject"
              rules={[VALIDATION_RULES.required()]}
            >
              <Input placeholder="Subject" />
            </S.FormItem>
            <S.FormItem
              name="body"
              label=""
              rules={[VALIDATION_RULES.required()]}
            >
              <WysiwygInput />
            </S.FormItem>
          </S.StyledEmailSection>
        ) : null}
      </S.EmailSection>
    </S.Container>
  );
};
