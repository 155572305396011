import styled from 'styled-components';

export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md}px;
  background-color: ${({ theme }) =>
    theme.color.info.backgrounds.secondaryDefault};
  gap: ${({ theme }) => theme.spacing.xs}px;
`;
