import React from 'react';

import { useTheme } from 'styled-components';

import * as S from './Progress.styles';

type Props = {
  type?: 'line' | 'circle';
  percent?: number;
  className?: string;
  width?: number;
  showInfo?: boolean;
};

export const Progress = ({
  type = 'line',
  width = 80,
  percent,
  className,
  showInfo = true,
}: Props) => {
  const {
    color: {
      info: { backgrounds },
    },
  } = useTheme();
  return (
    <S.Progress
      percent={percent ? Math.floor(percent) : 0}
      strokeWidth={5}
      width={width}
      strokeColor={backgrounds.primaryDefault}
      trailColor={backgrounds.secondaryHover}
      status={'normal'}
      type={type}
      className={className}
      showInfo={showInfo}
    />
  );
};
