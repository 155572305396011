import { useMemo } from 'react';

import { type ChartOptions } from 'chart.js';
import { useTheme } from 'styled-components';

export const useCrosshair = () => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      crosshair: {
        line: {
          width: 2,
          color: theme.color.general.borders.secondary,
          dashPattern: [4, 2],
        },
        snap: {
          enabled: true,
        },
      },
    } as ChartOptions['plugins'];
  }, [theme]);
};
