// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  LookupCompanyAliasesApi,
  InternalCompanyAliasLookupErrorCodes,
  InternalCompanyAliasLookupErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new LookupCompanyAliasesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalCompanyAliasLookupMethod = LookupCompanyAliasesApi['internalCompanyAliasLookup'];
export type InternalCompanyAliasLookupResponseType = MethodResult<InternalCompanyAliasLookupMethod>;

export const useInternalCompanyAliasLookup = (
  variables: Parameters<InternalCompanyAliasLookupMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyAliasLookupMethod,
    InternalCompanyAliasLookupErrorTypes
  >
) => {
  return useQuery<
    InternalCompanyAliasLookupMethod,
    InternalCompanyAliasLookupErrorTypes
  >(
    (...args) => api.internalCompanyAliasLookup(...args),
    InternalCompanyAliasLookupErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyAliasLookup'],
        ...apiQueryConfig.InternalCompanyAliasLookup,
        ...config?.queryConfig
      },
    },
    'InternalCompanyAliasLookup',
  );
};

export const useInternalCompanyAliasLookupInfinite = (
  variables: Parameters<InternalCompanyAliasLookupMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalCompanyAliasLookupMethod,
    InternalCompanyAliasLookupErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalCompanyAliasLookupMethod,
    InternalCompanyAliasLookupErrorTypes
  >(
    (...args) => api.internalCompanyAliasLookup(...args),
    InternalCompanyAliasLookupErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyAliasLookup', 'InternalCompanyAliasLookupInfinite'],
        ...apiQueryConfig.InternalCompanyAliasLookup,
        ...config?.queryConfig,
      },
    },
    'InternalCompanyAliasLookup',
  );
};

export const useInternalCompanyAliasLookupLazy = (baseOptions?: {
  variables?: Parameters<InternalCompanyAliasLookupMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyAliasLookupMethod,
    InternalCompanyAliasLookupErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalCompanyAliasLookupMethod,
    InternalCompanyAliasLookupErrorTypes
  >(
    (...args) => api.internalCompanyAliasLookup(...args),
    InternalCompanyAliasLookupErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalCompanyAliasLookup'],
        ...apiQueryConfig.InternalCompanyAliasLookup,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalCompanyAliasLookup',
  );
};

