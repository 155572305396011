import { type FC } from 'react';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormattableInput } from '@npm/core/ui/components/atoms/FormattableInput';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  formatEntityTaxId,
  parsedTaxIdValidator,
  parseFormattedTaxId,
} from '@npm/core/ui/utils/formatters';
import { type FormInstance } from 'antd';
import { type CheckboxChangeEvent } from 'antd/lib/checkbox';
import { type FormProps, useWatch } from 'antd/lib/form/Form';

import { EntityTaxIdGetStartedContent } from '../GetStarted';

import * as S from './Form.styles';
import { FormFields } from './Form.types';

type Props = {
  form: FormInstance;
  isExemptHidden?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const EntityTaxIdForm: FC<Props> = ({
  form,
  isExemptHidden = false,
  handleValuesChange,
}) => {
  const isExemptChecked = useWatch([FormFields.CHECKBOX_KEY], form);

  const onExemptChecked = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;

    if (checked) {
      form.resetFields([FormFields.INPUT_KEY]);
    }
  };

  return (
    <S.Wrap>
      <Margin bottom="xl">
        <EntityTaxIdGetStartedContent />
      </Margin>
      <Form form={form} onValuesChange={handleValuesChange}>
        <S.FormItem
          labelPosition="top"
          label="U.S Tax ID"
          name={FormFields.INPUT_KEY}
          extra="U.S EIN (Employer Identification Number)"
          rules={
            isExemptChecked
              ? undefined
              : [
                  { required: true, message: 'Field is required' },
                  {
                    validator: parsedTaxIdValidator,
                    message: 'Please enter a valid Tax ID',
                  },
                ]
          }
          validateTrigger="onSubmit"
        >
          <FormattableInput
            disabled={isExemptChecked}
            placeholder="00-0000000"
            formatter={formatEntityTaxId}
            parser={parseFormattedTaxId}
            data-cy={CypressDataIds.PostOnboarding.EntityTaxId.TaxId}
          />
        </S.FormItem>
        {!isExemptHidden && (
          <S.FormItem
            labelPosition="top"
            name={FormFields.CHECKBOX_KEY}
            valuePropName="checked"
          >
            <Checkbox
              onChange={onExemptChecked}
              defaultChecked={false}
              label="I do not have a U.S. Tax ID"
            />
          </S.FormItem>
        )}
      </Form>
    </S.Wrap>
  );
};
