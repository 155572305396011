import React, { type FC, type ReactNode } from 'react';

import { type ColorGroup, type DefaultTheme } from 'styled-components';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { type CardBaseProps } from '../../../atoms/CardBase';
import { Icon } from '../../../atoms/Icon';
import { Heading, Text } from '../../../atoms/Typography';

import * as S from './CardHeader.styles';

export type HeaderProps = {
  title?: ReactNode;
  titleColor?: ColorGroup;
  backgroundLayoutColor?: keyof DefaultTheme['color']['general']['layout'];
  icon?: ReactNode;
  iconText?: string;
  label?: ReactNode;
  labelAlign?: React.CSSProperties['alignSelf'];
  subHeader?: string | null;
  expandable?: boolean;
  isExpanded?: boolean;
  onChevronClick?: () => void;
  isLoading?: boolean;
  dataCy?: string;
  variant?: CardBaseProps['variant'];
  underlined?: boolean;
};

export const CardHeader: FC<HeaderProps> = ({
  title,
  titleColor,
  variant = 'default',
  backgroundLayoutColor = variant === 'compact' ? 'two' : 'one',
  icon,
  iconText,
  label,
  labelAlign,
  expandable,
  isExpanded,
  onChevronClick,
  subHeader,
  isLoading,
  dataCy,
  underlined,
}) => {
  const { isMobile } = useBreakpoints();

  const renderTitle = () => {
    if (typeof title === 'string') {
      if (variant === 'compact') {
        return (
          <Text size="sm" colorVariant="primary" color={titleColor}>
            {title}
          </Text>
        );
      } else {
        return (
          <Heading color={titleColor} variant="h3">
            {title}
          </Heading>
        );
      }
    }
    return title;
  };

  return (
    <S.HeaderWrapper
      data-cy={dataCy}
      $isCardExpanded={isExpanded}
      $underlined={underlined}
    >
      {subHeader && (
        <S.SubHeader
          $variant={variant}
          size="sm"
          colorVariant="secondary"
          color="general"
        >
          {subHeader}
        </S.SubHeader>
      )}
      <S.Content
        $variant={variant}
        $backgroundLayoutColor={backgroundLayoutColor}
        className="card-header-content"
      >
        {isLoading ? (
          <S.HeaderContentLoading />
        ) : (
          <>
            {(icon || title) && (
              <S.LeftContent className="card-header-content-left">
                {icon && (
                  <S.IconWrapper $noMarginRight={!iconText}>
                    <S.Icon>{icon}</S.Icon>
                    {iconText && (
                      <Text size="sm" color="general" colorVariant="primary">
                        {iconText}
                      </Text>
                    )}
                  </S.IconWrapper>
                )}
                {renderTitle()}
              </S.LeftContent>
            )}
            <S.RightContent
              className="card-header-content-right"
              $alignSelf={labelAlign}
            >
              {label}
              {expandable && (
                <S.ExpandButton
                  size="sm"
                  variant="text"
                  icon={
                    isExpanded ? (
                      <Icon name="chevron-up" size={isMobile ? 22 : 'md'} />
                    ) : (
                      <Icon name="chevron-down" size={isMobile ? 22 : 'md'} />
                    )
                  }
                  onClick={() => {
                    onChevronClick?.();
                  }}
                />
              )}
            </S.RightContent>
          </>
        )}
      </S.Content>
    </S.HeaderWrapper>
  );
};
