/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NewlyAddedAccounts } from '../model';
/**
 * AdminAccountsApi - axios parameter creator
 * @export
 */
export const AdminAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all accounts created recently grouped by date
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {Array<string>} [accountIds] 
         * @param {string} [search] 
         * @param {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'} [accountType] 
         * @param {'created_at' | 'updated_at' | 'sm_created_at'} [sortBy] defaults to sm_created_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {boolean} [hasHoldings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAccountsByDate: async (xWorkstation?: string, page?: number, size?: number, endDate?: string, startDate?: string, accountIds?: Array<string>, search?: string, accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount', sortBy?: 'created_at' | 'updated_at' | 'sm_created_at', sortDirection?: 'asc' | 'desc', hasHoldings?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/admin/new_accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (accountIds) {
                localVarQueryParameter['account_ids'] = accountIds.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (accountType !== undefined) {
                localVarQueryParameter['account_type'] = accountType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }

            if (hasHoldings !== undefined) {
                localVarQueryParameter['has_holdings'] = hasHoldings;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAccountsApi - functional programming interface
 * @export
 */
export const AdminAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all accounts created recently grouped by date
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {Array<string>} [accountIds] 
         * @param {string} [search] 
         * @param {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'} [accountType] 
         * @param {'created_at' | 'updated_at' | 'sm_created_at'} [sortBy] defaults to sm_created_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {boolean} [hasHoldings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAccountsByDate(xWorkstation?: string, page?: number, size?: number, endDate?: string, startDate?: string, accountIds?: Array<string>, search?: string, accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount', sortBy?: 'created_at' | 'updated_at' | 'sm_created_at', sortDirection?: 'asc' | 'desc', hasHoldings?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewlyAddedAccounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newAccountsByDate(xWorkstation, page, size, endDate, startDate, accountIds, search, accountType, sortBy, sortDirection, hasHoldings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAccountsApi - factory interface
 * @export
 */
export const AdminAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all accounts created recently grouped by date
         * @param {string} [xWorkstation] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {Array<string>} [accountIds] 
         * @param {string} [search] 
         * @param {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'} [accountType] 
         * @param {'created_at' | 'updated_at' | 'sm_created_at'} [sortBy] defaults to sm_created_at
         * @param {'asc' | 'desc'} [sortDirection] defaults to desc
         * @param {boolean} [hasHoldings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAccountsByDate(xWorkstation?: string, page?: number, size?: number, endDate?: string, startDate?: string, accountIds?: Array<string>, search?: string, accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount', sortBy?: 'created_at' | 'updated_at' | 'sm_created_at', sortDirection?: 'asc' | 'desc', hasHoldings?: boolean, options?: any): AxiosPromise<NewlyAddedAccounts> {
            return localVarFp.newAccountsByDate(xWorkstation, page, size, endDate, startDate, accountIds, search, accountType, sortBy, sortDirection, hasHoldings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for newAccountsByDate operation in AdminAccountsApi.
 * @export
 * @interface AdminAccountsApiNewAccountsByDateRequest
 */
export interface AdminAccountsApiNewAccountsByDateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {number}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly startDate?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly accountIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly search?: string

    /**
     * 
     * @type {'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly accountType?: 'PersonAccount' | 'OrganizationAccount' | 'AnonymousAccount'

    /**
     * defaults to sm_created_at
     * @type {'created_at' | 'updated_at' | 'sm_created_at'}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly sortBy?: 'created_at' | 'updated_at' | 'sm_created_at'

    /**
     * defaults to desc
     * @type {'asc' | 'desc'}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly sortDirection?: 'asc' | 'desc'

    /**
     * 
     * @type {boolean}
     * @memberof AdminAccountsApiNewAccountsByDate
     */
    readonly hasHoldings?: boolean
}

/**
 * AdminAccountsApi - object-oriented interface
 * @export
 * @class AdminAccountsApi
 * @extends {BaseAPI}
 */
export class AdminAccountsApi extends BaseAPI {
    /**
     * 
     * @summary lists all accounts created recently grouped by date
     * @param {AdminAccountsApiNewAccountsByDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountsApi
     */
    public newAccountsByDate(requestParameters: AdminAccountsApiNewAccountsByDateRequest = {}, options?: AxiosRequestConfig) {
        return AdminAccountsApiFp(this.configuration).newAccountsByDate(requestParameters.xWorkstation, requestParameters.page, requestParameters.size, requestParameters.endDate, requestParameters.startDate, requestParameters.accountIds, requestParameters.search, requestParameters.accountType, requestParameters.sortBy, requestParameters.sortDirection, requestParameters.hasHoldings, options).then((request) => request(this.axios, this.basePath));
    }
}

export type NewAccountsByDateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const NewAccountsByDateErrorCodes = [
    422,
];


