// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuerEntityCapTablePartnerConfigApi,
  IssuerEntityCapTablePartnerConfigIndexErrorCodes,
  IssuerEntityCapTablePartnerConfigIndexErrorTypes,
  IssuerEntityCapTablePartnerConfigUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuerEntityCapTablePartnerConfigApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type IssuerEntityCapTablePartnerConfigIndexMethod = IssuerEntityCapTablePartnerConfigApi['issuerEntityCapTablePartnerConfigIndex'];
export type IssuerEntityCapTablePartnerConfigIndexResponseType = MethodResult<IssuerEntityCapTablePartnerConfigIndexMethod>;

export const useIssuerEntityCapTablePartnerConfigIndex = (
  variables: Parameters<IssuerEntityCapTablePartnerConfigIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityCapTablePartnerConfigIndexMethod,
    IssuerEntityCapTablePartnerConfigIndexErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityCapTablePartnerConfigIndexMethod,
    IssuerEntityCapTablePartnerConfigIndexErrorTypes
  >(
    (...args) => api.issuerEntityCapTablePartnerConfigIndex(...args),
    IssuerEntityCapTablePartnerConfigIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityCapTablePartnerConfigIndex'],
        ...apiQueryConfig.IssuerEntityCapTablePartnerConfigIndex,
        ...config?.queryConfig
      },
    },
    'IssuerEntityCapTablePartnerConfigIndex',
  );
};

export const useIssuerEntityCapTablePartnerConfigIndexInfinite = (
  variables: Parameters<IssuerEntityCapTablePartnerConfigIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityCapTablePartnerConfigIndexMethod,
    IssuerEntityCapTablePartnerConfigIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityCapTablePartnerConfigIndexMethod,
    IssuerEntityCapTablePartnerConfigIndexErrorTypes
  >(
    (...args) => api.issuerEntityCapTablePartnerConfigIndex(...args),
    IssuerEntityCapTablePartnerConfigIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityCapTablePartnerConfigIndex', 'IssuerEntityCapTablePartnerConfigIndexInfinite'],
        ...apiQueryConfig.IssuerEntityCapTablePartnerConfigIndex,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityCapTablePartnerConfigIndex',
  );
};

export const useIssuerEntityCapTablePartnerConfigIndexLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityCapTablePartnerConfigIndexMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityCapTablePartnerConfigIndexMethod,
    IssuerEntityCapTablePartnerConfigIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityCapTablePartnerConfigIndexMethod,
    IssuerEntityCapTablePartnerConfigIndexErrorTypes
  >(
    (...args) => api.issuerEntityCapTablePartnerConfigIndex(...args),
    IssuerEntityCapTablePartnerConfigIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityCapTablePartnerConfigIndex'],
        ...apiQueryConfig.IssuerEntityCapTablePartnerConfigIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityCapTablePartnerConfigIndex',
  );
};


export type IssuerEntityCapTablePartnerConfigUpdateMethod = IssuerEntityCapTablePartnerConfigApi['issuerEntityCapTablePartnerConfigUpdate'];

export const useIssuerEntityCapTablePartnerConfigUpdate = () => {
  return useMutation<IssuerEntityCapTablePartnerConfigUpdateMethod>(
    (...args) => api.issuerEntityCapTablePartnerConfigUpdate(...args),
    IssuerEntityCapTablePartnerConfigUpdateErrorCodes,
    'IssuerEntityCapTablePartnerConfigUpdate',
    '/api/issuer-entities/{issuer_entity_id}/cap_table_partner_configs/{partner_name}',
    'patch'
  );
};
