import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import {
  FormItem,
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import {
  getFilenameExtension,
  getFilenameWithoutExtension,
} from '@npm/core/ui/utils/file';
import { type UploadFile } from 'antd/lib/upload/interface';

import * as S from './FileItem.styles';

export type UploadFormFields = ('downloadable' | 'watermark')[];

type Props = {
  file: UploadFile;
  onRemove?: () => void;
  progress?: number;
  disabled?: boolean;
  formFields?: UploadFormFields;
};

const IMAGE_FILE_PREFIX = 'image/';

export const FileItem = ({
  file,
  onRemove,
  progress,
  disabled,
  formFields = ['downloadable', 'watermark'],
}: Props) => {
  const { uid, name } = file;
  const isImageFile = file?.type?.startsWith(IMAGE_FILE_PREFIX);

  return (
    <S.Wrapper>
      <S.FileItem>
        {progress >= 0 ? (
          <S.Progress percent={progress * 100} />
        ) : (
          <InputFormItem
            label="File Name"
            initialValue={getFilenameWithoutExtension(name)}
            name={[uid, 'display_name']}
            rules={[VALIDATION_RULES.required('File name')]}
          >
            <Input
              placeholder="File Name"
              disabled={disabled}
              suffix={getFilenameExtension(name)}
            />
          </InputFormItem>
        )}
        <S.Rest style={formFields.length ? {} : { flexGrow: 0 }}>
          {!!formFields.length && (
            <S.SwitchesContainer>
              {formFields.includes('downloadable') && (
                <FormItem
                  valuePropName={'checked'}
                  name={[uid, 'downloadable']}
                  initialValue={false}
                >
                  <Switch
                    id="upload-form-download-switch"
                    title={'Download'}
                    disabled={disabled}
                  />
                </FormItem>
              )}
              {isImageFile && formFields.includes('watermark') && (
                <FormItem
                  valuePropName={'checked'}
                  name={[uid, 'watermark']}
                  initialValue={false}
                >
                  <Switch
                    id="upload-form-watermark-switch"
                    title={'Watermark'}
                    disabled={disabled}
                  />
                </FormItem>
              )}
            </S.SwitchesContainer>
          )}
          <Button
            onClick={onRemove}
            icon={<Icon name="trash" />}
            color="error"
            variant="outline"
            disabled={disabled}
          />
        </S.Rest>
      </S.FileItem>
    </S.Wrapper>
  );
};
