// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompanyPricingOverviewApi,
  CompanyPricingOverviewErrorCodes,
  CompanyPricingOverviewErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompanyPricingOverviewApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanyPricingOverviewMethod = CompanyPricingOverviewApi['companyPricingOverview'];
export type CompanyPricingOverviewResponseType = MethodResult<CompanyPricingOverviewMethod>;

export const useCompanyPricingOverview = (
  variables: Parameters<CompanyPricingOverviewMethod>[0],
  config?: UseQueryConfig<
    CompanyPricingOverviewMethod,
    CompanyPricingOverviewErrorTypes
  >
) => {
  return useQuery<
    CompanyPricingOverviewMethod,
    CompanyPricingOverviewErrorTypes
  >(
    (...args) => api.companyPricingOverview(...args),
    CompanyPricingOverviewErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyPricingOverview'],
        ...apiQueryConfig.CompanyPricingOverview,
        ...config?.queryConfig
      },
    },
    'CompanyPricingOverview',
  );
};

export const useCompanyPricingOverviewInfinite = (
  variables: Parameters<CompanyPricingOverviewMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyPricingOverviewMethod,
    CompanyPricingOverviewErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyPricingOverviewMethod,
    CompanyPricingOverviewErrorTypes
  >(
    (...args) => api.companyPricingOverview(...args),
    CompanyPricingOverviewErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyPricingOverview', 'CompanyPricingOverviewInfinite'],
        ...apiQueryConfig.CompanyPricingOverview,
        ...config?.queryConfig,
      },
    },
    'CompanyPricingOverview',
  );
};

export const useCompanyPricingOverviewLazy = (baseOptions?: {
  variables?: Parameters<CompanyPricingOverviewMethod>[0],
  config?: UseQueryConfig<
    CompanyPricingOverviewMethod,
    CompanyPricingOverviewErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyPricingOverviewMethod,
    CompanyPricingOverviewErrorTypes
  >(
    (...args) => api.companyPricingOverview(...args),
    CompanyPricingOverviewErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyPricingOverview'],
        ...apiQueryConfig.CompanyPricingOverview,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyPricingOverview',
  );
};

