import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Loader } from '@npm/core/ui/components/atoms/Loader';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import { AggregatedHoldingLabel } from '../../../../../../holdings/aggregated/List/components';
import { getHoldingIdInfo } from '../../../../../../holdings/Holdings.utils';
import { PriceBlock } from '../../../../../components/PriceBlock';
import {
  type HoldingDistribution,
  type HoldingDistributionVariant,
} from '../SellIndicationDrawer.types';

import * as S from './HoldingDetail.styles';

type Props = {
  holdingDistribution: HoldingDistribution;
  pps: number;
  onEditHolding?: () => void;
};

export const HoldingDetail = ({
  holdingDistribution,
  pps,
  onEditHolding,
}: Props) => {
  if (!holdingDistribution?.holding) return null;

  const { holding, variant, allocation } = holdingDistribution;

  const { idLabel, isMissingId, isMissingProofOfOwnership } =
    getHoldingIdInfo(holding);

  const isFilled = !isMissingId && !isMissingProofOfOwnership;

  const footerColorVariant =
    variant === 'success' || variant === 'posting'
      ? 'info'
      : variant === 'failed'
        ? 'error'
        : isFilled
          ? 'info'
          : 'warning';

  const headerIcon = getIcon(variant);

  return (
    <S.Card>
      <S.Header $isSuccess={variant === 'success'}>
        <Icon
          name={holding.aggregated ? 'layers' : 'file-text'}
          size={'xs'}
          color={'general'}
          colorVariant={'muted'}
        />
        <Text size={'sm'} colorVariant={'secondary'}>
          {holding.asset?.type?.name}
        </Text>
        <Text size={'sm'} colorVariant={'muted'}>
          •
        </Text>
        {holding.aggregated ? (
          <AggregatedHoldingLabel noBg />
        ) : (
          <Text size={'sm'} colorVariant={'primary'}>
            {idLabel}
          </Text>
        )}
        {headerIcon && <S.IconWrapper>{headerIcon}</S.IconWrapper>}
      </S.Header>

      <S.Body
        $priceBlockColorVariant={
          variant === 'posting'
            ? 'muted'
            : variant === 'failed'
              ? 'tertiary'
              : undefined
        }
      >
        <PriceBlock
          compact
          borderless
          quantity={allocation}
          price={pps}
          isBuy={false}
        />
      </S.Body>

      {variant === 'detail' ? (
        <S.Footer $variant={footerColorVariant}>
          <Flex align={'center'} gap={'xs'}>
            <Icon
              name={isFilled ? 'check' : 'clock'}
              size={'xxs'}
              color={footerColorVariant}
            />
            <Text size={'sm'} color={footerColorVariant}>
              {isFilled
                ? 'This order is ready to be posted to the marketplace'
                : isMissingId
                  ? idLabel.replace('Cert.', 'Certificate')
                  : 'Missing Proof of Ownership'}
            </Text>
          </Flex>
          {!isFilled && onEditHolding && (
            <Button
              size={'sm'}
              variant={'text'}
              icon={
                <Icon name={'plus'} size={'xs'} color={footerColorVariant} />
              }
              color={footerColorVariant}
              onClick={() => onEditHolding()}
            >
              Add holding details
            </Button>
          )}
        </S.Footer>
      ) : (
        <S.Footer $variant={footerColorVariant}>
          <Flex align={'center'} gap={'xs'}>
            {variant === 'posting' ? (
              <Loader size={'small'} height={'20px'} />
            ) : (
              <Icon
                name={variant === 'success' ? 'check' : 'x-close'}
                size={'xxs'}
                color={footerColorVariant}
              />
            )}
            <Text size={'sm'} color={footerColorVariant}>
              {variant === 'success'
                ? 'This order was posted to the marketplace'
                : variant === 'posting'
                  ? 'This order is being processed'
                  : 'This order was not posted to the marketplace'}
            </Text>
          </Flex>
        </S.Footer>
      )}
    </S.Card>
  );
};

const getIcon = (variant: HoldingDistributionVariant) => {
  switch (variant) {
    case 'posting':
      return <Loader size={'small'} />;
    case 'success':
      return <Icon name="check" size={'xs'} color={'info'} />;
    case 'failed':
      return <Icon name="x-close" size={'xs'} color={'error'} />;
    case 'detail':
    default:
      return null;
  }
};
