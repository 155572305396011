import {
  CbOrderItemState,
  CbSecondMarketOrderSort,
  type SubmissionApiSecondmarketSubmissionIndexRequest,
} from '@npm/data-access';

import {
  EXPIRING_ORDERS_STATE,
  getExpiringOrderDate,
} from '../../../client-orders/expiringOrders';

import { type InvestorOrdersTab } from './InvestorOrdersList.types';

const live = CbOrderItemState.items.live_in_marketplace;
const incomplete = CbOrderItemState.items.incomplete;

const allWithoutFilled = [
  CbOrderItemState.items.incomplete,
  CbOrderItemState.items.not_posted_to_marketplace,
  CbOrderItemState.items.live_in_marketplace,
  CbOrderItemState.items.matched,
  CbOrderItemState.items.pending_settlement,
  CbOrderItemState.items.cancelled,
  CbOrderItemState.items.expired,
];

export const getEnhancedVariables = (
  tab: InvestorOrdersTab,
  variables: SubmissionApiSecondmarketSubmissionIndexRequest
) => {
  switch (tab) {
    case 'all':
      return {
        ...variables,
        orderItemState: variables.orderItemState ?? [...allWithoutFilled],
      };
    case 'live':
      return {
        ...variables,
        orderItemState: [live],
      };
    case 'incomplete':
      return {
        ...variables,
        orderItemState: [incomplete],
      };
    case 'expiring':
      return {
        ...variables,
        orderItemState: EXPIRING_ORDERS_STATE,
        expiringBefore: getExpiringOrderDate(),
        findWithoutLiveNegotiation: true,
        sort: CbSecondMarketOrderSort.items.expiry_ascending,
      };
    default:
      return variables;
  }
};

export const isOrderNotEditable = (orderItemState: string) =>
  orderItemState === CbOrderItemState.items.expired ||
  orderItemState === CbOrderItemState.items.cancelled ||
  orderItemState === CbOrderItemState.items.matched ||
  orderItemState === CbOrderItemState.items.partially_filled ||
  orderItemState === CbOrderItemState.items.filled;
