import { forwardRef } from 'react';

import { type CheckboxProps as AntCheckboxProps } from 'antd/lib/checkbox';

import { StyledCheckbox } from './Checkbox.styles';

export interface CheckboxProps extends AntCheckboxProps {
  label: string | React.ReactNode;
  'data-cy'?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox({ children, ...props }, ref) {
    return (
      <StyledCheckbox {...props} ref={ref}>
        {children ?? props.label}
      </StyledCheckbox>
    );
  }
);
