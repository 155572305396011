import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { handleValidationError } from '@npm/core/ui/utils/form';
import {
  CbWorkstationType,
  useUserShowCurrent,
  useUserUpdate,
} from '@npm/data-access';
import { useForm } from 'antd/lib/form/Form';

import { useCurrentWorkstation } from '../../../../../auth/user/role';

import * as S from './NotificationSettingsModal.styles';

type Props = {
  visible: boolean;
  setVisible: (val: boolean) => void;
};

export type Values = {
  emailNotifications: boolean;
  webAppNotifications: boolean;
  watchlistEmailNotifications: boolean;
};

export const NotificationSettingsModal = ({ visible, setVisible }: Props) => {
  const { data, isLoading } = useUserShowCurrent({});
  const { isLoading: isSaving, execute } = useUserUpdate();
  const currentWorkstation = useCurrentWorkstation();

  const isBrokerWorkstation =
    currentWorkstation?.type?.code === CbWorkstationType.items.brokerage;

  const { withShowApiErrorMiddleware } = useAlerts();
  const [form] = useForm<Values>();

  const handleSubmit = async () => {
    let values: Values;

    try {
      values = await form.validateFields();
    } catch (err) {
      handleValidationError(form, err);
      return;
    }

    try {
      await withShowApiErrorMiddleware(execute)({
        id: data?.id,
        userUpdateRequestContract: {
          notifications_enable_email: values.emailNotifications,
          notifications_enable_web_app: values.webAppNotifications,
          notifications_enable_watchlist_email:
            values.watchlistEmailNotifications,
        },
      });
    } catch (err) {
      console.error(err);
      return;
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const items = [
    {
      label: 'In App Notifications',
      checked: data?.settings?.notifications?.web_app,
      key: 'webAppNotifications',
    },
    {
      label: 'Watchlist Email Notifications',
      checked: data?.settings?.notifications?.watchlist_email,
      key: 'watchlistEmailNotifications',
    },
  ];

  return (
    <Modal
      title="Notification Settings"
      open={visible}
      onCancel={handleCancel}
      destroyOnClose={true}
      size="md"
      footer={
        <Flex justify="flex-end">
          <Button
            variant="text"
            onClick={handleCancel}
            disabled={isLoading || isSaving}
            blockOnMobile
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} loading={isLoading} blockOnMobile>
            Save Settings
          </Button>
        </Flex>
      }
    >
      <Flex gap="sm" direction="column">
        {!isBrokerWorkstation && (
          <Text colorVariant="tertiary" size="xs">
            Here, you can set up notifications for all categories that you
            receive in the app or via email. You can set notifications for
            respective categories throughout different sections of the app (e.g.
            Watchlist).
          </Text>
        )}

        <Form
          form={form}
          initialValues={items.reduce((acc, item) => {
            return {
              ...acc,
              [item.key]: item.checked,
            };
          }, {})}
        >
          {items.map(item => {
            if (item.label === 'Email Notifications' && isBrokerWorkstation) {
              return null;
            }
            return (
              <S.ItemWrapper
                justify="space-between"
                key={item.key}
                align="center"
              >
                <Text size="sm" colorVariant="secondary">
                  {item.label}
                </Text>
                <FormItem name={item.key} valuePropName="checked">
                  <Switch id={item.key} />
                </FormItem>
              </S.ItemWrapper>
            );
          })}
        </Form>
      </Flex>
    </Modal>
  );
};
