import { useMemo } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type CompanyPricingApi } from '@npm/data-access';
import { Grid } from 'antd';
import { useTheme } from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { BidOfferHistoryChart } from './components/BidOfferHistoryChart/BidOfferHistoryChart';
import { getAverageAndBestLevelsColumns, Levels } from './components/Levels';

import * as S from './BidOfferHistory.styles';
import {
  getBidsColor,
  getLevelItems,
  getOffersColor,
} from './BidOfferHistory.utils';

type Props = {
  isCovered: boolean;
  data: CompanyPricingApi.HistoricalPriceIndex;
};

export const BidOfferHistory = withErrorBoundary(
  ({ isCovered, data }: Props) => {
    const { ref: containerRef, width: containerWidth } = useResizeObserver();
    const theme = useTheme();
    const breakpoint = Grid.useBreakpoint();

    const bidLevelItems = useMemo(() => {
      return getLevelItems(
        data.historical_prices.aggregated_bids,
        data.historical_prices.best_bids
      );
    }, [data]);

    const askLevelItems = useMemo(() => {
      return getLevelItems(
        data.historical_prices.aggregated_asks,
        data.historical_prices.best_asks
      );
    }, [data]);

    return (
      <Flex direction="column" gap="md">
        <BidOfferHistoryChart
          data={
            isCovered
              ? data.historical_prices
              : { ...data.historical_prices, primary_rounds: [] }
          }
        />
        {/* containerWidth and containerRef is here because table with scroll={{y:240}} breaks responsiveness */}
        <S.AverageAndBestLevelsWrapper key={containerWidth} ref={containerRef}>
          <Levels
            title="Bid Levels"
            tooltip={
              <>
                <Text size="sm" marginBottom="sm">
                  Best bid price observed in each month and volume-weighted
                  average bid price for the same period.
                </Text>
                <Text size="sm">
                  Indications are received through NPM&apos;s platform and its
                  data contribution network.
                </Text>
              </>
            }
            items={bidLevelItems}
            noDataTitle={'No Bids'}
            icon={<Icon name="segment" size="xs" color={getBidsColor(theme)} />}
            columns={getAverageAndBestLevelsColumns(breakpoint)}
          />
          <Levels
            title="Offer Levels"
            tooltip={
              <>
                <Text size="sm" marginBottom="sm">
                  Best offer price observed in each month and volume-weighted
                  average offer price for the same period.
                </Text>
                <Text size="sm">
                  Indications are received through NPM&apos;s platform and its
                  data contribution network.
                </Text>
              </>
            }
            items={askLevelItems}
            noDataTitle={'No Offers'}
            icon={
              <Icon name="segment" size="xs" color={getOffersColor(theme)} />
            }
            columns={getAverageAndBestLevelsColumns(breakpoint)}
          />
        </S.AverageAndBestLevelsWrapper>
      </Flex>
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);
