import React, { type ReactNode } from 'react';

import { type LabelVariant } from '@npm/utils';
import { type TagProps } from 'antd';

import { IconWrapper, StyledLabel } from './Label.styles';

export type LabelProps = {
  // TODO: using transient props like this is nope, these should be normal props
  // now they are not forwarded when overriding Label, e.g. like this ... = styled(Label)
  variant?: LabelVariant;
  round?: boolean;
  bordered?: boolean;
  dashed?: boolean;
  noBg?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
} & TagProps;

export const Label = ({
  variant = 'general',
  icon,
  children,
  onClick,
  round = false,
  bordered = round,
  dashed = false,
  noBg = false,
  ...props
}: LabelProps) => (
  <StyledLabel
    {...props}
    $variant={variant}
    $round={round}
    $bordered={bordered}
    $dashed={dashed}
    $noBg={noBg}
    {...(onClick ? { role: 'button' } : {})}
    onClick={onClick}
  >
    {icon && <IconWrapper>{icon}</IconWrapper>}
    {children}
  </StyledLabel>
);
