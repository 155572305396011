import React, { useMemo } from 'react';

import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { type CodebookItem } from '@npm/data-access';
import { CbTaxDocument, Codebooks, useCodebook } from '@npm/data-access';
import { type FormInstance, type FormProps } from 'antd';

import * as S from './TaxInformationForm.styles';

const DISABLED_W9_DOCUMENT_TOOLTIP_TEXT =
  'You must provide a U.S. Tax ID within the Personal Information section before you can select a W-9 Tax Form.';

type Props = {
  type: string;
  form: FormInstance<{ code: string }>;
  formItemsFilter?: (code: CodebookItem) => boolean;
  isW9Disabled?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const TaxInformationForm = ({
  type,
  form,
  formItemsFilter,
  isW9Disabled,
  handleValuesChange,
}: Props) => {
  const { data, isLoading } = useCodebook({
    type: Codebooks.TAX_DOCUMENT,
  });

  const filteredItems = useMemo(() => {
    let filteredData =
      data?.codebooks.filter(
        ({ attributes }) =>
          attributes.find(({ name }) => name === type)?.value === 'true'
      ) || [];

    if (formItemsFilter) {
      filteredData = filteredData.filter(formItemsFilter);
    }

    return filteredData;
  }, [data, formItemsFilter, type]);

  if (isLoading) return <Skeleton.Base />;

  return (
    <S.FormWrapper>
      <Form form={form} onValuesChange={handleValuesChange}>
        <FormItem name="code" rules={[VALIDATION_RULES.required()]}>
          <S.RadioGroup>
            {filteredItems.map(({ name, code, attributes }) => {
              const disableW9 =
                code === CbTaxDocument.items.DOCUMENT_W9 && isW9Disabled;

              return (
                <ButtonCard
                  key={code}
                  label={name}
                  value={code}
                  variant="radio"
                  description={
                    attributes.find(({ name }) => name === 'help').value
                  }
                  disabled={disableW9}
                  {...(disableW9 && {
                    tooltip: { text: DISABLED_W9_DOCUMENT_TOOLTIP_TEXT },
                  })}
                />
              );
            })}
          </S.RadioGroup>
        </FormItem>
      </Form>
    </S.FormWrapper>
  );
};
