import styled, {
  createGlobalStyle,
  css,
  type DefaultTheme,
} from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { Tabs } from '../Tabs';
import { SmallTextStyles, Text } from '../Typography';

const MIN_WIDTH = 200;
const MIN_WIDTH_MOBILE = 168;
const ICON_WIDTH = 16;

const zIndex = (key: keyof DefaultTheme['zIndex']) => css`
  .ant-dropdown.npm-dropdown-${key} {
    z-index: ${({ theme }) => theme.zIndex[key]};
  }
`;

export const NpmDropdown = createGlobalStyle`
  ${zIndex('base')}
  ${zIndex('dropdown')}
  ${zIndex('fixed')}
  ${zIndex('drawer')}
  ${zIndex('aboveDrawer')}
  ${zIndex('aboveAll')}

  .ant-dropdown.npm-dropdown {
    .ant-dropdown-menu {
      max-height: 60vh;
      overflow: auto;
      border-radius: ${({ theme }) => theme.borderRadius.md}px;
      background-color: ${({ theme }) => theme.color.general.layout.one};
      box-shadow: ${({ theme }) => theme.shadow.level2};
      padding: 0;
      min-width: ${MIN_WIDTH}px;
      ${({ theme }) => useBreakpoint(theme).mobile`
        min-width: ${MIN_WIDTH_MOBILE}px;
      `}
      .ant-dropdown-menu-item, .ant-dropdown-menu-item-selected {
        padding: ${({ theme }) => theme.spacing.sm}px ${({ theme }) =>
          theme.spacing.md}px;
        &-disabled {
          * {
            color: ${({ theme }) =>
              theme.color.general.typography.tertiary} !important;
          }
        }
        &:hover:not(.ant-dropdown-menu-item-disabled) {
          background-color: ${({ theme }) =>
            theme.color.info.backgrounds.secondaryHover};
        }
        &:hover:is(.ant-dropdown-menu-item-disabled) {
          background-color: transparent;
        }
        .ant-dropdown-menu-title-content {
          ${SmallTextStyles};
        }
        > svg {
          display: flex;
          width: ${ICON_WIDTH}px;
          margin-right: ${({ theme }) => theme.spacing.sm}px;
          color: ${({ theme }) => theme.color.info.typography.primary};
        }
      }
      .ant-dropdown-menu-item-danger {
        svg {
          color: ${({ theme }) => theme.color.error.typography.primary};
        }
      }
    }
    .ant-dropdown-menu-item-selected {
      background-color: ${({ theme }) =>
        theme.color.info.backgrounds.secondaryHover};
    }
  }
`;

export const MenuItemContainer = styled.div<{ $noMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    ${({ $noMargin, theme }) =>
      `margin-left: ${$noMargin ? 0 : theme.spacing.sm}px;`}
    color: ${({ theme }) => theme.color.info.typography.primary};
  }
`;
export const OverlayHeadingWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing.sm}px ${theme.spacing.md}px`};
`;
export const DescriptionText = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xs}px;
`;
export const DropdownBadge = styled(Tabs.Badge)`
  display: inline-block;
  min-height: 20px;
`;

export const SkeletonContainer = styled.div`
  // there is weird bug therefore 1px
  padding: 1px ${({ theme }) => theme.spacing.sm}px;
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 0;
  }
`;
