// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CodebookApi,
  CodebookErrorCodes,
  CodebookErrorTypes,
  CodebookPostErrorCodes,
  CodebookPostErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CodebookApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CodebookMethod = CodebookApi['codebook'];
export type CodebookResponseType = MethodResult<CodebookMethod>;

export const useCodebook = (
  variables: Parameters<CodebookMethod>[0],
  config?: UseQueryConfig<
    CodebookMethod,
    CodebookErrorTypes
  >
) => {
  return useQuery<
    CodebookMethod,
    CodebookErrorTypes
  >(
    (...args) => api.codebook(...args),
    CodebookErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['Codebook'],
        ...apiQueryConfig.Codebook,
        ...config?.queryConfig
      },
    },
    'Codebook',
  );
};

export const useCodebookInfinite = (
  variables: Parameters<CodebookMethod>[0],
  config?: UseInfiniteQueryConfig<
    CodebookMethod,
    CodebookErrorTypes
  >
) => {
  return useInfiniteQuery<
    CodebookMethod,
    CodebookErrorTypes
  >(
    (...args) => api.codebook(...args),
    CodebookErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['Codebook', 'CodebookInfinite'],
        ...apiQueryConfig.Codebook,
        ...config?.queryConfig,
      },
    },
    'Codebook',
  );
};

export const useCodebookLazy = (baseOptions?: {
  variables?: Parameters<CodebookMethod>[0],
  config?: UseQueryConfig<
    CodebookMethod,
    CodebookErrorTypes
  >
}) => {
  return useLazyQuery<
    CodebookMethod,
    CodebookErrorTypes
  >(
    (...args) => api.codebook(...args),
    CodebookErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['Codebook'],
        ...apiQueryConfig.Codebook,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'Codebook',
  );
};


export type CodebookPostMethod = CodebookApi['codebookPost'];
export type CodebookPostResponseType = MethodResult<CodebookPostMethod>;

export const useCodebookPost = (
  variables: Parameters<CodebookPostMethod>[0],
  config?: UseQueryConfig<
    CodebookPostMethod,
    CodebookPostErrorTypes
  >
) => {
  return useQuery<
    CodebookPostMethod,
    CodebookPostErrorTypes
  >(
    (...args) => api.codebookPost(...args),
    CodebookPostErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CodebookPost'],
        ...apiQueryConfig.CodebookPost,
        ...config?.queryConfig
      },
    },
    'CodebookPost',
  );
};

export const useCodebookPostInfinite = (
  variables: Parameters<CodebookPostMethod>[0],
  config?: UseInfiniteQueryConfig<
    CodebookPostMethod,
    CodebookPostErrorTypes
  >
) => {
  return useInfiniteQuery<
    CodebookPostMethod,
    CodebookPostErrorTypes
  >(
    (...args) => api.codebookPost(...args),
    CodebookPostErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CodebookPost', 'CodebookPostInfinite'],
        ...apiQueryConfig.CodebookPost,
        ...config?.queryConfig,
      },
    },
    'CodebookPost',
  );
};

export const useCodebookPostLazy = (baseOptions?: {
  variables?: Parameters<CodebookPostMethod>[0],
  config?: UseQueryConfig<
    CodebookPostMethod,
    CodebookPostErrorTypes
  >
}) => {
  return useLazyQuery<
    CodebookPostMethod,
    CodebookPostErrorTypes
  >(
    (...args) => api.codebookPost(...args),
    CodebookPostErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CodebookPost'],
        ...apiQueryConfig.CodebookPost,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CodebookPost',
  );
};

