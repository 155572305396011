// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MarketingDataApi,
  MarketingDataCreateErrorCodes,
  MarketingDataShowErrorCodes,
  MarketingDataShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MarketingDataApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type MarketingDataCreateMethod = MarketingDataApi['marketingDataCreate'];

export const useMarketingDataCreate = () => {
  return useMutation<MarketingDataCreateMethod>(
    (...args) => api.marketingDataCreate(...args),
    MarketingDataCreateErrorCodes,
    'MarketingDataCreate',
    '/api/public/marketing-data',
    'post'
  );
};

export type MarketingDataShowMethod = MarketingDataApi['marketingDataShow'];
export type MarketingDataShowResponseType = MethodResult<MarketingDataShowMethod>;

export const useMarketingDataShow = (
  variables: Parameters<MarketingDataShowMethod>[0],
  config?: UseQueryConfig<
    MarketingDataShowMethod,
    MarketingDataShowErrorTypes
  >
) => {
  return useQuery<
    MarketingDataShowMethod,
    MarketingDataShowErrorTypes
  >(
    (...args) => api.marketingDataShow(...args),
    MarketingDataShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarketingDataShow'],
        ...apiQueryConfig.MarketingDataShow,
        ...config?.queryConfig
      },
    },
    'MarketingDataShow',
  );
};

export const useMarketingDataShowInfinite = (
  variables: Parameters<MarketingDataShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarketingDataShowMethod,
    MarketingDataShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarketingDataShowMethod,
    MarketingDataShowErrorTypes
  >(
    (...args) => api.marketingDataShow(...args),
    MarketingDataShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarketingDataShow', 'MarketingDataShowInfinite'],
        ...apiQueryConfig.MarketingDataShow,
        ...config?.queryConfig,
      },
    },
    'MarketingDataShow',
  );
};

export const useMarketingDataShowLazy = (baseOptions?: {
  variables?: Parameters<MarketingDataShowMethod>[0],
  config?: UseQueryConfig<
    MarketingDataShowMethod,
    MarketingDataShowErrorTypes
  >
}) => {
  return useLazyQuery<
    MarketingDataShowMethod,
    MarketingDataShowErrorTypes
  >(
    (...args) => api.marketingDataShow(...args),
    MarketingDataShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarketingDataShow'],
        ...apiQueryConfig.MarketingDataShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarketingDataShow',
  );
};

