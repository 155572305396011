import { type IconProps } from '@npm/utils';
import { type ColorGroup } from 'styled-components';

import { Icon } from '../Icon';

import * as S from './SquareIcon.styles';

type Props = {
  iconName: IconProps['name'];
  size?: IconProps['size'];
  color?: ColorGroup;
};

export const SquareIcon = ({ iconName, size = 16, color = 'info' }: Props) => {
  return (
    <S.SquareIconWrapper $color={color}>
      <Icon name={iconName} size={size} color={color} />
    </S.SquareIconWrapper>
  );
};
