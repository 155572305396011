import React from 'react';

import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { TextWithTooltip } from '@npm/core/ui/components/molecules/TextWithTooltip';
import { HeaderUncollapsable } from '@npm/core/ui/components/organisms/WizardNew/Layout/Header';
import { type issuerWorkstationRouteKeys } from '@npm/core/ui/routes';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { Space } from 'antd';

export const MatchReportPageTitle = (props: RouteTitleProps) => {
  return (
    <>
      <Helmet pageTitle="Report a Trade"></Helmet>
      <HeaderUncollapsable
        title={
          <TextWithTooltip tooltipText="Leverage this self-service tool to input trade details and initiate the secondary workflow.">
            <Text>Report a Trade</Text>
          </TextWithTooltip>
        }
        exitRoute={
          '/issuer-workstation/marketplace/matches' satisfies issuerWorkstationRouteKeys
        }
      />
    </>
  );
};
