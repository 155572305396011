/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ServiceToServiceUserlessHoldingsHoldingIndex } from '../model';
/**
 * S2SUserlessHoldingsApi - axios parameter creator
 * @export
 */
export const S2SUserlessHoldingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all holdings without user permission filtering
         * @param {string} xNPMAuthorization 
         * @param {number} page 
         * @param {number} size 
         * @param {string} [xWorkstation] 
         * @param {string} [createdTimeGte] An ISO 8601 formatted date string. Only returns records created on or after this time.
         * @param {string} [updatedTimeGte] An ISO 8601 formatted date string. Only returns records updated on or after this time.
         * @param {number} [idGte] Only returns records with an ID greater than or equal to this value.
         * @param {string} [capTableType] Valid values include NPMS_PORTFOLIO, PRIVATE, PUBLIC, VERIFIED_TRANSFERS
         * @param {Array<string>} [holdingNpmGuids] An array of holding npm guids. Max array length is 50.
         * @param {string} [notLastModifiedBy] A string that depicts the party that did not last modify the record. For example, this parameter would be \&quot;john.smith@npm.com\&quot; if you do not want securities that were LAST MODIFIED by that person.
         * @param {boolean} [isActive] True if you only want active holdings, false if you only want inactive holdings. Do not set this parameter if you want both.
         * @param {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'} [sortBy] Defaults to created_at
         * @param {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'} [sortDirection] Defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUserlessListHoldings: async (xNPMAuthorization: string, page: number, size: number, xWorkstation?: string, createdTimeGte?: string, updatedTimeGte?: string, idGte?: number, capTableType?: string, holdingNpmGuids?: Array<string>, notLastModifiedBy?: string, isActive?: boolean, sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT', sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xNPMAuthorization' is not null or undefined
            assertParamExists('s2SUserlessListHoldings', 'xNPMAuthorization', xNPMAuthorization)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('s2SUserlessListHoldings', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('s2SUserlessListHoldings', 'size', size)
            const localVarPath = changeUrl(`/api/s2s/userless/holdings`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (createdTimeGte !== undefined) {
                localVarQueryParameter['created_time_gte'] = createdTimeGte;
            }

            if (updatedTimeGte !== undefined) {
                localVarQueryParameter['updated_time_gte'] = updatedTimeGte;
            }

            if (idGte !== undefined) {
                localVarQueryParameter['id_gte'] = idGte;
            }

            if (capTableType !== undefined) {
                localVarQueryParameter['cap_table_type'] = capTableType;
            }

            if (holdingNpmGuids) {
                localVarQueryParameter['holding_npm_guids'] = holdingNpmGuids.join(COLLECTION_FORMATS.csv);
            }

            if (notLastModifiedBy !== undefined) {
                localVarQueryParameter['not_last_modified_by'] = notLastModifiedBy;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sort_direction'] = sortDirection;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S2SUserlessHoldingsApi - functional programming interface
 * @export
 */
export const S2SUserlessHoldingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S2SUserlessHoldingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all holdings without user permission filtering
         * @param {string} xNPMAuthorization 
         * @param {number} page 
         * @param {number} size 
         * @param {string} [xWorkstation] 
         * @param {string} [createdTimeGte] An ISO 8601 formatted date string. Only returns records created on or after this time.
         * @param {string} [updatedTimeGte] An ISO 8601 formatted date string. Only returns records updated on or after this time.
         * @param {number} [idGte] Only returns records with an ID greater than or equal to this value.
         * @param {string} [capTableType] Valid values include NPMS_PORTFOLIO, PRIVATE, PUBLIC, VERIFIED_TRANSFERS
         * @param {Array<string>} [holdingNpmGuids] An array of holding npm guids. Max array length is 50.
         * @param {string} [notLastModifiedBy] A string that depicts the party that did not last modify the record. For example, this parameter would be \&quot;john.smith@npm.com\&quot; if you do not want securities that were LAST MODIFIED by that person.
         * @param {boolean} [isActive] True if you only want active holdings, false if you only want inactive holdings. Do not set this parameter if you want both.
         * @param {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'} [sortBy] Defaults to created_at
         * @param {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'} [sortDirection] Defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SUserlessListHoldings(xNPMAuthorization: string, page: number, size: number, xWorkstation?: string, createdTimeGte?: string, updatedTimeGte?: string, idGte?: number, capTableType?: string, holdingNpmGuids?: Array<string>, notLastModifiedBy?: string, isActive?: boolean, sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT', sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceToServiceUserlessHoldingsHoldingIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SUserlessListHoldings(xNPMAuthorization, page, size, xWorkstation, createdTimeGte, updatedTimeGte, idGte, capTableType, holdingNpmGuids, notLastModifiedBy, isActive, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S2SUserlessHoldingsApi - factory interface
 * @export
 */
export const S2SUserlessHoldingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S2SUserlessHoldingsApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all holdings without user permission filtering
         * @param {string} xNPMAuthorization 
         * @param {number} page 
         * @param {number} size 
         * @param {string} [xWorkstation] 
         * @param {string} [createdTimeGte] An ISO 8601 formatted date string. Only returns records created on or after this time.
         * @param {string} [updatedTimeGte] An ISO 8601 formatted date string. Only returns records updated on or after this time.
         * @param {number} [idGte] Only returns records with an ID greater than or equal to this value.
         * @param {string} [capTableType] Valid values include NPMS_PORTFOLIO, PRIVATE, PUBLIC, VERIFIED_TRANSFERS
         * @param {Array<string>} [holdingNpmGuids] An array of holding npm guids. Max array length is 50.
         * @param {string} [notLastModifiedBy] A string that depicts the party that did not last modify the record. For example, this parameter would be \&quot;john.smith@npm.com\&quot; if you do not want securities that were LAST MODIFIED by that person.
         * @param {boolean} [isActive] True if you only want active holdings, false if you only want inactive holdings. Do not set this parameter if you want both.
         * @param {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'} [sortBy] Defaults to created_at
         * @param {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'} [sortDirection] Defaults to desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUserlessListHoldings(xNPMAuthorization: string, page: number, size: number, xWorkstation?: string, createdTimeGte?: string, updatedTimeGte?: string, idGte?: number, capTableType?: string, holdingNpmGuids?: Array<string>, notLastModifiedBy?: string, isActive?: boolean, sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT', sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC', options?: any): AxiosPromise<ServiceToServiceUserlessHoldingsHoldingIndex> {
            return localVarFp.s2SUserlessListHoldings(xNPMAuthorization, page, size, xWorkstation, createdTimeGte, updatedTimeGte, idGte, capTableType, holdingNpmGuids, notLastModifiedBy, isActive, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for s2SUserlessListHoldings operation in S2SUserlessHoldingsApi.
 * @export
 * @interface S2SUserlessHoldingsApiS2SUserlessListHoldingsRequest
 */
export interface S2SUserlessHoldingsApiS2SUserlessListHoldingsRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly xNPMAuthorization: string

    /**
     * 
     * @type {number}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly size: number

    /**
     * 
     * @type {string}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly xWorkstation?: string

    /**
     * An ISO 8601 formatted date string. Only returns records created on or after this time.
     * @type {string}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly createdTimeGte?: string

    /**
     * An ISO 8601 formatted date string. Only returns records updated on or after this time.
     * @type {string}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly updatedTimeGte?: string

    /**
     * Only returns records with an ID greater than or equal to this value.
     * @type {number}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly idGte?: number

    /**
     * Valid values include NPMS_PORTFOLIO, PRIVATE, PUBLIC, VERIFIED_TRANSFERS
     * @type {string}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly capTableType?: string

    /**
     * An array of holding npm guids. Max array length is 50.
     * @type {Array<string>}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly holdingNpmGuids?: Array<string>

    /**
     * A string that depicts the party that did not last modify the record. For example, this parameter would be \&quot;john.smith@npm.com\&quot; if you do not want securities that were LAST MODIFIED by that person.
     * @type {string}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly notLastModifiedBy?: string

    /**
     * True if you only want active holdings, false if you only want inactive holdings. Do not set this parameter if you want both.
     * @type {boolean}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly isActive?: boolean

    /**
     * Defaults to created_at
     * @type {'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly sortBy?: 'DEFAULT_SORT_FIELDS.CREATED_AT' | 'DEFAULT_SORT_FIELDS.UPDATED_AT'

    /**
     * Defaults to desc
     * @type {'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'}
     * @memberof S2SUserlessHoldingsApiS2SUserlessListHoldings
     */
    readonly sortDirection?: 'SORT_DIRECTIONS.ASC' | 'SORT_DIRECTIONS.DESC'
}

/**
 * S2SUserlessHoldingsApi - object-oriented interface
 * @export
 * @class S2SUserlessHoldingsApi
 * @extends {BaseAPI}
 */
export class S2SUserlessHoldingsApi extends BaseAPI {
    /**
     * 
     * @summary lists all holdings without user permission filtering
     * @param {S2SUserlessHoldingsApiS2SUserlessListHoldingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SUserlessHoldingsApi
     */
    public s2SUserlessListHoldings(requestParameters: S2SUserlessHoldingsApiS2SUserlessListHoldingsRequest, options?: AxiosRequestConfig) {
        return S2SUserlessHoldingsApiFp(this.configuration).s2SUserlessListHoldings(requestParameters.xNPMAuthorization, requestParameters.page, requestParameters.size, requestParameters.xWorkstation, requestParameters.createdTimeGte, requestParameters.updatedTimeGte, requestParameters.idGte, requestParameters.capTableType, requestParameters.holdingNpmGuids, requestParameters.notLastModifiedBy, requestParameters.isActive, requestParameters.sortBy, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
    }
}

export type S2SUserlessListHoldingsErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SUserlessListHoldingsErrorCodes = [
    422,
];


