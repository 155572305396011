// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AtomicDocumentsApi,
  AtomicDocumentsIndexErrorCodes,
  AtomicDocumentsIndexErrorTypes,
  AtomicDocumentShowErrorCodes,
  AtomicDocumentShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AtomicDocumentsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AtomicDocumentsIndexMethod = AtomicDocumentsApi['atomicDocumentsIndex'];
export type AtomicDocumentsIndexResponseType = MethodResult<AtomicDocumentsIndexMethod>;

export const useAtomicDocumentsIndex = (
  variables: Parameters<AtomicDocumentsIndexMethod>[0],
  config?: UseQueryConfig<
    AtomicDocumentsIndexMethod,
    AtomicDocumentsIndexErrorTypes
  >
) => {
  return useQuery<
    AtomicDocumentsIndexMethod,
    AtomicDocumentsIndexErrorTypes
  >(
    (...args) => api.atomicDocumentsIndex(...args),
    AtomicDocumentsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AtomicDocumentsIndex'],
        ...apiQueryConfig.AtomicDocumentsIndex,
        ...config?.queryConfig
      },
    },
    'AtomicDocumentsIndex',
  );
};

export const useAtomicDocumentsIndexInfinite = (
  variables: Parameters<AtomicDocumentsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AtomicDocumentsIndexMethod,
    AtomicDocumentsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AtomicDocumentsIndexMethod,
    AtomicDocumentsIndexErrorTypes
  >(
    (...args) => api.atomicDocumentsIndex(...args),
    AtomicDocumentsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AtomicDocumentsIndex', 'AtomicDocumentsIndexInfinite'],
        ...apiQueryConfig.AtomicDocumentsIndex,
        ...config?.queryConfig,
      },
    },
    'AtomicDocumentsIndex',
  );
};

export const useAtomicDocumentsIndexLazy = (baseOptions?: {
  variables?: Parameters<AtomicDocumentsIndexMethod>[0],
  config?: UseQueryConfig<
    AtomicDocumentsIndexMethod,
    AtomicDocumentsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AtomicDocumentsIndexMethod,
    AtomicDocumentsIndexErrorTypes
  >(
    (...args) => api.atomicDocumentsIndex(...args),
    AtomicDocumentsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AtomicDocumentsIndex'],
        ...apiQueryConfig.AtomicDocumentsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AtomicDocumentsIndex',
  );
};


export type AtomicDocumentShowMethod = AtomicDocumentsApi['atomicDocumentShow'];
export type AtomicDocumentShowResponseType = MethodResult<AtomicDocumentShowMethod>;

export const useAtomicDocumentShow = (
  variables: Parameters<AtomicDocumentShowMethod>[0],
  config?: UseQueryConfig<
    AtomicDocumentShowMethod,
    AtomicDocumentShowErrorTypes
  >
) => {
  return useQuery<
    AtomicDocumentShowMethod,
    AtomicDocumentShowErrorTypes
  >(
    (...args) => api.atomicDocumentShow(...args),
    AtomicDocumentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AtomicDocumentShow'],
        ...apiQueryConfig.AtomicDocumentShow,
        ...config?.queryConfig
      },
    },
    'AtomicDocumentShow',
  );
};

export const useAtomicDocumentShowInfinite = (
  variables: Parameters<AtomicDocumentShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AtomicDocumentShowMethod,
    AtomicDocumentShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AtomicDocumentShowMethod,
    AtomicDocumentShowErrorTypes
  >(
    (...args) => api.atomicDocumentShow(...args),
    AtomicDocumentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AtomicDocumentShow', 'AtomicDocumentShowInfinite'],
        ...apiQueryConfig.AtomicDocumentShow,
        ...config?.queryConfig,
      },
    },
    'AtomicDocumentShow',
  );
};

export const useAtomicDocumentShowLazy = (baseOptions?: {
  variables?: Parameters<AtomicDocumentShowMethod>[0],
  config?: UseQueryConfig<
    AtomicDocumentShowMethod,
    AtomicDocumentShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AtomicDocumentShowMethod,
    AtomicDocumentShowErrorTypes
  >(
    (...args) => api.atomicDocumentShow(...args),
    AtomicDocumentShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AtomicDocumentShow'],
        ...apiQueryConfig.AtomicDocumentShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AtomicDocumentShow',
  );
};

