/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



  /**
 * 
 * @export
 * @interface PartnerIssuerStatusShow
 */
export interface PartnerIssuerStatusShow {
    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerStatusShow
     */
    'legal_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerStatusShow
     */
    'doing_business_as'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerStatusShow
     */
    'onboarding_status'?: PartnerIssuerStatusShowOnboardingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerIssuerStatusShow
     */
    'issuer_workstation_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerIssuerStatusShow
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerIssuerStatusShow
     */
    'marketplace_import_enabled'?: boolean;
}

export const PartnerIssuerStatusShowOnboardingStatusEnum = {
    Pending: 'pending',
    Complete: 'complete'
} as const;

export type PartnerIssuerStatusShowOnboardingStatusEnum = typeof PartnerIssuerStatusShowOnboardingStatusEnum[keyof typeof PartnerIssuerStatusShowOnboardingStatusEnum];


