import { useEffect } from 'react';

import { useOktaAuth } from '@okta/okta-react';

// e2e tests helper that sets data attribute on body to indicate if user is logged in
export const useUserLoggedInDataAttribute = () => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    const USER_LOGGED_IN_DATA_ATTRIBUTE = 'data-user-logged-in';
    if (!authState?.isAuthenticated) {
      document.body.removeAttribute(USER_LOGGED_IN_DATA_ATTRIBUTE);
    } else {
      document.body.setAttribute(USER_LOGGED_IN_DATA_ATTRIBUTE, '');
    }
  }, [authState]);
};
