import { useMemo } from 'react';

import { useAccountShow } from '@npm/data-access';

import { useUserRole } from '../context/userRole.context';

export const useCurrentAccount = (loadAccount = false) => {
  const userRole = useUserRole();

  let accountId =
    userRole?.workstationType === 'brokerage' &&
    userRole?.subRole?.type === 'obo'
      ? userRole?.subRole?.account?.id
      : undefined;

  if (
    userRole?.workstationType === 'investor' &&
    userRole?.subRole?.type === 'account-centric'
  ) {
    accountId = userRole?.subRole?.subject?.id;
  }

  const { data: account, isLoading: accountLoading } = useAccountShow(
    { id: accountId?.toString() },
    {
      queryConfig: {
        enabled: !!accountId && loadAccount,
      },
    }
  );
  const isUsBasedAccount = account?.contact_information?.country?.code === 'US';

  return useMemo(() => {
    const isInvAccountCentric =
      userRole?.workstationType === 'investor' &&
      userRole?.subRole?.type === 'account-centric';

    const isObo =
      userRole?.workstationType === 'brokerage' &&
      userRole?.subRole?.type === 'obo';

    return {
      accountId,
      account,
      accountLoading,
      isUsBasedAccount,
      isOboOrAccountCentric: isInvAccountCentric || isObo,
      isInvAccountCentric: isInvAccountCentric,
      isInvAllAccountsMode:
        userRole?.workstationType === 'investor' &&
        userRole?.subRole?.type === 'all-accounts',
      isObo:
        userRole?.workstationType === 'brokerage' &&
        userRole?.subRole?.type === 'obo',
    };
  }, [accountId, userRole, account, accountLoading, isUsBasedAccount]);
};
