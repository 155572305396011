import { useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Skeleton } from '../Skeleton';

type Props = {
  render: () => React.ReactNode;
  skeleton?: React.ReactNode;
};

/**
 * Render will be called once the placeholder is in view
 */
export const InViewRenderer = ({
  render,
  skeleton = <Skeleton.Base />,
}: Props) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleChange = useCallback(
    (inView: boolean) => {
      if (inView && !isContentVisible) setIsContentVisible(true);
    },
    [isContentVisible]
  );

  const { ref: placeholderRef } = useInView({
    onChange: handleChange,
  });

  return isContentVisible ? (
    <>{render()}</>
  ) : (
    <div ref={placeholderRef}>{skeleton}</div>
  );
};
