import { useEffect, useMemo } from 'react';

import { SELECT_PAGE_SIZE } from '@npm/core/ui/components/atoms/Select';
import {
  CbOrderItemUnit,
  CbSecondMarketOrderSort,
  type Holding,
  type HoldingApiHoldingIndexRequest,
  type SecondmarketOrderItemShow,
  useHoldingIndexInfinite,
} from '@npm/data-access';

import { type OboDefinition } from '../../../auth/user/role/userRole.types';

export type HoldingSelectHookVariables = Pick<
  HoldingApiHoldingIndexRequest,
  | 'accountId'
  | 'issuerEntityId'
  | 'secondmarket'
  | 'includeSpvs'
  | 'includePortfolioHoldings'
  | 'assetType'
  | 'search'
  | 'aggregatedHolding'
>;

type Props = HoldingSelectHookVariables & {
  order?: SecondmarketOrderItemShow;
  filterOptions?: (value: Holding, index: number, array: Holding[]) => boolean;
  oboOverride?: OboDefinition;
};

export const useHoldingSelectData = ({
  accountId,
  issuerEntityId,
  secondmarket,
  includeSpvs,
  includePortfolioHoldings,
  assetType,
  order,
  search,
  filterOptions,
  oboOverride,
  aggregatedHolding = false,
}: Props) => {
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useHoldingIndexInfinite(
    {
      page: 1,
      size: SELECT_PAGE_SIZE,
      issuerEntityId,
      accountId,
      search,
      secondmarket,
      assetType,
      includeSpvs,
      includePortfolioHoldings,
      aggregatedHolding,
      ...(oboOverride && {
        xOboAccountId: oboOverride.account?.id?.toString(),
        xOboUserId: oboOverride.representative?.user_id?.toString(),
      }),
      sort: CbSecondMarketOrderSort.items.newest,
    },
    { queryConfig: { enabled: !!issuerEntityId && !!accountId } }
  );

  const availableHoldings = useMemo(() => {
    let holdings: Holding[] = [];

    if (!data) {
      return holdings;
    }

    holdings = data.pages.reduce((mergedArray, page) => {
      return mergedArray.concat(page.holdings);
    }, []);

    if (filterOptions) {
      holdings = holdings.filter(filterOptions);
    }

    holdings = holdings?.filter(({ remaining_quantity }) => {
      if (
        order?.minimum_quantity &&
        (!order?.unit || order?.unit?.code === CbOrderItemUnit.items.SHARES)
      ) {
        return remaining_quantity >= order?.minimum_quantity;
      }
      return !!remaining_quantity;
    });

    return holdings;
  }, [data, order, filterOptions]);

  useEffect(() => {
    if (availableHoldings.length <= 5 && hasNextPage) {
      void fetchNextPage();
    }
  }, [availableHoldings, hasNextPage, fetchNextPage]);

  return {
    data,
    isLoading,
    availableHoldingsCount: availableHoldings?.length,
    availableHoldings,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  };
};
