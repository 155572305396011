import React from 'react';

import { Button, type ButtonProps } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';

import * as S from './buttons.styles';

export const NextButton = ({
  children,
  variant = 'default',
  ...rest
}: ButtonProps) => {
  return (
    <Button {...rest} variant={variant}>
      {children}
    </Button>
  );
};

export const BackButton = ({
  children,
  variant = 'text',
  iconVariant = 'arrow',
  ...rest
}: ButtonProps & { iconVariant?: 'arrow' | 'close' }) => {
  return (
    <S.BackButton
      {...rest}
      variant={variant}
      icon={
        iconVariant === 'arrow' ? (
          <Icon name="arrow-narrow-left" />
        ) : (
          <Icon name="x-close" size="sm" />
        )
      }
    >
      {children}
    </S.BackButton>
  );
};
