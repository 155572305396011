// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SharesTransferredApi,
  SharesTransferredShowErrorCodes,
  SharesTransferredShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SharesTransferredApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type SharesTransferredShowMethod = SharesTransferredApi['sharesTransferredShow'];
export type SharesTransferredShowResponseType = MethodResult<SharesTransferredShowMethod>;

export const useSharesTransferredShow = (
  variables: Parameters<SharesTransferredShowMethod>[0],
  config?: UseQueryConfig<
    SharesTransferredShowMethod,
    SharesTransferredShowErrorTypes
  >
) => {
  return useQuery<
    SharesTransferredShowMethod,
    SharesTransferredShowErrorTypes
  >(
    (...args) => api.sharesTransferredShow(...args),
    SharesTransferredShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SharesTransferredShow'],
        ...apiQueryConfig.SharesTransferredShow,
        ...config?.queryConfig
      },
    },
    'SharesTransferredShow',
  );
};

export const useSharesTransferredShowInfinite = (
  variables: Parameters<SharesTransferredShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SharesTransferredShowMethod,
    SharesTransferredShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SharesTransferredShowMethod,
    SharesTransferredShowErrorTypes
  >(
    (...args) => api.sharesTransferredShow(...args),
    SharesTransferredShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SharesTransferredShow', 'SharesTransferredShowInfinite'],
        ...apiQueryConfig.SharesTransferredShow,
        ...config?.queryConfig,
      },
    },
    'SharesTransferredShow',
  );
};

export const useSharesTransferredShowLazy = (baseOptions?: {
  variables?: Parameters<SharesTransferredShowMethod>[0],
  config?: UseQueryConfig<
    SharesTransferredShowMethod,
    SharesTransferredShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SharesTransferredShowMethod,
    SharesTransferredShowErrorTypes
  >(
    (...args) => api.sharesTransferredShow(...args),
    SharesTransferredShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SharesTransferredShow'],
        ...apiQueryConfig.SharesTransferredShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SharesTransferredShow',
  );
};

