import { Modal as BaseModal } from '@npm/core/ui/components/molecules/Modal';
import {
  Table,
  type TableProps,
} from '@npm/core/ui/components/molecules/Table';
import {
  WIZARD_CONTENT_WRAPPER_ID,
  WIZARD_PROGRESS_WRAPPER_ID,
  WIZARD_WRAPPER_ID,
} from '@npm/core/ui/components/organisms/Wizard/Layout';
import {
  preserveHorizontalFocusBorder,
  useBreakpoint,
} from '@npm/core/ui/utils/style';
import {
  type AccountForBrokerage,
  type AccountShowAggregate,
  type RepresentativeAggregate,
} from '@npm/data-access';
import styled, { css } from 'styled-components';

export const OBO_MODAL_SCROLLABLE_CONTAINER_ID = 'obo-modal-inner-wrapper';

export const Modal = styled(BaseModal)`
  .ant-modal-body {
    padding-top: 0;
  }

  #${WIZARD_WRAPPER_ID},
    #${WIZARD_PROGRESS_WRAPPER_ID},
    #${WIZARD_CONTENT_WRAPPER_ID} {
    background: transparent;
    padding: 0;

    & > div {
      padding-bottom: 0;
    }
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    #${WIZARD_CONTENT_WRAPPER_ID} {
      height: auto;
    }

    #${WIZARD_PROGRESS_WRAPPER_ID} {
      & > div {
        min-height: unset;
        margin-bottom: ${({ theme }) => theme.spacing.lg}px;
      }
    }
  `}
`;

export const InnerWrapper = styled.div.attrs({
  id: OBO_MODAL_SCROLLABLE_CONTAINER_ID,
})`
  max-height: 60vh;
  overflow: auto;
  ${preserveHorizontalFocusBorder}

  ${({ theme }) => useBreakpoint(theme).mobile`
    height: auto;
    max-height: unset;
  `}

  .card-content {
    padding-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;

export const FilterToolbarWrapper = styled.div`
  ${preserveHorizontalFocusBorder}
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
  // hack for using filter panel in modal where is issue with Grid's negative margin
  overflow: hidden;
`;

const TableStyles = css`
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
`;

export const AccountsTable = styled(Table)<TableProps<AccountForBrokerage>>`
  ${TableStyles}

  th:nth-last-child(-n + 2),
  td:nth-last-child(-n + 2) {
    text-align: right;
  }
`;

export const NegotiationAccountsTable = styled(Table)<
  TableProps<AccountShowAggregate>
>`
  ${TableStyles}
`;

export const RepresentativesTable = styled(Table)<
  TableProps<RepresentativeAggregate>
>`
  ${TableStyles}
`;
