// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SUpdateIssuerSecurityApi,
  S2SUpdateIssuerSecurityFromHoldingErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SUpdateIssuerSecurityApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SUpdateIssuerSecurityFromHoldingMethod = S2SUpdateIssuerSecurityApi['s2SUpdateIssuerSecurityFromHolding'];

export const useS2SUpdateIssuerSecurityFromHolding = () => {
  return useMutation<S2SUpdateIssuerSecurityFromHoldingMethod>(
    (...args) => api.s2SUpdateIssuerSecurityFromHolding(...args),
    S2SUpdateIssuerSecurityFromHoldingErrorCodes,
    'S2SUpdateIssuerSecurityFromHolding',
    '/api/s2s/issuers/{issuer_npm_guid}/securities/{security_npm_guid}',
    'patch'
  );
};
