import { NumberParam, useQueryParam } from 'use-query-params';

const queryParamKey = 'sellIndicationHoldingId';

export const getSellIndicationQueryParam = (
  sellIndicationHoldingId: number
) => {
  return `${queryParamKey}=${sellIndicationHoldingId}`;
};

export const useSellIndicationHoldingId = () => {
  return useQueryParam(queryParamKey, NumberParam, {
    updateType: 'replaceIn',
  });
};
