import { Codebooks } from './codebooks.types';

const Items = {
  buyer_and_seller: 'buyer_and_seller',
  seller: 'seller',
} as const;

export type AccountUiModeType = keyof typeof Items;

export const CbAccountUiMode = {
  code: Codebooks.ACCOUNT_UI_MODE,
  items: Items,
};
