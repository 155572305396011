import { useCallback, useMemo } from 'react';

import {
  type EventShowMethod,
  type Submission,
  type SubmissionShowErrorTypes,
  type SubmissionShowMethod,
  useEventShow,
  useSubmissionShow,
} from '@npm/data-access';
import { type AxiosRequestConfig } from 'axios';

// a hook that downloads both a submission and full event
export const useSubmissionWithEventShow = (
  variables: Parameters<SubmissionShowMethod>[0] &
    Pick<Parameters<EventShowMethod>[0], 'accountId'> &
    Pick<Parameters<EventShowMethod>[0], 'userId'>,
  config?: {
    onComplete?: (data: Submission) => void;
    onError?: (error: SubmissionShowErrorTypes) => void;
    axiosConfig?: AxiosRequestConfig;
  }
) => {
  const {
    data: submissionData,
    isLoading: isLoadingSubmission,
    refetch: refetchSubmission,
  } = useSubmissionShow(
    {
      submissionId: variables.submissionId,
      includeField: variables.includeField.includes('event')
        ? variables.includeField
        : [...variables.includeField, 'event'],
    },
    {
      queryConfig: { refetchOnMount: 'always' },
      onError: err => {
        config?.onError?.(err);
      },
      axiosConfig: config?.axiosConfig,
    }
  );

  const {
    data: eventData,
    isLoading: isLoadingEvent,
    refetch: refetchEvent,
  } = useEventShow(
    {
      id: submissionData?.event?.id,
      accountId: variables?.accountId,
      userId: variables?.userId,
      includeField: [
        'assets',
        'issuer_entity',
        'issuance',
        'order_settings',
        'settings',
        'submission_statistics',
      ],
    },
    {
      queryConfig: {
        enabled: !!submissionData?.event?.id,
      },
      onError: err => {
        config?.onError?.({
          data: err.data,
          originalStatus: err.status,
          status: -1,
        });
      },
      axiosConfig: config?.axiosConfig,
    }
  );

  const isLoading = isLoadingSubmission || isLoadingEvent;

  const refetch = useCallback(async () => {
    await refetchSubmission();
    await refetchEvent();
  }, [refetchSubmission, refetchEvent]);

  const data = useMemo(() => {
    if (!submissionData || !eventData) return null;
    return {
      ...submissionData,
      event: eventData,
    };
  }, [submissionData, eventData]);

  return {
    isLoading,
    data,
    refetch,
  };
};
