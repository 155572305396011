import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  CbWorkstationType,
  useUserShowCurrent,
  useUserUpdate,
} from '@npm/data-access';
import { useTheme } from 'styled-components';

import { useCurrentWorkstation } from '../../../auth/user/role';

function DrawerWatchListEmailToggle() {
  const { data, isLoading: isUserLoading } = useUserShowCurrent({});
  const { withShowApiErrorMiddleware } = useAlerts();
  const { isLoading: isSaving, execute: userUpdate } = useUserUpdate();
  const theme = useTheme();

  const currentWorkstation = useCurrentWorkstation();

  const isBrokerWorkstation =
    currentWorkstation?.type?.code === CbWorkstationType.items.brokerage;

  const watchlistEmailRemote = data?.settings?.notifications?.watchlist_email;
  const handleWatchListEmailToggle = async () => {
    try {
      await withShowApiErrorMiddleware(userUpdate)({
        id: data?.id,
        userUpdateRequestContract: {
          notifications_enable_watchlist_email: !watchlistEmailRemote,
        },
      });
    } catch (err) {
      console.error(err);
      return;
    }
  };

  if (isBrokerWorkstation) {
    return null;
  }

  return (
    <Flex direction="row" justify="space-between" align="center">
      <Flex gap={theme.spacing.xs} align="center">
        <TooltipInfoIcon
          title={
            !watchlistEmailRemote
              ? 'Subscribe to Watchlist Notification Emails to receive daily alerts when relevant bids or offers become available in SecondMarket.'
              : "Opting out means you won't receive email alerts for deals matching your criteria. We advise keeping notifications enabled to stay informed about relevant market opportunities."
          }
        />
        <Text size="sm" colorVariant="secondary">
          Watchlist Notifications
        </Text>
      </Flex>
      <Switch
        disabled={isUserLoading && isSaving}
        checked={!isUserLoading && watchlistEmailRemote}
        onClick={handleWatchListEmailToggle}
      />
    </Flex>
  );
}

export default DrawerWatchListEmailToggle;
