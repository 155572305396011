import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { DarkModeProvider } from '@npm/core/ui/context/DarkModeContext/DarkModeContext';
import { SecurityWrapper } from '@npm/okta';
import { DatadogService } from '@npm/utils';

import { App } from './App';
import { AppHeader } from './AppHeader';
import './trustedTypes';

DatadogService.init();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <SecurityWrapper>
          <DarkModeProvider>
            <App />
          </DarkModeProvider>
        </SecurityWrapper>
      }
    />
  )
);

ReactDOM.render(
  <div className="application">
    <AppHeader />
    <RouterProvider router={router} />
  </div>,
  document.getElementById('root')
);
