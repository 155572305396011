import { type ForwardRefExoticComponent } from 'react';

import { Checkbox as AntCheckbox, type CheckboxProps } from 'antd';
import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

type ExternalCheckboxProps = {
  // Group: typeof Group;
} & ForwardRefExoticComponent<
  CheckboxProps & React.RefAttributes<HTMLInputElement>
>;

// TODO: error state of the checkbox in the form (parent .ant-form-item-has-error)
export const StyledCheckbox = styled<ExternalCheckboxProps>(AntCheckbox)`
  & {
    color: ${({ theme }) => theme.color.general.typography.primary};
    font-size: ${({ theme }) => theme.typography.size.sm}px !important;

    ${({ theme }) => useBreakpoint(theme).mobile`
    font-size: ${({ theme }) => theme.typography.size.xs}px !important;
  `}
  }

  .ant-checkbox-disabled + span {
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    &::after {
      border-color: ${({ theme }) => theme.color.grey_0};
    }
  }

  .ant-checkbox-disabled {
    &:not(.ant-checkbox-checked) {
      .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.color.general.layout.two};
        border-color: ${({ theme }) =>
          theme.color.general.borders.secondary} !important;
      }
    }
  }

  .ant-checkbox-disabled {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${({ theme }) =>
          theme.color.general.backgrounds.primary} !important;
        border-color: ${({ theme }) =>
          theme.color.general.borders.secondary} !important;
      }
    }
  }

  .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.color.general.layout.two};
    border-color: ${({ theme }) => theme.color.general.borders.secondary};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.color.blue_60};
    border-color: ${({ theme }) => theme.color.blue_40};
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    width: 16px;
    height: 16px;
  }

  .ant-checkbox-checked::after {
    border: 1px solid ${({ theme }) => theme.color.blue_40};
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.color.blue_40};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.color.info.backgrounds.secondaryHover};
    border-color: transparent;
  }

  .ant-form-item-has-error & .ant-checkbox-inner {
    border: 2px solid ${({ theme }) => theme.color.error.typography.primary};
  }
`;
