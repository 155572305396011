import { Fragment, useEffect, useState } from 'react';

import { requestIdleCallback } from './ProgressiveRenderer.utils';

type Props = {
  items: {
    key: string | number;
    render: () => React.ReactNode;
    placeholder?: React.ReactNode;
  }[];
  key?: unknown;
};

/**
 * Use this component to render the content gradually rather than all at once.
 * Items will be rendered one by one. If items depend on one specific parameter,
 * pass it as a key. This should be your last resort. First try to optimize the page.
 */
export const ProgressiveRenderer = ({ items }: Props) => {
  const [revealedIndex, setRevealedIndex] = useState(-1);

  useEffect(() => {
    if (items.length === 0) return;

    const reveal = i => {
      setRevealedIndex(i);
      if (i < items.length) requestIdleCallback(() => reveal(i + 1));
    };

    reveal(0);
  }, [items.length]);

  return (
    <>
      {items.map(({ key, render, placeholder }, index) => (
        <Fragment key={key}>
          {index <= revealedIndex ? render() : placeholder}
        </Fragment>
      ))}
    </>
  );
};
