import { type ComponentProps } from 'react';

import styled, { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';

import { type GridComponentProps, type GridItem as GridItemComp } from './Grid';

const commonStylesByResolution = ({
  resolution,
  cols,
  rows,
  areas,
  gap,
  col = cols && (cols[resolution] || cols['default']),
  row = rows && (rows[resolution] || rows['default']),
}) => css`
  ${cols &&
  `
    grid-template-columns: ${
      col.count === 1 ? col.size : `repeat(${col.count}, ${col.size})`
    };
  `} ${rows &&
  `
    grid-template-rows: ${
      row.count === 1 ? row.size : `repeat(${row.count}, ${row.size})`
    };
  `}
  ${!rows && `grid-auto-rows: min-content;`}
  ${!cols && `grid-auto-columns: min-content;`}
  ${areas && `grid-template-areas: ${areas[resolution]};`}
  ${gap && `grid-gap: ${gap[resolution]};`}
`; // TODO: deprecated component, should be removed
export const Grid = styled.div<GridComponentProps>`
  display: grid;
  align-items: start;
  ${({ cols, rows, areas, gap }) =>
    commonStylesByResolution({
      resolution: 'default',
      cols,
      rows,
      areas,
      gap,
    })}; // SM Desktop
  ${({ theme, cols, rows, areas, gap }) => useBreakpoint(theme).tablet`
    ${commonStylesByResolution({
      resolution: 'smDesktop',
      cols,
      rows,
      areas,
      gap,
    })}
  `} // Tablet
  ${({ theme, cols, rows, areas, gap }) => useBreakpoint(theme).smTablet`
    ${commonStylesByResolution({
      resolution: 'tablet',
      cols,
      rows,
      areas,
      gap,
    })}
  `}  // Mobile
  ${({ theme, cols, rows, areas, gap }) => useBreakpoint(theme).mobile`
    ${commonStylesByResolution({
      resolution: 'mobile',
      cols,
      rows,
      areas,
      gap,
    })}
  `}
`;
export const GridItem = styled.div<{
  $areaName?: string;
  $justifySelf: ComponentProps<typeof GridItemComp>['justifySelf'];
}>`
  display: grid;
  align-items: start;
  height: 100%;

  ${({ $areaName }) => $areaName && `grid-area: ${$areaName};`};

  ${({ $justifySelf }) =>
    $justifySelf &&
    `
    justify-self: ${$justifySelf};
  `}
`;
