import { Codebooks } from './codebooks.types';

const Items = {
  blocked: 'blocked',
  complete: 'complete',
  in_progress: 'in_progress',
  incomplete: 'incomplete',

} as const;


export const CbTradeTimelineStatus = {
  code: Codebooks.TRADE_TIMELINE,
  items: Items,
};
