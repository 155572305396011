// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SecondmarketOrderItemApi,
  SecondmarketOrderItemIndexErrorCodes,
  SecondmarketOrderItemIndexErrorTypes,
  SecondmarketOrderItemShowErrorCodes,
  SecondmarketOrderItemShowErrorTypes,
  SecondmarketOrderItemUpdateErrorCodes,
  SecondmarketOrderItemCancelErrorCodes,
  InvestorOrderItemShowErrorCodes,
  InvestorOrderItemShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SecondmarketOrderItemApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type SecondmarketOrderItemIndexMethod = SecondmarketOrderItemApi['secondmarketOrderItemIndex'];
export type SecondmarketOrderItemIndexResponseType = MethodResult<SecondmarketOrderItemIndexMethod>;

export const useSecondmarketOrderItemIndex = (
  variables: Parameters<SecondmarketOrderItemIndexMethod>[0],
  config?: UseQueryConfig<
    SecondmarketOrderItemIndexMethod,
    SecondmarketOrderItemIndexErrorTypes
  >
) => {
  return useQuery<
    SecondmarketOrderItemIndexMethod,
    SecondmarketOrderItemIndexErrorTypes
  >(
    (...args) => api.secondmarketOrderItemIndex(...args),
    SecondmarketOrderItemIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketOrderItemIndex'],
        ...apiQueryConfig.SecondmarketOrderItemIndex,
        ...config?.queryConfig
      },
    },
    'SecondmarketOrderItemIndex',
  );
};

export const useSecondmarketOrderItemIndexInfinite = (
  variables: Parameters<SecondmarketOrderItemIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondmarketOrderItemIndexMethod,
    SecondmarketOrderItemIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondmarketOrderItemIndexMethod,
    SecondmarketOrderItemIndexErrorTypes
  >(
    (...args) => api.secondmarketOrderItemIndex(...args),
    SecondmarketOrderItemIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketOrderItemIndex', 'SecondmarketOrderItemIndexInfinite'],
        ...apiQueryConfig.SecondmarketOrderItemIndex,
        ...config?.queryConfig,
      },
    },
    'SecondmarketOrderItemIndex',
  );
};

export const useSecondmarketOrderItemIndexLazy = (baseOptions?: {
  variables?: Parameters<SecondmarketOrderItemIndexMethod>[0],
  config?: UseQueryConfig<
    SecondmarketOrderItemIndexMethod,
    SecondmarketOrderItemIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondmarketOrderItemIndexMethod,
    SecondmarketOrderItemIndexErrorTypes
  >(
    (...args) => api.secondmarketOrderItemIndex(...args),
    SecondmarketOrderItemIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondmarketOrderItemIndex'],
        ...apiQueryConfig.SecondmarketOrderItemIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondmarketOrderItemIndex',
  );
};


export type SecondmarketOrderItemShowMethod = SecondmarketOrderItemApi['secondmarketOrderItemShow'];
export type SecondmarketOrderItemShowResponseType = MethodResult<SecondmarketOrderItemShowMethod>;

export const useSecondmarketOrderItemShow = (
  variables: Parameters<SecondmarketOrderItemShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketOrderItemShowMethod,
    SecondmarketOrderItemShowErrorTypes
  >
) => {
  return useQuery<
    SecondmarketOrderItemShowMethod,
    SecondmarketOrderItemShowErrorTypes
  >(
    (...args) => api.secondmarketOrderItemShow(...args),
    SecondmarketOrderItemShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketOrderItemShow'],
        ...apiQueryConfig.SecondmarketOrderItemShow,
        ...config?.queryConfig
      },
    },
    'SecondmarketOrderItemShow',
  );
};

export const useSecondmarketOrderItemShowInfinite = (
  variables: Parameters<SecondmarketOrderItemShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondmarketOrderItemShowMethod,
    SecondmarketOrderItemShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondmarketOrderItemShowMethod,
    SecondmarketOrderItemShowErrorTypes
  >(
    (...args) => api.secondmarketOrderItemShow(...args),
    SecondmarketOrderItemShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketOrderItemShow', 'SecondmarketOrderItemShowInfinite'],
        ...apiQueryConfig.SecondmarketOrderItemShow,
        ...config?.queryConfig,
      },
    },
    'SecondmarketOrderItemShow',
  );
};

export const useSecondmarketOrderItemShowLazy = (baseOptions?: {
  variables?: Parameters<SecondmarketOrderItemShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketOrderItemShowMethod,
    SecondmarketOrderItemShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondmarketOrderItemShowMethod,
    SecondmarketOrderItemShowErrorTypes
  >(
    (...args) => api.secondmarketOrderItemShow(...args),
    SecondmarketOrderItemShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondmarketOrderItemShow'],
        ...apiQueryConfig.SecondmarketOrderItemShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondmarketOrderItemShow',
  );
};


export type SecondmarketOrderItemUpdateMethod = SecondmarketOrderItemApi['secondmarketOrderItemUpdate'];

export const useSecondmarketOrderItemUpdate = () => {
  return useMutation<SecondmarketOrderItemUpdateMethod>(
    (...args) => api.secondmarketOrderItemUpdate(...args),
    SecondmarketOrderItemUpdateErrorCodes,
    'SecondmarketOrderItemUpdate',
    '/api/secondmarket-order-items/{id}',
    'put'
  );
};

export type SecondmarketOrderItemCancelMethod = SecondmarketOrderItemApi['secondmarketOrderItemCancel'];

export const useSecondmarketOrderItemCancel = () => {
  return useMutation<SecondmarketOrderItemCancelMethod>(
    (...args) => api.secondmarketOrderItemCancel(...args),
    SecondmarketOrderItemCancelErrorCodes,
    'SecondmarketOrderItemCancel',
    '/api/secondmarket-order-items/{id}',
    'delete'
  );
};

export type InvestorOrderItemShowMethod = SecondmarketOrderItemApi['investorOrderItemShow'];
export type InvestorOrderItemShowResponseType = MethodResult<InvestorOrderItemShowMethod>;

export const useInvestorOrderItemShow = (
  variables: Parameters<InvestorOrderItemShowMethod>[0],
  config?: UseQueryConfig<
    InvestorOrderItemShowMethod,
    InvestorOrderItemShowErrorTypes
  >
) => {
  return useQuery<
    InvestorOrderItemShowMethod,
    InvestorOrderItemShowErrorTypes
  >(
    (...args) => api.investorOrderItemShow(...args),
    InvestorOrderItemShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorOrderItemShow'],
        ...apiQueryConfig.InvestorOrderItemShow,
        ...config?.queryConfig
      },
    },
    'InvestorOrderItemShow',
  );
};

export const useInvestorOrderItemShowInfinite = (
  variables: Parameters<InvestorOrderItemShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorOrderItemShowMethod,
    InvestorOrderItemShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorOrderItemShowMethod,
    InvestorOrderItemShowErrorTypes
  >(
    (...args) => api.investorOrderItemShow(...args),
    InvestorOrderItemShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorOrderItemShow', 'InvestorOrderItemShowInfinite'],
        ...apiQueryConfig.InvestorOrderItemShow,
        ...config?.queryConfig,
      },
    },
    'InvestorOrderItemShow',
  );
};

export const useInvestorOrderItemShowLazy = (baseOptions?: {
  variables?: Parameters<InvestorOrderItemShowMethod>[0],
  config?: UseQueryConfig<
    InvestorOrderItemShowMethod,
    InvestorOrderItemShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorOrderItemShowMethod,
    InvestorOrderItemShowErrorTypes
  >(
    (...args) => api.investorOrderItemShow(...args),
    InvestorOrderItemShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorOrderItemShow'],
        ...apiQueryConfig.InvestorOrderItemShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorOrderItemShow',
  );
};

