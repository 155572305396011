import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type CompanyPricingApi } from '@npm/data-access';

import { PremiumTextGuard } from '../../../../../auth/user/components';
import { type useDocumentViewer } from '../../../../../documents/DocumentViewer';
import { CompanyOverviewPriceDisplay } from '../../../components/CompanyOverviewPriceDisplay';

import { MutualFundMarksFilingsDropdown } from './MutualFundMarksFilingsDropdown';

type ShowDocumentPreviewFn = ReturnType<
  typeof useDocumentViewer
>['showDocumentPreview'];

export const getMutualFundMarksColumns = (
  showDocumentPreview?: ShowDocumentPreviewFn
): ExtendedColumnType<CompanyPricingApi.Mtm>[] => {
  return [
    {
      title: 'Parent Entity',
      key: 'mutual_fund_parent_name',
      render: ({ mutual_fund_parent_name }) => (
        <PremiumTextGuard>
          <Text size="sm" colorVariant="primary">
            {mutual_fund_parent_name}
          </Text>
        </PremiumTextGuard>
      ),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      title: 'Mutual Fund',
      key: 'name',
      render: ({ mutual_fund_series_name }) => (
        <PremiumTextGuard>
          <Text size="sm" colorVariant="primary">
            {mutual_fund_series_name}
          </Text>
        </PremiumTextGuard>
      ),
    },
    {
      title: 'Asset',
      key: 'asset_name',
      render: ({ mutual_fund_asset_name }) => (
        <PremiumTextGuard>
          <Text size="sm" colorVariant="primary">
            {mutual_fund_asset_name}
          </Text>
        </PremiumTextGuard>
      ),
      responsive: TABLE_BREAKPOINTS.TABLET_SM,
    },
    {
      title: 'PPS',
      key: 'price_per_unit',
      render: ({ price_per_share, implied_valuation, discount_or_premium }) => (
        <CompanyOverviewPriceDisplay
          price={price_per_share}
          impliedValuation={implied_valuation}
          change={discount_or_premium}
        />
      ),
      titleInfo: 'Price Per Share',
    },
    {
      title: 'Reporting Period',
      key: 'rep_pd_date',
      responsive: TABLE_BREAKPOINTS.DESKTOP,
      render: ({ reporting_period }) =>
        formatDate(reporting_period, { dateFormat: DATE_FORMATS.DATE }) ||
        EMPTY_VALUE,
    },
    {
      title: 'Size (USD)',
      key: 'total_value',
      responsive: TABLE_BREAKPOINTS.NONE,
      render: ({ valuation }) => (
        <Text.Price
          size="sm"
          colorVariant="primary"
          value={valuation}
          compact
        />
      ),
    },
    {
      title: 'Size (Shares)',
      key: 'balance',
      responsive: TABLE_BREAKPOINTS.NONE,
      render: ({ number_of_shares }) => (
        <Flex align={'center'} gap={'sm'}>
          <Text.Quantity
            size="sm"
            colorVariant="primary"
            compact
            value={number_of_shares}
          />
          <Text size="sm" colorVariant="primary">
            {number_of_shares === 1 ? 'Share' : 'Shares'}
          </Text>
        </Flex>
      ),
    },
    {
      title: 'Filing Date',
      key: 'filed_at',
      responsive: TABLE_BREAKPOINTS.NONE,
      render: ({ filing_date }) =>
        formatDate(filing_date, { dateFormat: DATE_FORMATS.DATE }) ||
        EMPTY_VALUE,
    },
    {
      key: 'filings',
      // TODO: Now showing only link to EDGAR until BE returns array of fillings
      render: mtm => (
        <MutualFundMarksFilingsDropdown
          mtm={mtm}
          showDocumentPreview={showDocumentPreview}
        />
      ),
    },
  ];
};
