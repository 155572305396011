// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FolderDocumentsCreateSyncApi,
  FolderDocumentsCreateSyncShowErrorCodes,
  FolderDocumentsCreateSyncShowErrorTypes,
  FolderDocumentsDestroySyncShowErrorCodes,
  FolderDocumentsDestroySyncShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FolderDocumentsCreateSyncApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FolderDocumentsCreateSyncShowMethod = FolderDocumentsCreateSyncApi['folderDocumentsCreateSyncShow'];
export type FolderDocumentsCreateSyncShowResponseType = MethodResult<FolderDocumentsCreateSyncShowMethod>;

export const useFolderDocumentsCreateSyncShow = (
  variables: Parameters<FolderDocumentsCreateSyncShowMethod>[0],
  config?: UseQueryConfig<
    FolderDocumentsCreateSyncShowMethod,
    FolderDocumentsCreateSyncShowErrorTypes
  >
) => {
  return useQuery<
    FolderDocumentsCreateSyncShowMethod,
    FolderDocumentsCreateSyncShowErrorTypes
  >(
    (...args) => api.folderDocumentsCreateSyncShow(...args),
    FolderDocumentsCreateSyncShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderDocumentsCreateSyncShow'],
        ...apiQueryConfig.FolderDocumentsCreateSyncShow,
        ...config?.queryConfig
      },
    },
    'FolderDocumentsCreateSyncShow',
  );
};

export const useFolderDocumentsCreateSyncShowInfinite = (
  variables: Parameters<FolderDocumentsCreateSyncShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    FolderDocumentsCreateSyncShowMethod,
    FolderDocumentsCreateSyncShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    FolderDocumentsCreateSyncShowMethod,
    FolderDocumentsCreateSyncShowErrorTypes
  >(
    (...args) => api.folderDocumentsCreateSyncShow(...args),
    FolderDocumentsCreateSyncShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderDocumentsCreateSyncShow', 'FolderDocumentsCreateSyncShowInfinite'],
        ...apiQueryConfig.FolderDocumentsCreateSyncShow,
        ...config?.queryConfig,
      },
    },
    'FolderDocumentsCreateSyncShow',
  );
};

export const useFolderDocumentsCreateSyncShowLazy = (baseOptions?: {
  variables?: Parameters<FolderDocumentsCreateSyncShowMethod>[0],
  config?: UseQueryConfig<
    FolderDocumentsCreateSyncShowMethod,
    FolderDocumentsCreateSyncShowErrorTypes
  >
}) => {
  return useLazyQuery<
    FolderDocumentsCreateSyncShowMethod,
    FolderDocumentsCreateSyncShowErrorTypes
  >(
    (...args) => api.folderDocumentsCreateSyncShow(...args),
    FolderDocumentsCreateSyncShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FolderDocumentsCreateSyncShow'],
        ...apiQueryConfig.FolderDocumentsCreateSyncShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FolderDocumentsCreateSyncShow',
  );
};


export type FolderDocumentsDestroySyncShowMethod = FolderDocumentsCreateSyncApi['folderDocumentsDestroySyncShow'];
export type FolderDocumentsDestroySyncShowResponseType = MethodResult<FolderDocumentsDestroySyncShowMethod>;

export const useFolderDocumentsDestroySyncShow = (
  variables: Parameters<FolderDocumentsDestroySyncShowMethod>[0],
  config?: UseQueryConfig<
    FolderDocumentsDestroySyncShowMethod,
    FolderDocumentsDestroySyncShowErrorTypes
  >
) => {
  return useQuery<
    FolderDocumentsDestroySyncShowMethod,
    FolderDocumentsDestroySyncShowErrorTypes
  >(
    (...args) => api.folderDocumentsDestroySyncShow(...args),
    FolderDocumentsDestroySyncShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderDocumentsDestroySyncShow'],
        ...apiQueryConfig.FolderDocumentsDestroySyncShow,
        ...config?.queryConfig
      },
    },
    'FolderDocumentsDestroySyncShow',
  );
};

export const useFolderDocumentsDestroySyncShowInfinite = (
  variables: Parameters<FolderDocumentsDestroySyncShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    FolderDocumentsDestroySyncShowMethod,
    FolderDocumentsDestroySyncShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    FolderDocumentsDestroySyncShowMethod,
    FolderDocumentsDestroySyncShowErrorTypes
  >(
    (...args) => api.folderDocumentsDestroySyncShow(...args),
    FolderDocumentsDestroySyncShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FolderDocumentsDestroySyncShow', 'FolderDocumentsDestroySyncShowInfinite'],
        ...apiQueryConfig.FolderDocumentsDestroySyncShow,
        ...config?.queryConfig,
      },
    },
    'FolderDocumentsDestroySyncShow',
  );
};

export const useFolderDocumentsDestroySyncShowLazy = (baseOptions?: {
  variables?: Parameters<FolderDocumentsDestroySyncShowMethod>[0],
  config?: UseQueryConfig<
    FolderDocumentsDestroySyncShowMethod,
    FolderDocumentsDestroySyncShowErrorTypes
  >
}) => {
  return useLazyQuery<
    FolderDocumentsDestroySyncShowMethod,
    FolderDocumentsDestroySyncShowErrorTypes
  >(
    (...args) => api.folderDocumentsDestroySyncShow(...args),
    FolderDocumentsDestroySyncShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FolderDocumentsDestroySyncShow'],
        ...apiQueryConfig.FolderDocumentsDestroySyncShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FolderDocumentsDestroySyncShow',
  );
};

