import React, { useMemo, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { ModalKey, useQsModal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbAccountType,
  useAccountIndex,
  useHoldingIndex,
} from '@npm/data-access';
import {
  NPM_SETTLEMENT_NAME,
  useUserContextStore,
} from '@npm/features/auth/user/context';

import { type OptInFormValues } from './OptInAccountSelection/OptInAccountSelection.types';

import * as S from './OptIn.styles';
import { CancelOptInModal, OptInFooter } from './components';
import { OptInMode } from './OptIn.enums';
import { useOptInForm } from './OptIn.hooks';
import { OptInAccountSelection } from './OptInAccountSelection';

export const OPT_IN_FORM_ID = 'opt-in-form';

export const OptIn = () => {
  const { isMobile, isSmTablet } = useBreakpoints();
  const user = useUserContextStore(store => store.user);

  const { isOpen, handleClose: handleCloseConfirm } = useQsModal(
    ModalKey.OPT_IN_MODAL
  );

  const [optInMode, setOptInMode] = useState<OptInMode | undefined>(
    OptInMode.COPY_ACCOUNT
  );

  const [form] = Form.useForm<OptInFormValues>();

  const { isLoading: isLoadingEntities, data: eligibleEntities } =
    useAccountIndex(
      {
        hasActiveEvents: false,
      },
      {
        queryConfig: {
          enabled: isOpen,
        },
      }
    );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const handleCloseAttempt = () => {
    if (hasUnsavedChanges) {
      setIsDiscardModalOpen(true);
    } else {
      datadogRum.addAction('closed OptIn', {
        actionType: 'custom',
      });
      handleCloseConfirm();
    }
  };

  const npmSettlementAccounts = eligibleEntities?.accounts?.filter(
    acc => acc.brokerage_firm?.name === NPM_SETTLEMENT_NAME
  );
  const isEntity = npmSettlementAccounts?.some(
    acc => acc.type?.code === CbAccountType.items.OrganizationAccount
  );
  const accountType = isEntity
    ? CbAccountType.items.OrganizationAccount
    : CbAccountType.items.PersonAccount;

  const selectedEntityID = Form.useWatch('selectedEntity', form);
  const accountId = isEntity
    ? selectedEntityID
    : user?.active_role?.subject?.id;

  const { data, isFetching: isFetchingHoldings } = useHoldingIndex(
    {
      accountId: accountId,
      page: 1,
      size: 100,
    },
    {
      queryConfig: {
        enabled: isOpen ? !!accountId : false,
      },
    }
  );

  const footer = (
    <OptInFooter
      id={OPT_IN_FORM_ID}
      onClose={handleCloseAttempt}
      disabled={isFetchingHoldings}
    />
  );

  const watchlist = useMemo(
    () =>
      data?.holdings
        ?.map(holding => holding.issuer_entity?.id)
        ?.filter(Boolean) || [],
    [data]
  );

  const { handleCreateAccount, handleCopyAccount } = useOptInForm(
    form,
    accountType,
    watchlist
  );

  return (
    <>
      <S.StyledModal
        open={isOpen}
        closable
        destroyOnClose
        cancelText="Cancel"
        onCancel={handleCloseAttempt}
        size="xl"
        isFullScreen={isSmTablet || isMobile}
        fullScreenProps={{ noHeader: true, onClose: handleCloseAttempt }}
        footer={(isMobile || isSmTablet) && footer}
      >
        <Form<OptInFormValues>
          id={OPT_IN_FORM_ID}
          form={form}
          initialValues={{
            legalName: '',
            selectedEntity: undefined,
          }}
          onValuesChange={() => setHasUnsavedChanges(true)}
          onFinish={() =>
            optInMode === OptInMode.COPY_ACCOUNT
              ? handleCopyAccount()
              : handleCreateAccount()
          }
        >
          <OptInAccountSelection
            accounts={eligibleEntities}
            isLoadingEntities={isLoadingEntities}
            optInMode={optInMode}
            setOptinMode={setOptInMode}
            footer={!(isMobile || isSmTablet) && footer}
            isEntity={isEntity}
            accountsData={data}
          />
        </Form>
      </S.StyledModal>

      <CancelOptInModal
        open={isDiscardModalOpen}
        onConfirm={() => {
          datadogRum.addAction('closed OptIn', {
            actionType: 'custom',
          });
          handleCloseConfirm();
        }}
        onClose={() => setIsDiscardModalOpen(false)}
      />
    </>
  );
};
