import { type ComponentProps } from 'react';

import { type Table } from '@npm/core/ui/components/molecules/Table';

import {
  TABLE_SCROLLABLE_ELEMENT_IDENTIFIER,
  type TableScrollIndicator,
} from './TableScrollIndicator';

export const useScrollIndicatorClick = <T,>(
  tableId: string,
  tableMaxHeight: number,
  showScrollIndicator: boolean
) => {
  const handleScrollIndicatorClick = () => {
    const tableElement = document.getElementById(tableId);

    if (tableElement) {
      const scrollContainer = tableElement.querySelector(
        TABLE_SCROLLABLE_ELEMENT_IDENTIFIER
      );

      if (scrollContainer) {
        scrollContainer.scrollBy({
          // scroll only 75% of the table height to still show the value
          // that was covered by the scroll indicator button before it was clicked
          top: tableMaxHeight * 0.75,
          behavior: 'smooth',
        });
      }
    }
  };

  const tableProps: Partial<ComponentProps<typeof Table<T>>> = {
    id: tableId,
    scroll: showScrollIndicator ? { y: `${tableMaxHeight}px` } : undefined,
  };

  const scrollIndicatorProps: ComponentProps<typeof TableScrollIndicator> = {
    tableId,
    handleClick: handleScrollIndicatorClick,
  };

  return {
    handleScrollIndicatorClick,
    tableProps,
    scrollIndicatorProps,
  };
};
