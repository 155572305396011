import { useEffect, useState } from 'react';

import { type ErrorObj } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { usePreservableQueryParamsStore } from '@npm/core/ui/services/paths';
import {
  type IssuerEntityAggregate,
  type IssuerEntityApiIssuerEntityShowRequest,
  useIssuerEntityShow,
} from '@npm/data-access';
import { NumberParam, useQueryParam } from 'use-query-params';

import { SM_PRESERVABLE_QUERY_PARAMS_GROUP } from './CompanySelectDrawer';

const ISSUER_ENTITY_NOTIFICATION_KEY = 'useIssuerEntityShow';

export const ISSUER_ENTITY_FETCH_INCLUDE_FIELDS: IssuerEntityApiIssuerEntityShowRequest['includeField'] =
  [
    'hidden_bids_count',
    'hidden_offers_count',
    'hidden_inventory_count',
    'live_inventory_count',
    'last_order_created_at_date',
  ];

export const useCompanySelect = () => {
  const [companyId, setCompanyId] = useQueryParam('companyId', NumberParam);

  const [selectedCompany, setSelectedCompany] =
    useState<IssuerEntityAggregate>();

  const setPreservableQueryParams = usePreservableQueryParamsStore(
    x => x.setPreservableQueryParams
  );

  const handleCompanySet = (company?: IssuerEntityAggregate) => {
    setSelectedCompany(company);
    setPreservableQueryParams(
      SM_PRESERVABLE_QUERY_PARAMS_GROUP,
      {
        companyId: company?.id,
      },
      false
    );
  };

  const onCompanySelect = (company?: IssuerEntityAggregate) => {
    setCompanyId(company?.id);
    handleCompanySet(company);
  };

  const { data: issuerEntity, isLoading: isIssuerEntityLoading } =
    useIssuerEntityShow(
      {
        id: companyId?.toString(),
        includeField: ISSUER_ENTITY_FETCH_INCLUDE_FIELDS,
      },
      {
        queryConfig: { enabled: !!companyId },
        onError: error => {
          if (
            (error as ErrorObj).status === 404 ||
            (error as ErrorObj).originalStatus === 404
          ) {
            setCompanyId(undefined);
            Notification.close(ISSUER_ENTITY_NOTIFICATION_KEY);
            Notification.error({
              key: ISSUER_ENTITY_NOTIFICATION_KEY,
              message: `Company #${companyId} not found`,
            });
          } else {
            throw error;
          }
        },
      }
    );

  useEffect(() => {
    // page reload
    if (issuerEntity) {
      handleCompanySet(issuerEntity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuerEntity]);

  return {
    companyId,
    setCompanyId: (companyId: number | undefined) => {
      if (!companyId) {
        // reset selected company state & preservable companyId query param
        handleCompanySet(undefined);
      }
      setCompanyId(companyId);
    },
    selectedCompany: companyId ? selectedCompany : undefined,
    onCompanySelect,
    issuerEntity,
    isIssuerEntityLoading,
  };
};
