import { useEffect } from 'react';

import {
  type AssetTypeCode,
  getCodebookCode,
  type IssuerEntitiesHoldingCreateRequestContract,
  useHoldingShow,
} from '@npm/data-access';
import { Form } from 'antd';

export type AddAggregatedHoldingFormValues = Pick<
  IssuerEntitiesHoldingCreateRequestContract,
  'quantity'
> & {
  type: AssetTypeCode;
};

type Params = {
  holdingId?: number;
};

export const useAggregatedHoldingForm = ({ holdingId }: Params) => {
  const [form] = Form.useForm<AddAggregatedHoldingFormValues>();

  const { data: holding, isFetching } = useHoldingShow(
    { id: holdingId?.toString() },
    {
      queryConfig: { enabled: Boolean(holdingId) },
    }
  );

  // if initial data, load to form
  useEffect(() => {
    if (holding) {
      form.setFieldsValue({
        type: getCodebookCode(holding.asset?.type),
        quantity: holding.quantity,
      });
    }
  }, [holding]);

  return { form, isFetching, holding };
};
