import React, { useMemo } from 'react';

import { Select, useSelectAsync } from '@npm/core/ui/components/atoms/Select';
import {
  type UserRoleAggregate,
  type UserRoleApiUserRoleIndexRequest,
  type UserRoleIndex,
  useUserRoleIndex,
} from '@npm/data-access';
import { uniqBy } from 'lodash';

import { USER_ROLE_INDEX_BASE_PARAMS } from '../../../auth/user/context';
import { renderAccountOption, SelectedAccount } from '../components';

type Props = {
  onSelect?: (val?: string | number) => void;
  onClear?: (val?: string | number) => void;
  onChange?: (val: string | number) => void;
  onItemChange?: (val?: UserRoleAggregate) => void;
  value?: string | number;
  defaultUserRole?: UserRoleAggregate;
  disabled?: boolean;
  filterOptions?: (
    value: UserRoleAggregate,
    index: number,
    array: UserRoleAggregate[]
  ) => boolean;
  onFetchComplete?: (
    data: UserRoleIndex,
    variables: UserRoleApiUserRoleIndexRequest
  ) => void;
};

export const UserRoleSearch = ({
  onItemChange,
  onChange,
  defaultUserRole,
  onFetchComplete,
  disabled,
  value,
  filterOptions,
  ...rest
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();

  const variables: UserRoleApiUserRoleIndexRequest = {
    ...USER_ROLE_INDEX_BASE_PARAMS,
    ...(searchTerm ? { search: searchTerm } : {}),
  };

  const { data, isLoading, error } = useUserRoleIndex(variables, {
    onError: Select.onError,
    onComplete: data => onFetchComplete?.(data, variables),
  });

  const options = useMemo(() => {
    if (!data && !defaultUserRole) return null;

    const filteredUserRoles = filterOptions
      ? data?.user_roles?.filter(filterOptions)
      : data?.user_roles;

    const userRoles =
      defaultUserRole && !searchTerm
        ? [defaultUserRole, ...(filteredUserRoles ?? [])]
        : (filteredUserRoles ?? []);

    return uniqBy(
      userRoles.map(userRole => ({
        label: (
          <SelectedAccount
            key={userRole.subject?.id}
            id={userRole.subject?.external_id}
            accountName={userRole.subject?.name}
            brokerageFirmName={userRole.subject?.brokerage_firm_name}
          />
        ),
        value: userRole.subject?.id,
        item: userRole,
      })),
      'value'
    );
  }, [data, defaultUserRole, searchTerm, value]);

  const getItem = (id: number) =>
    data?.user_roles?.find(v => v?.subject.id === id);

  return (
    <Select
      variant="search"
      placeholder="Search By ID or Name"
      loading={isLoading}
      error={error}
      onChange={v => {
        onChange(v);
        onItemChange?.(getItem(v));
      }}
      disabled={disabled}
      value={value}
      {...rest}
      {...selectAsyncProps}
    >
      {options?.map(option =>
        renderAccountOption({
          ...option,
          account: option.item.subject,
        })
      )}
    </Select>
  );
};
