import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { usePaginationQueryParam } from '@npm/core/ui/hooks/pagination/usePagination';
import { getPaginationQueryKey } from '@npm/core/ui/hooks/pagination/usePagination.utils';
import { isEqual, omit } from 'lodash';
import { useQueryParams } from 'use-query-params';

import {
  type FilterOptions,
  type FilterPanelProps,
  type FilterPanelReturnType,
  type FilterQueryKey,
  type FiltersObject,
} from '../FilterPanel.types';
import { decodeFilters, encodeFilters } from '../FilterPanel.utils';

import { calculateActiveFilters } from './activeFilterCalculator';

/**
 * Storage for filters, it allows to persist filters in query params or in memory as state.
 * Hooks cannot be called conditionally so we always call both cases and return only one.
 */
export const useFiltersStorage = <T>(
  key: FilterQueryKey,
  options?: FilterOptions<T>
) => {
  const paramConfigMap = useMemo(
    () => ({
      [key]: {
        encode: options?.customEncode ?? encodeFilters<T>,
        decode: options?.customDecode ?? decodeFilters<T>,
      },
    }),
    [key, options]
  );

  const [persistedQuery, setPersistedQuery] = useQueryParams(paramConfigMap);
  const [tmpQuery, setTmpQuery] = useState({});

  const resetPersistedQuery = useCallback(
    () => setPersistedQuery({ [key]: undefined }, 'replaceIn'),
    [key, setPersistedQuery]
  );

  const resetTmpQuery = useCallback(() => setTmpQuery({}), [setTmpQuery]);

  if (options?.persistToQuery === false) {
    return {
      query: tmpQuery,
      setQuery: setTmpQuery,
      resetQuery: resetTmpQuery,
    };
  } else {
    return {
      query: persistedQuery,
      setQuery: setPersistedQuery,
      resetQuery: resetPersistedQuery,
    };
  }
};

/**
 * Hook for managing persisted filters
 * Uses variables and filter values
 * variables - contains static query params, pagination and filter values
 * filter values - contains only values used for filtering
 * @param key - prefix for query params
 * @param baseVariables - initial variables
 * @param options - custom encode/decode functions
 */
export const usePersistedFilters = <T extends FiltersObject>(
  key: FilterQueryKey,
  baseVariables?: T,
  options?: FilterOptions<T>
): [FilterPanelReturnType<T>, FilterPanelProps<T>] => {
  const { resetQueryOnUnmount } = { resetQueryOnUnmount: true, ...options };

  const { query, setQuery, resetQuery } = useFiltersStorage(key, options);

  const { resetQuery: resetPaginationQuery } = usePaginationQueryParam(
    getPaginationQueryKey(window.location.href)
  );

  const savedFiltersInQuery = query[key];

  const [variables, setVariables] = useState<T>({
    ...baseVariables,
    ...savedFiltersInQuery,
  });

  const totalActiveFilters = calculateActiveFilters(
    options?.customizeFilterCount ?? {},
    savedFiltersInQuery ?? {}
  );

  // reset query on dismount
  useEffect(() => {
    return () => {
      if (resetQueryOnUnmount) {
        resetQuery();
      }
    };
  }, []);

  // reset pagination if baseVariables changes
  const prevBaseVariables = useRef(baseVariables);
  useEffect(() => {
    // page and size has to be ignored because we want to reset filter only if main variables changes (as issuerId, companyId, ...)
    if (
      !isEqual(
        omit(prevBaseVariables.current, 'page', 'size'),
        omit(baseVariables, 'page', 'size')
      )
    ) {
      // In case base variables gets changed (e.g. accountId, companyId, ...), we want to reset pagination and merge new variables with saved filters (from query),
      mergeUserFilterWithVariables({
        ...baseVariables,
        ...savedFiltersInQuery,
      });
      prevBaseVariables.current = baseVariables;
    }
  }, [baseVariables]);

  const saveUserFilterToQuery = (values: Partial<T>) => {
    // We set values specified by user and reset pagination.
    setQuery(
      { [key]: { ...savedFiltersInQuery, ...values, page: undefined } },
      'replaceIn'
    );
  };

  const mergeUserFilterWithVariables = (values: Partial<T>) => {
    // We merge values with variables and reset pagination.
    // The order matters - first reset pagination query, then set variables.
    resetPaginationQuery();
    setVariables(currentValue => ({
      ...currentValue,
      ...values,
      page: 1,
    }));
  };

  const handleSubmit = (values?: Partial<T>) => {
    if (values === undefined) {
      // This is a reset operation, we want to reset query and variables to initial state.
      resetQuery();
      setVariables(baseVariables);
      return;
    }

    const modifiedValues = options?.modifyOnSubmit?.(values) ?? values;
    saveUserFilterToQuery(modifiedValues);
    mergeUserFilterWithVariables(modifiedValues);
  };

  /**
   * How filter works?
   *
   * We pass the initial data to it. Initial data are not saved inside query.
   * Once user does  any action, this data overrides the initial data, this is also saved to query for future reference.
   * Default data are not saved to query.
   *
   * If filter is cleared (set to undefined), we remove it from query as well as from variables.
   *
   * Initial data = lot of stuff passed by default
   * Variables = merged data with initial data
   * Query = user's filters which differ from initial data
   *
   * handleSubmit - called with only data changing
   * setVariables - called with all data
   */

  return [
    {
      variables,
      setVariables,
      isFilterApplied: totalActiveFilters > 0,
      totalActiveFilters,
    },
    {
      handleSubmit,
      // Allow forms to populate filters on mount based on query params.
      initialFilterValues: savedFiltersInQuery,
      // Duplicated return to easily pass to filter components
      variables,
      totalActiveFilters,
    },
  ];
};

/**
 * Non-peristent version of filters. It uses constant as a key as it doesn't persist to query.
 * Option to persist to query is disabled automatically when called this way.
 */
export const useFilters = <T>(
  baseVariables?: T,
  options?: FilterOptions<T>
): [FilterPanelReturnType<T>, FilterPanelProps<T>] => {
  return usePersistedFilters(
    'non-persistent-filter' as FilterQueryKey,
    baseVariables,
    { ...options, persistToQuery: false }
  );
};

/**
 * Simple utility to allow for clearing filter by key.
 */
export const useResetFilterByKey = (key: FilterQueryKey) => {
  const [_, setQuery] = useQueryParams({});

  return { resetQuery: () => setQuery({ [key]: undefined }, 'replaceIn') };
};
