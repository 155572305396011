import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbBackgroundCheckState, CbOnboardingStatus } from '@npm/data-access';

import {
  type GetSectionsConfigFn,
  type GetWizardStepsArgs,
  type OnboardingSectionConfig,
  parentStepKeyToTitleMap,
  PERSONA_IDENTITY_VERIFICATION_STEP_KEY,
  sectionKeyToTitleMap,
  type StepKey,
} from './PostOnboarding.types';
import { filterOnboardingSectionsConfig } from './PostOnboarding.utils';
import {
  AuthorizedSignersPostOnboarding,
  BankAccountInformationPostOnboarding,
  BuyerSurveyPostOnboarding,
  GovernmentIDPostOnboarding,
  IndividualAccreditationPostOnboarding,
  IndividualInformationPostOnboarding,
  IndividualTaxIdPostOnboarding,
  MarketplaceAgreementPostOnboarding,
  PayingAgentAgreementPostOnboarding,
  PersonaVerification,
  PersonaVerificationFailed,
  SpousalInformationPostOnboarding,
  TaxInformationPostOnboarding,
} from './steps';

const identityVerificationSectionTooltip =
  'You will be required to provide a US Tax ID and a valid, unexpired government issued photo ID. This information is used to populate transaction documentation and satisfy any applicable regulatory requirements. If you do not have a US Tax ID, please check the checkbox next to "I do not have a US Tax ID.';

const getIdentityVerificationSteps = (
  personId: number,
  canEditSpouse: boolean
) => {
  const personalInformation = {
    key: 'personal_information_state',
    title: CbOnboardingStatus.titleMap.personal_information_state,
    component: stepProps => (
      <IndividualInformationPostOnboarding
        stepProps={stepProps}
        personId={personId}
      />
    ),
    isEditable: true,
  } as const;

  const taxInformation = {
    key: 'tax_information_state',
    title: CbOnboardingStatus.titleMap.tax_information_state,
    component: stepProps => (
      <TaxInformationPostOnboarding
        stepProps={stepProps}
        zendeskTicketGroupId="post_onboarding_individual_tax_information_state"
      />
    ),
  } as const;

  const representativePhoto = {
    key: 'representative_photo_state',
    title: CbOnboardingStatus.titleMap.representative_photo_state,
    component: stepProps => (
      <GovernmentIDPostOnboarding
        zendeskTicketGroupId="post_onboarding_individual_government_id"
        stepProps={stepProps}
      />
    ),
  } as const;

  const individualTaxId = {
    key: 'individual_tax_state',
    title: CbOnboardingStatus.titleMap.individual_tax_state,
    component: stepProps => (
      <IndividualTaxIdPostOnboarding
        zendeskTicketGroupId="post_onboarding_individual_tax_id"
        stepProps={stepProps}
        personId={personId}
      />
    ),
  } as const;

  const authorizedSigner = {
    key: 'authorized_signer_state',
    title: CbOnboardingStatus.titleMap.authorized_signer_state,
    component: stepProps => (
      <AuthorizedSignersPostOnboarding stepProps={stepProps} />
    ),
    isEditable: true,
  } as const;

  const spouseInformation = {
    key: 'spouse_information_state',
    title: CbOnboardingStatus.titleMap.spouse_information_state,
    component: stepProps => (
      <SpousalInformationPostOnboarding stepProps={stepProps} />
    ),
    isEditable: canEditSpouse,
  } as const;

  return {
    personalInformation,
    taxInformation,
    representativePhoto,
    individualTaxId,
    authorizedSigner,
    spouseInformation,
  };
};

const getOtherSteps = (personId: number) => {
  const accreditation = {
    key: 'accredited_investor_state',
    title: CbOnboardingStatus.titleMap.accredited_investor_state,
    titleTooltip: (
      <>
        Investors in private company securities must be, at minimum, an
        accredited investor within the meaning of Rule 501 of Regulation D.
        Please complete this step to establish this account’s status as an
        accredited investor. <br /> <br />
        Note: This step is not required if you are participating in a
        company-sponsored tender offer.
      </>
    ),
    component: stepProps => (
      <IndividualAccreditationPostOnboarding
        zendeskTicketGroupId="post_onboarding_individual_accreditation"
        stepProps={stepProps}
        personId={personId}
      />
    ),
  } as const;

  const buyerSurvey = {
    key: 'buyer_survey_state',
    title: CbOnboardingStatus.titleMap.buyer_survey_state,
    titleTooltip: (
      <>
        Completing the Buyer Survey helps us understand your financial
        experience and objectives, and is a required step to receive investment
        opportunities from us.
        <br />
        <br />
        Note: This survey is not required if you are participating in company
        sponsored tender offer.
      </>
    ),
    component: stepProps => (
      <BuyerSurveyPostOnboarding stepProps={stepProps} variant="individual" />
    ),
    isEditable: true,
  } as const;

  const bankInformation = {
    key: 'bank_account_state',
    title: CbOnboardingStatus.titleMap.bank_account_state,
    component: stepProps => (
      <BankAccountInformationPostOnboarding stepProps={stepProps} />
    ),
    isEditable: true,
  } as const;

  const marketplaceAgreement = {
    key: 'marketplace_agreement_state',
    title: CbOnboardingStatus.titleMap.marketplace_agreement_state,
    component: stepProps => (
      <MarketplaceAgreementPostOnboarding
        zendeskTicketGroupId="post_onboarding_individual_marketplace_agreement"
        stepProps={stepProps}
      />
    ),
  } as const;

  const payingAgentAgreement = {
    key: 'paying_agent_agreement_state',
    title: CbOnboardingStatus.titleMap.paying_agent_agreement_state,
    component: stepProps => (
      <PayingAgentAgreementPostOnboarding
        zendeskTicketGroupId="post_onboarding_individual_paying_agent_agreement"
        stepProps={stepProps}
      />
    ),
  } as const;

  return {
    accreditation,
    buyerSurvey,
    bankInformation,
    marketplaceAgreement,
    payingAgentAgreement,
  };
};

export const personaRequiredSteps: StepKey[] = [
  'personal_information_state',
  'tax_information_state',
  'representative_photo_state',
  'spouse_information_state',
];

export const getNPMSIndividualOnboardingSections: GetSectionsConfigFn =
  props => {
    const {
      onboardingStatus,
      npmsStepsArgs,
      personId,
      canEditSpouse,
      backgroundCheckStatus,
    } = props;
    const { isRequiredForMarketplaceSectionCompleted, isIndividualSellerOnly } =
      npmsStepsArgs || {};

    const {
      personalInformation,
      taxInformation,
      representativePhoto,
      individualTaxId,
      authorizedSigner,
      spouseInformation,
    } = getIdentityVerificationSteps(personId, canEditSpouse);

    const {
      accreditation,
      buyerSurvey,
      bankInformation,
      marketplaceAgreement,
      payingAgentAgreement,
    } = getOtherSteps(personId);

    const sellerAndBuyerOtherRequirements = [
      bankInformation,
      authorizedSigner,
      payingAgentAgreement,
    ];

    const sellerOtherRequirements = [
      accreditation,
      buyerSurvey,
      bankInformation,
      authorizedSigner,
      payingAgentAgreement,
    ];

    const isBackgroundCheckFailed =
      backgroundCheckStatus === CbBackgroundCheckState.items.failed ||
      backgroundCheckStatus === CbBackgroundCheckState.items.marked_for_review;
    const isBackgroundCheckPassed =
      backgroundCheckStatus === CbBackgroundCheckState.items.passed;
    const isBackgroundCheckComplete =
      isBackgroundCheckPassed || isBackgroundCheckFailed;

    const areAllPersonaStepsFilledIn = personaRequiredSteps.every(
      step => onboardingStatus[step].code === CbOnboardingStatus.items.completed
    );

    const isPersonaFlowCompleted =
      areAllPersonaStepsFilledIn && isBackgroundCheckComplete;

    const isPersonaFlowInProgress =
      !isPersonaFlowCompleted &&
      personaRequiredSteps.some(
        step =>
          onboardingStatus[step].code === CbOnboardingStatus.items.completed
      );

    const extraContent =
      isBackgroundCheckFailed || isPersonaFlowInProgress ? (
        <Alert
          type={
            backgroundCheckStatus === CbBackgroundCheckState.items.failed
              ? 'error'
              : 'warning'
          }
          icon={
            <Icon
              name={
                backgroundCheckStatus === CbBackgroundCheckState.items.failed
                  ? 'circle-x'
                  : 'warning'
              }
            />
          }
          message={
            backgroundCheckStatus === CbBackgroundCheckState.items.failed
              ? 'Identity Verification Failed'
              : 'Few steps remaining to complete verification'
          }
        />
      ) : null;

    return [
      {
        key: 'required_for_marketplace',
        title: isIndividualSellerOnly
          ? 'Required to Sell Your Shares'
          : sectionKeyToTitleMap.required_for_marketplace,
        subTitle: isIndividualSellerOnly
          ? 'We need to verify your identity before you can sell your shares directly in the market'
          : 'We need to verify your identity before you can place orders that will be visible to buyers and sellers on the Marketplace',
        v2SectionProps: {
          isMainSection: true,
          durationInMin: isIndividualSellerOnly ? 5 : 8,
        },
        extraContent,
        items: [
          ...(isIndividualSellerOnly ? [] : [accreditation, buyerSurvey]),
          {
            key: PERSONA_IDENTITY_VERIFICATION_STEP_KEY,
            title: parentStepKeyToTitleMap.persona_identity_verification,
            tooltip: identityVerificationSectionTooltip,
            component: isPersonaFlowCompleted
              ? null
              : stepProps =>
                  isBackgroundCheckFailed ? (
                    <PersonaVerificationFailed
                      stepProps={stepProps}
                      variant={
                        backgroundCheckStatus as
                          | typeof CbBackgroundCheckState.items.failed
                          | typeof CbBackgroundCheckState.items.marked_for_review
                      }
                    />
                  ) : (
                    <PersonaVerification
                      stepProps={stepProps}
                      variant={
                        isPersonaFlowInProgress ? 'in-progress' : 'not-started'
                      }
                    />
                  ),
            substeps: isPersonaFlowCompleted
              ? [
                  personalInformation,
                  taxInformation,
                  representativePhoto,
                  individualTaxId,
                  spouseInformation,
                ]
              : null,
          },
          marketplaceAgreement,
        ],
      },
      {
        key: 'other_requirements',
        title: 'Additional Requirements',
        v2SectionProps: {
          isMainSection: false,
          hidden: !isRequiredForMarketplaceSectionCompleted,
        },
        items: isIndividualSellerOnly
          ? sellerOtherRequirements
          : sellerAndBuyerOtherRequirements,
      },
    ];
  };

const getNonPersonaAMLKYCSection = ({
  personId,
  canEditSpouse,
}: GetWizardStepsArgs): OnboardingSectionConfig => {
  const {
    personalInformation,
    taxInformation,
    representativePhoto,
    individualTaxId,
    authorizedSigner,
    spouseInformation,
  } = getIdentityVerificationSteps(personId, canEditSpouse);

  return {
    key: 'aml_kyc',
    title: sectionKeyToTitleMap.aml_kyc,
    tooltip:
      'In order to help the government fight the funding of terrorism and money laundering activities, U.S. federal law requires all financial institutions to obtain, verify, and record information that identifies each person or entity with whom we conduct securities transactions.',
    showProgress: true,
    items: [
      {
        key: 'personal_information',
        title: parentStepKeyToTitleMap.personal_information,
        component: () => null,
        tooltip:
          'This information is collected to populate agreements and confirm your identity to keep you and NPM safe.',
        substeps: [personalInformation, taxInformation, spouseInformation],
      },
      {
        key: 'identity_verification',
        title: parentStepKeyToTitleMap.identity_verification,
        component: () => null,
        tooltip: identityVerificationSectionTooltip,
        substeps: [individualTaxId, representativePhoto],
      },
      authorizedSigner,
    ],
  };
};

export const getNonNPMSIndividualOnboardingSections: GetSectionsConfigFn =
  props => {
    const { personId } = props;

    const {
      marketplaceAgreement,
      payingAgentAgreement,
      accreditation,
      bankInformation,
    } = getOtherSteps(personId);

    return [
      getNonPersonaAMLKYCSection(props),
      {
        key: 'agreements',
        title: sectionKeyToTitleMap.agreements,
        tooltip: 'Legal agreements required for broker-dealer services.',
        items: [marketplaceAgreement, payingAgentAgreement],
      },
      {
        key: 'other_requirements',
        title: sectionKeyToTitleMap.other_requirements,
        tooltip:
          "Required information to complete the settlement process and to evaluate the individual's level of financial sophistication.",
        items: [accreditation, bankInformation],
      },
    ];
  };

export const getIndividualOnboardingSections: GetSectionsConfigFn = props => {
  const { variant, onboardingStatus } = props;
  const isNPMS = variant === 'npms';

  const config = isNPMS
    ? getNPMSIndividualOnboardingSections(props)
    : getNonNPMSIndividualOnboardingSections(props);

  return filterOnboardingSectionsConfig(config, onboardingStatus);
};
