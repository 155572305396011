// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SupplyStatsApi,
  SupplyStatsAggregatedAccountIndexErrorCodes,
  SupplyStatsAggregatedAccountIndexErrorTypes,
  SupplyStatsAggregatedIssuerIndexErrorCodes,
  SupplyStatsAggregatedIssuerIndexErrorTypes,
  TimelineHoldingsIndexErrorCodes,
  TimelineHoldingsIndexErrorTypes,
  TimelineAccountsIndexErrorCodes,
  TimelineAccountsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SupplyStatsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type SupplyStatsAggregatedAccountIndexMethod = SupplyStatsApi['supplyStatsAggregatedAccountIndex'];
export type SupplyStatsAggregatedAccountIndexResponseType = MethodResult<SupplyStatsAggregatedAccountIndexMethod>;

export const useSupplyStatsAggregatedAccountIndex = (
  variables: Parameters<SupplyStatsAggregatedAccountIndexMethod>[0],
  config?: UseQueryConfig<
    SupplyStatsAggregatedAccountIndexMethod,
    SupplyStatsAggregatedAccountIndexErrorTypes
  >
) => {
  return useQuery<
    SupplyStatsAggregatedAccountIndexMethod,
    SupplyStatsAggregatedAccountIndexErrorTypes
  >(
    (...args) => api.supplyStatsAggregatedAccountIndex(...args),
    SupplyStatsAggregatedAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SupplyStatsAggregatedAccountIndex'],
        ...apiQueryConfig.SupplyStatsAggregatedAccountIndex,
        ...config?.queryConfig
      },
    },
    'SupplyStatsAggregatedAccountIndex',
  );
};

export const useSupplyStatsAggregatedAccountIndexInfinite = (
  variables: Parameters<SupplyStatsAggregatedAccountIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SupplyStatsAggregatedAccountIndexMethod,
    SupplyStatsAggregatedAccountIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SupplyStatsAggregatedAccountIndexMethod,
    SupplyStatsAggregatedAccountIndexErrorTypes
  >(
    (...args) => api.supplyStatsAggregatedAccountIndex(...args),
    SupplyStatsAggregatedAccountIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SupplyStatsAggregatedAccountIndex', 'SupplyStatsAggregatedAccountIndexInfinite'],
        ...apiQueryConfig.SupplyStatsAggregatedAccountIndex,
        ...config?.queryConfig,
      },
    },
    'SupplyStatsAggregatedAccountIndex',
  );
};

export const useSupplyStatsAggregatedAccountIndexLazy = (baseOptions?: {
  variables?: Parameters<SupplyStatsAggregatedAccountIndexMethod>[0],
  config?: UseQueryConfig<
    SupplyStatsAggregatedAccountIndexMethod,
    SupplyStatsAggregatedAccountIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SupplyStatsAggregatedAccountIndexMethod,
    SupplyStatsAggregatedAccountIndexErrorTypes
  >(
    (...args) => api.supplyStatsAggregatedAccountIndex(...args),
    SupplyStatsAggregatedAccountIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SupplyStatsAggregatedAccountIndex'],
        ...apiQueryConfig.SupplyStatsAggregatedAccountIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SupplyStatsAggregatedAccountIndex',
  );
};


export type SupplyStatsAggregatedIssuerIndexMethod = SupplyStatsApi['supplyStatsAggregatedIssuerIndex'];
export type SupplyStatsAggregatedIssuerIndexResponseType = MethodResult<SupplyStatsAggregatedIssuerIndexMethod>;

export const useSupplyStatsAggregatedIssuerIndex = (
  variables: Parameters<SupplyStatsAggregatedIssuerIndexMethod>[0],
  config?: UseQueryConfig<
    SupplyStatsAggregatedIssuerIndexMethod,
    SupplyStatsAggregatedIssuerIndexErrorTypes
  >
) => {
  return useQuery<
    SupplyStatsAggregatedIssuerIndexMethod,
    SupplyStatsAggregatedIssuerIndexErrorTypes
  >(
    (...args) => api.supplyStatsAggregatedIssuerIndex(...args),
    SupplyStatsAggregatedIssuerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SupplyStatsAggregatedIssuerIndex'],
        ...apiQueryConfig.SupplyStatsAggregatedIssuerIndex,
        ...config?.queryConfig
      },
    },
    'SupplyStatsAggregatedIssuerIndex',
  );
};

export const useSupplyStatsAggregatedIssuerIndexInfinite = (
  variables: Parameters<SupplyStatsAggregatedIssuerIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    SupplyStatsAggregatedIssuerIndexMethod,
    SupplyStatsAggregatedIssuerIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    SupplyStatsAggregatedIssuerIndexMethod,
    SupplyStatsAggregatedIssuerIndexErrorTypes
  >(
    (...args) => api.supplyStatsAggregatedIssuerIndex(...args),
    SupplyStatsAggregatedIssuerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SupplyStatsAggregatedIssuerIndex', 'SupplyStatsAggregatedIssuerIndexInfinite'],
        ...apiQueryConfig.SupplyStatsAggregatedIssuerIndex,
        ...config?.queryConfig,
      },
    },
    'SupplyStatsAggregatedIssuerIndex',
  );
};

export const useSupplyStatsAggregatedIssuerIndexLazy = (baseOptions?: {
  variables?: Parameters<SupplyStatsAggregatedIssuerIndexMethod>[0],
  config?: UseQueryConfig<
    SupplyStatsAggregatedIssuerIndexMethod,
    SupplyStatsAggregatedIssuerIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    SupplyStatsAggregatedIssuerIndexMethod,
    SupplyStatsAggregatedIssuerIndexErrorTypes
  >(
    (...args) => api.supplyStatsAggregatedIssuerIndex(...args),
    SupplyStatsAggregatedIssuerIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SupplyStatsAggregatedIssuerIndex'],
        ...apiQueryConfig.SupplyStatsAggregatedIssuerIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SupplyStatsAggregatedIssuerIndex',
  );
};


export type TimelineHoldingsIndexMethod = SupplyStatsApi['timelineHoldingsIndex'];
export type TimelineHoldingsIndexResponseType = MethodResult<TimelineHoldingsIndexMethod>;

export const useTimelineHoldingsIndex = (
  variables: Parameters<TimelineHoldingsIndexMethod>[0],
  config?: UseQueryConfig<
    TimelineHoldingsIndexMethod,
    TimelineHoldingsIndexErrorTypes
  >
) => {
  return useQuery<
    TimelineHoldingsIndexMethod,
    TimelineHoldingsIndexErrorTypes
  >(
    (...args) => api.timelineHoldingsIndex(...args),
    TimelineHoldingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TimelineHoldingsIndex'],
        ...apiQueryConfig.TimelineHoldingsIndex,
        ...config?.queryConfig
      },
    },
    'TimelineHoldingsIndex',
  );
};

export const useTimelineHoldingsIndexInfinite = (
  variables: Parameters<TimelineHoldingsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    TimelineHoldingsIndexMethod,
    TimelineHoldingsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    TimelineHoldingsIndexMethod,
    TimelineHoldingsIndexErrorTypes
  >(
    (...args) => api.timelineHoldingsIndex(...args),
    TimelineHoldingsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TimelineHoldingsIndex', 'TimelineHoldingsIndexInfinite'],
        ...apiQueryConfig.TimelineHoldingsIndex,
        ...config?.queryConfig,
      },
    },
    'TimelineHoldingsIndex',
  );
};

export const useTimelineHoldingsIndexLazy = (baseOptions?: {
  variables?: Parameters<TimelineHoldingsIndexMethod>[0],
  config?: UseQueryConfig<
    TimelineHoldingsIndexMethod,
    TimelineHoldingsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    TimelineHoldingsIndexMethod,
    TimelineHoldingsIndexErrorTypes
  >(
    (...args) => api.timelineHoldingsIndex(...args),
    TimelineHoldingsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TimelineHoldingsIndex'],
        ...apiQueryConfig.TimelineHoldingsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TimelineHoldingsIndex',
  );
};


export type TimelineAccountsIndexMethod = SupplyStatsApi['timelineAccountsIndex'];
export type TimelineAccountsIndexResponseType = MethodResult<TimelineAccountsIndexMethod>;

export const useTimelineAccountsIndex = (
  variables: Parameters<TimelineAccountsIndexMethod>[0],
  config?: UseQueryConfig<
    TimelineAccountsIndexMethod,
    TimelineAccountsIndexErrorTypes
  >
) => {
  return useQuery<
    TimelineAccountsIndexMethod,
    TimelineAccountsIndexErrorTypes
  >(
    (...args) => api.timelineAccountsIndex(...args),
    TimelineAccountsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TimelineAccountsIndex'],
        ...apiQueryConfig.TimelineAccountsIndex,
        ...config?.queryConfig
      },
    },
    'TimelineAccountsIndex',
  );
};

export const useTimelineAccountsIndexInfinite = (
  variables: Parameters<TimelineAccountsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    TimelineAccountsIndexMethod,
    TimelineAccountsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    TimelineAccountsIndexMethod,
    TimelineAccountsIndexErrorTypes
  >(
    (...args) => api.timelineAccountsIndex(...args),
    TimelineAccountsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TimelineAccountsIndex', 'TimelineAccountsIndexInfinite'],
        ...apiQueryConfig.TimelineAccountsIndex,
        ...config?.queryConfig,
      },
    },
    'TimelineAccountsIndex',
  );
};

export const useTimelineAccountsIndexLazy = (baseOptions?: {
  variables?: Parameters<TimelineAccountsIndexMethod>[0],
  config?: UseQueryConfig<
    TimelineAccountsIndexMethod,
    TimelineAccountsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    TimelineAccountsIndexMethod,
    TimelineAccountsIndexErrorTypes
  >(
    (...args) => api.timelineAccountsIndex(...args),
    TimelineAccountsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TimelineAccountsIndex'],
        ...apiQueryConfig.TimelineAccountsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TimelineAccountsIndex',
  );
};

