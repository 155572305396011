import { type VenusApi } from '@npm/data-access';
import Big from 'big.js';

import { type PrimaryValuation } from '../../PrimaryRounds.types';

export const sortPrimaryRoundsData = (
  data: VenusApi.PrimaryOcfValuationShow[] | undefined,
  order: 'asc' | 'desc' = 'asc'
) => {
  if (data == null) {
    return [];
  }

  return [...data].sort((a, b) => {
    if (order === 'asc') {
      return new Date(a.investment_date) < new Date(b.investment_date) ? -1 : 1;
    }
    return new Date(a.investment_date) > new Date(b.investment_date) ? -1 : 1;
  });
};

export const transformPrimaryRoundsForTable = (
  items: VenusApi.PrimaryOcfValuationShow[]
): PrimaryValuation[] => {
  const sortedItems = sortPrimaryRoundsData(items, 'desc');
  return sortedItems.map((primaryRound, i) => {
    if (i === sortedItems.length - 1) {
      return { ...primaryRound, valuationChange: undefined };
    }

    const currentValuation = new Big(primaryRound.valuation);
    const previousValuation = new Big(sortedItems[i + 1].valuation);

    const valuationChange = currentValuation
      .sub(previousValuation)
      .div(previousValuation)
      .mul(100)
      .toNumber();

    return { ...primaryRound, valuationChange };
  });
};
