import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  type EventAggregate,
  type Holding,
  type SubmissionItemsInner,
} from '@npm/data-access';

import { getEventInfo } from '../../program/program.utils';
import {
  getIsHoldingEligible,
  getIsOrderHoldingExercisable,
} from '../Holdings.utils';

type Props = {
  holding: Holding;
  event: EventAggregate;
  orders: SubmissionItemsInner[];
  onHoldingClick?: (holding: Holding) => void;
};

export const OfferButton = ({
  event,
  holding,
  orders,
  onHoldingClick,
}: Props) => {
  const isIneligible = !getIsHoldingEligible(holding);
  const isAllOrNone = event.group?.all_or_none;

  const { isSellSchedule } = getEventInfo(event);
  const isEditable = !!orders[0] && !isSellSchedule;

  // NOTE: Edit is handled in order itself,
  // so render just empty div taking up space so rest of the columns are aligned
  if (isEditable) return <div style={{ width: 80 }} />;

  const isExercisable = getIsOrderHoldingExercisable(holding, event);

  // so much to bypass three if-checks
  const text = ['Ineligible', 'Offer All or None', 'Exercise', 'Offer'].find(
    (_, idx) => [isIneligible, isAllOrNone, isExercisable, true][idx]
  );

  return (
    <Button
      onClick={() => onHoldingClick?.(holding)}
      disabled={isIneligible || isAllOrNone}
      icon={
        isIneligible || isAllOrNone ? <Icon name="lock" size="sm" /> : undefined
      }
    >
      {text}
    </Button>
  );
};
