import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

import { BaseInfo } from '../../../../company/CompanyOverview/General/DescriptionWithBaseInfo/components';

export const TwoColumnBaseInfo = styled(BaseInfo)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.xxl / 2}px;
  padding: ${({ theme }) => theme.spacing.xxl / 2}px 0;

  ${({ theme }) => useBreakpoint(theme).mobile`
      gap: ${theme.spacing.xl / 2}px;
      padding: ${theme.spacing.xl / 2}px 0;
  `}

  & > div {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: unset;
    margin-bottom: unset;
    width: unset;

    &:not(:last-child) {
      margin-right: unset;
    }
  }
`;
