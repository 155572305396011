import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import styled from 'styled-components';

const LoaderBase = styled(Skeleton.Base).attrs({
  active: true,
  paragraph: false,
})`
  margin: 0;
`;

export const ChartHeaderLeftLoader = styled(LoaderBase)`
  width: 35%;
  & .ant-skeleton-title {
    height: 24px;
  }
`;

export const ChartHeaderRightLoader = styled(LoaderBase)`
  width: 25%;
  & .ant-skeleton-title {
    height: 24px;
  }
`;

export const ChartLoaderWrapper = styled.div`
  position: relative;
`;

export const ChartLoader = styled(Skeleton.Base)`
  & .ant-skeleton-title {
    height: 360px;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) => theme.color.general.backgrounds.muted};
`;

export const LegendAvatarLoader = styled(Skeleton.Base)`
  & .ant-skeleton-title {
    height: 16px;
    width: 16px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  }
`;

export const LegendTextLoader = styled(Skeleton.Base)`
  & .ant-skeleton-title {
    height: 16px;
    width: 128px;
    border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  }
`;
