import { useState } from 'react';

import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { Flex } from '../../atoms/common';
import { Select } from '../../atoms/Select';
import { Heading, Text } from '../../atoms/Typography';

import { EducationalModalStyles as S } from './EducationalModal.styles';
import { type EducationalModalProps } from './EducationalModal.types';

export const EducationalModal = ({
  isOpen,
  onClose,
  config,
}: EducationalModalProps) => {
  if (!config.items.length) {
    throw new Error('EducationalModal: No items provided');
  }

  const { isMobile, isSmTablet } = useBreakpoints();

  const isSmallResolutionVariant = isMobile || isSmTablet;

  const [selectedItemId, setSelectedItemId] = useState(config.items[0]?.id);

  const selectedItem = config.items.find(item => item.id === selectedItemId);

  if (!selectedItem) {
    return null;
  }

  const variant = config.variant ?? 'default';

  const displayContent =
    isSmallResolutionVariant && selectedItem.smallScreenContent
      ? selectedItem.smallScreenContent
      : selectedItem.content;

  return (
    <S.Modal
      open={isOpen}
      size={isSmallResolutionVariant ? 'sm' : 'lg'}
      onCancel={onClose}
      okText={null}
    >
      <S.Header>
        <Heading variant={'h2'}>{config.title}</Heading>
        <Text size={'sm'} colorVariant={'secondary'}>
          {config.subTitle}
        </Text>
      </S.Header>

      <S.Body>
        {isSmallResolutionVariant ? (
          <Select
            variant={'select'}
            allowClear={false}
            showSearch={false}
            value={selectedItemId}
            onChange={setSelectedItemId}
          >
            {config.items.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <S.ItemsList>
            {config.items.map(item => (
              <S.Item
                key={item.id}
                onClick={() => setSelectedItemId(item.id)}
                $isSelected={selectedItemId === item.id}
                $variant={variant}
              >
                <div>{item.icon}</div>
                <Flex direction={'column'} gap={'xs'}>
                  <Text size={'sm'} colorVariant={'primary'} weight={'bold'}>
                    {item.title}
                  </Text>
                  {item.subTitle && (
                    <Text size={'xs'} colorVariant={'secondary'}>
                      {item.subTitle}
                    </Text>
                  )}
                </Flex>
              </S.Item>
            ))}
          </S.ItemsList>
        )}

        <S.ContentCard>{displayContent}</S.ContentCard>
      </S.Body>
    </S.Modal>
  );
};
