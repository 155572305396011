// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SUserlessHoldingsApi,
  S2SUserlessListHoldingsErrorCodes,
  S2SUserlessListHoldingsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SUserlessHoldingsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SUserlessListHoldingsMethod = S2SUserlessHoldingsApi['s2SUserlessListHoldings'];
export type S2SUserlessListHoldingsResponseType = MethodResult<S2SUserlessListHoldingsMethod>;

export const useS2SUserlessListHoldings = (
  variables: Parameters<S2SUserlessListHoldingsMethod>[0],
  config?: UseQueryConfig<
    S2SUserlessListHoldingsMethod,
    S2SUserlessListHoldingsErrorTypes
  >
) => {
  return useQuery<
    S2SUserlessListHoldingsMethod,
    S2SUserlessListHoldingsErrorTypes
  >(
    (...args) => api.s2SUserlessListHoldings(...args),
    S2SUserlessListHoldingsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SUserlessListHoldings'],
        ...apiQueryConfig.S2SUserlessListHoldings,
        ...config?.queryConfig
      },
    },
    'S2SUserlessListHoldings',
  );
};

export const useS2SUserlessListHoldingsInfinite = (
  variables: Parameters<S2SUserlessListHoldingsMethod>[0],
  config?: UseInfiniteQueryConfig<
    S2SUserlessListHoldingsMethod,
    S2SUserlessListHoldingsErrorTypes
  >
) => {
  return useInfiniteQuery<
    S2SUserlessListHoldingsMethod,
    S2SUserlessListHoldingsErrorTypes
  >(
    (...args) => api.s2SUserlessListHoldings(...args),
    S2SUserlessListHoldingsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['S2SUserlessListHoldings', 'S2SUserlessListHoldingsInfinite'],
        ...apiQueryConfig.S2SUserlessListHoldings,
        ...config?.queryConfig,
      },
    },
    'S2SUserlessListHoldings',
  );
};

export const useS2SUserlessListHoldingsLazy = (baseOptions?: {
  variables?: Parameters<S2SUserlessListHoldingsMethod>[0],
  config?: UseQueryConfig<
    S2SUserlessListHoldingsMethod,
    S2SUserlessListHoldingsErrorTypes
  >
}) => {
  return useLazyQuery<
    S2SUserlessListHoldingsMethod,
    S2SUserlessListHoldingsErrorTypes
  >(
    (...args) => api.s2SUserlessListHoldings(...args),
    S2SUserlessListHoldingsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['S2SUserlessListHoldings'],
        ...apiQueryConfig.S2SUserlessListHoldings,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'S2SUserlessListHoldings',
  );
};

