import React, { Fragment } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { InfiniteScroll } from '@npm/core/ui/components/atoms/InfiniteScroll';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import {
  type SubmissionApiSecondmarketSubmissionIndexRequest,
  useSecondmarketSubmissionIndexInfinite,
} from '@npm/data-access';

import type { OrdersActionsConfig } from '../useOrdersConfig.hooks';

import { InvestorOrderCard } from './InvestorOrderCard';

type Props = {
  variables: SubmissionApiSecondmarketSubmissionIndexRequest;
  filters?: React.ReactNode;
  hideAdditionalFields?: boolean;
  hideDropdownItems?: boolean;
  showOrderType?: boolean;
} & OrdersActionsConfig;

export const InvestorOrdersCards = ({
  variables,
  filters,
  ...props
}: Props) => {
  const { data, isFetching, fetchNextPage, hasNextPage } =
    useSecondmarketSubmissionIndexInfinite(variables);

  return (
    <Flex direction="column" gap="sm">
      <Heading variant="h2">Order Items</Heading>
      {/* I wrote the exact same component in libs/workstations/investor/src/components/PortfolioAndWatchlistActivity/components/FilterPanel/FilterPanel.tsx and that one works while this one doesn't, forcing max-width to not cause overflow */}
      <Card noContentPadding style={{ overflow: 'hidden', maxWidth: '95vw' }}>
        {filters}
      </Card>
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasNextPage}
        isFetching={isFetching}
        loader={<Card isLoading />}
      >
        {data?.pages?.map(page => (
          <Fragment key={page.pagination.page}>
            {page.submissions.map(submission => (
              <InvestorOrderCard
                key={submission.id}
                submission={submission}
                {...props}
              />
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
    </Flex>
  );
};
