import React, { type ComponentProps, useState } from 'react';

import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal, useModal } from '@npm/core/ui/components/molecules/Modal';
import { SuccessModal } from '@npm/core/ui/components/organisms/SuccessModal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbSubscriptionPlan } from '@npm/data-access';
import { useLocalStorage } from 'usehooks-ts';

import { useZendesk, ZendeskCustomFieldName } from '../../../../app/zendesk';

import * as S from './UnlockPremiumModal.styles';
import { PlanAttributesList } from './PlanAttributesList';
import { PlanButton } from './PlanButton';
import {
  type SubscriptionPlan,
  subscriptionPlans,
} from './UnlockPremiumModal.config';

const LOCAL_STORAGE_PREMIUM_PLAN_REQUESTED_KEY = 'premium_plan_requested';

type Props = {
  openSuccessModal?: () => void;
  companyName?: string;
} & ComponentProps<typeof Modal>;

export const UnlockPremiumModal = ({ companyName, ...modalProps }: Props) => {
  const { isMobile } = useBreakpoints();
  const [selectedPlan, setSelectedPlan] =
    useState<keyof (typeof CbSubscriptionPlan)['items']>('premium');
  const [openSuccessModal, successModalProps] = useModal();
  const { createCustomerRequest, createRequestLoading } = useZendesk();
  const { showAlert } = useAlerts();

  const [premiumPlanRequested, setPremiumPlanRequested] = useLocalStorage(
    LOCAL_STORAGE_PREMIUM_PLAN_REQUESTED_KEY,
    false
  );

  const handleConfirm = async () => {
    if (selectedPlan === CbSubscriptionPlan.items.basic) {
      modalProps.onCancel(null);
    } else {
      const { success, message } = await createCustomerRequest(
        'data_plan_inquiry',
        'I would like to subscribe to the Premium plan',
        {
          [ZendeskCustomFieldName.CompanyName]: companyName,
        }
      );

      if (success) {
        setPremiumPlanRequested(true);
        openSuccessModal();
        modalProps.onCancel(null);
      } else {
        showAlert(message, {
          type: 'error',
        });
      }
    }
  };

  const renderPlanColumn = (plan: SubscriptionPlan) => {
    const { key, attributes, ...rest } = plan;

    return (
      <S.Col key={key}>
        <PlanButton
          onClick={() => setSelectedPlan(key)}
          isSelected={selectedPlan === key}
          isCurrentPlan={key === CbSubscriptionPlan.items.basic}
          {...rest}
        />
        <PlanAttributesList attributes={attributes} color={rest.color} />
      </S.Col>
    );
  };

  return (
    <>
      <Modal
        title="Unlock Premium Access"
        okText={
          selectedPlan === 'basic'
            ? 'Keep Current Plan'
            : premiumPlanRequested
              ? 'Premium Access Requested'
              : 'Get Access'
        }
        okButtonProps={{
          loading: createRequestLoading,
          onClick: handleConfirm,
          disabled: premiumPlanRequested && selectedPlan === 'premium',
        }}
        cancelText="Cancel"
        width={600}
        // to cover Tooltip
        zIndex={9999}
        {...modalProps}
      >
        <S.ContentWrapper>
          {isMobile ? (
            <Tabs
              variant="secondary"
              activeKey={selectedPlan}
              onChange={(key: keyof (typeof CbSubscriptionPlan)['items']) =>
                setSelectedPlan(key)
              }
              hideMoreButton
            >
              {subscriptionPlans.map(plan => (
                <Tabs.TabPane key={plan.key} tab={plan.title}>
                  {renderPlanColumn(plan)}
                </Tabs.TabPane>
              ))}
            </Tabs>
          ) : (
            subscriptionPlans.map(plan => renderPlanColumn(plan))
          )}
        </S.ContentWrapper>
      </Modal>
      <SuccessModal
        {...successModalProps}
        title="Thank you for your interest in Premium Data"
        subTitle="We look forward to having you onboard. A member of our sales team will be in touch as soon as possible."
      />
    </>
  );
};
