/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ServiceToServiceUserlessHoldingsSmHoldingUpdateBatch } from '../model';
// @ts-ignore
import { ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract } from '../model';
/**
 * S2SUserlessSMHoldingUpdateBatchesApi - axios parameter creator
 * @export
 */
export const S2SUserlessSMHoldingUpdateBatchesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates holding update batches without user permission validation
         * @param {string} xNPMAuthorization 
         * @param {ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract} serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUserlessBulkCreateSMHoldingUpdateBatches: async (xNPMAuthorization: string, serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xNPMAuthorization' is not null or undefined
            assertParamExists('s2SUserlessBulkCreateSMHoldingUpdateBatches', 'xNPMAuthorization', xNPMAuthorization)
            // verify required parameter 'serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract' is not null or undefined
            assertParamExists('s2SUserlessBulkCreateSMHoldingUpdateBatches', 'serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract', serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract)
            const localVarPath = changeUrl(`/api/s2s/userless/sm_holdings/update_batch`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * S2SUserlessSMHoldingUpdateBatchesApi - functional programming interface
 * @export
 */
export const S2SUserlessSMHoldingUpdateBatchesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = S2SUserlessSMHoldingUpdateBatchesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates holding update batches without user permission validation
         * @param {string} xNPMAuthorization 
         * @param {ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract} serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async s2SUserlessBulkCreateSMHoldingUpdateBatches(xNPMAuthorization: string, serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceToServiceUserlessHoldingsSmHoldingUpdateBatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.s2SUserlessBulkCreateSMHoldingUpdateBatches(xNPMAuthorization, serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * S2SUserlessSMHoldingUpdateBatchesApi - factory interface
 * @export
 */
export const S2SUserlessSMHoldingUpdateBatchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = S2SUserlessSMHoldingUpdateBatchesApiFp(configuration)
    return {
        /**
         * 
         * @summary creates holding update batches without user permission validation
         * @param {string} xNPMAuthorization 
         * @param {ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract} serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        s2SUserlessBulkCreateSMHoldingUpdateBatches(xNPMAuthorization: string, serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract, xWorkstation?: string, options?: any): AxiosPromise<ServiceToServiceUserlessHoldingsSmHoldingUpdateBatch> {
            return localVarFp.s2SUserlessBulkCreateSMHoldingUpdateBatches(xNPMAuthorization, serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract, xWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for s2SUserlessBulkCreateSMHoldingUpdateBatches operation in S2SUserlessSMHoldingUpdateBatchesApi.
 * @export
 * @interface S2SUserlessSMHoldingUpdateBatchesApiS2SUserlessBulkCreateSMHoldingUpdateBatchesRequest
 */
export interface S2SUserlessSMHoldingUpdateBatchesApiS2SUserlessBulkCreateSMHoldingUpdateBatchesRequest {
    /**
     * 
     * @type {string}
     * @memberof S2SUserlessSMHoldingUpdateBatchesApiS2SUserlessBulkCreateSMHoldingUpdateBatches
     */
    readonly xNPMAuthorization: string

    /**
     * 
     * @type {ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract}
     * @memberof S2SUserlessSMHoldingUpdateBatchesApiS2SUserlessBulkCreateSMHoldingUpdateBatches
     */
    readonly serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract: ServiceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof S2SUserlessSMHoldingUpdateBatchesApiS2SUserlessBulkCreateSMHoldingUpdateBatches
     */
    readonly xWorkstation?: string
}

/**
 * S2SUserlessSMHoldingUpdateBatchesApi - object-oriented interface
 * @export
 * @class S2SUserlessSMHoldingUpdateBatchesApi
 * @extends {BaseAPI}
 */
export class S2SUserlessSMHoldingUpdateBatchesApi extends BaseAPI {
    /**
     * 
     * @summary creates holding update batches without user permission validation
     * @param {S2SUserlessSMHoldingUpdateBatchesApiS2SUserlessBulkCreateSMHoldingUpdateBatchesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof S2SUserlessSMHoldingUpdateBatchesApi
     */
    public s2SUserlessBulkCreateSMHoldingUpdateBatches(requestParameters: S2SUserlessSMHoldingUpdateBatchesApiS2SUserlessBulkCreateSMHoldingUpdateBatchesRequest, options?: AxiosRequestConfig) {
        return S2SUserlessSMHoldingUpdateBatchesApiFp(this.configuration).s2SUserlessBulkCreateSMHoldingUpdateBatches(requestParameters.xNPMAuthorization, requestParameters.serviceToServiceUserlessHoldingsSmHoldingsUpdateBatchCreateRequestContract, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}

export type S2SUserlessBulkCreateSMHoldingUpdateBatchesErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const S2SUserlessBulkCreateSMHoldingUpdateBatchesErrorCodes = [
    422,
];


