type MinCodebookOrString<TCode = string> = TCode | { code?: TCode; };

export const getCodebookCode = <TCode = string>(codebook: MinCodebookOrString | null | undefined) => {
  if(!codebook) return undefined;

  if (typeof codebook === 'string') {
    return codebook as TCode;
  }

  if(typeof codebook === 'object' && 'code' in codebook) {
    return codebook.code as TCode;
  }

  return undefined;
}

export const codebookEquals = <TCode = string, TValue extends TCode = TCode>(codebook: MinCodebookOrString | null | undefined, equalsValue: TValue) => {
  const code = getCodebookCode(codebook);
  return code === equalsValue;
};

export const codebookIsOneOf = <TCode = string, TValue extends TCode = TCode>(codebook: MinCodebookOrString | null | undefined, equalsValues: TValue[]) => {
  const code = getCodebookCode(codebook);
  return equalsValues.includes(code as TValue);
};

export const getCodebookName = (codebook: MinCodebookOrString | null | undefined): string | undefined => {
  if(codebook && typeof codebook === 'object' && 'name' in codebook) {
    return codebook.name as string;
  }

  return undefined;
}
