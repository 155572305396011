/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminSupplyStatsAggregatedAccountsStats } from '../model';
// @ts-ignore
import { AdminSupplyStatsAggregatedIssuersStats } from '../model';
// @ts-ignore
import { AdminSupplyStatsTimelineAccountsStats } from '../model';
// @ts-ignore
import { AdminSupplyStatsTimelineHoldingsStats } from '../model';
/**
 * SupplyStatsApi - axios parameter creator
 * @export
 */
export const SupplyStatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary holdings stats aggregated by account
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'holdings_count' | 'total_quantity'} sortBy 
         * @param {string} [issuerNpmGuid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplyStatsAggregatedAccountIndex: async (startDate: string, endDate: string, sortBy: 'holdings_count' | 'total_quantity', issuerNpmGuid?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('supplyStatsAggregatedAccountIndex', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('supplyStatsAggregatedAccountIndex', 'endDate', endDate)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('supplyStatsAggregatedAccountIndex', 'sortBy', sortBy)
            const localVarPath = changeUrl(`/api/admin/supply_stats/aggregated/accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (issuerNpmGuid !== undefined) {
                localVarQueryParameter['issuer_npm_guid'] = issuerNpmGuid;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary holdings stats aggregated by account
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'total_secondmarket_owned_shares' | 'holdings_count'} sortBy 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplyStatsAggregatedIssuerIndex: async (startDate: string, endDate: string, sortBy: 'total_secondmarket_owned_shares' | 'holdings_count', page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('supplyStatsAggregatedIssuerIndex', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('supplyStatsAggregatedIssuerIndex', 'endDate', endDate)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('supplyStatsAggregatedIssuerIndex', 'sortBy', sortBy)
            const localVarPath = changeUrl(`/api/admin/supply_stats/aggregated/issuers`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary data points for account activity
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [issuerNpmGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timelineAccountsIndex: async (startDate: string, endDate: string, issuerNpmGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('timelineAccountsIndex', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('timelineAccountsIndex', 'endDate', endDate)
            const localVarPath = changeUrl(`/api/admin/supply_stats/timeline/accounts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (issuerNpmGuid !== undefined) {
                localVarQueryParameter['issuer_npm_guid'] = issuerNpmGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary data points for holding creation activity
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [issuerNpmGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timelineHoldingsIndex: async (startDate: string, endDate: string, issuerNpmGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('timelineHoldingsIndex', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('timelineHoldingsIndex', 'endDate', endDate)
            const localVarPath = changeUrl(`/api/admin/supply_stats/timeline/holdings`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (issuerNpmGuid !== undefined) {
                localVarQueryParameter['issuer_npm_guid'] = issuerNpmGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplyStatsApi - functional programming interface
 * @export
 */
export const SupplyStatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplyStatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary holdings stats aggregated by account
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'holdings_count' | 'total_quantity'} sortBy 
         * @param {string} [issuerNpmGuid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supplyStatsAggregatedAccountIndex(startDate: string, endDate: string, sortBy: 'holdings_count' | 'total_quantity', issuerNpmGuid?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSupplyStatsAggregatedAccountsStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supplyStatsAggregatedAccountIndex(startDate, endDate, sortBy, issuerNpmGuid, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary holdings stats aggregated by account
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'total_secondmarket_owned_shares' | 'holdings_count'} sortBy 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supplyStatsAggregatedIssuerIndex(startDate: string, endDate: string, sortBy: 'total_secondmarket_owned_shares' | 'holdings_count', page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSupplyStatsAggregatedIssuersStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supplyStatsAggregatedIssuerIndex(startDate, endDate, sortBy, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary data points for account activity
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [issuerNpmGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timelineAccountsIndex(startDate: string, endDate: string, issuerNpmGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSupplyStatsTimelineAccountsStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timelineAccountsIndex(startDate, endDate, issuerNpmGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary data points for holding creation activity
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [issuerNpmGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timelineHoldingsIndex(startDate: string, endDate: string, issuerNpmGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSupplyStatsTimelineHoldingsStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timelineHoldingsIndex(startDate, endDate, issuerNpmGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupplyStatsApi - factory interface
 * @export
 */
export const SupplyStatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplyStatsApiFp(configuration)
    return {
        /**
         * 
         * @summary holdings stats aggregated by account
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'holdings_count' | 'total_quantity'} sortBy 
         * @param {string} [issuerNpmGuid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplyStatsAggregatedAccountIndex(startDate: string, endDate: string, sortBy: 'holdings_count' | 'total_quantity', issuerNpmGuid?: string, page?: number, size?: number, options?: any): AxiosPromise<AdminSupplyStatsAggregatedAccountsStats> {
            return localVarFp.supplyStatsAggregatedAccountIndex(startDate, endDate, sortBy, issuerNpmGuid, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary holdings stats aggregated by account
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'total_secondmarket_owned_shares' | 'holdings_count'} sortBy 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supplyStatsAggregatedIssuerIndex(startDate: string, endDate: string, sortBy: 'total_secondmarket_owned_shares' | 'holdings_count', page?: number, size?: number, options?: any): AxiosPromise<AdminSupplyStatsAggregatedIssuersStats> {
            return localVarFp.supplyStatsAggregatedIssuerIndex(startDate, endDate, sortBy, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary data points for account activity
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [issuerNpmGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timelineAccountsIndex(startDate: string, endDate: string, issuerNpmGuid?: string, options?: any): AxiosPromise<AdminSupplyStatsTimelineAccountsStats> {
            return localVarFp.timelineAccountsIndex(startDate, endDate, issuerNpmGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary data points for holding creation activity
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} [issuerNpmGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timelineHoldingsIndex(startDate: string, endDate: string, issuerNpmGuid?: string, options?: any): AxiosPromise<AdminSupplyStatsTimelineHoldingsStats> {
            return localVarFp.timelineHoldingsIndex(startDate, endDate, issuerNpmGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for supplyStatsAggregatedAccountIndex operation in SupplyStatsApi.
 * @export
 * @interface SupplyStatsApiSupplyStatsAggregatedAccountIndexRequest
 */
export interface SupplyStatsApiSupplyStatsAggregatedAccountIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiSupplyStatsAggregatedAccountIndex
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiSupplyStatsAggregatedAccountIndex
     */
    readonly endDate: string

    /**
     * 
     * @type {'holdings_count' | 'total_quantity'}
     * @memberof SupplyStatsApiSupplyStatsAggregatedAccountIndex
     */
    readonly sortBy: 'holdings_count' | 'total_quantity'

    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiSupplyStatsAggregatedAccountIndex
     */
    readonly issuerNpmGuid?: string

    /**
     * 
     * @type {number}
     * @memberof SupplyStatsApiSupplyStatsAggregatedAccountIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SupplyStatsApiSupplyStatsAggregatedAccountIndex
     */
    readonly size?: number
}

/**
 * Request parameters for supplyStatsAggregatedIssuerIndex operation in SupplyStatsApi.
 * @export
 * @interface SupplyStatsApiSupplyStatsAggregatedIssuerIndexRequest
 */
export interface SupplyStatsApiSupplyStatsAggregatedIssuerIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiSupplyStatsAggregatedIssuerIndex
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiSupplyStatsAggregatedIssuerIndex
     */
    readonly endDate: string

    /**
     * 
     * @type {'total_secondmarket_owned_shares' | 'holdings_count'}
     * @memberof SupplyStatsApiSupplyStatsAggregatedIssuerIndex
     */
    readonly sortBy: 'total_secondmarket_owned_shares' | 'holdings_count'

    /**
     * 
     * @type {number}
     * @memberof SupplyStatsApiSupplyStatsAggregatedIssuerIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SupplyStatsApiSupplyStatsAggregatedIssuerIndex
     */
    readonly size?: number
}

/**
 * Request parameters for timelineAccountsIndex operation in SupplyStatsApi.
 * @export
 * @interface SupplyStatsApiTimelineAccountsIndexRequest
 */
export interface SupplyStatsApiTimelineAccountsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiTimelineAccountsIndex
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiTimelineAccountsIndex
     */
    readonly endDate: string

    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiTimelineAccountsIndex
     */
    readonly issuerNpmGuid?: string
}

/**
 * Request parameters for timelineHoldingsIndex operation in SupplyStatsApi.
 * @export
 * @interface SupplyStatsApiTimelineHoldingsIndexRequest
 */
export interface SupplyStatsApiTimelineHoldingsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiTimelineHoldingsIndex
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiTimelineHoldingsIndex
     */
    readonly endDate: string

    /**
     * 
     * @type {string}
     * @memberof SupplyStatsApiTimelineHoldingsIndex
     */
    readonly issuerNpmGuid?: string
}

/**
 * SupplyStatsApi - object-oriented interface
 * @export
 * @class SupplyStatsApi
 * @extends {BaseAPI}
 */
export class SupplyStatsApi extends BaseAPI {
    /**
     * 
     * @summary holdings stats aggregated by account
     * @param {SupplyStatsApiSupplyStatsAggregatedAccountIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplyStatsApi
     */
    public supplyStatsAggregatedAccountIndex(requestParameters: SupplyStatsApiSupplyStatsAggregatedAccountIndexRequest, options?: AxiosRequestConfig) {
        return SupplyStatsApiFp(this.configuration).supplyStatsAggregatedAccountIndex(requestParameters.startDate, requestParameters.endDate, requestParameters.sortBy, requestParameters.issuerNpmGuid, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary holdings stats aggregated by account
     * @param {SupplyStatsApiSupplyStatsAggregatedIssuerIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplyStatsApi
     */
    public supplyStatsAggregatedIssuerIndex(requestParameters: SupplyStatsApiSupplyStatsAggregatedIssuerIndexRequest, options?: AxiosRequestConfig) {
        return SupplyStatsApiFp(this.configuration).supplyStatsAggregatedIssuerIndex(requestParameters.startDate, requestParameters.endDate, requestParameters.sortBy, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary data points for account activity
     * @param {SupplyStatsApiTimelineAccountsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplyStatsApi
     */
    public timelineAccountsIndex(requestParameters: SupplyStatsApiTimelineAccountsIndexRequest, options?: AxiosRequestConfig) {
        return SupplyStatsApiFp(this.configuration).timelineAccountsIndex(requestParameters.startDate, requestParameters.endDate, requestParameters.issuerNpmGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary data points for holding creation activity
     * @param {SupplyStatsApiTimelineHoldingsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplyStatsApi
     */
    public timelineHoldingsIndex(requestParameters: SupplyStatsApiTimelineHoldingsIndexRequest, options?: AxiosRequestConfig) {
        return SupplyStatsApiFp(this.configuration).timelineHoldingsIndex(requestParameters.startDate, requestParameters.endDate, requestParameters.issuerNpmGuid, options).then((request) => request(this.axios, this.basePath));
    }
}

export type SupplyStatsAggregatedAccountIndexErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SupplyStatsAggregatedAccountIndexErrorCodes = [
    422,
];

export type SupplyStatsAggregatedIssuerIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SupplyStatsAggregatedIssuerIndexErrorCodes = [
];

export type TimelineAccountsIndexErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TimelineAccountsIndexErrorCodes = [
    422,
];

export type TimelineHoldingsIndexErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TimelineHoldingsIndexErrorCodes = [
    422,
];


