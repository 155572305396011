import { CbVisibility, type VisibilityCode } from '@npm/data-access';
import { type FormInstance } from 'antd/es/form/Form';
import { addDays, isAfter } from 'date-fns';

import { type VisibilityFormSectionFields } from './types';

/**
 * When changing order visibility to external, check if the current expiration is higher than maximum,
  if yes, reset the expiration date field.
 */
export const handleExpirationDateResetOnVisibilityChange = (
  form: FormInstance<VisibilityFormSectionFields>,
  visibility: VisibilityCode,
  expiration?: Date
) => {
  if (visibility === CbVisibility.items.external && expiration) {
    const currentDate = new Date();
    const expirationDate = new Date(expiration);
    const maxExternalOrderExpirationDate = addDays(currentDate, 30);
    const isHigherThanMaxDate = isAfter(
      expirationDate,
      maxExternalOrderExpirationDate
    );

    if (isHigherThanMaxDate) {
      form.setFieldValue('expiration', undefined);
    }
  }
};

export const getVisibilityPreview = (
  options: { code?: string; name?: string }[],
  val?: string
) => options.find(i => i.code === val)?.name;
