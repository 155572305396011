// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  ExecutivesApi,
  InternalExecutiveIndexErrorCodes,
  InternalExecutiveIndexErrorTypes,
  InternalExecutiveCreateErrorCodes,
  InternalShowExecutiveErrorCodes,
  InternalShowExecutiveErrorTypes,
  InternalUpdateExecutiveErrorCodes,
  InternalDeleteExecutiveErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new ExecutivesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalExecutiveIndexMethod = ExecutivesApi['internalExecutiveIndex'];
export type InternalExecutiveIndexResponseType = MethodResult<InternalExecutiveIndexMethod>;

export const useInternalExecutiveIndex = (
  variables: Parameters<InternalExecutiveIndexMethod>[0],
  config?: UseQueryConfig<
    InternalExecutiveIndexMethod,
    InternalExecutiveIndexErrorTypes
  >
) => {
  return useQuery<
    InternalExecutiveIndexMethod,
    InternalExecutiveIndexErrorTypes
  >(
    (...args) => api.internalExecutiveIndex(...args),
    InternalExecutiveIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalExecutiveIndex'],
        ...apiQueryConfig.InternalExecutiveIndex,
        ...config?.queryConfig
      },
    },
    'InternalExecutiveIndex',
  );
};

export const useInternalExecutiveIndexInfinite = (
  variables: Parameters<InternalExecutiveIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalExecutiveIndexMethod,
    InternalExecutiveIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalExecutiveIndexMethod,
    InternalExecutiveIndexErrorTypes
  >(
    (...args) => api.internalExecutiveIndex(...args),
    InternalExecutiveIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalExecutiveIndex', 'InternalExecutiveIndexInfinite'],
        ...apiQueryConfig.InternalExecutiveIndex,
        ...config?.queryConfig,
      },
    },
    'InternalExecutiveIndex',
  );
};

export const useInternalExecutiveIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalExecutiveIndexMethod>[0],
  config?: UseQueryConfig<
    InternalExecutiveIndexMethod,
    InternalExecutiveIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalExecutiveIndexMethod,
    InternalExecutiveIndexErrorTypes
  >(
    (...args) => api.internalExecutiveIndex(...args),
    InternalExecutiveIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalExecutiveIndex'],
        ...apiQueryConfig.InternalExecutiveIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalExecutiveIndex',
  );
};


export type InternalExecutiveCreateMethod = ExecutivesApi['internalExecutiveCreate'];

export const useInternalExecutiveCreate = () => {
  return useMutation<InternalExecutiveCreateMethod>(
    (...args) => api.internalExecutiveCreate(...args),
    InternalExecutiveCreateErrorCodes,
    'InternalExecutiveCreate',
    '/api/internal/companies/{company_id}/executives',
    'post'
  );
};

export type InternalShowExecutiveMethod = ExecutivesApi['internalShowExecutive'];
export type InternalShowExecutiveResponseType = MethodResult<InternalShowExecutiveMethod>;

export const useInternalShowExecutive = (
  variables: Parameters<InternalShowExecutiveMethod>[0],
  config?: UseQueryConfig<
    InternalShowExecutiveMethod,
    InternalShowExecutiveErrorTypes
  >
) => {
  return useQuery<
    InternalShowExecutiveMethod,
    InternalShowExecutiveErrorTypes
  >(
    (...args) => api.internalShowExecutive(...args),
    InternalShowExecutiveErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowExecutive'],
        ...apiQueryConfig.InternalShowExecutive,
        ...config?.queryConfig
      },
    },
    'InternalShowExecutive',
  );
};

export const useInternalShowExecutiveInfinite = (
  variables: Parameters<InternalShowExecutiveMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowExecutiveMethod,
    InternalShowExecutiveErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowExecutiveMethod,
    InternalShowExecutiveErrorTypes
  >(
    (...args) => api.internalShowExecutive(...args),
    InternalShowExecutiveErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowExecutive', 'InternalShowExecutiveInfinite'],
        ...apiQueryConfig.InternalShowExecutive,
        ...config?.queryConfig,
      },
    },
    'InternalShowExecutive',
  );
};

export const useInternalShowExecutiveLazy = (baseOptions?: {
  variables?: Parameters<InternalShowExecutiveMethod>[0],
  config?: UseQueryConfig<
    InternalShowExecutiveMethod,
    InternalShowExecutiveErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowExecutiveMethod,
    InternalShowExecutiveErrorTypes
  >(
    (...args) => api.internalShowExecutive(...args),
    InternalShowExecutiveErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowExecutive'],
        ...apiQueryConfig.InternalShowExecutive,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowExecutive',
  );
};


export type InternalUpdateExecutiveMethod = ExecutivesApi['internalUpdateExecutive'];

export const useInternalUpdateExecutive = () => {
  return useMutation<InternalUpdateExecutiveMethod>(
    (...args) => api.internalUpdateExecutive(...args),
    InternalUpdateExecutiveErrorCodes,
    'InternalUpdateExecutive',
    '/api/internal/executives/{id}',
    'put'
  );
};

export type InternalDeleteExecutiveMethod = ExecutivesApi['internalDeleteExecutive'];

export const useInternalDeleteExecutive = () => {
  return useMutation<InternalDeleteExecutiveMethod>(
    (...args) => api.internalDeleteExecutive(...args),
    InternalDeleteExecutiveErrorCodes,
    'InternalDeleteExecutive',
    '/api/internal/executives/{id}',
    'delete'
  );
};
