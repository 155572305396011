import { EMPTY_VALUE } from '@npm/core/ui/components/atoms/Typography';
import { TwoRowsCell } from '@npm/core/ui/components/molecules/TwoRowsCell';
import { formatDate } from '@npm/core/ui/utils/formatters';
import {
  CbSubmissionStructure,
  type CodebookIndex,
  type SubmissionStructureCode,
} from '@npm/data-access';

export const getStructurePreview = ({
  structure,
  spvManagementFee,
  spvCarriedInterestFee,
  hasNonSpvStructureFee,
  structurePercentageFee,
  structureCodebooks,
  showFees = true,
}: {
  structure?: SubmissionStructureCode;
  structureCodebooks?: CodebookIndex['codebooks'];
  hasSpvStructureFee?: boolean;
  spvManagementFee?: number;
  spvCarriedInterestFee?: number;
  hasNonSpvStructureFee?: boolean;
  structurePercentageFee?: number;
  showFees?: boolean;
}) => {
  if (!structure) {
    return EMPTY_VALUE;
  }
  const structureCodebookItem = structureCodebooks?.find(
    ({ code }) => code === structure
  );
  const structureLabel = structureCodebookItem?.name ?? structure;

  if (showFees) {
    if (
      structure === CbSubmissionStructure.items.spv &&
      (spvManagementFee || spvCarriedInterestFee)
    ) {
      return `${spvManagementFee ?? 0}/${
        spvCarriedInterestFee ?? 0
      } ${structureLabel} Single Layer`;
    }
    if (hasNonSpvStructureFee && structurePercentageFee) {
      return `${structureLabel} ${structurePercentageFee ?? 0}% Fee`;
    }
  }

  return structureLabel;
};

export const getExpirationDate = (
  expires_at: string | undefined,
  gtc: boolean | undefined
) => {
  if (expires_at && gtc) {
    return (
      <TwoRowsCell
        firstRow={formatDate(expires_at)}
        secondRowText="Good Till Canceled"
      />
    );
  }
  if (!gtc && !expires_at) return EMPTY_VALUE;
  if (gtc) return 'Good Till Cancelled';
  return formatDate(expires_at);
};

export const formatClearingPrice = (
  value: (string | number)[] | null | undefined
): undefined | number | [number, number] => {
  if (value == null || value.length === 0) {
    return undefined;
  }
  if (value.length === 1 || value[0] === value[1]) {
    return Number(value[0]);
  }
  return [Number(value[0]), Number(value[1])];
};
