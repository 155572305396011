import { type ComponentProps } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useTheme } from 'styled-components';

type Props = Pick<ComponentProps<typeof Tooltip>, 'title'>;

// TODO: NFE-7358 remove this component and use `TooltipInfoIcon` from `@npm/core/ui/components/atoms/Tooltip` instead
export const InfoTooltip = ({ title }: Props) => {
  const theme = useTheme();
  return (
    <Tooltip title={title} size="lg">
      <Text as="span" style={{ display: 'flex' }}>
        <Icon
          size="xs"
          color={theme.color.info.typography.primary}
          name="info-circle"
        />
      </Text>
    </Tooltip>
  );
};
