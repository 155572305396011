import { FormItem as BaseFormItem } from '@npm/core/ui/components/atoms/FormItem';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const FormItem = styled(BaseFormItem)`
  .ant-form-item-explain-error {
    max-width: 50%;

    ${({ theme }) => useBreakpoint(theme).mobile`
    max-width: 100%;
  `}
  }
`;
