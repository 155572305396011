import React, { useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Switch } from '@npm/core/ui/components/atoms/Switch';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbWorkstationType,
  useUserShowCurrent,
  useUserUpdate,
} from '@npm/data-access';
import { useTheme } from 'styled-components';

import { useCurrentWorkstation } from '../../../auth/user/role';

function WatchListToggle() {
  const { data, isLoading } = useUserShowCurrent({});
  const watchlistEmailRemote = data?.settings?.notifications?.watchlist_email;
  const [visible, setVisible] = useState(false);
  const currentWorkstation = useCurrentWorkstation();

  const isBrokerWorkstation =
    currentWorkstation?.type?.code === CbWorkstationType.items.brokerage;

  const { withShowApiErrorMiddleware } = useAlerts();
  const { isLoading: isSaving, execute } = useUserUpdate();

  const theme = useTheme();

  const handleSubmit = async () => {
    try {
      await withShowApiErrorMiddleware(execute)({
        id: data?.id,
        userUpdateRequestContract: {
          notifications_enable_watchlist_email: !watchlistEmailRemote,
        },
      });
    } catch (err) {
      console.error(err);
      return;
    }
    setVisible(false);
  };

  const { isMobile, isSmTablet } = useBreakpoints();

  if (isBrokerWorkstation) {
    return null;
  }

  return (
    <>
      <Flex
        gap={isSmTablet ? theme.spacing.sm : theme.spacing.lg}
        align="center"
      >
        <Flex gap={theme.spacing.sm} align="center">
          <TooltipInfoIcon
            title={
              !watchlistEmailRemote
                ? 'Subscribe to Watchlist Notification Emails to receive daily alerts when relevant bids or offers become available in SecondMarket.'
                : "Opting out means you won't receive email alerts for deals matching your criteria. We advise keeping notifications enabled to stay informed about relevant market opportunities."
            }
          />
          <Text size="sm" colorVariant="primary">
            {'Watchlist Notifications'}
          </Text>{' '}
        </Flex>
        <Switch
          checked={!isLoading && watchlistEmailRemote}
          onClick={() => {
            setVisible(true);
          }}
        />
      </Flex>

      <Modal
        width={594}
        onCancel={() => {
          setVisible(false);
        }}
        open={visible}
        title={
          watchlistEmailRemote
            ? 'Turn Off Watchlist Notifications'
            : 'Turn On Watchlist Notifications'
        }
        footer={
          <Flex
            style={{
              width: '100%',
            }}
            align="center"
            justify="flex-end"
          >
            <Button
              size={isMobile ? 'md' : 'lg'}
              variant="text"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size={isMobile ? 'md' : 'lg'}
              color={watchlistEmailRemote ? 'error' : 'info'}
              onClick={handleSubmit}
              disabled={isLoading || isSaving}
              loading={isSaving}
            >
              {watchlistEmailRemote
                ? 'Turn Off Notifications'
                : 'Turn On Notifications'}
            </Button>
          </Flex>
        }
      >
        <Flex gap={10} direction="column">
          {watchlistEmailRemote ? (
            <>
              <Text colorVariant="secondary">
                You are about to unsubscribe from the Watchlist Notifications
                email, which means that you will no longer be alerted when
                relevant bids or offers are made available.
              </Text>
              <Text colorVariant="secondary">
                Are you sure that you want to turn off your Watchlist
                Notifications?
              </Text>
            </>
          ) : (
            <Text colorVariant="secondary">
              You are about to subscribe to the Watchlist Notifications email,
              which provides regular alerts (daily or weekly) when relevant bids
              or offers become available.
            </Text>
          )}
        </Flex>
      </Modal>
    </>
  );
}

export default WatchListToggle;
