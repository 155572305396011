import { type Rule } from 'antd/lib/form';

import { type BuyerSurveyCodebookItem } from './CbBuyerSurvey';
import { type CodebookAttribute, type CodebookItem, Codebooks } from './codebooks.types';

export type BuyerSurveyQuestionCodebookItemKeys = keyof typeof Items;
export interface BuyerSurveyQuestionCodebookItem extends CodebookItem {
  code: BuyerSurveyQuestionCodebookItemKeys;
}

interface BuyerSurveyQuestionAttribute extends CodebookAttribute {
  name: 'help' | 'individual' | 'ordered_by_individual' | 'entity' | 'ordered_by_entity';
}

type BuyerSurveyFormControlType = 'checkbox' | 'radio' | 'multiselect';

const Items = {
  asset_classes: 'asset_classes',
  investment_objective: 'investment_objective',
  liquidity_preference: 'liquidity_preference',
  investment_horizon: 'investment_horizon',
  risk_tolerance: 'risk_tolerance',
  check_size_range: 'check_size_range',
  stage: 'stage',
  sectors: 'sectors',
  geographies: 'geographies',
  liquid_and_callable_assets: 'liquid_and_callable_assets',
  liquidity_needs: 'liquidity_needs',
  liquid_assets: 'liquid_assets',
  net_worth_survey: 'net_worth_survey',
  source_of_funds: 'source_of_funds',
  investment_size: 'investment_size',
  investment_experience: 'investment_experience',
} as const;

const BuyerSurveyQuestionTypes: Record<BuyerSurveyQuestionCodebookItemKeys, BuyerSurveyFormControlType> = {
  asset_classes: 'checkbox',
  investment_objective: 'checkbox',
  liquidity_preference: 'radio',
  investment_horizon: 'radio',
  risk_tolerance: 'radio',
  check_size_range: 'radio',
  stage: 'checkbox',
  sectors: 'multiselect',
  geographies: 'checkbox',
  liquid_and_callable_assets: 'radio',
  liquidity_needs: 'radio',
  liquid_assets: 'radio',
  net_worth_survey: 'radio',
  source_of_funds: 'checkbox',
  investment_size: 'radio',
  investment_experience: 'radio',
};

const BuyerSurveyQuestionValidationRules: Partial<{
  [key in BuyerSurveyQuestionCodebookItemKeys]: Rule[];
}> = {
  investment_objective: [
    { required: true, message: 'Investment Objective is required' },
  ],
  liquidity_preference: [
    { required: true, message: 'Liquidity Preferences is required' },
  ],
  investment_horizon: [
    { required: true, message: 'Investment Horizon is required' },
  ],
  risk_tolerance: [{ required: true, message: 'Risk Tolerance is required' }],
  check_size_range: [
    { required: true, message: 'Desired Check Size is required' },
  ],
  stage: [{ required: true, message: 'Desired Stage is required' }],
  sectors: [{ required: true, message: 'Desired Sectors is required' }],
  geographies: [{ required: true, message: 'Desired Geographies is required' }],
  liquid_and_callable_assets: [
    { required: true, message: 'Liquid and Callable Assets is required' },
  ],
};

export const CbBuyerSurveyQuestion = {
  code: Codebooks.BUYER_SURVEY_QUESTION,
  items: Items,
  questionTypes: BuyerSurveyQuestionTypes,
  getAnswersByQuestion: (
    question: BuyerSurveyQuestionCodebookItem['code'],
    answers: BuyerSurveyCodebookItem[]
  ) =>
    answers.filter(answer =>
      answer.attributes
        ?.find(attr => attr.name === 'question')
        ?.value?.includes(question)
    ),
  getHelpAttributeValue: (item: BuyerSurveyCodebookItem): string | undefined =>
    item.attributes.find(attr => attr.name === 'help')?.value,
  getAttributeValueByName: (item: BuyerSurveyQuestionCodebookItem, name: BuyerSurveyQuestionAttribute['name']) => item.attributes.find(attr => attr.name === name)?.value,
  getValidationRules: (key: BuyerSurveyQuestionCodebookItemKeys) =>
    BuyerSurveyQuestionValidationRules[key],
};
