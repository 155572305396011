import { Table as BaseTable } from '@npm/core/ui/components/molecules/Table';
import { type Holding } from '@npm/data-access';
import styled from 'styled-components';

export const Table = styled(BaseTable<Holding>)`
  th.state,
  td.state {
    text-align: right;

    .ant-tag {
      margin-left: auto;
    }
  }
`;
