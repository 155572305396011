import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
    display: block;
  `}
`;

export const Col = styled.div`
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;
