/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalCompaniesTaxonomy } from '../model';
/**
 * TaxonomiesApi - axios parameter creator
 * @export
 */
export const TaxonomiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary company taxonomy data
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyTaxonomies: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyTaxonomies', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/taxonomies`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxonomiesApi - functional programming interface
 * @export
 */
export const TaxonomiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxonomiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary company taxonomy data
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyTaxonomies(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCompaniesTaxonomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyTaxonomies(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxonomiesApi - factory interface
 * @export
 */
export const TaxonomiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxonomiesApiFp(configuration)
    return {
        /**
         * 
         * @summary company taxonomy data
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyTaxonomies(companyId: string, options?: any): AxiosPromise<InternalCompaniesTaxonomy> {
            return localVarFp.companyTaxonomies(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyTaxonomies operation in TaxonomiesApi.
 * @export
 * @interface TaxonomiesApiCompanyTaxonomiesRequest
 */
export interface TaxonomiesApiCompanyTaxonomiesRequest {
    /**
     * 
     * @type {string}
     * @memberof TaxonomiesApiCompanyTaxonomies
     */
    readonly companyId: string
}

/**
 * TaxonomiesApi - object-oriented interface
 * @export
 * @class TaxonomiesApi
 * @extends {BaseAPI}
 */
export class TaxonomiesApi extends BaseAPI {
    /**
     * 
     * @summary company taxonomy data
     * @param {TaxonomiesApiCompanyTaxonomiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomiesApi
     */
    public companyTaxonomies(requestParameters: TaxonomiesApiCompanyTaxonomiesRequest, options?: AxiosRequestConfig) {
        return TaxonomiesApiFp(this.configuration).companyTaxonomies(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompanyTaxonomiesErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyTaxonomiesErrorCodes = [
    401,
];


