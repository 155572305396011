import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { CbAccountType } from '@npm/data-access';

import { ZendeskContactButton } from '../../app/zendesk/support/ZendeskContactButton';
import { useCurrentAccount } from '../../auth/user/role';

import { IconBox } from './IconBox';

type Props = {
  modalProps: React.ComponentProps<typeof Modal>;
};

export const SponsorshipDowngradeModal = ({ modalProps }: Props) => {
  const currentAccount = useCurrentAccount();
  const isIndividual =
    currentAccount?.account?.type?.code === CbAccountType.items.PersonAccount;

  return (
    <Modal
      {...modalProps}
      title="Marketplace Access is Pending Approval"
      size="md"
      okText="Close"
      okButtonProps={{
        variant: 'outline',
      }}
    >
      <Flex>
        <IconBox iconName="clock" colorVariant="info" />
        <Flex direction="column" gap="md">
          <Text size="sm">
            We’ve noticed your account information was updated to reflect a
            non-US location. As a result, your direct access to Marketplace was
            deactivated to comply with regional policies and platform
            requirements. Our team received your onboarding information and your
            marketplace access is pending approval.
          </Text>
          <Text size="sm">
            If you have questions or concerns, email{' '}
            <Text href="mailto:support@npm.com" size="sm">
              support@npm.com
            </Text>{' '}
            or{' '}
            <ZendeskContactButton
              zendeskTicketGroupId={
                isIndividual
                  ? 'onboarding_individual_identity_verification'
                  : 'onboarding_entity_identity_verification'
              }
            >
              click here to contact us
            </ZendeskContactButton>
            .
          </Text>
        </Flex>
      </Flex>
    </Modal>
  );
};
