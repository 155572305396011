import React, { useMemo } from 'react';

import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';
import { Codebooks, useCodebook } from '@npm/data-access';
import { type FormInstance, type FormProps } from 'antd';

import { structureFormData } from '../../Accreditation.utils';

import { getFormConfig } from './IndividualAccreditationForm.config';

type Props = {
  form: FormInstance;
  handleValuesChange?: FormProps['onValuesChange'];
  scrollableParentId?: string;
  extraTopPadding?: number;
  questionMinHeight?: number | string;
};

export const IndividualAccreditationForm = ({
  form,
  handleValuesChange,
  scrollableParentId,
  extraTopPadding,
  questionMinHeight,
}: Props) => {
  const { data: accreditationQuestion, isLoading: areQuestionsLoading } =
    useCodebook({
      type: Codebooks.ACCREDITATION_QUESTION,
    });

  const { data: accreditationLabel, isLoading: areLabelsLoading } = useCodebook(
    {
      type: Codebooks.ACCREDITATION_LABEL,
    }
  );

  const [structuredData] = useMemo(
    () =>
      accreditationQuestion && accreditationLabel
        ? structureFormData(
            accreditationQuestion?.codebooks,
            accreditationLabel?.codebooks,
            'individual'
          )
        : [],
    [accreditationQuestion, accreditationLabel]
  );

  if (areQuestionsLoading || areLabelsLoading) {
    return <Skeleton.Base />;
  }

  const questions = getFormConfig(structuredData, form);

  return (
    <AutoScrollingForm
      questions={questions}
      form={form}
      onValuesChange={handleValuesChange}
      scrollableParentId={scrollableParentId}
      extraTopPadding={extraTopPadding}
      questionMinHeight={questionMinHeight}
    />
  );
};
