import React, { useEffect } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type AccountShowAggregate,
  type BrokerageFirmAggregate,
  CbAccountSponsorshipType,
  CbAccountType,
  CbAssetType,
  CbATSPool,
  CbOrderEntryType,
  CbOrderItemState,
  CbOrderSource,
  CbRoleType,
  CbSubmissionStructure,
  CbVisibility,
  type Holding,
  type IssuerEntityAggregate,
  type OrderSourceCode,
  type UserRoleApiUserRoleIndexRequest,
  type UserRoleIndex,
} from '@npm/data-access';
import { type FormInstance } from 'antd';

import {
  hasSpouseOrGuestRolesOnly,
  isNPMS,
  useUserContextStore,
} from '../../../../../auth/user/context';
import {
  type OboDefinition,
  useSponsorshipLevel,
} from '../../../../../auth/user/role';
import { CompanyCard } from '../../../../../company';
import {
  CompanySearch,
  HoldingSelect,
  UserRoleSearch,
} from '../../../../../filters';
import { getAssetTypeCode, isSpvHolding } from '../../../../../holdings';
import { type OrderSizeType } from '../../../../../order';
import { useHoldingQuantities } from '../../../../hooks/useHoldingsQuantities';
import { HoldingCard } from '../../../components/HoldingCard';
import { HoldingMultiSelect } from '../../../components/HoldingMultiSelect';
import { OrderAlerts } from '../../../components/OrderAlerts';
import {
  isAccountGuestRole,
  isAccountNotAccredited,
} from '../../../utils/order';
import { type OrderPlacementFormValues } from '../OrderPlacementDrawer.types';

import { AccountNotAccredited } from './components/AccountNotAccredited';
import { InsufficientPermission } from './components/InsufficientPermission';
import { NoHoldingsFound } from './components/NoHoldingsFound';
import { OrderEntrySection } from './components/OrderEntrySection';
import { OrderPreferencesSection } from './components/OrderPreferencesSection';
import { SideSelect } from './components/SideSelect';

import * as S from './OrderPlacementForm.styles';

type Props = {
  formId: string;
  form: FormInstance<OrderPlacementFormValues>;
  onValuesChange?: (
    changedValues: Partial<OrderPlacementFormValues>,
    values: OrderPlacementFormValues
  ) => void;
  disabledFields?: ('issuerEntityId' | 'buySell' | 'holdingId' | 'accountId')[];
  company: IssuerEntityAggregate;
  source: OrderSourceCode | undefined;
  activeAction: 'buy' | 'sell';
  selectedHoldings: Holding[] | undefined;
  setSelectedHoldings: (holdings: Holding[]) => void;
  openAddHoldingDrawer: () => void;
  allowedAssetTypeOptions: { label: string; value: string }[];
  noHoldingsFound?: boolean;
  holdingsLoading?: boolean;
  orderSizeType: OrderSizeType;
  toggleSizeType: () => void;
  updateSizeType: (val: OrderSizeType) => void;
  onAccountSwitch: () => void;
  brokerageFirm: BrokerageFirmAggregate | undefined;
  isInvestor: boolean;
  obo: OboDefinition | null;
  selectedAccount: AccountShowAggregate;
  handleSubmit: () => void;
  useSingleHoldingSelect: boolean;
};

const MANDATORY_FIELD_MESSAGE = 'This field is mandatory';

export const OrderPlacementForm = ({
  formId,
  form,
  onValuesChange,
  disabledFields,
  company,
  source,
  activeAction,
  selectedHoldings,
  setSelectedHoldings,
  openAddHoldingDrawer,
  allowedAssetTypeOptions,
  noHoldingsFound,
  holdingsLoading,
  orderSizeType,
  toggleSizeType,
  updateSizeType,
  brokerageFirm,
  onAccountSwitch,
  isInvestor,
  obo,
  selectedAccount,
  handleSubmit,
  useSingleHoldingSelect,
}: Props) => {
  const { isSponsorshipLevel3 } = useSponsorshipLevel();

  const { isIndividualNpmsBuyerInvestor, isEntityNpmsInvestor } =
    useUserContextStore(store => ({
      isIndividualNpmsBuyerInvestor: store.isIndividualNpmsBuyerInvestor,
      isEntityNpmsInvestor: store.isEntityNpmsInvestor,
    }));

  const initialVisibility =
    // INV Buyer Level3 are automatically external (can't toggle)
    isSponsorshipLevel3 && isIndividualNpmsBuyerInvestor
      ? CbVisibility.items.external
      : CbVisibility.items.internal;

  const initialValues: Partial<OrderPlacementFormValues> = {
    buySell: activeAction,
    assetType: CbAssetType.items.AnyShareClass,
    visibility: initialVisibility,
    atsPool: CbATSPool.items.internal,
    orderType: obo ? CbOrderEntryType.items.ioi : CbOrderEntryType.items.firm,
    structure: CbSubmissionStructure.items.direct,
    goodTillCancelled: true,
    holdingIds: [],
    ...(source === CbOrderSource.items.historical && {
      state: CbOrderItemState.items.expired,
    }),
  };

  const formValues =
    Form.useWatch([], form) ?? ({} as OrderPlacementFormValues);
  const accountId = obo?.account?.id ?? formValues.accountId;

  const isIndividualAccountSelected =
    selectedAccount?.type?.code === CbAccountType.items.PersonAccount;
  const isSelectedAccountLvl3 =
    CbAccountSponsorshipType.isSponsoredAccountLevel3(
      selectedAccount?.sponsorship_type
    );

  useEffect(() => {
    if (!selectedHoldings?.length) {
      return;
    }

    const hasAllSpvs = selectedHoldings.every(holding =>
      isSpvHolding(getAssetTypeCode(holding?.asset?.type))
    );

    if (
      hasAllSpvs &&
      form.getFieldValue('structure') !== CbSubmissionStructure.items.spv
    ) {
      form.setFieldsValue({
        structure: CbSubmissionStructure.items.spv,
      });
    }
    if (
      !hasAllSpvs &&
      form.getFieldValue('structure') !== CbSubmissionStructure.items.direct
    ) {
      form.setFieldsValue({
        structure: CbSubmissionStructure.items.direct,
      });
    }
  }, [form, selectedHoldings]);

  const quantities = useHoldingQuantities(selectedHoldings);

  const autoselectAccount = (
    data: UserRoleIndex,
    variables: UserRoleApiUserRoleIndexRequest
  ) => {
    if (!isInvestor || accountId || variables.search) return;
    const npmsAccounts = data?.user_roles?.filter(
      role =>
        isNPMS(role.subject) && role.role_name.code !== CbRoleType.items.SPOUSE
    );
    if (npmsAccounts?.length === 1) {
      form.setFieldValue('accountId', npmsAccounts[0]?.subject?.id);
    }
  };

  const user = useUserContextStore(store => store.user);

  const isSelectedAccountRestricted =
    (activeAction === 'buy' &&
      source !== CbOrderSource.items.historical &&
      isAccountNotAccredited(selectedAccount)) ||
    (!obo && isAccountGuestRole(selectedAccount, user?.email));

  const showFormFields =
    !isSelectedAccountRestricted &&
    accountId &&
    selectedAccount &&
    formValues.issuerEntityId;

  useEffect(() => {
    if (isIndividualAccountSelected && isInvestor) {
      form.setFieldsValue({ orderType: CbOrderEntryType.items.firm });
    }

    if (isEntityNpmsInvestor && !obo) {
      if (isSelectedAccountLvl3) {
        form.setFieldsValue({ visibility: CbVisibility.items.external });
      } else {
        form.setFieldsValue({ visibility: CbVisibility.items.internal });
      }
    }
  }, [
    form,
    isEntityNpmsInvestor,
    isIndividualAccountSelected,
    isInvestor,
    isSelectedAccountLvl3,
  ]);

  const handleHoldingsSelect = (holdings: Holding[] | Holding) => {
    setSelectedHoldings(Array.isArray(holdings) ? holdings : [holdings]);
  };

  const holdingValidationError = useSingleHoldingSelect
    ? 'Please select a holding'
    : 'Please select at least one holding';

  return (
    <S.OrderPlacementFormContainer>
      <Form<OrderPlacementFormValues>
        id={formId}
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={handleSubmit}
      >
        <FormItem
          name="issuerEntityId"
          label="Company"
          rules={[{ required: true, message: MANDATORY_FIELD_MESSAGE }]}
          marginBottom="md"
          hidden={disabledFields?.includes('issuerEntityId')}
        >
          <CompanySearch
            variables={{ unverifiedHoldings: true }}
            placeholder="Search"
            data-cy={CypressDataIds.Holdings.Form.CompanySearch}
            defaultEntity={company}
            requestCoverageVariant="row"
          />
        </FormItem>
        {disabledFields?.includes('issuerEntityId') && company && (
          <CompanyCard company={company} />
        )}
        <FormItem
          name="accountId"
          label="Account"
          rules={[{ required: true, message: MANDATORY_FIELD_MESSAGE }]}
          marginBottom="md"
          style={obo ? { display: 'none' } : {}}
        >
          <UserRoleSearch
            disabled={!!obo}
            onFetchComplete={autoselectAccount}
            filterOptions={
              isInvestor
                ? userRole => !hasSpouseOrGuestRolesOnly([userRole])
                : undefined
            }
          />
        </FormItem>

        <FormItem
          name="buySell"
          rules={[{ required: true, message: MANDATORY_FIELD_MESSAGE }]}
          marginBottom="md"
          hidden={disabledFields?.includes('buySell')}
        >
          <SideSelect
            onChange={(action: 'buy' | 'sell') => {
              if (formValues.quantity || formValues.minimumQuantity) {
                // Do not switch the size type automatically if there's already a value
                return;
              }
              updateSizeType(action === 'buy' ? 'USD' : 'Shares');
            }}
          />
        </FormItem>

        {!showFormFields ? (
          <div>
            {isSelectedAccountRestricted ? (
              activeAction === 'buy' &&
              isAccountNotAccredited(selectedAccount) ? (
                <AccountNotAccredited
                  selectedAccountId={accountId}
                  isInvestor={isInvestor}
                  obo={obo}
                />
              ) : (
                <InsufficientPermission
                  onClick={onAccountSwitch}
                  isObo={!!obo}
                />
              )
            ) : (
              <NoDataArea
                title={
                  !formValues.issuerEntityId
                    ? 'Select a company to enter an order'
                    : 'Select an account to enter IOI'
                }
                iconColor="info"
              />
            )}
          </div>
        ) : activeAction === 'sell' && (noHoldingsFound || holdingsLoading) ? (
          <Margin bottom="lg">
            <NoHoldingsFound
              loading={holdingsLoading}
              companyName={company?.name}
              onClick={openAddHoldingDrawer}
            />
          </Margin>
        ) : (
          <>
            {activeAction === 'sell' && (
              <Margin bottom="md" top="lg">
                <FormItem
                  name="holdingIds"
                  label="Holding"
                  rules={[
                    VALIDATION_RULES.required(),
                    VALIDATION_RULES.arrayMinLength(1, holdingValidationError),
                  ]}
                  marginBottom="sm"
                >
                  {useSingleHoldingSelect ? (
                    <HoldingSelect
                      onItemSelected={handleHoldingsSelect}
                      onAddHoldingClick={openAddHoldingDrawer}
                      accountId={accountId}
                      issuerEntityId={formValues.issuerEntityId}
                      disabled={
                        disabledFields?.includes('holdingId') &&
                        'holdingId' in formValues &&
                        !!formValues.holdingId
                      }
                      secondmarket
                      includeSpvs
                      includePortfolioHoldings
                      oboOverride={obo}
                      valueField="npm_guid"
                    />
                  ) : (
                    <HoldingMultiSelect
                      issuerEntityId={company?.id}
                      accountId={selectedAccount?.id}
                      secondmarket
                      includePortfolioHoldings
                      includeSpvs
                      quantities={
                        selectedHoldings.length > 1 ? quantities : undefined
                      }
                      onHoldingsSelect={setSelectedHoldings}
                      onAddHoldingClick={openAddHoldingDrawer}
                    />
                  )}
                </FormItem>
                {selectedHoldings.length === 1 && (
                  <HoldingCard holding={selectedHoldings[0]} />
                )}
              </Margin>
            )}

            <Margin bottom="lg" top="lg">
              <OrderEntrySection
                activeAction={activeAction}
                remainingQuantity={quantities.remainingQuantity}
                sizeType={orderSizeType}
                onSizeTypeChange={toggleSizeType}
                company={company}
                visibility={formValues.visibility}
              />
            </Margin>

            <Margin bottom="lg" top="lg">
              <OrderPreferencesSection
                activeAction={activeAction}
                assetTypes={allowedAssetTypeOptions}
                source={source}
                brokerageFirm={brokerageFirm}
                isObo={!!obo}
                isIndividualAccountSelected={isIndividualAccountSelected}
                issuer={company}
                selectedAccount={selectedAccount}
              />
            </Margin>

            <OrderAlerts
              formValues={formValues}
              brokerageFirm={brokerageFirm}
              source={source}
            />
          </>
        )}
      </Form>
    </S.OrderPlacementFormContainer>
  );
};
