import React from 'react';

import { getSideTooltipPosition } from '@npm/core/ui/components/atoms/ChartTooltip/ChartTooltip.utils';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  DATE_FORMATS,
  formatDate,
  parseDateToString,
} from '@npm/core/ui/utils/formatters';
import { type Chart, type TooltipModel } from 'chart.js';
import { throttle } from 'lodash';
import { useTheme } from 'styled-components';

import {
  getBidsColor,
  getOffersColor,
  getTradesColor,
} from '../../../BidOfferHistory/BidOfferHistory.utils';
import { type CompanyOverviewChartSeries } from '../../../CompanyOverview.types';
import { ChartLineIcon } from '../../../components/ChartLineIcon/ChartLineIcon';
import { PointLineIcon } from '../../../components/PointLineIcon';
import { getMutualFundMarksColor } from '../../../MutualFundMarks/MutualFundMarks.utils';
import { getPrimaryRoundsColor } from '../../../PrimaryRounds/PrimaryRounds.utils';
import { getTapeDColor } from '../../../TapeD/TapeD.utils';
import { getValuations409AColor } from '../../../Valuations409A/Valuations409A.utils';
import {
  type CompanyOverviewSummaryChartDataPoint,
  type SummaryChartTooltipData,
} from '../SummaryChart.types';
import { SUMMARY_CHART_TOOLTIP_HTML_ID } from '../SummaryChart.utils';

import * as S from './SummaryChartTooltip.styles';
import { SummaryChartTooltipRow } from './SummaryChartTooltipRow';

export const SUMMARY_CHART_TOOLTIP_PPS_HEIGHT = 308;
export const SUMMARY_CHART_TOOLTIP_VAL_HEIGHT = 272;
export const SUMMARY_CHART_TOOLTIP_WIDTH = 260;

type Props = {
  date: string | undefined;
  series: CompanyOverviewChartSeries;
  chartRef: React.RefObject<
    Chart<'line', CompanyOverviewSummaryChartDataPoint[], string>
  >;
} & SummaryChartTooltipData;

export const SummaryChartTooltip = ({
  date,
  series,
  chartRef,
  tapeDValue,
  bidValue,
  offerValue,
  tradeValue,
  primaryRoundValue,
  valuation409AValue,
  movingAverageValue,
}: Props) => {
  const theme = useTheme();

  if (!chartRef.current) {
    return null;
  }

  return (
    <div id={SUMMARY_CHART_TOOLTIP_HTML_ID} className="html-chart-tooltip">
      <Flex direction={'column'} gap={'sm'}>
        <S.Header>
          <Text size={'sm'} weight={'bold'}>
            Summary ({series === 'PPS' ? 'PPS' : 'Val.'})
          </Text>

          <Text size={'xs'} colorVariant={'secondary'}>
            {formatDate(date, { dateFormat: DATE_FORMATS.DATE })}
          </Text>
        </S.Header>
        <S.Body>
          <SummaryChartTooltipRow
            icon={<ChartLineIcon color={getTapeDColor(theme)} />}
            label={'Tape D'}
            value={tapeDValue}
          />
          <SummaryChartTooltipRow
            icon={<PointLineIcon color={getPrimaryRoundsColor(theme)} />}
            label={'Primary Round'}
            value={primaryRoundValue}
          />
          <SummaryChartTooltipRow
            icon={<Icon name="segment" size="xs" color={getTradesColor()} />}
            label={'Reported Trade Level'}
            value={tradeValue}
          />
          <SummaryChartTooltipRow
            icon={<Icon name="segment" size="xs" color={getBidsColor(theme)} />}
            label={'Bid Level'}
            value={bidValue}
          />
          <SummaryChartTooltipRow
            icon={
              <Icon name="segment" size="xs" color={getOffersColor(theme)} />
            }
            label={'Ask Level'}
            value={offerValue}
          />

          {series === 'PPS' && (
            <SummaryChartTooltipRow
              icon={<PointLineIcon color={getValuations409AColor(theme)} />}
              label={'409A Valuation'}
              value={valuation409AValue}
            />
          )}
          <SummaryChartTooltipRow
            icon={<ChartLineIcon color={getMutualFundMarksColor(theme)} />}
            label={'Mutual Fund Mark'}
            value={movingAverageValue}
          />
        </S.Body>
      </Flex>
    </div>
  );
};

export const getSummaryChartTooltipElement = () => {
  return document.getElementById(SUMMARY_CHART_TOOLTIP_HTML_ID);
};

export const showSummaryChartTooltip = throttle(
  ({
    context: { chart, tooltip },
    setHoveredDate,
    isPPS,
  }: {
    context: { chart: Chart; tooltip: TooltipModel<'line'> };
    setHoveredDate: (hoveredDate: string | null) => void;
    isPPS: boolean;
  }) => {
    const tooltipElement = getSummaryChartTooltipElement();
    if (!tooltipElement) return;

    const isActive = tooltip.opacity !== 0 || tooltipElement.matches(':hover');

    if (!isActive) {
      setHoveredDate(null);
      tooltipElement.style.display = 'none';
      return;
    }

    const dataPoint = tooltip.dataPoints?.[0];
    if (!dataPoint) return;

    setHoveredDate(
      parseDateToString(new Date(dataPoint.parsed.x), DATE_FORMATS.DATE_NUMERIC)
    );

    const height = isPPS
      ? SUMMARY_CHART_TOOLTIP_PPS_HEIGHT
      : SUMMARY_CHART_TOOLTIP_VAL_HEIGHT;

    const tooltipPosition = getSideTooltipPosition(
      dataPoint.element,
      chart.chartArea,
      SUMMARY_CHART_TOOLTIP_WIDTH,
      height,
      20
    );

    tooltipElement.style.display = 'block';
    tooltipElement.style.top = '0px';
    tooltipElement.style.left = '0px';
    tooltipElement.style.width = SUMMARY_CHART_TOOLTIP_WIDTH + 'px';
    tooltipElement.style.height = height + 'px';
    tooltipElement.style.transform = `translateX(${tooltipPosition.x}px) translateY(${tooltipPosition.y}px)`;
    tooltipElement.style.pointerEvents = 'none';
    tooltipElement.style.transition = 'all 0.1s ease-in-out';
  },
  100
);
