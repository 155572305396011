import styled from 'styled-components';

export const InstructionsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;
`;

export const DrawerTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing.sm}px;
`;
