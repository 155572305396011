/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FilingCreate } from '../model';
// @ts-ignore
import { FilingCreateRequestContract } from '../model';
// @ts-ignore
import { FilingIndex } from '../model';
// @ts-ignore
import { InternalFilingIndex } from '../model';
// @ts-ignore
import { InternalFilingShow } from '../model';
// @ts-ignore
import { InternalFilingStateShow } from '../model';
/**
 * FilingsApi - axios parameter creator
 * @export
 */
export const FilingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get company filings
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFilings: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyFilings', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/companies/{company_id}/filings`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new filing
         * @param {FilingCreateRequestContract} filingCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingCreate: async (filingCreateRequestContract: FilingCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filingCreateRequestContract' is not null or undefined
            assertParamExists('filingCreate', 'filingCreateRequestContract', filingCreateRequestContract)
            const localVarPath = changeUrl(`/api/filings`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filingCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get filing\'s file
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingsShow: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filingsShow', 'id', id)
            const localVarPath = changeUrl(`/api/filings/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteFiling: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDeleteFiling', 'id', id)
            const localVarPath = changeUrl(`/api/internal/filings/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a filing state
         * @param {string} companyId 
         * @param {string} filingNumber 
         * @param {string} filingState 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {boolean} [primary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingStatesCreate: async (companyId: string, filingNumber: string, filingState: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, primary?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalFilingStatesCreate', 'companyId', companyId)
            // verify required parameter 'filingNumber' is not null or undefined
            assertParamExists('internalFilingStatesCreate', 'filingNumber', filingNumber)
            // verify required parameter 'filingState' is not null or undefined
            assertParamExists('internalFilingStatesCreate', 'filingState', filingState)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/filing_states`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (filingNumber !== undefined) { 
                localVarFormParams.append('filing_number', filingNumber as any);
            }
    
            if (filingState !== undefined) { 
                localVarFormParams.append('filing_state', filingState as any);
            }
    
            if (primary !== undefined) { 
                localVarFormParams.append('primary', primary as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a filing
         * @param {string} companyId 
         * @param {number} filingType 
         * @param {string} filingDate 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {string} [url] 
         * @param {boolean} [active] 
         * @param {string} [fileName] 
         * @param {string} [documentType] 
         * @param {string} [mimeType] 
         * @param {string} [description] 
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsCreate: async (companyId: string, filingType: number, filingDate: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, url?: string, active?: boolean, fileName?: string, documentType?: string, mimeType?: string, description?: string, file?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalFilingsCreate', 'companyId', companyId)
            // verify required parameter 'filingType' is not null or undefined
            assertParamExists('internalFilingsCreate', 'filingType', filingType)
            // verify required parameter 'filingDate' is not null or undefined
            assertParamExists('internalFilingsCreate', 'filingDate', filingDate)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/filings`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (filingType !== undefined) { 
                localVarFormParams.append('filing_type', filingType as any);
            }
    
            if (filingDate !== undefined) { 
                localVarFormParams.append('filing_date', filingDate as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
            if (active !== undefined) { 
                localVarFormParams.append('active', active as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('file_name', fileName as any);
            }
    
            if (documentType !== undefined) { 
                localVarFormParams.append('document_type', documentType as any);
            }
    
            if (mimeType !== undefined) { 
                localVarFormParams.append('mime_type', mimeType as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get filings for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsIndex: async (companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('internalFilingsIndex', 'companyId', companyId)
            const localVarPath = changeUrl(`/api/internal/companies/{company_id}/filings`)
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary COI Filing data
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsLlmCoi: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalFilingsLlmCoi', 'id', id)
            const localVarPath = changeUrl(`/api/internal/filings/{id}/llm_coi`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsShow: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalFilingsShow', 'id', id)
            const localVarPath = changeUrl(`/api/internal/filings/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {boolean} [active] 
         * @param {string} [description] 
         * @param {string} [documentType] 
         * @param {string} [fileName] 
         * @param {number} [filingType] 
         * @param {string} [mimeType] 
         * @param {string} [url] 
         * @param {number} [companyId] 
         * @param {string} [file] 
         * @param {string} [llmCoiUrl] 
         * @param {boolean} [llmCoiProcessed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateFiling: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, active?: boolean, description?: string, documentType?: string, fileName?: string, filingType?: number, mimeType?: string, url?: string, companyId?: number, file?: string, llmCoiUrl?: string, llmCoiProcessed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateFiling', 'id', id)
            const localVarPath = changeUrl(`/api/internal/filings/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (includeField) {
                localVarFormParams.append('include_field', JSON.stringify(includeField));
            }

    
            if (active !== undefined) { 
                localVarFormParams.append('active', active as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (documentType !== undefined) { 
                localVarFormParams.append('document_type', documentType as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('file_name', fileName as any);
            }
    
            if (filingType !== undefined) { 
                localVarFormParams.append('filing_type', filingType as any);
            }
    
            if (mimeType !== undefined) { 
                localVarFormParams.append('mime_type', mimeType as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
            if (companyId !== undefined) { 
                localVarFormParams.append('company_id', companyId as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (llmCoiUrl !== undefined) { 
                localVarFormParams.append('llm_coi_url', llmCoiUrl as any);
            }
    
            if (llmCoiProcessed !== undefined) { 
                localVarFormParams.append('llm_coi_processed', llmCoiProcessed as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilingsApi - functional programming interface
 * @export
 */
export const FilingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get company filings
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyFilings(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilingIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyFilings(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a new filing
         * @param {FilingCreateRequestContract} filingCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filingCreate(filingCreateRequestContract: FilingCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilingCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filingCreate(filingCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get filing\'s file
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filingsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filingsShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDeleteFiling(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDeleteFiling(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a filing state
         * @param {string} companyId 
         * @param {string} filingNumber 
         * @param {string} filingState 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {boolean} [primary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFilingStatesCreate(companyId: string, filingNumber: string, filingState: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, primary?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFilingStateShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFilingStatesCreate(companyId, filingNumber, filingState, xNPMAuthorization, xSubscriptionPlan, includeField, primary, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a filing
         * @param {string} companyId 
         * @param {number} filingType 
         * @param {string} filingDate 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {string} [url] 
         * @param {boolean} [active] 
         * @param {string} [fileName] 
         * @param {string} [documentType] 
         * @param {string} [mimeType] 
         * @param {string} [description] 
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFilingsCreate(companyId: string, filingType: number, filingDate: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, url?: string, active?: boolean, fileName?: string, documentType?: string, mimeType?: string, description?: string, file?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFilingShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFilingsCreate(companyId, filingType, filingDate, xNPMAuthorization, xSubscriptionPlan, includeField, url, active, fileName, documentType, mimeType, description, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get filings for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFilingsIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFilingIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFilingsIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary COI Filing data
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFilingsLlmCoi(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFilingsLlmCoi(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalFilingsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFilingShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalFilingsShow(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {boolean} [active] 
         * @param {string} [description] 
         * @param {string} [documentType] 
         * @param {string} [fileName] 
         * @param {number} [filingType] 
         * @param {string} [mimeType] 
         * @param {string} [url] 
         * @param {number} [companyId] 
         * @param {string} [file] 
         * @param {string} [llmCoiUrl] 
         * @param {boolean} [llmCoiProcessed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateFiling(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, active?: boolean, description?: string, documentType?: string, fileName?: string, filingType?: number, mimeType?: string, url?: string, companyId?: number, file?: string, llmCoiUrl?: string, llmCoiProcessed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalFilingShow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateFiling(id, xNPMAuthorization, xSubscriptionPlan, includeField, active, description, documentType, fileName, filingType, mimeType, url, companyId, file, llmCoiUrl, llmCoiProcessed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilingsApi - factory interface
 * @export
 */
export const FilingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilingsApiFp(configuration)
    return {
        /**
         * 
         * @summary get company filings
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyFilings(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<FilingIndex> {
            return localVarFp.companyFilings(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new filing
         * @param {FilingCreateRequestContract} filingCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingCreate(filingCreateRequestContract: FilingCreateRequestContract, options?: any): AxiosPromise<FilingCreate> {
            return localVarFp.filingCreate(filingCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get filing\'s file
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filingsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.filingsShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDeleteFiling(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalDeleteFiling(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a filing state
         * @param {string} companyId 
         * @param {string} filingNumber 
         * @param {string} filingState 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {boolean} [primary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingStatesCreate(companyId: string, filingNumber: string, filingState: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, primary?: boolean, options?: any): AxiosPromise<InternalFilingStateShow> {
            return localVarFp.internalFilingStatesCreate(companyId, filingNumber, filingState, xNPMAuthorization, xSubscriptionPlan, includeField, primary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a filing
         * @param {string} companyId 
         * @param {number} filingType 
         * @param {string} filingDate 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {string} [url] 
         * @param {boolean} [active] 
         * @param {string} [fileName] 
         * @param {string} [documentType] 
         * @param {string} [mimeType] 
         * @param {string} [description] 
         * @param {string} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsCreate(companyId: string, filingType: number, filingDate: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, url?: string, active?: boolean, fileName?: string, documentType?: string, mimeType?: string, description?: string, file?: string, options?: any): AxiosPromise<InternalFilingShow> {
            return localVarFp.internalFilingsCreate(companyId, filingType, filingDate, xNPMAuthorization, xSubscriptionPlan, includeField, url, active, fileName, documentType, mimeType, description, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get filings for a company
         * @param {string} companyId 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsIndex(companyId: string, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFilingIndex> {
            return localVarFp.internalFilingsIndex(companyId, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary COI Filing data
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsLlmCoi(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<void> {
            return localVarFp.internalFilingsLlmCoi(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalFilingsShow(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalFilingShow> {
            return localVarFp.internalFilingsShow(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a filing
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [includeField] 
         * @param {boolean} [active] 
         * @param {string} [description] 
         * @param {string} [documentType] 
         * @param {string} [fileName] 
         * @param {number} [filingType] 
         * @param {string} [mimeType] 
         * @param {string} [url] 
         * @param {number} [companyId] 
         * @param {string} [file] 
         * @param {string} [llmCoiUrl] 
         * @param {boolean} [llmCoiProcessed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateFiling(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, includeField?: Array<string>, active?: boolean, description?: string, documentType?: string, fileName?: string, filingType?: number, mimeType?: string, url?: string, companyId?: number, file?: string, llmCoiUrl?: string, llmCoiProcessed?: boolean, options?: any): AxiosPromise<InternalFilingShow> {
            return localVarFp.internalUpdateFiling(id, xNPMAuthorization, xSubscriptionPlan, includeField, active, description, documentType, fileName, filingType, mimeType, url, companyId, file, llmCoiUrl, llmCoiProcessed, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyFilings operation in FilingsApi.
 * @export
 * @interface FilingsApiCompanyFilingsRequest
 */
export interface FilingsApiCompanyFilingsRequest {
    /**
     * 
     * @type {string}
     * @memberof FilingsApiCompanyFilings
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiCompanyFilings
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiCompanyFilings
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for filingCreate operation in FilingsApi.
 * @export
 * @interface FilingsApiFilingCreateRequest
 */
export interface FilingsApiFilingCreateRequest {
    /**
     * 
     * @type {FilingCreateRequestContract}
     * @memberof FilingsApiFilingCreate
     */
    readonly filingCreateRequestContract: FilingCreateRequestContract
}

/**
 * Request parameters for filingsShow operation in FilingsApi.
 * @export
 * @interface FilingsApiFilingsShowRequest
 */
export interface FilingsApiFilingsShowRequest {
    /**
     * 
     * @type {number}
     * @memberof FilingsApiFilingsShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiFilingsShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiFilingsShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalDeleteFiling operation in FilingsApi.
 * @export
 * @interface FilingsApiInternalDeleteFilingRequest
 */
export interface FilingsApiInternalDeleteFilingRequest {
    /**
     * 
     * @type {number}
     * @memberof FilingsApiInternalDeleteFiling
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalDeleteFiling
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalDeleteFiling
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFilingStatesCreate operation in FilingsApi.
 * @export
 * @interface FilingsApiInternalFilingStatesCreateRequest
 */
export interface FilingsApiInternalFilingStatesCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingStatesCreate
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingStatesCreate
     */
    readonly filingNumber: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingStatesCreate
     */
    readonly filingState: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingStatesCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingStatesCreate
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FilingsApiInternalFilingStatesCreate
     */
    readonly includeField?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof FilingsApiInternalFilingStatesCreate
     */
    readonly primary?: boolean
}

/**
 * Request parameters for internalFilingsCreate operation in FilingsApi.
 * @export
 * @interface FilingsApiInternalFilingsCreateRequest
 */
export interface FilingsApiInternalFilingsCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly companyId: string

    /**
     * 
     * @type {number}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly filingType: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly filingDate: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly includeField?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly url?: string

    /**
     * 
     * @type {boolean}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly active?: boolean

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly fileName?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly documentType?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly mimeType?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsCreate
     */
    readonly file?: string
}

/**
 * Request parameters for internalFilingsIndex operation in FilingsApi.
 * @export
 * @interface FilingsApiInternalFilingsIndexRequest
 */
export interface FilingsApiInternalFilingsIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFilingsLlmCoi operation in FilingsApi.
 * @export
 * @interface FilingsApiInternalFilingsLlmCoiRequest
 */
export interface FilingsApiInternalFilingsLlmCoiRequest {
    /**
     * 
     * @type {number}
     * @memberof FilingsApiInternalFilingsLlmCoi
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsLlmCoi
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsLlmCoi
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalFilingsShow operation in FilingsApi.
 * @export
 * @interface FilingsApiInternalFilingsShowRequest
 */
export interface FilingsApiInternalFilingsShowRequest {
    /**
     * 
     * @type {number}
     * @memberof FilingsApiInternalFilingsShow
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsShow
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalFilingsShow
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for internalUpdateFiling operation in FilingsApi.
 * @export
 * @interface FilingsApiInternalUpdateFilingRequest
 */
export interface FilingsApiInternalUpdateFilingRequest {
    /**
     * 
     * @type {number}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly includeField?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly active?: boolean

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly documentType?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly fileName?: string

    /**
     * 
     * @type {number}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly filingType?: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly mimeType?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly url?: string

    /**
     * 
     * @type {number}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly companyId?: number

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly file?: string

    /**
     * 
     * @type {string}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly llmCoiUrl?: string

    /**
     * 
     * @type {boolean}
     * @memberof FilingsApiInternalUpdateFiling
     */
    readonly llmCoiProcessed?: boolean
}

/**
 * FilingsApi - object-oriented interface
 * @export
 * @class FilingsApi
 * @extends {BaseAPI}
 */
export class FilingsApi extends BaseAPI {
    /**
     * 
     * @summary get company filings
     * @param {FilingsApiCompanyFilingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public companyFilings(requestParameters: FilingsApiCompanyFilingsRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).companyFilings(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new filing
     * @param {FilingsApiFilingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public filingCreate(requestParameters: FilingsApiFilingCreateRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).filingCreate(requestParameters.filingCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get filing\'s file
     * @param {FilingsApiFilingsShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public filingsShow(requestParameters: FilingsApiFilingsShowRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).filingsShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a filing
     * @param {FilingsApiInternalDeleteFilingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public internalDeleteFiling(requestParameters: FilingsApiInternalDeleteFilingRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).internalDeleteFiling(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a filing state
     * @param {FilingsApiInternalFilingStatesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public internalFilingStatesCreate(requestParameters: FilingsApiInternalFilingStatesCreateRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).internalFilingStatesCreate(requestParameters.companyId, requestParameters.filingNumber, requestParameters.filingState, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.includeField, requestParameters.primary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a filing
     * @param {FilingsApiInternalFilingsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public internalFilingsCreate(requestParameters: FilingsApiInternalFilingsCreateRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).internalFilingsCreate(requestParameters.companyId, requestParameters.filingType, requestParameters.filingDate, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.includeField, requestParameters.url, requestParameters.active, requestParameters.fileName, requestParameters.documentType, requestParameters.mimeType, requestParameters.description, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get filings for a company
     * @param {FilingsApiInternalFilingsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public internalFilingsIndex(requestParameters: FilingsApiInternalFilingsIndexRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).internalFilingsIndex(requestParameters.companyId, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary COI Filing data
     * @param {FilingsApiInternalFilingsLlmCoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public internalFilingsLlmCoi(requestParameters: FilingsApiInternalFilingsLlmCoiRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).internalFilingsLlmCoi(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a filing
     * @param {FilingsApiInternalFilingsShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public internalFilingsShow(requestParameters: FilingsApiInternalFilingsShowRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).internalFilingsShow(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a filing
     * @param {FilingsApiInternalUpdateFilingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApi
     */
    public internalUpdateFiling(requestParameters: FilingsApiInternalUpdateFilingRequest, options?: AxiosRequestConfig) {
        return FilingsApiFp(this.configuration).internalUpdateFiling(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.includeField, requestParameters.active, requestParameters.description, requestParameters.documentType, requestParameters.fileName, requestParameters.filingType, requestParameters.mimeType, requestParameters.url, requestParameters.companyId, requestParameters.file, requestParameters.llmCoiUrl, requestParameters.llmCoiProcessed, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompanyFilingsErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompanyFilingsErrorCodes = [
    401,
    404,
];

export type FilingCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FilingCreateErrorCodes = [
];

export type FilingsShowErrorTypes =
    | {
        status: 302;
    }

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FilingsShowErrorCodes = [
    302,
    404,
];

export type InternalDeleteFilingErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalDeleteFilingErrorCodes = [
    404,
];

export type InternalFilingStatesCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFilingStatesCreateErrorCodes = [
];

export type InternalFilingsCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFilingsCreateErrorCodes = [
];

export type InternalFilingsIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFilingsIndexErrorCodes = [
    401,
    404,
];

export type InternalFilingsLlmCoiErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFilingsLlmCoiErrorCodes = [
];

export type InternalFilingsShowErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalFilingsShowErrorCodes = [
    404,
];

export type InternalUpdateFilingErrorTypes =

    | {
        status: 404;
        data: Error;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalUpdateFilingErrorCodes = [
    404,
    422,
];


