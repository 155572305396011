import React from 'react';
import { type LinkProps } from 'react-router-dom';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useNavigate } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  type investorWorkstationRouteKeys,
  type secondMarketRouteKeys,
} from '@npm/core/ui/routes';
import {
  CbAccountType,
  CbAccountUiMode,
  CbEventStatus,
  type Codebook,
  useEventList,
  useWatchlistIndex,
} from '@npm/data-access';

import { isNPMS } from '../../auth/user/context';
import { useCurrentAccount } from '../../auth/user/role';

import { IconBox } from './IconBox';

type OnboardingCompletionProps = {
  modalProps: React.ComponentProps<typeof Modal>;
  accountId?: number;
};

type FooterProps = {
  handleRedirect: (to: LinkProps['to']) => void;
  isNPMSAccount: boolean;
  accountUIMode: Codebook;
  isIndividualAccount: boolean;
  watchlistCompanyId: number | undefined;
  isLoading: boolean;
  isMobile: boolean;
};

const getFooterButtonsProps = ({
  handleRedirect,
  isNPMSAccount,
  accountUIMode,
  isIndividualAccount,
  watchlistCompanyId,
  isLoading,
  isMobile,
}: FooterProps): Partial<OnboardingCompletionProps['modalProps']> => {
  const isSellerOnlyNPMSIndividual =
    isNPMSAccount &&
    isIndividualAccount &&
    accountUIMode?.code !== CbAccountUiMode.items.buyer_and_seller;

  if (isLoading) {
    return {
      footer: (
        <Skeleton.Button
          block={isMobile}
          style={{ width: isMobile ? '100%' : '160px' }}
        />
      ),
    };
  }

  const okButtonProps = {
    icon: <Icon name="external-link" size="xs" />,
  };

  if (isSellerOnlyNPMSIndividual) {
    const redirectPath: investorWorkstationRouteKeys | secondMarketRouteKeys =
      watchlistCompanyId
        ? '/second-market/investor/sell-indication'
        : '/investor-workstation/account/holdings';

    const queryParams = watchlistCompanyId
      ? `?companyId=${watchlistCompanyId}&addHolding=1`
      : `?addHolding=1`;

    return {
      okText: '+ Add Holdings',
      onOk: () => handleRedirect(`${redirectPath}${queryParams}`),
    };
  }

  if (isNPMSAccount) {
    const redirectPath: investorWorkstationRouteKeys = isIndividualAccount
      ? '/investor-workstation/individual-dashboard'
      : '/investor-workstation/entity-dashboard';

    return {
      okText: 'Go to Dashboard',
      onOk: () => handleRedirect(redirectPath),
      okButtonProps,
    };
  }

  return {
    okText: 'Continue to Live Programs',
    onOk: () =>
      handleRedirect(
        '/investor-workstation/programs/live-programs' satisfies investorWorkstationRouteKeys
      ),
    okButtonProps,
  };
};

export const OnboardingCompletedModal = ({
  accountId,
  modalProps,
}: OnboardingCompletionProps) => {
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();
  const currentAccount = useCurrentAccount();

  const isNPMSAccount = isNPMS({
    brokerage_firm_name: currentAccount?.account?.brokerage_firm?.name,
  });
  const accountUIMode = currentAccount?.account?.account_ui_mode;
  const isIndividualAccount =
    currentAccount?.account?.type?.code === CbAccountType.items.PersonAccount;
  const isSellerOnlyIndividual =
    isIndividualAccount &&
    accountUIMode?.code !== CbAccountUiMode.items.buyer_and_seller;

  const { data, isFetching } = useEventList(
    { accountId, eventStatus: CbEventStatus.items.ACTIVE, size: 0 },
    {
      queryConfig: {
        enabled: !!accountId && modalProps.open && !isNPMSAccount,
      },
    }
  );

  const hasLivePrograms = data?.pagination?.total_records > 0;

  const { data: watchlistData } = useWatchlistIndex(
    {
      accountId,
      size: 1,
    },
    {
      queryConfig: {
        enabled:
          !!accountId &&
          modalProps.open &&
          isNPMSAccount &&
          isSellerOnlyIndividual,
      },
    }
  );

  const watchlistCompanyId = watchlistData?.watchlists?.[0]?.issuer_entity_id;

  const handleRedirect = (to: LinkProps['to']) => {
    navigate(to);
  };

  const renderMessage = () => {
    if (isNPMSAccount) {
      if (!isSellerOnlyIndividual) {
        return 'Click on "Go to Dashboard" to add holdings to your portfolio and/or add companies to your watchlist.';
      }
    } else {
      if (hasLivePrograms) {
        return 'Click on "Continue to Live Programs" to participate in an event.';
      }
    }

    return '';
  };

  return (
    <Modal
      {...modalProps}
      {...getFooterButtonsProps({
        handleRedirect,
        isNPMSAccount,
        accountUIMode,
        isIndividualAccount,
        isLoading: isFetching,
        isMobile,
        watchlistCompanyId,
      })}
    >
      <Flex>
        <IconBox />
        <div>
          <Heading variant="h2" marginBottom="sm">
            Congratulations!
          </Heading>
          <Text size="sm">
            You have successfully completed all Onboarding steps.{' '}
            {renderMessage()}
          </Text>
        </div>
      </Flex>
    </Modal>
  );
};
