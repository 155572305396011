import { withErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type CompanyPricingApi } from '@npm/data-access';
import useResizeObserver from 'use-resize-observer';

import { BidOfferHistoryChart } from './components/BidOfferHistoryChart/BidOfferHistoryChart';
import { getLevelsColumns, Levels } from './components/Levels';

import { getTradesColor } from './BidOfferHistory.utils';

type Props = {
  isCovered: boolean;
  data: CompanyPricingApi.HistoricalPriceIndex;
};

export const ClosedTrades = withErrorBoundary(
  ({ isCovered, data }: Props) => {
    const { ref: containerRef, width: containerWidth } = useResizeObserver();

    return (
      <Flex direction="column" gap="md">
        <BidOfferHistoryChart
          data={
            isCovered
              ? data.historical_prices
              : { ...data.historical_prices, primary_rounds: [] }
          }
          mode="trades"
        />
        {/* containerWidth and containerRef is here because table with scroll={{y:240}} breaks responsiveness */}
        <div key={containerWidth} ref={containerRef}>
          <Levels
            title="Reported Trade Levels"
            items={data?.historical_prices?.aggregated_trades}
            noDataTitle={'No Reported Trades'}
            icon={<Icon name="segment" size="xs" color={getTradesColor()} />}
            columns={getLevelsColumns({ showTotalSize: true })}
          />
        </div>
      </Flex>
    );
  },
  {
    FallbackComponent: ErrorSkeleton,
  }
);
