import { type ReactNode } from 'react';

import { type ModalProps } from 'antd/lib/modal';

import { Modal } from '../../molecules/Modal';

type Props = Pick<
  ModalProps,
  'open' | 'onCancel' | 'onOk' | 'cancelText' | 'okText'
> & {
  children?: ReactNode;
};

export const DiscardModal = ({
  open,
  onOk,
  onCancel,
  okText = 'Yes, discard',
  cancelText = 'Not sure',
  children,
}: Props) => {
  return (
    <Modal
      okText={okText}
      cancelText={cancelText}
      title="Exit without saving"
      open={open}
      onOk={onOk}
      okButtonProps={{ color: 'error' }}
      onCancel={onCancel}
      size="md"
    >
      {children
        ? children
        : 'You are trying to close the window and your changes won’t be saved, do you want to discard the changes?'}
    </Modal>
  );
};
