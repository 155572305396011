import { Button } from '@npm/core/ui/components/atoms/Button';
import styled from 'styled-components';

import { FiltersToggleButton as BaseFiltersToggleButton } from '../../../../filters/filters/FilterPanel/components';

import { FILTER_PANEL_HORIZONTAL_PADDING } from './IssuerEntityIndexFilter.styles';

export const Divider = styled.div`
  height: 1px;
  margin: 0 ${({ theme }) => -theme.spacing[FILTER_PANEL_HORIZONTAL_PADDING]}px;
  background: ${({ theme }) => theme.color.general.borders.primary};
`;

export const CloseButton = styled(Button)`
  && {
    min-width: 40px;
    color: ${({ theme }) => theme.color.general.typography.primary};
  }
`;

export const FiltersToggleButton = styled(BaseFiltersToggleButton)`
  && {
    align-self: unset;
    height: 32px;
  }
`;
