import React, { Fragment } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type NumberFormatOptions } from '@npm/core/ui/utils/formatters';
import { type Codebook } from '@npm/data-access';
import { type IconProps } from '@npm/utils';
import { useTheme } from 'styled-components';

import { PremiumTextGuard } from '../../../../auth/user/components';

import * as S from './SectionStats.styles';

type Props = {
  key: string;
  title: string;
  value: string | number | [string | number, string | number];
  subTitle?: string;
  iconName?: IconProps['name'];
  isPremium?: boolean;
  tooltipInfo?: React.ReactNode;
  isLoading?: boolean;
  // doesn't render currency if `false`
  denomination?: Codebook | false;
  valueFormatOptions?: NumberFormatOptions;
  onClick?: () => void;
};

export const StatItem = ({
  title,
  value,
  subTitle,
  iconName,
  isPremium,
  tooltipInfo,
  isLoading,
  denomination,
  valueFormatOptions,
  onClick,
}: Props) => {
  const theme = useTheme();

  return (
    <S.Item onClick={onClick} $isClickable={!!onClick}>
      {isLoading ? (
        <Skeleton.Base
          noMargin
          style={{ margin: '12px 0' }}
          title={{
            width: 100,
          }}
          paragraph={{ rows: 1, style: { marginTop: 8 }, width: 50 }}
        />
      ) : (
        <>
          <div>
            <Flex justify="space-between" gap="md">
              <S.TitleContainer>
                {iconName && <Icon name={iconName} size="xs" />}
                <Text size="sm">{title}</Text>
              </S.TitleContainer>
              {tooltipInfo && (
                // the stopPropagation is needed cos stat item is clickable (on mobile if you would click on the tooltip, it would perform the action rather than show the tooltip)
                <div onClick={e => e.stopPropagation()}>
                  <Tooltip title={tooltipInfo}>
                    <Text color="info">
                      <Icon size="sm" name="info-circle" />
                    </Text>
                  </Tooltip>
                </div>
              )}
            </Flex>
            <Text size="xs" colorVariant="tertiary">
              {subTitle}
            </Text>
          </div>
          <Flex>
            <PremiumTextGuard disabled={!isPremium}>
              <div>
                {(Array.isArray(value) ? value : [value]).map(
                  (singleValue, index) => (
                    <Fragment key={index}>
                      {index > 0 && (
                        <Text colorVariant="tertiary" as="span">
                          {' '}
                          •{' '}
                        </Text>
                      )}
                      {denomination === false ? (
                        <S.QuantityValue value={singleValue} />
                      ) : (
                        <S.PriceValue
                          value={singleValue}
                          compact
                          currency={denomination?.name || 'USD'}
                          formatOptions={{
                            maximumFractionDigits: 2,
                            ...valueFormatOptions,
                          }}
                        />
                      )}
                    </Fragment>
                  )
                )}
              </div>
            </PremiumTextGuard>
            {onClick && (
              <S.ArrowIconWrapper>
                <Icon size="xs" name="chevron-right" />
              </S.ArrowIconWrapper>
            )}
          </Flex>
        </>
      )}
    </S.Item>
  );
};
