import { useBreakpoint } from '@npm/core/ui/utils/style/breakpoints';
import { getFocusBorderStyles } from '@npm/core/ui/utils/style/mixins';
import styled from 'styled-components';

export const Button = styled.button<{
  $isSelected: boolean;
  $color: 'info' | 'warning';
}>`
  min-height: 180px;
  padding: ${({ theme }) => theme.spacing.xl}px;
  text-align: left;
  background: ${({ theme }) => theme.color.general.layout.zero};
  border-radius: ${({ theme }) => theme.borderRadius.lg}px;
  border: 1px solid
    ${({ theme, $isSelected, $color }) =>
      $isSelected
        ? theme.color[$color].typography.primary
        : theme.color.transparent};

  body.tab-focus ${getFocusBorderStyles()};

  ${({ theme }) => useBreakpoint(theme).mobile`
    min-height: auto;
    padding: ${theme.spacing.md}px;
    padding-bottom: ${theme.spacing.md}px;
  `}
`;
