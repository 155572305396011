import React from 'react';

import { useTheme, type Variant } from 'styled-components';

import * as S from './Divider.styles';

type Margin = number | keyof ReturnType<typeof useTheme>['spacing'];

type Props = {
  type?: 'horizontal' | 'vertical';
  colorVariant?: Variant;
  marginBottom?: Margin;
  marginTop?: Margin;
  className?: string;
};

export const Divider = ({
  type = 'horizontal',
  colorVariant = 'primary',
  marginBottom,
  marginTop,
  className,
}: Props) => {
  const theme = useTheme();

  const getSpacing = (keyOrValue: Props['marginBottom']) => {
    if (typeof keyOrValue === 'string') {
      return theme.spacing[keyOrValue];
    }
    return keyOrValue;
  };

  return (
    <S.Divider
      type={type}
      className={className}
      $variant={colorVariant}
      $marginBottom={getSpacing(marginBottom)}
      $marginTop={getSpacing(marginTop)}
    />
  );
};
