import { type ComponentProps } from 'react';

import { type AutoScrollingForm } from '@npm/core/ui/components/organisms/AutoScrollingForm';
import { CbAccreditationQuestion } from '@npm/data-access';
import { type FormInstance } from 'antd';

import { type StructuredAccreditationFormData } from '../../Accreditation.types';
import { AutoScrollingQuestion } from '../AutoScrollingQuestion';

const HIDDEN_LABELS = ['total_investments', 'securities_license'];

export const getFormConfig: (
  accData: StructuredAccreditationFormData[],
  form: FormInstance
) => Pick<
  ComponentProps<typeof AutoScrollingForm>,
  'questions'
>['questions'] = accData =>
  accData.map(({ labels, question }) => ({
    title: question.name,
    name: question.code,
    subTitle: CbAccreditationQuestion.getAttributeValueByName(
      question,
      'subtext'
    ),
    titleVariant: 'h2',
    children: ({ onQuestionAnswered }) => (
      <AutoScrollingQuestion
        onQuestionAnswered={onQuestionAnswered}
        labels={labels.map(label => ({
          ...label,
          name: HIDDEN_LABELS.includes(label.code) ? null : label.name,
        }))}
      />
    ),
  }));
