import generatePicker from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import styled, { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { getSize, getVariant } from '../Button/Button.styles';
import { InputTextStyles } from '../Typography';

import { disabledCSS, errorStatusCSS, inputCSS, readOnlyCSS } from './styles';

export const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
const SUFFIX_WIDTH = 25;
export const StyledPanel = styled.div<{ $showTime: boolean }>`
  && {
    ${({ $showTime, theme }) =>
      $showTime
        ? css`
            .ant-picker-time-panel .ant-picker-time-panel-column {
              overflow-y: auto;
            }

            .ant-picker-header {
              color: ${theme.color.general.typography.primary};
            }

            .ant-picker-content
              .ant-picker-time-panel-cell
              .ant-picker-time-panel-cell-inner {
              color: ${theme.color.general.typography.primary};
            }

            .ant-picker-content
              .ant-picker-time-panel-cell
              .ant-picker-time-panel-cell-inner:hover {
              background-color: ${theme.color.general.backgrounds.muted};
            }

            .ant-picker-time-panel,
            .ant-picker-time-panel .ant-picker-time-panel-column {
              border-left-color: ${theme.color.general.borders.primary};
            }

            ${({ theme }) => useBreakpoint(theme).mobile`
              .ant-picker-date-panel {
                width: calc(100vw - 112px);
                max-width: 280px;
              }
              .ant-picker-time-panel {
                width: 112px;
              }
            `}
          `
        : css`
            .ant-picker-date-panel {
              width: 328px;
            }

            .ant-picker-content {
              width: 100%;
            }
          `}

    .ant-picker-cell-selected > .ant-picker-cell-inner,
    .ant-picker-cell-selected > .ant-picker-cell-inner:hover,
    .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner,
    .ant-picker-time-panel-cell-selected
      > .ant-picker-time-panel-cell-inner:hover {
      background-color: ${({ theme }) =>
        theme.color.info.borders.secondary} !important;
      color: ${({ theme }) =>
        theme.color.general.typography.primary} !important;
    }

    .ant-picker-cell-today > .ant-picker-cell-inner::before {
      border: 1px solid ${({ theme }) => theme.color.info.borders.primary};
    }

    /** This follows the same pattern as the original style */

    .ant-picker-cell-selected > .ant-picker-cell-inner::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: ${({ theme }) => theme.zIndex.dropdown};
      border: 1px solid ${({ theme }) => theme.color.info.borders.primary};
      border-radius: 2px;
      content: '';
    }

    .ant-picker-ok > button {
      ${({ theme }) => getSize('md', false, theme)};
      ${({ theme }) => getVariant('outline', 'info', theme)};
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)<{ $readOnly?: boolean }>`
  &.ant-picker {
    ${inputCSS};
    width: 100%;
    overflow: hidden;

    & > .ant-picker-input {
      & > input {
        ${InputTextStyles};
        padding-right: ${({ theme }) => theme.spacing.sm}px;
      }

      & > input::placeholder {
        color: ${({ theme }) => theme.color.general.typography.secondary};
      }

      & > .ant-picker-suffix {
        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          color: ${({ theme }) => theme.color.general.typography.secondary};
        }
      }

      /* A "cross" icon that shows up when you hover over a filled date  */

      & > .ant-picker-clear {
        right: calc(${SUFFIX_WIDTH}px + ${({ theme }) => theme.spacing.xs}px);
        color: ${({ theme }) => theme.color.info.typography.primary};
        background: none;
      }
    }

    &.ant-picker-focused .ant-picker-suffix > svg {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  &.ant-picker-small {
    padding: ${({ theme }) => theme.spacing.sm}px !important;
    min-height: 32px !important;
  }

  &.ant-picker-status-error:not(.ant-picker-disabled):not(
      .ant-picker-borderless
    ).ant-picker {
    ${errorStatusCSS};
  }

  &.ant-picker-status-warning:not(.ant-picker-disabled):not(
      .ant-picker-borderless
    ).ant-picker {
    ${errorStatusCSS}
  }

  &.ant-picker-disabled {
    ${disabledCSS};
  }

  ${({ $readOnly, theme }) =>
    $readOnly &&
    css`
      ${readOnlyCSS};

      &.ant-picker.ant-picker-focused {
        background-color: ${({ theme }) =>
          theme.color.general.backgrounds.muted};
        border-color: ${({ theme }) => theme.color.general.borders.secondary};
      }

      &.ant-picker {
        & > .ant-picker-input {
          & > .ant-picker-suffix {
            display: flex;
            justify-content: center;
            align-items: center;

            & > svg {
              color: ${theme.color.general.typography.muted};
            }
          }
        }
      }
    `};
`;
