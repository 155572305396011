import { formatQuantity } from '@npm/core/ui/utils/formatters';
import { type SecondmarketInvestorOrderItemShow } from '@npm/data-access';
import { type FormInstance } from 'antd';
import { type Rule } from 'antd/lib/form';
import { isNil } from 'lodash';

import { type OrderSizeType } from '../../../../order';
import { MINIMUM_NOTIONAL_VALUE } from '../../utils/minNotionalValue';

const minNotionalValueConfig = {
  value: 500000,
  errorMessage: 'Minimum notional value must be at least 500K USD',
};

const minIndividualEntityNotionalValueConfig = {
  value: MINIMUM_NOTIONAL_VALUE,
  errorMessage: 'Minimum notional value must be at least 25K USD',
};

const validatePositiveNumber = (
  value: number | null | undefined,
  required: boolean
) => {
  if (required) {
    if (isNil(value) || value <= 0) {
      return new Error('This field must be a positive number');
    }
  } else {
    if (!isNil(value) && value <= 0) {
      return new Error('This field must be either empty or a positive number');
    }
  }
  return null;
};

const validateRemainingQuantity = (
  quantityInShares: number,
  remainingQuantity: number,
  sizeType: OrderSizeType,
  pricePerShare?: number
) => {
  if (quantityInShares > remainingQuantity) {
    if (sizeType === 'USD')
      return pricePerShare
        ? new Error(
            `Size (${sizeType}) must be lower or equal to the remaining quantity of the selected holding * price per share (${formatQuantity(
              remainingQuantity * pricePerShare
            )})`
          )
        : null;

    return new Error(
      `Size (${sizeType}) must be lower or equal to the remaining quantity of the selected holding (${formatQuantity(
        remainingQuantity
      )})`
    );
  }
  return null;
};

export const getMinimumQuantityRules = (
  form: FormInstance,
  sizeType: OrderSizeType,
  required: boolean
): Rule[] => [
  {
    validator: (_, value) => {
      const quantity = form.getFieldValue('quantity');

      const error = validatePositiveNumber(value, required);
      if (error) return Promise.reject(error);
      if (!value) return Promise.resolve();

      if (sizeType === 'Shares' && value && !Number.isInteger(value)) {
        return Promise.reject(new Error('Partial shares are not allowed'));
      }

      if (value > quantity) {
        return Promise.reject(
          new Error('Minimum Size cannot be larger than Size')
        );
      }

      return Promise.resolve();
    },
  },
];

export const getQuantityRules = (
  activeAction: 'buy' | 'sell',
  sizeType: OrderSizeType,
  required: boolean,
  pricePerShare?: number,
  remainingQuantity?: number,
  shouldCheckHolding = true
): Rule[] => [
  {
    validator: (_, value) => {
      const error = validatePositiveNumber(value, required);
      if (error) return Promise.reject(error);
      if (!value) return Promise.resolve();

      if (sizeType === 'Shares' && value && !Number.isInteger(value)) {
        return Promise.reject(new Error('Partial shares are not allowed'));
      }

      if (activeAction === 'buy') return Promise.resolve();

      if (isNil(remainingQuantity) && shouldCheckHolding)
        return Promise.reject(new Error('Please select a holding first'));

      const quantityInShares =
        sizeType === 'USD' && pricePerShare ? value / pricePerShare : value;

      const remainingQuantityError = validateRemainingQuantity(
        quantityInShares,
        remainingQuantity,
        sizeType,
        pricePerShare
      );
      if (remainingQuantityError) return Promise.reject(remainingQuantityError);

      return Promise.resolve();
    },
  },
];

export const getPpsRules = (required: boolean): Rule[] => [
  {
    validator: (_, value) =>
      value > 0 || (!required && isNil(value))
        ? Promise.resolve()
        : Promise.reject(new Error('This field must be a positive number')),
  },
];

export const getEstimatedGrossPriceRules = (
  quantity: number,
  pricePerShare: number
): Rule[] => [
  {
    validator: () =>
      quantity * pricePerShare >= minNotionalValueConfig.value
        ? Promise.resolve()
        : Promise.reject(new Error(minNotionalValueConfig.errorMessage)),
  },
];

export const getNegotiateQuantityRules = (
  quantity: number,
  orderItem: SecondmarketInvestorOrderItemShow,
  checkForHolding: boolean,
  sizeType: OrderSizeType,
  remainingQuantity?: number,
  pricePerShare?: number
): Rule[] => [
  {
    validator: (_, value) => {
      const minimumQuantity = orderItem?.minimum_quantity;
      const totalValue = orderItem?.price * quantity;
      const checkNotionalValue =
        orderItem?.quantity === orderItem?.original_quantity;

      const error = validatePositiveNumber(value, true);
      if (error)
        return Promise.reject(new Error('Size must be greater than 0'));

      if (isNil(remainingQuantity) && checkForHolding) {
        return Promise.reject(new Error('Please select a holding first'));
      }

      if (value && !Number.isInteger(value)) {
        return Promise.reject(new Error('Partial shares are not allowed'));
      }

      if (!!minimumQuantity && quantity < minimumQuantity) {
        return Promise.reject(
          new Error(
            `Minimum order size is ${formatQuantity(minimumQuantity)} shares`
          )
        );
      }

      if (
        checkNotionalValue &&
        totalValue < minIndividualEntityNotionalValueConfig.value
      ) {
        return Promise.reject(
          new Error(minIndividualEntityNotionalValueConfig.errorMessage)
        );
      }

      if (quantity > orderItem?.quantity) {
        return Promise.reject(
          new Error(
            `Order size cannot be larger than ${formatQuantity(
              orderItem?.quantity
            )} shares`
          )
        );
      }

      const quantityInShares =
        sizeType === 'USD' && pricePerShare ? value / pricePerShare : value;

      if (checkForHolding) {
        const remainingQuantityError = validateRemainingQuantity(
          quantityInShares,
          remainingQuantity,
          sizeType,
          pricePerShare
        );
        if (remainingQuantityError)
          return Promise.reject(remainingQuantityError);
      }

      return Promise.resolve();
    },
  },
];

export const getCounterSizeRules = (
  drawerAction,
  price,
  orderPrice
): Rule[] => [
  {
    validator: async () => {
      if (orderPrice === price && drawerAction === 'counter') {
        return Promise.reject(
          new Error('Price Per Share must be different than the original price')
        );
      }
      return Promise.resolve();
    },
  },
];
