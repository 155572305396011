// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  PersonApi,
  PersonShowErrorCodes,
  PersonShowErrorTypes,
  PersonUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new PersonApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type PersonShowMethod = PersonApi['personShow'];
export type PersonShowResponseType = MethodResult<PersonShowMethod>;

export const usePersonShow = (
  variables: Parameters<PersonShowMethod>[0],
  config?: UseQueryConfig<
    PersonShowMethod,
    PersonShowErrorTypes
  >
) => {
  return useQuery<
    PersonShowMethod,
    PersonShowErrorTypes
  >(
    (...args) => api.personShow(...args),
    PersonShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PersonShow'],
        ...apiQueryConfig.PersonShow,
        ...config?.queryConfig
      },
    },
    'PersonShow',
  );
};

export const usePersonShowInfinite = (
  variables: Parameters<PersonShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    PersonShowMethod,
    PersonShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    PersonShowMethod,
    PersonShowErrorTypes
  >(
    (...args) => api.personShow(...args),
    PersonShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PersonShow', 'PersonShowInfinite'],
        ...apiQueryConfig.PersonShow,
        ...config?.queryConfig,
      },
    },
    'PersonShow',
  );
};

export const usePersonShowLazy = (baseOptions?: {
  variables?: Parameters<PersonShowMethod>[0],
  config?: UseQueryConfig<
    PersonShowMethod,
    PersonShowErrorTypes
  >
}) => {
  return useLazyQuery<
    PersonShowMethod,
    PersonShowErrorTypes
  >(
    (...args) => api.personShow(...args),
    PersonShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PersonShow'],
        ...apiQueryConfig.PersonShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PersonShow',
  );
};


export type PersonUpdateMethod = PersonApi['personUpdate'];

export const usePersonUpdate = () => {
  return useMutation<PersonUpdateMethod>(
    (...args) => api.personUpdate(...args),
    PersonUpdateErrorCodes,
    'PersonUpdate',
    '/api/persons/{id}',
    'put'
  );
};
