import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type Holding } from '@npm/data-access';

import * as S from '../Table/AggregatedHoldingsTable.styles';
import {
  ProofOfOwnershipDrawer,
  useProofOfOwnershipDrawer,
} from '../../ProofOfOwnershipDrawer';
import { type AggregatedHoldingActions } from '../AggregatedHoldingsList.types';

type Props = {
  holding: Holding;
} & Pick<AggregatedHoldingActions, 'onEditHoldingClick'>;

export const ProofOfOwnershipViewButton = ({
  holding,
  onEditHoldingClick,
}: Props) => {
  const [{ openProofOfOwnershipDrawer }, proofOfOwnershipDrawerProps] =
    useProofOfOwnershipDrawer();

  if (holding?.aggregated_proof_of_ownership_documents_count) {
    return (
      <>
        <S.ViewTextButton
          align="center"
          gap="xs"
          onClick={() => {
            openProofOfOwnershipDrawer(holding);
          }}
        >
          <Icon name="eye" size="xs" />
          <Text size="sm">View</Text>
        </S.ViewTextButton>
        <ProofOfOwnershipDrawer
          issuerEntity={holding?.issuer_entity}
          {...proofOfOwnershipDrawerProps}
        />
      </>
    );
  }
  return onEditHoldingClick ? (
    <Text
      size="sm"
      hyperlinkColor="warning"
      onClick={() => {
        onEditHoldingClick(holding);
      }}
    >
      + Add
    </Text>
  ) : (
    <Text size="sm">--</Text>
  );
};
