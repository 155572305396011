import { Modal as BaseModal } from '@npm/core/ui/components/molecules/Modal';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Modal = styled(BaseModal)`
  ol {
    padding-inline-start: ${({ theme }) => theme.spacing.lg}px;
  }

  .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-modal-footer {
    .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: unset;
    }

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding-top: 0;
    `}
  }
`;
