import React from 'react';

import { EMPTY_VALUE } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import {
  type ColumnType,
  TABLE_ACTIONS_COLUMN_WIDTH,
  TableActionsContainer,
} from '@npm/core/ui/components/molecules/Table';
import { CbOwnerType, type FolderAggregate } from '@npm/data-access';

import { getTaxDocumentTypeName } from '../../DataRoom/DataRoom.utils';

import { AccessButton } from './DataRoomsTable.styles';

export const getDataRoomsTableColumns = ({
  onAccessClick,
}: {
  onAccessClick: (room?: FolderAggregate) => void;
}): ColumnType<FolderAggregate>[] => {
  return [
    {
      title: 'Parent',
      key: 'company',
      render: room => {
        const { issuer_entity, owner_name } = room;
        return issuer_entity ? (
          <CompanyLogo
            url={issuer_entity?.logo_url}
            title={issuer_entity?.logo_name}
            size="sm"
            name={issuer_entity?.name}
          />
        ) : (
          owner_name
        );
      },
    },
    {
      title: 'Data Room',
      key: 'name',
      render: ({ name }) => name,
    },
    {
      title: 'Type',
      key: 'type',
      render: dataRoom => {
        const { owner_type, event_type } = dataRoom;
        return (
          (owner_type?.code === CbOwnerType.items.Portal
            ? getTaxDocumentTypeName(dataRoom)
            : event_type?.name) || EMPTY_VALUE
        );
      },
    },
    {
      key: 'actions',
      width: TABLE_ACTIONS_COLUMN_WIDTH,
      render: room => {
        return (
          <TableActionsContainer>
            <AccessButton onClick={() => onAccessClick(room)}>
              Access
            </AccessButton>
          </TableActionsContainer>
        );
      },
    },
  ];
};
