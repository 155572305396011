/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { FilingIndex } from '../model';
// @ts-ignore
import { StockClass } from '../model';
// @ts-ignore
import { StockClassCreateRequestContract } from '../model';
// @ts-ignore
import { StockClassIndex } from '../model';
// @ts-ignore
import { TransactionIndex } from '../model';
/**
 * StockClassesApi - axios parameter creator
 * @export
 */
export const StockClassesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all stock classes
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorStockClassesIndex: async (issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('investorStockClassesIndex', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/investor/issuers/{issuer_id}/stock_classes/`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get filings for a given stock class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassFilings: async (issuerId: string, stockClassId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stockClassFilings', 'issuerId', issuerId)
            // verify required parameter 'stockClassId' is not null or undefined
            assertParamExists('stockClassFilings', 'stockClassId', stockClassId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stock_classes/{stock_class_id}/filings`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"stock_class_id"}}`, encodeURIComponent(String(stockClassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary individual stock_class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassShow: async (issuerId: string, stockClassId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stockClassShow', 'issuerId', issuerId)
            // verify required parameter 'stockClassId' is not null or undefined
            assertParamExists('stockClassShow', 'stockClassId', stockClassId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stock_classes/{stock_class_id}`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"stock_class_id"}}`, encodeURIComponent(String(stockClassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get transactions for a given stock class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassTransactions: async (issuerId: string, stockClassId: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stockClassTransactions', 'issuerId', issuerId)
            // verify required parameter 'stockClassId' is not null or undefined
            assertParamExists('stockClassTransactions', 'stockClassId', stockClassId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stock_classes/{stock_class_id}/transactions`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"stock_class_id"}}`, encodeURIComponent(String(stockClassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new stock class
         * @param {string} issuerId 
         * @param {StockClassCreateRequestContract} stockClassCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassesCreate: async (issuerId: string, stockClassCreateRequestContract: StockClassCreateRequestContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stockClassesCreate', 'issuerId', issuerId)
            // verify required parameter 'stockClassCreateRequestContract' is not null or undefined
            assertParamExists('stockClassesCreate', 'stockClassCreateRequestContract', stockClassCreateRequestContract)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stock_classes`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockClassCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all stock classes
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassesIndex: async (issuerId: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('stockClassesIndex', 'issuerId', issuerId)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/stock_classes`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockClassesApi - functional programming interface
 * @export
 */
export const StockClassesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockClassesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all stock classes
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorStockClassesIndex(issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockClassIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorStockClassesIndex(issuerId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get filings for a given stock class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockClassFilings(issuerId: string, stockClassId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilingIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockClassFilings(issuerId, stockClassId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary individual stock_class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockClassShow(issuerId: string, stockClassId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockClassShow(issuerId, stockClassId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get transactions for a given stock class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockClassTransactions(issuerId: string, stockClassId: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockClassTransactions(issuerId, stockClassId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new stock class
         * @param {string} issuerId 
         * @param {StockClassCreateRequestContract} stockClassCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockClassesCreate(issuerId: string, stockClassCreateRequestContract: StockClassCreateRequestContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockClassesCreate(issuerId, stockClassCreateRequestContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all stock classes
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockClassesIndex(issuerId: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockClassIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockClassesIndex(issuerId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockClassesApi - factory interface
 * @export
 */
export const StockClassesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockClassesApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all stock classes
         * @param {string} issuerId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorStockClassesIndex(issuerId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, page?: number, size?: number, options?: any): AxiosPromise<StockClassIndex> {
            return localVarFp.investorStockClassesIndex(issuerId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get filings for a given stock class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassFilings(issuerId: string, stockClassId: string, options?: any): AxiosPromise<FilingIndex> {
            return localVarFp.stockClassFilings(issuerId, stockClassId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary individual stock_class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassShow(issuerId: string, stockClassId: string, options?: any): AxiosPromise<StockClass> {
            return localVarFp.stockClassShow(issuerId, stockClassId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get transactions for a given stock class
         * @param {string} issuerId 
         * @param {string} stockClassId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassTransactions(issuerId: string, stockClassId: string, page?: number, size?: number, options?: any): AxiosPromise<TransactionIndex> {
            return localVarFp.stockClassTransactions(issuerId, stockClassId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new stock class
         * @param {string} issuerId 
         * @param {StockClassCreateRequestContract} stockClassCreateRequestContract 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassesCreate(issuerId: string, stockClassCreateRequestContract: StockClassCreateRequestContract, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.stockClassesCreate(issuerId, stockClassCreateRequestContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all stock classes
         * @param {string} issuerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockClassesIndex(issuerId: string, page?: number, size?: number, options?: any): AxiosPromise<StockClassIndex> {
            return localVarFp.stockClassesIndex(issuerId, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for investorStockClassesIndex operation in StockClassesApi.
 * @export
 * @interface StockClassesApiInvestorStockClassesIndexRequest
 */
export interface StockClassesApiInvestorStockClassesIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInvestorStockClassesIndex
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInvestorStockClassesIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInvestorStockClassesIndex
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInvestorStockClassesIndex
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiInvestorStockClassesIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiInvestorStockClassesIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiInvestorStockClassesIndex
     */
    readonly size?: number
}

/**
 * Request parameters for stockClassFilings operation in StockClassesApi.
 * @export
 * @interface StockClassesApiStockClassFilingsRequest
 */
export interface StockClassesApiStockClassFilingsRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassFilings
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassFilings
     */
    readonly stockClassId: string
}

/**
 * Request parameters for stockClassShow operation in StockClassesApi.
 * @export
 * @interface StockClassesApiStockClassShowRequest
 */
export interface StockClassesApiStockClassShowRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassShow
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassShow
     */
    readonly stockClassId: string
}

/**
 * Request parameters for stockClassTransactions operation in StockClassesApi.
 * @export
 * @interface StockClassesApiStockClassTransactionsRequest
 */
export interface StockClassesApiStockClassTransactionsRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassTransactions
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassTransactions
     */
    readonly stockClassId: string

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiStockClassTransactions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiStockClassTransactions
     */
    readonly size?: number
}

/**
 * Request parameters for stockClassesCreate operation in StockClassesApi.
 * @export
 * @interface StockClassesApiStockClassesCreateRequest
 */
export interface StockClassesApiStockClassesCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassesCreate
     */
    readonly issuerId: string

    /**
     * 
     * @type {StockClassCreateRequestContract}
     * @memberof StockClassesApiStockClassesCreate
     */
    readonly stockClassCreateRequestContract: StockClassCreateRequestContract
}

/**
 * Request parameters for stockClassesIndex operation in StockClassesApi.
 * @export
 * @interface StockClassesApiStockClassesIndexRequest
 */
export interface StockClassesApiStockClassesIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof StockClassesApiStockClassesIndex
     */
    readonly issuerId: string

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiStockClassesIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof StockClassesApiStockClassesIndex
     */
    readonly size?: number
}

/**
 * StockClassesApi - object-oriented interface
 * @export
 * @class StockClassesApi
 * @extends {BaseAPI}
 */
export class StockClassesApi extends BaseAPI {
    /**
     * 
     * @summary lists all stock classes
     * @param {StockClassesApiInvestorStockClassesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public investorStockClassesIndex(requestParameters: StockClassesApiInvestorStockClassesIndexRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).investorStockClassesIndex(requestParameters.issuerId, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get filings for a given stock class
     * @param {StockClassesApiStockClassFilingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public stockClassFilings(requestParameters: StockClassesApiStockClassFilingsRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).stockClassFilings(requestParameters.issuerId, requestParameters.stockClassId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary individual stock_class
     * @param {StockClassesApiStockClassShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public stockClassShow(requestParameters: StockClassesApiStockClassShowRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).stockClassShow(requestParameters.issuerId, requestParameters.stockClassId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get transactions for a given stock class
     * @param {StockClassesApiStockClassTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public stockClassTransactions(requestParameters: StockClassesApiStockClassTransactionsRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).stockClassTransactions(requestParameters.issuerId, requestParameters.stockClassId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new stock class
     * @param {StockClassesApiStockClassesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public stockClassesCreate(requestParameters: StockClassesApiStockClassesCreateRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).stockClassesCreate(requestParameters.issuerId, requestParameters.stockClassCreateRequestContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all stock classes
     * @param {StockClassesApiStockClassesIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockClassesApi
     */
    public stockClassesIndex(requestParameters: StockClassesApiStockClassesIndexRequest, options?: AxiosRequestConfig) {
        return StockClassesApiFp(this.configuration).stockClassesIndex(requestParameters.issuerId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InvestorStockClassesIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorStockClassesIndexErrorCodes = [
];

export type StockClassFilingsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StockClassFilingsErrorCodes = [
];

export type StockClassShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StockClassShowErrorCodes = [
];

export type StockClassTransactionsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StockClassTransactionsErrorCodes = [
];

export type StockClassesCreateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StockClassesCreateErrorCodes = [
];

export type StockClassesIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const StockClassesIndexErrorCodes = [
];


