import React from 'react';

import { CbLabel } from '@npm/core/ui/components/atoms/Label';

import { DoneLabel } from './DoneLabel';

type Props = {
  hasFullAccessToMarketplace: boolean;
};

export const MarketplaceAccessStatusLabel = ({
  hasFullAccessToMarketplace,
}: Props) =>
  hasFullAccessToMarketplace ? (
    <DoneLabel />
  ) : (
    <CbLabel
      variant="general"
      iconProps={{ name: 'clock' }}
      label="Pending Approval"
    />
  );
