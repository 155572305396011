import styled from 'styled-components';

export const LineContainer = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing.sm}px;
  width: 100%;
`;

export const InnerLine = styled.div`
  height: 3px;
  width: 100%;
  background: ${({ theme }) => theme.color.general.typography.muted};
  border-radius: 18px;
  position: relative;
  margin: 0 2px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.general.layout.one};

  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.color.general.typography.muted};
  height: 9px;
`;

export const IndicatorsWrapper = styled.div`
  position: absolute;
  height: 1px;
  top: 1px;
  left: 2px;
  right: 2px;
`;

export const Indicator = styled.div<{ $left: number }>`
  position: absolute;
  left: ${({ $left }) => `${$left}%`};
  transform: translateX(-50%) translateY(-50%);

  width: 12px;
  height: 12px;

  top: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TriangleIndicator = styled(Indicator)`
  top: -6px;
  width: 16px;
  height: 16px;
`;
