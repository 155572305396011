// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AtomicDepositTransactionsApi,
  AtomicDepositTransactionsCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AtomicDepositTransactionsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AtomicDepositTransactionsCreateMethod = AtomicDepositTransactionsApi['atomicDepositTransactionsCreate'];

export const useAtomicDepositTransactionsCreate = () => {
  return useMutation<AtomicDepositTransactionsCreateMethod>(
    (...args) => api.atomicDepositTransactionsCreate(...args),
    AtomicDepositTransactionsCreateErrorCodes,
    'AtomicDepositTransactionsCreate',
    '/api/accounts/{account_id}/atomic-deposit',
    'post'
  );
};
