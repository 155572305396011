// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  S2SBrokerHoldingsApi,
  S2SBrokerHoldingUpdatePreVerifiedErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new S2SBrokerHoldingsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type S2SBrokerHoldingUpdatePreVerifiedMethod = S2SBrokerHoldingsApi['s2SBrokerHoldingUpdatePreVerified'];

export const useS2SBrokerHoldingUpdatePreVerified = () => {
  return useMutation<S2SBrokerHoldingUpdatePreVerifiedMethod>(
    (...args) => api.s2SBrokerHoldingUpdatePreVerified(...args),
    S2SBrokerHoldingUpdatePreVerifiedErrorCodes,
    'S2SBrokerHoldingUpdatePreVerified',
    '/api/s2s/broker_holdings/{security_npm_guid}/pre_verifieds',
    'patch'
  );
};
