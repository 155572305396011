// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FilingStatesApi,
  InternalFilingStatesIndexErrorCodes,
  InternalFilingStatesIndexErrorTypes,
  InternalFilingStatesShowErrorCodes,
  InternalFilingStatesShowErrorTypes,
  InternalUpdateFilingStateErrorCodes,
  InternalDeleteFilingStateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FilingStatesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalFilingStatesIndexMethod = FilingStatesApi['internalFilingStatesIndex'];
export type InternalFilingStatesIndexResponseType = MethodResult<InternalFilingStatesIndexMethod>;

export const useInternalFilingStatesIndex = (
  variables: Parameters<InternalFilingStatesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFilingStatesIndexMethod,
    InternalFilingStatesIndexErrorTypes
  >
) => {
  return useQuery<
    InternalFilingStatesIndexMethod,
    InternalFilingStatesIndexErrorTypes
  >(
    (...args) => api.internalFilingStatesIndex(...args),
    InternalFilingStatesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingStatesIndex'],
        ...apiQueryConfig.InternalFilingStatesIndex,
        ...config?.queryConfig
      },
    },
    'InternalFilingStatesIndex',
  );
};

export const useInternalFilingStatesIndexInfinite = (
  variables: Parameters<InternalFilingStatesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFilingStatesIndexMethod,
    InternalFilingStatesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFilingStatesIndexMethod,
    InternalFilingStatesIndexErrorTypes
  >(
    (...args) => api.internalFilingStatesIndex(...args),
    InternalFilingStatesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingStatesIndex', 'InternalFilingStatesIndexInfinite'],
        ...apiQueryConfig.InternalFilingStatesIndex,
        ...config?.queryConfig,
      },
    },
    'InternalFilingStatesIndex',
  );
};

export const useInternalFilingStatesIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalFilingStatesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFilingStatesIndexMethod,
    InternalFilingStatesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFilingStatesIndexMethod,
    InternalFilingStatesIndexErrorTypes
  >(
    (...args) => api.internalFilingStatesIndex(...args),
    InternalFilingStatesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFilingStatesIndex'],
        ...apiQueryConfig.InternalFilingStatesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFilingStatesIndex',
  );
};


export type InternalFilingStatesShowMethod = FilingStatesApi['internalFilingStatesShow'];
export type InternalFilingStatesShowResponseType = MethodResult<InternalFilingStatesShowMethod>;

export const useInternalFilingStatesShow = (
  variables: Parameters<InternalFilingStatesShowMethod>[0],
  config?: UseQueryConfig<
    InternalFilingStatesShowMethod,
    InternalFilingStatesShowErrorTypes
  >
) => {
  return useQuery<
    InternalFilingStatesShowMethod,
    InternalFilingStatesShowErrorTypes
  >(
    (...args) => api.internalFilingStatesShow(...args),
    InternalFilingStatesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingStatesShow'],
        ...apiQueryConfig.InternalFilingStatesShow,
        ...config?.queryConfig
      },
    },
    'InternalFilingStatesShow',
  );
};

export const useInternalFilingStatesShowInfinite = (
  variables: Parameters<InternalFilingStatesShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFilingStatesShowMethod,
    InternalFilingStatesShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFilingStatesShowMethod,
    InternalFilingStatesShowErrorTypes
  >(
    (...args) => api.internalFilingStatesShow(...args),
    InternalFilingStatesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFilingStatesShow', 'InternalFilingStatesShowInfinite'],
        ...apiQueryConfig.InternalFilingStatesShow,
        ...config?.queryConfig,
      },
    },
    'InternalFilingStatesShow',
  );
};

export const useInternalFilingStatesShowLazy = (baseOptions?: {
  variables?: Parameters<InternalFilingStatesShowMethod>[0],
  config?: UseQueryConfig<
    InternalFilingStatesShowMethod,
    InternalFilingStatesShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFilingStatesShowMethod,
    InternalFilingStatesShowErrorTypes
  >(
    (...args) => api.internalFilingStatesShow(...args),
    InternalFilingStatesShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFilingStatesShow'],
        ...apiQueryConfig.InternalFilingStatesShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFilingStatesShow',
  );
};


export type InternalUpdateFilingStateMethod = FilingStatesApi['internalUpdateFilingState'];

export const useInternalUpdateFilingState = () => {
  return useMutation<InternalUpdateFilingStateMethod>(
    (...args) => api.internalUpdateFilingState(...args),
    InternalUpdateFilingStateErrorCodes,
    'InternalUpdateFilingState',
    '/api/internal/filing_states/{id}',
    'put'
  );
};

export type InternalDeleteFilingStateMethod = FilingStatesApi['internalDeleteFilingState'];

export const useInternalDeleteFilingState = () => {
  return useMutation<InternalDeleteFilingStateMethod>(
    (...args) => api.internalDeleteFilingState(...args),
    InternalDeleteFilingStateErrorCodes,
    'InternalDeleteFilingState',
    '/api/internal/filing_states/{id}',
    'delete'
  );
};
