import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { AnimatePresence, motion } from 'framer-motion';

import * as S from '../WizardLayout.styles';
import { ErrorSkeleton } from '../../../../atoms/ErrorSkeleton';
import { useWizardStore } from '../../Wizard.store';
import { WIZARD_BODY_ID } from '../WizardLayout';

import { Content } from './Content';
import { ANIMATION_SETTINGS } from './Content.config';

type Props = {
  children: React.ReactNode;
  header?: React.ReactNode;
  sidebarContent?: React.ReactNode;
};

export const ContentMobile = (props: Props) => {
  const { sidebarContent, header, children } = props;
  const isStepperOpen = useWizardStore(s => s.isStepperMobileOpen);

  if (!sidebarContent) {
    return <Content {...props} />;
  }

  if (!isStepperOpen) {
    return (
      <ErrorBoundary FallbackComponent={ErrorSkeleton}>
        <S.Body id={WIZARD_BODY_ID}>
          {header && <S.SubHeader $noBorder>{header}</S.SubHeader>}
          <AnimatePresence exitBeforeEnter>
            <motion.div {...ANIMATION_SETTINGS}>{children}</motion.div>
          </AnimatePresence>
        </S.Body>
      </ErrorBoundary>
    );
  }

  return <S.Sidebar>{sidebarContent}</S.Sidebar>;
};
