import { Codebooks } from './codebooks.types';

const items = {
  NOT_ACTIVE: 'not_active',
  ACTIVATING: 'activating',
  ACTIVE: 'active',
} as const;

export const CbDealSyncAccessStatus = {
  code: Codebooks.DEAL_SYNC_ACCESS_STATUS,
  items,
};
