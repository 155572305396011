import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useTheme } from 'styled-components';

export const SideColumnTooltipContent = () => {
  const theme = useTheme();
  return (
    <Text size="sm" colorVariant="primary">
      Your order is marked with a dot{' '}
      <Text as="span" size="sm" style={{ whiteSpace: 'nowrap' }}>
        (
        <Icon
          size="xxs"
          name="circle"
          color={theme.color.success.typography.primary}
        />
        <Icon
          size="xxs"
          name="circle"
          color={theme.color.error.typography.primary}
        />
        )
      </Text>
    </Text>
  );
};
