import { Button as ButtonBase } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Label as LabelBase } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  MENU_ITEM_HEIGHT,
  SUBMENU_ITEM_HEIGHT,
} from '@npm/core/ui/components/organisms/Layout/Layout.styles';
import styled from 'styled-components';

export const Button = styled(ButtonBase).attrs({ variant: 'text' })<{
  $isCollapsed: boolean;
  $hideWhenCollapsed: boolean;
  $isHighlighted: boolean;
}>`
  && {
    width: 100%;
    flex-shrink: 0;

    height: ${({ size, $isCollapsed }) =>
      size === 'lg' || $isCollapsed ? MENU_ITEM_HEIGHT : SUBMENU_ITEM_HEIGHT}px;
    overflow: hidden;

    ${({ $isCollapsed, $hideWhenCollapsed }) =>
      $isCollapsed &&
      $hideWhenCollapsed &&
      `
      width: 0px;
      height: 0px;
      opacity: 0;
    `}

    border-radius: ${({ theme }) => theme.borderRadius.lg}px;
    margin: 2px 0px; // for outlines on click
    padding: 0px ${({ theme }) => theme.spacing.md}px;
    display: flex;
    justify-content: ${({ $isCollapsed }) =>
      $isCollapsed ? 'center' : 'flex-start'};
    gap: ${({ theme }) => theme.spacing.lg / 2}px;

    &,
    &.ant-btn-disabled {
      color: ${({ theme }) => theme.color.general.typography.primary};
      svg {
        color: ${({ theme }) => theme.color.general.typography.tertiary};
      }

      ${({ theme, $isHighlighted }) =>
        $isHighlighted &&
        `
        background-color: ${theme.color.general.layout.one};
      `}
    }

    &.ant-btn-disabled {
      cursor: auto;
    }
  }
`;

export const Row = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  gap: 'xs',
})`
  flex-grow: 1;
  overflow: hidden;
`;

export const Title = styled(Text).attrs({
  size: 'sm',
  colorVariant: 'primary',
  weight: 'bold',
  as: 'div',
})`
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const Label = styled(LabelBase).attrs({
  variant: 'info',
})`
  flex-shrink: 2;
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
