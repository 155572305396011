// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AdvisorReferralsApi,
  AdvisorReferralIndexErrorCodes,
  AdvisorReferralIndexErrorTypes,
  AdvisorReferralCreateErrorCodes,
  AdvisorReferralShowErrorCodes,
  AdvisorReferralShowErrorTypes,
  AdvisorReferralUpdateErrorCodes,
  AdvisorReferralDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AdvisorReferralsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AdvisorReferralIndexMethod = AdvisorReferralsApi['advisorReferralIndex'];
export type AdvisorReferralIndexResponseType = MethodResult<AdvisorReferralIndexMethod>;

export const useAdvisorReferralIndex = (
  variables: Parameters<AdvisorReferralIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >
) => {
  return useQuery<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >(
    (...args) => api.advisorReferralIndex(...args),
    AdvisorReferralIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralIndex'],
        ...apiQueryConfig.AdvisorReferralIndex,
        ...config?.queryConfig
      },
    },
    'AdvisorReferralIndex',
  );
};

export const useAdvisorReferralIndexInfinite = (
  variables: Parameters<AdvisorReferralIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >(
    (...args) => api.advisorReferralIndex(...args),
    AdvisorReferralIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralIndex', 'AdvisorReferralIndexInfinite'],
        ...apiQueryConfig.AdvisorReferralIndex,
        ...config?.queryConfig,
      },
    },
    'AdvisorReferralIndex',
  );
};

export const useAdvisorReferralIndexLazy = (baseOptions?: {
  variables?: Parameters<AdvisorReferralIndexMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AdvisorReferralIndexMethod,
    AdvisorReferralIndexErrorTypes
  >(
    (...args) => api.advisorReferralIndex(...args),
    AdvisorReferralIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AdvisorReferralIndex'],
        ...apiQueryConfig.AdvisorReferralIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AdvisorReferralIndex',
  );
};


export type AdvisorReferralCreateMethod = AdvisorReferralsApi['advisorReferralCreate'];

export const useAdvisorReferralCreate = () => {
  return useMutation<AdvisorReferralCreateMethod>(
    (...args) => api.advisorReferralCreate(...args),
    AdvisorReferralCreateErrorCodes,
    'AdvisorReferralCreate',
    '/api/advisor-referrals',
    'post'
  );
};

export type AdvisorReferralShowMethod = AdvisorReferralsApi['advisorReferralShow'];
export type AdvisorReferralShowResponseType = MethodResult<AdvisorReferralShowMethod>;

export const useAdvisorReferralShow = (
  variables: Parameters<AdvisorReferralShowMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralShowMethod,
    AdvisorReferralShowErrorTypes
  >
) => {
  return useQuery<
    AdvisorReferralShowMethod,
    AdvisorReferralShowErrorTypes
  >(
    (...args) => api.advisorReferralShow(...args),
    AdvisorReferralShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralShow'],
        ...apiQueryConfig.AdvisorReferralShow,
        ...config?.queryConfig
      },
    },
    'AdvisorReferralShow',
  );
};

export const useAdvisorReferralShowInfinite = (
  variables: Parameters<AdvisorReferralShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AdvisorReferralShowMethod,
    AdvisorReferralShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AdvisorReferralShowMethod,
    AdvisorReferralShowErrorTypes
  >(
    (...args) => api.advisorReferralShow(...args),
    AdvisorReferralShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AdvisorReferralShow', 'AdvisorReferralShowInfinite'],
        ...apiQueryConfig.AdvisorReferralShow,
        ...config?.queryConfig,
      },
    },
    'AdvisorReferralShow',
  );
};

export const useAdvisorReferralShowLazy = (baseOptions?: {
  variables?: Parameters<AdvisorReferralShowMethod>[0],
  config?: UseQueryConfig<
    AdvisorReferralShowMethod,
    AdvisorReferralShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AdvisorReferralShowMethod,
    AdvisorReferralShowErrorTypes
  >(
    (...args) => api.advisorReferralShow(...args),
    AdvisorReferralShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AdvisorReferralShow'],
        ...apiQueryConfig.AdvisorReferralShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AdvisorReferralShow',
  );
};


export type AdvisorReferralUpdateMethod = AdvisorReferralsApi['advisorReferralUpdate'];

export const useAdvisorReferralUpdate = () => {
  return useMutation<AdvisorReferralUpdateMethod>(
    (...args) => api.advisorReferralUpdate(...args),
    AdvisorReferralUpdateErrorCodes,
    'AdvisorReferralUpdate',
    '/api/advisor-referrals/{id}',
    'put'
  );
};

export type AdvisorReferralDestroyMethod = AdvisorReferralsApi['advisorReferralDestroy'];

export const useAdvisorReferralDestroy = () => {
  return useMutation<AdvisorReferralDestroyMethod>(
    (...args) => api.advisorReferralDestroy(...args),
    AdvisorReferralDestroyErrorCodes,
    'AdvisorReferralDestroy',
    '/api/advisor-referrals/{id}',
    'delete'
  );
};
