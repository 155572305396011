import {
  AxiosHeaders,
  type AxiosInstance,
  type AxiosRequestHeaders,
} from 'axios';

import { oktaAuth } from '../index';

const AUTH_REFRESH_QUERY_PARAM = 'authRefresh';
const AUTH_REFRESH_EXPIRES_IN_MS = 15000; // 15 seconds, then disregard the query param

const setAuthorizationToken = async (headers: AxiosRequestHeaders) => {
  try {
    const token = oktaAuth.getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token.toString()}`);
    }
  } catch (e) {
    console.log(e);
  }
};

export type OktaAuthorizationOptions = {
  allow401?: boolean;
};

export const initOktaAuthorization =
  (options: OktaAuthorizationOptions = {}) =>
  (axios: AxiosInstance) => {
    axios.interceptors.request.use(
      async config => {
        if (!config.headers) {
          config.headers = config.headers ?? new AxiosHeaders({});
        }
        await setAuthorizationToken(config.headers);
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalConfig = error.config;
        if (error?.response?.status === 401 && !options.allow401) {
          const urlParams = new URLSearchParams(window.location.search);
          const refreshRequestedAt = urlParams.get(AUTH_REFRESH_QUERY_PARAM);

          if (
            refreshRequestedAt &&
            new Date(refreshRequestedAt).valueOf() +
              AUTH_REFRESH_EXPIRES_IN_MS >
              Date.now()
          ) {
            return (window.location.href = '/403');
          }

          if (!originalConfig._retry) {
            originalConfig._retry = true;
            return axios(originalConfig);
          }

          const url = new URL(window.location.href);
          url.searchParams.append(
            AUTH_REFRESH_QUERY_PARAM,
            new Date().toISOString()
          );

          oktaAuth.signInWithRedirect({
            originalUri: url.toString(),
          });
        }

        return Promise.reject(error);
      }
    );
  };
