import { type ComponentProps } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';
import {
  CbCountry,
  CbDocumentType,
  CbOwnerType,
  CbSubregion,
  useAccountBeneficialOwnerUpdateTaxId,
  useCodebook,
  useDocumentCreate,
} from '@npm/data-access';
import { useQueryClient } from '@tanstack/react-query';

import { GovernmentIDForm } from '../../../../GovernmentIssuedId';

type Props = Omit<
  ComponentProps<typeof Drawer>,
  'title' | 'isLoading' | 'onSubmit' | 'buttonText'
> & {
  personId: number;
  accountId: number;
  onCreate: () => void;
};

export const GovernmentIssuedIdDrawer = ({
  personId,
  onCreate,
  accountId,
  onClose,
  ...rest
}: Props) => {
  const { withShowApiErrorMiddleware } = useAlerts();
  const [form] = Form.useForm();
  const { data: subregions } = useCodebook({
    type: CbSubregion.code,
    prefix: 'US',
  });

  const queryClient = useQueryClient();
  const { execute: docCreate, isLoading: docCreateIsLoading } =
    useDocumentCreate();
  const { execute: ownerUpdate, isLoading: ownerUpdateIsLoading } =
    useAccountBeneficialOwnerUpdateTaxId();

  const getCreateDocParams = () => {
    const {
      dateOfBirth,
      expirationDate,
      state,
      nationality,
      issuingCountry,
      category,
      ...restFields
    } = form.getFieldsValue();

    return {
      ...restFields,
      ownerType: CbOwnerType.items.BeneficialOwner,

      ownerId: personId,
      ...(dateOfBirth && {
        dateOfBirth: parseDateToString(dateOfBirth, DATE_FORMATS.DATE_NUMERIC),
      }),
      ...(expirationDate && {
        expirationDate: parseDateToString(
          expirationDate,
          DATE_FORMATS.DATE_NUMERIC
        ),
      }),
      ...(state && {
        state: subregions?.codebooks?.find(codebook => codebook.code === state)
          ?.code,
      }),
      ...(nationality && {
        nationality: `${CbCountry.code}.${nationality}`,
      }),
      ...(issuingCountry && {
        issuingCountry: `${CbCountry.code}.${issuingCountry}`,
      }),
      category: `${CbDocumentType.code}.${category}`,
    };
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      handleValidationError(form, err);
      return;
    }

    const params = getCreateDocParams();

    try {
      await withShowApiErrorMiddleware(docCreate)(params);
      queryClient.invalidateQueries(['AccountShow']);
    } catch (error) {
      console.error(error);
      return;
    }

    const { tax_id, tax_id_exempt } = form.getFieldsValue();

    try {
      await withShowApiErrorMiddleware(ownerUpdate)({
        id: personId,
        accountId: accountId,
        accountsBeneficialOwnerUpdateRequestContract: {
          tax_id,
          tax_id_exempt,
          id: personId,
          account_id: accountId,
        },
      });
    } catch (error) {
      console.error(error);
      return;
    }

    handleClose();
    onCreate();
  };

  return (
    <Drawer
      {...rest}
      title="Government-Issued ID"
      isFullHeight
      submitButtonProps={{
        onClick: handleSubmit,
        children: 'Submit',
        loading: docCreateIsLoading || ownerUpdateIsLoading,
      }}
      onClose={handleClose}
    >
      <GovernmentIDForm
        withTaxId
        inputLayout="vertical"
        form={form}
        subregions={subregions?.codebooks}
      />
    </Drawer>
  );
};
