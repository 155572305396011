import { useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbATSPool,
  CbOrderBuySell,
  type SecondmarketSubmissionForClientOrders,
} from '@npm/data-access';

import { useSponsorshipLevel } from '../../../../auth/user/role';
import { isExpiringOrder } from '../../../client-orders/expiringOrders';
import type { OpenRenewOrderModal } from '../../../client-orders/expiringOrders/RenewOrderModal/RenewOrderModal.types';
import { useCanRenewOrder } from '../../../hooks/useCanRenewOrder';

import type { OrderActions } from './InvestorOrdersList.types';
import { isOrderNotEditable } from './InvestorOrdersList.utils';

type DropdownOptions = 'detail' | 'edit' | 'cancel' | 'renew';

type Props = {
  submission: SecondmarketSubmissionForClientOrders;
  hideDropdownOptions?: DropdownOptions[];
  hideMainButton?: boolean;
  onRenewOrderClick?: OpenRenewOrderModal;
  showOnlyDetailButton?: boolean;
} & OrderActions;

export const InvestorOrdersDropdown = ({
  submission,
  hideDropdownOptions,
  hideMainButton,
  onOrderCancelClick,
  onOrderDetailClick,
  onOrderEditClick,
  onRenewOrderClick,
  showOnlyDetailButton,
  onParentOrderClick,
}: Props) => {
  const [isGoingToDetail, setIsGoingToDetail] = useState(false);

  const { isSponsorshipMinLevel2 } = useSponsorshipLevel();
  const { isMobile } = useBreakpoints();

  const order = submission?.items?.[0];

  const isExternal =
    order?.ats_pool?.code === CbATSPool.items.ats_i ||
    order?.ats_pool?.code === CbATSPool.items.ats_sm;

  const isOrderEditDisabled = isOrderNotEditable(order?.state?.code);
  const isDropdownDisabled = !isMobile && isOrderEditDisabled;

  const isBuy = submission?.side?.code === CbOrderBuySell.items.buy;

  const isExpiring = isExpiringOrder(order);
  const { canRenewOrder } = useCanRenewOrder(submission?.account?.id);

  const handleDetailClick = () => {
    try {
      setIsGoingToDetail(true);
      onOrderDetailClick?.(submission);
    } finally {
      setIsGoingToDetail(false);
    }
  };

  const renewButton =
    !hideMainButton && onRenewOrderClick && canRenewOrder && isExpiring ? (
      <Button
        onClick={() => onRenewOrderClick(order)}
        icon={<Icon name="refresh" />}
      >
        Renew {isBuy ? 'Bid' : 'Offer'}
      </Button>
    ) : null;

  const detailsButton =
    !hideMainButton && !renewButton ? (
      <Button
        variant="outline"
        onClick={handleDetailClick}
        loading={isGoingToDetail}
        style={{ flex: 1 }}
      >
        Details
      </Button>
    ) : null;

  if (showOnlyDetailButton) {
    return (
      <Flex justify="flex-end" align="center" gap="sm">
        {detailsButton}
      </Flex>
    );
  }

  const dropdownItems = [
    !hideDropdownOptions?.includes('detail') &&
      !detailsButton &&
      onOrderDetailClick && {
        label: 'Details',
        icon: <Icon name="info-circle" />,
        onClick: handleDetailClick,
        disabled: isGoingToDetail,
      },
    !hideDropdownOptions?.includes('renew') &&
      isExpiring &&
      canRenewOrder &&
      onRenewOrderClick &&
      !renewButton && {
        label: `Renew ${isBuy ? 'Bid' : 'Offer'}`,
        icon: <Icon name="refresh" />,
        onClick: () => onRenewOrderClick(order, isBuy),
      },
    !hideDropdownOptions?.includes('edit') &&
      onOrderEditClick && {
        label: 'Edit Order',
        icon: <Icon name="edit" />,
        onClick: () => onOrderEditClick(submission),
        disabled:
          (isExternal && !isSponsorshipMinLevel2) || isOrderEditDisabled,
      },
    !hideDropdownOptions?.includes('cancel') &&
      onOrderCancelClick && {
        label: 'Cancel Order',
        icon: <Icon name="trash" color="error" />,
        onClick: () => onOrderCancelClick(submission),
        disabled: !order?.cancellable,
      },
    !!submission?.items?.[0]?.parent_order_item_id &&
      !!onParentOrderClick &&
      !detailsButton &&
      onOrderDetailClick && {
        label: 'Parent Order',
        icon: <Icon name="external-link" />,
        onClick: () =>
          onParentOrderClick(submission?.items?.[0]?.parent_order_item_id),
        disabled: isGoingToDetail,
      },
  ].filter(Boolean);

  // TODO: NFE-6644
  return (
    <Flex justify="flex-end" align="center" gap="sm">
      {renewButton ?? detailsButton}
      <Tooltip
        title={isDropdownDisabled ? 'You can not edit this order.' : undefined}
      >
        <div>
          <Dropdown items={dropdownItems} disabled={isDropdownDisabled}>
            <Button
              variant="text"
              size="md"
              icon={<Icon name="dots-vertical" />}
            />
          </Dropdown>
        </div>
      </Tooltip>
    </Flex>
  );
};
