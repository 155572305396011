import { useState } from 'react';

import { type Holding } from '@npm/data-access';

import { type AggregatedHoldingDrawerProps } from './AggregatedHoldingDrawer';

type Return = [
  {
    openAggregatedHoldingDrawer: (holding?: Holding) => void;
  },
  Pick<
    AggregatedHoldingDrawerProps,
    'open' | 'onClose' | 'holdingId' | 'setHoldingId'
  >,
];

export const useAggregatedHoldingDrawer = (): Return => {
  const [open, setOpen] = useState(false);
  const [holdingId, setHoldingId] = useState<number | null>(null);

  const openAggregatedHoldingDrawer = (holding?: Holding) => {
    setOpen(true);
    if (holding) {
      setHoldingId(holding.id);
    }
  };

  const onClose = () => {
    setOpen(false);
    setHoldingId(null);
  };

  return [
    { openAggregatedHoldingDrawer },
    {
      open,
      holdingId,
      onClose,
      setHoldingId,
    },
  ];
};
