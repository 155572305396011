import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { getFullName } from '@npm/core/ui/utils/formatters';
import { type RepresentativeAggregate } from '@npm/data-access';

import * as S from '../../../SelectAccount/SelectAccountCards/SelectAccountCard/SelectAccountCard.styles';
import { type OboModalState } from '../../../../OboModal.types';

type Props = {
  selectedAccount: OboModalState['account'];
  isSelected: boolean;
  representative: RepresentativeAggregate;
  handleComplete: (nextOboState: OboModalState) => void;
};

export const SelectRepresentativeCard = ({
  selectedAccount,
  isSelected,
  representative,
  handleComplete,
}: Props) => {
  const handleButtonClick = () => {
    handleComplete({ account: selectedAccount, representative });
  };

  return (
    <S.ItemButton
      onClick={handleButtonClick}
      $isSelected={isSelected}
      data-dd-action-name="Select Representative"
    >
      <Text
        size="sm"
        colorVariant="primary"
        marginBottom="xs"
        data-dd-privacy="mask"
      >
        {getFullName(
          representative?.first,
          representative?.last,
          representative?.middle
        )}
      </Text>
      <Text size="xs" data-dd-privacy="mask">
        {representative.email}
      </Text>
      <Text size="xs" color="info">
        {representative.access_as?.name}
      </Text>
    </S.ItemButton>
  );
};
