// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AtomicExternalAccountsApi,
  AtomicExternalAccountsIndexErrorCodes,
  AtomicExternalAccountsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AtomicExternalAccountsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AtomicExternalAccountsIndexMethod = AtomicExternalAccountsApi['atomicExternalAccountsIndex'];
export type AtomicExternalAccountsIndexResponseType = MethodResult<AtomicExternalAccountsIndexMethod>;

export const useAtomicExternalAccountsIndex = (
  variables: Parameters<AtomicExternalAccountsIndexMethod>[0],
  config?: UseQueryConfig<
    AtomicExternalAccountsIndexMethod,
    AtomicExternalAccountsIndexErrorTypes
  >
) => {
  return useQuery<
    AtomicExternalAccountsIndexMethod,
    AtomicExternalAccountsIndexErrorTypes
  >(
    (...args) => api.atomicExternalAccountsIndex(...args),
    AtomicExternalAccountsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AtomicExternalAccountsIndex'],
        ...apiQueryConfig.AtomicExternalAccountsIndex,
        ...config?.queryConfig
      },
    },
    'AtomicExternalAccountsIndex',
  );
};

export const useAtomicExternalAccountsIndexInfinite = (
  variables: Parameters<AtomicExternalAccountsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AtomicExternalAccountsIndexMethod,
    AtomicExternalAccountsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AtomicExternalAccountsIndexMethod,
    AtomicExternalAccountsIndexErrorTypes
  >(
    (...args) => api.atomicExternalAccountsIndex(...args),
    AtomicExternalAccountsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AtomicExternalAccountsIndex', 'AtomicExternalAccountsIndexInfinite'],
        ...apiQueryConfig.AtomicExternalAccountsIndex,
        ...config?.queryConfig,
      },
    },
    'AtomicExternalAccountsIndex',
  );
};

export const useAtomicExternalAccountsIndexLazy = (baseOptions?: {
  variables?: Parameters<AtomicExternalAccountsIndexMethod>[0],
  config?: UseQueryConfig<
    AtomicExternalAccountsIndexMethod,
    AtomicExternalAccountsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AtomicExternalAccountsIndexMethod,
    AtomicExternalAccountsIndexErrorTypes
  >(
    (...args) => api.atomicExternalAccountsIndex(...args),
    AtomicExternalAccountsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AtomicExternalAccountsIndex'],
        ...apiQueryConfig.AtomicExternalAccountsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AtomicExternalAccountsIndex',
  );
};

