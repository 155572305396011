import { type ComponentProps } from 'react';

import styled, { css } from 'styled-components';

import {
  boldHeadingVariationSettings,
  Heading0Styles,
  Heading1Styles,
  Heading2Styles,
  Heading3Styles,
} from '../typography.styles';
import { type TypographyStyledComponentProps } from '../typography.types';

import { type Heading as HeadingComponent } from './Heading';

const variantsMap = {
  h0: Heading0Styles,
  h1: Heading1Styles,
  h2: Heading2Styles,
  h3: Heading3Styles,
};

export const Heading = styled.h1<
  {
    $variant: ComponentProps<typeof HeadingComponent>['variant'];
    $textAlign?: 'left' | 'center' | 'right';
    $weight?: 'default' | 'bold';
  } & TypographyStyledComponentProps
>`
  ${({ $variant }) => variantsMap[$variant]};
  ${({ $weight }) => $weight === 'bold' && boldHeadingVariationSettings}

  b, strong {
    ${boldHeadingVariationSettings}
  }

  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `};
`;
