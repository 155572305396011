import { useEffect } from 'react';

import { DatadogService } from '@npm/utils';

import { useLocation } from '../components/molecules/Link';
import { transformQueryStringToObj } from '../utils/url';

import { useCurrentRoute } from './useCurrentRoute';
import { useParams } from './useParams';

export const useDatadogPageTracking = () => {
  // Track every route change with useLocation API
  const location = useLocation();
  const currentRoute = useCurrentRoute();
  const params = useParams<{
    orderId?: string;
    orderItemId?: string;
    negotiationId?: string;
    accountId?: string;
    submissionId?: string;
    programId?: string;
  }>();

  useEffect(() => {
    let viewName = currentRoute?.route?.path || '/';
    const qs = transformQueryStringToObj(location.search);
    if (qs['step']) {
      viewName = viewName + `[step=${qs['step']}]`;
    }
    if (qs['substep']) {
      viewName = viewName + `[substep=${qs['substep']}]`;
    }
    if (qs['tab']) {
      viewName = viewName + `[tab=${qs['tab']}]`;
    }
    if (viewName) {
      DatadogService.startView(viewName, {
        order_id: params?.orderId || params?.orderItemId,
        submission_id: params?.submissionId,
        negotiation_id: params?.negotiationId,
        account_id: params?.accountId,
        program_id: params?.programId,
      });
    }
  }, [location.pathname, location.search, currentRoute, params]);
};
