import React, { useState } from 'react';

import { Button, type ButtonProps } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { MarketFeedDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import { type SecondmarketOrderItemShow } from '@npm/data-access';

import { useIsBrokerObserver } from '../../../../../auth/user/role/hooks/useIsBrokerObserver';
import { getOrderItemInfo } from '../../../../../order';
import { RequestMoreInformationButton } from '../../../../components';

import { ActionsContainer } from './ActionsContainer';
import { useCopyOrderItemLink } from './BrokerMarketOrderActions.hooks';

const DisabledTooltipText = {
  negotiate: 'Trading actions are restricted for your role.',
  negotiateWithoutPps: 'Cannot match without specifying PPS',
  editOrder: 'Edit Order is restricted for your role.',
  cancelOrder: 'Cancel Order is restricted for your role.',
  showOrderDetail: 'Access to Order Details is restricted for your role.',
};

export type MarketOrderActionsProps = {
  orderItem: SecondmarketOrderItemShow;
  brokerageFirmId: number;
  handleShowOrderDetails: (orderItem: SecondmarketOrderItemShow) => void;
  handleEditOrder?: ((orderItemId: number) => void) | undefined;
  handleCancelOrder: ((orderItemId: number) => void) | undefined;
  handleShowNegotiations: (orderItem: SecondmarketOrderItemShow) => void;
  handleStartNegotiation:
    | ((orderItem: SecondmarketOrderItemShow) => void)
    | undefined;
  buttonSize?: ButtonProps['size'];
};

export const BrokerMarketOrderActions = ({
  orderItem,
  brokerageFirmId,
  handleShowOrderDetails,
  handleEditOrder,
  handleCancelOrder,
  handleShowNegotiations,
  handleStartNegotiation,
  buttonSize = 'md',
}: MarketOrderActionsProps) => {
  const canNegotiate = !!handleStartNegotiation;
  const canEditOrder = !!handleEditOrder;
  const canCancelOrder = !!handleCancelOrder;
  const hasNegotiations = orderItem?.negotiations_total > 0;

  const [isGoingToDetail, setIsGoingToDetail] = useState(false);
  const { isMobile } = useBreakpoints();
  const isBrokerObserver = useIsBrokerObserver();

  const {
    isSpvStructure,
    isForwardStructure,
    isExternal,
    isInternal,
    isSoftIoi,
  } = getOrderItemInfo(orderItem);

  const isDifferentBrokerageFirm =
    orderItem.account?.brokerage_firm?.id !== brokerageFirmId;

  const handleCopyLink = useCopyOrderItemLink(orderItem.id);

  const handleDetailClick = async () => {
    try {
      setIsGoingToDetail(true);
      await handleShowOrderDetails(orderItem);
    } finally {
      setIsGoingToDetail(false);
    }
  };

  if (!brokerageFirmId) return null;

  const dropdownTrigger = (
    <Button
      data-cy={composeDataId(
        MarketFeedDataIds.Table.Actions.OtherActions,
        orderItem.id
      )}
      variant="text"
      size={buttonSize}
      icon={<Icon name="dots-vertical" />}
      loading={isGoingToDetail}
    />
  );

  const copyLinkDropdownItem = {
    label: 'Copy Link',
    onClick: () => handleCopyLink(),
  };

  const copyLinkDropdown = (
    <Dropdown items={[copyLinkDropdownItem]}>{dropdownTrigger}</Dropdown>
  );

  if (isBrokerObserver) {
    if (isDifferentBrokerageFirm) {
      return (
        <ActionsContainer>
          <RequestMoreInformationButton
            variant={'default'}
            size={buttonSize}
            orderItem={orderItem}
            title="Connect with NPM"
            style={{ flexGrow: 1 }}
          />
          {copyLinkDropdown}
        </ActionsContainer>
      );
    }

    return (
      <ActionsContainer>
        <Tooltip title={DisabledTooltipText.showOrderDetail}>
          <Button
            size={buttonSize}
            variant={'outline'}
            style={{ flexGrow: 1 }}
            disabled
          >
            Show Details
          </Button>
        </Tooltip>
        {copyLinkDropdown}
      </ActionsContainer>
    );
  }

  if (isDifferentBrokerageFirm) {
    if (isSoftIoi || isSpvStructure) {
      return (
        <ActionsContainer>
          <RequestMoreInformationButton
            size={buttonSize}
            orderItem={orderItem}
            title="Connect with NPM"
            style={{ flexGrow: 1 }}
          />
          {!isMobile && isInternal && (
            // Placeholder to keep the buttons aligned
            <Button
              variant="text"
              size={buttonSize}
              icon={<Icon name="dots-vertical" />}
              style={{ visibility: 'hidden' }}
              disabled
            />
          )}
          {isExternal && copyLinkDropdown}
        </ActionsContainer>
      );
    }

    return (
      <ActionsContainer>
        {orderItem.negotiations_total > 0 ? (
          <Button
            variant="outline"
            size={buttonSize}
            onClick={() => handleShowNegotiations(orderItem)}
            style={{ flexGrow: 1 }}
            data-cy={composeDataId(
              MarketFeedDataIds.Table.Actions.InNegotiation,
              orderItem.id
            )}
          >
            In Negotiation ({orderItem.negotiations_total})
          </Button>
        ) : (
          <Tooltip title={!canNegotiate ? DisabledTooltipText.negotiate : null}>
            <Button
              size={buttonSize}
              onClick={() => handleStartNegotiation?.(orderItem)}
              disabled={!canNegotiate}
              style={{ flexGrow: 1 }}
              data-cy={composeDataId(
                MarketFeedDataIds.Table.Actions.StartNegotiation,
                orderItem.id
              )}
            >
              Negotiate
            </Button>
          </Tooltip>
        )}
        {isExternal && copyLinkDropdown}
        {!isMobile && isInternal && (
          // Placeholder to keep the buttons aligned
          <Button
            variant="text"
            size={buttonSize}
            icon={<Icon name="dots-vertical" />}
            style={{ visibility: 'hidden' }}
            disabled
          />
        )}
      </ActionsContainer>
    );
  }

  const editCTA = (
    <Tooltip title={!canEditOrder ? DisabledTooltipText.editOrder : null}>
      <Button
        variant="outline"
        color="info"
        size={buttonSize}
        onClick={() => handleEditOrder?.(orderItem.id)}
        disabled={!canEditOrder}
        style={{ flexGrow: 1 }}
        data-cy={composeDataId(
          MarketFeedDataIds.Table.Actions.EditOrder,
          orderItem.id
        )}
      >
        Edit Order
      </Button>
    </Tooltip>
  );

  const negotiationsCTA = (
    <Button
      variant="outline"
      color="info"
      size={buttonSize}
      onClick={() => handleShowNegotiations(orderItem)}
      loading={orderItem.negotiations_total === 1 && isGoingToDetail}
      style={{ flexGrow: 1 }}
      data-cy={composeDataId(
        MarketFeedDataIds.Table.Actions.InNegotiation,
        orderItem.id
      )}
    >
      Negotiations ({orderItem.negotiations_total})
    </Button>
  );

  const orderDetailsCTA = (
    <Button
      onClick={handleDetailClick}
      size={buttonSize}
      variant={'outline'}
      style={{ flexGrow: 1 }}
      disabled={isGoingToDetail}
      loading={isGoingToDetail}
    >
      Show Details
    </Button>
  );

  const editDropdownItem = {
    label: 'Edit Order',
    onClick: () => handleEditOrder?.(orderItem.id),
    tooltip: !canEditOrder ? DisabledTooltipText.editOrder : null,
    disabled: !canEditOrder,
    'data-cy': composeDataId(
      MarketFeedDataIds.Table.Actions.EditOrder,
      orderItem.id
    ),
  };

  const showDetailsDropdownItem = {
    label: 'Show Details',
    onClick: handleDetailClick,
    disabled: isGoingToDetail,
  };

  const cancelDropdownItem = {
    label: 'Cancel Order',
    onClick: () => handleCancelOrder?.(orderItem.id),
    tooltip: !canCancelOrder ? DisabledTooltipText.cancelOrder : null,
    disabled: !canCancelOrder || !orderItem.cancellable || isGoingToDetail,
    danger: true,
    'data-cy': composeDataId(
      MarketFeedDataIds.Table.Actions.CancelOrder,
      orderItem.id
    ),
  };

  const startNegotiationDropdownItem = {
    'data-cy': composeDataId(
      MarketFeedDataIds.Table.Actions.StartNegotiation,
      orderItem.id
    ),
    label: 'Match Internal',
    onClick: () => handleStartNegotiation?.(orderItem),
    tooltip: !canNegotiate
      ? DisabledTooltipText.negotiate
      : !orderItem?.price
        ? DisabledTooltipText.negotiateWithoutPps
        : null,
    disabled: !canNegotiate || isGoingToDetail || !orderItem?.price,
  };

  if (isSpvStructure || isForwardStructure) {
    if (orderItem.cancellable) {
      return (
        <ActionsContainer>
          {editCTA}
          <Dropdown
            items={[
              ...(isForwardStructure ? [startNegotiationDropdownItem] : []),
              copyLinkDropdownItem,
              showDetailsDropdownItem,
              cancelDropdownItem,
            ]}
          >
            {dropdownTrigger}
          </Dropdown>
        </ActionsContainer>
      );
    }

    return (
      <ActionsContainer>
        {!isForwardStructure || !hasNegotiations
          ? orderDetailsCTA
          : negotiationsCTA}
        <Dropdown
          items={[
            copyLinkDropdownItem,
            ...(hasNegotiations ? [showDetailsDropdownItem] : []),
            editDropdownItem,
          ]}
        >
          {React.cloneElement(dropdownTrigger, {
            style: { marginLeft: 'auto' },
          })}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isExternal && orderItem.cancellable) {
    return (
      <ActionsContainer>
        {editCTA}
        <Dropdown
          items={[
            ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
            copyLinkDropdownItem,
            showDetailsDropdownItem,
            cancelDropdownItem,
          ]}
        >
          {dropdownTrigger}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isExternal && !orderItem.cancellable) {
    return (
      <ActionsContainer>
        {!hasNegotiations ? orderDetailsCTA : negotiationsCTA}
        <Dropdown
          items={[
            ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
            copyLinkDropdownItem,
            ...(!hasNegotiations ? [] : [showDetailsDropdownItem]),
            editDropdownItem,
          ]}
        >
          {dropdownTrigger}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isInternal && orderItem.cancellable) {
    return (
      <ActionsContainer>
        {editCTA}
        <Dropdown
          items={[
            ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
            showDetailsDropdownItem,
            cancelDropdownItem,
          ]}
        >
          {dropdownTrigger}
        </Dropdown>
      </ActionsContainer>
    );
  }

  if (isInternal && !orderItem.cancellable) {
    return (
      <ActionsContainer>
        {!hasNegotiations ? orderDetailsCTA : negotiationsCTA}
        {(hasNegotiations || canNegotiate || canEditOrder) && (
          <Dropdown
            items={[
              ...(isSoftIoi ? [] : [startNegotiationDropdownItem]),
              ...(!hasNegotiations ? [] : [showDetailsDropdownItem]),
              editDropdownItem,
            ]}
          >
            {dropdownTrigger}
          </Dropdown>
        )}
      </ActionsContainer>
    );
  }

  return null;
};
