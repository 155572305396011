import React, { useRef } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useDrawer } from '@npm/core/ui/components/organisms/Drawer';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbOwnerType,
  type FolderAggregate,
  type FoldersApiFolderIndexRequest,
  useUserRoleIndexLazy,
} from '@npm/data-access';

import { USER_ROLE_INDEX_BASE_PARAMS } from '../../auth/user/context';
import { AccountSwitcher } from '../../auth/user/context/RoleSwitcher/AccountSwitcher';
import { useCurrentAccount } from '../../auth/user/role';

import { DataRoomsCards } from './Cards';
import { DataRoomsTable } from './Table';

const getUserRoleIndexVariables = (room: FolderAggregate | null) => {
  if (!room) return {};
  const isEventDataRoom = room.owner_type?.code === CbOwnerType.items.Event;
  const isAccountDataRoom = room.owner_type?.code === CbOwnerType.items.Account;
  const isTaxFormDataRoom = room.owner_type?.code === CbOwnerType.items.Portal;

  return {
    ...USER_ROLE_INDEX_BASE_PARAMS,
    page: 1,
    eventId: isEventDataRoom ? room.owner_id : null,
    accountId: isAccountDataRoom ? room.owner_id : null,
    taxForm: isTaxFormDataRoom ? true : null,
  };
};

type Props = {
  variables: FoldersApiFolderIndexRequest;
  setVariables: React.Dispatch<
    React.SetStateAction<FoldersApiFolderIndexRequest>
  >;
  onAccessClick?: (room: FolderAggregate, roleId?: number) => void;
};

export const DataRoomsList = ({ onAccessClick, ...props }: Props) => {
  const { isMobile } = useBreakpoints();

  const selectedRoomRef = useRef<FolderAggregate>(null);

  const { isObo, isInvAccountCentric } = useCurrentAccount();

  const {
    isOpen: isAccountSwitcherOpen,
    openDrawer,
    closeDrawer,
  } = useDrawer();

  const [fetchAvailableAccounts] = useUserRoleIndexLazy();

  const onRoleChange = newRole => {
    if (!isInvAccountCentric) {
      onAccessClick(selectedRoomRef.current, newRole?.id);
    }
  };

  const handleAccessClick = async (room: FolderAggregate) => {
    selectedRoomRef.current = room;

    if (isObo || isInvAccountCentric) {
      onAccessClick(room);
      return;
    }

    try {
      const res = await fetchAvailableAccounts({
        variables: getUserRoleIndexVariables(room),
      });

      if (res.pagination?.total_records === 1) {
        onAccessClick(room, res.user_roles?.[0]?.id);
      } else {
        openDrawer();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isMobile ? (
        <DataRoomsCards onAccessClick={handleAccessClick} {...props} />
      ) : (
        <DataRoomsTable onAccessClick={handleAccessClick} {...props} />
      )}
      <AccountSwitcher
        title="Select an Account"
        isOpen={isAccountSwitcherOpen}
        onSubmit={onRoleChange}
        onClose={() => closeDrawer()}
        userRoleIndexVariables={getUserRoleIndexVariables(
          selectedRoomRef.current
        )}
        warningContent={
          <Text size="sm" colorVariant="primary">
            In order to access the &apos;
            <Text size="sm" weight="bold" colorVariant="primary" as="span">
              {selectedRoomRef.current?.name}
            </Text>
            &apos; data room you must select one of your eligible accounts
          </Text>
        }
        allowRedirect={false}
        noDataAreaTitle="You have no eligible account that can be used for accessing this data room."
      />
    </>
  );
};
