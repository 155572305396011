import React from 'react';

import { Checkbox } from '@npm/core/ui/components/atoms/Checkbox';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbLabel, Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { formatNumToAddComma } from '@npm/core/ui/utils/formatters';
import { CbHoldingState, type Holding } from '@npm/data-access';

import { getHoldingIdInfo } from '../../Holdings.utils';

import * as S from './HoldingSelectOption.styles';

type Props = {
  holding: Holding;
  isSelected?: boolean;
  showMissingCertificateWarning?: boolean;
};

export const HoldingSelectOption = ({
  holding,
  isSelected,
  showMissingCertificateWarning = false,
}: Props) => {
  const { idLabel, isMissingId } = getHoldingIdInfo(holding, false);
  const shouldShowMissingCertificateLabel =
    isMissingId && showMissingCertificateWarning;

  return (
    <Flex align={'center'}>
      {isSelected != null && <Checkbox checked={isSelected} label={null} />}
      <Flex direction={'column'} gap={'xs'} style={{ flex: 1 }}>
        <Flex align={'center'} gap={'xs'} $wrap>
          <Text size={'sm'} colorVariant={'secondary'}>
            {holding.asset?.type?.name}{' '}
            {holding.underlying_holding_type
              ? ` - ${holding.underlying_holding_type.name}`
              : ''}
          </Text>
          <Text.Dot size={'sm'} colorVariant={'tertiary'} />
          <Text size={'sm'} colorVariant={'primary'}>
            {idLabel}
          </Text>
        </Flex>
        <Flex align={'center'} justify={'space-between'} gap={'xs'}>
          {!!holding.quantity && (
            <S.QuantityLabel>
              QTY {formatNumToAddComma(holding.quantity)}
            </S.QuantityLabel>
          )}
          {shouldShowMissingCertificateLabel ? (
            <Label variant="warning" icon={<Icon name="warning" />}>
              {idLabel}
            </Label>
          ) : (
            holding.state &&
            CbHoldingState.getLabel(CbLabel, holding.state, true)
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
