import { useMemo } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbSubscriptionPlan } from '@npm/data-access';

import { useUnlockPremium } from '../../../auth/user/components';
import { useUserContextStore } from '../../../auth/user/context';

import * as S from './AvailableLabel.styles';
import { getAvailableLabelConfig } from './AvailableLabel.config';

type Props = {
  booleans: boolean[];
  requiresPremium?: boolean;
};

export const AvailableLabel = ({ booleans, requiresPremium }: Props) => {
  const isUserPremium = useUserContextStore(
    store =>
      store.user.subscription_plan.code === CbSubscriptionPlan.items.premium
  );

  const unlockPremium = useUnlockPremium();
  const availableCount = useMemo(
    () => booleans.filter(Boolean).length,
    [booleans]
  );

  const totalCount = booleans.length;

  const { iconName, label, variant, showUnlock } = getAvailableLabelConfig({
    availableCount,
    totalCount,
    premiumLock: requiresPremium && !isUserPremium,
  });

  return (
    <Flex justify="space-between">
      <S.StyledAvailableLabel variant={variant} icon={<Icon name={iconName} />}>
        {label}
      </S.StyledAvailableLabel>
      {showUnlock && (
        <S.StyledUnlockButton onClick={unlockPremium} size="sm" variant="text">
          Unlock
        </S.StyledUnlockButton>
      )}
    </Flex>
  );
};
