/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsIssuerEntityIndex } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { IssuerEntityAggregate } from '../model';
// @ts-ignore
import { IssuerEntityIndex } from '../model';
// @ts-ignore
import { IssuerEntitySimpleIndex } from '../model';
// @ts-ignore
import { PublicIssuerEntityIndex } from '../model';
/**
 * IssuerEntityApi - axios parameter creator
 * @export
 */
export const IssuerEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary lists all issuers associated with the account (through the non-expired events the account is associated with)
         * @param {number} accountId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIssuerEntityIndex: async (accountId: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountIssuerEntityIndex', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/issuer-entities`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of issuers with data summary which the current authorized user is associated based on resource type
         * @param {'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation'} resourceType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityFiltersIndex: async (resourceType: 'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation', page?: number, size?: number, search?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('issuerEntityFiltersIndex', 'resourceType', resourceType)
            const localVarPath = changeUrl(`/api/filters/issuer-entities`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (resourceType !== undefined) {
                localVarQueryParameter['resource_type'] = resourceType;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of issuers the current authorized user is associated with
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [unverifiedHoldings] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [withOrders] 
         * @param {boolean} [watchlistOnly] 
         * @param {boolean} [watchlistHide] 
         * @param {number} [watchlistAccountId] 
         * @param {boolean} [portfolioOnly] 
         * @param {string} [sector] 
         * @param {string} [investor] 
         * @param {boolean} [dualSided] 
         * @param {string} [totalFunding] 
         * @param {string} [latestDealSize] 
         * @param {string} [companyActive] 
         * @param {string} [latestValuation] 
         * @param {string} [lastFunded] 
         * @param {string} [sort] 
         * @param {Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityIndex: async (page?: number, size?: number, search?: string, unverifiedHoldings?: boolean, includeSpvs?: boolean, withOrders?: boolean, watchlistOnly?: boolean, watchlistHide?: boolean, watchlistAccountId?: number, portfolioOnly?: boolean, sector?: string, investor?: string, dualSided?: boolean, totalFunding?: string, latestDealSize?: string, companyActive?: string, latestValuation?: string, lastFunded?: string, sort?: string, includeField?: Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/issuer-entities`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (unverifiedHoldings !== undefined) {
                localVarQueryParameter['unverified_holdings'] = unverifiedHoldings;
            }

            if (includeSpvs !== undefined) {
                localVarQueryParameter['include_spvs'] = includeSpvs;
            }

            if (withOrders !== undefined) {
                localVarQueryParameter['with_orders'] = withOrders;
            }

            if (watchlistOnly !== undefined) {
                localVarQueryParameter['watchlist_only'] = watchlistOnly;
            }

            if (watchlistHide !== undefined) {
                localVarQueryParameter['watchlist_hide'] = watchlistHide;
            }

            if (watchlistAccountId !== undefined) {
                localVarQueryParameter['watchlist_account_id'] = watchlistAccountId;
            }

            if (portfolioOnly !== undefined) {
                localVarQueryParameter['portfolio_only'] = portfolioOnly;
            }

            if (sector !== undefined) {
                localVarQueryParameter['sector'] = sector;
            }

            if (investor !== undefined) {
                localVarQueryParameter['investor'] = investor;
            }

            if (dualSided !== undefined) {
                localVarQueryParameter['dual_sided'] = dualSided;
            }

            if (totalFunding !== undefined) {
                localVarQueryParameter['total_funding'] = totalFunding;
            }

            if (latestDealSize !== undefined) {
                localVarQueryParameter['latest_deal_size'] = latestDealSize;
            }

            if (companyActive !== undefined) {
                localVarQueryParameter['company_active'] = companyActive;
            }

            if (latestValuation !== undefined) {
                localVarQueryParameter['latest_valuation'] = latestValuation;
            }

            if (lastFunded !== undefined) {
                localVarQueryParameter['last_funded'] = lastFunded;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show the issuer specified in the path
         * @param {string} id 
         * @param {Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityShow: async (id: string, includeField?: Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issuerEntityShow', 'id', id)
            const localVarPath = changeUrl(`/api/issuer-entities/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of all issuers without authorization or headers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicIssuerEntityIndex: async (page?: number, size?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/public/issuer-entities`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssuerEntityApi - functional programming interface
 * @export
 */
export const IssuerEntityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssuerEntityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary lists all issuers associated with the account (through the non-expired events the account is associated with)
         * @param {number} accountId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountIssuerEntityIndex(accountId: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsIssuerEntityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountIssuerEntityIndex(accountId, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a list of issuers with data summary which the current authorized user is associated based on resource type
         * @param {'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation'} resourceType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityFiltersIndex(resourceType: 'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation', page?: number, size?: number, search?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntitySimpleIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityFiltersIndex(resourceType, page, size, search, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a list of issuers the current authorized user is associated with
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [unverifiedHoldings] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [withOrders] 
         * @param {boolean} [watchlistOnly] 
         * @param {boolean} [watchlistHide] 
         * @param {number} [watchlistAccountId] 
         * @param {boolean} [portfolioOnly] 
         * @param {string} [sector] 
         * @param {string} [investor] 
         * @param {boolean} [dualSided] 
         * @param {string} [totalFunding] 
         * @param {string} [latestDealSize] 
         * @param {string} [companyActive] 
         * @param {string} [latestValuation] 
         * @param {string} [lastFunded] 
         * @param {string} [sort] 
         * @param {Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityIndex(page?: number, size?: number, search?: string, unverifiedHoldings?: boolean, includeSpvs?: boolean, withOrders?: boolean, watchlistOnly?: boolean, watchlistHide?: boolean, watchlistAccountId?: number, portfolioOnly?: boolean, sector?: string, investor?: string, dualSided?: boolean, totalFunding?: string, latestDealSize?: string, companyActive?: string, latestValuation?: string, lastFunded?: string, sort?: string, includeField?: Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityIndex(page, size, search, unverifiedHoldings, includeSpvs, withOrders, watchlistOnly, watchlistHide, watchlistAccountId, portfolioOnly, sector, investor, dualSided, totalFunding, latestDealSize, companyActive, latestValuation, lastFunded, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show the issuer specified in the path
         * @param {string} id 
         * @param {Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityShow(id: string, includeField?: Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuerEntityAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityShow(id, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a list of all issuers without authorization or headers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicIssuerEntityIndex(page?: number, size?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicIssuerEntityIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicIssuerEntityIndex(page, size, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssuerEntityApi - factory interface
 * @export
 */
export const IssuerEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssuerEntityApiFp(configuration)
    return {
        /**
         * 
         * @summary lists all issuers associated with the account (through the non-expired events the account is associated with)
         * @param {number} accountId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIssuerEntityIndex(accountId: number, page?: number, size?: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsIssuerEntityIndex> {
            return localVarFp.accountIssuerEntityIndex(accountId, page, size, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of issuers with data summary which the current authorized user is associated based on resource type
         * @param {'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation'} resourceType 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityFiltersIndex(resourceType: 'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation', page?: number, size?: number, search?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntitySimpleIndex> {
            return localVarFp.issuerEntityFiltersIndex(resourceType, page, size, search, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of issuers the current authorized user is associated with
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [unverifiedHoldings] 
         * @param {boolean} [includeSpvs] 
         * @param {boolean} [withOrders] 
         * @param {boolean} [watchlistOnly] 
         * @param {boolean} [watchlistHide] 
         * @param {number} [watchlistAccountId] 
         * @param {boolean} [portfolioOnly] 
         * @param {string} [sector] 
         * @param {string} [investor] 
         * @param {boolean} [dualSided] 
         * @param {string} [totalFunding] 
         * @param {string} [latestDealSize] 
         * @param {string} [companyActive] 
         * @param {string} [latestValuation] 
         * @param {string} [lastFunded] 
         * @param {string} [sort] 
         * @param {Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityIndex(page?: number, size?: number, search?: string, unverifiedHoldings?: boolean, includeSpvs?: boolean, withOrders?: boolean, watchlistOnly?: boolean, watchlistHide?: boolean, watchlistAccountId?: number, portfolioOnly?: boolean, sector?: string, investor?: string, dualSided?: boolean, totalFunding?: string, latestDealSize?: string, companyActive?: string, latestValuation?: string, lastFunded?: string, sort?: string, includeField?: Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntityIndex> {
            return localVarFp.issuerEntityIndex(page, size, search, unverifiedHoldings, includeSpvs, withOrders, watchlistOnly, watchlistHide, watchlistAccountId, portfolioOnly, sector, investor, dualSided, totalFunding, latestDealSize, companyActive, latestValuation, lastFunded, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show the issuer specified in the path
         * @param {string} id 
         * @param {Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityShow(id: string, includeField?: Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<IssuerEntityAggregate> {
            return localVarFp.issuerEntityShow(id, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of all issuers without authorization or headers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicIssuerEntityIndex(page?: number, size?: number, search?: string, options?: any): AxiosPromise<PublicIssuerEntityIndex> {
            return localVarFp.publicIssuerEntityIndex(page, size, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountIssuerEntityIndex operation in IssuerEntityApi.
 * @export
 * @interface IssuerEntityApiAccountIssuerEntityIndexRequest
 */
export interface IssuerEntityApiAccountIssuerEntityIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly accountId: number

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiAccountIssuerEntityIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for issuerEntityFiltersIndex operation in IssuerEntityApi.
 * @export
 * @interface IssuerEntityApiIssuerEntityFiltersIndexRequest
 */
export interface IssuerEntityApiIssuerEntityFiltersIndexRequest {
    /**
     * 
     * @type {'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation'}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly resourceType: 'event' | 'agreement' | 'event_folder' | 'match' | 'tax_document' | 'transaction_confirmation'

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityFiltersIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for issuerEntityIndex operation in IssuerEntityApi.
 * @export
 * @interface IssuerEntityApiIssuerEntityIndexRequest
 */
export interface IssuerEntityApiIssuerEntityIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly unverifiedHoldings?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly includeSpvs?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly withOrders?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly watchlistOnly?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly watchlistHide?: boolean

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly watchlistAccountId?: number

    /**
     * 
     * @type {boolean}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly portfolioOnly?: boolean

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly sector?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly investor?: string

    /**
     * 
     * @type {boolean}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly dualSided?: boolean

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly totalFunding?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly latestDealSize?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly companyActive?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly latestValuation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly lastFunded?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly includeField?: Array<'type_totals' | 'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for issuerEntityShow operation in IssuerEntityApi.
 * @export
 * @interface IssuerEntityApiIssuerEntityShowRequest
 */
export interface IssuerEntityApiIssuerEntityShowRequest {
    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly id: string

    /**
     * 
     * @type {Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly includeField?: Array<'bids_count' | 'asks_count' | 'live_bids_count' | 'live_offers_count' | 'hidden_bids_count' | 'hidden_offers_count' | 'hidden_inventory_count' | 'live_inventory_count' | 'last_order_created_at_date'>

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiIssuerEntityShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for publicIssuerEntityIndex operation in IssuerEntityApi.
 * @export
 * @interface IssuerEntityApiPublicIssuerEntityIndexRequest
 */
export interface IssuerEntityApiPublicIssuerEntityIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiPublicIssuerEntityIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof IssuerEntityApiPublicIssuerEntityIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof IssuerEntityApiPublicIssuerEntityIndex
     */
    readonly search?: string
}

/**
 * IssuerEntityApi - object-oriented interface
 * @export
 * @class IssuerEntityApi
 * @extends {BaseAPI}
 */
export class IssuerEntityApi extends BaseAPI {
    /**
     * 
     * @summary lists all issuers associated with the account (through the non-expired events the account is associated with)
     * @param {IssuerEntityApiAccountIssuerEntityIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuerEntityApi
     */
    public accountIssuerEntityIndex(requestParameters: IssuerEntityApiAccountIssuerEntityIndexRequest, options?: AxiosRequestConfig) {
        return IssuerEntityApiFp(this.configuration).accountIssuerEntityIndex(requestParameters.accountId, requestParameters.page, requestParameters.size, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of issuers with data summary which the current authorized user is associated based on resource type
     * @param {IssuerEntityApiIssuerEntityFiltersIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuerEntityApi
     */
    public issuerEntityFiltersIndex(requestParameters: IssuerEntityApiIssuerEntityFiltersIndexRequest, options?: AxiosRequestConfig) {
        return IssuerEntityApiFp(this.configuration).issuerEntityFiltersIndex(requestParameters.resourceType, requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of issuers the current authorized user is associated with
     * @param {IssuerEntityApiIssuerEntityIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuerEntityApi
     */
    public issuerEntityIndex(requestParameters: IssuerEntityApiIssuerEntityIndexRequest = {}, options?: AxiosRequestConfig) {
        return IssuerEntityApiFp(this.configuration).issuerEntityIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.unverifiedHoldings, requestParameters.includeSpvs, requestParameters.withOrders, requestParameters.watchlistOnly, requestParameters.watchlistHide, requestParameters.watchlistAccountId, requestParameters.portfolioOnly, requestParameters.sector, requestParameters.investor, requestParameters.dualSided, requestParameters.totalFunding, requestParameters.latestDealSize, requestParameters.companyActive, requestParameters.latestValuation, requestParameters.lastFunded, requestParameters.sort, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show the issuer specified in the path
     * @param {IssuerEntityApiIssuerEntityShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuerEntityApi
     */
    public issuerEntityShow(requestParameters: IssuerEntityApiIssuerEntityShowRequest, options?: AxiosRequestConfig) {
        return IssuerEntityApiFp(this.configuration).issuerEntityShow(requestParameters.id, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of all issuers without authorization or headers
     * @param {IssuerEntityApiPublicIssuerEntityIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuerEntityApi
     */
    public publicIssuerEntityIndex(requestParameters: IssuerEntityApiPublicIssuerEntityIndexRequest = {}, options?: AxiosRequestConfig) {
        return IssuerEntityApiFp(this.configuration).publicIssuerEntityIndex(requestParameters.page, requestParameters.size, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AccountIssuerEntityIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 403;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AccountIssuerEntityIndexErrorCodes = [
    401,
    403,
    422,
];

export type IssuerEntityFiltersIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityFiltersIndexErrorCodes = [
    401,
];

export type IssuerEntityIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityIndexErrorCodes = [
    401,
];

export type IssuerEntityShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityShowErrorCodes = [
    401,
];

export type PublicIssuerEntityIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const PublicIssuerEntityIndexErrorCodes = [
];


