/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



  /**
 * 
 * @export
 * @interface ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
 */
export interface ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract {
    /**
     * 
     * @type {number}
     * @memberof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
     */
    'id_gte'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
     */
    'cap_table_type'?: ServiceToServiceUserlessHoldingsHoldingsIndexRequestContractCapTableTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
     */
    'holding_npm_guids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
     */
    'not_last_modified_by'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContract
     */
    'is_active'?: boolean;
}

export const ServiceToServiceUserlessHoldingsHoldingsIndexRequestContractCapTableTypeEnum = {
    NpmsPortfolio: 'NPMS_PORTFOLIO',
    Private: 'PRIVATE',
    Public: 'PUBLIC',
    VerifiedTransfers: 'VERIFIED_TRANSFERS'
} as const;

export type ServiceToServiceUserlessHoldingsHoldingsIndexRequestContractCapTableTypeEnum = typeof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContractCapTableTypeEnum[keyof typeof ServiceToServiceUserlessHoldingsHoldingsIndexRequestContractCapTableTypeEnum];


