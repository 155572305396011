import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { useNavigate } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';

import { CompanySearch } from '../filters';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export const SelectWatchlistModal = ({ isOpen, onCancel }: Props) => {
  const [form] = Form.useForm<{ watchlist: string }>();
  const selectedIssuerEntityId = Form.useWatch('watchlist', form);

  const navigate = useNavigate();

  return (
    <Modal
      open={isOpen}
      title="Select a company where you own stocks"
      okText="Confirm"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() =>
        navigate(
          `/second-market/investor/sell-indication?companyId=${selectedIssuerEntityId}`
        )
      }
      okButtonProps={{ disabled: !selectedIssuerEntityId }}
      destroyOnClose
    >
      <Form form={form}>
        <FormItem
          name={'watchlist'}
          label="Which company's stock do you own?"
          extra="Browse through the pre-IPO companies we work with"
        >
          <CompanySearch
            placeholder="Select a company"
            getPopupContainer={() => document.body}
            requestCoverageVariant="row"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};
