import React, { type ComponentProps } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { InlineInputNumber } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CbAssetType, type IssuerEntityAggregate } from '@npm/data-access';
import { Form } from 'antd';
import type { FormInstance } from 'antd/es/form/Form';

import { useIsInvestor } from '../../../auth/user/role';
import { ContextualHelpAssetType } from '../../../educationalModals/AssetType';
import { AssetTypesSelect } from '../../../filters';

import { type AddAggregatedHoldingFormValues } from './AggregatedHoldingForm.hooks';
import { AggregatedHoldingFormStyles as S } from './AggregatedHoldingForm.styles';

type Props = ComponentProps<typeof Form> & {
  form: FormInstance<AddAggregatedHoldingFormValues>;
  issuerEntity?: IssuerEntityAggregate;
  isEdit?: boolean;
};

export const AggregatedHoldingForm = ({
  form,
  issuerEntity,
  isEdit = false,
  ...formProps
}: Props) => {
  const isInvestor = useIsInvestor();

  return (
    <Form form={form} {...formProps}>
      <S.Container>
        <div>
          <Margin bottom="sm">
            <Text size="sm" colorVariant="primary" weight="bold">
              Tell us what you own in {issuerEntity?.name}
            </Text>
          </Margin>
          <Text size="sm" colorVariant="secondary">
            Provide details of your current holdings, including proof of
            ownership, to ensure we can verify your shares.
          </Text>
          {isInvestor && (
            <Margin top="md">
              <ContextualHelpAssetType />
            </Margin>
          )}
        </div>
        <FormItem
          name="type"
          label="Asset Type"
          rules={[VALIDATION_RULES.required()]}
        >
          <AssetTypesSelect
            name="type"
            form={form}
            disabled={isEdit}
            // we don't pass issuerEntityId as venus stock classes don't make sense here
            // series is always "Aggregated"
            filterOptions={codebookItem =>
              codebookItem.code !== CbAssetType.items.Blend
            }
            placeholder="Select"
          />
        </FormItem>
        <FormItem name="quantity" rules={[VALIDATION_RULES.required()]}>
          <InlineInputNumber label="Quantity" min={1} inputMode="decimal" />
        </FormItem>
      </S.Container>
    </Form>
  );
};
