import React, { useMemo } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { type Accreditation } from '@npm/data-access';

import {
  ZendeskContactButton,
  type ZendeskTicketGroupID,
} from '../../../../app/zendesk';

type Props = {
  answers: Accreditation['answers'];
  isFailed: boolean;
  zendeskTicketGroupId: ZendeskTicketGroupID;
};

export const FilledFormView = ({
  answers,
  isFailed,
  zendeskTicketGroupId,
}: Props) => {
  const items = useMemo(
    () =>
      answers?.map(ans => ({
        label: ans.field?.name,
        value: ans.answer?.name,
      })),
    [answers]
  );

  return (
    <>
      <Margin bottom="lg">
        <Alert
          type={isFailed ? 'error' : 'info'}
          message={
            <div>
              {isFailed ? (
                <>
                  Based on the information you provided,{' '}
                  <Text
                    size="sm"
                    as="span"
                    weight="bold"
                    colorVariant="primary"
                  >
                    you do not qualify as an accredited investor
                  </Text>
                  . If you have entered the wrong information, please{' '}
                  <ZendeskContactButton
                    zendeskTicketGroupId={zendeskTicketGroupId}
                  >
                    contact us
                  </ZendeskContactButton>
                </>
              ) : (
                <>
                  If you have entered the wrong information, please{' '}
                  <ZendeskContactButton
                    zendeskTicketGroupId={zendeskTicketGroupId}
                  >
                    contact us
                  </ZendeskContactButton>
                </>
              )}
            </div>
          }
          closable
        />
      </Margin>
      <ReadOnly items={items} />
    </>
  );
};
