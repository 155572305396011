import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import styled, { css } from 'styled-components';

const FileName = styled(Text).attrs({
  size: 'sm',
  color: 'info',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: ${({ theme }) => theme.spacing.sm}px;
`;

const FileContainer = styled.div(
  ({ theme: { spacing } }) => css`
    margin-top: ${spacing.sm}px;
    display: flex;
    align-items: center;
    width: 100%;
  `
);

const PaperClipWrapper = styled(Flex)(
  ({ theme: { spacing, color } }) => css`
    margin-right: ${spacing.sm}px;
    color: ${color.general.typography.secondary};
  `
);

const TrashButton = styled(Flex)(
  ({ theme: { spacing } }) => css`
    padding: ${spacing.xs}px;
    margin: -${spacing.xs}px; // to increase click area
    margin-left: auto;
  `
);

export const PooUploadComponentsStyles = {
  FileName,
  FileContainer,
  PaperClipWrapper,
  TrashButton,
};
