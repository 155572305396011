import { useMemo, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { getPageSize, Table } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type IssuerEntityAggregate, VenusApi } from '@npm/data-access';
import { type PaginationProps } from 'antd';
import { orderBy } from 'lodash';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../documents/DocumentViewer';
import { NotCoveredBanner } from '../components/NotCoveredBanner';

import * as S from './SourceDocuments.styles';
import { labelVariantByType, typeFilters } from './SourceDocuments.utils';

type Props = {
  issuerEntity: IssuerEntityAggregate | undefined;
  companyProfile: VenusApi.CompanyProfile | undefined;
};

export const SourceDocuments = ({ issuerEntity, companyProfile }: Props) => {
  const { isMobile, isTablet } = useBreakpoints();

  const { data, isFetching } = VenusApi.useCompanyFilings(
    {
      companyId: issuerEntity?.venus_id?.toString(),
    },
    {
      queryConfig: { enabled: issuerEntity?.venus_id != null },
      onError: err => {
        if (err?.status === 404) return; // ignore
        throw err;
      },
    }
  );

  const [typeFilter, setTypeFilter] = useState('all');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const filingsByType = useMemo(() => {
    return typeFilters.reduce<
      Record<string, VenusApi.SimpleFiling[] | undefined>
    >((acc, cur) => {
      acc[cur.key] = data?.filings?.filter(cur.filter);
      return acc;
    }, {});
  }, [data]);

  const filteredAndSortedFilings = useMemo(() => {
    const filteredFilings = filingsByType[typeFilter] ?? [];
    return orderBy(filteredFilings, ['filing_date'], [sortDirection]);
  }, [filingsByType, typeFilter, sortDirection]);

  const [pagination, setPagination] = useState<PaginationProps>({
    pageSize: getPageSize('table'),
    current: 1,
  });

  const { documentViewerModalProps, showDocumentPreview } = useDocumentViewer();

  if (companyProfile && !isFetching && !data?.filings?.length) {
    return <NotCoveredBanner issuerEntity={issuerEntity} />;
  }

  return (
    <Card variant="secondMarket" noContentPadding>
      <S.Controls>
        <Flex justify="space-between" gap="sm">
          <Tabs
            variant="tertiary"
            onChange={key => {
              setTypeFilter(key);
              setPagination(prev => ({ ...prev, current: 1 }));
            }}
            containerStyle={{ minWidth: 0, flexShrink: 1 }}
          >
            {typeFilters.map(({ title, key }) => {
              if (filingsByType[key]?.length === 0) return null;

              return (
                <Tabs.TabPane
                  tab={`${title} (${filingsByType[key]?.length ?? 0})`}
                  key={key}
                />
              );
            })}
          </Tabs>
          <Dropdown
            items={[
              {
                label: 'Newest',
                onClick: () => setSortDirection('desc'),
              },
              {
                label: 'Oldest',
                onClick: () => setSortDirection('asc'),
              },
            ]}
          >
            <Button
              variant="outline"
              icon={<Icon name="chevron-down" />}
              iconPosition="right"
            >
              {sortDirection === 'desc' ? 'Newest' : 'Oldest'}
            </Button>
          </Dropdown>
        </Flex>
      </S.Controls>
      <Table
        dataSource={filteredAndSortedFilings}
        loading={isFetching}
        columns={[
          {
            key: 'type',
            title: 'Type',
            render: ({ type }) => (
              <Label variant={labelVariantByType[type]}>{type}</Label>
            ),
          },
          {
            key: 'name',
            title: 'Filing Name',
            render: ({ name }) => name,
          },
          {
            key: 'filing_date',
            title: 'Filing Date',
            render: ({ filing_date }) =>
              formatDate(filing_date, {
                dateFormat: DATE_FORMATS.DATE,
              }),
          },
          {
            key: 'actions',
            title: '',
            render: ({ id, link }) => (
              <Button
                variant="text"
                size="md"
                icon={<Icon name="file" />}
                onClick={() => {
                  if (link) {
                    window.open(link);
                  } else {
                    showDocumentPreview({
                      type: 'with-path',
                      path: `/api/venus/${issuerEntity?.venus_id}/filings/${id}?issuer_entity_id=${issuerEntity?.id}`,
                    });
                  }
                }}
                block
              >
                {isMobile || isTablet ? undefined : 'View Document'}
              </Button>
            ),
            width: isMobile || isTablet ? 60 : 160,
          },
        ]}
        pagination={{ ...pagination, total: filteredAndSortedFilings.length }}
        onChange={setPagination}
        outlined={false}
      />
      <DocumentViewerModal {...documentViewerModalProps} />
    </Card>
  );
};
