// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MTMFilingApi,
  MTMFilingPreviewErrorCodes,
  MTMFilingPreviewErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MTMFilingApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type MTMFilingPreviewMethod = MTMFilingApi['mTMFilingPreview'];
export type MTMFilingPreviewResponseType = MethodResult<MTMFilingPreviewMethod>;

export const useMTMFilingPreview = (
  variables: Parameters<MTMFilingPreviewMethod>[0],
  config?: UseQueryConfig<
    MTMFilingPreviewMethod,
    MTMFilingPreviewErrorTypes
  >
) => {
  return useQuery<
    MTMFilingPreviewMethod,
    MTMFilingPreviewErrorTypes
  >(
    (...args) => api.mTMFilingPreview(...args),
    MTMFilingPreviewErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MTMFilingPreview'],
        ...apiQueryConfig.MTMFilingPreview,
        ...config?.queryConfig
      },
    },
    'MTMFilingPreview',
  );
};

export const useMTMFilingPreviewInfinite = (
  variables: Parameters<MTMFilingPreviewMethod>[0],
  config?: UseInfiniteQueryConfig<
    MTMFilingPreviewMethod,
    MTMFilingPreviewErrorTypes
  >
) => {
  return useInfiniteQuery<
    MTMFilingPreviewMethod,
    MTMFilingPreviewErrorTypes
  >(
    (...args) => api.mTMFilingPreview(...args),
    MTMFilingPreviewErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MTMFilingPreview', 'MTMFilingPreviewInfinite'],
        ...apiQueryConfig.MTMFilingPreview,
        ...config?.queryConfig,
      },
    },
    'MTMFilingPreview',
  );
};

export const useMTMFilingPreviewLazy = (baseOptions?: {
  variables?: Parameters<MTMFilingPreviewMethod>[0],
  config?: UseQueryConfig<
    MTMFilingPreviewMethod,
    MTMFilingPreviewErrorTypes
  >
}) => {
  return useLazyQuery<
    MTMFilingPreviewMethod,
    MTMFilingPreviewErrorTypes
  >(
    (...args) => api.mTMFilingPreview(...args),
    MTMFilingPreviewErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MTMFilingPreview'],
        ...apiQueryConfig.MTMFilingPreview,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MTMFilingPreview',
  );
};

