import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  CbAccountType,
  CbAccreditationState,
  CbBackgroundCheckState,
  type UserRoleAggregate,
} from '@npm/data-access';
import { useTheme } from 'styled-components';

import * as S from '../../../../ItemButton/ItemButton.styles';
import { ItemButton } from '../../../../ItemButton';
import type { ExtraColumnsType } from '../AccountsTable';

type Props = {
  userRole: UserRoleAggregate | null;
  onClick?: () => void;
  readOnly?: boolean;
  extraColumns?: ExtraColumnsType;
  index: number;
};

export const AccountButton = ({
  userRole,
  onClick,
  readOnly,
  extraColumns,
  index,
}: Props) => {
  const theme = useTheme();

  const { role_name, subject } = userRole;

  return (
    <ItemButton
      onClick={onClick}
      readOnly={readOnly}
      data-dd-action-name="Account Role Button"
    >
      <S.FlexWrap>
        <S.IconWrap>
          {subject?.type.code === CbAccountType.items.PersonAccount ? (
            <Icon
              name="user-circle"
              size="xs"
              color={theme.color.info.typography.primary}
            />
          ) : (
            <Icon
              name="building-bank"
              size="xs"
              color={theme.color.info.typography.primary}
            />
          )}
        </S.IconWrap>
        <div>
          <Text
            size="sm"
            colorVariant="primary"
            marginBottom="xs"
            data-dd-privacy="mask"
          >
            {subject?.type.code === CbAccountType.items.Anonymous
              ? 'Anonymous'
              : subject?.name}
          </Text>
          <Flex>
            <Text size="xs" data-dd-privacy="mask">
              {subject?.brokerage_firm_name} - {subject?.external_id}
            </Text>
            <S.DotSeparatorWrapper colorVariant="muted">
              •
            </S.DotSeparatorWrapper>
            <Text size="xs" color="info">
              {role_name?.name}
            </Text>
          </Flex>
          <Flex gap="md" align="center">
            <Flex gap="sm" align="center">
              <Text size="xs" colorVariant="tertiary">
                AML/KYC
              </Text>
              {CbBackgroundCheckState.getLabel(
                CbLabel,
                subject.background_check_state
              )}
            </Flex>
            <Flex gap="sm" align="center">
              {extraColumns?.length ? (
                extraColumns.map(column => (
                  <>
                    <Text size="xs" colorVariant="tertiary">
                      {column.title}
                    </Text>
                    {column.render(userRole, index)}
                  </>
                ))
              ) : (
                <>
                  <Text size="xs" colorVariant="tertiary">
                    Accreditation
                  </Text>
                  {CbAccreditationState.getLabel(
                    CbLabel,
                    subject.accreditation_state
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </div>
      </S.FlexWrap>
    </ItemButton>
  );
};
