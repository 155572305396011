import React, { type ReactElement, useMemo, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Tabs as AntTabs, type TabPaneProps, type TabsProps } from 'antd';
import { compact } from 'lodash';

import { useDarkModeContext } from '../../../context/DarkModeContext';
import { Button } from '../Button';
import { Dropdown, type DropdownProps } from '../Dropdown';
import { ErrorSkeleton } from '../ErrorSkeleton';
import { Icon } from '../Icon';

import * as S from './Tabs.styles';
import { useOverflowedTabs } from './Tabs.hooks';
import { type TabsVariant } from './Tabs.types';
import { handleApplePencilClick } from './Tabs.utils';

type Props = TabsProps & {
  variant?: TabsVariant;
  hideMoreButton?: boolean;
  outlined?: boolean;
  dropdownProps?: Partial<Pick<DropdownProps, 'zIndex' | 'fixedPosition'>>;
  containerStyle?: React.CSSProperties;
};

const Tabs = ({
  hideMoreButton = false,
  outlined,
  dropdownProps,
  containerStyle,
  ...props
}: Props) => {
  const { isDarkMode } = useDarkModeContext();
  const childrenArray = React.Children.toArray(props.children);
  const childrenCount = compact(childrenArray).length;

  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const overflowedTabs = useOverflowedTabs(
    tabsContainerRef,
    props.children as ReactElement<TabPaneProps>[]
  );

  const dropdownItems = useMemo(() => {
    return overflowedTabs.map(tab => ({
      label: tab.name,
      onClick: () => props.onChange?.(tab.key),
    }));
  }, [overflowedTabs, props]);

  const isMoreTabsVisible = !!overflowedTabs.length && !hideMoreButton;

  return (
    <S.TabsContainer
      ref={tabsContainerRef}
      $isMoreTabsVisible={isMoreTabsVisible}
      onPointerUp={e =>
        props.onChange &&
        handleApplePencilClick({ e, onChange: props.onChange })
      }
      style={containerStyle}
    >
      <S.DropdownGlobalStyles />
      <S.StyledTabs
        $isMoreTabsVisible={isMoreTabsVisible}
        $outlined={outlined}
        // do not apply active styles on a tab if there is only one (implemented for `tertiary` variant only)
        $isOneTab={childrenCount === 1}
        {...props}
      >
        {props.children}
      </S.StyledTabs>
      {overflowedTabs.length && !hideMoreButton ? (
        <S.MoreTabsContainer $isDarkMode={isDarkMode}>
          <Dropdown items={dropdownItems} zIndex="aboveAll" {...dropdownProps}>
            <Button variant="text" icon={<Icon name="menu" />} />
          </Dropdown>
        </S.MoreTabsContainer>
      ) : null}
    </S.TabsContainer>
  );
};

Tabs.TabPane = ({
  children,
  ...props
}: React.ComponentProps<typeof AntTabs.TabPane>) => (
  <AntTabs.TabPane {...props}>
    {children && (
      <ErrorBoundary FallbackComponent={ErrorSkeleton}>
        {children}
      </ErrorBoundary>
    )}
  </AntTabs.TabPane>
);
Tabs.Badge = S.Badge;
Tabs.TotalRecordsBadge = S.TotalRecordsBadge;

export { Tabs };
