import React, { type ComponentProps, useCallback, useMemo } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type FolderAggregate } from '@npm/data-access';
import { useTheme } from 'styled-components';

import type { DataRoom } from '../../DataRoom';

import * as S from './Path.styles';

type Props = {
  history: FolderAggregate[];
  actions?: React.ReactNode;
} & Pick<
  ComponentProps<typeof DataRoom>,
  | 'position'
  | 'onBackClick'
  | 'onHomeClick'
  | 'companyLogoUrl'
  | 'companyName'
  | 'canAccessDataRoomsList'
>;

export const Path = ({
  actions,
  history,
  position,
  onBackClick,
  onHomeClick,
  companyLogoUrl,
  companyName,
  canAccessDataRoomsList,
}: Props) => {
  const name = history[position]?.name;
  const parentId = history[position]?.parent_id;
  const parentName = history[position - 1]?.name;

  const { isMobile } = useBreakpoints();
  const theme = useTheme();

  // we handle position differently based on canAccessDataRoomsList
  const showDotsAtPosition = useMemo(() => {
    if (isMobile) {
      return canAccessDataRoomsList ? 0 : 1;
    } else {
      return canAccessDataRoomsList ? 1 : 2;
    }
  }, [canAccessDataRoomsList, isMobile]);

  const isRoot =
    parentId === undefined || (parentId === -1 && !canAccessDataRoomsList);

  const renderLevel = useCallback(
    (content: React.ReactNode, className?: string, isLast?: boolean) => (
      <S.Level className={className}>
        <S.LevelContent>
          <Icon name="chevron-right" size="xs" />
          {content}
          {isLast && <S.LevelBumper />}
        </S.LevelContent>
      </S.Level>
    ),
    []
  );

  const renderDots = useCallback(() => {
    const dotsButton = (
      <Button
        size="sm"
        icon={<Icon name="menu" />}
        variant="text"
        onClick={() => onBackClick?.()}
      />
    );

    return (
      <>
        {position > showDotsAtPosition && renderLevel(dotsButton, 'dots')}
        {position === showDotsAtPosition &&
          !canAccessDataRoomsList &&
          renderLevel(
            <Text
              size="sm"
              onClick={() => {
                const isFirstElementClicked = canAccessDataRoomsList
                  ? position === 1
                  : position === 2;

                if (isFirstElementClicked) {
                  onBackClick?.(2);
                } else {
                  onBackClick?.();
                }
              }}
            >
              {history[position - showDotsAtPosition]?.name}
            </Text>
          )}
        {!isMobile &&
          parentId !== -1 &&
          parentName &&
          renderLevel(
            <Text size="sm" onClick={() => onBackClick?.()}>
              {parentName}
            </Text>
          )}{' '}
      </>
    );
  }, [
    isMobile,
    position,
    showDotsAtPosition,
    renderLevel,
    parentName,
    parentId,
    onBackClick,
    canAccessDataRoomsList,
    history,
  ]);

  return (
    <S.PathCard
      header={
        companyName && {
          icon: (
            <CompanyLogo url={companyLogoUrl} title={companyName} size="sm" />
          ),
          iconText: companyName,
        }
      }
    >
      <S.PathContainer>
        <Button
          size="sm"
          icon={
            <Icon
              name="home"
              size="xs"
              color={
                isRoot ? theme.color.general.typography.primary : undefined
              }
            />
          }
          disabled={isRoot}
          variant="text"
          onClick={() => onHomeClick?.()}
          data-cy={CypressDataIds.DataRooms.FileExplorer.HomeButton}
        />
        {renderDots()}
        {!isRoot &&
          renderLevel(
            <Text weight="bold" size="sm" colorVariant="primary">
              {name}
            </Text>,
            '',
            true
          )}

        <S.ActionsContainer>{actions}</S.ActionsContainer>
      </S.PathContainer>
    </S.PathCard>
  );
};
