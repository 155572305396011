import { Flex } from '@npm/core/ui/components/atoms/common';
import styled, { css } from 'styled-components';

export const DottedText = styled.span(
  () => css`
    span {
      text-decoration: underline dotted;
    }
  `
);

export const ViewTextButton = styled(Flex)(
  ({ theme: { color } }) => css`
    cursor: pointer;
    && p,
    svg {
      color: ${color.info.typography.primary};
    }
    :hover {
      svg,
      p {
        color: ${color.info.backgrounds.primaryHover};
      }
    }
  `
);
