import { centerFlex } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm}px;
  background: ${({ theme }) => theme.color.general.layout.one};
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  text-align: left;
`;

export const IconWrapper = styled.div`
  ${centerFlex}
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
`;
