import styled, { css } from 'styled-components';

const Container = styled.div<{ $isInline?: boolean }>(
  ({ theme: { spacing, color }, $isInline }) => css`
    display: ${$isInline ? 'inline-flex' : 'flex'};
    align-items: center;
    justify-content: center;
    height: 28px;
    padding: ${spacing.xs}px ${spacing.sm}px ${spacing.xs}px ${spacing.xs}px;
    gap: ${spacing.xs}px;
    border-radius: 6px;
    background-color: ${color.advice.backgrounds.primaryDefault};
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      background-color: ${color.advice.backgrounds.primaryHover};
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 2px ${color.advice.borders.primary};
    }

    svg {
      color: ${color.advice.typography.primary};
    }
  `
);

export const ContextualHelp = { Container };
