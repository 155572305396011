import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  pending: 'pending',
  verify_holding: 'verify_holding',
  pending_issuer_review: 'pending_issuer_review',
  pending_rofr: 'pending_rofr',
  signature_in_progress: 'signature_in_progress',
  ready_to_settle: 'ready_to_settle',
  settled: 'settled',
  funded: 'funded',
  frozen: 'frozen',
  wired: 'wired',
  rofr_blocked: 'rofr_blocked',
  rofr_assigned: 'rofr_assigned',
  rofr_waive: 'rofr_waive',
  designated: 'designated',
  seller_designates: 'seller_designates',
  buyer_designates: 'buyer_designates',
  multiple: 'multiple',
  not_applicable: 'not_applicable',
  holding_entry: 'holding_entry',
  canceled: 'canceled',
};

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  pending: 'warning',
  verify_holding: 'warning',
  pending_issuer_review: 'warning',
  pending_rofr: 'warning',
  signature_in_progress: 'warning',
  ready_to_settle: 'info',
  settled: 'success',
  funded: 'success',
  frozen: 'error',
  wired: 'success',
  rofr_blocked: 'error',
  rofr_assigned: 'info',
  rofr_waive: 'success',
  designated: 'info',
  seller_designates: 'info',
  buyer_designates: 'info',
  multiple: 'info',
  not_applicable: 'disabled',
  holding_entry: 'warning',
  canceled: 'error',
};

const TooltipMap: Record<Keys, string | undefined> = {
  pending:
    'This transfer requires signatures on the Stock Transfer Notice (STN) by the buyer and seller',
  verify_holding:
    'This transfer is pending full holding details or verification. Sellers should provide complete holding details before verification by the company.',
  pending_issuer_review:
    'This transfer is under review by the company. You can reference the ROFR Period to estimate a transfer decision date.',
  pending_rofr:
    'This transfer is pending a decision from the company. Click Manage Transfer to indicate a transfer decision.',
  signature_in_progress:
    'Agreement signing for this transfer is in progress. All documents requiring your signature are available under the Agreements tab.',
  ready_to_settle:
    'NPM will contact the buyer directly with funding instructions.',
  settled: 'This transfer is pending settlement.',
  funded: 'NPM has received the buyer’s funds for settlement.',
  frozen: undefined,
  wired:
    'This transaction has closed. Reference the Agreements tab for applicable transfer documents.',
  rofr_blocked: 'This transfer has been blocked by the company.',
  rofr_assigned:
    'The company has assigned a new buyer to part or all of this transfer.',
  rofr_waive: 'This transfer has been approved by the company.',
  holding_entry: 'Seller adds all holding(s) details to the platform.',
  designated: undefined,
  seller_designates: undefined,
  buyer_designates: undefined,
  multiple: undefined,
  not_applicable: undefined,
  canceled: 'This proposed transaction has been canceled.',
};

type GetLabelOptions = {
  dashed?: boolean;
  useWarningIcon?: boolean;
};

export const CbMatchActivityState = {
  code: Codebooks.MATCH_ACTIVITY_STATE,
  items: Items,
  labels: LabelMap,
  getLabel: (
    Component: CbLabelComponent,
    codebookItem: CodebookItem | undefined,
    options?: GetLabelOptions
  ) => {
    const { dashed, useWarningIcon } = options ?? {};

    return Component({
      variant: LabelMap[codebookItem?.code as Keys],
      label: codebookItem?.name,
      dashed,
      iconProps: useWarningIcon ? { name: 'clock' } : undefined,
    });
  },
  getTooltip: (codebookItem: CodebookItem) =>
    TooltipMap[codebookItem?.code as Keys],
};
