import React from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type AccountBasic,
  type AccountForBrokerage,
  type Subject,
} from '@npm/data-access';
import { type LabeledValue } from 'antd/es/select';

import { UserOptionContainer } from './styles';

export const renderAccountOption = (
  option: LabeledValue & {
    account: AccountForBrokerage | Subject | AccountBasic | null;
  }
) => (
  <Select.Option key={option.value} value={option.value} label={option.label}>
    {option.account ? (
      <UserOptionContainer data-dd-privacy="mask">
        <Text size="sm" colorVariant="primary" marginBottom={4}>
          {option.account.name}
        </Text>
        <Text size="xs" colorVariant="secondary">
          {(option.account as AccountForBrokerage).brokerage_firm?.name ||
            (option.account as Subject).brokerage_firm_name}{' '}
          - {option.account.external_id}
        </Text>
      </UserOptionContainer>
    ) : (
      option.label
    )}
  </Select.Option>
);
