// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompaniesApi,
  CompaniesIndexErrorCodes,
  CompaniesIndexErrorTypes,
  CompanyProfileErrorCodes,
  CompanyProfileErrorTypes,
  CompaniesShowErrorCodes,
  CompaniesShowErrorTypes,
  CompaniesFilingDatesErrorCodes,
  CompaniesFilingDatesErrorTypes,
  CompaniesLatestStockClassDetailsErrorCodes,
  CompaniesLatestStockClassDetailsErrorTypes,
  CompaniesRequestDataErrorCodes,
  CompanyWaterfallDataErrorCodes,
  CompanyWaterfallDataErrorTypes,
  CompanyWaterfallLinkErrorCodes,
  CompanyWaterfallLinkErrorTypes,
  CompanyFinancialActivityErrorCodes,
  CompanyFinancialActivityErrorTypes,
  CompanyImpliedValuesErrorCodes,
  CompanyImpliedValuesErrorTypes,
  CompanyCapTableMetadataErrorCodes,
  CompanyCapTableMetadataErrorTypes,
  CompaniesLlmProcessedCoisErrorCodes,
  CompaniesLlmProcessedCoisErrorTypes,
  InternalCompaniesIndexErrorCodes,
  InternalCompaniesIndexErrorTypes,
  InternalCompaniesCreateErrorCodes,
  InternalCompaniesShowErrorCodes,
  InternalCompaniesShowErrorTypes,
  InternalUpdateCompanyErrorCodes,
  InternalDeleteCompanyErrorCodes,
  InternalCompaniesResetErrorCodes,
  InternalCompanyPreDeleteErrorCodes,
  InternalCompanyPreDeleteErrorTypes,
  InternalCompanyApplyStockSplitErrorCodes,
  InternalBulkUploadCompaniesErrorCodes,
  InternalUploadSacraDataErrorCodes,
  InternalMatchVenusIdsErrorCodes,
  InternalUploadCOIDataErrorCodes,
  InternalRetrieveEdgarFilingsErrorCodes,
  InternalInvestmentPreDeleteErrorCodes,
  InternalInvestmentPreDeleteErrorTypes,
  MotifCompaniesErrorCodes,
  MotifCompaniesErrorTypes,
  PublicCompaniesShowErrorCodes,
  PublicCompaniesShowErrorTypes,
  SecondMarketCompanyShowErrorCodes,
  SecondMarketCompanyShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompaniesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompaniesIndexMethod = CompaniesApi['companiesIndex'];
export type CompaniesIndexResponseType = MethodResult<CompaniesIndexMethod>;

export const useCompaniesIndex = (
  variables: Parameters<CompaniesIndexMethod>[0],
  config?: UseQueryConfig<
    CompaniesIndexMethod,
    CompaniesIndexErrorTypes
  >
) => {
  return useQuery<
    CompaniesIndexMethod,
    CompaniesIndexErrorTypes
  >(
    (...args) => api.companiesIndex(...args),
    CompaniesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesIndex'],
        ...apiQueryConfig.CompaniesIndex,
        ...config?.queryConfig
      },
    },
    'CompaniesIndex',
  );
};

export const useCompaniesIndexInfinite = (
  variables: Parameters<CompaniesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesIndexMethod,
    CompaniesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesIndexMethod,
    CompaniesIndexErrorTypes
  >(
    (...args) => api.companiesIndex(...args),
    CompaniesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesIndex', 'CompaniesIndexInfinite'],
        ...apiQueryConfig.CompaniesIndex,
        ...config?.queryConfig,
      },
    },
    'CompaniesIndex',
  );
};

export const useCompaniesIndexLazy = (baseOptions?: {
  variables?: Parameters<CompaniesIndexMethod>[0],
  config?: UseQueryConfig<
    CompaniesIndexMethod,
    CompaniesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesIndexMethod,
    CompaniesIndexErrorTypes
  >(
    (...args) => api.companiesIndex(...args),
    CompaniesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesIndex'],
        ...apiQueryConfig.CompaniesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesIndex',
  );
};


export type CompanyProfileMethod = CompaniesApi['companyProfile'];
export type CompanyProfileResponseType = MethodResult<CompanyProfileMethod>;

export const useCompanyProfile = (
  variables: Parameters<CompanyProfileMethod>[0],
  config?: UseQueryConfig<
    CompanyProfileMethod,
    CompanyProfileErrorTypes
  >
) => {
  return useQuery<
    CompanyProfileMethod,
    CompanyProfileErrorTypes
  >(
    (...args) => api.companyProfile(...args),
    CompanyProfileErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyProfile'],
        ...apiQueryConfig.CompanyProfile,
        ...config?.queryConfig
      },
    },
    'CompanyProfile',
  );
};

export const useCompanyProfileInfinite = (
  variables: Parameters<CompanyProfileMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyProfileMethod,
    CompanyProfileErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyProfileMethod,
    CompanyProfileErrorTypes
  >(
    (...args) => api.companyProfile(...args),
    CompanyProfileErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyProfile', 'CompanyProfileInfinite'],
        ...apiQueryConfig.CompanyProfile,
        ...config?.queryConfig,
      },
    },
    'CompanyProfile',
  );
};

export const useCompanyProfileLazy = (baseOptions?: {
  variables?: Parameters<CompanyProfileMethod>[0],
  config?: UseQueryConfig<
    CompanyProfileMethod,
    CompanyProfileErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyProfileMethod,
    CompanyProfileErrorTypes
  >(
    (...args) => api.companyProfile(...args),
    CompanyProfileErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyProfile'],
        ...apiQueryConfig.CompanyProfile,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyProfile',
  );
};


export type CompaniesShowMethod = CompaniesApi['companiesShow'];
export type CompaniesShowResponseType = MethodResult<CompaniesShowMethod>;

export const useCompaniesShow = (
  variables: Parameters<CompaniesShowMethod>[0],
  config?: UseQueryConfig<
    CompaniesShowMethod,
    CompaniesShowErrorTypes
  >
) => {
  return useQuery<
    CompaniesShowMethod,
    CompaniesShowErrorTypes
  >(
    (...args) => api.companiesShow(...args),
    CompaniesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesShow'],
        ...apiQueryConfig.CompaniesShow,
        ...config?.queryConfig
      },
    },
    'CompaniesShow',
  );
};

export const useCompaniesShowInfinite = (
  variables: Parameters<CompaniesShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesShowMethod,
    CompaniesShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesShowMethod,
    CompaniesShowErrorTypes
  >(
    (...args) => api.companiesShow(...args),
    CompaniesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesShow', 'CompaniesShowInfinite'],
        ...apiQueryConfig.CompaniesShow,
        ...config?.queryConfig,
      },
    },
    'CompaniesShow',
  );
};

export const useCompaniesShowLazy = (baseOptions?: {
  variables?: Parameters<CompaniesShowMethod>[0],
  config?: UseQueryConfig<
    CompaniesShowMethod,
    CompaniesShowErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesShowMethod,
    CompaniesShowErrorTypes
  >(
    (...args) => api.companiesShow(...args),
    CompaniesShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesShow'],
        ...apiQueryConfig.CompaniesShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesShow',
  );
};


export type CompaniesFilingDatesMethod = CompaniesApi['companiesFilingDates'];
export type CompaniesFilingDatesResponseType = MethodResult<CompaniesFilingDatesMethod>;

export const useCompaniesFilingDates = (
  variables: Parameters<CompaniesFilingDatesMethod>[0],
  config?: UseQueryConfig<
    CompaniesFilingDatesMethod,
    CompaniesFilingDatesErrorTypes
  >
) => {
  return useQuery<
    CompaniesFilingDatesMethod,
    CompaniesFilingDatesErrorTypes
  >(
    (...args) => api.companiesFilingDates(...args),
    CompaniesFilingDatesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesFilingDates'],
        ...apiQueryConfig.CompaniesFilingDates,
        ...config?.queryConfig
      },
    },
    'CompaniesFilingDates',
  );
};

export const useCompaniesFilingDatesInfinite = (
  variables: Parameters<CompaniesFilingDatesMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesFilingDatesMethod,
    CompaniesFilingDatesErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesFilingDatesMethod,
    CompaniesFilingDatesErrorTypes
  >(
    (...args) => api.companiesFilingDates(...args),
    CompaniesFilingDatesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesFilingDates', 'CompaniesFilingDatesInfinite'],
        ...apiQueryConfig.CompaniesFilingDates,
        ...config?.queryConfig,
      },
    },
    'CompaniesFilingDates',
  );
};

export const useCompaniesFilingDatesLazy = (baseOptions?: {
  variables?: Parameters<CompaniesFilingDatesMethod>[0],
  config?: UseQueryConfig<
    CompaniesFilingDatesMethod,
    CompaniesFilingDatesErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesFilingDatesMethod,
    CompaniesFilingDatesErrorTypes
  >(
    (...args) => api.companiesFilingDates(...args),
    CompaniesFilingDatesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesFilingDates'],
        ...apiQueryConfig.CompaniesFilingDates,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesFilingDates',
  );
};


export type CompaniesLatestStockClassDetailsMethod = CompaniesApi['companiesLatestStockClassDetails'];
export type CompaniesLatestStockClassDetailsResponseType = MethodResult<CompaniesLatestStockClassDetailsMethod>;

export const useCompaniesLatestStockClassDetails = (
  variables: Parameters<CompaniesLatestStockClassDetailsMethod>[0],
  config?: UseQueryConfig<
    CompaniesLatestStockClassDetailsMethod,
    CompaniesLatestStockClassDetailsErrorTypes
  >
) => {
  return useQuery<
    CompaniesLatestStockClassDetailsMethod,
    CompaniesLatestStockClassDetailsErrorTypes
  >(
    (...args) => api.companiesLatestStockClassDetails(...args),
    CompaniesLatestStockClassDetailsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesLatestStockClassDetails'],
        ...apiQueryConfig.CompaniesLatestStockClassDetails,
        ...config?.queryConfig
      },
    },
    'CompaniesLatestStockClassDetails',
  );
};

export const useCompaniesLatestStockClassDetailsInfinite = (
  variables: Parameters<CompaniesLatestStockClassDetailsMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesLatestStockClassDetailsMethod,
    CompaniesLatestStockClassDetailsErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesLatestStockClassDetailsMethod,
    CompaniesLatestStockClassDetailsErrorTypes
  >(
    (...args) => api.companiesLatestStockClassDetails(...args),
    CompaniesLatestStockClassDetailsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesLatestStockClassDetails', 'CompaniesLatestStockClassDetailsInfinite'],
        ...apiQueryConfig.CompaniesLatestStockClassDetails,
        ...config?.queryConfig,
      },
    },
    'CompaniesLatestStockClassDetails',
  );
};

export const useCompaniesLatestStockClassDetailsLazy = (baseOptions?: {
  variables?: Parameters<CompaniesLatestStockClassDetailsMethod>[0],
  config?: UseQueryConfig<
    CompaniesLatestStockClassDetailsMethod,
    CompaniesLatestStockClassDetailsErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesLatestStockClassDetailsMethod,
    CompaniesLatestStockClassDetailsErrorTypes
  >(
    (...args) => api.companiesLatestStockClassDetails(...args),
    CompaniesLatestStockClassDetailsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesLatestStockClassDetails'],
        ...apiQueryConfig.CompaniesLatestStockClassDetails,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesLatestStockClassDetails',
  );
};


export type CompaniesRequestDataMethod = CompaniesApi['companiesRequestData'];

export const useCompaniesRequestData = () => {
  return useMutation<CompaniesRequestDataMethod>(
    (...args) => api.companiesRequestData(...args),
    CompaniesRequestDataErrorCodes,
    'CompaniesRequestData',
    '/api/companies/{id}/request_data',
    'post'
  );
};

export type CompanyWaterfallDataMethod = CompaniesApi['companyWaterfallData'];
export type CompanyWaterfallDataResponseType = MethodResult<CompanyWaterfallDataMethod>;

export const useCompanyWaterfallData = (
  variables: Parameters<CompanyWaterfallDataMethod>[0],
  config?: UseQueryConfig<
    CompanyWaterfallDataMethod,
    CompanyWaterfallDataErrorTypes
  >
) => {
  return useQuery<
    CompanyWaterfallDataMethod,
    CompanyWaterfallDataErrorTypes
  >(
    (...args) => api.companyWaterfallData(...args),
    CompanyWaterfallDataErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyWaterfallData'],
        ...apiQueryConfig.CompanyWaterfallData,
        ...config?.queryConfig
      },
    },
    'CompanyWaterfallData',
  );
};

export const useCompanyWaterfallDataInfinite = (
  variables: Parameters<CompanyWaterfallDataMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyWaterfallDataMethod,
    CompanyWaterfallDataErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyWaterfallDataMethod,
    CompanyWaterfallDataErrorTypes
  >(
    (...args) => api.companyWaterfallData(...args),
    CompanyWaterfallDataErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyWaterfallData', 'CompanyWaterfallDataInfinite'],
        ...apiQueryConfig.CompanyWaterfallData,
        ...config?.queryConfig,
      },
    },
    'CompanyWaterfallData',
  );
};

export const useCompanyWaterfallDataLazy = (baseOptions?: {
  variables?: Parameters<CompanyWaterfallDataMethod>[0],
  config?: UseQueryConfig<
    CompanyWaterfallDataMethod,
    CompanyWaterfallDataErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyWaterfallDataMethod,
    CompanyWaterfallDataErrorTypes
  >(
    (...args) => api.companyWaterfallData(...args),
    CompanyWaterfallDataErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyWaterfallData'],
        ...apiQueryConfig.CompanyWaterfallData,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyWaterfallData',
  );
};


export type CompanyWaterfallLinkMethod = CompaniesApi['companyWaterfallLink'];
export type CompanyWaterfallLinkResponseType = MethodResult<CompanyWaterfallLinkMethod>;

export const useCompanyWaterfallLink = (
  variables: Parameters<CompanyWaterfallLinkMethod>[0],
  config?: UseQueryConfig<
    CompanyWaterfallLinkMethod,
    CompanyWaterfallLinkErrorTypes
  >
) => {
  return useQuery<
    CompanyWaterfallLinkMethod,
    CompanyWaterfallLinkErrorTypes
  >(
    (...args) => api.companyWaterfallLink(...args),
    CompanyWaterfallLinkErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyWaterfallLink'],
        ...apiQueryConfig.CompanyWaterfallLink,
        ...config?.queryConfig
      },
    },
    'CompanyWaterfallLink',
  );
};

export const useCompanyWaterfallLinkInfinite = (
  variables: Parameters<CompanyWaterfallLinkMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyWaterfallLinkMethod,
    CompanyWaterfallLinkErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyWaterfallLinkMethod,
    CompanyWaterfallLinkErrorTypes
  >(
    (...args) => api.companyWaterfallLink(...args),
    CompanyWaterfallLinkErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyWaterfallLink', 'CompanyWaterfallLinkInfinite'],
        ...apiQueryConfig.CompanyWaterfallLink,
        ...config?.queryConfig,
      },
    },
    'CompanyWaterfallLink',
  );
};

export const useCompanyWaterfallLinkLazy = (baseOptions?: {
  variables?: Parameters<CompanyWaterfallLinkMethod>[0],
  config?: UseQueryConfig<
    CompanyWaterfallLinkMethod,
    CompanyWaterfallLinkErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyWaterfallLinkMethod,
    CompanyWaterfallLinkErrorTypes
  >(
    (...args) => api.companyWaterfallLink(...args),
    CompanyWaterfallLinkErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyWaterfallLink'],
        ...apiQueryConfig.CompanyWaterfallLink,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyWaterfallLink',
  );
};


export type CompanyFinancialActivityMethod = CompaniesApi['companyFinancialActivity'];
export type CompanyFinancialActivityResponseType = MethodResult<CompanyFinancialActivityMethod>;

export const useCompanyFinancialActivity = (
  variables: Parameters<CompanyFinancialActivityMethod>[0],
  config?: UseQueryConfig<
    CompanyFinancialActivityMethod,
    CompanyFinancialActivityErrorTypes
  >
) => {
  return useQuery<
    CompanyFinancialActivityMethod,
    CompanyFinancialActivityErrorTypes
  >(
    (...args) => api.companyFinancialActivity(...args),
    CompanyFinancialActivityErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyFinancialActivity'],
        ...apiQueryConfig.CompanyFinancialActivity,
        ...config?.queryConfig
      },
    },
    'CompanyFinancialActivity',
  );
};

export const useCompanyFinancialActivityInfinite = (
  variables: Parameters<CompanyFinancialActivityMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyFinancialActivityMethod,
    CompanyFinancialActivityErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyFinancialActivityMethod,
    CompanyFinancialActivityErrorTypes
  >(
    (...args) => api.companyFinancialActivity(...args),
    CompanyFinancialActivityErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyFinancialActivity', 'CompanyFinancialActivityInfinite'],
        ...apiQueryConfig.CompanyFinancialActivity,
        ...config?.queryConfig,
      },
    },
    'CompanyFinancialActivity',
  );
};

export const useCompanyFinancialActivityLazy = (baseOptions?: {
  variables?: Parameters<CompanyFinancialActivityMethod>[0],
  config?: UseQueryConfig<
    CompanyFinancialActivityMethod,
    CompanyFinancialActivityErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyFinancialActivityMethod,
    CompanyFinancialActivityErrorTypes
  >(
    (...args) => api.companyFinancialActivity(...args),
    CompanyFinancialActivityErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyFinancialActivity'],
        ...apiQueryConfig.CompanyFinancialActivity,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyFinancialActivity',
  );
};


export type CompanyImpliedValuesMethod = CompaniesApi['companyImpliedValues'];
export type CompanyImpliedValuesResponseType = MethodResult<CompanyImpliedValuesMethod>;

export const useCompanyImpliedValues = (
  variables: Parameters<CompanyImpliedValuesMethod>[0],
  config?: UseQueryConfig<
    CompanyImpliedValuesMethod,
    CompanyImpliedValuesErrorTypes
  >
) => {
  return useQuery<
    CompanyImpliedValuesMethod,
    CompanyImpliedValuesErrorTypes
  >(
    (...args) => api.companyImpliedValues(...args),
    CompanyImpliedValuesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyImpliedValues'],
        ...apiQueryConfig.CompanyImpliedValues,
        ...config?.queryConfig
      },
    },
    'CompanyImpliedValues',
  );
};

export const useCompanyImpliedValuesInfinite = (
  variables: Parameters<CompanyImpliedValuesMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyImpliedValuesMethod,
    CompanyImpliedValuesErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyImpliedValuesMethod,
    CompanyImpliedValuesErrorTypes
  >(
    (...args) => api.companyImpliedValues(...args),
    CompanyImpliedValuesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyImpliedValues', 'CompanyImpliedValuesInfinite'],
        ...apiQueryConfig.CompanyImpliedValues,
        ...config?.queryConfig,
      },
    },
    'CompanyImpliedValues',
  );
};

export const useCompanyImpliedValuesLazy = (baseOptions?: {
  variables?: Parameters<CompanyImpliedValuesMethod>[0],
  config?: UseQueryConfig<
    CompanyImpliedValuesMethod,
    CompanyImpliedValuesErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyImpliedValuesMethod,
    CompanyImpliedValuesErrorTypes
  >(
    (...args) => api.companyImpliedValues(...args),
    CompanyImpliedValuesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyImpliedValues'],
        ...apiQueryConfig.CompanyImpliedValues,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyImpliedValues',
  );
};


export type CompanyCapTableMetadataMethod = CompaniesApi['companyCapTableMetadata'];
export type CompanyCapTableMetadataResponseType = MethodResult<CompanyCapTableMetadataMethod>;

export const useCompanyCapTableMetadata = (
  variables: Parameters<CompanyCapTableMetadataMethod>[0],
  config?: UseQueryConfig<
    CompanyCapTableMetadataMethod,
    CompanyCapTableMetadataErrorTypes
  >
) => {
  return useQuery<
    CompanyCapTableMetadataMethod,
    CompanyCapTableMetadataErrorTypes
  >(
    (...args) => api.companyCapTableMetadata(...args),
    CompanyCapTableMetadataErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyCapTableMetadata'],
        ...apiQueryConfig.CompanyCapTableMetadata,
        ...config?.queryConfig
      },
    },
    'CompanyCapTableMetadata',
  );
};

export const useCompanyCapTableMetadataInfinite = (
  variables: Parameters<CompanyCapTableMetadataMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyCapTableMetadataMethod,
    CompanyCapTableMetadataErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyCapTableMetadataMethod,
    CompanyCapTableMetadataErrorTypes
  >(
    (...args) => api.companyCapTableMetadata(...args),
    CompanyCapTableMetadataErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyCapTableMetadata', 'CompanyCapTableMetadataInfinite'],
        ...apiQueryConfig.CompanyCapTableMetadata,
        ...config?.queryConfig,
      },
    },
    'CompanyCapTableMetadata',
  );
};

export const useCompanyCapTableMetadataLazy = (baseOptions?: {
  variables?: Parameters<CompanyCapTableMetadataMethod>[0],
  config?: UseQueryConfig<
    CompanyCapTableMetadataMethod,
    CompanyCapTableMetadataErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyCapTableMetadataMethod,
    CompanyCapTableMetadataErrorTypes
  >(
    (...args) => api.companyCapTableMetadata(...args),
    CompanyCapTableMetadataErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyCapTableMetadata'],
        ...apiQueryConfig.CompanyCapTableMetadata,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyCapTableMetadata',
  );
};


export type CompaniesLlmProcessedCoisMethod = CompaniesApi['companiesLlmProcessedCois'];
export type CompaniesLlmProcessedCoisResponseType = MethodResult<CompaniesLlmProcessedCoisMethod>;

export const useCompaniesLlmProcessedCois = (
  variables: Parameters<CompaniesLlmProcessedCoisMethod>[0],
  config?: UseQueryConfig<
    CompaniesLlmProcessedCoisMethod,
    CompaniesLlmProcessedCoisErrorTypes
  >
) => {
  return useQuery<
    CompaniesLlmProcessedCoisMethod,
    CompaniesLlmProcessedCoisErrorTypes
  >(
    (...args) => api.companiesLlmProcessedCois(...args),
    CompaniesLlmProcessedCoisErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesLlmProcessedCois'],
        ...apiQueryConfig.CompaniesLlmProcessedCois,
        ...config?.queryConfig
      },
    },
    'CompaniesLlmProcessedCois',
  );
};

export const useCompaniesLlmProcessedCoisInfinite = (
  variables: Parameters<CompaniesLlmProcessedCoisMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesLlmProcessedCoisMethod,
    CompaniesLlmProcessedCoisErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesLlmProcessedCoisMethod,
    CompaniesLlmProcessedCoisErrorTypes
  >(
    (...args) => api.companiesLlmProcessedCois(...args),
    CompaniesLlmProcessedCoisErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesLlmProcessedCois', 'CompaniesLlmProcessedCoisInfinite'],
        ...apiQueryConfig.CompaniesLlmProcessedCois,
        ...config?.queryConfig,
      },
    },
    'CompaniesLlmProcessedCois',
  );
};

export const useCompaniesLlmProcessedCoisLazy = (baseOptions?: {
  variables?: Parameters<CompaniesLlmProcessedCoisMethod>[0],
  config?: UseQueryConfig<
    CompaniesLlmProcessedCoisMethod,
    CompaniesLlmProcessedCoisErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesLlmProcessedCoisMethod,
    CompaniesLlmProcessedCoisErrorTypes
  >(
    (...args) => api.companiesLlmProcessedCois(...args),
    CompaniesLlmProcessedCoisErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesLlmProcessedCois'],
        ...apiQueryConfig.CompaniesLlmProcessedCois,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesLlmProcessedCois',
  );
};


export type InternalCompaniesIndexMethod = CompaniesApi['internalCompaniesIndex'];
export type InternalCompaniesIndexResponseType = MethodResult<InternalCompaniesIndexMethod>;

export const useInternalCompaniesIndex = (
  variables: Parameters<InternalCompaniesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompaniesIndexMethod,
    InternalCompaniesIndexErrorTypes
  >
) => {
  return useQuery<
    InternalCompaniesIndexMethod,
    InternalCompaniesIndexErrorTypes
  >(
    (...args) => api.internalCompaniesIndex(...args),
    InternalCompaniesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompaniesIndex'],
        ...apiQueryConfig.InternalCompaniesIndex,
        ...config?.queryConfig
      },
    },
    'InternalCompaniesIndex',
  );
};

export const useInternalCompaniesIndexInfinite = (
  variables: Parameters<InternalCompaniesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalCompaniesIndexMethod,
    InternalCompaniesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalCompaniesIndexMethod,
    InternalCompaniesIndexErrorTypes
  >(
    (...args) => api.internalCompaniesIndex(...args),
    InternalCompaniesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompaniesIndex', 'InternalCompaniesIndexInfinite'],
        ...apiQueryConfig.InternalCompaniesIndex,
        ...config?.queryConfig,
      },
    },
    'InternalCompaniesIndex',
  );
};

export const useInternalCompaniesIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalCompaniesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalCompaniesIndexMethod,
    InternalCompaniesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalCompaniesIndexMethod,
    InternalCompaniesIndexErrorTypes
  >(
    (...args) => api.internalCompaniesIndex(...args),
    InternalCompaniesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalCompaniesIndex'],
        ...apiQueryConfig.InternalCompaniesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalCompaniesIndex',
  );
};


export type InternalCompaniesCreateMethod = CompaniesApi['internalCompaniesCreate'];

export const useInternalCompaniesCreate = () => {
  return useMutation<InternalCompaniesCreateMethod>(
    (...args) => api.internalCompaniesCreate(...args),
    InternalCompaniesCreateErrorCodes,
    'InternalCompaniesCreate',
    '/api/internal/companies',
    'post'
  );
};

export type InternalCompaniesShowMethod = CompaniesApi['internalCompaniesShow'];
export type InternalCompaniesShowResponseType = MethodResult<InternalCompaniesShowMethod>;

export const useInternalCompaniesShow = (
  variables: Parameters<InternalCompaniesShowMethod>[0],
  config?: UseQueryConfig<
    InternalCompaniesShowMethod,
    InternalCompaniesShowErrorTypes
  >
) => {
  return useQuery<
    InternalCompaniesShowMethod,
    InternalCompaniesShowErrorTypes
  >(
    (...args) => api.internalCompaniesShow(...args),
    InternalCompaniesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompaniesShow'],
        ...apiQueryConfig.InternalCompaniesShow,
        ...config?.queryConfig
      },
    },
    'InternalCompaniesShow',
  );
};

export const useInternalCompaniesShowInfinite = (
  variables: Parameters<InternalCompaniesShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalCompaniesShowMethod,
    InternalCompaniesShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalCompaniesShowMethod,
    InternalCompaniesShowErrorTypes
  >(
    (...args) => api.internalCompaniesShow(...args),
    InternalCompaniesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompaniesShow', 'InternalCompaniesShowInfinite'],
        ...apiQueryConfig.InternalCompaniesShow,
        ...config?.queryConfig,
      },
    },
    'InternalCompaniesShow',
  );
};

export const useInternalCompaniesShowLazy = (baseOptions?: {
  variables?: Parameters<InternalCompaniesShowMethod>[0],
  config?: UseQueryConfig<
    InternalCompaniesShowMethod,
    InternalCompaniesShowErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalCompaniesShowMethod,
    InternalCompaniesShowErrorTypes
  >(
    (...args) => api.internalCompaniesShow(...args),
    InternalCompaniesShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalCompaniesShow'],
        ...apiQueryConfig.InternalCompaniesShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalCompaniesShow',
  );
};


export type InternalUpdateCompanyMethod = CompaniesApi['internalUpdateCompany'];

export const useInternalUpdateCompany = () => {
  return useMutation<InternalUpdateCompanyMethod>(
    (...args) => api.internalUpdateCompany(...args),
    InternalUpdateCompanyErrorCodes,
    'InternalUpdateCompany',
    '/api/internal/companies/{id}',
    'put'
  );
};

export type InternalDeleteCompanyMethod = CompaniesApi['internalDeleteCompany'];

export const useInternalDeleteCompany = () => {
  return useMutation<InternalDeleteCompanyMethod>(
    (...args) => api.internalDeleteCompany(...args),
    InternalDeleteCompanyErrorCodes,
    'InternalDeleteCompany',
    '/api/internal/companies/{id}',
    'delete'
  );
};

export type InternalCompaniesResetMethod = CompaniesApi['internalCompaniesReset'];

export const useInternalCompaniesReset = () => {
  return useMutation<InternalCompaniesResetMethod>(
    (...args) => api.internalCompaniesReset(...args),
    InternalCompaniesResetErrorCodes,
    'InternalCompaniesReset',
    '/api/internal/companies/{id}/reset',
    'post'
  );
};

export type InternalCompanyPreDeleteMethod = CompaniesApi['internalCompanyPreDelete'];
export type InternalCompanyPreDeleteResponseType = MethodResult<InternalCompanyPreDeleteMethod>;

export const useInternalCompanyPreDelete = (
  variables: Parameters<InternalCompanyPreDeleteMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyPreDeleteMethod,
    InternalCompanyPreDeleteErrorTypes
  >
) => {
  return useQuery<
    InternalCompanyPreDeleteMethod,
    InternalCompanyPreDeleteErrorTypes
  >(
    (...args) => api.internalCompanyPreDelete(...args),
    InternalCompanyPreDeleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyPreDelete'],
        ...apiQueryConfig.InternalCompanyPreDelete,
        ...config?.queryConfig
      },
    },
    'InternalCompanyPreDelete',
  );
};

export const useInternalCompanyPreDeleteInfinite = (
  variables: Parameters<InternalCompanyPreDeleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalCompanyPreDeleteMethod,
    InternalCompanyPreDeleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalCompanyPreDeleteMethod,
    InternalCompanyPreDeleteErrorTypes
  >(
    (...args) => api.internalCompanyPreDelete(...args),
    InternalCompanyPreDeleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalCompanyPreDelete', 'InternalCompanyPreDeleteInfinite'],
        ...apiQueryConfig.InternalCompanyPreDelete,
        ...config?.queryConfig,
      },
    },
    'InternalCompanyPreDelete',
  );
};

export const useInternalCompanyPreDeleteLazy = (baseOptions?: {
  variables?: Parameters<InternalCompanyPreDeleteMethod>[0],
  config?: UseQueryConfig<
    InternalCompanyPreDeleteMethod,
    InternalCompanyPreDeleteErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalCompanyPreDeleteMethod,
    InternalCompanyPreDeleteErrorTypes
  >(
    (...args) => api.internalCompanyPreDelete(...args),
    InternalCompanyPreDeleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalCompanyPreDelete'],
        ...apiQueryConfig.InternalCompanyPreDelete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalCompanyPreDelete',
  );
};


export type InternalCompanyApplyStockSplitMethod = CompaniesApi['internalCompanyApplyStockSplit'];

export const useInternalCompanyApplyStockSplit = () => {
  return useMutation<InternalCompanyApplyStockSplitMethod>(
    (...args) => api.internalCompanyApplyStockSplit(...args),
    InternalCompanyApplyStockSplitErrorCodes,
    'InternalCompanyApplyStockSplit',
    '/api/internal/companies/{id}/apply_stock_split',
    'post'
  );
};

export type InternalBulkUploadCompaniesMethod = CompaniesApi['internalBulkUploadCompanies'];

export const useInternalBulkUploadCompanies = () => {
  return useMutation<InternalBulkUploadCompaniesMethod>(
    (...args) => api.internalBulkUploadCompanies(...args),
    InternalBulkUploadCompaniesErrorCodes,
    'InternalBulkUploadCompanies',
    '/api/internal/companies/bulk_upload',
    'post'
  );
};

export type InternalUploadSacraDataMethod = CompaniesApi['internalUploadSacraData'];

export const useInternalUploadSacraData = () => {
  return useMutation<InternalUploadSacraDataMethod>(
    (...args) => api.internalUploadSacraData(...args),
    InternalUploadSacraDataErrorCodes,
    'InternalUploadSacraData',
    '/api/internal/companies/upload_sacra_data',
    'post'
  );
};

export type InternalMatchVenusIdsMethod = CompaniesApi['internalMatchVenusIds'];

export const useInternalMatchVenusIds = () => {
  return useMutation<InternalMatchVenusIdsMethod>(
    (...args) => api.internalMatchVenusIds(...args),
    InternalMatchVenusIdsErrorCodes,
    'InternalMatchVenusIds',
    '/api/internal/companies/match_venus_ids',
    'post'
  );
};

export type InternalUploadCOIDataMethod = CompaniesApi['internalUploadCOIData'];

export const useInternalUploadCOIData = () => {
  return useMutation<InternalUploadCOIDataMethod>(
    (...args) => api.internalUploadCOIData(...args),
    InternalUploadCOIDataErrorCodes,
    'InternalUploadCOIData',
    '/api/internal/companies/upload_coi_data',
    'post'
  );
};

export type InternalRetrieveEdgarFilingsMethod = CompaniesApi['internalRetrieveEdgarFilings'];

export const useInternalRetrieveEdgarFilings = () => {
  return useMutation<InternalRetrieveEdgarFilingsMethod>(
    (...args) => api.internalRetrieveEdgarFilings(...args),
    InternalRetrieveEdgarFilingsErrorCodes,
    'InternalRetrieveEdgarFilings',
    '/api/internal/companies/{id}/retrieve_edgar_filings',
    'post'
  );
};

export type InternalInvestmentPreDeleteMethod = CompaniesApi['internalInvestmentPreDelete'];
export type InternalInvestmentPreDeleteResponseType = MethodResult<InternalInvestmentPreDeleteMethod>;

export const useInternalInvestmentPreDelete = (
  variables: Parameters<InternalInvestmentPreDeleteMethod>[0],
  config?: UseQueryConfig<
    InternalInvestmentPreDeleteMethod,
    InternalInvestmentPreDeleteErrorTypes
  >
) => {
  return useQuery<
    InternalInvestmentPreDeleteMethod,
    InternalInvestmentPreDeleteErrorTypes
  >(
    (...args) => api.internalInvestmentPreDelete(...args),
    InternalInvestmentPreDeleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestmentPreDelete'],
        ...apiQueryConfig.InternalInvestmentPreDelete,
        ...config?.queryConfig
      },
    },
    'InternalInvestmentPreDelete',
  );
};

export const useInternalInvestmentPreDeleteInfinite = (
  variables: Parameters<InternalInvestmentPreDeleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalInvestmentPreDeleteMethod,
    InternalInvestmentPreDeleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalInvestmentPreDeleteMethod,
    InternalInvestmentPreDeleteErrorTypes
  >(
    (...args) => api.internalInvestmentPreDelete(...args),
    InternalInvestmentPreDeleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestmentPreDelete', 'InternalInvestmentPreDeleteInfinite'],
        ...apiQueryConfig.InternalInvestmentPreDelete,
        ...config?.queryConfig,
      },
    },
    'InternalInvestmentPreDelete',
  );
};

export const useInternalInvestmentPreDeleteLazy = (baseOptions?: {
  variables?: Parameters<InternalInvestmentPreDeleteMethod>[0],
  config?: UseQueryConfig<
    InternalInvestmentPreDeleteMethod,
    InternalInvestmentPreDeleteErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalInvestmentPreDeleteMethod,
    InternalInvestmentPreDeleteErrorTypes
  >(
    (...args) => api.internalInvestmentPreDelete(...args),
    InternalInvestmentPreDeleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalInvestmentPreDelete'],
        ...apiQueryConfig.InternalInvestmentPreDelete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalInvestmentPreDelete',
  );
};


export type MotifCompaniesMethod = CompaniesApi['motifCompanies'];
export type MotifCompaniesResponseType = MethodResult<MotifCompaniesMethod>;

export const useMotifCompanies = (
  variables: Parameters<MotifCompaniesMethod>[0],
  config?: UseQueryConfig<
    MotifCompaniesMethod,
    MotifCompaniesErrorTypes
  >
) => {
  return useQuery<
    MotifCompaniesMethod,
    MotifCompaniesErrorTypes
  >(
    (...args) => api.motifCompanies(...args),
    MotifCompaniesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MotifCompanies'],
        ...apiQueryConfig.MotifCompanies,
        ...config?.queryConfig
      },
    },
    'MotifCompanies',
  );
};

export const useMotifCompaniesInfinite = (
  variables: Parameters<MotifCompaniesMethod>[0],
  config?: UseInfiniteQueryConfig<
    MotifCompaniesMethod,
    MotifCompaniesErrorTypes
  >
) => {
  return useInfiniteQuery<
    MotifCompaniesMethod,
    MotifCompaniesErrorTypes
  >(
    (...args) => api.motifCompanies(...args),
    MotifCompaniesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MotifCompanies', 'MotifCompaniesInfinite'],
        ...apiQueryConfig.MotifCompanies,
        ...config?.queryConfig,
      },
    },
    'MotifCompanies',
  );
};

export const useMotifCompaniesLazy = (baseOptions?: {
  variables?: Parameters<MotifCompaniesMethod>[0],
  config?: UseQueryConfig<
    MotifCompaniesMethod,
    MotifCompaniesErrorTypes
  >
}) => {
  return useLazyQuery<
    MotifCompaniesMethod,
    MotifCompaniesErrorTypes
  >(
    (...args) => api.motifCompanies(...args),
    MotifCompaniesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MotifCompanies'],
        ...apiQueryConfig.MotifCompanies,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MotifCompanies',
  );
};


export type PublicCompaniesShowMethod = CompaniesApi['publicCompaniesShow'];
export type PublicCompaniesShowResponseType = MethodResult<PublicCompaniesShowMethod>;

export const usePublicCompaniesShow = (
  variables: Parameters<PublicCompaniesShowMethod>[0],
  config?: UseQueryConfig<
    PublicCompaniesShowMethod,
    PublicCompaniesShowErrorTypes
  >
) => {
  return useQuery<
    PublicCompaniesShowMethod,
    PublicCompaniesShowErrorTypes
  >(
    (...args) => api.publicCompaniesShow(...args),
    PublicCompaniesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PublicCompaniesShow'],
        ...apiQueryConfig.PublicCompaniesShow,
        ...config?.queryConfig
      },
    },
    'PublicCompaniesShow',
  );
};

export const usePublicCompaniesShowInfinite = (
  variables: Parameters<PublicCompaniesShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    PublicCompaniesShowMethod,
    PublicCompaniesShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    PublicCompaniesShowMethod,
    PublicCompaniesShowErrorTypes
  >(
    (...args) => api.publicCompaniesShow(...args),
    PublicCompaniesShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['PublicCompaniesShow', 'PublicCompaniesShowInfinite'],
        ...apiQueryConfig.PublicCompaniesShow,
        ...config?.queryConfig,
      },
    },
    'PublicCompaniesShow',
  );
};

export const usePublicCompaniesShowLazy = (baseOptions?: {
  variables?: Parameters<PublicCompaniesShowMethod>[0],
  config?: UseQueryConfig<
    PublicCompaniesShowMethod,
    PublicCompaniesShowErrorTypes
  >
}) => {
  return useLazyQuery<
    PublicCompaniesShowMethod,
    PublicCompaniesShowErrorTypes
  >(
    (...args) => api.publicCompaniesShow(...args),
    PublicCompaniesShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['PublicCompaniesShow'],
        ...apiQueryConfig.PublicCompaniesShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'PublicCompaniesShow',
  );
};


export type SecondMarketCompanyShowMethod = CompaniesApi['secondMarketCompanyShow'];
export type SecondMarketCompanyShowResponseType = MethodResult<SecondMarketCompanyShowMethod>;

export const useSecondMarketCompanyShow = (
  variables: Parameters<SecondMarketCompanyShowMethod>[0],
  config?: UseQueryConfig<
    SecondMarketCompanyShowMethod,
    SecondMarketCompanyShowErrorTypes
  >
) => {
  return useQuery<
    SecondMarketCompanyShowMethod,
    SecondMarketCompanyShowErrorTypes
  >(
    (...args) => api.secondMarketCompanyShow(...args),
    SecondMarketCompanyShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondMarketCompanyShow'],
        ...apiQueryConfig.SecondMarketCompanyShow,
        ...config?.queryConfig
      },
    },
    'SecondMarketCompanyShow',
  );
};

export const useSecondMarketCompanyShowInfinite = (
  variables: Parameters<SecondMarketCompanyShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondMarketCompanyShowMethod,
    SecondMarketCompanyShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondMarketCompanyShowMethod,
    SecondMarketCompanyShowErrorTypes
  >(
    (...args) => api.secondMarketCompanyShow(...args),
    SecondMarketCompanyShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondMarketCompanyShow', 'SecondMarketCompanyShowInfinite'],
        ...apiQueryConfig.SecondMarketCompanyShow,
        ...config?.queryConfig,
      },
    },
    'SecondMarketCompanyShow',
  );
};

export const useSecondMarketCompanyShowLazy = (baseOptions?: {
  variables?: Parameters<SecondMarketCompanyShowMethod>[0],
  config?: UseQueryConfig<
    SecondMarketCompanyShowMethod,
    SecondMarketCompanyShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondMarketCompanyShowMethod,
    SecondMarketCompanyShowErrorTypes
  >(
    (...args) => api.secondMarketCompanyShow(...args),
    SecondMarketCompanyShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondMarketCompanyShow'],
        ...apiQueryConfig.SecondMarketCompanyShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondMarketCompanyShow',
  );
};

