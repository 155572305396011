import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  type TableProps as AntTableProps,
  Grid,
  type PaginationProps,
} from 'antd';
import { type Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { type ColumnType as CT } from 'antd/lib/table';
import {
  type ExpandedRowRender,
  type RowClassName,
} from 'rc-table/lib/interface';
import { useIntersectionObserver } from 'usehooks-ts';

import { Icon } from '../../atoms/Icon';
import { NoDataArea } from '../../atoms/NoDataArea';
import { Tooltip } from '../../atoms/Tooltip';
import { EMPTY_VALUE } from '../../atoms/Typography';

import * as S from './Table.styles';
import { ExpandableArea } from './ExpandableArea';
import { type ExtendedColumnType, type RowKey } from './Table.types';
import { getRowKey, savePageSize } from './Table.utils';
import { paginationItemRender } from './TableComponents.utils';

const PAGE_SIZE_OPTIONS = ['5', '10', '20', '50'];
const HIGHLIGHTED_ROW_CLASS = 'highlighted-row';
const EXPANDED_ICON_CLASS =
  'ant-table-row-expand-icon ant-table-row-expand-icon-expanded';
const COLLAPSED_ICON_CLASS =
  'ant-table-row-expand-icon ant-table-row-expand-icon-collapsed';
export const EXPANDABLE_AREA_TABLE_VERTICAL_CLASS =
  'expandable-area-table-vertical';

const DEFAULT_ITEMS_PER_PAGE = 5;

const { useBreakpoint } = Grid;

export type TableProps<T> = {
  noDataArea?: React.ReactElement;
  extraRowContent?: {
    render: (record: T, isRowExpanded?: boolean) => React.ReactNode;
    showAlways?: boolean;
  };
  selectedRow?: (record: T, index: number) => boolean;
  slim?: boolean;
  variant?: 'primary' | 'secondary' | 'compact';
  outlined?: boolean;
  splitRows?: boolean;
  onChange?: (pagination: PaginationProps) => void;
  disablePagination?: boolean;
  showExpandedContentInColumns?: boolean;
  headerVerticalAlign?: 'top' | 'middle' | 'bottom';
} & Omit<AntTableProps<T>, 'columns'> & {
    pagination?: PaginationProps;
    columns?: ExtendedColumnType<T>[];
    rowKey?: RowKey<T>;
  };

export const Table = <T,>({
  expandable,
  extraRowContent,
  pagination,
  noDataArea = <NoDataArea title="No Data" />,
  selectedRow,
  rowKey = 'id',
  columns,
  slim,
  variant = 'primary',
  onChange,
  dataSource,
  showHeader = true,
  disablePagination,
  showExpandedContentInColumns,
  outlined = true,
  splitRows = false,
  className,
  rowClassName,
  headerVerticalAlign,
  ...rest
}: TableProps<T>) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly React.Key[]>(
    expandable?.defaultExpandedRowKeys ?? []
  );
  const [highlightedRows, setHighlightedRows] = useState<readonly React.Key[]>(
    []
  );

  const scrollToTopRef = useRef<HTMLDivElement | null>(null);
  const { ref, isIntersecting } = useIntersectionObserver({
    threshold: 1,
  });

  const breakpoints = useBreakpoint();

  const currentBreakpoints = useMemo(() => {
    return Object.keys(breakpoints).filter(k => breakpoints[k]);
  }, [breakpoints]) as Breakpoint[];

  const sortedColumns = useMemo(
    () => (columns ?? []).sort((a, b) => (b.priority || 0) - (a.priority || 0)),
    [columns]
  );

  // show in expanded area if asExtraRow defined or based on breakpoints (responsive)
  const expandedRowColumns = useMemo(
    () =>
      sortedColumns.filter(
        ({ responsive }) =>
          responsive &&
          !currentBreakpoints.some(breakpoint =>
            (responsive as unknown as Breakpoint[]).includes(breakpoint)
          )
      ),
    [currentBreakpoints, sortedColumns]
  );

  const resultColumns = useMemo(() => {
    // remove from columns if hidden
    const filteredColumns = sortedColumns.filter(({ hidden }) => !hidden);

    return filteredColumns.map(column => {
      const { titleInfo, render, ...rest } = column;
      if (!render && !titleInfo) return column;

      const resultingColumn: CT<T> = { ...rest };

      if (render) {
        resultingColumn.render = (_, record, index) =>
          render(record, index) ?? EMPTY_VALUE;
      }

      if (titleInfo) {
        resultingColumn.title = (
          <S.TitleContainer $align={column.align}>
            <span>
              {column.title}
              <span style={{ whiteSpace: 'nowrap' }}>
                &nbsp;
                <Tooltip title={titleInfo} size={column.tooltipSize}>
                  <S.InfoContainer>
                    <Icon name="info-circle" size="xs" />
                  </S.InfoContainer>
                </Tooltip>
              </span>
            </span>
          </S.TitleContainer>
        );
      }

      return resultingColumn;
    });
  }, [sortedColumns]);

  // we need all keys only if extraRowContent?.showAlways (e.g. Sell Schedule)
  const allRowKeys = useMemo(
    () =>
      extraRowContent?.showAlways && dataSource?.map(v => getRowKey(v, rowKey)),
    [dataSource, rowKey, extraRowContent?.showAlways]
  );

  const hidePagination =
    !pagination ||
    (pagination.total ?? 0) <=
      (pagination.pageSize ??
        pagination.defaultPageSize ??
        DEFAULT_ITEMS_PER_PAGE);
  const showSizeChanger =
    (pagination?.total ?? 0) > Number(PAGE_SIZE_OPTIONS[0]);

  const isRowExpanded = (record?: T) =>
    expandedRowKeys.includes(getRowKey<T>(record, rowKey));

  const expandedRowRender: ExpandedRowRender<T> = record => (
    <>
      {isRowExpanded(record) && (
        <ExpandableArea
          variant={variant}
          columns={expandedRowColumns.map(column => ({
            ...column,
            title: column.titleDynamic?.(record) || column.title,
          }))}
          record={record}
          showExpandedContentInColumns={showExpandedContentInColumns}
          tableVerticalClassName={EXPANDABLE_AREA_TABLE_VERTICAL_CLASS}
        />
      )}
      {extraRowContent &&
        (extraRowContent.showAlways || isRowExpanded(record)) &&
        extraRowContent.render(record, isRowExpanded(record))}
    </>
  );

  const handleChange = (pagination: PaginationProps) => {
    onChange?.(pagination);
    savePageSize(pagination.pageSize);

    // ignore if topRef is in the view
    if (isIntersecting) return;

    // scroll to topRef if table is paginated and topRef is not in the view
    scrollToTopRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  /**
   * Filters row that are not expandable, but get highlighted by the outside defaultExpandedRowKeys prop
   * We want to un-highlight a row like this if user selects a different expandable row
   */
  const getFilterDefaultHighlightedRows = (keys: readonly React.Key[]) => {
    const { rowExpandable, defaultExpandedRowKeys } = expandable || {};
    if (!defaultExpandedRowKeys?.length) return keys;

    return keys?.filter(key => {
      const record = dataSource?.find(
        record => getRowKey(record, rowKey) === key
      );
      const isExpandable = record && rowExpandable?.(record);
      const isDefaultExpanded = defaultExpandedRowKeys?.includes(key);
      const isHighlighted = highlightedRows?.includes(key);
      return isExpandable || !isDefaultExpanded || !isHighlighted;
    });
  };

  const onExpand = record => {
    if (expandable?.rowExpandable && !expandable?.rowExpandable(record)) {
      return;
    }
    const id = getRowKey(record, rowKey);

    setExpandedRowKeys(
      isRowExpanded(record)
        ? expandedRowKeys.filter(k => k !== id)
        : [...expandedRowKeys, id]
    );

    // do not highlight if there is no click event on the row
    if (expandable?.rowExpandable?.(record) || expandable?.showExpandColumn) {
      setHighlightedRows(rows => {
        const filteredRows = getFilterDefaultHighlightedRows(rows);
        return !filteredRows?.includes(id) && !isRowExpanded(record)
          ? [id, ...filteredRows]
          : filteredRows?.filter(key => key !== id);
      });
    }

    expandable?.onExpand?.(isRowExpanded(record), record);
  };

  const getExpandIcon = (record: T) => {
    if (expandable?.rowExpandable && !expandable?.rowExpandable(record)) {
      return undefined;
    }
    const isExpanded = isRowExpanded(record);
    return (
      <S.ExpandIconWrapper data-dd-action-name="Expand Table Row">
        <span
          className={isExpanded ? EXPANDED_ICON_CLASS : COLLAPSED_ICON_CLASS}
        />
      </S.ExpandIconWrapper>
    );
  };

  useEffect(() => {
    if (expandable?.defaultExpandedRowKeys) {
      setHighlightedRows(expandable.defaultExpandedRowKeys);
      setExpandedRowKeys(expandable.defaultExpandedRowKeys);
    }
  }, [expandable?.defaultExpandedRowKeys]);

  return (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    <>
      {/* we use ScrollAnchor here because scrolling doesn't work properly if we attach our ref to the Table directly*/}
      <S.ScrollAnchor ref={scrollToTopRef} />
      <S.Table<any>
        ref={ref}
        $hidePagination={hidePagination}
        $variant={variant}
        $noHeader={!showHeader}
        $outlined={outlined}
        $splitRows={splitRows}
        $headerVerticalAlign={headerVerticalAlign}
        pagination={
          disablePagination || (hidePagination && !showSizeChanger)
            ? false
            : {
                ...(pagination ? pagination : {}),
                defaultPageSize:
                  pagination?.defaultPageSize ?? DEFAULT_ITEMS_PER_PAGE,
                showSizeChanger,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                position: ['bottomLeft'],
                size: breakpoints.xs ? 'small' : 'default',
                itemRender: paginationItemRender,
              }
        }
        expandable={{
          columnWidth: '20px',
          expandedRowRender,
          ...(expandedRowColumns?.length
            ? { showExpandColumn: true, rowExpandable: () => true }
            : { showExpandColumn: false, rowExpandable: () => false }),
          ...(extraRowContent?.showAlways
            ? { expandedRowKeys: allRowKeys }
            : { expandedRowKeys }),
          ...expandable,
        }}
        expandIcon={props => getExpandIcon(props.record)}
        onRow={(record, idx) => ({
          onClick: () => {
            onExpand(record);
            rest?.rowSelection?.onChange?.([idx], [record], {
              type: 'multiple',
            });
          },
          ...rest.onRow,
        })}
        locale={{
          emptyText: React.cloneElement(noDataArea, {
            loading: !!rest.loading,
            loader: null,
          }),
        }}
        rowClassName={(record, index, indent) => {
          const isHighlightedClass =
            selectedRow?.(record, index) ||
            highlightedRows.includes(getRowKey(record, rowKey))
              ? HIGHLIGHTED_ROW_CLASS
              : '';

          const customRowClassName =
            typeof rowClassName === 'string'
              ? rowClassName
              : (rowClassName as RowClassName<T>)?.(record, index, indent);

          return `${isHighlightedClass} ${customRowClassName || ''}`;
        }}
        rowKey={rowKey}
        columns={resultColumns}
        dataSource={dataSource}
        showHeader={showHeader}
        {...rest}
        className={`${splitRows ? 'table-split-rows' : ''} ${className || ''}`}
        onChange={handleChange}
      />
    </>
  );
};
