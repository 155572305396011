import React, { type ComponentProps } from 'react';

import { type Rule } from 'antd/lib/form';
import { useIsFirstRender } from 'usehooks-ts';

import * as S from '../ButtonCardQuestion/ButtonCardQuestion.styles';
import { CypressDataIds } from '../../../constants';
import { FormItem } from '../../atoms/FormItem';
import { MultiSelectWithSearch } from '../MultiSelectWithSearch';

type Props = {
  name: string;
  onQuestionAnswered: () => void;
  rules?: Rule[];
  isDisabled?: boolean;
  subtitle?: string;
  isQuestionLast?: boolean;
} & Pick<ComponentProps<typeof MultiSelectWithSearch>, 'options' | 'isLoading'>;

export const MultiSelectQuestion = ({
  name,
  options,
  isLoading,
  onQuestionAnswered,
  rules,
  isDisabled,
  subtitle,
  isQuestionLast,
}: Props) => {
  const isFirstMount = useIsFirstRender();

  return (
    <S.MultiSelectFormItem
      shouldUpdate={(prevValues, currValues) =>
        prevValues[name]?.length !== currValues[name]?.length
      }
    >
      {form => {
        // For scrollToField to work on nested form controls, set id of wrapper to form item name
        // https://ant.design/components/form/#scrollToFirstError-and-scrollToField-not-working-on-custom-form-control
        return (
          <div id={name}>
            <FormItem
              name={name}
              rules={rules}
              data-cy={name}
              validateTrigger={isFirstMount ? 'onSubmit' : 'onChange'}
            >
              <MultiSelectWithSearch
                id={name}
                options={options}
                isLoading={isLoading}
                initialValues={form.getFieldValue(name)}
                disabled={isDisabled}
                subtitle={subtitle}
              />
            </FormItem>
            {!isQuestionLast && (
              <S.NextButton
                variant="outline"
                data-cy={CypressDataIds.Components.AutoScrollingForm.NextButton}
                onClick={onQuestionAnswered}
                disabled={!form.getFieldValue(name)?.length}
              >
                Next
              </S.NextButton>
            )}
          </div>
        );
      }}
    </S.MultiSelectFormItem>
  );
};
