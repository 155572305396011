import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type DocumentAggregate } from '@npm/data-access';

import { PooDrawerComponentsStyles as S } from './styles';

type Props = {
  doc: DocumentAggregate;
  onClick?: (id: number) => void;
};

export const ProofOfOwnershipItem = ({ doc, onClick }: Props) => {
  return (
    <S.Item as="button" onClick={() => onClick?.(doc.id)}>
      <S.PaperClipWrapper>
        <Icon name="paperclip" size="md" />
      </S.PaperClipWrapper>
      <Text as="button" hyperlinkColor="info" size="default">
        {doc.display_name}
      </Text>
    </S.Item>
  );
};
