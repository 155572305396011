import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useTheme } from 'styled-components';

type Props = {
  sector?: string;
  motif?: string;
};

export const CompanySectorMotif = ({ sector, motif }: Props) => {
  const theme = useTheme();
  if (!sector && !motif) {
    return null;
  }

  return (
    <Flex $wrap>
      <Text
        size="xxs"
        colorVariant="secondary"
        lineClamp={1}
        style={{ marginRight: theme.spacing.xs }}
      >
        {sector}
      </Text>
      <Text size="xxs" colorVariant="secondary" lineClamp={1}>
        &gt; {motif}
      </Text>
    </Flex>
  );
};
