import React from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type ChipProps,
  DrawerSection,
} from '@npm/core/ui/components/molecules/DrawerSection';
import { capitalize } from 'lodash';

import { useObo } from '../../role';
import { AccountInfo } from '../AccountInfo';

type Props = {
  transactionRole?: 'stakeholder' | 'investor' | null;
  onAccountSwitch?: () => void;
  accountId?: number;
  showTitle?: boolean;
  content?: React.ReactNode;
  isObo?: boolean;
  showOboLabel?: boolean;
  roleLabel?: string;
  showTransactionRole?: boolean;
  informationalChip?: ChipProps;
};

export const YourRoleDrawerSection = ({
  transactionRole,
  onAccountSwitch,
  accountId,
  showTitle = true,
  content: customContent,
  isObo: isOboProp,
  showOboLabel = true,
  roleLabel = 'Your Role',
  showTransactionRole = true,
  informationalChip,
}: Props) => {
  const { isObo: isOboMode } = useObo();
  const isObo = isOboProp || isOboMode;

  const content = customContent ? (
    <>{customContent}</>
  ) : (
    <>
      {isObo && showOboLabel && (
        <Text size="sm" marginBottom="sm">
          Acting on behalf of
        </Text>
      )}
      <AccountInfo
        accountPanelProps={{
          showTitle: false,
          showRepresentative: false,
          onAccountSwitch,
        }}
        accountId={accountId}
      />
    </>
  );

  return showTitle ? (
    <DrawerSection
      iconName="user"
      title={
        <>
          <span>{roleLabel}</span>
          {showTransactionRole && (
            <Text colorVariant="secondary" as="span">
              &nbsp;&nbsp;•&nbsp;&nbsp;
              {capitalize(transactionRole)}
            </Text>
          )}
        </>
      }
      content={content}
      informationalChip={informationalChip}
    />
  ) : (
    content
  );
};
