import React, { type ComponentProps } from 'react';

import { type FormInstance } from 'antd';

import { Heading, Text } from '../../../atoms/Typography';
import { TitleWithIconTooltip } from '../../../molecules/TitleWithIconTooltip';

import * as S from './QuestionCard.styles';

export const AUTO_SCROLL_FORM_QUESTION_CLASS = 'auto-scroll-question';

type Props<T> = {
  title: string; // Question card title
  number: {
    // Question number
    show: boolean; // Flag, if the question number (`1 of 9`, etc.) should be shown
    index: number; // Question index
    total: number; // Total number of questions
  };
  form: FormInstance<T>;
  onQuestionAnswered: () => void;
  children: (props: {
    form: FormInstance<T>;
    onQuestionAnswered: () => void;
    isDisabled?: boolean;
  }) => React.ReactNode; // Question content
  addToRefsArray?: (ref: HTMLDivElement) => void;
  minHeight: number | string;
  subTitle?: string;
  isDisabled?: boolean;
  titleTooltip?: string; // Question card title tooltip
  titleVariant?: ComponentProps<typeof Heading>['variant'];
};

/**
 * Question card component
 * @param title
 * @param children
 * @param number
 * @param form
 * @param onQuestionAnswered
 * @param addToRefsArray
 * @param minHeight
 * @param subTitle
 * @param isDisabled
 * @param titleTooltip
 *
 */
export const QuestionCard = <T,>({
  title,
  children,
  number,
  form,
  onQuestionAnswered,
  addToRefsArray,
  minHeight,
  subTitle,
  isDisabled,
  titleTooltip,
  titleVariant = 'h1',
}: Props<T>) => {
  return (
    <S.Wrapper
      className={AUTO_SCROLL_FORM_QUESTION_CLASS}
      ref={addToRefsArray}
      $minHeight={minHeight}
      $isDisabled={isDisabled}
    >
      {number.show && (
        <Text size="sm" color="info" marginBottom="xs">
          {number.index + 1} of {number.total}
        </Text>
      )}
      <S.MarginSpacer $margin={!!subTitle}>
        {titleTooltip ? (
          <TitleWithIconTooltip
            variant={titleVariant}
            as="h2"
            title={title}
            tooltip={titleTooltip}
            marginBottom="xs"
          />
        ) : (
          <Heading variant={titleVariant} as="h2" marginBottom="xs">
            {title}
          </Heading>
        )}
      </S.MarginSpacer>
      {subTitle && (
        <S.MarginSpacer>
          <S.Subtitle size="sm">{subTitle}</S.Subtitle>
        </S.MarginSpacer>
      )}
      {children({ form, onQuestionAnswered, isDisabled })}
    </S.Wrapper>
  );
};
