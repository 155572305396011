import { useState } from 'react';

import {
  type EducationalModalConfig,
  type EducationalModalProps,
} from './EducationalModal.types';

type HookReturn = [() => void, EducationalModalProps];

export const useEducationalModal = (
  config: EducationalModalConfig
): HookReturn => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return [
    openModal,
    {
      isOpen,
      onClose,
      config,
    },
  ];
};
