import React from 'react';

import { type ButtonColor } from '@npm/core/ui/components/atoms/Button/Button.types';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CypressDataIds } from '@npm/core/ui/constants';
import { type IssuerEntityAggregate } from '@npm/data-access';

import { useWatchlistButton, WatchlistButton } from '../../watchlist';
import { CompanySectorMotif } from '../CompanySectorMotif';

import { IsSelectedIcon, StyledCompanyCard } from './CompanyCard.styles';
import { Content } from './Content';
import { DisabledTradingContent } from './DisabledTradingContent';

type Props = {
  company: IssuerEntityAggregate;
  isSelected?: boolean;
  onSelect?: (id: number) => void;
  variant?: 'broker' | 'investor' | 'mini';
  showSelectedIcon?: boolean;
  watchlistButtonColor?: ButtonColor;
  showWatchlistButton?: boolean;
  onWatchlistButtonClick?: (company: IssuerEntityAggregate) => void;
  watchlistButtonStopPropagation?: boolean;
  style?: React.CSSProperties;
  className?: string;
  watchlistAccountId?: number;
  footer?: React.ReactNode;
};

export const CompanyCard = ({
  company,
  isSelected,
  onSelect,
  variant = 'investor',
  showSelectedIcon = false,
  watchlistButtonColor,
  showWatchlistButton = false,
  watchlistButtonStopPropagation = true,
  watchlistAccountId,
  onWatchlistButtonClick,
  footer: cardFooter,
  style,
  className,
}: Props) => {
  const { isWatched, ...watchlistButtonProps } = useWatchlistButton({
    company,
    watchlistAccountId,
    onClick: onWatchlistButtonClick,
  });

  const description = (
    <CompanySectorMotif
      sector={company?.sector?.name}
      motif={company?.motif?.name}
    />
  );

  return (
    <StyledCompanyCard
      outlined={false}
      header={{
        title: (
          <CompanyLogo
            url={company.logo_url}
            name={company.name}
            description={description}
            isCompanyCard
          />
        ),
        label: showWatchlistButton && (
          <WatchlistButton
            data-cy={CypressDataIds.Drawer.WatchlistButton}
            iconOnly
            variant="text"
            color={watchlistButtonColor}
            stopPropagation={watchlistButtonStopPropagation}
            isWatched={onWatchlistButtonClick === null ? isSelected : isWatched}
            style={{ marginRight: 16 }}
            {...watchlistButtonProps}
          />
        ),
      }}
      footer={cardFooter}
      dataCy={CypressDataIds.Card.CompanyCard.component}
      onClick={onSelect && (() => onSelect(company.id))}
      $isSelected={isSelected}
      $isSelectable={!!onSelect}
      style={style}
      className={className}
      data-dd-action-name="Company Card"
    >
      {isSelected && showSelectedIcon && <IsSelectedIcon />}
      {company.secondmarket_trading ? (
        variant !== 'mini' && (
          <Content
            isSelected={isSelected}
            variant={variant}
            company={company}
          />
        )
      ) : (
        <DisabledTradingContent companyId={company.id} />
      )}
    </StyledCompanyCard>
  );
};
