/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InternalBrokerIndex } from '../model';
/**
 * BrokerIndexApi - axios parameter creator
 * @export
 */
export const BrokerIndexApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary return list of brokers that we can process flow screeners
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalBrokerIndex: async (xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/internal/brokers`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokerIndexApi - functional programming interface
 * @export
 */
export const BrokerIndexApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokerIndexApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary return list of brokers that we can process flow screeners
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalBrokerIndex(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalBrokerIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalBrokerIndex(xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokerIndexApi - factory interface
 * @export
 */
export const BrokerIndexApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokerIndexApiFp(configuration)
    return {
        /**
         * 
         * @summary return list of brokers that we can process flow screeners
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalBrokerIndex(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<InternalBrokerIndex> {
            return localVarFp.internalBrokerIndex(xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalBrokerIndex operation in BrokerIndexApi.
 * @export
 * @interface BrokerIndexApiInternalBrokerIndexRequest
 */
export interface BrokerIndexApiInternalBrokerIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof BrokerIndexApiInternalBrokerIndex
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof BrokerIndexApiInternalBrokerIndex
     */
    readonly xSubscriptionPlan?: string
}

/**
 * BrokerIndexApi - object-oriented interface
 * @export
 * @class BrokerIndexApi
 * @extends {BaseAPI}
 */
export class BrokerIndexApi extends BaseAPI {
    /**
     * 
     * @summary return list of brokers that we can process flow screeners
     * @param {BrokerIndexApiInternalBrokerIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerIndexApi
     */
    public internalBrokerIndex(requestParameters: BrokerIndexApiInternalBrokerIndexRequest = {}, options?: AxiosRequestConfig) {
        return BrokerIndexApiFp(this.configuration).internalBrokerIndex(requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InternalBrokerIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InternalBrokerIndexErrorCodes = [
    401,
];


