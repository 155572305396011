// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  NegotiationStatisticsApi,
  NegotiationStatisticsIndexErrorCodes,
  NegotiationStatisticsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new NegotiationStatisticsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type NegotiationStatisticsIndexMethod = NegotiationStatisticsApi['negotiationStatisticsIndex'];
export type NegotiationStatisticsIndexResponseType = MethodResult<NegotiationStatisticsIndexMethod>;

export const useNegotiationStatisticsIndex = (
  variables: Parameters<NegotiationStatisticsIndexMethod>[0],
  config?: UseQueryConfig<
    NegotiationStatisticsIndexMethod,
    NegotiationStatisticsIndexErrorTypes
  >
) => {
  return useQuery<
    NegotiationStatisticsIndexMethod,
    NegotiationStatisticsIndexErrorTypes
  >(
    (...args) => api.negotiationStatisticsIndex(...args),
    NegotiationStatisticsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NegotiationStatisticsIndex'],
        ...apiQueryConfig.NegotiationStatisticsIndex,
        ...config?.queryConfig
      },
    },
    'NegotiationStatisticsIndex',
  );
};

export const useNegotiationStatisticsIndexInfinite = (
  variables: Parameters<NegotiationStatisticsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    NegotiationStatisticsIndexMethod,
    NegotiationStatisticsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    NegotiationStatisticsIndexMethod,
    NegotiationStatisticsIndexErrorTypes
  >(
    (...args) => api.negotiationStatisticsIndex(...args),
    NegotiationStatisticsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NegotiationStatisticsIndex', 'NegotiationStatisticsIndexInfinite'],
        ...apiQueryConfig.NegotiationStatisticsIndex,
        ...config?.queryConfig,
      },
    },
    'NegotiationStatisticsIndex',
  );
};

export const useNegotiationStatisticsIndexLazy = (baseOptions?: {
  variables?: Parameters<NegotiationStatisticsIndexMethod>[0],
  config?: UseQueryConfig<
    NegotiationStatisticsIndexMethod,
    NegotiationStatisticsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    NegotiationStatisticsIndexMethod,
    NegotiationStatisticsIndexErrorTypes
  >(
    (...args) => api.negotiationStatisticsIndex(...args),
    NegotiationStatisticsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NegotiationStatisticsIndex'],
        ...apiQueryConfig.NegotiationStatisticsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NegotiationStatisticsIndex',
  );
};

