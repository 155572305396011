// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  GICSSectosApi,
  GicsSectorsErrorCodes,
  GicsSectorsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new GICSSectosApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type GicsSectorsMethod = GICSSectosApi['gicsSectors'];
export type GicsSectorsResponseType = MethodResult<GicsSectorsMethod>;

export const useGicsSectors = (
  variables: Parameters<GicsSectorsMethod>[0],
  config?: UseQueryConfig<
    GicsSectorsMethod,
    GicsSectorsErrorTypes
  >
) => {
  return useQuery<
    GicsSectorsMethod,
    GicsSectorsErrorTypes
  >(
    (...args) => api.gicsSectors(...args),
    GicsSectorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GicsSectors'],
        ...apiQueryConfig.GicsSectors,
        ...config?.queryConfig
      },
    },
    'GicsSectors',
  );
};

export const useGicsSectorsInfinite = (
  variables: Parameters<GicsSectorsMethod>[0],
  config?: UseInfiniteQueryConfig<
    GicsSectorsMethod,
    GicsSectorsErrorTypes
  >
) => {
  return useInfiniteQuery<
    GicsSectorsMethod,
    GicsSectorsErrorTypes
  >(
    (...args) => api.gicsSectors(...args),
    GicsSectorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GicsSectors', 'GicsSectorsInfinite'],
        ...apiQueryConfig.GicsSectors,
        ...config?.queryConfig,
      },
    },
    'GicsSectors',
  );
};

export const useGicsSectorsLazy = (baseOptions?: {
  variables?: Parameters<GicsSectorsMethod>[0],
  config?: UseQueryConfig<
    GicsSectorsMethod,
    GicsSectorsErrorTypes
  >
}) => {
  return useLazyQuery<
    GicsSectorsMethod,
    GicsSectorsErrorTypes
  >(
    (...args) => api.gicsSectors(...args),
    GicsSectorsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GicsSectors'],
        ...apiQueryConfig.GicsSectors,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GicsSectors',
  );
};

