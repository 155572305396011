import { useEffect } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { InlineInputNumber } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import {
  MAXIMUM_PRICE_FRACTION_DIGITS,
  MINIMUM_PRICE_FRACTION_DIGITS,
} from '@npm/core/ui/utils/formatters';
import {
  CbVisibility,
  type IssuerEntityAggregate,
  VenusApi,
  type VisibilityCode,
} from '@npm/data-access';
import { Form } from 'antd';

import {
  getReversedOrderSizeType,
  OrderSizeInput,
  OrderSizeToggle,
  type OrderSizeType,
  OrderValuationToggle,
  reverseOrderSize,
  useCalculatePpsOrValuation,
  useOrderValuationToggle,
} from '../../../../order';

import * as S from './OrderSize.styles';
import { EstLastPreferredPPS } from './EstLastPreferredPPS';
import { ImpliedValuation } from './ImpliedValuation';
import {
  getMinimumQuantityRules,
  getPpsRules,
  getQuantityRules,
} from './OrderSize.utils';

const { useCompaniesLatestStockClassDetails } = VenusApi;

type FormValues = {
  minimumQuantity: number;
  quantity: number;
  pricePerShare: number;
};

type Props = {
  activeAction: 'buy' | 'sell';
  sizeType: OrderSizeType;
  company: IssuerEntityAggregate;
  visibility: VisibilityCode | undefined;
  onSizeTypeChange?: () => void;
  remainingQuantity?: number;
  showSizeExtraRow?: boolean;
  header?: {
    showHeader: boolean;
    title?: string;
  };
  showMinimumQuantity?: boolean;
  showValuation?: boolean;
  showPpsValuation?: boolean;
};

export const OrderSize = ({
  activeAction,
  sizeType,
  company,
  visibility,
  onSizeTypeChange,
  remainingQuantity,
  showSizeExtraRow = true,
  header = {
    showHeader: true,
    title: 'Order Terms',
  },
  showMinimumQuantity = true,
  showValuation = true,
  showPpsValuation,
}: Props) => {
  const form = Form.useFormInstance<FormValues>();
  const minimumQuantity = Form.useWatch('minimumQuantity', form);
  const quantity = Form.useWatch('quantity', form);
  const pricePerShare = Form.useWatch('pricePerShare', form);
  const impliedValuation = Form.useWatch('impliedValuation', form);

  const { valuationType, updateValuationType, toggleValuationType } =
    useOrderValuationToggle();

  const isPricePerShareRequired = visibility === CbVisibility.items.external;

  const reverseSizeType = getReversedOrderSizeType(sizeType);

  useCalculatePpsOrValuation(
    form,
    company?.venus_id,
    valuationType,
    impliedValuation,
    pricePerShare,
    showValuation
  );

  const { data: latestStockClassData, isLoading: isStockClassDataLoading } =
    useCompaniesLatestStockClassDetails(
      { id: String(company?.venus_id) },
      {
        queryConfig: { enabled: !!company?.venus_id },
        onError: error => {
          // Do not destroy the entire form if fetching the latest stock class details fails
          if (error?.status === -1 && error?.originalStatus === 404) return;
          console.error(error);
        },
      }
    );

  const isMissingStockClassData =
    !latestStockClassData?.price_per_share || !latestStockClassData?.valuation;

  useEffect(() => {
    // Revalidate quantity and minimumQuantity if sizeType has changed
    // because the rules depend on sizeType
    if (minimumQuantity) {
      form.validateFields(['minimumQuantity']);
    }
    if (quantity) {
      form.validateFields(['quantity']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeType]);

  if (
    isMissingStockClassData &&
    !isStockClassDataLoading &&
    valuationType === 'Valuation'
  ) {
    updateValuationType('PPS');
  }

  return (
    <DrawerSection
      iconName={header.showHeader ? 'clipboard-text' : undefined}
      title={header.showHeader ? header.title : ''}
      showTitle={header.showHeader}
      headerAction={
        onSizeTypeChange ? (
          <OrderSizeToggle
            sizeType={sizeType}
            onChange={() => onSizeTypeChange()}
          />
        ) : null
      }
      content={
        <>
          <FormItem
            name="quantity"
            rules={getQuantityRules(
              activeAction,
              sizeType,
              true,
              pricePerShare,
              remainingQuantity
            )}
            marginBottom="sm"
          >
            <OrderSizeInput sizeType={sizeType} label="Size" required={true} />
          </FormItem>
          {showMinimumQuantity && (
            <FormItem
              name="minimumQuantity"
              dependencies={['quantity']}
              rules={getMinimumQuantityRules(form, sizeType, false)}
              marginBottom="sm"
            >
              <OrderSizeInput
                sizeType={sizeType}
                label="Min. Size"
                required={false}
              />
            </FormItem>
          )}

          {showValuation && (
            <OrderValuationToggle
              valuationType={valuationType}
              toggleValuationType={toggleValuationType}
              isLoading={isStockClassDataLoading}
              hidden={isMissingStockClassData}
            />
          )}

          <FormItem
            name="impliedValuation"
            rules={
              valuationType !== 'Valuation'
                ? []
                : getPpsRules(isPricePerShareRequired)
            }
            labelAlign="right"
            marginBottom="sm"
            hidden={valuationType !== 'Valuation'}
            extra={
              <EstLastPreferredPPS
                venusId={company?.venus_id}
                pricePerShare={pricePerShare}
              />
            }
          >
            <InlineInputNumber
              label="Est. Valuation"
              placeholder="0.00"
              currency="USD"
              required={
                isPricePerShareRequired && valuationType === 'Valuation'
              }
            />
          </FormItem>

          <FormItem
            name="pricePerShare"
            rules={getPpsRules(isPricePerShareRequired)}
            labelAlign="right"
            extra={
              showValuation ? (
                <ImpliedValuation
                  stockClassValuation={latestStockClassData?.valuation}
                  impliedValuation={impliedValuation}
                  isLoading={isStockClassDataLoading}
                />
              ) : showPpsValuation ? (
                <EstLastPreferredPPS
                  venusId={company?.venus_id}
                  pricePerShare={pricePerShare}
                  showDisclaimer={false}
                />
              ) : null
            }
            marginBottom="sm"
            hidden={valuationType !== 'PPS'}
          >
            <InlineInputNumber
              label="Price Per Share"
              placeholder="0.00"
              currency="USD"
              required={isPricePerShareRequired}
            />
          </FormItem>

          {showSizeExtraRow && (
            <Margin top="lg" bottom="lg">
              <S.DataRow justify="space-between" align="flex-end">
                <Text size="sm" colorVariant="secondary">
                  Size ({reverseSizeType})
                </Text>
                <Text.Quantity
                  size="sm"
                  value={[
                    reverseOrderSize(
                      reverseSizeType,
                      minimumQuantity,
                      pricePerShare
                    ),
                    reverseOrderSize(reverseSizeType, quantity, pricePerShare),
                  ]}
                  unit={reverseSizeType}
                  formatOptions={{
                    maximumFractionDigits:
                      reverseSizeType === 'Shares'
                        ? 0
                        : MAXIMUM_PRICE_FRACTION_DIGITS,
                    minimumFractionDigits:
                      reverseSizeType === 'Shares'
                        ? 0
                        : MINIMUM_PRICE_FRACTION_DIGITS,
                  }}
                />
              </S.DataRow>
            </Margin>
          )}
        </>
      }
    />
  );
};
