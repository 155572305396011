export const INSTITUTION_BENEFITS = [
  'Benchmark your Portfolio',
  'View Portfolio and Watchlist activity',
  'Post to Marketplace | Negotiate with counterparty',
  'Settle Trades',
  'Access financing activity + cap-table data, and more',
];

export const INDIVIDUAL_BENEFITS = [
  'Post your Offer',
  'Accept Bids | Negotiate with buyers',
  'Settle Trades',
  'Get Paid',
];

export const DASHBOARD_IS_WATCHLIST_DRAWER_OPEN_QUERY_KEY =
  'watchlistDrawerOpen';
