import React, { useState } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { getFilenameExtension } from '@npm/core/ui/utils/file';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { type AxiosRequestConfig } from 'axios';

import { type UploadRequestType } from '../../DataRoom';
import { UploadForm } from '../UploadForm';
import { type UploadFormFields } from '../UploadForm/FileItem';
import { useUploadForm } from '../UploadForm/UploadForm.hooks';

import { handleUploadError } from './UploadDrawer.utils';

const UPLOAD_DOCUMENT_FORM_ID = 'upload-document-form';

type Props = {
  onUploadFile: (
    payload: Omit<UploadRequestType, 'file'> & { file: File },
    options?: {
      onComplete?: () => void;
      axiosConfig?: AxiosRequestConfig;
    }
  ) => Promise<unknown>;
  formFields?: UploadFormFields;
  isOpen: boolean;
  onClose: () => void;
  accept?: string[] | null;
  acceptLabel?: string | null;
};

export const UploadDrawer = ({
  onUploadFile,
  formFields,
  isOpen,
  onClose,
  accept,
  acceptLabel,
}: Props) => {
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const [
    { updateProgress, addFile, removeFile, resetState },
    { form, fileList, isUploading, progresses },
  ] = useUploadForm();

  const { showAlert, showApiError, clearAlerts } = useAlerts();

  const handleOnClose = () => {
    onClose();
    resetState();
  };

  const uploadDocument = async ({ file, ...request }: UploadRequestType) =>
    onUploadFile(
      {
        ...request,
        file: file as unknown as File,
      },
      {
        axiosConfig: {
          onUploadProgress: e => updateProgress(file.uid, e),
        },
        onComplete: () => {
          removeFile(file);
        },
      }
    ).catch(error =>
      handleUploadError(file, error, form, updateProgress, showApiError)
    );

  const uploadAllDocuments = async () => {
    let fileDetails;

    clearAlerts();
    try {
      fileDetails = await form.validateFields();
    } catch (error) {
      handleValidationError(form, error);
      return;
    }

    const result = await Promise.allSettled(
      fileList.map(file =>
        uploadDocument({
          file: file,
          displayName:
            fileDetails[file.uid].display_name +
            getFilenameExtension(file.name),
          downloadable: fileDetails[file.uid].downloadable,
          watermark: fileDetails[file.uid].watermark,
        })
      )
    );

    updateProgress(); // remove all progresses, as we use them as isUploading indicator
    const numberOfErrors = result.filter(v => v.status === 'rejected').length;
    if (!numberOfErrors) {
      handleOnClose();
      Notification.success({
        message: 'Your files have been uploaded',
      });
    } else {
      showAlert(
        `${numberOfErrors} file${
          numberOfErrors > 1 ? 's' : ''
        } could not been uploaded`
      );
    }
  };

  return (
    <>
      <Drawer
        open={isOpen}
        title="Upload Files"
        data-cy={CypressDataIds.DataRooms.UploadDrawer.Drawer}
        submitButtonProps={{
          form: UPLOAD_DOCUMENT_FORM_ID,
          htmlType: 'submit',
          children: 'Upload All',
          disabled: !fileList.length || isUploading,
          icon: <Icon name="upload" />,
          'data-cy': CypressDataIds.DataRooms.UploadDrawer.SubmitButton,
        }}
        onClose={() => {
          if (fileList.length) {
            setIsDiscardModalOpen(true);
          } else {
            handleOnClose();
          }
        }}
        destroyOnClose
      >
        <UploadForm
          id={UPLOAD_DOCUMENT_FORM_ID}
          form={form}
          fileList={fileList}
          progresses={progresses}
          isUploading={isUploading}
          addFile={addFile}
          removeFile={removeFile}
          formFields={formFields}
          onFinish={uploadAllDocuments}
          accept={accept}
          acceptLabel={acceptLabel}
        />
      </Drawer>
      <Modal
        okText="Exit"
        cancelText="Cancel"
        title="Exit the uploading"
        open={isDiscardModalOpen}
        onOk={() => {
          setIsDiscardModalOpen(false);
          handleOnClose();
        }}
        okButtonProps={{ color: 'error' }}
        onCancel={() => {
          setIsDiscardModalOpen(false);
        }}
        size="md"
      >
        You’re about to leave your uploading process. Your files won’t be
        uploaded.
      </Modal>
    </>
  );
};
