import React from 'react';

import { Flex } from '../common';
import { Text } from '../Typography';

import * as S from './ProgressBar.styles';
import { getCurrentStepCompletion } from './ProgressBar.utils';
import { ProgressStep } from './ProgressStep';

type Props = {
  title?: React.ReactNode;
  currentStep: number;
  totalSteps: number;
  stepBackground?: string;
  extra?: React.ReactNode;
};

export const ProgressBar = ({
  title,
  currentStep,
  totalSteps,
  stepBackground,
  extra,
}: Props) => {
  return (
    <S.Progress>
      <Flex justify="space-between" align="flex-start">
        <S.TextContainer>
          {title && (
            <Text size="sm" marginBottom="xs">
              {title}
            </Text>
          )}
          <S.Counter>
            <Text size="xs" colorVariant="primary">
              {Math.floor(currentStep)} / {totalSteps}
            </Text>
          </S.Counter>
        </S.TextContainer>
        {extra}
      </Flex>
      <S.StepsContainer>
        {Array.from({ length: totalSteps }, (_, i) => (
          <ProgressStep
            key={i}
            percent={getCurrentStepCompletion(currentStep, i)}
            stepBackground={stepBackground}
          />
        ))}
      </S.StepsContainer>
    </S.Progress>
  );
};
