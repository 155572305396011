import styled, { css, type DefaultTheme } from 'styled-components';

import {
  preserveHorizontalFocusBorder,
  useBreakpoint,
} from '../../../utils/style';

export const TOP_ROW_MIN_HEIGHT = 64;
const TOP_ROW_MIN_HEIGHT_BREADCRUMBS = 52;
const TOP_ROW_MIN_HEIGHT_MOBILE_BREADCRUMBS = 38;
export const TOP_ROW_MIN_HEIGHT_MOBILE_NO_BREADCRUMBS = 56;

export const TopRow = styled.div<{
  $bgColor: keyof DefaultTheme['color']['general']['layout'] | null;
}>`
  background-color: ${({ theme, $bgColor }) =>
    $bgColor ? theme.color.general.layout[$bgColor] : 'transparent'};
  z-index: ${({ theme }) => theme.zIndex.fixed};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  // prevents cutting off button outline -> caused by overflow: auto
  ${preserveHorizontalFocusBorder};
  padding-top: ${({ theme }) => theme.spacing.lg / 2}px;
  padding-bottom: ${({ theme }) => theme.spacing.lg / 2}px;
  min-height: ${TOP_ROW_MIN_HEIGHT}px;

  // with breadcrumbs
  :not(:only-child) {
    min-height: ${TOP_ROW_MIN_HEIGHT_BREADCRUMBS}px;

    // this is for the Affix to stick correctly
    padding-bottom: 0;
    margin-bottom: -4px;
  }

  ${({ theme }) =>
    useBreakpoint(theme).mobile`${css`
      min-height: ${TOP_ROW_MIN_HEIGHT_MOBILE_NO_BREADCRUMBS}px;
      padding-top: ${theme.spacing.sm}px;
      padding-bottom: ${theme.spacing.sm}px;

      // with breadcrumbs
      :not(:only-child) {
        min-height: ${TOP_ROW_MIN_HEIGHT_MOBILE_BREADCRUMBS}px;
        padding-top: ${theme.spacing.sm}px;
        padding-bottom: ${theme.spacing.sm}px;
        margin-bottom: 0;
      }
    `}`}
`;

export const TopRowInner = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const BottomRow = styled.div<{
  $bgColor: keyof DefaultTheme['color']['general']['layout'] | null;
}>`
  background-color: ${({ theme, $bgColor }) =>
    $bgColor ? theme.color.general.layout[$bgColor] : 'transparent'};
  z-index: ${({ theme }) => theme.zIndex.fixed};
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing.md}px;
  padding-top: ${({ theme }) => theme.spacing.md}px;

  ${({ theme }) =>
    useBreakpoint(theme).mobile`${css`
      padding-top: ${({ theme }) => theme.spacing.lg / 2}px;
      padding-bottom: ${({ theme }) => theme.spacing.lg / 2}px;
    `}`}
`;

export const ButtonPlaceholder = styled.div`
  display: flex;

  width: fit-content;
  min-height: 40px;
  ${({ theme }) => useBreakpoint(theme).mobile`
    min-height: 0;
  `}
`;

export const Container = styled.div<{
  $bgColor: keyof DefaultTheme['color']['general']['layout'] | null;
  $padding: keyof DefaultTheme['spacing'] | null;
}>`
  background-color: ${({ theme, $bgColor }) =>
    $bgColor ? theme.color.general.layout[$bgColor] : 'transparent'};
  padding: 0
    ${({ theme, $padding }) => ($padding ? theme.spacing[$padding] : 0)}px;
  .ant-affix {
    z-index: ${({ theme }) => theme.zIndex.fixed};
  }
  // skeleton
  h2 {
    flex: 1;
  }

  .ant-skeleton-paragraph {
    margin-bottom: 0;
    height: ${({ theme }) => theme.typography.height.lg}px;

    ${({ theme }) =>
      useBreakpoint(theme).mobile`${css`
        height: ${({ theme }) => theme.typography.height.md}px;
      `}`}
  }
`;

export const SlimContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    ${({ theme }) => useBreakpoint(theme).mobile`
        display: block;
      `}
    // fine-tuning vertical alignment
      .ant-breadcrumb {
      line-height: ${theme.typography.height.sm}px;

      ${() => useBreakpoint(theme).mobile`
          line-height: ${theme.typography.height.xs}px
        `}
    }

    ${BottomRow} {
      background-color: transparent;
      margin-left: ${theme.spacing.md}px;
      padding-top: 0;
      padding-bottom: 0;

      ${() => useBreakpoint(theme).mobile`
          margin-left: 0;
          padding-top: 0;
          padding-bottom: ${theme.spacing.lg / 2}px;
        `}
    }

    ${TopRow} {
      background-color: transparent;
    }

    // if there are two rows in slim page header cancel min-height to make it slimmer
    ${TopRow}:not(:only-child) {
      min-height: auto;
      margin-bottom: 0;
      padding-bottom: ${theme.spacing.lg / 2}px;
    }
  `}
`;

export const TitleWrapper = styled.div<{
  $offset?: number;
  $labelPosition?: 'left' | 'right' | 'far-right';
}>`
  display: flex;
  align-items: center;
  flex-direction: ${({ $labelPosition = 'left' }) =>
    $labelPosition === 'left' ? 'row' : 'row-reverse'};

  ${({ $labelPosition }) =>
    $labelPosition === 'far-right' &&
    css`
      width: 100%;
      justify-content: space-between;
    `}

  ${({ $offset }) =>
    !!$offset &&
    css`
      max-width: calc(100% - ${$offset}px);
    `}
`;

export const PageHeaderLabelWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.sm}px;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}px;
`;
