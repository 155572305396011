import { Progress as ProgressBase } from '@npm/core/ui/components/atoms/Progress';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`;

export const FileItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md}px;
  flex-wrap: wrap;
  ${({ theme }) => useBreakpoint(theme).mobile`
      width: auto;
  `}

  & > *:first-child {
    min-width: 200px;
    max-width: 480px;
    min-height: 66px;
    align-items: center;
    flex: 1;
    ${({ theme }) => useBreakpoint(theme).mobile`
      width: 100%;
      max-width: none;
      padding-right: ${theme.spacing.sm}px;
    `}
  }
`;

export const Rest = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
     justify-content: space-between;
     gap: ${theme.spacing.sm}px;
  `}

  & > *:last-child {
    flex-shrink: 0;
  }
`;

export const SwitchesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}px;
  ${({ theme }) => useBreakpoint(theme).mobile`
     gap: ${theme.spacing.lg / 2}px;
  `}
`;

export const Progress = styled(ProgressBase)`
  padding-top: ${({ theme }) => theme.spacing.sm}px;
`;
