import React, { useCallback, useEffect, useMemo } from 'react';

import { type InputProps } from 'antd';
import type { ValidateStatus } from 'antd/es/form/FormItem';

import { useAfterViewUpdate } from '../../../hooks/useAfterViewUpdate';
import { Form } from '../Form';
import { Input } from '../Input';

type FormattableInputProps = Omit<InputProps, 'value'> & {
  value?: string | null;
  // prefix at which the caret should start
  prefix?: string;
  // value formatter
  formatter?: (val: string) => string;
  // parser that will return the final value
  parser?: (val: string) => string;
  onChange?: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onValidationStatusChange?: (status: ValidateStatus) => void;
};

export const FormattableInput: React.FC<FormattableInputProps> = ({
  onChange,
  onValidationStatusChange,
  value: externalValue,
  prefix,
  parser,
  formatter,
  onFocus,
  onBlur,
  ...props
}) => {
  const { status } = Form.Item.useStatus();
  const afterUpdate = useAfterViewUpdate();
  const formatData = useMemo(
    () => (formatter ? val => formatter(val) : val => val),
    [formatter]
  );
  const [internalValue, setInternalValue] = React.useState(
    formatData(externalValue)
  );

  useEffect(() => {
    if (status) {
      onValidationStatusChange?.(status);
    }
  }, [status]);

  useEffect(() => {
    setInternalValue(formatData(externalValue));
  }, [externalValue, formatData]);

  const handleCaret = useCallback(
    (target: EventTarget & HTMLInputElement) => {
      const caret = target.selectionStart;
      if (
        prefix &&
        target.value.includes(prefix) &&
        caret != null &&
        caret < target.value.length
      ) {
        afterUpdate(() => {
          target.selectionStart = caret;
          target.selectionEnd = caret;
        });
      }
    },
    [prefix, afterUpdate]
  );

  const change = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const input = target.value;
      setInternalValue(input);
      if (parser) {
        onChange?.(parser(formatData(input)));
      } else {
        onChange?.(formatData(input));
      }
      handleCaret(target);
    },
    [onChange, formatData, parser, handleCaret]
  );

  return (
    <Input
      {...props}
      onChange={change}
      placeholder={props.placeholder}
      value={internalValue}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
