import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { PercentageChange } from '@npm/core/ui/components/molecules/PercentageChange';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
} from '@npm/core/ui/components/molecules/Table';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { TwoRowsCell } from '@npm/core/ui/components/molecules/TwoRowsCell';
import { formatDate, getTimezoneShort } from '@npm/core/ui/utils/formatters';
import {
  CbOrderBuySell,
  CbOrderEntryType,
  type CodebookItem,
  type SecondmarketOrderItemShow,
  type SubmissionStructureCode,
} from '@npm/data-access';
import { type useTheme } from 'styled-components';

import { AccountName } from '../../../../account/components/AccountName';
import { OrderSizeDisplay } from '../../../../order';
import {
  getExpirationDate,
  getStructurePreview,
} from '../../../../order/OrderData';
import {
  AffiliateDisclosureToolTipLabel,
  LastRoundCell,
  VisibilityCell,
} from '../../../components';
import { SideColumnTooltipContent } from '../../../components/SideColumnTooltipContent';
import {
  affiliateDisclosureToolTipText,
  getVisibilityTooltip,
  orderTypeTooltip,
} from '../../../utils/tooltips';

import {
  BrokerMarketOrderActions,
  type MarketOrderActionsProps,
} from './components/BrokerMarketOrderActions';
import { InvestorMarketOrderActions } from './components/InvestorMarketOrderActions';

type CommonConfig = {
  title?: string | React.ReactElement;
  titleInfo?: string | React.ReactElement;
  key: string;
  render: (record: SecondmarketOrderItemShow) => React.ReactNode;
  cardConfig?: Partial<TableVerticalRowType<SecondmarketOrderItemShow>>;
  tableConfig?: Partial<ExtendedColumnType<SecondmarketOrderItemShow>>;
}[];

export const getMarketOrdersListConfig = ({
  isReadOnlyBroker,
  isInvestor,
  isBrokerAccountManager,
  sponsorshipType,
  actionsConfig,
  isCompanyCentric,
}: {
  isReadOnlyBroker: boolean;
  isInvestor: boolean;
  isBrokerAccountManager: boolean;
  sponsorshipType?: CodebookItem;
  canAccessAtsI?: boolean;
  isCompanyCentric?: boolean;
  actionsConfig?: Omit<MarketOrderActionsProps, 'orderItem'>;
  theme: ReturnType<typeof useTheme>;
}): {
  cardRows: TableVerticalRowType<SecondmarketOrderItemShow>[];
  tableColumns: ExtendedColumnType<SecondmarketOrderItemShow>[];
} => {
  const config: CommonConfig = [
    {
      title: 'Company',
      key: 'company',
      render: ({ issuer_entity }) => (
        <CompanyLogo
          url={issuer_entity?.logo_url}
          name={issuer_entity?.name}
          linkProps={{
            companyId: issuer_entity?.id,
            afterPrefixPath: isCompanyCentric
              ? '/company-data'
              : '/market-feed',
            variant: isCompanyCentric ? 'logo' : 'icon',
            iconVariant: isCompanyCentric ? 'external-link' : 'chevron-right',
          }}
        />
      ),
      cardConfig: {
        hidden: true,
      },
    },
    {
      title: 'Side',
      key: 'side',
      render: ({ order_side, account }) =>
        CbOrderBuySell.getLabel(
          CbLabel,
          order_side,
          isInvestor
            ? !!account
            : account?.brokerage_firm?.id === actionsConfig?.brokerageFirmId
        ),
      titleInfo: <SideColumnTooltipContent />,
      cardConfig: {
        hidden: true,
      },
    },
    {
      title: 'Asset Type',
      key: 'asset_type',
      render: ({ asset, order }) => (
        <TwoRowsCell
          firstRow={
            <Text size="sm" style={{ whiteSpace: 'nowrap' }}>
              {asset?.type?.name}
            </Text>
          }
          secondRowText={getStructurePreview({
            structure: order?.structure?.code as SubmissionStructureCode,
            hasSpvStructureFee: order?.has_spv_structure_fee,
            spvManagementFee: order?.spv_management_fee,
            spvCarriedInterestFee: order?.spv_carried_interest_fee,
            hasNonSpvStructureFee: order?.has_non_spv_structure_fee,
            structurePercentageFee: order?.structure_percentage_fee,
            structureCodebooks: [order?.structure],
          })}
        />
      ),
      titleInfo:
        'Share Class and Structure (Direct vs. SPV). For any SPV positions, applicable terms such as management fee, carried interest and layer of the SPV are also displayed',
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.TABLET,
      },
    },
    {
      title: 'Size',
      key: 'size',
      render: orderItem => <OrderSizeDisplay orderItem={orderItem} compact />,
      cardConfig: {
        hidden: true,
      },
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.TABLET,
        align: 'right',
      },
    },
    {
      title: 'PPS',
      key: 'pps',
      render: ({
        price,
        pct_premium_to_preferred_price,
        implied_valuation,
      }) => (
        <Flex direction="column" align={'flex-end'}>
          <Flex align="center">
            <Text.Price value={price} formatOptions={{ long: true }} />
            {!!pct_premium_to_preferred_price && (
              <PercentageChange
                change={Math.round(pct_premium_to_preferred_price)}
              />
            )}
          </Flex>
          {!!implied_valuation && (
            <Text size="xs" colorVariant="tertiary" as="span">
              Implied Val.{' '}
              <Text.Price size="xs" value={implied_valuation} compact />
            </Text>
          )}
        </Flex>
      ),
      titleInfo:
        'Price Per Share, Discount/Premium to Last Round (LR), and Implied Valuation',
      cardConfig: {
        hidden: true,
      },
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.TABLET_SM,
        align: 'right',
      },
    },
    {
      key: 'implied_valuation',
      title: 'Implied Valuation',
      render: ({ implied_valuation }) => (
        <Text.Price value={implied_valuation} compact />
      ),
      tableConfig: {
        hidden: true,
      },
    },
    {
      title: 'Inventory Type',
      key: 'type',
      render: ({ order_type }) =>
        CbOrderEntryType.getLabel(CbLabel, order_type),
      titleInfo: orderTypeTooltip,
      cardConfig: {
        hidden: true,
      },
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.DESKTOP_SM,
      },
    },
    {
      title: 'Date / Visibility',
      key: 'visibility',
      render: item => <VisibilityCell item={item} />,
      titleInfo: getVisibilityTooltip({
        isSponsoredInvestor: isInvestor && !!sponsorshipType,
      }),
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.DESKTOP,
        width: 120,
      },
    },
    {
      title: 'Last Round (LR)',
      key: 'latest_valuation',
      titleInfo:
        'Valuation and Price Per Share of the last Preferred or publicly known tender offer round',
      render: ({ issuer_entity }) => (
        <LastRoundCell
          issuerStatistics={issuer_entity?.issuer_entity_statistic}
        />
      ),
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.NONE,
      },
    },
    {
      title: 'Account',
      key: 'account',
      render: ({ account }) => (
        <AccountName account={account} withoutLink={isReadOnlyBroker} />
      ),
      cardConfig: {
        collapsed: true,
      },
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.NONE,
      },
    },
    {
      title: 'Note',
      key: 'note',
      render: ({ order }) =>
        order?.note?.contents ? (
          <Tooltip title={order.note.contents} size="lg">
            <Text color="info">
              <Icon size="sm" name="message" />
            </Text>
          </Tooltip>
        ) : (
          ''
        ),
      cardConfig: {
        collapsed: true,
        hidden: isInvestor,
      },
      tableConfig: {
        title: ' ',
      },
    },
    {
      title: `Created At (${getTimezoneShort()})`,
      key: 'created_at',
      render: ({ placed_at }) => formatDate(placed_at) || EMPTY_VALUE,
      tableConfig: {
        hidden: ({ account }) =>
          account?.brokerage_firm?.id !== actionsConfig?.brokerageFirmId,
        responsive: TABLE_BREAKPOINTS.NONE,
      },
      cardConfig: {
        hidden: ({ account }) =>
          account?.brokerage_firm?.id !== actionsConfig?.brokerageFirmId,
        collapsed: true,
      },
    },
    {
      title: `Expires At (${getTimezoneShort()})`,
      key: 'expires_at',
      render: ({ expires_at, gtc }) => getExpirationDate(expires_at, gtc),
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.NONE,
      },
      cardConfig: {
        collapsed: true,
      },
    },
    {
      title: 'Data Room',
      key: 'data_room',
      render: ({ data_room }) =>
        data_room == null ? EMPTY_VALUE : data_room ? 'Yes' : 'No',
      tableConfig: {
        hidden: ({ order_side }) =>
          order_side?.code !== CbOrderBuySell.items.sell,
        responsive: TABLE_BREAKPOINTS.NONE,
      },
      cardConfig: {
        hidden: ({ order_side }) =>
          order_side?.code !== CbOrderBuySell.items.sell,
        collapsed: true,
      },
    },
    {
      title: 'ROFR',
      key: 'rofr',
      render: ({ rofr }) => rofr?.name ?? EMPTY_VALUE,
      tableConfig: {
        hidden: ({ order_side }) =>
          order_side?.code !== CbOrderBuySell.items.sell,
        responsive: TABLE_BREAKPOINTS.NONE,
      },
      cardConfig: {
        hidden: ({ order_side }) =>
          order_side?.code !== CbOrderBuySell.items.sell,
        collapsed: true,
      },
    },
    {
      title: 'Order is on behalf of company insider or affiliate',
      key: 'affiliate_disclosure',
      titleInfo: affiliateDisclosureToolTipText,
      render: ({ affiliate_disclosure }) =>
        affiliate_disclosure?.name && (
          <AffiliateDisclosureToolTipLabel label={affiliate_disclosure.name} />
        ),
      cardConfig: {
        hidden: ({ affiliate_disclosure }) => {
          return typeof affiliate_disclosure === 'undefined';
        },
      },
      tableConfig: {
        responsive: TABLE_BREAKPOINTS.NONE,
        hidden: ({ affiliate_disclosure }) => {
          return typeof affiliate_disclosure === 'undefined';
        },
      },
    },
    {
      key: 'actions',
      render: orderItem => {
        if (isInvestor) {
          return (
            <InvestorMarketOrderActions
              {...actionsConfig}
              orderItem={orderItem}
              sponsorshipType={sponsorshipType}
            />
          );
        }

        return (
          <BrokerMarketOrderActions {...actionsConfig} orderItem={orderItem} />
        );
      },
      tableConfig: {
        width: 1,
        hidden: !actionsConfig || isBrokerAccountManager,
      },
      cardConfig: {
        hidden: true,
      },
    },
  ];

  return config.reduce(
    (acc, { cardConfig, tableConfig, ...common }) => {
      acc.cardRows.push({ ...common, ...cardConfig });
      acc.tableColumns.push({ ...common, ...tableConfig });
      return acc;
    },
    { cardRows: [], tableColumns: [] }
  );
};
