import { type ReactNode } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio/Radio';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import type { AccountIndex, HoldingIndex } from '@npm/data-access';

import { OptInMode } from '../OptIn.enums';

import * as S from './OptInAccountSelection.styles';
import { CopyAccount } from './CopyAccount';
import { CreateAccount } from './CreateAccount';

const imageCardTexts = [
  ' Connect with buyers + sellers on the SecondMarket trading platform',
  'See the value of your shares',
  'Free to access + low 1% fee to transact',
  'Always aligned with company equity rules and restrictions',
];

type Props = {
  accounts: AccountIndex;
  isLoadingEntities: boolean;
  optInMode: OptInMode;
  setOptinMode: (mode: OptInMode) => void;
  footer: ReactNode;
  isEntity: boolean;
  accountsData: HoldingIndex;
};

export const OptInAccountSelection = ({
  accounts,
  isLoadingEntities,
  optInMode,
  setOptinMode,
  footer,
  isEntity,
  accountsData,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoints();
  const { isDarkMode } = useDarkModeContext();

  const imageSrc = `/public/assets/optin/${
    isDarkMode ? 'dark' : 'light'
  }/optin-illustration-${isMobile || isTablet ? 'mobile' : 'desktop'}.png`;

  return (
    <>
      {(isMobile || isTablet) && (
        <S.MobileHeading>
          Join SecondMarket. NPM’s Marketplace for Private Companies
        </S.MobileHeading>
      )}

      <S.Container>
        <S.TextCard direction="column" gap="md">
          {!(isMobile || isTablet) && (
            <Heading variant="h1">
              Join SecondMarket. NPM’s Marketplace for Private Companies
            </Heading>
          )}

          {isEntity && (
            <RadioGroup
              onChange={e => setOptinMode(e.target.value)}
              value={optInMode}
            >
              <Radio
                value={OptInMode.COPY_ACCOUNT}
                label="Select existing account"
              />
              <Radio
                value={OptInMode.CREATE_ACCOUNT}
                label="Create new account"
              />
            </RadioGroup>
          )}

          {optInMode === OptInMode.COPY_ACCOUNT && (
            <CopyAccount
              isEntity={isEntity}
              accounts={accounts}
              isLoadingEntities={isLoadingEntities}
              accountsData={accountsData}
            />
          )}
          {optInMode === OptInMode.CREATE_ACCOUNT && <CreateAccount />}

          <div style={{ marginTop: 'auto' }}>{footer}</div>
        </S.TextCard>

        <S.ImageCard background="two">
          <S.ImageCardContent>
            <Flex direction="column" gap="md">
              <Heading variant="h1" color="info">
                Your Next Private Stock Opportunity Awaits
              </Heading>
              {imageCardTexts.map((text, index) => (
                <Flex gap="sm" key={index}>
                  <S.Icon name="circle-check" size="md" color="info" />
                  <Text>{text}</Text>
                </Flex>
              ))}
            </Flex>

            <S.Image src={imageSrc} alt="Opt in Illustration" />
          </S.ImageCardContent>
        </S.ImageCard>
      </S.Container>
    </>
  );
};
