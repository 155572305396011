import React, { type ReactElement } from 'react';

import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import { formatNumToAddComma } from '@npm/core/ui/utils/formatters';
import { Form, type FormItemProps } from 'antd';
import { type CollapseProps } from 'antd/lib/collapse/Collapse';
import { format as formatDate } from 'date-fns';
import { isNil, omitBy } from 'lodash';

import {
  type HoldingAssetTypeKey,
  type HoldingCreateForm,
  type HoldingFormItemConfig,
} from '../HoldingForm.types';
import {
  getFormItemConfig,
  getHoldingFormLabel,
  shouldRenderField,
} from '../HoldingForm.utils';

import * as S from './HoldingsFormItem.styles';

type Props = Omit<FormItemProps, 'name'> & {
  name: keyof HoldingCreateForm;
  assetType?: HoldingAssetTypeKey;
  collapsibleProps?: CollapseProps;
  holdingItemConfigOverride?: HoldingFormItemConfig;
};

export const HoldingsFormItem = ({
  name,
  children,
  assetType,
  collapsibleProps,
  holdingItemConfigOverride,
  ...rest
}: Props) => {
  const value = Form.useWatch(name);

  if (assetType && !shouldRenderField(assetType, name)) return null;

  const {
    label,
    tooltip,
    addOptionalSuffix,
    rules,
    placeholder,
    isInlineLabel,
    required,
  } = getFormItemConfig(name, assetType, holdingItemConfigOverride);

  const isValueDateObject =
    Object.prototype.toString.call(value) === '[object Date]';
  const isValueNumber = value !== null && !isNaN(Number(value));

  const getParsedValue = () => {
    if (isValueDateObject) {
      return formatDate(value, 'MMM dd, yyyy');
    }
    if (isValueNumber) {
      return formatNumToAddComma(value);
    }
    return value;
  };

  const formItem = (
    <S.FormItem
      name={name}
      label={
        !isInlineLabel &&
        getHoldingFormLabel(
          label,
          addOptionalSuffix,
          'react-component',
          tooltip
        )
      }
      rules={rules}
      {...rest}
    >
      {React.isValidElement(children)
        ? React.cloneElement(
            children,
            omitBy(
              {
                placeholder,
                label:
                  isInlineLabel &&
                  getHoldingFormLabel(
                    label,
                    addOptionalSuffix,
                    'react-component',
                    tooltip
                  ),
                required,
              },
              isNil
            )
          )
        : children}
    </S.FormItem>
  );

  return collapsibleProps ? (
    <>
      <CollapsibleFormSection.Item
        key={name}
        name={name}
        label={getHoldingFormLabel(label, addOptionalSuffix, 'react-component')}
        tooltipText={tooltip}
        preview={getParsedValue()}
        {...collapsibleProps}
      >
        {formItem}
      </CollapsibleFormSection.Item>
      <Divider marginBottom={0} marginTop={0} />
    </>
  ) : (
    formItem
  );
};

export const CollapsibleFormItemWrapper = ({
  children,
  ...rest
}: CollapseProps & { children: ReactElement; placeholder?: string }) =>
  React.cloneElement(children, { collapsibleProps: rest });
