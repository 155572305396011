import React from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import {
  Text,
  type TextWeight,
} from '@npm/core/ui/components/atoms/Typography';
import { Link, useLocation } from '@npm/core/ui/components/molecules/Link';
import {
  type AccountType,
  type BrokerageFirmAggregate,
  type Codebook,
} from '@npm/data-access';
import { Space } from 'antd';
import { type ColorGroup } from 'styled-components';

import { useCurrentAccount, useObo } from '../../../auth/user/role';
import { getWorkstationAccountDetailPath } from '../../../workstations/getWorkstationAccountDetailPath';

import * as S from './AccountName.styles';
import { GreenCircle } from './GreenCircle';
import { Name } from './Name';
import { RepresentativesListPopup } from './RepresentativesListPopup';

export type AccountInfo = {
  id?: number | null;
  type?: Codebook | null;
  name?: string | null;
  external_id?: string | null;
  brokerage_firm?: Partial<BrokerageFirmAggregate> | null;
  // This is to handle the case of using Subject or other types instead of Account type;
  brokerage_firm_name?: string | null;
  brokerage_firm_id?: number | null;
};

type Props = {
  account: AccountInfo;
  isActive?: boolean;
  showOboIndicator?: boolean;
  extraInfo?: React.ReactElement<Text>;
  openInNewTab?: boolean;
  withAccountType?: boolean;
  withoutLink?: boolean;
  hasSmallSpacing?: boolean;
  style?: React.CSSProperties;
  className?: string;
  nameTextWeight?: TextWeight;
  nameTextColor?: ColorGroup;
  representativesUserCount?: number;
  showBrokerage?: boolean;
};

export const AccountName = ({
  account,
  isActive = true,
  showOboIndicator = false,
  extraInfo,
  openInNewTab = false,
  withAccountType = true,
  withoutLink = false,
  hasSmallSpacing = false,
  style,
  className,
  nameTextWeight,
  nameTextColor,
  representativesUserCount,
  showBrokerage = true,
}: Props) => {
  const { id, name, external_id, type } = account || {};
  const { name: brokerageName } = account?.brokerage_firm || {
    name: account?.brokerage_firm_name,
    id: account?.brokerage_firm_id,
  };
  const location = useLocation();
  const { isOboOrAccountCentric, accountId: accountCentricId } =
    useCurrentAccount();
  const { oboAccountId } = useObo();

  const accountDetailPath = getWorkstationAccountDetailPath({
    pathname: location.pathname,
    accountId: id,
    accountType: type?.code as AccountType,
    isAccountCentric: isOboOrAccountCentric,
    accountCentricId,
  });

  const showGreenCircle = showOboIndicator && id === oboAccountId;

  const isForeignAccount = !account || !name?.length || !brokerageName?.length;
  const isActiveAccount = isActive && !isForeignAccount;

  return (
    <S.AccountLog
      $hasSmallSpacing={Boolean(extraInfo) || hasSmallSpacing}
      style={style}
      className={className}
    >
      <div>
        {isActiveAccount && accountDetailPath && !withoutLink ? (
          <Link
            {...(openInNewTab && { target: '_blank' })}
            to={accountDetailPath}
            data-dd-action-name="Account Link"
            style={{ display: 'inline-flex', alignSelf: 'flex-start' }}
          >
            <S.AccountNameWrapper data-dd-privacy="mask">
              {showGreenCircle && <GreenCircle />}
              <Name
                isActive={isActiveAccount}
                name={name}
                externalId={external_id}
                brokerageFirmName={brokerageName}
                type={type}
                data-dd-privacy="mask"
              />
            </S.AccountNameWrapper>
          </Link>
        ) : (
          <S.AccountNameWrapper
            data-dd-action-name="Account Name"
            data-dd-privacy="mask"
          >
            {showGreenCircle && <GreenCircle />}
            <Name
              isActive={nameTextColor === 'info'}
              name={name}
              externalId={external_id}
              brokerageFirmName={brokerageName}
              type={type}
              weight={nameTextWeight}
            />
          </S.AccountNameWrapper>
        )}
      </div>

      {showBrokerage && Boolean(brokerageName || external_id) && (
        <Space direction="horizontal" style={{ width: '100%', flexShrink: 0 }}>
          <Text size="xs">
            <Margin
              right="xs"
              as="span"
              style={{ wordBreak: 'break-all' }}
              data-dd-privacy="mask"
            >
              {brokerageName}
              {external_id && <> -&nbsp;{external_id}</>}
            </Margin>
            {withAccountType && type && (
              <Text size="xs" color="general" as="span">
                &#x2022;&nbsp;{type.name}
              </Text>
            )}
          </Text>
          {representativesUserCount ? (
            <RepresentativesListPopup
              count={representativesUserCount}
              accountId={account.id}
            />
          ) : null}
        </Space>
      )}

      {Boolean(extraInfo) && extraInfo}
    </S.AccountLog>
  );
};
