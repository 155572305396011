import { useEffect, useState } from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import {
  CbNotificationState,
  type NotificationApiNotificationIndexRequest,
  useNotificationIndex,
} from '@npm/data-access';
import { addMilliseconds } from 'date-fns';
import pluralize from 'pluralize';

import { useUserContextStore } from '../../auth/user/context';
import { useAreNotificationsEnabled } from '../hooks';
import { resetNotificationCache } from '../utils';

const NOTIFICATION_POLLING_INTERVAL = 10000; // in milliseconds

const QUERY_CUSTOM_KEY = 'NotificationIndex_NewNotifications';

export const useNewNotificationHandler = () => {
  const currentUser = useUserContextStore(state => state.user);
  const areNotificationsEnabled = useAreNotificationsEnabled();
  const enabled = !!currentUser && areNotificationsEnabled;

  const [variables, setVariables] =
    useState<NotificationApiNotificationIndexRequest>({
      notificationState: CbNotificationState.items.unread,
      page: 1,
      size: 0,
    });

  // Handle new notifications
  useNotificationIndex(variables, {
    queryConfig: {
      enabled: enabled && !!variables.createdAfter,
      // We use custom key to avoid race condition with the rest of the UI (Notification drawer)
      queryKey: [QUERY_CUSTOM_KEY],
    },
    onError: () => {
      // ignoring error, as we don't need to handle it
    },
    onComplete: data => {
      const notificationCount = data?.pagination?.total_records;

      if (notificationCount) {
        Notification.open({
          type: 'info',
          message: `You have ${notificationCount} new ${pluralize(
            'notification',
            notificationCount,
            false
          )}`,
        });

        void resetNotificationCache();
      }
    },
  });

  useEffect(() => {
    if (!enabled) {
      return () => void 0;
    }

    const intervalId = setInterval(() => {
      setVariables(prev => ({
        ...prev,
        // Unread notifications created within last 10s
        createdAfter: addMilliseconds(
          new Date(),
          -1 * NOTIFICATION_POLLING_INTERVAL
        ).toISOString(),
      }));
      // every minute
    }, NOTIFICATION_POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [enabled]);
};
