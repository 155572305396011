import { Flex } from '@npm/core/ui/components/atoms/common';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const StepContainer = styled(Flex).attrs({
  gap: 'sm',
})<{
  $variant: 'horizontal' | 'vertical';
}>`
  flex: 1 0 0;
  align-items: stretch;
  padding: ${({ theme }) => theme.spacing.md}px;

  ${({ $variant }) =>
    $variant === 'vertical' &&
    `
    flex-direction: column;
    padding: 0;
  `}
`;

export const IconWrap = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
  gap: 'sm',
})<{ $isCompleted?: boolean }>`
  flex-shrink: 0;
  width: 32px;
  height: 32px;

  margin-right: ${({ theme }) => theme.spacing.md}px;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background: ${({ theme, $isCompleted }) =>
    $isCompleted
      ? theme.color.info.backgrounds.primaryDefault
      : theme.color.general.layout.two};
  color: ${({ theme }) => theme.color.info.typography.primary};

  ${({ theme }) => useBreakpoint(theme).mobile`
    width: 22px;
    height: 22px;
    font-size: ${theme.typography.size.xs}px;
    margin-right: ${({ theme }) => theme.spacing.sm}px;
  `}
`;

export const Step = styled(Flex)<{
  $variant: 'horizontal' | 'vertical';
  $isCurrentStep?: boolean;
  $isCompleted?: boolean;
}>`
  flex-basis: 100%;
  padding: ${({ theme }) => theme.spacing.md}px;

  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid
    ${({ theme }) => theme.color.info.backgrounds.secondaryHover};
  background: ${({ theme }) => theme.color.info.backgrounds.secondaryDefault};

  ${({ $isCurrentStep, theme }) =>
    $isCurrentStep &&
    `border: 1px solid ${theme.color.info.borders.primary};
    box-shadow: ${theme.shadow.level1};
  `}

  ${({ $variant, $isCurrentStep, theme }) =>
    $variant === 'vertical' &&
    !$isCurrentStep &&
    `border-bottom: 1px solid ${theme.color.general.borders.primary};
  `}

  ${({ $isCompleted }) =>
    $isCompleted &&
    `border: none;
  `}

  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.sm}px;
  `}
`;
