import { useMemo } from 'react';

import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';
import { type VenusApi } from '@npm/data-access';
import type { Chart, TooltipModel } from 'chart.js';
import { eachMonthOfInterval } from 'date-fns';

import { CHART_ID } from './FinancingHistoryChart';

const getLabel = (dateString: string) => {
  return formatDate(dateString, {
    dateFormat: DATE_FORMATS.MONTH_AND_YEAR,
  });
};

const generateLabelsWithinRange = (rangeStart: Date, rangeEnd: Date) => {
  return eachMonthOfInterval({ start: rangeStart, end: rangeEnd }).map(date =>
    getLabel(date.toISOString())
  );
};

type DatasetEntry = {
  x: string;
  y: number | undefined;
  activity?: VenusApi.FinancialActivity;
};

export const prepareDataset = (
  data: VenusApi.FinancialActivity[],
  rangeStart: Date,
  rangeEnd: Date
) => {
  if (!data.length) return [];

  const entriesByLabel = data.reduce<Record<string, DatasetEntry>>(
    (index, activity) => {
      const currentDate = activity.date ? new Date(activity.date) : null;
      if (currentDate < rangeStart || currentDate > rangeEnd) {
        return index;
      }
      const label = getLabel(currentDate.toISOString());
      if (!index[label]) {
        index[label] = {
          x: getLabel(activity.date),
          y: activity.investment_amount,
          activity: activity,
        };
      }
      return index;
    },
    {}
  );

  const allLabels = generateLabelsWithinRange(rangeStart, rangeEnd);

  return allLabels.map(
    label =>
      entriesByLabel[label] || ({ x: label, y: undefined } as DatasetEntry)
  );
};

export const useMinAndMaxDate = (data: VenusApi.FinancialActivity[]) => {
  return useMemo(() => {
    if (!data.length) return [null, null];

    const dates = data.map(({ date }) => new Date(date).valueOf());

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    return [minDate, maxDate];
  }, [data]);
};

export const TOOLTIP_HEIGHT = '\n\n\n\n\n';
export const TOOLTIP_WIDTH = ' '.repeat(75);

const getTooltip = (chart: Chart) => {
  return chart.canvas.parentNode.querySelector(`#${CHART_ID}`) as HTMLElement;
};

export const showChartTooltip = (
  context: {
    chart: Chart;
    tooltip: TooltipModel<'line'>;
  },
  onIndexChanged: (index: number | undefined) => void
) => {
  const { chart, tooltip } = context;

  const tooltipEl = getTooltip(chart);

  const dataIndex = tooltip.dataPoints?.[0].dataIndex;
  const isActive = tooltip.opacity !== 0; // opacity is source of truth

  if (!isActive) {
    onIndexChanged(undefined);
    return;
  }
  onIndexChanged(dataIndex);

  tooltipEl.style.display = 'block';
  tooltipEl.style.position = 'absolute';

  let offsetLeft = (tooltip.dataPoints[0]?.element?.x ?? 0) - tooltip.width / 2;
  if (offsetLeft < 0) {
    offsetLeft = 0;
  }
  if (offsetLeft > chart.canvas.offsetWidth - tooltip.width) {
    offsetLeft = chart.canvas.offsetWidth - tooltip.width;
  }

  tooltipEl.style.left = offsetLeft + 'px';
  tooltipEl.style.top = chart.chartArea.height / 2 - tooltip.height / 2 + 'px';

  tooltipEl.style.width = tooltip.width + 'px';
  tooltipEl.style.height = tooltip.height + 'px';

  tooltipEl.style.transform = `translateX(${tooltip.x}px)`;
  tooltipEl.style.transition = 'all .1s ease';
};
