import styled from 'styled-components';

export const UploadButtons = styled.div`
  display: flex;
  margin-left: auto;
  padding-left: ${({ theme }) => theme.spacing.sm}px;
  gap: ${({ theme }) => theme.spacing.sm}px;
  && > .ant-btn {
    width: 120px;
  }
`;
