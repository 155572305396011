import styled from 'styled-components';

import { Text } from '../../atoms/Typography';

export const Container = styled.div<{
  $disablePadding?: boolean;
}>`
  width: 100%;
  height: 100%;
  padding: ${({ theme, $disablePadding }) =>
    $disablePadding ? 0 : theme.spacing.md}px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.color.general.layout.one};
  padding: ${({ theme }) => theme.spacing.sm}px;
`;

export const Message = styled(Text)`
  max-width: calc(340px + ${({ theme }) => theme.spacing.md * 2}px);
  text-align: center;
`;
