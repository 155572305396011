import { Card as BaseCard } from '@npm/core/ui/components/molecules/Card';
import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Card = styled(BaseCard)<{ $contentMaxWidth?: number }>`
  min-height: 472px;
  padding: ${({ theme }) => `${theme.spacing.xl}px ${theme.spacing.sm}px`};
  ${centerFlex}

  .card-content {
    ${centerFlex}
    text-align: center;
    flex-direction: column;
    max-width: ${({ $contentMaxWidth = 464 }) => $contentMaxWidth}px;
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    min-height: unset;
  `}
`;

export const IconWrapper = styled.div`
  ${centerFlex}
  width: 40px;
  height: 40px;
  margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
`;
