/* eslint-disable react/no-multi-comp */
import React from 'react';

import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { formatNumToAddComma, shorten } from '@npm/core/ui/utils/formatters';
import {
  CbAccountSponsorshipType,
  CbDealSyncAccessStatus,
  type CodebookShow,
  type IssuerEntityAggregate,
  useActionCounterShow,
  useNegotiationIndex,
} from '@npm/data-access';
import { NumberParam, useQueryParam } from 'use-query-params';

import { useFeatureFlags } from '../../../app/featureFlags';
import { useUserContextStore } from '../../../auth/user/context';
import {
  useCurrentWorkstation,
  useObo,
  usePremiumPlan,
} from '../../../auth/user/role';
import { useIsBrokerAccountManager } from '../../../auth/user/role/hooks/useIsBrokerAccountManager';
import { useIsBrokerObserver } from '../../../auth/user/role/hooks/useIsBrokerObserver';

import * as S from './SecondMarketTabs.styles';
import { TabsLink } from './components';

type Props = {
  selectedCompany: IssuerEntityAggregate;
};

const BrokerageTabs = ({
  companyId,
  accountId,
  selectedCompany,
}: {
  companyId: number | null;
  accountId: number | null;
} & Props) => {
  const isBrokerObserver = useIsBrokerObserver();

  const isBrokerAccountManager = useIsBrokerAccountManager();

  const tabsLinkProps = {
    extra: shorten(selectedCompany?.name, 25),
  };

  return (
    <>
      <TabsLink to="/second-market/market-feed" {...tabsLinkProps}>
        Marketplace
      </TabsLink>

      <TabsLink to="/second-market/company-data" {...tabsLinkProps}>
        Company Data
      </TabsLink>
      {/* TODO: NFE-5614 add total records badge */}
      {!isBrokerObserver && (
        <TabsLink to="/second-market/client-orders" {...tabsLinkProps}>
          Client Inventory
        </TabsLink>
      )}
      {!isBrokerAccountManager && !isBrokerObserver && (
        <TabsLink to="/second-market/negotiations" {...tabsLinkProps}>
          Client Opportunities
          <S.TotalRecordsBadge
            showZeroValue={false}
            useLoadHook={useNegotiationIndex}
            variables={{
              accountId,
              issuerEntityId: companyId,
              findActionable: true,
            }}
          />
        </TabsLink>
      )}
    </>
  );
};

const DealSyncTab = ({
  companyId,
  ...tabsLinkProps
}: {
  companyId: number | null;
} & Partial<React.ComponentProps<typeof TabsLink>>) => {
  const user = useUserContextStore(store => store.user);
  const status = user.deal_sync_access?.code;

  const isEntityNpmsInvestor = useUserContextStore(
    store => store.isEntityNpmsInvestor
  );

  const { isEnabled } = useFeatureFlags();

  return (
    isEntityNpmsInvestor &&
    isEnabled('FLOW_SCREENER') && (
      <TabsLink
        to={`/investor-workstation/dealsync${
          companyId ? `?companyId=${companyId}` : ''
        }`}
        {...tabsLinkProps}
        isDisabled={status !== CbDealSyncAccessStatus.items.ACTIVE}
        tooltip={
          status === CbDealSyncAccessStatus.items.NOT_ACTIVE
            ? 'You can request access to DealSync through Dashboard'
            : status === CbDealSyncAccessStatus.items.ACTIVATING
              ? 'A member of our team will be in touch to schedule an activation meeting'
              : undefined
        }
      >
        DealSync
      </TabsLink>
    )
  );
};

const SponsoredInvestorTabs = ({
  companyId,
  sponsorshipType,
  selectedCompany,
  variant,
}: {
  companyId: number | null;
  sponsorshipType: CodebookShow;
  variant?: 'entity' | 'individual';
} & Props) => {
  const { isMobile } = useBreakpoints();
  const { isPremiumContentUnlocked } = usePremiumPlan();
  const { isEnabled } = useFeatureFlags();

  const { data } = useActionCounterShow({
    category: ['secondmarket_order_item', 'negotiation'],
    issuerEntityId: companyId || undefined,
  });

  const tabsLinkProps = {
    extra: shorten(selectedCompany?.name, 25),
  };

  return (
    <>
      <TabsLink
        // adding the `companyId` param to the route manually is only necessary for /investor-workstation routes
        // /second-market routes preserve `companyId` automatically (this might be revisited though)
        to={`/investor-workstation/${variant === 'entity' ? 'entity' : 'individual'}-dashboard${
          companyId ? `?companyId=${companyId}` : ''
        }`}
        iconName="layout-dashboard"
      >
        Dashboard
      </TabsLink>
      <S.Divider type="vertical" colorVariant="secondary" />
      <TabsLink
        to="/second-market/sponsored-investor/market-feed"
        {...tabsLinkProps}
      >
        Marketplace
      </TabsLink>
      <TabsLink
        to="/second-market/sponsored-investor/orders"
        {...tabsLinkProps}
      >
        My Orders
        {data?.live_secondmarket_order_items > 0 && (
          <S.TabsBadge>{data.live_secondmarket_order_items}</S.TabsBadge>
        )}
      </TabsLink>
      {CbAccountSponsorshipType.isSponsoredAccountMinLvl2(sponsorshipType) && (
        <TabsLink
          to="/second-market/sponsored-investor/negotiations"
          {...tabsLinkProps}
        >
          My Opportunities
          {data?.active_negotiations > 0 && (
            <S.TabsBadge>
              {formatNumToAddComma(data.active_negotiations)}
            </S.TabsBadge>
          )}
        </TabsLink>
      )}
      <S.Divider type="vertical" colorVariant="secondary" />
      <TabsLink
        to="/second-market/sponsored-investor/company-data"
        {...tabsLinkProps}
      >
        Company Data
      </TabsLink>
      {!isMobile && isEnabled('SECTOR_PAGE') && (
        <TabsLink
          to="/investor-workstation/sector-analysis"
          isDisabled={!isPremiumContentUnlocked}
        >
          Sector Analysis
        </TabsLink>
      )}
      <DealSyncTab companyId={companyId} {...tabsLinkProps} />
    </>
  );
};

const NonSponsoredInvestorTabs = ({
  companyId,
  selectedCompany,
  variant,
}: {
  companyId: number | null;
  variant?: 'entity' | 'individual';
} & Props) => {
  const { isMobile } = useBreakpoints();
  const { isPremiumContentUnlocked } = usePremiumPlan();
  const { isEnabled } = useFeatureFlags();

  const tabsLinkProps = {
    extra: shorten(selectedCompany?.name, 25),
  };

  return (
    <>
      <TabsLink
        to={`/investor-workstation/${variant === 'entity' ? 'entity' : 'individual'}-dashboard${
          companyId ? `?companyId=${companyId}` : ''
        }`}
        iconName="layout-dashboard"
      >
        Dashboard
      </TabsLink>
      <S.Divider type="vertical" colorVariant="secondary" />
      <TabsLink
        to={`/investor-workstation/my-orders${
          companyId ? `?companyId=${companyId}` : ''
        }`}
        {...tabsLinkProps}
      >
        My Orders
      </TabsLink>
      <TabsLink
        {...tabsLinkProps}
        tooltip="We need to verify your identity before allowing you to directly respond to a standing bid/offer"
        isDisabled
        to="/"
      >
        My Opportunities
      </TabsLink>
      <TabsLink
        {...tabsLinkProps}
        tooltip="We need to verify your identity before granting access to the marketplace"
        isDisabled
        to="/"
      >
        Marketplace
      </TabsLink>
      <S.Divider type="vertical" colorVariant="secondary" />
      {companyId ? (
        <TabsLink
          to={`/investor-workstation/company-data?companyId=${companyId}`}
          {...tabsLinkProps}
        >
          Company Data
        </TabsLink>
      ) : (
        <TabsLink to={`/investor-workstation/company-data`} {...tabsLinkProps}>
          Company Data
        </TabsLink>
      )}
      {!isMobile && isEnabled('SECTOR_PAGE') && (
        <TabsLink
          to="/investor-workstation/sector-analysis"
          isDisabled={!isPremiumContentUnlocked}
        >
          Sector Analysis
        </TabsLink>
      )}
      <DealSyncTab companyId={companyId} {...tabsLinkProps} />
    </>
  );
};

export const SecondMarketTabs = (props: Props) => {
  const workstation = useCurrentWorkstation();
  const { isObo, oboAccountId: accountId } = useObo();
  const sponsorshipType = useUserContextStore(store => store.sponsorshipType);
  const [companyId] = useQueryParam('companyId', NumberParam);

  const isIndividualNpmsBuyerInvestor = useUserContextStore(
    store => store.isIndividualNpmsBuyerInvestor
  );

  const variant = isIndividualNpmsBuyerInvestor ? 'individual' : 'entity';
  const isBroker = workstation?.type?.code === 'brokerage' || isObo;

  return (
    <S.TabsContainer>
      {isBroker && (
        <BrokerageTabs companyId={companyId} accountId={accountId} {...props} />
      )}
      {!isBroker &&
        (sponsorshipType ? (
          <SponsoredInvestorTabs
            companyId={companyId}
            sponsorshipType={sponsorshipType}
            variant={variant}
            {...props}
          />
        ) : (
          <NonSponsoredInvestorTabs
            companyId={companyId}
            variant={variant}
            {...props}
          />
        ))}
    </S.TabsContainer>
  );
};
