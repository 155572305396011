import React, { type ComponentProps } from 'react';

import { Text } from '@npm/core/ui/components/atoms/Typography';
import { GetStarted as BaseGetStarted } from '@npm/core/ui/components/molecules/GetStarted';
import { useTheme } from 'styled-components';

import {
  type WithSupportTicketType,
  ZendeskContactButton,
} from '../../../../app/zendesk';
import { ONBOARDING_AGREEMENTS_TEXT_CONTENT } from '../../OnboardingAgreements.config';
import { type OnboardingAgreementType } from '../../OnboardingAgreements.types';

type Props = {
  type: OnboardingAgreementType;
  isLoading?: boolean;
} & ComponentProps<typeof BaseGetStarted> &
  WithSupportTicketType;

export const OnboardingAgreementGetStartedContent = ({
  type,
  zendeskTicketGroupId,
}: {
  type: Props['type'];
  zendeskTicketGroupId: Props['zendeskTicketGroupId'];
}) => {
  const { typography } = useTheme();

  return (
    <>
      <Text colorVariant="secondary" marginBottom="sm">
        {ONBOARDING_AGREEMENTS_TEXT_CONTENT.get_started[type]}
      </Text>
      <div style={{ fontSize: typography.size.sm }}>
        <Text size="sm">
          If you have any questions, please{' '}
          <ZendeskContactButton zendeskTicketGroupId={zendeskTicketGroupId}>
            contact us
          </ZendeskContactButton>
          .
        </Text>
      </div>
    </>
  );
};

export const OnboardingAgreementGetStarted = ({
  type,
  onGetStarted,
  isLoading,
  zendeskTicketGroupId,
}: Props) => {
  return (
    <BaseGetStarted
      onGetStarted={onGetStarted}
      title={ONBOARDING_AGREEMENTS_TEXT_CONTENT.title[type]}
      withBullets={false}
      buttonText="Sign"
      buttonIcon="redirect"
      buttonLoading={isLoading}
      content={
        <OnboardingAgreementGetStartedContent
          type={type}
          zendeskTicketGroupId={zendeskTicketGroupId}
        />
      }
    />
  );
};
