import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { BulletPoints } from '@npm/core/ui/components/molecules/BulletPoints';

import { ZendeskContactButton } from '../../app/zendesk';

type Props = {
  issuerEntityName?: string;
  isMissingPoO?: boolean;
  isMissingCertificateId?: boolean;
};

export const MissingHoldingDetailsAlert = ({
  issuerEntityName,
  isMissingPoO,
  isMissingCertificateId,
}: Props) => {
  const generateAlertDescription = () => {
    const missingFields = [];
    if (isMissingPoO) missingFields.push('Holdings Proof of Ownership');
    if (isMissingCertificateId)
      missingFields.push('Holdings Certificate ID / Grant ID');
    return (
      <Margin bottom="sm">
        <BulletPoints bulletPoints={missingFields} />
      </Margin>
    );
  };

  return (
    <Alert
      type="warning"
      icon={<Icon name="info-circle" size="sm" />}
      message="We need the following holding details before posting your order to the Marketplace."
      description={
        <>
          {generateAlertDescription()}
          <Text size={'sm'}>
            You can find your Certificates, Options Grants, and/or Awards by
            logging in to your cap-table management tool. We will then review
            your holdings prior to posting your offer
            {issuerEntityName ? ` to ${issuerEntityName} market. ` : '.'} Feel
            free to{' '}
            <ZendeskContactButton
              zendeskTicketGroupId={'online_submission_progress'}
            >
              contact us
            </ZendeskContactButton>{' '}
            if you have any questions.
          </Text>
        </>
      }
      expandable={false}
    />
  );
};
