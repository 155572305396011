import React from 'react';
import { useWizard } from 'react-use-wizard';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex, Padding } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { useNavigate } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import {
  CbAccountSponsorshipType,
  CbAccountUiMode,
  useAccountShow,
} from '@npm/data-access';
import { useTheme } from 'styled-components';

import { useUserContextStore } from '../../auth/user/context';
import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { DASHBOARD_IS_WATCHLIST_DRAWER_OPEN_QUERY_KEY } from '../../dashboard/dashboard.constants';
import { type PostOnboardingContext } from '../../postOnboarding/PostOnboarding.types';
import { getInvestorWorkstationLandingPage } from '../../workstations/workstationLandingPage.utils';
import { type OnboardingSectionConfig } from '../PostOnboarding.types';

type Props = {
  modalProps: React.ComponentProps<typeof Modal>;
  sectionsConfig: OnboardingSectionConfig[];
  accountId?: number;
};

export const RequiredForMarketplaceCompletedModal = ({
  modalProps,
  sectionsConfig,
  accountId,
}: Props) => {
  const { goToStep } = useWizard();
  const navigate = useNavigate();
  const theme = useTheme();
  const userContext = useUserContextStore();
  const role = useCurrentRole();

  const { steps } = useWizardStore((s: WizardStore<PostOnboardingContext>) => ({
    steps: s.steps,
  }));

  const { data, isFetching } = useAccountShow(
    { id: accountId?.toString() },
    {
      queryConfig: {
        enabled: modalProps.open && !!accountId,
      },
    }
  );

  const handleGoToDashboard = () => {
    const dashboardRoute = getInvestorWorkstationLandingPage(userContext, role);
    const url = new URL(dashboardRoute, window.location.origin);

    // IND sellers don't have a watchlist drawer on the dashboard
    if (data?.account_ui_mode?.code !== CbAccountUiMode.items.seller) {
      url.searchParams.set(DASHBOARD_IS_WATCHLIST_DRAWER_OPEN_QUERY_KEY, '1');
    }

    navigate(url.pathname + url.search);
  };

  const handleContinue = () => {
    const otherRequirements = sectionsConfig.find(
      ({ key }) => key === 'other_requirements'
    );

    const nextStepKey =
      otherRequirements?.items?.[0]?.substeps?.[0]?.key ??
      otherRequirements?.items?.[0]?.key;

    const nextStepIndex = steps.findIndex(({ key }) => key === nextStepKey);

    modalProps.onCancel(null);
    goToStep(nextStepIndex);
  };

  // TODO: NFE-7276 should include level 2 as well?
  const isFullMarketplaceAccess =
    CbAccountSponsorshipType.isSponsoredAccountMinLvl2(data?.sponsorship_type);
  const isSellerOnly =
    data?.account_ui_mode?.code === CbAccountUiMode.items.seller;

  return (
    <Modal
      {...modalProps}
      title={
        isFullMarketplaceAccess
          ? 'You now have access to SecondMarket'
          : 'Onboarding completed'
      }
      size="md"
      footer={null}
    >
      {isFetching ? (
        <Skeleton.Base />
      ) : (
        <Flex direction="column" gap="lg">
          <Card
            outlined={false}
            background="two"
            style={{
              borderRadius: theme.borderRadius.md + 'px',
              textAlign: 'center',
            }}
          >
            <Padding top="sm" bottom="sm">
              <Flex direction="column" align="center" gap="md">
                <Icon name="badge" size="xl" color="info" />
                <Flex direction="column" gap="sm">
                  <Heading variant="h2" as="h4">
                    {isFullMarketplaceAccess
                      ? 'Congratulations!'
                      : 'Our team received your onboarding information and your marketplace access is pending approval.'}
                  </Heading>
                  {isFullMarketplaceAccess && (
                    <Text size="sm">
                      {isSellerOnly
                        ? 'You can now sell your shares directly in the marketplace and negotiate with buyers'
                        : 'You can now access the market, post bids and offers and negotiate directly with counterparties.'}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Padding>
          </Card>
          <Flex direction="column" gap="sm" style={{ width: '100%' }}>
            <Button onClick={handleGoToDashboard} block>
              Continue to Dashboard
            </Button>
            <Button variant="text" onClick={handleContinue} block>
              Continue to Additional Requirements
            </Button>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};
