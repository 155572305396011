import { isEqual, isNil, isString } from 'lodash';

import { EMPTY_VALUE } from '../../components/atoms/Typography';

import { getCurrencySymbol } from './currency';

export const MAXIMUM_FRACTION_DIGITS = 6;
export const MAXIMUM_PRICE_FRACTION_DIGITS = 2;
export const MINIMUM_PRICE_FRACTION_DIGITS = 2;
export const MAXIMUM_LONG_PRICE_FRACTION_DIGITS = MAXIMUM_FRACTION_DIGITS;

export type NumberFormatOptions = Pick<
  Intl.NumberFormatOptions,
  'maximumFractionDigits' | 'minimumFractionDigits'
> & {
  /** Should apply to prices that were inputted by user. In that case, we want to show all digits. */
  long?: boolean;
  compact?: boolean;
};

export const formatNumToAddComma = (
  number: number | string,
  options?: NumberFormatOptions
) => {
  if (typeof number === 'undefined') {
    return '';
  }
  if (typeof number === 'string' && number.trim() === '') {
    return number;
  }
  return Number(number).toLocaleString('en-US', {
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    ...options,
  });
};

export const formatQuantity = (
  quantity: number | string | null | undefined,
  options?: NumberFormatOptions
) =>
  formatPrice(quantity, undefined, {
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    minimumFractionDigits: 0,
    ...options,
  });

export const formatRange = (
  range: [
    number | string | null | undefined,
    number | string | null | undefined,
  ],
  options?: NumberFormatOptions & {
    subFormatter?: (value: number | string | null | undefined) => string;
  }
) => {
  const subFormatter =
    options?.subFormatter ?? (value => formatQuantity(value, options));

  const [start, end] = range
    .filter(value => value != null)
    .map(Number)
    .sort((a, b) => a - b);

  if (start == null && end == null) {
    return EMPTY_VALUE;
  }
  if (start == null || end == null || start === end) {
    return subFormatter(start ?? end);
  }
  const formattedStart = subFormatter(start);
  const formattedEnd = subFormatter(end);

  return isEqual(formattedStart, formattedEnd)
    ? formattedStart
    : `${formattedStart} - ${formattedEnd}`;
};

export const formatPrice = (
  priceVal: number | string | null | undefined,
  denomination?: string,
  options?: NumberFormatOptions & { currencyAsSymbol?: boolean }
) => {
  if (priceVal === 0 || !isNil(priceVal)) {
    const priceNum = isString(priceVal) ? Number(priceVal) : priceVal;
    const priceNumAbs = Math.abs(priceNum);

    const currencySymbol =
      options?.currencyAsSymbol && denomination
        ? getCurrencySymbol(denomination)
        : undefined;

    return (
      (currencySymbol ? `${currencySymbol}` : '') +
      (priceNum < 0 ? '(' : '') +
      (options?.compact
        ? formatAsCompactNumber(priceNum)
        : formatNumToAddComma(priceNumAbs, {
            maximumFractionDigits: options?.long
              ? MAXIMUM_LONG_PRICE_FRACTION_DIGITS
              : MAXIMUM_PRICE_FRACTION_DIGITS,
            minimumFractionDigits: MINIMUM_PRICE_FRACTION_DIGITS,
            ...options,
          })) +
      (priceNum < 0 ? ')' : '') +
      (denomination && !currencySymbol ? ` ${denomination}` : '')
    );
  }
  return EMPTY_VALUE;
};

export const parseFormattedNumberWithComma = (number: string) =>
  number.replace(/\$\s?|(,*)/g, '');

export const formatABARoutingNumber = (number: string) => {
  return number
    ?.toString()
    .replace(/\D+/, '') // Deletes all non-digit characters
    .substring(0, 9); // max length
};

export const formatAsCompactNumber = (
  value: string | number | null | undefined,
  formatOptions?: NumberFormatOptions
) => {
  if (value == null) return EMPTY_VALUE;
  const getFormattedNumber = (options: Intl.NumberFormatOptions) =>
    Intl.NumberFormat('en', options).format(Number(value));

  // get number of digits without any fractions
  const initialFormat = getFormattedNumber({
    notation: 'compact',
    maximumFractionDigits: 0,
  });

  const originalNumberOfDigits = initialFormat.replace(/[^0-9]/g, '').length;

  // add number of fractions to have max 3 digits in total (e.g., 134K, 13.4K, 1.34K)
  const requiredFractionDigits = Math.max(0, 3 - originalNumberOfDigits);

  return getFormattedNumber({
    notation: 'compact',
    maximumFractionDigits: requiredFractionDigits,
    ...formatOptions,
  });
};
