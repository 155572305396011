import { type ReactNode } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useWatchlistIndex } from '@npm/data-access';

import { type WatchlistConfig } from '../useWatchlistConfig.hooks';

import { NoDataAreaWatchlist } from './components/NoDataAreaWatchlist';
import { WatchlistCards } from './components/WatchlistCards/WatchlistCards';
import {
  WATCHLIST_PAGINATION_QUERY_KEY,
  WatchlistTable,
} from './components/WatchlistTable/WatchlistTable';

import { type ActionConfigProps, type BaseConfig } from './WatchlistList.types';

type Props = BaseConfig &
  ActionConfigProps & {
    filters: ReactNode;
    config: WatchlistConfig;
    isCompanyCentric?: boolean;
    isSecondMarket?: boolean;
    tableExtra?: ReactNode;
  };

export const WatchlistList = ({
  variables,
  setVariables,
  onAddToWatchlist,
  onDeleteClick,
  onEditClick,
  isFilterApplied,
  filters,
  config,
  isCompanyCentric,
  isSecondMarket,
  tableExtra,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const { data, isLoading } = useWatchlistIndex(variables);

  const { updatePaginationVariables, pagination } = usePagination(
    WATCHLIST_PAGINATION_QUERY_KEY,
    {
      variables,
      setVariables,
      paginationData: data?.pagination,
    }
  );

  return isMobile ? (
    <>
      {filters}
      <WatchlistCards
        rows={config}
        variables={variables}
        isFilterApplied={isFilterApplied}
        onAddToWatchlist={onAddToWatchlist}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        isCompanyCentric={isCompanyCentric}
        isSecondMarket={isSecondMarket}
      />
    </>
  ) : (
    <Card variant="secondMarket" noContentPadding>
      <>
        <Flex justify="space-between" align="center">
          {filters} {tableExtra}
        </Flex>
        <WatchlistTable
          data={data}
          isLoading={isLoading}
          columns={config}
          pagination={pagination}
          updatePaginationVariables={updatePaginationVariables}
          noDataArea={
            isLoading ? undefined : (
              <NoDataAreaWatchlist
                isFilterApplied={isFilterApplied}
                onAdd={onAddToWatchlist}
              />
            )
          }
        />
      </>
    </Card>
  );
};
