import React, { useMemo, useState } from 'react';

import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { truncate } from '@npm/core/ui/utils/formatters';
import {
  CbNotificationCategory,
  CbWorkstationType,
  useUserCategoriesIndex,
} from '@npm/data-access';

import { useCurrentWorkstation } from '../../../../../auth/user/role';
import { NoPaddingButton } from '../../NotificationDrawer.styles';

type OptionType = {
  label: string;
  value: string | undefined;
};

const ALL_OPTION: OptionType = {
  label: 'All',
  value: undefined,
};

type Props = {
  onChange: (value: string | undefined) => void;
};

export const NotificationCategorySelect = ({ onChange }: Props) => {
  const [value, setValue] = useState<OptionType>({
    value: CbNotificationCategory.items.marketplace,
    label: '',
  });

  const { data, isLoading } = useUserCategoriesIndex(
    {},
    {
      onError: () => {
        // ignore error, in the worst case we will show only 'All' option
      },
      onComplete: data => {
        const marketplaceCategory = data?.user_categories?.find(
          codebook => codebook.code === CbNotificationCategory.items.marketplace
        )?.name;

        setValue(
          marketplaceCategory
            ? {
                value: CbNotificationCategory.items.marketplace,
                label: data?.user_categories?.find(
                  codebook =>
                    codebook.code === CbNotificationCategory.items.marketplace
                )?.name,
              }
            : ALL_OPTION
        );

        if (!marketplaceCategory) {
          onChange(undefined);
        }
      },
    }
  );

  const currentWorkstation = useCurrentWorkstation();
  const isBroker =
    currentWorkstation?.type?.code === CbWorkstationType.items.brokerage;

  const dropDownItems = useMemo(() => {
    const codebookItems = data?.user_categories || [];

    const items = [
      ALL_OPTION,
      ...codebookItems
        .filter(
          codebook =>
            isBroker || codebook.code !== CbNotificationCategory.items.accounts
        )
        .map(item => ({
          label: item.name,
          value: item.code,
        })),
    ] satisfies OptionType[];

    return items.map(item => {
      return {
        label: item.label,
        onClick: () => {
          setValue(item);
          onChange(item.value);
        },
      };
    });
  }, [data, isBroker, onChange]);

  return (
    <Dropdown items={dropDownItems} zIndex={'aboveAll'}>
      <NoPaddingButton
        variant="text"
        icon={<Icon name="chevron-down" />}
        size="sm"
        style={{ justifyContent: 'flex-start' }}
        loading={isLoading}
      >
        {truncate(value.label, 11)}
      </NoPaddingButton>
    </Dropdown>
  );
};
