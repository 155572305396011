import React, { type ComponentProps } from 'react';

import { DateRangePickerInput } from '@npm/core/ui/components/atoms/Input';
import type { DateRangeContract } from '@npm/data-access';
import moment from 'moment';
import { type RangeValue } from 'rc-picker/lib/interface';

import { type FilterItemProps } from '../FilterPanel';

const DATE_FORMAT = 'YYYY-MM-DD';

type Props = FilterItemProps &
  Omit<ComponentProps<typeof DateRangePickerInput>, 'onChange'> & {
    onChange?: (value: DateRangeContract | undefined) => void;
    value?: DateRangeContract;
  };

export const DateRangePickerFilter = ({
  name,
  onFilterSubmit,
  onChange,
  value,
  ...rest
}: Props) => {
  const dateValue: RangeValue<Date> = value
    ? [
        value.start ? moment(value.start).toDate() : null,
        value.end ? moment(value.end).toDate() : null,
      ]
    : null;

  return (
    <DateRangePickerInput
      value={dateValue}
      onChange={(dates: RangeValue<Date>) => {
        if (!dates) {
          onChange?.(undefined);
          onFilterSubmit?.(name);
          return;
        }

        const [startDate, endDate] = dates;
        if (!!startDate && !!endDate) {
          onChange?.({
            start: startDate
              ? moment(startDate).format(DATE_FORMAT)
              : undefined,
            end: endDate ? moment(endDate).format(DATE_FORMAT) : undefined,
          });
          onFilterSubmit?.(name);
        }
      }}
      {...rest}
    />
  );
};
