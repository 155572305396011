/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActionStatusResult } from '../model';
// @ts-ignore
import { IssuersSecuritiesMarkIndexAggregate } from '../model';
// @ts-ignore
import { Mark } from '../model';
// @ts-ignore
import { MarkCreateRequestContract } from '../model';
// @ts-ignore
import { MarkIndex } from '../model';
// @ts-ignore
import { MarkUpdateRequestContract } from '../model';
/**
 * MarksApi - axios parameter creator
 * @export
 */
export const MarksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates a mark under the given security
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {MarkCreateRequestContract} markCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkCreate: async (accountId: string, companyId: string, securityId: string, markCreateRequestContract: MarkCreateRequestContract, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('investorMarkCreate', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('investorMarkCreate', 'companyId', companyId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('investorMarkCreate', 'securityId', securityId)
            // verify required parameter 'markCreateRequestContract' is not null or undefined
            assertParamExists('investorMarkCreate', 'markCreateRequestContract', markCreateRequestContract)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"security_id"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkDelete: async (accountId: string, companyId: string, securityId: string, id: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('investorMarkDelete', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('investorMarkDelete', 'companyId', companyId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('investorMarkDelete', 'securityId', securityId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('investorMarkDelete', 'id', id)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"security_id"}}`, encodeURIComponent(String(securityId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all marks under a security
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkIndex: async (accountId: string, companyId: string, securityId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('investorMarkIndex', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('investorMarkIndex', 'companyId', companyId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('investorMarkIndex', 'securityId', securityId)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"security_id"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkShow: async (accountId: string, companyId: string, securityId: string, id: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('investorMarkShow', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('investorMarkShow', 'companyId', companyId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('investorMarkShow', 'securityId', securityId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('investorMarkShow', 'id', id)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"security_id"}}`, encodeURIComponent(String(securityId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {MarkUpdateRequestContract} markUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkUpdate: async (accountId: string, companyId: string, securityId: string, id: string, markUpdateRequestContract: MarkUpdateRequestContract, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('investorMarkUpdate', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('investorMarkUpdate', 'companyId', companyId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('investorMarkUpdate', 'securityId', securityId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('investorMarkUpdate', 'id', id)
            // verify required parameter 'markUpdateRequestContract' is not null or undefined
            assertParamExists('investorMarkUpdate', 'markUpdateRequestContract', markUpdateRequestContract)
            const localVarPath = changeUrl(`/api/investor/accounts/{account_id}/companies/{company_id}/securities/{security_id}/marks/{id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"security_id"}}`, encodeURIComponent(String(securityId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication s2s_key required
            await setApiKeyToObject(localVarHeaderParameter, "X-NPM-Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xNPMUser !== undefined && xNPMUser !== null) {
                localVarHeaderParameter['X-NPM-User'] = String(xNPMUser);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a mark under the given security
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {MarkCreateRequestContract} markCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkCreate: async (issuerId: string, securityNpmGuid: string, markCreateRequestContract: MarkCreateRequestContract, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerMarkCreate', 'issuerId', issuerId)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('issuerMarkCreate', 'securityNpmGuid', securityNpmGuid)
            // verify required parameter 'markCreateRequestContract' is not null or undefined
            assertParamExists('issuerMarkCreate', 'markCreateRequestContract', markCreateRequestContract)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"security_npm_guid"}}`, encodeURIComponent(String(securityNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkDelete: async (id: string, issuerId: string, securityNpmGuid: string, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issuerMarkDelete', 'id', id)
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerMarkDelete', 'issuerId', issuerId)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('issuerMarkDelete', 'securityNpmGuid', securityNpmGuid)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"security_npm_guid"}}`, encodeURIComponent(String(securityNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lists all marks under a security
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkIndex: async (issuerId: string, securityNpmGuid: string, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerMarkIndex', 'issuerId', issuerId)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('issuerMarkIndex', 'securityNpmGuid', securityNpmGuid)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"security_npm_guid"}}`, encodeURIComponent(String(securityNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkShow: async (id: string, issuerId: string, securityNpmGuid: string, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issuerMarkShow', 'id', id)
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerMarkShow', 'issuerId', issuerId)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('issuerMarkShow', 'securityNpmGuid', securityNpmGuid)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"security_npm_guid"}}`, encodeURIComponent(String(securityNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {MarkUpdateRequestContract} markUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkUpdate: async (id: string, issuerId: string, securityNpmGuid: string, markUpdateRequestContract: MarkUpdateRequestContract, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issuerMarkUpdate', 'id', id)
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('issuerMarkUpdate', 'issuerId', issuerId)
            // verify required parameter 'securityNpmGuid' is not null or undefined
            assertParamExists('issuerMarkUpdate', 'securityNpmGuid', securityNpmGuid)
            // verify required parameter 'markUpdateRequestContract' is not null or undefined
            assertParamExists('issuerMarkUpdate', 'markUpdateRequestContract', markUpdateRequestContract)
            const localVarPath = changeUrl(`/api/admin/issuers/{issuer_id}/securities/{security_npm_guid}/marks/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"security_npm_guid"}}`, encodeURIComponent(String(securityNpmGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarksApi - functional programming interface
 * @export
 */
export const MarksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates a mark under the given security
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {MarkCreateRequestContract} markCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorMarkCreate(accountId: string, companyId: string, securityId: string, markCreateRequestContract: MarkCreateRequestContract, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorMarkCreate(accountId, companyId, securityId, markCreateRequestContract, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorMarkDelete(accountId: string, companyId: string, securityId: string, id: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorMarkDelete(accountId, companyId, securityId, id, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all marks under a security
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorMarkIndex(accountId: string, companyId: string, securityId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorMarkIndex(accountId, companyId, securityId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorMarkShow(accountId: string, companyId: string, securityId: string, id: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorMarkShow(accountId, companyId, securityId, id, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {MarkUpdateRequestContract} markUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async investorMarkUpdate(accountId: string, companyId: string, securityId: string, id: string, markUpdateRequestContract: MarkUpdateRequestContract, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.investorMarkUpdate(accountId, companyId, securityId, id, markUpdateRequestContract, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a mark under the given security
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {MarkCreateRequestContract} markCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerMarkCreate(issuerId: string, securityNpmGuid: string, markCreateRequestContract: MarkCreateRequestContract, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerMarkCreate(issuerId, securityNpmGuid, markCreateRequestContract, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerMarkDelete(id: string, issuerId: string, securityNpmGuid: string, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionStatusResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerMarkDelete(id, issuerId, securityNpmGuid, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lists all marks under a security
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerMarkIndex(issuerId: string, securityNpmGuid: string, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuersSecuritiesMarkIndexAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerMarkIndex(issuerId, securityNpmGuid, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerMarkShow(id: string, issuerId: string, securityNpmGuid: string, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerMarkShow(id, issuerId, securityNpmGuid, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {MarkUpdateRequestContract} markUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerMarkUpdate(id: string, issuerId: string, securityNpmGuid: string, markUpdateRequestContract: MarkUpdateRequestContract, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerMarkUpdate(id, issuerId, securityNpmGuid, markUpdateRequestContract, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarksApi - factory interface
 * @export
 */
export const MarksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarksApiFp(configuration)
    return {
        /**
         * 
         * @summary creates a mark under the given security
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {MarkCreateRequestContract} markCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkCreate(accountId: string, companyId: string, securityId: string, markCreateRequestContract: MarkCreateRequestContract, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.investorMarkCreate(accountId, companyId, securityId, markCreateRequestContract, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkDelete(accountId: string, companyId: string, securityId: string, id: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.investorMarkDelete(accountId, companyId, securityId, id, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all marks under a security
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkIndex(accountId: string, companyId: string, securityId: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<MarkIndex> {
            return localVarFp.investorMarkIndex(accountId, companyId, securityId, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkShow(accountId: string, companyId: string, securityId: string, id: string, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<Mark> {
            return localVarFp.investorMarkShow(accountId, companyId, securityId, id, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the mark by id
         * @param {string} accountId 
         * @param {string} companyId 
         * @param {string} securityId 
         * @param {string} id 
         * @param {MarkUpdateRequestContract} markUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {string} [xNPMUser] 
         * @param {string} [xSubscriptionPlan] 
         * @param {string} [xNPMAuthorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        investorMarkUpdate(accountId: string, companyId: string, securityId: string, id: string, markUpdateRequestContract: MarkUpdateRequestContract, xWorkstation?: string, xNPMUser?: string, xSubscriptionPlan?: string, xNPMAuthorization?: string, options?: any): AxiosPromise<Mark> {
            return localVarFp.investorMarkUpdate(accountId, companyId, securityId, id, markUpdateRequestContract, xWorkstation, xNPMUser, xSubscriptionPlan, xNPMAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a mark under the given security
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {MarkCreateRequestContract} markCreateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkCreate(issuerId: string, securityNpmGuid: string, markCreateRequestContract: MarkCreateRequestContract, xWorkstation?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.issuerMarkCreate(issuerId, securityNpmGuid, markCreateRequestContract, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkDelete(id: string, issuerId: string, securityNpmGuid: string, xWorkstation?: string, options?: any): AxiosPromise<ActionStatusResult> {
            return localVarFp.issuerMarkDelete(id, issuerId, securityNpmGuid, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lists all marks under a security
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkIndex(issuerId: string, securityNpmGuid: string, xWorkstation?: string, options?: any): AxiosPromise<IssuersSecuritiesMarkIndexAggregate> {
            return localVarFp.issuerMarkIndex(issuerId, securityNpmGuid, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkShow(id: string, issuerId: string, securityNpmGuid: string, xWorkstation?: string, options?: any): AxiosPromise<Mark> {
            return localVarFp.issuerMarkShow(id, issuerId, securityNpmGuid, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the mark by id
         * @param {string} id 
         * @param {string} issuerId 
         * @param {string} securityNpmGuid 
         * @param {MarkUpdateRequestContract} markUpdateRequestContract 
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerMarkUpdate(id: string, issuerId: string, securityNpmGuid: string, markUpdateRequestContract: MarkUpdateRequestContract, xWorkstation?: string, options?: any): AxiosPromise<Mark> {
            return localVarFp.issuerMarkUpdate(id, issuerId, securityNpmGuid, markUpdateRequestContract, xWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for investorMarkCreate operation in MarksApi.
 * @export
 * @interface MarksApiInvestorMarkCreateRequest
 */
export interface MarksApiInvestorMarkCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly securityId: string

    /**
     * 
     * @type {MarkCreateRequestContract}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly markCreateRequestContract: MarkCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkCreate
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for investorMarkDelete operation in MarksApi.
 * @export
 * @interface MarksApiInvestorMarkDeleteRequest
 */
export interface MarksApiInvestorMarkDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly securityId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkDelete
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for investorMarkIndex operation in MarksApi.
 * @export
 * @interface MarksApiInvestorMarkIndexRequest
 */
export interface MarksApiInvestorMarkIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkIndex
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkIndex
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkIndex
     */
    readonly securityId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkIndex
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkIndex
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkIndex
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for investorMarkShow operation in MarksApi.
 * @export
 * @interface MarksApiInvestorMarkShowRequest
 */
export interface MarksApiInvestorMarkShowRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly securityId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkShow
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for investorMarkUpdate operation in MarksApi.
 * @export
 * @interface MarksApiInvestorMarkUpdateRequest
 */
export interface MarksApiInvestorMarkUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly companyId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly securityId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly id: string

    /**
     * 
     * @type {MarkUpdateRequestContract}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly markUpdateRequestContract: MarkUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly xNPMUser?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiInvestorMarkUpdate
     */
    readonly xNPMAuthorization?: string
}

/**
 * Request parameters for issuerMarkCreate operation in MarksApi.
 * @export
 * @interface MarksApiIssuerMarkCreateRequest
 */
export interface MarksApiIssuerMarkCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkCreate
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkCreate
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {MarkCreateRequestContract}
     * @memberof MarksApiIssuerMarkCreate
     */
    readonly markCreateRequestContract: MarkCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkCreate
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for issuerMarkDelete operation in MarksApi.
 * @export
 * @interface MarksApiIssuerMarkDeleteRequest
 */
export interface MarksApiIssuerMarkDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkDelete
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkDelete
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkDelete
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for issuerMarkIndex operation in MarksApi.
 * @export
 * @interface MarksApiIssuerMarkIndexRequest
 */
export interface MarksApiIssuerMarkIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkIndex
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkIndex
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkIndex
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for issuerMarkShow operation in MarksApi.
 * @export
 * @interface MarksApiIssuerMarkShowRequest
 */
export interface MarksApiIssuerMarkShowRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkShow
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkShow
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkShow
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for issuerMarkUpdate operation in MarksApi.
 * @export
 * @interface MarksApiIssuerMarkUpdateRequest
 */
export interface MarksApiIssuerMarkUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkUpdate
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkUpdate
     */
    readonly issuerId: string

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkUpdate
     */
    readonly securityNpmGuid: string

    /**
     * 
     * @type {MarkUpdateRequestContract}
     * @memberof MarksApiIssuerMarkUpdate
     */
    readonly markUpdateRequestContract: MarkUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MarksApiIssuerMarkUpdate
     */
    readonly xWorkstation?: string
}

/**
 * MarksApi - object-oriented interface
 * @export
 * @class MarksApi
 * @extends {BaseAPI}
 */
export class MarksApi extends BaseAPI {
    /**
     * 
     * @summary creates a mark under the given security
     * @param {MarksApiInvestorMarkCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public investorMarkCreate(requestParameters: MarksApiInvestorMarkCreateRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).investorMarkCreate(requestParameters.accountId, requestParameters.companyId, requestParameters.securityId, requestParameters.markCreateRequestContract, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete the mark by id
     * @param {MarksApiInvestorMarkDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public investorMarkDelete(requestParameters: MarksApiInvestorMarkDeleteRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).investorMarkDelete(requestParameters.accountId, requestParameters.companyId, requestParameters.securityId, requestParameters.id, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all marks under a security
     * @param {MarksApiInvestorMarkIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public investorMarkIndex(requestParameters: MarksApiInvestorMarkIndexRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).investorMarkIndex(requestParameters.accountId, requestParameters.companyId, requestParameters.securityId, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the mark by id
     * @param {MarksApiInvestorMarkShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public investorMarkShow(requestParameters: MarksApiInvestorMarkShowRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).investorMarkShow(requestParameters.accountId, requestParameters.companyId, requestParameters.securityId, requestParameters.id, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the mark by id
     * @param {MarksApiInvestorMarkUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public investorMarkUpdate(requestParameters: MarksApiInvestorMarkUpdateRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).investorMarkUpdate(requestParameters.accountId, requestParameters.companyId, requestParameters.securityId, requestParameters.id, requestParameters.markUpdateRequestContract, requestParameters.xWorkstation, requestParameters.xNPMUser, requestParameters.xSubscriptionPlan, requestParameters.xNPMAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a mark under the given security
     * @param {MarksApiIssuerMarkCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public issuerMarkCreate(requestParameters: MarksApiIssuerMarkCreateRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).issuerMarkCreate(requestParameters.issuerId, requestParameters.securityNpmGuid, requestParameters.markCreateRequestContract, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete the mark by id
     * @param {MarksApiIssuerMarkDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public issuerMarkDelete(requestParameters: MarksApiIssuerMarkDeleteRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).issuerMarkDelete(requestParameters.id, requestParameters.issuerId, requestParameters.securityNpmGuid, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lists all marks under a security
     * @param {MarksApiIssuerMarkIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public issuerMarkIndex(requestParameters: MarksApiIssuerMarkIndexRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).issuerMarkIndex(requestParameters.issuerId, requestParameters.securityNpmGuid, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the mark by id
     * @param {MarksApiIssuerMarkShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public issuerMarkShow(requestParameters: MarksApiIssuerMarkShowRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).issuerMarkShow(requestParameters.id, requestParameters.issuerId, requestParameters.securityNpmGuid, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the mark by id
     * @param {MarksApiIssuerMarkUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarksApi
     */
    public issuerMarkUpdate(requestParameters: MarksApiIssuerMarkUpdateRequest, options?: AxiosRequestConfig) {
        return MarksApiFp(this.configuration).issuerMarkUpdate(requestParameters.id, requestParameters.issuerId, requestParameters.securityNpmGuid, requestParameters.markUpdateRequestContract, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}

export type InvestorMarkCreateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorMarkCreateErrorCodes = [
    404,
    422,
];

export type InvestorMarkDeleteErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorMarkDeleteErrorCodes = [
    404,
];

export type InvestorMarkIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorMarkIndexErrorCodes = [
];

export type InvestorMarkShowErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorMarkShowErrorCodes = [
    404,
];

export type InvestorMarkUpdateErrorTypes =

    | {
        status: 404;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const InvestorMarkUpdateErrorCodes = [
    404,
];

export type IssuerMarkCreateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerMarkCreateErrorCodes = [
    422,
];

export type IssuerMarkDeleteErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerMarkDeleteErrorCodes = [
];

export type IssuerMarkIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerMarkIndexErrorCodes = [
];

export type IssuerMarkShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerMarkShowErrorCodes = [
];

export type IssuerMarkUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerMarkUpdateErrorCodes = [
];


