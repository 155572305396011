import { Codebooks } from './codebooks.types';

const Items = {
    live_programs: 'live_programs',
    matches: 'matches',
    buyer_and_seller_personal_npms: 'buyer_and_seller_personal_npms',
    seller_personal_npms: 'seller_personal_npms',
    entity_npms: 'entity_npms',
} as const;


export const CbInvestorLandingPage = {
  code: Codebooks.INVESTOR_LANDING_PAGE,
  items: Items,
};
