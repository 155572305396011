import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useNavigate } from '@npm/core/ui/components/molecules/Link';
import type { SecondmarketOrderItem } from '@npm/data-access';

import {
  getSellIndicationQueryParam,
  useSellIndicationHoldingId,
} from '../../../../../../hooks/useSellIndicationHoldingId';
import { ExpiresOnDateTime } from '../../../../../CompanyMarket/BidsAndOffers/BidsAndOffersList/OrderCard/ExpiresOnDateTime';
import { OrderCardSkeleton } from '../../../../../CompanyMarket/BidsAndOffers/BidsAndOffersList/OrderCard/OrderCardSkeleton';
import { PriceRow } from '../../../../../CompanyMarket/BidsAndOffers/BidsAndOffersList/OrderCard/PriceRow';

import * as S from './SellIndicationOrderCard.styles';

type Props = {
  order: SecondmarketOrderItem | undefined;
};

export const SellIndicationOrderCard = ({ order }: Props) => {
  const navigate = useNavigate();

  const [selectedHoldingId] = useSellIndicationHoldingId();

  if (!order) {
    return <OrderCardSkeleton />;
  }

  const showDivider = order.order?.structure?.name && order.asset?.type?.name;
  const isNegotiating = order?.negotiations_total > 0;

  const redirectToOpportunity = () =>
    navigate(
      `/second-market/investor/opportunity/${order?.id}?${getSellIndicationQueryParam(selectedHoldingId)}`
    );

  return (
    <S.SellIndicationOrderCardWrapper>
      <Flex justify={'space-between'} align={'center'} gap={'sm'}>
        <Flex gap="xs">
          <Label variant={'success'} round>
            Buyer&apos;s Bid
          </Label>
          {isNegotiating && (
            <Label variant={'info'} round>
              You are Negotiating
            </Label>
          )}
        </Flex>

        <Flex gap={'xs'} align={'center'}>
          <Text size="sm" colorVariant="primary">
            {order.order?.structure?.name}
          </Text>

          {showDivider && <Divider type={'vertical'} />}

          <Text size="xs" colorVariant="secondary">
            {order.asset?.type?.name}
          </Text>
        </Flex>
      </Flex>
      <PriceRow orderItem={order} compact hideStructureAndAsset />
      <Flex justify={order.expires_at ? 'space-between' : 'flex-end'}>
        <ExpiresOnDateTime date={order?.expires_at} showTimeIcon />
        {isNegotiating ? (
          <Button
            size="sm"
            color="info"
            onClick={redirectToOpportunity}
            icon={<Icon name="arrows-opposite" />}
          >
            Active Opportunity ({order?.negotiations_total})
          </Button>
        ) : (
          <Button
            size="sm"
            color="error"
            onClick={redirectToOpportunity}
            icon={<Icon name={'chevron-right'} />}
            iconPosition="right"
          >
            Sell Your Shares
          </Button>
        )}
      </Flex>
    </S.SellIndicationOrderCardWrapper>
  );
};
