import { Form } from '@npm/core/ui/components/atoms/Form';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

const UPLOADERS_SECTION_WIDTH = 800;

export const StyledForm = styled(Form)`
  max-width: ${UPLOADERS_SECTION_WIDTH}px;
`;

export const UploadItemsWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  gap: ${({ theme }) => theme.spacing.md}px;

  & > * {
    flex: 1 1 auto;
    max-width: 50%;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
    flex-direction: column;

    & > * {
      max-width: 100%;
    }
  `}
`;

export const SwitchLabelText = styled.div`
  color: ${({ theme }) => theme.color.general.typography.primary};

  ${({ theme }) => useBreakpoint(theme).tablet`
    margin-left: ${theme.spacing.sm}px;
  `}
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.sm}px;
  gap: ${({ theme }) => theme.spacing.md}px;
`;

export const SwitchTooltip = styled(Tooltip)`
  display: block;
  color: ${({ theme }) => theme.color.info.typography.primary};
  width: fit-content;
  margin-top: ${({ theme }) => theme.spacing.sm}px;
`;
