import React, { type ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { ErrorSkeleton } from '@npm/core/ui/components/atoms/ErrorSkeleton';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { useLocation } from '@npm/core/ui/components/molecules/Link';
import { useNoPaddingLayout } from '@npm/core/ui/components/organisms/Layout';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useUserContextStore } from '@npm/features/auth/user/context';
import { useCurrentWorkstation, useObo } from '@npm/features/auth/user/role';
import { useIsBrokerAccountManager } from '@npm/features/auth/user/role/hooks/useIsBrokerAccountManager';
import { useIsBrokerObserver } from '@npm/features/auth/user/role/hooks/useIsBrokerObserver';
import {
  CompanySelectDrawer,
  useCompanySelect,
  useCompanySelectDrawerContext,
} from '@npm/features/second-market/company-select/CompanySelectDrawer';
import {
  OrderPlacement,
  PlaceAnOrderButton,
  useOrderPlacementDrawerContext,
} from '@npm/features/second-market/drawers/OrderEntryDrawer';
import { SecondMarketHeader } from '@npm/features/second-market/SecondMarketHeader';

import { CompanyCarousel } from './CompanyCarousel/CompanyCarousel';

import * as S from './SecondMarketContainer.styles';

type Props = {
  children: ReactNode;
};

const NPM_SM_CONTAINER_ID = 'npm-sm-container';

export const SecondMarketContainer = ({ children }: Props) => {
  const breakpoints = useBreakpoints();
  const { pathname } = useLocation();

  const workstation = useCurrentWorkstation();
  const isInvestorWS = workstation?.type?.code === 'investor';
  const { isObo } = useObo();
  const isInvestor = isInvestorWS && !isObo;
  const { isOpen, closeDrawer, toggleDrawer } = useCompanySelectDrawerContext();

  const isBrokerObserver = useIsBrokerObserver();
  const isBrokerAccountManager = useIsBrokerAccountManager();

  const isIndividualNpmsBuyerInvestor = useUserContextStore(
    store => store.isIndividualNpmsBuyerInvestor
  );

  const {
    companyId,
    setCompanyId,
    selectedCompany,
    onCompanySelect,
    isIssuerEntityLoading,
  } = useCompanySelect();

  const [{ handlePlaceOrder }, orderPlacementProps] =
    useOrderPlacementDrawerContext();

  const isLoading = isIssuerEntityLoading && companyId;

  useNoPaddingLayout();

  const showCompanySelectDrawer =
    pathname === '/second-market/market-feed' ||
    pathname === '/second-market/sponsored-investor/market-feed';

  return (
    <S.Layout id={NPM_SM_CONTAINER_ID}>
      <SecondMarketHeader
        rightControls={
          // do not pass `handlePlaceOrder` directly or the click event gets passed as `initialValues`
          !isBrokerObserver && !isBrokerAccountManager ? (
            <PlaceAnOrderButton
              onClick={() => handlePlaceOrder()}
              isIndividualNpmsBuyerInvestor={isIndividualNpmsBuyerInvestor}
            />
          ) : null
        }
        onCompanySelect={setCompanyId}
        selectedCompany={selectedCompany}
      />
      {showCompanySelectDrawer && (
        <CompanyCarousel toggleCompanySelectDrawer={toggleDrawer} />
      )}
      <Flex>
        {showCompanySelectDrawer && (
          <CompanySelectDrawer
            open={isOpen}
            onClose={closeDrawer}
            selectedCompanyId={companyId}
            selectedCompany={selectedCompany}
            onSelect={company => {
              onCompanySelect(company);
              closeDrawer();
            }}
            variant={isInvestor ? 'investor' : 'broker'}
            // due to side navigation
            getContainer={
              (breakpoints.isDesktop &&
                document.getElementById(NPM_SM_CONTAINER_ID)) ||
              undefined
            }
          />
        )}
        <S.ContentPanel>
          <ErrorBoundary FallbackComponent={ErrorSkeleton}>
            {isLoading && !selectedCompany ? <Skeleton.Base /> : children}
          </ErrorBoundary>
        </S.ContentPanel>
        <OrderPlacement
          {...orderPlacementProps}
          {...(isIndividualNpmsBuyerInvestor && {
            initialValues: { buySell: 'buy' },
            disabledFields: ['buySell'],
          })}
        />
      </Flex>
    </S.Layout>
  );
};
