import create from 'zustand';

import {
  StepState,
  type WizardStore,
  type WizardStoreAction,
  WizardStoreActionTypes,
} from './Wizard.types';

const INITIAL_STATE = {
  data: {},
  steps: [],
  activeStepState: StepState.NONE,
  isStepperMobileOpen: false,
};

const reducer = <T>(state: WizardStore<T>, args: WizardStoreAction<T>) => {
  switch (args.type) {
    case WizardStoreActionTypes.UPDATE_DATA:
      return { ...state, data: { ...state.data, ...args.payload } };

    case WizardStoreActionTypes.UPDATE_STEPS:
      return { ...state, steps: args.payload };

    case WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE:
      return { ...state, activeStepState: args.payload };

    case WizardStoreActionTypes.TOGGLE_STEPPER_MOBILE_OPEN:
      return { ...state, isStepperMobileOpen: !state.isStepperMobileOpen };

    case WizardStoreActionTypes.RESET_CONTEXT: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

const useBaseStore = create<WizardStore<{}>>(set => ({
  ...INITIAL_STATE,
  dispatch: (args: WizardStoreAction<{}>) => set(state => reducer(state, args)),
}));

export const useWizardStore = useBaseStore as {
  <T>(): WizardStore<T>;
  <T, U>(selector: (s: WizardStore<T>) => U): U;
};
