import { Codebooks } from './codebooks.types';

const Items = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
} as const;

export type BannerStatus = typeof Items[keyof typeof Items];

export const CbBannerStatus = {
  code: Codebooks.BANNER_STATUS,
  items: Items,
};
