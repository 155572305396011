// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AccreditationApi,
  BrokerageFirmAccreditationIndexErrorCodes,
  BrokerageFirmAccreditationIndexErrorTypes,
  BrokerageFirmAccreditationStatisticShowErrorCodes,
  BrokerageFirmAccreditationStatisticShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AccreditationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type BrokerageFirmAccreditationIndexMethod = AccreditationApi['brokerageFirmAccreditationIndex'];
export type BrokerageFirmAccreditationIndexResponseType = MethodResult<BrokerageFirmAccreditationIndexMethod>;

export const useBrokerageFirmAccreditationIndex = (
  variables: Parameters<BrokerageFirmAccreditationIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccreditationIndexMethod,
    BrokerageFirmAccreditationIndexErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmAccreditationIndexMethod,
    BrokerageFirmAccreditationIndexErrorTypes
  >(
    (...args) => api.brokerageFirmAccreditationIndex(...args),
    BrokerageFirmAccreditationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccreditationIndex'],
        ...apiQueryConfig.BrokerageFirmAccreditationIndex,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmAccreditationIndex',
  );
};

export const useBrokerageFirmAccreditationIndexInfinite = (
  variables: Parameters<BrokerageFirmAccreditationIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmAccreditationIndexMethod,
    BrokerageFirmAccreditationIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmAccreditationIndexMethod,
    BrokerageFirmAccreditationIndexErrorTypes
  >(
    (...args) => api.brokerageFirmAccreditationIndex(...args),
    BrokerageFirmAccreditationIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccreditationIndex', 'BrokerageFirmAccreditationIndexInfinite'],
        ...apiQueryConfig.BrokerageFirmAccreditationIndex,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmAccreditationIndex',
  );
};

export const useBrokerageFirmAccreditationIndexLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmAccreditationIndexMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccreditationIndexMethod,
    BrokerageFirmAccreditationIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmAccreditationIndexMethod,
    BrokerageFirmAccreditationIndexErrorTypes
  >(
    (...args) => api.brokerageFirmAccreditationIndex(...args),
    BrokerageFirmAccreditationIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccreditationIndex'],
        ...apiQueryConfig.BrokerageFirmAccreditationIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmAccreditationIndex',
  );
};


export type BrokerageFirmAccreditationStatisticShowMethod = AccreditationApi['brokerageFirmAccreditationStatisticShow'];
export type BrokerageFirmAccreditationStatisticShowResponseType = MethodResult<BrokerageFirmAccreditationStatisticShowMethod>;

export const useBrokerageFirmAccreditationStatisticShow = (
  variables: Parameters<BrokerageFirmAccreditationStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccreditationStatisticShowMethod,
    BrokerageFirmAccreditationStatisticShowErrorTypes
  >
) => {
  return useQuery<
    BrokerageFirmAccreditationStatisticShowMethod,
    BrokerageFirmAccreditationStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmAccreditationStatisticShow(...args),
    BrokerageFirmAccreditationStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccreditationStatisticShow'],
        ...apiQueryConfig.BrokerageFirmAccreditationStatisticShow,
        ...config?.queryConfig
      },
    },
    'BrokerageFirmAccreditationStatisticShow',
  );
};

export const useBrokerageFirmAccreditationStatisticShowInfinite = (
  variables: Parameters<BrokerageFirmAccreditationStatisticShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    BrokerageFirmAccreditationStatisticShowMethod,
    BrokerageFirmAccreditationStatisticShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    BrokerageFirmAccreditationStatisticShowMethod,
    BrokerageFirmAccreditationStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmAccreditationStatisticShow(...args),
    BrokerageFirmAccreditationStatisticShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccreditationStatisticShow', 'BrokerageFirmAccreditationStatisticShowInfinite'],
        ...apiQueryConfig.BrokerageFirmAccreditationStatisticShow,
        ...config?.queryConfig,
      },
    },
    'BrokerageFirmAccreditationStatisticShow',
  );
};

export const useBrokerageFirmAccreditationStatisticShowLazy = (baseOptions?: {
  variables?: Parameters<BrokerageFirmAccreditationStatisticShowMethod>[0],
  config?: UseQueryConfig<
    BrokerageFirmAccreditationStatisticShowMethod,
    BrokerageFirmAccreditationStatisticShowErrorTypes
  >
}) => {
  return useLazyQuery<
    BrokerageFirmAccreditationStatisticShowMethod,
    BrokerageFirmAccreditationStatisticShowErrorTypes
  >(
    (...args) => api.brokerageFirmAccreditationStatisticShow(...args),
    BrokerageFirmAccreditationStatisticShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['BrokerageFirmAccreditationStatisticShow'],
        ...apiQueryConfig.BrokerageFirmAccreditationStatisticShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'BrokerageFirmAccreditationStatisticShow',
  );
};

