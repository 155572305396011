import { Select } from '@npm/core/ui/components/atoms/Select';
import { VenusApi } from '@npm/data-access';

import { type FilterPanelItem } from '../../../filters';
import {
  DataAvailabilities,
  dataAvailabilityLabels,
} from '../CompaniesData.types';

import { type CompaniesDataFilterFormValues } from './CompaniesDataFilter.types';

const { useGicsSectors, useMotifs } = VenusApi;

type GetCompaniesDataConfigOptions = {
  sectorQuery: ReturnType<typeof useGicsSectors>;
  motifQuery: ReturnType<typeof useMotifs>;
};

export const getCompaniesDataFilters = (
  options: GetCompaniesDataConfigOptions
) =>
  [
    {
      name: 'dataAvailabilities',
      label: 'Available Data Category',
      alwaysVisible: true,
      render: props => {
        return (
          <Select
            {...props}
            allowClear
            mode="multiple"
            options={Object.values(DataAvailabilities).map(availability => ({
              label: dataAvailabilityLabels[availability],
              value: availability,
            }))}
            onChange={value => props.onFilterSubmit?.(value)}
          />
        );
      },
    },
    {
      name: 'sectorIds',
      label: 'Sectors',
      alwaysVisible: true,
      render: props => (
        <Select
          {...props}
          allowClear
          mode="multiple"
          loading={options.sectorQuery.isLoading}
          onChange={value => props.onFilterSubmit?.(value)}
          options={options.sectorQuery.data?.gics_sectors?.map(sector => ({
            label: sector.name,
            value: sector.id,
          }))}
          placeholder={`All (${options.sectorQuery.data?.gics_sectors?.length})`}
        />
      ),
    },
    {
      name: 'motifIds',
      label: 'Industry Themes',
      alwaysVisible: true,
      render: props => (
        <Select
          {...props}
          allowClear
          mode="multiple"
          loading={options.motifQuery.isLoading}
          onChange={value => props.onFilterSubmit?.(value)}
          options={options.motifQuery.data?.motifs?.map(motif => ({
            label: motif.name,
            value: motif.id,
          }))}
          placeholder={`All (${options.motifQuery.data?.motifs?.length})`}
        />
      ),
    },
  ] satisfies FilterPanelItem<CompaniesDataFilterFormValues>[];
