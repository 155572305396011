import { type Accreditation, CbAccreditationLevel } from '@npm/data-access';

import { type WithSupportTicketType } from '../../../app/zendesk';

import { FilledFormView } from './FilledFormView';

type Props = {
  data: Accreditation | undefined;
} & WithSupportTicketType;

export const IndividualAccreditationReadOnly = ({
  data,
  zendeskTicketGroupId,
}: Props) => {
  const isFailed =
    data?.level?.code === CbAccreditationLevel.items.not_available;

  if (!data) return null;

  return (
    <FilledFormView
      answers={data?.answers}
      isFailed={isFailed}
      zendeskTicketGroupId={zendeskTicketGroupId}
    />
  );
};
