import { Codebooks } from './codebooks.types';

const Items = {
  timeline: 'timeline',
  holding: 'holding',
  onboarding: 'onboarding',
} as const;

export type TradeBannerType = typeof Items[keyof typeof Items];

export const CbTradeBannerType = {
  code: Codebooks.TRADE_BANNER_TYPE,
  items: Items,
};
