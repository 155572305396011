// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  InvestmentsApi,
  InternalAllInvestmentIndexErrorCodes,
  InternalAllInvestmentIndexErrorTypes,
  InternalCreateInvestmentErrorCodes,
  InternalInvestmentIndexErrorCodes,
  InternalInvestmentIndexErrorTypes,
  InternalShowInvestmentErrorCodes,
  InternalShowInvestmentErrorTypes,
  InternalDeleteInvestmentErrorCodes,
  InternalUpdateInvestmentErrorCodes,
  CompanyInvestmentsIndexErrorCodes,
  CompanyInvestmentsIndexErrorTypes,
  InvestmentTotalFinancingErrorCodes,
  InvestmentTotalFinancingErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new InvestmentsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalAllInvestmentIndexMethod = InvestmentsApi['internalAllInvestmentIndex'];
export type InternalAllInvestmentIndexResponseType = MethodResult<InternalAllInvestmentIndexMethod>;

export const useInternalAllInvestmentIndex = (
  variables: Parameters<InternalAllInvestmentIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllInvestmentIndexMethod,
    InternalAllInvestmentIndexErrorTypes
  >
) => {
  return useQuery<
    InternalAllInvestmentIndexMethod,
    InternalAllInvestmentIndexErrorTypes
  >(
    (...args) => api.internalAllInvestmentIndex(...args),
    InternalAllInvestmentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllInvestmentIndex'],
        ...apiQueryConfig.InternalAllInvestmentIndex,
        ...config?.queryConfig
      },
    },
    'InternalAllInvestmentIndex',
  );
};

export const useInternalAllInvestmentIndexInfinite = (
  variables: Parameters<InternalAllInvestmentIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalAllInvestmentIndexMethod,
    InternalAllInvestmentIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalAllInvestmentIndexMethod,
    InternalAllInvestmentIndexErrorTypes
  >(
    (...args) => api.internalAllInvestmentIndex(...args),
    InternalAllInvestmentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllInvestmentIndex', 'InternalAllInvestmentIndexInfinite'],
        ...apiQueryConfig.InternalAllInvestmentIndex,
        ...config?.queryConfig,
      },
    },
    'InternalAllInvestmentIndex',
  );
};

export const useInternalAllInvestmentIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalAllInvestmentIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllInvestmentIndexMethod,
    InternalAllInvestmentIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalAllInvestmentIndexMethod,
    InternalAllInvestmentIndexErrorTypes
  >(
    (...args) => api.internalAllInvestmentIndex(...args),
    InternalAllInvestmentIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalAllInvestmentIndex'],
        ...apiQueryConfig.InternalAllInvestmentIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalAllInvestmentIndex',
  );
};


export type InternalCreateInvestmentMethod = InvestmentsApi['internalCreateInvestment'];

export const useInternalCreateInvestment = () => {
  return useMutation<InternalCreateInvestmentMethod>(
    (...args) => api.internalCreateInvestment(...args),
    InternalCreateInvestmentErrorCodes,
    'InternalCreateInvestment',
    '/api/internal/investments',
    'post'
  );
};

export type InternalInvestmentIndexMethod = InvestmentsApi['internalInvestmentIndex'];
export type InternalInvestmentIndexResponseType = MethodResult<InternalInvestmentIndexMethod>;

export const useInternalInvestmentIndex = (
  variables: Parameters<InternalInvestmentIndexMethod>[0],
  config?: UseQueryConfig<
    InternalInvestmentIndexMethod,
    InternalInvestmentIndexErrorTypes
  >
) => {
  return useQuery<
    InternalInvestmentIndexMethod,
    InternalInvestmentIndexErrorTypes
  >(
    (...args) => api.internalInvestmentIndex(...args),
    InternalInvestmentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestmentIndex'],
        ...apiQueryConfig.InternalInvestmentIndex,
        ...config?.queryConfig
      },
    },
    'InternalInvestmentIndex',
  );
};

export const useInternalInvestmentIndexInfinite = (
  variables: Parameters<InternalInvestmentIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalInvestmentIndexMethod,
    InternalInvestmentIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalInvestmentIndexMethod,
    InternalInvestmentIndexErrorTypes
  >(
    (...args) => api.internalInvestmentIndex(...args),
    InternalInvestmentIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalInvestmentIndex', 'InternalInvestmentIndexInfinite'],
        ...apiQueryConfig.InternalInvestmentIndex,
        ...config?.queryConfig,
      },
    },
    'InternalInvestmentIndex',
  );
};

export const useInternalInvestmentIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalInvestmentIndexMethod>[0],
  config?: UseQueryConfig<
    InternalInvestmentIndexMethod,
    InternalInvestmentIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalInvestmentIndexMethod,
    InternalInvestmentIndexErrorTypes
  >(
    (...args) => api.internalInvestmentIndex(...args),
    InternalInvestmentIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalInvestmentIndex'],
        ...apiQueryConfig.InternalInvestmentIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalInvestmentIndex',
  );
};


export type InternalShowInvestmentMethod = InvestmentsApi['internalShowInvestment'];
export type InternalShowInvestmentResponseType = MethodResult<InternalShowInvestmentMethod>;

export const useInternalShowInvestment = (
  variables: Parameters<InternalShowInvestmentMethod>[0],
  config?: UseQueryConfig<
    InternalShowInvestmentMethod,
    InternalShowInvestmentErrorTypes
  >
) => {
  return useQuery<
    InternalShowInvestmentMethod,
    InternalShowInvestmentErrorTypes
  >(
    (...args) => api.internalShowInvestment(...args),
    InternalShowInvestmentErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowInvestment'],
        ...apiQueryConfig.InternalShowInvestment,
        ...config?.queryConfig
      },
    },
    'InternalShowInvestment',
  );
};

export const useInternalShowInvestmentInfinite = (
  variables: Parameters<InternalShowInvestmentMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowInvestmentMethod,
    InternalShowInvestmentErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowInvestmentMethod,
    InternalShowInvestmentErrorTypes
  >(
    (...args) => api.internalShowInvestment(...args),
    InternalShowInvestmentErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowInvestment', 'InternalShowInvestmentInfinite'],
        ...apiQueryConfig.InternalShowInvestment,
        ...config?.queryConfig,
      },
    },
    'InternalShowInvestment',
  );
};

export const useInternalShowInvestmentLazy = (baseOptions?: {
  variables?: Parameters<InternalShowInvestmentMethod>[0],
  config?: UseQueryConfig<
    InternalShowInvestmentMethod,
    InternalShowInvestmentErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowInvestmentMethod,
    InternalShowInvestmentErrorTypes
  >(
    (...args) => api.internalShowInvestment(...args),
    InternalShowInvestmentErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowInvestment'],
        ...apiQueryConfig.InternalShowInvestment,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowInvestment',
  );
};


export type InternalDeleteInvestmentMethod = InvestmentsApi['internalDeleteInvestment'];

export const useInternalDeleteInvestment = () => {
  return useMutation<InternalDeleteInvestmentMethod>(
    (...args) => api.internalDeleteInvestment(...args),
    InternalDeleteInvestmentErrorCodes,
    'InternalDeleteInvestment',
    '/api/internal/investments/{id}',
    'delete'
  );
};

export type InternalUpdateInvestmentMethod = InvestmentsApi['internalUpdateInvestment'];

export const useInternalUpdateInvestment = () => {
  return useMutation<InternalUpdateInvestmentMethod>(
    (...args) => api.internalUpdateInvestment(...args),
    InternalUpdateInvestmentErrorCodes,
    'InternalUpdateInvestment',
    '/api/internal/investments/{id}',
    'put'
  );
};

export type CompanyInvestmentsIndexMethod = InvestmentsApi['companyInvestmentsIndex'];
export type CompanyInvestmentsIndexResponseType = MethodResult<CompanyInvestmentsIndexMethod>;

export const useCompanyInvestmentsIndex = (
  variables: Parameters<CompanyInvestmentsIndexMethod>[0],
  config?: UseQueryConfig<
    CompanyInvestmentsIndexMethod,
    CompanyInvestmentsIndexErrorTypes
  >
) => {
  return useQuery<
    CompanyInvestmentsIndexMethod,
    CompanyInvestmentsIndexErrorTypes
  >(
    (...args) => api.companyInvestmentsIndex(...args),
    CompanyInvestmentsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyInvestmentsIndex'],
        ...apiQueryConfig.CompanyInvestmentsIndex,
        ...config?.queryConfig
      },
    },
    'CompanyInvestmentsIndex',
  );
};

export const useCompanyInvestmentsIndexInfinite = (
  variables: Parameters<CompanyInvestmentsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyInvestmentsIndexMethod,
    CompanyInvestmentsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyInvestmentsIndexMethod,
    CompanyInvestmentsIndexErrorTypes
  >(
    (...args) => api.companyInvestmentsIndex(...args),
    CompanyInvestmentsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyInvestmentsIndex', 'CompanyInvestmentsIndexInfinite'],
        ...apiQueryConfig.CompanyInvestmentsIndex,
        ...config?.queryConfig,
      },
    },
    'CompanyInvestmentsIndex',
  );
};

export const useCompanyInvestmentsIndexLazy = (baseOptions?: {
  variables?: Parameters<CompanyInvestmentsIndexMethod>[0],
  config?: UseQueryConfig<
    CompanyInvestmentsIndexMethod,
    CompanyInvestmentsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyInvestmentsIndexMethod,
    CompanyInvestmentsIndexErrorTypes
  >(
    (...args) => api.companyInvestmentsIndex(...args),
    CompanyInvestmentsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyInvestmentsIndex'],
        ...apiQueryConfig.CompanyInvestmentsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyInvestmentsIndex',
  );
};


export type InvestmentTotalFinancingMethod = InvestmentsApi['investmentTotalFinancing'];
export type InvestmentTotalFinancingResponseType = MethodResult<InvestmentTotalFinancingMethod>;

export const useInvestmentTotalFinancing = (
  variables: Parameters<InvestmentTotalFinancingMethod>[0],
  config?: UseQueryConfig<
    InvestmentTotalFinancingMethod,
    InvestmentTotalFinancingErrorTypes
  >
) => {
  return useQuery<
    InvestmentTotalFinancingMethod,
    InvestmentTotalFinancingErrorTypes
  >(
    (...args) => api.investmentTotalFinancing(...args),
    InvestmentTotalFinancingErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestmentTotalFinancing'],
        ...apiQueryConfig.InvestmentTotalFinancing,
        ...config?.queryConfig
      },
    },
    'InvestmentTotalFinancing',
  );
};

export const useInvestmentTotalFinancingInfinite = (
  variables: Parameters<InvestmentTotalFinancingMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestmentTotalFinancingMethod,
    InvestmentTotalFinancingErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestmentTotalFinancingMethod,
    InvestmentTotalFinancingErrorTypes
  >(
    (...args) => api.investmentTotalFinancing(...args),
    InvestmentTotalFinancingErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestmentTotalFinancing', 'InvestmentTotalFinancingInfinite'],
        ...apiQueryConfig.InvestmentTotalFinancing,
        ...config?.queryConfig,
      },
    },
    'InvestmentTotalFinancing',
  );
};

export const useInvestmentTotalFinancingLazy = (baseOptions?: {
  variables?: Parameters<InvestmentTotalFinancingMethod>[0],
  config?: UseQueryConfig<
    InvestmentTotalFinancingMethod,
    InvestmentTotalFinancingErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestmentTotalFinancingMethod,
    InvestmentTotalFinancingErrorTypes
  >(
    (...args) => api.investmentTotalFinancing(...args),
    InvestmentTotalFinancingErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestmentTotalFinancing'],
        ...apiQueryConfig.InvestmentTotalFinancing,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestmentTotalFinancing',
  );
};

