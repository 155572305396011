import { CbRoleType } from '@npm/data-access';

import { useUserContextStore } from '../../auth/user/context';
import { useObo } from '../../auth/user/role';

const RESTRICTED_ROLES = [
  CbRoleType.items.ACCOUNT_GUEST,
  CbRoleType.items.GUEST_AND_SIGNER,
] as string[];

export const useCanRenewOrder = (orderAccountId: number | undefined) => {
  const { isObo } = useObo();
  const roles = useUserContextStore(store => store.investorAccounts);

  const currentRole = roles.find(r => r.subject.id === orderAccountId);

  const canRenewOrder =
    isObo || !RESTRICTED_ROLES.includes(currentRole?.role_name?.code);

  return { canRenewOrder };
};
