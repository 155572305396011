import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type IconNames } from '@npm/utils';
import { type ColorGroup } from 'styled-components';

import * as S from './IconBox.styles';

type Props = {
  iconName?: IconNames;
  colorVariant?: ColorGroup;
};

export const IconBox = ({
  iconName = 'badge',
  colorVariant = 'success',
}: Props) => {
  return (
    <S.IconWrapper>
      <Icon name={iconName} size="xl" color={colorVariant} />
    </S.IconWrapper>
  );
};
