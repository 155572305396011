import { Heading } from '@npm/core/ui/components/atoms/Typography';

import * as S from './PlugIcon.styles';
import { PlugLeft } from './PlugLeft';
import { PlugRight } from './PlugRight';

type Props = {
  status?: string;
};

export const PlugIcon = ({ status }: Props) => {
  return (
    <S.IconWrapper>
      <PlugLeft />
      <S.IconTextWrapper>
        {status && (
          <Heading color="general" colorVariant="secondary" variant="h1">
            {status}
          </Heading>
        )}
      </S.IconTextWrapper>
      <S.RightPlugWrapper>
        <PlugRight />
      </S.RightPlugWrapper>
    </S.IconWrapper>
  );
};
