import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme: { spacing, color } }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${spacing.sm}px;
    margin-bottom: ${spacing.lg}px;
    padding: ${spacing.md}px;
    gap: ${spacing.md}px;
    border: 1px solid ${color.info.borders.secondary};
    border-radius: ${spacing.sm}px;
  `
);

export const AggregatedHoldingFormStyles = { Container };
