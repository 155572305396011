import { CbATSPool, type CodebookItem } from '@npm/data-access';

import { Text, type TextProps } from '../Typography';

interface Props {
  codebookItem: CodebookItem | undefined;
  brokerageFirm: { name?: string } | undefined;
  textProps?: Partial<TextProps>;
}

export const ATSPoolLabel = ({
  brokerageFirm,
  codebookItem,
  textProps = {},
}: Props) => (
  <Text size="sm" colorVariant="primary" {...textProps} as="span">
    {!brokerageFirm || codebookItem?.code === CbATSPool.items.internal ? (
      codebookItem?.name
    ) : (
      <>
        {brokerageFirm.name}
        {codebookItem?.code === CbATSPool.items.ats_sm
          ? ` Investors & Brokers `
          : ` Investors Only `}
        <Text
          size="sm"
          {...textProps}
          style={{ whiteSpace: 'nowrap', ...(textProps.style ?? {}) }}
          colorVariant="secondary"
          as="span"
        >
          ({codebookItem?.name})
        </Text>
      </>
    )}
  </Text>
);
