import { Button } from '@npm/core/ui/components/atoms/Button';
import { centerSelfAbsolute } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const BlurOverlay = styled.div`
  content: '';
  position: absolute;
  backdrop-filter: blur(4px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const UnlockModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 90%;
  max-width: 400px;
  max-height: 250px;
  padding: ${({ theme }) => theme.spacing.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background-color: ${({ theme }) =>
    theme.color.general.layout.zero}E5; //90% opacity
  border-radius: ${({ theme }) => theme.borderRadius.md}px;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      margin-bottom: ${({ theme }) => theme.spacing.sm}px;
    }
  }

  svg {
    color: ${({ theme }) => theme.color.info.typography.primary};
  }

  p {
    text-align: center;
  }
`;

export const UnlockButtonWrapper = styled.div<{
  $initialWrapperHeight: number;
  $isMultiLine: boolean;
}>`
  position: relative;
  display: ${({ $isMultiLine }) => ($isMultiLine ? 'block' : 'inline-block')};
  height: ${({ $isMultiLine, $initialWrapperHeight }) =>
    `${$isMultiLine ? $initialWrapperHeight : 25}px`};
`;

export const UnlockButton = styled(Button)<{ $isMultiLine: boolean }>`
  && {
    background-color: ${({ theme }) => theme.color.transparent};
    min-width: unset;
    padding: 0;

    svg {
      margin-top: -2px;
    }

    ${({ $isMultiLine }) => $isMultiLine && centerSelfAbsolute}
  }
`;
