// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  NdaApi,
  EventNdaSingularErrorCodes,
  EventNdaSingularErrorTypes,
  NdaIndexErrorCodes,
  NdaIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new NdaApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventNdaSingularMethod = NdaApi['eventNdaSingular'];
export type EventNdaSingularResponseType = MethodResult<EventNdaSingularMethod>;

export const useEventNdaSingular = (
  variables: Parameters<EventNdaSingularMethod>[0],
  config?: UseQueryConfig<
    EventNdaSingularMethod,
    EventNdaSingularErrorTypes
  >
) => {
  return useQuery<
    EventNdaSingularMethod,
    EventNdaSingularErrorTypes
  >(
    (...args) => api.eventNdaSingular(...args),
    EventNdaSingularErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventNdaSingular'],
        ...apiQueryConfig.EventNdaSingular,
        ...config?.queryConfig
      },
    },
    'EventNdaSingular',
  );
};

export const useEventNdaSingularInfinite = (
  variables: Parameters<EventNdaSingularMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventNdaSingularMethod,
    EventNdaSingularErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventNdaSingularMethod,
    EventNdaSingularErrorTypes
  >(
    (...args) => api.eventNdaSingular(...args),
    EventNdaSingularErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventNdaSingular', 'EventNdaSingularInfinite'],
        ...apiQueryConfig.EventNdaSingular,
        ...config?.queryConfig,
      },
    },
    'EventNdaSingular',
  );
};

export const useEventNdaSingularLazy = (baseOptions?: {
  variables?: Parameters<EventNdaSingularMethod>[0],
  config?: UseQueryConfig<
    EventNdaSingularMethod,
    EventNdaSingularErrorTypes
  >
}) => {
  return useLazyQuery<
    EventNdaSingularMethod,
    EventNdaSingularErrorTypes
  >(
    (...args) => api.eventNdaSingular(...args),
    EventNdaSingularErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventNdaSingular'],
        ...apiQueryConfig.EventNdaSingular,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventNdaSingular',
  );
};


export type NdaIndexMethod = NdaApi['ndaIndex'];
export type NdaIndexResponseType = MethodResult<NdaIndexMethod>;

export const useNdaIndex = (
  variables: Parameters<NdaIndexMethod>[0],
  config?: UseQueryConfig<
    NdaIndexMethod,
    NdaIndexErrorTypes
  >
) => {
  return useQuery<
    NdaIndexMethod,
    NdaIndexErrorTypes
  >(
    (...args) => api.ndaIndex(...args),
    NdaIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NdaIndex'],
        ...apiQueryConfig.NdaIndex,
        ...config?.queryConfig
      },
    },
    'NdaIndex',
  );
};

export const useNdaIndexInfinite = (
  variables: Parameters<NdaIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    NdaIndexMethod,
    NdaIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    NdaIndexMethod,
    NdaIndexErrorTypes
  >(
    (...args) => api.ndaIndex(...args),
    NdaIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['NdaIndex', 'NdaIndexInfinite'],
        ...apiQueryConfig.NdaIndex,
        ...config?.queryConfig,
      },
    },
    'NdaIndex',
  );
};

export const useNdaIndexLazy = (baseOptions?: {
  variables?: Parameters<NdaIndexMethod>[0],
  config?: UseQueryConfig<
    NdaIndexMethod,
    NdaIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    NdaIndexMethod,
    NdaIndexErrorTypes
  >(
    (...args) => api.ndaIndex(...args),
    NdaIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['NdaIndex'],
        ...apiQueryConfig.NdaIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'NdaIndex',
  );
};

