import React, { forwardRef } from 'react';

import { type CheckboxGroupProps as AntCheckboxGroupProps } from 'antd/lib/checkbox';

import * as S from './CheckboxGroup.styles';

type Props = AntCheckboxGroupProps & { isColumn?: boolean };

export const CheckboxGroup = forwardRef<HTMLDivElement, Props>(
  function CheckboxGroup({ children, isColumn, ...rest }, ref) {
    return (
      <S.StyledCheckboxGroup {...rest} $isColumn={isColumn} ref={ref}>
        {children}
      </S.StyledCheckboxGroup>
    );
  }
);
