import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { Greeting } from '../../../auth/user/components';
import { useOnboardingStatus } from '../../dashboard.hooks';
import type { UserVariantType } from '../../dashboard.types';
import {
  getEntityStepperItems,
  getIndividualStepperItems,
  getInvestorStepperItems,
} from '../../dashboard.utils';

import { StepperItems } from './StepperItems/StepperItems';

import * as S from './OnboardingBanner.styles';

type Props = {
  accountId: number;
  handleOnClick: () => void;
  variant: UserVariantType;
  subtitle?: string;
};

export const OnboardingBanner: React.FC<Props> = ({
  accountId,
  handleOnClick,
  variant,
  subtitle,
}) => {
  const { isDarkMode } = useDarkModeContext();
  const { isMobile, isTablet, isSmDesktop } = useBreakpoints();
  const layout =
    isMobile || isTablet || isSmDesktop ? 'vertical' : 'horizontal';

  const {
    isOnboardingStarted,
    isAmlKycComplete,
    isMarketplaceAgreementComplete,
    isBuyerEligibilityComplete,
  } = useOnboardingStatus(accountId, variant);

  const getStepperItems = () => {
    switch (variant) {
      case 'individual':
        return getIndividualStepperItems({
          isOnboardingStarted,
          isAmlKycComplete,
          isMarketplaceAgreementComplete,
        });
      case 'entity':
        return getEntityStepperItems({
          isOnboardingStarted,
          isBuyerEligibilityComplete,
          isAmlKycComplete,
          isMarketplaceAgreementComplete,
        });

      case 'investor':
        return getInvestorStepperItems({
          isOnboardingStarted,
          isBuyerEligibilityComplete,
          isAmlKycComplete,
          isMarketplaceAgreementComplete,
        });
      default:
        return [];
    }
  };

  const buttonText = isOnboardingStarted
    ? 'Complete Onboarding'
    : 'Get Started';

  const onboardingButton = (
    <Button icon={<Icon name="external-link" />} onClick={handleOnClick}>
      {buttonText}
    </Button>
  );

  return (
    <S.Container $variant={layout} $isDarkMode={isDarkMode}>
      <S.GreetingWrapper $variant={layout}>
        <Margin bottom="md">
          <Greeting iconBackground={layout === 'horizontal' ? 'one' : 'two'} />
        </Margin>
        <Text
          marginBottom={layout === 'horizontal' ? 'md' : 0}
          colorVariant="secondary"
        >
          {subtitle ||
            'Complete onboarding to enable direct access to our Marketplace.'}
        </Text>
        {layout === 'horizontal' && onboardingButton}
      </S.GreetingWrapper>
      {layout === 'vertical' && onboardingButton}
      <StepperItems items={getStepperItems()} variant={layout} />
    </S.Container>
  );
};
