import styled from 'styled-components';

export const Container = styled.div<{
  $alignVertical?: 'flex-start' | 'center' | 'flex-end';
}>`
  display: flex;
  align-items: ${({ $alignVertical }) => $alignVertical || 'flex-start'};

  > span {
    margin-top: ${({ theme }) => theme.spacing.xs / 2}px;
  }
`;
