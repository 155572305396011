import { Tag as AntTag } from 'antd';
import styled, { css, type DefaultTheme } from 'styled-components';

import { centerFlex } from '../../../utils/style';
import { ExtraSmallTextStyles, SmallTextStyles } from '../Typography';

import type { ChipSize, ChipType } from './index';

export const getType = (type: ChipType, theme: DefaultTheme) => {
  return {
    regular: css`
      padding: ${theme.spacing.xs / 2}px ${theme.spacing.sm}px;
      background-color: ${theme.color.info.backgrounds.primaryDefault};
      color: ${theme.color.grey_0};
      border: none;
    `,

    logo: css`
      padding: ${theme.spacing.sm}px ${theme.spacing.lg / 2}px;
      background: ${theme.color.general.layout.zero};
      border: 1px solid ${theme.color.info.borders.primary};
      color: ${theme.color.general.typography.primary};

      > span {
        margin-left: ${theme.spacing.sm}px;
      }
    `,
  }[type];
};

export const StyledChip = styled(AntTag)<{
  $type: ChipType;
  $size: ChipSize;
  $clickable: boolean;
}>`
  &,
  &.ant-tag {
    ${centerFlex}
    ${SmallTextStyles}
    display: inline-flex;
    border-radius: ${({ theme }) => theme.spacing.xs}px;
    margin: ${({ theme }) =>
      `0px ${theme.spacing.sm}px ${theme.spacing.sm}px 0px`};
    background-clip: padding-box;
    outline: none;

    ${({ $type, theme }) => getType($type, theme)};

    ${({ $size }) =>
      $size === 'sm' &&
      `
        ${ExtraSmallTextStyles}
      `};

    // remove antd styling on click
    &::before,
    &::after {
      display: none;
    }

    .ant-tag-close-icon {
      display: inline-flex;
      margin-left: ${({ theme }) => theme.spacing.lg / 4}px;
      color: ${({
        theme: {
          color: { grey_0, general },
        },
        $type,
      }) => ($type === 'regular' ? grey_0 : general.typography.primary)};
    }

    ${({ $clickable, theme }) =>
      $clickable &&
      `
        cursor: pointer;
        transition: all .2s;

        &:hover,
        &:focus {
          background-color: ${theme.color.blue_40};
        }

        &:focus {
          border-color: ${theme.color.blue_60};
          box-shadow: 0px 1px 6px -2px rgba(0, 0, 0, 0.3);
        }
    `}
  }
`;
