import { Button } from '@npm/core/ui/components/atoms/Button';
import styled, { css } from 'styled-components';

export const ToggleButton = styled(Button)`
  && {
    padding: 0px;

    ${({ theme }) => css`
      background-color: ${theme.color.general.layout.one};

      &:not(:last-child) {
        margin-right: ${theme.spacing.xs}px;
      }
    `}}

    :active,
    :focus,
    :hover {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;
