import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Helmet as ReactHelmet } from 'react-helmet';

import { useTheme } from 'styled-components';

/* set background color for mobile devices around notch / dynamic island */
export const MetaThemeColor = () => {
  const theme = useTheme();

  return (
    <ReactHelmet>
      <meta name="theme-color" content={theme.color?.general?.layout?.one} />
    </ReactHelmet>
  );
};
