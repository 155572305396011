import { Card as BaseCard } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style/breakpoints';
import styled from 'styled-components';

export const Card = styled(BaseCard)`
  .card-content {
    align-items: start;
    padding: ${({ theme }) => theme.spacing.xl}px;

    ${({ theme }) => useBreakpoint(theme).mobile`
      text-align: center;
      align-items: center;
      padding: ${theme.spacing.md}px;
    `}
  }
`;
