import { forwardRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { CardBase, type CardBaseProps } from '../../atoms/CardBase';
import { ErrorSkeleton } from '../../atoms/ErrorSkeleton';

import { CardHeader, type HeaderProps } from './CardHeader';

export interface CardProps
  extends Omit<CardBaseProps, 'header' | 'hideContent'> {
  header?: HeaderProps;
  expandable?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  flex?: boolean;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  {
    header,
    expandable,
    isExpanded,
    setIsExpanded,
    isLoading,
    children,
    noContentPadding,
    dataCy,
    outlined,
    ...rest
  },
  ref
) {
  const [_isExpanded, _setIsExpanded] = useState<boolean>(isExpanded ?? true);
  const [hasError, setHasError] = useState(false);

  return (
    <CardBase
      {...rest}
      dataCy={dataCy}
      isLoading={isLoading}
      hideContent={!(isExpanded ?? _isExpanded)}
      outlined={outlined}
      header={
        header && (
          <CardHeader
            onChevronClick={() => (setIsExpanded ?? _setIsExpanded)(s => !s)}
            expandable={expandable}
            isExpanded={isExpanded ?? _isExpanded}
            isLoading={isLoading}
            {...header}
          />
        )
      }
      noContentPadding={hasError ? false : noContentPadding}
      ref={ref}
    >
      <ErrorBoundary
        fallbackRender={props => <ErrorSkeleton {...props} dense />}
        onError={() => setHasError(true)}
        onReset={() => setHasError(false)}
      >
        {children}
      </ErrorBoundary>
    </CardBase>
  );
});
