import React from 'react';

import { PulsingDot } from '@npm/core/ui/components/atoms/Icon';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useTheme } from 'styled-components';

import { useObo } from '../../../role';
import { SwitcherButton } from '../../SwitcherButton';
import { Label, Row, Title } from '../../SwitcherButton/SwitcherButton.styles';
import { OboDropdown } from '../OboDropdown';

type Props = {
  isCollapsed: boolean;
};

export const OboButton = ({ isCollapsed }: Props) => {
  const theme = useTheme();
  const { oboAccount } = useObo();

  return (
    <OboDropdown>
      <SwitcherButton
        size="lg"
        onClick={() => null}
        data-cy={CypressDataIds.OboModal.OboButton}
        data-dd-action-name="OBO Dropdown Button"
        isCollapsed={isCollapsed}
        icon={isCollapsed && <PulsingDot />}
        style={{
          border: `2px solid ${theme.color.success.typography.primary}`,
        }}
      >
        <Row data-dd-privacy="mask">
          <Title title={oboAccount?.name}>{oboAccount?.name}</Title>
          {oboAccount?.brokerage_firm?.name && (
            <Label title={oboAccount?.brokerage_firm?.name}>
              {oboAccount?.brokerage_firm?.name}
            </Label>
          )}
        </Row>
      </SwitcherButton>
    </OboDropdown>
  );
};
