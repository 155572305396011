import { BORDER_THICKNESS, useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { css } from 'styled-components';

export const ContainerDesktop = styled.div<{
  $showTopBorder?: boolean;
  $isFullWidth?: boolean;
}>`
  ${({ theme, $showTopBorder = true }) => css`
    background-color: ${theme.color.general.layout.one};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
    ${$showTopBorder &&
    `border-top: 1px solid ${theme.color.general.borders.primary};`};
    gap: ${({ theme }) => theme.spacing.sm}px;
  `}

  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `}
`;

export const ContainerMobile = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.sm}px;
    & > *:first-child {
      margin-top: ${theme.spacing.sm}px;
      margin-bottom: ${theme.spacing.md}px;
    }
  `}

  .card-content {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const InnerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.sm}px;

  ${({ theme }) => useBreakpoint(theme).mobile`
      padding: ${BORDER_THICKNESS};
      overflow-x: hidden;
      overflow-y: hidden;
  `}

  &&& .ant-tabs-nav {
    margin: 0;
  }
`;
