import React from 'react';

import { Button, type ButtonProps } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type CSSProperties } from 'styled-components';

type Props = {
  isWatched?: boolean;
  iconOnly?: boolean;
} & ButtonProps;

export const WatchlistButton = ({
  isWatched,
  iconOnly,
  color = 'info',
  variant = 'outline',
  size = 'md',
  loading,
  onClick,
  stopPropagation,
  ...rest
}: Props) => {
  const buttonProps: ButtonProps = isWatched
    ? {
        color,
        icon: <Icon name="star-filled" />,
        children: iconOnly ? undefined : 'Watching',
      }
    : {
        color: 'info',
        icon: <Icon name="star" />,
        children: iconOnly ? undefined : 'Watchlist',
      };

  // so the disabled cursor doesn't flash there on loading
  const loadingProps = { style: { pointerEvents: 'none' } as CSSProperties };

  return (
    <span
      onClick={e =>
        // this stops propagation to parent if Button is disabled
        stopPropagation && e.stopPropagation()
      }
    >
      <Button
        disabled={iconOnly && !!loading}
        size={size}
        iconSize={variant === 'text' ? 'sm' : 'xs'}
        onClick={e => {
          onClick?.(e);
        }}
        loading={!iconOnly && loading}
        variant={variant}
        stopPropagation={stopPropagation}
        {...(!!loading && loadingProps)}
        {...buttonProps}
        {...rest}
      />
    </span>
  );
};
