/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Venus-Service API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompaniesOverviewIndex } from '../model';
// @ts-ignore
import { CompanyOverviewSectors } from '../model';
/**
 * CompaniesOverviewsApi - axios parameter creator
 * @export
 */
export const CompaniesOverviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get companies overviews
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [companyIds] 
         * @param {Array<string>} [sectors] 
         * @param {Array<number>} [motifIds] 
         * @param {Array<string>} [dataAvailabilities] 
         * @param {string} [sortBy] 
         * @param {string} [sortDir] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesOverviews: async (xNPMAuthorization?: string, xSubscriptionPlan?: string, companyIds?: Array<string>, sectors?: Array<string>, motifIds?: Array<number>, dataAvailabilities?: Array<string>, sortBy?: string, sortDir?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/companies/overviews`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyIds) {
                localVarQueryParameter['company_ids'] = companyIds.join(COLLECTION_FORMATS.csv);
            }

            if (sectors) {
                localVarQueryParameter['sectors'] = sectors.join(COLLECTION_FORMATS.csv);
            }

            if (motifIds) {
                localVarQueryParameter['motif_ids'] = motifIds.join(COLLECTION_FORMATS.csv);
            }

            if (dataAvailabilities) {
                localVarQueryParameter['data_availabilities'] = dataAvailabilities.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort_dir'] = sortDir;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get companies sectors for index page
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesOverviewsSectors: async (xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/companies/overviews/sectors`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesOverviewsApi - functional programming interface
 * @export
 */
export const CompaniesOverviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesOverviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get companies overviews
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [companyIds] 
         * @param {Array<string>} [sectors] 
         * @param {Array<number>} [motifIds] 
         * @param {Array<string>} [dataAvailabilities] 
         * @param {string} [sortBy] 
         * @param {string} [sortDir] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesOverviews(xNPMAuthorization?: string, xSubscriptionPlan?: string, companyIds?: Array<string>, sectors?: Array<string>, motifIds?: Array<number>, dataAvailabilities?: Array<string>, sortBy?: string, sortDir?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesOverviewIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesOverviews(xNPMAuthorization, xSubscriptionPlan, companyIds, sectors, motifIds, dataAvailabilities, sortBy, sortDir, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get companies sectors for index page
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesOverviewsSectors(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyOverviewSectors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesOverviewsSectors(xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesOverviewsApi - factory interface
 * @export
 */
export const CompaniesOverviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesOverviewsApiFp(configuration)
    return {
        /**
         * 
         * @summary get companies overviews
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {Array<string>} [companyIds] 
         * @param {Array<string>} [sectors] 
         * @param {Array<number>} [motifIds] 
         * @param {Array<string>} [dataAvailabilities] 
         * @param {string} [sortBy] 
         * @param {string} [sortDir] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesOverviews(xNPMAuthorization?: string, xSubscriptionPlan?: string, companyIds?: Array<string>, sectors?: Array<string>, motifIds?: Array<number>, dataAvailabilities?: Array<string>, sortBy?: string, sortDir?: string, page?: number, size?: number, options?: any): AxiosPromise<CompaniesOverviewIndex> {
            return localVarFp.companiesOverviews(xNPMAuthorization, xSubscriptionPlan, companyIds, sectors, motifIds, dataAvailabilities, sortBy, sortDir, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get companies sectors for index page
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesOverviewsSectors(xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<CompanyOverviewSectors> {
            return localVarFp.companiesOverviewsSectors(xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companiesOverviews operation in CompaniesOverviewsApi.
 * @export
 * @interface CompaniesOverviewsApiCompaniesOverviewsRequest
 */
export interface CompaniesOverviewsApiCompaniesOverviewsRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly xSubscriptionPlan?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly companyIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly sectors?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly motifIds?: Array<number>

    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly dataAvailabilities?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly sortDir?: string

    /**
     * 
     * @type {number}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesOverviewsApiCompaniesOverviews
     */
    readonly size?: number
}

/**
 * Request parameters for companiesOverviewsSectors operation in CompaniesOverviewsApi.
 * @export
 * @interface CompaniesOverviewsApiCompaniesOverviewsSectorsRequest
 */
export interface CompaniesOverviewsApiCompaniesOverviewsSectorsRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesOverviewsApiCompaniesOverviewsSectors
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesOverviewsApiCompaniesOverviewsSectors
     */
    readonly xSubscriptionPlan?: string
}

/**
 * CompaniesOverviewsApi - object-oriented interface
 * @export
 * @class CompaniesOverviewsApi
 * @extends {BaseAPI}
 */
export class CompaniesOverviewsApi extends BaseAPI {
    /**
     * 
     * @summary get companies overviews
     * @param {CompaniesOverviewsApiCompaniesOverviewsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesOverviewsApi
     */
    public companiesOverviews(requestParameters: CompaniesOverviewsApiCompaniesOverviewsRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesOverviewsApiFp(this.configuration).companiesOverviews(requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, requestParameters.companyIds, requestParameters.sectors, requestParameters.motifIds, requestParameters.dataAvailabilities, requestParameters.sortBy, requestParameters.sortDir, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get companies sectors for index page
     * @param {CompaniesOverviewsApiCompaniesOverviewsSectorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesOverviewsApi
     */
    public companiesOverviewsSectors(requestParameters: CompaniesOverviewsApiCompaniesOverviewsSectorsRequest = {}, options?: AxiosRequestConfig) {
        return CompaniesOverviewsApiFp(this.configuration).companiesOverviewsSectors(requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type CompaniesOverviewsErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompaniesOverviewsErrorCodes = [
    401,
    422,
];

export type CompaniesOverviewsSectorsErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const CompaniesOverviewsSectorsErrorCodes = [
    401,
];


