// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  StripeTapeDCheckoutApi,
  StripeTapeDErrorCodes,
  StripeTapeDCompleteErrorCodes,
  StripeTapeDCompleteErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new StripeTapeDCheckoutApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type StripeTapeDMethod = StripeTapeDCheckoutApi['stripeTapeD'];

export const useStripeTapeD = () => {
  return useMutation<StripeTapeDMethod>(
    (...args) => api.stripeTapeD(...args),
    StripeTapeDErrorCodes,
    'StripeTapeD',
    '/api/stripe/taped-checkout',
    'post'
  );
};

export type StripeTapeDCompleteMethod = StripeTapeDCheckoutApi['stripeTapeDComplete'];
export type StripeTapeDCompleteResponseType = MethodResult<StripeTapeDCompleteMethod>;

export const useStripeTapeDComplete = (
  variables: Parameters<StripeTapeDCompleteMethod>[0],
  config?: UseQueryConfig<
    StripeTapeDCompleteMethod,
    StripeTapeDCompleteErrorTypes
  >
) => {
  return useQuery<
    StripeTapeDCompleteMethod,
    StripeTapeDCompleteErrorTypes
  >(
    (...args) => api.stripeTapeDComplete(...args),
    StripeTapeDCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StripeTapeDComplete'],
        ...apiQueryConfig.StripeTapeDComplete,
        ...config?.queryConfig
      },
    },
    'StripeTapeDComplete',
  );
};

export const useStripeTapeDCompleteInfinite = (
  variables: Parameters<StripeTapeDCompleteMethod>[0],
  config?: UseInfiniteQueryConfig<
    StripeTapeDCompleteMethod,
    StripeTapeDCompleteErrorTypes
  >
) => {
  return useInfiniteQuery<
    StripeTapeDCompleteMethod,
    StripeTapeDCompleteErrorTypes
  >(
    (...args) => api.stripeTapeDComplete(...args),
    StripeTapeDCompleteErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['StripeTapeDComplete', 'StripeTapeDCompleteInfinite'],
        ...apiQueryConfig.StripeTapeDComplete,
        ...config?.queryConfig,
      },
    },
    'StripeTapeDComplete',
  );
};

export const useStripeTapeDCompleteLazy = (baseOptions?: {
  variables?: Parameters<StripeTapeDCompleteMethod>[0],
  config?: UseQueryConfig<
    StripeTapeDCompleteMethod,
    StripeTapeDCompleteErrorTypes
  >
}) => {
  return useLazyQuery<
    StripeTapeDCompleteMethod,
    StripeTapeDCompleteErrorTypes
  >(
    (...args) => api.stripeTapeDComplete(...args),
    StripeTapeDCompleteErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['StripeTapeDComplete'],
        ...apiQueryConfig.StripeTapeDComplete,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'StripeTapeDComplete',
  );
};

