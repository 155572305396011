import { type RouteDefinition } from './router.types';
import { type brokerageWorkstationRouteKeys } from './routes.brokerageWorkstation';
import { type investorWorkstationRouteKeys } from './routes.investorWorkstation';
import { type issuerWorkstationRouteKeys } from './routes.issuerWorkstation';
import { type onboardingRouteKeys } from './routes.onboarding';
import { type portfolioImportRouteKeys } from './routes.portfolioImport';
import { type secondMarketRouteKeys } from './routes.secondMarket';

export const routes: RouteDefinition[] = [];

export type routeKeys =
  | brokerageWorkstationRouteKeys
  | investorWorkstationRouteKeys
  | issuerWorkstationRouteKeys
  | onboardingRouteKeys
  | portfolioImportRouteKeys
  | secondMarketRouteKeys;

export const registerRoutes = (routesToAdd: RouteDefinition[]) => {
  routesToAdd.forEach(route => {
    routes.push(route);
  });
};
