import React, { type ComponentProps, useCallback, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { useConfirmOnLeave } from '@npm/core/ui/components/organisms/DiscardModal';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import {
  Drawer,
  DrawerConfirmDialog,
  useDrawerConfirmDialog,
} from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  type AccountSimple,
  CbHoldingState,
  type Holding,
  type IssuerEntityAggregate,
} from '@npm/data-access';
import { Form } from 'antd';

import { usePermissions } from '../../auth/permissions/usePermissions';
import { useObo } from '../../auth/user/role';
import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { type OboDefinition } from '../../auth/user/role/userRole.types';
import { HoldingForm, useHoldingForm, usePostponedHoldingForm } from '../Form';
import {
  type HoldingCreateForm,
  type HoldingExtended,
} from '../Form/HoldingForm.types';

import * as S from './HoldingDrawer.styles';
import { DrawerTitle, InstructionsAlert } from './components';

type HoldingDrawerProps = {
  id?: number;
  focusedHolding?: HoldingExtended;
  onSuccess?: (holding?: Holding) => void;
  onReportedTradeFlowSubmit?: (
    holding: HoldingCreateForm,
    updatedHolding?: Holding
  ) => void;
  initialIssuerEntity?: IssuerEntityAggregate;
  initialAccount?: AccountSimple;
  onAddHoldingUploadFailed?: (id: number) => void;
  onAccountSwitch?: () => void;
  oboOverride?: OboDefinition;
  isCorrectionMode?: boolean;
  isCertificateNumberRequired?: boolean;
  showCompanyLogoInTitle?: boolean;
  footerAlertMessage?: string;
  title?: React.ReactNode;
  topContent?: React.ReactNode;
  confirmBeforeSubmit?: boolean;
  waitForHoldingIndexInvalidationOnUpdate?: boolean;
  showProofOfOwnershipUpload?: boolean;
} & Pick<
  ComponentProps<typeof HoldingForm>,
  | 'disabledAccountSelect'
  | 'disabledIssuerEntitySelect'
  | 'hideIssuerEntitySelect'
  | 'showAccountSectionTitle'
  | 'filterAssetTypeOptions'
> &
  Pick<ComponentProps<typeof Drawer>, 'open' | 'onClose' | 'backToRoute'>;

export const HoldingDrawer = ({
  id,
  focusedHolding,
  onClose,
  open,
  backToRoute,
  onSuccess,
  initialIssuerEntity,
  initialAccount,
  onAddHoldingUploadFailed,
  hideIssuerEntitySelect = false,
  disabledAccountSelect = Boolean(id),
  disabledIssuerEntitySelect = Boolean(id),
  showAccountSectionTitle = false,
  onAccountSwitch,
  oboOverride,
  isCorrectionMode = false,
  isCertificateNumberRequired,
  showCompanyLogoInTitle = false,
  footerAlertMessage,
  title,
  topContent,
  onReportedTradeFlowSubmit,
  filterAssetTypeOptions,
  confirmBeforeSubmit = false,
  waitForHoldingIndexInvalidationOnUpdate = false,
  showProofOfOwnershipUpload = true,
}: HoldingDrawerProps) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { canWrite, BRO_ACCOUNT_MANAGER } = usePermissions();
  const role = useCurrentRole();
  const disabled = !(canWrite || BRO_ACCOUNT_MANAGER);

  const { oboAccount } = useObo();
  const [form] = Form.useForm<HoldingCreateForm>();

  const { setHasUnsavedChanges, onCloseAttempt, discardModalProps } =
    useConfirmOnLeave('HoldingDrawer' + id);

  const { isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog } =
    useDrawerConfirmDialog();

  const handleCloseConfirm = useCallback(() => {
    onClose?.();
    form.resetFields();
    setHasUnsavedChanges(false);
    setIsSubmitDisabled(false);
  }, [form, onClose, setHasUnsavedChanges]);

  const handleUpdate = useCallback(
    (holding?: Holding) => {
      onSuccess?.(holding);
      setHasUnsavedChanges(false);
      if (holding?.id) {
        handleCloseConfirm();
      }
    },
    [onSuccess, handleCloseConfirm, setHasUnsavedChanges]
  );

  const isReportTradeFlow = !!onReportedTradeFlowSubmit;
  const isDraft =
    isReportTradeFlow &&
    (!focusedHolding || focusedHolding?.variant === 'preCreated');

  // For create and update existing holdings
  const {
    data,
    isLoading,
    isUpdating,
    submit,
    collapsibleSectionArgs,
    handleDisplayValidationErrors,
  } = useHoldingForm({
    form,
    id: id?.toString(),
    onAddHoldingUploadFailed,
    onUpdate: handleUpdate,
    oboOverride,
    setIsSubmitDisabled,
    disabled: isDraft,
    onSubmit: onReportedTradeFlowSubmit,
  });

  // For postponed creation of new holdings
  const {
    data: postponedData,
    submit: postponedSubmit,
    collapsibleSectionArgs: postponedCollapsibleSectionArgs,
    isLoading: postponedIsLoading,
  } = usePostponedHoldingForm({
    form,
    focusedHolding,
    setIsSubmitDisabled,
    onSubmit: onReportedTradeFlowSubmit,
    initialIssuerEntity,
  });

  const handleSubmit = () => {
    if (isDraft) {
      postponedSubmit();
    } else {
      submit({
        waitForHoldingIndexInvalidationOnUpdate,
      });
    }
  };

  const handleOpenConfirmDialog = async () => {
    try {
      await form.validateFields();
      openConfirmDialog();
    } catch (err) {
      handleDisplayValidationErrors(err);
      console.error(err);
    }
  };

  const genericData = data || postponedData;

  return (
    <>
      <Drawer
        backToRoute={backToRoute}
        title={
          <DrawerTitle
            title={title}
            id={id}
            withCompanyLogo={showCompanyLogoInTitle}
            issuerEntity={initialIssuerEntity}
          />
        }
        open={open}
        onClose={() => onCloseAttempt(handleCloseConfirm)}
        isLoading={isLoading}
        footer={
          <S.Footer>
            {footerAlertMessage && (
              <S.FooterAlert message={footerAlertMessage} />
            )}
            <Button
              disabled={disabled || isSubmitDisabled}
              loading={isUpdating || postponedIsLoading}
              onClick={
                confirmBeforeSubmit
                  ? handleOpenConfirmDialog
                  : () => form.submit()
              }
              data-cy={CypressDataIds.Holdings.Drawer.SubmitButton}
            >
              {id || focusedHolding ? 'Save Holding' : 'Add New Holding'}
            </Button>
          </S.Footer>
        }
      >
        <>
          {topContent}

          {genericData?.state?.code ===
            CbHoldingState.items.needs_verification && (
            <InstructionsAlert
              instructions={genericData?.correction_instructions}
            />
          )}

          <HoldingForm
            form={form}
            onValuesChange={() => setHasUnsavedChanges(true)}
            initialData={genericData}
            initialFilledQty={postponedData?.filledQuantity}
            hideIssuerEntitySelect={hideIssuerEntitySelect}
            disabledIssuerEntitySelect={disabledIssuerEntitySelect}
            disabledAccountSelect={disabledAccountSelect}
            showAccountSectionTitle={showAccountSectionTitle}
            preselectedValues={{
              id: genericData?.id,
              issuer_entity: genericData?.issuer_entity || initialIssuerEntity,
              account: genericData?.account || initialAccount,
              registered_name: oboAccount?.name || role?.subject?.name,
              proof_of_ownership_document:
                genericData?.proof_of_ownership_document,
            }}
            currency={genericData?.asset?.denomination?.name || 'USD'}
            collapsibleSectionArgs={
              isDraft ? postponedCollapsibleSectionArgs : collapsibleSectionArgs
            }
            onAccountSwitch={onAccountSwitch}
            isCorrectionMode={isCorrectionMode}
            isCertificateNumberRequired={isCertificateNumberRequired}
            isDraft={isDraft}
            isReportTradeFlow={isReportTradeFlow}
            filterAssetTypeOptions={filterAssetTypeOptions}
            handleSubmit={handleSubmit}
            showProofOfOwnershipUpload={showProofOfOwnershipUpload}
          />
        </>

        {isConfirmDialogOpen && (
          <DrawerConfirmDialog
            message="I confirm that I have reviewed my holding information."
            handleConfirm={() => {
              closeConfirmDialog();
              handleSubmit();
            }}
            handleCancel={closeConfirmDialog}
            handleClickOutsideConfirm={closeConfirmDialog}
            offsetBottom={56}
          />
        )}
      </Drawer>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
