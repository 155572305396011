import { useCallback } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { DATE_FORMATS, parseDateToString } from '@npm/core/ui/utils/formatters';
import {
  CbCountry,
  CbDocumentType,
  CbOwnerType,
  CbSubregion,
} from '@npm/data-access';
import {
  type DocumentApiDocumentCreateRequest,
  type Person,
  useCodebook,
  useDocumentCreate,
  useDocumentShow,
  usePersonShow,
} from '@npm/data-access';
import { useQueryClient } from '@tanstack/react-query';

import { type GovernmentIDFormType } from './Form/GovernmentIDForm.types';

export const useGovernmentIdForm = (
  personId: Person['id'],
  {
    onCreate,
  }: {
    onCreate?: () => void;
  }
) => {
  const { withShowApiErrorMiddleware } = useAlerts();

  const [form] = Form.useForm<GovernmentIDFormType>();
  const { data: subregions } = useCodebook({
    type: CbSubregion.code,
    prefix: 'US',
  });
  const queryClient = useQueryClient();

  const { data: personData, isLoading: personLoading } = usePersonShow(
    { id: personId },
    {
      queryConfig: {
        enabled: !!personId,
      },
    }
  );

  const { data: documentData, isLoading: documentLoading } = useDocumentShow(
    {
      id: personData?.identity_document_id,
    },
    {
      queryConfig: {
        enabled: !!personData?.identity_document_id,
      },
    }
  );

  const { execute: createDocument, isLoading: isCreating } =
    useDocumentCreate();

  const getCreateDocumentParams =
    useCallback((): DocumentApiDocumentCreateRequest => {
      const {
        dateOfBirth,
        expirationDate,
        state,
        nationality,
        issuingCountry,
        category,
        ...restFields
      } = form.getFieldsValue();

      return {
        ...restFields,
        ownerType: CbOwnerType.items.Person,
        ownerId: personId,
        ...(dateOfBirth && {
          dateOfBirth: parseDateToString(
            dateOfBirth,
            DATE_FORMATS.DATE_NUMERIC
          ),
        }),
        ...(expirationDate && {
          expirationDate: parseDateToString(
            expirationDate,
            DATE_FORMATS.DATE_NUMERIC
          ),
        }),
        ...(state && {
          state: subregions?.codebooks?.find(
            codebook => codebook.code === state
          )?.code,
        }),
        ...(nationality && {
          nationality: `${CbCountry.code}.${nationality}`,
        }),
        ...(issuingCountry && {
          issuingCountry: `${CbCountry.code}.${issuingCountry}`,
        }),
        category: `${CbDocumentType.code}.${category}`,
      };
    }, [form, personId, subregions]);

  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      handleValidationError(form, e);
      return;
    }

    const requestParams = getCreateDocumentParams();

    try {
      await withShowApiErrorMiddleware(createDocument)(requestParams);

      queryClient.invalidateQueries([
        'PersonShow',
        'AccountShow',
        'AccountOnboardingStatusShow',
      ]);

      onCreate?.();
    } catch (e) {
      console.error(e);
    }
  }, [
    form,
    getCreateDocumentParams,
    withShowApiErrorMiddleware,
    createDocument,
    onCreate,
    queryClient,
  ]);

  return {
    form,
    data: documentData,
    isLoading:
      (personData?.identity_document_id && documentLoading) || personLoading,
    isCreating,
    handleSubmit,
    subregions,
  };
};
