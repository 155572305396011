import type { ComponentProps } from 'react';

import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { type Holding, useHoldingShow } from '@npm/data-access';

type Props = {
  holding: Holding | undefined;
} & ComponentProps<typeof Modal>;

export const CorrectionInstructionsModal = ({
  holding,
  ...modalProps
}: Props) => {
  const { data, isFetching } = useHoldingShow(
    {
      id: holding?.id?.toString(),
    },
    {
      queryConfig: {
        enabled: !!holding?.id && !holding?.correction_instructions,
      },
    }
  );

  return (
    <Modal
      {...modalProps}
      title={`Correction Instructions for ${holding?.certificate_number || data?.certificate_number || 'holding'}`}
      okText="Close"
      cancelButtonProps={{ hidden: true }}
      size="md"
    >
      {isFetching ? (
        <Skeleton.Base />
      ) : (
        <Text size="sm">
          {holding?.correction_instructions || data?.correction_instructions}
        </Text>
      )}
    </Modal>
  );
};
