import styled from 'styled-components';

export const Container = styled.div`
  .ant-upload-drag {
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    background-color: ${({ theme }) => theme.color.general.layout.one};
    border-color: ${({ theme }) => theme.color.info.backgrounds.primaryDefault};

    &:not(.ant-upload-disabled):hover {
      border-color: ${({ theme }) => theme.color.info.backgrounds.primaryHover};
      background-color: ${({ theme }) => theme.color.general.layout.two};
    }
  }

  .ant-upload-drag-hover {
    border-color: ${({ theme }) => theme.color.info.backgrounds.primaryHover};
    background-color: ${({ theme }) => theme.color.general.layout.two};
  }
}
`;

export const UploadInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 222px;

  padding: ${({ theme }) => theme.spacing.xxl}px
    ${({ theme }) => theme.spacing.sm}px;

  & > .ant-btn {
    margin-bottom: ${({ theme }) => theme.spacing.lg}px;
  }

  & .ant-progress-circle {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }
`;
