import { CbLabel, Label } from '@npm/core/ui/components/atoms/Label';
import {
  type ExtendedColumnType,
  Table,
} from '@npm/core/ui/components/molecules/Table';
import {
  CbAccreditationState,
  CbBackgroundCheckState,
  type UserRoleAggregate,
} from '@npm/data-access';

import { AccountName } from '../../../../../../account/components/AccountName';

import { NoDataArea } from './NoDataArea';

export type ExtraColumnsType = ExtendedColumnType<UserRoleAggregate>[];

type Props = {
  data: UserRoleAggregate[] | undefined;
  isLoading: boolean;
  selectedRole: UserRoleAggregate | undefined;
  setSelectedRole: (userRole: UserRoleAggregate) => void;
  isAnyFilterApplied: boolean;
  noDataAreaTitle?: string;
  extraColumns?: ExtraColumnsType;
  hideBrokerageFirm?: boolean;
};

export const AccountsTable = ({
  data,
  isLoading,
  selectedRole,
  setSelectedRole,
  isAnyFilterApplied,
  noDataAreaTitle,
  extraColumns,
  hideBrokerageFirm = false,
}: Props) => {
  return (
    <Table
      dataSource={data ?? []}
      columns={[
        {
          title: hideBrokerageFirm ? 'Eligible Accounts' : 'Account',
          key: 'name',
          render: userRole => (
            <AccountName account={userRole.subject} withoutLink />
          ),
        },
        {
          title: 'Brokerage Firm',
          key: 'brokerage_firm_name',
          render: userRole => (
            <Label variant="info">
              {userRole.subject?.brokerage_firm_name}
            </Label>
          ),
          hidden: hideBrokerageFirm,
        },
        {
          title: 'Role',
          key: 'role',
          render: userRole => userRole.role_name?.name,
        },
        {
          title: 'AML/KYC',
          key: 'background_check_state',
          width: '15%',
          render: userRole =>
            CbBackgroundCheckState.getLabel(
              CbLabel,
              userRole.subject?.background_check_state
            ),
        },
        ...(extraColumns?.length
          ? extraColumns
          : [
              {
                title: 'Accreditation',
                key: 'accreditation_state',
                width: '15%',
                render: userRole =>
                  CbAccreditationState.getLabel(
                    CbLabel,
                    userRole.subject?.accreditation_state
                  ),
              },
            ]),
      ]}
      rowSelection={{
        type: 'radio',
        selectedRowKeys: [selectedRole?.id],
        onChange: (_, a) => {
          const userRole = a[0];
          if (userRole.id !== selectedRole?.id) {
            setSelectedRole(userRole);
          }
        },
      }}
      loading={isLoading}
      noDataArea={
        <NoDataArea
          isAnyFilterApplied={isAnyFilterApplied}
          title={noDataAreaTitle}
        />
      }
    />
  );
};
