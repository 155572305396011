import React, { useMemo, useState } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { FileUpload } from '@npm/core/ui/components/atoms/FileUpload';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { DatePickerInput, Input } from '@npm/core/ui/components/atoms/Input';
import { Radio } from '@npm/core/ui/components/atoms/Radio';
import { Select } from '@npm/core/ui/components/atoms/Select';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { CbDocumentType, type CodebookShow } from '@npm/data-access';
import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';

import { CountrySelect } from '../../../../../features/src/filters';
import { IndividualTaxIdForm } from '../../TaxId';

import { ModalHowTo } from './ModalHowTo/ModalHowTo';

import * as S from './GovernmentIDForm.styles';
import { FORM_FIELDS_CONFIG, ID_IMAGE_SRC } from './GovernmentIDForm.constants';
import { type GovernmentIDFormType } from './GovernmentIDForm.types';
import { GovernmentIDFormItem } from './GovernmentIDFormItem';

type Props = {
  form: FormInstance<GovernmentIDFormType>;
  subregions?: CodebookShow[];
  inputLayout?: 'vertical' | 'horizontal';
  withTaxId?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const GovernmentIDForm = ({
  form,
  subregions,
  inputLayout = 'horizontal',
  withTaxId = false,
  handleValuesChange,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const inputFlexDirection = useMemo(
    () => (inputLayout === 'vertical' || isMobile ? 'column' : 'row'),
    [isMobile, inputLayout]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    items: categories,
    getLabel,
    documentIdItems: documentIdCategories,
  } = CbDocumentType;

  const subregionOptions = useMemo(
    () =>
      subregions?.map(codebook => ({
        label: codebook.name,
        value: codebook.code,
      })),
    [subregions]
  );

  const renderDriversLicenseItems = () => (
    <Margin bottom="md">
      <Flex direction={inputFlexDirection}>
        <GovernmentIDFormItem layout={inputLayout} field="numberLicense">
          <Input placeholder="0000-0000-0000" />
        </GovernmentIDFormItem>
        <GovernmentIDFormItem layout={inputLayout} field="state">
          <Select placeholder="Search" options={subregionOptions} showSearch />
        </GovernmentIDFormItem>
      </Flex>
    </Margin>
  );

  const renderPassportItems = () => (
    <>
      <Margin bottom="md">
        <Flex direction={inputFlexDirection}>
          <GovernmentIDFormItem layout={inputLayout} field="issuingCountry">
            <CountrySelect />
          </GovernmentIDFormItem>
          <GovernmentIDFormItem layout={inputLayout} field="nationality">
            <CountrySelect />
          </GovernmentIDFormItem>
        </Flex>
      </Margin>
      <Margin bottom="md">
        <GovernmentIDFormItem layout={inputLayout} field="numberPassport">
          <Input placeholder="0000-0000-0000" />
        </GovernmentIDFormItem>
      </Margin>
    </>
  );

  return (
    <>
      <Margin bottom="lg">
        <Alert message="A valid, unexpired government issued photo ID is required (e.g. Drivers license, Passport or Military ID)" />
      </Margin>
      <Form
        form={form}
        onValuesChange={handleValuesChange}
        data-cy="government-id-form"
      >
        <GovernmentIDFormItem
          layout={inputLayout}
          field="category"
          initialValue={documentIdCategories[0]}
        >
          <S.RadioGroup>
            {documentIdCategories.map(category => (
              <Radio
                key={category}
                value={category}
                label={getLabel(category)}
                onChange={() => {
                  form.resetFields();
                }}
              />
            ))}
          </S.RadioGroup>
        </GovernmentIDFormItem>
        <FormItem shouldUpdate>
          {() => {
            const { category } = form.getFieldsValue();

            return (
              <>
                {category === categories.drivers_license &&
                  renderDriversLicenseItems()}
                {category === categories.passport && renderPassportItems()}
                <Margin bottom="md">
                  <Flex direction={inputFlexDirection}>
                    <GovernmentIDFormItem
                      layout={inputLayout}
                      field="expirationDate"
                    >
                      <DatePickerInput
                        disabledDate={
                          FORM_FIELDS_CONFIG.expirationDate.disabledDate
                        }
                      />
                    </GovernmentIDFormItem>
                    <GovernmentIDFormItem
                      layout={inputLayout}
                      field="dateOfBirth"
                    >
                      <DatePickerInput
                        disabledDate={
                          FORM_FIELDS_CONFIG.dateOfBirth.disabledDate
                        }
                      />
                    </GovernmentIDFormItem>
                  </Flex>
                </Margin>

                {withTaxId && (
                  <Margin bottom="md">
                    <IndividualTaxIdForm
                      compact={true}
                      customExtraMsg="U.S. SSN (Social Security Number) or ITIN (Individual Tax Identification Number)"
                      form={form}
                    />
                  </Margin>
                )}

                <Flex>
                  <S.SampleImageSection>
                    <S.SampleImage src={ID_IMAGE_SRC[category]} />
                    <div>
                      <Heading variant="h3" marginBottom="sm">
                        Government issued photo ID
                      </Heading>
                      <Text size="xs">
                        The file must be PDF, JPG or PNG.{' '}
                        <Text size="xs" onClick={() => setIsModalOpen(true)}>
                          How to digitize your photo ID
                        </Text>
                      </Text>
                    </div>
                  </S.SampleImageSection>
                </Flex>
                <Margin bottom="md">
                  <GovernmentIDFormItem layout={inputLayout} field="file">
                    <FileUpload
                      data-cy={
                        CypressDataIds.PostOnboarding
                          .BeneficialOwnershipAssessment.GovernmentIssuedID
                          .FileUpload
                      }
                    />
                  </GovernmentIDFormItem>
                </Margin>
              </>
            );
          }}
        </FormItem>
      </Form>
      <ModalHowTo
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
