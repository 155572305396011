import React, { type FC } from 'react';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { getPageSize } from '@npm/core/ui/components/molecules/Table';
import {
  type CbEventStatus,
  type EventAggregate,
  useEventListInfinite,
} from '@npm/data-access';
import { useCurrentRole } from '@npm/features/auth/user/role/hooks/useCurrentRole';
import { ProgramsList } from '@npm/features/program/ProgramsList';

type Props = {
  eventsStatus:
    | typeof CbEventStatus.items.ACTIVE
    | typeof CbEventStatus.items.EXPIRED
    | typeof CbEventStatus.items.UPCOMING;
} & Partial<React.ComponentProps<typeof ProgramsList>>;

export const Programs: FC<Props> = ({ eventsStatus, ...programsListProps }) => {
  const role = useCurrentRole();
  const history = useHistory();

  const { data, isFetching, fetchNextPage, hasNextPage } = useEventListInfinite(
    {
      includeField: [
        'issuer_entity',
        'issuance',
        'order_settings',
        'submission_statistics',
        'settings',
        'allow_non_binding_orders',
      ],
      issuerEntityId: role?.subject?.id,
      eventStatus: eventsStatus,
      page: 1,
      size: getPageSize('cards'),
    }
  );

  const onClick = (event: EventAggregate, tab?: 'documents') => {
    const programUrl = `./${event.id}`;
    history.push(tab ? `${programUrl}?tab=${tab}` : programUrl);
  };

  return (
    <ProgramsList
      data={data}
      hasMore={hasNextPage}
      fetchMore={fetchNextPage}
      isFetching={isFetching}
      noDataText={`There are no ${eventsStatus} Programs.`}
      cardProps={{ workstation: 'issuer', onClick }}
      {...programsListProps}
    />
  );
};
