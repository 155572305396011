import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Margin } from '@npm/core/ui/components/atoms/common';
import {
  DEFAULT_SUPPORTED_CONTENT_TYPES,
  DEFAULT_SUPPORTED_FILE_EXTENSIONS,
} from '@npm/core/ui/components/atoms/FileUpload/FileUpload.constants';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { ContextualHelp } from '@npm/core/ui/components/molecules/ContextualHelp';
import {
  EducationalModal,
  useEducationalModal,
} from '@npm/core/ui/components/organisms/EducationalModal';
import { type UploadFile } from 'antd/lib/upload/interface';

import { useIsInvestor } from '../../../auth/user/role';
import { UploadDragger } from '../../../dataRooms';
import { educationalModalCertificatesConfig } from '../../../educationalModals/Certificates';

import { FileItem } from './components/FileItem';

import { ProofOfOwnershipUploadStyles as S } from './ProofOfOwnershipUpload.styles';

type Props = Pick<
  ComponentProps<typeof UploadDragger>,
  'fileList' | 'onUpload'
> & { onRemove: (file: UploadFile) => void };

export const ProofOfOwnershipUpload = ({
  fileList,
  onRemove,
  onUpload,
}: Props) => {
  const isInvestor = useIsInvestor();

  const [openCertificatesHelp, educationalModalProps] = useEducationalModal(
    educationalModalCertificatesConfig
  );

  const heading = <Heading variant="h3">Proof of Ownership</Heading>;

  const content = fileList?.length ? (
    <>
      <S.CheckContainer align="center" justify="center">
        <Icon name="check" size="xs" color="info" strokeWidth={2} />
      </S.CheckContainer>
      <div style={{ width: '100%' }}>
        <Margin bottom={'md'}>{heading}</Margin>
        <Button
          style={{ width: '100%' }}
          stopPropagation={false}
          variant={'outline'}
          icon={<Icon name="upload" />}
        >
          Upload More
        </Button>
        <S.FileItemsContainer direction="column">
          {fileList.map(file => (
            <FileItem file={file} key={file.uid} onRemove={onRemove} />
          ))}
        </S.FileItemsContainer>
      </div>
    </>
  ) : (
    <>
      <Button icon={<Icon name="upload" />} stopPropagation={false} />
      <div>
        {heading}
        <Text size="sm" color="info" marginBottom="md">
          Upload your certificates and/or option grants
        </Text>
        <Text size="xs">Files must be PDF, JPG or PNG</Text>
      </div>
      <div style={{ width: '100%' }}>
        {isInvestor && (
          <ContextualHelp
            onClick={e => {
              openCertificatesHelp();
              e.stopPropagation();
            }}
          >
            Where do I find my certificates and option grants?
          </ContextualHelp>
        )}
      </div>
    </>
  );

  return (
    <>
      <S.Container>
        <UploadDragger
          accept={[
            ...DEFAULT_SUPPORTED_FILE_EXTENSIONS,
            ...DEFAULT_SUPPORTED_CONTENT_TYPES,
          ]}
          fileList={fileList}
          onUpload={onUpload}
        >
          <S.InnerContainer
            direction="column"
            align="center"
            justify="center"
            gap="md"
          >
            {content}
          </S.InnerContainer>
        </UploadDragger>
      </S.Container>
      <EducationalModal {...educationalModalProps} />
    </>
  );
};
