import React, { type ComponentProps } from 'react';

import { NoDataArea } from '@npm/core/ui/components/atoms/NoDataArea';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import { type FolderAggregate, useFolderIndex } from '@npm/data-access';

import { type DataRoomsList } from '../DataRoomsList';

import { getDataRoomsTableColumns } from './DataRoomsTable.columns';

type Props = ComponentProps<typeof DataRoomsList>;

export const DataRoomsTable = ({
  onAccessClick,
  variables,
  setVariables,
}: Props) => {
  const { data, isLoading } = useFolderIndex(variables);

  const { updatePaginationVariables, pagination } = usePagination('dataRooms', {
    variables,
    setVariables,
    paginationData: data?.pagination,
  });

  return (
    <div>
      <Table<FolderAggregate>
        columns={getDataRoomsTableColumns({ onAccessClick })}
        rowKey={item => item.id}
        dataSource={data?.folders}
        noDataArea={<NoDataArea title="No Data Rooms found." />}
        onChange={updatePaginationVariables}
        pagination={pagination}
        loading={isLoading}
        outlined
      />
    </div>
  );
};
