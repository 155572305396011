import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';

type Props = {
  durationInMin: number;
};

export const DurationLabel = ({ durationInMin }: Props) => {
  return (
    <Flex align="center" gap="xs">
      <Icon name="clock" color="info" size="xxs" />
      <Text size="sm" color="info" colorVariant="primary">
        {durationInMin} Minutes
      </Text>
    </Flex>
  );
};
