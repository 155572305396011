import React from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import {
  type AccountShowAggregate,
  CbAccreditationState,
  CbBackgroundCheckState,
} from '@npm/data-access';

import * as S from '../../../SelectAccount/SelectAccountCards/SelectAccountCard/SelectAccountCard.styles';
import { AccountName } from '../../../../../../../../account/components/AccountName';
import { type OboModalState } from '../../../../OboModal.types';
import { RepresentativeButton } from '../../../SelectAccount/RepresentativeButton';

type Props = {
  account: AccountShowAggregate;
  handleSelect: (nextOboState: OboModalState) => void;
  goToRepresentativeStep: () => void;
  handleComplete: (nextOboState: OboModalState) => void;
  topContent?: React.ReactNode;
};

export const SelectNegotiationAccountCard = ({
  account,
  handleSelect,
  handleComplete,
  goToRepresentativeStep,
  topContent,
}: Props) => {
  const representative = account?.representative;

  const handleButtonClick = () => {
    handleComplete({
      account,
      representative,
    });
  };

  const handleSwitchRepresentative = () => {
    handleSelect({ account, representative });
    goToRepresentativeStep();
  };

  return (
    <S.ItemButton key={account.id} onClick={handleButtonClick}>
      {topContent && (
        <Margin bottom="sm" style={{ width: '100%' }}>
          {topContent}
        </Margin>
      )}
      <Margin bottom="sm">
        <AccountName account={account} isActive={false} />
      </Margin>
      <S.PairWrapper>
        <S.PairWrapper>
          <Text size="xs" colorVariant="tertiary">
            AML/KYC
          </Text>
          {CbBackgroundCheckState.getLabel(
            CbLabel,
            account.background_check_state
          )}
        </S.PairWrapper>
        <S.PairWrapper>
          <Text size="xs" colorVariant="tertiary">
            Accreditation
          </Text>
          {CbAccreditationState.getLabel(CbLabel, account.accreditation_state)}
        </S.PairWrapper>
      </S.PairWrapper>
      {account.representative_count > 0 && (
        <RepresentativeButton
          onClick={
            account.representative_count > 1 ? handleSwitchRepresentative : null
          }
          representative={account.representative}
        />
      )}
    </S.ItemButton>
  );
};
