// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AtomicAgreementApi,
  AccountAtomicAgreementIndexErrorCodes,
  AccountAtomicAgreementIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AtomicAgreementApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountAtomicAgreementIndexMethod = AtomicAgreementApi['accountAtomicAgreementIndex'];
export type AccountAtomicAgreementIndexResponseType = MethodResult<AccountAtomicAgreementIndexMethod>;

export const useAccountAtomicAgreementIndex = (
  variables: Parameters<AccountAtomicAgreementIndexMethod>[0],
  config?: UseQueryConfig<
    AccountAtomicAgreementIndexMethod,
    AccountAtomicAgreementIndexErrorTypes
  >
) => {
  return useQuery<
    AccountAtomicAgreementIndexMethod,
    AccountAtomicAgreementIndexErrorTypes
  >(
    (...args) => api.accountAtomicAgreementIndex(...args),
    AccountAtomicAgreementIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAtomicAgreementIndex'],
        ...apiQueryConfig.AccountAtomicAgreementIndex,
        ...config?.queryConfig
      },
    },
    'AccountAtomicAgreementIndex',
  );
};

export const useAccountAtomicAgreementIndexInfinite = (
  variables: Parameters<AccountAtomicAgreementIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountAtomicAgreementIndexMethod,
    AccountAtomicAgreementIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountAtomicAgreementIndexMethod,
    AccountAtomicAgreementIndexErrorTypes
  >(
    (...args) => api.accountAtomicAgreementIndex(...args),
    AccountAtomicAgreementIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAtomicAgreementIndex', 'AccountAtomicAgreementIndexInfinite'],
        ...apiQueryConfig.AccountAtomicAgreementIndex,
        ...config?.queryConfig,
      },
    },
    'AccountAtomicAgreementIndex',
  );
};

export const useAccountAtomicAgreementIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountAtomicAgreementIndexMethod>[0],
  config?: UseQueryConfig<
    AccountAtomicAgreementIndexMethod,
    AccountAtomicAgreementIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountAtomicAgreementIndexMethod,
    AccountAtomicAgreementIndexErrorTypes
  >(
    (...args) => api.accountAtomicAgreementIndex(...args),
    AccountAtomicAgreementIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountAtomicAgreementIndex'],
        ...apiQueryConfig.AccountAtomicAgreementIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountAtomicAgreementIndex',
  );
};

