import React from 'react';

import { Button, type ButtonProps } from '@npm/core/ui/components/atoms/Button';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { MarketFeedDataIds } from '@npm/core/ui/constants';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import {
  CbOrderBuySell,
  CbOrderEntryType,
  CbSubmissionStructure,
  type SecondmarketOrderItemShow,
} from '@npm/data-access';

import { useUserContextStore } from '../../../../../auth/user/context';
import { RequestMoreInformationButton } from '../../../../components';

import { ActionsContainer } from './ActionsContainer';

export type MarketOrderActionsProps = {
  orderItem: SecondmarketOrderItemShow;
  handleShowOrderDetails: () => void;
  handleShowNegotiations: (
    orderItem: SecondmarketOrderItemShow,
    isEntityNpmsInvestor?: boolean,
    isIndividualNpmsBuyerInvestor?: boolean
  ) => void;
  handleStartNegotiation:
    | ((orderItem: SecondmarketOrderItemShow) => void)
    | undefined;
  buttonSize?: ButtonProps['size'];
  isGoingToOrderDetail?: boolean;
};

export const InvestorL2MarketOrderActions = ({
  orderItem,
  handleShowOrderDetails,
  handleShowNegotiations,
  handleStartNegotiation,
  buttonSize = 'md',
  isGoingToOrderDetail,
}: MarketOrderActionsProps) => {
  const { isEntityNpmsInvestor, isIndividualNpmsBuyerInvestor } =
    useUserContextStore(store => ({
      isEntityNpmsInvestor: store.isEntityNpmsInvestor,
      isIndividualNpmsBuyerInvestor: store.isIndividualNpmsBuyerInvestor,
    }));

  const requestMoreInfoCTA = (
    <RequestMoreInformationButton
      size={buttonSize}
      orderItem={orderItem}
      title="Connect with NPM"
      blockOnMobile
      style={{ flexGrow: 1 }}
    />
  );

  let negotiateCTAText: string;
  if (orderItem.order_side?.code === CbOrderBuySell.items.buy) {
    negotiateCTAText = 'Respond to Buyer';
  } else if (orderItem.order_side?.code === CbOrderBuySell.items.sell) {
    negotiateCTAText = 'Respond to Seller';
  } else {
    negotiateCTAText = 'Respond'; // Not in design and should not happen, but typescript allows it, se we have a fallback
  }

  const negotiateCTA = (
    <Tooltip
      title={
        handleStartNegotiation
          ? undefined
          : 'To negotiate with counterparties, you must be an Account Admin & Signer or Account Admin.'
      }
    >
      <Button
        size={buttonSize}
        onClick={() =>
          handleShowNegotiations?.(
            orderItem,
            isEntityNpmsInvestor,
            isIndividualNpmsBuyerInvestor
          )
        }
        disabled={!handleStartNegotiation}
        style={{ flexGrow: 1 }}
        data-cy={composeDataId(
          MarketFeedDataIds.Table.Actions.StartNegotiation,
          orderItem.id
        )}
      >
        {negotiateCTAText}
      </Button>
    </Tooltip>
  );

  const negotiationsCTA = (
    <Button
      variant="outline"
      color="info"
      size={buttonSize}
      onClick={() =>
        handleShowNegotiations?.(
          orderItem,
          isEntityNpmsInvestor,
          isIndividualNpmsBuyerInvestor
        )
      }
      loading={orderItem?.negotiations_total === 1 && isGoingToOrderDetail}
      style={{ flexGrow: 1 }}
      data-cy={composeDataId(
        MarketFeedDataIds.Table.Actions.InNegotiation,
        orderItem?.id
      )}
    >
      Negotiations ({orderItem?.negotiations_total})
    </Button>
  );

  const showOrderDetailCTA = (
    <Button
      variant="outline"
      color="info"
      size={buttonSize}
      onClick={handleShowOrderDetails}
      style={{ flexGrow: 1 }}
      loading={isGoingToOrderDetail}
    >
      Show Details
    </Button>
  );

  if (orderItem.account) {
    if (orderItem.cancellable || orderItem.negotiations_total < 1) {
      return <ActionsContainer>{showOrderDetailCTA}</ActionsContainer>;
    }

    return <ActionsContainer>{negotiationsCTA}</ActionsContainer>;
  }

  if (
    orderItem.order_type?.code === CbOrderEntryType.items.soft_ioi ||
    orderItem.order?.structure?.code === CbSubmissionStructure.items.spv
  ) {
    return <ActionsContainer>{requestMoreInfoCTA}</ActionsContainer>;
  }

  if (orderItem.negotiations_total > 0) {
    return <ActionsContainer>{negotiationsCTA}</ActionsContainer>;
  }

  return (
    <ActionsContainer>
      {orderItem.negotiations_total > 0 ? negotiationsCTA : negotiateCTA}
    </ActionsContainer>
  );
};
