import { componentLoader } from '@npm/core/ui/routes';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';
import { type PreplatformRouteKeys } from '@npm/core/ui/routes/routes.preplatform';

import { type PersonaType } from '../Preplatform.types';

type RouteDef = RouteDefinition & {
  path: PreplatformRouteKeys;
};

export const workstationPrefix = 'preplatform';

export const routes: RouteDef[] = [
  {
    path: `/${workstationPrefix}`,
    needUser: false,
    needLogin: false,
    component: componentLoader(() => import('../pages/PersonaSelect')),
  },
  {
    path: `/${workstationPrefix}/:personaType`,
    needUser: false,
    needLogin: false,
    component: componentLoader(() => import('../pages/PersonaSelect')),
  },
  {
    path: `/${workstationPrefix}/:personaType/sign-up`,
    needUser: false,
    needLogin: false,
    component: componentLoader(() => import('../pages/SignUp')),
  },
  {
    path: `/${workstationPrefix}/:personaType/confirmation`,
    needUser: false,
    needLogin: false,
    component: componentLoader(() => import('../pages/Confirmation')),
  },
];

export const preplatformRouteMap = {
  personaSelect: {
    path: `/${workstationPrefix}`,
  },
  personaSelectWithType: {
    path: `/${workstationPrefix}/:personaType`,
    getPath(personaType: PersonaType) {
      return this.path.replace(':personaType', personaType);
    },
  },
  signUp: {
    path: `/${workstationPrefix}/:personaType/sign-up`,
    getPath(personaType: PersonaType) {
      return this.path.replace(':personaType', personaType);
    },
  },
  confirmation: {
    path: `/${workstationPrefix}/:personaType/confirmation`,
    getPath(personaType: PersonaType) {
      return this.path.replace(':personaType', personaType);
    },
  },
} as const;
