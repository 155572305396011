import React, { type ComponentProps, useMemo } from 'react';

import { Select } from '@npm/core/ui/components/atoms/Select';
import { VenusApi } from '@npm/data-access';

type Props = ComponentProps<typeof Select>;

export const IssuerEntitySectorsSelect = (props: Props) => {
  const { useGicsSectors } = VenusApi;
  const { data: gicsSectors, isLoading, error } = useGicsSectors({});

  const options = useMemo(() => {
    if (!gicsSectors?.gics_sectors) return null;
    return gicsSectors.gics_sectors.map(sector => ({
      label: sector?.name,
      value: sector?.name,
    }));
  }, [gicsSectors]);

  return (
    <Select
      allowClear
      loading={isLoading}
      placeholder={options ? `Select (${options.length})` : 'Select'}
      options={options}
      error={error}
      {...props}
    />
  );
};
