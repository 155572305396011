import React, { useState } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import {
  Codebooks,
  useCodebook,
  type UserRoleAggregate,
} from '@npm/data-access';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { useTheme } from 'styled-components';

import { useAllowedAssetTypeOptions } from '../../../../../order';
import { ActionsTabs } from '../../Form';
import { WatchlistEntrySection } from '../../Form/WatchlistEntrySection';

import * as S from './AdvancedSettingsSection.styles';

type Props = {
  filterAccounts?: (role: UserRoleAggregate) => boolean;
};

export const AdvancedSettingsSection = ({ filterAccounts }: Props) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const form = useFormInstance();

  const { data: assetTypeData } = useCodebook({
    type: Codebooks.ASSET_TYPE,
  });

  const allowedAssetTypeOptions = useAllowedAssetTypeOptions(
    assetTypeData?.codebooks ?? []
  );

  return (
    <S.Wrapper onClick={e => e.stopPropagation()}>
      <Divider marginBottom={theme.spacing.sm} />
      <S.Button
        size="sm"
        color="info"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} size="sm" />
        {isCollapsed ? 'Show' : 'Hide'} Advanced Settings
      </S.Button>
      <S.Footer $isCollapsed={isCollapsed}>
        <Divider marginBottom={theme.spacing.sm} />
        <Margin bottom="md">
          <ActionsTabs form={form} />
        </Margin>
        <WatchlistEntrySection
          form={form}
          assetTypes={allowedAssetTypeOptions}
          showSectionTitles={false}
          showAccountFormItem
          filterAccounts={filterAccounts}
        />
      </S.Footer>
    </S.Wrapper>
  );
};
