// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompanyProfileApi,
  CompanyProfileShowErrorCodes,
  CompanyProfileShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompanyProfileApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompanyProfileShowMethod = CompanyProfileApi['companyProfileShow'];
export type CompanyProfileShowResponseType = MethodResult<CompanyProfileShowMethod>;

export const useCompanyProfileShow = (
  variables: Parameters<CompanyProfileShowMethod>[0],
  config?: UseQueryConfig<
    CompanyProfileShowMethod,
    CompanyProfileShowErrorTypes
  >
) => {
  return useQuery<
    CompanyProfileShowMethod,
    CompanyProfileShowErrorTypes
  >(
    (...args) => api.companyProfileShow(...args),
    CompanyProfileShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyProfileShow'],
        ...apiQueryConfig.CompanyProfileShow,
        ...config?.queryConfig
      },
    },
    'CompanyProfileShow',
  );
};

export const useCompanyProfileShowInfinite = (
  variables: Parameters<CompanyProfileShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompanyProfileShowMethod,
    CompanyProfileShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompanyProfileShowMethod,
    CompanyProfileShowErrorTypes
  >(
    (...args) => api.companyProfileShow(...args),
    CompanyProfileShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompanyProfileShow', 'CompanyProfileShowInfinite'],
        ...apiQueryConfig.CompanyProfileShow,
        ...config?.queryConfig,
      },
    },
    'CompanyProfileShow',
  );
};

export const useCompanyProfileShowLazy = (baseOptions?: {
  variables?: Parameters<CompanyProfileShowMethod>[0],
  config?: UseQueryConfig<
    CompanyProfileShowMethod,
    CompanyProfileShowErrorTypes
  >
}) => {
  return useLazyQuery<
    CompanyProfileShowMethod,
    CompanyProfileShowErrorTypes
  >(
    (...args) => api.companyProfileShow(...args),
    CompanyProfileShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompanyProfileShow'],
        ...apiQueryConfig.CompanyProfileShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompanyProfileShow',
  );
};

