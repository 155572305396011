import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  annual_income_group: 'annual_income_group',
  org_type_q: 'org_type_q',
  qualified_purchaser_q: 'qualified_purchaser_q',
  qualified_institutional_buyer_assets_q: 'qualified_institutional_buyer_assets_q',
  qualified_institutional_buyer_type_q: 'qualified_institutional_buyer_type_q',
  qualified_client_type_q: 'qualified_client_type_q',
  other_type_q: 'other_type_q',
  entity_assets_q: 'entity_assets_q',
  entity_investments_owned_q: 'entity_investments_owned_q',
  entity_formed_to_acquire_securities_q: 'entity_formed_to_acquire_securities_q',
  entity_equity_owner_accredited_q: 'entity_equity_owner_accredited_q',
  entity_assets_rep_q: 'entity_assets_rep_q',
  entity_assets_rep_investment_q: 'entity_assets_rep_investment_q',
  entity_assets_rep_erisa_q: 'entity_assets_rep_erisa_q',
  entity_investment_decisions_q: 'entity_investment_decisions_q',
  entity_total_assets_q: 'entity_total_assets_q',
  reset_form_q: 'reset_form_q',
  entity_investors_directed_q: 'entity_investors_directed_q',
  securities_license_q: 'securities_liecense_q',
  net_worth_group: 'net_worth_group',
  total_investment_group: 'total_investment_group',
} as const;

export type AccreditationQuestionType = keyof typeof Items;
type AttributeName = 'entity' | 'individual' | 'subtext';
export const CbAccreditationQuestion = {
  code: Codebooks.ACCREDITATION_QUESTION,
  items: Items,
  getAttributeValueByName: (item: CodebookItem, name: AttributeName) =>
    item.attributes?.find(q => q.name === name)?.value,
};
