import React, { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';

import { BottomNavigation } from '@npm/core/ui/components/atoms/BottomNavigation';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import {
  StepState,
  type WizardStore,
  WizardStoreActionTypes,
} from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type IconNames } from '@npm/utils';

import {
  type PostOnboardingContextType,
  type SectionKey,
} from '../PostOnboarding.types';
import { findNextIncompleteOnboardingStep } from '../PostOnboarding.utils';

import * as S from './StepActionButtons.styles';

const SECTIONS_WITHOUT_SKIP_OR_NEXT_BUTTON: SectionKey[] = [
  'required_for_marketplace',
];

type Props = {
  stepTitle: React.ReactNode;
  isFilled: boolean;
  isEditable?: boolean;
  handleSubmit?: () => void;
  disableSubmitButton?: boolean;
  handleEdit?: () => void;
  isLoading?: boolean;
  handleCloseFormAttempt?: (callback?: () => void) => void;
  updateButtonProps?: {
    children?: React.ReactNode;
    icon?: IconNames;
    hideButton?: boolean;
    'data-cy'?: string;
  };
};

export const StepActionButtons = ({
  stepTitle,
  handleSubmit,
  handleEdit,
  isFilled,
  isEditable = false,
  isLoading,
  disableSubmitButton,
  updateButtonProps,
  handleCloseFormAttempt,
}: Props) => {
  const { isMobile } = useBreakpoints();
  const { activeStep, nextStep, goToStep, isLastStep } = useWizard();
  const { activeSectionKey, onboardingStatus, steps, dispatch } =
    useWizardStore((s: WizardStore<PostOnboardingContextType>) => ({
      activeSectionKey: s.data.activeSectionProps?.activeSection?.key,
      onboardingStatus: s.data.onboardingStatus,
      steps: s.steps,
      dispatch: s.dispatch,
    }));

  const nextIncompleteStep = findNextIncompleteOnboardingStep({
    steps,
    onboardingStatus,
    startIndex: activeStep,
  });

  const shouldShowSkipOrNextButton =
    SECTIONS_WITHOUT_SKIP_OR_NEXT_BUTTON.indexOf(
      activeSectionKey as SectionKey
    ) === -1;

  const handleNext = () => {
    if (nextIncompleteStep === -1) {
      isLastStep ? goToStep(0) : nextStep();
    } else if (nextIncompleteStep === activeStep) {
      goToStep(0);
    } else {
      goToStep(nextIncompleteStep);
    }
  };

  const submitButton = !isFilled && handleSubmit && (
    <Button
      onClick={handleSubmit}
      loading={isLoading}
      disabled={isLoading || disableSubmitButton}
      blockOnMobile
    >
      Submit
    </Button>
  );

  const skipButton = !isFilled && shouldShowSkipOrNextButton && (
    <Button
      variant="text"
      onClick={() => {
        handleCloseFormAttempt
          ? handleCloseFormAttempt(handleNext)
          : handleNext();
      }}
      disabled={isLoading}
      blockOnMobile
    >
      Skip <Icon name="chevron-right" />
    </Button>
  );

  const updateButton = (isEditable || handleEdit) &&
    !updateButtonProps?.hideButton && (
      <Button
        variant="outline"
        icon={<Icon name={updateButtonProps?.icon ?? 'edit'} />}
        onClick={
          handleEdit ??
          (() =>
            dispatch({
              type: WizardStoreActionTypes.UPDATE_ACTIVE_STEP_STATE,
              payload: StepState.EDIT,
            }))
        }
        disabled={isLoading}
        blockOnMobile
        data-cy={updateButtonProps?.['data-cy']}
      >
        {updateButtonProps?.children ?? `Update ${stepTitle}`}
      </Button>
    );

  const nextButton = (isFilled || shouldShowSkipOrNextButton) && (
    <Button
      variant="text"
      onClick={handleNext}
      disabled={isLoading}
      blockOnMobile
    >
      Next <Icon name="chevron-right" />
    </Button>
  );

  const buttons = [skipButton, nextButton, submitButton, updateButton].filter(
    Boolean
  );

  const mobileButtonsRender = (
    <BottomNavigation
      nextButton={skipButton || nextButton}
      backButton={submitButton || updateButton}
    />
  );

  const buttonsRender = (
    <S.Wrapper>
      {submitButton || updateButton}
      {skipButton || nextButton}
    </S.Wrapper>
  );

  useEffect(() => {
    if (isMobile) {
      dispatch({
        type: WizardStoreActionTypes.UPDATE_DATA,
        payload: { hideBottomNavigation: buttons.length === 0 },
      });
    }
  }, [isMobile, dispatch, buttons.length]);

  if (buttons.length === 0) {
    return null;
  }

  return isMobile ? mobileButtonsRender : buttonsRender;
};
