import { type FC } from 'react';

import { type AlertProps, Alert as AntAlert } from 'antd';
import styled from 'styled-components';

import { centerFlex, useBreakpoint } from '../../../utils/style';
import { SmallTextBoldVariationSettings, SmallTextStyles } from '../Typography';

// [Antd Typings] Ant'd internal types are not exported, need to re-type.
export const Alert = styled<FC<AlertProps>>(AntAlert)`
  &,
  .ant-alert {
    width: 100%;
    height: 32px;
    padding: ${({ theme }) =>
      `${theme.spacing.xs * 1.5}px ${theme.spacing.sm}px`};
    border-radius: ${({ theme }) => theme.borderRadius.md}px;
    align-items: center;

    ${({ theme }) => useBreakpoint(theme).mobile`
      height: 26px;
    `}
    .ant-alert-content {
      display: flex;
      align-items: center;
    }

    .ant-alert-message,
    .ant-alert-description {
      margin: 0;
      ${SmallTextStyles}
    }

    .ant-alert-message {
      ${SmallTextBoldVariationSettings}
    }

    .ant-alert-description {
      margin-left: ${({ theme, message }) =>
        message ? theme.spacing.sm : 0}px;
    }

    .ant-alert-close-icon {
      ${centerFlex};
      /* The height for this icon must be equal to the line height of the message */
      height: ${({ theme }) => theme.typography.height.xs}px;
      margin-right: ${({ theme }) => theme.spacing.sm}px;
      margin-left: 0;

      ${({ theme }) => useBreakpoint(theme).mobile`
        /* The height for this icon must be equal to the line height of the message */
        height: ${({ theme }) => theme.typography.height.xxs}px;
      `}
    }

    &.ant-alert-info {
      background: ${({ theme }) =>
        theme.color.info.backgrounds.secondaryDefault};
      border-color: transparent;

      .ant-alert-message,
      .ant-alert-description,
      svg {
        color: ${({ theme }) => theme.color.info.typography.primary};
      }
    }

    &.ant-alert-success {
      background: ${({ theme }) =>
        theme.color.success.backgrounds.secondaryDefault};
      border-color: transparent;

      .ant-alert-message,
      .ant-alert-description,
      svg {
        color: ${({ theme }) => theme.color.success.typography.primary};
      }
    }

    &.ant-alert-warning {
      background: ${({ theme }) => theme.color.general.layout.two};
      border-color: transparent;

      .ant-alert-message,
      .ant-alert-description,
      svg {
        color: ${({ theme }) => theme.color.general.typography.secondary};
      }
    }

    &.ant-alert-error {
      background: ${({ theme }) =>
        theme.color.error.backgrounds.secondaryDefault};
      border-color: transparent;

      .ant-alert-message,
      .ant-alert-description,
      svg {
        color: ${({ theme }) => theme.color.error.typography.primary};
      }
    }
  }
`;

export const IconWrap = styled.div`
  ${centerFlex};
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing.xs}px;
  margin-right: ${({ theme }) => theme.spacing.sm}px !important;
`;
