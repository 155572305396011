import React, { useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useLayoutConfig } from '@npm/core/ui/components/organisms/Layout';
import { CypressDataIds } from '@npm/core/ui/constants';

import { useUserContextStore } from '../../../../auth/user/context';
import { SelectWatchlistModal } from '../../../../dashboard';
import { useOrderPlacementDrawerContext } from '../OrderPlacementDrawer/OrderPlacementDrawer.context';

import * as S from './PlaceAnOrderButton.styles';

type Props = {
  onClick: () => void;
  isIndividualNpmsBuyerInvestor?: boolean | null;
};

export const PlaceAnOrderButton = ({
  onClick,
  isIndividualNpmsBuyerInvestor,
}: Props) => {
  const [visible, setIsVisible] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { obo, isInvestor }] = useOrderPlacementDrawerContext();

  const { isMobile, isTablet } = useLayoutConfig();
  const block = isMobile || isTablet;

  const { sponsorshipType: isSponsored, isSpouseOrGuestRolesOnly } =
    useUserContextStore(store => ({
      sponsorshipType: !!store.sponsorshipType,
      isSpouseOrGuestRolesOnly: store.isSpouseOrGuestRolesOnly,
    }));

  if (isInvestor && isIndividualNpmsBuyerInvestor) {
    return (
      <>
        <S.ButtonsContainer>
          <Button
            variant={isSponsored ? 'default' : 'outline'}
            color="success"
            onClick={onClick}
            blockOnMobile
          >
            Buy
          </Button>
          <Button
            variant={isSponsored ? 'default' : 'outline'}
            color="error"
            onClick={() => setIsVisible(true)}
            blockOnMobile
          >
            Sell
          </Button>
        </S.ButtonsContainer>
        <SelectWatchlistModal
          isOpen={visible}
          onCancel={() => setIsVisible(false)}
        />
      </>
    );
  }

  if (isInvestor || obo) {
    return (
      <Button
        data-cy={CypressDataIds.OrderEntryDrawer.Open}
        icon={<Icon name="arrows-opposite" />}
        onClick={onClick}
        block={block}
        disabled={isSpouseOrGuestRolesOnly}
      >
        {isInvestor ? 'Enter IOI' : 'Place an Order'}
      </Button>
    );
  }

  return (
    <S.TradeButton
      data-cy={CypressDataIds.OrderEntryDrawer.Open}
      block={isMobile || isTablet}
      onClick={onClick}
      icon={<Icon name="arrows-opposite" />}
    >
      Trade
    </S.TradeButton>
  );
};
