import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon as IconBase } from '@npm/core/ui/components/atoms/Icon';
import { Select } from '@npm/core/ui/components/atoms/Select';
import {
  Text as BaseText,
  Heading,
} from '@npm/core/ui/components/atoms/Typography';
import { Card } from '@npm/core/ui/components/molecules/Card';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const SelectOption = styled(Select.Option)`
  line-height: ${({ theme }) => theme.typography.height.sm}px;
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
  && .ant-select-item-option-content {
    font-size: ${({ theme }) => theme.typography.size.sm}px;
  }
`;

export const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

export const Text = styled(BaseText)`
  margin-top: ${({ theme }) => theme.spacing.xs}px;
`;

export const Nobr = styled.span`
  white-space: nowrap;
`;

export const TitleContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xl}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${({ theme }) => useBreakpoint(theme).tablet`
    grid-template-columns: 1fr;
    
    /* This will make the second child appear first */
    & > *:first-child {
      order: 2;
    }
    
    /* This will make the first child appear second */
    & > *:nth-child(2) {
      order: 1;
    }
  `}
  padding-left: ${({ theme }) => theme.spacing.sm}px;
  padding-right: ${({ theme }) => theme.spacing.sm}px;
`;

export const MobileHeading = styled(Heading).attrs({
  variant: 'h1',
  marginBottom: 'md',
})`
  padding-left: ${({ theme }) => theme.spacing.sm}px;
  padding-right: ${({ theme }) => theme.spacing.sm}px;
`;

export const TextCard = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.xxl}px
    ${({ theme }) => theme.spacing.lg}px 0 ${({ theme }) => theme.spacing.lg}px;
  ${({ theme }) => useBreakpoint(theme).tablet`
    padding: ${({ theme }) => theme.spacing.lg}px 0 ${({ theme }) => theme.spacing.lg}px 0;
  `}
`;

export const ImageCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.lg}px;
  ${({ theme }) => useBreakpoint(theme).tablet`
    padding: ${({ theme }) => theme.spacing.lg}px ${({ theme }) => theme.spacing.xs}px  ${({ theme }) => theme.spacing.lg}px ${({ theme }) => theme.spacing.xs}px;
  `}
`;

export const ImageCardContent = styled(Flex).attrs({
  direction: 'column',
})`
  gap: ${({ theme }) => theme.spacing.xl}px;
  ${({ theme }) => useBreakpoint(theme).tablet`
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.spacing.sm}px;
  `}
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => useBreakpoint(theme).tablet`
    width: 70%;
  `}
  ${({ theme }) => useBreakpoint(theme).mobile`
    width: 90%;
  `}
`;

export const Icon = styled(IconBase)`
  flex-shrink: 0;
`;
