import { type ChartType, type Plugin } from 'chart.js';

type Options = {
  color?: string;
  indexes?: number[];
};

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    highlightBarColumns?: Options;
  }
}

/**
 * Plugin to highlight specific columns of a bar chart.
 * Pass the indexes of the columns to highlight in options.indexes.
 * If there are more data points than ticks, the plugin will not highlight anything.
 * The highlight is static, i.e. not bound to hover events.
 */
export const HighlightPlugin: Plugin = {
  id: 'highlightBarColumns',
  beforeDatasetsDraw: function (chart, foo, options?: Options) {
    try {
      const color = options?.color || 'rgba(0,0,0, 0.1)';
      const indexes = options?.indexes || [];
      const axeX = chart.scales.x;

      const axeY = chart.scales.y;
      const ctx = chart.ctx;

      const maxDatasetLength = Math.max(
        0,
        ...(chart.data?.datasets ?? []).map(dataset => dataset.data.length)
      );

      if (maxDatasetLength > axeX!.ticks!.length) {
        // Do not highlight if there are more data points than ticks
        return;
      }

      for (const index of indexes) {
        if (index >= 0 && index < axeX!.ticks!.length) {
          // If the index is valid, locate the tick
          const tickPosition = axeX!.getPixelForTick(index);
          const width =
            index > 0
              ? tickPosition - axeX!.getPixelForTick(index - 1)
              : axeX!.ticks.length > 1
                ? axeX!.getPixelForTick(index + 1) - tickPosition
                : axeX!.width;
          const x = tickPosition;
          const y = axeY!.getPixelForValue(axeY!.max);
          const height = axeY!.getPixelForValue(axeY!.min) - y;

          // Drawer a rectangle on the canvas before drawing the datasets
          // so that it is behind the bars
          ctx.fillStyle = color;
          ctx.fillRect(x - width / 4, y, width / 2, height);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
};
