import React, { type ComponentProps } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { CbBeneficialOwnerRole } from '@npm/data-access';
import { type FormProps } from 'antd/lib/form';

import { ContactInformationForm } from '../../../../ContactInformation';
import { type BeneficialOwnersIndividual } from '../../../BeneficialOwnership.types';
import { InfoTooltip } from '../../InfoTooltip';
import { SpacedFormItem } from '../SpacedFormItem';

type Props = Omit<ComponentProps<typeof Drawer>, 'title'> & {
  onAddIndividual: (v: BeneficialOwnersIndividual) => Promise<void>;
  controlPersonOnly?: boolean;
  disableControlPerson?: boolean;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const AddIndividualDrawer = ({
  onAddIndividual,
  controlPersonOnly,
  disableControlPerson,
  isLoading,
  onClose,
  handleValuesChange,
  ...rest
}: Props) => {
  const [form] = Form.useForm<BeneficialOwnersIndividual>();

  const finish = async (values: BeneficialOwnersIndividual) => {
    try {
      await onAddIndividual(values);
      form.resetFields();
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Drawer
      {...rest}
      onClose={onClose}
      title="Add Individual"
      submitButtonProps={{
        onClick: form.submit,
        children: 'Submit',
        loading: isLoading,
      }}
      isFullHeight
      destroyOnClose
    >
      <Form<BeneficialOwnersIndividual>
        form={form}
        onFinish={finish}
        onFinishFailed={e => handleValidationError(form, e)}
        onValuesChange={handleValuesChange}
      >
        <SpacedFormItem
          name="role"
          label={
            <Flex gap="xs">
              <Text>Role</Text>
              <InfoTooltip title="Choose the role that best describes their position in the category previously selected" />
            </Flex>
          }
          initialValue={
            (controlPersonOnly
              ? CbBeneficialOwnerRole.items.controlPerson
              : undefined) ||
            (disableControlPerson
              ? CbBeneficialOwnerRole.items.beneficialOwner
              : undefined)
          }
        >
          <RadioGroup disabled={controlPersonOnly || disableControlPerson}>
            <Flex direction="column">
              <Radio
                value={CbBeneficialOwnerRole.items.controlPerson}
                label="Control Person"
              />
              <Radio
                value={CbBeneficialOwnerRole.items.beneficialOwner}
                label="Beneficial Owner"
              />
              <Radio
                value={CbBeneficialOwnerRole.items.both}
                label="Control Person & Beneficial Owner"
              />
            </Flex>
          </RadioGroup>
        </SpacedFormItem>
        <SpacedFormItem name="first" label="First Name">
          <Input placeholder="First Name" />
        </SpacedFormItem>
        <SpacedFormItem required={false} name="middle" label="Middle Name">
          <Input placeholder="Middle Name" />
        </SpacedFormItem>
        <SpacedFormItem name="last" label="Last Name">
          <Input placeholder="Last Name" />
        </SpacedFormItem>
        <ContactInformationForm inputLayout="vertical" form={form} />
      </Form>
    </Drawer>
  );
};
