import { type ComponentProps } from 'react';

import { ButtonCard } from '@npm/core/ui/components/atoms/ButtonCard';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { CbAccountUiMode } from '@npm/data-access';
import { Radio } from 'antd';
import type { FormInstance } from 'antd/es/form/Form';

import { WatchlistInput } from '../../account/WatchlistInput';
import { BenefitsCard } from '../BenefitsCard';

import * as S from './TradingModeModal.styles';
import type { TradingModeFormValues } from './TradingModeModal.hooks';

type Props = {
  isOpen: boolean;
  form: FormInstance<TradingModeFormValues>;
  initialValues: TradingModeFormValues;
  hasExistingWatchlist: boolean;
  watchlistLoading?: boolean;
} & ComponentProps<typeof Modal>;

export const TradingModeModal = ({
  isOpen,
  form,
  initialValues,
  hasExistingWatchlist,
  watchlistLoading,
  ...modalProps
}: Props) => {
  const selectedAccountUiMode = Form.useWatch('account_ui_mode', form);

  const isWatchlistFieldHidden =
    selectedAccountUiMode !== 'seller' || hasExistingWatchlist;

  return (
    <Modal
      open={isOpen}
      title="Select Trading Mode"
      okText="Select Mode"
      cancelText="Close"
      destroyOnClose
      {...modalProps}
    >
      <Form initialValues={initialValues} form={form}>
        <FormItem name="account_ui_mode" marginBottom="lg">
          <Radio.Group
            defaultValue={initialValues?.account_ui_mode}
            style={{ display: 'flex' }}
          >
            <Flex gap="sm" style={{ flex: '1' }}>
              <S.ButtonCardContainer>
                <ButtonCard
                  variant="radio"
                  value={CbAccountUiMode.items.buyer_and_seller}
                  label="Investor mode"
                  showDefaultInput
                />
              </S.ButtonCardContainer>
              <S.ButtonCardContainer>
                <ButtonCard
                  variant="radio"
                  value={CbAccountUiMode.items.seller}
                  label="Seller only"
                  showDefaultInput
                />
              </S.ButtonCardContainer>
            </Flex>
          </Radio.Group>
        </FormItem>
        <FormItem
          name={'watchlist'}
          label={"Which company's stock do you own?"}
          rules={
            isWatchlistFieldHidden
              ? []
              : [
                  VALIDATION_RULES.arrayMinLength(
                    1,
                    'Please select at least one company'
                  ),
                ]
          }
          extra={
            <Text size={'sm'} colorVariant={'secondary'}>
              Browse through the pre-IPO companies we work with
            </Text>
          }
          marginBottom="md"
          hidden={isWatchlistFieldHidden}
        >
          {watchlistLoading ? (
            <Skeleton.Base paragraph={false} />
          ) : (
            <WatchlistInput mode="multiple" />
          )}
        </FormItem>
        <BenefitsCard accountType={selectedAccountUiMode} />
      </Form>
    </Modal>
  );
};
