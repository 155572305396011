import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { LAYOUT_WRAPPER_ID } from '@npm/core/ui/components/organisms/Layout';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  CbDocumentType,
  CbOwnerType,
  type IssuerEntityAggregate,
  useDocumentIndex,
} from '@npm/data-access';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../documents/DocumentViewer';
import { DrawerTitle } from '../../Drawer/components';

import { ProofOfOwnershipItem } from './components/ProofOfOwnershipItem';

export type ProofOfOwnershipDrawerProps = {
  holdingId?: number;
  issuerEntity?: IssuerEntityAggregate;
  open: boolean;
  onClose: () => void;
};

export const ProofOfOwnershipDrawer = ({
  holdingId,
  onClose,
  open,
  issuerEntity,
}: ProofOfOwnershipDrawerProps) => {
  const { isMobile } = useBreakpoints();

  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  const { data, isLoading } = useDocumentIndex(
    {
      ownerId: holdingId,
      ownerType: CbOwnerType.items.Holding,
      type: CbDocumentType.items.proof_of_ownership_document,
    },
    {
      queryConfig: { enabled: Boolean(holdingId) },
    }
  );

  const closeDrawer = () => {
    onClose?.();
  };

  return (
    <>
      <Drawer
        getContainer={() =>
          isMobile
            ? document.body
            : document.getElementById(LAYOUT_WRAPPER_ID) || document.body
        }
        title={
          <DrawerTitle
            title={'Proofs of Ownership'}
            withCompanyLogo
            issuerEntity={issuerEntity}
          />
        }
        open={open}
        onClose={closeDrawer}
        isLoading={isLoading}
        submitButtonProps={{
          children: 'Close',
          onClick: closeDrawer,
        }}
      >
        <Flex direction="column" gap={'md'}>
          {data?.documents?.map(doc => (
            <ProofOfOwnershipItem
              key={doc.id}
              doc={doc}
              onClick={showNpmDocument}
            />
          ))}
        </Flex>
      </Drawer>
      <DocumentViewerModal {...documentViewerModalProps} />
    </>
  );
};
