import { Text } from '../../atoms/Typography';

import * as S from './PriceCard.styles';

type Props = {
  value: number | undefined;
  title: string;
};

export const PriceCard = ({ value, title }: Props) => {
  const hasValue = value != null;

  return (
    <S.PriceCardWrapper $highlighted={hasValue}>
      <Text.Price
        size={'xl'}
        value={value ?? 0}
        currencyAsSymbol
        showZeroAsEmpty
        zeroColorVariant={'tertiary'}
        formatOptions={{ minimumFractionDigits: 0 }}
      />

      <Text
        size={'sm'}
        colorVariant={hasValue ? 'primary' : 'tertiary'}
        align={'center'}
      >
        {title}
      </Text>
    </S.PriceCardWrapper>
  );
};
