import { type ReactNode, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { CardWithBackground } from '@npm/core/ui/components/molecules/CardWithBackground';

import * as S from './BasicPageLayout.styles';
import { getCompanyDataAsset } from './BasicPageLayout.config';
import { type BasicPageLayoutCardType } from './BasicPageLayout.types';
import { PremiumFeatureLabel } from './PremiumFeatureLabel';
import { ReadMoreModal } from './ReadMoreModal';

export type BasicPageMainCardProps = BasicPageLayoutCardType & {
  imagesSuffix: 'dark' | 'light';
  layoutConfig: {
    mainBgImagePosition: string;
    mainBgImageSize: string;
    mainCardShowUpgradeButton: boolean;
  };
  requestPremiumMembership: () => void;
  readMoreModal?: {
    title: string;
    description: ReactNode;
    imagePrefix: string;
  };
  titleMaxWidth?: string;
  showUpgradeButton?: boolean;
};

export const BasicPageMainCard = ({
  layoutConfig,
  imagePrefix,
  imagesSuffix,
  title,
  titleMaxWidth,
  description,
  requestPremiumMembership,
  readMoreModal,
  showUpgradeButton = false,
}: BasicPageMainCardProps) => {
  const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState(false);

  return (
    <CardWithBackground
      size={layoutConfig.mainBgImageSize}
      position={layoutConfig.mainBgImagePosition}
      src={getCompanyDataAsset(imagePrefix, imagesSuffix)}
    >
      <S.MainCardContentWrapper direction="column" gap="lg">
        <PremiumFeatureLabel />
        <Heading variant="h0" style={{ maxWidth: titleMaxWidth }}>
          {title}
        </Heading>
        {typeof description === 'string' ? (
          <Text size="sm">{description}</Text>
        ) : (
          description
        )}

        <Flex gap="sm">
          <Button variant="default" onClick={requestPremiumMembership}>
            Request Trial
          </Button>
          {layoutConfig.mainCardShowUpgradeButton && showUpgradeButton && (
            <Button variant="outline" onClick={requestPremiumMembership}>
              Upgrade to Premium
            </Button>
          )}
          {readMoreModal && (
            <>
              <Button
                variant="text"
                onClick={() => setIsReadMoreModalOpen(true)}
              >
                Read More
              </Button>
              <ReadMoreModal
                {...readMoreModal}
                imagesSuffix={imagesSuffix}
                requestPremiumMembership={requestPremiumMembership}
                open={isReadMoreModalOpen}
                onCancel={() => setIsReadMoreModalOpen(false)}
              />
            </>
          )}
        </Flex>
      </S.MainCardContentWrapper>
    </CardWithBackground>
  );
};
