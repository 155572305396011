import { Flex } from '@npm/core/ui/components/atoms/common';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { css } from 'styled-components';

const Container = styled.div`
  & .ant-upload-drag {
    margin-bottom: ${({ theme }) => theme.spacing.md}px;
  }

  && .ant-upload-btn {
    width: 100%;
    display: flex;
  }
  .ant-upload-drag-container {
    width: 100%;
  }
`;

const InnerContainer = styled(Flex)(
  ({ theme: { spacing } }) => css`
    padding: ${spacing.sm}px ${spacing.md}px;
    ${({ theme }) => useBreakpoint(theme).mobile`
      padding: ${spacing.sm}px;
  `}
  `
);

const FileItemsContainer = styled(Flex)(
  ({ theme: { spacing } }) => css`
    width: 100%;
    margin-top: ${spacing.sm}px;
  `
);

const CheckContainer = styled(Flex)(
  ({ theme: { color, borderRadius } }) => css`
    width: 40px;
    height: 40px;
    border: 2px solid ${color.info.borders.primary};
    border-radius: ${borderRadius.md}px;
  `
);

export const ProofOfOwnershipUploadStyles = {
  Container,
  InnerContainer,
  FileItemsContainer,
  CheckContainer,
};
