import { Flex } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';

import * as S from './OrderCard.styles';

export const OrderCardSkeleton = () => {
  return (
    <S.OrderCardContainer>
      <Skeleton.Input size="small" />
      <S.PriceRowWrapper>
        <Skeleton.Input block />
      </S.PriceRowWrapper>
      <Flex justify="space-between">
        <Skeleton.Input size="small" />
        <Skeleton.Button />
      </Flex>
    </S.OrderCardContainer>
  );
};
