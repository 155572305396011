import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { FileUpload } from '@npm/core/ui/components/atoms/FileUpload';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { Text } from '@npm/core/ui/components/atoms/Typography/Text';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type FormInstance, type FormProps } from 'antd/es/form/Form';

import { type OnboardingAgreementType } from '../../OnboardingAgreements.types';

import * as S from './OnboardingAgreementUploadForm.styles';

export type OnboardingAgreementUploadFormType = {
  file: File;
};

type Props = {
  type: OnboardingAgreementType;
  title: React.ReactNode;
  form: FormInstance<OnboardingAgreementUploadFormType>;
  variant?: 'upload' | 'replace';
  handleCancelReplace?: () => void;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const OnboardingAgreementUploadForm = ({
  type,
  title,
  form,
  variant = 'upload',
  handleCancelReplace,
  handleValuesChange,
}: Props) => {
  const { isMobile } = useBreakpoints();

  return (
    <S.Card>
      <Heading variant="h2" marginBottom="md">
        {variant === 'upload'
          ? 'To complete this section, please provide the applicable signed agreement.'
          : `You can replace the ${title} by submitting a new file`}
      </Heading>
      <Text size="sm" marginBottom="md">
        Note that clients are not able to upload existing agreements, but may
        instead sign a new document online.
      </Text>
      <Text size="xs" marginBottom="sm">
        The file must be a PDF, JPG, or PNG.
      </Text>
      <Flex
        gap="sm"
        direction={isMobile ? 'column' : 'row'}
        align={isMobile ? 'stretch' : 'flex-start'}
        style={{ width: '100%' }}
      >
        <Form form={form} onValuesChange={handleValuesChange}>
          <InputFormItem
            name="file"
            rules={[VALIDATION_RULES.required()]}
            validateTrigger="onSubmit"
          >
            <FileUpload label={`Upload ${title}`} buttonVariant="outline" />
          </InputFormItem>
        </Form>
        {variant === 'replace' && (
          <Button
            icon={<Icon name="x-close" />}
            variant="text"
            onClick={() => {
              handleCancelReplace();
              form.resetFields();
            }}
          >
            Cancel Upload
          </Button>
        )}
      </Flex>
    </S.Card>
  );
};
