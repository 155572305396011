import { type LabelVariant, type CbLabelComponent, type CbLabelProps, type IconNames } from '@npm/utils';
import { type CodebookItem, Codebooks } from './codebooks.types';

const Items = {
  incomplete: 'incomplete',
  not_started: 'not_started',
  provided: 'provided',
} as const;

type Keys = keyof typeof Items;


const LabelMap: Record<Keys, LabelVariant> = {
  incomplete: 'general',
  not_started: 'general',
  provided: 'success',
};

const LabelIconMap: Record<Keys, IconNames> = {
  incomplete: 'info-circle',
  not_started: 'info-circle',
  provided: 'check',
};

export const CbAccountOnboardingTAndS = {
  code: Codebooks.ACCOUNT_ONBOARDING_T_AND_S,
  items: Items,
   getLabel: (
      Component: CbLabelComponent,
      codebookItem: CodebookItem | undefined,
      labelProps?: Partial<CbLabelProps>
    ) => Component({
        ...labelProps,
        variant: LabelMap[(codebookItem?.code as Keys) ?? 'not_started'],
        label: codebookItem?.name ?? 'Not Started',
        iconProps: { name: LabelIconMap[(codebookItem.code as Keys) ?? 'not_started'] },
      })
};
