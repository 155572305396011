import { HAMBURGER_BUTTON_DIMENSION } from '@npm/core/ui/components/organisms/Layout/HamburgerMenu/HamburgerButton/HamburgerButton.styles';
import {
  SM_HEADER_DESKTOP_HEIGHT,
  SM_HEADER_MOBILE_HEIGHT,
  SM_HEADER_TABLET_HEIGHT,
} from '@npm/core/ui/hooks/useSMHeaderHeight';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { css } from 'styled-components';

import { CompanySearch } from '../../filters';

const COMPANY_SELECT_WIDTH_DESKTOP = 226;
const COMPANY_SELECT_WIDTH_SM_DESKTOP = 320;

export const Container = styled.div<{
  $appHeaderHeight?: number;
}>`
  ${({ theme, $appHeaderHeight }) => css`
    position: sticky;
    z-index: ${theme.zIndex.fixed};
    top: ${$appHeaderHeight}px;
    display: flex;
    min-height: ${SM_HEADER_DESKTOP_HEIGHT}px;
    padding: 0 ${theme.spacing.md}px;
    border-bottom: 1px solid ${theme.color.general.borders.primary};
    background-color: ${theme.color.general.layout.zero};

    > div {
      display: flex;
      align-items: center;
    }

    ${Middle} {
      padding: 0 ${theme.spacing.xl}px;
      overflow-y: auto;
    }

    ${Right} {
      flex-basis: 350px;
      display: flex;
      justify-content: right;
      flex: 1;
    }

    ${() => useBreakpoint(theme).desktop`${css`
      ${Middle} {
        padding: 0 ${theme.spacing.sm}px;
      }
    `}
    `};

    ${() =>
      useBreakpoint(theme).smDesktop`${css`
        min-height: ${SM_HEADER_TABLET_HEIGHT}px;
        padding: 0;
        flex-wrap: wrap;
        padding: ${theme.spacing.md}px;
        padding-bottom: 0;

        ${Middle} {
          margin-top: auto;
          height: ${SM_HEADER_DESKTOP_HEIGHT}px; // 60px
          width: 100%;
          order: 3;
          padding: 0;
        }

        ${Right} {
          order: 2;
          flex: 0 1 auto;
          justify-content: right;
          margin-left: auto;
          text-align: right;
        }
      `}`};

    ${() =>
      useBreakpoint(theme).mobile`${css`
        min-height: ${SM_HEADER_MOBILE_HEIGHT}px;
        padding: ${theme.spacing.sm}px;
        padding-bottom: 0;

        > div {
          display: -webkit-box;
          align-items: center;
        }

        ${Right} {
          height: auto;
          display: block;
          padding-right: 0;
        }
      `}
    `};
  `}
`;

export const Left = styled.div``;

export const Middle = styled.div``;

export const Right = styled.div``;

export const CompanySelect = styled(CompanySearch)`
  ${({ theme }) => useBreakpoint(theme).desktop`
    width: ${COMPANY_SELECT_WIDTH_DESKTOP}px;
  `}

  ${({ theme }) => useBreakpoint(theme).smDesktop`
    width: ${COMPANY_SELECT_WIDTH_SM_DESKTOP}px;
  `}

  width: ${COMPANY_SELECT_WIDTH_SM_DESKTOP}px;
  max-width: calc(
    100vw - ${HAMBURGER_BUTTON_DIMENSION}px -
      ${({ theme }) => theme.spacing.lg}px
  );
`;

export const IndividualCompanySelect = styled.div`
  ${({ theme }) => useBreakpoint(theme).desktop`
    width: ${COMPANY_SELECT_WIDTH_DESKTOP}px;
  `}

  ${({ theme }) => useBreakpoint(theme).smDesktop`
    width: ${COMPANY_SELECT_WIDTH_SM_DESKTOP}px;
  `}

  width: ${COMPANY_SELECT_WIDTH_SM_DESKTOP}px;
  max-width: calc(
    100vw - ${HAMBURGER_BUTTON_DIMENSION}px -
      ${({ theme }) => theme.spacing.lg}px
  );
`;
