import { Flex } from '@npm/core/ui/components/atoms/common';
import styled from 'styled-components';

export const AffiliateDisclosureToolTipLabelFlex = styled(Flex).attrs({
  align: 'center',
})`
  height: 100%;
`;

export const AffiliateDisclosureToolTipLabel = ({
  label,
}: {
  label: string;
}) => {
  return (
    <AffiliateDisclosureToolTipLabelFlex>
      {label}
    </AffiliateDisclosureToolTipLabelFlex>
  );
};
