import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

import {
  getFocusBorderStyles,
  preserveHorizontalFocusBorder,
  useBreakpoint,
} from '../../../utils/style';
import { Heading3Styles, SmallTextStyles } from '../../atoms/Typography';
import {
  Drawer as BaseDrawer,
  DRAWER_CONTENT_ID,
} from '../../organisms/Drawer';
import { type useLayoutConfig } from '../../organisms/Layout';

export const Modal = styled(AntModal)<{ $maxHeight?: number }>`
  .ant-modal-close {
    color: ${({ theme }) => theme.color.general.typography.secondary};
    body.tab-focus ${getFocusBorderStyles()};

    .ant-modal-close-x {
      width: auto;
      height: auto;
      line-height: 0;
      padding: ${({ theme }) => theme.spacing.md}px;
      ${({ theme }) => useBreakpoint(theme).mobile`
      padding-top: ${theme.spacing.sm}px;
    `}
    }
  }

  .ant-modal-header {
    background: ${({ theme }) => theme.color.general.layout.one};
    padding: ${({ theme }) => `${theme.spacing.lg / 2}px 0`};
    border: none;

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding: ${theme.spacing.sm}px 0;
    `}

    .ant-modal-title {
      max-width: calc(100% - 25px);
      ${Heading3Styles};
    }
  }

  .ant-modal-content {
    background: ${({ theme }) => theme.color.general.layout.one};
    padding: 0 ${({ theme }) => theme.spacing.md}px;

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding: 0 ${theme.spacing.sm}px;
    `}

    border-radius: ${({ theme }) => theme.borderRadius.md}px;
  }

  .ant-modal-body {
    ${SmallTextStyles};
    background: ${({ theme }) => theme.color.general.layout.one};
    padding: ${({ theme }) => theme.spacing.md}px 0;
    ${preserveHorizontalFocusBorder};
    max-height: ${({ $maxHeight }) => $maxHeight}px;
    overflow: auto;

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding: ${theme.spacing.sm}px 0;
    `}
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: ${({ theme }) => theme.spacing.md}px 0;
    border: none;

    & .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin-left: ${({ theme }) => theme.spacing.md}px;
    }

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding: ${theme.spacing.md}px 0 ${theme.spacing.sm}px;
      flex-wrap: wrap-reverse;

      & > .ant-btn {
        width: 100%;

        :not(:only-child):first-child {
          margin-top:  ${({ theme }) => theme.spacing.sm}px;
        }
      }

      & .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: initial;
      }
    `}
  }
`;

export const Drawer = styled(BaseDrawer)<{
  $layoutConfig: ReturnType<typeof useLayoutConfig>;
}>`
  top: ${({ $layoutConfig }) => $layoutConfig.header.height}px;
  height: calc(100% - ${({ $layoutConfig }) => $layoutConfig.header.height}px);

  && .ant-drawer-content-wrapper {
    // TODO: this doesn't match the designs, the box shadow comes from app header and is stronger
    box-shadow: ${({ theme }) => theme.shadow.level2};
  }

  #${DRAWER_CONTENT_ID} {
    padding-top: ${({ theme }) => theme.spacing.md}px;
    padding-left: ${({ theme }) => theme.spacing.md}px;
    padding-right: ${({ theme }) => theme.spacing.md}px;

    ${({ theme }) => useBreakpoint(theme).mobile`
      padding-top: ${theme.spacing.sm}px;
      padding-left: ${theme.spacing.sm}px;
      padding-right: ${theme.spacing.sm}px;
    `}
  }
`;
