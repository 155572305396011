import { type CbBankAccountType } from '@npm/data-access';

const ABA_NUMBER_FIELD_ENUM = 'aba_number';
export const ALTERNATE_BENEFICIARY_ENUM = 'alternate_beneficiary';

export const COMMON_FORM_FIELDS_ENUMS = {
  BANK_ACCOUNT_TYPE: 'type',
  BANK_NAME: 'name',
  ACCOUNT_NUMBER: 'account_number',
  BENEFICIARY_NAME: 'beneficiary_name',
  ADDITIONAL_INSTRUCTIONS: 'additional_instructions',
};

export const DOMESTIC_FORM_FIELDS_ENUM = {
  ...COMMON_FORM_FIELDS_ENUMS,
  ABA_NUMBER: ABA_NUMBER_FIELD_ENUM,
} as const;

export const BROKERAGE_FORM_FIELDS_ENUM = {
  ...COMMON_FORM_FIELDS_ENUMS,
  ABA_NUMBER: ABA_NUMBER_FIELD_ENUM,
  BROKERAGE_ACCOUNT: 'brokerage_account',
  BROKERAGE_NAME: 'brokerage_name',
};

export const INTERNATIONAL_FORM_FIELDS_ENUM = {
  ...COMMON_FORM_FIELDS_ENUMS,
  REQUIRE_INTERMEDIARY_BANK: 'require_intermediary_bank',
  SWIFT_CODE: 'swift_code',
  IBAN: 'iban',
  INTERMEDIARY_ROUTING_TYPE: 'intermediary_routing_type',
  INTERMEDIARY_ROUTING_NUMBER: 'intermediary_routing_number',
  INTERMEDIARY_BANK_NAME: 'intermediary_bank_name',
};

type CbBankAccountItems = (typeof CbBankAccountType)['items'];
export type CbBankAccountItemCode =
  CbBankAccountItems[keyof CbBankAccountItems];
