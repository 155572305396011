import React from 'react';

import { chunk } from 'lodash';

import { type ExtendedColumnType } from '../Table.types';

import * as S from './ExpandableArea.styles';

type Props<T> = {
  columns: ExtendedColumnType<T>[];
  record: T;
  variant?: 'primary' | 'secondary' | 'compact';
  showExpandedContentInColumns?: boolean;
  tableVerticalClassName?: string;
};

export const ExpandableArea = <T,>({
  columns,
  record,
  variant,
  showExpandedContentInColumns,
  tableVerticalClassName,
}: Props<T>) => {
  const filtered = columns.filter(({ hidden }) =>
    typeof hidden === 'function' ? !hidden(record) : !hidden
  );

  const rows = filtered.map(({ key, title, titleInfo, render }) => ({
    key,
    title,
    titleInfo,
    render: () => render?.(record, -1),
  }));

  if (showExpandedContentInColumns) {
    const [leftColumn, rightColumn] = chunk(rows, Math.ceil(rows.length / 2));
    return (
      <S.Container>
        <S.PaddedColumnsContainer
          columns={[
            <S.TableVertical
              $variant={variant}
              rows={leftColumn ?? []}
              data={undefined}
              key="leftColumn"
            />,
            <S.TableVertical
              $variant={variant}
              rows={rightColumn ?? []}
              data={undefined}
              key="rightColumn"
            />,
          ]}
        />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.TableVertical
        $variant={variant}
        rows={rows}
        data={undefined}
        borderVariant="secondary"
        className={tableVerticalClassName}
      />
    </S.Container>
  );
};
