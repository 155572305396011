type PathMetadata = typeof onboardingGeneralMetadata;

export const registerOnboardingGeneralMetadata = (metadata: PathMetadata) => {
  onboardingGeneralMetadata.forEach(m => {
    metadata.push(m);
  });
};

export const onboardingGeneralMetadata = [
  {
    pathRegex: /.*post-onboarding.*[&?]step=entity_tax_state.*/,
    metadata: {
      screenId: 'NFE-631',
      screenName: 'Postonboarding wizard - Entity Tax ID (UCI163)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=tax_information_state.*/,
    metadata: {
      screenId: 'NFE-630',
      screenName: 'Postonboarding wizard - Tax Information (UCI162)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=spouse_information_state.*/,
    metadata: {
      screenId: 'NFE-637',
      screenName: 'Postonboarding wizard - Spousal Information (UCI169)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=individual_tax_state.*/,
    metadata: {
      screenId: 'NFE-634',
      screenName: 'Postonboarding wizard - Individual Tax ID (UCI166)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=entity_formation_document_state.*/,
    metadata: {
      screenId: 'NFE-632',
      screenName: 'Postonboarding wizard - Entity Formation Document (UCI164)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=authorized_signer_state.*/,
    metadata: {
      screenId: 'NFE-633',
      screenName: 'Postonboarding wizard - Authorized Sginer (UCI165)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=representative_photo_state.*/,
    metadata: {
      screenId: 'NFE-635',
      screenName: 'Postonboarding wizard - Government-Issued ID (UCI167)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=bank_account_state.*/,
    metadata: {
      screenId: 'NFE-636',
      screenName: 'Postonboarding wizard - Bank Accounts (UCI168)',
    },
  },
  {
    pathRegex:
      /.*post-onboarding.*[&?]step=beneficial_ownership_assessment_state.*/,
    metadata: {
      screenId: 'NFE-999',
      screenName: 'Postonboarding wizard - Beneficial Ownership Assessment',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=paying_agent_agreement_state.*/,
    metadata: {
      screenId: 'NFE-1001',
      screenName: 'Postonboarding wizard - Paying Agent Services Agreement',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=marketplace_agreement_state.*/,
    metadata: {
      screenId: 'NFE-1000',
      screenName: 'Postonboarding wizard - Marketplace Agreement',
    },
  },
  {
    pathRegex: /.*onboarding\/terms-and-conditions\/plain.*/,
    metadata: {
      screenId: 'NFE-620',
      screenName: 'Terms of Use',
    },
  },
  {
    pathRegex: /.*onboarding\/terms-and-conditions.*/,
    metadata: {
      screenId: 'NFE-620',
      screenName: 'Terms of Use',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=account-setup.*/,
    metadata: {
      screenId: 'NFE-621',
      screenName:
        'Investor Onboarding - Selecting Entity/Individual Account (UCI153)',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=entity-information.*/,
    metadata: {
      screenId: 'NFE-622',
      screenName:
        'Investor Onboarding (Entity) - Address + Phone Number (UCI154)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=entity_information_state.*/,
    metadata: {
      screenId: 'NFE-622',
      screenName:
        'Investor Onboarding (Entity) - Address + Phone Number (UCI154)',
    },
  },
  {
    pathRegex: /.*post-onboarding.*[&?]step=personal_information_state.*/,
    metadata: {
      screenId: 'NFE-627',
      screenName:
        'Investor Onboarding (Individual) - Address + Phone Number (UCI154)',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=individual-information.*/,
    metadata: {
      screenId: 'NFE-627',
      screenName:
        'Investor Onboarding (Individual) - Address + Phone Number (UCI154)',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=individual-accreditation.*/,
    metadata: {
      screenId: 'NFE-628',
      screenName:
        'INV Onboarding - step 070: Accreditation (Individual Account)',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=accreditation.*/,
    metadata: {
      screenId: 'NFE-623',
      screenName:
        'Investor Onboarding (Entity) - Accreditation + Accreditation Sign (UCI155)',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=buyer-survey.*/,
    metadata: {
      screenId: 'NFE-624',
      screenName: 'Investor Onboarding - Buyer Survey (UCI156)',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=watchlist.*/,
    metadata: {
      screenId: 'NFE-625',
      screenName: 'Investor Onboarding - Watchlist (UCI157)',
    },
  },
  {
    pathRegex: /.*onboarding.*[&?]step=holdings.*/,
    metadata: {
      screenId: 'NFE-626',
      screenName: 'INV Onboarding - step 110: Holdings',
    },
  },
  {
    pathRegex: /.*post-onboarding*/,
    metadata: {
      screenId: 'NFE-508',
      screenName: 'Investor Onboarding',
    },
  },
  {
    pathRegex: /.*onboarding\/okta-sign\.*/,
    metadata: {
      screenName: 'Okta Sign',
      screenId: 'NFE-650',
    },
  },
];
