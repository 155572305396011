import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon as BaseIcon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  display: inline-flex;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  background: ${({ theme }) => theme.color.general.layout.one};
  padding: ${({ theme }) => theme.spacing.xs}px;
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
`;

export const AssetTypeName = styled(Text).attrs({
  size: 'sm',
  colorVariant: 'secondary',
})`
  &:last-child {
    margin-right: ${({ theme }) => theme.spacing.xs}px;
  }
`;

export const Separator = styled.div`
  color: ${({ theme }) => theme.color.general.typography.tertiary};
`;

export const Icon = styled(BaseIcon)`
  flex-shrink: 0;
`;
