import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Text } from '@npm/core/ui/components/atoms/Typography';

export const CreateAccount = () => {
  return (
    <InputFormItem
      name={'legalName'}
      label="Full Legal Name of Entity"
      rules={[
        VALIDATION_RULES.required('Legal Name'),
        VALIDATION_RULES.min(5),
        VALIDATION_RULES.max(120),
      ]}
      validateTrigger={['onChange', 'onSubmit']}
      extra={
        <Text size={'sm'} colorVariant={'secondary'}>
          Example: “Acme LLC”
        </Text>
      }
    >
      <Input placeholder="Legal Name" />
    </InputFormItem>
  );
};
