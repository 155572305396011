import React from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import {
  INDIVIDUAL_BENEFITS,
  INSTITUTION_BENEFITS,
} from '../dashboard.constants';

import * as S from './BenefitsCard.styles';

interface Props {
  accountType: 'seller' | 'buyer_and_seller';
}

export const BenefitsCard = ({ accountType }: Props) => {
  const { isMobile } = useBreakpoints();

  const isSeller = accountType === 'seller';
  const displayBenefits = isSeller ? INDIVIDUAL_BENEFITS : INSTITUTION_BENEFITS;

  return (
    <S.Card>
      <Flex align="center" gap="xs">
        <Icon name="user" color={isSeller ? 'warning' : 'info'} size="xs" />
        <Heading variant="h2" as="span" color={isSeller ? 'warning' : 'info'}>
          {isSeller
            ? 'Individual / Employee - I’m a Seller Only'
            : 'Individual / Employee - Looking to Buy & Sell'}
        </Heading>
      </Flex>
      <Margin bottom="md" />

      <Flex gap="md" direction={isMobile ? 'column' : 'row'} $wrap>
        {displayBenefits?.map((item, i) => (
          <Flex key={i} gap="sm" align="center">
            <Icon name="check" color="success" size="xxs" />
            <Text size="sm">{item}</Text>
          </Flex>
        ))}
      </Flex>
    </S.Card>
  );
};
