import React, { useState } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  FormItem,
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useConfirmOnLeave } from '@npm/core/ui/components/organisms/DiscardModal';
import { DiscardModal } from '@npm/core/ui/components/organisms/DiscardModal';
import { eventBus } from '@npm/core/ui/utils/eventBus';
import { handleValidationError } from '@npm/core/ui/utils/form';
import { getFullName } from '@npm/core/ui/utils/formatters';
import {
  type AffiliateDisclosureType,
  type IssuerEntityAggregate,
} from '@npm/data-access';
import { type ModalProps } from 'antd';

import { useNpmsAccountUser } from '../../../hooks/useNpmsAccountUser';

import * as S from './AffiliateDisclosureWithSignatureModal.styles';
import { buttonLabels } from './AffiliateDisclosureWithSignatureModal.constants';

export type AffiliateDisclosureValues = {
  affiliateDisclosure: AffiliateDisclosureType;
  signature?: string;
};

type Props = {
  isOpen: boolean;
  onSubmit: (options: AffiliateDisclosureValues) => void;
  reviewTerms: string;
  issuerEntity?: IssuerEntityAggregate;
} & ModalProps;

export const AffiliateDisclosureWithSignatureModal = ({
  isOpen,
  onSubmit,
  reviewTerms,
  issuerEntity,
  onCancel,
}: Props) => {
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const [form] = Form.useForm<AffiliateDisclosureValues>();

  const affiliateDisclosure = Form.useWatch('affiliateDisclosure', form);
  const signature = Form.useWatch('signature', form);

  const npmsAccountUser = useNpmsAccountUser();
  const { first, middle, last } = npmsAccountUser?.person || {};
  const fullLegalName = getFullName(first, last, middle);

  const isSubmitDisabled = !affiliateDisclosure || signature !== fullLegalName;

  const { setHasUnsavedChanges, discardModalProps } = useConfirmOnLeave(
    'AffiliateDisclosureWithSignatureModal'
  );

  const handleSubmit = async () => {
    let values: AffiliateDisclosureValues;

    setSubmitLoading(true);
    try {
      values = await form.validateFields();
    } catch (err) {
      handleValidationError(form, err);
      return;
    }

    try {
      setHasUnsavedChanges(false);
      await onSubmit(values);
      setSubmitLoading(false);
      handleCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseConfirm = () => {
    form.resetFields();
    setHasUnsavedChanges(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        title="Order Terms & Affiliate Disclosure"
        size="lg"
        okText="I Confirm"
        onOk={handleSubmit}
        okButtonProps={{ disabled: isSubmitDisabled, loading: isSubmitLoading }}
        cancelText="Cancel"
        onCancel={onCancel}
      >
        {issuerEntity?.secondmarket_trading === false && (
          <Margin bottom="md">
            <Alert
              message={
                <>
                  <Margin bottom="sm">
                    <Flex gap="xs">
                      <Text size="sm" weight="bold" colorVariant="primary">
                        {issuerEntity?.name}
                      </Text>
                      <Text size="sm" colorVariant="primary">
                        {' does not permit transfers '}
                      </Text>
                      <S.UnderlinedText size="sm" colorVariant="primary">
                        at this time
                      </S.UnderlinedText>
                    </Flex>
                  </Margin>
                  NPM has been informed by {issuerEntity?.name} that all share
                  transfers are being restricted for the time being. Thank you
                  for your understanding and cooperation. We will let you know
                  if anything changes.{' '}
                  <Text
                    color="info"
                    weight="bold"
                    size="sm"
                    onClick={() =>
                      eventBus.dispatch('REQUEST_SUPPORT', {
                        key: 'disabled_trading',
                        title: `${issuerEntity?.name} does not permit transfers at this time`,
                      })
                    }
                  >
                    Contact us with any questions
                  </Text>
                </>
              }
            />
          </Margin>
        )}
        <Text size="sm" colorVariant="secondary" marginBottom="md">
          {reviewTerms}
        </Text>
        <Divider />
        <Margin bottom="sm">
          <Flex align="center" gap="xs">
            <Text size="sm" colorVariant="primary">
              Are you a Company Insider or Affiliate of the Company?
            </Text>
            <TooltipInfoIcon
              title={
                <Text size="sm" colorVariant="primary">
                  <Text
                    as="span"
                    size="sm"
                    colorVariant="primary"
                    weight="bold"
                  >
                    Company Insider
                  </Text>{' '}
                  is any officer or director of the Company or shareholder with
                  more than 10% ownership of the Company. <br />
                  <br />
                  <Text
                    as="span"
                    size="sm"
                    colorVariant="primary"
                    weight="bold"
                  >
                    Affiliate
                  </Text>{' '}
                  is a person that has the power to direct the management and
                  policies of the Company, whether through the ownership of
                  voting securities, by contract, or otherwise.
                </Text>
              }
            />
          </Flex>
        </Margin>
        <Form<AffiliateDisclosureValues>
          form={form}
          onValuesChange={() => setHasUnsavedChanges(true)}
          onFinish={handleSubmit}
        >
          <FormItem
            name="affiliateDisclosure"
            marginBottom="md"
            rules={[VALIDATION_RULES.required()]}
          >
            <RadioGroup>
              {Object.entries(buttonLabels).map(([key, value]) => (
                <Radio key={key} value={key} label={value} />
              ))}
            </RadioGroup>
          </FormItem>
          <InputFormItem
            name="signature"
            label="Your Signature"
            rules={[
              VALIDATION_RULES.matchSpecificValue(
                fullLegalName,
                `Signature must match your full legal name "${fullLegalName}"`
              ),
            ]}
            validateTrigger="onBlur"
            extra={`Your full legal name “${fullLegalName}”`}
            data-dd-privacy="mask"
          >
            <Input placeholder="Your Signature" />
          </InputFormItem>
        </Form>
      </Modal>
      <DiscardModal {...discardModalProps} />
    </>
  );
};
