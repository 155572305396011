// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AccountPreferredBuyerApi,
  AccountPreferredBuyerCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AccountPreferredBuyerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountPreferredBuyerCreateMethod = AccountPreferredBuyerApi['accountPreferredBuyerCreate'];

export const useAccountPreferredBuyerCreate = () => {
  return useMutation<AccountPreferredBuyerCreateMethod>(
    (...args) => api.accountPreferredBuyerCreate(...args),
    AccountPreferredBuyerCreateErrorCodes,
    'AccountPreferredBuyerCreate',
    '/api/accounts/{account_id}/preferred-buyers',
    'post'
  );
};
