import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbBeneficialOwnerEntityExemption } from '@npm/data-access';
import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';

import {
  type BeneficialOwnersExemptions,
  type FromGetStartedFuncs,
} from '../BeneficialOwnership.types';

import { ExemptForm } from './ExemptForm';
import { ExemptionComplete } from './ExemptionComplete';

type Props = {
  accountName?: string;
  accountId?: number;
  form?: FormInstance;
  onFinish?: (v: BeneficialOwnersExemptions, finish?: boolean) => Promise<void>;
  exemptions: BeneficialOwnersExemptions;
  completeStep?: () => void;
  handleValuesChange?: FormProps['onValuesChange'];
} & FromGetStartedFuncs;

export const BeneficialOwnershipEdit = ({
  accountName,
  form,
  onFinish,
  accountId,
  exemptions,
  completeStep,
  handleValuesChange,
  ...rest
}: Props) => {
  const formFinish = values =>
    onFinish(
      values,
      values?.entity_exemption != null &&
        values.entity_exemption !== CbBeneficialOwnerEntityExemption.items.none
    );

  return (
    <Flex direction="column">
      {!exemptions?.entity_exemption && (
        <ExemptForm
          onFinish={formFinish}
          form={form}
          accountName={accountName}
          handleValuesChange={handleValuesChange}
        />
      )}

      {exemptions?.entity_exemption && (
        <ExemptionComplete
          onReset={form?.resetFields}
          completeStep={completeStep}
          accountId={accountId}
          accountName={accountName}
          formResult={exemptions}
          {...rest}
        />
      )}
    </Flex>
  );
};
