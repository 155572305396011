import { Codebooks } from './codebooks.types';

const Items = {
  activity: 'activity',
  valuation: 'valuation',
} as const;

export const CbIssuerEntitySort = {
  code: Codebooks.ISSUER_ENTITY_SORT,
  items: Items,
};
