// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FairMarketValuesApi,
  InternalFairMarketValueIndexErrorCodes,
  InternalFairMarketValueIndexErrorTypes,
  InternalFairMarketValueCreateErrorCodes,
  InternalAllFairMarketValuesIndexErrorCodes,
  InternalAllFairMarketValuesIndexErrorTypes,
  InternalShowFairMarketValueErrorCodes,
  InternalShowFairMarketValueErrorTypes,
  InternalUpdateFairMarketValueErrorCodes,
  InternalDeleteFairMarketValueErrorCodes,
  InternalUploadFmvsErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FairMarketValuesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalFairMarketValueIndexMethod = FairMarketValuesApi['internalFairMarketValueIndex'];
export type InternalFairMarketValueIndexResponseType = MethodResult<InternalFairMarketValueIndexMethod>;

export const useInternalFairMarketValueIndex = (
  variables: Parameters<InternalFairMarketValueIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFairMarketValueIndexMethod,
    InternalFairMarketValueIndexErrorTypes
  >
) => {
  return useQuery<
    InternalFairMarketValueIndexMethod,
    InternalFairMarketValueIndexErrorTypes
  >(
    (...args) => api.internalFairMarketValueIndex(...args),
    InternalFairMarketValueIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFairMarketValueIndex'],
        ...apiQueryConfig.InternalFairMarketValueIndex,
        ...config?.queryConfig
      },
    },
    'InternalFairMarketValueIndex',
  );
};

export const useInternalFairMarketValueIndexInfinite = (
  variables: Parameters<InternalFairMarketValueIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalFairMarketValueIndexMethod,
    InternalFairMarketValueIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalFairMarketValueIndexMethod,
    InternalFairMarketValueIndexErrorTypes
  >(
    (...args) => api.internalFairMarketValueIndex(...args),
    InternalFairMarketValueIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalFairMarketValueIndex', 'InternalFairMarketValueIndexInfinite'],
        ...apiQueryConfig.InternalFairMarketValueIndex,
        ...config?.queryConfig,
      },
    },
    'InternalFairMarketValueIndex',
  );
};

export const useInternalFairMarketValueIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalFairMarketValueIndexMethod>[0],
  config?: UseQueryConfig<
    InternalFairMarketValueIndexMethod,
    InternalFairMarketValueIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalFairMarketValueIndexMethod,
    InternalFairMarketValueIndexErrorTypes
  >(
    (...args) => api.internalFairMarketValueIndex(...args),
    InternalFairMarketValueIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalFairMarketValueIndex'],
        ...apiQueryConfig.InternalFairMarketValueIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalFairMarketValueIndex',
  );
};


export type InternalFairMarketValueCreateMethod = FairMarketValuesApi['internalFairMarketValueCreate'];

export const useInternalFairMarketValueCreate = () => {
  return useMutation<InternalFairMarketValueCreateMethod>(
    (...args) => api.internalFairMarketValueCreate(...args),
    InternalFairMarketValueCreateErrorCodes,
    'InternalFairMarketValueCreate',
    '/api/internal/companies/{company_id}/fair_market_values',
    'post'
  );
};

export type InternalAllFairMarketValuesIndexMethod = FairMarketValuesApi['internalAllFairMarketValuesIndex'];
export type InternalAllFairMarketValuesIndexResponseType = MethodResult<InternalAllFairMarketValuesIndexMethod>;

export const useInternalAllFairMarketValuesIndex = (
  variables: Parameters<InternalAllFairMarketValuesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllFairMarketValuesIndexMethod,
    InternalAllFairMarketValuesIndexErrorTypes
  >
) => {
  return useQuery<
    InternalAllFairMarketValuesIndexMethod,
    InternalAllFairMarketValuesIndexErrorTypes
  >(
    (...args) => api.internalAllFairMarketValuesIndex(...args),
    InternalAllFairMarketValuesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllFairMarketValuesIndex'],
        ...apiQueryConfig.InternalAllFairMarketValuesIndex,
        ...config?.queryConfig
      },
    },
    'InternalAllFairMarketValuesIndex',
  );
};

export const useInternalAllFairMarketValuesIndexInfinite = (
  variables: Parameters<InternalAllFairMarketValuesIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalAllFairMarketValuesIndexMethod,
    InternalAllFairMarketValuesIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalAllFairMarketValuesIndexMethod,
    InternalAllFairMarketValuesIndexErrorTypes
  >(
    (...args) => api.internalAllFairMarketValuesIndex(...args),
    InternalAllFairMarketValuesIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalAllFairMarketValuesIndex', 'InternalAllFairMarketValuesIndexInfinite'],
        ...apiQueryConfig.InternalAllFairMarketValuesIndex,
        ...config?.queryConfig,
      },
    },
    'InternalAllFairMarketValuesIndex',
  );
};

export const useInternalAllFairMarketValuesIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalAllFairMarketValuesIndexMethod>[0],
  config?: UseQueryConfig<
    InternalAllFairMarketValuesIndexMethod,
    InternalAllFairMarketValuesIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalAllFairMarketValuesIndexMethod,
    InternalAllFairMarketValuesIndexErrorTypes
  >(
    (...args) => api.internalAllFairMarketValuesIndex(...args),
    InternalAllFairMarketValuesIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalAllFairMarketValuesIndex'],
        ...apiQueryConfig.InternalAllFairMarketValuesIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalAllFairMarketValuesIndex',
  );
};


export type InternalShowFairMarketValueMethod = FairMarketValuesApi['internalShowFairMarketValue'];
export type InternalShowFairMarketValueResponseType = MethodResult<InternalShowFairMarketValueMethod>;

export const useInternalShowFairMarketValue = (
  variables: Parameters<InternalShowFairMarketValueMethod>[0],
  config?: UseQueryConfig<
    InternalShowFairMarketValueMethod,
    InternalShowFairMarketValueErrorTypes
  >
) => {
  return useQuery<
    InternalShowFairMarketValueMethod,
    InternalShowFairMarketValueErrorTypes
  >(
    (...args) => api.internalShowFairMarketValue(...args),
    InternalShowFairMarketValueErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowFairMarketValue'],
        ...apiQueryConfig.InternalShowFairMarketValue,
        ...config?.queryConfig
      },
    },
    'InternalShowFairMarketValue',
  );
};

export const useInternalShowFairMarketValueInfinite = (
  variables: Parameters<InternalShowFairMarketValueMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowFairMarketValueMethod,
    InternalShowFairMarketValueErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowFairMarketValueMethod,
    InternalShowFairMarketValueErrorTypes
  >(
    (...args) => api.internalShowFairMarketValue(...args),
    InternalShowFairMarketValueErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowFairMarketValue', 'InternalShowFairMarketValueInfinite'],
        ...apiQueryConfig.InternalShowFairMarketValue,
        ...config?.queryConfig,
      },
    },
    'InternalShowFairMarketValue',
  );
};

export const useInternalShowFairMarketValueLazy = (baseOptions?: {
  variables?: Parameters<InternalShowFairMarketValueMethod>[0],
  config?: UseQueryConfig<
    InternalShowFairMarketValueMethod,
    InternalShowFairMarketValueErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowFairMarketValueMethod,
    InternalShowFairMarketValueErrorTypes
  >(
    (...args) => api.internalShowFairMarketValue(...args),
    InternalShowFairMarketValueErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowFairMarketValue'],
        ...apiQueryConfig.InternalShowFairMarketValue,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowFairMarketValue',
  );
};


export type InternalUpdateFairMarketValueMethod = FairMarketValuesApi['internalUpdateFairMarketValue'];

export const useInternalUpdateFairMarketValue = () => {
  return useMutation<InternalUpdateFairMarketValueMethod>(
    (...args) => api.internalUpdateFairMarketValue(...args),
    InternalUpdateFairMarketValueErrorCodes,
    'InternalUpdateFairMarketValue',
    '/api/internal/fair_market_values/{id}',
    'put'
  );
};

export type InternalDeleteFairMarketValueMethod = FairMarketValuesApi['internalDeleteFairMarketValue'];

export const useInternalDeleteFairMarketValue = () => {
  return useMutation<InternalDeleteFairMarketValueMethod>(
    (...args) => api.internalDeleteFairMarketValue(...args),
    InternalDeleteFairMarketValueErrorCodes,
    'InternalDeleteFairMarketValue',
    '/api/internal/fair_market_values/{id}',
    'delete'
  );
};

export type InternalUploadFmvsMethod = FairMarketValuesApi['internalUploadFmvs'];

export const useInternalUploadFmvs = () => {
  return useMutation<InternalUploadFmvsMethod>(
    (...args) => api.internalUploadFmvs(...args),
    InternalUploadFmvsErrorCodes,
    'InternalUploadFmvs',
    '/api/internal/fair_market_values/upload',
    'post'
  );
};
