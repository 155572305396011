import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  filterRoutesByFeatureFlag,
  getAllRoutesRecursively,
} from '@npm/core/ui/routes/router.utils';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { ErrorPath } from '@npm/features/routes/components/ErrorPath';
import { ProtectedRoute } from '@npm/features/routes/components/ProtectedRoute';
import { useOktaAuth } from '@okta/okta-react';

import { routes, workstationPrefix } from './routes.preplatform';

export const PreplatformRouter = () => {
  const { isEnabled } = useFeatureFlags();

  const allRoutes = useMemo(() => {
    return getAllRoutesRecursively(
      filterRoutesByFeatureFlag(isEnabled, routes)
    );
  }, []);

  const { authState } = useOktaAuth();

  if (authState?.isAuthenticated) {
    return <Navigate to={'/'} />;
  }

  return (
    <Routes>
      {allRoutes.map(route => {
        const path = route.path.replace(`/${workstationPrefix}`, '');
        return route.needLogin !== false ? (
          <Route
            element={
              <ProtectedRoute permissionsChecks={route.permissionsChecks} />
            }
            key={path}
          >
            <Route path={path} Component={route.component} />
          </Route>
        ) : (
          <Route key={path} path={path} Component={route.component} />
        );
      })}
      <Route
        path="*"
        element={
          <ErrorPath
            heading="Error"
            title="Sorry, the page you visited does not exist."
            status="404"
          />
        }
      />
    </Routes>
  );
};
