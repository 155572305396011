// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  BeneficialOwnerApi,
  AccountBeneficialOwnersIndexErrorCodes,
  AccountBeneficialOwnersIndexErrorTypes,
  AccountBeneficialOwnerCreateErrorCodes,
  AccountBeneficialOwnerUpdateTaxIdErrorCodes,
  AccountBeneficialOwnerDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new BeneficialOwnerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountBeneficialOwnersIndexMethod = BeneficialOwnerApi['accountBeneficialOwnersIndex'];
export type AccountBeneficialOwnersIndexResponseType = MethodResult<AccountBeneficialOwnersIndexMethod>;

export const useAccountBeneficialOwnersIndex = (
  variables: Parameters<AccountBeneficialOwnersIndexMethod>[0],
  config?: UseQueryConfig<
    AccountBeneficialOwnersIndexMethod,
    AccountBeneficialOwnersIndexErrorTypes
  >
) => {
  return useQuery<
    AccountBeneficialOwnersIndexMethod,
    AccountBeneficialOwnersIndexErrorTypes
  >(
    (...args) => api.accountBeneficialOwnersIndex(...args),
    AccountBeneficialOwnersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBeneficialOwnersIndex'],
        ...apiQueryConfig.AccountBeneficialOwnersIndex,
        ...config?.queryConfig
      },
    },
    'AccountBeneficialOwnersIndex',
  );
};

export const useAccountBeneficialOwnersIndexInfinite = (
  variables: Parameters<AccountBeneficialOwnersIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountBeneficialOwnersIndexMethod,
    AccountBeneficialOwnersIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountBeneficialOwnersIndexMethod,
    AccountBeneficialOwnersIndexErrorTypes
  >(
    (...args) => api.accountBeneficialOwnersIndex(...args),
    AccountBeneficialOwnersIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBeneficialOwnersIndex', 'AccountBeneficialOwnersIndexInfinite'],
        ...apiQueryConfig.AccountBeneficialOwnersIndex,
        ...config?.queryConfig,
      },
    },
    'AccountBeneficialOwnersIndex',
  );
};

export const useAccountBeneficialOwnersIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountBeneficialOwnersIndexMethod>[0],
  config?: UseQueryConfig<
    AccountBeneficialOwnersIndexMethod,
    AccountBeneficialOwnersIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountBeneficialOwnersIndexMethod,
    AccountBeneficialOwnersIndexErrorTypes
  >(
    (...args) => api.accountBeneficialOwnersIndex(...args),
    AccountBeneficialOwnersIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountBeneficialOwnersIndex'],
        ...apiQueryConfig.AccountBeneficialOwnersIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountBeneficialOwnersIndex',
  );
};


export type AccountBeneficialOwnerCreateMethod = BeneficialOwnerApi['accountBeneficialOwnerCreate'];

export const useAccountBeneficialOwnerCreate = () => {
  return useMutation<AccountBeneficialOwnerCreateMethod>(
    (...args) => api.accountBeneficialOwnerCreate(...args),
    AccountBeneficialOwnerCreateErrorCodes,
    'AccountBeneficialOwnerCreate',
    '/api/accounts/{account_id}/beneficial-owners',
    'post'
  );
};

export type AccountBeneficialOwnerUpdateTaxIdMethod = BeneficialOwnerApi['accountBeneficialOwnerUpdateTaxId'];

export const useAccountBeneficialOwnerUpdateTaxId = () => {
  return useMutation<AccountBeneficialOwnerUpdateTaxIdMethod>(
    (...args) => api.accountBeneficialOwnerUpdateTaxId(...args),
    AccountBeneficialOwnerUpdateTaxIdErrorCodes,
    'AccountBeneficialOwnerUpdateTaxId',
    '/api/accounts/{account_id}/beneficial-owners/{id}',
    'put'
  );
};

export type AccountBeneficialOwnerDestroyMethod = BeneficialOwnerApi['accountBeneficialOwnerDestroy'];

export const useAccountBeneficialOwnerDestroy = () => {
  return useMutation<AccountBeneficialOwnerDestroyMethod>(
    (...args) => api.accountBeneficialOwnerDestroy(...args),
    AccountBeneficialOwnerDestroyErrorCodes,
    'AccountBeneficialOwnerDestroy',
    '/api/accounts/{account_id}/beneficial-owners/{id}',
    'delete'
  );
};
