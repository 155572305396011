import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { Modal as ModalBase } from '../../molecules/Modal';

import { type EducationalModalVariant } from './EducationalModal.types';

const Modal = styled(ModalBase)`
  &&& .ant-modal-body {
    padding: 0 0 ${({ theme }) => theme.spacing.md}px 0;

    position: relative;
    overflow: visible;
  }

  &&& .ant-modal-footer {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}px;

  text-align: center;

  padding: ${({ theme }) => theme.spacing.xl}px
    ${({ theme }) => theme.spacing.md}px;

  ${({ theme }) => useBreakpoint(theme).smTablet`
    text-align: left;
    align-items: flex-start;
    padding: ${theme.spacing.sm}px 0;
  `}
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 282px 1fr;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.xxl}px;

  ${({ theme }) => useBreakpoint(theme).smTablet`
    grid-template-columns: 1fr;
    gap: ${theme.spacing.md}px;
    align-items: revert;
  `}
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}px;
`;

const Item = styled.div<{
  $isSelected: boolean;
  $variant: EducationalModalVariant;
}>`
  display: flex;
  align-items: ${({ $variant }) =>
    $variant === 'default' ? 'center' : 'flex-start'};
  gap: ${({ theme }) => theme.spacing.sm}px;

  padding: ${({ theme, $variant }) =>
      theme.spacing[$variant === 'withLogos' ? 'md' : 'sm']}px
    ${({ theme }) => theme.spacing.md}px;

  background: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.color.info.backgrounds.secondaryHover
      : theme.color.general.layout.two};

  border: 1px solid
    ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.color.info.borders.primary
        : theme.color.general.borders.primary};
  border-radius: ${({ theme }) => theme.borderRadius.md}px;

  cursor: pointer;
`;

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}px;

  padding: ${({ theme }) => theme.spacing.md}px
    ${({ theme }) => theme.spacing.lg}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  border: 1px solid ${({ theme }) => theme.color.general.borders.primary};

  ${({ theme }) => useBreakpoint(theme).smTablet`
    padding: 0;
    border: 0;
  `}
`;

export const EducationalModalStyles = {
  Modal,
  Header,
  Body,
  ItemsList,
  Item,
  ContentCard,
};
