import React from 'react';

import { type ButtonProps } from '@npm/core/ui/components/atoms/Button';
import { motion } from 'framer-motion';

import { Button } from './SwitcherButton.styles';

type Props = {
  children: React.ReactNode;
  isCollapsed?: boolean;
  hideWhenCollapsed?: boolean;
  isHighlighted?: boolean;
  'data-cy'?: string;
  'data-dd-action-name'?: string;
} & Pick<
  ButtonProps,
  'icon' | 'onClick' | 'disabled' | 'className' | 'style' | 'size'
>;

export const SwitcherButton = ({
  children,
  isCollapsed = false,
  hideWhenCollapsed = false,
  isHighlighted = false,
  ...rest
}: Props) => {
  return (
    <motion.div
      transition={{ duration: 0.2 }}
      layoutDependency={isCollapsed}
      layout
    >
      <Button
        $isCollapsed={isCollapsed}
        $hideWhenCollapsed={hideWhenCollapsed}
        $isHighlighted={isHighlighted}
        {...rest}
      >
        {!isCollapsed && children}
      </Button>
    </motion.div>
  );
};
