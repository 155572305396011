// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CapTablePartnerUploadApi,
  CapTablePartnerUploadsListErrorCodes,
  CapTablePartnerUploadsListErrorTypes,
  GetCapTablePartnerUploadsErrorCodes,
  GetCapTablePartnerUploadsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CapTablePartnerUploadApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CapTablePartnerUploadsListMethod = CapTablePartnerUploadApi['capTablePartnerUploadsList'];
export type CapTablePartnerUploadsListResponseType = MethodResult<CapTablePartnerUploadsListMethod>;

export const useCapTablePartnerUploadsList = (
  variables: Parameters<CapTablePartnerUploadsListMethod>[0],
  config?: UseQueryConfig<
    CapTablePartnerUploadsListMethod,
    CapTablePartnerUploadsListErrorTypes
  >
) => {
  return useQuery<
    CapTablePartnerUploadsListMethod,
    CapTablePartnerUploadsListErrorTypes
  >(
    (...args) => api.capTablePartnerUploadsList(...args),
    CapTablePartnerUploadsListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CapTablePartnerUploadsList'],
        ...apiQueryConfig.CapTablePartnerUploadsList,
        ...config?.queryConfig
      },
    },
    'CapTablePartnerUploadsList',
  );
};

export const useCapTablePartnerUploadsListInfinite = (
  variables: Parameters<CapTablePartnerUploadsListMethod>[0],
  config?: UseInfiniteQueryConfig<
    CapTablePartnerUploadsListMethod,
    CapTablePartnerUploadsListErrorTypes
  >
) => {
  return useInfiniteQuery<
    CapTablePartnerUploadsListMethod,
    CapTablePartnerUploadsListErrorTypes
  >(
    (...args) => api.capTablePartnerUploadsList(...args),
    CapTablePartnerUploadsListErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CapTablePartnerUploadsList', 'CapTablePartnerUploadsListInfinite'],
        ...apiQueryConfig.CapTablePartnerUploadsList,
        ...config?.queryConfig,
      },
    },
    'CapTablePartnerUploadsList',
  );
};

export const useCapTablePartnerUploadsListLazy = (baseOptions?: {
  variables?: Parameters<CapTablePartnerUploadsListMethod>[0],
  config?: UseQueryConfig<
    CapTablePartnerUploadsListMethod,
    CapTablePartnerUploadsListErrorTypes
  >
}) => {
  return useLazyQuery<
    CapTablePartnerUploadsListMethod,
    CapTablePartnerUploadsListErrorTypes
  >(
    (...args) => api.capTablePartnerUploadsList(...args),
    CapTablePartnerUploadsListErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CapTablePartnerUploadsList'],
        ...apiQueryConfig.CapTablePartnerUploadsList,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CapTablePartnerUploadsList',
  );
};


export type GetCapTablePartnerUploadsMethod = CapTablePartnerUploadApi['getCapTablePartnerUploads'];
export type GetCapTablePartnerUploadsResponseType = MethodResult<GetCapTablePartnerUploadsMethod>;

export const useGetCapTablePartnerUploads = (
  variables: Parameters<GetCapTablePartnerUploadsMethod>[0],
  config?: UseQueryConfig<
    GetCapTablePartnerUploadsMethod,
    GetCapTablePartnerUploadsErrorTypes
  >
) => {
  return useQuery<
    GetCapTablePartnerUploadsMethod,
    GetCapTablePartnerUploadsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploads(...args),
    GetCapTablePartnerUploadsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploads'],
        ...apiQueryConfig.GetCapTablePartnerUploads,
        ...config?.queryConfig
      },
    },
    'GetCapTablePartnerUploads',
  );
};

export const useGetCapTablePartnerUploadsInfinite = (
  variables: Parameters<GetCapTablePartnerUploadsMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetCapTablePartnerUploadsMethod,
    GetCapTablePartnerUploadsErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetCapTablePartnerUploadsMethod,
    GetCapTablePartnerUploadsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploads(...args),
    GetCapTablePartnerUploadsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploads', 'GetCapTablePartnerUploadsInfinite'],
        ...apiQueryConfig.GetCapTablePartnerUploads,
        ...config?.queryConfig,
      },
    },
    'GetCapTablePartnerUploads',
  );
};

export const useGetCapTablePartnerUploadsLazy = (baseOptions?: {
  variables?: Parameters<GetCapTablePartnerUploadsMethod>[0],
  config?: UseQueryConfig<
    GetCapTablePartnerUploadsMethod,
    GetCapTablePartnerUploadsErrorTypes
  >
}) => {
  return useLazyQuery<
    GetCapTablePartnerUploadsMethod,
    GetCapTablePartnerUploadsErrorTypes
  >(
    (...args) => api.getCapTablePartnerUploads(...args),
    GetCapTablePartnerUploadsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetCapTablePartnerUploads'],
        ...apiQueryConfig.GetCapTablePartnerUploads,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetCapTablePartnerUploads',
  );
};

