import React from 'react';

import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import {
  type IssuerEntityApiIssuerEntityIndexRequest,
  useIssuerEntityIndex,
} from '@npm/data-access';
import { omit } from 'lodash';

import * as S from './styles';

export type FilterTabKey = 'all' | 'watchlist' | 'portfolio';

type Props = {
  variables: IssuerEntityApiIssuerEntityIndexRequest;
  handleChange: (key: FilterTabKey) => void;
};

export const FilterTabs = ({ variables, handleChange }: Props) => {
  const { data: { type_totals } = {} } = useIssuerEntityIndex({
    ...omit(variables, ['watchlistOnly', 'portfolioOnly']),
    includeField: ['type_totals'],
  });

  return (
    <S.FilterTabs
      defaultActiveKey="all"
      activeKey={
        variables.watchlistOnly
          ? 'watchlist'
          : variables.portfolioOnly
            ? 'portfolio'
            : 'all'
      }
      onChange={handleChange}
      variant="tertiary"
      hideMoreButton
    >
      <Tabs.TabPane
        key="all"
        tab={type_totals?.all == null ? 'All' : `All (${type_totals.all})`}
      />
      <Tabs.TabPane
        key="watchlist"
        tab={
          type_totals?.watchlist == null
            ? 'In my Watchlist'
            : `In my Watchlist (${type_totals.watchlist})`
        }
      />
      <Tabs.TabPane
        key="portfolio"
        tab={
          type_totals?.portfolio == null
            ? 'In my Portfolio'
            : `In my Portfolio (${type_totals.portfolio})`
        }
      />
    </S.FilterTabs>
  );
};
