import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  type Account,
  useDocumentCreate,
  useOrganizationShow,
  useOrganizationUpdate,
} from '@npm/data-access';
import { useQueryClient } from '@tanstack/react-query';

import { type FilesForm } from './FormationDocument.types';

export const useFormationDocumentForm = (
  account: Account,
  { onUpdate }: { onUpdate?: () => void }
) => {
  const [form] = Form.useForm<FilesForm>();
  const { withShowApiErrorMiddleware } = useAlerts();

  const queryClient = useQueryClient();
  const docCreate = useDocumentCreate();
  const orgUpdate = useOrganizationUpdate();

  const { data, isLoading } = useOrganizationShow(
    { id: account?.organization_id },
    {
      queryConfig: {
        enabled: !!account?.organization_id,
      },
    }
  );

  const handleSubmit = async () => {
    let values: FilesForm;

    try {
      values = await form.validateFields();
    } catch (e) {
      return console.error('Failed:', e);
    }

    const constituentDoc = values.constituentDoc;
    const loaDoc = values.loaDoc;

    try {
      await Promise.all([
        constituentDoc &&
          withShowApiErrorMiddleware(docCreate.execute)({
            file: constituentDoc,
            category: 'constituent_document',
            ownerType: 'Account',
            ownerId: account?.id,
            displayName: constituentDoc.name,
          }),
        loaDoc &&
          withShowApiErrorMiddleware(docCreate.execute)({
            file: loaDoc,
            category: 'loa',
            ownerType: 'Account',
            ownerId: account?.id,
            displayName: loaDoc.name,
          }),
      ]);

      queryClient.invalidateQueries(['AccountShow']);

      await withShowApiErrorMiddleware(orgUpdate.execute)({
        id: account?.organization_id,
        organizationUpdateRequestContract: {
          loa_needed: !values.authorized,
        },
      });

      onUpdate?.();
    } catch (e) {
      console.error(e);
      return;
    }
  };

  return {
    form,
    isLoading,
    isUpdating: docCreate.isLoading || orgUpdate.isLoading,
    handleSubmit,
    data,
  };
};
