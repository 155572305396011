import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';

export const DoneLabel = () => {
  return (
    <Flex align="center" gap="xs">
      <Icon name="check" color="info" size="xxs" />
      <Text size="sm" color="info" colorVariant="primary">
        Done
      </Text>
    </Flex>
  );
};
