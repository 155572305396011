/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Company-Pricing API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SectorReturns } from '../model';
// @ts-ignore
import { VolumeIndex } from '../model';
/**
 * SectorApi - axios parameter creator
 * @export
 */
export const SectorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get Motif Bid/Ask volume per quarter
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMotifVolume: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMotifVolume', 'id', id)
            const localVarPath = changeUrl(`/api/motifs/{id}/volume_history`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get Sector Rates of Returns
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorReturns: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSectorReturns', 'id', id)
            const localVarPath = changeUrl(`/api/sectors/{id}/returns`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get Sector Bid/Ask volume per quarter
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorVolume: async (id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSectorVolume', 'id', id)
            const localVarPath = changeUrl(`/api/sectors/{id}/volume_history`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xNPMAuthorization !== undefined && xNPMAuthorization !== null) {
                localVarHeaderParameter['X-NPM-Authorization'] = String(xNPMAuthorization);
            }

            if (xSubscriptionPlan !== undefined && xSubscriptionPlan !== null) {
                localVarHeaderParameter['X-Subscription-Plan'] = String(xSubscriptionPlan);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectorApi - functional programming interface
 * @export
 */
export const SectorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get Motif Bid/Ask volume per quarter
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMotifVolume(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolumeIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMotifVolume(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get Sector Rates of Returns
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectorReturns(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectorReturns>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectorReturns(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get Sector Bid/Ask volume per quarter
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectorVolume(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolumeIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectorVolume(id, xNPMAuthorization, xSubscriptionPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectorApi - factory interface
 * @export
 */
export const SectorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectorApiFp(configuration)
    return {
        /**
         * 
         * @summary get Motif Bid/Ask volume per quarter
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMotifVolume(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<VolumeIndex> {
            return localVarFp.getMotifVolume(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get Sector Rates of Returns
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorReturns(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<SectorReturns> {
            return localVarFp.getSectorReturns(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get Sector Bid/Ask volume per quarter
         * @param {number} id 
         * @param {string} [xNPMAuthorization] 
         * @param {string} [xSubscriptionPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorVolume(id: number, xNPMAuthorization?: string, xSubscriptionPlan?: string, options?: any): AxiosPromise<VolumeIndex> {
            return localVarFp.getSectorVolume(id, xNPMAuthorization, xSubscriptionPlan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMotifVolume operation in SectorApi.
 * @export
 * @interface SectorApiGetMotifVolumeRequest
 */
export interface SectorApiGetMotifVolumeRequest {
    /**
     * 
     * @type {number}
     * @memberof SectorApiGetMotifVolume
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SectorApiGetMotifVolume
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof SectorApiGetMotifVolume
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for getSectorReturns operation in SectorApi.
 * @export
 * @interface SectorApiGetSectorReturnsRequest
 */
export interface SectorApiGetSectorReturnsRequest {
    /**
     * 
     * @type {number}
     * @memberof SectorApiGetSectorReturns
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SectorApiGetSectorReturns
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof SectorApiGetSectorReturns
     */
    readonly xSubscriptionPlan?: string
}

/**
 * Request parameters for getSectorVolume operation in SectorApi.
 * @export
 * @interface SectorApiGetSectorVolumeRequest
 */
export interface SectorApiGetSectorVolumeRequest {
    /**
     * 
     * @type {number}
     * @memberof SectorApiGetSectorVolume
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SectorApiGetSectorVolume
     */
    readonly xNPMAuthorization?: string

    /**
     * 
     * @type {string}
     * @memberof SectorApiGetSectorVolume
     */
    readonly xSubscriptionPlan?: string
}

/**
 * SectorApi - object-oriented interface
 * @export
 * @class SectorApi
 * @extends {BaseAPI}
 */
export class SectorApi extends BaseAPI {
    /**
     * 
     * @summary get Motif Bid/Ask volume per quarter
     * @param {SectorApiGetMotifVolumeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorApi
     */
    public getMotifVolume(requestParameters: SectorApiGetMotifVolumeRequest, options?: AxiosRequestConfig) {
        return SectorApiFp(this.configuration).getMotifVolume(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get Sector Rates of Returns
     * @param {SectorApiGetSectorReturnsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorApi
     */
    public getSectorReturns(requestParameters: SectorApiGetSectorReturnsRequest, options?: AxiosRequestConfig) {
        return SectorApiFp(this.configuration).getSectorReturns(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get Sector Bid/Ask volume per quarter
     * @param {SectorApiGetSectorVolumeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorApi
     */
    public getSectorVolume(requestParameters: SectorApiGetSectorVolumeRequest, options?: AxiosRequestConfig) {
        return SectorApiFp(this.configuration).getSectorVolume(requestParameters.id, requestParameters.xNPMAuthorization, requestParameters.xSubscriptionPlan, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetMotifVolumeErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetMotifVolumeErrorCodes = [
    401,
];

export type GetSectorReturnsErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetSectorReturnsErrorCodes = [
    401,
];

export type GetSectorVolumeErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetSectorVolumeErrorCodes = [
    401,
];


