import React from 'react';

import { CbRoleType, Codebooks } from '@npm/data-access';
import { type WatchlistApiWatchlistIndexRequest } from '@npm/data-access';

import {
  AccountFilter,
  BrokerageUserFilter,
  CodebooksSelectFilter,
  DateStringSelectFilter,
  type FilterPanelItem,
  IssuerEntityFilter,
} from '../../../filters';

export type WatchlistIndexFilterParams = Pick<
  WatchlistApiWatchlistIndexRequest,
  | 'orderBuySell'
  | 'createdAfter'
  | 'issuerEntityId'
  | 'accountId'
  | 'assignedUserId'
>;

type WatchlistFilterPanelItem = FilterPanelItem<WatchlistIndexFilterParams>;

export const getWatchlistIndexFilters = (
  showCompanySearch: boolean,
  showAccountSearch: boolean,
  isInvestor?: boolean
): WatchlistFilterPanelItem[] => [
  ...(showAccountSearch
    ? [
        {
          name: 'accountId',
          label: 'Account',
          render: props => <AccountFilter {...props} />,
        } as WatchlistFilterPanelItem,
      ]
    : []),
  ...(showCompanySearch
    ? [
        {
          name: 'issuerEntityId',
          label: 'Company',
          render: props => <IssuerEntityFilter {...props} />,
        } as WatchlistFilterPanelItem,
      ]
    : []),
  {
    name: 'orderBuySell',
    label: 'Side',
    render: props => (
      <CodebooksSelectFilter
        codebook={Codebooks.ORDER_BUY_SELL}
        placeholder="All"
        {...props}
      />
    ),
  },
  {
    name: 'assignedUserId',
    label: 'Assigned To',
    hidden: isInvestor,
    render: props => (
      <BrokerageUserFilter
        roleType={[
          CbRoleType.items.ACCOUNT_MANAGER,
          CbRoleType.items.BROKERAGE_ADMIN,
        ]}
        mode="multiple"
        variant="select"
        {...props}
      />
    ),
  },
  {
    name: 'createdAfter',
    label: 'Created At',
    render: props => (
      <DateStringSelectFilter
        options={[
          'today',
          'lastWeek',
          'last2Weeks',
          'lastMonth',
          'last6Months',
          'last1Year',
        ]}
        {...props}
      />
    ),
  },
];
