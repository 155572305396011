import React from 'react';

import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Typography';

import { ContextualHelp as S } from './ContextualHelp.styles';

type Props = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  $isInline?: boolean;
};

export const ContextualHelp = ({ children, $isInline, onClick }: Props) => {
  return (
    <S.Container onClick={onClick} $isInline={$isInline}>
      <Icon name="bulb" size="xs" />
      <Text size="xs" weight="bold">
        {children}
      </Text>
    </S.Container>
  );
};
