import { type FC, useMemo } from 'react';

import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Radio } from '@npm/core/ui/components/atoms/Radio';
import { type AccountShowAggregate, CbRoleType } from '@npm/data-access';
import { type FormInstance } from 'antd';
import { type FormProps } from 'antd/es/form/Form';
import { map, reject, some } from 'lodash';

import * as S from './SpousalInformationForm.styles';
import { FormFields, type SpousalForm } from './SpousalInformationForm.types';

type Props = {
  form: FormInstance<SpousalForm>;
  account: AccountShowAggregate;
  handleValuesChange?: FormProps['onValuesChange'];
};

export const SpousalInformationForm: FC<Props> = ({
  form,
  account,
  handleValuesChange,
}) => {
  // returns all of the account's users emails excluding spouse and the account owner
  const accountUsersEmails = useMemo(
    () =>
      map(
        reject(
          account.users,
          user =>
            some(user.roles, { code: CbRoleType.items.SPOUSE }) ||
            user.email === account?.user?.email
        ),
        'email'
      ),
    [account]
  );

  return (
    <S.Wrap>
      <Form form={form} onValuesChange={handleValuesChange}>
        <S.FormItem
          label="Spouse"
          name={FormFields.HAS_SPOUSE}
          rules={[VALIDATION_RULES.required()]}
        >
          <S.RadioGroup>
            <Radio value={true} label="I have a spouse" />
            <Radio value={false} label="I do not have a spouse" />
          </S.RadioGroup>
        </S.FormItem>

        <S.FormItem shouldUpdate={true}>
          {form =>
            form.getFieldValue(FormFields.HAS_SPOUSE) && (
              <>
                <S.Row>
                  <S.FormItem
                    name={FormFields.FIRST}
                    label="First Name"
                    rules={[VALIDATION_RULES.required('First name')]}
                  >
                    <Input placeholder="First Name" />
                  </S.FormItem>
                  <S.FormItem
                    label="Middle Name"
                    name={FormFields.MIDDLE}
                    requiredMark="optional"
                  >
                    <Input placeholder="Middle Name" />
                  </S.FormItem>
                </S.Row>
                <S.Row>
                  <S.FormItem
                    label="Last Name"
                    rules={[VALIDATION_RULES.required('Last name')]}
                    name={FormFields.LAST}
                  >
                    <Input placeholder="Last Name" />
                  </S.FormItem>
                </S.Row>
                <S.Row>
                  <S.FormItem
                    label="Email"
                    name={FormFields.EMAIL}
                    validateTrigger="onBlur"
                    rules={[
                      VALIDATION_RULES.email(),
                      VALIDATION_RULES.cannotMatchSpecificValues(
                        [account.user?.email],
                        'Spouse cannot have the same email as account owner'
                      ),
                    ]}
                    extra={
                      accountUsersEmails.includes(
                        form.getFieldValue(FormFields.EMAIL)
                      ) ? (
                        <Alert message="This will remove the existing role from the user you’re assigning as spouse" />
                      ) : null
                    }
                  >
                    <Input placeholder="Email" />
                  </S.FormItem>
                </S.Row>
              </>
            )
          }
        </S.FormItem>
      </Form>
    </S.Wrap>
  );
};
