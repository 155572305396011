import { FormItem } from '@npm/core/ui/components/atoms/FormItem';
import { CollapsibleFormSection } from '@npm/core/ui/components/molecules/CollapsibleFormSection';
import {
  CbOrderEntryType,
  type CodebookShow,
  useCodebook,
} from '@npm/data-access';

import { CodebooksSelect } from '../../../../filters';

type Props = {
  value: string | undefined;
  initialValue?: string;
  onItemChange: (value: string) => void;
  isObo: boolean;
  hidden?: boolean;
} & Partial<React.ComponentProps<typeof CollapsibleFormSection.Item>>;

export const OrderTypeFormSection = ({
  value,
  initialValue,
  onItemChange,
  isObo,
  hidden,
  ...props
}: Props) => {
  const { data: orderTypeData } = useCodebook({
    type: CbOrderEntryType.code,
  });

  const filterInvestorOptions = (val: CodebookShow) =>
    val.code !== CbOrderEntryType.items.soft_ioi;

  return (
    <CollapsibleFormSection.Item
      label="Order Type"
      preview={getOrderTypePreview(orderTypeData?.codebooks ?? [], value)}
      style={hidden ? { display: 'none' } : {}}
      key="orderType"
      name="orderType"
      {...props}
    >
      <FormItem name="orderType" marginBottom="sm">
        <CodebooksSelect
          defaultValue={initialValue}
          codebook={CbOrderEntryType.code}
          allowClear={false}
          onChange={onItemChange}
          filterOptions={isObo ? undefined : filterInvestorOptions}
        />
      </FormItem>
    </CollapsibleFormSection.Item>
  );
};

export const getOrderTypePreview = (
  options: { code?: string; name?: string }[],
  val?: string
) => options.find(i => i.code === val)?.name;
