import React, { useState } from 'react';

import {
  type SearchProps as AntSearchProps,
  type InputRef,
} from 'antd/lib/input';
import { omit } from 'lodash';

import { StyledSearch } from './Search.styles';

export interface StyledSearchProps extends AntSearchProps {
  placeholder?: string;
}

export const Search = React.forwardRef(
  (props: StyledSearchProps, ref: React.Ref<InputRef>) => {
    const validateNumberTypeInput = (val: string) => {
      // should pass if number type input is not specified
      if (props.inputMode !== 'decimal' && props.inputMode !== 'numeric') {
        return true;
      }

      // should match decimal pattern
      if (props.inputMode === 'decimal') return /^\d+(\.\d+)?$/.test(val);

      // should match only numeric pattern
      return /^\d+$/.test(val);
    };

    const handleSearch: AntSearchProps['onSearch'] = (val, e) => {
      if (!props.onSearch) return;

      // allow reset by deleting the input value
      if (val === '') {
        return props.onSearch('', e);
      }

      const trimmedVal = val.trim();

      if (!trimmedVal) {
        return;
      }

      if (!validateNumberTypeInput(trimmedVal)) return;

      props.onSearch(trimmedVal, e);
    };

    const { placeholder = 'Search' } = props;

    const [isSearchIconVisible, setIsSearchIconVisible] =
      useState<boolean>(false);

    // ant sets the disabled classname on the inner field, but
    // the red border is displayed by its parent
    return (
      <StyledSearch
        $readOnly={props.readOnly}
        $isSearchIconVisible={isSearchIconVisible}
        autoComplete="off"
        ref={ref}
        className={props.disabled ? 'ant-input-group-wrapper-disabled' : ''}
        // avoid "Invalid value for prop `submit`" warning
        {...omit(props, 'submit')}
        onFocus={() => {
          setIsSearchIconVisible(true);
        }}
        onBlur={() => {
          setIsSearchIconVisible(false);
        }}
        onSearch={(val, event) => {
          event?.preventDefault();

          try {
            // Close the virtual keyboard. Use document.activeElement instead of
            // event.target because the target can be the search icon, not the input.
            if (
              !!document.activeElement &&
              document.activeElement.tagName === 'INPUT'
            ) {
              (document.activeElement as HTMLInputElement).blur();
            }
          } catch (error) {
            console.error(error);
          }

          handleSearch(val);
        }}
        placeholder={placeholder}
      />
    );
  }
);
