import { useState } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  useHistory,
  useLocation,
} from '@npm/core/ui/components/molecules/Link';
import {
  type RoleType,
  type UserCreateRequestContract,
  type UserRoleAggregate,
  useUserRoleCreate,
  useUserRoleDestroy,
  useUserRoleUpdate,
} from '@npm/data-access';

import { useCurrentRole } from '../../auth/user/role/hooks/useCurrentRole';
import { useEmail } from '../hooks';

export type SelectedUser = {
  id: number;
  name: string;
  role?: UserRoleAggregate['role_name'];
};

export type HandleCreateProps = UserCreateRequestContract & {
  role: RoleType;
  search?: {
    key: string;
  };
  body: string;
  subject: string;
};

export const useUserRoleActions = ({ accountId }: { accountId: number }) => {
  const [selectedUser, setSelectedUser] = useState<SelectedUser>(null);

  const location = useLocation();
  const history = useHistory();
  const roleStore = useCurrentRole();
  const { executeSendEmail } = useEmail();
  const { withShowApiErrorMiddleware } = useAlerts();

  const { execute: executeUserRoleDestroy, isLoading: loadingUserRoleDestroy } =
    useUserRoleDestroy();
  const { execute: executeUserRoleUpdate, isLoading: loadingUserRoleUpdate } =
    useUserRoleUpdate();
  const { execute: executeUserRoleCreate, isLoading: loadingUserRoleCreate } =
    useUserRoleCreate();

  const handleDelete = async () =>
    await withShowApiErrorMiddleware(executeUserRoleDestroy)({
      accountId,
      userId: selectedUser.id,
    });

  const handleUpdate = async (role: RoleType) =>
    await withShowApiErrorMiddleware(executeUserRoleUpdate)({
      userRoleUpdateRequestContract: {
        account_id: accountId,
        user_id: selectedUser.id,
        role_type: role,
      },
    });

  const handleCreate = async ({
    first,
    middle,
    last,
    email,
    role,
    search,
    body,
    subject,
  }: HandleCreateProps) => {
    const userId = parseInt(search?.key);
    const sendEmail = !!body || !!subject;

    const args = {
      account_id: accountId,
      role_type: role,
    };

    // create new user
    if (!userId) {
      const result = await withShowApiErrorMiddleware(executeUserRoleCreate)({
        userRoleCreateRequestContract: {
          ...args,
          new_user: {
            skip_okta: sendEmail,
            first,
            last,
            email,
            ...(middle && { middle }),
          },
        },
      });

      if (sendEmail) {
        await executeSendEmail({
          userId: result.user_id,
          email: { subject, body },
        });
      }

      return;
    }

    // add existing user
    await withShowApiErrorMiddleware(executeUserRoleCreate)({
      userRoleCreateRequestContract: { ...args, user_id: userId },
    });

    const url = `${location.pathname}?roleId=${roleStore?.id}&pageState=3&step=personal_information_state&tab=onboarding`;

    history.push(url);

    if (sendEmail) {
      await executeSendEmail({ userId: userId, email: { subject, body } });
    }
  };

  return {
    handleDelete,
    handleUpdate,
    handleCreate,
    selectedUser,
    setSelectedUser,
    isLoading:
      loadingUserRoleDestroy || loadingUserRoleUpdate || loadingUserRoleCreate,
  };
};
