import React from 'react';

import { type DefaultTheme } from 'styled-components';

import { type TypographyItemProps } from '../typography.types';
import { getAdditionalProps } from '../typography.utils';

import * as S from './TextCurrency.styles';

export type TextCurrencyProps = {
  marginLeft?: number | keyof DefaultTheme['spacing'];
} & TypographyItemProps;

export const TextCurrency = ({
  children,
  as,
  marginBottom,
  marginLeft,
  color,
  colorVariant,
  onClick,
  href,
  target,
  className,
  ...props
}: TextCurrencyProps) => {
  return (
    <S.TextCurrency
      as={as || 'span'}
      className={className}
      $marginBottom={marginBottom}
      // default margin-left is 2px as it is usually always placed next to price
      $marginLeft={marginLeft}
      $color={color || 'general'}
      $colorVariant={colorVariant || 'secondary'}
      {...getAdditionalProps({ onClick, href, target })}
      {...props}
    >
      {children}
    </S.TextCurrency>
  );
};
