import { useDarkModeContext } from '@npm/core/ui/context/DarkModeContext';

type Props = {
  lightModeSrc?: string;
  darkModeSrc?: string;
  alt: string;
};

export const EducationalModalCompanyLogo = ({
  lightModeSrc,
  darkModeSrc,
  alt,
}: Props) => {
  const { isDarkMode } = useDarkModeContext();

  return <img src={isDarkMode ? darkModeSrc : lightModeSrc} alt={alt} />;
};
