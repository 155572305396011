import { type LabelProps } from '../../atoms/Label';
import { Tooltip } from '../../atoms/Tooltip';
import { type TooltipProps } from '../../atoms/Tooltip/Tooltip.types';

import * as S from './TooltipLabel.styles';

type Props = LabelProps & {
  tooltip: TooltipProps['title'];
  tooltipProps?: Omit<TooltipProps, 'title'>;
};

export const TooltipLabel = ({
  tooltip,
  tooltipProps = {},
  ...labelProps
}: Props) => {
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      <S.DashedLabel {...labelProps} />
    </Tooltip>
  );
};
