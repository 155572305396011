// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CapTableStatisticApi,
  CapTableStatisticsShowErrorCodes,
  CapTableStatisticsShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CapTableStatisticApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CapTableStatisticsShowMethod = CapTableStatisticApi['capTableStatisticsShow'];
export type CapTableStatisticsShowResponseType = MethodResult<CapTableStatisticsShowMethod>;

export const useCapTableStatisticsShow = (
  variables: Parameters<CapTableStatisticsShowMethod>[0],
  config?: UseQueryConfig<
    CapTableStatisticsShowMethod,
    CapTableStatisticsShowErrorTypes
  >
) => {
  return useQuery<
    CapTableStatisticsShowMethod,
    CapTableStatisticsShowErrorTypes
  >(
    (...args) => api.capTableStatisticsShow(...args),
    CapTableStatisticsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CapTableStatisticsShow'],
        ...apiQueryConfig.CapTableStatisticsShow,
        ...config?.queryConfig
      },
    },
    'CapTableStatisticsShow',
  );
};

export const useCapTableStatisticsShowInfinite = (
  variables: Parameters<CapTableStatisticsShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    CapTableStatisticsShowMethod,
    CapTableStatisticsShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    CapTableStatisticsShowMethod,
    CapTableStatisticsShowErrorTypes
  >(
    (...args) => api.capTableStatisticsShow(...args),
    CapTableStatisticsShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CapTableStatisticsShow', 'CapTableStatisticsShowInfinite'],
        ...apiQueryConfig.CapTableStatisticsShow,
        ...config?.queryConfig,
      },
    },
    'CapTableStatisticsShow',
  );
};

export const useCapTableStatisticsShowLazy = (baseOptions?: {
  variables?: Parameters<CapTableStatisticsShowMethod>[0],
  config?: UseQueryConfig<
    CapTableStatisticsShowMethod,
    CapTableStatisticsShowErrorTypes
  >
}) => {
  return useLazyQuery<
    CapTableStatisticsShowMethod,
    CapTableStatisticsShowErrorTypes
  >(
    (...args) => api.capTableStatisticsShow(...args),
    CapTableStatisticsShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CapTableStatisticsShow'],
        ...apiQueryConfig.CapTableStatisticsShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CapTableStatisticsShow',
  );
};

