import { TextIcon } from '@npm/core/ui/components/atoms/TextIcon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type EducationalModalConfig } from '@npm/core/ui/components/organisms/EducationalModal/EducationalModal.types';

const contentTextSharedProps = {
  size: 'default',
  colorVariant: 'primary',
} as const;

export const educationalModalAssetTypeConfig: EducationalModalConfig = {
  title: 'What are the various types of assets?',
  subTitle: 'To see the differences, click on an asset type below.',

  items: [
    {
      id: 'common',
      title: 'Common Stock',

      icon: <TextIcon label={'CS'} />,

      content: (
        <Text {...contentTextSharedProps}>
          Common Stock is a form of equity that is generally issued to founders
          and employees. Private Companies can also award employees by giving
          them stock options which they can exercise at a set price to acquire
          shares of common stock. Note that in the event of a liquidation,
          Common stock will typically be paid after preferred stock.
        </Text>
      ),
    },

    {
      id: 'option',
      title: 'Options (ISOs/NSOs)',

      icon: <TextIcon label={'OPT'} />,

      content: (
        <Text {...contentTextSharedProps}>
          Options are the most common form of equity compensation for employees
          in private companies. Options give an employee the right—but not the
          obligation—to acquire company stock at a set price (called the strike
          price). Employees in the United States can receive incentive stock
          options (ISOs) and/or non-qualified stock options (NSOs) depending on
          their employment status and other legal considerations. If you are a
          non-U.S Employee, please choose the “Other” grant type when entering
          your Option. When exercising Options, employees should be mindful that
          each grant type (ISO/NSO/Other) has unique tax implications.
        </Text>
      ),
    },

    {
      id: 'rsu',
      title: 'Restricted Stock Unit',

      icon: <TextIcon label={'RSU'} />,

      content: (
        <Text {...contentTextSharedProps}>
          Restricted Stock Units (RSUs) are a form of compensation offered to
          employees, typically as part of their overall benefits package. RSUs
          represent a promise from the company to grant shares of the company’s
          stock to an employee at a future date, provided certain conditions
          (like continued employment or company performance targets) are met.
        </Text>
      ),
    },

    {
      id: 'preferred',
      title: 'Preferred Stock',

      icon: <TextIcon label={'PS'} />,

      content: (
        <Text {...contentTextSharedProps}>
          Preferred Stock is a form of equity which can have additional rights
          and benefits that are not available to holders of Common Stock.
          Preferred stock is generally issued to investors, such as venture
          capitalists or private equity firms, in exchange for capital to fund
          the company’s operations or growth. Preferred stock is considered to
          be less risky than common stock because preferred shareholders have a
          higher priority claim to the company&apos;s assets in a liquidation or
          bankruptcy. Note that some issuers may require that preferred stock be
          converted into common stock before being transferred to a buyer.
        </Text>
      ),
    },

    {
      id: 'unit',
      title: 'Unit',

      icon: <TextIcon label={'U'} />,

      content: (
        <Text {...contentTextSharedProps}>
          Units represent a share or interest in a company, particularly in
          companies which are structured as limited liability companies (LLCs)
          or partnerships. Units are commonly issued to both employees and
          investors in companies with these structures.
        </Text>
      ),
    },

    {
      id: 'spv',
      title: 'SPV (Single / Double Layer)',

      icon: <TextIcon label={'SPV'} />,

      content: (
        <Text {...contentTextSharedProps}>
          A Special-purpose vehicle (SPV) is a legal entity which allows
          investors to pool their capital together into one vehicle which makes
          a single investment in a private company. Ownership of an SPV can be
          transferred and sold in a marketplace such as NPM’s without direct
          involvement or approval from the issuer. SPVs can charge fees to their
          investors such as a management fee which is charged on a regular basis
          and carried interest which is charged as a percentage of any profit an
          investor makes from investing in the SPV.
        </Text>
      ),
    },

    {
      id: 'warrant',
      title: 'Warrant',

      icon: <TextIcon label={'W'} />,

      content: (
        <Text {...contentTextSharedProps}>
          Similarly to options, warrants are financial instruments that give the
          holder the right, but not the obligation, to purchase the
          company&apos;s stock at a set price (called as the exercise price or
          strike price). Warrants are typically issued to investors alongside
          other securities, such as preferred stock or debt, as an added
          incentive and a way to provide additional upside.
        </Text>
      ),
    },
  ],
};
