import type { Watchlist } from '@npm/data-access';

type WatchlistMap = Map<number, Watchlist>;
/**
 * Converts watchlists to a map where issuer_entity_id is the key
 */
export const getWatchlistMap = (watchlists: Watchlist[]): WatchlistMap => {
  return new Map(
    watchlists.map(watchlist => [watchlist.issuer_entity_id, watchlist])
  );
};

export const canCompanyBeUnselected = (
  companyId: number,
  watchlistMap: WatchlistMap
) => {
  const watchlist = watchlistMap.get(companyId);
  if (!watchlist) return true;
  return watchlist.user_can_delete_company;
};

/**
 * Select onChange wrapper - does not allow removing companies that have `user_can_delete_company` set to false
 */
export const keepUndeletableValues = (
  newValues: string | string[],
  prevValues: string | string[],
  watchlistMap: WatchlistMap
) => {
  if (!Array.isArray(newValues)) return newValues; // not applicable to single select

  const prevValuesArr = Array.isArray(prevValues) ? prevValues : [prevValues];

  const newValuesProcessed = [...newValues];
  prevValuesArr.forEach((id, index) => {
    if (
      !canCompanyBeUnselected(parseInt(id), watchlistMap) &&
      !newValuesProcessed.includes(id)
    ) {
      newValuesProcessed.splice(index, 0, id);
    }
  });

  return newValuesProcessed;
};

export const areAllValuesUndeletable = (
  values: string | string[],
  watchlistMap: WatchlistMap
) => {
  const valuesArr = Array.isArray(values) ? values : [values];
  return valuesArr.every(
    (value: string) => !canCompanyBeUnselected(parseInt(value), watchlistMap)
  );
};
