import { RBAC } from '@npm/core/ui/models/permissions';
import {
  componentLoader,
  type onboardingRouteKeys,
} from '@npm/core/ui/routes/index';
import { type RouteDefinition } from '@npm/core/ui/routes/router.types';

import { TOS_URL_ROUTE } from '../constants';

type RouteDef = RouteDefinition & {
  path: onboardingRouteKeys;
};

export const workstationPrefix = 'onboarding';
export const postOnboardingPrefix = 'post-onboarding';
export const PLAIN_TOS_URL =
  `/${workstationPrefix}/terms-and-conditions/plain` as const;

const { isInvMenuOnboarding, isInvAoboOrAccountCentric, isUserNotAnonymous } =
  RBAC.investor;

export const routes: RouteDef[] = [
  {
    path: `/${postOnboardingPrefix}`,
    component: componentLoader(() => import('../pages/PostOnboarding')),
    permissionsChecks: [
      isInvAoboOrAccountCentric,
      isInvMenuOnboarding,
      isUserNotAnonymous,
    ],
    pageHistory: {
      doNotIncludeInHistory: true,
    },
  },
  {
    path: PLAIN_TOS_URL,
    needUser: false,
    component: componentLoader(() => import('../pages/PlainTOSPage')),
    mobileMenuType: 'basic',
    pageHistory: {
      doNotIncludeInHistory: true,
    },
  },
  {
    path: TOS_URL_ROUTE,
    needUser: false,
    component: componentLoader(() => import('../pages/TermsAndConditions')),
    mobileMenuType: 'basic',
    pageHistory: {
      doNotIncludeInHistory: true,
    },
  },
  {
    path: `/${workstationPrefix}/postplatform`,
    component: componentLoader(() => import('../pages/PostPlatform')),
    mobileMenuType: 'basic',
    pageHistory: {
      doNotIncludeInHistory: true,
    },
  },
];
