// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AtomicPositionApi,
  AccountAtomicPositionIndexErrorCodes,
  AccountAtomicPositionIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AtomicPositionApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountAtomicPositionIndexMethod = AtomicPositionApi['accountAtomicPositionIndex'];
export type AccountAtomicPositionIndexResponseType = MethodResult<AccountAtomicPositionIndexMethod>;

export const useAccountAtomicPositionIndex = (
  variables: Parameters<AccountAtomicPositionIndexMethod>[0],
  config?: UseQueryConfig<
    AccountAtomicPositionIndexMethod,
    AccountAtomicPositionIndexErrorTypes
  >
) => {
  return useQuery<
    AccountAtomicPositionIndexMethod,
    AccountAtomicPositionIndexErrorTypes
  >(
    (...args) => api.accountAtomicPositionIndex(...args),
    AccountAtomicPositionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAtomicPositionIndex'],
        ...apiQueryConfig.AccountAtomicPositionIndex,
        ...config?.queryConfig
      },
    },
    'AccountAtomicPositionIndex',
  );
};

export const useAccountAtomicPositionIndexInfinite = (
  variables: Parameters<AccountAtomicPositionIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountAtomicPositionIndexMethod,
    AccountAtomicPositionIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountAtomicPositionIndexMethod,
    AccountAtomicPositionIndexErrorTypes
  >(
    (...args) => api.accountAtomicPositionIndex(...args),
    AccountAtomicPositionIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountAtomicPositionIndex', 'AccountAtomicPositionIndexInfinite'],
        ...apiQueryConfig.AccountAtomicPositionIndex,
        ...config?.queryConfig,
      },
    },
    'AccountAtomicPositionIndex',
  );
};

export const useAccountAtomicPositionIndexLazy = (baseOptions?: {
  variables?: Parameters<AccountAtomicPositionIndexMethod>[0],
  config?: UseQueryConfig<
    AccountAtomicPositionIndexMethod,
    AccountAtomicPositionIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountAtomicPositionIndexMethod,
    AccountAtomicPositionIndexErrorTypes
  >(
    (...args) => api.accountAtomicPositionIndex(...args),
    AccountAtomicPositionIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountAtomicPositionIndex'],
        ...apiQueryConfig.AccountAtomicPositionIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountAtomicPositionIndex',
  );
};

