import { Codebooks } from './codebooks.types';

const items = {
  not_provided: 'not_provided',
  non_us: 'non_us',
} as const;

/**
 * This codebook contains a few extra options for filtering by country.
 * It should be used in conjunction with the COUNTRY codebook.
 * If you need just the list of countries, use COUNTRY.
 */
export const CbCountryFilters = {
  code: Codebooks.COUNTRY_FILTERS,
  items,
};
