import React from 'react';

import { TooltipLabel } from '@npm/core/ui/components/molecules/TooltipLabel';
import { type LabelVariant } from '@npm/utils';

const AGGREGATED_TOOLTIP =
  'An Aggregated holding describes the total ownership you own in a particular asset type.\n\nOnce matched, we will ask you to confirm the exact certificates/grants that are being sold';

type Props = {
  variant?: LabelVariant;
  noBg?: boolean;
};

export const AggregatedHoldingLabel = ({
  variant = 'general',
  noBg,
}: Props) => (
  <TooltipLabel variant={variant} tooltip={AGGREGATED_TOOLTIP} noBg={noBg}>
    Aggregated
  </TooltipLabel>
);
