import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';

import { GetStartedCard } from '../../../onboardingAndSubmissionSteps/components/GetStartedCard';
import { PersonaVerificationButton } from '../../Persona/PersonaVerificationButton';
import { StepContainer } from '../../StepContainer';

type Props = {
  stepProps: Wizard.StepProps;
  variant?: 'in-progress' | 'not-started';
};

export const PersonaVerification = ({
  stepProps,
  variant = 'not-started',
}: Props) => {
  return (
    <StepContainer stepProps={stepProps} isFullWidth hideStepHeading>
      <GetStartedCard
        title="We need to get to know you better"
        description={
          <Text size="sm">
            This information is collected to populate agreements and confirm
            your identity to keep you and NPM safe.
          </Text>
        }
        iconName="person-shield"
        cta={
          <PersonaVerificationButton
            buttonVariant={variant === 'not-started' ? 'outline' : 'default'}
            text={
              variant === 'not-started'
                ? 'Start Verification'
                : 'Complete Your Verification'
            }
          />
        }
      />
    </StepContainer>
  );
};
