/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { TrustedContact } from '../model';
// @ts-ignore
import { TrustedContactCreateRequestContract } from '../model';
// @ts-ignore
import { TrustedContactUpdateRequestContract } from '../model';
/**
 * TrustedContactApi - axios parameter creator
 * @export
 */
export const TrustedContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary creates the trusted contact for the person
         * @param {TrustedContactCreateRequestContract} trustedContactCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustedContactCreate: async (trustedContactCreateRequestContract: TrustedContactCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trustedContactCreateRequestContract' is not null or undefined
            assertParamExists('trustedContactCreate', 'trustedContactCreateRequestContract', trustedContactCreateRequestContract)
            const localVarPath = changeUrl(`/api/trusted-contacts`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trustedContactCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary shows the trusted contact with the given id
         * @param {string} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustedContactShow: async (id: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('trustedContactShow', 'id', id)
            const localVarPath = changeUrl(`/api/trusted-contacts/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the trusted contact with the given id
         * @param {number} id 
         * @param {TrustedContactUpdateRequestContract} trustedContactUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustedContactUpdate: async (id: number, trustedContactUpdateRequestContract: TrustedContactUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('trustedContactUpdate', 'id', id)
            // verify required parameter 'trustedContactUpdateRequestContract' is not null or undefined
            assertParamExists('trustedContactUpdate', 'trustedContactUpdateRequestContract', trustedContactUpdateRequestContract)
            const localVarPath = changeUrl(`/api/trusted-contacts/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trustedContactUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrustedContactApi - functional programming interface
 * @export
 */
export const TrustedContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrustedContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary creates the trusted contact for the person
         * @param {TrustedContactCreateRequestContract} trustedContactCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trustedContactCreate(trustedContactCreateRequestContract: TrustedContactCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrustedContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trustedContactCreate(trustedContactCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary shows the trusted contact with the given id
         * @param {string} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trustedContactShow(id: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrustedContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trustedContactShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates the trusted contact with the given id
         * @param {number} id 
         * @param {TrustedContactUpdateRequestContract} trustedContactUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trustedContactUpdate(id: number, trustedContactUpdateRequestContract: TrustedContactUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrustedContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trustedContactUpdate(id, trustedContactUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrustedContactApi - factory interface
 * @export
 */
export const TrustedContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrustedContactApiFp(configuration)
    return {
        /**
         * 
         * @summary creates the trusted contact for the person
         * @param {TrustedContactCreateRequestContract} trustedContactCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustedContactCreate(trustedContactCreateRequestContract: TrustedContactCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<TrustedContact> {
            return localVarFp.trustedContactCreate(trustedContactCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary shows the trusted contact with the given id
         * @param {string} id 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustedContactShow(id: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<TrustedContact> {
            return localVarFp.trustedContactShow(id, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the trusted contact with the given id
         * @param {number} id 
         * @param {TrustedContactUpdateRequestContract} trustedContactUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustedContactUpdate(id: number, trustedContactUpdateRequestContract: TrustedContactUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<TrustedContact> {
            return localVarFp.trustedContactUpdate(id, trustedContactUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for trustedContactCreate operation in TrustedContactApi.
 * @export
 * @interface TrustedContactApiTrustedContactCreateRequest
 */
export interface TrustedContactApiTrustedContactCreateRequest {
    /**
     * 
     * @type {TrustedContactCreateRequestContract}
     * @memberof TrustedContactApiTrustedContactCreate
     */
    readonly trustedContactCreateRequestContract: TrustedContactCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for trustedContactShow operation in TrustedContactApi.
 * @export
 * @interface TrustedContactApiTrustedContactShowRequest
 */
export interface TrustedContactApiTrustedContactShowRequest {
    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactShow
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for trustedContactUpdate operation in TrustedContactApi.
 * @export
 * @interface TrustedContactApiTrustedContactUpdateRequest
 */
export interface TrustedContactApiTrustedContactUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TrustedContactUpdateRequestContract}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly trustedContactUpdateRequestContract: TrustedContactUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof TrustedContactApiTrustedContactUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * TrustedContactApi - object-oriented interface
 * @export
 * @class TrustedContactApi
 * @extends {BaseAPI}
 */
export class TrustedContactApi extends BaseAPI {
    /**
     * 
     * @summary creates the trusted contact for the person
     * @param {TrustedContactApiTrustedContactCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrustedContactApi
     */
    public trustedContactCreate(requestParameters: TrustedContactApiTrustedContactCreateRequest, options?: AxiosRequestConfig) {
        return TrustedContactApiFp(this.configuration).trustedContactCreate(requestParameters.trustedContactCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary shows the trusted contact with the given id
     * @param {TrustedContactApiTrustedContactShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrustedContactApi
     */
    public trustedContactShow(requestParameters: TrustedContactApiTrustedContactShowRequest, options?: AxiosRequestConfig) {
        return TrustedContactApiFp(this.configuration).trustedContactShow(requestParameters.id, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the trusted contact with the given id
     * @param {TrustedContactApiTrustedContactUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrustedContactApi
     */
    public trustedContactUpdate(requestParameters: TrustedContactApiTrustedContactUpdateRequest, options?: AxiosRequestConfig) {
        return TrustedContactApiFp(this.configuration).trustedContactUpdate(requestParameters.id, requestParameters.trustedContactUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type TrustedContactCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: TrustedContact;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TrustedContactCreateErrorCodes = [
    401,
    422,
];

export type TrustedContactShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TrustedContactShowErrorCodes = [
];

export type TrustedContactUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 404;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const TrustedContactUpdateErrorCodes = [
    401,
    404,
    422,
];


