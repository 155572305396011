import { useMemo, useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import {
  type AccountUiModeType,
  CbAccountUiMode,
  useAccountUpdate,
  useWatchlistCreate,
  useWatchlistIndex,
} from '@npm/data-access';

import {
  isIndividualAccount,
  isNPMS,
  useUserContextStore,
} from '../../auth/user/context';

import { TradingModeModal } from './TradingModeModal';

export type TradingModeFormValues = {
  account_ui_mode: AccountUiModeType;
  watchlist?: string[];
};

export const useTradingModeModal = (
  defaultValue?: AccountUiModeType
): [
  {
    openModal: () => void;
    handleTradingModeMenuItemClick: (key: string) => boolean;
  },
  { tradingModal: JSX.Element },
] => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<TradingModeFormValues>();

  const history = useHistory();
  const { withShowApiErrorMiddleware } = useAlerts();

  const investorAccounts = useUserContextStore(store => store.investorAccounts);
  const npmsAccount = useMemo(() => {
    return investorAccounts?.find(
      role => isNPMS(role?.subject) && isIndividualAccount(role)
    );
  }, [investorAccounts]);
  const npmsAccountId = npmsAccount?.subject?.id;

  const currentAccountUiMode = npmsAccount?.subject?.account_ui_mode
    ?.code as AccountUiModeType;

  const initialAccountUiMode =
    defaultValue ?? currentAccountUiMode ?? CbAccountUiMode.items.seller;

  const { data: watchlistData, isLoading: watchlistLoading } =
    useWatchlistIndex(
      {
        accountId: npmsAccountId,
        includeField: ['account', 'issuer_entity', 'user_can_delete_company'],
        size: 1,
      },
      {
        roles: {
          roleId: undefined,
          acrossAccount: true,
        },
        queryConfig: {
          enabled: !!npmsAccountId,
        },
      }
    );

  const hasWatchlist = watchlistData?.watchlists?.length > 0;

  const { execute: createWatchlist, isLoading: createLoading } =
    useWatchlistCreate();

  const { execute: updateAccount, isLoading: updateLoading } =
    useAccountUpdate();

  const handleOnClose = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleTradingModeChange = async () => {
    let values: TradingModeFormValues;

    try {
      values = await form.validateFields();
    } catch (error) {
      console.error(error);
      return;
    }

    if (values.account_ui_mode === currentAccountUiMode) {
      setIsModalOpen(false);
      return;
    }

    if (!hasWatchlist) {
      await withShowApiErrorMiddleware(createWatchlist)({
        watchlistCreateRequestContract: {
          account_id: npmsAccountId,
          issuer_entity_id: values.watchlist.map(Number),
        },
      });
    }

    await withShowApiErrorMiddleware(updateAccount)(
      {
        id: npmsAccountId,
        accountUpdateRequestContract: {
          account_ui_mode: values.account_ui_mode,
        },
      },
      {
        onComplete(data) {
          if (data?.account_ui_mode?.code === 'seller') {
            history.replace(
              `/second-market/investor/company-overview?allAccounts=1&companyId=${watchlistData?.watchlists[0]?.issuer_entity_id}`
            );
          }

          if (data?.account_ui_mode?.code === 'buyer_and_seller') {
            history.replace('/investor-workstation/individual-dashboard');
          }
        },
      }
    );
    handleOnClose();
  };

  const modalComponent = (
    <TradingModeModal
      isOpen={isModalOpen}
      initialValues={{ account_ui_mode: initialAccountUiMode }}
      onCancel={handleOnClose}
      onOk={handleTradingModeChange}
      okButtonProps={{ loading: createLoading || updateLoading }}
      form={form}
      hasExistingWatchlist={hasWatchlist}
      watchlistLoading={watchlistLoading}
    />
  );

  const handleTradingModeMenuItemClick = (key: string) => {
    if (key === 'trading-mode') {
      setIsModalOpen(true);
      return true;
    }
    return false;
  };

  return [
    {
      openModal: () => setIsModalOpen(true),
      handleTradingModeMenuItemClick,
    },
    { tradingModal: modalComponent },
  ];
};
