import React from 'react';

import { type InputProps } from 'antd';
import {
  formatIncompletePhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';

import { FormattableInput } from './index';

type PhoneNumberInputProps = Omit<InputProps, 'value'> & {
  value?: string | null;
  onChange?: (value: string) => void;
};

const parseNumber = (text: string) => {
  if (!text) {
    return text;
  }

  try {
    return parsePhoneNumber(text, 'US').number;
  } catch {
    return text;
  }
};

const formatNumber = (text: string) => {
  if (!text) {
    return text;
  }

  try {
    const formatted = formatIncompletePhoneNumber(
      text.startsWith('+1') && text.length === 12
        ? text.replace('+1', '')
        : text,
      'US'
    );
    if (formatted.endsWith(')')) {
      return text.replace(/[()\s]/g, '');
    }
    return formatted;
  } catch {
    return text;
  }
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  ...props
}) => {
  return (
    <FormattableInput
      {...props}
      formatter={formatNumber}
      prefix={'+'}
      parser={parseNumber}
      value={props.value}
      type={'tel'}
    />
  );
};
