// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TrustedContactApi,
  TrustedContactCreateErrorCodes,
  TrustedContactShowErrorCodes,
  TrustedContactShowErrorTypes,
  TrustedContactUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TrustedContactApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TrustedContactCreateMethod = TrustedContactApi['trustedContactCreate'];

export const useTrustedContactCreate = () => {
  return useMutation<TrustedContactCreateMethod>(
    (...args) => api.trustedContactCreate(...args),
    TrustedContactCreateErrorCodes,
    'TrustedContactCreate',
    '/api/trusted-contacts',
    'post'
  );
};

export type TrustedContactShowMethod = TrustedContactApi['trustedContactShow'];
export type TrustedContactShowResponseType = MethodResult<TrustedContactShowMethod>;

export const useTrustedContactShow = (
  variables: Parameters<TrustedContactShowMethod>[0],
  config?: UseQueryConfig<
    TrustedContactShowMethod,
    TrustedContactShowErrorTypes
  >
) => {
  return useQuery<
    TrustedContactShowMethod,
    TrustedContactShowErrorTypes
  >(
    (...args) => api.trustedContactShow(...args),
    TrustedContactShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TrustedContactShow'],
        ...apiQueryConfig.TrustedContactShow,
        ...config?.queryConfig
      },
    },
    'TrustedContactShow',
  );
};

export const useTrustedContactShowInfinite = (
  variables: Parameters<TrustedContactShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    TrustedContactShowMethod,
    TrustedContactShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    TrustedContactShowMethod,
    TrustedContactShowErrorTypes
  >(
    (...args) => api.trustedContactShow(...args),
    TrustedContactShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['TrustedContactShow', 'TrustedContactShowInfinite'],
        ...apiQueryConfig.TrustedContactShow,
        ...config?.queryConfig,
      },
    },
    'TrustedContactShow',
  );
};

export const useTrustedContactShowLazy = (baseOptions?: {
  variables?: Parameters<TrustedContactShowMethod>[0],
  config?: UseQueryConfig<
    TrustedContactShowMethod,
    TrustedContactShowErrorTypes
  >
}) => {
  return useLazyQuery<
    TrustedContactShowMethod,
    TrustedContactShowErrorTypes
  >(
    (...args) => api.trustedContactShow(...args),
    TrustedContactShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['TrustedContactShow'],
        ...apiQueryConfig.TrustedContactShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'TrustedContactShow',
  );
};


export type TrustedContactUpdateMethod = TrustedContactApi['trustedContactUpdate'];

export const useTrustedContactUpdate = () => {
  return useMutation<TrustedContactUpdateMethod>(
    (...args) => api.trustedContactUpdate(...args),
    TrustedContactUpdateErrorCodes,
    'TrustedContactUpdate',
    '/api/trusted-contacts/{id}',
    'put'
  );
};
