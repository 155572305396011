import { type CSSProperties } from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';

type Props = {
  style?: CSSProperties;
};

export const OrderStatusFirm = ({ style }: Props) => (
  <Label
    variant="info"
    round
    icon={<Icon name="circle-check-filled" />}
    style={style}
  >
    Firm
  </Label>
);
