import { type CbLabelComponent, type LabelVariant } from '@npm/utils';

import { Codebooks } from './codebooks.types';
import type { CodebookOrString } from '../generated';
import { getCodebookCode, getCodebookName } from '../../utils';

const Items = {
  no: 'no',
  yes: 'yes',
  not_provided: 'not_provided',
} as const;

type Keys = keyof typeof Items;

const LabelMap: Record<Keys, LabelVariant> = {
  no: 'info',
  yes: 'info',
  not_provided: 'general',
};

export const CbInstitutionalClient = {
  code: Codebooks.INSTITUTIONAL_CLIENT,
  items: Items,
  getLabel: (
    Component: CbLabelComponent,
    codebook: CodebookOrString | undefined
  ) =>
    Component({
      variant: LabelMap[(getCodebookCode<Keys>(codebook)) ?? 'none'],
      label: getCodebookName(codebook),
      round: true,
    }),
};
