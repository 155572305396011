import { Text } from '@npm/core/ui/components/atoms/Typography';

import {
  BasicPageLayout,
  type BasicPageLayoutProps,
} from '../components/BasicPageLayout';

export type TapeDPricingBasicProps = {
  companyName: string;
};

const mainCard: BasicPageLayoutProps['mainCard'] = {
  imagePrefix: 'taped-main-card-basic',
  title: 'Institutional-Grade Private Market Data',
  description:
    'Get unparalleled transparency into the price factors driving valuations in the private market. Stay ahead of market movements with comprehensive price insights from Tape D™ powered by Nasdaq Private Market.',
  showUpgradeButton: true,
  titleMaxWidth: '300px',
};

const footerCard: BasicPageLayoutProps['footerCard'] = {
  image: {
    left: {
      prefix: 'taped-footer-card-basic-left',
      position: {
        tablet: {
          x: '-20px',
        },
        desktop: {
          x: '10%',
        },
      },
    },
    right: {
      prefix: 'taped-footer-card-basic-right',
      position: {
        tablet: {
          x: '22%',
        },
        desktop: {
          x: '40%',
        },
      },
    },
    mobilePrefix: 'taped-footer-card-basic-mobile',
  },
  title: 'Stay Ahead of the Private Market',
  titleMaxWidth: '365px',
  description: 'Get access to Premium Data by NPM',
};

const DISPLAY_INLINE_STYLES = { display: 'inline' };
const middleCards: BasicPageLayoutProps['middleCards'] = [
  {
    imagePrefix: 'taped-basic',
    title: 'Tape D™ Daily Price',
    description: (
      <Text size="sm">
        Understand the daily price movements of liquid companies with the{' '}
        <Text size="sm" weight="bold" style={DISPLAY_INLINE_STYLES}>
          Tape D™ Daily Price
        </Text>
        . Our proprietary methodology uses advanced signal processing to
        estimate the intrinsic value of private companies, enabling time series
        analysis and comparison against other companies or sectors.
      </Text>
    ),
  },
  {
    imagePrefix: 'closed-trades-basic',
    title: 'Reported Trade Levels',
    description:
      "Monitor where secondary transactions are clearing across the market via closed trade levels. See the total transaction volume generated by NPM and the company's data contribution partner network, along with the average PPS and implied valuation for each month with activity.",
  },
  {
    imagePrefix: 'bid-and-offer-basic',
    title: 'Bid + Offer History',
    description:
      "See where the secondary market is valuing private company shares based on over 6,000 historical buy and sell indications collected by NPM and the company's network of institutional brokers, banks and investors. Track market activity, bid/ask spreads, and  whether implied valuations are a premium (or discount) to the last primary round.",
  },
  {
    imagePrefix: 'primary-rounds-basic',
    title: 'Primary Rounds',
    description:
      "Tape D™ provides detailed information on the price per share and estimated valuation of each primary round. These valuations are calculated based on fully diluted shares outstanding, tracked using NPM's proprietary methodology and issued share count estimates.",
  },
  {
    imagePrefix: '409-valuations-basic',
    title: '409A Valuations',
    description:
      'Gain insights into the fair market value (FMV) of common stock for over 4,000 private companies. Leverages reported data to provide a clear view of whether the FMV of common stock is increasing or decreasing. This valuable information helps you stay informed about market trends and make data-driven investment decisions.',
  },

  {
    imagePrefix: 'mutual-fund-marks-basic',
    title: 'Mutual Fund Marks',
    description:
      'Tape D™ makes it simple to see how mutual funds mark the value of their private stock, leveraging data reported to the SEC. With over 20,000 marks collected from hundreds of private funds, you can easily filter by specific parent entities or time period and understand distinctions between how common and preferred stock are valued.',
  },
];

const readMoreModal: BasicPageLayoutProps['readMoreModal'] = {
  imagePrefix: 'taped-read-more-modal-basic',
  title: 'What is the Tape D™ Pricing suite?',
  description: (
    <>
      <Text size="sm">
        Tape D™ by Nasdaq Private Market offers a robust suite of tools to
        address the challenges of private stock market pricing. With infrequent
        trades and significant illiquidity, private market price discovery is
        often difficult. Tape D™ provides a detailed understanding of market
        data, integrating primary rounds, closed trades, orders, 409A
        valuations, mutual fund marks, and public company comparables.
      </Text>
      <Text size="sm">
        Tape D™ compiles extensive data, including over 4,700 valuations, 5,000
        capital raises, and 1.4 million deal terms from 550 companies. It also
        includes 6,000 buy and sell indications, representing $25 billion in
        order volume. This comprehensive dataset provides investors with
        accurate intelligence on a stock&apos;s current value and historical
        performance.
      </Text>
    </>
  ),
};

export const TapeDPricingBasic = ({ companyName }: TapeDPricingBasicProps) => {
  return (
    <BasicPageLayout
      companyName={companyName}
      mainCard={mainCard}
      footerCard={footerCard}
      middleCards={middleCards}
      readMoreModal={readMoreModal}
    />
  );
};
