import { useEffect, useState } from 'react';

import { type CustomUserClaims, type UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export const useOktaUserClaims = () => {
  const { oktaAuth } = useOktaAuth();
  const [userClaims, setUserClaims] = useState<UserClaims<CustomUserClaims>>();

  useEffect(() => {
    oktaAuth.getUser().then(res => {
      setUserClaims(res);
    });
  }, [oktaAuth]);

  return userClaims;
};
