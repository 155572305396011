import generatePicker from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import styled from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import {
  InputTextStyles,
  SmallTextStyles,
} from '../Typography/typography.styles';

import { HeightStyle } from './styles';

const CLEAR_OFFSET = 38;
const MOBILE_WIDTH = 280;

export const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const RangePicker = styled(DatePicker.RangePicker)`
  ${InputTextStyles};
  ${HeightStyle}
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm}px;
  border-color: ${({ theme }) => theme.color.general.borders.secondary};
  background-color: ${({ theme }) => theme.color.general.layout.two};
  color: ${({ theme }) => theme.color.general.typography.primary};

  & > .ant-picker-input {
    & > input {
      ${InputTextStyles};
      padding-right: ${({ theme }) => theme.spacing.sm}px;
    }

    & > input::placeholder {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  &.ant-picker-focused {
    border-color: ${({ theme }) => theme.color.info.borders.primary};
    background-color: ${({ theme }) => theme.color.general.layout.one};
    box-shadow:
      ${({ theme }) => theme.shadow.level1},
      0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
  }

  & .ant-picker-active-bar {
    background-color: ${({ theme }) => theme.color.info.borders.primary};
  }

  & > .ant-picker-suffix {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  & > .ant-picker-clear {
    right: ${CLEAR_OFFSET}px;
    background: none;
  }
`;

export const DateRangePickerStyledPanel = styled.div`
  .ant-picker-cell-range-start:before,
  .ant-picker-cell-range-end:before,
  .ant-picker-cell-in-range:before {
    background: transparent !important;
    border-top: 1px dashed ${({ theme }) => theme.color.info.typography.primary};
    border-bottom: 1px dashed
      ${({ theme }) => theme.color.info.typography.primary};
  }

  .ant-picker-cell-range-start,
  .ant-picker-cell-range-end {
    color: ${({ theme }) => theme.color.info.typography.primary};
    .ant-picker-cell-inner {
      border: 1px solid ${({ theme }) => theme.color.info.typography.primary};
      background: ${({ theme }) => theme.color.info.backgrounds.secondaryHover};
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }
  }

  .ant-picker-cell-range-start {
    .ant-picker-cell-inner {
      color: ${({ theme }) => theme.color.info.typography.primary};
      &:before {
        border: none;
      }
    }
  }

  .ant-picker-ranges {
    display: flex;
    gap: ${({ theme }) => theme.spacing.sm}px;
  }

  .ant-tag {
    ${SmallTextStyles}
    background: ${({ theme }) =>
      theme.color.info.backgrounds.secondaryDefault} !important;
    padding: ${({ theme }) => theme.spacing.xs}px
      ${({ theme }) => theme.spacing.sm}px !important;
    color: ${({ theme }) => theme.color.info.typography.primary} !important;
    border: 1px solid ${({ theme }) => theme.color.info.typography.primary} !important;
    border-radius: ${({ theme }) => theme.borderRadius.md}px !important;

    &::before,
    &::after {
      display: none;
    }
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
    width: ${MOBILE_WIDTH}px;
    overflow: hidden;

    .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
    .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
      visibility: initial !important;
    }
  `}
`;
