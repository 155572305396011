import { useWizard } from 'react-use-wizard';

import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import { type PostOnboardingContextType } from '../PostOnboarding.types';
import { findNextIncompleteOnboardingStep } from '../PostOnboarding.utils';
import { ACTIVE_STEP_QUERY_PARAM_KEY } from '../PostOnboardingWizardWrapper';

export const useReturnUrl = () => {
  const { activeStep } = useWizard();

  const { steps, onboardingStatus } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      steps: s.steps,
      onboardingStatus: s.data.onboardingStatus,
    })
  );

  const nextIncompleteStep = findNextIncompleteOnboardingStep({
    steps,
    onboardingStatus,
    startIndex: activeStep,
  });

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.set(ACTIVE_STEP_QUERY_PARAM_KEY, nextIncompleteStep.toString());
  url.search = params.toString();

  return url.toString();
};
