import React, { type ComponentProps, type FC, type ReactNode } from 'react';

import { CardBigRow } from '@npm/core/ui/components/atoms/common';
import { BigNumberValue } from '@npm/core/ui/components/molecules/BigNumberValue';
import { type Card } from '@npm/core/ui/components/molecules/Card';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { type Modal } from '@npm/core/ui/components/molecules/Modal';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import { CypressDataIds } from '@npm/core/ui/constants';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';
import { type DocumentSimple, type Holding } from '@npm/data-access';

import { StatusChangeButtons } from '../components';
import { HoldingLabel } from '../components/HoldingLabel';
import { getDisabledHoldingActionsTooltip } from '../Holdings.utils';

import * as S from './HoldingCard.styles';
import { ActionButtons } from './ActionButtons';
import { getHoldingRows } from './HoldingCard.rows';

type Props = {
  isLoading?: boolean;
  holding?: Holding;
  header?:
    | ((holding: Holding) => ComponentProps<typeof Card>['header'])
    | ComponentProps<typeof Card>['header']
    | boolean;
  footer?: ReactNode;
  footerVariant?: S.FooterVariant;
  config?: {
    showOfferPrice?: boolean;
    showRemainingQuantity?: boolean;
    accountWithoutLink?: boolean;
    showAccount?: boolean;
    showIssuance?: boolean;
    showVerifiedOwnership?: boolean;
    showProofOfOwnership?: boolean;
    showAsset?: boolean;
    showQuantity?: boolean;
    showVestedQuantity?: boolean;
    showClass?: boolean;
    showPreVerified?: boolean;
    showStatus?: boolean;
    showTimestamps?: boolean;
    numberOfRowsToShow?: number;
    onEditClick?: (id: number, holding: Holding) => void;
    onDeleteClick?: (holding: Holding) => void;
    onStatusChangeButtonClick?: ComponentProps<
      typeof StatusChangeButtons
    >['onClick'];
    onEnterIOIClick?: (holding: Holding) => void;
    getHoldingStateTooltip?: (holding: Holding) => string | null;
  };
  onDocumentClick?: (doc: DocumentSimple) => void;
  openModal?: (props?: ComponentProps<typeof Modal>) => void;
  notAuthorized?: boolean;
  className?: string;
};

export const HoldingCard: FC<Props> = ({
  isLoading,
  holding,
  header = true,
  footer,
  footerVariant,
  config = {},
  openModal,
  onDocumentClick,
  notAuthorized,
  className,
}) => {
  const {
    onDeleteClick,
    showOfferPrice,
    showRemainingQuantity,
    accountWithoutLink,
    showAccount,
    showClass = true,
    showVerifiedOwnership = true,
    showProofOfOwnership = true,
    onEditClick,
    onStatusChangeButtonClick,
    onEnterIOIClick,
    showIssuance,
    showPreVerified = false,
    showStatus = true,
    showTimestamps = true,
    numberOfRowsToShow,
    getHoldingStateTooltip,
  } = config;

  const isEditable = !!onEditClick && !!onDeleteClick;
  const shouldShowActionButtons = isEditable && !onStatusChangeButtonClick;
  const shouldShowStatusChangeButtons = !!onStatusChangeButtonClick;

  const getHeaderConfig = (): ComponentProps<typeof Card>['header'] => {
    if (header === true) {
      return {
        icon: !showIssuance && (
          <CompanyLogo
            url={holding?.issuer_entity?.logo_url}
            title={holding?.issuer_entity?.logo_name}
            size="sm"
          />
        ),
        iconText: holding?.issuer_entity?.name,
        label: <HoldingLabel holding={holding} isUniqueIdClickable={false} />,
      };
    } else if (header) {
      return typeof header === 'function' ? header?.(holding) : header;
    }
    return null;
  };

  const actionButtons = (
    <ActionButtons
      holding={holding}
      onEditClick={() => onEditClick(holding?.id, holding)}
      onDeleteClick={() => onDeleteClick(holding)}
      onEnterIOIClick={onEnterIOIClick && (() => onEnterIOIClick(holding))}
      disabledTooltip={
        getDisabledActionTooltipText(notAuthorized) ||
        getDisabledHoldingActionsTooltip(holding)
      }
    />
  );

  const statusChangeButtons = (
    <StatusChangeButtons
      holding={holding}
      onClick={onStatusChangeButtonClick}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
    />
  );

  return (
    <S.Card
      isLoading={isLoading}
      header={getHeaderConfig()}
      footer={
        footer ??
        ((isEditable || onStatusChangeButtonClick) && (
          <>
            {shouldShowActionButtons && actionButtons}
            {shouldShowStatusChangeButtons && statusChangeButtons}
          </>
        ))
      }
      $footerVariant={footerVariant}
      className={className}
      data-cy={CypressDataIds.Holdings.Cards.Card}
    >
      <CardBigRow>
        <BigNumberValue title={'Quantity'} value={holding?.quantity} />
        <BigNumberValue title={'Vested Quantity'} value={holding?.vested_qty} />
      </CardBigRow>
      <TableVertical
        rows={getHoldingRows({
          onDocumentClick,
          config: {
            showOfferPrice,
            showAccount,
            accountWithoutLink,
            showClass,
            showVerifiedOwnership,
            showRemainingQuantity,
            showProofOfOwnership,
            onStatusChangeButtonClick,
            showIssuance,
            showPreVerified,
            showStatus,
            showTimestamps,
            numberOfRowsToShow,
            getHoldingStateTooltip,
            onEditClick,
          },
          openModal,
        })}
        data={holding}
      />
    </S.Card>
  );
};
