// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuerEntityPriceRangeApi,
  IssuerEntityPriceRangeShowErrorCodes,
  IssuerEntityPriceRangeShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuerEntityPriceRangeApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type IssuerEntityPriceRangeShowMethod = IssuerEntityPriceRangeApi['issuerEntityPriceRangeShow'];
export type IssuerEntityPriceRangeShowResponseType = MethodResult<IssuerEntityPriceRangeShowMethod>;

export const useIssuerEntityPriceRangeShow = (
  variables: Parameters<IssuerEntityPriceRangeShowMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityPriceRangeShowMethod,
    IssuerEntityPriceRangeShowErrorTypes
  >
) => {
  return useQuery<
    IssuerEntityPriceRangeShowMethod,
    IssuerEntityPriceRangeShowErrorTypes
  >(
    (...args) => api.issuerEntityPriceRangeShow(...args),
    IssuerEntityPriceRangeShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityPriceRangeShow'],
        ...apiQueryConfig.IssuerEntityPriceRangeShow,
        ...config?.queryConfig
      },
    },
    'IssuerEntityPriceRangeShow',
  );
};

export const useIssuerEntityPriceRangeShowInfinite = (
  variables: Parameters<IssuerEntityPriceRangeShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    IssuerEntityPriceRangeShowMethod,
    IssuerEntityPriceRangeShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    IssuerEntityPriceRangeShowMethod,
    IssuerEntityPriceRangeShowErrorTypes
  >(
    (...args) => api.issuerEntityPriceRangeShow(...args),
    IssuerEntityPriceRangeShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['IssuerEntityPriceRangeShow', 'IssuerEntityPriceRangeShowInfinite'],
        ...apiQueryConfig.IssuerEntityPriceRangeShow,
        ...config?.queryConfig,
      },
    },
    'IssuerEntityPriceRangeShow',
  );
};

export const useIssuerEntityPriceRangeShowLazy = (baseOptions?: {
  variables?: Parameters<IssuerEntityPriceRangeShowMethod>[0],
  config?: UseQueryConfig<
    IssuerEntityPriceRangeShowMethod,
    IssuerEntityPriceRangeShowErrorTypes
  >
}) => {
  return useLazyQuery<
    IssuerEntityPriceRangeShowMethod,
    IssuerEntityPriceRangeShowErrorTypes
  >(
    (...args) => api.issuerEntityPriceRangeShow(...args),
    IssuerEntityPriceRangeShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['IssuerEntityPriceRangeShow'],
        ...apiQueryConfig.IssuerEntityPriceRangeShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'IssuerEntityPriceRangeShow',
  );
};

