import React, { type ComponentProps, useMemo, useState } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';
import { type DocumentAggregate } from '@npm/data-access';

import * as S from '../FileExporer.styles';
import { DocumentIcon } from '../../../../../documents/DocumentIcon/DocumentIcon';
import { type DataRoom } from '../../../DataRoom';
import { EditDocumentDrawer } from '../../EditDocumentDrawer';

type Props = {
  document: DocumentAggregate;
  isFolderEditable: boolean;
  useEditableProp?: boolean;
  actionsDisabled?: boolean;
} & Pick<
  ComponentProps<typeof DataRoom>,
  'onDownloadClick' | 'onDocumentEdit' | 'onDocumentDelete' | 'onDocumentClick'
>;

const supportedFormatsForPreview = [
  'image/png',
  'image/jpeg',
  'application/pdf',
];

const ProcessingIcon = () => (
  <Tooltip title="File is being processed">
    <S.ProcessingIcon>
      <Icon name="loader" size="xs" />
    </S.ProcessingIcon>
  </Tooltip>
);

export const DocumentRow = ({
  document,
  isFolderEditable,
  useEditableProp,
  onDownloadClick,
  onDocumentEdit,
  onDocumentDelete,
  onDocumentClick,
  actionsDisabled,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  const isProcessing = document.state === 'NEW';

  const actions = useMemo(() => {
    return [
      {
        label: 'Download',
        icon: <Icon name="download" size="xs" />,
        onClick: () => onDownloadClick?.(document.id, document.display_name),
        hidden: !document.downloadable || isProcessing,
        'data-cy':
          CypressDataIds.DataRooms.FileExplorer.DocumentDownloadTrigger,
      },
      {
        label: 'Edit Document',
        icon: <Icon name="edit" size="xs" />,
        onClick: () => setIsEditDrawerOpen(true),
        hidden:
          !isFolderEditable ||
          (useEditableProp && !document.editable) ||
          isProcessing,
        disabled: actionsDisabled,
        tooltip: getDisabledActionTooltipText(actionsDisabled),
        'data-cy': CypressDataIds.DataRooms.EditDocumentDrawer.TriggerButton,
      },
      {
        label: 'Delete',
        icon: <Icon name="trash" size="xs" />,
        onClick: () => setIsConfirmDeleteModalOpen(true),
        danger: true,
        hidden: !isFolderEditable || (useEditableProp && !document.editable),
        disabled: actionsDisabled,
        tooltip: getDisabledActionTooltipText(actionsDisabled),
        'data-cy': CypressDataIds.DataRooms.DeleteFileModal.TriggerButton,
      },
    ].filter(action => !action.hidden);
  }, [
    document,
    onDownloadClick,
    isFolderEditable,
    useEditableProp,
    actionsDisabled,
    isProcessing,
  ]);

  const isSupportedForPreview = supportedFormatsForPreview.includes(
    document.content_type
  );

  const title = isProcessing ? (
    <Text size="sm" color="general" colorVariant="tertiary">
      {document.display_name}
    </Text>
  ) : (
    <Text
      size="sm"
      color={isSupportedForPreview ? 'info' : 'general'}
      onClick={isSupportedForPreview ? () => onDocumentClick?.(document) : null}
    >
      {document.display_name}
    </Text>
  );

  return (
    <S.FileRow data-cy={CypressDataIds.DataRooms.FileExplorer.DocumentRow}>
      <S.Title>
        <S.IconContainer>
          {isProcessing ? (
            <ProcessingIcon />
          ) : (
            <>
              <DocumentIcon content_type={document.content_type} />
              {document.watermark && (
                <S.IconBadge>
                  <Icon
                    name="droplet-filled"
                    size={6}
                    color="white"
                    strokeWidth={0.5}
                  />
                </S.IconBadge>
              )}
            </>
          )}
        </S.IconContainer>
        {title}
      </S.Title>

      <S.Actions>
        {isMobile ? (
          <Dropdown items={actions}>
            <Button
              variant="text"
              icon={<Icon name="dots-vertical" size="xs" />}
              data-cy={CypressDataIds.DataRooms.FileExplorer.RowDropdownTrigger}
              hidden={actions.length === 0}
            />
          </Dropdown>
        ) : (
          actions.map(({ label, icon, onClick, danger, disabled, ...rest }) => (
            <Tooltip
              title={getDisabledActionTooltipText(disabled)}
              placement={'topLeft'}
              key={label}
            >
              <Button
                icon={icon}
                size="md"
                variant="text"
                color={danger ? 'error' : 'info'}
                onClick={onClick}
                disabled={disabled}
                {...rest}
              />
            </Tooltip>
          ))
        )}
      </S.Actions>

      <EditDocumentDrawer
        document={document}
        isOpen={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
        onSubmit={onDocumentEdit}
      />

      <Modal
        title="Delete the document"
        okText="Delete"
        cancelText="Cancel"
        destroyOnClose
        open={isConfirmDeleteModalOpen}
        onOk={async () => {
          try {
            await onDocumentDelete?.(document.id);
            setIsConfirmDeleteModalOpen(false);
          } catch (e) {
            console.error(e);
          }
        }}
        okButtonProps={{ color: 'error', icon: <Icon name="trash" /> }}
        onCancel={() => {
          setIsConfirmDeleteModalOpen(false);
        }}
        size="md"
      >
        You are about to delete a document ‘<b>{document.display_name}</b>’. Are
        you sure you want to continue? This action can’t be undone.
      </Modal>
    </S.FileRow>
  );
};
