// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  MarkToMarketsApi,
  MarkToMarketMutualFundEquityAssetsIndexErrorCodes,
  MarkToMarketMutualFundEquityAssetsIndexErrorTypes,
  MarkToMarketCompanyEquityAssetsIndexErrorCodes,
  MarkToMarketCompanyEquityAssetsIndexErrorTypes,
  MarkToMarketIssuerEquityAssetsIndexErrorCodes,
  MarkToMarketIssuerEquityAssetsIndexErrorTypes,
  MarkToMarketUpdateErrorCodes,
  MarkToMarketGetPendingErrorCodes,
  MarkToMarketGetPendingErrorTypes,
  MarkToMarketMovingAveragePricesErrorCodes,
  MarkToMarketMovingAveragePricesErrorTypes,
  MarkToMarketQuarterlyStatsIndexErrorCodes,
  MarkToMarketQuarterlyStatsIndexErrorTypes,
  MarkToMarketQuarterlyStatsIndexByCompanyErrorCodes,
  MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new MarkToMarketsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type MarkToMarketMutualFundEquityAssetsIndexMethod = MarkToMarketsApi['markToMarketMutualFundEquityAssetsIndex'];
export type MarkToMarketMutualFundEquityAssetsIndexResponseType = MethodResult<MarkToMarketMutualFundEquityAssetsIndexMethod>;

export const useMarkToMarketMutualFundEquityAssetsIndex = (
  variables: Parameters<MarkToMarketMutualFundEquityAssetsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketMutualFundEquityAssetsIndexMethod,
    MarkToMarketMutualFundEquityAssetsIndexErrorTypes
  >
) => {
  return useQuery<
    MarkToMarketMutualFundEquityAssetsIndexMethod,
    MarkToMarketMutualFundEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketMutualFundEquityAssetsIndex(...args),
    MarkToMarketMutualFundEquityAssetsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketMutualFundEquityAssetsIndex'],
        ...apiQueryConfig.MarkToMarketMutualFundEquityAssetsIndex,
        ...config?.queryConfig
      },
    },
    'MarkToMarketMutualFundEquityAssetsIndex',
  );
};

export const useMarkToMarketMutualFundEquityAssetsIndexInfinite = (
  variables: Parameters<MarkToMarketMutualFundEquityAssetsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarkToMarketMutualFundEquityAssetsIndexMethod,
    MarkToMarketMutualFundEquityAssetsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarkToMarketMutualFundEquityAssetsIndexMethod,
    MarkToMarketMutualFundEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketMutualFundEquityAssetsIndex(...args),
    MarkToMarketMutualFundEquityAssetsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketMutualFundEquityAssetsIndex', 'MarkToMarketMutualFundEquityAssetsIndexInfinite'],
        ...apiQueryConfig.MarkToMarketMutualFundEquityAssetsIndex,
        ...config?.queryConfig,
      },
    },
    'MarkToMarketMutualFundEquityAssetsIndex',
  );
};

export const useMarkToMarketMutualFundEquityAssetsIndexLazy = (baseOptions?: {
  variables?: Parameters<MarkToMarketMutualFundEquityAssetsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketMutualFundEquityAssetsIndexMethod,
    MarkToMarketMutualFundEquityAssetsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    MarkToMarketMutualFundEquityAssetsIndexMethod,
    MarkToMarketMutualFundEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketMutualFundEquityAssetsIndex(...args),
    MarkToMarketMutualFundEquityAssetsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarkToMarketMutualFundEquityAssetsIndex'],
        ...apiQueryConfig.MarkToMarketMutualFundEquityAssetsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarkToMarketMutualFundEquityAssetsIndex',
  );
};


export type MarkToMarketCompanyEquityAssetsIndexMethod = MarkToMarketsApi['markToMarketCompanyEquityAssetsIndex'];
export type MarkToMarketCompanyEquityAssetsIndexResponseType = MethodResult<MarkToMarketCompanyEquityAssetsIndexMethod>;

export const useMarkToMarketCompanyEquityAssetsIndex = (
  variables: Parameters<MarkToMarketCompanyEquityAssetsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketCompanyEquityAssetsIndexMethod,
    MarkToMarketCompanyEquityAssetsIndexErrorTypes
  >
) => {
  return useQuery<
    MarkToMarketCompanyEquityAssetsIndexMethod,
    MarkToMarketCompanyEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketCompanyEquityAssetsIndex(...args),
    MarkToMarketCompanyEquityAssetsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketCompanyEquityAssetsIndex'],
        ...apiQueryConfig.MarkToMarketCompanyEquityAssetsIndex,
        ...config?.queryConfig
      },
    },
    'MarkToMarketCompanyEquityAssetsIndex',
  );
};

export const useMarkToMarketCompanyEquityAssetsIndexInfinite = (
  variables: Parameters<MarkToMarketCompanyEquityAssetsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarkToMarketCompanyEquityAssetsIndexMethod,
    MarkToMarketCompanyEquityAssetsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarkToMarketCompanyEquityAssetsIndexMethod,
    MarkToMarketCompanyEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketCompanyEquityAssetsIndex(...args),
    MarkToMarketCompanyEquityAssetsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketCompanyEquityAssetsIndex', 'MarkToMarketCompanyEquityAssetsIndexInfinite'],
        ...apiQueryConfig.MarkToMarketCompanyEquityAssetsIndex,
        ...config?.queryConfig,
      },
    },
    'MarkToMarketCompanyEquityAssetsIndex',
  );
};

export const useMarkToMarketCompanyEquityAssetsIndexLazy = (baseOptions?: {
  variables?: Parameters<MarkToMarketCompanyEquityAssetsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketCompanyEquityAssetsIndexMethod,
    MarkToMarketCompanyEquityAssetsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    MarkToMarketCompanyEquityAssetsIndexMethod,
    MarkToMarketCompanyEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketCompanyEquityAssetsIndex(...args),
    MarkToMarketCompanyEquityAssetsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarkToMarketCompanyEquityAssetsIndex'],
        ...apiQueryConfig.MarkToMarketCompanyEquityAssetsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarkToMarketCompanyEquityAssetsIndex',
  );
};


export type MarkToMarketIssuerEquityAssetsIndexMethod = MarkToMarketsApi['markToMarketIssuerEquityAssetsIndex'];
export type MarkToMarketIssuerEquityAssetsIndexResponseType = MethodResult<MarkToMarketIssuerEquityAssetsIndexMethod>;

export const useMarkToMarketIssuerEquityAssetsIndex = (
  variables: Parameters<MarkToMarketIssuerEquityAssetsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketIssuerEquityAssetsIndexMethod,
    MarkToMarketIssuerEquityAssetsIndexErrorTypes
  >
) => {
  return useQuery<
    MarkToMarketIssuerEquityAssetsIndexMethod,
    MarkToMarketIssuerEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketIssuerEquityAssetsIndex(...args),
    MarkToMarketIssuerEquityAssetsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketIssuerEquityAssetsIndex'],
        ...apiQueryConfig.MarkToMarketIssuerEquityAssetsIndex,
        ...config?.queryConfig
      },
    },
    'MarkToMarketIssuerEquityAssetsIndex',
  );
};

export const useMarkToMarketIssuerEquityAssetsIndexInfinite = (
  variables: Parameters<MarkToMarketIssuerEquityAssetsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarkToMarketIssuerEquityAssetsIndexMethod,
    MarkToMarketIssuerEquityAssetsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarkToMarketIssuerEquityAssetsIndexMethod,
    MarkToMarketIssuerEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketIssuerEquityAssetsIndex(...args),
    MarkToMarketIssuerEquityAssetsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketIssuerEquityAssetsIndex', 'MarkToMarketIssuerEquityAssetsIndexInfinite'],
        ...apiQueryConfig.MarkToMarketIssuerEquityAssetsIndex,
        ...config?.queryConfig,
      },
    },
    'MarkToMarketIssuerEquityAssetsIndex',
  );
};

export const useMarkToMarketIssuerEquityAssetsIndexLazy = (baseOptions?: {
  variables?: Parameters<MarkToMarketIssuerEquityAssetsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketIssuerEquityAssetsIndexMethod,
    MarkToMarketIssuerEquityAssetsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    MarkToMarketIssuerEquityAssetsIndexMethod,
    MarkToMarketIssuerEquityAssetsIndexErrorTypes
  >(
    (...args) => api.markToMarketIssuerEquityAssetsIndex(...args),
    MarkToMarketIssuerEquityAssetsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarkToMarketIssuerEquityAssetsIndex'],
        ...apiQueryConfig.MarkToMarketIssuerEquityAssetsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarkToMarketIssuerEquityAssetsIndex',
  );
};


export type MarkToMarketUpdateMethod = MarkToMarketsApi['markToMarketUpdate'];

export const useMarkToMarketUpdate = () => {
  return useMutation<MarkToMarketUpdateMethod>(
    (...args) => api.markToMarketUpdate(...args),
    MarkToMarketUpdateErrorCodes,
    'MarkToMarketUpdate',
    '/api/mark_to_markets/{mark_to_market_id}',
    'put'
  );
};

export type MarkToMarketGetPendingMethod = MarkToMarketsApi['markToMarketGetPending'];
export type MarkToMarketGetPendingResponseType = MethodResult<MarkToMarketGetPendingMethod>;

export const useMarkToMarketGetPending = (
  variables: Parameters<MarkToMarketGetPendingMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketGetPendingMethod,
    MarkToMarketGetPendingErrorTypes
  >
) => {
  return useQuery<
    MarkToMarketGetPendingMethod,
    MarkToMarketGetPendingErrorTypes
  >(
    (...args) => api.markToMarketGetPending(...args),
    MarkToMarketGetPendingErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketGetPending'],
        ...apiQueryConfig.MarkToMarketGetPending,
        ...config?.queryConfig
      },
    },
    'MarkToMarketGetPending',
  );
};

export const useMarkToMarketGetPendingInfinite = (
  variables: Parameters<MarkToMarketGetPendingMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarkToMarketGetPendingMethod,
    MarkToMarketGetPendingErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarkToMarketGetPendingMethod,
    MarkToMarketGetPendingErrorTypes
  >(
    (...args) => api.markToMarketGetPending(...args),
    MarkToMarketGetPendingErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketGetPending', 'MarkToMarketGetPendingInfinite'],
        ...apiQueryConfig.MarkToMarketGetPending,
        ...config?.queryConfig,
      },
    },
    'MarkToMarketGetPending',
  );
};

export const useMarkToMarketGetPendingLazy = (baseOptions?: {
  variables?: Parameters<MarkToMarketGetPendingMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketGetPendingMethod,
    MarkToMarketGetPendingErrorTypes
  >
}) => {
  return useLazyQuery<
    MarkToMarketGetPendingMethod,
    MarkToMarketGetPendingErrorTypes
  >(
    (...args) => api.markToMarketGetPending(...args),
    MarkToMarketGetPendingErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarkToMarketGetPending'],
        ...apiQueryConfig.MarkToMarketGetPending,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarkToMarketGetPending',
  );
};


export type MarkToMarketMovingAveragePricesMethod = MarkToMarketsApi['markToMarketMovingAveragePrices'];
export type MarkToMarketMovingAveragePricesResponseType = MethodResult<MarkToMarketMovingAveragePricesMethod>;

export const useMarkToMarketMovingAveragePrices = (
  variables: Parameters<MarkToMarketMovingAveragePricesMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketMovingAveragePricesMethod,
    MarkToMarketMovingAveragePricesErrorTypes
  >
) => {
  return useQuery<
    MarkToMarketMovingAveragePricesMethod,
    MarkToMarketMovingAveragePricesErrorTypes
  >(
    (...args) => api.markToMarketMovingAveragePrices(...args),
    MarkToMarketMovingAveragePricesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketMovingAveragePrices'],
        ...apiQueryConfig.MarkToMarketMovingAveragePrices,
        ...config?.queryConfig
      },
    },
    'MarkToMarketMovingAveragePrices',
  );
};

export const useMarkToMarketMovingAveragePricesInfinite = (
  variables: Parameters<MarkToMarketMovingAveragePricesMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarkToMarketMovingAveragePricesMethod,
    MarkToMarketMovingAveragePricesErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarkToMarketMovingAveragePricesMethod,
    MarkToMarketMovingAveragePricesErrorTypes
  >(
    (...args) => api.markToMarketMovingAveragePrices(...args),
    MarkToMarketMovingAveragePricesErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketMovingAveragePrices', 'MarkToMarketMovingAveragePricesInfinite'],
        ...apiQueryConfig.MarkToMarketMovingAveragePrices,
        ...config?.queryConfig,
      },
    },
    'MarkToMarketMovingAveragePrices',
  );
};

export const useMarkToMarketMovingAveragePricesLazy = (baseOptions?: {
  variables?: Parameters<MarkToMarketMovingAveragePricesMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketMovingAveragePricesMethod,
    MarkToMarketMovingAveragePricesErrorTypes
  >
}) => {
  return useLazyQuery<
    MarkToMarketMovingAveragePricesMethod,
    MarkToMarketMovingAveragePricesErrorTypes
  >(
    (...args) => api.markToMarketMovingAveragePrices(...args),
    MarkToMarketMovingAveragePricesErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarkToMarketMovingAveragePrices'],
        ...apiQueryConfig.MarkToMarketMovingAveragePrices,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarkToMarketMovingAveragePrices',
  );
};


export type MarkToMarketQuarterlyStatsIndexMethod = MarkToMarketsApi['markToMarketQuarterlyStatsIndex'];
export type MarkToMarketQuarterlyStatsIndexResponseType = MethodResult<MarkToMarketQuarterlyStatsIndexMethod>;

export const useMarkToMarketQuarterlyStatsIndex = (
  variables: Parameters<MarkToMarketQuarterlyStatsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketQuarterlyStatsIndexMethod,
    MarkToMarketQuarterlyStatsIndexErrorTypes
  >
) => {
  return useQuery<
    MarkToMarketQuarterlyStatsIndexMethod,
    MarkToMarketQuarterlyStatsIndexErrorTypes
  >(
    (...args) => api.markToMarketQuarterlyStatsIndex(...args),
    MarkToMarketQuarterlyStatsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketQuarterlyStatsIndex'],
        ...apiQueryConfig.MarkToMarketQuarterlyStatsIndex,
        ...config?.queryConfig
      },
    },
    'MarkToMarketQuarterlyStatsIndex',
  );
};

export const useMarkToMarketQuarterlyStatsIndexInfinite = (
  variables: Parameters<MarkToMarketQuarterlyStatsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarkToMarketQuarterlyStatsIndexMethod,
    MarkToMarketQuarterlyStatsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarkToMarketQuarterlyStatsIndexMethod,
    MarkToMarketQuarterlyStatsIndexErrorTypes
  >(
    (...args) => api.markToMarketQuarterlyStatsIndex(...args),
    MarkToMarketQuarterlyStatsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketQuarterlyStatsIndex', 'MarkToMarketQuarterlyStatsIndexInfinite'],
        ...apiQueryConfig.MarkToMarketQuarterlyStatsIndex,
        ...config?.queryConfig,
      },
    },
    'MarkToMarketQuarterlyStatsIndex',
  );
};

export const useMarkToMarketQuarterlyStatsIndexLazy = (baseOptions?: {
  variables?: Parameters<MarkToMarketQuarterlyStatsIndexMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketQuarterlyStatsIndexMethod,
    MarkToMarketQuarterlyStatsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    MarkToMarketQuarterlyStatsIndexMethod,
    MarkToMarketQuarterlyStatsIndexErrorTypes
  >(
    (...args) => api.markToMarketQuarterlyStatsIndex(...args),
    MarkToMarketQuarterlyStatsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarkToMarketQuarterlyStatsIndex'],
        ...apiQueryConfig.MarkToMarketQuarterlyStatsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarkToMarketQuarterlyStatsIndex',
  );
};


export type MarkToMarketQuarterlyStatsIndexByCompanyMethod = MarkToMarketsApi['markToMarketQuarterlyStatsIndexByCompany'];
export type MarkToMarketQuarterlyStatsIndexByCompanyResponseType = MethodResult<MarkToMarketQuarterlyStatsIndexByCompanyMethod>;

export const useMarkToMarketQuarterlyStatsIndexByCompany = (
  variables: Parameters<MarkToMarketQuarterlyStatsIndexByCompanyMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketQuarterlyStatsIndexByCompanyMethod,
    MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes
  >
) => {
  return useQuery<
    MarkToMarketQuarterlyStatsIndexByCompanyMethod,
    MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes
  >(
    (...args) => api.markToMarketQuarterlyStatsIndexByCompany(...args),
    MarkToMarketQuarterlyStatsIndexByCompanyErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketQuarterlyStatsIndexByCompany'],
        ...apiQueryConfig.MarkToMarketQuarterlyStatsIndexByCompany,
        ...config?.queryConfig
      },
    },
    'MarkToMarketQuarterlyStatsIndexByCompany',
  );
};

export const useMarkToMarketQuarterlyStatsIndexByCompanyInfinite = (
  variables: Parameters<MarkToMarketQuarterlyStatsIndexByCompanyMethod>[0],
  config?: UseInfiniteQueryConfig<
    MarkToMarketQuarterlyStatsIndexByCompanyMethod,
    MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes
  >
) => {
  return useInfiniteQuery<
    MarkToMarketQuarterlyStatsIndexByCompanyMethod,
    MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes
  >(
    (...args) => api.markToMarketQuarterlyStatsIndexByCompany(...args),
    MarkToMarketQuarterlyStatsIndexByCompanyErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['MarkToMarketQuarterlyStatsIndexByCompany', 'MarkToMarketQuarterlyStatsIndexByCompanyInfinite'],
        ...apiQueryConfig.MarkToMarketQuarterlyStatsIndexByCompany,
        ...config?.queryConfig,
      },
    },
    'MarkToMarketQuarterlyStatsIndexByCompany',
  );
};

export const useMarkToMarketQuarterlyStatsIndexByCompanyLazy = (baseOptions?: {
  variables?: Parameters<MarkToMarketQuarterlyStatsIndexByCompanyMethod>[0],
  config?: UseQueryConfig<
    MarkToMarketQuarterlyStatsIndexByCompanyMethod,
    MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes
  >
}) => {
  return useLazyQuery<
    MarkToMarketQuarterlyStatsIndexByCompanyMethod,
    MarkToMarketQuarterlyStatsIndexByCompanyErrorTypes
  >(
    (...args) => api.markToMarketQuarterlyStatsIndexByCompany(...args),
    MarkToMarketQuarterlyStatsIndexByCompanyErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['MarkToMarketQuarterlyStatsIndexByCompany'],
        ...apiQueryConfig.MarkToMarketQuarterlyStatsIndexByCompany,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'MarkToMarketQuarterlyStatsIndexByCompany',
  );
};

