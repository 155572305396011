// https://developer.chrome.com/blog/using-requestidlecallback#checking_for_requestidlecallback
export const requestIdleCallback =
  window.requestIdleCallback ??
  function (cb: (options: IdleDeadline) => void) {
    const start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };
