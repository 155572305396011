import React, { type ComponentProps, useCallback } from 'react';

import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import { downloadBlob } from '@npm/core/ui/utils/apiHelper';
import {
  CbWorkstationType,
  type FolderAggregate,
  useDocumentDestroy,
  useDocumentDownloadLazy,
  useDocumentUpdate,
  useFolderCreate,
  useFolderDestroy,
  useFolderUpdate,
} from '@npm/data-access';

import { usePermissions } from '../../auth/permissions/usePermissions';
import { useCurrentWorkstation } from '../../auth/user/role';
import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../documents/DocumentViewer';
import { DataRoom, useDataRoom } from '../DataRoom';
import {
  type AddFolderFormValues,
  type EditDocumentFormValues,
  type EditFolderFormValues,
} from '../DataRoom/components';

type DataRoomDetailProps = {
  room: FolderAggregate | undefined;
  canAccessDataRoomsList?: boolean;
  isLoading?: boolean;
} & Pick<
  ComponentProps<typeof DataRoom>,
  'onHomeClick' | 'isEditable' | 'useEditableProp'
>;

export const DataRoomDetail = ({
  room,
  onHomeClick,
  canAccessDataRoomsList,
  isEditable,
  useEditableProp,
  isLoading,
}: DataRoomDetailProps) => {
  const workstation = useCurrentWorkstation();
  const isIssuerWS = workstation?.type?.code === CbWorkstationType.items.issuer;
  const { ISS_DATAROOM_UPLOAD } = usePermissions();
  const actionsDisabled = isIssuerWS ? !ISS_DATAROOM_UPLOAD : false;

  const { withShowApiErrorMiddleware } = useAlerts();

  const [{ goToRoot }, { selectedFolder, ...restDataRoomProps }] = useDataRoom(
    room,
    canAccessDataRoomsList
  );

  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();

  const [executeDownload] = useDocumentDownloadLazy({
    config: {
      axiosConfig: { responseType: 'blob' },
    },
  });

  const downloadDocument = useCallback(
    async (id, fileName) => {
      try {
        const data = await executeDownload({ variables: { id } });
        downloadBlob(data, fileName);
      } catch (e) {
        console.error(e);
      }
    },
    [executeDownload]
  );

  // Reset to root if restricted to on room, otherwise reset to data rooms list
  const _onHomeClick = useCallback(
    () => (canAccessDataRoomsList ? onHomeClick() : goToRoot()),
    [canAccessDataRoomsList, onHomeClick, goToRoot]
  );

  const { execute: editDocument } = useDocumentUpdate();

  const handleDocumentEdit = async (
    id: number,
    values: EditDocumentFormValues
  ) => {
    await withShowApiErrorMiddleware(editDocument)({
      id,
      documentUpdateRequestContract: values,
    });
  };

  const { execute: deleteDocument } = useDocumentDestroy();

  const handleDocumentDelete = async (id: number) => {
    await withShowApiErrorMiddleware(deleteDocument)({ id });
  };

  const { execute: addFolder } = useFolderCreate();

  const handleFolderAdd = async ({ name }: AddFolderFormValues) => {
    await withShowApiErrorMiddleware(addFolder)({
      folderCreateRequestContract: {
        name,
        parent_id: selectedFolder?.id ?? room.id,
        owner_id: selectedFolder?.owner_id ?? room.owner_id,
        owner_type: selectedFolder?.owner_type.code ?? room.owner_type.code,
      },
    });
  };

  const { execute: editFolder } = useFolderUpdate();

  const handleFolderEdit = async (
    id: number,
    { name }: EditFolderFormValues
  ) => {
    await withShowApiErrorMiddleware(editFolder)({
      id,
      folderUpdateRequestContract: {
        name,
      },
    });
  };

  const { execute: deleteFolder } = useFolderDestroy();

  const handleFolderDelete = async (id: number) => {
    await withShowApiErrorMiddleware(deleteFolder)({ id });
  };

  return (
    <>
      <DocumentViewerModal {...documentViewerModalProps} />
      <DataRoom
        isLoading={isLoading}
        isEditable={isEditable}
        useEditableProp={useEditableProp}
        actionsDisabled={actionsDisabled}
        canAccessDataRoomsList={canAccessDataRoomsList}
        companyName={canAccessDataRoomsList && room?.issuer_entity?.name}
        companyLogoUrl={canAccessDataRoomsList && room?.issuer_entity?.logo_url}
        onHomeClick={_onHomeClick}
        onDownloadClick={downloadDocument}
        selectedFolder={selectedFolder || room}
        onDocumentEdit={handleDocumentEdit}
        onDocumentDelete={handleDocumentDelete}
        onDocumentClick={showNpmDocument}
        onFolderAdd={handleFolderAdd}
        onFolderEdit={handleFolderEdit}
        onFolderDelete={handleFolderDelete}
        {...restDataRoomProps}
      />
    </>
  );
};
