import React from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { Notification } from '@npm/core/ui/components/atoms/Notification';
import { ReadOnly } from '@npm/core/ui/components/molecules/ReadOnly';
import { Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';
import { type BuyerSurveyApiBuyerSurveyCreateRequest } from '@npm/data-access';

import {
  BuyerSurveyForm,
  useBuyerSurveyForm,
} from '../../../onboardingAndSubmissionSteps/BuyerSurvey';
import { type PostOnboardingContextType } from '../../PostOnboarding.types';
import { StepContainer } from '../../StepContainer';
import { useAutoScrollingFormProps } from '../steps.hooks';

const { StepState } = Wizard;

type Props = {
  stepProps: Wizard.StepProps;
  variant: 'individual' | 'entity';
};

export const BuyerSurveyPostOnboarding = ({ stepProps, variant }: Props) => {
  const { accountId, stepState } = useWizardStore(
    (s: WizardStore<PostOnboardingContextType>) => ({
      accountId: s.data.account?.id,
      stepState: s.activeStepState,
    })
  );
  const autoScrollingFormProps = useAutoScrollingFormProps();

  const [form] =
    Form.useForm<
      BuyerSurveyApiBuyerSurveyCreateRequest['accountsBuyerSurveyCreateRequestContract']
    >();

  const {
    onSubmit,
    isSubmitting,
    data,
    isLoading,
    getFormConfig,
    refetch,
    getReadOnlyData,
  } = useBuyerSurveyForm(form, {
    accountId,
    variant,
    onSubmitSuccess: () => {
      refetch().then(() => {
        stepProps.handleStepComplete();
        Notification.success({
          message: 'You have updated your Investor Buyer Survey',
        });
      });
    },
  });

  return (
    <StepContainer
      stepProps={stepProps}
      isLoading={isLoading}
      isUpdating={isSubmitting}
      handleSubmit={onSubmit}
    >
      {stepState === StepState.EDIT && (
        <BuyerSurveyForm
          form={form}
          initialData={data}
          formConfig={getFormConfig()}
          isLoading={isLoading}
          handleValuesChange={() => stepProps.setFormHasUnsavedChanges(true)}
          {...autoScrollingFormProps}
        />
      )}
      {stepState === StepState.FILLED && <ReadOnly items={getReadOnlyData()} />}
    </StepContainer>
  );
};
