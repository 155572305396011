import React from 'react';

import type { ExtendedColumnType } from '@npm/core/ui/components/molecules/Table';
import { DATE_FORMATS, formatDate } from '@npm/core/ui/utils/formatters';

import { CompanyOverviewPriceDisplay } from '../../../components/CompanyOverviewPriceDisplay';
import { type TapeDValue } from '../../TapeD.types';

export const TAPE_D_TABLE_COLUMNS: ExtendedColumnType<TapeDValue>[] = [
  {
    title: 'Pricing Date',
    key: 'date',
    render: ({ date }) => formatDate(date, { dateFormat: DATE_FORMATS.DATE }),
  },
  {
    title: 'Low Estimate',
    key: 'lowEstimate',
    render: ({
      low_estimate,
      low_implied_valuation,
      low_discount_or_premium,
    }) => {
      return (
        <CompanyOverviewPriceDisplay
          price={low_estimate}
          impliedValuation={low_implied_valuation}
          change={low_discount_or_premium}
        />
      );
    },
  },
  {
    title: 'Mid Estimate',
    key: 'midEstimate',
    render: ({
      mid_estimate,
      mid_implied_valuation,
      mid_discount_or_premium,
    }) => {
      return (
        <CompanyOverviewPriceDisplay
          price={mid_estimate}
          impliedValuation={mid_implied_valuation}
          change={mid_discount_or_premium}
        />
      );
    },
  },
  {
    title: 'High Estimate',
    key: 'highEstimate',
    render: ({
      high_estimate,
      high_implied_valuation,
      high_discount_or_premium,
    }) => {
      return (
        <CompanyOverviewPriceDisplay
          price={high_estimate}
          impliedValuation={high_implied_valuation}
          change={high_discount_or_premium}
        />
      );
    },
  },
];
