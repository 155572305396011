import { centerFlex, useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing.xxl / 2}px;
  flex-wrap: wrap;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  max-width: 30%;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xl}px;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
      width: calc(50% - ${theme.spacing.xl}px);
      max-width: calc(50% - ${theme.spacing.xl}px);
      margin-bottom: ${theme.spacing.sm}px;
  `}
`;

export const IconWrapper = styled.div`
  ${centerFlex}
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.xl}px;
  height: ${({ theme }) => theme.spacing.xl}px;
  border-radius: ${({ theme }) => theme.borderRadius.md}px;
  background-color: ${({ theme }) => theme.color.general.layout.two};
  margin-right: ${({ theme }) => theme.spacing.lg / 3}px;

  svg {
    color: ${({ theme }) => theme.color.general.typography.secondary};
  }

  ${({ theme }) => useBreakpoint(theme).mobile`
      width: ${theme.spacing.lg}px;
      height: ${theme.spacing.lg}px;
  `}
`;
