import React, { type ComponentProps } from 'react';

import { type Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';

import { BadgeCircle } from '../../styles';

import * as S from './styles';

type Props = ComponentProps<typeof Button> & {
  isOpened?: boolean;
  count?: number;
  showOnlyIconOnMobile?: boolean;
};

export const FiltersToggleButton = ({
  isOpened,
  count,
  showOnlyIconOnMobile = true,
  ...rest
}: Props) => {
  const { isMobile } = useBreakpoints();

  const badge =
    count && !isOpened ? (
      <BadgeCircle>
        {' '}
        <Text size={isMobile ? 'sm' : 'xs'} weight="bold">
          {count}
        </Text>
      </BadgeCircle>
    ) : null;

  return (
    <S.FilterToggleButton
      variant="outline"
      icon={!badge && <Icon name={isOpened ? 'x-close' : 'filter'} />}
      $isOpened={isOpened}
      size="lg"
      {...rest}
    >
      {badge}
      {(!isMobile || !showOnlyIconOnMobile) && (isOpened ? 'Hide' : 'Filters')}
    </S.FilterToggleButton>
  );
};
