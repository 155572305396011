import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { type Holding } from '@npm/data-access';

import {
  getDisabledDeleteActionTooltip,
  getNoRemainingQuantityTooltip,
} from '../../../Holdings.utils';
import { type AggregatedHoldingActions } from '../AggregatedHoldingsList.types';

type Props = {
  holding: Holding;
} & Pick<
  AggregatedHoldingActions,
  'onSellHoldingClick' | 'onDeleteHoldingClick' | 'onEditHoldingClick'
>;

export const AggregatedHoldingActionButtons = ({
  holding,
  onSellHoldingClick,
  onEditHoldingClick,
  onDeleteHoldingClick,
}: Props) => {
  const hasNoQuantityLeft = holding?.remaining_quantity <= 0;

  return (
    <Flex justify="flex-end" gap="sm" align="center">
      <Tooltip title={getNoRemainingQuantityTooltip(holding)}>
        <Button
          color="error"
          onClick={() => onSellHoldingClick(holding)}
          disabled={hasNoQuantityLeft}
        >
          Sell
        </Button>
      </Tooltip>
      <Dropdown
        items={[
          {
            label: 'Edit',
            icon: <Icon name="edit" />,
            onClick: () => onEditHoldingClick(holding),
          },
          {
            label: 'Delete',
            icon: <Icon name="trash" color="error" />,
            disabled: !holding.deletable,
            onClick: () => onDeleteHoldingClick(holding),
            tooltip: getDisabledDeleteActionTooltip(holding),
          },
        ]}
      >
        <Button variant="text" size="md" icon={<Icon name="dots-vertical" />} />
      </Dropdown>
    </Flex>
  );
};
