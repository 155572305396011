import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type Wizard } from '@npm/core/ui/components/organisms/WizardNew';
import { CbBackgroundCheckState } from '@npm/data-access';

import { ZendeskContactButton } from '../../../app/zendesk';
import { GetStartedCard } from '../../../onboardingAndSubmissionSteps/components/GetStartedCard';
import { StepContainer } from '../../StepContainer';

const ZENDESK_TICKET_GROUP_ID = 'onboarding_individual_identity_verification';

type Props = {
  stepProps: Wizard.StepProps;
  variant:
    | typeof CbBackgroundCheckState.items.failed
    | typeof CbBackgroundCheckState.items.marked_for_review;
};

export const PersonaVerificationFailed = ({ stepProps, variant }: Props) => {
  return (
    <StepContainer stepProps={stepProps} isFullWidth hideStepHeading>
      <GetStartedCard
        title={
          variant === CbBackgroundCheckState.items.failed
            ? 'We failed to verify your identity'
            : 'We are reviewing your identity verification'
        }
        description={
          <Text size="sm" colorVariant="secondary">
            If you have questions or concerns, email{' '}
            <Text size="sm" href="mailto:support@npm.com">
              support@npm.com
            </Text>{' '}
            or{' '}
            <ZendeskContactButton
              zendeskTicketGroupId={ZENDESK_TICKET_GROUP_ID}
            >
              click here
            </ZendeskContactButton>
            .
          </Text>
        }
        iconColor={
          variant === CbBackgroundCheckState.items.failed ? 'error' : 'warning'
        }
        iconName={
          variant === CbBackgroundCheckState.items.failed
            ? 'circle-x'
            : 'warning'
        }
      />
    </StepContainer>
  );
};
