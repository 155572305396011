import React, { type ComponentProps } from 'react';

import { Wizard } from '@npm/core/ui/components/organisms/Wizard';

import * as S from './PostOnboarding.styles';
import { type PostOnboardingContext } from './PostOnboarding.types';
import { findPostOnboardingNextIncompleteStep } from './PostOnboarding.utils';
import { Sidebar, type SidebarConfig } from './Sidebar';

type Props = {
  context: Wizard.WizardContext<PostOnboardingContext>;
  updateContext: React.Dispatch<Wizard.ContextAction<PostOnboardingContext>>;
  sidebarProps: {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarConfig: SidebarConfig;
  };
  handleCompleteWizard?: ComponentProps<typeof Wizard.Wizard>['onComplete'];
  headerContent?: React.ReactNode;
  expandablePanel?: React.ReactNode;
  resetWizardStyles?: boolean;
};

// legacy onboarding wizard
// still used in brokerage workstation - account detail onboarding tab + account onboarding in full screen mode
export const PostOnboarding = ({
  context,
  updateContext,
  headerContent,
  handleCompleteWizard,
  sidebarProps,
  expandablePanel,
  resetWizardStyles = false,
}: Props) => {
  const { sidebarOpen, setSidebarOpen, sidebarConfig } = sidebarProps;
  const { goToStep } = Wizard.useGoToStepInWizard({
    context,
    updateContext,
  });

  const handleCompleteStep = () => {
    const nextIncompleteStep = findPostOnboardingNextIncompleteStep(context);
    goToStep(nextIncompleteStep);
  };

  const handleStepperItemClick = (
    key: string,
    isParentItemClicked: boolean
  ) => {
    goToStep(key, null, 'replaceIn');
    if (!isParentItemClicked) {
      setSidebarOpen(false);
    }
  };

  return (
    <>
      {resetWizardStyles && <S.ResetWizardStyles />}
      <Wizard.Wizard<PostOnboardingContext>
        context={context}
        steps={context?.steps}
        updateContext={updateContext}
        wizardLayoutProps={{
          sidebarOpen,
          toggleSidebarOpen: () => setSidebarOpen(!sidebarOpen),
          sidebarContent: (
            <Sidebar
              sidebarConfig={sidebarConfig}
              onboardingStatusData={context?.data?.onboardingStatus}
              handleItemClick={handleStepperItemClick}
              activeStepKey={context.steps[context.stepIndex || 0].key}
            />
          ),
          headerContent,
        }}
        onCompleteStep={handleCompleteStep}
        onComplete={handleCompleteWizard}
        showProgressBar={false}
        expandablePanel={expandablePanel}
      />
    </>
  );
};
