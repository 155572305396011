import React from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import {
  DEFAULT_SUPPORTED_CONTENT_TYPES,
  DEFAULT_SUPPORTED_FILE_EXTENSIONS,
} from '@npm/core/ui/components/atoms/FileUpload/FileUpload.constants';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Progress } from '@npm/core/ui/components/atoms/Progress';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import {
  AlertContainer,
  useAlerts,
} from '@npm/core/ui/components/molecules/AlertContainer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { getFilenameExtension } from '@npm/core/ui/utils/file';
import { Upload } from 'antd';
import { type UploadFile } from 'antd/lib/upload/interface';

import * as S from './UploadDragger.styles';

type Props = {
  fileList: UploadFile[];
  onUpload: (file: UploadFile) => void;
  isUploading?: boolean;
  title?: string;
  progress?: number;
  multiple?: boolean;
  accept?: string[] | null;
  acceptLabel?: string | null;
  children?: React.ReactNode;
};

export const SUPPORTED_CONTENT_TYPES = [
  ...DEFAULT_SUPPORTED_CONTENT_TYPES,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const SUPPORTED_FILE_EXTENSIONS = [
  ...DEFAULT_SUPPORTED_FILE_EXTENSIONS,
  '.xlsx',
];

export const DEFAULT_ACCEPT = [
  ...SUPPORTED_CONTENT_TYPES,
  ...SUPPORTED_FILE_EXTENSIONS,
];

export const UploadDragger = ({
  title,
  progress,
  fileList,
  isUploading,
  onUpload,
  multiple = true,
  accept = DEFAULT_ACCEPT,
  acceptLabel = 'The file must be a PDF, JPG, XLSX or PNG.',
  children,
}: Props) => {
  const { isMobile } = useBreakpoints();

  const { showAlert, clearAlerts } = useAlerts();

  return (
    <>
      <S.Container>
        <Upload.Dragger
          multiple={multiple}
          beforeUpload={file => {
            clearAlerts();
            const extension = getFilenameExtension(file.name);
            if (!extension) {
              showAlert(`You cannot upload a file with no extension`);
            } else if (
              !!accept &&
              !accept.some(
                acceptableType =>
                  acceptableType === file.type || acceptableType === extension
              )
            ) {
              showAlert(`${file.name}: ${acceptLabel ?? 'Not supported'}`);
            } else {
              onUpload(file);
            }
            return false;
          }}
          showUploadList={false}
          accept={accept?.join(',')}
          fileList={fileList}
          data-cy={CypressDataIds.Components.UploadForm.Dragger}
        >
          {children || (
            <S.UploadInner>
              {progress ? (
                <div>
                  <Progress type="circle" percent={progress * 100} />
                  <Text size="sm">Uploading...</Text>
                </div>
              ) : (
                <>
                  {' '}
                  <Button
                    icon={<Icon name="upload" />}
                    stopPropagation={false}
                  />
                  <Heading variant="h3" marginBottom="xs">
                    {title ??
                      (isMobile
                        ? 'Upload your files'
                        : 'Drag and drop your files')}
                  </Heading>
                  {!isMobile && (
                    <Text size="sm" color="info" marginBottom="md">
                      or select a file
                    </Text>
                  )}
                  {acceptLabel && <Text size="xs">{acceptLabel}</Text>}
                </>
              )}
            </S.UploadInner>
          )}
        </Upload.Dragger>
      </S.Container>
      <AlertContainer />
    </>
  );
};
