import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Label } from '@npm/core/ui/components/atoms/Label';
import { CbOrderBuySell, type SecondmarketOrderItem } from '@npm/data-access';

import { type OpenRenewOrderModal } from '../../../../../client-orders/expiringOrders/RenewOrderModal/RenewOrderModal.types';
import {
  OrderStatusLabel,
  RequestMoreInformationButton,
} from '../../../../../components';

export const getLabels = (order: SecondmarketOrderItem, accountId: number) => {
  const isBuy = order?.order_side?.code === CbOrderBuySell.items.buy;
  const isMyOrder = order?.account?.id === accountId;
  const isNegotiating = order?.negotiations_total > 0;

  return (
    <Flex justify="space-between">
      <Flex gap="sm">
        <Label variant={isBuy ? 'success' : 'error'} round>
          {isBuy
            ? isMyOrder
              ? 'You are Buying'
              : 'Bid'
            : isMyOrder
              ? 'You are Selling'
              : 'Offer'}
        </Label>
        {isNegotiating && (
          <Label variant={'info'} round>
            You are Negotiating
          </Label>
        )}
      </Flex>
      <OrderStatusLabel state={order?.state} variant="individual" />
    </Flex>
  );
};

type OrderCardGetButtonVariantOptions = {
  order: SecondmarketOrderItem;
  accountId: number;
  isSponsorshipMinLevel2: boolean;
  isIOI: boolean;
  isBuy: boolean;
  onOrderClick: () => void;
  onRenewClick?: OpenRenewOrderModal;
  canRenewOrder: boolean;
};

export const getButtonVariant = ({
  order,
  accountId,
  isSponsorshipMinLevel2,
  isIOI,
  isBuy,
  onOrderClick,
  onRenewClick,
  canRenewOrder,
}: OrderCardGetButtonVariantOptions) => {
  const isMySellOrder = order?.account?.id === accountId && !isBuy;
  const isMyBuyOrder = order?.account?.id === accountId && isBuy;
  const isNegotiating = order?.negotiations_total > 0;

  if (onRenewClick && canRenewOrder) {
    return (
      <Button
        size="sm"
        color="info"
        icon={<Icon name="refresh" />}
        onClick={() =>
          onRenewClick(order, isBuy, !isMySellOrder && !isSponsorshipMinLevel2)
        }
      >
        Renew {isBuy ? 'Bid' : 'Offer'}
      </Button>
    );
  }

  if (!isSponsorshipMinLevel2 && !isMySellOrder) {
    return <RequestMoreInformationButton orderItem={order} size="sm" />;
  }

  if (isIOI && isBuy) {
    return (
      <RequestMoreInformationButton
        title="Connect with NPM"
        orderItem={order}
        size="sm"
      />
    );
  }

  if (isNegotiating) {
    return (
      <Button
        onClick={onOrderClick}
        size="sm"
        color="info"
        icon={<Icon name="arrows-opposite" />}
      >
        Active Opportunity ({order?.negotiations_total})
      </Button>
    );
  }

  const buttonVariant = isBuy
    ? isMyBuyOrder
      ? 'outline'
      : 'default'
    : isMySellOrder
      ? 'outline'
      : 'text';

  const buttonText = isBuy
    ? isMyBuyOrder
      ? 'Review My Bid'
      : 'Sell Your Shares'
    : isMySellOrder
      ? 'Review My Offer'
      : 'Review Offer';

  const buttonIcon = isBuy ? (isMyBuyOrder ? 'edit' : 'chevron-right') : 'edit';
  const buttonIconPosition = isBuy ? (isMyBuyOrder ? 'left' : 'right') : 'left';

  return (
    <Button
      size="sm"
      variant={buttonVariant}
      color={isBuy ? 'error' : 'info'}
      onClick={onOrderClick}
      iconPosition={buttonIconPosition}
      {...((isBuy || isMySellOrder) && {
        icon: <Icon name={buttonIcon} size="sm" />,
      })}
    >
      {buttonText}
    </Button>
  );
};
