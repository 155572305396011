// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FilingGroupsApi,
  FilingGroupsIndexErrorCodes,
  FilingGroupsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FilingGroupsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FilingGroupsIndexMethod = FilingGroupsApi['filingGroupsIndex'];
export type FilingGroupsIndexResponseType = MethodResult<FilingGroupsIndexMethod>;

export const useFilingGroupsIndex = (
  variables: Parameters<FilingGroupsIndexMethod>[0],
  config?: UseQueryConfig<
    FilingGroupsIndexMethod,
    FilingGroupsIndexErrorTypes
  >
) => {
  return useQuery<
    FilingGroupsIndexMethod,
    FilingGroupsIndexErrorTypes
  >(
    (...args) => api.filingGroupsIndex(...args),
    FilingGroupsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingGroupsIndex'],
        ...apiQueryConfig.FilingGroupsIndex,
        ...config?.queryConfig
      },
    },
    'FilingGroupsIndex',
  );
};

export const useFilingGroupsIndexInfinite = (
  variables: Parameters<FilingGroupsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    FilingGroupsIndexMethod,
    FilingGroupsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    FilingGroupsIndexMethod,
    FilingGroupsIndexErrorTypes
  >(
    (...args) => api.filingGroupsIndex(...args),
    FilingGroupsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FilingGroupsIndex', 'FilingGroupsIndexInfinite'],
        ...apiQueryConfig.FilingGroupsIndex,
        ...config?.queryConfig,
      },
    },
    'FilingGroupsIndex',
  );
};

export const useFilingGroupsIndexLazy = (baseOptions?: {
  variables?: Parameters<FilingGroupsIndexMethod>[0],
  config?: UseQueryConfig<
    FilingGroupsIndexMethod,
    FilingGroupsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    FilingGroupsIndexMethod,
    FilingGroupsIndexErrorTypes
  >(
    (...args) => api.filingGroupsIndex(...args),
    FilingGroupsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FilingGroupsIndex'],
        ...apiQueryConfig.FilingGroupsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FilingGroupsIndex',
  );
};

