/* eslint-disable no-restricted-imports */
import {
  type NavigateFunction,
  type NavigateOptions,
  type To,
  useLocation as useReactRouterLocation,
} from 'react-router-dom';
import {
  createPath,
  useNavigate as useReactRouterNavigate,
} from 'react-router-dom';

import { useCurrentRoute } from '../../../hooks/useCurrentRoute';
import { useHelmetStore } from '../../atoms/Helmet';

import {
  getLocationDescriptorObject,
  getNewStateWithPageHistory,
} from './Link.utils';

// We wrap React Router navigation to preserve some query params
export const useNavigate = () => {
  const reactRouterNavigate = useReactRouterNavigate();
  const location = useReactRouterLocation();
  const currentRoute = useCurrentRoute();
  const pageTitle = useHelmetStore(x => x.pageTitle);

  const navigate: NavigateFunction = (
    to: To | number,
    options: NavigateOptions = {}
  ) => {
    if (typeof to === 'number') {
      reactRouterNavigate(to);
      return;
    }

    const locationDescriptor = getLocationDescriptorObject(to, options?.state);
    if (
      !options.replace &&
      createPath(location) === createPath(locationDescriptor)
    ) {
      return;
    }
    reactRouterNavigate(locationDescriptor, {
      ...options,
      state: options.replace
        ? location?.state
        : getNewStateWithPageHistory(
            locationDescriptor,
            pageTitle,
            {
              ...location,
              search: window.location.search,
            },
            currentRoute
          ),
    });
  };

  return navigate;
};

export type Navigator = ReturnType<typeof useNavigate>;
