import { type ApiError } from '@npm/core/ui/services/errors/defaultErrorHandler';
import {
  type DocumentCreateErrorTypes,
  getApiErrorCode,
} from '@npm/data-access';
import { type FormInstance } from 'antd/lib/form';
import { type UploadFile } from 'antd/lib/upload';
import { type AxiosProgressEvent } from 'axios';

export const handleUploadError = (
  file: UploadFile<unknown>,
  error: DocumentCreateErrorTypes,
  form: FormInstance<unknown>,
  updateProgress: (
    fileUid?: string,
    progressEvent?: AxiosProgressEvent
  ) => void,
  showApiError: (error: ApiError) => void
) => {
  //note: this updateProgress affects totalProgress, to improve that, don't use progress as indicator of file being uploaded
  updateProgress(file.uid, null);
  if (error?.data?.error_code === 'validation_error') {
    const { error_fields } = error.data;
    // hard-coding "display_name", so if "md5" comes with "already uploaded", it is shown under input
    const field_name = 'display_name';
    const field_message =
      error_fields?.[0].field_error_messages ?? error.data?.error_message;
    form.setFields([{ name: [file.uid, field_name], errors: [field_message] }]);
  } else if (getApiErrorCode(error) === 413) {
    form.setFields([
      {
        name: [file.uid, 'display_name'],
        errors: ['File exceeds the size limit'],
      },
    ]);
  } else {
    showApiError(error as unknown);
  }
  throw error;
};
