/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Cap-Table API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPartnerOcxUploadsOcxImportErrorIndex } from '../model';
// @ts-ignore
import { AdminPartnerOcxUploadsOcxImportErrorStats } from '../model';
/**
 * OcxUploadsApi - axios parameter creator
 * @export
 */
export const OcxUploadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary provides statistics about OCX import errors for an OCX file
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcxUploadImportErrorStats: async (issuerId: string, uploadId: number, xWorkstation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('getOcxUploadImportErrorStats', 'issuerId', issuerId)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('getOcxUploadImportErrorStats', 'uploadId', uploadId)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/ocx_uploads/{upload_id}/ocx_import_error_stats`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"upload_id"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary provides a list of errors that occurred when attempting to import data from a partner provided OCX file
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
         * @param {number} page 
         * @param {number} size Should be no greater than 100.
         * @param {string} [xWorkstation] 
         * @param {string} [sheetName] Empty values are treated as null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcxUploadImportErrors: async (issuerId: string, uploadId: number, page: number, size: number, xWorkstation?: string, sheetName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'issuerId' is not null or undefined
            assertParamExists('getOcxUploadImportErrors', 'issuerId', issuerId)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('getOcxUploadImportErrors', 'uploadId', uploadId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getOcxUploadImportErrors', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getOcxUploadImportErrors', 'size', size)
            const localVarPath = changeUrl(`/api/admin/partner/issuers/{issuer_id}/ocx_uploads/{upload_id}/ocx_import_errors`)
                .replace(`{${"issuer_id"}}`, encodeURIComponent(String(issuerId)))
                .replace(`{${"upload_id"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sheetName !== undefined) {
                localVarQueryParameter['sheet_name'] = sheetName;
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OcxUploadsApi - functional programming interface
 * @export
 */
export const OcxUploadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OcxUploadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary provides statistics about OCX import errors for an OCX file
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOcxUploadImportErrorStats(issuerId: string, uploadId: number, xWorkstation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerOcxUploadsOcxImportErrorStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOcxUploadImportErrorStats(issuerId, uploadId, xWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary provides a list of errors that occurred when attempting to import data from a partner provided OCX file
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
         * @param {number} page 
         * @param {number} size Should be no greater than 100.
         * @param {string} [xWorkstation] 
         * @param {string} [sheetName] Empty values are treated as null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOcxUploadImportErrors(issuerId: string, uploadId: number, page: number, size: number, xWorkstation?: string, sheetName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPartnerOcxUploadsOcxImportErrorIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOcxUploadImportErrors(issuerId, uploadId, page, size, xWorkstation, sheetName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OcxUploadsApi - factory interface
 * @export
 */
export const OcxUploadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OcxUploadsApiFp(configuration)
    return {
        /**
         * 
         * @summary provides statistics about OCX import errors for an OCX file
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
         * @param {string} [xWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcxUploadImportErrorStats(issuerId: string, uploadId: number, xWorkstation?: string, options?: any): AxiosPromise<AdminPartnerOcxUploadsOcxImportErrorStats> {
            return localVarFp.getOcxUploadImportErrorStats(issuerId, uploadId, xWorkstation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary provides a list of errors that occurred when attempting to import data from a partner provided OCX file
         * @param {string} issuerId An NPM GUID of an issuer.
         * @param {number} uploadId An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
         * @param {number} page 
         * @param {number} size Should be no greater than 100.
         * @param {string} [xWorkstation] 
         * @param {string} [sheetName] Empty values are treated as null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcxUploadImportErrors(issuerId: string, uploadId: number, page: number, size: number, xWorkstation?: string, sheetName?: string, options?: any): AxiosPromise<AdminPartnerOcxUploadsOcxImportErrorIndex> {
            return localVarFp.getOcxUploadImportErrors(issuerId, uploadId, page, size, xWorkstation, sheetName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getOcxUploadImportErrorStats operation in OcxUploadsApi.
 * @export
 * @interface OcxUploadsApiGetOcxUploadImportErrorStatsRequest
 */
export interface OcxUploadsApiGetOcxUploadImportErrorStatsRequest {
    /**
     * An NPM GUID of an issuer.
     * @type {string}
     * @memberof OcxUploadsApiGetOcxUploadImportErrorStats
     */
    readonly issuerId: string

    /**
     * An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
     * @type {number}
     * @memberof OcxUploadsApiGetOcxUploadImportErrorStats
     */
    readonly uploadId: number

    /**
     * 
     * @type {string}
     * @memberof OcxUploadsApiGetOcxUploadImportErrorStats
     */
    readonly xWorkstation?: string
}

/**
 * Request parameters for getOcxUploadImportErrors operation in OcxUploadsApi.
 * @export
 * @interface OcxUploadsApiGetOcxUploadImportErrorsRequest
 */
export interface OcxUploadsApiGetOcxUploadImportErrorsRequest {
    /**
     * An NPM GUID of an issuer.
     * @type {string}
     * @memberof OcxUploadsApiGetOcxUploadImportErrors
     */
    readonly issuerId: string

    /**
     * An auto-incremented id of an OCX upload. This is the cap table partner upload id of an upload whose type is an OCX upload.
     * @type {number}
     * @memberof OcxUploadsApiGetOcxUploadImportErrors
     */
    readonly uploadId: number

    /**
     * 
     * @type {number}
     * @memberof OcxUploadsApiGetOcxUploadImportErrors
     */
    readonly page: number

    /**
     * Should be no greater than 100.
     * @type {number}
     * @memberof OcxUploadsApiGetOcxUploadImportErrors
     */
    readonly size: number

    /**
     * 
     * @type {string}
     * @memberof OcxUploadsApiGetOcxUploadImportErrors
     */
    readonly xWorkstation?: string

    /**
     * Empty values are treated as null.
     * @type {string}
     * @memberof OcxUploadsApiGetOcxUploadImportErrors
     */
    readonly sheetName?: string
}

/**
 * OcxUploadsApi - object-oriented interface
 * @export
 * @class OcxUploadsApi
 * @extends {BaseAPI}
 */
export class OcxUploadsApi extends BaseAPI {
    /**
     * 
     * @summary provides statistics about OCX import errors for an OCX file
     * @param {OcxUploadsApiGetOcxUploadImportErrorStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcxUploadsApi
     */
    public getOcxUploadImportErrorStats(requestParameters: OcxUploadsApiGetOcxUploadImportErrorStatsRequest, options?: AxiosRequestConfig) {
        return OcxUploadsApiFp(this.configuration).getOcxUploadImportErrorStats(requestParameters.issuerId, requestParameters.uploadId, requestParameters.xWorkstation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary provides a list of errors that occurred when attempting to import data from a partner provided OCX file
     * @param {OcxUploadsApiGetOcxUploadImportErrorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcxUploadsApi
     */
    public getOcxUploadImportErrors(requestParameters: OcxUploadsApiGetOcxUploadImportErrorsRequest, options?: AxiosRequestConfig) {
        return OcxUploadsApiFp(this.configuration).getOcxUploadImportErrors(requestParameters.issuerId, requestParameters.uploadId, requestParameters.page, requestParameters.size, requestParameters.xWorkstation, requestParameters.sheetName, options).then((request) => request(this.axios, this.basePath));
    }
}

export type GetOcxUploadImportErrorStatsErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetOcxUploadImportErrorStatsErrorCodes = [
];

export type GetOcxUploadImportErrorsErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetOcxUploadImportErrorsErrorCodes = [
    422,
];


