import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useTheme } from 'styled-components';

import * as S from './StepperItems.styles';

export type Item = {
  stepNumber: number;
  title: string;
  description: string;
  isCompleted?: boolean;
  isCurrentStep?: boolean;
};

export type Items = {
  items: Item[];
  variant: 'horizontal' | 'vertical';
};

export const StepperItems = ({ items, variant }: Items) => {
  const { isMobile } = useBreakpoints();
  const theme = useTheme();

  return (
    <S.StepContainer $variant={variant}>
      {items.map(
        ({ stepNumber, title, description, isCompleted, isCurrentStep }) => (
          <S.Step
            key={stepNumber}
            $variant={variant}
            $isCurrentStep={isCurrentStep}
            $isCompleted={isCompleted}
          >
            <S.IconWrap $isCompleted={isCompleted}>
              {isCompleted ? (
                <Icon
                  name="check"
                  size={isMobile ? 'xxs' : 'xs'}
                  color={theme.color.grey_0}
                />
              ) : (
                stepNumber
              )}
            </S.IconWrap>
            <Flex direction="column" gap={isMobile ? 'xs' : 'sm'}>
              <Text size="xs" color="info" weight="bold">
                {title}
              </Text>
              <Text
                size="sm"
                colorVariant={isCompleted ? 'tertiary' : 'primary'}
              >
                {description}
              </Text>
            </Flex>
          </S.Step>
        )
      )}
    </S.StepContainer>
  );
};
