import { Text } from '@npm/core/ui/components/atoms/Typography';

import { normalizeSize, type OrderSizeType } from '../../../../../../order';
import type { SellFormValues } from '../SellIndicationDrawer.types';

type Props = {
  values: SellFormValues;
  orderSizeType: OrderSizeType;
  estimatedGrossProceeds: number;
  exerciseCosts: number;
};

export const useReviewSectionConfig = ({
  values,
  orderSizeType,
  estimatedGrossProceeds,
  exerciseCosts,
}: Props) => {
  const normalizedSize = normalizeSize({
    ...values,
    sizeType: orderSizeType,
  });

  return [
    {
      title: 'Total Size (Shares)',
      key: 'sizeShares',
      render: () => (
        <Text.Quantity
          value={normalizedSize.Shares}
          formatOptions={{ maximumFractionDigits: 0 }}
        />
      ),
    },
    {
      title: 'Estimated Gross Proceeds',
      key: 'estimatedGrossProceeds',
      render: () => <Text.Price value={estimatedGrossProceeds} />,
    },
    {
      title: 'Execution Fee',
      key: 'executionFee',
      render: () => <Text.Quantity value={1} unit="%" />,
    },
    {
      title: 'Exercise Cost',
      key: 'exerciseCost',
      render: () => <Text.Price value={exerciseCosts} />,
      hidden: !exerciseCosts,
    },
  ];
};
