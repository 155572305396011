import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import { type DocumentIDCategory } from '@npm/data-access';
import { addDays, startOfToday } from 'date-fns';

export const FORM_FIELDS_CONFIG = {
  numberLicense: {
    name: 'number',
    rules: [VALIDATION_RULES.required('License Number')],
    label: 'License Number',
  },
  numberPassport: {
    name: 'number',
    rules: [VALIDATION_RULES.required('Passport Number')],
    label: 'Passport Number',
  },
  nationality: {
    name: 'nationality',
    rules: [VALIDATION_RULES.required('Nationality')],
    label: 'Nationality',
  },
  issuingCountry: {
    name: 'issuingCountry',
    rules: [VALIDATION_RULES.required('Issuing Country')],
    label: 'Issuing Country',
  },
  state: {
    name: 'state',
    rules: [VALIDATION_RULES.required('State')],
    label: 'State',
  },
  category: {
    name: 'category',
    rules: [VALIDATION_RULES.required('Photo ID Type')],
    label: 'Photo ID Type',
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    rules: [VALIDATION_RULES.required('Date of Birth')],
    label: 'Date of Birth',
    disabledDate: (date: Date) =>
      date.getTime() > addDays(Date.now(), -1).getTime(),
  },
  expirationDate: {
    name: 'expirationDate',
    rules: [VALIDATION_RULES.required('Expiration Date')],
    label: 'Expiration Date',
    disabledDate: (date: Date) =>
      date.getTime() < addDays(startOfToday(), 1).getTime(),
  },
  file: {
    name: 'file',
    rules: [
      {
        ...VALIDATION_RULES.required('Uploading a file'),
        validateTrigger: ['onSubmit'],
      },
    ],
    label: '',
  },
};

export const ID_IMAGE_SRC: Record<DocumentIDCategory, string> = {
  drivers_license: '/public/assets/drivers-license.png',
  passport: '/public/assets/passport.png',
  other: '/public/assets/other-id.png',
};
