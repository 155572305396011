import {
  Button,
  BUTTON_ACTIVE_SHADOW,
} from '@npm/core/ui/components/atoms/Button';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled, { css, type DefaultTheme } from 'styled-components';

export const borderWithShadowCSS = css`
  box-shadow:
    0 0 0 2px ${({ theme }) => theme.color.info.borders.primary},
    ${BUTTON_ACTIVE_SHADOW};
`;

export const FilterToggleButton = styled(Button)<{ $isOpened?: boolean }>`
  ${({ theme, $isOpened }) => css`
    && {
      align-self: end;
      padding: 0 ${theme.spacing.sm}px;

      ${$isOpened && borderWithShadowCSS}

      ${useBreakpoint(theme).mobile`${css`
        height: 48px; // exception
        min-width: 48px;
      `}`}
    }
  `}
`;

// special case for filters, add to DS and refactor Label if to be used elsewhere
export const FilterChip = styled.span<{
  $isClear?: boolean;
  $bgColor: keyof DefaultTheme['color']['general']['layout'];
}>`
  ${({ theme, $isClear, $bgColor }) => css`
    &&& > .ant-tag {
      text-decoration: none;
      border: none;
      background-color: ${theme.color.general.layout[$bgColor]};
      padding: ${theme.spacing.xs}px ${theme.spacing.lg / 2}px;
      ${$isClear &&
      css`
        cursor: pointer;

        svg {
          margin-left: ${theme.spacing.xs}px;
          margin-right: -4px; // fine-tune paddings with icon
        }
      `}
    }
  `}
`;

export const ResultsLabelContainer = styled.div`
  &&& > .ant-tag {
    text-decoration: none;
    border: none;
    background-color: ${({ theme }) => theme.color.general.layout.two};
    padding: ${({ theme }) =>
      `${theme.spacing.xs}px ${theme.spacing.lg / 2}px`};
  }
`;
