import { EMPTY_VALUE } from '../../components/atoms/Typography';

export const formatPercentage = (
  part?: number | null,
  total?: number | null
) => {
  if (part == null || total == null || total === 0) {
    return EMPTY_VALUE;
  }
  const percentage = Math.round((part / total) * 100);
  return `${percentage}%`;
};
