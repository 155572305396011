import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useTheme } from 'styled-components';

export const LogoSkeleton = () => {
  const theme = useTheme();
  const { isMobile } = useBreakpoints();

  return (
    <Flex align="center" gap="md">
      <Skeleton.Avatar shape="square" size={isMobile ? 24 : 32} />
      <Skeleton.Base
        noMargin
        title={{
          width: 140,
          style: {
            height: isMobile
              ? theme.spacing.md + 'px'
              : theme.spacing.xxl / 2 + 'px',
            marginBottom: 0,
          },
        }}
        paragraph={false}
      />
    </Flex>
  );
};
