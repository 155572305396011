import { Flex } from '@npm/core/ui/components/atoms/common';
import { Divider as DividerBase } from '@npm/core/ui/components/atoms/Divider';
import { RadioGroup as RadioGroupBase } from '@npm/core/ui/components/atoms/Radio';
import { Tabs } from '@npm/core/ui/components/atoms/Tabs';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.color.general.borders.primary};
  min-height: 38px;
`;

export const RangeTabsWrapper = styled.div`
  flex: 1;
`;

export const RangeTabs = styled(Tabs)`
  padding: ${({ theme }) => theme.spacing.sm}px;
  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: 0 ${theme.spacing.sm}px;
  `}
`;

export const RadioGroup = styled(RadioGroupBase)`
  ${({ theme }) => useBreakpoint(theme).mobile`
    padding: ${theme.spacing.sm}px ${theme.spacing.md}px;
  `}
`;

export const FiltersDivider = styled(DividerBase)`
  background-color: ${({ theme }) => theme.color.general.borders.primary};
  height: 25px;
  transform: translateY(35%);
`;

export const MobileFilterInfoContainer = styled.div`
  flex-shrink: 0;
`;
