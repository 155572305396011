// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  TermsOfServiceUserApi,
  TermsOfServiceUserCreateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new TermsOfServiceUserApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type TermsOfServiceUserCreateMethod = TermsOfServiceUserApi['termsOfServiceUserCreate'];

export const useTermsOfServiceUserCreate = () => {
  return useMutation<TermsOfServiceUserCreateMethod>(
    (...args) => api.termsOfServiceUserCreate(...args),
    TermsOfServiceUserCreateErrorCodes,
    'TermsOfServiceUserCreate',
    '/api/terms-of-service-users',
    'post'
  );
};
