// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IssuancesApi,
  InternalIssuanceIndexErrorCodes,
  InternalIssuanceIndexErrorTypes,
  InternalShowIssuanceErrorCodes,
  InternalShowIssuanceErrorTypes,
  InternalUpdateIssuanceErrorCodes,
  InternalIssuancesRemoveErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IssuancesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalIssuanceIndexMethod = IssuancesApi['internalIssuanceIndex'];
export type InternalIssuanceIndexResponseType = MethodResult<InternalIssuanceIndexMethod>;

export const useInternalIssuanceIndex = (
  variables: Parameters<InternalIssuanceIndexMethod>[0],
  config?: UseQueryConfig<
    InternalIssuanceIndexMethod,
    InternalIssuanceIndexErrorTypes
  >
) => {
  return useQuery<
    InternalIssuanceIndexMethod,
    InternalIssuanceIndexErrorTypes
  >(
    (...args) => api.internalIssuanceIndex(...args),
    InternalIssuanceIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIssuanceIndex'],
        ...apiQueryConfig.InternalIssuanceIndex,
        ...config?.queryConfig
      },
    },
    'InternalIssuanceIndex',
  );
};

export const useInternalIssuanceIndexInfinite = (
  variables: Parameters<InternalIssuanceIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalIssuanceIndexMethod,
    InternalIssuanceIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalIssuanceIndexMethod,
    InternalIssuanceIndexErrorTypes
  >(
    (...args) => api.internalIssuanceIndex(...args),
    InternalIssuanceIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIssuanceIndex', 'InternalIssuanceIndexInfinite'],
        ...apiQueryConfig.InternalIssuanceIndex,
        ...config?.queryConfig,
      },
    },
    'InternalIssuanceIndex',
  );
};

export const useInternalIssuanceIndexLazy = (baseOptions?: {
  variables?: Parameters<InternalIssuanceIndexMethod>[0],
  config?: UseQueryConfig<
    InternalIssuanceIndexMethod,
    InternalIssuanceIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalIssuanceIndexMethod,
    InternalIssuanceIndexErrorTypes
  >(
    (...args) => api.internalIssuanceIndex(...args),
    InternalIssuanceIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalIssuanceIndex'],
        ...apiQueryConfig.InternalIssuanceIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalIssuanceIndex',
  );
};


export type InternalShowIssuanceMethod = IssuancesApi['internalShowIssuance'];
export type InternalShowIssuanceResponseType = MethodResult<InternalShowIssuanceMethod>;

export const useInternalShowIssuance = (
  variables: Parameters<InternalShowIssuanceMethod>[0],
  config?: UseQueryConfig<
    InternalShowIssuanceMethod,
    InternalShowIssuanceErrorTypes
  >
) => {
  return useQuery<
    InternalShowIssuanceMethod,
    InternalShowIssuanceErrorTypes
  >(
    (...args) => api.internalShowIssuance(...args),
    InternalShowIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowIssuance'],
        ...apiQueryConfig.InternalShowIssuance,
        ...config?.queryConfig
      },
    },
    'InternalShowIssuance',
  );
};

export const useInternalShowIssuanceInfinite = (
  variables: Parameters<InternalShowIssuanceMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowIssuanceMethod,
    InternalShowIssuanceErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowIssuanceMethod,
    InternalShowIssuanceErrorTypes
  >(
    (...args) => api.internalShowIssuance(...args),
    InternalShowIssuanceErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowIssuance', 'InternalShowIssuanceInfinite'],
        ...apiQueryConfig.InternalShowIssuance,
        ...config?.queryConfig,
      },
    },
    'InternalShowIssuance',
  );
};

export const useInternalShowIssuanceLazy = (baseOptions?: {
  variables?: Parameters<InternalShowIssuanceMethod>[0],
  config?: UseQueryConfig<
    InternalShowIssuanceMethod,
    InternalShowIssuanceErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowIssuanceMethod,
    InternalShowIssuanceErrorTypes
  >(
    (...args) => api.internalShowIssuance(...args),
    InternalShowIssuanceErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowIssuance'],
        ...apiQueryConfig.InternalShowIssuance,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowIssuance',
  );
};


export type InternalUpdateIssuanceMethod = IssuancesApi['internalUpdateIssuance'];

export const useInternalUpdateIssuance = () => {
  return useMutation<InternalUpdateIssuanceMethod>(
    (...args) => api.internalUpdateIssuance(...args),
    InternalUpdateIssuanceErrorCodes,
    'InternalUpdateIssuance',
    '/api/internal/issuances/{id}',
    'put'
  );
};

export type InternalIssuancesRemoveMethod = IssuancesApi['internalIssuancesRemove'];

export const useInternalIssuancesRemove = () => {
  return useMutation<InternalIssuancesRemoveMethod>(
    (...args) => api.internalIssuancesRemove(...args),
    InternalIssuancesRemoveErrorCodes,
    'InternalIssuancesRemove',
    '/api/internal/issuances/{id}/remove',
    'post'
  );
};
