import { Loader as BaseLoader } from '@npm/core/ui/components/atoms/Loader';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import styled from 'styled-components';

export const PersonaWrapperModal = styled(Modal)`
  .ant-modal-body,
  .ant-modal-content {
    position: relative;
    padding: 0;

    iframe {
      min-height: 650px;
      width: 100%;
    }

    .ant-modal-close {
      display: none;
    }
  }

  .ant-modal-footer {
    display: none;
  }
`;

export const FloatingX = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.lg}px;
  right: ${({ theme }) => theme.spacing.lg}px;
  color: ${({ theme }) => theme.color.general.typography.secondary};
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PersonaPlaceholder = styled.div`
  width: 400px;
  height: 650px;
`;

export const Loader = styled(BaseLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
