import React from 'react';

import { useTheme } from 'styled-components';

import { Flex, Margin } from '../common';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import { Heading, Text } from '../Typography';

import * as S from './StepperItem.styles';
import { VariantIcon } from './VariantIcon';

export type StepperItemVariant = 'default' | 'done' | 'error' | 'waiting';
export type StepperItemSize = 'sm' | 'md';

type Props = {
  title: React.ReactNode;
  size?: StepperItemSize;
  onClick?: () => void;
  variant?: StepperItemVariant;
  isActive?: boolean;
  isExpanded?: boolean;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  tooltip?: string;
  extra?: React.ReactNode;
  iconProps?: Partial<React.ComponentProps<typeof VariantIcon>>;
  testId?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const StepperItem = ({
  onClick,
  title,
  variant = 'default',
  size = 'sm',
  isActive = false,
  isExpanded = false,
  icon,
  description,
  tooltip,
  extra,
  iconProps,
  testId,
  ...rest
}: Props) => {
  const theme = useTheme();

  return (
    <S.StepperItem
      as={onClick ? 'button' : 'div'}
      onClick={onClick}
      data-cy={testId}
      $variant={variant}
      $size={size}
      $isActive={isActive}
      $isExpanded={isExpanded}
      $isClickable={!!onClick}
      $hasDescription={!!description}
      {...rest}
    >
      <Flex
        align={description ? 'flex-start' : 'center'}
        style={{ width: '100%' }}
      >
        <VariantIcon
          size={size}
          variant={variant || 'error'}
          isActive={isActive || isExpanded}
          icon={icon}
          {...iconProps}
        />
        <div style={{ width: '100%' }}>
          <Margin bottom={size === 'sm' ? 0 : 'sm'} id="margin">
            <Flex align="center" justify="space-between" id="second flex">
              <Flex align="center" id="third flex">
                {size === 'sm' ? (
                  <S.TitleSm
                    size="sm"
                    color={isActive || isExpanded ? 'info' : 'general'}
                    colorVariant={
                      variant === 'default' ? 'tertiary' : 'secondary'
                    }
                    weight={isActive || isExpanded ? 'bold' : 'default'}
                  >
                    {title}{' '}
                  </S.TitleSm>
                ) : (
                  <Heading variant="h2">{title}</Heading>
                )}
                {tooltip && (
                  <Tooltip size="sm" title={tooltip}>
                    <S.TooltipIconWrapper as="span">
                      <Icon
                        size="xs"
                        name="info-circle"
                        color={theme.color.info.typography.primary}
                      />
                    </S.TooltipIconWrapper>
                  </Tooltip>
                )}
              </Flex>
              {extra && (
                <Flex align="center">
                  <S.ExtraWrapper
                    $variant={variant}
                    $isActive={isActive || isExpanded}
                  >
                    {extra}
                  </S.ExtraWrapper>
                </Flex>
              )}
            </Flex>
          </Margin>
          {description && (
            <Text
              size="sm"
              colorVariant={size === 'sm' ? 'tertiary' : 'secondary'}
            >
              {description}
            </Text>
          )}
        </div>
      </Flex>
    </S.StepperItem>
  );
};
