import React, { type ComponentProps } from 'react';

import { Button } from '@npm/core/ui/components/atoms/Button';
import type { ButtonVariant } from '@npm/core/ui/components/atoms/Button/Button.types';
import { Flex, Padding } from '@npm/core/ui/components/atoms/common';
import { Dropdown } from '@npm/core/ui/components/atoms/Dropdown';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { CypressDataIds } from '@npm/core/ui/constants';
import { composeDataId } from '@npm/core/ui/utils/cypress';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';
import {
  CbHoldingState,
  CbWorkstationType,
  type Holding,
} from '@npm/data-access';

import { usePermissions } from '../../auth/permissions/usePermissions';
import { useCurrentWorkstation } from '../../auth/user/role';
import { getDisabledDeleteActionTooltip } from '../Holdings.utils';
import { type HoldingStatusChangeModal } from '../StatusChangeModal';

import * as S from './styles';

type Props = {
  holding: Holding;
  onClick: (args: {
    type: ComponentProps<typeof HoldingStatusChangeModal>['type'];
    id: number;
    certificate_number?: string;
  }) => void;
  onEditClick?: (id: number, holding: Holding) => void;
  onDeleteClick?: (holding: Holding) => void;
  variant?: 'row' | 'drawer';
  showRejectButton?: boolean;
  rejectButtonVariant?: ButtonVariant;
};

export const StatusChangeButtons = ({
  holding,
  onClick,
  onEditClick,
  onDeleteClick,
  variant = 'row',
  showRejectButton,
  rejectButtonVariant = 'text',
}: Props) => {
  const { canWrite, BRO_ACCOUNT_MANAGER } = usePermissions();
  const disabled = !(canWrite || BRO_ACCOUNT_MANAGER);
  const isInvestor =
    useCurrentWorkstation().type.code === CbWorkstationType.items.investor;

  const {
    id,
    certificate_number,
    state,
    can_be_verified,
    pre_verified,
    correction_instructions,
  } = holding;

  const isPending = state.code === CbHoldingState.items.pending;
  const needsVerification =
    state.code === CbHoldingState.items.needs_verification;
  const isVerified = state.code === CbHoldingState.items.verified;
  const hasEditCorrections = !!correction_instructions && needsVerification;

  const disabledTooltipText = getDisabledActionTooltipText(disabled);
  const disabledTooltipTextForDelete = getDisabledDeleteActionTooltip(holding);
  const isDeleteDisabled = disabled || !!disabledTooltipTextForDelete;

  if (isVerified) {
    return null;
  }

  const preVerifyButton = (
    <Tooltip title={disabledTooltipText}>
      <Button
        icon={<Icon name="check" />}
        onClick={() => onClick({ type: 'pre-verify', id, certificate_number })}
        disabled={disabled}
      >
        Pre-Verify
      </Button>
    </Tooltip>
  );

  const verifyButton = (
    <Tooltip
      title={
        !can_be_verified ? (
          <>
            This holding is missing certain data-points. Edit holding on behalf
            of the seller or Reject holding with correction instructions.
          </>
        ) : (
          disabledTooltipText
        )
      }
      placement="topRight"
    >
      <Button
        icon={<Icon name="check" />}
        onClick={() => onClick({ type: 'verify', id, certificate_number })}
        disabled={!canWrite || !can_be_verified}
        data-cy={composeDataId(CypressDataIds.Holdings.Button.Verify, id)}
      >
        Verify
      </Button>
    </Tooltip>
  );

  const revertButton = (
    <Tooltip title={disabledTooltipText}>
      <Button
        variant="text"
        icon={<Icon name="history" />}
        onClick={() => onClick({ type: 'revert', id, certificate_number })}
        disabled={disabled}
        data-cy={composeDataId(CypressDataIds.Holdings.Button.Revert, id)}
      >
        Revert to Pending
      </Button>
    </Tooltip>
  );

  const rejectButton = (
    <Button
      variant={rejectButtonVariant}
      color="error"
      icon={<Icon name="x-close" />}
      onClick={() => onClick({ type: 'reject', id, certificate_number })}
      disabled={disabled}
      data-cy={composeDataId(CypressDataIds.Holdings.Button.Reject, id)}
    >
      Reject
    </Button>
  );

  const getEditButton = (variant: 'broker' | 'issuer') => (
    <Tooltip title={disabledTooltipText}>
      <Button
        icon={<Icon name="edit" />}
        onClick={() => onEditClick?.(holding.id, holding)}
        variant={variant === 'issuer' ? 'default' : 'outline'}
        color="info"
        disabled={disabled}
      >
        Edit {variant === 'issuer' && 'Holding'}
      </Button>
    </Tooltip>
  );

  const deleteButton = (
    <Tooltip title={disabledTooltipTextForDelete} placement="left">
      <Button
        color="error"
        variant="text"
        icon={<Icon name="trash" />}
        disabled={isDeleteDisabled}
        onClick={() => onDeleteClick?.(holding)}
        style={{ maxWidth: '40px' }}
        data-cy={composeDataId(
          CypressDataIds.Holdings.Button.Delete,
          holding.id
        )}
      />
    </Tooltip>
  );

  const dropdownButton = (
    <S.DropdownButton
      variant="text"
      icon={<Icon name="dots-vertical" size="xs" />}
    />
  );

  const getDropdownItems: (args?: {
    isEditHidden?: boolean;
  }) => ComponentProps<typeof Dropdown>['items'] = ({
    isEditHidden = false,
  } = {}) => [
    {
      label: 'Edit Holding',
      icon: <Icon name="edit" />,
      onClick: () => onEditClick?.(holding.id, holding),
      hidden: isEditHidden || !onEditClick,
      disabled,
    },
    {
      label: 'Reject',
      icon: (
        <Icon
          name="x-close"
          color={disabled ? 'general' : onDeleteClick ? 'info' : 'error'}
          colorVariant={disabled ? 'muted' : 'primary'}
        />
      ),
      onClick: () => onClick({ type: 'reject', id, certificate_number }),
      hidden: needsVerification || showRejectButton,
      disabled,
      'data-cy': composeDataId(CypressDataIds.Holdings.Button.Reject, id),
    },
    {
      label: 'Delete',
      icon: (
        <Icon
          name="trash"
          color={isDeleteDisabled ? 'general' : 'error'}
          colorVariant={isDeleteDisabled ? 'muted' : 'primary'}
        />
      ),
      onClick: () => onDeleteClick?.(holding),
      hidden: !onDeleteClick,
      disabled: isDeleteDisabled,
      tooltip: disabledTooltipTextForDelete,
      'data-cy': composeDataId(CypressDataIds.Holdings.Button.Delete, id),
    },
  ];

  if (BRO_ACCOUNT_MANAGER) {
    const canBePreVerified = pre_verified !== null;

    return (
      <S.ButtonsContainer>
        {needsVerification ? (
          revertButton
        ) : pre_verified ? (
          <>
            {getEditButton('broker')}
            {deleteButton}
          </>
        ) : (
          <>
            {canBePreVerified && preVerifyButton}
            {canBePreVerified ? (
              <Dropdown items={getDropdownItems()}>{dropdownButton}</Dropdown>
            ) : (
              <Flex gap="sm" style={{ width: '100%' }}>
                {getEditButton('broker')}
                <Dropdown items={getDropdownItems({ isEditHidden: true })}>
                  {dropdownButton}
                </Dropdown>
              </Flex>
            )}
          </>
        )}
      </S.ButtonsContainer>
    );
  }

  if (variant === 'drawer') {
    if (isInvestor) {
      return null;
    }

    return (
      <Padding all="md">
        <Flex direction="column" gap="sm">
          {!hasEditCorrections && rejectButton}
          {!can_be_verified
            ? getEditButton('issuer')
            : isPending
              ? verifyButton
              : revertButton}
        </Flex>
      </Padding>
    );
  }

  return (
    <S.ButtonsContainer>
      {isPending ? verifyButton : revertButton}
      {isPending && showRejectButton ? rejectButton : null}
      <Dropdown items={getDropdownItems()}>{dropdownButton}</Dropdown>
    </S.ButtonsContainer>
  );
};
