import React, { useState } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { CypressDataIds } from '@npm/core/ui/constants';
import { useForm } from 'antd/lib/form/Form';

const NEW_FOLDER_FORM_ID = 'new-folder-form';

export type AddFolderFormValues = {
  name: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (values: AddFolderFormValues) => Promise<void>;
};

export const AddFolderDrawer = ({ isOpen, onClose, onSubmit }: Props) => {
  const [form] = useForm<AddFolderFormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const fields = await form.validateFields();
      await onSubmit?.(fields);
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer
      open={isOpen}
      title="Add New Folder"
      onClose={handleClose}
      destroyOnClose
      submitButtonProps={{
        children: 'Save',
        form: NEW_FOLDER_FORM_ID,
        htmlType: 'submit',
        'data-cy': CypressDataIds.DataRooms.AddFolderDrawer.SubmitButton,
        loading: isSubmitting,
      }}
    >
      <Form<AddFolderFormValues>
        form={form}
        data-cy={CypressDataIds.DataRooms.AddFolderDrawer.Form}
        id={NEW_FOLDER_FORM_ID}
        onFinish={handleSubmit}
      >
        <InputFormItem
          name="name"
          label="Folder Name"
          rules={[VALIDATION_RULES.required('Name')]}
        >
          <Input placeholder="Folder Name" />
        </InputFormItem>
      </Form>
    </Drawer>
  );
};
