// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  InvestorNegotiationStatisticsApi,
  InvestorNegotiationStatisticsIndexErrorCodes,
  InvestorNegotiationStatisticsIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new InvestorNegotiationStatisticsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InvestorNegotiationStatisticsIndexMethod = InvestorNegotiationStatisticsApi['investorNegotiationStatisticsIndex'];
export type InvestorNegotiationStatisticsIndexResponseType = MethodResult<InvestorNegotiationStatisticsIndexMethod>;

export const useInvestorNegotiationStatisticsIndex = (
  variables: Parameters<InvestorNegotiationStatisticsIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorNegotiationStatisticsIndexMethod,
    InvestorNegotiationStatisticsIndexErrorTypes
  >
) => {
  return useQuery<
    InvestorNegotiationStatisticsIndexMethod,
    InvestorNegotiationStatisticsIndexErrorTypes
  >(
    (...args) => api.investorNegotiationStatisticsIndex(...args),
    InvestorNegotiationStatisticsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorNegotiationStatisticsIndex'],
        ...apiQueryConfig.InvestorNegotiationStatisticsIndex,
        ...config?.queryConfig
      },
    },
    'InvestorNegotiationStatisticsIndex',
  );
};

export const useInvestorNegotiationStatisticsIndexInfinite = (
  variables: Parameters<InvestorNegotiationStatisticsIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    InvestorNegotiationStatisticsIndexMethod,
    InvestorNegotiationStatisticsIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    InvestorNegotiationStatisticsIndexMethod,
    InvestorNegotiationStatisticsIndexErrorTypes
  >(
    (...args) => api.investorNegotiationStatisticsIndex(...args),
    InvestorNegotiationStatisticsIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InvestorNegotiationStatisticsIndex', 'InvestorNegotiationStatisticsIndexInfinite'],
        ...apiQueryConfig.InvestorNegotiationStatisticsIndex,
        ...config?.queryConfig,
      },
    },
    'InvestorNegotiationStatisticsIndex',
  );
};

export const useInvestorNegotiationStatisticsIndexLazy = (baseOptions?: {
  variables?: Parameters<InvestorNegotiationStatisticsIndexMethod>[0],
  config?: UseQueryConfig<
    InvestorNegotiationStatisticsIndexMethod,
    InvestorNegotiationStatisticsIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    InvestorNegotiationStatisticsIndexMethod,
    InvestorNegotiationStatisticsIndexErrorTypes
  >(
    (...args) => api.investorNegotiationStatisticsIndex(...args),
    InvestorNegotiationStatisticsIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InvestorNegotiationStatisticsIndex'],
        ...apiQueryConfig.InvestorNegotiationStatisticsIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InvestorNegotiationStatisticsIndex',
  );
};

