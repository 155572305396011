// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  SecondmarketOrderbookItemPositionApi,
  SecondmarketOrderbookItemPositionShowErrorCodes,
  SecondmarketOrderbookItemPositionShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new SecondmarketOrderbookItemPositionApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type SecondmarketOrderbookItemPositionShowMethod = SecondmarketOrderbookItemPositionApi['secondmarketOrderbookItemPositionShow'];
export type SecondmarketOrderbookItemPositionShowResponseType = MethodResult<SecondmarketOrderbookItemPositionShowMethod>;

export const useSecondmarketOrderbookItemPositionShow = (
  variables: Parameters<SecondmarketOrderbookItemPositionShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketOrderbookItemPositionShowMethod,
    SecondmarketOrderbookItemPositionShowErrorTypes
  >
) => {
  return useQuery<
    SecondmarketOrderbookItemPositionShowMethod,
    SecondmarketOrderbookItemPositionShowErrorTypes
  >(
    (...args) => api.secondmarketOrderbookItemPositionShow(...args),
    SecondmarketOrderbookItemPositionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketOrderbookItemPositionShow'],
        ...apiQueryConfig.SecondmarketOrderbookItemPositionShow,
        ...config?.queryConfig
      },
    },
    'SecondmarketOrderbookItemPositionShow',
  );
};

export const useSecondmarketOrderbookItemPositionShowInfinite = (
  variables: Parameters<SecondmarketOrderbookItemPositionShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    SecondmarketOrderbookItemPositionShowMethod,
    SecondmarketOrderbookItemPositionShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    SecondmarketOrderbookItemPositionShowMethod,
    SecondmarketOrderbookItemPositionShowErrorTypes
  >(
    (...args) => api.secondmarketOrderbookItemPositionShow(...args),
    SecondmarketOrderbookItemPositionShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['SecondmarketOrderbookItemPositionShow', 'SecondmarketOrderbookItemPositionShowInfinite'],
        ...apiQueryConfig.SecondmarketOrderbookItemPositionShow,
        ...config?.queryConfig,
      },
    },
    'SecondmarketOrderbookItemPositionShow',
  );
};

export const useSecondmarketOrderbookItemPositionShowLazy = (baseOptions?: {
  variables?: Parameters<SecondmarketOrderbookItemPositionShowMethod>[0],
  config?: UseQueryConfig<
    SecondmarketOrderbookItemPositionShowMethod,
    SecondmarketOrderbookItemPositionShowErrorTypes
  >
}) => {
  return useLazyQuery<
    SecondmarketOrderbookItemPositionShowMethod,
    SecondmarketOrderbookItemPositionShowErrorTypes
  >(
    (...args) => api.secondmarketOrderbookItemPositionShow(...args),
    SecondmarketOrderbookItemPositionShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['SecondmarketOrderbookItemPositionShow'],
        ...apiQueryConfig.SecondmarketOrderbookItemPositionShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'SecondmarketOrderbookItemPositionShow',
  );
};

