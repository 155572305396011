// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  FlowScreenerApi,
  FlowScreenerIndexErrorCodes,
  FlowScreenerIndexErrorTypes,
  GetFlowScreenerDocumentErrorCodes,
  GetFlowScreenerDocumentErrorTypes,
  GetFlowScreenerOutputTemplateErrorCodes,
  GetFlowScreenerOutputTemplateErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new FlowScreenerApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type FlowScreenerIndexMethod = FlowScreenerApi['flowScreenerIndex'];
export type FlowScreenerIndexResponseType = MethodResult<FlowScreenerIndexMethod>;

export const useFlowScreenerIndex = (
  variables: Parameters<FlowScreenerIndexMethod>[0],
  config?: UseQueryConfig<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >
) => {
  return useQuery<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >(
    (...args) => api.flowScreenerIndex(...args),
    FlowScreenerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FlowScreenerIndex'],
        ...apiQueryConfig.FlowScreenerIndex,
        ...config?.queryConfig
      },
    },
    'FlowScreenerIndex',
  );
};

export const useFlowScreenerIndexInfinite = (
  variables: Parameters<FlowScreenerIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >(
    (...args) => api.flowScreenerIndex(...args),
    FlowScreenerIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['FlowScreenerIndex', 'FlowScreenerIndexInfinite'],
        ...apiQueryConfig.FlowScreenerIndex,
        ...config?.queryConfig,
      },
    },
    'FlowScreenerIndex',
  );
};

export const useFlowScreenerIndexLazy = (baseOptions?: {
  variables?: Parameters<FlowScreenerIndexMethod>[0],
  config?: UseQueryConfig<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    FlowScreenerIndexMethod,
    FlowScreenerIndexErrorTypes
  >(
    (...args) => api.flowScreenerIndex(...args),
    FlowScreenerIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['FlowScreenerIndex'],
        ...apiQueryConfig.FlowScreenerIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'FlowScreenerIndex',
  );
};


export type GetFlowScreenerDocumentMethod = FlowScreenerApi['getFlowScreenerDocument'];
export type GetFlowScreenerDocumentResponseType = MethodResult<GetFlowScreenerDocumentMethod>;

export const useGetFlowScreenerDocument = (
  variables: Parameters<GetFlowScreenerDocumentMethod>[0],
  config?: UseQueryConfig<
    GetFlowScreenerDocumentMethod,
    GetFlowScreenerDocumentErrorTypes
  >
) => {
  return useQuery<
    GetFlowScreenerDocumentMethod,
    GetFlowScreenerDocumentErrorTypes
  >(
    (...args) => api.getFlowScreenerDocument(...args),
    GetFlowScreenerDocumentErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetFlowScreenerDocument'],
        ...apiQueryConfig.GetFlowScreenerDocument,
        ...config?.queryConfig
      },
    },
    'GetFlowScreenerDocument',
  );
};

export const useGetFlowScreenerDocumentInfinite = (
  variables: Parameters<GetFlowScreenerDocumentMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetFlowScreenerDocumentMethod,
    GetFlowScreenerDocumentErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetFlowScreenerDocumentMethod,
    GetFlowScreenerDocumentErrorTypes
  >(
    (...args) => api.getFlowScreenerDocument(...args),
    GetFlowScreenerDocumentErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetFlowScreenerDocument', 'GetFlowScreenerDocumentInfinite'],
        ...apiQueryConfig.GetFlowScreenerDocument,
        ...config?.queryConfig,
      },
    },
    'GetFlowScreenerDocument',
  );
};

export const useGetFlowScreenerDocumentLazy = (baseOptions?: {
  variables?: Parameters<GetFlowScreenerDocumentMethod>[0],
  config?: UseQueryConfig<
    GetFlowScreenerDocumentMethod,
    GetFlowScreenerDocumentErrorTypes
  >
}) => {
  return useLazyQuery<
    GetFlowScreenerDocumentMethod,
    GetFlowScreenerDocumentErrorTypes
  >(
    (...args) => api.getFlowScreenerDocument(...args),
    GetFlowScreenerDocumentErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetFlowScreenerDocument'],
        ...apiQueryConfig.GetFlowScreenerDocument,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetFlowScreenerDocument',
  );
};


export type GetFlowScreenerOutputTemplateMethod = FlowScreenerApi['getFlowScreenerOutputTemplate'];
export type GetFlowScreenerOutputTemplateResponseType = MethodResult<GetFlowScreenerOutputTemplateMethod>;

export const useGetFlowScreenerOutputTemplate = (
  variables: Parameters<GetFlowScreenerOutputTemplateMethod>[0],
  config?: UseQueryConfig<
    GetFlowScreenerOutputTemplateMethod,
    GetFlowScreenerOutputTemplateErrorTypes
  >
) => {
  return useQuery<
    GetFlowScreenerOutputTemplateMethod,
    GetFlowScreenerOutputTemplateErrorTypes
  >(
    (...args) => api.getFlowScreenerOutputTemplate(...args),
    GetFlowScreenerOutputTemplateErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetFlowScreenerOutputTemplate'],
        ...apiQueryConfig.GetFlowScreenerOutputTemplate,
        ...config?.queryConfig
      },
    },
    'GetFlowScreenerOutputTemplate',
  );
};

export const useGetFlowScreenerOutputTemplateInfinite = (
  variables: Parameters<GetFlowScreenerOutputTemplateMethod>[0],
  config?: UseInfiniteQueryConfig<
    GetFlowScreenerOutputTemplateMethod,
    GetFlowScreenerOutputTemplateErrorTypes
  >
) => {
  return useInfiniteQuery<
    GetFlowScreenerOutputTemplateMethod,
    GetFlowScreenerOutputTemplateErrorTypes
  >(
    (...args) => api.getFlowScreenerOutputTemplate(...args),
    GetFlowScreenerOutputTemplateErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['GetFlowScreenerOutputTemplate', 'GetFlowScreenerOutputTemplateInfinite'],
        ...apiQueryConfig.GetFlowScreenerOutputTemplate,
        ...config?.queryConfig,
      },
    },
    'GetFlowScreenerOutputTemplate',
  );
};

export const useGetFlowScreenerOutputTemplateLazy = (baseOptions?: {
  variables?: Parameters<GetFlowScreenerOutputTemplateMethod>[0],
  config?: UseQueryConfig<
    GetFlowScreenerOutputTemplateMethod,
    GetFlowScreenerOutputTemplateErrorTypes
  >
}) => {
  return useLazyQuery<
    GetFlowScreenerOutputTemplateMethod,
    GetFlowScreenerOutputTemplateErrorTypes
  >(
    (...args) => api.getFlowScreenerOutputTemplate(...args),
    GetFlowScreenerOutputTemplateErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['GetFlowScreenerOutputTemplate'],
        ...apiQueryConfig.GetFlowScreenerOutputTemplate,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'GetFlowScreenerOutputTemplate',
  );
};

