import { Alert } from '@npm/core/ui/components/atoms/Alert';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { CbOrderItemState, type OrderItemStateCode } from '@npm/data-access';

type Props = {
  errors: ReturnType<typeof validateDateFields>;
};

export const DateValidationAlerts = ({ errors }: Props) => {
  if (!errors.length) return null;
  return (
    <Flex direction="column" gap="sm">
      {errors.map(error => (
        <Alert type="error" message={error.message} key={error.message} />
      ))}
    </Flex>
  );
};

export const validateDateFields = (formValues: {
  orderReceivedDate?: Date;
  placedAtDate?: Date;
  orderPublishedDate?: Date;
  expiration?: Date;
  state?: OrderItemStateCode; // historical only
}) => {
  const errors = [];

  if (
    formValues.state === CbOrderItemState.items.expired &&
    !!formValues.expiration &&
    formValues.expiration > new Date()
  ) {
    errors.push(new Error('Expires At date must be in the past'));
  }

  if (
    !!formValues.placedAtDate &&
    !!formValues.orderReceivedDate &&
    formValues.placedAtDate < formValues.orderReceivedDate
  ) {
    errors.push(new Error('Created At date cannot be before Received At'));
  }

  if (
    !!formValues.orderPublishedDate &&
    !!formValues.orderReceivedDate &&
    formValues.orderPublishedDate < formValues.orderReceivedDate
  ) {
    errors.push(new Error('Published At date cannot be before Received At'));
  }

  if (
    !!formValues.orderPublishedDate &&
    formValues.orderPublishedDate < (formValues.placedAtDate ?? new Date())
  ) {
    errors.push(new Error('Published At date cannot be before Created At'));
  }

  return errors;
};
