import { Select as AntSelect } from 'antd';
import styled, { css } from 'styled-components';

import { useBreakpoint } from '../../../utils/style';
import { INPUT_HEIGHT } from '../Input/InlineInputNumber.styles';
import {
  InputTextStyles,
  SmallTextStyles,
  TextNumberStyles,
} from '../Typography';

/* Dropdown is rendered as an absolute-positioned element elsewehere */
export const StyledDropdown = styled.div`
  > .rc-virtual-list {
    width: 100%;
    background-color: ${({ theme }) => theme.color.general.layout.two};

    .ant-select-item {
      color: ${({ theme }) => theme.color.general.typography.primary};

      &.ant-select-item-option-active {
        background-color: ${({ theme }) => theme.color.info.borders.secondary};
      }

      height: 32px;
      ${({ theme }) => useBreakpoint(theme).mobile`
        min-height: 40px;
      `}
    }
  }
`;
export const StyledSelect = styled(AntSelect)<{
  label?: string;
  $readOnly?: boolean;
}>`
  ${InputTextStyles}; /* Borders and box shadows are set in the child .ant-select-selector child */
  /* ANT styles are too specific, it would need a lot more code */
  border: none !important;
  box-shadow: none !important;
  width: 100%; // && for increased specificity
  && {
    .ant-select-selection-search::before {
      content: '${({ label }) => label ?? ''}';
      ${SmallTextStyles}
      line-height: ${INPUT_HEIGHT - 2}px !important;
    }

    .ant-select-selector {
      height: ${INPUT_HEIGHT}px;
      display: flex;
      align-items: center;
      border-radius: ${({ theme }) => theme.borderRadius.md}px;
      border-color: ${({ theme }) => theme.color.general.borders.secondary};
      background-color: ${({ theme }) => theme.color.general.layout.two};
      padding: ${({ theme }) =>
        `${theme.spacing.sm}px ${theme.spacing.lg / 2}px `};
      color: ${({ theme }) => theme.color.general.typography.primary};
    }

    .ant-select-arrow {
      transition: transform 0 ease-in;
      color: ${({ theme }) => theme.color.general.typography.primary};
      right: ${({ theme }) => theme.spacing.lg / 2}px;
      width: 12px;
      height: 12px;
    }

    /** Placeholder text */

    .ant-select-selection-placeholder {
      ${TextNumberStyles}
      text-align: right;
      line-height: ${({ theme }) => INPUT_HEIGHT - theme.spacing.sm * 2 - 2}px;
      color: ${({ theme }) => theme.color.general.typography.secondary};
    }

    /** Selected item */

    .ant-select-selection-item {
      ${TextNumberStyles}
      text-align: right;
      line-height: ${({ theme }) => INPUT_HEIGHT - theme.spacing.sm * 2 - 2}px;
      color: ${({ theme }) => theme.color.general.typography.primary};
    }

    /** Arrow when open */

    &.ant-select-open .ant-select-arrow {
      transform: rotate(180deg);
    }

    /** Hover */

    &.ant-select-open:not(.ant-select-disabled),
    &:not(.ant-select-disabled):hover {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.color.info.borders.primary};
      }
    }

    /** Focused */

    &.ant-select-open:not(.ant-select-disabled),
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: ${({ theme }) => theme.color.info.borders.primary};
      box-shadow:
        ${({ theme }) => theme.shadow.level1},
        0 0 0 1px ${({ theme }) => theme.color.info.borders.primary};
    }

    /* Selector container: error state */

    &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ) {
      .ant-select-selector {
        background-color: ${({ theme }) => theme.color.general.layout.two};
        border-color: ${({ theme }) =>
          theme.color.error.typography.primary} !important;
      }

      &.ant-select-open > .ant-select-selector,
      &.ant-select-focused > .ant-select-selector {
        border-color: ${({ theme }) => theme.color.error.typography.primary};
        box-shadow:
          ${({ theme }) => theme.shadow.level1},
          0 0 0 1px ${({ theme }) => theme.color.error.typography.primary};
      }
    }

    /* Selector container: disabled state */

    &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: ${({ theme }) => theme.color.general.layout.two};
      border-color: ${({ theme }) => theme.color.general.borders.primary};
      color: ${({ theme }) => theme.color.general.typography.muted};
    }

    :is(.ant-select.ant-select-disabled) .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.color.general.typography.muted};
    }

    :is(.ant-select.ant-select-disabled) .ant-select-arrow {
      color: ${({ theme }) => theme.color.general.typography.muted};
    }

    :is(.ant-select.ant-select-disabled) .ant-select-selection-search::before {
      color: ${({ theme }) => theme.color.general.typography.muted};
    }

    /* Read only state */
    ${({ $readOnly, theme }) =>
      $readOnly &&
      css`
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: ${theme.color.general.backgrounds.muted};
          border-color: ${theme.color.general.borders.secondary};
          color: ${theme.color.general.typography.secondary};
        }

        .ant-select-selection-item {
          color: ${theme.color.general.typography.secondary};
        }

        &.ant-select-focused .ant-select-selector {
          background-color: ${theme.color.general.backgrounds.muted} !important;
          border-color: ${theme.color.general.borders.secondary} !important;
        }

        .ant-select-selection-placeholder {
          color: ${theme.color.general.typography.primary};
        }

        .ant-select-selection-search::before {
          color: ${({ theme }) => theme.color.general.typography.secondary};
        }

        .ant-select-arrow {
          color: ${theme.color.general.typography.muted};
        }
      `};

    /* Clear icon */

    .ant-select-clear {
      background-color: ${({ theme }) => theme.color.general.layout.two};
      width: 20px;
      height: 20px;
      margin-top: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
