import React, { type FC, useCallback } from 'react';

import { type CodebookItem } from '@npm/data-access';
import { type FormInstance } from 'antd';

import { LabelItem } from '../../components/LabelItem';

import * as S from './AutoScrollingQuestion.styles';

type Props = {
  labels: CodebookItem[];
  onQuestionAnswered: () => void;
};

export const AutoScrollingQuestion: FC<Props> = ({
  labels,
  onQuestionAnswered,
}) => {
  const onChange = useCallback(
    (form: FormInstance) => {
      try {
        const values = form.getFieldsValue(labels.map(l => l.code));
        if (Object.values(values).every(v => v !== undefined)) {
          onQuestionAnswered();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [labels, onQuestionAnswered]
  );

  return (
    <S.FormItem shouldUpdate={true}>
      {form => {
        return (
          <S.Wrap>
            {labels.map(label => (
              <LabelItem
                key={label.code}
                label={label}
                onChange={() => onChange(form as FormInstance)}
              />
            ))}
          </S.Wrap>
        );
      }}
    </S.FormItem>
  );
};
