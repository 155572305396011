import React, { memo, useLayoutEffect, useMemo, useState } from 'react';

import { Menu as AntMenu, type MenuProps } from 'antd';
import { useTheme } from 'styled-components';

import { useCurrentRoute } from '../../../../hooks/useCurrentRoute';
import { pageHistoryService } from '../../../../services/pageHistory/pageHistory.service';
import { Icon } from '../../../atoms/Icon';
import { useHistory, useLocation } from '../../../molecules/Link';
import { useSidebarStore } from '../Sidebar/Sidebar.store';

import * as S from './Navigation.styles';
import { type MenuItem, type MenuItemGroup } from './Navigation.types';
import {
  findSelectedRootItem,
  mapMenuItemsWithActionItemsCount,
} from './Navigation.utils';

type Props = {
  menuItems: (MenuItem | MenuItemGroup)[];
  handleClick?: (key: string) => boolean;
};

export const Navigation = memo(function Navigation({
  menuItems,
  handleClick: customClickHandler,
}: Props) {
  const currentRoute = useCurrentRoute();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const isCollapsed = useSidebarStore(store => store.isCollapsed);

  const theme = useTheme();

  const history = useHistory();

  const handleClick: MenuProps['onClick'] = e => {
    if (!customClickHandler?.(e.key)) {
      history.push(
        {
          pathname: e.key,
        },
        {
          // reset page history on menu click
          pageHistory: [],
        }
      );
    }
  };

  // Handle Apple Pencil touch
  // onTouchEnd is always firing, even if the user is just scrolling
  let startY: number | undefined;
  const handleTouchStart: MenuProps['onTouchStart'] = e => {
    // Get the initial touch position
    startY = e.touches[0]?.clientY;
  };

  const handleTouchEnd: MenuProps['onTouchEnd'] = e => {
    // Get the final touch position
    const endY = e.changedTouches[0]?.clientY;

    const threshold = 10;

    if (startY == null || endY == null || Math.abs(startY - endY) > threshold) {
      // Scrolling, so we ignore this touch event
      return;
    }

    const target = e?.nativeEvent?.target as HTMLElement;
    const offsetParent = target?.offsetParent as HTMLElement;
    const key = offsetParent?.dataset?.menuId?.split('-/')[1];

    if (key && !customClickHandler?.(key)) {
      history.push(
        {
          pathname: '/' + key,
        },
        {
          // reset page history on menu click
          pageHistory: [],
        }
      );
    }
  };

  const selectedItem = useMemo(() => {
    return findSelectedRootItem(
      menuItems,
      pageHistoryService.getCurrent(location, currentRoute)?.[0]?.location
        ?.pathname ??
        currentRoute?.parents?.[0]?.path ??
        currentRoute?.route?.path
    );
  }, [menuItems, currentRoute?.parents, currentRoute?.route, location]);

  const mappedItems = useMemo(
    () => mapMenuItemsWithActionItemsCount(menuItems, isCollapsed),
    [menuItems, isCollapsed]
  );

  useLayoutEffect(() => {
    if (!isCollapsed) {
      const rootItem = menuItems.find((item: MenuItemGroup) =>
        item?.children?.find(child => child.key === selectedItem)
      );

      setOpenKeys(rootItem ? [rootItem.key] : []);
    }
  }, [isCollapsed, selectedItem, menuItems]);

  return (
    <S.NavigationWrapper $isCollapsed={isCollapsed}>
      <AntMenu
        inlineIndent={theme.spacing.md} // padding-left
        mode="inline"
        openKeys={openKeys}
        onOpenChange={keys => setOpenKeys(keys)}
        selectedKeys={selectedItem ? [selectedItem] : undefined}
        style={{ height: '100%', borderRight: 0 }}
        expandIcon={({ isOpen }: { isOpen: boolean }) =>
          isOpen ? null : <Icon name="chevron-down" size="xs" />
        }
        items={mappedItems}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      />
      <S.MenuPopup />
    </S.NavigationWrapper>
  );
});
