import { CardBase } from '@npm/core/ui/components/atoms/CardBase';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { useTheme } from 'styled-components';

import * as S from './CompanyDataSectionLoader.styles';
import { CompanyDataSectionLegendLoader } from './CompanyDataSectionLegendLoader';

export const CompanyDataSectionLoader = () => {
  const theme = useTheme();

  const { isMobile, isTablet } = useBreakpoints();

  return (
    <Flex direction={'column'} gap={'md'}>
      <CardBase>
        <Flex direction={'column'}>
          <Flex align={'center'} justify={'space-between'}>
            <S.ChartHeaderLeftLoader active paragraph={false} />
            <S.ChartHeaderRightLoader active paragraph={false} />
          </Flex>
          <S.ChartLoaderWrapper>
            <S.ChartLoader active paragraph={false} />
            <S.ImageWrapper>
              <Icon
                color={theme.color.general.typography.tertiary}
                name={'chart'}
                size={'xxl'}
              />
            </S.ImageWrapper>
          </S.ChartLoaderWrapper>

          <Flex align={'center'} justify={'center'} gap={'md'}>
            <CompanyDataSectionLegendLoader />
            <CompanyDataSectionLegendLoader />
            {!isMobile && <CompanyDataSectionLegendLoader />}
            {!isMobile && !isTablet && <CompanyDataSectionLegendLoader />}
          </Flex>
        </Flex>
      </CardBase>
    </Flex>
  );
};
