import { useMemo } from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Form } from '@npm/core/ui/components/atoms/Form';
import { SquareIcon } from '@npm/core/ui/components/atoms/Icon/SquareIcon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { DrawerSection } from '@npm/core/ui/components/molecules/DrawerSection';
import { type Holding, type IssuerEntityAggregate } from '@npm/data-access';
import { type FormInstance } from 'antd';

import { YourRoleDrawerSection } from '../../../../../../auth/user/context/YourRoleDrawerSection';
import {
  HoldingDrawer,
  MissingPoOAlert,
  useHoldingDrawer,
} from '../../../../../../holdings';
import { AggregatedHoldingDrawer } from '../../../../../../holdings/aggregated/Drawer';
import { useAggregatedHoldingDrawer } from '../../../../../../holdings/aggregated/Drawer/AggregatedHoldingDrawer.hooks';
import type { OrderSizeType } from '../../../../../../order';
import { HoldingCard } from '../../../../../drawers/components/HoldingCard';
import { OrderStatusFirm } from '../../../../components';
import { calculateExerciseCosts } from '../../EnterSellIndication.utils';
import { HoldingDetail } from '../HoldingDetail';
import { ReviewOrderSection } from '../ReviewOrderSection';
import {
  type HoldingsDistribution,
  type SellFormValues,
} from '../SellIndicationDrawer.types';

type Props = {
  issuerEntity: IssuerEntityAggregate;
  confirmationForm: FormInstance<SellFormValues>;
  values: SellFormValues | undefined;
  holding: Holding | undefined;
  holdingsDistribution: HoldingsDistribution | undefined;
  orderSizeType: OrderSizeType;
  accountId: number;
  errorMessage?: string | false;
  onUpdateHolding?: (holding: Holding) => void;
};

export const SellIndicationConfirmation = ({
  confirmationForm,
  issuerEntity,
  values,
  holding,
  holdingsDistribution,
  orderSizeType,
  accountId,
  errorMessage,
  onUpdateHolding,
}: Props) => {
  const [{ openEditHoldingDrawer }, holdingDrawerProps] = useHoldingDrawer({
    preselectedIssuerEntityId: values?.issuerEntityId,
    preselectedAccountId: values?.accountId,
  });

  const [{ openAggregatedHoldingDrawer }, aggregatedHoldingDrawerProps] =
    useAggregatedHoldingDrawer();

  const handleEditHolding = (h: Holding) => {
    if (h.aggregated) {
      openAggregatedHoldingDrawer(h);
    } else {
      openEditHoldingDrawer(h.id);
    }
  };

  const isMissingPoO = holding?.aggregated
    ? !holding.aggregated_proof_of_ownership_documents_count
    : !holding?.proof_of_ownership_document;

  const exerciseCosts = useMemo(() => {
    if (!holdingsDistribution) {
      return 0;
    }

    return holdingsDistribution.reduce((acc, holdingDistribution) => {
      return (
        acc +
        calculateExerciseCosts(
          holdingDistribution.holding,
          holdingDistribution.allocation
        )
      );
    }, 0);
  }, [holdingsDistribution]);

  if (!values) {
    return null;
  }

  const drawerContent = (
    <Flex direction="column" gap="sm">
      <HoldingCard holding={holding} header={true} />
      {isMissingPoO && (
        <MissingPoOAlert
          description=""
          message={
            <Flex justify="space-between">
              <Flex>
                <Text size="sm">Missing Proof of Ownership</Text>
              </Flex>
              <Margin left="lg">
                <Text
                  as="button"
                  size="sm"
                  hyperlinkColor="warning"
                  onClick={() => handleEditHolding(holding)}
                >
                  + Add
                </Text>
              </Margin>
            </Flex>
          }
        />
      )}
    </Flex>
  );

  return (
    <div>
      <Form form={confirmationForm}>
        <Flex direction={'column'} gap={'lg'}>
          <Flex direction={'column'} gap={'sm'}>
            <Flex align={'center'} gap={'sm'}>
              <SquareIcon iconName={'user'} />

              <Text size={'default'} weight={'bold'}>
                Account
              </Text>
            </Flex>

            <YourRoleDrawerSection accountId={accountId} showTitle={false} />
          </Flex>

          <Flex direction={'column'} gap={'md'}>
            {holdingsDistribution ? (
              <>
                <Flex align={'center'} gap={'sm'}>
                  <SquareIcon iconName={'clipboard-text'} />

                  <Text size={'default'} weight={'bold'}>
                    Your Offer Details
                  </Text>

                  <OrderStatusFirm style={{ marginLeft: 'auto' }} />
                </Flex>

                {holdingsDistribution.map(holdingDistribution => {
                  const { holding } = holdingDistribution;

                  return (
                    <HoldingDetail
                      key={holding.id}
                      holdingDistribution={holdingDistribution}
                      pps={values.pricePerShare}
                      onEditHolding={() => handleEditHolding(holding)}
                    />
                  );
                })}
              </>
            ) : (
              holding && (
                <DrawerSection
                  iconName="info-circle"
                  title="Your Holding"
                  content={drawerContent}
                />
              )
            )}
          </Flex>

          <ReviewOrderSection
            values={values}
            exerciseCosts={exerciseCosts}
            orderSizeType={orderSizeType}
            errorMessage={errorMessage}
          />
        </Flex>
      </Form>
      <HoldingDrawer onSuccess={onUpdateHolding} {...holdingDrawerProps} />
      <AggregatedHoldingDrawer
        accountId={accountId}
        issuerEntity={issuerEntity}
        onSuccess={onUpdateHolding}
        {...aggregatedHoldingDrawerProps}
      />
    </div>
  );
};
