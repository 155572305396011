// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AnnualPercentageYieldApi,
  AnnualPercentageYieldShowErrorCodes,
  AnnualPercentageYieldShowErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AnnualPercentageYieldApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AnnualPercentageYieldShowMethod = AnnualPercentageYieldApi['annualPercentageYieldShow'];
export type AnnualPercentageYieldShowResponseType = MethodResult<AnnualPercentageYieldShowMethod>;

export const useAnnualPercentageYieldShow = (
  variables: Parameters<AnnualPercentageYieldShowMethod>[0],
  config?: UseQueryConfig<
    AnnualPercentageYieldShowMethod,
    AnnualPercentageYieldShowErrorTypes
  >
) => {
  return useQuery<
    AnnualPercentageYieldShowMethod,
    AnnualPercentageYieldShowErrorTypes
  >(
    (...args) => api.annualPercentageYieldShow(...args),
    AnnualPercentageYieldShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AnnualPercentageYieldShow'],
        ...apiQueryConfig.AnnualPercentageYieldShow,
        ...config?.queryConfig
      },
    },
    'AnnualPercentageYieldShow',
  );
};

export const useAnnualPercentageYieldShowInfinite = (
  variables: Parameters<AnnualPercentageYieldShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AnnualPercentageYieldShowMethod,
    AnnualPercentageYieldShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AnnualPercentageYieldShowMethod,
    AnnualPercentageYieldShowErrorTypes
  >(
    (...args) => api.annualPercentageYieldShow(...args),
    AnnualPercentageYieldShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AnnualPercentageYieldShow', 'AnnualPercentageYieldShowInfinite'],
        ...apiQueryConfig.AnnualPercentageYieldShow,
        ...config?.queryConfig,
      },
    },
    'AnnualPercentageYieldShow',
  );
};

export const useAnnualPercentageYieldShowLazy = (baseOptions?: {
  variables?: Parameters<AnnualPercentageYieldShowMethod>[0],
  config?: UseQueryConfig<
    AnnualPercentageYieldShowMethod,
    AnnualPercentageYieldShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AnnualPercentageYieldShowMethod,
    AnnualPercentageYieldShowErrorTypes
  >(
    (...args) => api.annualPercentageYieldShow(...args),
    AnnualPercentageYieldShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AnnualPercentageYieldShow'],
        ...apiQueryConfig.AnnualPercentageYieldShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AnnualPercentageYieldShow',
  );
};

