/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market Company-Pricing API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FlowScreenerIndex } from '../model';
/**
 * FlowScreenerApi - axios parameter creator
 * @export
 */
export const FlowScreenerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get FlowScreeners
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flowScreenerIndex: async (status?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/flow_screeners`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get FlowScreeners
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowScreenerDocument: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFlowScreenerDocument', 'id', id)
            const localVarPath = changeUrl(`/api/flow_screeners/{id}/download_input_document`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get FlowScreener Output Template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowScreenerOutputTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/flow_screeners/download_output_template`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowScreenerApi - functional programming interface
 * @export
 */
export const FlowScreenerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowScreenerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get FlowScreeners
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flowScreenerIndex(status?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowScreenerIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flowScreenerIndex(status, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get FlowScreeners
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowScreenerDocument(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowScreenerIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowScreenerDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get FlowScreener Output Template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlowScreenerOutputTemplate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlowScreenerOutputTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowScreenerApi - factory interface
 * @export
 */
export const FlowScreenerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowScreenerApiFp(configuration)
    return {
        /**
         * 
         * @summary get FlowScreeners
         * @param {string} [status] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flowScreenerIndex(status?: string, page?: number, size?: number, options?: any): AxiosPromise<FlowScreenerIndex> {
            return localVarFp.flowScreenerIndex(status, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get FlowScreeners
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowScreenerDocument(id: number, options?: any): AxiosPromise<FlowScreenerIndex> {
            return localVarFp.getFlowScreenerDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get FlowScreener Output Template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlowScreenerOutputTemplate(options?: any): AxiosPromise<void> {
            return localVarFp.getFlowScreenerOutputTemplate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for flowScreenerIndex operation in FlowScreenerApi.
 * @export
 * @interface FlowScreenerApiFlowScreenerIndexRequest
 */
export interface FlowScreenerApiFlowScreenerIndexRequest {
    /**
     * 
     * @type {string}
     * @memberof FlowScreenerApiFlowScreenerIndex
     */
    readonly status?: string

    /**
     * 
     * @type {number}
     * @memberof FlowScreenerApiFlowScreenerIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof FlowScreenerApiFlowScreenerIndex
     */
    readonly size?: number
}

/**
 * Request parameters for getFlowScreenerDocument operation in FlowScreenerApi.
 * @export
 * @interface FlowScreenerApiGetFlowScreenerDocumentRequest
 */
export interface FlowScreenerApiGetFlowScreenerDocumentRequest {
    /**
     * 
     * @type {number}
     * @memberof FlowScreenerApiGetFlowScreenerDocument
     */
    readonly id: number
}

/**
 * FlowScreenerApi - object-oriented interface
 * @export
 * @class FlowScreenerApi
 * @extends {BaseAPI}
 */
export class FlowScreenerApi extends BaseAPI {
    /**
     * 
     * @summary get FlowScreeners
     * @param {FlowScreenerApiFlowScreenerIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowScreenerApi
     */
    public flowScreenerIndex(requestParameters: FlowScreenerApiFlowScreenerIndexRequest = {}, options?: AxiosRequestConfig) {
        return FlowScreenerApiFp(this.configuration).flowScreenerIndex(requestParameters.status, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get FlowScreeners
     * @param {FlowScreenerApiGetFlowScreenerDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowScreenerApi
     */
    public getFlowScreenerDocument(requestParameters: FlowScreenerApiGetFlowScreenerDocumentRequest, options?: AxiosRequestConfig) {
        return FlowScreenerApiFp(this.configuration).getFlowScreenerDocument(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get FlowScreener Output Template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowScreenerApi
     */
    public getFlowScreenerOutputTemplate(options?: AxiosRequestConfig) {
        return FlowScreenerApiFp(this.configuration).getFlowScreenerOutputTemplate(options).then((request) => request(this.axios, this.basePath));
    }
}

export type FlowScreenerIndexErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const FlowScreenerIndexErrorCodes = [
    401,
];

export type GetFlowScreenerDocumentErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetFlowScreenerDocumentErrorCodes = [
    401,
];

export type GetFlowScreenerOutputTemplateErrorTypes =

    | {
        status: 401;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const GetFlowScreenerOutputTemplateErrorCodes = [
    401,
];


