import React, { memo, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

import { DarkModeSwitcher } from '@npm/core/ui/components/organisms/DarkModeSwitcher';
import {
  Content,
  SidebarLayout,
} from '@npm/core/ui/components/organisms/Layout';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import {
  RoleSwitcher,
  WorkstationSwitcher,
} from '@npm/features/auth/user/context';
import { OboButton } from '@npm/features/auth/user/context/Obo/OboButton';
import { useObo } from '@npm/features/auth/user/role';
import { useLogoRedirectLink } from '@npm/features/logo';

import { BrokerageMenu } from './BrokerageMenu';

type Props = {
  children: React.ReactNode;
};

export const BrokerageLayout = memo(function BrokerageLayout({
  children,
}: Props) {
  const { isObo } = useObo();
  const { route } = useCurrentRoute() ?? {};

  const logoLinkUrl = useLogoRedirectLink();

  const renderMenu = useCallback(
    (isSidebarCollapsed: boolean) => (
      <>
        <WorkstationSwitcher isCollapsed={isSidebarCollapsed} />
        {isObo ? (
          <OboButton isCollapsed={isSidebarCollapsed} />
        ) : (
          <RoleSwitcher isCollapsed={isSidebarCollapsed} />
        )}
        <BrokerageMenu />
        <DarkModeSwitcher isCollapsed={isSidebarCollapsed} />
      </>
    ),
    [isObo]
  );

  const content = (
    <>
      {children}
      <Outlet />
    </>
  );

  if (route?.layout?.noLayout) {
    return <>{content}</>;
  }

  return (
    <SidebarLayout
      logoLinkUrl={logoLinkUrl}
      renderMenu={renderMenu}
      showHamburgerMenu={!route?.layout?.noHamburgerMenu}
    >
      <Content>{content}</Content>
    </SidebarLayout>
  );
});
