/* eslint-disable react/no-multi-comp */
import { type ComponentProps } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { EMPTY_VALUE, Text } from '@npm/core/ui/components/atoms/Typography';
import {
  DATE_FORMATS,
  formatAsCompactNumber,
  formatDate,
} from '@npm/core/ui/utils/formatters';
import { type IssuerEntityAggregate } from '@npm/data-access';
import { type ColorGroup, type Variant } from 'styled-components';

import { useIsBrokerage } from '../../../auth/user/role';
import { type CompanyCard } from '../CompanyCard';

import * as S from './Content.styles';

type Props = {
  isSelected?: boolean;
  company?: IssuerEntityAggregate;
} & Pick<ComponentProps<typeof CompanyCard>, 'variant'>;

type LabelProps = {
  colorVariant: Variant;
  label?: string;
  denomination?: string;
};

type PriceProps = {
  value?: number;
  colorGroup?: ColorGroup;
  denomination?: string;
  compact?: boolean;
};

const Label = ({ colorVariant, label, denomination }: LabelProps) => (
  <Text size="xs" colorVariant={colorVariant}>
    {label}{' '}
    {denomination && (
      <S.Currency colorVariant={colorVariant}>{denomination}</S.Currency>
    )}
  </Text>
);

const Total = ({
  label = 'Total Size',
  colorVariant,
  value,
}: LabelProps & PriceProps) => (
  <S.Total>
    <Text size="xxs" colorVariant="tertiary">
      {label}
    </Text>
    <Text size="xxs" colorVariant={colorVariant}>
      {value ? formatAsCompactNumber(value) : EMPTY_VALUE}
    </Text>
  </S.Total>
);

export const Content = ({ isSelected, variant, company }: Props) => {
  const labelColorVariant = isSelected ? 'primary' : 'tertiary';
  const valueColorVariant = isSelected ? 'primary' : 'secondary';

  const { isBrokerageNotObo } = useIsBrokerage();

  if (
    !isBrokerageNotObo &&
    company.live_inventory_count === 0 &&
    company.hidden_inventory_count > 0
  ) {
    return <HiddenInventoryContent isSelected={isSelected} company={company} />;
  }

  const brokerLeftColumn = (
    <S.LeftCol>
      <Label colorVariant={labelColorVariant} label="Highest Bid" />
      <Text.Price
        value={company?.bid_price_high}
        currency={company?.denomination?.name}
        compact={company?.bid_price_high > 999}
        color="success"
        size="default"
      />
      <Total
        colorVariant={valueColorVariant}
        value={company?.bid_price_total}
      />
    </S.LeftCol>
  );

  const brokerRightColumn = (
    <S.RightCol>
      <Label colorVariant={labelColorVariant} label="Lowest Offer" />
      <Text.Price
        value={company?.ask_price_low}
        currency={company?.denomination?.name}
        compact={company?.ask_price_low > 999}
        color="error"
        size="default"
      />
      <Total
        colorVariant={valueColorVariant}
        value={company?.offer_price_total}
      />
    </S.RightCol>
  );

  const investorLeftColumn = (
    <S.LeftColCentered style={{ whiteSpace: 'nowrap' }}>
      <Label
        colorVariant={labelColorVariant}
        label="Last Round Est. Valuation"
      />
      <Text.Price
        value={company?.issuer_entity_statistic?.latest_valuation}
        currency={company?.denomination?.name}
        compact
        color="info"
        size="default"
      />
    </S.LeftColCentered>
  );

  const investorRightColumn = (
    <S.RightColCentered>
      <Label
        colorVariant={labelColorVariant}
        label="Last Round Price Per Share"
      />
      <Text.Price
        value={company?.issuer_entity_statistic?.last_round_pps}
        currency={company?.denomination?.name}
        color="info"
        size="default"
      />
    </S.RightColCentered>
  );

  return (
    <S.Container>
      {variant === 'broker' ? brokerLeftColumn : investorLeftColumn}
      <S.Divider />
      {variant === 'broker' ? brokerRightColumn : investorRightColumn}
    </S.Container>
  );
};

const HiddenInventoryContent = ({ isSelected, company }: Props) => {
  const labelColorVariant = isSelected ? 'primary' : 'tertiary';

  const leftColumn = (
    <S.LeftCol>
      <Flex align={'center'} justify={'flex-end'} gap={'xs'}>
        <Icon name={'eye-off'} size={'xxs'} colorVariant={'tertiary'} />
        <Label colorVariant={labelColorVariant} label="Hidden Bids" />
      </Flex>
      <Text.Quantity
        value={company?.hidden_bids_count}
        color="success"
        size="default"
      />
    </S.LeftCol>
  );

  const rightColumn = (
    <S.RightCol>
      <Flex align={'center'} gap={'xs'}>
        <Label colorVariant={labelColorVariant} label="Hidden Offers" />
        <Icon name={'eye-off'} size={'xxs'} colorVariant={'tertiary'} />
      </Flex>
      <Text.Quantity
        value={company?.hidden_offers_count}
        color="error"
        size="default"
      />
    </S.RightCol>
  );

  return (
    <S.HiddenInventoryContainer>
      <Flex justify={'center'} gap={'sm'}>
        {leftColumn}
        <S.Divider />
        {rightColumn}
      </Flex>
      <S.HiddenInventoryFooter>
        <Text size="xs" colorVariant="tertiary">
          Last order created at
        </Text>
        <Text size="xs" colorVariant="secondary">
          {company?.last_order_created_at_date
            ? formatDate(company.last_order_created_at_date, {
                dateFormat: DATE_FORMATS.DATE,
              })
            : EMPTY_VALUE}
        </Text>
      </S.HiddenInventoryFooter>
    </S.HiddenInventoryContainer>
  );
};
