import { useEffect, useState } from 'react';

import { Margin } from '@npm/core/ui/components/atoms/common';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type AccountsBeneficialOwnerShow } from '@npm/data-access';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  BoaFromGetStartedAction,
  type FromGetStartedFuncs,
} from '../BeneficialOwnership.types';
import { GovernmentIssuedIdDrawer } from '../Form/Drawers';

import { getColumns } from './OwnersTable.config';
import { RemoveOwnerConfirm } from './RemoveOwnerConfirm';

type Props = {
  owners: AccountsBeneficialOwnerShow[] | undefined;
  isLoading: boolean;
  accountId: number;
  readOnly?: boolean;
} & Pick<FromGetStartedFuncs, 'onGetStarted'>;

export const OwnersTable = ({
  owners,
  isLoading,
  accountId,
  readOnly = false,
  onGetStarted,
}: Props) => {
  const [ownerInfo, setOwnerInfo] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [params, setQueryParams] = useQueryParams({
    fromGetStarted: StringParam,
  });
  const { isMobile } = useBreakpoints();
  const [personId, setPersonId] = useState<number | null>(null);

  const openGovernmentIdDrawer = (id: number) => setPersonId(id);

  const removeOwner = (id: number, name: string) => setOwnerInfo({ id, name });

  const onAddId = () => setPersonId(null);

  useEffect(() => {
    if (
      params.fromGetStarted === BoaFromGetStartedAction.GovernmentIssuedId &&
      owners?.length !== 0
    ) {
      setPersonId(owners?.[0]?.id);
      setQueryParams({ fromGetStarted: undefined }, 'replaceIn');
    }
  }, [params.fromGetStarted]);

  return (
    <>
      {(owners?.length > 0 || isLoading) && (
        <Margin top="xl" bottom="lg">
          <Table<AccountsBeneficialOwnerShow>
            columns={getColumns(
              onGetStarted
                ? () => onGetStarted(BoaFromGetStartedAction.GovernmentIssuedId)
                : openGovernmentIdDrawer,
              removeOwner,
              isMobile,
              readOnly
            )}
            loading={isLoading}
            dataSource={owners}
          />
        </Margin>
      )}
      <GovernmentIssuedIdDrawer
        accountId={accountId}
        personId={personId}
        open={!!personId}
        onClose={() => setPersonId(null)}
        onCreate={onAddId}
      />
      <RemoveOwnerConfirm
        name={ownerInfo?.name}
        id={ownerInfo?.id}
        accountId={accountId}
        open={!!ownerInfo}
        onCancel={() => setOwnerInfo(null)}
      />
    </>
  );
};
