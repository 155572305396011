// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  BeneficialOwnershipAssessmentApi,
  AccountBeneficialOwnershipAssessmentShowErrorCodes,
  AccountBeneficialOwnershipAssessmentShowErrorTypes,
  AccountBeneficialOwnershipAssessmentUpdateErrorCodes,
  AccountBeneficialOwnershipAssessmentDestroyErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new BeneficialOwnershipAssessmentApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AccountBeneficialOwnershipAssessmentShowMethod = BeneficialOwnershipAssessmentApi['accountBeneficialOwnershipAssessmentShow'];
export type AccountBeneficialOwnershipAssessmentShowResponseType = MethodResult<AccountBeneficialOwnershipAssessmentShowMethod>;

export const useAccountBeneficialOwnershipAssessmentShow = (
  variables: Parameters<AccountBeneficialOwnershipAssessmentShowMethod>[0],
  config?: UseQueryConfig<
    AccountBeneficialOwnershipAssessmentShowMethod,
    AccountBeneficialOwnershipAssessmentShowErrorTypes
  >
) => {
  return useQuery<
    AccountBeneficialOwnershipAssessmentShowMethod,
    AccountBeneficialOwnershipAssessmentShowErrorTypes
  >(
    (...args) => api.accountBeneficialOwnershipAssessmentShow(...args),
    AccountBeneficialOwnershipAssessmentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBeneficialOwnershipAssessmentShow'],
        ...apiQueryConfig.AccountBeneficialOwnershipAssessmentShow,
        ...config?.queryConfig
      },
    },
    'AccountBeneficialOwnershipAssessmentShow',
  );
};

export const useAccountBeneficialOwnershipAssessmentShowInfinite = (
  variables: Parameters<AccountBeneficialOwnershipAssessmentShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    AccountBeneficialOwnershipAssessmentShowMethod,
    AccountBeneficialOwnershipAssessmentShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    AccountBeneficialOwnershipAssessmentShowMethod,
    AccountBeneficialOwnershipAssessmentShowErrorTypes
  >(
    (...args) => api.accountBeneficialOwnershipAssessmentShow(...args),
    AccountBeneficialOwnershipAssessmentShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AccountBeneficialOwnershipAssessmentShow', 'AccountBeneficialOwnershipAssessmentShowInfinite'],
        ...apiQueryConfig.AccountBeneficialOwnershipAssessmentShow,
        ...config?.queryConfig,
      },
    },
    'AccountBeneficialOwnershipAssessmentShow',
  );
};

export const useAccountBeneficialOwnershipAssessmentShowLazy = (baseOptions?: {
  variables?: Parameters<AccountBeneficialOwnershipAssessmentShowMethod>[0],
  config?: UseQueryConfig<
    AccountBeneficialOwnershipAssessmentShowMethod,
    AccountBeneficialOwnershipAssessmentShowErrorTypes
  >
}) => {
  return useLazyQuery<
    AccountBeneficialOwnershipAssessmentShowMethod,
    AccountBeneficialOwnershipAssessmentShowErrorTypes
  >(
    (...args) => api.accountBeneficialOwnershipAssessmentShow(...args),
    AccountBeneficialOwnershipAssessmentShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AccountBeneficialOwnershipAssessmentShow'],
        ...apiQueryConfig.AccountBeneficialOwnershipAssessmentShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AccountBeneficialOwnershipAssessmentShow',
  );
};


export type AccountBeneficialOwnershipAssessmentUpdateMethod = BeneficialOwnershipAssessmentApi['accountBeneficialOwnershipAssessmentUpdate'];

export const useAccountBeneficialOwnershipAssessmentUpdate = () => {
  return useMutation<AccountBeneficialOwnershipAssessmentUpdateMethod>(
    (...args) => api.accountBeneficialOwnershipAssessmentUpdate(...args),
    AccountBeneficialOwnershipAssessmentUpdateErrorCodes,
    'AccountBeneficialOwnershipAssessmentUpdate',
    '/api/accounts/{id}/beneficial-ownership-assessment',
    'put'
  );
};

export type AccountBeneficialOwnershipAssessmentDestroyMethod = BeneficialOwnershipAssessmentApi['accountBeneficialOwnershipAssessmentDestroy'];

export const useAccountBeneficialOwnershipAssessmentDestroy = () => {
  return useMutation<AccountBeneficialOwnershipAssessmentDestroyMethod>(
    (...args) => api.accountBeneficialOwnershipAssessmentDestroy(...args),
    AccountBeneficialOwnershipAssessmentDestroyErrorCodes,
    'AccountBeneficialOwnershipAssessmentDestroy',
    '/api/accounts/{id}/beneficial-ownership-assessment',
    'delete'
  );
};
