/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountsAtomicDocumentIndex } from '../model';
/**
 * AtomicDocumentsApi - axios parameter creator
 * @export
 */
export const AtomicDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary atomic documents show
         * @param {number} accountId 
         * @param {string} documentId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atomicDocumentShow: async (accountId: number, documentId: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('atomicDocumentShow', 'accountId', accountId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('atomicDocumentShow', 'documentId', documentId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/atomic-documents/{document_id}`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary atomic documents index
         * @param {number} accountId 
         * @param {string} [type] 
         * @param {string} [subType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [taxYear] 
         * @param {number} [limit] 
         * @param {string} [cursor] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atomicDocumentsIndex: async (accountId: number, type?: string, subType?: string, startDate?: string, endDate?: string, taxYear?: string, limit?: number, cursor?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('atomicDocumentsIndex', 'accountId', accountId)
            const localVarPath = changeUrl(`/api/accounts/{account_id}/atomic-documents`)
                .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (subType !== undefined) {
                localVarQueryParameter['sub_type'] = subType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (taxYear !== undefined) {
                localVarQueryParameter['tax_year'] = taxYear;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AtomicDocumentsApi - functional programming interface
 * @export
 */
export const AtomicDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AtomicDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary atomic documents show
         * @param {number} accountId 
         * @param {string} documentId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atomicDocumentShow(accountId: number, documentId: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atomicDocumentShow(accountId, documentId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary atomic documents index
         * @param {number} accountId 
         * @param {string} [type] 
         * @param {string} [subType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [taxYear] 
         * @param {number} [limit] 
         * @param {string} [cursor] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async atomicDocumentsIndex(accountId: number, type?: string, subType?: string, startDate?: string, endDate?: string, taxYear?: string, limit?: number, cursor?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsAtomicDocumentIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.atomicDocumentsIndex(accountId, type, subType, startDate, endDate, taxYear, limit, cursor, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AtomicDocumentsApi - factory interface
 * @export
 */
export const AtomicDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AtomicDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary atomic documents show
         * @param {number} accountId 
         * @param {string} documentId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atomicDocumentShow(accountId: number, documentId: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.atomicDocumentShow(accountId, documentId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary atomic documents index
         * @param {number} accountId 
         * @param {string} [type] 
         * @param {string} [subType] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [taxYear] 
         * @param {number} [limit] 
         * @param {string} [cursor] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atomicDocumentsIndex(accountId: number, type?: string, subType?: string, startDate?: string, endDate?: string, taxYear?: string, limit?: number, cursor?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<AccountsAtomicDocumentIndex> {
            return localVarFp.atomicDocumentsIndex(accountId, type, subType, startDate, endDate, taxYear, limit, cursor, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for atomicDocumentShow operation in AtomicDocumentsApi.
 * @export
 * @interface AtomicDocumentsApiAtomicDocumentShowRequest
 */
export interface AtomicDocumentsApiAtomicDocumentShowRequest {
    /**
     * 
     * @type {number}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for atomicDocumentsIndex operation in AtomicDocumentsApi.
 * @export
 * @interface AtomicDocumentsApiAtomicDocumentsIndexRequest
 */
export interface AtomicDocumentsApiAtomicDocumentsIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly accountId: number

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly subType?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly taxYear?: string

    /**
     * 
     * @type {number}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly cursor?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof AtomicDocumentsApiAtomicDocumentsIndex
     */
    readonly xUserRoleId?: string
}

/**
 * AtomicDocumentsApi - object-oriented interface
 * @export
 * @class AtomicDocumentsApi
 * @extends {BaseAPI}
 */
export class AtomicDocumentsApi extends BaseAPI {
    /**
     * 
     * @summary atomic documents show
     * @param {AtomicDocumentsApiAtomicDocumentShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtomicDocumentsApi
     */
    public atomicDocumentShow(requestParameters: AtomicDocumentsApiAtomicDocumentShowRequest, options?: AxiosRequestConfig) {
        return AtomicDocumentsApiFp(this.configuration).atomicDocumentShow(requestParameters.accountId, requestParameters.documentId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary atomic documents index
     * @param {AtomicDocumentsApiAtomicDocumentsIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtomicDocumentsApi
     */
    public atomicDocumentsIndex(requestParameters: AtomicDocumentsApiAtomicDocumentsIndexRequest, options?: AxiosRequestConfig) {
        return AtomicDocumentsApiFp(this.configuration).atomicDocumentsIndex(requestParameters.accountId, requestParameters.type, requestParameters.subType, requestParameters.startDate, requestParameters.endDate, requestParameters.taxYear, requestParameters.limit, requestParameters.cursor, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type AtomicDocumentShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AtomicDocumentShowErrorCodes = [
];

export type AtomicDocumentsIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const AtomicDocumentsIndexErrorCodes = [
    401,
];


