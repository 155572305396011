// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EventOrderItemsStatisticApi,
  EventOrderItemStatisticIndexErrorCodes,
  EventOrderItemStatisticIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EventOrderItemsStatisticApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EventOrderItemStatisticIndexMethod = EventOrderItemsStatisticApi['eventOrderItemStatisticIndex'];
export type EventOrderItemStatisticIndexResponseType = MethodResult<EventOrderItemStatisticIndexMethod>;

export const useEventOrderItemStatisticIndex = (
  variables: Parameters<EventOrderItemStatisticIndexMethod>[0],
  config?: UseQueryConfig<
    EventOrderItemStatisticIndexMethod,
    EventOrderItemStatisticIndexErrorTypes
  >
) => {
  return useQuery<
    EventOrderItemStatisticIndexMethod,
    EventOrderItemStatisticIndexErrorTypes
  >(
    (...args) => api.eventOrderItemStatisticIndex(...args),
    EventOrderItemStatisticIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventOrderItemStatisticIndex'],
        ...apiQueryConfig.EventOrderItemStatisticIndex,
        ...config?.queryConfig
      },
    },
    'EventOrderItemStatisticIndex',
  );
};

export const useEventOrderItemStatisticIndexInfinite = (
  variables: Parameters<EventOrderItemStatisticIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    EventOrderItemStatisticIndexMethod,
    EventOrderItemStatisticIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    EventOrderItemStatisticIndexMethod,
    EventOrderItemStatisticIndexErrorTypes
  >(
    (...args) => api.eventOrderItemStatisticIndex(...args),
    EventOrderItemStatisticIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EventOrderItemStatisticIndex', 'EventOrderItemStatisticIndexInfinite'],
        ...apiQueryConfig.EventOrderItemStatisticIndex,
        ...config?.queryConfig,
      },
    },
    'EventOrderItemStatisticIndex',
  );
};

export const useEventOrderItemStatisticIndexLazy = (baseOptions?: {
  variables?: Parameters<EventOrderItemStatisticIndexMethod>[0],
  config?: UseQueryConfig<
    EventOrderItemStatisticIndexMethod,
    EventOrderItemStatisticIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    EventOrderItemStatisticIndexMethod,
    EventOrderItemStatisticIndexErrorTypes
  >(
    (...args) => api.eventOrderItemStatisticIndex(...args),
    EventOrderItemStatisticIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EventOrderItemStatisticIndex'],
        ...apiQueryConfig.EventOrderItemStatisticIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EventOrderItemStatisticIndex',
  );
};

