import { CbLabel, CbLabelStyled } from '@npm/core/ui/components/atoms/Label';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { type HeaderProps } from '@npm/core/ui/components/molecules/Card/CardHeader';
import { CbHoldingState, type Holding } from '@npm/data-access';

import { HoldingCardHeaderLabel } from './HoldingCardHeaderLabel';

export const getHoldingCardHeaderConfigWithStateLabel: (
  holding: Holding,
  getStateTooltip?: (holding: Holding) => string | null
) => HeaderProps = (holding, getStateTooltip) => {
  return {
    title: (
      <HoldingCardHeaderLabel
        certificate_number={holding.certificate_number}
        asset={holding.asset}
      />
    ),
    label: (
      <Tooltip title={getStateTooltip?.(holding)}>
        {holding.state?.code ? (
          CbHoldingState.getLabel(CbLabel, holding.state, true)
        ) : (
          <CbLabelStyled $padding="xs" variant="general" label="Draft" />
        )}
      </Tooltip>
    ),
  };
};
