import React, { type ComponentProps, useMemo } from 'react';

import { Modal, useModal } from '@npm/core/ui/components/molecules/Modal';

import {
  DocumentViewerModal,
  useDocumentViewer,
} from '../../../documents/DocumentViewer';
import { HoldingsTable } from '../Table';

import * as S from './HoldingsTableGrouped.styles';
import { groupHoldingsByAssetType } from './HoldingsTableGrouped.utils';

type Props = Omit<
  ComponentProps<typeof HoldingsTable>,
  'pagination' | 'onChange'
>;

export const HoldingsTableGrouped = ({
  holdings: allHoldings,
  ...props
}: Props) => {
  const { documentViewerModalProps, showNpmDocument } = useDocumentViewer();
  const [openModal, modalProps] = useModal();

  const additionalProps: Pick<Props, 'onDocumentClick' | 'openModal'> = {
    onDocumentClick: showNpmDocument,
    openModal,
  };

  const groupedHoldings = useMemo(
    () => groupHoldingsByAssetType(allHoldings),
    [allHoldings]
  );

  return (
    <>
      <S.Layout>
        {Object.entries(groupedHoldings).map(([key, holdings]) => (
          <HoldingsTable
            {...props}
            {...additionalProps}
            holdings={holdings}
            assetType={holdings[0].asset.type}
            totalRecords={holdings.length}
            key={key}
          />
        ))}
      </S.Layout>
      <DocumentViewerModal {...documentViewerModalProps} />
      <Modal okText="Close" {...modalProps} />
    </>
  );
};
