import React from 'react';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { useTheme } from 'styled-components';

import { type SubscriptionPlan } from '../UnlockPremiumModal.config';

import * as S from './PlanButton.styles';

type Props = {
  isSelected: boolean;
  isCurrentPlan: boolean;
  onClick: () => void;
} & Pick<SubscriptionPlan, 'title' | 'description' | 'icon' | 'color'>;

export const PlanButton = ({
  title,
  description,
  icon,
  color,
  isSelected,
  isCurrentPlan,
  onClick,
}: Props) => {
  const theme = useTheme();

  return (
    <S.Button onClick={onClick} $isSelected={isSelected} $color={color}>
      <Flex gap="xs" align="center">
        <Icon
          name={icon}
          size="md"
          color={theme.color[color].typography.primary}
        />
        <Heading variant="h1" as="h4">
          {title}
        </Heading>
        {isCurrentPlan && (
          <Text
            size="default"
            colorVariant="muted"
            as="span"
            style={{ marginLeft: 'auto' }}
          >
            Current plan
          </Text>
        )}
      </Flex>
      <Margin top="sm">
        <Text size="default" colorVariant="secondary">
          {description}
        </Text>
      </Margin>
    </S.Button>
  );
};
