// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  EmploymentInformationApi,
  EmploymentInformationCreateErrorCodes,
  EmploymentInformationShowErrorCodes,
  EmploymentInformationShowErrorTypes,
  EmploymentInformationUpdateErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new EmploymentInformationApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type EmploymentInformationCreateMethod = EmploymentInformationApi['employmentInformationCreate'];

export const useEmploymentInformationCreate = () => {
  return useMutation<EmploymentInformationCreateMethod>(
    (...args) => api.employmentInformationCreate(...args),
    EmploymentInformationCreateErrorCodes,
    'EmploymentInformationCreate',
    '/api/employment-informations',
    'post'
  );
};

export type EmploymentInformationShowMethod = EmploymentInformationApi['employmentInformationShow'];
export type EmploymentInformationShowResponseType = MethodResult<EmploymentInformationShowMethod>;

export const useEmploymentInformationShow = (
  variables: Parameters<EmploymentInformationShowMethod>[0],
  config?: UseQueryConfig<
    EmploymentInformationShowMethod,
    EmploymentInformationShowErrorTypes
  >
) => {
  return useQuery<
    EmploymentInformationShowMethod,
    EmploymentInformationShowErrorTypes
  >(
    (...args) => api.employmentInformationShow(...args),
    EmploymentInformationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EmploymentInformationShow'],
        ...apiQueryConfig.EmploymentInformationShow,
        ...config?.queryConfig
      },
    },
    'EmploymentInformationShow',
  );
};

export const useEmploymentInformationShowInfinite = (
  variables: Parameters<EmploymentInformationShowMethod>[0],
  config?: UseInfiniteQueryConfig<
    EmploymentInformationShowMethod,
    EmploymentInformationShowErrorTypes
  >
) => {
  return useInfiniteQuery<
    EmploymentInformationShowMethod,
    EmploymentInformationShowErrorTypes
  >(
    (...args) => api.employmentInformationShow(...args),
    EmploymentInformationShowErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['EmploymentInformationShow', 'EmploymentInformationShowInfinite'],
        ...apiQueryConfig.EmploymentInformationShow,
        ...config?.queryConfig,
      },
    },
    'EmploymentInformationShow',
  );
};

export const useEmploymentInformationShowLazy = (baseOptions?: {
  variables?: Parameters<EmploymentInformationShowMethod>[0],
  config?: UseQueryConfig<
    EmploymentInformationShowMethod,
    EmploymentInformationShowErrorTypes
  >
}) => {
  return useLazyQuery<
    EmploymentInformationShowMethod,
    EmploymentInformationShowErrorTypes
  >(
    (...args) => api.employmentInformationShow(...args),
    EmploymentInformationShowErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['EmploymentInformationShow'],
        ...apiQueryConfig.EmploymentInformationShow,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'EmploymentInformationShow',
  );
};


export type EmploymentInformationUpdateMethod = EmploymentInformationApi['employmentInformationUpdate'];

export const useEmploymentInformationUpdate = () => {
  return useMutation<EmploymentInformationUpdateMethod>(
    (...args) => api.employmentInformationUpdate(...args),
    EmploymentInformationUpdateErrorCodes,
    'EmploymentInformationUpdate',
    '/api/employment-informations/{id}',
    'put'
  );
};
