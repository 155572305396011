// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  OcfValuationsApi,
  InternalIndexAllOcfValuationErrorCodes,
  InternalIndexAllOcfValuationErrorTypes,
  InternalIndexOcfValuationErrorCodes,
  InternalIndexOcfValuationErrorTypes,
  InternalShowOcfValuationErrorCodes,
  InternalShowOcfValuationErrorTypes,
  InternalDestroyOcfValuationErrorCodes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new OcfValuationsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type InternalIndexAllOcfValuationMethod = OcfValuationsApi['internalIndexAllOcfValuation'];
export type InternalIndexAllOcfValuationResponseType = MethodResult<InternalIndexAllOcfValuationMethod>;

export const useInternalIndexAllOcfValuation = (
  variables: Parameters<InternalIndexAllOcfValuationMethod>[0],
  config?: UseQueryConfig<
    InternalIndexAllOcfValuationMethod,
    InternalIndexAllOcfValuationErrorTypes
  >
) => {
  return useQuery<
    InternalIndexAllOcfValuationMethod,
    InternalIndexAllOcfValuationErrorTypes
  >(
    (...args) => api.internalIndexAllOcfValuation(...args),
    InternalIndexAllOcfValuationErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIndexAllOcfValuation'],
        ...apiQueryConfig.InternalIndexAllOcfValuation,
        ...config?.queryConfig
      },
    },
    'InternalIndexAllOcfValuation',
  );
};

export const useInternalIndexAllOcfValuationInfinite = (
  variables: Parameters<InternalIndexAllOcfValuationMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalIndexAllOcfValuationMethod,
    InternalIndexAllOcfValuationErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalIndexAllOcfValuationMethod,
    InternalIndexAllOcfValuationErrorTypes
  >(
    (...args) => api.internalIndexAllOcfValuation(...args),
    InternalIndexAllOcfValuationErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIndexAllOcfValuation', 'InternalIndexAllOcfValuationInfinite'],
        ...apiQueryConfig.InternalIndexAllOcfValuation,
        ...config?.queryConfig,
      },
    },
    'InternalIndexAllOcfValuation',
  );
};

export const useInternalIndexAllOcfValuationLazy = (baseOptions?: {
  variables?: Parameters<InternalIndexAllOcfValuationMethod>[0],
  config?: UseQueryConfig<
    InternalIndexAllOcfValuationMethod,
    InternalIndexAllOcfValuationErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalIndexAllOcfValuationMethod,
    InternalIndexAllOcfValuationErrorTypes
  >(
    (...args) => api.internalIndexAllOcfValuation(...args),
    InternalIndexAllOcfValuationErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalIndexAllOcfValuation'],
        ...apiQueryConfig.InternalIndexAllOcfValuation,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalIndexAllOcfValuation',
  );
};


export type InternalIndexOcfValuationMethod = OcfValuationsApi['internalIndexOcfValuation'];
export type InternalIndexOcfValuationResponseType = MethodResult<InternalIndexOcfValuationMethod>;

export const useInternalIndexOcfValuation = (
  variables: Parameters<InternalIndexOcfValuationMethod>[0],
  config?: UseQueryConfig<
    InternalIndexOcfValuationMethod,
    InternalIndexOcfValuationErrorTypes
  >
) => {
  return useQuery<
    InternalIndexOcfValuationMethod,
    InternalIndexOcfValuationErrorTypes
  >(
    (...args) => api.internalIndexOcfValuation(...args),
    InternalIndexOcfValuationErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIndexOcfValuation'],
        ...apiQueryConfig.InternalIndexOcfValuation,
        ...config?.queryConfig
      },
    },
    'InternalIndexOcfValuation',
  );
};

export const useInternalIndexOcfValuationInfinite = (
  variables: Parameters<InternalIndexOcfValuationMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalIndexOcfValuationMethod,
    InternalIndexOcfValuationErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalIndexOcfValuationMethod,
    InternalIndexOcfValuationErrorTypes
  >(
    (...args) => api.internalIndexOcfValuation(...args),
    InternalIndexOcfValuationErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalIndexOcfValuation', 'InternalIndexOcfValuationInfinite'],
        ...apiQueryConfig.InternalIndexOcfValuation,
        ...config?.queryConfig,
      },
    },
    'InternalIndexOcfValuation',
  );
};

export const useInternalIndexOcfValuationLazy = (baseOptions?: {
  variables?: Parameters<InternalIndexOcfValuationMethod>[0],
  config?: UseQueryConfig<
    InternalIndexOcfValuationMethod,
    InternalIndexOcfValuationErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalIndexOcfValuationMethod,
    InternalIndexOcfValuationErrorTypes
  >(
    (...args) => api.internalIndexOcfValuation(...args),
    InternalIndexOcfValuationErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalIndexOcfValuation'],
        ...apiQueryConfig.InternalIndexOcfValuation,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalIndexOcfValuation',
  );
};


export type InternalShowOcfValuationMethod = OcfValuationsApi['internalShowOcfValuation'];
export type InternalShowOcfValuationResponseType = MethodResult<InternalShowOcfValuationMethod>;

export const useInternalShowOcfValuation = (
  variables: Parameters<InternalShowOcfValuationMethod>[0],
  config?: UseQueryConfig<
    InternalShowOcfValuationMethod,
    InternalShowOcfValuationErrorTypes
  >
) => {
  return useQuery<
    InternalShowOcfValuationMethod,
    InternalShowOcfValuationErrorTypes
  >(
    (...args) => api.internalShowOcfValuation(...args),
    InternalShowOcfValuationErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowOcfValuation'],
        ...apiQueryConfig.InternalShowOcfValuation,
        ...config?.queryConfig
      },
    },
    'InternalShowOcfValuation',
  );
};

export const useInternalShowOcfValuationInfinite = (
  variables: Parameters<InternalShowOcfValuationMethod>[0],
  config?: UseInfiniteQueryConfig<
    InternalShowOcfValuationMethod,
    InternalShowOcfValuationErrorTypes
  >
) => {
  return useInfiniteQuery<
    InternalShowOcfValuationMethod,
    InternalShowOcfValuationErrorTypes
  >(
    (...args) => api.internalShowOcfValuation(...args),
    InternalShowOcfValuationErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['InternalShowOcfValuation', 'InternalShowOcfValuationInfinite'],
        ...apiQueryConfig.InternalShowOcfValuation,
        ...config?.queryConfig,
      },
    },
    'InternalShowOcfValuation',
  );
};

export const useInternalShowOcfValuationLazy = (baseOptions?: {
  variables?: Parameters<InternalShowOcfValuationMethod>[0],
  config?: UseQueryConfig<
    InternalShowOcfValuationMethod,
    InternalShowOcfValuationErrorTypes
  >
}) => {
  return useLazyQuery<
    InternalShowOcfValuationMethod,
    InternalShowOcfValuationErrorTypes
  >(
    (...args) => api.internalShowOcfValuation(...args),
    InternalShowOcfValuationErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['InternalShowOcfValuation'],
        ...apiQueryConfig.InternalShowOcfValuation,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'InternalShowOcfValuation',
  );
};


export type InternalDestroyOcfValuationMethod = OcfValuationsApi['internalDestroyOcfValuation'];

export const useInternalDestroyOcfValuation = () => {
  return useMutation<InternalDestroyOcfValuationMethod>(
    (...args) => api.internalDestroyOcfValuation(...args),
    InternalDestroyOcfValuationErrorCodes,
    'InternalDestroyOcfValuation',
    '/api/internal/ocf_valuations/{id}',
    'delete'
  );
};
