import type { ComponentProps } from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';

import type { OrderValuationType } from '../order.types';

import * as S from './OrderValuationToggle.styles';

type Props = {
  valuationType: OrderValuationType;
  toggleValuationType: () => void;
  disabled?: boolean;
  textProps?: Pick<
    ComponentProps<typeof Text>,
    'color' | 'colorVariant' | 'size'
  >;
  isLoading?: boolean;
  hidden?: boolean;
};

export const OrderValuationToggle = ({
  valuationType,
  toggleValuationType,
  disabled = false,
  textProps = { color: 'info', colorVariant: 'primary', size: 'sm' },
  isLoading,
  hidden,
}: Props) => {
  if (hidden && !isLoading) return null;

  return (
    <Flex justify="flex-end" style={{ marginBottom: '4px' }}>
      {isLoading ? (
        <Skeleton.Input size="small" />
      ) : (
        <S.ToggleButton
          variant="text"
          onClick={() => toggleValuationType()}
          disabled={disabled}
          icon={<Icon name="arrows-exchange-horizontal" size="md" />}
          size="sm"
        >
          <Text
            {...textProps}
            {...(disabled
              ? { color: 'general', colorVariant: 'muted' }
              : undefined)}
          >
            {valuationType}
          </Text>
        </S.ToggleButton>
      )}
    </Flex>
  );
};
