import { useMemo } from 'react';

import { getPageSize, Table } from '@npm/core/ui/components/molecules/Table';
import { usePagination } from '@npm/core/ui/hooks/pagination/usePagination';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { removeEmptyValues } from '@npm/core/ui/utils/object';
import { VenusApi } from '@npm/data-access';
import { useTheme } from 'styled-components';

import { UnlockPremiumContextProvider } from '../../auth/user/components';
import { FILTER_QUERY_KEY, usePersistedFilters } from '../../filters';

import { type CompaniesDataFilterType } from './CompaniesDataFilter/CompaniesDataFilter.types';

import { CompaniesDataFilter } from './CompaniesDataFilter';
import { CompaniesDataSorting } from './CompaniesDataSorting';
import { getConfig } from './CompaniesDataTable.config';
import { FiltersRow } from './CompaniesDataTable.styles';

const { useCompaniesOverviews, useGicsSectors, useMotifs } = VenusApi;

export const CompaniesDataTable = () => {
  const breakpoints = useBreakpoints();
  const theme = useTheme();

  const [
    {
      variables: { sectorIds, motifIds, ...variables },
      setVariables,
    },
    filterPanelProps,
  ] = usePersistedFilters<CompaniesDataFilterType>(
    FILTER_QUERY_KEY.companiesOveriview,
    {
      page: 1,
      size: getPageSize(),
    }
  );

  const sectorQuery = useGicsSectors({});
  const motifQuery = useMotifs({});

  const motifIdsVariable = useMemo(() => {
    const ids = new Set([...(motifIds ?? [])]);

    motifQuery?.data?.motifs?.forEach(motif => {
      if (sectorIds?.includes(motif.gics_sector_id)) {
        ids.add(motif.id);
      }
    });

    return [...ids];
  }, [motifIds, motifQuery?.data?.motifs, sectorIds]);

  const { data, isLoading } = useCompaniesOverviews(
    removeEmptyValues(
      {
        ...variables,
        motifIds: motifIdsVariable,
      },
      {
        removeEmptyArray: true,
      }
    ) || {}
  );

  const { updatePaginationVariables, pagination } = usePagination(
    'companiesOverviews',
    {
      variables,
      setVariables,
      paginationData: data?.pagination,
    }
  );

  const filters = (
    <CompaniesDataFilter
      {...filterPanelProps}
      totalRecords={pagination.total}
      sectorQuery={sectorQuery}
      motifQuery={motifQuery}
    />
  );

  const sorting = (
    <CompaniesDataSorting
      sort={{ sortBy: variables?.sortBy, sortDirection: variables?.sortDir }}
      onChange={({ sortBy, sortDirection }) => {
        filterPanelProps.handleSubmit({
          sortBy,
          sortDir: sortDirection,
        });
      }}
    />
  );

  return (
    <UnlockPremiumContextProvider>
      <>
        <FiltersRow>
          {filters}
          {sorting}
        </FiltersRow>
        <Table
          headerVerticalAlign="middle"
          outlined={false}
          variant="compact"
          columns={getConfig({ breakpoints, theme })}
          dataSource={data?.companies_overviews || []}
          loading={isLoading}
          pagination={pagination}
          onChange={updatePaginationVariables}
          rowKey="company_id"
          expandable={{
            showExpandColumn: breakpoints.isMobile,
            rowExpandable: () => breakpoints.isMobile,
          }}
        />
      </>
    </UnlockPremiumContextProvider>
  );
};
