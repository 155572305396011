import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton';
import { useBreakpoint } from '@npm/core/ui/utils/style';
import styled from 'styled-components';

export const UserPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.sm}px ${theme.spacing.md}px`};
  padding-bottom: 0;
  min-height: 70px;
`;

export const NameWrapper = styled.div`
  margin: ${({ theme }) =>
    `0 ${theme.spacing.md}px 0 ${theme.spacing.lg / 2}px`};
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    color: ${({ theme }) => theme.color.general.typography.primary};
    margin-bottom: 0;
  }

  ${({ theme }) => useBreakpoint(theme).tablet`
    margin: 0;
  `}
`;

export const StyledSkeleton = styled(Skeleton.Base)`
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 12px;
    width: 85% !important;

    & + li {
      margin-top: ${({ theme }) => theme.spacing.sm}px;
    }
  }
`;
