import { ValueVisibilityToggler } from '@npm/core/ui/components/atoms/ValueVisibilityToggler';
import { CbBankAccountType, type PaymentInformation } from '@npm/data-access';

import * as S from './ReadOnly.styles';

export const prepareReadOnlyData = ({
  bank_account,
  type,
}: PaymentInformation = {}) => {
  const routingType = bank_account?.intermediary_routing_type;

  const isInternationalBankType =
    bank_account?.type.code === CbBankAccountType.items.INTERNATIONAL_BANK;
  const isBrokerageAccountType =
    bank_account?.type.code === CbBankAccountType.items.BROKERAGE_ACCOUNT;

  return [
    {
      label: 'Type',
      value: type?.name,
      displayRow: !!type?.name,
    },
    {
      label: 'Bank Type',
      value: bank_account?.type?.name,
    },
    {
      label: 'Do you require an Intermediary / Correspondent Bank?',
      value: bank_account?.require_intermediary_bank ? 'Yes' : 'No',
      displayRow: isInternationalBankType,
    },
    {
      label:
        routingType?.code === 'aba-routing'
          ? 'Intermediary Bank Information - ABA Routing Number'
          : 'Intermediary Bank Information - SWIFT Code',
      value: (
        <S.IdToggleWrap>
          <ValueVisibilityToggler
            value={bank_account?.intermediary_routing_number}
            mask="*"
            visibleCharsNumber={4}
          />
        </S.IdToggleWrap>
      ),
      displayRow:
        isInternationalBankType && bank_account?.require_intermediary_bank,
    },
    {
      label: 'Intermediary Bank Information - Bank Name',
      value: bank_account?.intermediary_bank_name,
      displayRow:
        isInternationalBankType && bank_account?.require_intermediary_bank,
    },
    {
      label: 'SWIFT/BIC Code',
      key: 'swift_code',
      value: bank_account?.swift_code,
      displayRow: isInternationalBankType,
    },
    {
      label: 'ABA Routing Number',
      value: bank_account?.aba_number,
      displayRow: !isInternationalBankType,
    },
    {
      label: 'Bank Name',
      value: bank_account?.name,
      displayRow: !isBrokerageAccountType,
    },
    {
      label: `${
        isBrokerageAccountType ? 'Brokerage Account Instructions - ' : ''
      } Name on Account`,
      value: bank_account?.beneficiary_name,
    },
    {
      label: 'Brokerage Name',
      value: bank_account?.brokerage_name,
      displayRow: isBrokerageAccountType,
    },
    {
      label: 'Brokerage Account Instructions - Brokerage Account Number',
      value: (
        <S.IdToggleWrap>
          <ValueVisibilityToggler
            value={bank_account?.account_number}
            mask="*"
            visibleCharsNumber={4}
            maskAnchor="start"
          />
        </S.IdToggleWrap>
      ),
      displayRow: isBrokerageAccountType,
    },
    {
      label: bank_account?.iban ? 'IBAN' : 'Bank Account Number',
      value: (
        <S.IdToggleWrap>
          <ValueVisibilityToggler
            value={bank_account?.account_number}
            mask="*"
            visibleCharsNumber={4}
            maskAnchor="start"
          />
        </S.IdToggleWrap>
      ),
      displayRow: !isBrokerageAccountType,
    },
    {
      label: 'Additional Instructions (Optional)',
      value: bank_account?.additional_instructions,
    },
  ];
};
