import React, { type ComponentProps, type FC } from 'react';

import { type Card } from '@npm/core/ui/components/molecules/Card';
import { TableVertical } from '@npm/core/ui/components/molecules/TableVertical';
import { CypressDataIds } from '@npm/core/ui/constants';
import { getDisabledActionTooltipText } from '@npm/core/ui/utils/getDisabledActionTooltipText';

import { StatusChangeButtons } from '../components';
import { getDisabledHoldingActionsTooltip } from '../Holdings.utils';

import * as S from './SecondMarketHoldingCard.styles';
import { ActionButtons } from './ActionButtons';
import { type HoldingCard } from './HoldingCard';
import { HoldingCardHeaderLabel } from './HoldingCardHeaderLabel';
import { getHoldingRows } from './SecondMarketHoldingCard.rows';

export const NUMBER_OF_ROWS_TO_SHOW = 3;

type Props = ComponentProps<typeof HoldingCard> &
  Pick<ComponentProps<typeof Card>, 'variant'> &
  Pick<
    ComponentProps<typeof HoldingCardHeaderLabel>,
    'labelVariant' | 'idMaxLength'
  >;

export const SecondMarketHoldingCard: FC<Props> = ({
  isLoading,
  holding,
  header = true,
  footer,
  config = {},
  openModal,
  onDocumentClick,
  notAuthorized,
  className,
  variant,
  labelVariant,
  idMaxLength = 36,
}) => {
  const {
    onDeleteClick,
    showRemainingQuantity = true,
    showQuantity = true,
    showVestedQuantity = true,
    showOfferPrice,
    showClass = true,
    showAccount = false,
    showVerifiedOwnership = true,
    showProofOfOwnership = true,
    showAsset = false,
    onEditClick,
    onStatusChangeButtonClick,
    showIssuance,
    numberOfRowsToShow = NUMBER_OF_ROWS_TO_SHOW,
  } = config;

  const isEditable = onEditClick && onDeleteClick;

  return (
    <S.Card
      outlined
      isLoading={isLoading}
      // `header === true` -> show holding labels, which are part of the vertical table
      header={
        header === true || !header
          ? null
          : typeof header === 'function'
            ? header(holding)
            : header
      }
      footer={
        (footer || isEditable || onStatusChangeButtonClick) && (
          <>
            {footer}
            {isEditable && (
              <ActionButtons
                holding={holding}
                onEditClick={() => onEditClick(holding?.id, holding)}
                onDeleteClick={() => onDeleteClick(holding)}
                disabledTooltip={
                  getDisabledActionTooltipText(notAuthorized) ||
                  getDisabledHoldingActionsTooltip(holding)
                }
              />
            )}
            {onStatusChangeButtonClick && (
              <StatusChangeButtons
                onClick={onStatusChangeButtonClick}
                holding={holding}
              />
            )}
          </>
        )
      }
      className={className}
      variant={variant}
      data-cy={CypressDataIds.Holdings.Cards.Card}
    >
      <TableVertical
        rows={[
          ...(header === true
            ? [
                {
                  title: (
                    <HoldingCardHeaderLabel
                      holding={holding}
                      asset={holding?.asset}
                      certificate_number={
                        !holding?.aggregated &&
                        (holding?.certificate_number || 'N/A')
                      }
                      wrap={false}
                      idMaxLength={idMaxLength}
                      labelVariant={labelVariant}
                    />
                  ),
                  render: () => <span>&nbsp;</span>,
                },
              ]
            : []),
          ...getHoldingRows({
            onDocumentClick,
            config: {
              showOfferPrice,
              showAccount,
              showClass,
              showVerifiedOwnership,
              showRemainingQuantity,
              showProofOfOwnership,
              onStatusChangeButtonClick,
              showIssuance,
              showAsset,
              showVestedQuantity,
              showQuantity,
            },
            openModal,
            visibleRowCount: numberOfRowsToShow + (header === true ? 1 : 0),
          }),
        ]}
        data={holding}
      />
    </S.Card>
  );
};
