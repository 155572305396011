import { type ComponentProps, useEffect, useState } from 'react';

import { Notification } from '@npm/core/ui/components/atoms/Notification';
import {
  type IssuerEntityAggregate,
  useWatchlistCreate,
  useWatchlistDestroy,
} from '@npm/data-access';
import { useQueryClient } from '@tanstack/react-query';

import { useCurrentAccount, useObo } from '../../../auth/user/role';
import { updateWatchlistCache } from '../../utils/';

import { type WatchlistButton } from './WatchlistButton';

type Params = {
  company: IssuerEntityAggregate;
  watchlistAccountId?: number;
  onClick?: (company: IssuerEntityAggregate) => void | null;
};

type WatchlistHookType = Pick<
  ComponentProps<typeof WatchlistButton>,
  'isWatched' | 'loading'
> & {
  onClick: () => Promise<void>;
};

export const useWatchlistButton = ({
  company,
  watchlistAccountId,
  onClick,
}: Params): WatchlistHookType => {
  const { accountId } = useCurrentAccount();
  const { isObo } = useObo();

  // using local state after mutations, as invalidating whole list is a nope
  const [isWatched, setIsWatched] = useState(company?.watchlist);

  const { execute: addToWatchlist, isLoading: isAddingToWatchlist } =
    useWatchlistCreate();

  const { execute: removeFromWatchlist, isLoading: isRemovingFromWatchlist } =
    useWatchlistDestroy();

  const queryClient = useQueryClient();

  const isLoading = isAddingToWatchlist || isRemovingFromWatchlist;

  const handleWatchlistButtonClick = async () => {
    if (onClick === null || isLoading) return;

    // to override default behaviour
    if (onClick) {
      setIsWatched(v => {
        updateWatchlistCache(!v, company.id, queryClient);
        return !v;
      });
      void onClick(company);
      return;
    }

    try {
      if (isWatched) {
        await removeFromWatchlist({
          issuerEntityId: company.id,
          watchlistAccountId: isObo ? accountId : watchlistAccountId,
        });
        Notification.success({
          message: `${company.name} was removed from your watchlist.`,
        });
      } else {
        await addToWatchlist({
          watchlistCreateRequestContract: {
            account_id: isObo ? accountId : watchlistAccountId,
            issuer_entity_id: [company.id],
          },
        });
        Notification.success({
          message: `${company.name} was added to your watchlist.`,
        });
      }

      setIsWatched(v => {
        updateWatchlistCache(!v, company.id, queryClient);
        return !v;
      });
    } catch (e) {
      Notification.error({
        message:
          'Something went wrong while updating watchlist, please try again.',
      });
      console.error(e);
    }
  };

  useEffect(() => {
    // when refetched by action elsewhere (e.g. in select drawer on top of company detail)
    setIsWatched(company?.watchlist);
  }, [company?.watchlist]);

  return {
    onClick: handleWatchlistButtonClick,
    loading: isLoading,
    isWatched,
  };
};
