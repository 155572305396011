import React, { useCallback } from 'react';

import { Checkbox, type FormInstance, Radio } from 'antd';
import { type Rule } from 'antd/lib/form';

import { ButtonCard, type TooltipProps } from '../../atoms/ButtonCard';
import { Grid } from '../../atoms/Grid';

import * as S from './ButtonCardQuestion.styles';

type OptionType = {
  name: string;
  label: string;
  value?: unknown;
  description?: string;
  icon?: JSX.Element;
  tooltip?: TooltipProps;
  disabled?: boolean;
};

type CustomItemType = {
  name: string;
  render: () => JSX.Element;
};

type OptionItemType = OptionType | CustomItemType;

type Props = {
  name: string;
  options: OptionItemType[];
  columns: number;
  onQuestionAnswered: () => void;
  isDisabled?: boolean;
  form: FormInstance;
  variant: 'radio' | 'checkbox';
  rules?: Rule[];
  isFormDisabled?: boolean;
  isQuestionLast?: boolean;
  testId?: string;
};

export const ButtonCardQuestion = ({
  name,
  onQuestionAnswered,
  options,
  rules,
  columns = 1,
  variant,
  isDisabled,
  isFormDisabled = false,
  isQuestionLast,
  testId,
}: Props) => {
  const Group = variant === 'radio' ? Radio.Group : Checkbox.Group;

  const renderOptionItem = useCallback(
    (option: OptionItemType) => {
      if ((option as CustomItemType).render) {
        return (option as CustomItemType).render();
      }

      const { name, value, icon, label, tooltip, description, disabled } =
        option as OptionType;

      return (
        <ButtonCard
          key={name}
          value={value ?? name}
          data-cy={name}
          {...{
            icon: icon,
            label: label,
            tooltip: tooltip,
            variant: variant,
            description: description,
            disabled: isFormDisabled || disabled || isDisabled,
          }}
        />
      );
    },
    [variant, isDisabled, isFormDisabled]
  );

  return (
    <S.Wrapper>
      <S.FormItem name={name} rules={rules} data-cy={testId || name}>
        <Group
          onChange={() => {
            variant === 'radio' && onQuestionAnswered();
          }}
          style={{ width: '100%' }}
        >
          <S.Grid $cols={columns} $rows={Math.ceil(options.length / columns)}>
            {options.map(option => {
              return (
                <Grid.GridItem key={option.name}>
                  {renderOptionItem(option)}
                </Grid.GridItem>
              );
            })}
          </S.Grid>
        </Group>
      </S.FormItem>
      {variant === 'checkbox' && !isQuestionLast && (
        <S.NextButton variant="outline" onClick={onQuestionAnswered}>
          Next
        </S.NextButton>
      )}
    </S.Wrapper>
  );
};
