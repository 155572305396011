import React from 'react';

import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { type MenuItemGroup } from '@npm/core/ui/components/organisms/Layout';
import { RBAC } from '@npm/core/ui/models/permissions';
import { type ActionCounter } from '@npm/data-access';
import {
  filterMenuItems,
  type UserContextStore,
} from '@npm/features/auth/user/context';
import type { UserRoleContextType } from '@npm/features/auth/user/role/userRole.types';

export const workstationPrefix = 'issuer-workstation';

const { isIssMenuAgreements, isIssMenuOther, isIssMenuPrograms } = RBAC.issuer;

export const getIssuerSidebarItems: (
  userContext: UserContextStore,
  userRole: UserRoleContextType | null,
  counterData: ActionCounter | undefined,
  isCollapsed: boolean
) => MenuItemGroup[] = (userContext, userRole, counterData, isCollapsed) =>
  filterMenuItems(
    [
      {
        label: 'Dashboard',
        key: `/${workstationPrefix}/general/dashboard`,
        permissionsChecks: [isIssMenuOther],
        icon: <Icon name="layout-dashboard" />,
      },
      {
        label: 'Accounts',
        key: `/${workstationPrefix}/general/accounts`,
        permissionsChecks: [isIssMenuOther],
        icon: <Icon name="user" />,
      },
      {
        label: 'General',
        key: 'general',
        icon: <Icon name="building-bank" size="xs" />,
        children: [
          {
            label: 'Unverified Holdings',
            key: `/${workstationPrefix}/marketplace/unverified-holdings`,
            permissionsChecks: [isIssMenuOther],
            actionItemsCount: counterData?.unverified_holdings,
          },
          {
            label: 'Verified Holdings',
            key: `/${workstationPrefix}/marketplace/holdings`,
            permissionsChecks: [isIssMenuOther],
          },
          {
            label: 'Matches',
            key: `/${workstationPrefix}/marketplace/matches`,
            permissionsChecks: [isIssMenuOther],
            actionItemsCount: counterData?.rofr_matches,
          },
          {
            label: 'Agreements',
            key: `/${workstationPrefix}/marketplace/agreements`,
            permissionsChecks: [isIssMenuAgreements],
            actionItemsCount: counterData?.unsigned_agreements,
          },
        ],
      },
      {
        label: 'Programs',
        key: 'programs',
        icon: <Icon name="table" />,
        children: [
          {
            label: 'Upcoming Programs',
            key: `/${workstationPrefix}/programs/upcoming-programs`,
            permissionsChecks: [isIssMenuPrograms],
            actionItemsCount: counterData?.upcoming_programs,
          },
          {
            label: 'Active Programs',
            key: `/${workstationPrefix}/programs/active-programs`,
            permissionsChecks: [isIssMenuPrograms],
            actionItemsCount: counterData?.active_programs,
          },
          {
            label: 'Expired Programs',
            key: `/${workstationPrefix}/programs/expired-programs`,
            permissionsChecks: [isIssMenuPrograms],
          },
        ],
      },
    ],
    userContext,
    userRole,
    null
  );
