import { Flex } from '@npm/core/ui/components/atoms/common';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
`;

export const OrderedList = styled.ol`
  padding-inline-start: ${({ theme }) => theme.spacing.md}px;
  margin-bottom: 0;
`;
