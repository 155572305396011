import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useAlerts } from '@npm/core/ui/components/molecules/AlertContainer';
import {
  type ApiError,
  processApiError,
} from '@npm/core/ui/services/errors/defaultErrorHandler';
import {
  type AccountShowAggregate,
  type AffiliateDisclosureType,
  type ATSPoolCode,
  type BrokerageFirmAggregate,
  CbAccountSponsorshipType,
  CbAccountType,
  CbAccreditationState,
  CbAffiliateDisclosure,
  CbATSPool,
  CbRoleType,
  CbVisibility,
  type VisibilityCode,
} from '@npm/data-access';

import { ZendeskContactButton } from '../../../app/zendesk';
import { usePermissions } from '../../../auth/permissions/usePermissions';
import { useUserContextStore } from '../../../auth/user/context';
import {
  useIsInvestor,
  useObo,
  useSponsorshipLevel,
} from '../../../auth/user/role';

export const isAccountGuestRole = (
  selectedAccount: AccountShowAggregate,
  userEmail: string
) => {
  const currentUser = selectedAccount?.users?.find(u => u.email === userEmail);

  return !!currentUser?.roles?.find(
    r =>
      r.code === CbRoleType.items.ACCOUNT_GUEST ||
      r.code === CbRoleType.items.GUEST_AND_SIGNER
  );
};

export const isAccountNotAccredited = (
  selectedAccount: AccountShowAggregate
) => {
  const isAnonymous =
    selectedAccount?.type?.code === CbAccountType.items.Anonymous;

  if (!selectedAccount || isAnonymous) return false;

  const isAccreditedAccount =
    selectedAccount?.accreditation_state?.code ===
    CbAccreditationState.items.passed;

  return !isAccreditedAccount;
};

export const canSelectATSPool = (
  visibility: VisibilityCode,
  brokerageFirm: Pick<BrokerageFirmAggregate, 'access_ats_i'>
) => {
  return (
    visibility !== CbVisibility.items.internal && !!brokerageFirm?.access_ats_i
  );
};

export const useOrderVisibilityPermissions = (
  account?: AccountShowAggregate
) => {
  const { isSponsorshipLevel3 } = useSponsorshipLevel();
  const isInvestor = useIsInvestor();

  const isEntityNpmsInvestor = useUserContextStore(
    store => store.isEntityNpmsInvestor
  );

  const { BRO_CHANGE_ORDER_VISIBILITY } = usePermissions();

  // allowed for BRO and LVL3 Entities
  const isVisibilityEditAllowed =
    !isInvestor ||
    (isEntityNpmsInvestor &&
      CbAccountSponsorshipType.isSponsoredAccountLevel3(
        account?.sponsorship_type
      ));
  // allowed for BRO with special permissions and LVL3
  const isAllowedToExternalize =
    BRO_CHANGE_ORDER_VISIBILITY || (isInvestor && isSponsorshipLevel3);

  return {
    isVisibilityEditAllowed,
    isAllowedToExternalize,
  };
};

export const useShowOrderTooltips = () => {
  const { isObo } = useObo();
  const isEntityNpmsInvestor = useUserContextStore(
    store => store.isEntityNpmsInvestor
  );
  const { isSponsorshipLevel3 } = useSponsorshipLevel();

  return isObo || (isEntityNpmsInvestor && isSponsorshipLevel3);
};

export const useATSPoolPermissions = () => {
  const isInvestor = useIsInvestor();
  // ATS pool change is relevant only for external orders with access to ats_i
  // and allowed only for BRO
  const getIsAtsPoolEditAllowed = (
    visibility: VisibilityCode,
    brokerageFirm: Pick<BrokerageFirmAggregate, 'access_ats_i'>
  ) => !isInvestor && canSelectATSPool(visibility, brokerageFirm);

  return {
    getIsAtsPoolEditAllowed,
  };
};

export const getATSPoolValue = (
  visibility: VisibilityCode,
  affiliateDisclosure?: AffiliateDisclosureType
): ATSPoolCode => {
  if (visibility === CbVisibility.items.external) {
    const isLimited =
      affiliateDisclosure === CbAffiliateDisclosure.items.yes ||
      affiliateDisclosure === CbAffiliateDisclosure.items.complete_later;
    return isLimited ? CbATSPool.items.ats_i : CbATSPool.items.ats_sm;
  } else {
    return CbATSPool.items.internal;
  }
};

export const usePassValidationErrorHandler = () => {
  const { showAlert, showApiError } = useAlerts();

  const handlePassValidationError = (
    error: ApiError,
    containerId?: string,
    alertTitle = 'Your order was marked for review.'
  ) => {
    const processedError = processApiError(error);

    if (
      (error?.originalStatus === 422 || error.status === 422) &&
      processedError.message.includes('pass validation')
    ) {
      showAlert(alertTitle, {
        containerId,
        type: 'info',
        staticMessage: (
          <Text size="sm" colorVariant="primary">
            <ZendeskContactButton zendeskTicketGroupId="second_market_market_feed">
              Please contact us
            </ZendeskContactButton>{' '}
            if you have any questions.
          </Text>
        ),
      });
    } else {
      showApiError(error, { containerId });
    }
  };

  return {
    handlePassValidationError,
  };
};
