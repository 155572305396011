import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Tooltip } from '@npm/core/ui/components/atoms/Tooltip';
import { Heading } from '@npm/core/ui/components/atoms/Typography';
import { type TypographyItemProps } from '@npm/core/ui/components/atoms/Typography/typography.types';
import { useTheme } from 'styled-components';

import * as S from './SectionHeading.styles';

export type SectionHeadingProps = {
  title: string;
  titleSuffix?: string | React.JSX.Element;
  tooltip?: React.ReactNode;
  marginBottom?: TypographyItemProps['marginBottom'];
};

export const SectionHeading = ({
  title,
  titleSuffix,
  tooltip,
  marginBottom,
}: SectionHeadingProps) => {
  const theme = useTheme();

  return (
    <Heading variant="h2" marginBottom={marginBottom}>
      {title} {titleSuffix}
      {tooltip && (
        <Tooltip title={tooltip}>
          <S.TooltipIconWrapper>
            <Icon
              name="info-circle"
              size="sm"
              color={theme.color.info.typography.primary}
            />
          </S.TooltipIconWrapper>
        </Tooltip>
      )}
    </Heading>
  );
};
