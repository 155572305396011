import React from 'react';
import { useParams } from 'react-router-dom';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Helmet } from '@npm/core/ui/components/atoms/Helmet';
import { CbLabel } from '@npm/core/ui/components/atoms/Label';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type RouteTitleProps } from '@npm/core/ui/routes/router.types';
import { truncate } from '@npm/core/ui/utils/formatters';
import { CbSubmissionState } from '@npm/data-access';

import { useSubmissionWithEventShow } from '../../../submission/useSubmissionWithEventShow';

export const SubmissionPageTitle = ({ raw }: RouteTitleProps) => {
  const { isMobile } = useBreakpoints();
  const { submissionId } = useParams();

  const { data, isLoading } = useSubmissionWithEventShow({
    submissionId: parseInt(submissionId),
    includeField: [
      'steps',
      'items',
      'event',
      'transition_checklist',
      'account',
      'user',
      'signed_by',
      'signed_by_spouse',
      'account',
    ],
  });

  const title = `Submission #${submissionId}`;

  if (isLoading) {
    return null;
  }

  if (raw) {
    return <>{isMobile ? truncate(title) : title}</>;
  }

  return (
    <>
      <Helmet pageTitle={title}></Helmet>
      <Flex gap="sm" align="baseline">
        {CbSubmissionState.getLabel(CbLabel, data.state)}
        {title}
      </Flex>
    </>
  );
};
