import { Flex } from '@npm/core/ui/components/atoms/common';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { Instructions } from '@npm/core/ui/components/molecules/Instructions';
import { type EducationalModalConfig } from '@npm/core/ui/components/organisms/EducationalModal/EducationalModal.types';

import { EducationalModalCompanyLogo } from './components/EducationalModalCompanyLogo';

const cartaLogoDark = '/public/assets/company-logo/carta-dark.svg';
const cartaLogoLight = '/public/assets/company-logo/carta-light.svg';
const pulleyLogo = '/public/assets/company-logo/pulley.svg';
const shareworksLogoDark = '/public/assets/company-logo/shareworks-dark.svg';
const shareworksLogoLight = '/public/assets/company-logo/shareworks-light.svg';

const cartaInstructions = (
  <Instructions
    instructions={[
      'Login to Carta and Access your portfolio',
      'Within your portfolio, view all issued certificates and grants',
      'Click into a specific certificate and/or grant to download a copy that proves that you own equity in a given company',
    ]}
  />
);

const shareworksInstructions = (
  <Instructions
    instructions={[
      'Upon logging in, select your Documents tab.',
      'From the Documents section, you can access any documents related to your certificate, grant, or award for a given company.',
      'To view/download a document, simply select the document name.',
    ]}
  />
);

const pulleyInstructions = (
  <Instructions
    instructions={[
      'Upon logging in, select your Dashboard tab and go to your Portfolio or Documents section.',
      'Find the specific ID corresponding to the certificate or option grant you would like to add.',
      'Click to view a specific ID or document and take a screenshot or download your proof of ownership.',
    ]}
  />
);

export const educationalModalCertificatesConfig: EducationalModalConfig = {
  title: 'Where can I find my certificates and options grants?',
  subTitle: 'Select your company’s cap table management tool.',

  variant: 'withLogos',

  items: [
    {
      id: 'carta',
      title: 'Carta',
      subTitle:
        'Software and services helps people manage equity, build businesses.',

      icon: (
        <EducationalModalCompanyLogo
          lightModeSrc={cartaLogoLight}
          darkModeSrc={cartaLogoDark}
          alt={'Carta logo'}
        />
      ),

      content: (
        <>
          <Heading variant={'h3'}>
            Your Carta certificates and options grants can be found here
          </Heading>
          {cartaInstructions}
        </>
      ),
      smallScreenContent: (
        <>
          <Flex align={'center'} gap={'sm'}>
            <EducationalModalCompanyLogo
              lightModeSrc={cartaLogoLight}
              darkModeSrc={cartaLogoDark}
              alt={'Carta logo'}
            />
            <Text colorVariant={'primary'}>
              Find Your Certificates in Carta
            </Text>
          </Flex>
          {cartaInstructions}
        </>
      ),
    },

    {
      id: 'shareworks',
      title: 'Shareworks',
      subTitle:
        'Equity compensation management platform designed for both private and public.',

      icon: (
        <EducationalModalCompanyLogo
          lightModeSrc={shareworksLogoLight}
          darkModeSrc={shareworksLogoDark}
          alt={'Shareworks logo'}
        />
      ),

      content: (
        <>
          <Heading variant={'h3'}>
            Your Shareworks certificates and options grants can be found here
          </Heading>
          {shareworksInstructions}
        </>
      ),
      smallScreenContent: (
        <>
          <Flex align={'center'} gap={'sm'}>
            <EducationalModalCompanyLogo
              lightModeSrc={shareworksLogoLight}
              darkModeSrc={shareworksLogoDark}
              alt={'Shareworks logo'}
            />
            <Text colorVariant={'primary'}>
              Find Your Certificates in Shareworks
            </Text>
          </Flex>
          {shareworksInstructions}
        </>
      ),
    },

    {
      id: 'pulley',
      title: 'Pulley',

      subTitle:
        'Pulley helps thousands of companies issue and track their equity.',

      icon: (
        <EducationalModalCompanyLogo
          lightModeSrc={pulleyLogo}
          darkModeSrc={pulleyLogo}
          alt={'Pulley logo'}
        />
      ),

      content: (
        <>
          <Heading variant={'h3'}>
            Your Pulley certificates and options grants can be found here
          </Heading>
          {pulleyInstructions}
        </>
      ),
      smallScreenContent: (
        <>
          <Flex align={'center'} gap={'sm'}>
            <EducationalModalCompanyLogo
              lightModeSrc={pulleyLogo}
              darkModeSrc={pulleyLogo}
              alt={'Pulley logo'}
            />
            <Text colorVariant={'primary'}>
              Find Your Certificates in Pulley
            </Text>
          </Flex>
          {pulleyInstructions}
        </>
      ),
    },
  ],
};
