import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Skeleton } from '@npm/core/ui/components/atoms/Skeleton/Skeleton';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import { CypressDataIds } from '@npm/core/ui/constants';
import { CbAccountType, CbWorkstationType } from '@npm/data-access';

import { useCurrentAccount, useCurrentWorkstation } from '../../../role';
import { useCurrentRole } from '../../../role/hooks/useCurrentRole';
import { SwitcherButton } from '../../SwitcherButton';
import { Label, Row, Title } from '../../SwitcherButton/SwitcherButton.styles';
import { useNumberOfInvestorAccounts } from '../RoleSwitcher.hooks';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  isCollapsed: boolean;
};

export const RoleSwitcherButton = ({
  onClick,
  disabled,
  isCollapsed,
}: Props) => {
  const { isInvAccountCentric } = useCurrentAccount();
  const workstation = useCurrentWorkstation();
  const role = useCurrentRole();

  const numberOfInvestorAccounts = useNumberOfInvestorAccounts();

  const renderButtonContent = () => {
    if (workstation?.type?.code === CbWorkstationType.items.investor) {
      if (!isInvAccountCentric) {
        return (
          <Row>
            <Title>Accounts</Title>
            {numberOfInvestorAccounts && (
              <Label>{numberOfInvestorAccounts}</Label>
            )}
          </Row>
        );
      }

      return (
        <Row data-dd-privacy="mask">
          <Title title={role?.subject?.name}>
            {role?.subject?.type?.code === CbAccountType.items.Anonymous
              ? 'Anonymous'
              : role?.subject?.name}
          </Title>
          {role?.subject?.brokerage_firm_name && (
            <Label title={role.subject.brokerage_firm_name}>
              {role.subject.brokerage_firm_name}
            </Label>
          )}
        </Row>
      );
    }

    if (workstation?.type?.code === CbWorkstationType.items.brokerage) {
      return (
        <Row>
          <Title title={role?.role_name?.name} data-dd-privacy="mask">
            {role?.role_name?.name}
          </Title>
          {role?.subject?.name && (
            <Label data-dd-privacy="mask" title={role.subject.name}>
              {role.subject.name}
            </Label>
          )}
        </Row>
      );
    }

    if (workstation?.type?.code === CbWorkstationType.items.issuer) {
      return (
        <Flex
          direction="column"
          align="flex-start"
          style={{ overflow: 'hidden' }}
        >
          <Title title={role?.subject?.name} data-dd-privacy="mask">
            {role?.subject?.name}
          </Title>
          <Text size="xxs" colorVariant="tertiary">
            {role?.role_name?.name}
          </Text>
        </Flex>
      );
    }

    if (workstation?.type?.code === CbWorkstationType.items.advisor) {
      return (
        <Row>
          <Title title={role?.role_name?.name} data-dd-privacy="mask">
            {role?.role_name?.name}
          </Title>
          {role?.subject?.name && (
            <Label data-dd-privacy="mask" title={role.subject.name}>
              {role.subject.name}
            </Label>
          )}
        </Row>
      );
    }

    return null;
  };

  return (
    <SwitcherButton
      size="lg"
      onClick={onClick}
      disabled={disabled}
      data-cy={CypressDataIds.RoleSwitcher.OpenButton}
      data-dd-action-name="Role Switcher Button"
      isCollapsed={isCollapsed}
      hideWhenCollapsed
      isHighlighted
      icon={
        workstation?.type?.code === CbWorkstationType.items.issuer ? (
          <CompanyLogo
            title={role?.subject?.logo_name}
            url={role?.subject?.logo_url}
            size={16}
          />
        ) : null
      }
    >
      {renderButtonContent() || <Skeleton.Input size="small" />}
    </SwitcherButton>
  );
};
