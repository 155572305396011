// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  IntrinsicValuesApi,
  LatestIntrinsicValueErrorCodes,
  LatestIntrinsicValueErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new IntrinsicValuesApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type LatestIntrinsicValueMethod = IntrinsicValuesApi['latestIntrinsicValue'];
export type LatestIntrinsicValueResponseType = MethodResult<LatestIntrinsicValueMethod>;

export const useLatestIntrinsicValue = (
  variables: Parameters<LatestIntrinsicValueMethod>[0],
  config?: UseQueryConfig<
    LatestIntrinsicValueMethod,
    LatestIntrinsicValueErrorTypes
  >
) => {
  return useQuery<
    LatestIntrinsicValueMethod,
    LatestIntrinsicValueErrorTypes
  >(
    (...args) => api.latestIntrinsicValue(...args),
    LatestIntrinsicValueErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['LatestIntrinsicValue'],
        ...apiQueryConfig.LatestIntrinsicValue,
        ...config?.queryConfig
      },
    },
    'LatestIntrinsicValue',
  );
};

export const useLatestIntrinsicValueInfinite = (
  variables: Parameters<LatestIntrinsicValueMethod>[0],
  config?: UseInfiniteQueryConfig<
    LatestIntrinsicValueMethod,
    LatestIntrinsicValueErrorTypes
  >
) => {
  return useInfiniteQuery<
    LatestIntrinsicValueMethod,
    LatestIntrinsicValueErrorTypes
  >(
    (...args) => api.latestIntrinsicValue(...args),
    LatestIntrinsicValueErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['LatestIntrinsicValue', 'LatestIntrinsicValueInfinite'],
        ...apiQueryConfig.LatestIntrinsicValue,
        ...config?.queryConfig,
      },
    },
    'LatestIntrinsicValue',
  );
};

export const useLatestIntrinsicValueLazy = (baseOptions?: {
  variables?: Parameters<LatestIntrinsicValueMethod>[0],
  config?: UseQueryConfig<
    LatestIntrinsicValueMethod,
    LatestIntrinsicValueErrorTypes
  >
}) => {
  return useLazyQuery<
    LatestIntrinsicValueMethod,
    LatestIntrinsicValueErrorTypes
  >(
    (...args) => api.latestIntrinsicValue(...args),
    LatestIntrinsicValueErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['LatestIntrinsicValue'],
        ...apiQueryConfig.LatestIntrinsicValue,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'LatestIntrinsicValue',
  );
};

