import { useCallback, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';

import { useTheme } from 'styled-components';

import * as S from './ScrollIndicator.styles';

type Props = {
  bottomOffset: number;
};

/**
 * Render this component at the very bottom of a relative container.
 */
export const ScrollIndicator = ({ bottomOffset }: Props) => {
  const theme = useTheme();
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const [bottomIntersectionRef, hasReachedBottom] = useInView({
    threshold: 0,
    rootMargin: `0px 0px ${-bottomOffset + theme.spacing.lg}px 0px`,
    initialInView: true,
  });

  const setRefs = useCallback(
    node => {
      bottomRef.current = node;
      bottomIntersectionRef(node);
    },
    [bottomIntersectionRef]
  );

  const handleClick = () => {
    const scrollOptions: ScrollIntoViewOptions = { behavior: 'smooth' };

    if (!isMobile) {
      scrollOptions.block = bottomOffset === 0 ? 'end' : 'start';
      scrollOptions.inline = 'nearest';
    }

    if (!hasReachedBottom) {
      bottomRef.current?.scrollIntoView(scrollOptions);
    }
  };

  return (
    <>
      <div
        ref={setRefs}
        style={{ width: 1, height: 1 }} // Needed to detect the intersection for complex layouts.
      />
      <S.ScrollIndicator
        $bottomOffset={bottomOffset}
        $hasReachedBottom={hasReachedBottom}
        onClick={handleClick}
        role="button"
      />
    </>
  );
};
