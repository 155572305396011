import React, { useCallback, useMemo } from 'react';

import { Form } from '@npm/core/ui/components/atoms/Form';
import { CypressDataIds } from '@npm/core/ui/constants';
import { type FormInstance, type FormProps } from 'antd';
import { type UploadFile } from 'antd/lib/upload/interface';

import { type UploadRequestType } from '../../DataRoom';

import * as S from './UploadForm.styles';
import { FileItem, type UploadFormFields } from './FileItem';
import { UploadDragger } from './UploadDragger';

type Props = {
  form: FormInstance<Record<string, UploadRequestType>>;
  fileList: UploadFile[];
  addFile: (file: UploadFile) => void;
  removeFile: (file: UploadFile) => void;
  progresses?: Record<
    string,
    {
      loaded: number;
      total: number;
    }
  >;
  isUploading?: boolean;
  multiple?: boolean;
  formFields?: UploadFormFields;
  draggerTitle?: string;
  accept?: string[] | null;
  acceptLabel?: string | null;
} & Pick<FormProps, 'id' | 'onFinish'>;

export const UploadForm = ({
  form,
  fileList,
  progresses,
  addFile,
  removeFile,
  isUploading,
  formFields,
  multiple = true,
  draggerTitle,
  accept,
  acceptLabel,
  ...formProps
}: Props) => {
  const totalProgress = useMemo(() => {
    const all = Object.values(progresses).reduce(
      (acc, val) => {
        acc.loaded += val.loaded;
        acc.total += val.total;
        return acc;
      },
      { loaded: 0, total: 0 }
    );
    return all.total ? all.loaded / all.total : undefined;
  }, [progresses]);

  const renderFiles = useCallback(
    () =>
      fileList.map(file => {
        const { loaded, total } = progresses[file.uid] || {};

        return (
          <FileItem
            key={file.uid}
            file={file}
            onRemove={() => removeFile(file)}
            progress={loaded ? loaded / total : undefined}
            disabled={isUploading}
            formFields={formFields}
          />
        );
      }),
    [fileList, progresses, isUploading, removeFile, formFields]
  );

  return (
    <S.Container>
      <UploadDragger
        fileList={fileList}
        onUpload={file => {
          if (!multiple && fileList[0]) {
            removeFile(fileList[0]);
          }
          addFile(file);
        }}
        progress={totalProgress}
        isUploading={isUploading}
        multiple={multiple}
        title={draggerTitle}
        accept={accept}
        acceptLabel={acceptLabel}
      />
      <Form
        form={form}
        data-cy={CypressDataIds.DataRooms.UploadDrawer.Form}
        {...formProps}
      >
        {renderFiles()}
      </Form>
    </S.Container>
  );
};
