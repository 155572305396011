// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  AgreementApi,
  AgreementIndexErrorCodes,
  AgreementIndexErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new AgreementApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type AgreementIndexMethod = AgreementApi['agreementIndex'];
export type AgreementIndexResponseType = MethodResult<AgreementIndexMethod>;

export const useAgreementIndex = (
  variables: Parameters<AgreementIndexMethod>[0],
  config?: UseQueryConfig<
    AgreementIndexMethod,
    AgreementIndexErrorTypes
  >
) => {
  return useQuery<
    AgreementIndexMethod,
    AgreementIndexErrorTypes
  >(
    (...args) => api.agreementIndex(...args),
    AgreementIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AgreementIndex'],
        ...apiQueryConfig.AgreementIndex,
        ...config?.queryConfig
      },
    },
    'AgreementIndex',
  );
};

export const useAgreementIndexInfinite = (
  variables: Parameters<AgreementIndexMethod>[0],
  config?: UseInfiniteQueryConfig<
    AgreementIndexMethod,
    AgreementIndexErrorTypes
  >
) => {
  return useInfiniteQuery<
    AgreementIndexMethod,
    AgreementIndexErrorTypes
  >(
    (...args) => api.agreementIndex(...args),
    AgreementIndexErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['AgreementIndex', 'AgreementIndexInfinite'],
        ...apiQueryConfig.AgreementIndex,
        ...config?.queryConfig,
      },
    },
    'AgreementIndex',
  );
};

export const useAgreementIndexLazy = (baseOptions?: {
  variables?: Parameters<AgreementIndexMethod>[0],
  config?: UseQueryConfig<
    AgreementIndexMethod,
    AgreementIndexErrorTypes
  >
}) => {
  return useLazyQuery<
    AgreementIndexMethod,
    AgreementIndexErrorTypes
  >(
    (...args) => api.agreementIndex(...args),
    AgreementIndexErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['AgreementIndex'],
        ...apiQueryConfig.AgreementIndex,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'AgreementIndex',
  );
};

