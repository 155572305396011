import { Table as BaseTable } from '@npm/core/ui/components/molecules/Table';
import styled from 'styled-components';

export const NAME_CELL_CLASS = 'name-cell';

export const Table = styled(BaseTable)`
  .${NAME_CELL_CLASS} {
    word-break: break-all;
  }
`;

export const SignaturesWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.color.success.typography.primary};
    margin-right: ${({ theme }) => theme.spacing.xs}px;
  }
`;
