import { useMemo } from 'react';

import { useLocation } from '../components/molecules/Link';
import { findRoute, routes } from '../routes';

export const useCurrentRoute = () => {
  const location = useLocation();

  return useMemo(() => findRoute(routes, location.pathname), [location]);
};
