import React from 'react';

import { type BannerProps } from '@npm/core/ui/components/atoms/Banner';
import { Button } from '@npm/core/ui/components/atoms/Button';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { type TableVerticalRowType } from '@npm/core/ui/components/molecules/TableVertical';
import { CypressDataIds } from '@npm/core/ui/constants';
import {
  DATE_FORMATS,
  formatDate,
  getDurations,
} from '@npm/core/ui/utils/formatters';
import {
  CbEventPhase,
  CbOwnerType,
  codebookEquals,
  type CodebookOrString,
  type EventAggregate,
  type FeeConfiguration,
  getCodebookCode,
  getCodebookName,
} from '@npm/data-access';

import { type ProgramProps } from '../ProgramCard';

import * as S from './ProgramCard.styles';
import { ProgramFees } from './ProgramFees';
import { SubmissionStatistics } from './SubmissionStatistics';

export enum ProgramCardFields {
  TYPE = 'Type',
  ROLE = 'Role',
  OFFER_PRICE = 'Offer Price',
  MIN_RESERVE_PRICE = 'Reserve Price',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  RESERVE_PRICE = 'Reserve Price',
  MAX_RESERVE_PRICE = 'Max Price',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MAX_BID_PRICE = 'Max Price',
  MIN_BUY_SIZE = 'Min Quantity',
  CONSIDERATION_STOCK_PRICE = 'Consideration Stock Price',
  DATA_ROOM = 'Data Room',
  NDA = 'NDA',
  ORDERS = 'Orders',
  SUBMISSIONS = 'Submissions',
  FEES = 'Fees',
}

export const getProgramCardRows = (
  workstation: ProgramProps['workstation'],
  shortened: boolean,
  hideFields: string[] = [], // Overwrites previous rules
  clickHandler?: (event: EventAggregate, tab?: 'documents') => void,
  feeConfiguration?: FeeConfiguration,
  isInformationalEvent?: boolean
): TableVerticalRowType<EventAggregate>[] => [
  {
    title: ProgramCardFields.TYPE,
    key: 'type',
    render: ({ settings, type }) =>
      settings?.type_display_value?.name ?? type?.name,
    hidden: hideFields.includes(ProgramCardFields.TYPE),
  },
  {
    title: ProgramCardFields.OFFER_PRICE,
    key: 'offer_price',
    render: ({ order_settings, issuance, issuer_entity }) => (
      <Text.Price
        value={[
          order_settings?.min_offer_price,
          order_settings?.max_offer_price,
        ]}
        currency={
          issuance?.denomination?.name ?? issuer_entity?.denomination?.name
        }
        formatOptions={{ long: true }}
      />
    ),
    hidden: ({ type }) =>
      hideFields.includes(ProgramCardFields.OFFER_PRICE) ||
      type.code !== CbOwnerType.items.TenderOffer ||
      shortened,
  },
  {
    title: ProgramCardFields.MIN_RESERVE_PRICE,
    key: 'min_reserve_price',
    render: ({ order_settings, issuance, issuer_entity }) => (
      <Text.Price
        value={order_settings?.min_reserve_price}
        currency={
          issuance?.denomination?.name ?? issuer_entity?.denomination?.name
        }
        formatOptions={{ long: true }}
      />
    ),
    hidden: ({ type, order_settings }) =>
      hideFields.includes(ProgramCardFields.MIN_RESERVE_PRICE) ||
      !order_settings?.min_reserve_price ||
      type.code !== CbOwnerType.items.DutchAuctionSell,
  },
  {
    title: ProgramCardFields.RESERVE_PRICE,
    key: 'reserve_price',
    render: ({ order_settings, issuance, issuer_entity }) => (
      <Text.Price
        value={order_settings?.reserve_price}
        currency={
          issuance?.denomination?.name ?? issuer_entity?.denomination?.name
        }
        formatOptions={{ long: true }}
      />
    ),
    hidden: ({ type, order_settings }) =>
      hideFields.includes(ProgramCardFields.MIN_RESERVE_PRICE) ||
      !order_settings?.reserve_price ||
      type.code !== CbOwnerType.items.DutchAuctionBuy,
  },
  {
    title: ProgramCardFields.MAX_RESERVE_PRICE,
    key: 'max_reserve_price',
    render: ({ order_settings, issuance, issuer_entity }) => (
      <Text.Price
        value={order_settings?.max_reserve_price}
        currency={
          issuance?.denomination?.name ?? issuer_entity?.denomination?.name
        }
        formatOptions={{ long: true }}
      />
    ),
    hidden: ({ type, order_settings }) =>
      hideFields.includes(ProgramCardFields.MAX_RESERVE_PRICE) ||
      !order_settings?.max_reserve_price ||
      !(
        type.code === CbOwnerType.items.DutchAuctionSell &&
        order_settings.sell_schedule
      ),
  },
  {
    title: ProgramCardFields.MAX_BID_PRICE,
    key: 'max_bid_price',
    render: ({ order_settings, issuance, issuer_entity }) => (
      <Text.Price
        value={order_settings?.max_reserve_price}
        currency={
          issuance?.denomination?.name ?? issuer_entity?.denomination?.name
        }
        formatOptions={{ long: true }}
      />
    ),
    hidden: ({ order_settings, type }) =>
      hideFields.includes(ProgramCardFields.MAX_BID_PRICE) ||
      !order_settings?.max_reserve_price ||
      type.code !== CbOwnerType.items.DutchAuctionBuy,
  },
  {
    title: ProgramCardFields.MIN_BUY_SIZE,
    key: 'min_bid_size',
    render: event => (
      <Text.Quantity value={event.order_settings?.min_buy_size} />
    ),
    hidden: ({ order_settings, type }) =>
      hideFields.includes(ProgramCardFields.MIN_BUY_SIZE) ||
      !order_settings?.min_buy_size ||
      type.code !== CbOwnerType.items.DutchAuctionBuy ||
      shortened,
  },
  {
    title: ProgramCardFields.CONSIDERATION_STOCK_PRICE,
    key: 'consideration_stock_price',
    render: ({ order_settings, issuance, issuer_entity }) => (
      <Text.Price
        value={order_settings?.consideration_stock_price}
        currency={
          issuance?.denomination?.name ?? issuer_entity?.denomination?.name
        }
        formatOptions={{ long: true }}
      />
    ),
    hidden: ({ order_settings, type }) =>
      !order_settings?.consideration_stock_price ||
      type.code !== CbOwnerType.items.MergerAndAcquisition,
  },
  {
    title: ProgramCardFields.FEES,
    key: 'fees',
    render: event => (
      <ProgramFees event={event} feeConfiguration={feeConfiguration} />
    ),
    hidden: !feeConfiguration,
  },
  {
    title: ProgramCardFields.DATA_ROOM,
    key: 'data_room',
    render: event => (
      <Button
        variant="outline"
        size="sm"
        color="info"
        onClick={() => {
          clickHandler(event, 'documents');
        }}
      >
        Access Documents
      </Button>
    ),
    hidden: ({ data_room }) =>
      !data_room ||
      !clickHandler ||
      hideFields.includes(ProgramCardFields.DATA_ROOM),
  },
  {
    title: ProgramCardFields.NDA,
    key: 'nda_signed_at',
    render: ({ nda_signed_at }) =>
      nda_signed_at ? (
        <S.NdaContainer
          data-cy={CypressDataIds.InvestorWorkstation.Nda.NdaSigned}
        >
          <Text size="sm" colorVariant="primary" color="success">
            <Flex as="span" align="center" gap="xs">
              <Icon name="check" size="xs" />
              Signed
            </Flex>
          </Text>
          <Text size="sm" colorVariant="secondary">
            {formatDate(nda_signed_at, { dateFormat: DATE_FORMATS.DATE })}
          </Text>
        </S.NdaContainer>
      ) : (
        <Text
          size="sm"
          color="warning"
          colorVariant="primary"
          data-cy={CypressDataIds.InvestorWorkstation.Nda.NdaSigned}
        >
          <Flex as="span" align="center" gap="xs">
            <Icon name="ballpen" size="xs" />
            Signature needed
          </Flex>
        </Text>
      ),
    hidden: ({ allow_nda }) =>
      !allow_nda ||
      hideFields.includes(ProgramCardFields.NDA) ||
      workstation === 'issuer' ||
      !(workstation === 'investor' || shortened),
  },
  {
    title: isInformationalEvent
      ? ProgramCardFields.SUBMISSIONS
      : ProgramCardFields.ORDERS,
    key: 'submission_statistics',
    render: ({ submission_statistics, type }) => (
      <SubmissionStatistics
        statistics={submission_statistics}
        ownerType={type}
      />
    ),
    hidden: ({ type }) =>
      hideFields.includes(ProgramCardFields.ORDERS) ||
      shortened ||
      type?.code === CbOwnerType.items.MergerAndAcquisition,
  },
];

export const getBannerTypeByPhase = (
  phaseCode: string
): BannerProps['type'] => {
  switch (phaseCode) {
    case CbEventPhase.items.overview:
      return 'success';
    case CbEventPhase.items.preview:
      return 'info';
    case CbEventPhase.items.upcoming:
    case CbEventPhase.items.review:
    default:
      return 'warning';
  }
};

export const getBannerMessageByPhase = (
  currentPhase: CodebookOrString,
  expired: boolean
) => {
  if (codebookEquals(currentPhase, CbEventPhase.items.upcoming)) {
    return null;
  } else if (codebookEquals(currentPhase, CbEventPhase.items.overview)) {
    return 'Live';
  } else if (expired) {
    return 'Expired';
  } else {
    return getCodebookName(currentPhase);
  }
};

export const getBannerDescriptionByPhase = (event: EventAggregate) => {
  if (event.expired) {
    return formatDate(event.review_end ?? event.stop, { withTimeZone: true });
  }

  let startOfPhase: string | undefined = undefined;
  let endOfPhase: string | undefined = undefined;

  switch (getCodebookCode(event.current_phase)) {
    // Upcoming
    case CbEventPhase.items.upcoming:
      startOfPhase = event.preview_start || event.start;
      endOfPhase = undefined;
      break;
    // Active
    case CbEventPhase.items.preview:
      startOfPhase = undefined;
      endOfPhase = event.start;
      break;
    case CbEventPhase.items.overview:
      startOfPhase = undefined;
      endOfPhase = event.stop;
      break;
    case CbEventPhase.items.review:
      startOfPhase = undefined;
      endOfPhase = event.review_end;
      break;
    default:
      break;
  }

  if (startOfPhase) {
    const remainingTime = getDurations(
      new Date(),
      new Date(startOfPhase),
      false
    ).slice(0, 3);

    return `Starts in ${remainingTime
      .map(r => `${r.time} ${r.text}`)
      .join(', ')}`;
  }

  if (endOfPhase) {
    const remainingTime = getDurations(
      new Date(),
      new Date(endOfPhase),
      false
    ).slice(0, 3);

    return `Ends in ${remainingTime
      .map(r => `${r.time} ${r.text}`)
      .join(', ')}`;
  }

  return '-';
};
