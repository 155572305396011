// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AxiosRequestConfig } from 'axios';
import {
  Configuration,
  CompaniesOverviewsApi,
  CompaniesOverviewsErrorCodes,
  CompaniesOverviewsErrorTypes,
  CompaniesOverviewsSectorsErrorCodes,
  CompaniesOverviewsSectorsErrorTypes,
} from '..';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMutation, useQuery, useLazyQuery, UseQueryConfig, MethodResult, useInfiniteQuery, UseInfiniteQueryConfig } from '../../hooks/apiBaseHooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { apiQueryConfig, apiMutationConfig } from "../../config/react-query";
import { getAxiosInstance } from "../../config/axios";

const axiosInstance = getAxiosInstance();
const api = new CompaniesOverviewsApi(
  new Configuration({
          basePath: axiosInstance.defaults.baseURL
      }), undefined, axiosInstance,
);


export type CompaniesOverviewsMethod = CompaniesOverviewsApi['companiesOverviews'];
export type CompaniesOverviewsResponseType = MethodResult<CompaniesOverviewsMethod>;

export const useCompaniesOverviews = (
  variables: Parameters<CompaniesOverviewsMethod>[0],
  config?: UseQueryConfig<
    CompaniesOverviewsMethod,
    CompaniesOverviewsErrorTypes
  >
) => {
  return useQuery<
    CompaniesOverviewsMethod,
    CompaniesOverviewsErrorTypes
  >(
    (...args) => api.companiesOverviews(...args),
    CompaniesOverviewsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesOverviews'],
        ...apiQueryConfig.CompaniesOverviews,
        ...config?.queryConfig
      },
    },
    'CompaniesOverviews',
  );
};

export const useCompaniesOverviewsInfinite = (
  variables: Parameters<CompaniesOverviewsMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesOverviewsMethod,
    CompaniesOverviewsErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesOverviewsMethod,
    CompaniesOverviewsErrorTypes
  >(
    (...args) => api.companiesOverviews(...args),
    CompaniesOverviewsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesOverviews', 'CompaniesOverviewsInfinite'],
        ...apiQueryConfig.CompaniesOverviews,
        ...config?.queryConfig,
      },
    },
    'CompaniesOverviews',
  );
};

export const useCompaniesOverviewsLazy = (baseOptions?: {
  variables?: Parameters<CompaniesOverviewsMethod>[0],
  config?: UseQueryConfig<
    CompaniesOverviewsMethod,
    CompaniesOverviewsErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesOverviewsMethod,
    CompaniesOverviewsErrorTypes
  >(
    (...args) => api.companiesOverviews(...args),
    CompaniesOverviewsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesOverviews'],
        ...apiQueryConfig.CompaniesOverviews,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesOverviews',
  );
};


export type CompaniesOverviewsSectorsMethod = CompaniesOverviewsApi['companiesOverviewsSectors'];
export type CompaniesOverviewsSectorsResponseType = MethodResult<CompaniesOverviewsSectorsMethod>;

export const useCompaniesOverviewsSectors = (
  variables: Parameters<CompaniesOverviewsSectorsMethod>[0],
  config?: UseQueryConfig<
    CompaniesOverviewsSectorsMethod,
    CompaniesOverviewsSectorsErrorTypes
  >
) => {
  return useQuery<
    CompaniesOverviewsSectorsMethod,
    CompaniesOverviewsSectorsErrorTypes
  >(
    (...args) => api.companiesOverviewsSectors(...args),
    CompaniesOverviewsSectorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesOverviewsSectors'],
        ...apiQueryConfig.CompaniesOverviewsSectors,
        ...config?.queryConfig
      },
    },
    'CompaniesOverviewsSectors',
  );
};

export const useCompaniesOverviewsSectorsInfinite = (
  variables: Parameters<CompaniesOverviewsSectorsMethod>[0],
  config?: UseInfiniteQueryConfig<
    CompaniesOverviewsSectorsMethod,
    CompaniesOverviewsSectorsErrorTypes
  >
) => {
  return useInfiniteQuery<
    CompaniesOverviewsSectorsMethod,
    CompaniesOverviewsSectorsErrorTypes
  >(
    (...args) => api.companiesOverviewsSectors(...args),
    CompaniesOverviewsSectorsErrorCodes,
    variables,
    {
      ...config,
      queryConfig: {
        queryKey: ['CompaniesOverviewsSectors', 'CompaniesOverviewsSectorsInfinite'],
        ...apiQueryConfig.CompaniesOverviewsSectors,
        ...config?.queryConfig,
      },
    },
    'CompaniesOverviewsSectors',
  );
};

export const useCompaniesOverviewsSectorsLazy = (baseOptions?: {
  variables?: Parameters<CompaniesOverviewsSectorsMethod>[0],
  config?: UseQueryConfig<
    CompaniesOverviewsSectorsMethod,
    CompaniesOverviewsSectorsErrorTypes
  >
}) => {
  return useLazyQuery<
    CompaniesOverviewsSectorsMethod,
    CompaniesOverviewsSectorsErrorTypes
  >(
    (...args) => api.companiesOverviewsSectors(...args),
    CompaniesOverviewsSectorsErrorCodes,
    baseOptions?.variables,
    {
      ...baseOptions?.config,
      queryConfig: {
        queryKey: ['CompaniesOverviewsSectors'],
        ...apiQueryConfig.CompaniesOverviewsSectors,
        ...baseOptions?.config?.queryConfig,
      },
    },
    'CompaniesOverviewsSectors',
  );
};

