import { useMemo } from 'react';

import { isUserPermitted, Permission } from '@npm/core/ui/models/permissions';
import { CbWorkstationType, type RoleType } from '@npm/data-access';

import { useUserContextStore } from '../user/context';
import { useCurrentWorkstation } from '../user/role';
import { useCurrentRole } from '../user/role/hooks/useCurrentRole';

type PermissionsObject = Partial<Record<Permission, boolean>> & {
  // contains boolean based on currently accessed workstation
  // used for multiple features, which are shared between investor and issuer workstation
  // INV_WRITE for investor and ISS_WRITE for issuer workstation
  canWrite: boolean;
};

export const usePermissions: () => PermissionsObject = () => {
  const workstation = useCurrentWorkstation();
  const role = useCurrentRole();
  const isPermissionsCheckOn = useUserContextStore(
    store => store.isPermissionsCheckOn
  );
  const roleType = role?.role_name?.code as RoleType;

  const permissionsObj: Partial<Record<Permission, boolean>> = useMemo(
    () =>
      Object.keys(Permission).reduce(
        (acc, curr: Permission) => ({
          ...acc,
          [curr]: isPermissionsCheckOn ? isUserPermitted(curr, roleType) : true,
        }),
        {}
      ),
    [roleType]
  );
  const canWrite = isPermissionsCheckOn
    ? workstation?.type?.code === CbWorkstationType.items.brokerage ||
      workstation?.type?.code === CbWorkstationType.items.investor
      ? isUserPermitted('INV_WRITE', roleType)
      : isUserPermitted('ISS_WRITE', roleType)
    : true;

  return { ...permissionsObj, canWrite };
};
