import { type UseQueryOptions as UseReactQueryOptions } from '@tanstack/react-query/build/lib/types';

import { usePreviousValueIfNewIsUndefined } from '../../../hooks/usePreviousValueIfNewIsUndefined';
import { formatNumToAddComma } from '../../../utils/formatters';

import * as S from './TotalRecordsBadge.styles';

type FirstArgument<T extends (...args: unknown[]) => unknown> =
  Parameters<T>[0];

type LoadMethod<TMethod extends (...args: unknown[]) => unknown> = (
  variables: FirstArgument<TMethod>,
  config: {
    onError?: (error: unknown) => void;
    queryConfig?: UseReactQueryOptions<unknown, unknown>;
  }
) => {
  data: {
    pagination?: {
      total_records?: number;
    };
  };
  isLoading: boolean;
};

export const useTotalRecords = <TMethod extends LoadMethod<TMethod>>(
  useLoadHook: TMethod,
  variables: FirstArgument<TMethod>,
  queryConfig?: UseReactQueryOptions<unknown, unknown>
) => {
  const { data } = useLoadHook(
    {
      ...variables,
      page: 1,
      size: 0,
    },
    {
      queryConfig,
      onError: () => {
        // ignore error
      },
    }
  );

  return usePreviousValueIfNewIsUndefined(
    (
      data as unknown as {
        pagination?: {
          total_records?: number;
        };
      }
    )?.pagination?.total_records
  );
};

type Props<TMethod extends LoadMethod<TMethod>> = {
  useLoadHook: TMethod;
  variables: FirstArgument<TMethod>;
  queryConfig?: UseReactQueryOptions<unknown, unknown>;
  className?: string;
  isEnabled?: boolean;
  showZeroValue?: boolean;
};

export const TotalRecordsBadge = <TMethod extends LoadMethod<TMethod>>({
  useLoadHook,
  variables,
  className,
  isEnabled = true,
  showZeroValue = false,
}: Props<TMethod>) => {
  const totalRecords = useTotalRecords(useLoadHook, variables);

  return totalRecords != null &&
    isEnabled &&
    (showZeroValue || totalRecords > 0) ? (
    <S.Badge className={className}>{formatNumToAddComma(totalRecords)}</S.Badge>
  ) : null;
};
