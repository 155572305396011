import { type Dispatch, useEffect } from 'react';

import { StringParam, useQueryParams } from 'use-query-params';
import { useIsFirstRender } from 'usehooks-ts';

import {
  type ContextAction,
  ContextActionType,
  type StepDefinition,
  type WizardContext,
} from '../Wizard.types';

type Props<T> = {
  context: WizardContext<T>;
  updateContext: Dispatch<ContextAction<T>>;
  enabled?: boolean;
};

export const useUpdateStepInWizardBasedOnQueryParams = <T>({
  context,
  updateContext,
  enabled = true,
}: Props<T>) => {
  const isFirstMount = useIsFirstRender();

  const [queryParams, setQueryParams] = useQueryParams({
    step: StringParam,
    substep: StringParam,
  });

  // update step in context based on changing query params
  // (for browser's back and forward button to work correctly)
  useEffect(() => {
    if (queryParams.step && context.steps && enabled) {
      updateContext?.({
        type: ContextActionType.UPDATE_STEP_INDEX,
        payload: {
          step: queryParams.step,
          substep: queryParams.substep ?? undefined,
        },
      });
    }
  }, [queryParams.step, queryParams.substep, context.steps, enabled]);

  // on first mount, set step to the first one if no queryParams.step
  // or query param step key doesn't exist
  useEffect(() => {
    if (isFirstMount) {
      const isStepAvailable = Boolean(
        context.steps?.find(
          (s: StepDefinition<T>) => s.key === queryParams.step
        )
      );

      if (
        context.steps.length &&
        enabled &&
        (!queryParams.step || !isStepAvailable)
      ) {
        setQueryParams(
          {
            step: context.steps[0]?.key,
          },
          'replaceIn'
        );
      }
    }
  }, [context.steps, queryParams.step, enabled]);

  // If queryParams.step is defined, but no queryParams.substep, and there are some substeps available,
  // navigate to the first substep. We rely on it in handleNext of ProgramSubmission, so the substep
  // must be correct.
  useEffect(() => {
    if (queryParams.step && !queryParams.substep && enabled) {
      const availableSubsteps = context.steps?.find(
        one => one.key === queryParams.step
      )?.substeps;
      if (availableSubsteps?.length) {
        setQueryParams({ substep: availableSubsteps[0]?.key }, 'replaceIn');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.steps, queryParams.step, enabled]);
};
